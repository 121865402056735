import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import ilib from "ilib/lib/ilib";
import LocaleInfo from "ilib/lib/LocaleInfo";
import Spottable from "@enact/spotlight/Spottable";
import { Job } from "@enact/core/util";
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import AcquiredGraph from "../AcquiredGraph/AcquiredGraph";
import css from "./TCalendar.module.less";

import DatePicker from "../TDatePicker/DatePicker";
import { $L } from "../../utils/helperMethods";
import * as Config from "../../utils/Config";
import * as HelperMethods from "../../utils/helperMethods";
import TTimeProgressBar from "../TTimeProgressBar/TTimeProgressBar";
// const DEFAULT_WEEKDAYS = [$L('Sun'), $L('Mon'), $L('Tue'), $L('Wed'), $L('Thu'), $L('Fri'), $L('Sat')];
// 달력: fitnessBrefing 참고
const DEFAULT_WEEKDAYS = [
  $L({ key: "_calender_sun", value: "S" }),
  $L({ key: "_calender_mon", value: "M" }),
  $L({ key: "_calender_tue", value: "T" }),
  $L({ key: "_calender_wed", value: "W" }),
  $L({ key: "_calender_thu", value: "T" }),
  $L({ key: "_calender_fri", value: "F" }),
  $L({ key: "_calender_sat", value: "S" }),
];
const li = new LocaleInfo(ilib.getLocale());
//todo
const FIRST_DATY_OF_WEEK = li.getFirstDayOfWeek();
const WEEKDAYS = [];
for (let i = 0; i < DEFAULT_WEEKDAYS.length; i++) {
  WEEKDAYS[i] = DEFAULT_WEEKDAYS[(i + FIRST_DATY_OF_WEEK) % 7];
}
/*
      data : {year, month, date, thisMonth}
  */
const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);
//  selectedDate: 기본값: Wed Aug 30 2023 13:05:09 GMT+0900 (한국 표준시)
//                달력 날짜 선택시: "2022-04-29"

const TCalendarItem = ({
  data,
  onClick,
  selectedDateStr,
  //progressbar
  hasProgress,
  ...rest }) => {
  // const dataPercent = useMemo(() => {
  //   let steps = 0,
  //     kcal = 0,
  //     time = 0;
  //   if (data.history) {
  //     const keys = Object.keys(data.history);
  //     if (keys && keys.length > 0) {
  //       //last history percentage
  //       const lastItem = data.history[keys[keys.length - 1]];
  //       steps = lastItem.dailyLifitCandy * 100;
  //       kcal = lastItem.dailyLifitCalorie * 100;
  //       time = lastItem.dailyLifitTime * 100;
  //     }
  //   }
  //   return [steps, kcal, time];
  // }, [data]);
  const [pressed, setPressed] = useState(false);
  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TCALENDAR_PRESS_DELAY);
    }, Config.TCALENDAR_PRESS_DELAY)
  );

  const selected = useMemo(() => {
    const dateStr = HelperMethods.convertDateToString(
      data.year,
      data.month,
      data.date
    );
    return data.spotlight && dateStr === selectedDateStr;
  }, [data, selectedDateStr]);

  const _onClick = useCallback((ev) => {
    if(data.disabled || !data.spotlight){
        ev.stopPropagation();
        return;
    }

    setPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick(
          HelperMethods.convertDateToString(data.year, data.month, data.date)
        );
      }
    });


  }, [onClick, data]);

  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        css.calendarItem,
        selected ? css.selected : null,
        data.disabled && css.disabled,
        pressed && css.pressed,
      )}
      spotlightDisabled={!data.spotlight}
      disabled = {data.disabled}
      onClick={_onClick}
    >
      <div className={classNames(css.title, data.thisMonth ?  null : css.dark)}>
        {hasProgress ?
          (data.progress && data.total && (data.progress === data.total) ?
            <div className={css.timeCheck}/>
          :
          <>
            {data.date}
            <TTimeProgressBar
              progress={data.progress}
              total={data.total}
              className={css.timeProgressBar}
            />
          </>
          )
        :
          data.date
        }
      </div>
      {/* {onClick && (
          <div className={css.historylayer}>
              <AcquiredGraph className={css.graph} data={dataPercent} disabled={!data.spotlight} />
          </div>
      )} */}
    </SpottableComponent>
  );
};

const TCalendar = ({
  onSelectedDate,
  selectedDate=null,
  whitelist=null,
  className=null,
  hasProgress=false,
  progressList={},
  // progress=0,
  // total=1,
 }) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);
  const [_selectedDate, _setSelectedDate] = useState(new Date());

  useEffect(()=>{
    let newSelectedDate = selectedDate ? selectedDate: new Date();
    const newSelectedDateStr = HelperMethods.convertDateToString2(newSelectedDate);
    const _selectedDateStr = HelperMethods.convertDateToString2(_selectedDate);
    if(newSelectedDateStr !== _selectedDateStr){
      _setSelectedDate(newSelectedDate);
    }
  },[selectedDate]);

  const year = useMemo(()=>{
    return _selectedDate.getFullYear();
  },[_selectedDate]);

  const month = useMemo(()=>{
    return _selectedDate.getMonth()+1;
  },[_selectedDate]);

  const dateList = useMemo(() => {
    const toDay = new Date();
    const date = [];
    const now = new Date(year, month - 1);
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(firstDayOfMonth);
    lastDayOfPrevMonth.setDate(0);

    /* prevMonth date */
    for (let i = 0; i < firstDayOfMonth.getDay() - FIRST_DATY_OF_WEEK; i++) {
      const id = HelperMethods.convertDateToString(lastDayOfPrevMonth.getFullYear(), lastDayOfPrevMonth.getMonth() + 1, lastDayOfPrevMonth.getDate()) || '';
      date.push({
        year: lastDayOfPrevMonth.getFullYear(),
        month: lastDayOfPrevMonth.getMonth() + 1,
        date: lastDayOfPrevMonth.getDate(),
        thisMonth: false,
        spotlight: false,
        id: id,
        progress: progressList?.[id]?.progress || 0,
        total: progressList?.[id]?.total || 0
      });
      lastDayOfPrevMonth.setDate(lastDayOfPrevMonth.getDate() - 1);
    }
    date.reverse();
    /* this month date */
    const lastDate = lastDayOfMonth.getDate();

    for (let i = 0; i < lastDate; i++) {
      const dateStr = HelperMethods.convertDateToString(
        year,
        month,
        firstDayOfMonth.getDate()
      );
      let historyCount = 0;
      // date.push({ year: year, month: month, date: firstDayOfMonth.getDate(), dark: false, history: candyActivities[dateStr], spotlight: true });
      let spottable = firstDayOfMonth < toDay;
      if(whitelist && whitelist.length > 0){
        spottable = whitelist.indexOf(HelperMethods.convertDateToString2(firstDayOfMonth)) >=0;
      }

      const id = HelperMethods.convertDateToString(year, month, firstDayOfMonth.getDate()) || '';
      date.push({
        year: year,
        month: month,
        date: firstDayOfMonth.getDate(),
        thisMonth: true,
        spotlight: spottable, //can only spot before today
        disabled: !spottable,
        id: id,
        progress: progressList?.[id]?.progress || 0,
        total: progressList?.[id]?.total || 0
      });
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }
    /* next month date */
    for (let i = date.length; i < 42; i++) {
      const id = HelperMethods.convertDateToString(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, firstDayOfMonth.getDate()) || '';
      date.push({
        year: firstDayOfMonth.getFullYear(),
        month: firstDayOfMonth.getMonth() + 1,
        date: firstDayOfMonth.getDate(),
        thisMonth: false,
        spotlight: false,
        id: id,
        progress: progressList?.[id]?.progress || 0,
        total: progressList?.[id]?.total || 0
      });
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    return date;
  }, [_selectedDate, whitelist, year, month]);

  const onSelectMonth = useCallback(
    ({ first, last, previous }) => {
      const date = new Date(first);
      _setSelectedDate(date);
  }, []);

  const onClickSelectDate = useCallback((clickedDateStr) => {
    if(onSelectedDate){
      onSelectedDate({selectedDate: clickedDateStr})
    }
      _setSelectedDate(new Date(clickedDateStr));
  }, [onSelectedDate]);

  return (
    <Container
      className={classNames(
        css.calendar,
        className,
        themeMode === "dark" && css.isDark
      )}
    >
      <DatePicker
        mode="monthly"
        onSelect={onSelectMonth}
        selectedDate={_selectedDate}
      />
      <div className={css.weekdays}>
        {WEEKDAYS.map((item, index) => (
          <div key={index} className={css.weekdayItem}>
            {item}
          </div>
        ))}
      </div>
      <div className={css.datelist}>
        {dateList.map((item, index) => (
          <TCalendarItem
            key={"date" + index}
            data={item}
            selectedDateStr={HelperMethods.convertDateToString2(_selectedDate)}
            onClick={onClickSelectDate}
            hasProgress={hasProgress}
          />
        ))}
      </div>
    </Container>
  );
};

export default TCalendar;
