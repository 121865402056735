import css from "./BellySmoothTest.module.less";
import React, { useState, useEffect, useRef, useCallback } from "react";
import classnames from "classnames";
import RangePicker from '@enact/sandstone/RangePicker';
import { useDispatch, useSelector } from "react-redux";
import useActivatePositionDetection from "../../hooks/useActivatePositionDetection";
import {  changeLocalSettings } from "../../features/common/commonSlice";
import BellySmoother from "../BodyCheckUp/BodyAlignment/BodyAlignmentScan/BellySmoodther";
import TButton from "../../components/TButton/TButton";
import TPanel from "../../components/TPanel/TPanel";
import TBody from "../../components/TBody";
import THeader from "../../components/THeader";
import PIPCamera from "../../components/PIPCamera/PIPCamera";
import useCaptureImage from "../../hooks/useCaptureImage";
import bellyPoint from "../../../assets/bodyScan/point.png";

const BOX_SIZE = 20;
const BELLYBOX1 = [620, 330]; //x1, y1
const BELLYBOX2 = [560, 390];
const BELLYBOX3 = [675, 393];

const getRandomCoord = (box) => {
	const x = Math.floor(Math.random() * BOX_SIZE) + box[0];
	const y = Math.floor(Math.random() * BOX_SIZE) + box[1];
	return [x, y];
}

const BELLY_CHECKING_INDEX = [1, 4, 17];

/**
 * @module BellySmoothTest
 */
let intervalKey = null;
const BellySmoodhTest = ({...rest}) => {
	const {
		activatePositionDetection,
		deActivatePositionDetection,
		bodyPositionLiveData
	  } = useActivatePositionDetection();
	const { saveRecordedVideo } = useCaptureImage({});
	const {xFitBodyBellySmootherQueueSize} = useSelector((state) => state.common.localSettings);
	const cameraSize = useSelector((state) => state.camera.cameraSize);
	const [bodyPositionLiveDataLocal, setBodyPositionLiveDataLocal] = useState([]);
	const [smoothMode, setSmoothMode] = useState(true);
	const bellySmootherRef = useRef([]);
	const dispatch = useDispatch();

	useEffect(()=>{
		return () =>{
			if(intervalKey){
				clearInterval(intervalKey);
			}
			deActivatePositionDetection();
		}
	},[]);

	const onCameraReady = useCallback(()=>{
		if(typeof window === 'object' && !window.PalmSystem){
			intervalKey = setInterval(() => {
				const joints2D = [];
				joints2D[BELLY_CHECKING_INDEX[0]] = getRandomCoord(BELLYBOX1);
				joints2D[BELLY_CHECKING_INDEX[1]] = getRandomCoord(BELLYBOX2);
				joints2D[BELLY_CHECKING_INDEX[2]] = getRandomCoord(BELLYBOX3);
				setBodyPositionLiveDataLocal({joints2D});
			}, 100);
		}else{
			activatePositionDetection({ isDetectionDelayed: true });
		}
	},[]);

	useEffect(() => {
		const canvasSize = { w: 960, h: 1080 };
		const cameraW = cameraSize.width ? cameraSize.width : 1280; //for test
		const cameraH = cameraSize.height ? cameraSize.height : 720; //for test
		const scale = canvasSize.h / cameraH;
		const leftMargin = (cameraW * scale - canvasSize.w) / 2;

		const liveData = (typeof window === 'object' && !window.PalmSystem) ? bodyPositionLiveDataLocal : bodyPositionLiveData;
		if (!!liveData?.joints2D?.length) {
		  BELLY_CHECKING_INDEX.forEach((value, index) => {
			const bellyCoordination = liveData.joints2D[value];
			const reversedCameraBellyX = canvasSize.w - (bellyCoordination[0] * scale - leftMargin);
			const reversedCameraBellyY = bellyCoordination[1] * scale;
			if(!bellySmootherRef.current[index]){
				bellySmootherRef.current[index] = new BellySmoother(xFitBodyBellySmootherQueueSize);
			}
			bellySmootherRef.current[index].pushValue(reversedCameraBellyX, reversedCameraBellyY);
		  });
		}
	  }, [bodyPositionLiveData, bodyPositionLiveDataLocal]);

	const changeMode = useCallback(()=>{
		BELLY_CHECKING_INDEX.forEach((_, index) => {
			bellySmootherRef.current[index]?.changeSmoothMode(!smoothMode);
		});
		setSmoothMode(!smoothMode);
	},[smoothMode]);
	const saveVideo = useCallback(()=>{
		saveRecordedVideo();
	},[saveRecordedVideo]);

	const onChangeQueueSize = useCallback(({value})=>{
		BELLY_CHECKING_INDEX.forEach((_, index) => {
			bellySmootherRef.current[index]?.changeQueueSize(value);
		});
		dispatch(changeLocalSettings({ xFitBodyBellySmootherQueueSize: value }));
	},[dispatch]);

	return (
	<TPanel {...rest}>
		<THeader title={"BellySmoothTest"}/>
		<TBody>
			<div className={css.buttoncontainer}>
				<TButton size="big" onClick={changeMode}>{smoothMode? 'SmoothMode' : 'NormalMode'}</TButton>
				<div className={css.titleArea}>
					<div className={css.switchs}>
						<span className={css.switchTitle}>{'Smoother Queue Size'}</span>
						<RangePicker	className={css.switch}
							max={30} min={1} step={1} width="small" value={xFitBodyBellySmootherQueueSize} onChange={onChangeQueueSize}
						/>
					</div>
				</div>
				<TButton size="big" onClick={saveVideo}>{'saveLastRecordedVideo'}</TButton>
			</div>
			<div className={css.bellyCameraBg}>
				<PIPCamera size="medium" onCameraReady={onCameraReady}/>
				<div>
					{
					BELLY_CHECKING_INDEX.map((bIndex, index) => {
						const point = bellySmootherRef.current[index] ? bellySmootherRef.current[index].getAverageData() : [];
						const style = point[0] ? {left: point[0] + "px", top: point[1] + "px"}: {};
						return (
						<img
							key={index}
							src={bellyPoint}
							alt="bellyPoint"
							className={classnames(css.bellyPoint, point.length <=0 && css.hide)}
							style={style}
						/>
						);
					})}
				</div>
			</div>
		</TBody>
	</TPanel>
  );
}

export default BellySmoodhTest;