import css from "./WorkoutPanel.module.less";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import { $L } from "../../utils/helperMethods";
import * as Config from "../../utils/Config";
import THorizontalScrollList, {
  ITEM_SIZE,
} from "../../components/THorizontalScrollList/THorizontalScrollList";
import useContent from "../../hooks/useContent";
import SMediaItem from "../../components/SMediaList/SMediaItem";
import TScroller from "../../components/TScroller/TScroller";
import { getContent } from "../../features/fitService/fitServiceSlice";
import { addPanels } from "../../features/panels/panelsSlice";

const CONTENT_TYPES = {
  bodyAlignment: { type: "recommend", subType: "bodyAlignment" },
  physicalTest: { type: "recommend", subType: "physicalTest" },
  virtualTraning: { type: "recommend", subType: "bodyAlignment" }, // todo
};

const WorkoutPanel = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const { title, type, subType } = panelInfo;
  const { contentList } = useContent(CONTENT_TYPES[type]);

  const onItemClick = useCallback(
    ({ itemInfo }) => {
      if (itemInfo) {
        dispatch(getContent({ contentId: itemInfo.contentId })); // focus? click?
      }
      dispatch(
        addPanels({
          name: Config.panel_names.CONTENT_PLAYER_START,
          panelInfo: {
            type: itemInfo?.type,
            subType: itemInfo?.subType,
            contentId: itemInfo?.contentId,
          },
        })
      );
    },
    [dispatch]
  );

  const mediaList = useMemo(() => {
    const newList = contentList[subType];
    const badList = [];
    const commonList = [];

    newList.map((item) => {
      item.score === "bad" ? badList.push(item) : commonList.push(item);
    });

    return { badList, commonList };
  }, [contentList, subType]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={title} />
      <TBody className={css.body}>
        <TScroller verticalScrollbar={"auto"}>
          {mediaList?.badList?.length > 0 && (
            <>
              <div className={css.mediaListTitle}>
                {$L("Great {bodyPart} workouts").replace("{bodyPart}", title)}
              </div>
              <THorizontalScrollList itemSize={ITEM_SIZE.large}>
                {mediaList?.badList?.map((item, index) => (
                  <SMediaItem
                    key={"mediaitem_" + index}
                    className={css.contentList}
                    itemSize={ITEM_SIZE.large}
                    itemInfo={item}
                    onClick={onItemClick}
                    playIcon={true}
                  />
                ))}
              </THorizontalScrollList>
            </>
          )}
          <div className={css.mediaListTitle}>
            {$L("Great {bodyPart} workouts").replace("{bodyPart}", title)}
          </div>
          <THorizontalScrollList itemSize={ITEM_SIZE.large}>
            {mediaList?.commonList?.map((item, index) => (
              <SMediaItem
                key={"mediaitem_" + index}
                className={css.contentList}
                itemSize={ITEM_SIZE.large}
                itemInfo={item}
                onClick={onItemClick}
                playIcon={true}
              />
            ))}
          </THorizontalScrollList>
        </TScroller>
      </TBody>
    </TPanel>
  );
};

export default WorkoutPanel;
