import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lunaSend from "../lunaSend";
import { setSavingVideoPath } from "../components/PIPCamera/PIPCamera";

import * as helperMethods from "../utils/helperMethods";
import { DATAKEY, updateTestResults } from "../features/physicalTest/physicalTestSlice";
import { postPhycalTestData } from "../features/fitService/fitServiceSlice";
import * as Config from "../utils/Config";
import { updatePanel } from "../features/panels/panelsSlice";

const usePhysicalTest = () => {
  const { cameraSize } = useSelector((state) => state.camera);
  const { userAge, userGender } = useSelector((state) => state.common.localSettings);
  const { usbDeviceUri, userNumber } = useSelector((state) => state.common.appStatus);
  const currentTestedData = useSelector(state => state.physicalTest.current);

  const dispatch = useDispatch();

  //media/internal/images/
  //tmp/usb/sda/sda1
  const saveSFTData = useCallback((sftData, sftTsvData) => {
    let _usbDeviceUri = usbDeviceUri;
    if (!_usbDeviceUri) {
      _usbDeviceUri = '/media/internal/sft';
      lunaSend.createToast("No Usb: Data will be saved in internal path");
    } else {
      _usbDeviceUri = usbDeviceUri + '/sft';
    }
    let filePath = "";
    const date = new Date();
    const timeStr = helperMethods.timeToFilePathStr(date);
    const prePathStr = `${_usbDeviceUri}/${userNumber}-${userAge}-${userGender}/${timeStr}-`;

    if (sftData) {
      const testResult = JSON.stringify(sftData);
      filePath = prePathStr + `SftResult.dat`;
      lunaSend.writeFile({ path: filePath, data: testResult }, {});
      setSavingVideoPath(prePathStr + 'video.webm');
      if (sftTsvData) {
        let filePathTsv = "";
        const powerwalkingTsv = convertJsonToTsv(sftTsvData, "sftResult", "powerwalking");
        console.log('powerwalkingTsv', powerwalkingTsv);
        filePathTsv = prePathStr + `SftResult_powerWalking.tsv`;
        lunaSend.writeFile({ path: filePathTsv, data: powerwalkingTsv }, {});
        const onlegstandTsv = convertJsonToTsv(sftTsvData, "sftResult", "onlegstand");
        console.log('onlegstandTsv', onlegstandTsv);
        filePathTsv = prePathStr + `SftResult_onlegstand.tsv`;
        lunaSend.writeFile({ path: filePathTsv, data: onlegstandTsv }, {});
      }
    }
  }, [userNumber, userAge, userGender, usbDeviceUri]);

  //subKey only for sftResult
  const convertJsonToTsv = useCallback((result, subKey) => {
    let tsvString = '';

    if (result[subKey] && result[subKey].timeStamp) {
      const timeStamp = result[subKey].timeStamp;
      const originData = result[subKey].originData;
      const subTitle = ["", "", "Standard Deviation", "Avg", "Max", "Max-Avg", "Min", "Min-Avg"];
      // header
      tsvString += 'timeStamp\t';
      console.log('timeStamp', timeStamp, result);
      for (let i = 0; i < timeStamp.length; i++) {
        tsvString += timeStamp[i] + '\t';
      }
      for (let i = 0; i < subTitle.length; i++) {
        tsvString += subTitle[i] + '\t';
      }
      tsvString += '{br}';
      // data
      let rowData = [
        'Shoulder degree',
        'Shoulder center x value',
        'Shoulder center y value',
        'Shoulder left x value',
        'Shoulder left y value',
        'Shoulder right x value',
        'Shoulder right y value',
        'Pelvic degree',
        'Pelvic center x value',
        'Pelvic center y value',
        'Pelvic left x value',
        'Pelvic left y value',
        'Pelvic right x value',
        'Pelvic right y value'
      ];

      if (subKey === 'onlegstand') { //no pelvic data
        rowData = rowData.slice(0, 7);
      }
      for (let i = 0; i < rowData.length; i++) {
        tsvString += rowData[i] + '\t';
        const valueArray = [];
        for (let j = 0; j < timeStamp.length; j++) {
          const data = originData[j];
          let value = 0;
          switch (i) {
            case 0:
              value = data.shoulder.degree || 0;
              break;
            case 1:
              value = data.shoulder.position.center[0] || 0;
              break;
            case 2:
              value = data.shoulder.position.center[1] || 0;
              break;
            case 3:
              value = data.shoulder.position.left[0] || 0;
              break;
            case 4:
              value = data.shoulder.position.left[1] || 0;
              break;
            case 5:
              value = data.shoulder.position.right[0] || 0;
              break;
            case 6:
              value = data.shoulder.position.right[1] || 0;
              break;
            case 7:
              value = data.pelvic.degree || 0;
              break;
            case 8:
              value = data.pelvic.position.center[0] || 0;
              break;
            case 9:
              value = data.pelvic.position.center[1] || 0;
              break;
            case 10:
              value = data.pelvic.position.left[0] || 0;
              break;
            case 11:
              value = data.pelvic.position.left[1] || 0;
              break;
            case 12:
              value = data.pelvic.position.right[0] || 0;
              break;
            case 13:
              value = data.pelvic.position.right[1] || 0;
              break;
          }
          valueArray.push(value);
          tsvString += value + '\t';
        }
        const sum = valueArray.reduce((total, currentValue) => total + currentValue, 0);
        const avg = sum / timeStamp.length;
        const standardDeviation = helperMethods.calculateStandardDeviation(valueArray);
        const max = Math.max(...valueArray);
        const min = Math.min(...valueArray);
        for (let k = 0; k < subTitle.length; k++) {
          if (k === 0) {
            //do nothing
          } else if (k === 1) {
            tsvString += rowData[i];
          } else {
            switch (k) {
              case 2: //표준편차
                tsvString += standardDeviation || 0;
                break;
              case 3: //Avg
                tsvString += avg;
                break;
              case 4: //Max
                tsvString += max;
                break;
              case 5: //Max-Avg
                tsvString += (max - avg);
                break;
              case 6: //Min
                tsvString += min;
                break;
              case 7: //Min-Avg
                tsvString += (min - avg);
                break;
            }
          }
          tsvString += '\t';
        }
        tsvString += '{br}';
      }
    }
    return tsvString;
  }, []);

  const getkneeGuideLineStyle = useCallback((position) => {
    const style = {};
    const video = document.querySelector("video");
    if (Array.isArray(position) && video && typeof window === "object") {
      const p1 = position[0], p2 = position[1];
      const scale = video.clientHeight / cameraSize.height;
      p1[0] *= scale;
      p1[1] *= scale;
      p2[0] *= scale;
      p2[1] *= scale;

      if (window) {
        p1[0] = (window.innerWidth - p1[0]) + window.innerWidth * 0.25; //25% 이동  비디오가 50%라서. +반전.
        p2[0] = (window.innerWidth - p2[0]) + window.innerWidth * 0.25; //25% 이동  비디오가 50%라서.
      }
      // style.position = "absolute";
      // style.width = Math.abs(p2[0] - p1[0]) + "px";
      // style.left = p1[0] < p2[0] ? (p1[0]+ 'px') : (p2[0] + 'px');
      // style.top = p1[1] + 'px';

      style.width = Math.abs(p2[0] - p1[0]) + 100;  // 전체 너비 좌우 50씩 늘리기
      style.left = p1[0] < p2[0] ? p1[0] - 50 : p2[0] - 50; // 좌측 - 50
      style.top = p1[1];
      style.right = p1[0] < p2[0] ? p2[0] + 50 : p1[0] + 50; // 우측 + 50

      return style;
    }
    return null;
  }, [cameraSize]);


  const saveTestResults = useCallback(() => {
    const now = new Date();
    const keys = Object.keys(DATAKEY);
    const toSave = {};
    const isoDateTime = helperMethods.timeToISO8601Str(now);
    keys.forEach(key => {
      if (currentTestedData[DATAKEY[key]] && Object.keys(currentTestedData[DATAKEY[key]]).length > 0) {
        toSave[DATAKEY[key]] = { ...currentTestedData[DATAKEY[key]] };
      }
    });
    console.log('yhcho toSave', toSave);
    //update to reducer
    //2023-09-11
    const dateStr = helperMethods.convertDateToString2(now);
    dispatch(updateTestResults({ date: dateStr, value: toSave }));
    dispatch(updatePanel({ name: Config.panel_names.PHYSICAL_TEST_REPORT, panelInfo: { selectedDate: dateStr } }));
    //push to server
    const toServerSave = helperMethods.cloneObject(toSave);
    toServerSave.date = isoDateTime;
    dispatch(postPhycalTestData(toServerSave));

  }, [dispatch, currentTestedData]);
  return { saveSFTData, getkneeGuideLineStyle, saveTestResults };
};

export default usePhysicalTest;
