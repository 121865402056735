import React, { useState, useCallback, useMemo, useEffect } from "react";
import Spotlight from "@enact/spotlight";
import css from "./BodyAlignmentReport.module.less";
import * as Config from "../../../../utils/Config";
import * as HelperMethods from "../../../../utils/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPanels,
  updatePanel,
  addPanels
} from "../../../../features/panels/panelsSlice";
import { getBodyCheckUpData } from "../../../../features/fitService/fitServiceSlice";
import {
  BODY_SCAN_SCORE,
  BODY_SCAN_BASIC_SCORE,
} from "../../../../utils/Constants";
import { MENUTYPE, TAB_DETAIL, getTabMenuTitle } from "./Constants";
import { BODY_CHECKUP_TYPE } from "../../../../utils/Constants";
import {
  changeAppStatus,
  changeLocalSettings,
  changeVoiceGuideStatus,
} from "../../../../features/common/commonSlice";
import { $L } from "../../../../utils/helperMethods";
//컴포넌트
import ReportTop from "../../../../components/ReportTop/ReportTop";
import TSimpleButtonTab, {
  LIST_TYPE,
} from "../../../../components/TSimpleButtonTab/TSimpleButtonTab";
import TPanel from "../../../../components/TPanel/TPanel";
import THeader from "../../../../components/THeader/THeader";
import TBody from "../../../../components/TBody/TBody";
import Overall from "./TabContents/Overall";
import SubTab from "./TabContents/SubTab";
import {
  voiceGuideBodyAlignment,
  voiceGuideEmpty,
} from "../feedback/feedbackBodyAlignment";
import * as TTSService from "../../../../lunaSend/TTSService";
import TextPopup from "../../../../components/TextPopup";
import TutorialButton from "../../../../components/TutorialButton/TutorialButton";

/**
 * @module BodyAlignmentReport
 * @returns view Details Button 클릭시 tab layout
 */
let tabMenuTitle = null;
const BodyAlignmentReport = ({ panelInfo, ...rest }) => {
  if (!tabMenuTitle) {
    tabMenuTitle = getTabMenuTitle();
  }

  const dispatch = useDispatch();
  const [isText, setText] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    panelInfo.selectedDate ? panelInfo.selectedDate : null
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    panelInfo?.selectedTabIndex ? panelInfo.selectedTabIndex : 0
  ); //tab
  const { dateList, testResults } = useSelector((state) => state.bodyAlignment);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const { audioBodyScan_Overall, audioBodyScan_Detail, pictureType } =
    useSelector((state) => state.common.appStatus);
  const { overall, subTab } = useSelector(
    (state) =>
      state.common.appStatus.voiceGuideStatus[BODY_CHECKUP_TYPE.BODY_ALIGNMENT]
  );
  const [feedback, setFeedback] = useState(null);

  const currentData = useMemo(() => {
    if (selectedDate) {
      return testResults[selectedDate];
    }
    return {};
  }, [testResults, selectedDate]);

  const tabInfos = useMemo(() => {
    const titleArray = tabMenuTitle;
    const ret = [];
    const keys = Object.keys(MENUTYPE);
    let totalScore = 0;
    for (let i = 0; i < keys.length; i++) {
      let score = 0;
      let detailType = "";
      if (keys[i] !== MENUTYPE.OVERALL) {
        const detail = TAB_DETAIL[keys[i]];

        const scoreKey = currentData?.[detail.mainkey]?.[detail.testkey]?.[3];
        const typeKey = currentData?.[detail.mainkey]?.[detail.testkey]?.[0];
        if (BODY_SCAN_SCORE[scoreKey]) {
          score = BODY_SCAN_SCORE[scoreKey];
          detailType = typeKey;
        }
        const scoreKey2 = currentData?.[detail.mainkey]?.[detail.testkey2]?.[3];
        const typeKey2 = currentData?.[detail.mainkey]?.[detail.testkey2]?.[0];
        if (BODY_SCAN_SCORE[scoreKey2]) {
          score = Math.min(score, BODY_SCAN_SCORE[scoreKey2]);
          detailType = typeKey2;
        }
      }
      ret.push({
        type: keys[i],
        sortIndex: i,
        title: titleArray[keys[i]][0],
        detailTitle: titleArray[keys[i]][1],
        score: (Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION && keys[i] === MENUTYPE.PELVIC_ROTATION) ? 0:score,
        detailType: detailType,
      });

      if (cesShowMode) {
        // ces show mode
        if (
          Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION &&
          keys[i] === MENUTYPE.PELVIC_ROTATION
        ) {
          totalScore += 7;
        } else {
          totalScore += score;
        }
      } else {
        totalScore += score;
      }
    }
    ret[0].score = totalScore + BODY_SCAN_BASIC_SCORE;
    ret.sort((a, b) => {
      if (a.type === MENUTYPE.OVERALL || b.type === MENUTYPE.OVERALL) {
        return 0;
      }

      // MENUTYPE.PELVIC_ROTATION을 맨 마지막으로 정렬
      if(Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION){
        if (a.type === MENUTYPE.PELVIC_ROTATION) return 1;
        if (b.type === MENUTYPE.PELVIC_ROTATION) return -1;
      }
      if (a.score > b.score) return 1;
      if (a.score < b.score) return -1;
      if (a.score === b.score) {
        return b.sortIndex < a.sortIndex ? 1 : -1;
      }
    });
    return ret;
  }, [currentData, cesShowMode]);

  const tabNames = useMemo(() => {
    return tabInfos.map((item) => item.title);
  }, [tabInfos]);
  const scoreArray = useMemo(() => {
    return tabInfos.map((item) => item.score);
  }, [tabInfos]);

  const disabledItem = useMemo(() => {
    if (currentData && !cesShowMode) {
      if (Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION) {
        return [tabMenuTitle[MENUTYPE.PELVIC_ROTATION][0]];
      }else{
        return [];
      }
    } else {
      return tabNames.slice(1);
    }
  }, [currentData, tabNames, cesShowMode]);

  const onItemClick = useCallback(
    ({ index, ev }) => {
      TTSService.stop();
      console.log("Selected tab index= ", index, ev);
      setSelectedTabIndex(index);
      setText(false);
      dispatch(
        updatePanel({
          name: Config.panel_names.BODY_ALIGNMENT_REPORT,
          panelInfo: { selectedTabIndex: index },
        })
      );
    },
    [dispatch]
  );

  const onSelectedDate = useCallback(
    ({ selectedDate: date }) => {
      console.log("selectedDate", date);
      //todo read data...from reducer
      const strDate = HelperMethods.convertDateToString2(date);
      if(strDate !== selectedDate){
        setSelectedDate(strDate);
        setSelectedTabIndex(0);
        dispatch(getBodyCheckUpData({type: BODY_CHECKUP_TYPE.BODY_ALIGNMENT, date:strDate, syncFlag:"immediately"}));
        dispatch(
          updatePanel({
            name: Config.panel_names.BODY_ALIGNMENT_REPORT,
            panelInfo: { selectedDate: strDate },
          })
        );
      }
    },
    [dispatch, selectedDate]
  );

  const launchReportDetail = useCallback(()=>{
    dispatch(addPanels({
      name: Config.panel_names.BODY_ALIGNMENT_REPORT_DETAIL,
      panelInfo:{title: tabInfos[selectedTabIndex].detailTitle,
      tabDetail: TAB_DETAIL[tabInfos[selectedTabIndex].type],
      menuType:tabInfos[selectedTabIndex].type,
      selectedDate}}));
  },[dispatch, tabInfos, selectedTabIndex, selectedDate]);

  const renderTabContent = useCallback(() => {
      switch (tabInfos[selectedTabIndex].type) {
        case MENUTYPE.OVERALL:
          return (
            <Overall
              date={selectedDate}
              scoreArray={scoreArray}
              feedback={feedback}
            tabInfos={tabInfos}
            />
          );
        default:
          return (
            <SubTab
              date={selectedDate}
              menuType={tabInfos[selectedTabIndex].type} launchReportDetail={launchReportDetail}
            />
          );
    }
  }, [tabInfos, selectedTabIndex, selectedDate, scoreArray, feedback, launchReportDetail]);

  const onSpotlightDownFromTop = useCallback((ev) => {
    Spotlight.focus("BodyAlignmentReportTab");
    ev.stopPropagation();
  }, []);

  const isEmpty = useMemo(() => {
    return !dateList || dateList.length <= 0;
  }, [dateList]);

  useEffect(() => {
    setFeedback(
      isEmpty
        ? voiceGuideEmpty()
        : voiceGuideBodyAlignment(selectedTabIndex, tabInfos, cesShowMode)
    );
  }, [selectedTabIndex, tabInfos, isEmpty, cesShowMode]);

  useEffect(() => {
    if (feedback && feedback !== null) {
      if (selectedTabIndex === 0) {
        overall ? TTSService.speak(feedback.feedback) : TTSService.stop();
      } else {
        subTab ? TTSService.speak(feedback.feedback) : TTSService.stop();
      }
    }
    return () => TTSService.stop();
  }, [feedback, overall, subTab]);

  useEffect(() => {
    if (cesShowMode) {
      dispatch(
        changeVoiceGuideStatus({
          reportType: BODY_CHECKUP_TYPE.BODY_ALIGNMENT,
          tabType: "overall",
          value: false,
        })
      );
      dispatch(
        changeVoiceGuideStatus({
          reportType: BODY_CHECKUP_TYPE.BODY_ALIGNMENT,
          tabType: "subTab",
          value: false,
        })
      );
    }
  }, [cesShowMode]);

  return (
    <>
      <TPanel {...rest}>
        <THeader title={$L("Body Alignment")} />
        {/* tab 버튼 상단부 */}
        <TBody className={css.body}>
          <ReportTop
            className={css.top}
            pose
            dateList={
              isEmpty
                ? [HelperMethods.convertDateToString2(new Date())]
                : dateList
            }
            selectedDateStr={selectedDate}
            onSelectedDate={onSelectedDate}
            onSpotlightDown={onSpotlightDownFromTop}
            selectedTabIndex={selectedTabIndex}
            // disabled={cesShowMode}
            isText={isText}
            setText={setText}
            isEmpty={isEmpty}
            cesShowMode={cesShowMode}
          />
          <TSimpleButtonTab
            className={css.tab}
            spotlightId={"BodyAlignmentReportTab"}
            listType={LIST_TYPE.report}
            contents={tabNames}
            selectedIndex={selectedTabIndex}
            maxItemCount={10}
            onItemClick={onItemClick}
            score={scoreArray}
            disabledItem={disabledItem}
            noMarquee
          />
          <div className={css.tabLayout}>{renderTabContent()}</div>
          {/* tab 하단부 contents */}
          {/* <BodyAlignmentTabContentsLayout detailTotalInfo={contentsInfos[selectedTabIndex]} tabIndex={selectedTabIndex}/> */}
          <div className={css.notification}>
            {!cesShowMode ? (
              <div className={css.guideText}>
                {$L(
                  "Measuring body alignment with a camera may be affected by the work environment(clothing, etc.)."
                )}
              </div>
            ) : (
              <>
                <div className={css.guideText}>
                  {$L(
                    "LG Intelli-FiT recommendations and feedback should not be considered, or used as a substitute for professional medical advice or treatment."
                  )}
                </div>
                <div className={css.guideText}>
                  {$L(
                    "Measuring your body alignment using the camera may be influenced by your surroundings (e.g. clothing)."
                  )}
                </div>
              </>
            )}
          </div>
          {!cesShowMode && <div className={css.medicalCenter} />}
          <TutorialButton isLandingPage={false}></TutorialButton>
        </TBody>
      </TPanel>
      {feedback && feedback.feedback && (
        <TextPopup
          open={isText}
          onClose={() => setText(false)}
          children={feedback.feedback}
        />
      )}
    </>
  );
};

const propsAreEqual = (prev, next) => {
  const keys = Object.keys(prev);
  const nextKeys = Object.keys(next);
  if (keys.length !== nextKeys.length) {
    return false;
  }
  for (let i = 0; i < keys.length; i++) {
    if (prev[keys[i]] !== next[keys[i]]) {
      if (JSON.stringify(prev[keys[i]]) === JSON.stringify(next[keys[i]])) {
        continue;
      }
      return false;
    }
  }
  return true;
};

export default React.memo(BodyAlignmentReport, propsAreEqual);
