import css from "./FitnessGoalSetting.module.less";
import classNames from "classnames";

import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $L, convertNewlinesToBr } from "../../../utils/helperMethods";

import { addPanels } from "../../../features/panels/panelsSlice";
import { postSurvey } from "../../../features/fitService/fitServiceSlice";
import * as Config from "../../../utils/Config";

import TScroller from "../../../components/TScroller/TScroller";
import ResetSurveyBody from "./ResetSurveyBody/ResetSurveyBody";
import MonthGoal from "./MonthGoal/MonthGoal";
import TButton, {TYPES, SIZES, COLOR, ICONS} from "../../../components/TButton/TButton";

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TToast from "../../../components/TToast/TToast";
const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

const GOAL_ITEM = ["date", "time", "calorie"];
// dummy server data
const USER_DATA = {
  date: {
    user: 21,
    goal: 25,
  },
  time: {
    user: 60000,
    goal: 72000,
  },

  calorie: {
    user: 5765,
    goal: 6000,
  },
};

const FitnessGoalSetting = () => {
  const dispatch = useDispatch();
  const {isToasted, message} = useSelector((state) => state.common.appStatus.toast);
  const scrollTo = useRef(null);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  const onClickGoalSetting = useCallback(() => {
  }, []);

  const onFocusGoalSetting = useCallback(() => {
    if (scrollTo && scrollTo.current) {
			scrollTo.current({align: "top", animate: true});
		}
  }, []);

  const onClickClose = useCallback(() => {
  }, []);

  const onFocusClose = useCallback(() => {
    if (scrollTo && scrollTo.current) {
			scrollTo.current({align: "bottom", animate: true});
		}
  }, [])

  const onClickStore = useCallback(() => {
    dispatch(postSurvey());
  }, [dispatch]);

  const onFocusStore = useCallback(() => {
    if (scrollTo && scrollTo.current) {
			scrollTo.current({align: "bottom", animate: true});
		}
  }, []);

  return (
    <>
      <TScroller className={css.scroller} cbScrollTo={getScrollTo}>
        <div className={css.innerContainer}>
          <div className={css.desciption}>
            {convertNewlinesToBr(
              $L(
                "Set your monthly goals. {br}A goal recommended by an expert will be set automatically even if you don't set your goals."
              )
            )}
          </div>
          {/* month goal */}
          <Container className={css.goalContainer}>
            <div className={css.titleArea}>
              <div className={css.title}>{$L("My monthly goals")}</div>
              <TButton
                className={css.button}
                type={TYPES.withIcon}
                icon={ICONS.plus}
                size={SIZES.small}
                color={COLOR.transparent}
                onClick={onClickGoalSetting}
                onFocus={onFocusGoalSetting}
              >
                {$L("Set goals")}
              </TButton>
            </div>
            {USER_DATA &&
            <div className={css.list}>
              {GOAL_ITEM.map((item, index) => <MonthGoal key={"goal" + index} type={GOAL_ITEM[index]} itemInfo={USER_DATA[item]}/>)}
            </div>}
          </Container>
          {/* reset survey */}
          <ResetSurveyBody className={css.survey}/>
          <div className={css.buttonContainer} >
            <TButton size={SIZES.xLarge} onClick={onClickClose} onFocus={onFocusClose}>{$L("Cancel")}</TButton>
            <TButton size={SIZES.xLarge} onClick={onClickStore} onFocus={onFocusStore}>{$L("Save")}</TButton>
          </div>
        </div>
      </TScroller>
      {isToasted && <TToast text={message} />}
    </>

  );
};

export default FitnessGoalSetting;
