import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const RecordIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 16C20.9249 16 16 20.9249 16 27C16 33.0751 20.9249 38 27 38C33.0751 38 38 33.0751 38 27H31.8358"
        stroke={convertThemeColor(iconType)}
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RecordIcon;
