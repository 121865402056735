import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const InHomeOutdoorActivityIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8319 16.812H27.7907C29.4475 16.812 30.7907 18.1552 30.7907 19.812V27.271L30.7799 27.2753V29.1866L32.2322 33.6103C32.3478 33.9623 32.4362 34.3226 32.4967 34.6881L33.5532 41.0725C33.711 42.0261 33.0659 42.927 32.1124 43.0848C31.1589 43.2426 30.258 42.5975 30.1002 41.644L29.0888 35.5322C29.0847 35.4616 29.0732 35.3911 29.054 35.3219L29.0436 35.2595C29.0373 35.2213 29.0304 35.1831 29.0229 35.1451L27.9354 33.7003L27.935 33.7099L25.6204 30.6077C24.9531 29.7134 24.6041 28.6216 24.629 27.5061L24.6433 26.8668L24.8866 18.7002C24.9179 17.6484 25.7797 16.812 26.8319 16.812ZM24.4771 32.4198L24.4156 34.2192C24.4006 34.6577 24.2005 35.0692 23.8649 35.3518L19.342 39.1608C18.6027 39.7834 18.5081 40.8874 19.1307 41.6266C19.7533 42.3659 20.8573 42.4605 21.5965 41.8379L26.1195 38.029C26.6767 37.5597 27.1193 36.9813 27.4258 36.3377C27.3761 36.2935 27.3302 36.2436 27.2889 36.1882L24.4771 32.4198Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6343 16.8179C26.8059 16.8179 26.1343 17.4894 26.1343 18.3179C26.1343 19.0971 26.7284 19.7375 27.4884 19.8109V24.9218C27.4884 25.405 27.7212 25.8587 28.1138 26.1404C28.5064 26.4222 29.0107 26.4976 29.4685 26.3429L30.776 25.9011L30.7965 22.7276L30.4884 22.8317V19.672C30.4884 18.0957 29.2106 16.8179 27.6343 16.8179ZM32.7975 22.0515C32.7996 22.079 32.8005 22.1068 32.8003 22.1349L32.7804 25.2239L35.5995 24.2714C36.3844 24.0062 36.8056 23.155 36.5405 22.3702C36.2753 21.5853 35.4241 21.1641 34.6392 21.4292L32.7975 22.0515Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M22.3279 26.7405L21.5379 25.7438C20.492 24.4242 20.7367 22.5024 22.08 21.4871L25.5583 18.8579C26.164 18.4001 26.9267 18.1966 27.6802 18.29V18.29"
        stroke={convertThemeColor(iconType)}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.877 15.3137C29.8099 15.3137 31.377 13.7467 31.377 11.8137C31.377 9.88072 29.8099 8.31372 27.877 8.31372C25.944 8.31372 24.377 9.88072 24.377 11.8137C24.377 13.7467 25.944 15.3137 27.877 15.3137Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M14 45.6866H40"
        stroke={convertThemeColor(iconType)}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default InHomeOutdoorActivityIcon;
