import WebWorkerUtil, {WORKER_ID} from "../WebWorker/WebWorkerUtil";
import { setMatBytesData} from "../../features/ble/bleSlice";
import {updateCurrentTestingStatus, updateMatStatusAndValue } from "../../features/bodyBalance/bodyBalanceSlice";
const MAT_SIZE = [660, 330];//todo
const KEY_MESSAGEID_POSITION = 2;
const KEY_NRP_POSITION = 3;
const KEY_PAYLOAD_POSITION = 5;
const KEY_DATA_POSITION = 8;

const MAT_REPORT_STATUS_STR = ["idle", "ready", "not ready", "start", "finished"];
const convertLittleToBigIndian = (array, size=4) => {
	let ret = 0;
	for(let i = 0; i<array.length && i<size; i++){
		ret += (array[i] * (256**i));
	}
	return ret;
}
const TWOLEG_FEATURE_KEYS=['archIndex_L', 'archIndex_R', 'fore_L', 'rear_L', 'fore_R', 'rear_R', 'balanceRatio_L', 'balanceRatio_R', 'balanceRatio_FF', 'balanceRatio_RF', 'staticBalance_X', 'staticBalance_Y'];
const TWOLEG_FEATURE_BYTE_SIZE=[2,2,2,2,2,2,2,2,2,2,2,2];

const ONELEG_FEATURE_KEYS=['leftBalance_X', 'leftBalance_Y', 'rightBalance_X', 'rightBalance_Y'];
const ONELEG_FEATURE_BYTE_SIZE=[2,2,2,2];

const convertHitFeatureData = (bytes, startPosition, type) => {
	const feature = {};
	let keys = [];
	let byteSizeArray = [];
	switch(type){
		case 0x22: //양발서기 features
			keys = TWOLEG_FEATURE_KEYS;
			byteSizeArray = TWOLEG_FEATURE_BYTE_SIZE;
			break;
		case 0x32: //한발서기 features
			keys = ONELEG_FEATURE_KEYS;
			byteSizeArray = ONELEG_FEATURE_BYTE_SIZE;
			break;
	}
	let position = startPosition;
	for(let i=0; i<keys.length; i++){
		const d = bytes.slice(position, position+byteSizeArray[i]);
		feature[keys[i]] = String(convertLittleToBigIndian(d, byteSizeArray[i])/1000);
		position+=byteSizeArray[i];
	}
	return feature;
}
export default class MatDataParser {
	constructor (thunkAPI, useBleSerial) {
		this.thunkAPI = thunkAPI;
		this.lastCalled = new Date();
		this.lastRawData = [];
		this.lastMessageId = -1;
		this.useBleSerial = useBleSerial;
		if(useBleSerial){ //skipHeader
			this.keyPayloadPosition = 0;
			this.keyDataPosition = 3;
		}else{
			this.keyPayloadPosition = KEY_PAYLOAD_POSITION;
			this.keyDataPosition = KEY_DATA_POSITION;
		}
		this.keyTypePosition = this.keyPayloadPosition;
	}
	//Signature(1byte), Version(1byte), MessageId(1byte), NRP(2byte), payload(...)
	updateMatData = (bytes) => {
		console.log('yhcho MatDataParser message, bytes:', '0x'+bytes[this.keyTypePosition].toString(16), bytes);
		//walk Info
		const current = new Date();
		//for debug
		if (current - this.lastCalled > 1000) {
			this.thunkAPI.dispatch(setMatBytesData(bytes));
			this.lastCalled = new Date();
			console.log('MatDataParser setMatData', bytes);
		}
		let nrp = 0;
		if(!this.useBleSerial){//use header data only for ble
			nrp = convertLittleToBigIndian([bytes[KEY_NRP_POSITION], bytes[KEY_NRP_POSITION+1]]);
			const messageId = bytes[KEY_MESSAGEID_POSITION];
			if(this.lastMessageId === messageId){
				const data = bytes.slice(this.keyPayloadPosition);
				this.lastRawData.push(...data);
				if(nrp === 0){
					this.thunkAPI.dispatch(updateCurrentTestingStatus({rawData: this.lastRawData}));
					this.lastRawData = [];
				}
			}
			if(this.lastMessageId !== messageId){
				this.lastRawData = [];
			}
			this.lastMessageId = messageId;
		}

		switch(bytes[this.keyTypePosition]){
			case 0x10: //산책모드
			{
				const foot = bytes[this.keyDataPosition];
				const foot_state = bytes[this.keyDataPosition+1];
				const value = foot * 256 | foot_state;
				WebWorkerUtil.postMessage(WORKER_ID.HIT, { type: "setWalkInputData", value: [value] }, true);
				break;
			}
			case 0x20: //양발서기 Report Message  32
			case 0x30: //한발서기 Report Message  48
			case 0x40: //보행분석 Report Message
			{
				const status = bytes[this.keyDataPosition];
				const value = bytes[this.keyDataPosition+1];
				this.thunkAPI.dispatch(updateMatStatusAndValue({matStatus: MAT_REPORT_STATUS_STR[status], matStatusValue: value}));
				//  setTimeout(()=>{
				// 	this.thunkAPI.dispatch(updateMatStatusAndValue({matStatus: "", matStatusValue: ""}));
				// },0);
				break;
			}
			case 0x23: //양발서기 왼발/오른발 좌표  35
			case 0x33: //한발서기 왼발/오른발 좌표  51
			{
				let keyIndex = this.keyDataPosition;
				const left_position = [];
				const right_position = [];
				for(let i=0; i<8; i++){
					const x = [bytes[keyIndex], bytes[keyIndex+1]]; //8, 9
					const y = [bytes[keyIndex+2], bytes[keyIndex+3]]; //10, 11
					const xx = convertLittleToBigIndian(x);
					const yy = convertLittleToBigIndian(y);
					if(i<4){
						left_position.push(xx);
						left_position.push(yy);
					}else{
						right_position.push(xx);
						right_position.push(yy);
					}
					keyIndex += 4;
				}
				this.thunkAPI.dispatch(updateCurrentTestingStatus({"left_foot_position": left_position, "right_foot_position": right_position}));
				break;
			}
			// 한발서기, 양발서기 rawData 첫 raw 만 여기서 저장 이후부터는 type 안들어옴.
			case 0x21: // 33
			case 0x31: // 49
			{
				const data = bytes.slice(this.keyDataPosition);
				if(nrp === 0){
					this.thunkAPI.dispatch(updateCurrentTestingStatus({rawData: data}));
					this.lastRawData = [];
				}else{
					this.lastRawData = data;
				}
				break;
			}
			case 0x22: //양발서기 features 34
			{
				const featureList = convertHitFeatureData(bytes,this.keyDataPosition, bytes[this.keyTypePosition]);
				WebWorkerUtil.postMessage(WORKER_ID.HIT, { type: "analyzeTwoLegStance", value: [featureList] }, false);
				break;
			}
			case 0x32: //한발서기 features 50
			{
				const featureList = convertHitFeatureData(bytes,this.keyDataPosition, bytes[this.keyTypePosition]);
				WebWorkerUtil.postMessage(WORKER_ID.HIT, { type: "analyzeSingleLegStance", value: [featureList] }, false);
				break;
			}
		}
	}
}