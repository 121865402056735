import { useState, useCallback } from "react";
import TBarGraph from "../../../../components/TBarGraph/TBarGraph";

const TBarGraphLayout = () => {
	const [selectedId, setSelectedId] = useState(null);

	const onSelect = useCallback(({selected}) => {
			console.log(selected);
			setSelectedId(selected);
	}, [selectedId]);


	return (
			<>
				<TBarGraph
					size="small"
					width="small"
				>
				</TBarGraph>
			</>
	);
};

export default TBarGraphLayout;