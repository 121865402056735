import React, {useCallback, useRef, useState, useEffect, useMemo} from 'react';
import { useDispatch, useSelector} from "react-redux";
import { Job } from "@enact/core/util";
import Spotlight from "@enact/spotlight";
import * as Config from '../../utils/Config';
import * as SoundEffect from "../../utils/SoundEffect";
import css from './BodyCheckUpMain.module.less';
import {addPanels} from '../../features/panels/panelsSlice';
import THeader from "../../components/THeader/THeader";
import {
  $L,
  convertDateToDaysAgo,
  convertNewlinesToBr,
} from "../../utils/helperMethods";
import TPanel from '../../components/TPanel/TPanel';
import TBody from '../../components/TBody';
import PreloadImage from "../../components/PreloadImage/PreloadImage";
import MainSection from "./MainSection";
import TVirtualSpotContainer from '../../components/TVirtualSpotContainer';
import virtualspot_bg from '../../../assets/bodycheckup/mainSections/virtualspot_bg.png';

import alignment_bg_pre from '../../../assets/bodycheckup/mainSections/img_bodycheck_gate_alignment_bg_pre.png';
import physical_bg_pre from '../../../assets/bodycheckup/mainSections/img_bodycheck_gate_physical_bg_pre.png';
import balance_bg_pre from '../../../assets/bodycheckup/mainSections/img_bodycheck_gate_balance_bg_pre.png';
import rom_pre_bg_01 from "../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_01_pre.png";
import rom_pre_bg_02 from "../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_02_pre.png";
import rom_pre_bg_03 from "../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_03_pre.png";
import { changeAppStatus, changeImageIndex } from '../../features/common/commonSlice';
import { BBT_TYPE } from './BodyBalance/BodyBalanceTest/Constants';
import CameraPopup from '../Camera/CameraPopup';
import useBLE from '../../hooks/useBLE';
import SmartMatPopup from '../SmartMat/SmartMatPopup';
import TPopUp from '../../components/TPopUp/TPopUp';
import TestSelectPopup from "../../components/TestSelectPopup/TestSelectPopup";
import { getROM_INFO } from "./ROM/ROMTest/Constants";
import TScroller from '../../components/TScroller/TScroller';

const COLORS = [[69, 118, 142], [129, 146, 112], [220, 161, 94], [218, 146, 172]];

const preloadImages = [
  alignment_bg_pre,
  physical_bg_pre,
  balance_bg_pre,
  rom_pre_bg_01,
  rom_pre_bg_02,
  rom_pre_bg_03
];

let ROM_INFO = null;

const BodyCheckUpMain = ({...rest}) => {
  const dispatch = useDispatch();
  const { cameraList } = useSelector(state => state.camera);

  if (!ROM_INFO) {
    ROM_INFO = getROM_INFO();
  }
  const initialCheckedState = Array(ROM_INFO.length).fill(true);

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [pressedIndex, setPressedIndex] = useState(-1);
  const { imageIndex } = useSelector((state) => state.common.appStatus);
  const { dateList: bodyAlignmentDateList } = useSelector((state) => state.bodyAlignment);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const {bleConnectStatusRef} = useBLE({autoConnect: cesShowMode});
  const { dateList: romDateList } = useSelector((state) => state.rom);
  const { dateList: bodyBalanceDateList } = useSelector((state) => state.bodyBalance);
  const { dateList: physicalTestDateList } = useSelector((state) => state.physicalTest);
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);
  const [showSmartMatPopup, setShowSmartMatPopup] = useState(false);

  // focus Text
  const [guideText, setGuideText] = useState(null);

  // ROM TestSelectPopup
  const [showTestSelectPopup, setShowTestSelectPopup] = useState(false);

  useEffect(() => {
    return () => dispatch(changeImageIndex({imageIndex: (imageIndex + 1) % 3}));
  }, [])

  const ITEMS = useMemo(() => {
    const itemConfig = [
      { type: 'bodyalignment', target: Config.panel_names.BODY_ALIGNMENT_REPORT, list: bodyAlignmentDateList },
      { type: 'rom', target: Config.panel_names.ROM_REPORT, list: romDateList },
      { type: 'bodybalance', target: Config.panel_names.BODY_BALANCE_REPORT, list: bodyBalanceDateList },
      { type: 'physicaltest', target: Config.panel_names.PHYSICAL_TEST_GATE, list: physicalTestDateList }
    ];

    return itemConfig.map(item => {
      const date = item.list.length > 0 ? item.list[item.list.length - 1] : null;
      const daysAgo = convertDateToDaysAgo(date);
      if(cesShowMode && daysAgo === null){
        if(item.type === 'bodyalignment' ){
          item.target = Config.panel_names.BODY_ALIGNMENT_SCAN;
        }
        if (item.type === "rom") {
          item.target = Config.panel_names.ROM_TEST;
        }
        if (item.type === "bodybalance") {
          item.target = Config.panel_names.BODY_BALANCE_TEST;
        }
      }
      return { type: item.type, target: item.target, daysAgo };
    });
  }, [bodyAlignmentDateList, romDateList, bodyBalanceDateList, physicalTestDateList]);

  const clearPressedIndexJob = useRef(new Job((func) => {
    setPressedIndex(-1);
    setTimeout(func, Config.BUTTON_PRESS_DELAY/2);
  }, Config.BUTTON_PRESS_DELAY));

	const onClick = useCallback((index) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressedIndex(index);
    clearPressedIndexJob.current.start(()=>{
      const item = ITEMS[index];
      if(cesShowMode){
        console.log('onClick', item.type, item.target);
        if(item.type === 'bodyalignment'){
          //todo camera check
          if(cameraList.length < 1){
            setIsConnectionPopupOpen(true);
          }else{
            dispatch(addPanels({ name: Config.panel_names.BODY_ALIGNMENT_SCAN }));
            dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
          }
        }else if(item.type === 'bodybalance'){
          console.log('bodybalance', item.type, item.target);
          if (bleConnectStatusRef.current !== "connected") {
            setShowSmartMatPopup(true);
          } else {
            dispatch(addPanels({ name: Config.panel_names.BODY_BALANCE_TEST}));
            dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
          }
        }
      }else{
        if(item.target){
          dispatch(addPanels({name : item.target}));
        }
      }
    });
	},[dispatch, cesShowMode, cameraList, bleConnectStatusRef]);

  const onSelected = useCallback((index) => {
    if (index >=0 ) {
      SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    };
    setFocusedIndex(index);
    setPressedIndex(-1);
    clearPressedIndexJob.current.stop();
	},[]);

  const onSpotlightUp = useCallback(() => {
    Spotlight.focus('bodycheckupmain_header');
	},[]);

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  const closeSmartMatPopup = useCallback(() => {
    setShowSmartMatPopup(false);
  }, []);

  const closeTestSelectPopup = useCallback((ev) => {
    console.log("ev.checked", ev.checked);
    setShowTestSelectPopup(false);
    if (ev.checked) {
      dispatch(
        addPanels({
          name: Config.panel_names.ROM_TEST,
          panelInfo: { romList: ev.checked },
        })
      );
      dispatch(
        changeAppStatus({ showLoadingPanel: { show: true, type: "tips" } })
      );
    }
  }, []);

  // const onClickTerms = useCallback((index) => {
	// 	if (index) {//start
	// 		if(cestarget === Config.panel_names.BODY_ALIGNMENT_SCAN){
  //       dispatch(addPanels({ name: Config.panel_names.BODY_ALIGNMENT_SCAN }));
  //       dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
  //       } else if (cestarget === Config.panel_names.ROM_TEST) {
  //         openTestPopup();
  //     }else if(cestarget === Config.panel_names.BODY_BALANCE_TEST){
  //       dispatch(addPanels({name : Config.panel_names.BODY_BALANCE_TEST, panelInfo:{bbtList:[BBT_TYPE.TWO_LEG_STANCE]}}));
  //       dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
  //     }
	// 	}else{
  //     Spotlight.focus(Config.panel_names.BODY_CHECK_UP_MAIN);
  //   }
  //   setShowCesTermsAgree(false);
	// }, [dispatch, cestarget]);

  useEffect(() => {
    switch (focusedIndex) {
      case 0:
        setGuideText(
          $L(
            "This quick test can help people check their body posture and tips for proper body alignment."
          )
        );
        break;
      case 1:
        setGuideText(
          $L(
            "Range of motion, also known as ROM, is a measure of flexibility involving ligaments, tendons, muscles, bones,{br}and joints, so testing for ROM is essential in determining fitness and in assessing possible damage."
          )
        );
        break;
      case 2:
        setGuideText(
          $L(
            "Physical balance ability can be evaluated or shape of the foot can be checked,{br}and walking habits can be seen through the walking path test."
          )
        );
        break;
      case 3:
        setGuideText(
          $L(
            "The Senior Fitness Test focuses on assessing different fitness components{br}such as strength, endurance, flexibility, agility, and balance."
          )
        );
        break;
      default:
        setGuideText($L("Assess your fitness level with different tests."));
        break;
    }
  }, [focusedIndex]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader
        title={$L("My Body Analysis")}
        spotlightId={"bodycheckupmain_header"}
      />
      <TBody className={css.tBody}>
        <TVirtualSpotContainer
          className={css.sectionContainer}
          debug={false}
          colors={COLORS}
          bgImage={virtualspot_bg}
          onSelected={onSelected}
          onClick={onClick}
          onSpotlightUp={onSpotlightUp}
        >
          {ITEMS.map((item, i) => (
            <MainSection key={item.type} daysAgo={item.daysAgo} imageIndex={imageIndex} type={item.type} focused={focusedIndex === i} pressed={pressedIndex === i}/>
          ))}
        </TVirtualSpotContainer>
        <div className={css.title}>{convertNewlinesToBr(guideText)}</div>
        {showTestSelectPopup && (
          <TestSelectPopup
            initialCheckedState={initialCheckedState}
            testInfos={ROM_INFO}
            romTest={true}
            onClose={closeTestSelectPopup}
          />
        )}
        <PreloadImage preloadImages={preloadImages} />
        {isConnectionPopupOpen &&
          <CameraPopup onClose={onCloseCameraPopup}/>
        }
        {showSmartMatPopup &&
          <SmartMatPopup onClose={closeSmartMatPopup} />
        }
      </TBody>
    </TPanel>
  );
};

export default BodyCheckUpMain;
