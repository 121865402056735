import React, { useCallback } from 'react';
import TTimeLine from "../../../../components/TTimeLine";
import guideImage from '../../../../../assets/guideImage.svg';
import TScroller from "../../../../components/TScroller/TScroller";

const DUMMY_ITEMS = [
  {
    "title": "아테네 신타그마 광장 산책 코스",
    "image": guideImage,
    "trackType": "cycloid",
    "category": "산책코스",
    "date": "2023-10-13",
    "summary": [
      { "label": "거리", "value": 1, "type": "distance" },
      { "label": "걸음수", "value": 1400, "type": "steps" },
      { "label": "시간", "value": 600, "type": "time" },
      { "label": "열량", "value": 100, "type": "calorie" }
    ]
  },
  {
    "title": "지리산 둘레길",
    "image": guideImage,
    "trackType": "line",
    "category": "조깅코스",
    "date": "2023-10-12",
    "summary": [
        { "label": "거리", "value": 2400, "type": "distance" },
        { "label": "걸음수", "value": 1200, "type": "steps" },
        { "label": "시간", "value": 540, "type": "time" },
        { "label": "열량", "value": 80, "type": "calorie" }
    ]
  },
  {
    "title": "지리산 둘레길",
    "image": guideImage,
    "trackType": "line",
    "category": "조깅코스",
    "date": "2023-10-10",
    "summary": [
        { "label": "거리", "value": 2400, "type": "distance" },
        { "label": "걸음수", "value": 1200, "type": "steps" },
        { "label": "시간", "value": 540, "type": "time" },
        { "label": "열량", "value": 80, "type": "calorie" }
    ]
  },
  {
    "title": "아테네 신타그마 광장 산책 코스",
    "image": guideImage,
    "trackType": "cycloid",
    "category": "산책코스",
    "date": "2023-10-10",
    "summary": [
      { "label": "거리", "value": 1, "type": "distance" },
      { "label": "걸음수", "value": 1400, "type": "steps" },
      { "label": "시간", "value": 600, "type": "time" },
      { "label": "열량", "value": 100, "type": "calorie" }
    ]
  }
];

const TTimeLineLayout = () => {
  const itemRenderer = useCallback((item) => {
    if (!item) return;

    const styles = {
      height: '200px',
      background: 'white',
      width: '100%',
      margin: '0 40px',
      padding: '0 20px',
      lineHeight: '200px',
      borderRadius: '20px',
      fontFamily: 'LGSmartUIR'
    };

    return (
      <div style={styles}>{item.title}</div>
    );
  }, []);

  return (
    <>
      <TScroller style={{ height: '90%', color: '#212121' }}>
        <TTimeLine items={DUMMY_ITEMS} itemRenderer={itemRenderer}/>
      </TScroller>
    </>
  );
};

export default TTimeLineLayout;
