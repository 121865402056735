import css from "./PlaybackSpeed.module.less";
import React, { useState, useEffect, useRef, useCallback } from "react";
import TBody from "../../components/TBody/TBody";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TShakaPlayer from "../../components/TShakaPlayer/TShakaPlayer";
// import SFTchair from "../../../assets/video/SFTchair.mp4";
// import SFTdumbbell from "../../../assets/video/bodyScan.mp4";
// import chairStandThumb from "../../../assets/images/chairStandThumb.png";

import TButton from "../../components/TButton/TButton";

/**
 * @module ShakaPlayerTest
 */
// const VIDEO_SOURCE = [SFTchair, SFTdumbbell];
const VIDEO_SOURCE = [
	"https://d1up91cavfzvg.cloudfront.net/vod/eedbec9d-c818-4cd9-bf83-1ba4d09c4c80/9c7c6390548b465fb9975ff798069425.m3u8",
	"https://d1up91cavfzvg.cloudfront.net/vod/e9fb51d5-309c-46e7-96fb-02941e347813/c707303b1b904c13a2cb5763b3428062.m3u8",
	"https://d1up91cavfzvg.cloudfront.net/vod/be14f16b-e289-4e45-b785-2d6ac05778dc/123c706eac224480840c23d6a7b64efc.m3u8",
	"https://d1up91cavfzvg.cloudfront.net/vod/12b107b9-8ed4-4b00-9fbb-7d0bb07f1ae2/4a448cc15da4495ba2834d499f76d1fd.m3u8"

];

const ShakaPlayerTest = ({...rest}) => {
	const playerRef = useRef(null);
  const reftestRef = useRef(null);
	const [videoIndex, setVideoIndex] = useState(0);
  const [playProgress, setplayProgress] = useState(0);
  const RATES = [0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0]

	 const onVideoProgress = useCallback((e) => {
      setplayProgress(e);
  },[]);

  const CONFIG = {
    controlPanelElements: ['playback_rate'],
  };

  useEffect(()=>{
		setTimeout(() => {
			playerRef.current?.shakaRef.current.ui?.configure(CONFIG);
		}, 0);
	},[]);

  const onVideoEnd = useCallback(() => {
    console.log('ShakaPlayerTest onVideoEnd')
    setVideoIndex((videoIndex+1)%VIDEO_SOURCE.length);
  },[videoIndex]);

	const onPlay = useCallback(()=>{
    playerRef.current.seekTo(playProgress);
    playerRef.current.play();
	}, [playProgress]);

	const onPause = useCallback(()=>{
    playerRef.current.pause();
	},[])

  const handlePlaybackRate = useCallback((rate) => () => {
      playerRef.current.setPlaybackRate(rate);
  },[]);

return (
<TPanel {...rest}>
  <THeader title={"Video Speed Test"}/>
  <TBody>
    <TShakaPlayer
      playerRef={playerRef}
      className={css["shaka-player"]}
      src={VIDEO_SOURCE[videoIndex]}
      onProgress={onVideoProgress}
      onEnded={onVideoEnd}
      reftest={reftestRef}
    />
    <div className={css.buttoncontainer}>
      <TButton onClick={onPlay}>play</TButton>
      <TButton onClick={onPause}>pause</TButton>
      <div className={css.box}>
        {RATES.map((rate, idx) => (
        <TButton key={idx} onClick={handlePlaybackRate(rate)}> {rate}x </TButton>
        ))}
      </div>
    </div>
  </TBody>
</TPanel>
  );
}

export default ShakaPlayerTest;