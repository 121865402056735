import React, { useMemo } from "react";
import css from "./CoPResults.module.less";
import { $L } from '../../../../../utils/helperMethods';
import ArchBarItem from '../../../../../components/ArchBarItem/ArchBarItem';

const CoPResults = ({ currentData, style, OverallImg }) => {
  const balanceArchIndexData = useMemo(() => {
    const ret = { leftacrhIndex: 0, rightarchIndex: 1 };
    if (currentData?.onelegstanceR) {
      ret.leftacrhIndex = currentData?.onelegstanceR?.value_leftBalance;
      ret.rightarchIndex = currentData?.onelegstanceR?.value_rightBalance;
    }
    return ret;
  }, [currentData]);

  return (
    <ArchBarItem leftBalanceData={balanceArchIndexData.leftacrhIndex} rightBalanceRData={balanceArchIndexData.rightarchIndex} style={style} OverallImg={OverallImg} />
  )
}

export default CoPResults;