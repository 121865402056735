import React, { useMemo, useCallback, useState, useRef } from "react";
import classNames from "classnames";
import css from "./BodyAlignmentItem.module.less";

import * as Config from "../../../utils/Config";
import * as SoundEffect from "../../../utils/SoundEffect";
import { $L, convertNewlinesToBr } from "../../../utils/helperMethods";

import { Job } from "@enact/core/util";
import Spottable from '@enact/spotlight/Spottable';

import BODYALIGNMENT_HEAD_SHIFT_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_headshift.png"
import BODYALIGNMENT_NECK_TILT_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_necktilt.png"
import BODYALIGNMENT_SHOLUDER_TILT_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_sholudertilt.png"
import BODYALIGNMENT_SHOLUDER_SHIFT_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_sholudershift.png"
import BODYALIGNMENT_PELVIC_ROTATION_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_pelvicrotation.png"
import BODYALIGNMENT_PELVIC_TILT_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_pelvictilt.png"
import BODYALIGNMENT_PELVIC_SHIFT_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_pelvicshift.png"
import BODYALIGNMENT_LEG_ALIGNMENT_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_legalignment.png"
import BODYALIGNMENT_KNEEE_IMAGE from "../../../../assets/landingPage/bodyAlignmentList/img_module_knee.png"

const SpottableComponent = Spottable("div");

/**
*
* @param {type} 'headShift', 'neckTilt', 'shoulderTilt', 'shoulderShift', 'pelvicRotation', 'pelvicTilt', 'pelvicShift', 'legAlignment', 'knee'
* @returns
*/

const ListData = () => ({
  'headShift': {
    title: $L("Head Shift"),
    image: BODYALIGNMENT_HEAD_SHIFT_IMAGE
  },
  'neckTilt': {
    title: $L("Neck Tilt"),
    image: BODYALIGNMENT_NECK_TILT_IMAGE
  },
  'shoulderTilt': {
    title: $L("Shoulder Tilt"),
    image: BODYALIGNMENT_SHOLUDER_TILT_IMAGE
  },
  'shoulderShift': {
    title: $L("Shoulder Shift"),
    image: BODYALIGNMENT_SHOLUDER_SHIFT_IMAGE
  },
  'pelvicRotation': {
    title: $L("Pelvic Shift"),
    image: BODYALIGNMENT_PELVIC_ROTATION_IMAGE
  },
  'pelvicTilt': {
    title: $L("Pelvic Tilt"),
    image: BODYALIGNMENT_PELVIC_TILT_IMAGE
  },
  'pelvicShift': {
    title: $L("Pelvic Rotation"),
    image: BODYALIGNMENT_PELVIC_SHIFT_IMAGE
  },
  'legAlignment': {
    title: $L("Leg Alignment"),
    image: BODYALIGNMENT_LEG_ALIGNMENT_IMAGE
  },
  'knee': {
    title: $L("Knee Flexion/Extension"),
    image: BODYALIGNMENT_KNEEE_IMAGE
  },
})

const BodyAlignmentItem = ({type, onClick, onFocus, className, ...rest}) => {
  const [pressed, setPressed] = useState(false);
  
  const listData = useMemo(()=>{
    return ListData()[type];
  },[type]);

	const _onClick = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressed(true);
    clearPressedJob.current.start(() => {
      if(onClick){
        onClick(ev);
      }
    });

	}, [onClick]);

  const _onFocus = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    if (onFocus) {
      onFocus(ev);
    }
  }, [onFocus])

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        css.item,
        css[type],
        className,
        pressed && css.pressed,
        
      )}
      onClick={_onClick}
      onFocus={_onFocus}
    >
      <div className={css.title}>{listData.title}</div>
      <div className={css.image} style={{backgroundImage: `url(${listData.image})`}}/>
        
    </SpottableComponent>
  );
}

export default BodyAlignmentItem;