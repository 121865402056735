import React, { useRef, useCallback, useEffect, useState } from "react";
import classNames from 'classnames';
import css from "./DistanceGuideCanvas.module.less";

const perpendicularLength = 50;
const BASELINE_COLOR = "#95FFE7";
const arrowSize = 11;

const getDistance = (p1, p2) =>{
	return Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2);
}
const drawBaseLine = (ctx, p1, p2)=>{
	ctx.beginPath();
	ctx.lineWidth = 2;
	ctx.strokeStyle = BASELINE_COLOR;
	ctx.lineCap = 'round';
	ctx.setLineDash([4, 4])
	ctx.moveTo(p1[0], p1[1]);
	ctx.lineTo(p2[0], p2[1]);
	ctx.stroke();
	ctx.setLineDash([0, 0]);
	// Calculate the angle of the connecting line
	const angle = Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);

	// Draw perpendicular lines at both ends
	drawPerpendicularLine(ctx, p1, angle, perpendicularLength);
	drawPerpendicularLine(ctx, p2, angle + Math.PI, perpendicularLength);

	const distance = getDistance(p1, p2);
	if(distance > arrowSize*2.5){
		// Draw filled arrowheads
		drawFilledArrowhead(ctx, p1[0], p1[1], angle, arrowSize);
		drawFilledArrowhead(ctx, p2[0], p2[1], angle + Math.PI, arrowSize);
	}
}
const drawPerpendicularLine =(ctx, p, angle, length) => {
	const xStart = p[0] - (length/2) * Math.cos(angle + Math.PI / 2);
	const yStart = p[1] - (length/2) * Math.sin(angle + Math.PI / 2);
	const xEnd = p[0] + (length/2) * Math.cos(angle + Math.PI / 2);
	const yEnd = p[1] + (length/2) * Math.sin(angle + Math.PI / 2);

	ctx.beginPath();
	ctx.lineWidth = 2;
	ctx.strokeStyle = "#21FFCD";
	ctx.lineCap = 'round';
	// ctx.setLineDash([2, 2]);//길이, 공백, 길이
	ctx.moveTo(xStart, yStart);
	ctx.lineTo(xEnd, yEnd);
	ctx.stroke();
}
const drawFilledArrowhead = (context, x, y, angle, arrowSize) => {
	const xEnd = x + arrowSize * Math.cos(angle);
	const yEnd = y + arrowSize * Math.sin(angle);

	context.beginPath();
	context.moveTo(x, y);
	context.lineTo(xEnd - 0.5 * arrowSize * Math.cos(angle - Math.PI / 2), yEnd - 0.5 * arrowSize * Math.sin(angle - Math.PI / 2));
	context.lineTo(xEnd + 0.5 * arrowSize * Math.cos(angle - Math.PI / 2), yEnd + 0.5 * arrowSize * Math.sin(angle - Math.PI / 2));
	context.lineTo(x, y);
	context.fillStyle = BASELINE_COLOR;
	context.fill();
}

const CHECKING_POINT = [31,33,23,28]; //손, 발 포인트 중 짧은 선을 그리는 걸로...

/**
 * @module DistanceGuideCanvas
 * @returns DistanceGuideCanvas
 */
const DistanceGuideCanvas = ({bodyPositionLiveData, cameraSize, className, ...rest}) => {
	const canvasRef = useRef();
	const [canvasSize, setCanvasSize] = useState([0,0]);
	useEffect(()=>{
		const w = canvasRef.current.getBoundingClientRect().width;
		const h = canvasRef.current.getBoundingClientRect().height;
		setCanvasSize([w,h]);
	},[]);
	useEffect(()=>{
		const ctx = canvasRef.current.getContext('2d');
		try{
			if(ctx && canvasSize[0] > 0 && cameraSize && cameraSize.width > 0){
				ctx.clearRect(0,0,canvasSize[0], canvasSize[1]);

				if(!!bodyPositionLiveData?.joints2D?.length){
					const scale = canvasSize[1] / cameraSize.height;
					const leftMargin = (cameraSize.width * scale - canvasSize[0]) / 2;
					const convertedPoints = [];
					CHECKING_POINT.forEach((value, index) => {
						const bellyCoordination = bodyPositionLiveData.joints2D[value];
						const x = (cameraSize.width - bellyCoordination[0]) * scale - leftMargin;
						const y = bellyCoordination[1] * scale;
						convertedPoints.push([x,y]);
					});
					const d1 = getDistance(convertedPoints[0], convertedPoints[2]);
					const d2 = getDistance(convertedPoints[0], convertedPoints[3]);
					const d3 = getDistance(convertedPoints[1], convertedPoints[2]);
					const d4 = getDistance(convertedPoints[1], convertedPoints[3]);
					const minDistance = Math.min(d1,d2,d3,d4);
					if(minDistance === d1){
						drawBaseLine(ctx, convertedPoints[0], convertedPoints[2]);
					}else if(minDistance === d2){
						drawBaseLine(ctx, convertedPoints[0], convertedPoints[3]);
					}else if(minDistance === d3){
						drawBaseLine(ctx, convertedPoints[1], convertedPoints[2]);
					}else if(minDistance === d4){
						drawBaseLine(ctx, convertedPoints[1], convertedPoints[3]);
					}else{
						console.log('DistanceGuideCanvas invalide case');
					}
				}
			}
		}catch(e){
			console.warn('DistanceGuideCanvas draw exception ', e);
		}
	},[bodyPositionLiveData, cameraSize, canvasSize]);

	return (
		<canvas
			{...rest}
			ref={canvasRef}
			className={classNames(css.canvas,className)}
			width={canvasSize[0]}
			height={canvasSize[1]}
		/>
	)
}

export default DistanceGuideCanvas;
