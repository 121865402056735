import React, { useRef, useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./ActivityPlayer.module.less";
import TPanel from "../../../components/TPanel/TPanel";
import TShakaPlayer from "../../../components/TShakaPlayer/TShakaPlayer";
import TTrack, { TRACK_TYPE } from '../../../components/TTrack/TTrack';
import { $L, cloneObject, convertNewlinesToBr, getLocalServerUrl, timeToISO8601Str, transTimeSecToText } from '../../../utils/helperMethods';
import * as SoundEffect from '../../../utils/SoundEffect';
import classNames from "classnames";
import ic_bpm from '../../../../assets/icon/ic_bpm.svg';
import ic_foot_guide_left_s from '../../../../assets/icon/ic_foot_guide_left_s.svg';
import ic_foot_guide_left_match_s from '../../../../assets/icon/ic_foot_guide_left_match_s.svg';
import ic_foot_guide_right_s from '../../../../assets/icon/ic_foot_guide_right_s.svg';
import ic_foot_guide_right_match_s from '../../../../assets/icon/ic_foot_guide_right_match_s.svg';
import ic_inhome_walk_kcal from '../../../../assets/icon/ic_inhome_walk_kcal.svg';
import ic_inhome_walk_time from '../../../../assets/icon/ic_inhome_walk_time.svg';
import WebWorkerUtil, { WORKER_ID } from "../../../utils/WebWorker/WebWorkerUtil";
import { MAT_STATUS, changeMatStatus, startStopBleService } from "../../../features/ble/bleSlice";
import TStepper from "../../../components/TStepper/TStepper";
import WalkingEndPopup from "./ActivityEndPopup";
import WalkingReportPopup from "./ActivityReportPopup";
import { addPanels, popPanel } from "../../../features/panels/panelsSlice";
import { changeThemeModeStatus } from "../../../features/common/commonSlice";
import Marquee from "@enact/ui/Marquee";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";
import * as Config from "../../../utils/Config";
import { calorieCalc } from "../../../utils/calorieCalc";
import useActivity from '../../../hooks/useActivity';
import Spottable from "@enact/spotlight/Spottable";
import TButton, { SIZES, TYPES } from "../../../components/TButton/TButton";
import { READY_TIMER, WALKING_MODE, PLAYER_CONFIG, ACTIVITY_SEQUENCE, SETTING_POPUP, concedeTextToNumber, recommendSpeed } from "./Constants";
import border_dot from "../../../../assets/inHomeOutdoorActivity/border_dot.svg";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import ContentControllerModal from "../../ContentPlayerPanel/ContentPlayerModal/ContentControllerModal";
import useBLE from "../../../hooks/useBLE";
import PIPCamera from "../../../components/PIPCamera/PIPCamera";
import useActivatePositionDetection from "../../../hooks/useActivatePositionDetection";
import BaseLine from "../../../components/BaseLine/BaseLine";
import GesturePopup from "./GesturePopup";
import RatioSettingPopup from "./RatioSettingPopup";
import usePrevious from "../../../hooks/usePrevious";
import ToggleButton, { TYPES as TOGGLE_TYPES } from "../../../components/ReportTop/ToggleButton";
import useDebugKey from "../../../hooks/useDebugKey";
import useGesture from "../../../hooks/useGesture";
import VRPlayer, { MODES } from "../../../components/VRPlayer/VRPlayer";
import { off, on } from "@enact/core/dispatcher";
import * as TTSService from "../../../lunaSend/TTSService";
import TestGuideSignMsg from "../../../components/TestGuideSignMsg/TestGuideSignMsg";
import useBpm from "../../../hooks/useBpm";
import { updateSettings } from "../../../features/fitService/fitServiceSlice";
import { updateMaxCameraBpm } from "../../../features/camera/cameraSlice";

const walkOutputDefault = { velocity: 0, validLCount: 0, invalidLCount: 0, validRCount: 0, invalidRCount: 0 };
const startWalkingInfoDefault = { progressDistance: 0, progressStep: 0, progressTime: 0, progressCalories: 0 };

const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);
let lastCalledBaseLine = null;
let lastCalledMatched = null;
const MAT_DELAY_TIME = 1000;
const HIGHER_TEXT_DELAY = 2000;
const ActivityPlayer = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
	const playerRef = useRef(null);
	const webcamRef = useRef(null);
  const mHitWorker = useRef(null);
  const mXFitWorker = useRef(null);
  const footRef = useRef(null);
  const exerciseInterval = useRef(null);
  const interval = useRef(null);
  const reportDataRef = useRef({ heartBeat: 0 });
  const useCameraRef = useRef(false);
  const { matStatus } = useSelector(state => state.ble);
  const { isAppForeground, themeMode } = useSelector((state) => state.common.appStatus);
  const survey = useSelector((state) => state.fitService.survey);
  const { cameraList } = useSelector(state => state.camera);
  const { cesShowMode, serverType, localURL } = useSelector((state) => state.common.localSettings);
  const [walkOutput, setWalkOutput] = useState(walkOutputDefault);
  const localSettings = useSelector((state) => state.common.localSettings);
  const { concede, stride } = useSelector(state => state.fitService.settings);
  const [timer, setTimer] = useState(READY_TIMER);
  const [exerciseTime, setExerciseTime] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const [activityStatus, setActivityStatus] = useState(panelInfo.init ? ACTIVITY_SEQUENCE.INIT : ACTIVITY_SEQUENCE.ACTIVITY);
  const [videoEnd, setVideoEnd] = useState(false);
  const [startWalkingInfo, setStartWalkingInfo] = useState(startWalkingInfoDefault);
  const [spotIndex, setSpotIndex] = useState(-1);
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const [kneeGuideLineStyle, setKneeGuideLineStyle] = useState(null);
  const { activityTypeRef, contentInfo, saveActivityHistory, getkneeGuideLineStyle } = useActivity(panelInfo);
  const matchedCount = useRef(0);
  const matchedDelay = useRef(false);
  const matchedInterval = useRef(null);
  const higherMsgShowingRef = useRef(null);
  const higherMsgHidingRef = useRef(null);
  const showHigherMsg = useRef(false);
  const { bleConnectStatus } = useBLE({});
  const { activatePositionDetection, deActivatePositionDetection, bodyPositionLiveData, cancelGetEventNotification, activeRPPG, deActiveRPPG, bpm } = useActivatePositionDetection();
  const { bpmStatus, bpmWarningText } = useBpm({ bpm });
  const [settingPopup, setSettingPopup] = useState('');
  const workoutModeRef = useRef((cesShowMode && activityTypeRef.current === 'hiking') ? true : false);
  const { gestureRelease } = useGesture();
  const contentInfoRef = usePrevious(contentInfo);
  const maxCameraBpmRef = useRef(0);

  const clearJob = useCallback((ref) => {
    if (ref) {
        clearInterval(ref);
        ref = null;
    } else {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
      if (exerciseInterval.current) {
        clearInterval(exerciseInterval.current);
        exerciseInterval.current = null;
      }
      if (matchedInterval.current) {
        clearInterval(matchedInterval.current);
        matchedInterval.current = null;
      }
    }
  }, []);

  // todo remove: 경치감상 바로가기
  const goScenery = useCallback(() => {
    dispatch(changeMatStatus(MAT_STATUS.PLAY));
    onVideoEnd();
  }, []);
  useDebugKey({ onClick: goScenery });

  const videoInfo = useMemo(() => {
    if (activityStatus === ACTIVITY_SEQUENCE.SCENERY && Array.isArray(contentInfo.hotSpot) && contentInfo.hotSpot[spotIndex]) {
      return { playUrl: contentInfo.hotSpot[spotIndex].playUrl, thumbnailUrl: contentInfo.hotSpot[spotIndex].thumbnailUrl };
    }

    return { playUrl: contentInfo.playUrl, thumbnailUrl: contentInfo.thumbnailUrl };
  }, [contentInfo, activityStatus, spotIndex]);

  const progressDistance = useMemo(() => {
    return (progressPercent / 100 * contentInfo.distance);
  }, [progressPercent, contentInfo]);

  const calorieExpenditure = useMemo(() => {
    return calorieCalc(activityTypeRef.current, survey.weight, exerciseTime);
  }, [contentInfo, survey, exerciseTime]);

  const stepperOrder = useMemo(() => {
    const arr =
      activityTypeRef.current === "hiking"
        ? [$L("Course {num}").replace("{num}", "1")]
        : [$L("Start")];

    for (let i = 0; i < contentInfo.hotSpot.length; i++) {
      if (contentInfo.hotSpot[i]?.distance < contentInfo.distance) {
        arr.push('');
      }
    }
    arr.push($L("Finished"));

    return arr;
  }, [contentInfo]);

  const stepperNum = useMemo(() => {
    return Math.floor(progressPercent / 100 * (stepperOrder.length - 1));
  }, [progressPercent, stepperOrder]);

  const showPIPCamera = useMemo(() => {
    if (!isAppForeground) {
      return false;
    }
    if (typeof window === 'object' && window.PalmSystem) {
      return cameraList && cameraList.length > 0;
    }else{
      return true;
    }
  }, [isAppForeground, cameraList]);

  useEffect(() => {
    const obj = {
      progressDistance: formatUnit(progressDistance, 2),
      progressStep: walkOutput.validLCount + walkOutput.validRCount,
      progressTime: exerciseTime,
      progressCalories: calorieExpenditure,
      averageSpeed: formatUnit(progressDistance, 2) / (exerciseTime / 3600) // todo: 영상 넘길 때 averageSpeed 값 계산 필요
    };

    if (activityTypeRef.current === 'hiking') {
      obj.progressHeight = formatUnit(progressDistance); // report 용
    }

    obj.totalDistance = obj.progressDistance - startWalkingInfo.progressDistance;
    obj.totalStep = obj.progressStep - startWalkingInfo.progressStep;
    obj.totalTime = obj.progressTime - startWalkingInfo.progressTime;
    obj.totalCalories = obj.progressCalories - startWalkingInfo.progressCalories;

    if (activityStatus && bpm) {
      obj.heartBeat = bpm.toFixed(0);
      // max bpm
      if(Number(bpm) > 0 && bpm > maxCameraBpmRef.current){
        maxCameraBpmRef.current = bpm;
      }
    }

    obj.feedback = {
      percent: parseInt(progressDistance / (contentInfoRef.current.distance) * 100),
      totalStep: (walkOutput.validLCount + walkOutput.invalidLCount + walkOutput.validRCount + walkOutput.invalidRCount),
      successfulStep: walkOutput.validLCount + walkOutput.validRCount,
      successfulRateL: parseInt(walkOutput.validLCount / (walkOutput.validLCount + walkOutput.invalidLCount) * 100),
      successfulRateR: parseInt(walkOutput.validRCount / (walkOutput.validRCount + walkOutput.invalidRCount) * 100)
    };

    reportDataRef.current = Object.assign({}, reportDataRef.current, obj);
  }, [progressDistance, walkOutput, exerciseTime, calorieExpenditure, bpm, startWalkingInfo]);

  const setFunc = useCallback((res) => {
    if (matchedCount && matchedCount.current && res && res.value && matchedCount.current.value < res.value) {
      matchedDelay.current = true;
      clearJob(matchedInterval.current);
      clearTimeout(higherMsgShowingRef.current);
      clearTimeout(higherMsgHidingRef.current);
      higherMsgShowingRef.current = setTimeout(() => {
        showHigherMsg.current = true;
      }, HIGHER_TEXT_DELAY);
      higherMsgHidingRef.current = setTimeout(() => {
        showHigherMsg.current = false;
      }, HIGHER_TEXT_DELAY+2000);
      matchedInterval.current = setInterval(() => {
        matchedDelay.current = false;
      }, MAT_DELAY_TIME);
    }
    matchedCount.current = cloneObject(res);

    return [matchedDelay.current];
  }, []);

  const engineHandler = useCallback((type, param) => {
    const isHiking = workoutModeRef.current && (activityTypeRef.current === 'hiking');

    if (type === 'xFit') {
      const percent = localSettings[`climingEnduranceRatio_${concedeTextToNumber[param || concede]}`];

      if (isHiking && mXFitWorker.current && percent !== undefined) {
        console.log(`${isHiking ? 'hiking' : 'walking'} engineHandler xFit setting... concede: ${concedeTextToNumber[param || concede]}°, percent: ${percent}`);

        WebWorkerUtil.postMessage(mXFitWorker.current, { type: "init" }, true);
        WebWorkerUtil.postMessage(mXFitWorker.current, { type: "setSFTEnduranceRatio", value: [percent] }, true);
        WebWorkerUtil.postMessage(mXFitWorker.current, { type: "setParam", value: ["sft", "7", "front"] }, true);
        if (useCameraRef.current) activatePositionDetection({ isDetectionDelayed: false });
      }
    } else if (mHitWorker.current) {
      switch (type) {
        case 'init': { // 기능 초기화
          console.log(`${isHiking ? 'hiking' : 'walking'} engineHandler init...`);
          if (isHiking) {
            WebWorkerUtil.postMessage(mHitWorker.current, {
              type: "initClimbingMode",
              setOption: {
                key: 'setWalkInputData',
                replaceKey: 'setClimbingInputData',
                valueKey: 'process',
                setFunc
              }
            }, true);
          } else {
            WebWorkerUtil.postMessage(mHitWorker.current, { type: "initWalkMode", value: [1, 1] }, true);
          }
          break;
        }
        case 'reset': { // output data 초기화
          console.log(`${isHiking ? 'hiking' : 'walking'} engineHandler reset...`);
          if (isHiking) {
            WebWorkerUtil.postMessage(mHitWorker.current, { type: "resetClimbingMode" }, true);
          } else {
            WebWorkerUtil.postMessage(mHitWorker.current, { type: "resetWalkMode" }, true);
          }
        }
        case 'setParam': { // 파라미터 설정
          console.log(`${isHiking ? 'hiking' : 'walking'} engineHandler setParam...`);
          if (isHiking) {
            WebWorkerUtil.postMessage(mHitWorker.current, { type: "setClimbingParams", value: [stride, contentInfo.contentVelocity] }, true);
            // WebWorkerUtil.postMessage(mHitWorker.current, { type: "setClimbingSlopeAngle", value: [55] }, true);
          } else {
            WebWorkerUtil.postMessage(mHitWorker.current, { type: "setWalkModeParams", value: [stride, contentInfo.contentVelocity, contentInfo.boostVelocity, contentInfo.boostStrength] }, true);
          }
        }
      }
    }
  }, [contentInfo, concede, stride]);

  const startWalking = useCallback((mode = WALKING_MODE.init) => {
    setActivityStatus(ACTIVITY_SEQUENCE.ACTIVITY);

    if (mode && mode !== WALKING_MODE.play) {
      dispatch(changeMatStatus(MAT_STATUS.STOP));
      setTimer(READY_TIMER);
      setSpotIndex(-1);
      setVideoEnd(false);
      setVideoCurrentTime(0);

      if (mode === WALKING_MODE.continue && contentInfo.history.progressDistance) {
        const ratio = contentInfo.history.progressDistance / contentInfo.distance;
        playerRef.current.seekTo(playerRef.current.getDuration() * ratio);
        setExerciseTime(contentInfo.history.progressTime);
        setProgressPercent(ratio * 100);
        setWalkOutput({ ...walkOutputDefault, validLCount: Math.floor(contentInfo.history.progressStep / 2), validRCount: Math.ceil(contentInfo.history.progressStep / 2) });
        setStartWalkingInfo(contentInfo.history);
      } else {
        if (playerRef && playerRef.current && playerRef.current.getCurrentTime() > 0) {
          playerRef.current.seekTo(0.1);
        }

        engineHandler('reset');
        setExerciseTime(0);
        setProgressPercent(0);
        setWalkOutput(walkOutputDefault);
        setStartWalkingInfo(startWalkingInfoDefault);
      }

      engineHandler('xFit');
      // todo: spot -> activity 고려 필요
      dispatch(startStopBleService({ clientId: null, mode: "walkmode", start: true }));
    }

    if (timer < 0 || mode !== WALKING_MODE.play) {
      interval.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer >= 0) {
            reportDataRef.current.startTime = timeToISO8601Str(new Date());

            setTimeout(() => {
              dispatch(changeMatStatus(MAT_STATUS.PLAY));
              clearJob(interval.current);
            }, 0);
            return prevTimer;
          } else {
            return prevTimer + 1;
          }
        })
      }, 1000);
    }
  }, [contentInfo, engineHandler, timer]);

  const endWalking = useCallback(() => {
    saveActivityHistory(reportDataRef.current);
    SoundEffect.stopAll();
    dispatch(startStopBleService({ clientId: null, mode: "walkmode", start: false }));
  }, []);

  const onVideoLoadedMetadata = useCallback(() => {
    if (playerRef && playerRef.current) {
      if (videoCurrentTime > 0 && Math.round(videoCurrentTime) < Math.round(playerRef.current.getDuration())) {
        playerRef.current.seekTo(videoCurrentTime);
      }
    }
  }, [activityStatus, videoCurrentTime]);

  const onVideoLoadedData = useCallback(() => {
    if (!playerRef.current.paused()) {
      playerRef.current.pause();
    }

    if (activityStatus <= ACTIVITY_SEQUENCE.ACTIVITY) {
      if (videoCurrentTime > 0) {
        if (Math.round(videoCurrentTime) >= Math.round(playerRef.current.getDuration())) {
          onWalkingEnd();
        } else {
          onVideoPause();
        }
      } else {
        startWalking((activityStatus === ACTIVITY_SEQUENCE.ACTIVITY) ? WALKING_MODE.continue : WALKING_MODE.init);
      }
    }
  }, [startWalking, activityStatus, videoCurrentTime]);

	const onVideoProgress = useCallback((ev) => {
    const current = ev.toFixed(1);

    setVideoCurrentTime(ev);
    if (current && playerRef && playerRef.current && playerRef.current.getDuration()) {
      setProgressPercent(current / playerRef.current.getDuration() * 100);
    }
  }, [activityStatus]);

  const onVideoPause = useCallback((bool) => {
    if (typeof bool === 'object' && bool.target && bool.target !== playerRef.current?.shakaRef?.current?.videoElement) return;

    let status = MAT_STATUS.PAUSE;

    if (!playerRef.current.paused()) {
      playerRef.current.pause();
    }

    if ((bool === undefined && matStatus === MAT_STATUS.PAUSE) || bool === false) {
      status = timer >= 0 ? MAT_STATUS.PLAY : MAT_STATUS.STOP;
      startWalking(WALKING_MODE.play);
    } else if (timer < 0) {
      clearJob(interval.current);
    }
    dispatch(changeMatStatus(status));
  }, [matStatus, timer, startWalking]);

  const onVideoSeek = useCallback((ev) => {
    const current = ev.value.toFixed();
    setVideoCurrentTime(ev.value);
    setTimer(ev.value);
    setProgressPercent(current / playerRef.current.getDuration() * 100);
  }, []);

  const onVideoEnd = useCallback(() => {
    console.log('onVideoEnd...', videoInfo.playUrl);

    setProgressPercent(100);
    setVideoEnd(true);
    deActiveRPPG();
    deActivatePositionDetection();
    dispatch(startStopBleService({ clientId: null, mode: "walkmode", start: false }));
  }, [contentInfo, videoInfo, activityStatus]);

  const onWalkingEnd = useCallback(() => {
    if (playerRef.current && !playerRef.current.paused()) {
      playerRef.current.pause();
    }

    setActivityStatus(ACTIVITY_SEQUENCE.END);
    endWalking();
  }, []);

  const handlePlaybackRate = useCallback((rate) => {
    if (playerRef && playerRef.current && playerRef.current.getDuration() !== playerRef.current.getCurrentTime()) {
      if (rate <= 0) {
        playerRef.current.pause();
        return;
      }
      const newRate = Math.min(rate, 3);
      const prevRate = playerRef.current.shakaRef.current.player.getPlaybackRate();
      if (newRate !== prevRate) {
        playerRef.current.setPlaybackRate(newRate);
      }
      if (playerRef.current.paused()) {
        playerRef.current.play();
      }
    }
  }, []);

  const onResponseHitWorker = useCallback((e) => {
    if (e.type === 'response') {
      setWalkOutput((prevValue) => {
        return {
          velocity: e.value.velocity,
          validLCount: prevValue.validLCount + Number(e.value.left && e.value.validCount),
          invalidLCount: prevValue.invalidLCount + Number(e.value.left && e.value.invalidCount),
          validRCount: prevValue.validRCount + Number(e.value.right && e.value.validCount),
          invalidRCount: prevValue.invalidRCount + Number(e.value.right && e.value.invalidCount)
        };
      });
      handlePlaybackRate(e.value.rate);

      const rightFoot = getLocalServerUrl(contentInfoRef.current.rightFoot, serverType, localURL);
      const leftFoot = getLocalServerUrl(contentInfoRef.current.leftFoot, serverType, localURL);

      SoundEffect.stopAudio(rightFoot);
      SoundEffect.stopAudio(leftFoot);
      if (e.value.right) {
        SoundEffect.playAudio(rightFoot, false, () => {}, contentInfoRef.current.footVolume || 1);
      } else if (e.value.left) {
        SoundEffect.playAudio(leftFoot, false, () => {}, contentInfoRef.current.footVolume || 1);
      }

      if (footRef && footRef.current) {
        footRef.current.firstChild.firstChild.style.opacity = Number(e.value.validHit && e.value.left);
        footRef.current.lastChild.firstChild.style.opacity = Number(e.value.validHit && e.value.right);
      }

      if (exerciseInterval.current === null && e.value.velocity) {
        exerciseInterval.current = setInterval(() => {
          setTimer((prev) => {
            return prev + 1;
          });
          setExerciseTime((prev) => {
            return prev + 1;
          });
        }, 1000);
      } else if (e.value.velocity === 0 && exerciseInterval.current) {
        clearJob(exerciseInterval.current);
      }
    }
  }, []);

  const onResponseXFitWorker = useCallback((e) => {
    if (e.type === "getSftBaseline") {
      const baseline = e.value;
      if (baseline.result) {
        setKneeGuideLineStyle(getkneeGuideLineStyle(baseline.result, webcamRef.current));
      }
    }
  }, [getkneeGuideLineStyle]);

  useEffect(() => {
		WebWorkerUtil.setCallback(mXFitWorker.current, onResponseXFitWorker);
	}, [onResponseXFitWorker]);

  useEffect(() => {
    if (workoutModeRef.current && matStatus === MAT_STATUS.PLAY && activityStatus === ACTIVITY_SEQUENCE.ACTIVITY && bodyPositionLiveData !== undefined && !!bodyPositionLiveData?.id && activityTypeRef.current === 'hiking') {
      const current = new Date();
      if (!lastCalledBaseLine || current - lastCalledBaseLine > 2500) {
        WebWorkerUtil.postMessage(mXFitWorker.current, { type: "getSftBaseline", value: [bodyPositionLiveData] }, true);
        lastCalledBaseLine = current;
      }
      WebWorkerUtil.postMessage(mXFitWorker.current, { type: "process", value: [bodyPositionLiveData] }, true);
    }
  }, [matStatus, activityStatus, bodyPositionLiveData]);

  const onCameraReady = useCallback(() => {
    useCameraRef.current = true;
    if (activityStatus <= ACTIVITY_SEQUENCE.ACTIVITY) {
      activeRPPG();
      if (workoutModeRef.current && (activityTypeRef.current === 'hiking')) activatePositionDetection({ isDetectionDelayed: false });
    }
  }, [activityStatus]);

  useEffect(() => {
    if(!mHitWorker.current){
      WebWorkerUtil.makeWorker(WORKER_ID.HIT, onResponseHitWorker, true).then((workerId) => {
        mHitWorker.current = workerId;
        engineHandler('init');
      });
    }
    if (!mXFitWorker.current && panelInfo && panelInfo.subType === 'hiking') {
      WebWorkerUtil.makeWorker(WORKER_ID.XFIT, onResponseXFitWorker, true).then((workerId) => {
        mXFitWorker.current = workerId;
      });
    }

    return () => {
      WebWorkerUtil.terminate(mHitWorker.current);
      WebWorkerUtil.terminate(mXFitWorker.current);
      deActiveRPPG();
      deActivatePositionDetection();
      gestureRelease();
      dispatch(changeMatStatus(MAT_STATUS.PLAY)); // reset
      dispatch(changeThemeModeStatus("light"));
      endWalking();
      clearJob();
      dispatch(updateMaxCameraBpm(maxCameraBpmRef.current));
      dispatch(updateSettings({ concede: Config.CES_DEFAULT_CONCADE }));
    }
  }, []);

  const popupClickHandler = useCallback((status) => () => {
    switch (status) {
      case ACTIVITY_SEQUENCE.INIT:
        gestureRelease();
        engineHandler('init');
        startWalking(WALKING_MODE.init);
        break;
      case ACTIVITY_SEQUENCE.ACTIVITY:
        if (videoEnd) {
          onWalkingEnd();
        } else if (activityStatus === ACTIVITY_SEQUENCE.SCENERY_READY) {
          onVideoPause();
        } else {
          setActivityStatus(ACTIVITY_SEQUENCE.ACTIVITY);
        }
        break;
      case ACTIVITY_SEQUENCE.SCENERY:
      case ACTIVITY_SEQUENCE.REPORT:
        setActivityStatus(status);
        break;
      default:
        closePopup();
        break;
    }

    if (activityStatus !== status) {
      if (status > ACTIVITY_SEQUENCE.SCENERY_READY) {
        dispatch(changeThemeModeStatus('dark'));
      } else if (activityStatus === ACTIVITY_SEQUENCE.END && status === ACTIVITY_SEQUENCE.INIT) {
        dispatch(changeThemeModeStatus("light"));
      }
    }
  }, [stepperNum, activityStatus, videoEnd, startWalking]);

  const settingPopupHandler = useCallback((key, value) => {
    if (matStatus !== MAT_STATUS.PAUSE) { // Popup Open
      onVideoPause();
    } else if (matStatus === MAT_STATUS.PAUSE && !(key && value === undefined)) { // Popup Close
      onVideoPause(false);
    }

    if (key) {
      if (value !== undefined) {
        if (key === SETTING_POPUP.RATIO_SETTING && value) {
          cancelGetEventNotification();
          engineHandler('xFit', value);
        }
        setSettingPopup('');
      } else {
        setSettingPopup(key);
      }
    } else {
      setSettingPopup('');
    }
  }, [engineHandler, matStatus]);

  const changeWorkoutMode = useCallback((index) => {
    let bool = Boolean(index);

    if (workoutModeRef.current !== bool) {
      if (bool) {
        settingPopupHandler(SETTING_POPUP.RATIO_SETTING);
      } else {
        deActivatePositionDetection();
      }

      workoutModeRef.current = bool;

      engineHandler('init');
      engineHandler('reset');
      engineHandler('xFit');
    }
  }, []);


  const closePopup = useCallback(() => {
    dispatch(popPanel());
  }, [dispatch]);

  // scenery, activity end check
  useEffect(() => {
    if (matStatus === MAT_STATUS.PLAY) {
      const nextSpotIndex = stepperNum - 1;

      if (contentInfo && Array.isArray(contentInfo.hotSpot) && contentInfo.hotSpot[nextSpotIndex] && nextSpotIndex !== spotIndex) {
        onVideoPause();
        setSpotIndex(nextSpotIndex);
        setActivityStatus(ACTIVITY_SEQUENCE.SCENERY_READY);
      } else if (videoEnd && activityStatus < ACTIVITY_SEQUENCE.END) {
        onWalkingEnd();
      }
    }
  }, [contentInfo, activityStatus, stepperNum, videoEnd]);

  const formatUnit = useCallback((distance, digits) => {
    /** 소수점 표기 방식
     * 거리(km): 소수점 둘째자리
     * 높이(m): 소수점 미표기
     */

    if (activityTypeRef.current === 'hiking' && !digits) {
      return Math.round(distance * 1000);
    }

    return Number(distance.toFixed(digits || 2));
  }, []);

  const controllerVisible = useMemo(() => {
    return (matStatus === MAT_STATUS.PAUSE && activityStatus === ACTIVITY_SEQUENCE.ACTIVITY && !videoEnd);
  }, [matStatus, activityStatus, videoEnd]);

  useEffect(() => {
    if (activityStatus > ACTIVITY_SEQUENCE.ACTIVITY) {
      clearJob();
    }

    if (bleConnectStatus !== 'connected' && activityStatus < ACTIVITY_SEQUENCE.SCENERY) {
      dispatch(popPanel());
    }
  }, [bleConnectStatus, activityStatus]);

  const movePreviewPage = useCallback(() => {
    dispatch(popPanel());
    dispatch(addPanels({ name: Config.panel_names.AI_MANAGER_FEEDBACK, panelInfo: { enterFrom: panelInfo.enterFrom, reportData: reportDataRef.current.feedback } }))
    // dispatch(popPanel(Config.panel_names.BODY_BALANCE_REPORT));
  }, []);

  const backKeyHandler = useCallback(() => {
    if (cesShowMode) {
      dispatch(popPanel());
    }
  }, []);

  /** ces hotkey */
  const handleKeydown = useCallback((ev)=>{
    if (activityStatus <= ACTIVITY_SEQUENCE.ACTIVITY) {
      const keyCode = ev.keyCode;
      const SCAN_KEY = 403;
      if (keyCode === SCAN_KEY) {
        goScenery();
      };
    }
	}, [activityStatus])

  const guideSignMsg = useMemo(() => {
    if (matStatus === MAT_STATUS.PLAY && activityStatus === ACTIVITY_SEQUENCE.ACTIVITY) {
      if (bpmWarningText.maxText || bpmWarningText.warnText) {
        return { msg: bpmWarningText.maxText || bpmWarningText.warnText };
      } else if (workoutModeRef.current && showHigherMsg.current === true && (walkOutput.validLCount + walkOutput.invalidLCount + walkOutput.validRCount + walkOutput.invalidRCount) > 0) {
        return { voice: "A1", msg: $L("Please raise your knees more.") };
      }
    }

    return null;
  }, [bpmWarningText, matStatus, activityStatus]);

  const roundBpm = useMemo(() => {
    const ret = bpm ? Math.round(bpm) : 0;
    return ret && ret !== 0 ? `<span>${ret}</span> BPM` : `<span>···</span>`;
  }, [bpm]);

  useEffect(() => {
    on("keydown", handleKeydown);
    return () => {
      off("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  return (
    <TPanel handleCancel={backKeyHandler} {...rest}>
      {matStatus === MAT_STATUS.STOP && activityStatus <= ACTIVITY_SEQUENCE.ACTIVITY && timer < 0 ? <div className={classNames(css.overlay, css.readyPanel)}>
        <div className={css.box}>
          {/* <div className={css.title}>{activityTypeRef.current === 'hiking' ? $L("Are you ready to hike?") : $L("Are you ready to go for a walk?")}</div> */}
          <div className={css.title}>{
            (cesShowMode && activityTypeRef.current === 'hiking') ?
            <>
            <div>{$L("Please, Raise your knees as high as 90 degrees as possible.")}</div>
            <div>{$L("It helps strengthen your lower body strength.")}</div>
            </>
            :
            activityTypeRef.current === 'hiking' ? $L("Are you ready to hike?") : $L("Are you ready to go for a walk?")
          }</div>
          <div className={css.countdown}>{timer * -1}</div>
        </div>
      </div> : null}
      {activityStatus < ACTIVITY_SEQUENCE.SCENERY && <TStepper
        order={stepperOrder}
        number={stepperNum}
        percent={progressPercent}
        type={activityTypeRef.current === 'hiking' ? 'vertical' : 'horizontal'}
        isReverse={activityTypeRef.current === 'hiking'}
        useStartFinish
      />}
      {guideSignMsg && <TestGuideSignMsg voice={guideSignMsg.voice} message={guideSignMsg.msg} />}
      {activityStatus <= ACTIVITY_SEQUENCE.SCENERY && <AudioPlayer srcTypeStr={getLocalServerUrl((activityStatus === ACTIVITY_SEQUENCE.SCENERY ? contentInfo.sceneryBgm : contentInfo.bgm), serverType, localURL)} loop volume={contentInfo.volume || Config.BODY_TEST_BG_VOLUME}/>}
      {activityStatus === ACTIVITY_SEQUENCE.SCENERY ?
      <>
      <VRPlayer src={getLocalServerUrl(videoInfo.playUrl, serverType, localURL)} mode={panelInfo.contentId === 6 ? MODES.time : MODES.vr} delta={0.2} className={css.sceneryPlayer}/>
      <Container className={css.sceneryContainer}>
        <div className={css.spotBtnBottomBg}>
          <div className={css.title}>{$L(contentInfo.title)}</div>
          <div className={css.btnWrapper}>
            {(cesShowMode && panelInfo.preview) ?
            <TButton size={SIZES.xLarge} onClick={movePreviewPage}>{$L("Finish")}</TButton>
            :
            <>
            <TButton size={SIZES.xLarge} disabled>{$L("Go to the course")}</TButton>
            <TButton size={SIZES.xLarge} onClick={popupClickHandler(ACTIVITY_SEQUENCE.ACTIVITY)}>{$L("Next course")}</TButton>
            </>
            }
          </div>
        </div>
      </Container>
      </>
      :
      <>
      <SpottableComponent onClick={onVideoPause} className={classNames(css.playerContainer, css[activityTypeRef.current])} spotlightDisabled={controllerVisible || activityStatus !== ACTIVITY_SEQUENCE.ACTIVITY}>
        <TShakaPlayer
          playerRef={playerRef}
          src={getLocalServerUrl(videoInfo.playUrl, serverType, localURL)}
          playerConfig={PLAYER_CONFIG}
          onLoadedMetadata={onVideoLoadedMetadata}
          onLoadedData={onVideoLoadedData}
          onProgress={onVideoProgress}
          onEnded={onVideoEnd}
          muted={true}
        />
        <div className={classNames(css.borderBox, css.contentInfo, activityStatus >= ACTIVITY_SEQUENCE.SCENERY && css.hidden, css[activityTypeRef.current])}>
          <div className={css.contentInfoBox}>
            <Marquee className={css.title} marqueeOn={"render"}>{$L(contentInfo.title)}</Marquee>
            {activityTypeRef.current !== 'hiking' && <div className={css.distance}><span className={css.curDistance}>{formatUnit(progressDistance)}</span><span className={css.totalDistance}> / {formatUnit(contentInfo.distance)}Km</span></div>}
            {activityTypeRef.current && <TTrack
              type={TRACK_TYPE[contentInfo.trackType]}
              percentage={progressPercent}
              guidePercentage={(recommendSpeed[activityTypeRef.current] * (timer / 3600)) / contentInfo.distance * 100}
              isAnimate={matStatus === MAT_STATUS.PLAY}
              className={css.track}
              hotSpot={contentInfo.hotSpotPercent}
              scale={activityTypeRef.current === 'hiking' ? 0.86 : 1}
              // width={activityTypeRef.current === 'hiking' ? 230 : 268}
              // height={activityTypeRef.current === 'hiking' ? 127 : 147}
            />}
            {activityTypeRef.current === 'hiking' ?
            <div className={css.distance}><span className={css.curDistance}>{formatUnit(progressDistance)}m</span><span className={css.totalDistance}> / {formatUnit(contentInfo.distance)}m</span></div>
            :
            <div className={css.curSpeed}>{walkOutput.velocity}Km/h</div>
            }
          </div>
          {activityTypeRef.current !== 'hiking' && <>
          <div className={css.guideSpeed}><span>{$L("Recommended speed")}</span> {recommendSpeed[activityTypeRef.current]}Km/h</div>
          {/* <hr/>
          <Container className={css.controlVelocity}>
            <TIconButton className={css.withMargin} iconType="minus" size={SIZES.tiny} color={COLOR.black} disabled={curVelocity <= 0.5} onClick={velocityHandler(0.5)}/>
            <div>{convertNewlinesToBr($L("걸음/영상{br}속도 맞춤"))}</div>
            <TIconButton className={css.withMargin} iconType="plus" size={SIZES.tiny} color={COLOR.black} disabled={curVelocity >= 10} onClick={velocityHandler(-0.5)}/>
          </Container> */}
          </>}
        </div>
        {activityStatus <= ACTIVITY_SEQUENCE.ACTIVITY && <div className={classNames(css.bodyInfo)}>
          <div className={classNames(css.borderBox, css.bpm)}>
                <div className={css.bpmLayer}>
                  <img src={ic_bpm} alt="" />
                    <div dangerouslySetInnerHTML={{ __html: roundBpm }} />
                </div>
                <div className={css.bpmStatus}>{bpmStatus}</div>
          </div>
          <div className={classNames(css.borderBox, css.userBox, activityTypeRef.current !== 'hiking' && css.hidden, (!matchedDelay.current && activityStatus === ACTIVITY_SEQUENCE.ACTIVITY && matStatus === MAT_STATUS.PLAY) && css.warning)}>
            {showPIPCamera && activityStatus <= ACTIVITY_SEQUENCE.SCENERY_READY && (
              <div className={css.user}>
                <PIPCamera
                  webcamRef={webcamRef}
                  size={"medium"}
                  onCameraReady={onCameraReady}
                />
                {activityTypeRef.current === 'hiking' && workoutModeRef.current && <BaseLine kneeGuideLineStyle={kneeGuideLineStyle} currentExerciseCounter={matchedCount.current} matchingTime={MAT_DELAY_TIME} size='small'/>}
                {workoutModeRef.current && <div className={css.baselineGuideMsg}>{convertNewlinesToBr($L("Raise your knees{br}to a {angle}-degree angle").replace("{angle}", concedeTextToNumber[concede]))}</div>}
              </div>
            )}
            <div className={css.stepBox}>
              <div className={css.step}><span className={css.stepNum}>{walkOutput.validLCount + walkOutput.validRCount}</span><span> STEP</span></div>
              <hr/>
              <div className={css.totalCount}>{$L("Total")} <span>{(walkOutput.validLCount + walkOutput.invalidLCount) + (walkOutput.validRCount + walkOutput.invalidRCount)} STEP</span></div>
            </div>
          </div>
          {activityTypeRef.current !== 'hiking' && <>
            <div ref={footRef} className={classNames(css.borderBox, css.footprint)}>
              <div className={css.footImg}>
                <img src={ic_foot_guide_left_match_s} alt='' className={css.active}/>
                <img src={ic_foot_guide_left_s} alt=''/>
              </div>
              <img src={border_dot} className={css.footBorder}/>
              <div className={css.footImg}>
                <img src={ic_foot_guide_right_match_s} alt='' className={css.active}/>
                <img src={ic_foot_guide_right_s} alt=''/>
              </div>
            </div>
            <div className={classNames(css.borderBox, css.etc)}>
              <div className={css.step}><span className={css.stepNum}>{(walkOutput.validLCount + walkOutput.invalidLCount) + (walkOutput.validRCount + walkOutput.invalidRCount)}</span><span> STEP</span></div>
              <hr/>
              <div className={css.etcBottom}>
                <div className={css.kcal}><img src={ic_inhome_walk_kcal} alt=''/><span>{calorieExpenditure}Kcal</span></div>
                <div><img src={ic_inhome_walk_time} alt=''/><span>{transTimeSecToText(Math.max(0, exerciseTime), true)}</span></div>
              </div>
            </div>
          </>}
          {cesShowMode && activityTypeRef.current === 'hiking' &&
            <div style={{ textAlign: 'center' }}><TButton withMarquee={true} onClick={goScenery} className={classNames(css.btn, css.finishBtn)} size={SIZES.large}>{$L("360° View")}</TButton></div>
          }
        </div>}
      </SpottableComponent>
      {controllerVisible && <ContentControllerModal
        playerRef={playerRef}
        currentTime={videoCurrentTime}
        duration={playerRef?.current.getDuration() || 0}
        onPause={onVideoPause}
        onSeek={onVideoSeek}
        onClose={closePopup}
      >
        {activityTypeRef.current === 'hiking' && <>
        <TButton type={TYPES.withIcon} icon='hiking' withMarquee={true} className={css.btn} disabled><span/>{$L("Go to the course")}</TButton>
        <TButton type={TYPES.withIcon} icon='setting' withMarquee={true} className={classNames(css.btn, css.selectAngle)} disabled={!workoutModeRef.current} onClick={() => settingPopupHandler(SETTING_POPUP.RATIO_SETTING)}><span/>{$L('Workout mode')}<span>{concedeTextToNumber[concede]}°</span></TButton>
        </>}
      </ContentControllerModal>}
      {(activityTypeRef.current === 'hiking' && activityStatus === ACTIVITY_SEQUENCE.ACTIVITY) && <Container className={classNames(css.settingButtons, themeMode === 'dark' && css.isDark)}>
        <ToggleButton
          type={TOGGLE_TYPES.activity}
          label={[$L("Off"), $L("On")]}
          isRightSelected={workoutModeRef.current}
          onClick={changeWorkoutMode}
          className={css.workoutToggle}
        />
        <TButton type={TYPES.withIcon} icon='setting' withMarquee={true} className={classNames(css.btn, css.selectAngle)} disabled={!workoutModeRef.current} onClick={() => settingPopupHandler(SETTING_POPUP.RATIO_SETTING)}><span/>{$L('Workout mode')}<span>{concedeTextToNumber[concede]}°</span></TButton>
      </Container>}
      </>}
      {activityStatus === ACTIVITY_SEQUENCE.END && <WalkingEndPopup open onClick={popupClickHandler} onClose={closePopup}/>}
      {activityStatus === ACTIVITY_SEQUENCE.REPORT && <WalkingReportPopup open contentInfo={contentInfo} reportData={reportDataRef.current} onClick={popupClickHandler} onClose={closePopup}/>}
      {activityStatus === ACTIVITY_SEQUENCE.SCENERY_READY && <GesturePopup onClick={popupClickHandler}/>}
      {settingPopup === SETTING_POPUP.RATIO_SETTING && <RatioSettingPopup open onClick={settingPopupHandler} onClose={settingPopupHandler}/>}
    </TPanel>
  );
}

export default ActivityPlayer;