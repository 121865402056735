import classNames from 'classnames';
import css from './BarItem.module.less';

import { useEffect, useState, useMemo } from 'react';

import { $L } from '../../../../../utils/helperMethods';

import { useCallback } from 'react';

const BarItem = ({ my = 0, progress, className, ...rest }) => {
  // const [my, setMy] = useState(0);
  // const [length, setLength] = useState(0);

	// useEffect(() => {
	// 	setMy(my)
	// }, [my]);

  const weekText = useCallback((week)=>{
    if (week === 0) {
      return $L("Start");
    }
    return $L("{week}W").replace('{week}', week);
},[]);

  const WEEKLIST = useMemo(() => {
    return [
      {id: 0, title: weekText(0)},
      {id: 1, title: weekText(1)},
      {id: 2, title: weekText(2)},
      {id: 3, title: weekText(3)},
      {id: 4, title: weekText(4)},
    ]
  }, []);


	return (
    <div className={classNames(css.bar, className ? className : null)}>
      <div className={css.dotStart} />
      <div className={css.dotEnd} />

			<div className={css.fixedBar}/>
      {/* progress */}
      {progress && (
        <div
          className={classNames(css.programBar, progress >= 100 && css.border)}
          style={{ width: `${progress}%` }}
        >
          <div className={classNames(css.marker, css.programMarker)}>
            <span>{$L("Progress")}</span>
            <span>{`${progress}%`}</span>
          </div>
        </div>
      )}
      {/* my */}
      <div
        className={classNames(css.myBar, my >= 100 && css.border)}
        style={{ width: `${my}%` }}
      >
        <div className={classNames(css.marker, css.myMarker)}>
          <span>{$L("My")}</span>
          <span>{`${my}%`}</span>
        </div>
      </div>
      {/* week */}
      <div className={css.weekList}>
        {WEEKLIST.map((item, index) => (
          <div key={item.id} className={css.item}>
            <div className={classNames(
              css.divider,
              (index === 0 || index === 4) && css.none, 
            )}/>
            <div className={classNames(css.title, index <= Math.floor(my / 25) && css.complete)}>{item.title}</div>
          </div>
        ))}
      </div>
      {/* circle */}
      {/* <div className={css.circle} />
      <div className={css.circle}/> */}
		</div>
	)
}

export default BarItem