import classNames from "classnames";
import css from "./Question.module.less";

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { setToggle } from "../../../features/survey/surveySlice";

import SDropdown from "./SDropdown/SDropdown";
import SNone from "./SNone/SNone";
import SCheckbox from "./SCheckbox/SCheckbox";
import SRadio from "./SRadio/SRadio";
import SSlider from "./SSlider/SSlider";
import TIconButton, {ICON_TYPES, SIZES, COLOR} from "../../../components/TIconButton/TIconButton";

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

/**
 *
 * @param {type} 'mainQuestion', 'subQuestion'
 * @returns
 */

const Question = ({ question, className, articles, type="mainQuestion", ...rest }) => {
  const dispatch = useDispatch();
  const { profileNick } = useSelector((state) => state.common.appStatus);
  const toggle = useSelector((state) => state.survey.toggle);

  const onClickToggleBtn = useCallback((id) => (e) => {
		dispatch(setToggle({...toggle, [id]: !toggle[id]}));
  }, [dispatch, toggle]);

	return (
    <>
      {question &&
      <Container
        className={classNames(css.question, css[question.id], css[question.direction], css[question.answer_form], css[type], className)}
        {...rest}
      >
        <div className={css.title}>{question.title.replace('[고객명]', profileNick)}</div>
        {(() => {
          switch (question.answer_form) {
            case "dropdown": return (<SDropdown question={question}/>);
            case "radio": return ( <SRadio question={question}/>);
            case "checkbox": return (<SCheckbox question={question}/>);
            case "slider": return ( <SSlider question={question}/>);
            case "none": return (<SNone question={question}/>);
            default: return null;
          }
        })()
        }
        {/* sub question */}
        {articles && articles.length > 0 && articles.map((article) => (article.sub_survey &&
          <Container key={question.id + article.article_id} className={css.article}>
            <div className={css.title}>
              <TIconButton
                className={css.icon}
                iconType={toggle[article.article_id] ? ICON_TYPES.minus : ICON_TYPES.plus}
                size={SIZES.tiny}
                color={COLOR.black}
                onClick={onClickToggleBtn(article.article_id)}
              />
              {article.sub_title && <div>{article.sub_title}</div>}
            </div>
            {toggle[article.article_id] && (
            <div className={css.questionContainer}>
              {article.sub_survey.map((question) => (
                <Question key={'subQuestion_'+question.id} question={question} type="subQuestion" />
              ))}
            </div>
            )}
          </Container>
			  ))}
      </Container>
      }
    </>
	);
};

export default Question;