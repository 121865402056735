import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import css from "./CoordinatePlane.module.less";
import { $L } from "../../utils/helperMethods";
import AlertCircleFull from "../../../assets/icon/alert_circle_full.svg";

const CoordinatePlane = ({
  balanceRatioXData,
  balanceRatioYData,
  style = {},
  totalLength,
}) => {
  const {
    bbtCoordinatePlaneWrapStyle,
    bbtCoordinatePlaneStyle,
    bbtInfoWrapStyle,
  } = style;

  // leftValue + rightValue=1, frontValue + rFrontValue=1
  const leftValue = (balanceRatioXData + 1) / 2;
  const rightValue = 1 - leftValue;
  const frontValue = (balanceRatioYData + 1) / 2;
  const rFrontValue = 1 - frontValue;

  const leftPercentage = Math.round(leftValue * 100);
  const rightPercentage = Math.round(rightValue * 100);
  const frontPercentage = Math.round(frontValue * 100);
  const rFrontPercentage = Math.round(rFrontValue * 100);

  const percentageArray = [
    leftPercentage,
    frontPercentage,
    rightPercentage,
    rFrontPercentage,
  ];
  const percentageMax = Math.max(...percentageArray);
  const directionArray = [
    $L("left"),
    $L("forward"),
    $L("right"),
    $L("backward"),
  ];

  const renderDirection = useCallback(() => {
    switch (percentageMax) {
      case leftPercentage:
        return directionArray[0];
      case frontPercentage:
        return directionArray[1];
      case rightPercentage:
        return directionArray[2];
      case rFrontPercentage:
        return directionArray[3];
      default:
        return $L("Your body balance is good");
    }
  }, [percentageArray]);

  const infoCont = $L(
    `The center of the body is tilted {direction} by <span>{percentageMax}%</span>.`
  )
    .replace("{direction}", renderDirection())
    .replace("{percentageMax}", percentageMax);

  return (
    <>
      <div
        className={css.coordinatePlaneWrap}
        style={{
          width: `${totalLength}px`,
          height: `${totalLength}px`,
          ...(style && bbtCoordinatePlaneWrapStyle),
        }}
      >
        <div
          className={css.coordinatePlane}
          style={{
            width: `${totalLength}px`,
            height: `${totalLength}px`,
            ...(style && bbtCoordinatePlaneStyle),
          }}
        >
          <div className={css.xAxis}>
            <div
              className={classNames(css.direction, css.directionLeft)}
              style={{ right: `${totalLength + 7}px` }}
            >
              {$L("Left")}
              <div className={classNames(css.value, css.valueLeft)}>
                {leftPercentage}%
              </div>
            </div>
            <div
              className={classNames(css.direction, css.directionRight)}
              style={{ left: `${totalLength + 7}px` }}
            >
              {$L("Right")}
              <div className={classNames(css.value, css.valueRight)}>
                {rightPercentage}%
              </div>
            </div>
          </div>
          <div className={css.yAxis}>
            <div className={classNames(css.direction, css.directionForward)}>
              {$L({ key: "direction_Front", value: "Forward" })}
              <div className={css.valueFB}>
                <div className={classNames(css.value)}>{frontPercentage}%</div>
              </div>
            </div>
            <div className={classNames(css.direction, css.directionBack)}>
              {$L({ key: "direction_Back", value: "Back" })}
              <div className={css.valueFB}>
                <div className={classNames(css.value)}>{rFrontPercentage}%</div>
              </div>
            </div>
          </div>
          <div
            className={css.point}
            style={{ left: `${rightPercentage}%`, top: `${rFrontPercentage}%` }}
          >
            <div className={css.pointText}>{$L("Center of Gravity")}</div>
          </div>
        </div>
      </div>
      <div className={css.infoWrap} style={style && bbtInfoWrapStyle}>
        <img src={AlertCircleFull} alt="" />
        <div
          className={css.info}
          dangerouslySetInnerHTML={{ __html: infoCont }}
        />
      </div>
    </>
  );
};

export default CoordinatePlane;
