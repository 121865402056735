export const ADAPTER_ADDRESS = "4c:bc:e9:29:17:0d";
export const ADDRESS = "de:04:50:6e:60:c7";
export const DEFAULT_INTERVAL = 2000;

const decimalToLittleEndian =(decimalValue,size=2) => {
    const byteArray = [];

    // 32비트 바이트 배열에 값을 설정
    byteArray[0] = decimalValue & 0xFF;
    if(size > 1){
        byteArray[1] = (decimalValue >> 8) & 0xFF;
    }
    if(size > 2){
        byteArray[2] = (decimalValue >> 16) & 0xFF;
    }
    if(size > 3){
        byteArray[3] = (decimalValue >> 24) & 0xFF;
    }
    return byteArray;
}

  //mat excel 표의 소수점 데이터 변환용
  //실제값이 times 를 곱한뒤 hex 포맷으로 전송
export const convertFeaturesFromFloat = (size=[], data=[], times=1000) => {
    const ret = [];
    for(let i=0; i<data.length; i++){
        let sizeValue = 1;
        if(Array.isArray(size)){
            sizeValue = size[i];
        }else{
            sizeValue = size;
        }
        ret.push(...decimalToLittleEndian(Math.floor(data[i] * times), sizeValue));
    }
    return ret;
}