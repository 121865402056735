import React, { useEffect, useCallback } from "react";
import TMediaVerticalList from "../../../../components/TMediaVerticalList";
import dummyProductList from "../../../../../assets/mock/dummyProductList.json";

const TMediaVerticalListLayout = () => {
	const onItemClick = useCallback(({index, itemInfo, ev})=>{
		console.log('TMediaVerticalListLayout onItemClick', index, itemInfo, ev);
	},[]);

	return (
		<>
			<TMediaVerticalList
				products={dummyProductList.data}
				onItemClick={onItemClick}
				//width: 원소의 가로, heigh: 원소의 세로, gap: 원소 사이의 거리, rowCount: 가로에 들어가는 원소의 최대 개수, colCount: 세로에 들어가는 원소의 최대 개수
				itemLayout={{width: 600, height: 800, gap: 0, rowCount: 4, colCount: 2}}
			/>
		</>
	);
};

export default TMediaVerticalListLayout;