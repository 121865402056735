import classNames from 'classnames';
import css from './ToolTip.module.less';

const POSITION = { bottom: 'bottom', top: 'top'};

const ToolTip = ({ className, position='bottom', title, description, ...rest}) => {

	return (
		<div
			className={classNames(
				css.toolTip,
				css[position],
				className
			)}
			{...rest}
		>
			<div className={css.arrow}/>
			<div className={css.title}>{title}</div>
			<div className={css.description}>{description}</div>
		</div>
	);
};


export default ToolTip;
export { POSITION };
