import React, { useState, useEffect, useRef } from "react";
import classnames from 'classnames';
import ri from '@enact/ui/resolution';
import css from "./ScanAnimator.module.less";

const ScanAnimator = () => {
    const bottomimageRef = useRef(null);
    const scanImageRef = useRef(null);

    return (
        <div className={css.base}>
            <div ref={scanImageRef} className={css.image_scan}/>
        </div>
    );
};

export default ScanAnimator;
