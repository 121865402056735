import css from "./Activity.module.less";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { $L, transSecondsToHMS, numberWithCommas, transNumToDayText } from "../../../../utils/helperMethods";
import * as Config from "../../../../utils/Config";
import TGGraph from "../../../../components/TGGraph/TGGraph";

import ICON_DAY from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_day.png"
import ICON_TIME from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_time.png"
import ICON_CALORIE from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_calorie.png"

const getActivityData = (itemInfo) => {
  switch (itemInfo.id) {
    case "date":
      return {
        title: $L("Number of days you worked out"),
        data: transNumToDayText(itemInfo.data),
        progress: Math.floor(itemInfo.data / itemInfo.goal * 100),
        icon: ICON_DAY,
        commentTitle: $L("You have been exercising regularly."),
        comment: $L(
          "You've worked out regularly. Working out 20 days or more a month will increase your physical strength by 20%."
        ),
      };
    case "time":
      return {
        title: $L("Workout Time"),
        data: transSecondsToHMS(itemInfo.data, false, true),
        progress: Math.floor(itemInfo.data / itemInfo.goal * 100),
        icon: ICON_TIME,
        commentTitle: $L("You exercised less than your goal."),
        comment: $L("The workout time is short compared to the total workout time. Why don't you improve the effect of your workout by increasing the workout time a little?"),
      };
    case "calorie":
      return {
        title: $L("Calories burned"),
        data: `${numberWithCommas(itemInfo.data)} Kcal`,
        progress: Math.floor(itemInfo.data / itemInfo.goal * 100),
        icon: ICON_CALORIE,
        commentTitle: $L("You did a vigorous workout."),
        comment: $L("These are high-intensity workouts that don't strain the body. Burning a lot of calories helps you lose weight."),
      };
    default:
      return {};
  }
};

const Activity = ({className, itemInfo, ...rest }) => {
  const activityData = useMemo(() => {
    return getActivityData(itemInfo);
  }, [itemInfo]);

  return (
    <div
      className={classNames(
        className,
        css.activity,
      )}
      {...rest}
    >
      <div className={css.dataContainer}>
        <div className={css.graphBox}>
          <TGGraph
            className={css.graph}
            size={104}
            lineWidth={14}
            primaryPercentage={activityData.progress}
            isDark={true}
          />
          <div className={css.icon} style={{backgroundImage: `url(${activityData.icon})`}}/>
        </div>
        <div className={css.info}>
          <div className={css.title}>{activityData.title}</div>
          <div className={css.data}>{activityData.data}</div>
        </div>
      </div>
      <div className={css.hr} />
      <div className={css.tip}>{$L("Coaching tips")}</div>
      <div className={css.commentTitle}>{activityData.commentTitle}</div>
      <div className={css.comment}>{activityData.comment}</div>



    </div>
  );
};

export default Activity;