import React, { useCallback, useMemo } from "react";
import classNames from 'classnames';
import css from "./BodyBalanceResultsReport.module.less";
import { $L, convertNewlinesToBr } from "../../../../../../utils/helperMethods";
import BodyBalanceTabResults from "../BodyBalanceTabResults";
import CoPResults from "../CoPResults";
import FootTypeResults from "../FootTypeResults";

import TCanvasMatRaw from "../../../../../../components/TCanvasMatRaw/TCanvasMatRaw";
import AlertCheck from "../../../../../../../assets/icon/alert_check.svg";
import { bodyBalanceMenu } from "../../Constants";

const BodyBalanceResultsReport = ({
  date,
  currentData,
  tabType,
  bodyBalanceResultsData,
}) => {
  const tabInfo = useMemo(() => {
    const infoDesc = bodyBalanceResultsData.infoDesc;
    const PrescriptionDesc = bodyBalanceResultsData.PrescriptionDesc;
    const leftFoot = bodyBalanceResultsData.leftFoot;
    const leftFootInfo = bodyBalanceResultsData.leftFootInfo;
    const rightFoot = bodyBalanceResultsData.rightFoot;
    const rightFootInfo = bodyBalanceResultsData.rightFootInfo;
    return {
      infoDesc,
      PrescriptionDesc,
      leftFoot,
      leftFootInfo,
      rightFoot,
      rightFootInfo,
    };
  }, [bodyBalanceResultsData]);

  const twolegTestResultsRawData = useMemo(()=>currentData?.twolegstance?.rawData, [currentData]);
  const onelegsTestResultsRawData = useMemo(()=>currentData?.onelegstanceR?.rawData, [currentData]);

  const renderResponseBox = useCallback(() => {
    switch (tabType) {
      case bodyBalanceMenu.COP:
        return (
          <div className={css.responseBox}>
            <TCanvasMatRaw
              rawData={onelegsTestResultsRawData}
              width={40}
              height={20}
              date={date}
              type="oneleg"
            />
          </div>
        );
      case bodyBalanceMenu.FOOT_TYPE:
      case bodyBalanceMenu.BODY_BALANCE_TAB:
        return (
          <div className={css.responseBox}>
            <TCanvasMatRaw
              rawData={twolegTestResultsRawData}
              width={40}
              height={20}
              date={date}
              type="twoleg"
            />
          </div>
        );
      default:
        return null;
    }
  }, [tabType, onelegsTestResultsRawData, twolegTestResultsRawData, date]);

  const renderCoordinates = useCallback(() => {
    switch (tabType) {
      case bodyBalanceMenu.COP:
        return <CoPResults currentData={currentData} />;
      case bodyBalanceMenu.BODY_BALANCE_TAB:
        return <BodyBalanceTabResults currentData={currentData} />;
      case bodyBalanceMenu.FOOT_TYPE:
        return <FootTypeResults currentData={currentData} />;
      default:
        return null;
    }
  }, [tabType, currentData]);

  return (
    <div className={css.report}>
      <div className={css.leftSect}>
        {renderResponseBox()}
        <div className={css.resultInfoArea}>
          <div className={css.infoDesc}>
            {convertNewlinesToBr(tabInfo?.infoDesc)}
          </div>
          {(tabType === bodyBalanceMenu.FOOT_TYPE ||
            tabType === bodyBalanceMenu.COP) && (
            <div className={css.footWrap}>
              <div className={css.FootInner}>
                <div className={css.footTit}>
                  {convertNewlinesToBr(tabInfo?.leftFoot)}
                </div>
                <div className={css.footInfo}>
                  {convertNewlinesToBr(tabInfo?.leftFootInfo)}
                </div>
              </div>
              <div className={css.FootInner}>
                <div className={css.footTit}>
                  {convertNewlinesToBr(tabInfo?.rightFoot)}
                </div>
                <div className={css.footInfo}>
                  {convertNewlinesToBr(tabInfo?.rightFootInfo)}
                </div>
              </div>
            </div>
          )}
          {/* {tabType === bodyBalanceMenu.BODY_BALANCE_TAB && (
            <div>
              <div className={css.PrescriptionTitArea}>
                <img src={AlertCheck} alt="" />
                <div className={css.PrescriptionTit}>{$L("Workout prescription")}</div>
              </div>
              <div className={css.PrescriptionDesc}>
                {convertNewlinesToBr(tabInfo?.PrescriptionDesc)}
              </div>
            </div>
          )} */}
        </div>
      </div>
      <div
        className={css.coordinatesWrap}
        style={
          tabType === bodyBalanceMenu.BODY_BALANCE_TAB
            ? {
                width: "414px",
                height: "410px",
                padding: "50px",
                // display: "block",
              }
            : {}
        }
      >
        <div className={css.coordinates}>
          {renderCoordinates()}
        </div>
      </div>
    </div>
  );
};

export default BodyBalanceResultsReport;
