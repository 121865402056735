import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const ReportIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.775 14.5C35.1083 14.5 37.0627 15.3565 38.6382 17.0694C40.2137 18.7824 41.0009 20.8194 41 23.1806C41 23.5972 40.9767 24.0083 40.93 24.4139C40.8833 24.8194 40.8017 25.2185 40.685 25.6111H31.935L29.555 22.0694C29.4383 21.8843 29.275 21.7338 29.065 21.6181C28.855 21.5023 28.6333 21.4444 28.4 21.4444C28.0967 21.4444 27.8223 21.537 27.5768 21.7222C27.3313 21.9074 27.1624 22.1389 27.07 22.4167L25.18 28.0417L23.955 26.2361C23.8383 26.0509 23.675 25.9005 23.465 25.7847C23.255 25.669 23.0333 25.6111 22.8 25.6111H13.315C13.1983 25.2176 13.1167 24.8181 13.07 24.4125C13.0233 24.0069 13 23.6079 13 23.2153C13 20.831 13.7817 18.7824 15.345 17.0694C16.9083 15.3565 18.8567 14.5 21.19 14.5C22.31 14.5 23.3661 14.7199 24.3582 15.1597C25.3503 15.5995 26.2309 16.213 27 17C27.7467 16.213 28.6161 15.5995 29.6082 15.1597C30.6003 14.7199 31.6559 14.5 32.775 14.5ZM27 39.5C26.58 39.5 26.1773 39.4245 25.7918 39.2736C25.4063 39.1227 25.0624 38.8972 24.76 38.5972L15.38 29.2569C15.24 29.1181 15.1117 28.9792 14.995 28.8403C14.8783 28.7014 14.7617 28.5509 14.645 28.3889H22.03L24.41 31.9306C24.5267 32.1157 24.69 32.2662 24.9 32.3819C25.11 32.4977 25.3317 32.5556 25.565 32.5556C25.8683 32.5556 26.1483 32.463 26.405 32.2778C26.6617 32.0926 26.8367 31.8611 26.93 31.5833L28.82 25.9583L30.01 27.7639C30.15 27.9491 30.325 28.0995 30.535 28.2153C30.745 28.331 30.9667 28.3889 31.2 28.3889H39.32L38.97 28.8056L38.62 29.2222L29.205 38.5972C28.9017 38.8981 28.5633 39.1241 28.19 39.275C27.8167 39.4259 27.42 39.5009 27 39.5Z"
        fill={convertThemeColor(iconType)}
      />
    </svg>
  );
};

export default ReportIcon;
