import classNames from 'classnames';
import css from './MySurveyPanel.module.less';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";

import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import Question from '../../SurveyPanel/Question/Question';
import TScroller from '../../../components/TScroller/TScroller';

import * as Config from "../../../utils/Config";
import { changeThemeModeStatus } from "../../../features/common/commonSlice";
import { $L } from '../../../utils/helperMethods';
import { getSurveyData } from './surveyInfo';
import TButton, {SIZES} from '../../../components/TButton/TButton';
import PageInfo from '../../SurveyPanel/PageInfo/PageInfo';
import { popPanel, updatePanel } from '../../../features/panels/panelsSlice';
import { postSurvey } from '../../../features/fitService/fitServiceSlice';


const TYPES = ["basic", "body", "exercise", "life"];
const surveyData = getSurveyData();

const MySurveyPanel = ({panelInfo, ...rest}) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollTo = useRef(null);

  useEffect(() => {
    if (panelInfo.selectedIndex) {
      setSelectedIndex(panelInfo.selectedIndex);
    }
  }, [panelInfo]);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  useEffect(() => {
    if (scrollTo && scrollTo.current) {
      scrollTo.current({ position: { y: 0 }, animate: false });
    }
  }, []);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  const onClickCancel = useCallback((ev) => {
    dispatch(popPanel());
  }, [dispatch]);

  const onClickSave = useCallback((ev) => {
    dispatch(postSurvey());
    dispatch(popPanel());
  }, [dispatch]);


  const onScrollStart = useCallback(() => {
    console.log("jjs", "onScroll start")
  }, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={surveyData[TYPES[selectedIndex]].title} />
      <TBody className={css.body}>
        <TScroller
          className={css.scroller}
          verticalScrollbar={selectedIndex === 0 ? "hidden" : "auto"}
          onScrollStart={onScrollStart}
          cbScrollTo={getScrollTo}
        >
          <div className={classNames(css.innerContainer)}>
            <PageInfo index={selectedIndex} className={css.pageInfo}/>
            <div className={css.buttonArea}>
              <TButton size={SIZES.xLarge} className={css.prevBtn} onClick={onClickCancel}>
                {$L("Cancel")}
              </TButton>
              <TButton size={SIZES.xLarge} className={css.nextBtn} onClick={onClickSave}>
                {$L("Save")}
              </TButton>
            </div>
          </div>
        </TScroller>

      </TBody>
    </TPanel>
  );
};

export default MySurveyPanel;