import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spotlight from "@enact/spotlight";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import Spottable from "@enact/spotlight/Spottable";
import compose from "ramda/src/compose";
import { Marquee, MarqueeController } from "@enact/sandstone/Marquee";
import css from './BriefingDetail.module.less';
import classNames from "classnames";
import { $L, transSecToText } from '../../../utils/helperMethods';
import { addPanels } from '../../../features/panels/panelsSlice';
import { panel_names } from '../../../utils/Config';
import TPanel from '../../../components/TPanel';
import THeader from "../../../components/THeader";
import TBody from '../../../components/TBody';
import TScroller from "../../../components/TScroller/TScroller";
import TButton, { COLOR, SIZES } from '../../../components/TButton/TButton';
import { changeThemeModeStatus } from '../../../features/common/commonSlice';
import dummy_profile from '../../../../assets/Theader/dummy_profile.png';
import dummy_profile_01 from '../../../../assets/images/dummy_profile_01.png';
import dummy_profile_02 from '../../../../assets/images/dummy_profile_02.png';
import dummy_profile_03 from '../../../../assets/images/dummy_profile_03.png';
import dummy_profile_04 from '../../../../assets/images/dummy_profile_04.png';
import dummy_profile_05 from '../../../../assets/images/dummy_profile_05.png';

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");
const SpottableComponent = Spottable("div");

const BriefingDetailBase = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const scrollTo = useRef(null);
  const profileNick = useSelector(state => state.common.appStatus.profileNick);

  //todo
  const profileImage = dummy_profile;
  const ranking = [
    {
      profileNick: 'user1',
      profileImage: dummy_profile_01,
      score: 100,
    },
    {
      profileNick: 'user1',
      profileImage: dummy_profile_02,
      score: 90,
    },
    {
      profileNick: 'user1',
      profileImage: dummy_profile_03,
      score: 80,
    },
    {
      profileNick: 'user1',
      profileImage: dummy_profile_04,
      score: 70,
    },
    {
      profileNick: 'user1',
      profileImage: dummy_profile_05,
      score: 60,
    },
    {
      profileNick: 'user1',
      profileImage: dummy_profile_01,
      score: 50,
    },
    {
      profileNick: 'user1',
      profileImage: dummy_profile_02,
      score: 40,
    },
  ]

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  const gotoWorkoutPlay = useCallback(() => {
    //todo
    dispatch(addPanels({ name: panel_names.CONTENT_PLAYER_START, panelInfo: { contentId: `physicalTest_LOWER_BODY_STR_Pelvic_Shift_5_4` } }));
  }, []);


  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("View briefing")}/>
      <TBody className={css.tBody}>
        <div className={css.topLayer}>
          <img className={css.imgBox} src={panelInfo?.image}></img>
          <div className={css.textBox}>
            <div className={css.title}>{panelInfo?.title}</div>
            <div className={css.subTitle}>{panelInfo?.subTitle}</div>
            <TButton size={SIZES.large} onClick={gotoWorkoutPlay}>{$L("Go to exercise")}</TButton>
          </div>
        </div>

        <div className={css.contentsLayer}>
          <div className={css.leftBox}>
            <span className={css.totalScoreText}>{$L("Total Score")}</span>
            <span className={css.totalScoreNumber}>{panelInfo?.totalScore+$L("points")}</span>
            <div className={css.hr}/>
            {panelInfo?.totalScore === 100
            ?
            <div className={css.perfect}>
              <div className={css.icon}/>
              <div className={css.text}>
                {$L("100점을 기록하셨네요. 대단해요")}
              </div>
              <div className={css.desc}>
                {$L("계속해서 바른 운동 자세로 건강한 몸을 만들어보세요.")}
              </div>
            </div>
            :
            <TScroller className={css.contentScroller}>
              {panelInfo?.minusList.length > 0 && panelInfo?.minusList.map((li, idx) => (
                <div key={`minusList_${idx}`} className={css.minusList}>
                  <img className={css.imgBox} src={li?.capture}></img>
                  <div className={css.textBox}>
                    <div className={css.timeScore}>
                      <div className={css.timeIcon} />
                      <div className={css.time}>{transSecToText(li?.captureTime)}</div>
                      <div className={css.minusScore}>{li?.minusScore}</div>
                    </div>
                    <div className={css.feedback}>{li?.feedback}</div>
                  </div>
                </div>
              ))}
            </TScroller>
            }
          </div>

          <div className={css.rightBox}>
            <div className={css.topRank}>
              <div className={css.crownIcon} />
              <div className={css.crownText}>{$L("Ranking") + $L("TOP 100")}</div>
            </div>
            <TScroller className={css.rankingScroller}>
              <div>
                <div className={classNames(css.ranking, css.myRank)}>
                  <div className={css.rankIndex}>{panelInfo?.rank}</div>
                  <img className={css.profileImg} src={profileImage} />
                  <div className={css.rankInfo}>
                    <div className={css.score}>{panelInfo?.totalScore+$L("points")}</div>
                    <Marquee className={css.profileNick} marqueeOn={"focus"}>
                      {profileNick || "ces 2024"}
                    </Marquee>
                  </div>
                </div>
                {ranking.length > 0 && ranking.map((rank, idx) => (
                  <div key={`rank_${idx}`} className={css.ranking}>
                    <div className={classNames(css.rankIndex, idx < 3 ? css.top : null)}>{idx + 1}</div>
                    <img className={css.profileImg} src={rank?.profileImage} />
                    <div className={css.rankInfo}>
                      <div className={css.score}>{rank?.score+$L("points")}</div>
                      <Marquee className={css.profileNick} marqueeOn={"focus"}>
                        {rank?.profileNick}
                      </Marquee>
                    </div>
                  </div>
                ))}
              </div>
            </TScroller>
          </div>
        </div>

      </TBody>
    </TPanel>
  );
};

const BriefingDetailDecorator = compose(MarqueeController({ marqueeOnFocus: true }));
const BriefingDetail = BriefingDetailDecorator(BriefingDetailBase);
export default BriefingDetail;