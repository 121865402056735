import TimePicker from '@enact/sandstone/TimePicker';
import css from './TTimePicker.module.less';
import React from 'react';
import classNames from 'classnames';

const TTimePicker = ({ disabled=false, ...rest }) => {
  return (
    <TimePicker
      {...rest}
      className={classNames(css.timePicker, disabled ? css.disabled : null)}
      noLabel={true}
      disabled={disabled}
    />
  );
};

export default TTimePicker;