import classNames from 'classnames';
import css from './ShareMethod.module.less';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { popPanel, addPanels } from "../../../features/panels/panelsSlice";

import * as Config from '../../../utils/Config';
import { $L } from '../../../utils/helperMethods';

import real from "../../../../assets/report/mg_sharing_report_real.png";
import silhouette from "../../../../assets/report/mg_sharing_report_silhouettes.png";
import { changeThemeModeStatus } from "../../../features/common/commonSlice";

import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import TButton, { SIZES } from '../../../components/TButton/TButton';

import Spotlight from '@enact/spotlight';
import Spottable from "@enact/spotlight/Spottable";

const SpottableComponent = Spottable('div');

const ShareMethod = ({...rest}) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    Spotlight.focus("email");
  }, []);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  const METHOD_LISTS = useMemo(() => {
		return [
			{id: "real", image: real, title: "실사 이미지로 표시"},
			{id: "silhouette", image: silhouette, title: "실루엣 이미지로 표시"},
		];
	  }, []);

  const onClickMethod = useCallback((index)=>(ev) => {
    setSelectedIndex(index)
  }, []);

  const onClickEMailBtn = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.EMAIL_SEND, panelInfo: {selectedIndex: selectedIndex}}));
  },[dispatch, selectedIndex]);

  const onClickMobileBtn = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.MOBILE_SEND, panelInfo: {selectedIndex: selectedIndex}}));
  },[dispatch, selectedIndex]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("How to share a report")} />
      <TBody className={css.body}>
        <div className={css.description}>{$L("Select the body style used in the report.")}</div>
        <div className={css.iconButtonArea}>
          {METHOD_LISTS.map((item, index)=>{
            return (
              <SpottableComponent
                key={item.id}
                className={classNames(
                  css.iconButton,
                  index === selectedIndex && css.selected
                )}
                onClick={onClickMethod(index)}
                spotlightId={item.id}
                data-webos-voice-intent={'Select'}
                data-webos-voice-label={null}
              >
                <div className={css.image} style={{ backgroundImage: `url(${item.image})`}}/>
                <div className={css.title}>{$L(item.title)}</div>
              </SpottableComponent>
          )})}
        </div>
        <div className={css.buttonArea}>
          <TButton onClick={onClickEMailBtn} size={SIZES.xLarge}>{$L("Send an e-mail")}</TButton>
          <TButton onClick={onClickMobileBtn} size={SIZES.xLarge}>{$L("Send to your phone")}</TButton>
        </div>
      </TBody>

    </TPanel>
  );
};

export default ShareMethod;