import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activated: false,
  alarms: [
    {active: false, time: "09:00:00"},
    {active: false, time: "09:00:00"},
    {active: false, time: "09:00:00"},
    {active: false, time: "09:00:00"},
    {active: false, time: "09:00:00"},
    {active: false, time: "09:00:00"},
    {active: false, time: "09:00:00"},
  ]
}

export const alarm = createSlice({
  name: "alarm",
  initialState,
  reducers: {
    updateAlarm: (state, action) => {
      const { activated, alarms } = action.payload.value;
      state.activated = activated;
      state.alarms = alarms;
    }
  }
});

export const {
  updateAlarm
}
= alarm.actions;

export default alarm.reducer;
