import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lunaSend from "../lunaSend";
import {getLastRecordedVideo,setSavingVideoPath,getSavingVideoPath} from "../components/PIPCamera/PIPCamera";

import { changeAppStatus} from "../features/common/commonSlice";


const useCaptureImage = () => {
  const { userAge, userGender } = useSelector((state) => state.common.localSettings);
  const { usbDeviceUri, userNumber } = useSelector((state) => state.common.appStatus);

  const dispatch = useDispatch();

  const writeVideoBuffer = async (index, filePath) => {
    console.log('writeVideoBuffer ++', index);
    const ret = await getLastRecordedVideo(index);
    console.log('writeVideoBuffer --', index, ret);
    if(ret.buffer ){
      const newarray = Array.from(new Uint8Array(ret.buffer));
      lunaSend.writeVideoBuffer({path: filePath, data: newarray, create: (index===0)}, {
        onSuccess:()=>{
          if(ret.nextIndex >=0){
            writeVideoBuffer(ret.nextIndex, filePath);
          }
        },
        onFailure: () => {
          lunaSend.createToast("Video File saving Failed");
          dispatch(changeAppStatus({showLoadingPanel: {show: false}}));
        }
      });
    }else if(index === 0){
      lunaSend.createToast("No Video buffer");
      dispatch(changeAppStatus({showLoadingPanel: {show: false}}));
    }else{
      dispatch(changeAppStatus({showLoadingPanel: {show: false}}));
      lunaSend.createToast("Video File saved...");
    }
  };
  const copyVideoFile = async (filePath) => {
    lunaSend.copyFile({src: '/tmp/tmpvideo.webm', dest: filePath}, {
      onSuccess:()=>{
        dispatch(changeAppStatus({showLoadingPanel: {show: false}}));
        lunaSend.createToast("Video File saved...");
        setSavingVideoPath("");
      },
      onFailure: () => {
        lunaSend.createToast("Video File saving Failed");
        dispatch(changeAppStatus({showLoadingPanel: {show: false}}));
      }
    });
  };
  const saveRecordedVideo = useCallback(() => {
    let _usbDeviceUri = usbDeviceUri;
    if(!_usbDeviceUri){
      lunaSend.createToast("No Usb: Can't save video");
      return;
    }
    let filePath = getSavingVideoPath();
    if(filePath){
      dispatch(changeAppStatus({showLoadingPanel: {show: true, type: 'wait'}}));
      copyVideoFile(filePath);
    }else{
      lunaSend.createToast("No File path exist");
    }
  },[dispatch, userNumber, userAge, userGender, usbDeviceUri]);

  return { saveRecordedVideo };
};

export default useCaptureImage;
