import { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Config from "../utils/Config";
import { on, off } from "@enact/core/dispatcher";
import { addPanels } from "../features/panels/panelsSlice";
import { SFT_TYPE } from "../views/BodyCheckUp/PhysicalTest/PhysicalTestCapture/Constants";

const useDebugKey = ({isLandingPage=false, onClick}) => {
  const panels = useSelector((state) => state.panels.panels);
  const debugKey = useRef([]);
  const dispatch = useDispatch();

  const handleKeydown = useCallback(
    (ev) => {
      if (isLandingPage && panels && panels.length > 0) {
        return;
      }
      if (ev && ev.key >= 0 && ev.key <= 9) {
        if (debugKey.current.length >= Config.DEBUG_KEY.length) {
          debugKey.current.shift();
        }
        debugKey.current.push(String(ev.key));
        if (debugKey.current.join("") === Config.DEBUG_KEY) {
          debugKey.current = [];
          dispatch(addPanels({ name: Config.panel_names.DEBUG_PANEL }));
        }
        if (debugKey.current.join("") === Config.TESTPANEL_KEY) {
          debugKey.current = [];
          dispatch(addPanels({ name: Config.panel_names.TEST_PANEL }));
        }
        if (debugKey.current.join("") === Config.SFT_TEST) {
          debugKey.current = [];
          dispatch(
            addPanels({
              name: Config.panel_names.PHYSICAL_TEST_CAPTURE,
              panelInfo: {
                sftList: [SFT_TYPE.ONE_LEGGED_STAND],
              },
            })
          );
        }
        if (debugKey.current.join("") === Config.SFT_TEST2) {
          debugKey.current = [];
          dispatch(
            addPanels({
              name: Config.panel_names.PHYSICAL_TEST_CAPTURE,
              panelInfo: {
                sftList: [SFT_TYPE.POWER_WALKING],
              },
            })
          );
        }

        const curPanel = panels && panels.length > 0 && panels[panels.length - 1];
        if (debugKey.current.join("") === Config.ACTIVITY_SCENERY && curPanel && curPanel.name === Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER) {
          debugKey.current = [];
          if (onClick) {
            onClick();
          }
        }
      }
    },
    [panels, dispatch, isLandingPage, onClick]
  );

  useEffect(() => {
    on("keydown", handleKeydown);
    return () => {
      off("keydown", handleKeydown);
    };
  }, [handleKeydown]);

};

export default useDebugKey;