import classNames from 'classnames';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { $L } from '../../../utils/helperMethods';
import css from "./MyCommerce.module.less";
import TButton from '../../../components/TButton/TButton';
import TScroller from '../../../components/TScroller/TScroller';
import dummyProductList from "../../../../assets/mock/dummyProductList.json";
import Spotlight from '@enact/spotlight';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import Spottable from "@enact/spotlight/Spottable";
import TMediaVerticalList from '../../../components/TMediaVerticalList/TMediaVerticalList';
import TDropdown from '../../../components/TDropDown/TDropDown';
import ri from '@enact/ui/resolution';

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");
const dropDownMenu = [$L("NIKE"), $L("adidas"), $L("FILA")]

const MyCommerce = () => {
  const [selectedId, setSelectedId] = useState(null);

	const onBrandSelect = useCallback(({selected}) => {
			setSelectedId(selected);
	}, [selectedId]);

  const onItemClick = useCallback(({index, itemInfo, ev})=>{
		console.log('TMediaVerticalListLayout onItemClick', index, itemInfo, ev);
	},[]);

  const onRecentBtnClick = () => {

  };
  const onTrendyBtnClick = () => {

  };



  return (
    <>
      <Container className={css.filterSection}>
        <TDropdown placeholder="브랜드" selected={selectedId} onSelect={onBrandSelect}>
          {dropDownMenu}
        </TDropdown>
        <div>
          <TButton onClick={onRecentBtnClick} size="small">{$L("From the newest")}</TButton>
          <TButton onClick={onTrendyBtnClick} size="small">{$L("Descending order of popularity")}</TButton>
        </div>
      </Container>
      <TMediaVerticalList
        className={css.tMediaList}
        products={dummyProductList.data}
        onItemClick={onItemClick}
        itemLayout={{width: 800, height: 1000, gap: 40, rowCount: 4, colCount: 2}}
      />


    </>

  );
};

export default MyCommerce;