import React, { useEffect, useCallback } from "react";
import classNames from "classnames";
import css from "./TSimpleButtonTab.module.less";
import { useSelector, useDispatch } from "react-redux";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TSimpleButtonItem from "./TSimpleButtonItem";
const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

export const LIST_TYPE = {
  large: "large",
  medium: "medium",
  small: "small",
  header: "header",
  report: "report",
  detailTest: "detailTest",
  leftRightTab: "leftRightTab",
  smallReport: "smallReport",
};
/*
contents: array of button title
maxItemCount: only for header type
 */
const TSimpleButtonTab = ({
  className,
  listType = LIST_TYPE.large,
  type,
  selectedIndex,
  onItemClick,
  contents = [],
  disabledItem = [],
  maxItemCount = 5,
  score = [],
  noMarquee = false,
  ...rest
}) => {
  const onClick = useCallback(
    (index) => (ev) => {
      if (onItemClick) {
        onItemClick({ index, ev });
      }
    },
    [selectedIndex]
  );

  return (
    <Container
      className={classNames(css.tabs, css[listType], className)}
      {...rest}
    >
      {contents.map((item, index) => {
        return (
          <TSimpleButtonItem
            key={"simplebtnitem" + index}
            selected={index === selectedIndex}
            onClick={onClick(index)}
            listType={listType}
            maxItemCount={maxItemCount}
            score={score[index]}
            index={index}
            selectedTabIndex={selectedIndex}
            type={type}
            disabled={disabledItem.includes(item)}
            noMarquee={noMarquee}
          >
            {item}
          </TSimpleButtonItem>
        );
      })}
    </Container>
  );
};

export default TSimpleButtonTab;
