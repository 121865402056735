/**
 * AcquiredGraph
 *
 * @module AcquiredGraph
 */

import classNames from 'classnames';
import React, { useEffect, useCallback, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import css from './AcquiredGraph.module.less';

const CANVAS_WIDTH = 260;
const CANVAS_HEIGHT = 260;
const SMALL = 0;
const MEDIUM = 1;
const LARGE = 2;
//bg, datacolor
const COLORS = [
    ['#ff645a33', '#ff645a'],
    ['#ffd21433', '#ffd214'],
    ['#60a7e833', '#60a7e8'],
];
const DISABLEE_COLOR = '#e4e4e4';
//data : percent  ex: [30, 40, 50] steps, kcal, time
const AcquiredGraph = ({ className, data, disabled }) => {
    let canvasRef = useRef(null);

    const drawCorner = useCallback((ctx, x, y, start, end, cornerRadius) => {
        ctx.beginPath();
        ctx.arc(x, y, cornerRadius, start, end, true);
        ctx.stroke();
    }, []);

    const drawLine = useCallback((ctx, x1, y1, x2, y2) => {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    }, []);

    const clearRightDummy = useCallback((ctx) => {
        ctx.clearRect(236, 0, 20, 260);
    }, []);

    const drawPercentRect = useCallback(
        (ctx, index, percent, drawBg) => {
            ctx.strokeStyle = COLORS[index][1];
            if (drawBg) {
                ctx.lineCap = 'butt';
                ctx.strokeStyle = COLORS[index][0];
                if (disabled) {
                    ctx.strokeStyle = DISABLEE_COLOR;
                }
            } else {
                ctx.lineCap = 'round';
            }
            // define the rectangle
            const offsetX = 236;
            let offsetY = 88; //ok
            let horizLineLength = 122;
            let vertLineLength = 42;
            let cornerRadius = 20;
            if (index === MEDIUM) {
                // define the rectangle
                offsetY = 56;
                horizLineLength = 128;
                vertLineLength = 48;
                cornerRadius = 50;
            } else if (index === LARGE) {
                // define the rectangle
                offsetY = 20;
                horizLineLength = 128;
                vertLineLength = 40;
                cornerRadius = 90;
            }
            // calc some lengths for use in percent complete
            const cornerLength = (2 * cornerRadius * Math.PI) / 4;
            const totalLength = cornerLength * 2 + horizLineLength * 2 + vertLineLength;

            const startT = 0;
            const startTL = horizLineLength;
            const startL = startTL + cornerLength;
            const startBL = startL + vertLineLength;
            const startB = startBL + cornerLength;

            // percent expressed as a length-traveled-along-rect
            let accumLength = (percent / 100) * totalLength;
            let d, x1, y1, x2, y2, start, end;
            // clear the canvas
            // ctx.clearRect(0,0,canvas.width,canvas.height);

            // top line
            d = accumLength - startT;
            d = Math.min(d, horizLineLength);
            if (d > 0) {
                x1 = offsetX;
                y1 = offsetY;
                x2 = offsetX - d;
                y2 = offsetY;
                drawLine(ctx, x1, y1, x2, y2);
            }

            // top-left corner
            d = accumLength - startTL;
            d = Math.min(d, cornerLength);
            if (d > 0) {
                x1 = offsetX - horizLineLength;
                y1 = offsetY + cornerRadius;
                start = Math.PI + Math.PI / 2;
                end = Math.PI + Math.PI / 2 - ((d / cornerLength) * Math.PI) / 2;
                // start   = Math.PI + (d/cornerLength) * Math.PI/2;
                drawCorner(ctx, x1, y1, start, end, cornerRadius);
            }

            // left line
            d = accumLength - startL;
            d = Math.min(d, vertLineLength);
            if (d > 0) {
                x1 = offsetX - horizLineLength - cornerRadius;
                y1 = offsetY + cornerRadius;
                x2 = offsetX - horizLineLength - cornerRadius;
                y2 = offsetY + cornerRadius + d;
                drawLine(ctx, x1, y1, x2, y2);
            }

            // bottom-left corner
            d = accumLength - startBL;
            d = Math.min(d, cornerLength);
            if (d > 0) {
                x1 = offsetX - horizLineLength;
                y1 = offsetY + cornerRadius + vertLineLength;
                start = Math.PI;
                end = Math.PI / 3;
                end = Math.PI - ((d / cornerLength) * Math.PI) / 2;
                // end   = Math.PI/2 - (d/cornerLength) * Math.PI/2;
                drawCorner(ctx, x1, y1, start, end, cornerRadius);
            }

            // bottom line
            d = accumLength - startB;
            d = Math.min(d, horizLineLength);
            if (d > 0) {
                x1 = offsetX - horizLineLength;
                y1 = offsetY + cornerRadius + vertLineLength + cornerRadius;
                x2 = offsetX - horizLineLength + d;
                y2 = offsetY + cornerRadius + vertLineLength + cornerRadius;
                drawLine(ctx, x1, y1, x2, y2);
            }
        },
        [disabled, drawCorner, drawLine]
    );

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.lineWidth = 22;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        //bg
        drawPercentRect(ctx, SMALL, 100, true);
        drawPercentRect(ctx, MEDIUM, 100, true);
        drawPercentRect(ctx, LARGE, 100, true);

        //data
        if (!disabled) {
            drawPercentRect(ctx, SMALL, data[0], false);
            drawPercentRect(ctx, MEDIUM, data[1], false);
            drawPercentRect(ctx, LARGE, data[2], false);

            clearRightDummy(ctx);
        }
    }, [data, disabled]);

    return <canvas ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} className={classNames(css.canvas, className)} />;
};

export default React.memo(AcquiredGraph, shallowEqual);
