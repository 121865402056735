import css from './EmailSend.module.less';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { popPanel, addPanels } from "../../../features/panels/panelsSlice";
import {
  changeThemeModeStatus,
  changeToastStatus,
} from "../../../features/common/commonSlice";

import { $L, isEmailAddress } from '../../../utils/helperMethods';

import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import TButton, { SIZES } from '../../../components/TButton/TButton';
import TInput, { KINDS, ICONS } from '../../../components/TInput/TInput';
import TListItemButton from '../../../components/TListItemButton/TListItemButton';
import TScroller from '../../../components/TScroller/TScroller';
import TIconButton from '../../../components/TIconButton/TIconButton';
import TToast from '../../../components/TToast/TToast';

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import Spotlight from '@enact/spotlight';


const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

const EmailSend = ({panelInfo, ...rest}) => {
  const dispatch = useDispatch();
  const {isToasted, message} = useSelector((state) => state.common.appStatus.toast);
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);
  const [emailList, setEmailList] = useState(["harmonika3412@gmail.com", "youujjj@gmail.com", "happyhong99@gmail.com", "kkkgukjj@naver.com", "uuii12345@naver.com"]);
  const recentEmailList = ["khj01@naver.com", "ilg1995@gmail.com", "rainbow841208@naver.com"];
  const maxList = 10

  useEffect(() => {
    Spotlight.focus("emailSendSpot");
  }, []);

  useEffect(() => {
		if(email && isEmailAddress(email)){
			setValid(true);
		}else{
			setValid(false);
		}
	},[email]);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  const onEmailChange = useCallback((e) => {
		setEmail(e.value);
	}, []);

  const onDeactivateInput = useCallback(() => {
		if(email && !valid){
			console.log("유효하지 않은 이메일입니다. 다시 입력해주세요.")
		}
	}, [email, valid]);

  const onClickPlusBtn = useCallback(() => {
    if (emailList.includes(email)) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("Email already exists");
      return;
    }

    if (emailList.length === maxList) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("입력 가능 횟수를 초과");
      return;
    }

    if (valid && email) {
      setEmailList([email, ...emailList]);
      setEmail("");
    } else {
      dispatch(changeToastStatus({ isToasted: true, message: $L("Check your email address.") }));
      console.log(email, emailList)
      console.log("유효하지 않는 이메일");
    }
  }, [valid, emailList, email]);

  const onClickRecentEmail = useCallback((index) => (ev) => {
    if (emailList.includes(recentEmailList[index])) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("Email already exists");
      return;
    }

    if (emailList.length === maxList) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("입력 가능 횟수를 초과");
      return;
    }

    setEmailList([recentEmailList[index], ...emailList]);

  }, [emailList, recentEmailList]);


  const onClickMinusBtn = useCallback((index) => (e) => {
    const updatedEmailList = emailList.filter((_, i) => i !== index);
    setEmailList(updatedEmailList);
  }, [emailList]);

  const onClickPrevBtn = useCallback(() => {
    dispatch(popPanel());
  },[dispatch]);

  const onClickNextBtn = useCallback(() => {

  },[]);

  const instruction = useMemo(()=>{
    return $L(`
      <span>이메일 공유를 위해 이메일 주소를 입력해주세요. </span>
      <span>입력하신 이메일 주소로 메일이 발송되며, 메일을 통해 리포트를 다운받으실 수 있어요~</span>
  `)}, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Send an e-mail")} />
      <TBody className={css.body}>
        <div className={css.instruction} dangerouslySetInnerHTML={{ __html: instruction }}/>
        <div className={css.inputSection}>
          <div className={css.inputConainer}>
            <TInput
              kind={KINDS.withIcon}
              icon={ICONS.email}
              spotlightId={"emailSendSpot"}
              className={css.input}
              autoFocus
              dismissOnEnter={true}
              value={email}
              onChange={onEmailChange}
              onDeactivate={onDeactivateInput}
              placeholder={$L('Enter your e-mail')}
            />
            <TIconButton iconType="plus" size={"small"} onClick={onClickPlusBtn} />
          </div>
          <Container className={css.recentContainer}>
            <div className={css.title}>{$L('Recent list')}</div>
            <div className={css.list}>
              {recentEmailList.map((email, index) => (
                <TListItemButton
                  onClick={onClickRecentEmail(index)}
                  key={"recent"+index}
                  className={css.email}
                >
                  {email}
                </TListItemButton>
              ))}
            </div>
          </Container>
        </div>
        <Container className={css.recipientContainer}>
          <div className={css.title}>{`${$L('Recipient')} ${emailList.length}/10`} </div>
          <div className={css.hr}/>
          <TScroller className={css.scroller}>
            <div className={css.list}>
              {emailList.map((email, index) => (
                <TListItemButton className={css.recipient} key={"recipient"+index} onClick={onClickMinusBtn(index)}>
                  <div className={css.email}>{email}</div>
                  <div className={css.deleteIcon}/>
                </TListItemButton>
              ))}
            </div>
          </TScroller>
        </Container>
        <div className={css.buttonArea}>
          <TButton size={SIZES.xLarge} onClick={onClickPrevBtn}>{$L("Cancel")}</TButton>
          <TButton size={SIZES.xLarge} onClick={onClickNextBtn}>{$L("Send")}</TButton>
        </div>
        {isToasted && <TToast text={message}/>}
      </TBody>
    </TPanel>
  );
};

export default EmailSend;