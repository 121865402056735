import React, { useMemo } from "react";
import css from "./OverallDetail.module.less";
import classNames from "classnames";
import { $L } from "../../../../../../utils/helperMethods";
import { CANVAS_POINT, DEGREE_DATA, IMG_DATA } from "../ConstantsSubTab";
import { getConvertTestName } from "../Constants";
import Spottable from "@enact/spotlight/Spottable";
import ROMReportAngleCanvas from "../../ROMReportAngleCanvas/ROMReportAngleCanvas";

let convertTestName = null;
const OverallDetail = ({ detailInfo, type, feedback, ...rest }) => {
  if (!convertTestName) {
    convertTestName = getConvertTestName();
  }

  const testListArr = useMemo(() => {
    if (detailInfo.testList) {
      const testList = detailInfo.testList;
      let testListArr = [];
      for (const test in testList) {
        const dummyObj = {
          title: test,
          images: [],
          value: [],
          grade: [],
        };
        const testObj = testList[test];

        // images and value and grade
        if (
          typeof testObj.images === "object" &&
          typeof testObj.value === "object" &&
          typeof testObj.grade === "object" &&
          testObj.images !== null
        ) {
          for (const index in testObj.images) {
            dummyObj.value.push(testObj.value[index]);
            dummyObj.grade.push(testObj.grade[index]);
            dummyObj.images.push(testObj.images[index]);
          }
        } else {
          dummyObj.value.push(testObj.value);
          dummyObj.grade.push(testObj.grade);
          dummyObj.images.push(testObj.images);
        }

        testListArr.push(dummyObj);
      }
      return testListArr;
    }
  }, [detailInfo]);

  return (
    <div className={css.overallDetail}>
      <div className={css.feedbackLayer}>
        <div
          className={css.feedback}
          dangerouslySetInnerHTML={{ __html: feedback?.title }}
        />
        {feedback?.subtitle && (
          <div className={css.feedbackDesc}>{feedback?.subtitle}</div>
        )}
        <div className={css.notification}>
          {$L("The image shown on the screen is not flipped left or right.")}
        </div>
      </div>
      <div>
        <div className={css.flexBox}>
          {testListArr &&
            testListArr.map((test, testIndex) => (
              <div
                key={testIndex}
                className={classNames(
                  css.section,
                  test.images.length === 1 && css.isSingleItem
                )}
              >
                <div className={css.title}>{convertTestName[test.title]}</div>
                <div className={css.bodyImage}>
                  {test.images.map((imageUrl, dataIndex) => {
                    const value =
                      test.value[dataIndex] === 0 ||
                      test.value[dataIndex] === 999 ||
                      test.value[dataIndex] === -999
                        ? "-"
                        : Math.round(test.value[dataIndex]);
                    const grade = test.grade[dataIndex];
                    let isNoData = value === "-";
                    const convertGrade = {
                      a: $L("Good"),
                      b: $L("Borderline"),
                      c: $L("Weak"),
                    };
                    const gradeColor = {
                      a: "fine",
                      b: "manage",
                      c: "danger",
                    };

                    const convertLR = dataIndex === 0 ? "L" : "R";
                    const isMultyArr = test.images.length === 2;

                    let dummyImg = "";
                    if (isMultyArr) {
                      dummyImg = IMG_DATA[type][test.title][convertLR][0];
                    } else {
                      dummyImg = IMG_DATA[type][test.title][0];
                    }

                    return (
                      <div
                        key={dataIndex}
                        className={classNames(
                          css.eachImgBox,
                          isNoData && css.noData,
                          {
                            [css.marginRight]:
                              dataIndex === 0 && test.images.length === 2,
                          }
                        )}
                      >
                        <div
                          className={classNames(
                            css.imgBox,
                            !isMultyArr && css.narrow
                          )}
                        >
                          {!isNoData && (
                            <ROMReportAngleCanvas
                              className={classNames(css.eachImg)}
                              grade={grade}
                              centerPoint={
                                CANVAS_POINT?.[type]?.[test.title]?.[
                                  dataIndex
                                ]?.[0]
                              }
                              basePoint={
                                CANVAS_POINT?.[type]?.[test.title]?.[
                                  dataIndex
                                ]?.[1]
                              }
                              guideAngles={DEGREE_DATA[type][test.title]}
                              degree={value}
                              isNegative={
                                CANVAS_POINT?.[type]?.[test.title]?.[
                                  dataIndex
                                ]?.[2]
                              }
                              debug={false}
                            />
                          )}

                          <div
                            className={classNames(css.eachImg)}
                            style={{
                              backgroundImage: `url(${
                                isNoData ? dummyImg : imageUrl
                              })`,
                            }}
                          />
                        </div>
                        {isMultyArr && (
                          <div
                            className={classNames(
                              css.labelLR,
                              dataIndex !== 0 && css.right
                            )}
                          >
                            {$L(convertLR)}
                          </div>
                        )}
                        <div className={css.divider} />
                        <div className={css.infoBox}>
                          <span className={css.value}>{value}°</span>
                          <div className={css.infoDivider} />
                          <span
                            className={classNames(
                              css.gradeBox,
                              !isNoData && css[gradeColor[grade]]
                            )}
                          >
                            {isNoData ? $L("Not Tested") : convertGrade[grade]}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default OverallDetail;
