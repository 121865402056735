import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Config from "../utils/Config";
import * as HelperMethods from "../utils/helperMethods";
import { DATAKEY, updateTestResults } from "../features/rom/romSlice";
import { postRomData} from "../features/fitService/fitServiceSlice";
import { updatePanel} from "../features/panels/panelsSlice";

const useROM = () => {
  const dispatch = useDispatch();
  const currentTestedData = useSelector(state => state.rom.current);
  const saveTestResults = useCallback(() => {
    const now = new Date();
    const keys = Object.keys(DATAKEY);
    const toSave = {};
    const isoDateTime = HelperMethods.timeToISO8601Str(now);
    keys.forEach(key => {
      if(currentTestedData[DATAKEY[key]] && Object.keys(currentTestedData[DATAKEY[key]]).length > 0){
        toSave[DATAKEY[key]] = {...currentTestedData[DATAKEY[key]], date: isoDateTime};
      }
    });
    //update to reducer
    //2023-09-11
    const dateStr = HelperMethods.convertDateToString2(now);
    dispatch(updateTestResults({date: dateStr, value: toSave}));
    dispatch(updatePanel({name: Config.panel_names.ROM_REPORT, panelInfo: { selectedDate: dateStr }}));
    //push to server
    const toServerSave = HelperMethods.cloneObject(toSave);
    toServerSave.date = isoDateTime;
    dispatch(postRomData(toServerSave));
    // window.toServerSave = toServerSave;
  },[dispatch, currentTestedData]);

  return { currentTestedData, saveTestResults };
};

export default useROM;