import classNames from "classnames";
import { useCallback, useRef, useState, useMemo, useEffect } from "react";
import Spottable from "@enact/spotlight/Spottable";
import { Marquee, MarqueeController } from "@enact/sandstone/Marquee";
import { Job } from "@enact/core/util";
import * as Config from "../../utils/Config";
import { panel_names } from "../../utils/Config";
import * as SoundEffect from "../../utils/SoundEffect";
import compose from "ramda/src/compose";
import css from "./TabItem.module.less";
import { getTargetByDirectionFromElement } from "@enact/spotlight/src/target";
import { useSelector } from "react-redux";
import TToolTip, { COLOR, POSITION } from "../TToolTip";

const SpottableComponent = Spottable("div");

const clearFocusDisabled = (ref) => {
  if (ref) {
    ref.current = false;
  }
}
let focusDisabledJob = new Job(clearFocusDisabled, 10);

const TabItemmBase = ({
  index = 0,
  icons,
  title,
  target,
  expanded = false,
  selected = false,
  isMainItem = false,
  isSubItem = false,
  className,
  onSelect,
  onFocus,
  onClick,
  mainExpanded,
  deActivateTab,
  ...rest
}) => {
  const { themeMode, userNumber } = useSelector(
    (state) => state.common.appStatus
  );
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const [focused, setFocused] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [isTooltip, setTooltip] = useState(false);
  const itemRef = useRef();
  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onSelect = useCallback(() => {
    if (onSelect) {
      onSelect(index);
    }
  }, [index]);

  const _onFocus = useCallback(() => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    setFocused(true);
    if (onFocus) {
      onFocus(index);
    }

    if(!expanded && title && isMainItem) {
      setTooltip(true);
    }
  }, [index]);

  const _onBlur = useCallback(() => {
    setFocused(false);
    setPressed(false);
    clearPressedJob.current.stop();

    if(!expanded && title && isMainItem) {
      setTooltip(false);
    }
  }, []);

  const isUnauthorized = useMemo(() => {
    if (!cesShowMode && !userNumber) {
      if (target && target[0]) {
        if (
          target[0].name === panel_names.MY_PANEL || 
          target[0].name === panel_names.FITNESS_MANAGER || 
          target[0].name === panel_names.REPORT_PANEL
        ) {
          return true;
        }
      }
    }
    if (cesShowMode) {
      if (target && target[0]) {
        if (
          target[0].name === panel_names.HOMECARE_PROGRAM_MAIN || 
          target[0].name === panel_names.MY_PANEL || 
          target[0].name === panel_names.SETTING_PANEL
        ) {
          return true;
        }
      }
    }
    return false;
  }, [userNumber, target, cesShowMode]);

  const _onClick = useCallback(
    (ev) => {
      // if(ev?.target?.nodeName === 'IMG'){
      //   return;
      // }
      if (isUnauthorized) {
        return;
      }
      SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
      setPressed(true);
      clearPressedJob.current.start(() => {
        if (onClick) {
          onClick({ index, target });
        }
      });
    },
    [target, index, onClick, isUnauthorized]
  );

  const onKeyDown = useCallback(
    (ev) => {
      if (ev?.key === "ArrowRight") {
        const next = getTargetByDirectionFromElement(
          "right",
          itemRef.current.node
        );
        if (!next && deActivateTab) {
          deActivateTab();
        }
      }
    },
    [deActivateTab]
  );

  const isDivider = useMemo(() => {
    return !title;
  }, []);

  delete rest.hasChildren;
  delete rest.getChildren;

  const renderIcon = useCallback(() => {
    if (icons) {
      const Component = icons;
      return (
        <Component
          iconType={
            focused
              ? "focused"
              : selected
              ? "selected"
              : expanded
              ? "expanded"
              : "normal"
          }
        />
      );
    } else {
      return null;
    }
  }, [focused, selected, expanded]);

  return (
    <SpottableComponent
      {...rest}
      ref={itemRef}
      className={classNames(
        css.tabitem,
        expanded && css.expanded,
        !isDivider && focused && css.focused,
        !isDivider && selected && css.selected,
        !isDivider && pressed && css.pressed,
        isDivider && css.dividerBox,
        className,
        mainExpanded && css.AllExpanded,
        themeMode === "dark" && css.isDark,
        themeMode === "transparent" && css.isTransparent
      )}
      onSelect={_onSelect}
      onFocus={_onFocus}
      onBlur={_onBlur}
      onClick={_onClick}
      spotlightDisabled={isDivider}
      onKeyDown={onKeyDown}
    >
      {/* {icons && icons[0] && icons[2] && icons[3] && ( */}
      {icons && (
        // <img className={css.icon} src={(focused || selected) ? icons[2]: icons[0]} />
        <div className={css.icon}>
          {renderIcon()}
          {/* <img
            src={
              focused
                ? icons[2]
                : selected
                ? icons[3]
                : expanded
                ? icons[1]
                : icons[0]
            }
          /> */}
        </div>
      )}
      {expanded && title && (
        <Marquee
          className={classNames(css.text, isSubItem && css.subItem)}
          marqueeOn={"focus"}
        >
          {title}
        </Marquee>
      )}
      {!expanded && title && isMainItem && (
        <TToolTip
          position={POSITION.right}
          color={COLOR.mineralGreen}
          className={css.toolTip}
          isOpen={isTooltip}
        >
          {title}
        </TToolTip>
      )}
      {isDivider && <div className={css.divider} />}
    </SpottableComponent>
  );
};
const ItemDecorator = compose(MarqueeController({ marqueeOnFocus: true }));
const TabItem = ItemDecorator(TabItemmBase);
export default TabItem;
