// TEST
import Shoulder_Exercise_Narration_thum from "../../../assets/images/Shoulder_Exercise_Narration_thum.jpg"
import Pelvic_Exercise_Narration_BGM_thum from "../../../assets/images/Pelvic_Exercise_Narration_BGM_thum.jpg"
import Leg_exercise_Only_BGM_thum from "../../../assets/images/Leg_exercise_Only_BGM_thum.jpg"


export const WORKOUT = {
  TEST: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/hometraining/Shoulder_Exercise/Shoulder_Exercise.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/hometraining/Pelvic_Exercise/Pelvic_Exercise.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/hometraining/Leg_exercise/Leg_exercise.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  NECK_01: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_neck_01_01/falls_neck_01_01.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_neck_01_01/lake_neck_01_01.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_neck_01_01/park_neck_01_01.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  NECK_02: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_neck_02_12/falls_neck_02_12.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_neck_02_12/lake_neck_02_12.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_neck_02_12/park_neck_02_12.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  SHOULDER_01: [
{
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_shoulder_01_07/falls_shoulder_01_07.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_shoulder_01_07/lake_shoulder_01_07.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_shoulder_01_07/park_shoulder_01_07.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  SHOULDER_02: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_shoulder_02_10/falls_shoulder_02_10.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_shoulder_02_10/lake_shoulder_02_10.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_shoulder_02_10/park_shoulder_02_10.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  SHOULDER_03: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_shoulder_03_13/falls_shoulder_03_13.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_shoulder_03_13/lake_shoulder_03_13.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_shoulder_03_13/park_shoulder_03_13.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  SHOULDER_04: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_shoulder_04_14/falls_shoulder_04_14.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_shoulder_04_14/lake_shoulder_04_14.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_shoulder_04_14/park_shoulder_04_14.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  PELVIC_01: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_pelvic_01_05/falls_pelvic_01_05.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_pelvic_01_05/lake_pelvic_01_05.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_pelvic_01_05/park_pelvic_01_05.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  PELVIC_02: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_pelvic_02_15/falls_pelvic_02_15.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_pelvic_02_15/lake_pelvic_02_15.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_pelvic_02_15/park_pelvic_02_15.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  PELVIC_03: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_pelvic_03_16/falls_pelvic_03_16.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_pelvic_03_16/lake_pelvic_03_16.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_pelvic_03_16/park_pelvic_03_16.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  LEG_01: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_leg_01_02/falls_leg_01_02.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_leg_01_02/lake_leg_01_02.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_leg_01_02/park_leg_01_02.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  LEG_03: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_leg_03_09/falls_leg_03_09.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_leg_03_09/lake_leg_03_09.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_leg_03_09/park_leg_03_09.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
  KNEE_01: [
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/falls_voiceC/falls_knee_01_04/falls_knee_01_04.mpd",
      thumnail: Shoulder_Exercise_Narration_thum,
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/lake_voiceA/lake_knee_01_04/lake_knee_01_04.mpd",
      thumnail: Pelvic_Exercise_Narration_BGM_thum
    },
    {
      playUrl: "https://d16h06llvpeyov.cloudfront.net/virtual_training/park_voiceB/park_knee_01_04/park_knee_01_04.mpd",
      thumnail: Leg_exercise_Only_BGM_thum
    },
  ],
}