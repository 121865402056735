import TAccordion from "../../../../components/TAccordion/TAccordion";
import { useState, useCallback } from "react";

const mock = [
  {
    date: "2023-06-24",
    title:
      "전신 안정성 강화 운동 (부상방지,안정적인 몸 만들기) 운동명이 들어갑니다.",
    subTitle: "시니어 건강 증진 활력 프로젝트 오늘도 건강하게 스트레칭!",
    data: {
      time: "1:00",
      bodyPart: "어깨",
      painScore: "9",
    },
  },
  {
    date: "2023-06-24",
    title:
      "전신 안정성 강화 운동 (부상방지,안정적인 몸 만들기) 운동명이 들어갑니다. 긴 메세지 일시 marquee 적용됩니다.",
    subTitle: "시니어 건강 증진 활력 프로젝트 오늘도 건강하게 스트레칭!",
    data: {
      time: "1:00",
      bodyPart: "어깨",
      painScore: "9",
    },
  },
];

const TAccordionLayout = () => {
  const [clickedItemIndexs, setClickedItemIndexs] = useState([]);

  const handleClickedItem = useCallback(
    ({ clickedItemIndexs }) => {
      setClickedItemIndexs(clickedItemIndexs);
    },
    [clickedItemIndexs]
  );

  return (
    <>
      <div style={{ width: "1400px" }}>
        <TAccordion
          data={mock}
          clickedItemIndexs={clickedItemIndexs}
          handleClickedItem={handleClickedItem}
        ></TAccordion>
      </div>
    </>
  );
};

export default TAccordionLayout;
