import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
} from "../../../../utils/Constants";
import { $L } from "../../../../utils/helperMethods";
import { romMenu } from "../ROMReport/Constants";

export const getBODY_DETAIL_TYPE = () => {
  return {
    KNEE_L: $L("Knee Left"),
    KNEE_R: $L("Knee Right"),
    HIP_L: $L("Hip Left"),
    HIP_R: $L("Hip Right"),
    SHOULDER_L: $L("Shoulder Left"),
    SHOULDER_R: $L("Shoulder Right"),
    KNEE_BOTH: $L("Both Knees"),
    HIP_BOTH: $L("Both Hips"),
    SHOULDER_BOTH: $L("Both Shoulders"),
    NECK: $L("Neck"),
    TRUNK: $L("Trunk"),
  };
};

export const FEEDBACK_TYPE = {
  COMMON: "COMMON",
  MAIN_FEEDBACK: "MAIN_FEEDBACK",
  SUB_FEEDBACK: "SUB_FEEDBACK",
};

export const getBODY_PART_TYPE = () => {
  return {
    SHOULDER: $L("Shoulder"),
    NECK: $L("Neck"),
    KNEE: $L("Knee"),
    HIP: $L("Hip"),
    TRUNK: $L("Trunk"),
  };
};
export const getBODY_PART_RANGE_TYPE = () => {
  return {
    SHOULDER: $L("Shoulder Range Of Motion"),
    NECK: $L("Neck Range Of Motion"),
    KNEE: $L("Knee Range Of Motion"),
    HIP: $L("Hip Range Of Motion"),
    TRUNK: $L("Trunk Range Of Motion"),
  };
};

export const SUB_TYPE = {
  // NECK
  NECK_LATERAL_FLEXION: "NeckLateralFlexion",
  NECK_FLEXION: "NeckFlexion",
  NECK_EXTENSION: "NeckExtension",

  // SHOULDER
  SHOULDER_ABDUCTION: "ShoulderAbduction",
  SHOULDER_INTERNAL_ROTATION: "ShoulderInternalRotation",
  SHOULDER_EXTERNAL_ROTATION: "ShoulderExternalRotation",
  SHOULDER_FLEXION: "ShoulderFlexion",
  SHOULDER_EXTENSION: "ShoulderExtension",

  // TRUNK
  TRUNK_LATERAL_FLEXION: "TrunkLateralFlexion",
  TRUNK_EXTENSION: "TrunkExtension",
  TRUNK_FLEXION: "TrunkFlexion",

  // KNEE
  KNEE_FLEXION: "KneeFlexion",

  // HIP
  HIP_FLEXION: "HipFlexion",
  HIP_EXTENSION: "HipExtension",
};

export const getSUB_TYPE = () => {
  return {
    // NECK
    [SUB_TYPE.NECK_LATERAL_FLEXION]: $L("Neck Lateral Flexion"),
    [SUB_TYPE.NECK_FLEXION]: $L("Neck Flexion"),
    [SUB_TYPE.NECK_EXTENSION]: $L("Neck Extension"),

    // SHOULDER
    [SUB_TYPE.SHOULDER_ABDUCTION]: $L("Shoulder Abduction"),
    [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: $L("Shoulder Internal Rotation"),
    [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: $L("Shoulder External Rotation"),
    [SUB_TYPE.SHOULDER_FLEXION]: $L("Shoulder Flexion"),
    [SUB_TYPE.SHOULDER_EXTENSION]: $L("Shoulder Extension"),

    // TRUNK
    [SUB_TYPE.TRUNK_LATERAL_FLEXION]: $L("Trunk Left/Right Lateral Flexion"),
    [SUB_TYPE.TRUNK_FLEXION]: $L("Trunk Flexion"),
    [SUB_TYPE.TRUNK_EXTENSION]: $L("Trunk Extension"),

    // KNEE
    [SUB_TYPE.KNEE_FLEXION]: $L({
      key: "rom_Knee_Flexion",
      value: "Knee Flexion",
    }),

    // HIP
    [SUB_TYPE.HIP_FLEXION]: $L("Hip Flexion"),
    [SUB_TYPE.HIP_EXTENSION]: $L("Hip Extension"),
  };
};

export const getFeedbackROM = () => {
  return {
    [FEEDBACK_TYPE.COMMON]: {
      [SCORE_MINT_KEY]: $L("{bodyPart}가 좋습니다."),
      [SCORE_YELLOW_KEY]: $L("{bodyPart} 향상이 필요해 보이네요."),
      [SCORE_RED_KEY]: $L("{bodyPart} 향상이 매우 필요해 보이네요."),
    },
    [romMenu.OVERALL]: {
      [SCORE_MINT_KEY]: {
        feedback: $L("You have an excellent range of motion for a joint."),
      },
      [SCORE_YELLOW_KEY]: {
        title: $L(
          "To restore the mobility of {partsArr}, proper exercise is required."
        ),
        subtitle: $L(
          "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
        ),
      },
      [SCORE_RED_KEY]: {
        title: $L(
          "To restore the mobility of {partsArr}, proper exercise is required."
        ),
        subtitle: $L(
          "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
        ),
      },
    },
    [romMenu.HIP]: {
      [SCORE_MINT_KEY]: {
        [SUB_TYPE.HIP_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on hip flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on hip flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on hip flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on hip flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on hip flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on hip flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on hip flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on hip flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.HIP_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on hip extension is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on hip extension is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on hip extension is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on hip extension is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on hip extension is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on hip extension is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on hip extension is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on hip extension is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
      },
      [SCORE_YELLOW_KEY]: {
        [SUB_TYPE.HIP_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on hip flexion is somewhat limited, and in this case, it is recommended to take caution when performing movements such as putting on shoes, wearing pants and sitting on a low chair, etc."
            ),
            $L(
              "The range of joint movement on hip flexion is slightly limited, and in this case, it may be difficult to perform the housework smoothly such as cleaning the floor and washing clothes while sitting, etc."
            ),
            $L(
              "The range of movement on hip flexion is somewhat limited, and it is recommended to sit on a chair or on a sofa instead of a sedentary lifestyle."
            ),
            $L(
              "Mobility of hip flexion is slightly limited. In this case, you may have influence on performing various activities such as enjoying hobbies and sports, etc."
            ),
            $L(
              "The range of joint movement on hip flexion is slightly limited, and in this case, caution is required when enjoying sports activities that include hip flexion movements such as cycling, yoga, climbing, tennis and hiking, etc."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L("Proper exercise is required for restoration."),
            $L(
              "Among the test items on the range of joint movement, limitation is shown in hip flexion, and it can result in limitation in range of movement can be when the joint is unstable. Increase the stability of the joint through stabilization exercise on the hip joint."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To prevent excessive strain on the hip joint, limitation in range of joint movement can be prevented by maintaining a proper posture."
            ),
            $L(
              "Mobility of the joint can be restored through proper stretching and exercise."
            ),
          ],
        },
        [SUB_TYPE.HIP_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on hip extension is slightly limited, and in this case, it may be difficult to perform daily activities that include movements on extending the hip."
            ),
            $L(
              "The range of joint movement on hip extension is somewhat limited, and in this case, it may be difficult to smoothly perform daily activities that include movements on stretching the hip joint to the back."
            ),
            $L(
              "Mobility of hip extension is slightly limited. In this case, you can have influence on performing various sports activities that include hip extension such as swimming, soccer, golf and hiking, etc."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L("Proper exercise is required for restoration."),
            $L(
              "Unstable joint can result in limitation in range of joint movement. Increase the stability of the joint through stabilization exercise on the hip joint."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "When there is damage to the tissues or bones around the hip joint, there can be limitation in range of movement."
            ),
            $L(
              "Being overweight is one of the causes of degenerative change on the hip joint, and it can influence the range of joint movement in the future, so maintaining the proper body weight is recommended."
            ),
            $L(
              "To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To prevent excessive strain on the joint, limitation in range of joint movement can be prevented by maintaining a proper posture."
            ),
            $L(
              "Mobility of the joint can be restored through proper stretching and exercise."
            ),
          ],
        },
      },
      [SCORE_RED_KEY]: {
        [SUB_TYPE.HIP_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of movement on hip flexion is seriously limited, and in this case, there can be restrictions in performing daily activities such as walking and climbing up and down the stairs, etc."
            ),
            $L(
              "The range of movement on hip flexion is seriously limited, and personal hygiene management such as clipping toenails, and washing your face and hair may be difficult."
            ),
            $L(
              "Limitation in range of movement on hip flexion is serious, and it is recommended to use a shoehorn when putting on shoes."
            ),
            $L(
              "The range of movement on hip flexion is seriously limited. Due to this, your quality of life in daily activities can be reduced, and moreover, it can accompany mental stress."
            ),
            $L(
              "The limitation in range of movement on hip flexion is serious, and in this case, it can influence you from walking smoothly."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "In this case, it is recommended to consult with the specialist."
            ),
            $L(
              "To evaluate the cause, it is recommended to visit the local hospital."
            ),
            $L(
              "After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "Among the causes, there can be inflammation on the hip joint or on the surroundings, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "It can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
        [SUB_TYPE.HIP_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of movement on hip extension is seriously limited, and in this case, there can be restrictions in performing daily activities such as walking and climbing up and down the stairs, etc."
            ),
            $L(
              "The range of movement on hip extension is seriously limited, and it may be difficult to perform the daily activities smoothly."
            ),
            $L(
              "Limitation in range of movement on hip extension is serious, and in this case, there can be difficulties in performing daily activities."
            ),
            $L(
              "The range of movement on hip extension is seriously limited. Due to this, your quality of life in daily activities can be reduced, and moreover, it can accompany mental stress."
            ),
            $L(
              "The limitation in range of movement on hip extension is serious, and in this case, it can influence you from walking smoothly."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "For accurate evaluation, it is recommended to visit the local hospital."
            ),
            $L(
              "Among the causes, there can be inflammation on the hip joint or on the surroundings, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "It can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
      },
    },
    [romMenu.KNEE]: {
      [SCORE_MINT_KEY]: {
        [SUB_TYPE.KNEE_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on knee flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on knee flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on knee flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on knee flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on knee flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on knee flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on knee flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on knee flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
      },
      [SCORE_YELLOW_KEY]: {
        [SUB_TYPE.KNEE_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on knee flexion is slightly limited, and in this case, it may be difficult to perform daily activities that include movements on bending your knee joint."
            ),
            $L(
              "The range of joint movement on knee flexion is somewhat limited, and in this case, it is recommended to take caution when performing daily activities such as putting on shoes and socks, and wearing pants, etc."
            ),
            $L(
              "The range of movement on knee joint flexion is somewhat limited, and in this case, it is recommended to sit on a chair or on a sofa instead of a sedentary lifestyle."
            ),
            $L(
              "Mobility of knee joint flexion is slightly limited. In this case, you may have influence on performing various activities such as enjoying hobbies and sports, etc."
            ),
            $L(
              "Mobility of knee flexion is slightly more limited than the normal standard, and caution is required when performing sports activities including knee flexion movements such as soccer, tennis, badminton and yoga, etc."
            ),
            $L(
              "It is evaluated that the knee flexion is slightly limited, and in this case, there can be influence on the walking habit."
            ),
            $L(
              "Knee flexion is slightly more limited than the normal standard, and there can be influence on walking."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L("Proper exercise is required for restoration."),
            $L(
              "Increase the stability of the joint through stabilization exercise on the knee joint."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "There is slight limitation in range of joint movement on knee flexion. Stretch your joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To prevent excessive strain on the knee joint, limitation in range of joint movement can be prevented by maintaining a proper posture."
            ),
            $L(
              "Mobility of the joint can be restored through proper stretching and exercise."
            ),
          ],
        },
      },
      [SCORE_RED_KEY]: {
        [SUB_TYPE.KNEE_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of movement on knee flexion is seriously limited, and in this case, there can be restrictions in performing daily activities such as walking and climbing up and down the stairs, etc."
            ),
            $L(
              "The range of movement on knee flexion is seriously limited, and it may be difficult to perform daily activities including personal hygiene such as clipping toenails and putting on socks, etc."
            ),
            $L(
              "When the limitation in range of movement on knee flexion is serious, it is recommended to use a mop for cleaning the house instead of getting on your knees to wipe the floor."
            ),
            $L(
              "When the limitation in range of movement on knee flexion is serious, there can be difficulties in sedentary lifestyle such as picking up an object on the ground or sitting on the floor, etc."
            ),
            $L(
              "The limitation in range of movement on knee joint flexion is serious, and in this case, it can influence you from walking smoothly."
            ),
            $L(
              "The range of movement on knee flexion is seriously limited. Limitation in knee mobility can influence the walking ability to cause problem in normal gait."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "In this case, it is recommended to consult with the specialist."
            ),
            $L(
              "To evaluate the cause, it is recommended to visit the local hospital."
            ),
            $L(
              "After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "Among the causes, there can be inflammation on the knee joint or on the surroundings, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "It can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
      },
    },
    [romMenu.NECK]: {
      [SCORE_MINT_KEY]: {
        [SUB_TYPE.NECK_LATERAL_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on neck lateral flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on neck lateral flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on neck lateral flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on neck lateral flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on neck lateral flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on neck lateral flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on neck lateral flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on neck lateral flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.NECK_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on neck flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on neck flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on neck flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on neck flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on neck flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on neck flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on neck flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on neck flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.NECK_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on neck extension is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on neck extension is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on neck extension is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on neck extension is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on neck extension is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on neck extension is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on neck extension is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on neck extension is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
      },
      [SCORE_YELLOW_KEY]: {
        [SUB_TYPE.NECK_LATERAL_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on neck lateral flexion is slightly limited. Limitation of joint mobility on neck lateral flexion can influence various activities such as hobbies and sports, etc. including swimming and shooting."
            ),
            $L(
              "The range of joint movement on neck lateral flexion is slightly limited. Restoration of the range of joint movement on neck lateral flexion can help perform the daily activities smoothly, and degenerative cervical disorder can be prevented."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the neck joint mobility to the normal range, proper exercise is required."
            ),
            $L(
              "Unstable neck joint can result in limitation in range of joint movement. Increase the stability of the joint through stabilization exercise on the neck joint."
            ),
            $L(
              "When the neck joint is not moved for long period of time, the range of joint movement on neck lateral flexion can be limited. To prevent this, regular exercise on the range of joint movement on neck lateral flexion is recommended."
            ),
            $L(
              "To maintain the normal range of joint movement on neck lateral flexion, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your neck joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To prevent excessive strain on the neck joint, limitation in range of joint movement on neck lateral flexion can be prevented by maintaining a proper posture."
            ),
            $L(
              "Mobility of the neck joint can be restored through proper stretching and exercise."
            ),
          ],
        },
        [SUB_TYPE.NECK_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on neck flexion is slightly limited. Caution is required when performing movements on bending the neck such as sewing, reading and using the mobile phone, etc."
            ),
            $L(
              "The range of joint movement on neck flexion is slightly limited. There can be adverse effect on restoring the range of joint movement on neck flexion when working or reading a book for a long time."
            ),
            $L(
              "The range of joint movement on neck flexion is slightly limited. Limitation of joint mobility on neck flexion can influence various sports and leisure activities."
            ),
            $L(
              "The range of joint movement on neck flexion is slightly limited. Limitation of joint mobility on neck flexion can influence various hobby activities (calligraphy, go, flower arrangement, playing puzzles and board game) and other activities."
            ),
            $L(
              "The range of joint movement on neck flexion is slightly limited. Restoration of the range of joint movement on neck flexion can help perform the daily activities smoothly."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the neck joint mobility to the normal range, proper exercise is required."
            ),
            $L(
              "Unstable neck joint can result in limitation in range of joint movement on neck flexion. Increase the stability of the neck joint through stabilization exercise on the neck joint."
            ),
            $L(
              "When the neck joint is not moved for long period of time, the range of joint movement on neck flexion can be limited. To prevent this, regular exercise on the range of joint movement on neck flexion is recommended."
            ),
            $L(
              "To maintain the normal range of joint movement on neck flexion, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your neck joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To prevent excessive strain on the neck joint, limitation in range of joint movement on neck flexion can be prevented by maintaining a proper posture."
            ),
            $L(
              "Mobility of the neck joint can be restored through proper stretching and exercise."
            ),
            $L(
              "Normal range of joint movement on the cervical spine joint is limited to require proper exercise for restoration."
            ),
          ],
        },
        [SUB_TYPE.NECK_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on neck extension is slightly limited. In this case, caution is required when performing movements such as cleaning the window or taking out an object from a high place, etc."
            ),
            $L(
              "Limitation of joint mobility on neck extension can influence sports and leisure activities (badminton, basketball, climbing and surfing) and other various activities."
            ),
            $L(
              "The range of joint movement on neck extension is slightly limited. Restoration of the range of joint movement on neck extension can help perform the daily activities smoothly."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the neck joint mobility to the normal range, proper exercise is required."
            ),
            $L(
              "Unstable neck joint can result in limitation in range of joint movement on neck extension. Increase the stability of the joint through stabilization exercise on the neck joint."
            ),
            $L(
              "When the neck joint is not moved for long period of time, the range of joint movement on neck extension can be limited. To prevent this, regular exercise on the range of joint movement on neck extension is recommended."
            ),
            $L(
              "To maintain the normal range of joint movement on cervical spine joint, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your neck joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To prevent excessive strain on the neck joint, limitation in range of joint movement on neck extension can be prevented by maintaining a proper posture."
            ),
            $L(
              "Mobility of the neck joint can be restored through proper stretching and exercise."
            ),
            $L(
              "Normal range of joint movement on the cervical spine joint is limited to require proper exercise for restoration."
            ),
          ],
        },
      },
      [SCORE_RED_KEY]: {
        [SUB_TYPE.NECK_LATERAL_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Limitation in the range of joint movement on neck lateral flexion is somewhat serious. It may be difficult to smoothly perform the movements in daily activities that accompany lateral flexion (drying the hair, looking at a monitor on the next seat and wearing clothes with narrow opening)."
            ),
            $L(
              "Limitation in the range of joint movement on neck lateral flexion is serious. Limitation in the range of joint movement on neck lateral flexion can restrict the side sleeping position."
            ),
            $L(
              "Limitation in the range of joint movement on neck lateral flexion is serious. Limitation in the range of joint movement on neck lateral flexion can restrict movements in daily life such as leaning your head on someone beside you."
            ),
            $L(
              "Limitation in the range of joint movement on neck lateral flexion is serious, and it may be difficult to perform movements required in daily activities."
            ),
            $L(
              "The range of joint movement on neck lateral flexion is serious limited. Due to this, your quality of life in daily activities can be reduced, and moreover, it can accompany mental stress."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "Among the causes, there can be inflammation on the neck joint or around the neck joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "When the limitation in range of joint movement on neck lateral flexion is serious, it can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
        [SUB_TYPE.NECK_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on neck flexion is seriously limited. It may be difficult to perform activities on hygiene management items (clipping toenails, washing the hair and brushing teeth) when the range of joint movement on neck flexion is seriously limited."
            ),
            $L(
              "The range of joint movement on neck flexion is seriously limited. When the range of joint movement on neck flexion is seriously limited, it may be difficult to perform daily activity items smoothly (bowing, picking up an object from the floor, cleaning, tying the shoelaces, opening a lid and driving)."
            ),
            $L(
              "Limitation of joint mobility on neck flexion is serious, and it may be difficult to perform movement that are required in the daily activities."
            ),
            $L(
              "The range of joint movement on neck flexion is seriously limited. Due to this, your quality of life in daily activities can be reduced, and moreover, it can accompany mental stress."
            ),
            $L(
              "The range of joint movement on neck flexion is seriously limited. Limitation in range of joint movement on neck flexion can restrict the performance of movement in daily activities."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "Among the causes, there can be inflammation on the neck joint or around the neck joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "When the limitation in range of joint movement on neck flexion is serious, it can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
        [SUB_TYPE.NECK_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement on neck extension is very limited. When the range of joint movement on neck extension is seriously limited, it may be difficult to perform movements such as tilting the head to look upward."
            ),
            $L(
              "Range of joint movement on neck extension is very limited. When the range of joint movement on neck extension is serious limited, you may experience difficulties when looking up to see a clock, washing your hair in a standing position, and when tilting your head back to drink a liquid."
            ),
            $L(
              "Limitation of joint mobility on neck extension is serious, and it may be difficult to perform movement that are required in the daily activities."
            ),
            $L(
              "The range of joint movement on neck extension is seriously limited. Due to this, your quality of life in daily activities can be reduced, and moreover, it can accompany mental stress."
            ),
            $L(
              "Range of joint movement on neck extension is very limited. Limitation in range of joint movement on neck extension can restrict the performance of movement in daily activities."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "Among the causes, there can be inflammation on the neck joint or around the joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "In the current evaluation on active range of joint movement, serious limitation is shown on the cervical spine joint. For accurate evaluation, it is recommended to visit the local hospital."
            ),
            $L(
              "When the limitation in range of joint movement on neck extension is serious, it can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
      },
    },
    [romMenu.SHOULDER]: {
      [SCORE_MINT_KEY]: {
        [SUB_TYPE.SHOULDER_ABDUCTION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on shoulder abduction is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on shoulder abduction is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on shoulder abduction is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on shoulder abduction is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on shoulder abduction is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on shoulder abduction is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on shoulder abduction is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on shoulder abduction is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on shoulder internal rotation is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on shoulder internal rotation is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on shoulder internal rotation is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on shoulder internal rotation is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on shoulder internal rotation is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on shoulder internal rotation is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on shoulder internal rotation is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on shoulder internal rotation is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on shoulder external rotation is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on shoulder external rotation is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on shoulder external rotation is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on shoulder external rotation is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on shoulder external rotation is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on shoulder external rotation is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on shoulder external rotation is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on shoulder external rotation is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.SHOULDER_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on shoulder flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on shoulder flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on shoulder flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on shoulder anterior flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on shoulder anterior flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on shoulder flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on shoulder anterior flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on shoulder flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.SHOULDER_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on shoulder extension is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on shoulder extension is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on shoulder extension is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on shoulder extension is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on shoulder extension is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on shoulder extension is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on shoulder extension is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on shoulder extension is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
      },
      [SCORE_YELLOW_KEY]: {
        [SUB_TYPE.SHOULDER_ABDUCTION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your range of joint movement on shoulder abduction is somewhat limited, and in this case, caution is required when performing movements such as taking out an object from a high place."
            ),
            $L(
              "Currently, your range of joint movement on shoulder abduction is slightly limited. Limitation of joint mobility can influence various activities such as hobbies and sports, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder abduction is somewhat limited, and in this case, caution is required when participating in sports activities including shoulder abduction such as swimming and baseball, etc."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L("Proper stretching and exercise are required for recovery."),
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L(
              "Stretch your joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Increase the stability of the joint through stabilization exercise on the shoulder joint."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. Reduce the tension on the muscles through sufficient amount of rest and sleep."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When the strength of a specific muscle is weakened, please perform proper muscular strength exercise."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. Muscle balance must be restored through stabilization exercise and stretching."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is slightly limited, and in this case, it is recommended to take caution when performing daily activities that include movements using your shoulders."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is somewhat limited, and in this case, it is recommended to take caution when performing movements such as carrying a bag on your shoulder, and when putting your hands on your back pocket."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is limited, and in this case, it is recommended to take caution when performing daily activities such as driving a car."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is slightly limited. Limitation of joint mobility can influence various activities such as hobbies and sports, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is somewhat limited, and in this case, caution is required when participating in sports activities including shoulder internal rotation such as golf, baseball, tennis and bowling."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L("Proper stretching and exercise are required for recovery."),
            $L(
              "Stretch your joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. Increase the stability of the joint through stabilization exercise on the shoulder joint."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. Reduce the tension on the muscles through sufficient amount of rest and sleep."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When the strength of a specific muscle is weakened, please perform proper muscular strength exercise."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. Muscle balance must be restored through stabilization exercise and stretching."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your range of joint movement on shoulder external rotation is somewhat limited, and in this case, it is recommended to take caution when performing movements such as combing or washing your hair."
            ),
            $L(
              "Currently, your range of joint movement on shoulder external rotation is limited, and in this case, it is recommended to take caution when performing daily activities such as driving a car."
            ),
            $L(
              "Currently, your range of joint movement on shoulder external rotation is slightly limited. Limitation of joint mobility can influence various activities such as hobbies and sports, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder external rotation is somewhat limited, and in this case, caution is required when participating in sports activities such as swimming, baseball, tennis and bowling."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. Increase the stability of the joint through stabilization exercise on the shoulder joint."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. Reduce the tension on the muscles through sufficient amount of rest and sleep."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When the strength of a specific muscle is weakened, please perform proper muscular strength exercise."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. Muscle balance must be restored through stabilization exercise and stretching."
            ),
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L("Proper stretching and exercise are required for recovery."),
            $L(
              "Stretch your joint smoothly to the possible range to relax the joint."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your range of joint movement on shoulder flexion is somewhat limited, and in this case, caution is required when performing movements such as taking out an object from a high place."
            ),
            $L(
              "Currently, your range of joint movement on shoulder flexion is limited, and in this case, it is recommended to take caution when performing movements such as combing or washing your hair."
            ),
            $L(
              "Currently, your range of joint movement on shoulder flexion is limited, and in this case, it is recommended to take caution when performing daily activities such as holding onto a strap when riding on the public transportation."
            ),
            $L(
              "Currently, your range of joint movement on shoulder flexion is slightly limited. Limitation of joint mobility can influence various activities such as hobbies and sports, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder anterior flexion is somewhat limited, and in this case, caution is required when participating in sports activities such as basketball, volleyball, climbing and bowling."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L("Proper stretching and exercise are required for recovery."),
            $L(
              "Stretch your joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. Increase the stability of the joint through stabilization exercise on the shoulder joint."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. Reduce the tension on the muscles through sufficient amount of rest and sleep."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When the strength of a specific muscle is weakened, please perform proper muscular strength exercise."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. Muscle balance must be restored through stabilization exercise and stretching."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your range of joint movement on shoulder extension is somewhat limited, and in this case, it is recommended to take caution when performing daily activities such as pulling on the hinged door."
            ),
            $L(
              "Currently, your range of joint movement on shoulder extension is slightly limited. Limitation of joint mobility can influence various activities such as hobbies and sports, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder extension is somewhat limited, and in this case, caution is required when participating in sports activities such as swimming, tennis, ski and bowling."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the joint mobility to the normal range, proper exercise is required."
            ),
            $L("Proper stretching and exercise are required for recovery."),
            $L(
              "Stretch your joint smoothly to the possible range to relax the joint."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. To prevent this, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. Increase the stability of the joint through stabilization exercise on the shoulder joint."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. Reduce the tension on the muscles through sufficient amount of rest and sleep."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When the strength of a specific muscle is weakened, please perform proper muscular strength exercise."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. To maintain the normal range of joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. Muscle balance must be restored through stabilization exercise and stretching."
            ),
          ],
        },
      },
      [SCORE_RED_KEY]: {
        [SUB_TYPE.SHOULDER_ABDUCTION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your range of joint movement on shoulder abduction is seriously limited, and you may experience difficulty in movements required in the everyday life such as getting dressed."
            ),
            $L(
              "Currently, your range of joint movement on shoulder abduction is seriously limited, and in this case, it may be difficult to perform personal hygiene activities smoothly such as taking a shower, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder abduction is seriously limited, and you may experience difficulty in performing daily activities smoothly such as lifting objects from a high place, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder abduction is serious limited, and in this case, it may be difficult to perform daily activities that include movements using your shoulders."
            ),
            $L(
              "Currently, your range of joint movement on shoulder abduction is serious limited, and in this case, and you may experience difficulty in performing daily activities such as combing or washing your hair."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "Among the causes, there can be inflammation on the joint or around the joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "After consulting with the specialist, it is recommended to perform exercise on range of joint movement, or establish an appropriate treatment plan."
            ),
            $L(
              "When it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When having secondary symptoms such as muscular weakening, pain and edema, etc., it is recommended to that you visit the local hospital."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. When accompanying pain on the tissues around the joint, you must consult with the specialist."
            ),
            $L(
              "Currently, your limitation in range of joint movement on shoulder abduction is serious, and it can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your joint movement on shoulder internal rotation is seriously limited, and it may be difficult to perform movements that are required in the daily activities."
            ),
            $L(
              "Currently, your joint movement on shoulder internal rotation is seriously limited, and it may be difficult to perform movements that are required in the daily activities such as getting dressed."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is seriously limited, and in this case, it may be difficult to perform personal hygiene activities smoothly such as taking a shower, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is limited, and you may experience difficulty in performing daily activities smoothly such as lifting objects from a high place, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is limited, and in this case, it may be difficult to perform daily activities such as carrying a bag on your shoulder, and putting your hands on your back pocket."
            ),
            $L(
              "Currently, your range of joint movement on shoulder internal rotation is limited, and in this case, it may be difficult to perform daily activities such as driving a car."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. After consulting with the specialist, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. When it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When having secondary symptoms such as muscular weakening, pain and edema, etc., it is recommended to that you visit the local hospital."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. When accompanying pain on the tissues around the joint, you must consult with the specialist."
            ),
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Among the causes, there can be inflammation on the joint or around the joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your joint movement on shoulder external rotation is seriously limited, and it may be difficult to perform movements that are required in the daily activities."
            ),
            $L(
              "Currently, your joint movement on shoulder external rotation is seriously limited, and it may be difficult to perform movements that are required in the daily activities such as getting dressed."
            ),
            $L(
              "Currently, your range of joint movement on shoulder external rotation is seriously limited, and in this case, it may be difficult to perform personal hygiene activities smoothly such as taking a shower, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder external rotation is limited, and in this case, it may be difficult to perform daily activities smoothly such as combing or washing your hair."
            ),
            $L(
              "Currently, your range of joint movement on shoulder external rotation is limited, and in this case, it may be difficult to perform daily activities such as driving a car."
            ),
            $L(
              "Currently, your range of joint movement on shoulder external rotation is limited, and it may be difficult to perform daily activities such as cleaning."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. After consulting with the specialist, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. When it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When having secondary symptoms such as muscular weakening, pain and edema, etc., it is recommended to that you visit the local hospital."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. When accompanying pain on the tissues around the joint, you must consult with the specialist."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "Among the causes, there can be inflammation on the joint or around the joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your joint movement on shoulder flexion is seriously limited, and it may be difficult to perform movements that are required in the daily activities."
            ),
            $L(
              "Currently, your joint movement on shoulder anterior flexion is seriously limited, and it may be difficult to perform movements that are required in the daily activities such as getting dressed."
            ),
            $L(
              "Currently, your range of joint movement on shoulder flexion is seriously limited, and in this case, it may be difficult to perform personal hygiene activities smoothly such as taking a shower, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder flexion is limited, and in this case, it may be difficult to perform daily activities smoothly such as taking out an object from a high place."
            ),
            $L(
              "Currently, your range of joint movement on shoulder flexion is limited, and in this case, it may be difficult to perform daily activities such as combing or washing your hair."
            ),
            $L(
              "Currently, your range of joint movement on shoulder flexion is limited, and in this case, it may be difficult to perform daily activities such as holding onto a strap when riding on the public transportation."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "Among the causes, there can be inflammation on the joint or around the joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. After consulting with the specialist, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. When it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When having secondary symptoms such as muscular weakening, pain and edema, etc., it is recommended to that you visit the local hospital."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. When accompanying pain on the tissues around the joint, you must consult with the specialist."
            ),
          ],
        },
        [SUB_TYPE.SHOULDER_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Currently, your joint movement on shoulder extension is seriously limited, and it may be difficult to perform movements that are required in the daily activities."
            ),
            $L(
              "Currently, your joint movement on shoulder extension is seriously limited, and it may be difficult to perform movements that are required in the daily activities such as getting dressed."
            ),
            $L(
              "Currently, your range of joint movement on shoulder extension is seriously limited, and in this case, it may be difficult to perform personal hygiene activities smoothly such as taking a shower, etc."
            ),
            $L(
              "Currently, your range of joint movement on shoulder extension is limited, and in this case, it may be difficult to perform daily activities such as pulling on the hinged door."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "When the joint is not moved for long period of time, the range of joint movement can be limited. After consulting with the specialist, regular exercise on the range of joint movement is recommended."
            ),
            $L(
              "Unstable joint can result in limitation in range of joint movement. After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "Stress can increase the tension on the muscles to put pressure on the joint. When it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
            $L(
              "When there is limitation in active range of joint movement, it can accompany weakening of muscles surrounding the relevant joint. When having secondary symptoms such as muscular weakening, pain and edema, etc., it is recommended to that you visit the local hospital."
            ),
            $L(
              "Limitation in range of joint movement can also influence the range of adjacent joint movement. After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Imbalance of the muscles surrounding the joint can result in limitation in range of joint movement. When accompanying pain on the tissues around the joint, you must consult with the specialist."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "Among the causes, there can be inflammation on the joint or around the joint, so consultation with the specialist is required when this condition is continued for long time."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
          ],
        },
      },
    },
    [romMenu.TRUNK]: {
      [SCORE_MINT_KEY]: {
        [SUB_TYPE.TRUNK_LATERAL_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on trunk left/right lateral flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on trunk left/right lateral flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on trunk left/right lateral flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on trunk left/right lateral flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on trunk left/right lateral flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on trunk left/right lateral flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on trunk left/right lateral flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on trunk left/right lateral flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.TRUNK_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on trunk anterior flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on trunk anterior flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on trunk anterior flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on trunk anterior flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on trunk anterior flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on trunk anterior flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on trunk anterior flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on trunk anterior flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
        [SUB_TYPE.TRUNK_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of joint movement refers to the range of motion on the joint, and your range of joint movement on trunk posterior flexion is in the normal range."
            ),
            $L(
              "The current range of motion can be checked directly through the evaluation on the range of joint movement, and your range of joint movement on trunk posterior flexion is in the normal range."
            ),
            $L(
              "Range of joint movement is the test evaluating the degree of movement on moving the joints, and currently, your range of joint movement on trunk posterior flexion is evaluated to be normal."
            ),
            $L(
              "Range of joint movement is measuring the degree of movement on moving the joints, and currently, your range of joint movement on trunk posterior flexion is within the normal range."
            ),
            $L(
              "Range of joint movement shows how much the specific joint can be moved, and currently, the opinion on your range of joint movement on trunk posterior flexion is shown to be normal."
            ),
            $L(
              "Range of joint movement shows the movement of a specific joint or body part in numerical value, and currently, the opinion on your range of joint movement on trunk extension is shown to be normal."
            ),
            $L(
              "Range of joint movement is the evaluation on measuring the degree of joint movement in angles. Currently, your range of joint movement on trunk posterior flexion is within the normal range."
            ),
            $L(
              "Range of joint movement is the evaluation on the angle of movement along with the connected muscles and surrounding tissues, and currently, your range of joint movement on trunk posterior flexion is evaluated to be normal."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [],
        },
      },
      [SCORE_YELLOW_KEY]: {
        [SUB_TYPE.TRUNK_LATERAL_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of movement on trunk left/right lateral flexion is slightly limited. In this case, it is recommended to take caution when performing movements such as lifting an object from the ground."
            ),
            $L(
              "Range of movement on trunk left/right lateral flexion is slightly limited, and it can have influence in enjoying hobbies such as Yoga or Pilates."
            ),
            $L(
              "Range of movement on trunk left/right lateral flexion is somewhat limited. Limitation of mobility on trunk left/right lateral flexion can influence various activities such as hobbies and sports, etc."
            ),
            $L(
              "Range of movement on trunk left/right lateral flexion is somewhat limited. Limitation in range of movement on trunk left/right lateral flexion can influence the walking habits."
            ),
            $L(
              "Range of movement on trunk left/right lateral flexion is somewhat limited. Limitation in range of movement on trunk left/right lateral flexion can have influence on walking."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the trunk mobility to the normal range, proper exercise is required."
            ),
            $L("Proper exercise are required for recovery."),
            $L(
              "Excessive strain on the back can be reduced through proper weight control to prevent the limitation in trunk joint mobility."
            ),
            $L(
              "To prevent excessive strain on the trunk joint, limitation in range of joint movement can be prevented by maintaining a proper posture."
            ),
            $L(
              "When the abdominal muscle strength is weakened, it can result in limitation in range of movement, so proper muscular strength exercise is recommended."
            ),
            $L(
              "When relevant muscles are reduced, it can result in limitation in range of movement, so proper stretching is recommended."
            ),
            $L(
              "Increase the stability of the joint through stabilization exercise on the back."
            ),
            $L(
              "To maintain the normal range of trunk joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your joint smoothly to the possible range to relax the back."
            ),
          ],
        },
        [SUB_TYPE.TRUNK_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of joint movement on trunk flexion is slightly limited. Limitation of trunk joint mobility can influence various activities such as hobbies and sports, etc."
            ),
            $L(
              "Limitation in range of movement on trunk anterior flexion can restrict you from enjoying hobbies that require balance such as ballet, figure skating, surfing and yoga, etc."
            ),
            $L(
              "Range of movement on trunk anterior flexion is slightly limited. In this case, it is recommended to take caution when performing movements such as picking up an object from the ground."
            ),
            $L(
              "Range of movement on trunk anterior flexion is slightly limited. In this case, it may be difficult to drive a car for a long time."
            ),
            $L(
              "Range of movement on trunk anterior flexion is slightly limited. In this case, there may be limitations in gardening or cleaning."
            ),
            $L(
              "Limitation in range of movement on trunk anterior flexion can influence the walking habits."
            ),
            $L(
              "Limitation in range of movement on lumbar anterior flexion can have influence on walking."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the trunk mobility to the normal range, proper exercise is required."
            ),
            $L("Proper exercise are required for recovery."),
            $L(
              "Excessive strain on the back can be reduced through proper weight control to prevent the limitation in trunk joint mobility."
            ),
            $L(
              "To prevent excessive strain on the trunk joint, limitation in range of joint movement can be prevented by maintaining a proper posture."
            ),
            $L(
              "When the abdominal muscle strength is weakened, it can result in limitation in range of movement, so proper muscular strength exercise is recommended."
            ),
            $L(
              "When relevant muscles are reduced, it can result in limitation in range of movement, so proper stretching is recommended."
            ),
            $L(
              "Increase the stability of the joint through stabilization exercise on the back."
            ),
            $L(
              "To maintain the normal range of trunk joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your joint smoothly to the possible range to relax the back."
            ),
          ],
        },
        [SUB_TYPE.TRUNK_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Range of movement on lumbar extension is slightly limited. Limitation of lumbar joint mobility can influence you from enjoying hobbies such as Yoga, Pilates and playing an instrument."
            ),
            $L(
              "Range of movement on lumbar extension is slightly limited. In this case, it may be difficult to participate in sports that require balance such as surfing."
            ),
            $L(
              "Range of movement on lumbar extension is slightly limited. Limitation of lumbar joint mobility can influence you from enjoying sports activities that require stretching such as ski, etc."
            ),
            $L(
              "Range of movement on lumbar extension is slightly limited. Limitation in range of movement on lumbar extension can have influence on gait and walking."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "To restore the trunk mobility to the normal range, proper exercise is required."
            ),
            $L("Proper exercise are required for recovery."),
            $L(
              "Excessive strain on the back can be reduced through proper weight control to prevent the limitation in trunk joint mobility."
            ),
            $L(
              "To prevent excessive strain on the trunk joint, limitation in range of joint movement can be prevented by maintaining a proper posture."
            ),
            $L(
              "When the abdominal muscle strength is weakened, it can result in limitation in range of movement, so proper muscular strength exercise is recommended."
            ),
            $L(
              "When relevant muscles are reduced, it can result in limitation in range of movement, so proper stretching is recommended."
            ),
            $L(
              "Increase the stability of the joint through stabilization exercise on the back."
            ),
            $L(
              "To maintain the normal range of trunk joint movement, please maintain your joint health through regular exercise, stretching and proper posture."
            ),
            $L(
              "Stretch your joint smoothly to the possible range to relax the back."
            ),
          ],
        },
      },
      [SCORE_RED_KEY]: {
        [SUB_TYPE.TRUNK_LATERAL_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Limitation in range of movement on trunk left/right lateral flexion is serious. When this condition is continued, it may be difficult to stand or maintain one posture for a long time."
            ),
            $L(
              "Limitation in range of movement on trunk left/right lateral flexion is serious. Caution is required because when this condition is continued, it may develop into scoliosis."
            ),
            $L(
              "Limitation in range of movement on trunk left/right lateral flexion is serious. When this condition is continued, it may be difficult to maintain the posture and balance to have influence also on other parts of the body such as the hip joint and knee, etc."
            ),
            $L(
              "Range of movement on trunk left/right lateral flexion is seriously limited. Due to this, your quality of life in daily activities can be reduced, and moreover, it can accompany mental stress."
            ),
            $L(
              "Limitation in range of movement on trunk left/right lateral flexion is serious. Limitation in range of joint movement on trunk left/right lateral flexion can restrict the performance of movement in daily activities."
            ),
            $L(
              "Limitation in range of movement on trunk left/right lateral flexion is serious. When there is limitation in the range of movement on the trunk, it may be difficult to perform daily activities such as putting socks on."
            ),
            $L(
              "Limitation in range of movement on trunk left/right lateral flexion is serious. When the range of movement on the trunk is seriously limited, it is recommended to wash your hair while standing up instead of bending your back."
            ),
            $L(
              "Limitation in range of movement on trunk left/right lateral flexion is serious. When the range of movement on the trunk is seriously limited, it may have influence on walking."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "In this case, it is recommended to consult with the specialist."
            ),
            $L(
              "To evaluate the cause, it is recommended to visit the local hospital."
            ),
            $L(
              "After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "It can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
        [SUB_TYPE.TRUNK_FLEXION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "The range of movement on lumbar anterior flexion is seriously limited, and you may experience difficulty in performing daily activities smoothly such as lifting objects from a high place, etc."
            ),
            $L(
              "Limitation of trunk anterior flexion is serious, and it may be difficult to perform movement that are required in the daily activities."
            ),
            $L(
              "Limitation in range of movement on trunk anterior flexion is serious. Limitation in range of joint movement on trunk anterior flexion can restrict the performance of movement in daily activities."
            ),
            $L(
              "Limitation in range of movement on trunk anterior flexion is serious. Limitation in range of movement on trunk anterior flexion can reduce the efficiency of body activities and movements."
            ),
            $L(
              "Range of movement on trunk anterior flexion is seriously limited. In this case, it may be difficult to put on your shoes."
            ),
            $L(
              "Limitation in range of movement on trunk anterior flexion is serious. When there is limitation in the range of movement on the trunk anterior flexion, it may be difficult to perform daily activities such as putting socks on."
            ),
            $L(
              "Limitation in range of movement on trunk anterior flexion is serious. When the range of movement on the trunk anterior flexion is seriously limited, it may have influence on walking."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "In this case, it is recommended to consult with the specialist."
            ),
            $L(
              "To evaluate the cause, it is recommended to visit the local hospital."
            ),
            $L(
              "After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "It can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
        [SUB_TYPE.TRUNK_EXTENSION]: {
          [FEEDBACK_TYPE.MAIN_FEEDBACK]: [
            $L(
              "Limitation in range of movement on lumbar extension is serious. When this condition is continued, it may be difficult to maintain a erect posture, and this can have influence on walking (gait)."
            ),
            $L(
              "Limitation in range of movement on lumbar extension is serious. When this condition is continued, there can be problems in sitting or standing for a long time."
            ),
            $L(
              "Limitation in range of movement on lumbar extension is serious. When this condition is continued, it may be difficult to perform movements such as getting up from the bed or standing up while sitting."
            ),
            $L(
              "Limitation in range of movement on lumbar extension is serious. When this condition is continued, you may not be able to stretch your back to have difficulty in lifting or moving heavy items."
            ),
            $L(
              "Limitation in range of movement on lumbar extension is serious. Due to this, your quality of life in daily activities can be reduced, and moreover, it can accompany mental stress."
            ),
            $L(
              "Limitation in range of movement on lumbar extension is serious. When the range of movement on the trunk extension is seriously limited, it may have influence on walking."
            ),
          ],
          [FEEDBACK_TYPE.SUB_FEEDBACK]: [
            $L(
              "In this case, it is recommended to consult with the specialist."
            ),
            $L(
              "To evaluate the cause, it is recommended to visit the local hospital."
            ),
            $L(
              "After consulting with the specialist, it is recommended to establish an appropriate treatment plan."
            ),
            $L(
              "After consulting with a specialist, consistent management is required along with proper treatment for improving the mobility."
            ),
            $L(
              "Continuous management and monitoring are required. Monitor the improvement and worsening of the symptoms through consultation with the specialist."
            ),
            $L(
              "There can be many causes, and it can be related to the age, neural damage, muscular weakening or other health issues. Therefore, it is recommended to consult with the specialist to receive evaluation when the symptom or issue continues."
            ),
            $L(
              "It can cause secondary symptoms such as spasticity, muscular weakening, pain and edema, etc., so when it is difficult to perform daily activities, it is recommended that you visit the local hospital."
            ),
          ],
        },
      },
    },
  };
};
