/**
 * TScroller
 *
 * @module TScroller
 */

import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef} from 'react';
import css from './TScroller.module.less';
import Scroller from '@enact/sandstone/Scroller/Scroller';
import {Job} from '@enact/core/util';
import {on, off} from '@enact/core/dispatcher';
import { useSelector } from "react-redux";

const TScroller = ({className, children, verticalScrollbar='auto', focusableScrollbar=true, direction='vertical', horizontalScrollbar='hidden', scrollMode, onScrollStart, onScrollStop, ...rest}) => {
    const isScrolling = useRef(false);
	const scrollPosition = useRef("top");
    const [onMoveScrollBarEffect, setOnMoveScrollBarEffect] = useState(false);
	const offMoveScrollBarEffect = new Job((func) => func(), 1000);
    const { themeMode } = useSelector((state) => state.common.appStatus);
    const [isMounted, setIsMounted] = useState(false);
    useEffect(()=>{
        setIsMounted(true);
        return ()=>{
            setIsMounted(false);
        }
    },[]);

    const handleWheel = useCallback(() => {
        if (!onMoveScrollBarEffect) {
            setOnMoveScrollBarEffect(true);
            offMoveScrollBarEffect.current.start(setOnMoveScrollBarEffect);
        } else {
            return;
        }
    }, [offMoveScrollBarEffect, onMoveScrollBarEffect]);
    useEffect(() => {
        on('wheel', handleWheel, document.getElementById('TScroller'));
        return () => {
            off('wheel', handleWheel, document.getElementById('TScroller'));
        };
    }, [handleWheel]);

    const _onScrollStart = useCallback((ev) => {
		if(onScrollStart){
			onScrollStart(ev);
		}
		isScrolling.current = true;
	}, [onScrollStart]);
	const _onScrollStop = useCallback((ev) => {
		if(onScrollStop){
			onScrollStop(ev);
		}
		isScrolling.current = false;
		if(ev.reachedEdgeInfo){
			if(ev.reachedEdgeInfo.top){
				scrollPosition.current = "top";
			}else if(ev.reachedEdgeInfo.bottom){
				scrollPosition.current = "bottom";
			}else{
				scrollPosition.current = "middle";
			}
		}else{
			scrollPosition.current = "middle";
		}
	}, [onScrollStop]);

    // delete rest.snapToCenter;
    return (
        <Scroller
            {...rest}
            onScrollStart = {_onScrollStart}
            onScrollStop = {_onScrollStop}
            id='TScroller'
            scrollMode={scrollMode || 'translate'}
            focusableScrollbar={focusableScrollbar}
            className={classNames(
                className,
                isMounted && css.tScroller,
                onMoveScrollBarEffect ? css.onMove : '',
                themeMode === "dark" && css.isDark
            )}
            direction={direction}
            horizontalScrollbar={horizontalScrollbar}
            verticalScrollbar={verticalScrollbar}
            overscrollEffectOn={{
                arrowKey: false,
                drag: false,
                pageKey: false,
                track: false,
                wheel: false
            }}
        >
            {children}
        </Scroller>
    );
};

export default TScroller;
