import { clampingNumber } from "./helperMethods"

export const exerciseType = {
  WALKING: 'walking',
  STAIRS: 'stairs',
  HILL: 'hill',
  HIKING: 'hiking'
}

const ACTIVITY_MAT = {
  walking: 3.8,
  jogging: 7.0,
  hiking: 7.5,
  golf: 3.5
}

const weightByAge = {
  20: { male: 1, female: 0.9 },
  30: { male: 0.98, female: 0.88 },
  40: { male: 0.96, female: 0.86 },
  50: { male: 0.94, female: 0.84 },
  60: { male: 0.92, female: 0.82 },
  70: { male: 0.9, female: 0.8 }
}

const weightByWeight = {
  40: { male: 0.85, female: 0.9 },
  50: { male: 0.9, female: 0.95 },
  60: { male: 0.95, female: 1 },
  70: { male: 1, female: 1.05 },
  80: { male: 1.05, female: 1.1 },
  90: { male: 1.1, female: 1.15 },
  100: { male: 1.15, female: 1.2 },
  110: { male: 1.2, female: 1.25 }
}

// x: km/h, y: calories
const linearRegressionData = {
  [exerciseType.WALKING]: [
    { x: 3, y: 210 },
    { x: 4, y: 260 },
    { x: 5, y: 310 },
    { x: 6, y: 360 },
    { x: 7, y: 420 }
  ],
  [exerciseType.STAIRS]: [
    { x: 1, y: 150 },
    { x: 2, y: 220 },
    { x: 3, y: 300 }
  ],
  [exerciseType.HILL]: [
    { x: 2, y: 180 },
    { x: 3, y: 250 },
    { x: 4, y: 320 }
  ],
  [exerciseType.HIKING]: [
    { x: 2, y: 170 },
    { x: 3, y: 240 },
    { x: 4, y: 310 }
  ]
}

const slope = (dataKey) => {
  const data = linearRegressionData[dataKey];
  const xAvg = data.reduce((acc, cur) => acc + cur.x, 0) / data.length;
  const yAvg = data.reduce((acc, cur) => acc + cur.y, 0) / data.length;

  let numerator = 0;
  let denominator = 0;

  for (let i = 0; i < data.length; i++) {
    numerator += (data[i].x - xAvg) * (data[i].y - yAvg);
    denominator += (data[i].x - xAvg) ** 2;
  }

  return numerator / denominator;
}

const linearRegressionConstant = (dataKey) => {
  const data = linearRegressionData[dataKey];
  const xAvg = data.reduce((acc, cur) => acc + cur.x, 0) / data.length;
  const yAvg = data.reduce((acc, cur) => acc + cur.y, 0) / data.length;
  const slopeValue = slope(dataKey);

  return yAvg - slopeValue * xAvg;
}

// export const calorieCalc = (dataKey, met, exerciseTime, gender, age, weight) => {
//   const baseExpenditure = slope(dataKey) * met + linearRegressionConstant(dataKey);

//   const ageValue = weightByAge[clampingNumber(Math.floor(age / 10) * 10, 20, 70)][gender];
//   const weightValue = weightByWeight[clampingNumber(Math.floor(weight / 10) * 10, 40, 110)][gender];

//   return Number(((baseExpenditure * (exerciseTime / 3600)) * ageValue * weightValue).toFixed(0));
// }

export const calorieCalc = (category, weight = 66.55, exerciseTime) => {
  return Number((ACTIVITY_MAT[category] * (3.5 * weight * (exerciseTime / 60)) * 5 / 1000).toFixed(0)) || 0;
}