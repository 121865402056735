import { useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gattAutoConnect, gattConnect, CONNECTION_STATUS, cancelScanAndGetDevice} from "../features/ble/bleSlice";
import usePrevious from "./usePrevious";

const useBLE = ({autoConnect=false}) => {
  const dispatch = useDispatch();
  const autoConnectRef = usePrevious(autoConnect);
  const bleDeviceList = useSelector(state => state.ble.bleDeviceList);
  const isBleSearching = useSelector(state => state.ble.isBleSearching);
  const lastConnectedDevices =	useSelector(state => state.common.localSettings.lastConnectedBleDevices);
  const bleConnectStatus = useSelector((state) => state.ble.gattConnectingStatus.connectStatus);
  const bleConnectStatusRef = usePrevious(bleConnectStatus);
  const isBleSearchingRef = usePrevious(isBleSearching);

  useEffect(()=>{
    if(autoConnect){
      dispatch(gattAutoConnect());
    }
    return ()=>{
      if(isBleSearchingRef.current || autoConnectRef.current){
        dispatch(cancelScanAndGetDevice());
      }
    }
  },[autoConnect]);

  useEffect(()=>{
    if(autoConnect && bleConnectStatus !== CONNECTION_STATUS.connected){
      const targetDeviceAddress= [];
      for(let i = 0; i < bleDeviceList.length; i++){
        if(lastConnectedDevices.indexOf(bleDeviceList[i].address) >= 0) {
            targetDeviceAddress.push(bleDeviceList[i].address);
        }
      }
      if(targetDeviceAddress.length > 0){
        for(let i = lastConnectedDevices.length-1; i >=0; i--){
          if(targetDeviceAddress.indexOf(lastConnectedDevices[i])>=0){
            console.log('useBLE try auto connect ', lastConnectedDevices[i]);
            dispatch(gattConnect(lastConnectedDevices[i]));
            break;
          }
        }
      }
    }
  },[bleDeviceList]);

  return { bleConnectStatus, bleConnectStatusRef, bleDeviceList, gattAutoConnect };
};

export default useBLE;