import classNames from "classnames";
import css from "./SRadio.module.less";
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { $L } from "../../../../utils/helperMethods";
import TButton, {COLOR, SIZES} from "../../../../components/TButton/TButton";
import { updateSurvey } from "../../../../features/fitService/fitServiceSlice";

const SRadio = ({question, className}) => {
  const dispatch = useDispatch()
  const answer = useSelector((state) => state.fitService.survey);

  const _onClick = useCallback((item) => (ev) => {
    dispatch(updateSurvey({[question.id]: item.seq}))
  }, [dispatch, question])

	return (
    answer && 
    <div className={classNames(css.radio, className)}>
      {question.article.map((item, index) => (
        <TButton
          className={css.item}
          key={"radio" + index}
          selected={answer[question.id] === item.seq}
          onClick={_onClick(item)}
        >
          {$L(item.article_title)}
        </TButton>
      ))}
    </div>
	);
};

export default SRadio;