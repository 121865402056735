import css from "./DebugPanel.module.less";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import SwitchItem from '@enact/sandstone/SwitchItem';
import RadioItem from '@enact/sandstone/RadioItem';
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import RangePicker from '@enact/sandstone/RangePicker';
import TPanel from "../../components/TPanel";
import THeader from "../../components/THeader";
import TScroller from "../../components/TScroller/TScroller";
import appinfo from '../../../webos-meta/appinfo.json';
import {  changeLocalSettings } from "../../features/common/commonSlice";
import WebWorkerUtil, {WORKER_ID} from "../../utils/WebWorker/WebWorkerUtil";
import { setAudioFeedback } from "../../lunaSend/TTSService";
import { getUrl, FIT_BASEURL_DEV, FIT_BASEURL_TWIN } from '../../features/fitService/fitServiceConfig';
import TInput from "../../components/TInput/TInput";
import TButton from "../../components/TButton/TButton";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");
/**
 * @module DebugPanel
 */

function DebugPanel({...rest}) {
  const dispatch = useDispatch();
  const appStatus = useSelector((state) => state.common.appStatus);
  const {cameraList, activatedCameraId, updatingCameraList} = useSelector(state => state.camera);
  const localSettings = useSelector((state) => state.common.localSettings);
  const [serverUrl, setServerUrl] = useState('');
  const [xFitEngineVersion, setXFitEngineVersion] = useState('');
  const [hitEngineVersion, setHitEngineVersion] = useState('');
  const mXFitWorker = useRef(null);
  const mHitWorker = useRef(null);

  const onResponseXFitWorker = useCallback((e)=>{
	if(e.type === 'getVersion'){
		setXFitEngineVersion(e.value);
	}
  },[]);
  const onResponseHitWorker = useCallback((e)=>{
	console.log('onResponseHitWorker', e);
	if(e.type === 'getVersion'){
		setHitEngineVersion(e.value);
	}
  },[]);

  useEffect(()=>{
	WebWorkerUtil.makeWorker(WORKER_ID.XFIT, onResponseXFitWorker).then((workerId)=>{
		mXFitWorker.current = workerId;
		WebWorkerUtil.postMessage(mXFitWorker.current, {type:"getVersion"}, true);
	});
	WebWorkerUtil.makeWorker(WORKER_ID.HIT, onResponseHitWorker).then((workerId)=>{
		mHitWorker.current = workerId;
		WebWorkerUtil.postMessage(mHitWorker.current, {type:"getVersion"}, true);
		WebWorkerUtil.postMessage(mHitWorker.current, {type:"initWalkMode", value:[2, 0]}, true);
		WebWorkerUtil.postMessage(mHitWorker.current, {type:"resetWalkMode"}, true);
		WebWorkerUtil.postMessage(mHitWorker.current, {type:"setWalkModeParams", value:[65, 4]}, true);
		setTimeout(() => {
			WebWorkerUtil.postMessage(mHitWorker.current, {type:"setWalkInputData", value:[0x101 & 0x1111]}, false);
		}, 1);
		setTimeout(() => {
			WebWorkerUtil.postMessage(mHitWorker.current, {type:"setWalkInputData", value:[0x100 & 0x1111]}, false);
		}, 100);
		setTimeout(() => {
			WebWorkerUtil.postMessage(mHitWorker.current, {type:"setWalkInputData", value:[0x001 & 0x1111]}, false);
			WebWorkerUtil.postMessage(mHitWorker.current, {type:"getWalkOutputData"}, true);
		}, 200);
	});
  },[]);
  const cameraName = useMemo(() => {
	for(let i in cameraList){
		if(cameraList[i].deviceId === activatedCameraId){
			return cameraList[i].label;
		}
	}
	return "";
   }, [cameraList, activatedCameraId]);

  const infos = useMemo(() => {
		let v = [];
		v.push({title: 'Version(App)', value: appinfo.version});
		v.push({title: 'WebOS', value: appStatus.webOSVersion+"("+appStatus.webOSVersionReal+")"});
		v.push({title: 'EMPAccount(account,lang)', value: appStatus.userNumber+","+localSettings.languageSetting});
		v.push({title: 'DeviceId', value: appStatus.deviceId});
		v.push({title: 'xFitEngine', value: xFitEngineVersion});
		v.push({title: 'HitEngine', value: hitEngineVersion});
		v.push({title: 'Camera', value: cameraName});
		v.push({title: ' ', value: ' '});
		return v;
	}, [appStatus, xFitEngineVersion, hitEngineVersion, cameraName]);

  useEffect(() => {
	let url = "";
	switch (localSettings.serverType) {
	  case 'dev':
		url = FIT_BASEURL_DEV;
		break;
	  case 'twin':
		url = FIT_BASEURL_TWIN;
		break;
	  case 'local':
		url = localSettings.localURL;
		break;
	}
	setServerUrl(url);
  }, [localSettings.serverType]);

  const switches = useMemo(() => {
		let v = [];
		v.push({title: 'CES SHOW Mode(Reboot)', value: localSettings.cesShowMode, important: true, func:({selected}) => {
			// dispatch(changeLocalSettings({ cesShowMode: selected, skipVideoGuide: selected, useBleSerial: selected }));
			dispatch(changeLocalSettings({ cesShowMode: selected }));
			if(typeof window === 'object'){
				window.location.reload();
			}
		}});
		v.push({title: 'Fake Login', value: localSettings.useFakeLogin, func:({selected}) => {
			dispatch(changeLocalSettings({ useFakeLogin: selected }));
		}});
		v.push({
			title: "AudioFeedback(TV only)",
			value: localSettings.setAudioFeedback,
			func: ({ selected }) => {
				setAudioFeedback(selected);
				dispatch(changeLocalSettings({ setAudioFeedback: selected }));
			},
		});
		v.push({
		title: "CustomDummyData(Window only)",
		value: localSettings.showCustomDummyData,
		func: ({ selected }) => {
			dispatch(changeLocalSettings({ showCustomDummyData: selected }));
			}});
		v.push({title: 'USB SerialMode(Reboot)', value: localSettings.useBleSerial, func:({selected}) => {
			dispatch(changeLocalSettings({ useBleSerial: selected }));
			if(typeof window === 'object'){
				window.location.reload();
			}
		}});
		v.push({title: 'Ble Mat dummy', value: localSettings.useBleDummy, func:({selected}) => {
			dispatch(changeLocalSettings({ useBleDummy: selected }));
		}});
		v.push({title: 'Debug Skip VideoGuide', value: localSettings.skipVideoGuide, func:({selected}) => {
			dispatch(changeLocalSettings({ skipVideoGuide: selected }));
		}});
		v.push({title: 'Debug Show BodyCoordinations', value: localSettings.showBodyCoordinations, func:({selected}) => {
			dispatch(changeLocalSettings({ showBodyCoordinations: selected }));
		}});
		v.push({title: 'XFit Body: setCustomBaseline', value: localSettings.xFitBodySetCustomBaseline, func:({selected}) => {
			dispatch(changeLocalSettings({ xFitBodySetCustomBaseline: selected }));
		}});
		v.push({title: 'Camera Recording', value: localSettings.cameraRecordingActivated, func:({selected}) => {
			dispatch(changeLocalSettings({ cameraRecordingActivated: selected }));
		}});
		return v;
	}, [dispatch, localSettings]);

	const rangepickers = useMemo(() => {
		let v = [];
		v.push({title: 'Camera : ROI SIZE', value: localSettings.roiSize, max: 0.5, min: 0.1, step: 0.1, func:({value}) => {
			dispatch(changeLocalSettings({ roiSize: Math.round(value*10)/10 }));
		}});
		v.push({title: 'SFT : EnduranceRatio', value: localSettings.sftEnduranceRatio, max: 90, min: 0, step: 10, func:({value}) => {
			dispatch(changeLocalSettings({ sftEnduranceRatio: value }));
		}});
		v.push({title: 'Climing : EnduranceRatio High 90°', value: localSettings.climingEnduranceRatio_90, max: 90, min: 0, step: 10, func:({value}) => {
			dispatch(changeLocalSettings({ climingEnduranceRatio_90: value }));
		}});
		v.push({title: 'Climing : EnduranceRatio Middle 60°', value: localSettings.climingEnduranceRatio_60, max: 90, min: 0, step: 10, func:({value}) => {
			dispatch(changeLocalSettings({ climingEnduranceRatio_60: value }));
		}});
		v.push({title: 'Climing : EnduranceRatio Low 30°', value: localSettings.climingEnduranceRatio_30, max: 90, min: 0, step: 10, func:({value}) => {
			dispatch(changeLocalSettings({ climingEnduranceRatio_30: value }));
		}});
		v.push({title: 'XFit Body: Process Queue Size(1~10)', value: localSettings.xFitBodyProcessQueueSize, max: 10, min: 1, step: 1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitBodyProcessQueueSize: value }));
		}});
		v.push({title: 'XFit : Bottom Position(Engine)(70~95)', value: localSettings.xFitBodyBottomPosition, max: 95, min: 70, step: 1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitBodyBottomPosition: value }));
		}});
		v.push({title: 'XFit : Bottom Position(GUI)(70~95)', value: localSettings.xFitBodyBottomPositionGUI, max: 95, min: 70, step: 1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitBodyBottomPositionGUI: value }));
		}});
		v.push({title: '임상시험 변화량 기준시간(sec)(1~10)', value: localSettings.xFitKTestThresHoldInterval, max: 10, min: 1, step: 1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitKTestThresHoldInterval: value }));
		}});
		v.push({title: '임상시험 외발서기 어깨각도 기준(0~10)', value: localSettings.xFitKTestThresHoldOneLegShoulderAngle, max: 10, min: 0, step: 0.1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitKTestThresHoldOneLegShoulderAngle: Number(value.toFixed(1)) }));
		}});
		v.push({title: '임상시험 외발서기 어깨 좌표 기준(Pixel)(1~100)', value: localSettings.xFitKTestThresHoldOneLegShoulderDistance, max: 100, min: 1, step: 1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitKTestThresHoldOneLegShoulderDistance: value }));
		}});
		v.push({title: '임상시험 파워워킹 어깨각도 기준(0~10)', value: localSettings.xFitKTestThresHoldWalkingShoulderAngle, max: 10, min: 0, step: 0.1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitKTestThresHoldWalkingShoulderAngle: Number(value.toFixed(1)) }));
		}});
		v.push({title: '임상시험 파워워킹 어깨 좌표 기준(Pixel)(1~100)', value: localSettings.xFitKTestThresHoldWalkingShoulderDistance, max: 100, min: 1, step: 1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitKTestThresHoldWalkingShoulderDistance: value }));
		}});
		v.push({title: '임상시험 파워워킹 골반 기준(0~10)', value: localSettings.xFitKTestThresHoldWalkingPelvicAngle, max: 10, min: 0, step: 0.1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitKTestThresHoldWalkingPelvicAngle: Number(value.toFixed(1)) }));
		}});
		v.push({title: '임상시험 파워워킹 골반 좌표 기준(Pixel)(1~100)', value: localSettings.xFitKTestThresHoldWalkingPelvicDistance, max: 100, min: 1, step: 1, func:({value}) => {
			dispatch(changeLocalSettings({ xFitKTestThresHoldWalkingPelvicDistance: value }));
		}});
		return v;
	}, [dispatch, localSettings]);



	const handleUrlChange = useCallback((e) => {
		setServerUrl(e.value);
	}, []);

	const handleUrlSave = useCallback((e) => {
		if (localSettings.serverType === 'local') {
			dispatch(changeLocalSettings({ localURL: serverUrl }));
			if(typeof window === 'object'){
				window.location.reload();
			}
		}
	}, [serverUrl, localSettings]);


	const onChangeServerSetting = useCallback((type) => () => {
		dispatch(changeLocalSettings({ serverType: type }));
		if(typeof window === 'object' && type !== 'local'){
			window.location.reload();
		}
	}, [dispatch]);


	const onChangeLanguageSetting = useCallback((type) => () => {
		dispatch(changeLocalSettings({ languageSetting: type }));
		if(typeof window === 'object'){
			window.location.reload();
		}
	},[dispatch]);

	const onChange2DPose = useCallback((type) => () => {
		dispatch(changeLocalSettings({ camera2DPose: type }));
	}, [dispatch]);

	const onChangeRPPGSetting = useCallback((type) => () => {
		dispatch(changeLocalSettings({ cameraRPPGModel: type }));
	}, [dispatch]);

	return (
	<TPanel {...rest}>
		<THeader title={"Debug Panel"} iconType="back"/>
		{infos.map((item, index) =>
			{return(
				<div className={css.titleArea} key={"info"+index}>
					<div className={css.titleBox}>
						<div className={css.text}>{item.title}</div>
					</div>
					<div className={css.textArea}>
						<div className={css.text}>{item.value}</div>
					</div>
				</div>
			)
			}
		)}
		<TScroller className={css.scroller}>
			<Container className={css.settingLayer}>
				<Container className={css.titleArea}>
					<div className={css.switchs}>
						<span className={css.switchTitle}>{'Server(Reboot)'}</span>
						<RadioItem	className={css.switch} selected={localSettings.serverType === 'dev'} onClick={onChangeServerSetting('dev')}>dev</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.serverType === 'twin'} onClick={onChangeServerSetting('twin')}>twin</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.serverType === 'local'} onClick={onChangeServerSetting('local')}>local</RadioItem>
		  			</div>
					<div className={css.urlInputSect}>
						<TInput
							className={css.input}
							autoFocus
							type={"text"}
							dismissOnEnter
							value={serverUrl}
							onChange={handleUrlChange}
							disabled={localSettings.serverType !== 'local'}
							placeholder={serverUrl}
							spotlightDisabled={localSettings.serverType !== 'local' ? true : false}
						/>
						{localSettings.serverType === 'local' && <TButton onClick={handleUrlSave}>저장</TButton>}
					</div>
				</Container>
				<Container className={css.titleArea}>
					<div className={css.switchs}>
						<span className={css.switchTitle}>{'LanguageSetting(Reboot)'}</span>
						<RadioItem	className={css.switch} selected={localSettings.languageSetting === 'system'} onClick={onChangeLanguageSetting('system')}>System</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.languageSetting === 'en'} onClick={onChangeLanguageSetting('en')}>en</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.languageSetting === 'ko'} onClick={onChangeLanguageSetting('ko')}>ko</RadioItem>
					</div>
				</Container>
				<Container className={css.titleArea}>
					<div className={css.switchs}>
						<span className={css.switchTitle}>{'Camera 2DPose Setting'}</span>
						<RadioItem	className={css.switch} selected={localSettings.camera2DPose === 'NPU_mid'} onClick={onChange2DPose('NPU_mid')}>NPU_mid</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.camera2DPose === 'NPU_low'} onClick={onChange2DPose('NPU_low')}>NPU_low</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.camera2DPose === 'GPU_mid'} onClick={onChange2DPose('GPU_mid')}>GPU_mid</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.camera2DPose === 'GPU_low'} onClick={onChange2DPose('GPU_low')}>GPU_low</RadioItem>
					</div>
				</Container>
				<Container className={css.titleArea}>
					<div className={css.switchs}>
						<span className={css.switchTitle}>{'Camera rPPG Setting'}</span>
						<RadioItem	className={css.switch} selected={localSettings.cameraRPPGModel === 'CPU'} onClick={onChangeRPPGSetting('CPU')}>CPU</RadioItem>
						<RadioItem	className={css.switch} selected={localSettings.cameraRPPGModel === 'NPU'} onClick={onChangeRPPGSetting('NPU')}>NPU</RadioItem>
					</div>
				</Container>
				{switches.map((item, index) => {
					return(
					<div className={css.titleArea} key={"switch"+index}>
						<div className={css.switchs}>
							<span className={classNames(css.switchTitle, item.important && css.important)}>{item.title}</span>
							<SwitchItem selected={item.value} onToggle={item.func} className={css.switch}>{item.value ? 'On' : 'Off'}</SwitchItem>
						</div>
					</div>
					)}
				)}
				{rangepickers.map((item, index) =>
					{return(
						<Container className={css.titleArea} key={"pickers"+index}>
							<div className={css.switchs}>
								<span className={css.switchTitle}>{item.title}</span>
								<RangePicker	className={css.switch}
									max={item.max} min={item.min} step={item.step} width="small" value={item.value} onChange={item.func}
								/>
							</div>
						</Container>
					)}
				)}
			</Container>
		</TScroller>
	</TPanel>
  	);
}

export default DebugPanel;