import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lunaSend from "../lunaSend";
import * as HelperMethods from "../utils/helperMethods";
import { setSavingVideoPath } from "../components/PIPCamera/PIPCamera";
import { DATAKEY, updateTestResults, updateCurrentTestingStatus } from "../features/bodyAlignment/bodyAlignmentSlice";
import { localBBox, localBodyCoordinations, localBodyTestResult } from "../../assets/mock/bodyScan/bodyCoordinations";
import { postBodyAlignmentData } from "../features/fitService/fitServiceSlice";
import { frontPicture } from "../../assets/mock/bodyScan/frontPicture.js";
import { sidePicture } from "../../assets/mock/bodyScan/sidePicture.js";
import * as Config from "../utils/Config";
import { updatePanel } from "../features/panels/panelsSlice";

const BBOX_TOP_BOTTOM_MARGIN = 0.1; //percent
const BBOX_TOP_MARGIN = 0.1; //percent
const BBOX_BOTTOM_MARGIN = 0.2; //percent
const OUT_SIZE = { w: 366, h: 480 };

//height 는 box 크기로 고정, width 는 비율에 맞게 재계산
const BASE_JOINT_INDEX = 0; //배꼽아래점을 기준.
const HEAD_TOP_INDEX = 10; //정수리
const ANKLE_INDEX_1 = 3;  //발목
const ANKLE_INDEX_2 = 6;  //발목
//using Points

const calculateCuttingRectWithPoint = (bbox, cameraSize, joint2D) => {
  const ret = { x: 0, y: 0, w: 0, h: 0 };

  // const bbox_center = { x: bbox[0] + bbox[2] / 2, y: bbox[1] + bbox[3] / 2 };
  // ret.x = bbox_center.x - calculatedW / 2;
  if (!joint2D || !cameraSize || !cameraSize.width) {
    console.warn("calculateCuttingRectWithPoint No Data, return bbox", bbox);
    return bbox;
  }
  const mirroredBboxCenterX = cameraSize.width - joint2D[BASE_JOINT_INDEX][0];
  let bottom = joint2D[ANKLE_INDEX_1][1] > joint2D[ANKLE_INDEX_2][1] ? joint2D[ANKLE_INDEX_1][1] : joint2D[ANKLE_INDEX_2][1];
  let top = joint2D[HEAD_TOP_INDEX][1];
  const topMargin = (bottom - top) * BBOX_TOP_MARGIN;
  const bottomMargin = (bottom - top) * BBOX_BOTTOM_MARGIN;
  top -= topMargin;
  bottom += bottomMargin;
  if (top < 0) {
    top = 0;
  }
  if (bottom > (cameraSize.height - 1)) {
    bottom = cameraSize.height - 1;
  }
  ret.y = top;
  ret.h = bottom - top;
  const calculatedW = (ret.h * OUT_SIZE.w) / OUT_SIZE.h;
  ret.x = mirroredBboxCenterX - calculatedW / 2;
  ret.w = calculatedW;
  return ret;
};


const convertCoordinations = (coordinations, cutResult, cameraSize) => {
  const res = [];
  console.log("cutResult in convertCoordinations= ", cutResult);
  if (coordinations !== undefined && cutResult !== undefined) {
    // Due to reversed camera x coordination needs recalculation (ScreenWidth -currentXCoordinate)
    const recalculatedCoordinations = [];

    //mirror
    coordinations.forEach((coordination) => {
      const newXCoordinate = cameraSize.width - coordination[0];
      const updatedCoordinate = [
        newXCoordinate, //x
        coordination[1], //y
        coordination[2], //score
      ];
      recalculatedCoordinations.push(updatedCoordinate);
    });

    for (let i = 0; i < recalculatedCoordinations.length; i++) {
      let calculatedCoords = { x: null, y: null };
      calculatedCoords.x = recalculatedCoordinations[i][0] - cutResult.x;
      calculatedCoords.y = recalculatedCoordinations[i][1] - cutResult.y;
      res.push(calculatedCoords);
    }
  }
  return res;
};
const convertOriginBBox = (bbox, cutResult, cameraSize) => {
  const res = [];
  if (cutResult !== undefined) {
    res[0] = cameraSize.width - bbox[0] - bbox[2]; //mirror
    res[0] -= cutResult.x;
    res[1] = bbox[1] - cutResult.y;
    res[2] = bbox[2];
    res[3] = bbox[3];
  }
  return res;
};
const convertBodyTestResults = (bodyPoints, cutResult) => {
  const res = [];
  if (bodyPoints !== undefined && cutResult !== undefined) {
    for (let i = 0; i < bodyPoints.length; i++) {
      let calculatedCoords = [];
      calculatedCoords[0] = bodyPoints[i][0] - cutResult.x;
      calculatedCoords[1] = bodyPoints[i][1] - cutResult.y;
      res.push(calculatedCoords);
    }
  }
  return res;
};

//subKey only for sftResult
const convertJsonToTsv = (result) => {
  let tsvString = '';
  // header
  tsvString += ' \tbodyTypeFront\tbodyTypeRight{br}';
  // data
  for (let i = 0; i <= 40; i++) {
    const index = `#${i}`;
    const bodyTypeFrontData = result.front && result.front[i];
    const bodyTypeRightData = result.side && result.side[i];
    const x1 = bodyTypeFrontData ? bodyTypeFrontData.x : '';
    const y1 = bodyTypeFrontData ? bodyTypeFrontData.y : '';
    const x2 = bodyTypeRightData ? bodyTypeRightData.x : '';
    const y2 = bodyTypeRightData ? bodyTypeRightData.y : '';
    tsvString += `${index} body key point x value\t${x1}\t${x2}{br}`;
    tsvString += `${index} body key point y value\t${y1}\t${y2}{br}`;
  }
  return tsvString;
};

const useBodyAlignment = ({ onCaptured }) => {
  const dispatch = useDispatch();
  const { userAge, userGender, cesShowMode } = useSelector((state) => state.common.localSettings);
  const currentTestedData = useSelector(state => state.bodyAlignment.current);
  const { cameraSize } = useSelector((state) => state.camera);
  const { usbDeviceUri, userNumber } = useSelector((state) => state.common.appStatus);

  /**
   *
   * @param {*} bodyType  front/side
   */
  const captureImage = (bodyType, bodyCoordinations, bodyTestResults) => {
    if (typeof window === 'object' && !window.PalmSystem) {
      const values = localBodyTestResult[bodyType];
      values.bodyCoordination = localBodyCoordinations[bodyType];
      values.bbox = localBBox[bodyType];
      values.image = bodyType === DATAKEY.FRONT ? frontPicture : sidePicture;
      dispatch(updateCurrentTestingStatus(values));
      if (onCaptured) {
        onCaptured();
      }
      return;
    }
    let bbox = [];

    if (!!bodyCoordinations?.bbox) {
      bbox = bodyCoordinations.bbox;
    }

    console.log("captureImage cameraSize", bodyType, cameraSize);
    if (typeof window === 'object' && !window.PalmSystem) {
      bbox = [560, 100, 120, 300];
    }
    const video = document.querySelector(`[data-spotlight-id="pipcamera"]`);
    const canvas = document.querySelector(`[data-spotlight-id="pipcamera_canvas"]`);
    let recalculatedBodyTestResults = {}, recalculatedTotalBodyCoordinations = [], recalculatedBBox = [];
    if (canvas) {

      //using Point
      const cuttingRect = calculateCuttingRectWithPoint(bbox, cameraSize, bodyCoordinations?.joints2D);

      console.log(
        "captureImage bodyCoordinations",
        bodyType,
        bodyCoordinations
      );
      console.log("captureImage bodyTestResults", bodyType, bodyTestResults);
      if (!!bodyCoordinations?.joints2D) {
        recalculatedTotalBodyCoordinations = convertCoordinations(bodyCoordinations.joints2D, cuttingRect, cameraSize);
        recalculatedBBox = convertOriginBBox(bbox, cuttingRect, cameraSize);
        console.log("captureImage recalculatedTotalBodyCoordinations", bodyType, recalculatedTotalBodyCoordinations);

        if (!!bodyTestResults) {
          const mirrorBodyTestResults = {};
          for (const bodySection in bodyTestResults) {
            let bodySectionTestData = bodyTestResults[bodySection];
            let recalculatedBodySection = [
              bodySectionTestData[0],
              bodySectionTestData[1],
              [],
              bodySectionTestData[3],
            ];
            let recalculatedCoordinates = [];

            if (!!bodySectionTestData[2]?.length) {
              recalculatedCoordinates = bodySectionTestData[2].map(
                (testData) => {
                  if (testData === undefined) {
                    return testData;
                  }
                  //mirror
                  return [cameraSize.width - testData[0], testData[1]];
                }
              );
            }
            recalculatedBodySection[2] = recalculatedCoordinates;
            mirrorBodyTestResults[bodySection] = recalculatedBodySection;
          }

          const data = mirrorBodyTestResults;
          const keys = Object.keys(data);
          for (let i = 0; i < keys.length; i++) {
            //yhcho engine dummy data
            if(keys[i] === "Q_angle_left" || keys[i] === "Q_angle_right"){
              continue;
            }
            recalculatedBodyTestResults[keys[i]] = [...data[keys[i]]];
            const recalculatedValues = convertBodyTestResults(data[keys[i]][2], cuttingRect);
            recalculatedBodyTestResults[keys[i]][2] = recalculatedValues;
          }
          console.log("captureImage recalculatedBodyTestResults", bodyType, recalculatedBodyTestResults);
        }
      }

      // canvas.width = width;
      // canvas.height = height;
      canvas.width = cuttingRect.w;
      canvas.height = cuttingRect.h;

      const context = canvas.getContext("2d");

      context.save();
      context.scale(-1, 1);
      // context.translate(-width, 0);
      context.translate(-cuttingRect.w, 0);

      // context.drawImage(video, video.videoWidth/ 3, 0, 100, video.videoHeight);
      //using bbox data from luna-send api
      // context.drawImage(video, x, y, width, height);

      // context.drawImage(video, x, y, width, height, 0, 0, width, height);

      console.log("captureImage cuttingRect....", cuttingRect);

      context.drawImage(video, (cameraSize.width - cuttingRect.x - cuttingRect.w), cuttingRect.y, cuttingRect.w, cuttingRect.h, 0, 0, cuttingRect.w, cuttingRect.h);

      // context.beginPath();
      // context.lineWidth = "4";
      // context.strokeStyle = "red";
      // context.rect(x / 2, y, width / 2, height);
      // context.stroke();

      // context.drawImage(video, video.videoWidth/4, 0, video.videoWidth/ 3, video.videoHeight, 0, 0, video.videoWidth/ 3, video.videoHeight);
      context.restore();

      let generatedImage = canvas.toDataURL("image/jpeg", 0.9);
      console.log("captureImage generatedImage= ", generatedImage);

      const values = recalculatedBodyTestResults;
      values.bodyCoordination = recalculatedTotalBodyCoordinations;
      values.bbox = recalculatedBBox;
      values.image = generatedImage;
      dispatch(updateCurrentTestingStatus(values));
      if (onCaptured) {
        onCaptured();
      }
      return;
    }
  };
  //media/internal/images/
  //tmp/usb/sda/sda1
  const saveImageAndData = useCallback((_currentTestedData) => {
    let _usbDeviceUri = usbDeviceUri;
    if (!_usbDeviceUri) {
      _usbDeviceUri = '/media/internal/bodyscan';
      lunaSend.createToast("No Usb: Data will be saved in internal path");
    } else {
      _usbDeviceUri = usbDeviceUri + '/bodyscan';
    }
    let filePath = "";
    let filePathTsv = "";
    const date = new Date();
    const timeStr = HelperMethods.timeToFilePathStr(date);
    const prePathStr = `${_usbDeviceUri}/${userNumber}-${userAge}-${userGender}/${timeStr}-`;
    if (currentTestedData[DATAKEY.FRONT]) {
      filePath = prePathStr + `Front.jpg`;
      lunaSend.writeBase64Image({ path: filePath, data: currentTestedData[DATAKEY.FRONT].image },
        {
          onFailure: () => {
            lunaSend.createToast("Failed to Save");
          }
        }
      );
    }
    if (currentTestedData[DATAKEY.SIDE]) {
      filePath = prePathStr + `Side.jpg`;
      lunaSend.writeBase64Image({ path: filePath, data: currentTestedData[DATAKEY.SIDE].image }, {});
    }
    try {
      const bodyCoord = {};
      bodyCoord.bodyTypeFront = currentTestedData[DATAKEY.FRONT].bodyCoordination;
      bodyCoord.bodyTypeRight = currentTestedData[DATAKEY.SIDE].bodyCoordination;
      const testResult = JSON.stringify(bodyCoord);
      filePath = prePathStr + `BodyCoord.dat`;
      lunaSend.writeFile({ path: filePath, data: testResult }, {});
      const BodyCoordResult = convertJsonToTsv(bodyCoord);
      filePathTsv = prePathStr + `BodyCoord.tsv`;
      lunaSend.writeFile({ path: filePathTsv, data: BodyCoordResult }, {});
    } catch (e) {
      console.log('saveImageAndData e', e);
    }
    try {
      const result = {};
      result.bodyTypeFront = { ...currentTestedData[DATAKEY.FRONT] };
      delete result.bodyTypeFront.image;
      delete result.bodyTypeFront.bbox;
      delete result.bodyTypeFront.bodyCoordination;
      result.bodyTypeRight = { ...currentTestedData[DATAKEY.SIDE] };
      delete result.bodyTypeRight.image;
      delete result.bodyTypeRight.bbox;
      delete result.bodyTypeRight.bodyCoordination;
      const testResult = JSON.stringify(result);
      filePath = prePathStr + `TestResult.dat`;
      lunaSend.writeFile({ path: filePath, data: testResult }, {});
    } catch (e) {
      console.log('saveImageAndData e', e);
    }
    setSavingVideoPath(prePathStr + 'video.webm');
  }, [userNumber, userAge, userGender, usbDeviceUri, currentTestedData]);

  const saveTestResults = useCallback(() => {
    const now = new Date();
    const keys = Object.keys(DATAKEY);
    const toSave = {};
    const isoDateTime = HelperMethods.timeToISO8601Str(now);
    keys.forEach(key => {
      if (currentTestedData[DATAKEY[key]] && Object.keys(currentTestedData[DATAKEY[key]]).length > 0) {
        toSave[DATAKEY[key]] = { ...currentTestedData[DATAKEY[key]] };
      }
    });
    //update to reducer
    //2023-09-11
    const dateStr = HelperMethods.convertDateToString2(now);
    toSave.date = isoDateTime;
    dispatch(updateTestResults({ date: dateStr, value: toSave }));
    dispatch(updatePanel({ name: Config.panel_names.BODY_ALIGNMENT_REPORT, panelInfo: { selectedDate: dateStr } }));
    // saveImageAndData();
    //push to server
    if(!cesShowMode){
      const toServerSave = HelperMethods.cloneObject(toSave);
      toServerSave.date = isoDateTime;
      dispatch(postBodyAlignmentData(toServerSave));
    }
  }, [dispatch, currentTestedData, saveImageAndData, cesShowMode]);

  return { currentTestedData, captureImage, saveTestResults };
};

export default useBodyAlignment;
