import { useState, useCallback } from 'react';
import TAlarmPicker from '../../../../components/TAlarmPicker/TAlarmPicker';

const TAlarmPickerLayout = () => {
  const [time, setTime] =  useState({
    hours: 0, 
    minutes: 0,
    meridiem: 0
  });

  const { hours, minutes } = time;

  const onChange = useCallback(({ value }) => {
    setTime(value)
  }, []);

  return (
      <>
        <TAlarmPicker alarmTimerHour={hours} alarmTimerMinute={minutes} onChange={onChange} />
      </>
  );
};

export default TAlarmPickerLayout;