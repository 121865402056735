import { $L } from "../../../../utils/helperMethods";

export const USER_GENDER = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

export const getSFT_UNIT = () => {
  return {
    upperStrength: $L("개"),
    lowerStrength: $L("개"),
    stepInPlace: $L("개"),
    balance: $L("초"),
    upandgo: $L("초"),
    lowerFlexibility: $L("cm"),
  };
};

export const SFT_DATA = {
  upperStrength: {
    [USER_GENDER.MALE]: { data: [27, 25, 23, 18, 13], minValue: 11 },
    [USER_GENDER.FEMALE]: { data: [24, 22, 20, 16, 10], minValue: 9 },
  },
  lowerStrength: {
    [USER_GENDER.MALE]: { data: [23, 22, 20, 16, 11], minValue: 9 },
    [USER_GENDER.FEMALE]: { data: [21, 20, 18, 14, 9], minValue: 8 },
  },
  balance: {
    [USER_GENDER.MALE]: { data: [54, 50, 43, 26, 7], minValue: 0 },
    [USER_GENDER.FEMALE]: { data: [50, 46, 39, 22, 4], minValue: 0 },
  },
  lowerFlexibility: {
    [USER_GENDER.MALE]: { data: [8.5, 6.7, 4.6, 0.4, -5.5], minValue: -7.3 },
    [USER_GENDER.FEMALE]: { data: [8.7, 7.2, 5.5, 2.0, -3], minValue: -4 },
  },
  upandgo: {
    [USER_GENDER.MALE]: { data: [3.0, 3.1, 3.6, 4.9, 6.4], minValue: 6.8 },
    [USER_GENDER.FEMALE]: { data: [3.2, 3.7, 4.2, 5.4, 6.7], minValue: 7.2 },
  },
  stepInPlace: {
    [USER_GENDER.MALE]: { data: [135, 128, 119, 101, 74], minValue: 67 },
    [USER_GENDER.FEMALE]: { data: [130, 122, 111, 91, 60], minValue: 52 },
  },
};
