/**
 * TGoalHistory
 *
 * @module TGoalHistory
 */

import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import css from "./TGoalHistory.module.less";
import TGGraph from "../TGGraph/TGGraph";
import TGoalComponent from "./TGoalComponent";
const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const TGoalHistory = ({
  gSize,
  progressIncrement = 1,
  items = [],
  defaultFocus,
  className,
  ...rest
}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);
  const [focusItem, setFocusItem] = useState(defaultFocus);

  const convertPer = useMemo(() => {
    const currentData = items.filter((item) => item.type === focusItem)[0]
      .currentData;
    const goalData = items.filter((item) => item.type === focusItem)[0]
      .goalData;

    return parseInt((currentData / goalData) * 100);
  }, [items, focusItem]);

  return (
    <Container
      className={classNames(
        css.dataLayer,
        className,
        themeMode === "dark" && css.isDark
      )}
    >
      <div className={css.graphWrapper}>
        <TGGraph
          size={gSize}
          primaryIncrement={progressIncrement}
          primaryPercentage={convertPer}
          isDark={true}
        />
        <div className={css.textWrapper}>
          <span className={css.graphNum}>{convertPer}</span>
          <span className={css.graphPer}>%</span>
        </div>
      </div>

      <Container className={css.infoWrapper}>
        {items.map((item, idx) => (
          <TGoalComponent
            key={item.type + idx}
            active={item.type === focusItem}
            currentData={item.currentData}
            goalData={item.goalData}
            type={item.type}
            setFocusItem={setFocusItem}
          />
        ))}
      </Container>
    </Container>
  );
};

export default TGoalHistory;
