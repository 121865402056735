import { $L } from "../../../utils/helperMethods";
import ic_inhome_result_speed_s from "../../../../assets/inHomeOutdoorActivity/ic_inhome_result_speed_s.svg";
import ic_inhome_result_time_s from "../../../../assets/inHomeOutdoorActivity/ic_inhome_result_time_s.svg";
import ic_inhome_result_heartrate_s from "../../../../assets/inHomeOutdoorActivity/ic_inhome_result_heartrate_s.svg";
import ic_inhome_result_calories_s from "../../../../assets/inHomeOutdoorActivity/ic_inhome_result_calories_s.svg";

export const READY_TIMER = -3;

export const WALKING_MODE = {
  init: 'init',
  continue: 'continue',
  play: 'play'
}

export const PLAYER_CONFIG = {
  streaming: {
    bufferingGoal: 40,
    rebufferingGoal: 5,
  },
  manifest: {
    disableAudio: true
  }
};

export const ACTIVITY_SEQUENCE = {
  INIT: 0, // 운동 초기화
  ACTIVITY: 1, // 운동중
  SCENERY_READY: 2, // 경치 감상 전
  SCENERY: 3, // 경치 감상
  END: 4, // 운동 완료
  REPORT: 5, // 리포트
}

export const SETTING_POPUP = {
  RATIO_SETTING: 'RATIO_SETTING'
}

export const RECORD_INFO = () => {
  return [
    {
      key: "averageSpeed",
      label: $L("Average speed"),
      unit: "Km/h",
      icon: ic_inhome_result_speed_s,
    },
    {
      key: "progressTime",
      label: $L("Progress Time"),
      unit: "",
      icon: ic_inhome_result_time_s,
    },
    {
      key: "progressCalories",
      label: $L("Calories burned"),
      unit: "Kcal",
      icon: ic_inhome_result_calories_s,
    },
    {
      key: "heartBeat",
      label: $L("End the heart rate"),
      unit: "BPM",
      icon: ic_inhome_result_heartrate_s,
    },
  ];
}

export const concedeTextToNumber = {
  high: 90,
  middle: 60,
  low: 30
};

export const recommendSpeed = {
  walking: 4,
  jogging: 7,
  hiking: 3
}