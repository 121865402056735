import css from "./WebWorkerTestPanel.module.less";
import { useCallback, useEffect, useRef, useState } from "react";
import TPanel from "../../../components/TPanel/TPanel";
import THeader from "../../../components/THeader";
import TBody from "../../../components/TBody/TBody";
import TButton from "../../../components/TButton/TButton";

import WebWorkerUtil, {WORKER_ID} from "../../../utils/WebWorker/WebWorkerUtil";

const LOG_MAX_LENGTH = 25;
let intervalKey = null;

const WebWorkerTestPanel = () => {
	const [,forceUpdate] = useState();
	const [workerMessage, setWorkerMessage] = useState("None");
	const intervalLog = useRef([]);
	const mWorker = useRef(null);

	const onProcess = useCallback(async () => {
		if(!mWorker.current){
			mWorker.current = await WebWorkerUtil.makeWorker(WORKER_ID.TEST, onResponse);
		}
		WebWorkerUtil.postMessage(mWorker.current, {type:"performTest"}, false);
	},[]);
	const onTerminate = useCallback(() => {
		WebWorkerUtil.terminate(mWorker.current);
		mWorker.current = null;
		setWorkerMessage("terminated");
	},[]);
	const onResponse = useCallback((e) => {
		console.log('WebWorkerTestPanel onResponse e',e);
		if(e.type === 'start' || e.type === 'finished'){
			setWorkerMessage(e.type + " duration "+e.duration);
		}
	},[]);

	useEffect(()=>{
		WebWorkerUtil.makeWorker(WORKER_ID.TEST, onResponse).then((workerId)=>{
			mWorker.current = workerId;
		});
		intervalKey = setInterval(() => {
			const date = new Date();
			if(intervalLog.current.length >= LOG_MAX_LENGTH){
				intervalLog.current.shift();
			}
			const last = intervalLog.current[intervalLog.current.length-1];
			let duration = 0;
			if(last){
				duration = date - last.date;
			}
			intervalLog.current.push({date: date, duration: duration});

			// workerCodeTest();
			forceUpdate({});
		}, 500);
		return () =>{
			if(intervalKey){
				clearInterval(intervalKey);
			}
		}
	},[]);

	return (
	<TPanel>
		<THeader title={"WebWorker Test Panel"}/>
		<TBody>
			<div className={css.body}>
				<div className={css.logcontainer}>
				{
					intervalLog.current.map((item, index)=>{
						return <div key={"log"+index}>{item.date.toLocaleString()+" Duration:"+item.duration}</div>
					})
				}
				</div>
				<div className={css.workerscontainer}>
					<TButton onClick={onProcess}>performTest</TButton>
					<TButton onClick={onTerminate}>terminate</TButton>
					<div>{workerMessage+ " "+WebWorkerUtil.getState(mWorker.current)}</div>
				</div>
			</div>
		</TBody>
	</TPanel>
  );
}

export default WebWorkerTestPanel;