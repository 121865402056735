import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import css from "./BodyPositionViewer.module.less";
import * as Config from "../../utils/Config";

const drawDot = (ctx, x1, y1,color, radius = 5, lineWidth=2)=> {
	ctx.beginPath();
	ctx.strokeStyle = "white";
	ctx.arc(x1, y1, radius, 0, 2 * Math.PI);
	ctx.fillStyle = color;
	ctx.lineWidth = lineWidth; //흰색 선
	ctx.fill();
	ctx.stroke();
	ctx.closePath();
};
const drawRect = (ctx, x1, y1, w, h, color, linewidth = 2)=> {
	ctx.beginPath();
	ctx.strokeStyle = color;
	ctx.lineWidth = linewidth;
	ctx.strokeRect(x1,y1, w, h);
	ctx.closePath();
};
/**
 * @param
 * @returns BodyPositionViewer Test용
 */
const BodyPositionViewer = ({className, bodyPositionLiveData, cameraSize, ...rest}) => {
    const canvasRef = useRef();
    const [canvasSize, setCanvasSize] = useState([0,0]);
    const {roiSize} = useSelector(state => state.common.localSettings);
    useEffect(()=>{
        const w = canvasRef.current.getBoundingClientRect().width;
        const h = canvasRef.current.getBoundingClientRect().height;
        setCanvasSize([w,h]);
    },[]);
    useEffect(()=>{
        const ctx = canvasRef.current.getContext('2d');
        if(canvasSize[0] > 0 && cameraSize && cameraSize.width > 0){
            ctx.clearRect(0,0,canvasSize[0], canvasSize[1]);
            const scale = canvasSize[1] / cameraSize.height;
            const leftMargin = (cameraSize.width * scale - canvasSize[0]) / 2;
            const roiWidth = cameraSize.width * scale * roiSize;
            drawRect(ctx, (canvasSize[0]-roiWidth)/2, 0, roiWidth, canvasSize[1], 'blue', 6);
            if(!!bodyPositionLiveData?.joints2D?.length){
                bodyPositionLiveData.joints2D.forEach((bellyCoordination, index) => {
                    const reversedCameraBellyX = (cameraSize.width - bellyCoordination[0]) * scale - leftMargin;
                    const reversedCameraBellyY = bellyCoordination[1] * scale;
                    const ctx = canvasRef.current.getContext('2d');
                    let dotColor = Config.DEBUG_BODY_POINT_COLOR.etc;
                    if(Config.DEBUG_BODY_POINT_COLOR[index]){
                        dotColor = Config.DEBUG_BODY_POINT_COLOR[index];
                    }
                    drawDot(ctx, reversedCameraBellyX, reversedCameraBellyY, dotColor);
                });
            }
        }
    },[bodyPositionLiveData, cameraSize, canvasSize, roiSize]);

    return (
		<canvas
			{...rest}
			ref={canvasRef}
			className={classNames(css.viewer,className)}
			width={canvasSize[0]}
			height={canvasSize[1]}
		/>
    )
}

export default BodyPositionViewer;