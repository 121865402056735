import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { $L } from '../../../utils/helperMethods';
import css from "./NoticeDetail.module.less";
import TScroller from "../../../components/TScroller/TScroller";
import TButton from "../../../components/TButton/TButton";
import Spotlight from "@enact/spotlight";
import dummyTerms from '../../../../assets/mock/dummyTerms.json'
import dummyNotice from '../../../../assets/mock/dummyNotice.json';
import TPanel from "../../../components/TPanel/TPanel";
import Alert from '@enact/sandstone/Alert';
import { popPanel } from "../../../features/panels/panelsSlice";

const NoticeDetail = ({ index, open, onClose, children, ...rest }) => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();
  const getScrollTo = useCallback((cbScrollTo) => {
      scrollTo.current = cbScrollTo;
  }, [index]);

  useEffect(()=>{
    if (scrollTo && scrollTo.current) {
      scrollTo.current({position: {y: 0}, animate: false});
    }
  }, [index]);

  const noticeData = dummyNotice.data[index];
  console.log(noticeData);

  const _onClose = useCallback((ev) => {
    if(onClose){
      onClose(ev)
    }
  }, [onClose]);

  useEffect(()=>{
    Spotlight.focus('noticeDetailConfirm');
  },[])

  return (
    <Alert {...rest} className={css.panelNoticeDetail}
      open
      onClose={_onClose}
      type={"fullscreen"}
    >
      <div className={css.title} dangerouslySetInnerHTML={{ __html: noticeData.title}} />
      <div className={css.date} dangerouslySetInnerHTML={{ __html: noticeData.date}} />
      <TScroller className={css.tScroller} cbScrollTo={getScrollTo} >
          <div>
            <div className={css.text} dangerouslySetInnerHTML={{ __html: noticeData.text}} />
          </div>
      </TScroller>
      <div className={css.btnBox}>
        <TButton type ="normal" spotlightId="noticeDetailConfirm" onClick={_onClose}>{$L("Verified")}</TButton>
      </div>
    </Alert>
  );
};

export default NoticeDetail;