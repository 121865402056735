import { useState, useCallback } from 'react';
import css from './DetailLayout.module.less';
import TStepper from '../../../../components/TStepper/TStepper';
import TButton from '../../../../components/TButton/TButton';
import { $L } from '../../../../utils/helperMethods';

const order = [
  $L("Start"),
  $L("TESTTESTTESTTESTTEST"),
  $L("TESTTESTTESTTESTTEST"),
  $L("TESTTESTTESTTESTTEST"),
  $L("TESTTESTTESTTESTTEST"),
  $L("TESTTESTTESTTESTTEST"),
  $L("Finished"),
];

const TStepperLayout = () => {

  const [number, setNumber] = useState(0);

  const onPrevClick = useCallback(() => {
    if(number > 0){
      setNumber(number-1)
    };
  }, [number]);

  const onNextClick = useCallback(() => {
    if(number < order.length){
      setNumber(number+1)
    }
  }, [number]);

  return (
    <>
      <div className={css.stepperLayout}>
        <TStepper className={css.stepper1} order={order} number={number} type="vertical"/>
        <TStepper className={css.stepper2} order={order} number={number} type="horizontal"/>
      </div>
      <div>
        <TButton disabled={number===0} onClick={onPrevClick}>{$L("Previous page")}</TButton>
        <TButton disabled={number===order.length-1} onClick={onNextClick}>{$L("Next")}</TButton>
      </div>
    </>

  );
};

export default TStepperLayout;