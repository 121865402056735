import classNames from "classnames";
import css from "./SMediaItem.module.less";

import React, { useMemo, useCallback, useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import compose from "ramda/src/compose";

import * as Config from '../../utils/Config';
import * as SoundEffect from "../../utils/SoundEffect";
import { $L, stringTimetoSec, convertUtf8ToUni, getLocalServerUrl} from "../../utils/helperMethods";

import CustomImage from "../CustomImage";

import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import { Marquee, MarqueeController } from '@enact/sandstone/Marquee';
import { Job } from "@enact/core/util";

import LIKE_NOR from "../../../assets/media/ic_like_nor.png";
import LIKE_SEL from "../../../assets/media/ic_like_sel.png";
import CHECK_NOR from "../../../assets/media/ic_check_nor.png";
import CHECK_SEL from "../../../assets/media/ic_check_sel.png";
import TIconButton from "../TIconButton/TIconButton";
import { SUBTYPE_TITLE } from "../../views/HomecareProgram/Constants";

const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: 'default-element'}, 'div');

export const TYPES = {
  normal: "normal",
  withCheck: "withCheck",
  withCheckIndex: "withCheckIndex",
  mobile: "mobile"
};

const MediaItemBase = ({
  index,
  className,
  type=TYPES.normal,
  itemSize,
  onClick,
  onFocus,
  onBlur,
  itemInfo,
  playIcon,
  centerTitle,
  mode,
  secondSubText, // ai추천
  subTypeIcon, // ai추천
  ...rest
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  // const { cesShowMode, serverType, localURL } = useSelector((state) => state.common.localSettings);

  const clearPressedJob = useRef(
    new Job((func) => {
      setIsPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const itemDescription = useMemo(() => {
    let res = {title: "", thumbnailUrl: "", playTime: "", playPercent: null, category: "", calories:"", distance: "", isLike: false, isNew: false};
    if (itemInfo) {
      res.title = convertUtf8ToUni(itemInfo?.title);
      res.thumbnailUrl = convertUtf8ToUni(itemInfo?.thumbnailUrl);
      res.playTime = itemInfo?.playTime;
      res.category = itemInfo?.category;
      res.calories = itemInfo?.calories;
      res.distance = itemInfo?.distance;
      res.level = itemInfo?.level;
      res.playPercent = Math.floor(stringTimetoSec(itemInfo?.currentPlayTime) / stringTimetoSec(itemInfo?.playTime)  * 100 );
      res.isLike = itemInfo?.icons?.isLike;
      res.isNew = itemInfo?.icons?.isNew;
      res.playCount = itemInfo?.playCount;
      res.subType = itemInfo?.subType;
    }

    // if (cesShowMode && itemInfo) {
    //   res.thumbnailUrl = getLocalServerUrl(convertUtf8ToUni(itemInfo?.thumbnailUrl), serverType, localURL).replace(/\+/g, ' ');
    // }

    return res;
  }, [index, itemInfo]);

  const _onClick = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);

    if (ev?.target?.id !== "countMinus" || ev?.target?.id !== "countPlus") {
      setIsPressed(false);
    }
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick({ index, itemInfo, ev });
      }
    });
    if (type === TYPES.withCheck) setIsChecked((prev) => !prev);
  }, [index, itemInfo, onClick]);

  const _onFocus = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    if (onFocus) {
      onFocus(ev)
    }
  }, [onFocus]);

  const _onBlur = useCallback((ev) => {
    setIsPressed(false);
    clearPressedJob.current.stop();
    if (onBlur) {
      onBlur(ev)
    }
  }, [onBlur]);

  const countHandler = useCallback((type) => () => {
    if(itemCount < 0 || itemCount > 10) {
      return;
    } else {
      if(type === "minus") {
        setItemCount(itemCount - 1);
      } else if(type === "plus") {
        setItemCount(itemCount + 1);
      }
    }
  }, [itemCount]);

  useEffect(() => {
    type === TYPES.withCheck && index === 0 && setIsChecked(true);
  }, [type]);

  return (
    // <Container className={css.sMediaItem}>
    <SpottableComponent
      {...rest}
      className={classNames(
        className,
        css.mediaItem,
        css[itemSize],
        css[type],
        isPressed && css.pressed,
      )}
      onClick={_onClick}
      onFocus={_onFocus}
      onBlur={_onBlur}
    >
      <div className={css.imageArea}>
        <CustomImage
          src={itemDescription.thumbnailUrl}
          className={css.image}
          animationSpeed="normal"
          delay={0}
        />
        {/* play button */}
        {playIcon && <div className={css.playIcon} />}
        {/* play time */}
        {itemDescription.playTime && <div className={css.playTime}>{itemDescription.playTime}</div>}
        {/* new icon */}
        {type === TYPES.normal && itemDescription.isNew && <div className={css.newIcon}/>}
        {/* check icon */}
        {type === TYPES.withCheck &&
          <div className={css.checkIcon} style={{ backgroundImage: `url(${isChecked ? CHECK_SEL : CHECK_NOR})`}} />
        }
        {/* checkIndex icon */}
        {type === TYPES.withCheckIndex &&
          <div className={css.checkIndex} />
        }
        {/* like icon */}
        {itemDescription.isLike !== undefined && (
          <div className={css.likeIcon} style={{ backgroundImage: `url(${itemDescription.isLike ? LIKE_SEL : LIKE_NOR})`}} />
        )}
        {/* subType icon */}
        {subTypeIcon && itemDescription.subType !== undefined && (
          <div className={css.subTypeIcon}>{SUBTYPE_TITLE[itemDescription.subType]}</div>
        )}
        {/* play gauage */}
        {itemDescription.playPercent > 0 && (
          <>
            <div className={css.totalGauge}></div>
            <div className={css.playGauge} style={{width: `${itemDescription.playPercent}%`}}></div>
          </>
        )}
      </div>
      <Marquee className={classNames(css.title, centerTitle ? css.centerTitle : null)} marqueeSpacing={'20%'}>
        {itemDescription.title}
      </Marquee>
      <Marquee className={css.subTextArea} marqueeSpacing={'20%'}>
        {itemDescription.category && <span className={css.text}>{itemDescription.category}</span>}
        {itemDescription.calories && <span className={css.text}>{itemDescription.calories}</span>}
        {itemDescription.level && <span className={css.text}>{itemDescription.level}</span>}
        {itemDescription.distance && <span className={css.text}>{`${itemDescription.distance}km`}</span>}
      </Marquee>
      {secondSubText &&
        <Marquee className={css.secondSubTextArea} marqueeSpacing={'20%'}>
          {itemDescription.playCount &&
            <span className={css.text}>
              {$L("진행횟수")+" "}
              <span>{itemDescription.playCount}</span>
              {'/30'}
            </span>
          }
        </Marquee>
      }
      {mode === 'edit' &&
        <Container className={css.countContainer}>
          <TIconButton
            iconType={"countMinus"}
            id={"countMinus"}
            disabled={itemCount === 0}
          />
          <div className={css.count}>
            {itemCount + $L("회")}
          </div>
          <TIconButton
            iconType={"countPlus"}
            id={"countPlus"}
            disabled={itemCount === 10}
          />
        </Container>
      }
    </SpottableComponent>
  );
};

const ImageItemDecorator = compose(
	MarqueeController({marqueeOnFocus: true}),
);
const SMediaItem = ImageItemDecorator(MediaItemBase);
export default SMediaItem;
