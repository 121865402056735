import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $L } from "../../../utils/helperMethods";
import css from "./FitnessReport.module.less";
import TButton from "../../../components/TButton/TButton";
import TScroller from "../../../components/TScroller/TScroller";
import Spotlight from "@enact/spotlight";

import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TSimpleDatePicker from "../../../components/TSimpleDatePicker/TSimpleDatePicker";
import TChips from "../../../components/TChips/TChips";
import DonutChartItem from "./DonutChartItem/DonutChartItem";
import TwoVerticalBarGraph from "./TwoVerticalBarGraph/TwoVerticalBarGraph";
import GGraphItem from "./GGraphItem/GGraphItem";
import BodyCheckUpResult from "./BodyCheckUpResult/BodyCheckUpResult";
import TLineGraph from "../../../components/TLineGraph/TLineGraph";
import TDropdown from "../../../components/TDropDown/TDropDown";

const percent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  Spottable("div")
);

const dummyWorkoutDayList = {
  "2024-01" : {user: 11, plan: 17 },
  "2023-12" : {user: 22, plan: 20 },
  "2023-11" : {user: 10, plan: 15 },
  "2023-10" : {user: 23, plan: 25 },
  "2023-09" : {user: 15, plan: 10 },
  "2023-08" : {user: 25, plan: 25 },
  "2023-05" : {user: 12, plan: 14 },
  "2023-03" : {user: 11, plan: 17 },
}

const dummyWorkoutTimeList = {
  "2024-01" : {user: 48234, plan: 80000 },
  "2023-12" : {user: 56789, plan: 72000 },
  "2023-11" : {user: 72651, plan: 40000 },
  "2023-10" : {user: 11892, plan: 10000 },
  "2023-09" : {user: 65023, plan: 80000 },
  "2023-08" : {user: 54301, plan: 50000 },
  "2023-05" : {user: 30987, plan: 20000 },
  "2023-03" : {user: 20543, plan: 60000 },
}

const dummyWorkoutCalorieList = {
  "2024-01" : {user: 2688, plan: 5000 },
  "2023-12" : {user: 1253, plan: 1000 },
  "2023-11" : {user: 3210, plan: 3000 },
  "2023-10" : {user: 4152, plan: 8000 },
  "2023-09" : {user: 2525, plan: 3000 },
  "2023-08" : {user: 1826, plan: 5000 },
  "2023-05" : {user: 2387, plan: 2000 },
  "2023-03" : {user: 5118, plan: 6000 },
}

const dummyWorkoutScoreList = {
  "2023-06": { score: 74 },
  "2023-07": { score: 88 },
  "2023-08": { score: 113 },
  "2023-09": { score: 106 },
  "2023-10": { score: 92 },
  "2023-11": { score: 67 },
  "2023-12": { score: 101 },
  "2024-01": { score: 78 },
};

const dummyBodyAlignmentTestResult = {
  "headShift": {
    "2024-01" : {score: 15, time: 2200},
    "2023-12" : {score: 13, time: 2100},
    "2023-11" : {score: 11, time: 1300},
    "2023-10" : {score: 7, time: 900},
  },
  "neckTilt": {
    "2024-01" : {score: 9.7, time: 1000},
    "2023-12" : {score: 9.3, time: 1100},
    "2023-11" : {score: 8.9, time: 1200},
    "2023-10" : {score: 8.4, time: 2000},
  },
  "shoulderTilt": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.0, time: 2000},
    "2023-11" : {score: 2.3, time: 1000},
    "2023-10" : {score: 2.3, time: 6000},
  },
  "pelvicTilt": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.9, time: 3000},
    "2023-11" : {score: 1.3, time: 7000},
    "2023-10" : {score: 2.5, time: 2000},
  },
  "pelvicShift": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.4, time: 6000},
    "2023-11" : {score: 0.0, time: 4000},
    "2023-10" : {score: 2.3, time: 9000},
  },
  "pelvicRotation": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.8, time: 4000},
    "2023-11" : {score: 4.1, time: 2000},
    "2023-10" : {score: 2.3, time: 1000},
  },
  "trunkShift": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.8, time: 6000},
    "2023-11" : {score: 4.1, time: 3000},
    "2023-10" : {score: 2.3, time: 1000},
  },
  "kneeFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.9, time: 8000},
    "2023-11" : {score: 1.3, time: 6000},
    "2023-10" : {score: 2.5, time: 9000},
  },
  "legAlignment": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 1000},
    "2023-11" : {score: 3.1, time: 2000},
    "2023-10" : {score: 1.6, time: 3000},
  },
};

const dummyROMTestResult = {
  "neckLateralFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.8, time: 3000},
    "2023-11" : {score: 4.1, time: 2000},
    "2023-10" : {score: 2.3, time: 4000},
  },
  "neckFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 5000},
    "2023-11" : {score: 3.1, time: 3000},
    "2023-10" : {score: 1.6, time: 1000},
  },
  "neckExtension": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.0, time: 2000},
    "2023-11" : {score: 2.3, time: 1000},
    "2023-10" : {score: 2.3, time: 6000},
  },
  "shoulderAbduction": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.9, time: 3000},
    "2023-11" : {score: 1.3, time: 7000},
    "2023-10" : {score: 2.5, time: 2000},
  },
  "shoulderInternalRotation": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.4, time: 6000},
    "2023-11" : {score: 0.0, time: 4000},
    "2023-10" : {score: 2.3, time: 9000},
  },
  "shoulderExternalRotation": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.8, time: 4000},
    "2023-11" : {score: 4.1, time: 2000},
    "2023-10" : {score: 2.3, time: 1000},
  },
  "shoulderFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.8, time: 6000},
    "2023-11" : {score: 4.1, time: 3000},
    "2023-10" : {score: 2.3, time: 1000},
  },
  "shoulderExtension": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 3.9, time: 8000},
    "2023-11" : {score: 1.3, time: 6000},
    "2023-10" : {score: 2.5, time: 9000},
  },
  "trunkLateralFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 1000},
    "2023-11" : {score: 3.1, time: 2000},
    "2023-10" : {score: 1.6, time: 3000},
  },
  "trunkExtension": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 1000},
    "2023-11" : {score: 3.1, time: 2000},
    "2023-10" : {score: 1.6, time: 3000},
  },
  "trunkFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 1000},
    "2023-11" : {score: 3.1, time: 2000},
    "2023-10" : {score: 1.6, time: 3000},
  },
  "kneeFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 1000},
    "2023-11" : {score: 3.1, time: 2000},
    "2023-10" : {score: 1.6, time: 3000},
  },
  "hipFlexion": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 1000},
    "2023-11" : {score: 3.1, time: 2000},
    "2023-10" : {score: 1.6, time: 3000},
  },
  "hipExtension": {
    "2024-01" : {score: 2.3, time: 4000},
    "2023-12" : {score: 1.2, time: 1000},
    "2023-11" : {score: 3.1, time: 2000},
    "2023-10" : {score: 1.6, time: 3000},
  },
};

const FitnessReport = () => {
  const scrollTo = useRef(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTopIndex, setSelectedTopIndex] = useState(0);
  const [bodyCheckUpIndex, setBodyCheckUpIndex] = useState(0);
  const [monthList, setMonthList] = useState([]);
  const {intensity_low, intensity_mid, intensity_high} = useSelector((state) => state.fitService.survey);
  const [selectedId, setSelectedId] = useState(null);
  const dropDownMenu = [$L("Category"), $L("Heart Rate")];

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  const onSelectedDate = useCallback(({ selectedDate }) => {
    setSelectedDate(selectedDate);
  }, []);

  const handleDateRange = useCallback(({ first }) => {
    const date = new Date(first);
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;

    let dateList = [];
    for (let i = 2; i >= 0; i--) {
      const month = ((currentMonth - i + 12) % 12) || 12;
      const year = currentYear + Math.floor((currentMonth - i - 1) / 12);
      const formattedMonth = month.toString().padStart(2, '0');
      dateList.push(`${year}-${formattedMonth}`);
    }

    setMonthList(dateList);
  }, []);

  const onClickTopChipItem = useCallback(({ index }) => {
    setSelectedTopIndex(index);
  }, []);

  const onClickBodyCheckUpChipItem = useCallback(({ index }) => {
    setBodyCheckUpIndex(index);
  }, []);

  const onSelectId = useCallback(({ selected }) => {
    setSelectedId(selected);
  }, []);

  return (
    <>
      <div className={css.dateContainer}>
        <div className={css.dateWrapper}>
          <TSimpleDatePicker
            className={css.tSimpleDatePicker}
            period={"monthly"}
            selectedDate={selectedDate}
            onSelectedDate={onSelectedDate}
            handleDateRange={handleDateRange}
            size="small"
            width="small"
          />
          <div className={css.alert}>{$L("You can check the data up to the last three months.")}</div>
        </div>
        <TChips
          contents={[$L("O2O Program"), $L("AI Program ")]}
          selectedIndex={selectedTopIndex}
          onItemClick={onClickTopChipItem}
        />
      </div>

      <TScroller
        cbScrollTo={getScrollTo}
        className={css.scroller}
      >
        <div className={css.scrollInnerContainer}>
          <div className={css.bodyCheckUpContainer}>
            <div className={css.titleWrapper}>
              <div className={css.title}>{$L("Result of My Body Analysis")}</div>
              <TChips 
                contents={[$L("Body Alignment"), $L("ROM"), $L("Body Balance"), $L("Physical Test")]}
                selectedIndex={bodyCheckUpIndex}
                onItemClick={onClickBodyCheckUpChipItem}
              />
            </div>
            {bodyCheckUpIndex === 0 &&
            <BodyCheckUpResult type={"bodyalignment"} monthList={monthList} itemInfo={dummyBodyAlignmentTestResult} />}
            {bodyCheckUpIndex === 1 &&
            <BodyCheckUpResult type={"rom"} monthList={monthList} itemInfo={dummyROMTestResult} />}
            {/* todo : add dummy data */}
            {bodyCheckUpIndex === 2 &&
            <BodyCheckUpResult type={"bodyalignment"} monthList={monthList} itemInfo={dummyBodyAlignmentTestResult} />}
            {bodyCheckUpIndex === 3 &&
            <BodyCheckUpResult type={"rom"} monthList={monthList} itemInfo={dummyROMTestResult} />}
          </div>
          <div className={css.achievementContainer}>
            <div className={css.title}>{$L("Exercise Performance")}</div>
            <div className={css.gGraphWrapper}>
              <GGraphItem type={"date"} itemInfo={dummyWorkoutDayList[monthList[2]]}/>
              <GGraphItem type={"time"} itemInfo={dummyWorkoutTimeList[monthList[2]]}/>
              <GGraphItem type={"calorie"} itemInfo={dummyWorkoutCalorieList[monthList[2]]}/>
            </div>
          </div>
          <div className={css.twoVerticalBarGraphContainer}>
            <div className={css.titleWrapper}>
              <div className={css.title}>{$L("Recommended exercise")}</div>
            </div>
            <div className={css.twoVerticalBarGraphWrapper}>
              <TwoVerticalBarGraph type={"date"} title={$L("Number of days you worked out")} itemInfo={dummyWorkoutDayList} monthList={monthList}/>
              <TwoVerticalBarGraph type={"time"} title={$L("Workout Time")} itemInfo={dummyWorkoutTimeList} monthList={monthList}/>
              <TwoVerticalBarGraph type={"calorie"} title={$L("Calories burned")} itemInfo={dummyWorkoutCalorieList} monthList={monthList}/>
            </div>
          </div>
          {/* todo : dummy data change */}
          <div className={css.donutChartContainer}>
            <DonutChartItem type={"intensity"} itemInfo={[20, 50, 30]}/>
            <DonutChartItem type={"exercise"} itemInfo={[60, 25, 10, 5]}/>
            <DonutChartItem type={"routine"} itemInfo={[80, 20]}/>
            <DonutChartItem type={"time"} itemInfo={[80, 20]}/>
          </div>
          <div className={css.titleContainer}>
            <div className={css.title}>{$L("Workout Score")}</div>
            <TDropdown
              placeholder={$L("Category")}
              direction="below"
              size="small"
              width="small"
              selected={selectedId}
              onSelect={onSelectId}
            >
              {dropDownMenu}
            </TDropdown>
          </div>
          <div className={css.lineChartContaienr}>
            <div className={css.lineChartWrapper}>
              <TLineGraph
                width={1394}
                height={312}
                label={Object.keys(dummyWorkoutScoreList).map(li => li.split("-").splice(1).join("/") )}
                data={Object.values(dummyWorkoutScoreList).map(li => li.score)}
                maxRange={100}
                yRange={20}
                yLabel={true}
                unit={"point"}
              />
            </div>
          </div>

        </div>
      </TScroller>
    </>

  );

};

export default FitnessReport;
