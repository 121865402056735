import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import classnames from 'classnames';
import Spritesheet from "react-responsive-spritesheet";
import ri from '@enact/ui/resolution';
import css from "./ScanAnimator.module.less";
import keypoints from "../../../../utils/bodyLiveData";
import bellyPointImg from "../../../../../assets/bodyScan/cross_point.png";
import scanSprites from "../../../../../assets/bodyScan/10frame_sec/scan-sprites.png";
import usePrevious from "../../../../hooks/usePrevious";
const canvasSize=[ri.scale(960), ri.scale(1080)];

const INDEX_FOOT_LEFT = 31;
const INDEX_FOOT_RIGHT = 33;
const CHECKING_POINTS = [37, 38, 11, 14, 7, 1, 4, 2, 5];
const CHECKING_POINTS_TIME = [0.1, 0.1, 0.2, 0.2, 0.3, 0.4, 0.4, 0.5, 0.5]; //point showing time percent

const getAvgPoint = (p1, p2) => {
    return [(p1[0]+p2[0])/2, (p1[1]+p2[1])/2];
};

const spriteData = {
    sprite: {
      image: scanSprites,
      widthFrame: 256,
      heightFrame: 600,
      steps: 40,
      fps: 10,
    }
};

const ScanAnimator = ({bodyPositionLiveData, cameraSize}) => {
    const { xFitBodyBottomPositionGUI } = useSelector((state) => state.common.localSettings);
    const animationFrameId = useRef(null);
    const bottomimageRef = useRef(null);
    const scanSpriteRef = useRef(null);
    const startTime = useRef(0);
    const [spriteInstance, setSpriteInstance] = useState(null);
    const [bellyPoints , setBellyPoints] = useState([]);
    const [bellyShowing, setBellyshowing] = useState([]);
	const bellyShowingRef = usePrevious(bellyShowing);

    useEffect(() => {
        if(!cameraSize || !cameraSize.width || !spriteInstance ){
            return;
        }
        let _bodyPositionLiveData = bodyPositionLiveData;
        if(typeof window === 'object' && !window.PalmSystem ){
            _bodyPositionLiveData = keypoints;
        }
        const scale = canvasSize[1] / cameraSize.height;
        const leftMargin = (cameraSize.width * scale - canvasSize[0]) / 2;
        const calculatedPoints = [];
        if(!!_bodyPositionLiveData?.joints2D?.length){
            _bodyPositionLiveData.joints2D.forEach((bellyCoordination, index) => {
                const reversedCameraBellyX = (cameraSize.width - bellyCoordination[0]) * scale - leftMargin;
                const reversedCameraBellyY = bellyCoordination[1] * scale;
                calculatedPoints.push([reversedCameraBellyX, reversedCameraBellyY]);
            });
        }
        //bottom point
        const footPoint = getAvgPoint(calculatedPoints[INDEX_FOOT_LEFT], calculatedPoints[INDEX_FOOT_RIGHT]);
        const bellyPoint = calculatedPoints[17];
        const headTopPoint = calculatedPoints[10];
        const eyePoint = getAvgPoint(calculatedPoints[35], calculatedPoints[36]);
        window.bottomnode = bottomimageRef.current;//test
        // scanSpriteRef.current.style.left = (bellyPoint[0] - scanSpriteRef.current.getBoundingClientRect().width/2)+'px';
        // scanSpriteRef.current.style.bottom = (100-xFitBodyBottomPositionGUI)+"%";
        scanSpriteRef.current.style.opacity = 1;
        const spriteHeight =((footPoint[1]-headTopPoint[1])*1.3);
        const spriteWidth = (256/600) * spriteHeight;
        scanSpriteRef.current.style.height = spriteHeight+'px';
        scanSpriteRef.current.style.width = spriteWidth +'px';
        scanSpriteRef.current.style.left = (bellyPoint[0] - spriteWidth/2) + 'px';
        scanSpriteRef.current.style.top = (bellyPoint[1] - spriteHeight/2) + 'px';
        spriteInstance.setDirection("forward");
        spriteInstance.goToAndPause(0);
        spriteInstance.goToAndPlay(1);

        const TotalAniTime = (spriteData.sprite.steps/spriteData.sprite.fps)*1000;
        // bottomimageRef.current.style.left =  (bellyPoint[0] - bottomimageRef.current.getBoundingClientRect().width/2)+'px';
        // bottomimageRef.current.style.top =  (footPoint[1] - bottomimageRef.current.getBoundingClientRect().height/2)+'px';
        // scanImageRef.current.style.left =  (bellyPoint[0] - scanImageRef.current.getBoundingClientRect().width/2)+'px';
        // scanImageRef.current.style.bottom =  (canvasSize[1]-footPoint[1])+'px';
        // //10%
        // scanImageRef.current.style.setProperty('--scanimageMaxHeight', ((footPoint[1]-headTopPoint[1])*1.1)+'px');

        const points = [];
        for(let i=0; i< CHECKING_POINTS.length; i++){
            const point = calculatedPoints[CHECKING_POINTS[i]];
            points.push(point);
        }
        setBellyPoints(points);
        if(typeof window === "object"){
            const animate = (timestamp) => {
                if(startTime.current === 0){
                    startTime.current = timestamp;
                }
                let elapsed = timestamp - startTime.current;
                let found = false;
                const newShowingState = [...bellyShowingRef.current];
                for(let i=0; i< points.length; i++){
                    elapsed/TotalAniTime
                    if(!newShowingState[i] && CHECKING_POINTS_TIME[i] < elapsed/TotalAniTime){
                        found = true;
                        newShowingState[i] = true;
                    }
                }
                if(found){
                    setBellyshowing(newShowingState);
                }
                animationFrameId.current = window.requestAnimationFrame(animate);
            };
            animationFrameId.current = window.requestAnimationFrame(animate);
        }
        return () => {
            if(animationFrameId.current !== null && typeof window === "object"){
                window.cancelAnimationFrame(animationFrameId.current);
            }
            spriteInstance?.pause();
        };
    }, [cameraSize, spriteInstance]);

    const getInstance = useCallback((instance)=>{
        setSpriteInstance(instance);
    },[]);

    return (
        <div className={css.base}>
            {/* <img className={css.image} src={imgScan}/> */}
            <div className={css.imageContainer} ref={scanSpriteRef}>
                <Spritesheet
                    image={spriteData.sprite.image}
                    widthFrame={spriteData.sprite.widthFrame}
                    heightFrame={spriteData.sprite.heightFrame}
                    steps={spriteData.sprite.steps}
                    fps={spriteData.sprite.fps}
                    autoplay={false}
                    getInstance={getInstance}
                />
            </div>
            {/* <div ref={scanImageRef} className={css.image_scan}/>
            <div ref={bottomimageRef} className={css.image_scan_bottom}/> */}
            {CHECKING_POINTS.map((bIndex, index) => {
                const point = bellyPoints[index];
                if(!point || !bellyShowing[index]){
                    return null;
                }
                const style = point[0] ? {left: point[0] + "px", top: point[1] + "px"}: {};
                return (
                <img
                    key={index}
                    src={bellyPointImg}
                    className={classnames(css.bellyPoint)}
                    style={style}
                />
                );
            })
            }
        </div>
    );
}

export default ScanAnimator;
