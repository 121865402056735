import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import css from "./JMediaItem.module.less";
import compose from "ramda/src/compose";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import { Marquee, MarqueeController } from "@enact/sandstone/Marquee";
import { useSelector, useDispatch } from "react-redux";
import dummyImage from "../../../assets/dummyImage.png";
import dummyImageHiking from "../../../assets/dummyImageHiking.png";
import * as Utils from "../../utils/helperMethods";
import CustomImage from "../CustomImage";
import { LIST_TYPE } from "./JMediaList";
import TIconButton from "../TIconButton/TIconButton";
import * as Config from '../../utils/Config';
import * as SoundEffect from "../../utils/SoundEffect";
import { $L } from "../../utils/helperMethods";

const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: 'default-element'}, 'div');

const MediaItemBase = ({
  index,
  listType = LIST_TYPE.large,
  width,
  title,
  onItemClick,
  itemInfo,
  lastIndex,
  checkProp = false,
  playProp = false,
  centerTitle,
  mode,
  ...rest
}) => {
  const dispatch = useDispatch();

  const gaugeRef = useRef(null);
  const itemRef = useRef(null);
  const [isLike, setLike] = useState(false);
  const [isNew, setNew] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [itemCount, setItemCount] = useState(0);

  const getRef = useCallback((ref) => {
    //adjust list height
    try {
      const height = ref.node.getBoundingClientRect().height;
      itemRef.current = ref.node;
      const listHeight =
        ref.node.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect()
          ?.height;
      if (!listHeight && height) {
        ref.node.parentNode.parentNode.parentNode.parentNode.style.height =
          height + "px";
      }
      // ref.node.parentNode.parentNode.parentNode.parentNode.style.height =
      //   "338px";
    } catch (e) {
      console.log("MediaItemBase list adjusting failed");
    }
  }, []);
  const onClick = useCallback(
    (ev) => {
      SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
      if (onItemClick) {
        onItemClick({ index, itemInfo, ev });
      }
      // ev.stopPropagation();
      // ev.preventDefault();

      // onClick test
      isNew && setNew(false);
      checkProp && setChecked((prev) => !prev);
    },
    [index, itemInfo, onItemClick, isNew, checkProp]
  );

  const itemDescription = useMemo(() => {
    let res = {
      dataType: "",
      title: "",
      subTitle: [],
      thumbnailUrl: "",
      playTime: "",
      icons: {},
    };
    if (itemInfo) {
      res.title = Utils.convertUtf8ToUni(itemInfo.title);
      res.thumbnailUrl = itemInfo.thumbnailUrl;
      res.playTime = itemInfo.playTime && Utils.convertUtf8ToUni(itemInfo.playTime);

      let subTitleArr = [];

      let itemInfoKeys = []
      itemInfoKeys = (itemInfo?.icons) && Object.keys(itemInfo.icons);

      // dataType에 따른 아이콘 부여
      if (itemInfo.icons && itemInfoKeys?.length > 0) {
        if (itemInfo.dataType === "workout") {
          res.dataType = "workout";
          res.icons.playCount = itemInfo.icons.playCount;

          itemInfo.category &&
            subTitleArr.push(Utils.convertUtf8ToUni(itemInfo.category));
          itemInfo.calories &&
            subTitleArr.push(Utils.convertUtf8ToUni(itemInfo.calories));
        } else if (itemInfo.dataType === "hiking") {
          res.dataType = "hiking";

          if (
            itemInfoKeys.includes("isNew") &&
            itemInfoKeys.includes("isLike")
          ) {
            res.icons.isNew = itemInfo.icons.isNew;
            res.icons.isLike = itemInfo.icons.isLike;

            // onClick test
            setNew(itemInfo.icons.isNew);
            setLike(itemInfo.icons.isLike);
          }

          itemInfo.calories &&
            subTitleArr.push(Utils.convertUtf8ToUni(itemInfo.calories));
          itemInfo.distance &&
            subTitleArr.push(Utils.convertUtf8ToUni(isNaN(itemInfo.distance) ? itemInfo.distance : `${itemInfo.distance}km`));
        }
      }

      if (itemInfo.currentPlayTime && itemInfo.playTime) {
        const playTimeArr = itemInfo.playTime.split(":");
        const currentPlayTimeArr = itemInfo.currentPlayTime.split(":");
        // playTimeArr.length = 3
        // index 0, 1, 2

        const convertTime = (arr) => {
          const reversedArr = [...arr].reverse().map((item) => Number(item));

          let totalTime = 0;
          for (let i = 0; reversedArr.length > i; i++) {
            totalTime += reversedArr[i] * 60 ** i;
          }
          return totalTime;
        };

        const convertPlayTime = convertTime(playTimeArr);
        const convertCurrentPlayTime = convertTime(currentPlayTimeArr);
        res.playingPercent =
          (convertCurrentPlayTime / convertPlayTime) * 100 + "%";
      }

      res.subTitle = subTitleArr;

      //todo
      if (index >= 0) {
      }
      if (res.thumbnailUrl === "dummy") {
        res.thumbnailUrl = dummyImage;
      } else if (res.thumbnailUrl === "dummyHiking") {
        res.thumbnailUrl = dummyImageHiking;
      }
    }
    return res;
  }, [index, itemInfo]);

  useEffect(() => {
    if (gaugeRef.current && itemDescription) {
      gaugeRef.current.style.width = itemDescription.playingPercent;
    }
  }, [itemDescription]);

  const onFocusHandler = useCallback(() => {
    if (itemRef.current) {
      SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
      itemRef.current.parentNode.style.zIndex = 1;
    }
  }, []);
  const onBlurHandler = useCallback(() => {
    if (itemRef.current) {
      itemRef.current.parentNode.style.zIndex = 0;
    }
  }, []);

  const countHandler = useCallback((type) => () => {
    if(itemCount < 0 || itemCount > 10) {
      return;
    } else {
      if(type === "minus") {
        setItemCount(itemCount - 1);
      } else if(type === "plus") {
        setItemCount(itemCount + 1);
      }
    }
  }, [itemCount]);

  useEffect(() => {
    index === 0 && setChecked(true);
  }, []);

  return (
    <SpottableComponent
      {...rest}
      className={classNames(css.jMediaItem, css[listType])}
      style={{ width: width + "px" }}
      onClick={onClick}
      ref={getRef}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
    >
      <div className={css.imageArea}>
        <div className={css.imageBox}>
          <CustomImage
            src={itemDescription.thumbnailUrl}
            className={css.image}
            animationSpeed="normal"
            delay={0}
          />
          {Object.keys(itemDescription.icons).length > 0 && (
            <div className={classNames(css.commonAbsolute)}>
              {itemDescription.dataType === "workout" ? (
                checkProp ? (
                  <span
                    className={classNames(
                      css.iconLeft,
                      css.iconCheck,
                      isChecked && css.selected
                    )}
                  />
                ) : itemDescription.icons.playCount &&
                  Number(itemDescription.icons.playCount) > 0 ? (
                  <span className={css.iconLeft}>
                    {Utils.$L(`{NUM}회 진행`).replace(
                      "{NUM}",
                      itemDescription.icons.playCount
                    )}
                  </span>
                ) : null
              ) : itemDescription.dataType === "hiking" ? (
                <>
                  {isNew && (
                    <span className={css.iconLeft}>{Utils.$L("New").toUpperCase()}</span>
                  )}
                  <TIconButton
                    className={css.iconRight}
                    iconType={isLike ? "heartWhite" : "heartHollowWhite"}
                    onClick={() => setLike(!isLike)}
                    spotlightDisabled={true}
                  ></TIconButton>
                </>
              ) : null}
            </div>
          )}

          {playProp && <div className={css.playIcon} />}
          {itemDescription.playTime && <span className={css.playTime}>{itemDescription.playTime}</span>}
          <div
            className={classNames(
              css.commonAbsolute,
              css.focusCover,
              isChecked && css.selected
            )}
          ></div>

          {itemDescription.playingPercent && (
            <>
              <div className={classNames(css.totalGauge)}></div>
              <div ref={gaugeRef} className={classNames(css.playGauge)}></div>
            </>
          )}
        </div>
      </div>

      <div className={css.textArea}>
        <Marquee className={classNames(css.title, centerTitle ? css.centerTitle : null)} marqueeOn={"focus"}>
          {itemDescription.title}
        </Marquee>
        <Marquee className={classNames(css.subTitle)} marqueeOn={"focus"}>
          {itemDescription.subTitle.length > 0 &&
            itemDescription.subTitle.map((text, index) => (
              <span
                key={`subTitle_${index}`}
                className={classNames(css.subText)}
              >
                {text}
              </span>
            ))}
        </Marquee>
      </div>
      {mode === 'edit' &&
        <Container className={css.countContainer}>
          <TIconButton
            // className={css.iconRight}
            iconType={"countMinus"}
            onClick={countHandler("minus")}
            disabled={itemCount === 0}
          />
          <div className={css.count}>
            {itemCount + $L("회")}
          </div>
          <TIconButton
            // className={css.iconRight}
            iconType={"countPlus"}
            onClick={countHandler("plus")}
            disabled={itemCount === 10}
          />
        </Container>
      }
    </SpottableComponent>
  );
};

const ImageItemDecorator = compose(MarqueeController({ marqueeOnFocus: true }));
const JMediaItem = ImageItemDecorator(MediaItemBase);
export default JMediaItem;
