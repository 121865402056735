import React, { useCallback } from 'react';
import css from "./WalkInPlace.module.less";
import { $L } from '../../../../../utils/helperMethods';
import EmptyResultsReport from '../../../../../components/EmptyResultsReport/EmptyResultsReport';

const WalkInPlace = ({openTestSelectPopup}) => {
  const selectTest = useCallback(() => {
    openTestSelectPopup();
  },[openTestSelectPopup]);

  return (
    <div className={css.Container}>
      <div>WalkInPlace</div>
    </div>
  )
}

export default WalkInPlace;