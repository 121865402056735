import css from './DetailLayout.module.less';
import classNames from 'classnames';
import TButton, {TYPES, ICONS, COLOR, SIZES} from '../../../../components/TButton/TButton';

const TButtonLayout = () => {
    return (
        <div className={css.buttonLayout}>
            <TButton className={css.withMargin}>Normal</TButton>
            <TButton className={css.withMargin} withMarquee={true}>
                Normal with marquee
            </TButton>
            <TButton className={css.withMargin} disabled={true}>
                Normal Disabled
            </TButton>

            <TButton className={css.withMargin} size={SIZES.tiny}>
                tiny size Btn
            </TButton>
            <TButton className={css.withMargin} size={SIZES.tiny} withMarquee={true}>
                tiny size Btn width marquee
            </TButton>
            <TButton className={css.withMargin} size={SIZES.small}>
                small size Btn
            </TButton>
            <TButton className={css.withMargin} size={SIZES.large}>
                large size Btn
            </TButton>
            <TButton className={css.withMargin} size={SIZES.xLarge} color={COLOR.black}>
                x-large and black color
            </TButton>
            <TButton className={css.withMargin} size={SIZES.test}>
                test size Btn
            </TButton>
            <TButton className={css.withMargin} size={SIZES.tiny} color={COLOR.green} >
                tiny size and green color
            </TButton>
            <TButton className={css.withMargin} type={TYPES.withIcon} icon={ICONS.bell}>
                IconButton
            </TButton>
            <TButton className={css.withMargin} type={TYPES.withIcon} icon={ICONS.bell} withMarquee={true} >
                IconButton width marquee
            </TButton>

        </div>
    );
};
export default TButtonLayout;