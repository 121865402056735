import css from "./TListItemButton.module.less";
import classNames from "classnames";
import { useCallback, useState, useRef } from "react";

import * as Config from "../../utils/Config";
import * as SoundEffect from "../../utils/SoundEffect";

import { Job } from "@enact/core/util";
import Spottable from "@enact/spotlight/Spottable";
import { useSelector } from "react-redux";

const SpottableComponent = Spottable("div");

const TListItemButton = ({className, onClick, children, ...rest }) => {
  const [isPressed, setIsPressed] = useState(false);
  const { themeMode } = useSelector((state) => state.common.appStatus);

  const clearPressedJob = useRef(
    new Job((func) => {
      setIsPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setIsPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick(ev);
      }
    });
  }, [onClick]);

  const _onBlur = useCallback((ev) => {
    setIsPressed(false);
    clearPressedJob.current.stop();
  }, []);

  const _onFocus = useCallback(() => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
  }, []);

  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        className,
        css.tListItemButton,
        isPressed && css.pressed,
        themeMode === "dark" ? css.dark : css.light
      )}
      onClick={_onClick}
      onFocus={_onFocus}
      onBlur={_onBlur}
    >
      {children}
    </SpottableComponent>
  );
};

export default TListItemButton;