import React, { useMemo, useState, useCallback, useEffect } from "react";
import Spotlight from "@enact/spotlight";
import css from "./PhysicalTest.module.less";
import * as Config from "../../../utils/Config";
import * as HelperMethods from "../../../utils/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import PhysicalTestTabContentsLayout from "./PhysicalTestTabContentsLayout";
import { updatePanel } from "../../../features/panels/panelsSlice";
import { getBodyCheckUpData } from "../../../features/fitService/fitServiceSlice";
import { $L } from "../../../utils/helperMethods";
import { MENUTYPE, TAB_DETAIL, getTabMenuTitle } from "./Constants";
//컴포넌트
import TPanel from "../../../components/TPanel/TPanel";
import THeader from "../../../components/THeader";
import TBody from "../../../components/TBody/TBody";
import ReportTop from "../../../components/ReportTop/ReportTop";
import TSimpleButtonTab, {
  LIST_TYPE,
} from "../../../components/TSimpleButtonTab/TSimpleButtonTab";
import {
  voiceGuide,
  voiceGuideEmpty,
  voiceGuideOverall,
} from "./feedback/feedbackSFT";
import * as TTSService from "../../../lunaSend/TTSService";
import TextPopup from "../../../components/TextPopup/TextPopup";
import { BODY_CHECKUP_TYPE } from "../../../utils/Constants";
import WebWorkerUtil, { WORKER_ID } from "../../../utils/WebWorker/WebWorkerUtil";
import { useRef } from "react";

/**
 * @module PhysicalTestReport
 * @returns My Report 클릭시 탭레이아웃
 */
let tabMenuTitle = null;
const PhysicalTestReport = ({ panelInfo }) => {
  if (!tabMenuTitle) {
    tabMenuTitle = getTabMenuTitle();
  }
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(
    panelInfo.selectedDate ? panelInfo.selectedDate : null
  );
  const [isText, setText] = useState(false);
  const [feedback, setFeedback] = useState();
  const { dateList, testResults } = useSelector((state) => state.physicalTest);
  const { overall, subTab } = useSelector(
    (state) => state.common.appStatus.voiceGuideStatus[BODY_CHECKUP_TYPE.PHYSICAL_TEST]
  );
  // const [selectedTabIndex, setSelectedTabIndex] = useState(
  //   panelInfo?.selectedTabIndex ? panelInfo.selectedTabIndex : 0
  // ); //tab
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const mWorker = useRef(null);
  const { age, sex } = useSelector(state => state.fitService.survey);

  const isEmpty = useMemo(() => {
    return !dateList || dateList.length <= 0;
  }, [dateList]);

  const tabInfos = useMemo(() => {
    const titleArray = tabMenuTitle;
    const ret = [];
    const keys = Object.keys(MENUTYPE);
    let totalScore = 0;
    for (let i = 0; i < keys.length; i++) {
      let score = 0,
        value = 0,
        grade = "",
        testkey = "",
        boundary,
        minValue;

      if (keys[i] !== MENUTYPE.OVERALL) {
        const detail = TAB_DETAIL[keys[i]];
        score =
          testResults[selectedDate]?.[detail.testkey]?.score &&
          testResults[selectedDate]?.[detail.testkey]?.score >= 0
            ? testResults[selectedDate]?.[detail.testkey]?.score
            : null;
        grade = testResults[selectedDate]?.[detail.testkey]?.grade
          ? testResults[selectedDate]?.[detail.testkey]?.grade
          : null;
        value = testResults[selectedDate]?.[detail.testkey]?.value
          ? testResults[selectedDate]?.[detail.testkey]?.value
          : null;
        testkey = detail.testkey;

        const originBoundary =
          testResults[selectedDate]?.[detail.testkey]?.boundary;
        if (originBoundary) {
          boundary = originBoundary.slice(0, 5);
          minValue = originBoundary.slice(-1);
        }
      }

      ret.push({
        type: keys[i],
        tabName: titleArray[keys[i]][0],
        tabNameTwoLine: titleArray[keys[i]][1],
        testkey: testkey,
        score: score,
        grade: grade,
        value: value,
        boundary,
        minValue,
        sortIndex: i,
      });
      totalScore += score;
    }
    ret[0].score = totalScore;
    ret.sort((a, b) => {
      const convertGrade = {
        s: 5,
        a: 4,
        b: 3,
        c: 2,
        d: 1,
      };
      // null 값 처리
      if (a.grade === null && b.grade !== null) {
        return 1;
      }
      if (b.grade === null && a.grade !== null) {
        return -1;
      }

      if (a.type === MENUTYPE.OVERALL && b.type !== MENUTYPE.OVERALL) {
        return -1;
      }
      if (b.type === MENUTYPE.OVERALL && a.type !== MENUTYPE.OVERALL) {
        return 1;
      }

      if (convertGrade[a.grade] < convertGrade[b.grade]) return -1;
      if (convertGrade[b.grade] < convertGrade[a.grade]) return 1;

      if (a.grade === b.grade) {
        if (a.score === b.score) {
          return b.sortIndex < a.sortIndex ? 1 : -1;
        } else {
          return b.score < a.score ? 1 : -1;
        }
      }
    });
    const lowestScoreTabNames = [];
    const filterdRet = ret.filter(
      (item) => item.type !== MENUTYPE.OVERALL && item.score !== null
    );
    const lowestScore = Math.min(...filterdRet.map((item) => item.score));
    filterdRet.forEach((item) => {
      if (item.score === lowestScore) {
        lowestScoreTabNames.push(item.tabName);
      }
    });
    const tabName = ret.map((item) => item.tabName);
    const tabNameTwoLine = ret.map((item) => item.tabNameTwoLine);
    const score = ret.map((item) => item.score);
    const grade = ret.map((item) => item.grade);

    return {
      contentsInfos: ret,
      lowestScoreTabNames,
      lowestScore,
      tabName,
      tabNameTwoLine,
      score,
      grade,
    };
  }, [testResults, selectedDate]);

  const onItemClick = useCallback(
    ({ index }) => {
      TTSService.stop();
      setSelectedTabIndex(index);
      setText(false);
      dispatch(
        updatePanel({
          name: Config.panel_names.PHYSICAL_TEST_REPORT,
          panelInfo: { selectedTabIndex: selectedTabIndex },
        })
      );
    },
    [dispatch]
  );

  const onSpotlightDownFromTop = useCallback((ev) => {
    Spotlight.focus("PhysicalTestReportTab");
    ev.stopPropagation();
  }, []);

  const disabledItem = useMemo(() => {
    if (!isEmpty) {
      const isNullArr = tabInfos.contentsInfos.filter(
        (item) => item.score === null
      );
      const disabledItemArr = [];
      isNullArr.map((item) => disabledItemArr.push(item.tabNameTwoLine));
      return disabledItemArr;
    } else {
      return tabInfos.tabNameTwoLine.slice(1);
    }
  }, [isEmpty, tabInfos]);

  useEffect(() => {
    const feedbackCall = () => {
      if (selectedTabIndex === 0) {
        return isEmpty
          ? voiceGuideEmpty()
          : voiceGuideOverall(
              tabInfos.lowestScoreTabNames,
              tabInfos.lowestScore,
              disabledItem
            );
      } else {
        return voiceGuide(
          tabInfos.score[selectedTabIndex],
          tabInfos.tabName[selectedTabIndex]
        );
      }
    };

    setFeedback(feedbackCall());
  }, [selectedTabIndex, tabInfos, isEmpty, disabledItem]);

  useEffect(() => {
    if (feedback) {
      if (selectedTabIndex === 0) {
        console.log("SFT feedback text: ", feedback);
        overall ? TTSService.speak(feedback.feedbackVoice) : TTSService.stop();
      } else {
        console.log("SFT feedback text: ", feedback);
        subTab ? TTSService.speak(feedback.feedbackVoice) : TTSService.stop();
      }
    }
    return () => {
      TTSService.stop();
    };
  }, [selectedTabIndex, overall, subTab, feedback]);

  const onSelectedDate = useCallback(
    ({ selectedDate: date }) => {
      const strDate = HelperMethods.convertDateToString2(date);
      if (strDate !== selectedDate) {
        setSelectedDate(strDate);
        setSelectedTabIndex(0);
        dispatch(getBodyCheckUpData({type: BODY_CHECKUP_TYPE.PHYSICAL_TEST, date:strDate, syncFlag:"immediately"}));
        dispatch(
          updatePanel({
            name: Config.panel_names.PHYSICAL_TEST_REPORT,
            panelInfo: { selectedDate: strDate },
          })
        );
      }
    },
    [dispatch, selectedDate]
  );

  const onResponseWorker = useCallback((e) => {
    console.log('onResponseWorker...', e);
    if (e.type === 'getSftScoreBoundary') {

    }
  })

  useEffect(() => {
		WebWorkerUtil.makeWorker(WORKER_ID.XFIT, onResponseWorker).then((workerId) => {
      mWorker.current = workerId;
      WebWorkerUtil.postMessage(mWorker.current, { type: "init" }, true);
      /** value
       * test_id: SFT mode 별 test id
       * age: 60 ~ 94, default: 60
       * gender: male | female, default: male
       */
      WebWorkerUtil.postMessage(mWorker.current, { type: "getSftScoreBoundary", value: ["2", HelperMethods.clampingNumber(age, 60, 94), sex === 1 ? "female" : "male"] }, true);
    });
  }, []);

  return (
    <>
      <TPanel>
        <THeader title={$L("Physical Test")} />
        {/* tab 버튼상단부 */}
        <TBody style={{ paddingTop: "9px" }}>
          <ReportTop
            className={css.top}
            onSpotlightDown={onSpotlightDownFromTop}
            reportType={BODY_CHECKUP_TYPE.PHYSICAL_TEST}
            selectedTabIndex={selectedTabIndex}
            dateList={
              isEmpty
                ? [HelperMethods.convertDateToString2(new Date())]
                : dateList
            }
            selectedDateStr={selectedDate}
            onSelectedDate={onSelectedDate}
            disabled={!dateList || dateList.length <= 0}
            isText={isText}
            setText={setText}
          />
          <div className={css.tabLayOut}>
            {tabInfos.contentsInfos && tabInfos.contentsInfos.length > 0 && (
              <TSimpleButtonTab
                spotlightId={"PhysicalTestReportTab"}
                className={css.tab}
                listType={LIST_TYPE.report}
                contents={tabInfos.tabNameTwoLine}
                selectedIndex={selectedTabIndex}
                maxItemCount={7}
                onItemClick={onItemClick}
                score={tabInfos.grade}
                disabledItem={disabledItem}
                type="PhysicalTest"
                noMarquee={true}
              />
            )}
          </div>
          {/* tab하단부 Contents*/}
          <PhysicalTestTabContentsLayout
            physicalTestInfo={tabInfos.contentsInfos[selectedTabIndex]}
            selectedDate={selectedDate}
            contentsInfos={tabInfos.contentsInfos}
            feedback={feedback}
            isEmpty={isEmpty}
          />
          <div className={css.medicalCenter} />
        </TBody>
      </TPanel>
      <TextPopup
        open={isText}
        onClose={() => setText(false)}
        children={feedback?.feedbackVoice}
      />
    </>
  );
};

export default PhysicalTestReport;
