import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { memoize } from "@enact/core/util";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import DurationFmt from 'ilib/lib/DurationFmt';

import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import { $L, convertDateToString2, formatDateTime, formatTime } from '../../../utils/helperMethods';
import css from './HPRoutineProgram.module.less';
import TSimpleDatePicker from "../../../components/TSimpleDatePicker/TSimpleDatePicker";
import TButton, { SIZES, TYPES } from '../../../components/TButton/TButton';
import { addPanels, updatePanel } from '../../../features/panels/panelsSlice';
import { panel_names } from '../../../utils/Config';
import TCalendar from '../../../components/TCalendar/TCalendar';
import TTimeProgressBar from '../../../components/TTimeProgressBar/TTimeProgressBar';
import Times from '../../../components/Times/Times';
import useContent from '../../../hooks/useContent';
import { getAiList, getContent } from '../../../features/fitService/fitServiceSlice';
import THorizontalScrollList, { ITEM_SIZE } from '../../../components/THorizontalScrollList/THorizontalScrollList';
import SMediaItem from '../../../components/SMediaList/SMediaItem';

const Container = SpotlightContainerDecorator({ enterTo: "dafault-focused" }, "div");
const memoGetDurFmt = memoize((/* locale */) => new DurationFmt({length: 'long', style: 'clock', useNative: false}));
const getDurFmt = () => {
  if (typeof window === 'undefined') return null;

	return memoGetDurFmt();
};
const DUR_FMT = getDurFmt();

const HPRoutineProgram = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const { activated, alarms } = useSelector(state => state.alarm);
  const aiLists = useSelector((state) => state.content.aiLists);
  const [selectDate, setSelectDate] = useState(new Date());
  const selectDateRef = useRef(selectDate);
  const [selectDay, setSelectDay] = useState(new Date().getDay());

  // todo remove demo
  const whitelist = ["2023-12-11", "2023-12-13", "2023-12-15",
  "2023-12-18", "2023-12-20", "2023-12-22",
  "2023-12-25", "2023-12-27", "2023-12-29",
  "2024-01-01", "2024-01-03", "2024-01-05"];
  // todo data 날짜별
  const progressList = {
    "2023-12-11": {
      progress: 287,
      total: 287
    },
    "2023-12-13": {
      progress: 50,
      total: 287
    },
    "2023-12-15": {
      progress: 50,
      total: 230
    },
    "2023-12-18": {
      progress: 0,
      total: 287
    }
  };

  //todo data 프로그램별
  const progress = 102;
  const total = 287;

  const SELECT_CONTENT = useMemo(() => {
    return { type: "recommend", selectList: aiLists[panelInfo.programIndex], mode: 'routine' }
  },[aiLists, panelInfo]);

  const { selectContentList } = useContent(SELECT_CONTENT);

  useEffect(() => {
    selectDateRef.current = selectDate;
  },[selectDate]);

  useEffect(() => {
    dispatch(getAiList());

    if(panelInfo.panelInfo && panelInfo.panelInfo.lastFocusedId){
      setTimeout(() => {
        setSelectDate(new Date(panelInfo.panelInfo.lastFocusedId))
      }, 0);
    } else {
      setTimeout(() => {
        setSelectDate(new Date());
      }, 0);
    }
    return () => {
      dispatch(updatePanel({ name: panel_names.HOMECARE_ROUTINE_PROGRAM, panelInfo: Object.assign({}, panelInfo.panelInfo, { lastFocusedId: convertDateToString2(selectDateRef.current) })}));
    }
  },[]);

  const onClickDetail = useCallback(() => {
    dispatch(addPanels({ name: panel_names.HOMECARE_ROUTINE_PROGRAM_COMPOSITION, panelInfo: panelInfo }));
  },[panelInfo]);

  const onClickCenter = useCallback(() => {
    // todo
    dispatch(addPanels({ name: panel_names.HOMECARE_CENTER_INFO, panelInfo: panelInfo.panelInfo }));
  },[panelInfo]);

  const onClickAlarm = useCallback(() => {
    dispatch(addPanels({ name: panel_names.HOMECARE_ALARM_SETTING, panelInfo: {
      title: panelInfo.panelInfo?.title,
      programTitle: panelInfo.panelInfo?.programList[panelInfo.programIndex].programTitle,
      timesAweek: panelInfo.panelInfo?.timesAweek
    }}));
  },[panelInfo]);

  const onClickStart = useCallback(() => {
    const data = [];
    aiLists?.[panelInfo.programIndex]?.[selectDay]?.contentList.forEach(el => {
      data.push({ contentId: el });
    });
    dispatch(addPanels({ name: panel_names.CONTENT_PLAYER_START, panelInfo: data }));
  },[aiLists, panelInfo, selectDay]);

  const onSelectedDate = useCallback(({ selectedDate }) => {
    setSelectDate(selectedDate);
    setSelectDay(new Date(selectedDate).getDay());
  },[]);

  const onMediaClick = useCallback(({ itemInfo }) => {
    if (itemInfo) {
      dispatch(getContent({ contentId: itemInfo.contentId }));
    }
    dispatch(addPanels({ name: panel_names.CONTENT_PLAYER_START, panelInfo: [{ contentId: itemInfo?.contentId }] }));
  }, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("O2O 정규프로그램")} />
      <TBody className={css.body}>
        <Container className={css.leftContainer}>
          <div className={css.toplayer}>
            <TSimpleDatePicker
              period={"total"}
              selectedDate={selectDate}
              // onSelectedDate={_onSelectedDate}
              size="small"
              width="small"
              // whitelist={dateList}
              useCalendar={false}
              noArrow={true}
            />
            <TButton
              onClick={onClickDetail}
            >
              {$L("See program details")}
            </TButton>
          </div>
          <div className={css.contantLayer}>
            <div className={css.centerInfo}>
              <img src={panelInfo.panelInfo?.logoSmall} alt='center image' className={css.logo} />
              <div className={css.info}>
                <TButton
                  type={TYPES.noBg}
                  className={css.title}
                  onClick={onClickCenter}
                >
                  {panelInfo.panelInfo?.title}
                  <span className={css.arrow}/>
                </TButton>
                <div className={css.week}>
                  {"1주차 프로그램"+" "}<span>{$L("In progress")}</span>
                </div>
                <div className={css.alarmLayer}>
                  <span className={css.alarmimg}/>
                  {activated && alarms[selectDay].active ?
                    <div className={css.alarTime}>
                      {$L("Workout Time")}
                      <span className={css.hr}/>
                      <div className={css.time}>
                        {formatDateTime('aa hh:mm', "2000-01-01 "+ alarms[selectDay].time)}
                      </div>
                    </div>
                  :
                    <div className={css.alarTime}>
                      {$L("You don't have any alerts")}
                    </div>
                  }
                  <TButton size={SIZES.tiny} onClick={onClickAlarm}>
                    {$L("Set alerts")}
                  </TButton>
                </div>
              </div>
            </div>
            <div className={css.hr} />
            {selectContentList &&
            selectContentList[selectDay] &&
            selectContentList[selectDay].length > 0
            ?
              <THorizontalScrollList
                itemSize={ITEM_SIZE.small}
                startGap={48}
              >
              {selectContentList[selectDay].map((item, index) => (
                  <SMediaItem
                    key={`routine_${selectDay}_${index}`}
                    itemSize={ITEM_SIZE.small}
                    itemInfo={item}
                    onClick={onMediaClick}
                  />
                ))}
              </THorizontalScrollList>
            :
              <div className={css.noWokrout}>
                {$L("Today is a day off from workout.")}
              </div>
            }
            {selectContentList &&
              selectContentList[selectDay] &&
              selectContentList[selectDay].length > 0 && (
              <div className={css.buttonLayer}>
                <TButton
                  className={css.button}
                  size={SIZES.xLarge}
                  onClick={onClickStart}
                >
                  {$L("Getting started")}
                </TButton>
              </div>
            )}
          </div>
        </Container>
        <Container className={css.rightLayer}>
          <div className={css.tooltip}>
            <div
              dangerouslySetInnerHTML={{
                __html: $L("Select the {DATE}.").replace(
                  "{DATE}",
                  `<span>${$L("The date of your next workout")}</span>`
                ),
              }}
            />
            {$L("Show exercise programs for this day.")}
          </div>
          <div className={css.calenderLayer}>
            <TCalendar
              className={css.calender}
              hasProgress={true}
              progressList={progressList}
              onSelectedDate={onSelectedDate}
              selectedDate={selectDate}
              whitelist={whitelist}
            />
            <div className={css.todayTimeLayer}>
              <div className={css.titleLayer}>
                <div className={css.title}>{"Today Worktout time"}</div>
                <div className={css.timeInfo}>
                  <div>{formatTime(progressList[convertDateToString2(selectDate)]?.progress || 0)}</div>
                  <div>/</div>
                  <div>{formatTime(progressList[convertDateToString2(selectDate)]?.total || 0)}</div>
                  <div>{$L("min")}</div>
                </div>
              </div>
              <div className={css.hr}/>
              <div className={css.titleLayer}>
                <div className={css.title}>
                  <span className={css.img} />
                  <div className={css.title} >{"Worktout time"}</div>
                </div>
                <div className={css.percent}>{Math.round((progress/total * 100))+'%'}</div>
              </div>
              <TTimeProgressBar
                progress={progress}
                total={total}
                className={css.timeProgressBar}
              />
              <div className={css.timeInfo}>
                <Times noCurrentTime total={progress ? progress: 0} formatter={DUR_FMT} />
                <div>/</div>
                <Times noTotalTime current={total} formatter={DUR_FMT} />
                <div>{$L("min")}</div>
              </div>
            </div>
          </div>
        </Container>
      </TBody>
    </TPanel>

  );
};

export default HPRoutineProgram;
