
import css from "./ROMReport.module.less";
import classNames from "classnames";
import { useCallback, useState, useRef } from "react";

import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const ROMReport = ({className, ...rest}) => {


  return (
    <Container
      {...rest}
      className={classNames(
        className,
      )}
    >
      <div className={css.dummyImage}></div>
    </Container>
  );
};

export default ROMReport;