import classNames from "classnames";
import css from "./TTestClosePopup.module.less";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Spottable from "@enact/spotlight/Spottable";
import THeader from "../THeader/THeader";
import * as Config from "../../utils/Config";
import Alert from "@enact/sandstone/Alert";
import { Job } from "@enact/core/util";
import { changeThemeModeStatus } from "../../features/common/commonSlice";

const SpottableComponent = Spottable("div");

const TTestClosePopup = ({ onClick }) => {
  const dispatch = useDispatch();
  const [pressed, setPressed] = useState(false);

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const onBlur = useCallback(() => {
    setPressed(false);
    clearPressedJob.current.stop();
  }, []);

  // const _onClick = useCallback((index, ev) => {
  //   if (onClick) {
  //     if (index === 1) {
  //       if(ev) ev.stopPropagation();
  //       dispatch(popPanel());
  //     }
  //     onClick(index, ev);
  //   }
  // }, [dispatch, onClick]);

  const toggleHandler = useCallback(() => {
    setPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick();
      }
    });
  },[onClick]);

  const onClose = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  },[]);

  return (
    <Alert type={"fullscreen"} open className={css.popupPanel} onClose={onClose}>
      <THeader iconType="back" useTool={false}/>
      <div className={css.container}>
        <SpottableComponent
          id="pausePlayToggle"
          className={css.playIcon}
          onClick={toggleHandler}
          onBlur={onBlur}
        >
          <div className={classNames(css.play, pressed ? css.pressed : null)}/>
        </SpottableComponent>
      </div>
    </Alert>
  );
};

export default TTestClosePopup;
