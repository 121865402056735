import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import css from "./TestEndCompletePopup.module.less";
import TButton from "../TButton";
import Spritesheet from "react-responsive-spritesheet";
import Spotlight from "@enact/spotlight";
import Alert from '@enact/sandstone/Alert';
import { $L } from "../../utils/helperMethods";
import CompleteImg from "../../../assets/testPopup/test_complete.png";
import testEndSprites from "../../../assets/testPopup/testEnd/10frame_sec/test-end-sprites.png";
import TToolTip, {POSITION, COLOR} from "../TToolTip/TToolTip";

const TestEndCompletePopup = ({ open, onClose, testType, retryPreviousTest, ...rest }) => {
  const intervalRef = useRef(null);
  const closeTimerRef= useRef(null);
  const spriteRef = useRef(null);
  const retryRef = useRef();
  const [testRetry, setTestRetry] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const spriteData = useMemo(() => ({
    sprite: {
      image: testEndSprites,
      scaleUp: 1.0,
      widthFrame: 820,
      heightFrame: 500,
      steps: 28,
      fps: 10,
    }
  }), []);

  const _onClose = useCallback((ev) => {
    console.log('onClose', ev);
    if(onClose){
      onClose(ev)
    }
  }, [onClose]);

  useEffect(() => {
    if(spriteRef.current){
      spriteRef.current.pause();
      spriteRef.current.setDirection("forward");
      spriteRef.current.setStartAt(0);
      spriteRef.current.frame = 0;
      spriteRef.current.init();
      spriteRef.current.play();
    }
  }, []);

  useEffect(() => {
    if(testRetry){
      clearInterval(intervalRef.current);
      clearTimeout(closeTimerRef.current);
      intervalRef.current = null;
      closeTimerRef.current = null;
      return;
    }
    intervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    closeTimerRef.current = setTimeout(() => {
      _onClose({});
    }, 5000);
    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(closeTimerRef.current);
    };
  }, [testRetry]);

  const retryTest = useCallback(()=>{
    if(retryPreviousTest){
      retryPreviousTest();
      setTestRetry(true);
    }
  },[]);

  const retryRenderer = useCallback((isTooltip)=>{
    return(
      <TToolTip
        position={POSITION.top}
        color={COLOR.green}
        relativeRef={retryRef}
        relativePosition="right"
        isOpen={isTooltip}
      >
        {$L("You can retake any test you want.")}
      </TToolTip>
    );
  },[]);

  return (
    <Alert {...rest} className={css.panel}
      open
      onClose={_onClose}
      type={"fullscreen"}
    >
      <div className={css.container}>
        <img src={CompleteImg} alt="CompleteImg"/>
        {spriteData.sprite &&
          <div className={css.imageContainer}>
            <Spritesheet
              ref={spriteRef}
              image={spriteData.sprite.image}
              widthFrame={spriteData.sprite.widthFrame}
              heightFrame={spriteData.sprite.heightFrame}
              steps={spriteData.sprite.steps}
              fps={spriteData.sprite.fps}
              autoplay={true}
              loop={true}
            />
          </div>
        }
        <div className={css.title}>{testType === "single" ? $L("You've completed the test!"):$L("You've completed all tests!")}</div>
            {!testRetry && (
          <div
            className={css.countdown}
            dangerouslySetInnerHTML={{
              __html: $L("The result page will load in {NUM} seconds.").replace(
                "{NUM}",
                `<span>${countdown}</span>`
              ),
            }}
          />
        )}
      </div>
      {testType === "total" &&
        <div className={css.retryBox} ref={retryRef}>
          <TButton
            size ="small"
            className={css.retryBtn}
            itemRenderer={retryRenderer}
            onClick={retryTest}
          >
            {$L("Repeat the previous test")}
          </TButton>
        </div>
      }
    </Alert>
  );
};

export default TestEndCompletePopup;