import React, { useRef, useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from 'classnames';
import css from "./Canvas.module.less";
import * as HelperMethods from "../../utils/helperMethods";
import * as Config from "../../utils/Config";

import {BODY_SCAN_COLOR, SCORE_RED_KEY, SCORE_YELLOW_KEY, SCORE_MINT_KEY} from "../../utils/Constants";
import {ILLUST_IMAGE_INFO, getIllustImage, getBaseLine, WHOLEBODY_ILLUST_WIDTH, WHOLEBODY_ILLUST_HEIGHT} from "../../../assets/images/bodyIllust/bodyIllust";

import arrowR from "../../../assets/images/arrowR.svg";
import arrowY from "../../../assets/images/arrowY.svg";
import arrowM from "../../../assets/images/arrowM.svg";

//dummy Arrow Top (3color)
import arrowBad_T from "../../../assets/images/arrowBad_T.svg";
import arrowGood_T from "../../../assets/images/arrowGood_T.svg";
import arrowNormal_T from "../../../assets/images/arrowNormal_T.svg";
//골반
import rotateRed from "../../../assets/images/rotateRed.svg";
import rotateYellow from "../../../assets/images/rotateYellow.svg";
import rotateMint from "../../../assets/images/rotateGreen.svg";

/*
원형 잇는 solid line
*/
const drawLine = (ctx, x1, y1, x2, y2, color, width) => {
	ctx.beginPath();
	ctx.lineWidth = width;
	ctx.strokeStyle = color;
	ctx.moveTo(x1, y1);
	ctx.lineTo(x2, y2);
	ctx.stroke();
	ctx.closePath();
};
/*
 사각형
*/
const drawRect = (ctx, x1, y1, w, h, color, linewidth = 2)=> {
	ctx.beginPath();
	ctx.strokeStyle = color;
	ctx.lineWidth = linewidth;
	ctx.strokeRect(x1,y1, w, h);
	ctx.closePath();
};
const drawDot = (ctx, x1, y1,color, radius = 8, lineWidth=2)=> {
	ctx.beginPath();
	// ctx.strokeStyle = "white";
	ctx.arc(x1, y1,radius, 0, 2 * Math.PI);
	// ctx.arc(x1, y1, 6, 0, 2 * Math.PI);
	ctx.fillStyle = color;
	ctx.lineWidth = lineWidth; //흰색 선
	ctx.fill();
	if(lineWidth > 0){
		ctx.stroke();
	}
	ctx.closePath();
};

/*
	선 잇는 동그라미
*/
const drawDots = (ctx, x1, y1, x2, y2, color, radius, linewidth)=> {
	drawDot(ctx, x1, y1, color, radius, linewidth);
	drawDot(ctx, x2, y2, color, radius, linewidth);
};

/*
	점선
*/
const dottedLine = (ctx, x1, y1, x2, y2, linewidth) => {
  ctx.beginPath();
  ctx.lineWidth = linewidth;
  ctx.strokeStyle = DOTTED_LINE_STYLE;

  // 동그라미 모양의 점선 패턴
  const circleDiameter = linewidth * 1.5; // 원의 지름
  const gap = linewidth; // 빈 공간
  // ctx.setLineDash([circleDiameter, gap]);

  // 선 그리기
  ctx.moveTo(x1, y1);
  const dx = x2 - x1;
  const dy = y2 - y1;
  const distance = Math.sqrt(dx * dx + dy * dy);
  const segments = Math.ceil(distance / (circleDiameter + gap));
  const stepX = dx / segments;
  const stepY = dy / segments;

  for (let i = 0; i < segments; i++) {
    const cx = x1 + stepX * i;
    const cy = y1 + stepY * i;
    drawDot(ctx, cx, cy, DOTTED_LINE_STYLE, linewidth/2, linewidth/2);
  }
  ctx.closePath();
  // 패턴 초기화
  // ctx.setLineDash([0, 0]);
};

const calculateCuttingSize2 = (top, bottom, outSize, xCenter, imageSize) => {
	const ret = { x: 0, y: 0, w: 0, h: 0 };
	const originH = bottom-top;
	const calculatedW = (originH * outSize[0]) / outSize[1];

	ret.x = xCenter - calculatedW/2;
	if(ret.x < 0){
		ret.x = 0;
	}
	ret.y = top;
	ret.w = calculatedW;
	ret.h = originH;
	if((ret.x +ret.w) >= (imageSize[0])){
		ret.x = imageSize[0] - calculatedW -1;
	}
	return ret;
};

// const calculateCuttingSize = (top, bottom, outSize) => {
// 	const ret = { x: 0, y: 0, w: 0, h: 0 };
// 	const originH = bottom-top;
// 	const calculatedW = (originH * outSize[0]) / outSize[1];

// 	ret.x = outSize[0]/2 - calculatedW/2;
// 	ret.y = top;
// 	ret.w = calculatedW;
// 	ret.h = originH;
// 	return ret;
// };
const convertCoordinations = (coordinations, cutResult, canvasSize) => {
	const res = [];
	if (coordinations !== undefined && cutResult !== undefined) {
		for (let i = 0; i < coordinations.length; i++) {
			let calculatedCoords = { x: null, y: null };
			calculatedCoords.x = coordinations[i].x - cutResult.x;
			calculatedCoords.y = coordinations[i].y - cutResult.y;
			calculatedCoords.x *= (canvasSize[0]/cutResult.w);
			calculatedCoords.y *= (canvasSize[1]/cutResult.h);
			res.push(calculatedCoords);
		}
	}
	return res;
};
//[x,y,width,height]
const convertBBox = (bbox, cutResult, canvasSize) => {
	const res = [];
	if(bbox){
		const scaleX = (canvasSize[0]/cutResult.w);
		const scaleY = (canvasSize[1]/cutResult.h);
		res[0] = bbox[0] - cutResult.x;
		res[1] = bbox[1] - cutResult.y;
		res[0] *= scaleX;
		res[1] *= scaleY;
		res[2] = bbox[2] * scaleX;
		res[3] = bbox[3] * scaleY;
	}
	return res;
};
const convertTestResult = (data, cutResult, canvasSize) => {
	const res = HelperMethods.cloneObject(data);
	const keys = Object.keys(data);
	for(let i=0; i< keys.length; i++){
		res[keys[i]] = [...data[keys[i]]];
		const positions = data[keys[i]][2];
		const newPosition = [];
		if(positions){
			for(let j=0; j<positions.length; j++){
				newPosition.push([positions[j][0] - cutResult.x, positions[j][1] - cutResult.y]);
				newPosition[j][0] *= (canvasSize[0]/cutResult.w);
				newPosition[j][1] *= (canvasSize[1]/cutResult.h);
			}
		}
		res[keys[i]][2] = newPosition;
	}
	return res;
};

const getPointFromAngle = (point1=[], point2=[], angle) => {
		const radian = angle*(Math.PI/180)
		const answerX = (point2[0]-point1[0])*Math.cos(radian)-(point2[1]-point1[1])*Math.sin(radian)
		const answerY = (point2[0]-point1[0])*Math.sin(radian)+(point2[1]-point1[1])*Math.cos(radian)
		const answer = [answerX+point1[0], answerY+point1[1]]
		return answer
}

const getIllustPoints = (bodyType, type, testkey, angle, testResultSub, state) => {
	let points = [[],[]];
	let baseLine = getBaseLine(type, testkey, testResultSub);
	if(angle > 90 &&(testkey === 'leg_alignment_right'|| testkey === 'leg_alignment_left')){
		angle = Math.abs((180-angle)/2);
	}
	if(state === 'front'){
		angle = -angle;
	}
	if(testkey === 'leg_alignment_right'|| testkey === 'leg_alignment_left'){
		points[1] = baseLine[0];
		points[3] = baseLine[1];
		if(testkey === 'leg_alignment_right' && state === 'in' ||
			testkey === 'leg_alignment_left' && state === 'out' ){
			angle = -angle;
		}
		// const center = [baseLine[0][0], (baseLine[0][1] +baseLine[1][1])/2];
		const center = baseLine[2];
		const anglePoint = getPointFromAngle(baseLine[0], center, angle);
		points[0] = anglePoint;
		points[2] = anglePoint;
		return points;
	}
	if(state === 'right high'){
		if(testkey !== 'neck_tilt'){
			baseLine.reverse();
		}
		angle = -angle;
	}
	const anglePoint = getPointFromAngle(baseLine[0], baseLine[1], angle);
	points[1] = testkey === 'pelvic_rotation' ? baseLine[1]: anglePoint;
	points[0] = baseLine[0];
	return points.concat(baseLine);
}

const UPPER_BODY_COOR_INDEX_BOTTOM = 0;
const BODY_COOR_INDEX_TOP = 18;
const BODY_COOR_INDEX_BOTTOM = 2;
const BODY_COOR_INDEX_BOTTOM2 = 3;
const LOWER_BODY_COOR_INDEX_TOP = 17;
// const LOWER_BODY_COOR_INDEX_BOTTOM = 31;
//trunkBody
// const TRUNK_BODY_COOR_INDEX_TOP = 10;
const TRUNK_BODY_COOR_INDEX_BOTTOM = 4;
const TRUNK_BODY_COOR_INDEX_BOTTOM2 = 5;

const LEG_ARROW_MARGIN= 10;
const FONT_SIZE = 28;
const ARROW_MARGIN = 16;
const TEXT_MARGIN = FONT_SIZE/2;
const DOTTED_LINE_STYLE = '#B2B0AE';
const DOT_LINE_WIDTH = 0;
const DOT_RADIUS = 8;
const LINE_WIDTH = 4;
const STANDARD_CANVAS_HEIGHT = 374; //키우면 화살표 등 작아짐

const LEVEL_ATTRIBUTE = {
	[SCORE_RED_KEY]: {
		color: BODY_SCAN_COLOR.danger,
		arrowT: arrowBad_T,
		rotate: rotateRed,
		arrow: arrowR,
		scoreKey: SCORE_RED_KEY
	},
	[SCORE_YELLOW_KEY]: {
		color: BODY_SCAN_COLOR.manage,
		arrowT: arrowNormal_T,
		rotate: rotateYellow,
		arrow: arrowY,
		scoreKey: SCORE_YELLOW_KEY
	},
	[SCORE_MINT_KEY]: {
		color: BODY_SCAN_COLOR.fine,
		arrowT: arrowGood_T,
		rotate: rotateMint,
		arrow: arrowM,
		scoreKey: SCORE_MINT_KEY
	},
}
/**
 * @param front front, side
 * @prarms type 'wholebody(전신)', 'upperbody(상체)', 'body(몸통, 명치~무릎까지)', 'lowerbody(하체)' 'trunkbody(10번 ~ 4번과 5번 사이)'...
 * @module Canvas
 * @returns Canvas
 */
const Canvas = ({
  bodyType = "front",
  type = "body",
  testkeys = [],
  className,
  testResult = {},
  adjustScale = 1.0,
  isIllust,
  disabledText = false,
  ...rest
}) => {
	const canvasRef = useRef();
	const imageRef = useRef();
	const drawTimerRef = useRef();
	const [canvasSize, setCanvasSize]=useState([0,0]);
	const [imageSize, setImageSize]=useState([0,0]);
	const { showBodyCoordinations } = useSelector((state) => state.common.localSettings);
	// let canvas;

	const bodyCoordinations = useMemo(()=>{
		return testResult?.[bodyType]?.bodyCoordination;
	},[testResult, bodyType]);

	const bbox = useMemo(()=>{
		return testResult?.[bodyType]?.bbox;
	},[testResult, bodyType]);

	const imageSrc = useMemo(()=>{
		return testResult?.[bodyType]?.image;
	},[testResult, bodyType]);

	const imageSource = useMemo(()=>{
		if(isIllust){
			return getIllustImage(bodyType, type, testkeys[0], testResult);
		}
		if(imageSrc){
			return imageSrc;
		}
	},[imageSrc, isIllust, testkeys, type, bodyType]);


	const onLoadImage = useCallback((ev)=>{
		if(ev.target){
			setImageSize([ev.target.naturalWidth, ev.target.naturalHeight]);
			if(type === 'wholebody'){
				// setCanvasSize([ev.target.naturalWidth, ev.target.naturalHeight]);
				setCanvasSize([canvasRef.current.getBoundingClientRect().width, canvasRef.current.getBoundingClientRect().height]);
			}else{
				setCanvasSize([canvasRef.current.getBoundingClientRect().width, canvasRef.current.getBoundingClientRect().height]);
			}
		}
	},[type]);

	const cropImage = useCallback((ctx)=>{
		//use original image
		let ret;
		switch(type){
			case 'upperbody':{
				ret = calculateCuttingSize2(0, bodyCoordinations[UPPER_BODY_COOR_INDEX_BOTTOM].y, canvasSize, bodyCoordinations[7].x, imageSize);
				break;
			}
			case 'body':{
				const b = (bodyCoordinations[BODY_COOR_INDEX_BOTTOM].y +  bodyCoordinations[BODY_COOR_INDEX_BOTTOM2].y) / 2;
				ret = calculateCuttingSize2(bodyCoordinations[BODY_COOR_INDEX_TOP].y, b, canvasSize, bodyCoordinations[0].x, imageSize);
				break;
			}
			case 'lowerbody':{
				ret = calculateCuttingSize2(bodyCoordinations[LOWER_BODY_COOR_INDEX_TOP].y, imageSize[1]-1, canvasSize, bodyCoordinations[0].x, imageSize);
				break;
			}
			case 'trunkbody' : {
				const b = (bodyCoordinations[TRUNK_BODY_COOR_INDEX_BOTTOM].y + bodyCoordinations[TRUNK_BODY_COOR_INDEX_BOTTOM2].y) / 2;
				ret = calculateCuttingSize2(0, b, canvasSize, bodyCoordinations[0].x, imageSize);
				break;
			}
			default:
				ret = calculateCuttingSize2(0, imageSize[1]-1, canvasSize, bodyCoordinations[0].x, imageSize);
				break;
		}

		//crop image
		ctx.drawImage(imageRef.current, ret.x, ret.y, ret.w, ret.h, 0, 0, canvasSize[0], canvasSize[1]);

		return ret; //cuttingSize
	},[type, canvasSize, imageSize, bodyType]);

	const cyhDrawText=(ctx, x, y, angle, color, textAlign='left', alignBottom=0,scale=1.0)=>{
		const str = angle === '0.0' ? "0°": angle+"°";
		const fontsize = FONT_SIZE*scale;
		ctx.font = fontsize+"px LGSmartUIB";
		ctx.textAlign = textAlign; //txt 정렬
		ctx.fillStyle = color;
		// const m = ctx.measureText(str);
		if(alignBottom>0){ //align arrow bottom position
			ctx.fillText(str, x, y+(alignBottom-fontsize));
		}else{
			ctx.fillText(str, x, y);
		}
	}
	//상하 반전
	const cyhDrawReverseImg= (ctx, img, x, y, w, h)=> {
		ctx.save();
		ctx.translate(x+w/2, y+h);
		ctx.rotate(180 * Math.PI/180);
		ctx.translate(-x-w/2, -y-h);
		ctx.drawImage(img , x, y+h, w, h);
		ctx.restore();
	}
	//좌우반전
	const cyhDrawReverseImgX= (ctx, img, x, y, w, h)=> {
		ctx.save(); // 현재 상태 저장
		// 이미지를 좌우 반전시키기 위해 X 축으로 -1 배율로 확대합니다.
		ctx.scale(-1, 1);
		// 이미지를 그립니다. 이미지는 이제 좌우 반전되어 있습니다.
		ctx.drawImage(img, -x - w, y, w, h);
		ctx.restore(); // 이전 상태 복원
	}
	const cyhDrawArrow=useCallback((ctx, x1,y1,x2,y2,mode="lt", angle, attr, scale=1.0, reverse=false)=>{
		let arrowImage = '';
		if(mode === 'lt' || mode === 'lc' ||  mode === 'rc' || mode === 'ltcenter'){
			arrowImage = attr.arrow;
		}else if(mode === 'center'){
			arrowImage = attr.arrowT;
		}else if(mode === 'rotateCenter'){
			arrowImage = attr.rotate;
		}
		//예방인 경우 화살표 안그림
		const drawArrow = attr.scoreKey !== SCORE_MINT_KEY;
		if( typeof window === "object"){
			const img = new window.Image();
			img.src = arrowImage;
			img.onload = function(ev){ //onload 실행후 drawImage 실행
				if(!ev.target ){
					return;
				}
				const w = ev.target.naturalWidth*scale;
				const h = ev.target.naturalHeight*scale;
				// console.log('cyhDrawArraw mode reverse', mode, reverse, scale);
          if (!disabledText) {
				if(mode === 'lt'){
					if(angle === '0.0'){
						cyhDrawText(ctx, x1-TEXT_MARGIN*scale, y1, angle, attr.color, 'right', FONT_SIZE*scale*1.5, scale);
					}else{
						if(reverse){
							drawArrow && cyhDrawReverseImg(ctx, img, x1+(ARROW_MARGIN*scale), y1-h/2, w, h);
							cyhDrawText(ctx, x1+w, y1-h, angle, attr.color, 'right', h, scale);
						}else{
							drawArrow && ctx.drawImage(img , x1-w -(ARROW_MARGIN*scale), y1-h/2, w, h);
							cyhDrawText(ctx, x1-w, y1-h, angle, attr.color, 'left', h, scale);
						}
					}
				}else if(mode === 'ltcenter'){
					if(angle === '0.0'){
						cyhDrawText(ctx, x1-TEXT_MARGIN*scale, (y1+y2)/2, angle, attr.color, 'right', FONT_SIZE*scale*1.5, scale);
					}else{
						if(reverse){
							drawArrow && cyhDrawReverseImg(ctx, img, x1+(ARROW_MARGIN*scale), (y1+y2)/2-h/2, w, h);
							cyhDrawText(ctx, x1 + FONT_SIZE*scale/2, (y1+y2)/2-h, angle, attr.color, 'left', h, scale);
						}else{
							drawArrow && ctx.drawImage(img , x1-w-(ARROW_MARGIN*scale), (y1+y2)/2-h/2, w, h);
							cyhDrawText(ctx, x1 - FONT_SIZE*scale/2, (y1+y2)/2-h, angle, attr.color, 'right', h, scale);
						}
					}
				}else if(mode === 'center'){
					if(angle === '0.0'){
						cyhDrawText(ctx, (x1+x2)/2, y1-h/2, angle, attr.color, 'center', FONT_SIZE*scale*1.5, scale);
					}else{
						drawArrow && ctx.drawImage(img , x1-w/2, y1-h-(ARROW_MARGIN*scale), w, h);
						cyhDrawText(ctx, x1-w/2, y1-h/2, angle, attr.color, 'right', h, scale);
					}
				}else if(mode === 'rotateCenter'){
					const maxX = Math.max(x1, x2);
					const minX = Math.min(x1, x2);
					if(reverse){
						drawArrow && cyhDrawReverseImgX(ctx, img, maxX,  (y1+y2)/2-h/2, w, h);
					}else{
						drawArrow && ctx.drawImage(img , minX-w, (y1+y2)/2-h/2, w, h);
					}
					if(true/*type !== 'wholebody'*/){
						cyhDrawText(ctx, (x1+x2)/2, (y1+y2)/2, angle, attr.color, 'center', 0, scale);
					}else{
						if(reverse || angle === '0.0'){
							cyhDrawText(ctx, (x1+x2)/2 + w/2 + TEXT_MARGIN*scale , (y1+y2)/2, angle, attr.color, 'left', 0, scale);
						}else{
							cyhDrawText(ctx, (x1+x2)/2 - w/2 - TEXT_MARGIN*scale, (y1+y2)/2, angle, attr.color, 'right', 0, scale);
						}
					}
				}else if(mode === 'lc'){
					if(angle === '0.0'){
						cyhDrawText(ctx, x1-TEXT_MARGIN*scale, y1, angle, attr.color, 'right', FONT_SIZE*scale*1.5, scale);
					}else{
						if(reverse){
							drawArrow && cyhDrawReverseImg(ctx, img, x1-w-(ARROW_MARGIN*scale), y1-h/2, w, h);
						}else{
							drawArrow && ctx.drawImage(img , x1-w-(ARROW_MARGIN*scale), y1-h/2, w, h);
						}
						cyhDrawText(ctx, x1-w-(ARROW_MARGIN*scale), y1-FONT_SIZE*scale, angle, attr.color, 'left', 0, scale);
					}
				}else if(mode === 'rc'){
					if(angle === '0.0'){
						cyhDrawText(ctx, x1+TEXT_MARGIN*scale, y1, " 0", attr.color, 'left', FONT_SIZE*scale*1.5, scale);
					}else{
						if(reverse){
							drawArrow && ctx.drawImage(img , x1+(ARROW_MARGIN*scale), y1-h/2, w, h);
						}else{
							drawArrow && cyhDrawReverseImg(ctx, img, x1+(ARROW_MARGIN*scale), y1-h/2, w, h);
						}
						cyhDrawText(ctx, x1+(ARROW_MARGIN*scale)+w, y1-FONT_SIZE*scale, angle, attr.color, 'right', 0, scale);
					}
				}
			}
        };
		}
	}, [type, disabledText]);
	//캔버스에 그리는 각도
	const drawAngle = useCallback((ctx, testResultSub, testkey, scale=1.0)=>{
		let drawLineAndDot = true;
		if(!testResultSub[testkey]){
			return;
		}
		let p = testResultSub[testkey][2];
		const state = testResultSub[testkey][0]; //in/out
		const angle = Number(testResultSub[testkey][1]).toFixed(1);
		const scorekey = testResultSub[testkey][3];
		const attr = LEVEL_ATTRIBUTE[scorekey];

		if(isIllust){
			p = getIllustPoints(bodyType, type, testkey, angle, testResultSub, state);
			const newPoint = [];
			for(let i=0; i<p.length; i++){
				if(p[i]){
					const cx = p[i][0] * (canvasSize[0]/WHOLEBODY_ILLUST_WIDTH);
					const cy = p[i][1] * (canvasSize[1]/WHOLEBODY_ILLUST_HEIGHT);
					newPoint.push([cx,cy]);
				}
			}
			p = newPoint;
		}
		// if(!isIllust){
		// 	drawLineAndDot = false;
		// }
		switch(testkey){
			case 'head_shift':
			case 'knee_flexion':
			case 'pelvic_shift':
			case 'trunk_shift':
			case 'neck_tilt':
			case 'shoulder_tilt':
			case 'pelvic_tilt':
				{ // lt case
					if(drawLineAndDot){
						dottedLine(ctx, p[2][0], p[2][1], p[3][0], p[3][1], LINE_WIDTH*scale);
						drawLine(ctx, p[0][0], p[0][1], p[1][0], p[1][1], attr.color, LINE_WIDTH*scale);
						drawDots(ctx, p[0][0], p[0][1], p[1][0], p[1][1], attr.color, DOT_RADIUS*scale, DOT_LINE_WIDTH*scale);
					}
					if(testkey === 'head_shift' || testkey === 'trunk_shift'){
						cyhDrawArrow(ctx, p[1][0], p[1][1], p[0][0], p[0][1],'ltcenter', angle, attr, scale, state ==='back');
					}else if(testkey === 'neck_tilt'){
						cyhDrawArrow(ctx, p[1][0], p[1][1], p[0][0], p[0][1],'ltcenter', angle, attr, scale, state ==='left high');
					}else if(testkey === 'shoulder_tilt' || testkey === 'pelvic_tilt'){
						cyhDrawArrow(ctx, p[1][0], p[1][1], p[0][0], p[0][1],'center', angle, attr, scale);
					}else{
						cyhDrawArrow(ctx, p[1][0], p[1][1], p[0][0], p[0][1],'lt', angle, attr, scale, state ==='back');
					}
					break;
			}
			case 'pelvic_rotation':
			{ // rotation
				if(Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION !== true){
					cyhDrawArrow(ctx, p[1][0], p[1][1], p[0][0], p[0][1],'rotateCenter', angle, attr, scale, state !=='right');
				}
				break;
			}
			case 'leg_alignment_left':
				{
					if(drawLineAndDot){
						dottedLine(ctx, p[1][0], p[1][1], p[3][0], p[3][1], LINE_WIDTH*scale);
						drawLine(ctx, p[1][0], p[1][1], p[0][0], p[0][1], attr.color, LINE_WIDTH*scale);
						drawLine(ctx, p[0][0], p[0][1], p[3][0], p[3][1], attr.color, LINE_WIDTH*scale);
						drawDots(ctx, p[1][0], p[1][1], p[0][0], p[0][1], attr.color, DOT_RADIUS*scale, DOT_LINE_WIDTH*scale);
						drawDots(ctx, p[0][0], p[0][1], p[3][0], p[3][1], attr.color, DOT_RADIUS*scale, DOT_LINE_WIDTH*scale);
					}
					cyhDrawArrow(ctx, p[0][0], p[0][1], p[0][0], p[0][1],'lc', angle, attr, scale, state ==='in');
				break;
			}
			case 'leg_alignment_right':
				{
					if(drawLineAndDot){
						dottedLine(ctx, p[1][0], p[1][1], p[3][0], p[3][1], LINE_WIDTH*scale);
						drawLine(ctx, p[0][0], p[0][1], p[1][0], p[1][1], attr.color, LINE_WIDTH*scale);
						drawLine(ctx, p[2][0], p[2][1], p[3][0], p[3][1], attr.color, LINE_WIDTH*scale);
						drawDots(ctx, p[0][0], p[0][1], p[1][0], p[1][1], attr.color, DOT_RADIUS*scale, DOT_LINE_WIDTH*scale);
						drawDots(ctx, p[2][0], p[2][1], p[3][0], p[3][1], attr.color, DOT_RADIUS*scale, DOT_LINE_WIDTH*scale);
					}
					cyhDrawArrow(ctx, p[0][0], p[0][1], p[0][0], p[0][1],'rc', angle, attr, scale, state ==='in');
				break;
			}
		}
	},[bodyType, isIllust, type, canvasSize]);

	useEffect(() => {
		if(drawTimerRef.current){
			clearTimeout(drawTimerRef.current);
			drawTimerRef.current = null;
		}
		drawTimerRef.current = setTimeout(() => {
			try{
				if(canvasSize[0] > 0 && canvasSize[1]>0){
					const ctx = canvasRef.current.getContext('2d');
					ctx.clearRect(0, 0, canvasSize[0], canvasSize[1]);
					const scale = adjustScale;
					//ret: cutting Size
					let cuttingSize = {};
					if(isIllust){
						ctx.drawImage(imageRef.current,0, 0, imageSize[0], imageSize[1], 0, 0, canvasSize[0], canvasSize[1]);
					}else{
						cuttingSize = cropImage(ctx);
					}

					//test
					if(showBodyCoordinations && bodyCoordinations){
						let convertedCoord = bodyCoordinations;
						convertedCoord = convertCoordinations(bodyCoordinations, cuttingSize, canvasSize);
						for(let i=0; i< convertedCoord.length; i++){
							let dotColor = Config.DEBUG_BODY_POINT_COLOR.etc;
							if(Config.DEBUG_BODY_POINT_COLOR[i]){
								dotColor = Config.DEBUG_BODY_POINT_COLOR[i];
							}
							drawDot(ctx, convertedCoord[i].x, convertedCoord[i].y, dotColor, 4, 2);
						}
						const convertedBBox = convertBBox(bbox[bodyType], cuttingSize, canvasSize);
						drawRect(ctx, convertedBBox[0], convertedBBox[1], convertedBBox[2], convertedBBox[3], 'white');
					}

					if(type==="wholebody"){
						for(let i=0; i<testkeys.length;i++){
							//yhcho
							let convertedTestResult = isIllust ? testResult[bodyType] : convertTestResult(testResult[bodyType], cuttingSize, canvasSize);
							// drawAngle(ctx, testResult[bodyType], testkeys[i], scale);
							drawAngle(ctx, convertedTestResult, testkeys[i], scale);
						}
					}else{
						if(testResult[bodyType]){
							let convertedTestResult = isIllust ? testResult[bodyType] : convertTestResult(testResult[bodyType], cuttingSize, canvasSize);
							for(let i=0; i<testkeys.length;i++){
								drawAngle(ctx, convertedTestResult, testkeys[i], scale);
							}
						}
					}
				}
			}catch(e){
				console.warn('Canvas Exception ',e);
			}
		}, 100);
	}, [canvasSize, bodyType, type, testkeys, adjustScale]);

	return (
		<>
		<img
			ref={imageRef}
			className={css.image}
			src={imageSource}
			onLoad={onLoadImage}
			alt=""
		/>
		<canvas
			{...rest}
			ref={canvasRef}
			className={classNames(css.canvas,className)}
			width={canvasSize[0]}
			height={canvasSize[1]}
		/>
		</>
	);
}

const propsAreEqual = (prev, next) => {
	const keys = Object.keys(prev);
	const nextKeys = Object.keys(next);
	if(keys.length !== nextKeys.length){
	  return false;
	}
	for(let i=0; i<keys.length; i++){
	  if(prev[keys[i]] !== next[keys[i]]){
		if((JSON.stringify(prev[keys[i]]) === JSON.stringify(next[keys[i]]))){
		  continue;
		}
		return false;
	  }
	}
	return true;
  }
export default React.memo(Canvas, propsAreEqual);
