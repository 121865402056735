import React from 'react';
import * as Config from './Config';
import StringReSourceKO from '../../resources/ko/strings.json';
import Enact_$L from '@enact/i18n/$L';
import { JSEncrypt } from "jsencrypt";
import { CONSTANTS_MONTH } from "./Constants";

const fillZero = (str, length) => {
  return ("00000000" + str).slice(-length);
};

export const splitText = (text, limitLength = 300) => {
  if (typeof text === "string" && text.length > 0) {
    let callTextArr = [""];
    const textArr = text.split(". ");

    let arrIndex = 0;
    for (let i = 0; i < textArr.length; i++) {
      if (i === textArr.length - 1) {
        callTextArr[arrIndex] += textArr[i];
        break;
      }
      textArr[i] += ".";

      if (callTextArr[arrIndex].length + textArr[i].length > limitLength) {
        arrIndex += 1;
      }
      if (!callTextArr[arrIndex]) {
        callTextArr[arrIndex] = "";
      }
      callTextArr[arrIndex] += textArr[i] + " ";
    }

    return callTextArr;
  }
};
// return [
//   "고객님의 관리가 필요한 부위는 머리 앞뒤 이동, 목  좌우 기울기, 골반  앞뒤 틀어짐, 무릎  굽힘/폄 입니다. ",
//   "양쪽 흉쇄유돌근과 후두하근이 단축되어 있으며, 경추 심부 굴곡근은 신장되어 있을 가능성이 있습니다. ",
//   "목의 불균형이 지속되면 인접한 관절을 포함한 주변 조직에 스트레스를 줄 수 있습니다. ",
//   "무릎 정렬을 바로한 후 안정화 운동을 통해 정렬을 바로잡아주세요."
// ]

/** 줄바꿈 함수 (문자열 사이에 {br} 추가해서 사용) */
export const convertNewlinesToBr = (text) => {
  if (text) {
    return text.split('{br}').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== text.length - 1 && <br />} {/* 마지막 줄 다음 <br> 추가 x */}
      </React.Fragment>
    ));
  } else {
    return null;
  }
};

// 초 > minute, seconds 분류 (ex 30 => 00:30, 60 => 01:00)
export const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const stripHTML = (html) => {
  if (html) {
    return html.replace(/<[^>]*>/g, ''); // HTML 태그를 정규 표현식을 사용하여 제거
  }
  return html;
}

export const $L = (str) => {
  let languageSetting = 'system';
  if (typeof window === "object" && window.store) {
    languageSetting = window.store.getState().common.localSettings.languageSetting;
  }
  if (typeof window === 'object' && window.PalmSystem && languageSetting === 'system') {
    return Enact_$L(str).replace(/{br}/g, '{br}');
  } else if (typeof window === "object") {
    let language = (typeof window.navigator === 'object') ? (window.navigator.language || window.navigator.userLanguage) : "en-US";
    if (languageSetting !== 'system') {
      language = languageSetting;
    }
    const resource = language.indexOf('ko') >= 0 ? StringReSourceKO : null;
    if (typeof str === 'object') {
      if (resource && resource[str.key]) {
        return resource[str.key].replace(/{br}/g, '{br}');
      } else {
        str = str.value;
      }
    } else if (resource && resource[str]) {
      return resource[str].replace(/{br}/g, '{br}');
    }
  }
  return str && str.replace(/{br}/g, '{br}');
};

export const getLanguageCode = (country) => {
  return Config.SUPPORT_COUNTRYS[country] ? Config.SUPPORT_COUNTRYS[country] : 'en';
};

/**
 * Generates a query string from the key/values of a JSON object
 * @param object The json object
 * @returns A value representing a URL compatible query string
 */
export const createQueryString = (object) => {
  const parts = [];
  for (const key of Object.getOwnPropertyNames(object)) {
    if (object[key] !== null && object[key] !== undefined && object[key] !== "") {
      parts.push(`${key}=${encodeURIComponent(object[key])}`);
    }
  }
  return parts.join('&');
};

export const createStringfyParams = (object) => {
  const parts = {};
  for (const key of Object.getOwnPropertyNames(object)) {
    if (object[key] !== null && object[key] !== undefined && object[key] !== "") {
      parts[key] = object[key].toString();
    }
  }
  return parts;
};
let localLaunchParams = {};
// let localLaunchParams = {
// 	intent:'PlayContent',intentParam: 'punch'
// };
export const getLaunchParams = () => {
  let params = {};

  if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.launchParams) {
    try {
      params = JSON.parse(window.PalmSystem.launchParams);
      if (params['x-webos-app-container-launch'] === true) {
        params = params.details;
      }
    } catch (e) {
      params = {};
    }
    return params;
  } else {
    return localLaunchParams;
  }
};

export const clearLaunchParams = () => {
  console.log('common.clearLaunchParams');
  if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.launchParams) {
    window.PalmSystem.launchParams = '';
  } else {
    localLaunchParams = {};
  }
};

// export const transDateText = (date) => {
// 	if (!date) {
// 		return '';
// 	}
// 	const year = date.slice(0, 4);
// 	const month  = date.slice(4, 6);
// 	const day  = date.slice(6, 8);

// 	console.log('년, 월, 일', year,month,day )
// 	try {
// 		const factory = DateFactory({
// 			type: 'gregorian',
// 			year: year,
// 			month: month,
// 			day: day
// 		});
// 		const fmt = new DateFmt({
// 			length: 'medium',
// 			type: 'date',
// 			date: 'dmy',
// 			useNative: false
// 		});
// 		return fmt.format(factory) || '';
// 	} catch (e) {
// 		return '';
// 	}
// };


export const transSecToText = (sec) => {
  if (isNaN(sec)) {
    sec = 0;
  }
  const hh = Math.floor(sec / 3600);
  const mm = Math.floor(Math.round(sec - hh * 3600) / 60);
  const ss = Math.round(sec) % 60;
  const strHH = String(hh).length === 1 ? "0" + String(hh) : String(hh);
  const strMM = String(mm).length === 1 ? "0" + String(mm) : String(mm);
  const strSS = String(ss).length === 1 ? "0" + String(ss) : String(ss);
  return hh > 0 ? (strHH + ":" + strMM + ":" + strSS) : (strMM + ":" + strSS);
};
export const getNearestTarget = (direction, node) => {
  const parentNode = node.parentNode;
  const children = parentNode.children;
  const currentRect = node.getBoundingClientRect();
  const currentCenter = { x: (currentRect.left + currentRect.right) / 2, y: (currentRect.top + currentRect.bottom) / 2 };

  const minorGap = 50;
  let nearestNode = null;
  let minDistance = 1920;
  for (let child of children) {
    if (child !== node) {
      const targetRect = child.getBoundingClientRect();
      const targetCenter = { x: (targetRect.left + targetRect.right) / 2, y: (targetRect.top + targetRect.bottom) / 2 };
      if ((direction === 'down' && currentCenter.y + minorGap < targetCenter.y)
        || (direction === 'up' && currentCenter.y - minorGap > targetCenter.y)
        || (direction === 'left' && currentCenter.x - minorGap > targetCenter.x)
        || (direction === 'right' && currentCenter.x + minorGap < targetCenter.x)
      ) {
        if ((direction === 'left' || direction === 'right') && Math.abs(currentCenter.y - targetCenter.y) > minorGap) {
          continue;
        }
        const distance = Math.sqrt(Math.pow((targetCenter.y - currentCenter.y), 2) + Math.pow((targetCenter.x - currentCenter.x), 2));
        if (minDistance > distance) {
          minDistance = distance;
          nearestNode = child;
        }
      }
    }
  }
  if (nearestNode) {
    return nearestNode;
  } else {
    return null;
  }
};

export const getRectDiff = (element1, element2) => {

  const element1Rect = element1.getBoundingClientRect();
  const element2Rect = element2.getBoundingClientRect();

  return ({
    right: element1Rect.right - element2Rect.right,
    left: element1Rect.left - element2Rect.left,
    top: element1Rect.top - element2Rect.top,
    bottom: element1Rect.bottom - element2Rect.bottom,
  });
};

export const transTimeToText = (hour, minutes) => {
  if (hour < 10) {
    hour = '0' + hour;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  return hour + ":" + minutes;
};

export const transSecondsToHMS = (
  seconds,
  whitespace = false,
  onlyHM = false
) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formatNumber = (number) => (number < 10 ? '0' + number : number);
  if (whitespace) {
    return `${formatNumber(hours)} : ${formatNumber(minutes)}${
      onlyHM ? "" : ` : ${formatNumber(remainingSeconds)}`
    }`;
  }
  return `${formatNumber(hours)}:${formatNumber(minutes)}${
    onlyHM ? "" : `:${formatNumber(remainingSeconds)}`
  }`;
};

// minutes -> 6H 5M
export const convertTimeToText = (minutes, useLongFormat = false) => {
  // 분을 숫자로 변환
  const convertNumMinutes = Number(minutes);

  if (convertNumMinutes >= 60) {
    // 시간과 분으로 분리
    const hours = Math.floor(convertNumMinutes / 60);
    const remainingMinutes = convertNumMinutes % 60;

    // 시간과 분 포맷
    let formattedHours = `${hours}`;
    if (useLongFormat) {
      formattedHours += " " + (hours === 1 ? $L("hour") : $L("hours"));
    } else {
      formattedHours += "h";
    }

    let formattedMinutes = "";
    if (remainingMinutes > 0) {
      if (useLongFormat) {
        formattedMinutes =
          remainingMinutes <= 1
            ? ` ${remainingMinutes} ${$L("minute")}}`
            : ` ${remainingMinutes} ${$L("minutes")}`;
      } else {
        formattedMinutes = ` ${remainingMinutes}m`;
      }
    }

    // 시간과 분 조합하여 반환
    return formattedHours + (formattedMinutes ? " " + formattedMinutes : "");
  } else {
    // 60 미만의 경우 분을 한 자릿수 또는 두 자릿수로 포맷하여 반환
    let formattedMinutes = `${convertNumMinutes}`;
    if (useLongFormat) {
      formattedMinutes += " " + convertNumMinutes <= 1 ? $L("minute") : $L("minutes");
    } else {
      formattedMinutes += "m";
    }
    return formattedMinutes;
  }
};

export const transTimeSecToText = (sec, showSec = false, compact = false) => {
  const hour = Math.floor(sec / 3600);
  const minutes = Math.floor(Math.round(sec - hour * 3600) / 60);
  let text = transTimeToText(hour, minutes);

  if (showSec) {
    const second = sec - hour * 3600 - minutes * 60;
    text += `:${String(second).padStart(2, '0')}`;

    if (compact) {
      const splitText = text.split(":");
      if (splitText[0] === "00") {
        splitText.shift();
        const compactText = splitText.join(":");
        return compactText;
      }
    }
  }

  return text;
}

export const transNumToAgeText = (age) => {
  if (age <= 1) {
    return $L("{num} year old").replace('{num}', age);
  } else if (age > 1){
    return $L("{num} years old").replace('{num}', age);
  } else {
    return null
  }
};

export const transNumToDayText = (number) => {
  if (number <= 1) {
    return $L("{days} day").replace("{days}", number);
  }else {
    return $L("{days} days").replace("{days}", number);
  }
};

export const transNumToMonthText = (number) => {
  const index = Number(number) - 1;
  return $L(CONSTANTS_MONTH[index]);
};

export const transDateToText = (date, language, format) => {
  if (format === "SHORT") {
    return convertToUSDateFormat(date.split("-").join("."), language);
  } else {
    const newDate = new Date(date);
    const option = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const localLang = language ?? "default";
    const dateToText = newDate.toLocaleString(localLang, option);
    return dateToText;
  }
};

//"1963.05.04" --> "05.04.1963"
export const convertToUSDateFormat = (dateString, language) => {
  if (language === "ko") {
    return dateString;
  } else {
    const parts = dateString.split(".");
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${month}.${day}.${year}`;
    } else {
      return null;
    }
  }
};

export const durationText = (sec) => {
  const hh = Math.floor(sec / 3600);
  const mm = Math.floor(Math.round(sec - hh * 3600) / 60);
  const ss = Math.round(sec) % 60;
  let res = "";
  if (ss > 0) {
    res = $L("{ss}s").replace('{ss}', ss);
  }
  if (mm > 0) {
    res = $L("{mm}m").replace('{mm}', mm) + " " + res;
  }
  if (hh > 0) {
    res = $L("{hh}h").replace('{hh}', hh) + " " + res;
  }
  return res.trimEnd();
};

//"0:00:10" --> 10
export const stringTimetoSec = (str) => {
  let sec = 0;
  if (typeof (str) === 'string') {
    const sp = str.split(':');
    if (sp.length === 3) {
      sec = Number(sp[0]) * 3600 + Number(sp[1]) * 60 + Number(sp[2]);
    } else if (sp.length === 2) {
      sec = Number(sp[0]) * 60 + Number(sp[1]);
    } else if (sp.length === 1) {
      sec = Number(sp[1]);
    } else {
      sec = Number(str);
    }
  }
  return sec;
};
let DomParser;
if (typeof window === 'object') {
  DomParser = typeof window === "object" && new window.DOMParser();
}
export const replaceHtmlEntites = (s) => {
  if (!s) {
    return s;
  }
  if (DomParser) {
    const finalResult = DomParser.parseFromString(s, "text/html");
    return finalResult.body.innerText;
  } else {
    return s;
  }
};
const strTime = (s) => {
  return String(s).length === 1 ? "0" + String(s) : String(s);
};
export const timeToISO8601DateStr = (date) => {
  if (!date) {
    return "";
  }
  return date.getFullYear() + '-' + strTime(date.getMonth() + 1) + '-' + strTime(date.getDate());
};
// "2021-10-06T13:44:00"
export const timeToISO8601Str = (date) => {
  return date.getFullYear() + '-' + strTime(date.getMonth() + 1) + '-' + strTime(date.getDate()) + "T" + strTime(date.getHours())
    + ":" + strTime(date.getMinutes()) + ":" + strTime(date.getSeconds());
};
// "20211006T134400"
export const timeToFilePathStr = (date) => {
  return date.getFullYear() + strTime(date.getMonth() + 1) + strTime(date.getDate()) + "T" + strTime(date.getHours())
    + strTime(date.getMinutes()) + strTime(date.getSeconds());
};
export const readLocalStorage = (key, defaultValue) => {
  const value = typeof window === 'object' && window.localStorage.getItem(key);
  if (!value && defaultValue !== undefined) {
    return defaultValue;
  }
  return value === 'undefined' ? null : JSON.parse(value);
};

export const writeLocalStorage = (key, value) => {
  if (typeof window === 'object') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};
export const convertNormalStr = (str) => {
  if (!str) return str;
  let ret = str.replace(/(\{br})/gm, "{br}");
  ret = ret.replace(/(\\t)/gm, "\t");
  ret = ret.replace(/(\\")/gm, "\"");
  return ret;
};

export const windowClose = () => {
  if (typeof window === 'object' && window.PalmSystem) {
    window.close();
  } else if (typeof window === 'object') {
    window.location.reload();
  }
}

export const convertUniToUtf8 = (str) => {
  try {
    return unescape(encodeURIComponent(str));
  } catch {
    return str;
  }
}
export const convertUtf8ToUni = (str) => {
  try {
    return decodeURIComponent(escape(str));
  } catch {
    return str;
  }
}
export const jsonConcat = (o1, o2) => {
  for (let key in o2) {
    o1[key] = o2[key];
  }
  return o1;
}
export const scaleW = (value) => {
  if (typeof window === 'object') {
    return value * (window.innerWidth / 1920);
  }
  return value;
}
export const scaleH = (value) => {
  if (typeof window === 'object') {
    return value * (window.innerHeight / 1080);
  }
  return value;
}

export const convertLocalTimetoUTC = (date) => {
  if (date instanceof Date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }
  return null;
}
export const convertUTCtoLocalTime = (date) => {
  if (date instanceof Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }
  return null;
}

// return "2022-04-29" or "2022-04"
export const convertDateToString = (fullyear, month, date) => {
  let str = fullyear + "-" + fillZero(month, 2);
  if (date) {
    str += "-" + fillZero(date, 2)
  }
  return str;
}
// return "2022-04-29"
export const convertDateToString2 = (date) => {
  if (typeof date === 'string') {
    return date;
  }
  if (!date) {
    return "";
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1을 해주고 두 자리로 만듭니다.
  const day = String(date.getDate()).padStart(2, '0'); // 일을 두 자리로 만듭니다.

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

// return "2022.04.29"
export const converDateStrtoDot = (dateStr) => {
  if (dateStr) {
    const str = dateStr.split('-');
    fillZero(str[1], 2);
    fillZero(str[2], 2);
    return str.join(".");
  } else {
    return dateStr;
  }
}

export const converDateStrtoShort = (dateStr, isFullDate) => {
  const parts = dateStr.split('-'); // 문자열을 '-'를 기준으로 분리
  if (parts.length === 3) {
    const year = parts[0];
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);

    // '월.일' 형식으로 재구성
    let replacements = {
      "{month}": month,
      "{day}": day,
    };

    if (isFullDate) {
      replacements["{year}"] = year;
      const formattedDate = $L("{month}/{day}/{year}").replace(
        /{month}|{day}|{year}/g,
        (key) => replacements[key]
      );
      return formattedDate;
    } else {
      const formattedDate = $L("{month}/{day}").replace(
        /{month}|{day}/g,
        (key) => replacements[key]
      );
      return formattedDate;
    }
  } else {
    return 'invalid';
  }
}
export const getTodayStr = () => {
  const date = new Date();
  return date.getFullYear() + '/' + strTime(date.getMonth() + 1) + '/' + strTime(date.getDate());
}

export const convertDateToDaysAgo = (dateStr) => {
  if (dateStr) {
    const currentDate = new Date();
    const targetDate = convertLocalTimetoUTC(new Date(dateStr));
    const timeDifference = currentDate - targetDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysAgo
  }
  return null
}

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
  MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKCLAaU0vExTCYpEwe0gaQ/ZPg7faMTP
  R0hz6cnkIFW5Vkeo3gLEaajIxNiOZm5bCWPT83zWBhVeYLjBYM4sMT8CAwEAAQ==
  -----END PUBLIC KEY-----`;
export const encryptPassword = (password) => {

  const jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(PUBLIC_KEY);

  // Perform our encryption based on our public key - only private key can read it!
  const encrypted = jsencrypt.encrypt(password);
  console.log('yhcho encrypt encrypted ', password, encrypted);
  return encrypted;
}


export const cloneObject = (obj) => {
  const clone = {};
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      clone[key] = [...obj[key]];
    } else if (typeof obj[key] == "object" && obj[key] != null) {
      clone[key] = cloneObject(obj[key]);
    } else {
      clone[key] = obj[key];
    }
  }
  return clone;
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//Daniel
export const makeShortUsername = (str) => {
  let ret = str;
  if (str && str.length > 10) {
    ret = str.slice(0, 10) + '...';
  }
  return ret;
}

/**
 * await Utils.wait(10000);
 * @param {*} time
 * @returns
 */
export const wait = (time) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export const isChildFullyShowing = (parentNode, childNode) => {
  try {
    const parentRect = parentNode.getBoundingClientRect();
    const childRect = childNode.getBoundingClientRect();

    if (
      childRect.top >= parentRect.top &&
      childRect.left >= parentRect.left &&
      childRect.bottom <= parentRect.bottom &&
      childRect.right <= parentRect.right
    ) {
      return true;
    }
  } catch (e) {
    return true;
  }
  return false;
}


export const isEmailAddress = (str) => {
  const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  return exptext.test(str);
}

export const calculateStandardDeviation = (numbers) => {
  // 평균을 계산합니다.
  const mean = numbers.reduce((sum, num) => sum + num, 0) / numbers.length;

  // 각 숫자와 평균의 차이를 제곱한 값을 배열에 저장합니다.
  const squaredDifferences = numbers.map(num => Math.pow(num - mean, 2));

  // 분산을 계산합니다.
  const variance = squaredDifferences.reduce((sum, squaredDiff) => sum + squaredDiff, 0) / numbers.length;

  // 표준편차는 분산의 제곱근입니다.
  const standardDeviation = Math.sqrt(variance);

  return standardDeviation;
}

const getDate = (date) => {
  let d = new Date(date);

  try {
    if (d.toString() === 'Invalid Date' || d === null) {
      d = date.split(/\.|\/|\-|\:|\s/g).join('');
      d = new Date(d.substr(0, 4), d.substr(4, 2) - 1, d.substr(6, 2) || 1, d.substr(8, 2), d.substr(10, 2), d.substr(12, 2));
    }
  } catch (err) {
    console.log('getDate...', err)
  }

  return d;
};

export const getWeekNumber = (date) => {
  // Calculate the year and month for the date
  const year = date.getFullYear();
  const month = date.getMonth();

  // Find the first and last day of the month
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  const lastDayOfFirstWeek = new Date(
    year,
    month,
    1 + (6 - firstDayOfMonth.getDay())
  );

  // Calculate the first day of the last week of the month
  const firstDayOfLastWeek = new Date(
    year,
    month,
    lastDayOfMonth.getDate() - lastDayOfMonth.getDay()
  );

  if (
    lastDayOfMonth.getDay() !== 6 &&
    date.getDate() >= firstDayOfLastWeek.getDate()
  ) {
    // If lastDay on this month isn't Saturday on a day
    let nextMonth = month < 11 ? month + 1 : 0;
    // Return to the first week of the next month
    return `${CONSTANTS_MONTH[nextMonth]} 1W`;
  } else if (date.getDate() <= lastDayOfFirstWeek.getDate()) {
    // If the date is in the first week
    return `${CONSTANTS_MONTH[month]} 1W`;
  } else {
    // Calculate next week
    let countWeek = 2;
    let countDate = lastDayOfFirstWeek.getDate() + 7;

    while (date.getDate() > countDate) {
      countWeek += 1;
      countDate += 7;
    }
    return `${CONSTANTS_MONTH[month]} ${countWeek}W`;
  }
};
// return "Oct 2W"

export const dateDiff = (a = new Date(), b = new Date()) => {
  const aDate = getDate(a);
  const bDate = getDate(b);

  return Math.ceil((aDate.getTime() - bDate.getTime()) / (1000 * 3600 * 24));
}

export const formatDateTime = (format, date = '') => {
  if (!date) return '';

  const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
  let d = getDate(date);

  return format.replace(/(YYYY|YY|MM|M|DD|D|aa|HH|H|hh|h|mm|m|ss|s|E)/gi, ($1) => {
    switch ($1) {
      case 'YYYY': return d.getFullYear();
      case 'YY': return `${d.getFullYear()}`.slice(-2);
      case 'MM': return ('0' + (d.getMonth() + 1)).slice(-2);
      case 'M': return d.getMonth() + 1;
      case 'DD': return ('0' + d.getDate()).slice(-2);
      case 'D': return d.getDate();
      case 'aa': return d.getHours() < 12 ? '오전' : '오후';
      case 'HH': return ('0' + d.getHours()).slice(-2);
      case 'H': return d.getHours();
      case 'hh': return ('0' + (d.getHours() % 12 || 12)).slice(-2);
      case 'h': return d.getHours() % 12 || 12;
      case 'mm': return ('0' + d.getMinutes()).slice(-2);
      case 'm': return d.getMinutes();
      case 'ss': return ('0' + d.getSeconds()).slice(-2);
      case 's': return d.getSeconds();
      case 'E': return WEEKDAY[d.getDay()];
    }
  });
}

export const clampingNumber = (num, minValue = 0, maxValue = 100) => {
  return (isNaN(num) || num === NaN) ? 0 : Math.min(Math.max(minValue, num), maxValue);
};

export const getLocalServerUrl = (realUrl, serverType, localURL) => {
  let convertedUrl = realUrl;
  if(serverType === 'local' && localURL && realUrl && realUrl.indexOf(localURL) < 0){
    let url = '';
    if(realUrl.indexOf('cloudfront.net/') >= 0) {
      url = realUrl.split('cloudfront.net/')[1];
    } else if(realUrl.indexOf('amazonaws.com/') >= 0) {
      url = realUrl.split('amazonaws.com/')[1];
    }
    convertedUrl = localURL + url;
    // http://192.168.1.19:8788/
  }
  // console.log('getLocalServerUrl realUrl', realUrl);
  // console.log('getLocalServerUrl convertedUrl', convertedUrl);
  return convertedUrl;
}