import React, { useEffect, useState, useRef, useCallback } from "react";
import css from "./TestPartCompletePopup.module.less";
import TButton from "../TButton";
import Spotlight from "@enact/spotlight";
import Alert from '@enact/sandstone/Alert';
import { $L, convertNewlinesToBr } from "../../utils/helperMethods";
import GuideImage from "../../../assets/guideImage.svg";
import TShakaPlayer from "../TShakaPlayer/TShakaPlayer";
import Thumbnail from "../../../assets/testPopup/autoplay_image.png"

const TestPartCompletePopup = ({ index, open, onClose, retry, currentTestTitle, retryPreviousTest, ...rest }) => {
  const videoPlayer = useRef();
  const [countdown, setCountdown] = useState(5);
  const [isAutoplayStopped, setIsAutoplayStopped] = useState(false);

  const _onClose = useCallback((ev) => {
    console.log('onClose', ev);
    if(onClose){
      onClose(ev)
    }
  }, [onClose]);

  useEffect(() => {
    Spotlight.focus('nextPartTestStart');
    if(!isAutoplayStopped){
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      const timeout = setTimeout(() => {
        _onClose({});
      }, 5000);
      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [isAutoplayStopped]);

  const retryTest = useCallback(()=>{
    if(retryPreviousTest){
      retryPreviousTest();
    }
  },[]);

  const stopAutoPlay = useCallback(()=>{
    setIsAutoplayStopped(true);
  },[]);

  return (
    <div {...rest} className={css.panel}>
      <img className={css["shaka-player"]} src={Thumbnail} alt="partImg"/>
      {!isAutoplayStopped &&
        <>
          <div className={css.countdown}><span>{countdown}</span>{$L("초 후에 다음 테스트가 진행됩니다.")}</div>
          <TButton className={css.autoPlayStop} withMarquee={true} onClick={stopAutoPlay}><span/>{$L("Stop automatic play")}</TButton>
        </>
      }
      <div className={css.container}>
        <div className={css.testTitle}>{currentTestTitle}</div>
        <div className={css.testDetail}>{currentTestTitle}{convertNewlinesToBr(" 상태를 측정합니다.{br}편하게 서주세요.")}</div>
        <div className={css.flexBox}>
          <TButton spotlightId="nextPartTestStart" className={css.testBtn} onClick={_onClose}>{$L("Start the test now")}</TButton>
          <TButton className={css.retryBtn} onClick={retryTest}>{$L("Repeat the previous test")}</TButton>
        </div>
      </div>
      
    </div>
  );
};

export default TestPartCompletePopup;