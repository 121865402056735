import css from "./GGraph.module.less";
import classNames from "classnames";
import { useCallback, useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Config from "../../../utils/Config";
import { $L } from "../../../utils/helperMethods";

import TToolTip, { POSITION } from "../../../components/TToolTip/TToolTip";
import TGGraph from "../../../components/TGGraph/TGGraph";
import { addPanels } from "../../../features/panels/panelsSlice";

import Spottable from "@enact/spotlight/Spottable";
import { Job } from "@enact/core/util";

const SpottableComponent = Spottable("div");

const GGraph = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const appStatus = useSelector((state) => state.common.appStatus);
  const [pressed, setPressed] = useState(false);
  const [focused, setFocused] = useState(false);
  const [goalPercentage, setGoalPercentage] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const goalInfo = useMemo(() => {
    const dummyGoal = {
      type: $L("Goal Achievement Rate"),
      currentData: 12, // day
      progressData: 14, // day
      goalData: 31, // day
    };

    const goalPercentage = (dummyGoal.currentData / dummyGoal.goalData) * 100;
    const progressPercentage = (dummyGoal.progressData / dummyGoal.goalData) * 100;
    // setTimeout(() => {
    //   setGoalPercentage(goalPercentage);
    //   setProgressPercentage(progressPercentage);
    // }, 3000);
    setGoalPercentage(goalPercentage);
    setProgressPercentage(progressPercentage);
    return {
      goalType: dummyGoal.type,
      currentData: dummyGoal.currentData,
      progressData: dummyGoal.progressData,
      goalData: dummyGoal.goalData,
      goalPercentage,
      progressPercentage,
    };
  }, [appStatus]);

  const _onClick = useCallback(() => {
    setPressed(true);
    clearPressedJob.current.start(() => {
      dispatch(
        addPanels({
          name: Config.panel_names.FITNESS_MANAGER,
          panelInfo: { selectedTabIndex: 1 },
        })
      );
    });
  }, [dispatch]);

  const _onFocus = useCallback(() => {
    setFocused(true);
  }, [])

  const _onBlur = useCallback(() => {
    setPressed(false);
    setFocused(false);
    clearPressedJob.current.stop();
  }, [])

  return (
    <SpottableComponent
      className={classNames(
        css.graphContainer,
        pressed && css.pressed,
        className
      )}
      onClick={_onClick}
      onFocus={_onFocus}
      onBlur={_onBlur}
    >
      <TToolTip
        position={POSITION.top}
        isOpen={focused}
        style={{
          position: "absolute",
          bottom: "calc(100% - 12px)",
        }}
      >
        {`운동 실적 ${Math.round(goalInfo.goalPercentage)}%, 진도율 ${Math.round(goalInfo.progressPercentage)}%`}
      </TToolTip>
      <div>
        <TGGraph
          size={196}
          primaryPercentage={progressPercentage}
          secondaryPercentage={goalPercentage}
          animationInterval={300}
          lineWidth={26}
        />
        <div className={css.goalPercentage}>
          {Math.round(goalInfo.goalPercentage)}
          <span>%</span>
        </div>
        <div className={css.textWrapper}>
          <p className={css.typeName}>{goalInfo.goalType}</p>
          <div className={css.goalContainer}>
            <span>{goalInfo.currentData}</span>
            <span>
              /{goalInfo.goalData}
                {$L("Days")}
            </span>
          </div>
        </div>
      </div>
  </SpottableComponent>
  );
};

export default GGraph;