import classNames from 'classnames';

import React, {useEffect, useCallback} from 'react';
import { useSelector } from "react-redux";
import css from './TSettingSection.module.less';
import TButton from '../TButton/TButton';

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const TSettingSection = ({
	type = 'normal',
	size = "medium",
	border = "none",
	text,
	subText,
	subText2,
	value,
	button1,
	button2,
	button3,
	button1SpotlightId,
	button2SpotlightId,
	button3SpotlightId,
	itemRenderer,
	onClick,
	className,
	...rest
}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);

	const hasText = text || subText;

	const onClickBtn = useCallback((selectValue) => (ev) => {
		if (onClick) {
			onClick(selectValue, ev);
		}
	}, [onClick]);

	return (
		<Container className={classNames(
			css.section,
			css[type],
			css[size],
			css[border],
				themeMode === "dark" && css.isDark,
			className,
		)}>
			{hasText &&
				<div className={css.textContainer}>
					{text && <div className={css.text}>{text}</div>}
					{subText && <div className={css.subText}>{subText}</div>}
				</div>
			}
			<div className={css.rightContainer}>
				{button1 && button1.text && <TButton className={css.button} spotlightId={button1SpotlightId} selected={value === button1.value} disabled={button1.disabled} onClick={onClickBtn(button1.value)}>{button1.text}</TButton>}
				{button2 && button2.text && <TButton className={css.button} spotlightId={button2SpotlightId} selected={value === button2.value} disabled={button2.disabled} onClick={onClickBtn(button2.value)}>{button2.text}</TButton>}
				{button3 && button3.text && <TButton className={css.button} spotlightId={button3SpotlightId} selected={value === button3.value} disabled={button3.disabled} onClick={onClickBtn(button3.value)}>{button3.text}</TButton>}
				{/* {value && <div className={css.value}>{value}</div>} */}
				{itemRenderer && itemRenderer()}
			</div>
		</Container>
	);
};

export default TSettingSection;