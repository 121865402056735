import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	toggle: {
		neck: true,
		shoulder: true,
		elbow: true,
		wrist: true,
		back: true,
		waist: true,
		hips: true,
		thigh: true,
		calf: true,
		ankle: true,
		sole: true,
		smoke_past: true,
		smoke_curr: true,
		drink_curr: true,
		drink_past: true
	}
};


export const survey = createSlice({
	name: "survey",
	initialState,
	reducers: {
		setToggle: (state, action) => {
			state.toggle = action.payload
		}
	},
});

export const { setToggle } = survey.actions;

export default survey.reducer;