import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
  FOOTTYPE_VALUE,
  ONE_LEG_BALANCE_VALUE
} from "../../../../../utils/Constants";
import { $L } from "../../../../../utils/helperMethods";
import { bodyBalanceMenu, getEmptyResultsReportData } from "../Constants";
import {
  bodyBalanceTabStatus,
  feedbackBodyBalanceTab,
  feedbackBodyBalanceTabVoice,
  feedbackCoP,
  feedbackCoPVoice,
  feedbackFootType,
  feedbackFootTypeVoice,
  feedbackViewDetails,
  footTypeStatus,
  getCesFeedbackOverall,
} from "./constantsFeedback";

export const getSummaryFeedback = (obj) => {
  const twoLegData = obj?.twolegstance;
  const status = (data) => {
    if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
      return true;
    } else {
      return false;
    }
  };

  const footTypeLeftStatus = status(Number(twoLegData.value_archIndex_L));
  const footTypeRightStatus = status(Number(twoLegData.value_archIndex_R));

  const footTypeStatus = footTypeLeftStatus && footTypeRightStatus;
  const bodyBalanceStatus =
    voiceGuideBodyBalance(bodyBalanceMenu.BODY_BALANCE_TAB, obj)?.status ===
    bodyBalanceTabStatus.PERFECT;

  let feedback;
  if (footTypeStatus && bodyBalanceStatus) {
    feedback = $L(
      "Your feet appears to be healthy and you have a proper center of gravity. As we age, the arches of the feet are flattens and we lose the strength that keeps the center of gravity in place. To compensate for this, it’s good to strengthen our muscles in the lower body and improve balance. Let us recommend an exercise that can strengthen both the leg muscles and balance ability."
    );
  } else if (!footTypeStatus && bodyBalanceStatus) {
    feedback = $L(
      "You have a proper center of gravity, but a part of your foot may be altered. An altered foot can put more pressure on your foot or ankle, and can cause misalignment in the body because the foot cannot hold the body up properly. Strengthening the lower body can help compensate for the physical problem caused by an altered foot. Let us recommend an exercise that can strengthen both the leg muscles and balance ability."
    );
  } else if (footTypeStatus && !bodyBalanceStatus) {
    feedback = $L(
      "Your feet appears to be healthy but the center of gravity is slightly tilted. The ability to maintain balance is an essential factor when doing daily activities. Many factors, such as visual information, cognitive ability, and muscle strength, affect our ability to balance. Let us recommend an exercise that can strengthen both the leg muscles and balance ability."
    );
  } else {
    feedback = $L(
      "A part of your foot may be altered and the center of gravity is slightly tilted. You need to strengthen the leg muscles to reduce the pressure applied on your feet, and improve balance ability to improve the center of gravity. Let us recommend an exercise that can strengthen both the leg muscles and balance ability."
    );
  }

  return feedback;
};

export const voiceGuideEmpty = (tabType) => {
  if (tabType) {
    const emptyData = getEmptyResultsReportData()[tabType];
    const feedback = emptyData.title + " " + emptyData.detail;
    return { feedbackVoice: feedback };
  }
};

export const voiceGuideOverallBodyBalance = (cesShowMode, obj) => {
  if (cesShowMode) {
    const twoLegData = obj?.twolegstance;
    const feedbackArr = getCesFeedbackOverall();
    if (twoLegData) {
      const twoLegBalanceFeedback = voiceGuideBodyBalance(
        bodyBalanceMenu.BODY_BALANCE_TAB,
        obj
      )?.bodyBalanceTab;

      const indexL = Number(twoLegData.value_archIndex_L);
      const indexR = Number(twoLegData.value_archIndex_R);

      const status = (data) => {
        if (data < FOOTTYPE_VALUE.flatMax) {
          return { key: footTypeStatus.FLATFOOT, text: $L("Flat Foot") };
        } else if (FOOTTYPE_VALUE.susflatMin <= data && data < FOOTTYPE_VALUE.susflatMax) {
          return {
            key: footTypeStatus.SUS_FLATFOOT,
            text: $L("Low-arhced foot"),
          };
        } else if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
          return { key: footTypeStatus.COMMON, text: $L("Medium-arched foot") };
        } else if (FOOTTYPE_VALUE.suscavusMin < data && data <= FOOTTYPE_VALUE.suscavusMax) {
          return {
            key: footTypeStatus.SUS_LUMBAR,
            text: $L("High-arched foot"),
          };
        } else if (FOOTTYPE_VALUE.cavusMin < data) {
          return { key: footTypeStatus.LUMBAR, text: $L("Hollow Foot") };
        }
      };

      const footData = {
        left: {
          position: $L("Left Foot"),
          status: status(indexL),
        },
        right: {
          position: $L("Right Foot"),
          status: status(indexR),
        },
      };

      const feedbackEl = $L("The {position} is ") + "{footStatus}. ";
      const feedback = [];
      Object.keys(footData).map((item) => {
        const el = feedbackEl
          .replace("{position}", footData[item].position)
          .replace("{footStatus}", footData[item].status.text);
        const descFootStatus =
          feedbackArr[bodyBalanceMenu.FOOT_TYPE][footData[item].status.key];
        feedback.push(el + descFootStatus);
      });

      const feedbackToText = twoLegBalanceFeedback + " " + feedback.join(" ");
      return {
        feedback: feedbackToText,
        feedbackVoice: feedbackToText.replace("{br}", ""),
      };
    }
  } else {
    const feedback = $L(
      "매트로 발 건강을 측정한 결과입니다. {br}각 결과에 대한 상세 정보는 상세 탭에서 확인하실 수 있어요."
    );

    return { feedback: feedback, feedbackVoice: feedback.replace("{br}", "") };
  }
};

export const voiceGuideBodyBalance = (tabType, obj) => {
  const twoLegData = obj?.twolegstance;
  const oneLegData = obj?.onelegstanceR;

  if (twoLegData) {
    if (tabType === bodyBalanceMenu.FOOT_TYPE) {
      const indexL = Number(twoLegData.value_archIndex_L);
      const indexR = Number(twoLegData.value_archIndex_R);

      const status = (data) => {
        if (data < FOOTTYPE_VALUE.flatMax) {
          return footTypeStatus.FLATFOOT;
        } else if (FOOTTYPE_VALUE.flatMax <= data && data < FOOTTYPE_VALUE.normalMin) {
          return footTypeStatus.SUS_FLATFOOT;
        } else if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
          return footTypeStatus.COMMON;
        } else if (FOOTTYPE_VALUE.normalMax < data && data <= FOOTTYPE_VALUE.cavusMin) {
          return footTypeStatus.SUS_LUMBAR;
        } else if (FOOTTYPE_VALUE.cavusMin < data) {
          return footTypeStatus.LUMBAR;
        }
      };

      const footData = {
        left: {
          position: $L("Left Foot"),
          value: indexL,
          status: status(indexL),
        },
        right: {
          position: $L("Right Foot"),
          value: indexR,
          status: status(indexR),
        },
      };

      for (const position in footData) {
        const footStatus = footData[position].status;
        const footPosition = footData[position].position;

        const feedbackFootTypeObj = feedbackFootType();
        const viewFeedback = feedbackFootTypeObj[footStatus]?.replace(
          "{position}",
          footPosition
        );

        footData[position].feedback = viewFeedback;
      }

      let feedback = "";
      let feedbackFootTypeVoiceObj = feedbackFootTypeVoice();
      if (footData.left.status === footData.right.status) {
        const sameStatus = footData.left.status;

        switch (sameStatus) {
          case footTypeStatus.FLATFOOT:
            feedback = feedbackFootTypeVoiceObj[footTypeStatus.TWO_FLATFOOT];
            break;
          case footTypeStatus.SUS_FLATFOOT:
            feedback = feedbackFootTypeVoiceObj[footTypeStatus.TWO_FLATFOOT];
            break;
          case footTypeStatus.COMMON:
            feedback = feedbackFootTypeVoiceObj[footTypeStatus.TWO_COMMON];
            break;
          case footTypeStatus.LUMBAR:
            feedback = feedbackFootTypeVoiceObj[footTypeStatus.TWO_LUMBAR];
            break;
          case footTypeStatus.SUS_LUMBAR:
            feedback = feedbackFootTypeVoiceObj[footTypeStatus.TWO_LUMBAR];
            break;
          default:
            break;
        }
      } else {
        for (const position in footData) {
          const data = footData[position];
          if (
            data.status === footTypeStatus.FLATFOOT ||
            data.status === footTypeStatus.SUS_FLATFOOT
          ) {
            feedback +=
              feedbackFootTypeVoiceObj[footTypeStatus.ONE_FLATFOOT].replace(
                "{position}",
                data.position
              ) + "{br}";
          } else if (
            data.status === footTypeStatus.LUMBAR ||
            data.status === footTypeStatus.SUS_LUMBAR
          ) {
            feedback +=
              feedbackFootTypeVoiceObj[footTypeStatus.ONE_LUMBAR].replace(
                "{position}",
                data.position
              ) + "{br}";
          } else if (data.status === footTypeStatus.COMMON) {
            feedback +=
              feedbackFootTypeVoiceObj[footTypeStatus.COMMON].replace(
                "{position}",
                data.position
              ) + "{br}";
          }
        }
      }

      return {
        footType: {
          left: footData.left.feedback,
          right: footData.right.feedback,
        },
        feedback: feedback,
        feedbackVoice: feedback.replaceAll("{br}", ""),
      };
    } else if (tabType === bodyBalanceMenu.BODY_BALANCE_TAB) {
      const balanceRatioXData = Number(twoLegData.value_balanceRatio_LR);
      const balanceRatioYData = Number(twoLegData.value_balanceRatio_FFRF);
      const balanceRatioX = Number(twoLegData.balanceRatio_LR); // 1좌측 2정상 3우측
      const balanceRatioY = Number(twoLegData.balanceRatio_FFRF); // 1전만 2정상 3후만

      const leftValue = (balanceRatioXData + 1) / 2;
      const rightValue = 1 - leftValue;
      const frontValue = (balanceRatioYData + 1) / 2;
      const rFrontValue = 1 - frontValue;

      const leftPercentage = Math.round(leftValue * 100);
      const rightPercentage = Math.round(rightValue * 100);
      const frontPercentage = Math.round(frontValue * 100);
      const rFrontPercentage = Math.round(rFrontValue * 100);

      const positionObj = {
        front: $L("forward"),
        back: $L("backward"),
        left: $L("left"),
        right: $L("right"),
      };

      const key = () => {
        let ret;
        if (balanceRatioX === 2 && balanceRatioY === 2) {
          ret = bodyBalanceTabStatus.PERFECT;
        } else if (balanceRatioX === 2 && (balanceRatioY === 1 || balanceRatioY === 3)) {
          ret = bodyBalanceTabStatus.TILT_FB;
        } else if (balanceRatioY === 2 && (balanceRatioX === 1 || balanceRatioX === 3)) {
          ret = bodyBalanceTabStatus.TILT_LR;
        } else if ((balanceRatioX === 1 || balanceRatioX === 3) && (balanceRatioY === 1 || balanceRatioY === 3)) {
          ret = bodyBalanceTabStatus.TILT_ALL;
        }
        return ret;
      };

      let viewFeedback = "";

      const renderFeedbackPosX = () => {
        let posX;
        if (balanceRatioX === 3) {
          posX = positionObj.right;
        } else if (balanceRatioX === 1) {
          posX = positionObj.left;
        }
        return posX;
      };

      const renderFeedbackPosY = () => {
        let posY;
        if (balanceRatioY === 3) {
          posY = positionObj.back;
        } else if (balanceRatioY === 1) {
          posY = positionObj.front;
        }
        return posY;
      };

      const renderFeedbackValueX = () => {
        let valX;
        if (balanceRatioX === 3) {
          valX = rightPercentage;
        } else if (balanceRatioX === 1) {
          valX = leftPercentage;
        }
        return valX;
      };

      const renderFeedbackValueY = () => {
        let valY;
        if (balanceRatioY === 3) {
          valY = rFrontPercentage;
        } else if (balanceRatioY === 1) {
          valY = frontPercentage;
        }
        return valY;
      };

      const replacements = {
        "{xPosition}": renderFeedbackPosX(),
        "{yPosition}": renderFeedbackPosY(),
        "{xValue}": renderFeedbackValueX(),
        "{yValue}": renderFeedbackValueY(),
      };

      const feedbackBodyBalanceObj = feedbackBodyBalanceTab();
      viewFeedback = feedbackBodyBalanceObj[key()].replace(
        /{xPosition}|{yPosition}|{xValue}|{yValue}/g,
        (key) => replacements[key]
      );

      return {
        bodyBalanceTab: viewFeedback,
        feedback: feedbackBodyBalanceTabVoice,
        feedbackVoice: feedbackBodyBalanceTabVoice.replaceAll("{br}", ""),
        status: key(),
      };
    }
  }
  if (oneLegData) {
    if (tabType === bodyBalanceMenu.COP) {
      const indexL = Number(oneLegData.value_leftBalance);
      const indexR = Number(oneLegData.value_rightBalance);

      const status = (data) => {
        if (data < ONE_LEG_BALANCE_VALUE.preventionMax) {
          return SCORE_MINT_KEY;
        } else if (ONE_LEG_BALANCE_VALUE.managementMin <= data && data <= ONE_LEG_BALANCE_VALUE.managementMax) {
          return SCORE_YELLOW_KEY;
        } else if (ONE_LEG_BALANCE_VALUE.dangerMin < data) {
          return SCORE_RED_KEY;
        }
      };

      const footData = {
        left: {
          position: $L("Left Foot"),
          value: indexL,
          status: status(indexL),
        },
        right: {
          position: $L("Right Foot"),
          value: indexR,
          status: status(indexR),
        },
      };

      for (const position in footData) {
        const footStatus = footData[position].status;
        const footPosition = footData[position].position;

        const feedbackCoPObj = feedbackCoP();
        const viewFeedback = feedbackCoPObj[footStatus].replace(
          "{position}",
          footPosition
        );

        footData[position].feedback = viewFeedback;
      }

      return {
        CoP: {
          left: footData.left.feedback,
          right: footData.right.feedback,
        },
        feedback: feedbackCoPVoice,
        feedbackVoice: feedbackCoPVoice.replaceAll("{br}", ""),
      };
    }
  }
};

export const bodyBalanceViewDetails = (tabType, obj) => {
  const twoLegData = obj?.twolegstance;
  let feedbackViewDetailsObj = feedbackViewDetails();

  if (
    tabType === bodyBalanceMenu.BODY_BALANCE_TAB ||
    tabType === bodyBalanceMenu.COP
  ) {
    const feedbackArr = feedbackViewDetailsObj[tabType];

    return {
      mainTitle: feedbackArr?.mainTitle,
      mainDesc: feedbackArr?.status,
      diseases: feedbackArr?.diseases,
      exercise: feedbackArr?.exercise,
    };
  } else if (tabType === bodyBalanceMenu.FOOT_TYPE) {
    if (twoLegData) {
      const indexL = twoLegData.value_archIndex_L;
      const indexR = twoLegData.value_archIndex_R;

      const status = (data) => {
        let status, footType;
        if (data < FOOTTYPE_VALUE.susflatMax) {
          status = footTypeStatus.FLATFOOT;
          footType = $L("Flat Foot");
        } else if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
          status = footTypeStatus.COMMON;
          footType = $L("Medium-arched foot");
        } else if (FOOTTYPE_VALUE.suscavusMin < data) {
          status = footTypeStatus.LUMBAR;
          footType = $L("Hollow Foot");
        }
        return { status, footType };
      };

      return {
        left: {
          key: status(indexL).status,
          footType: status(indexL).footType,
          ...feedbackViewDetailsObj[tabType][status(indexL).status],
        },
        right: {
          key: status(indexR).status,
          footType: status(indexR).footType,
          ...feedbackViewDetailsObj[tabType][status(indexR).status],
        },
      };
    }
  }
};
