import { MENUTYPE } from "../../BodyAlignmentReport/Constants";

// neck_tilt
import ic_bodycheck_picto_neck_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_neck_01.svg";
import ic_bodycheck_picto_neck_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_neck_02.svg";
import ic_bodycheck_picto_neck_03 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_neck_03.svg";

// shoulder_tilt
import ic_bodycheck_picto_shoulder_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_shoulder_01.svg";
import ic_bodycheck_picto_shoulder_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_shoulder_02.svg";

// pelvic_tilt
import ic_bodycheck_picto_pelvic_tilt_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_pelvic_tilt_01.svg";
import ic_bodycheck_picto_pelvic_tilt_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_pelvic_tilt_02.svg";
import ic_bodycheck_picto_pelvic_tilt_03 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_pelvic_tilt_03.svg";

// pelvic_rotation
import ic_bodycheck_picto_pelvic_rotation_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_pelvic_rotation_01.svg";
import ic_bodycheck_picto_pelvic_rotation_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_pelvic_rotation_02.svg";
import ic_bodycheck_picto_pelvic_rotation_03 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_pelvic_rotation_03.svg";

// leg_alignment
import ic_bodycheck_picto_leg_alignment_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_leg_alignment_01.svg";
import ic_bodycheck_picto_leg_alignment_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_leg_alignment_02.svg";
import ic_bodycheck_picto_leg_alignment_03 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_leg_alignment_03.svg";

// head_shift
import ic_bodycheck_picto_nect_shift_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_nect_shift_01.svg";
import ic_bodycheck_picto_nect_shift_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_nect_shift_02.svg";
import ic_bodycheck_picto_nect_shift_03 from "../../../../../../assets/bodyAlignment/pictogram/ic_bodycheck_picto_nect_shift_03.svg";

// trunk_shift
import ic_trunk_shift_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_trunk_shift_01.svg";
import ic_trunk_shift_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_trunk_shift_02.svg";

// pelvic_shift
import ic_pelvic_shift_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_pelvic_shift_01.svg";
import ic_pelvic_shift_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_pelvic_shift_02.svg";
import ic_pelvic_shift_03 from "../../../../../../assets/bodyAlignment/pictogram/ic_pelvic_shift_03.svg";

// knee_flexion
import ic_knee_flexion_01 from "../../../../../../assets/bodyAlignment/pictogram/ic_knee_flexion_01.svg";
import ic_knee_flexion_02 from "../../../../../../assets/bodyAlignment/pictogram/ic_knee_flexion_02.svg";
import ic_knee_flexion_03 from "../../../../../../assets/bodyAlignment/pictogram/ic_knee_flexion_03.svg";

export const pictogramType = {
  [MENUTYPE.NECK_TILT]: [
    {
      icon: ic_bodycheck_picto_neck_01,
      title: "Scalenus",
    },
    {
      icon: ic_bodycheck_picto_neck_02,
      title: "Musculus Sterno Cleido Mastoideus",
    },
    {
      icon: ic_bodycheck_picto_neck_03,
      title: "Musculus Sterno Cleido Mastoideus",
    },
  ],
  [MENUTYPE.SHOULDER_TILT]: [
    {
      icon: ic_bodycheck_picto_shoulder_01,
      title: "Levator Scapula",
    },
    {
      icon: ic_bodycheck_picto_shoulder_02,
      title: "Latissimus Dorsi",
    },
  ],
  [MENUTYPE.PELVIC_TILT]: [
    {
      icon: ic_bodycheck_picto_pelvic_tilt_01,
      title: "Hip Adductor",
    },
    {
      icon: ic_bodycheck_picto_pelvic_tilt_02,
      title: "Gluteus Medius",
    },
    {
      icon: ic_bodycheck_picto_pelvic_tilt_03,
      title: "Quadratus Lumborum",
    },
  ],
  [MENUTYPE.PELVIC_ROTATION]: [
    {
      icon: ic_bodycheck_picto_pelvic_rotation_01,
      title: "Hip Adductor",
    },
    {
      icon: ic_bodycheck_picto_pelvic_rotation_02,
      title: "Quadratus Lumborum",
    },
    {
      icon: ic_bodycheck_picto_pelvic_rotation_03,
      title: "Oblique",
    },
  ],
  [MENUTYPE.LEG_ALIGNMENT]: [
    {
      icon: ic_bodycheck_picto_leg_alignment_01,
      title: "Hip Adductor",
    },
    {
      icon: ic_bodycheck_picto_leg_alignment_02,
      title: "Hip Adductor",
    },
    {
      icon: ic_bodycheck_picto_leg_alignment_03,
      title: "Gluteus Medius",
    },
  ],
  [MENUTYPE.HEAD_SHIFT]: [
    {
      icon: ic_bodycheck_picto_nect_shift_01,
      title: "Suboccpitalis",
    },
    {
      icon: ic_bodycheck_picto_nect_shift_02,
      title: "Capital Flexors",
    },
    {
      icon: ic_bodycheck_picto_nect_shift_03,
      title: "Rhomboid, Lower Trapezius",
    },
  ],
  [MENUTYPE.TRUNK_SHIFT]: [
    // shoulder shift
    {
      icon: ic_trunk_shift_01,
      title: "Pectoralis Minor",
    },
    {
      icon: ic_trunk_shift_02,
      title: "Serratus Anterior",
    },
  ],
  [MENUTYPE.PELVIC_SHIFT]: [
    {
      icon: ic_pelvic_shift_01,
      title: "Iliopsoas",
    },
    {
      icon: ic_pelvic_shift_02,
      title: "Hamstring",
    },
    {
      icon: ic_pelvic_shift_03,
      title: "Hamstring",
    },
  ],
  [MENUTYPE.KNEE_FLEXION]: [
    {
      icon: ic_knee_flexion_01,
      title: "Hamstring",
    },
    {
      icon: ic_knee_flexion_02,
      title: "Hamstring",
    },
    {
      icon: ic_knee_flexion_03,
      title: "Rectus Femoris",
    },
  ],
};
