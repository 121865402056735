import css from './PreviewPanel.module.less';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import QRCode from 'react-qr-code';

import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import Spotlight from '@enact/spotlight';
import SpotlightIds from '../../utils/SpotlightIds';

import {
  $L,
  convertToUSDateFormat,
  transNumToAgeText,
  scaleW,
  transDateToText,
	convertNewlinesToBr,
} from "../../utils/helperMethods";
import { captureScreen } from '../../utils/captureScreen';
import { changeAppStatus, changeThemeModeStatus } from '../../features/common/commonSlice';
import { deleteCapturedScreen, postCapturedScreen } from '../../features/fitService/fitServiceSlice';
import { resetPanels } from '../../features/panels/panelsSlice';

import TPanel from '../../components/TPanel/TPanel';
import THeader from '../../components/THeader';
import TBody from '../../components/TBody/TBody';
import TScroller from '../../components/TScroller/TScroller';
import InfoSection from './components/InfoSection/InfoSection';
import TButton, { SIZES } from '../../components/TButton/TButton';

import BodyAlignmentReport from './page/BodyAlignment/BodyAlignmentReport';
import ROMReport from './page/ROM/ROMReport';
import BodyBalanceReport from './page/BodyBalance/BodyBalanceReport';
import PhysicalTestReport from './page/PhysicalTest/PhysicalTestReport';

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const TYPES = {
  BODY_ALIGNMENT: "BODY_ALIGNMENT",
  ROM: "ROM",
  BODY_BALANCE: "BODY_BALANCE",
  PHYSICAL_TEST: "PHYSICAL_TEST",
};

const getLIST = () => {
	return [
		{ type: "bodyalignment", title: $L("Body Alignment") },
		{ type: "rom", title: $L("Range Of Motion") },
		{ type: "bodybalance", title: $L("Body Balance") },
		{ type: "physicaltest", title: $L("Physical Test") },
	];
};

const PREVIEW_SPOTLIGHT_ID = [
	"preview_bodyalignment",
	"preview_rom",
	"preview_bodybalance",
	"preview_physicaltest",
];

let LIST = null;
const SPOTLIGHT_ID = "preview_panel_container";

const PreviewPanel = ({panelInfo, ...rest}) => {
	if (!LIST) {
		LIST = getLIST();
	}
	const dispatch = useDispatch();
  const { type } = panelInfo;
	const { height, weight, sex, age } = useSelector((state) => state.fitService.survey);
  const { profileNick, language } = useSelector(
    (state) => state.common.appStatus
  );
	const broadcast = useSelector((state) => state.common.broadcast);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);

  const { dateList: bodyAlignmentDateList } = useSelector(
    (state) => state.bodyAlignment
  );
  const { dateList: bodyBalanceDateList } = useSelector(
    (state) => state.bodyBalance
  );
	const [imageUrl, setImageUrl] = useState("");
	const [isMounted, setIsMounted] = useState(false);
	const scrollTo = useRef(null);

  // const enterType = useMemo(() => {
  //   return type ?? TYPES.BODY_ALIGNMENT;
  // }, [type]);

  const USER_INFO = useMemo(() => {
    return [
      { id: "name", title: $L("Name"), info: profileNick ? profileNick : "LG" },
      {
        id: "gender",
        title: $L("Gender"),
        info: sex === 0 ? $L("Male") : $L("Female"),
      },
      {
        id: "birth",
        title: $L("Birth"),
        info: transDateToText("1963-05-30", language, "SHORT"),
      },
      {
        id: "age",
        title: $L("Age"),
        info: (age ? age : 60) + "",
      },
      { id: "height", title: $L("Height"), info: `${height ? height : 172}cm` },
      { id: "weight", title: $L("Weight"), info: `${weight ? weight : 70}kg` },
    ];
  }, [profileNick, age, sex, height, weight, language]);

	const onClickItem = useCallback((index) => (ev) => {
		const node = document.querySelector(`[data-spotlight-id="${PREVIEW_SPOTLIGHT_ID[index]}"]`);
		scrollTo.current({ node: node, animate: true });
	}, []);


	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, []);

	useEffect(() => {
		dispatch(changeThemeModeStatus("dark"));
		return () =>{
			dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
			dispatch(changeThemeModeStatus("light"));
		}
	}, []);

	useEffect(() => {
		if(broadcast?.type === 'imageUrlCreated'){
			setImageUrl(broadcast?.moreInfo?.url);
			dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
			Spotlight.focus(SpotlightIds.THEADER_BACK);
		}
	}, [broadcast]);

	useEffect(()=>{
		dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: "wait" } }));
		setIsMounted(true);
		return ()=>{
				setIsMounted(false);
		}
	},[]);

	useEffect(() => {
		if(isMounted) {
			setTimeout(() => {
				captureScreen(SPOTLIGHT_ID)
				.then(thumbnail => {
					dispatch(postCapturedScreen(thumbnail));
				})
				.catch(error => {
					console.error('Error capturing screen:', error);
					dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
				});
			}, 1000);
		}
	}, [isMounted]);

	// const onClickCaptureButton = useCallback(() => {
	// 	dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: "wait" } }));
	// 	setTimeout(() => {
	// 		captureScreen(SPOTLIGHT_ID)
	// 		.then(thumbnail => {
	// 			dispatch(postCapturedScreen(thumbnail));
	// 		})
	// 		.catch(error => {
	// 			console.error('Error capturing screen:', error);
	// 			dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
	// 		});
	// 	}, 0);
	// }, []);

	const backKeyHandler = useCallback((ev) => {
		if (imageUrl) {
			const fileName = imageUrl.split('/').pop();
			dispatch(deleteCapturedScreen(fileName));
		}
		dispatch(resetPanels());
		ev?.stopPropagation();
	}, [dispatch, imageUrl]);

	return (
		<TPanel className={css.panel} {...rest} handleCancel={backKeyHandler}>
			<THeader title={$L("Preview")} onClick={backKeyHandler} />
			<TBody className={css.body}>
				<TScroller
					className={css.scroller}
					cbScrollTo={getScrollTo}
				>
					<Container className={css.innerContainer} spotlightId={SPOTLIGHT_ID}>
						{/* <Container className={css.gateContainer}>
							<div className={css.jimmyImage}/>
							<div className={css.infoContainer}>
								{USER_INFO.map((item) => {
									return (
										<div className={css.infoBox} key={item.id}>
											<div className={css.qBox}>{$L(item.title)}</div>
											<div className={css.aBox}>{$L(item.info)}</div>
										</div>
									);
								})}
							</div>
							<div className={css.buttonWrapper}>
								{LIST.map((item, index) => (
									<InfoSection
										key={"order" + index}
										type={item.type}
										title={item.title}
										onClick={onClickItem(index)}
									/>
								))}
							</div>
						</Container > */}
            {!cesShowMode && bodyAlignmentDateList.length > 0 && (
              <BodyAlignmentReport
                spotlightId={PREVIEW_SPOTLIGHT_ID[0]}
                dateList={bodyAlignmentDateList}
              />
            )}
						{/* <ROMReport spotlightId={PREVIEW_SPOTLIGHT_ID[1]}/> */}
            {!cesShowMode && bodyBalanceDateList.length > 0 && (
              <BodyBalanceReport
                spotlightId={PREVIEW_SPOTLIGHT_ID[2]}
                dateList={bodyBalanceDateList}
              />
            )}
						{/* <PhysicalTestReport spotlightId={PREVIEW_SPOTLIGHT_ID[3]}/> */}

            {/* cesShowMode */}
            {cesShowMode ? (
              type === TYPES.BODY_ALIGNMENT &&
              bodyAlignmentDateList.length > 0 ? (
                <BodyAlignmentReport
                  spotlightId={PREVIEW_SPOTLIGHT_ID[0]}
                  dateList={bodyAlignmentDateList}
                />
              ) : (
                bodyBalanceDateList.length > 0 && (
                  <BodyBalanceReport
                    spotlightId={PREVIEW_SPOTLIGHT_ID[2]}
                    dateList={bodyBalanceDateList}
                  />
                )
              )
            ) : null}
            <div className={css.footer}>
              {$L(
                "LG Intelli-FiT recommendations and feedback should not be considered, or used as a substitute for professional medical advice or treatment."
              )}
            </div>
					</Container>

				</TScroller>
				{/* <div className={css.captureButtonWrapper}>
				{!imageUrl &&
					<TButton
						className={css.captureButton}
						size={SIZES.xLarge}
						onClick={onClickCaptureButton}
					>
						{$L("CAPTURE BUTTON")}
					</TButton>
				}
				</div> */}
				<div className={css.qRCodeWrapper}>
					{imageUrl && (
						<QRCode
							className={css.qrcode}
							value={imageUrl}
							size={scaleW(250)}
						/>
					)}
					<div className={css.guideText}>
						{convertNewlinesToBr($L("If you’d like to access the report,{br}simply scan the QR code to download it to your mobile device."))}
					</div>
				</div>
			</TBody>

		</TPanel>
	);
};

export default PreviewPanel;
export { TYPES };
