import { useEffect, useState, useMemo, useCallback } from "react";
import classNames from "classnames";
import css from "./FootTypeArchBarItem.module.less";
import { $L } from "../../utils/helperMethods";
import {FOOTTYPE_VALUE} from "../../utils/Constants";
import BarMark from "../../../assets/icon/barMark.svg";
import HollowFoot from "../../../assets/icon/hollow_foot.svg";
import NormalFoot from "../../../assets/icon/normal_foot.svg";
import FlatFoot from "../../../assets/icon/flat_foot.svg";

const FootTypeArchBarItem = ({
  archIndexLData,
  archIndexRData,
  style = {},
}) => {
  const [leftFoot, setLeftFoot] = useState(0);
  const [rightFoot, setRightFoot] = useState(0);

  const {
    footArchBarItemSectStyle,
    footArchInfoWrapStyle,
    footArchInfoWrapTextStyle,
    footArchIndexStyle,
    footLevelStyle,
    footRenderTypeStyle,
    footStepFlexStyle,
    footStepFlexInfoStyle,
    footStepFlexImgStyle,
    footStepStyle,
    fixedBarStyle,
  } = style;

  const resetData = useCallback((data) => {
    if (data > FOOTTYPE_VALUE.cavusMax) {
      return (data = FOOTTYPE_VALUE.cavusMax);
    } else if (data < FOOTTYPE_VALUE.flatMin) {
      return (data = FOOTTYPE_VALUE.flatMin);
    } else if (FOOTTYPE_VALUE.flatMin <= data <= FOOTTYPE_VALUE.cavusMax) {
      return data;
    }
  }, []);

  const positionData = useMemo(() => {
    const roundToTwoDecimalPlaces = (number) => {
      if (number % 1 !== 0) {
        return parseFloat(number.toFixed(2));
      } else {
        return number;
      }
    };

    const renderType = (data) => {
      if (data < FOOTTYPE_VALUE.flatMax) {
        return $L("Flat Foot");
      } else if (FOOTTYPE_VALUE.susflatMin <= data && data < FOOTTYPE_VALUE.susflatMax) {
        return $L("Low-arhced foot");
      } else if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
        return $L("Medium-arched foot");
      } else if (FOOTTYPE_VALUE.suscavusMin < data && data <= FOOTTYPE_VALUE.suscavusMax) {
        return $L("High-arched foot");
      } else if (FOOTTYPE_VALUE.cavusMin < data) {
        return $L("Hollow Foot");
      }
    };

    const percentageData = (data) => {
      let ret = 0;
      if (data < FOOTTYPE_VALUE.flatMax) {
        ret =
          (100 / (FOOTTYPE_VALUE.flatMax - FOOTTYPE_VALUE.flatMin)) *
          (data - FOOTTYPE_VALUE.flatMin);
      } else if (FOOTTYPE_VALUE.susflatMin <= data && data < FOOTTYPE_VALUE.susflatMax) {
        ret =
          (100 / (FOOTTYPE_VALUE.susflatMax - FOOTTYPE_VALUE.susflatMin)) *
          (data - FOOTTYPE_VALUE.susflatMin);
      } else if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
        ret =
          (100 / (FOOTTYPE_VALUE.normalMax - FOOTTYPE_VALUE.normalMin)) *
          (data - FOOTTYPE_VALUE.normalMin);
      } else if (FOOTTYPE_VALUE.suscavusMin < data && data <= FOOTTYPE_VALUE.suscavusMax) {
        ret =
          (100 / (FOOTTYPE_VALUE.suscavusMax - FOOTTYPE_VALUE.suscavusMin)) *
          (data - FOOTTYPE_VALUE.suscavusMin);
      } else if (FOOTTYPE_VALUE.cavusMin < data) {
        ret =
          (100 / (FOOTTYPE_VALUE.cavusMax - FOOTTYPE_VALUE.cavusMin)) *
          (data - FOOTTYPE_VALUE.cavusMin);
      }

      return Math.max(0, Math.min(ret, 100));
    };

    const renderTypeDisplay = (data) => {
      if (data < FOOTTYPE_VALUE.flatMax) {
        return { display: "block" };
      } else if (FOOTTYPE_VALUE.susflatMin <= data && data < FOOTTYPE_VALUE.susflatMax) {
        return style && footRenderTypeStyle;
      } else if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
        return { display: "block" };
      } else if (FOOTTYPE_VALUE.suscavusMin < data && data <= FOOTTYPE_VALUE.suscavusMax) {
        return style && footRenderTypeStyle;
      } else if (FOOTTYPE_VALUE.cavusMin < data) {
        return { display: "block" };
      }
    };

    const footData = {
      left: {
        data: leftFoot,
        displayName: $L({ key: "Left Foot", value: "Left" }),
      },
      right: {
        data: rightFoot,
        displayName: $L({ key: "Right Foot", value: "Right" }),
      },
    };
    const ret = {
      left: {},
      right: {},
    };

    for (const position in ret) {
      const data = footData[position];
      const roundData = Number(Number(data.data).toFixed(2));
      ret[position] = {
        footDirection: data.displayName,
        renderType: renderType(roundData),
        foot: roundToTwoDecimalPlaces(Number(roundData)),
        percentageData: percentageData(roundData),
        renderTypeDisplay: renderTypeDisplay(roundData),
      };
    }

    return { left: ret.left, right: ret.right };
  }, [leftFoot, rightFoot]);

  useEffect(() => {
    setLeftFoot(resetData(archIndexLData));
    setRightFoot(resetData(archIndexRData));
  }, [archIndexLData, archIndexRData]);

  const renderArchBarComponents = useCallback((item) => {
    return (
      <div
        className={css.archBarItemSect}
        style={style && footArchBarItemSectStyle}
      >
        <div
          className={css.archInfoWrap}
          style={style && footArchInfoWrapStyle}
        >
          <div className={css.archInfoWrapText} style={style && footArchInfoWrapTextStyle}>
            <div className={css.archIndex} style={style && footArchIndexStyle}>{item?.footDirection}</div>
            <div className={css.type} style={item?.renderTypeDisplay}>
              {item?.renderType}
            </div>
          </div>
          <div className={css.level} style={style && footLevelStyle}>{`(${item?.foot})`}</div>
        </div>
        <div
          className={classNames(css.fixedBar)}
          style={style && fixedBarStyle}
        >
          <div
            className={classNames(
              css.fixedBarSection,
              css.mainBar,
              item?.foot < FOOTTYPE_VALUE.flatMax ? css.located : null
            )}
          >
            <div
              className={classNames(
                item?.foot < FOOTTYPE_VALUE.flatMax ? css.marker : css.none
              )}
              style={{
                left: `${item?.percentageData}%`,
                backgroundImage: `url(${BarMark})`,
              }}
            />
            <div className={css.stepFlex} style={style && footStepFlexStyle}>
              <span style={style && footStepFlexInfoStyle}>{$L("Flat Foot")}</span>
              <span className={css.step} style={style && footStepStyle}>
                {"(<" + FOOTTYPE_VALUE.flatMax + ")"}
              </span>
              <img src={FlatFoot} alt="" style={style && footStepFlexImgStyle} />
            </div>
          </div>

          <div
            className={classNames(
              css.fixedBarSection,
              css.sideBar,
              FOOTTYPE_VALUE.susflatMin <= item?.foot && item?.foot < FOOTTYPE_VALUE.susflatMax ? css.located : null
            )}
          >
            <div
              className={classNames(
                FOOTTYPE_VALUE.susflatMin <= item?.foot && item?.foot < FOOTTYPE_VALUE.susflatMax ? css.marker : css.none
              )}
              style={{
                left: `${item?.percentageData}%`,
                backgroundImage: `url(${BarMark})`,
              }}
            />
          </div>

          <div
            className={classNames(
              css.fixedBarSection,
              css.mainBar,
              FOOTTYPE_VALUE.normalMin <= item?.foot && item?.foot <= FOOTTYPE_VALUE.normalMax ? css.located : null
            )}
          >
            <div
              className={classNames(
                FOOTTYPE_VALUE.normalMin <= item?.foot && item?.foot <= FOOTTYPE_VALUE.normalMax ? css.marker : css.none
              )}
              style={{
                left: `${item?.percentageData}%`,
                backgroundImage: `url(${BarMark})`,
              }}
            />
            <div className={css.stepFlex} style={style && footStepFlexStyle}>
              <span style={style && footStepFlexInfoStyle}>{$L("Normal")}</span>
              <span className={css.step} style={style && footStepStyle}>
                {"(" +
                  FOOTTYPE_VALUE.normalMin +
                  "~" +
                  FOOTTYPE_VALUE.normalMax +
                  ")"}
              </span>
              <img src={NormalFoot} alt="" style={style && footStepFlexImgStyle} />
            </div>
          </div>

          <div
            className={classNames(
              css.fixedBarSection,
              css.sideBar,
              FOOTTYPE_VALUE.suscavusMin < item?.foot && item?.foot <= FOOTTYPE_VALUE.suscavusMax ? css.located : null
            )}
          >
            <div
              className={classNames(
                FOOTTYPE_VALUE.suscavusMin < item?.foot && item?.foot <= FOOTTYPE_VALUE.suscavusMax ? css.marker : css.none
              )}
              style={{
                left: `${item?.percentageData}%`,
                backgroundImage: `url(${BarMark})`,
              }}
            />
          </div>

          <div
            className={classNames(
              css.fixedBarSection,
              css.mainBar,
              FOOTTYPE_VALUE.cavusMin < item?.foot ? css.located : null
            )}
          >
            <div
              className={classNames(FOOTTYPE_VALUE.cavusMin < item?.foot ? css.marker : css.none)}
              style={{
                left: `${item?.percentageData}%`,
                backgroundImage: `url(${BarMark})`,
              }}
            />
            <div className={css.stepFlex} style={style && footStepFlexStyle}>
              <span style={style && footStepFlexInfoStyle}>{$L("Hollow Foot")}</span>
              <span className={css.step} style={style && footStepStyle}>
                {"(" + FOOTTYPE_VALUE.cavusMin + "<)"}
              </span>
              <img src={HollowFoot} alt="" style={style && footStepFlexImgStyle} />
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <>
      {/* leftFoot */}
      {renderArchBarComponents(positionData?.left)}

      {/* rightFoot */}
      {renderArchBarComponents(positionData?.right)}
    </>
  );
};

export default FootTypeArchBarItem;