import React, {useState, useEffect, useCallback} from 'react';
import { useDispatch } from 'react-redux';
import {$L} from '../../../utils/helperMethods';
import SubBody from '../SubBody';
import * as Config from "../../../utils/Config";
import {updatePanel} from "../../../features/panels/panelsSlice";
import Stride from './Stride';
import MotivationSet from './MotivationSet';
import Concede from './Concede';

const SubEtc = ({ ...rest }) => {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onTabChanged = useCallback(({index})=>{
    setSelectedTabIndex(index);
    dispatch(updatePanel({name: Config.panel_names.SETTING_PANEL, panelInfo: {selectedTab: index}}));
  },[]);

  useEffect(() => {
    console.log('selectedTabIndex ',selectedTabIndex);
  }, [selectedTabIndex]);

  const tabList = [
    { title: $L("Stride length"), children: <Stride/> },
    { title: $L("Concede"), children: <Concede/> },

    // { title: $L("동기부여"), children: <MotivationSet/> },


    // { title: $L("Motivation"), children: <MotivationSet/> },

    // { title: $L("Lock the app"), children: <Stride/> }
  ];

  return (
    <SubBody simpleButtonList={tabList.map(tab => tab.title)} onTabChanged={onTabChanged} {...rest}>
      {tabList[selectedTabIndex].children || null}
    </SubBody>
  );
};

export default SubEtc;