import { useDispatch } from "react-redux";
import { useEffect } from "react";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader/THeader";
import TBody from "../../components/TBody/TBody";
import { $L } from '../../utils/helperMethods';
import { changeThemeModeStatus } from "../../features/common/commonSlice";
import Terms from "./Terms";

const TermsConditionsPanel = ({...rest}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  return (
    <TPanel {...rest}>
      {/* <THeader title={$L("Terms and Conditions of Use")} iconType="none"/> */}
      <TBody>
        <Terms/>
      </TBody>
    </TPanel>
  );
};

export default TermsConditionsPanel;
