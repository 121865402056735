import { createSlice } from "@reduxjs/toolkit";

export const logDataSlice = createSlice({
  name: "logData",
  initialState: {
    totalLog: [],
    loggerWindowState: false,
  },
  reducers: {
    addTotalLog: (state, action) => {
      state.totalLog = [...state.totalLog, action.payload];
    },
    onLoggerWindowState: (state, action) => {
      state.loggerWindowState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addTotalLog, onLoggerWindowState } = logDataSlice.actions;

export default logDataSlice.reducer;
