/**
 * TRadarChart
 *
 * @module TRadarChart
 */
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import css from './TRadarChart.module.less';
import * as d3 from 'd3';

const FONT_SIZE = 30;

/**
 * @param {string} className - A class name that the user wants to add.
 * @param {number} sides - The number of sides to create the polygon with (e.g. 3 for a triangle, 4 for a square, 5 for a pentagon, etc.).
 * @param {number | number[]} levels - Splits the chart based on specified numbers within the chart.
 * @param {{ value: number, name: string, id: number }[]} personalData - An array of personal data to be used for drawing the chart.
 * @param {{ value: number, name: string, id: number }[]} averageData - An array of average data to be used for drawing the chart.
 * @param {number} size - The width and height of the chart.
 * @param {number} startIndex - The starting index (default is 0) to specify the starting position of the chart (higher values rotate the chart clockwise).
 * @param {string[]} labelColors - An array containing the color values of the labels.
 */
const TRadarChart = ({
  className,
  sides = 5,
  levels,
  personalData,
  averageData,
  size,
  startIndex = 0,
  labelColors,
  ...rest
}) => {
  const svgRef = useRef(null);
  const offset = Math.PI;
  const polyangle = useMemo(() => (Math.PI * 2) / sides, [sides]);
  const r0 = useMemo(() => (size * 0.6) / 2, [size]);
  const center = useMemo(() => ({ x: size / 2, y: size / 2 }), [size]);
  const scale = d3.scaleLinear().domain([0, 100]).range([0, r0]).nice();
  const targetOrder = useMemo(() => {
    const targetData = [...personalData];
    targetData.sort((a, b) => b.value - a.value);

    return targetData.map((data) => data.name);
  }, [personalData]);

  const sortedData = useCallback(
    (data) => {
      const targetData = [...data];

      return targetData.sort((a, b) => targetOrder.indexOf(a.name) - targetOrder.indexOf(b.name));
    },
    [targetOrder],
  );

  const generatePoint = ({ length, angle }) => {
    return {
      x: center.x + length * Math.sin(offset - angle),
      y: center.y + length * Math.cos(offset - angle),
    };
  };

  const drawPath = ({ points, parent }) => {
    const lineGenerator = d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y);

    parent.append('path').attr('d', lineGenerator(points));
  };

  const generateAndDrawLevels = (levels) => {
    const group = d3.select(svgRef.current).append('g').attr('class', css.levels);

    if (typeof levels === 'number') {
      for (let step = 1; step <= levels; step++) {
        const points = [];
        const hyp = (step / levels) * r0;

        for (let vertex = 0; vertex < sides; vertex++) {
          const theta = vertex * polyangle;

          points.push(generatePoint({ length: hyp, angle: theta }));
        }

        drawPath({ points: [...points, points[0]], parent: group });
      }
    } else {
      [...levels, 1].forEach((customLevel) => {
        const points = [];
        const hyp = customLevel * r0;

        for (let vertex = 0; vertex < sides; vertex++) {
          const theta = vertex * polyangle;

          points.push(generatePoint({ length: hyp, angle: theta }));
        }

        drawPath({ points: [...points, points[0]], parent: group });
      });
    }
  };

  const generateAndDrawLines = () => {
    const group = d3.select(svgRef.current).append('g').attr('class', css.lines);

    for (let vertex = 1; vertex <= sides; vertex++) {
      const theta = vertex * polyangle;
      const point = generatePoint({ length: r0, angle: theta });

      drawPath({ points: [center, point], parent: group });
    }
  };

  const drawData = ({ data, type = 'average' }) => {
    const group = d3.select(svgRef.current).append('g').attr('data-type', type).attr('class', css.shape);

    const points = [];

    sortedData(data).forEach((d, i) => {
      const len = scale(d.value);
      const theta = polyangle * (i + startIndex);

      points.push(generatePoint({ length: len, angle: theta }));
    });

    drawPath({ points: [...points, points[0]], parent: group });
  };

  const drawLabels = ({ data, labelColors = [] }) => {
    const group = d3.select(svgRef.current).append('g');
    const targetData = sortedData(data);

    for (let vertex = 0; vertex < sides; vertex++) {
      const _360degrees = Math.PI * 2;
      const _45degrees = _360degrees / 8;
      const label = targetData[vertex].name;
      const angle = polyangle * (vertex + startIndex);
      let point;
      let labelPosition;

      switch (true) {
        case angle % _360degrees > _45degrees && angle % _360degrees <= _45degrees * 3:
          labelPosition = 'right';
          point = generatePoint({ length: r0 * 1.1, angle });
          break;
        case angle % _360degrees > _45degrees * 3 && angle % _360degrees <= _45degrees * 5:
          labelPosition = 'down';
          point = generatePoint({ length: (r0 + FONT_SIZE) * 1.1, angle });
          break;
        case angle % _360degrees > _45degrees * 5 && angle % _360degrees <= _45degrees * 7:
          labelPosition = 'left';
          point = generatePoint({ length: r0 * 1.1, angle });
          break;
        default:
          labelPosition = 'up';
          point = generatePoint({ length: r0 * 1.1, angle });
          break;
      }

      group
        .append('text')
        .attr('x', point.x)
        .attr('y', point.y)
        .html(label)
        .style('font-size', FONT_SIZE)
        .style('fill', labelColors[vertex] ?? 'rgba(0, 0, 0, 1)')
        .attr('label-position', labelPosition)
        .attr('class', css.label);
    }
  };

  const drawLegends = (legends) => {
    const dxBetweenDot = 10;
    const dxBetweenLengend = 30;
    const group = d3
      .select(svgRef.current)
      .append('text')
      .attr('x', size - (dxBetweenDot * 2 + dxBetweenLengend))
      .attr('y', size - FONT_SIZE)
      .attr('class', css.legends);

    legends.forEach((legend, i) => {
      group
        .append('tspan')
        .html('●')
        .attr('dx', i === 1 ? dxBetweenLengend : 0)
        .attr('fill', legend.color);
      group.append('tspan').html(legend.text).attr('dx', dxBetweenDot);
    });
  };

  useEffect(() => {
    generateAndDrawLevels(levels);
    generateAndDrawLines();
    drawData({ data: averageData });
    drawData({ data: personalData, type: 'personal' });
    drawLabels({
      data: personalData,
      labelColors,
    });
    drawLegends([
      { text: '내 점수', color: 'rgba(245, 178, 194, 1)' },
      { text: '동년배 평균', color: 'rgba(176, 176, 176, 1)' },
    ]);

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };
  }, [sides, levels, personalData, averageData, size, startIndex]);

  useEffect(() => {
    if (sides !== personalData?.length)
      console.warn('The length of the data and the number of sides must be the same.');
  }, [sides, personalData?.length]);

  return (
    <div className={classNames(css.container, className)} {...rest}>
      <svg ref={svgRef} width={size} height={size} className={css.radar} />
    </div>
  );
};

export default TRadarChart;
