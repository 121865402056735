const SpotlightIds = {
  LANDING_TBODY: "landing_tbody",
  TBODY: "tbody",
  THEADER_BACK: "theaderBack",
  SETTINGS_MAIN_TOPLIST: "settings_main_toplist",
  SETTINGS_SUB_CONNECTION: "settings_sub_connection",
  SETTINGS_SUB_CAMERA_SCROLLER: "settings_sub_camera_scroller",

  // mediaList
  WALKING: "WALKING",
  RECOMMEND: "RECOMMEND",
  // textPopup on ReportTop
  REPORT_TEXT_BUTTON: "report_text_button",
};

export default SpotlightIds;
