import classNames from 'classnames';
import { useEffect } from 'react';
import css from './Stepper.module.less';

const Stepper = ({length = 0, number = 0,  className, ...rest}) => {

  const circles = [];

  for (let i = 0; i < length; i++) {
    circles.push(
      <div 
        key={'step' + i} 
        className={classNames(css.step, i <= number  ? css.active : null)}
      >
        <div className={classNames(css.circle)} />
        <div className={css.text}>{i+1}</div>
    </div>
    );
  }

  return (
    <div className={classNames(
      css.stepper, 
      className ? className : null,
    )}
    >
      <div className={css.fixedBar} />
      <div className={css.stepBar} style={{ width: `${(100 / (length - 1)) * number}%` }}/>
      {length > 0 ? circles : <div> No Order </div>}
    </div>
  )
}

export default Stepper;