//Signature(1byte), Version(1byte), MessageId(1byte), NRP(2byte), payload(...)
import {Job} from '@enact/core/util';

import dummyMatWorkmode from './dummyMatWorkmode';
import dummyMatBodyChecker1 from './dummyMatBodyChecker1';
import dummyMatBodyChecker2L from './dummyMatBodyChecker2L';
import dummyMatBodyChecker2R from './dummyMatBodyChecker2R';
import dummyMatBodyChecker3 from './dummyMatBodyChecker3';

// mode : walkmode, bodycheck1, bodycheck2, bodycheck3
export default class MatDummyActivator {
	constructor (callback, thunkAPI, mode) {
		this.callback = callback;
		this.thunkAPI = thunkAPI;
		this.mode = mode;
		this.seqIndex = 0;
		this.sequenceJob = null;
		this.dummyData = {};
		this.getDummyData();
	}
	getDummyData = () => {
		switch(this.mode){
			case 'walkmode': this.dummyData = dummyMatWorkmode; break;
			case 'bodycheck1': this.dummyData = dummyMatBodyChecker1; break;
			// case 'bodycheck2': this.dummyData = dummyMatBodyChecker2; break;
			case 'bodycheck2Left': this.dummyData = dummyMatBodyChecker2L; break;
			case 'bodycheck2Right': this.dummyData = dummyMatBodyChecker2R; break;
			case 'bodycheck3': this.dummyData = dummyMatBodyChecker3; break;
		}
	}
	doNextSequence = () => {
		const seq = this.dummyData.sequence[this.seqIndex];
		if(seq && seq.value){
			this.callback(
				{...this.dummyData.common, "changed" : {"value" : { "bytes": seq.value}}}
			, this.thunkAPI);
			this.sequenceJob = new Job(this.doNextSequence);
			this.sequenceJob.startAfter(seq.interval ? seq.interval: this.dummyData.interval);
		}
		this.seqIndex++;
		if(this.dummyData.loop && this.seqIndex >= this.dummyData.sequence.length){
			this.seqIndex = 0;
		}
	}
	startJob = () => {
		this.stopJob();
		this.seqIndex = 0;
		this.sequenceJob = new Job(this.doNextSequence);
		this.sequenceJob.startAfter(this.dummyData.interval);
	}
	stopJob = () => {
		if(this.sequenceJob){
			this.sequenceJob.stop();
			this.sequenceJob = null;
		}
	}
}

