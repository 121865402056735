import {
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
  SCORE_MINT_KEY,
} from "../../../../utils/Constants";
import { MENUTYPE, MENUTYPE_TEST } from "../BodyAlignmentReport/Constants";
import { $L } from "../../../../utils/helperMethods";

// ces Image
import lg_shoulder_tilt_front_good from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_tilt_front_good.jpg";
import lg_shoulder_tilt_back_good from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_tilt_back_good.jpg";

import lg_neck_tilt_front_tight_left from "../../../../../assets/images/bodyAlignmentSummary/lg_neck_tilt_front_tight_left.jpg";
import lg_neck_tilt_back_tight_left from "../../../../../assets/images/bodyAlignmentSummary/lg_neck_tilt_back_tight_left.jpg";
import lg_neck_tilt_front_tight_right from "../../../../../assets/images/bodyAlignmentSummary/lg_neck_tilt_front_tight_right.jpg";
import lg_neck_tilt_back_tight_right from "../../../../../assets/images/bodyAlignmentSummary/lg_neck_tilt_back_tight_right.jpg";

import lg_shoulder_tilt_front_tight_high_left from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_tilt_front_tight_high_left.jpg";
import lg_shoulder_tilt_back_high_left from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_tilt_back_high_left.jpg";
import lg_shoulder_tilt_front_tight_high_right from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_tilt_front_tight_high_right.jpg";
import lg_shoulder_tilt_back_high_right from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_tilt_back_high_right.jpg";

import lg_lateral_pelvic_tilt_front_high_left from "../../../../../assets/images/bodyAlignmentSummary/lg_lateral_pelvic_tilt_front_high_left.jpg";
import lg_lateral_pelvic_tilt_back_high_left from "../../../../../assets/images/bodyAlignmentSummary/lg_lateral_pelvic_tilt_back_high_left.jpg";
import lg_lateral_pelvic_tilt_front_tight_high_right from "../../../../../assets/images/bodyAlignmentSummary/lg_lateral_pelvic_tilt_front_tight_high_right.jpg";
import lg_lateral_pelvic_tilt_back_high_right from "../../../../../assets/images/bodyAlignmentSummary/lg_lateral_pelvic_tilt_back_high_right.jpg";

import lg_bow_leg_front from "../../../../../assets/images/bodyAlignmentSummary/lg_bow_leg_front.jpg";
import lg_bow_leg_back from "../../../../../assets/images/bodyAlignmentSummary/lg_bow_leg_back.jpg";
import lg_knock_knee_front from "../../../../../assets/images/bodyAlignmentSummary/lg_knock_knee_front.jpg";
import lg_knock_knee_back from "../../../../../assets/images/bodyAlignmentSummary/lg_knock_knee_back.jpg";

import lg_head_shift_anterior_front from "../../../../../assets/images/bodyAlignmentSummary/lg_head_shift_anterior_front.jpg";
import lg_head_shift_anterior_back from "../../../../../assets/images/bodyAlignmentSummary/lg_head_shift_anterior_back.jpg";
import lg_head_shift_posterior_front from "../../../../../assets/images/bodyAlignmentSummary/lg_head_shift_posterior_front.png";
import lg_head_shift_posterior_back from "../../../../../assets/images/bodyAlignmentSummary/lg_head_shift_posterior_back.jpg";

import lg_shoulder_shift_anterior_front from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_shift_anterior_front.jpg";
import lg_shoulder_shift_anterior_back from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_shift_anterior_back.jpg";
import lg_shoulder_shift_posterior_front from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_shift_posterior_front.jpg";
import lg_shoulder_shift_posterior_back from "../../../../../assets/images/bodyAlignmentSummary/lg_shoulder_shift_posterior_back.jpg";

import lg_pelvic_shift_anterior_front from "../../../../../assets/images/bodyAlignmentSummary/lg_pelvic_shift_anterior_front.jpg";
import lg_pelvic_shift_anterior_back from "../../../../../assets/images/bodyAlignmentSummary/lg_pelvic_shift_anterior_back.jpg";
import lg_pelvic_shift_posterior_front from "../../../../../assets/images/bodyAlignmentSummary/lg_pelvic_shift_posterior_front.jpg";
import lg_pelvic_shift_posterior_back from "../../../../../assets/images/bodyAlignmentSummary/lg_pelvic_shift_posterior_back.jpg";

import lg_knee_flexion_front from "../../../../../assets/images/bodyAlignmentSummary/lg_knee_flexion_front.jpg";
import lg_knee_flexion_back from "../../../../../assets/images/bodyAlignmentSummary/lg_knee_flexion_back.jpg";
import lg_knee_extension_front from "../../../../../assets/images/bodyAlignmentSummary/lg_knee_extension_front.jpg";
import lg_knee_extension_back from "../../../../../assets/images/bodyAlignmentSummary/lg_knee_extension_back.jpg";

import neck_01_01 from "../../../../../assets/images/cesWorkoutThumbnails/neck_01_01.png";
import leg_01_02 from "../../../../../assets/images/cesWorkoutThumbnails/leg_01_02.png";
import leg_01_03 from "../../../../../assets/images/cesWorkoutThumbnails/leg_01_03.png";
import knee_01_04 from "../../../../../assets/images/cesWorkoutThumbnails/knee_01_04.png";
import pelvic_01_05 from "../../../../../assets/images/cesWorkoutThumbnails/pelvic_01_05.png";
import leg_01_06 from "../../../../../assets/images/cesWorkoutThumbnails/leg_01_06.png";
import shoulder_01_07 from "../../../../../assets/images/cesWorkoutThumbnails/shoulder_01_07.png";
import leg_02_08 from "../../../../../assets/images/cesWorkoutThumbnails/leg_02_08.png";
import leg_03_09 from "../../../../../assets/images/cesWorkoutThumbnails/leg_03_09.png";
import shoulder_02_10 from "../../../../../assets/images/cesWorkoutThumbnails/shoulder_02_10.png";
import leg_04_11 from "../../../../../assets/images/cesWorkoutThumbnails/leg_04_11.png";
import neck_02_12 from "../../../../../assets/images/cesWorkoutThumbnails/neck_02_12.png";
import shoulder_03_13 from "../../../../../assets/images/cesWorkoutThumbnails/shoulder_03_13.png";
import shoulder_04_14 from "../../../../../assets/images/cesWorkoutThumbnails/shoulder_04_14.png";
import pelvic_02_15 from "../../../../../assets/images/cesWorkoutThumbnails/pelvic_02_15.png";
import pelvic_03_16 from "../../../../../assets/images/cesWorkoutThumbnails/pelvic_03_16.png";

export const FEEDBACK_TYPE = {
  OVERALL_FEEDBACK: "OVERALL_FEEDBACK",
  LIFE_STYLE: "LIFE_STYLE",
  CES_LIFE_STYLE: "CES_LIFE_STYLE",
  CONDITION: "CONDITION",
  MUSCLE_MANAGEMENT: "MUSCLE_MANAGEMENT",
  OVERALL_MUSCLE_MANAGEMENT: "OVERALL_MUSCLE_MANAGEMENT",
  SPECIFY_STATUS: "SPECIFY_STATUS",
  DISEASE: "DISEASE", // 관련 질환
  EXERCISE: "EXERCISE", // 운동
  TIGHT: "TIGHT", // 단축 근육
  ELONGATED: "ELONGATED", // 신장 근육
  STABILIZING: "STABILIZING", // 안정화 근육
};

export const getCesShowOVerallGuide = () => {
  return {
    [MENUTYPE.NECK_TILT]: [
      $L(
        "Neck lateral flexion muscles on the tilted side is shortened, and there is possibility of muscle stretching on the other side. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
      ),
      $L(
        "Neck is slightly tilted to one side and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
      ),
    ],
    [MENUTYPE.SHOULDER_TILT]: [
      "Shoulder is slightly tilted to one side and imbalance of muscles around the shoulder is estimated. When left and right imbalance of the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint.",
    ],
    [MENUTYPE.PELVIC_TILT]: [
      $L(
        "Pelvis is slightly tilted to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
      ),
      $L(
        "When the levator muscle is shortened on one side of the pelvis, there is possibility of the muscle on the other side being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
      ),
    ],
    [MENUTYPE.PELVIC_ROTATION]: [
      $L(
        "Pelvis is slightly rotated to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
      ),
    ],
    [MENUTYPE.LEG_ALIGNMENT]: {
      [MENUTYPE_TEST.BOW_LEG]: [
        $L(
          "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
      ],
      [MENUTYPE_TEST.KNOCK_KNEE]: [
        $L(
          "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
      ],
    },
    [MENUTYPE.HEAD_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: [
        $L(
          "Head is slightly shifted to the front and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "SCM (Sternocleidomastoid) and suboccpital muscle are shortened on both sides, and there is possibility of the deep neck flexor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
      ],
      [MENUTYPE_TEST.POSTERIOR]: [
        $L(
          "Head is slightly shifted to the back and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "Erector spinae on the cervical spine is shortened, and there is possibility of the suboccpital muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
      ],
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: [
        $L(
          "The shoulder is shifted slightly to the front, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "Pectoralis major and pectoralis minor are shortened, and there is possibility of the rhomboid and lower trapezius being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
      ],
      [MENUTYPE_TEST.POSTERIOR]: [
        $L(
          "The shoulder is shifted slightly to the back, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "Erector spinae and rhomboid are shortened, and there is possibility of the serratus anterior being weakened. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
      ],
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: [
        $L(
          "Pelvis is slightly tilted to the front and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "Pelvis is tilted to the front to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Iliopsoas or erector spinae may be shortened due to increase in the anterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
        ),
        $L(
          "Pectoralis major and erector spinae are shortened, and there is possibility of the Rectus Abdominis and hamstring being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Hyperlordosis can be caused on the back when the anterior pelvic shift condition is continued."
        ),
      ],
      [MENUTYPE_TEST.POSTERIOR]: [
        $L(
          "Pelvis is slightly tilted to the back and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "Pelvis is tilted to the back to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Rectus Abdominis or hamstring may be shortened due to increase in the posterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
        ),
        $L(
          "Hamstring and Rectus Abdominis are shortened, and there is possibility of the Iliopsoas and erector spinae being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Kyphosiscan be caused when the posterior pelvic shift condition is continued."
        ),
      ],
    },
    [MENUTYPE.KNEE_FLEXION]: {
      [MENUTYPE_TEST.ANTERIOR]: [
        $L(
          "Knee is slightly bent from the normal alignment to influence the walking posture. Straighten the knee alignment to correct the alignment through stabilization exercise."
        ),
        $L(
          "Knee in a slightly bent condition can have influence to normal walking. Straighten the knee alignment to correct the alignment through stabilization exercise."
        ),
        $L(
          "Hamstring is shortened, and there is possibility of the Quadriceps Femoris being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "The body can tilt forward when the knees are bent, so please maintain a proper spinal alignment."
        ),
        $L(
          "The position of the knee is slightly off the baseline, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be strained."
        ),
        $L(
          "When the knee flexion condition is continued, it can cause pain. Restore the healthy knee through consistent exercise."
        ),
      ],
      [MENUTYPE_TEST.POSTERIOR]: [
        $L(
          "Misalignment of the knee can strain the adjacent knee. Please set the alignment through muscular strength exercise and stretching."
        ),
        $L(
          "When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be stressed."
        ),
        $L(
          "When the knee is slightly out of alignment to continue this condition, degenerative change or pain can be caused, so consistent management is required."
        ),
        $L(
          "Management is recommended for stretching on the shortened muscles around the knee, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Activate the knee stabilization muscles to increase the stability of the knee."
        ),
        $L(
          "Knee stabilization exercise can help you maintain healthy knees. Consistent management is required."
        ),
        $L(
          "Do you normally wear shoes with high heels. When the knee is shifted back, it can also influence the adjacent joint."
        ),
        $L(
          "When standing for a long time, how about bending your knees slightly? It will reduce the load on the knee."
        ),
        $L(
          "Do you have the habit of sticking out your belly excessively? Moving your body weight to the front can strain the knee."
        ),
      ],
    },
  };
};

export const getFeedbackGuide = () => {
  return {
    [MENUTYPE.NECK_TILT]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L(
          "For consistent management, avoid the habit of resting the chin to one side in everyday life."
        ),
        $L(
          "For consistent management, maintain the proper neck alignment in everyday life, and sit on a chair in a proper posture."
        ),
        $L(
          "For consistent management, perform stretching every 30 minutes to prevent excessive tension on the neck in everyday life."
        ),
      ],
      [FEEDBACK_TYPE.CONDITION]: [
        $L("If prolonged, it can lead to facial asymmetry."),
        $L(
          "Knee joint dysfunction can occur due to difference in the left and right neck tilt."
        ),
        $L(
          "There is possibility of developing into a herniated cervical disc when difference in the left and right neck tilt is continued."
        ),
        $L(
          "There is possibility of accompanying thoracic outlet syndrome when difference in the left and right neck tilt is serious."
        ),
        $L(
          "When having severe muscle imbalance due to difference in the left and right neck tilt, tension headache and migraine can occur."
        ),
        $L(
          "There is possibility of torticollis when the difference in the left and right neck tilt is serious, so please consult with the specialist."
        ),
        $L(
          "It can accompany myofascial pain syndrome due to difference in the left and right neck tilt."
        ),
      ],
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: [
        $L(
          "Neck lateral flexion muscles on the tilted side is shortened, and there is possibility of muscle stretching on the other side. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Neck is slightly tilted to one side and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
      ],
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: [
        $L(
          "Neck lateral flexion muscles on the tilted side is shortened, and there is possibility of muscle stretching on the other side. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Neck is slightly tilted to one side and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
      ],
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [SCORE_MINT_KEY]: [
          $L(
            "Both ears are located on the horizontal line, so the tilt of the neck is in a proper condition."
          ),
          $L(
            "Neck lateral flexion muscles on both sides are showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
          ),
          $L("Left and right neck tilt items are within the normal range."),
          $L(
            "Both ears are located within the normal range to have the head in a proper position."
          ),
          $L(
            "Left and right balance of the neck is in a proper condition. Please maintain the current condition through consistent management."
          ),
          $L(
            "Muscles around the neck are relatively showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L(
            "One ear is located slightly lower than the other ear, so the neck is tilted slightly to one side."
          ),
          $L(
            "Both ears are located slightly off the horizontal line to have the neck tilted."
          ),
          $L("Slight issue is observed in the left and right neck tilt items."),
          $L("Minor issue is observed in the left and right neck tilt items."),
          $L("Position of the head is slightly tilted."),
          $L("One year is slightly closer to the ipsilateral shoulder."),
        ],
        [SCORE_RED_KEY]: [
          $L(
            "One ear is located lower than the other ear, so the neck is tilted slightly to one side considerably."
          ),
          $L("Position of the head is tilted considerably."),
          $L(
            "Moderate issue is observed in the left and right neck tilt items."
          ),
          $L(
            "Issue in the risk level is observed in the left and right neck tilt items."
          ),
          $L(
            "Head alignment is inappropriate to require consistent management and improvement of lifestyle."
          ),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [SCORE_MINT_KEY]: [
          $L(
            "Avoid the habit of resting the chin to one side in everyday life."
          ),
          $L(
            "Maintain the proper neck alignment in everyday life, and sit on a chair in a proper posture."
          ),
          $L(
            "Perform stretching every 30 minutes to prevent excessive tension on the neck in everyday life."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L(
            "Neck lateral flexion muscles on the tilted side is shortened, and there is possibility of muscle stretching on the other side. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Neck is slightly tilted to one side and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
        [SCORE_RED_KEY]: [
          $L(
            "Neck lateral flexion muscles on the tilted side is shortened, and there is possibility of muscle stretching on the other side. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Neck is slightly tilted to one side and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
      },
    },
    [MENUTYPE.SHOULDER_TILT]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L(
          "For consistent management, bag must be carried in turns on the left and right shoulder to disperse the weight."
        ),
        $L(
          "For consistent management, please take caution on not carrying the weight only on one shoulder."
        ),
      ],
      [FEEDBACK_TYPE.CONDITION]: [
        $L(
          "There is possibility of accompanying scoliosis when difference in the left and right shoulder tilt is serious."
        ),
        $L(
          "When the difference in the left and right shoulder tilt is serious, myofascial pain syndrome can occur from imbalance of the muscles around the shoulder."
        ),
        $L(
          "There is possibility of accompanying thoracic outlet syndrome when difference in the left and right shoulder tilt is serious."
        ),
      ],
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: [
        $L(
          "Height of left and right shoulder can be different due to the difference in the leg length."
        ),
        $L(
          "When the levator muscle is shortened on one shoulder, there is possibility of the depressor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Shoulder is slightly tilted to one side and imbalance of muscles around the shoulder is estimated. When left and right imbalance of the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
      ],
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: [
        $L(
          "When the levator muscle is shortened on one shoulder, there is possibility of the depressor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
        $L(
          "Shoulder is slightly tilted to one side and imbalance of muscles around the shoulder is estimated. When left and right imbalance of the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
      ],
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [SCORE_MINT_KEY]: [
          $L(
            "Both sides of the shoulder are not off the horizontal line greatly, so the shoulder tilt is in a proper condition."
          ),
          $L(
            "Alignment on both sides of the shoulder is in a proper condition."
          ),
          $L("Height on both side of the shoulder are in balance."),
          $L("Both sides of the shoulder are in symmetry."),
          $L(
            "Muscles around both sides of the shoulder are relatively showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L(
            "One shoulder is located slightly lower than the other shoulder, so the shoulder is tilted slightly to one side."
          ),
          $L("One shoulder is slightly lower than the other shoulder."),
          $L("Slight asymmetry is observed on both sides of the shoulder."),
          $L(
            "Alignment on both sides of the shoulder is showing minor imbalance."
          ),
          $L(
            "Minor issue is observed in the left and right shoulder tilt items."
          ),
          $L(
            "Slight issue is observed in the left and right shoulder tilt items."
          ),
        ],
        [SCORE_RED_KEY]: [
          $L(
            "One shoulder is located lower than the other shoulder, so the shoulder is tilted considerably to one side."
          ),
          $L(
            "Considerable asymmetry is observed on both sides of the shoulder."
          ),
          $L("One shoulder is considerably lower than the other shoulder."),
          $L("Distinct asymmetry is observed on both sides of the shoulder."),
          $L("Alignment on both sides of the shoulder is showing imbalance."),
          $L(
            "Issue of risk level is observed in the left and right shoulder tilt items."
          ),
          $L(
            "Moderate issue is observed in the left and right shoulder tilt items."
          ),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [SCORE_MINT_KEY]: [
          $L(
            "Carrying a bag to one side can have influence on left and right shoulder imbalance. To disperse the weight, bag must be carried in turns on the left and right shoulder."
          ),
          $L(
            "Please take caution on not carrying the weight only on one shoulder."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L(
            "When the levator muscle is shortened on one shoulder, there is possibility of the depressor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Shoulder is slightly tilted to one side and imbalance of muscles around the shoulder is estimated. When left and right imbalance of the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
        [SCORE_RED_KEY]: [
          $L(
            "When the levator muscle is shortened on one shoulder, there is possibility of the depressor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Shoulder is slightly tilted to one side and imbalance of muscles around the shoulder is estimated. When left and right imbalance of the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
      },
    },
    [MENUTYPE.PELVIC_TILT]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L(
          "For consistent management, legs should not be crossed when sitting, and proper sitting posture is required."
        ),
        $L(
          "For consistent management, please take caution on not concentrating the body weight only on one leg when standing in everyday life."
        ),
        $L(
          "For consistent management, leaning on one leg in everyday life can have influence on pelvic imbalance, so balancing the body weight while standing is recommended."
        ),
        $L(
          "For consistent management, leaning to one side on the desk or sitting in a tilted posture must be avoided normally."
        ),
        $L(
          "For consistent management, it is recommended to balance the body weight on both sides of the leg when walking."
        ),
      ],
      [FEEDBACK_TYPE.CONDITION]: [
        $L(
          "When having serious difference in left and right sides of the pelvis, there is possibility of accompanying scoliosis."
        ),
        $L(
          "When the difference in left and right sides of the pelvis is continued for long period, it can cause lumbar sprain and herniated lumbar disc."
        ),
        $L(
          "When having serious difference on left and right sides of the pelvis, there is possibility of accompanying Sacroiliac Joint Syndrome."
        ),
        $L(
          "When the difference in the left and right sides of the pelvis is serious, piriformis syndrome can occur from imbalance of the muscles around the shoulder."
        ),
      ],
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: [
        $L(
          "There can be imbalance in both sides of the pelvis due to the difference in leg length."
        ),
        $L(
          "Pelvis is slightly tilted to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "When the levator muscle is shortened on one side of the pelvis, there is possibility of the muscle on the other side being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
      ],
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: [
        $L(
          "Pelvis is slightly tilted to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "When the levator muscle is shortened on one side of the pelvis, there is possibility of the muscle on the other side being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
        ),
      ],
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [SCORE_MINT_KEY]: [
          $L(
            "Both sides of the pelvis are not off the horizontal line greatly, so the left and right pelvic tilt is in a proper condition."
          ),
          $L("Both sides of the pelvis are located in the horizontal line."),
          $L("Pelvic tilt is within the normal range."),
          $L("Both sides of the pelvis are located within the normal range."),
          $L(
            "Both sides of the pelvis are located within the normal range in the baseline."
          ),
          $L("Height of the left and right pelvis is in symmetry."),
          $L("Alignment on both sides of the pelvis is in symmetry."),
          $L(
            "Muscles around both sides of the pelvis are showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L(
            "One side of the pelvis is located slightly lower than the other side of the pelvis, so the pelvis is tilted slightly to one side."
          ),
          $L("Height of the left and right pelvis is in slight asymmetry."),
          $L(
            "Height of the left and right pelvis is slightly off the horizontal line."
          ),
          $L("Opinion on minor issue is observed in the pelvic tilt items."),
          $L("Slight issue is observed in the pelvic tilt items."),
          $L(
            "Alignment on both sides of the pelvis is showing slight imbalance."
          ),
          $L(
            "One side of the pelvis is slightly lower than the other side of the pelvis."
          ),
        ],
        [SCORE_RED_KEY]: [
          $L(
            "One side of the pelvis is located lower than the other side of the pelvis, so considerable pelvic tilt is shown."
          ),
          $L(
            "Height of the left and right pelvis is in considerable asymmetry."
          ),
          $L("Height of the left and right pelvis is off the horizontal line."),
          $L(
            "One side of the pelvis is lower than the other side of the pelvis."
          ),
          $L("Alignment on both sides of the pelvis is showing imbalance."),
          $L(
            "Opinion on issue of risk level is observed in the pelvic tilt items."
          ),
          $L("Moderate issue is observed in the pelvic tilt items."),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [SCORE_MINT_KEY]: [
          $L(
            "Legs should not be crossed when sitting, and proper sitting posture is required."
          ),
          $L(
            "In everyday life, please take caution on not concentrating the body weight only on one leg when standing."
          ),
          $L(
            "Leaning on one leg in everyday life can have influence on pelvic imbalance, so balancing the body weight while standing is recommended."
          ),
          $L(
            "Leaning to one side on the desk or sitting in a tilted posture must be avoided normally."
          ),
          $L(
            "When walking, it is recommended to balance the body weight on both sides of the leg."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L(
            "Pelvis is slightly tilted to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "When the levator muscle is shortened on one side of the pelvis, there is possibility of the muscle on the other side being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
        ],
        [SCORE_RED_KEY]: [
          $L(
            "Pelvis is slightly tilted to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "When the levator muscle is shortened on one side of the pelvis, there is possibility of the muscle on the other side being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
        ],
      },
    },
    [MENUTYPE.PELVIC_ROTATION]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L(
          "For consistent management, do not cross your legs, and maintain a proper sitting posture in everyday life."
        ),
        $L(
          "For consistent management, please take caution on not concentrating the body weight only on one leg when standing normally."
        ),
        $L(
          "For consistent management, leaning to one side on the desk or sitting in a tilted posture must be avoided normally."
        ),
        $L(
          "For consistent management, it is recommended to balance the body weight on both sides of the leg when walking."
        ),
        $L(
          "For consistent management, sitting with your legs raised to one side must be avoided in everyday life."
        ),
        $L(
          "For consistent management, it is recommended to sit on a chair instead of sitting on the floor in everyday life."
        ),
        $L(
          "For consistent management, holding a heavy object to rotate the body to one side must be avoided in everyday life."
        ),
        $L(
          "For consistent management, please perform stretching to relax the muscles after repeating the work on rotating the body."
        ),
        $L(
          "For consistent management, exercises including the movement of rotating the body to one side (Ex.: Golf, tennis, etc.) must be avoided as possible when there is pain."
        ),
      ],
      [FEEDBACK_TYPE.CONDITION]: [
        $L(
          "When having serious difference in left and right sides of the pelvis, there is possibility of accompanying scoliosis."
        ),
        $L(
          "When the difference in left and right sides of the pelvis is continued for long period, it can cause lumbar sprain and herniated lumbar disc."
        ),
        $L(
          "When having serious difference on left and right sides of the pelvis, there is possibility of accompanying Hip Joint Impingement Syndrome."
        ),
        $L(
          "When the difference in the left and right sides of the pelvis is serious, piriformis syndrome can occur from imbalance of the muscles around the shoulder."
        ),
        $L(
          "When the difference in left and right sides of the pelvis is continued for long period, the risk of occurring with arthritis can be increased."
        ),
        $L(
          "When the difference in left and right sides of the pelvis is serious, you must consult with the specialist when having pain on the surrounding parts."
        ),
      ],
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: [
        $L(
          "Pelvis is slightly rotated to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
        $L(
          "After proper alignment of the pelvis, activate the muscles around the pelvis through stabilization exercise."
        ),
        $L(
          "After proper alignment of the pelvis, enable balance of the muscles around the pelvis through stabilization exercise."
        ),
      ],
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: [
        $L(
          "Pelvis is slightly rotated to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
        ),
      ],
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [SCORE_MINT_KEY]: [
          $L(
            "The navel is located within the mid-range of the left and right sides of the pelvis."
          ),
          $L("Pelvic alignment is in symmetry."),
          $L("The left and right sides of the pelvis are showing balance."),
          $L("Left and right sides of the pelvis are in neutral."),
          $L("Left and right sides of the pelvis are located properly."),
          $L(
            "Muscles around the pelvis are showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L("Pelvis is turned slightly to one side."),
          $L("There is minor tilt to one side of the pelvis."),
          $L(
            "One side of the pelvis is somewhat tilted forward compared to the other side of the pelvis."
          ),
          $L("Pelvis is slightly rotated."),
          $L("Minor issue is observed in the pelvic rotation items."),
          $L("Slight issue is observed in the pelvic rotation tilt items."),
        ],
        [SCORE_RED_KEY]: [
          $L("Pelvis is turned considerably to one side."),
          $L("Pelvis is tilted to one side."),
          $L(
            "One side of the pelvis is significantly tilted forward compared to the other side of the pelvis."
          ),
          $L("Pelvis is rotated."),
          $L(
            "Opinion on issue of risk level is observed in the anterior and posterior pelvic tilt items."
          ),
          $L(
            "Moderate issue is observed in the anterior and posterior pelvic tilt items."
          ),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [SCORE_MINT_KEY]: [
          $L(
            "Do not cross your legs, and maintain a proper sitting posture in everyday life."
          ),
          $L({
            key: "_In everyday life, please take caution on not concentrating the body weight only on one leg when standing.",
            value:
              "In everyday life, please take caution on not concentrating the body weight only on one leg when standing.",
          }),
          $L({
            key: "_Leaning to one side on the desk or sitting in a tilted posture must be avoided normally.",
            value:
              "Leaning to one side on the desk or sitting in a tilted posture must be avoided normally.",
          }),
          $L(
            "When walking, it is recommended to balance the body weight on both sides of the leg."
          ),
          $L(
            "In everyday life, sitting with your legs raised to one side must be avoided."
          ),
          $L(
            "In everyday life, it is recommended to sit on a chair instead of sitting on the floor."
          ),
          $L(
            "Holding a heavy object to rotate the body to one side must be avoided in everyday life."
          ),
          $L(
            "After repeating the work on rotating the body, please perform stretching to relax the muscles."
          ),
          $L(
            "When there is pain, exercises including the movement of rotating the body to one side (Ex.: Golf, tennis, etc.) must be avoided as possible."
          ),
        ],
        [SCORE_YELLOW_KEY]: [
          $L(
            "Pelvis is slightly rotated to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
        [SCORE_RED_KEY]: [
          $L(
            "Pelvis is slightly rotated to one side and imbalance of muscles around the pelvis is estimated. When imbalance of the muscles around the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
      },
    },
    [MENUTYPE.LEG_ALIGNMENT]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: {
        // detailType === "out"
        [MENUTYPE_TEST.BOW_LEG]: [
          $L(
            "For consistent management, please avoid cross-legged posture as possible."
          ),
          $L(
            "For consistent management, sitting normally in a cross-legged posture should be avoided as possible as it can have influence to the knee joint."
          ),
          $L(
            "For consistent management, sedentary lifestyle can give stress to the tissues surrounding the knee, so maintaining the cross-legged posture should be avoided."
          ),
          $L(
            "For consistent management, it is recommended to sit on a chair or on a sofa as possible instead of sitting on the floor."
          ),
          $L(
            "For consistent management, try not to sit cross-legged as possible."
          ),
          $L(
            "When leg deformation is continued even after the adolescent period, consultation with the specialist may be required."
          ),
        ],
        [MENUTYPE_TEST.KNOCK_KNEE]: [
          $L(
            "For consistent management, please take caution on not sitting in W-shaped leg posture as possible."
          ),
          $L(
            "For consistent management, sitting in W-shaped leg posture should be avoided as possible as it can have influence to the knee joint."
          ),
          $L(
            "For consistent management, sedentary lifestyle can give stress to the tissues surrounding the knee, so sitting on a chair or on a sofa instead of sitting on the floor is recommended."
          ),
          $L(
            "For consistent management, try not to sit cross-legged as possible, and please sit in a proper posture."
          ),
          $L(
            "For consistent management, please check whether walking on knock knees."
          ),
        ],
      },
      [FEEDBACK_TYPE.CONDITION]: {
        [MENUTYPE_TEST.BOW_LEG]: [
          $L(
            "When the leg deformation is continued for long period, the risk of occurring with arthritis can be increased."
          ),
          $L(
            "As the leg deformation is continued for long period, the risk of damage on the meniscus can be increased."
          ),
          $L(
            "When the leg deformation is serious, there can be damage to the ligaments and muscles around the knee."
          ),
        ],
        [MENUTYPE_TEST.KNOCK_KNEE]: [
          $L(
            "When the leg deformation is continued for long period, the risk of occurring with arthritis can be increased."
          ),
          $L(
            "As the leg deformation is continued for long period, the risk of damage on the meniscus can be increased."
          ),
          $L(
            "When the leg deformation is serious, there can be damage to the ligaments and muscles around the knee."
          ),
          $L(
            "When the knock knee is continued for long period, the possibility of occurring with plantar fasciitis can be increased."
          ),
          $L(
            "When knock knee is serious, there is possibility of accompanying hallux valgus."
          ),
          $L(
            "When knock knee is serious, it must be checked whether accompanying flat foot."
          ),
        ],
      },
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.BOW_LEG]: [
          $L(
            "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "After proper leg alignment, enable balance of the surrounding muscles through strengthening exercise and stretching."
          ),
        ],
        [MENUTYPE_TEST.KNOCK_KNEE]: [
          $L(
            "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "After proper leg alignment, enable balance of the surrounding muscles through strengthening exercise and stretching."
          ),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.BOW_LEG]: [
          $L(
            "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
        [MENUTYPE_TEST.KNOCK_KNEE]: [
          $L(
            "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
        ],
      },
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [MENUTYPE_TEST.BOW_LEG]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Angle of the thigh and calf is located within the normal range in the baseline."
            ),
            $L("Leg alignment is in a proper condition."),
            $L(
              "Angles is located within the normal range in the leg alignment item."
            ),
            $L(
              "Distance between the knees is within the normal range to have the leg alignment located properly."
            ),
            $L(
              "Muscles around the leg are showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Angle of the thigh and calf is outside the normal range, and correction of leg alignment is recommended."
            ),
            $L("Minor leg deformation is shown."),
            $L("Leg alignment is somewhat off the baseline."),
            $L("Leg alignment is slightly off the normal standard."),
            $L("Leg alignment is slightly curved outward."),
            $L("Leg is somewhat curved to recommend consistent management."),
            $L("Minor issue is observed in the leg alignment items."),
            $L("Slight issue is observed in the leg alignment items."),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Angle of the thigh and calf is considerably larger than the normal range."
            ),
            $L("Leg alignment is considerably off the normal standard."),
            $L("Leg deformation is shown."),
            $L("Leg alignment is definitely off the baseline."),
            $L("Leg alignment is curved outward."),
            $L("Leg is curved to recommend consistent management."),
            $L(
              "Opinion on issue of risk level is observed in the leg alignment items."
            ),
            $L("Moderate issue is observed in the leg alignment items."),
          ],
        },
        [MENUTYPE_TEST.KNOCK_KNEE]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Angle of the thigh and calf is located within the normal range in the baseline."
            ),
            $L("Leg alignment is in a proper condition."),
            $L(
              "Angles is located within the normal range in the leg alignment item."
            ),
            $L(
              "Distance between the knees is within the normal range to have the leg alignment located properly."
            ),
            $L(
              "Muscles around the leg are showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Angle of the thigh and calf is outside the normal range, and correction of leg alignment is recommended."
            ),
            $L("Minor leg deformation is shown."),
            $L("Leg alignment is somewhat off the baseline."),
            $L("Leg alignment is slightly off the normal standard."),
            $L("Leg alignment is slightly curved inward."),
            $L("Leg is somewhat curved to recommend consistent management."),
            $L("Minor issue is observed in the leg alignment items."),
            $L("Slight issue is observed in the leg alignment items."),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Angle of the thigh and calf is considerably outside the normal range."
            ),
            $L("Leg alignment is considerably off the normal standard."),
            $L("Leg deformation is shown."),
            $L("Leg alignment is definitely off the baseline."),
            $L("Leg alignment is curved inward."),
            $L("Leg is curved to recommend consistent management."),
            $L(
              "Opinion on issue of risk level is observed in the leg alignment items."
            ),
            $L("Moderate issue is observed in the leg alignment items."),
          ],
        },
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [MENUTYPE_TEST.BOW_LEG]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Sitting normally in a cross-legged posture should be avoided as possible as it can have influence to the knee joint."
            ),
            $L(
              "Sedentary lifestyle can give stress to the tissues surrounding the knee, so maintaining the cross-legged posture should be avoided."
            ),
            $L(
              "If possible, it is recommended to sit on a chair or on a sofa instead of sitting on the floor."
            ),
            $L("When sitting, try not to sit cross-legged as possible."),
            $L(
              "When leg deformation is continued even after the adolescent period, consultation with the specialist may be required."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
          ],
        },
        [MENUTYPE_TEST.KNOCK_KNEE]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Sitting in W-shaped leg posture should be avoided as possible as it can have influence to the knee joint."
            ),
            $L(
              "Sedentary lifestyle can give stress to the tissues surrounding the knee, so sitting on a chair or on a sofa instead of sitting on the floor is recommended."
            ),
            $L(
              "When sitting, try not to sit cross-legged as possible, and please sit in a proper posture."
            ),
            $L("When walking, please check whether walking on knock knees."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Knee alignment is slightly tilted, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
          ],
        },
      },
    },
    [MENUTYPE.HEAD_SHIFT]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: {
        // detailType === "front"
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "When working in front of a computer monitor lower than the eye level for a long time, the head can shift forward. For consistent management, the computer monitor must be adjusted according to the eye level."
          ),
          $L(
            "Anterior head shift can occur when watching a monitor or TV for a long time in a posture of shifting the head forward. For consistent management, please check whether the head is not shifted forward normally."
          ),
          $L(
            "Anterior head shift may be caused from looking down on a smartphone or a book. For consistent management, please adjust according to the eye level when looking at a smartphone or a book."
          ),
          $L(
            "Anterior head shift may be caused from playing games for a long time in an inappropriate posture. For consistent management, maintain the proper posture."
          ),
          $L(
            "Anterior head shift may be caused from driving for a long time with the head shifted forward. Maintain a proper posture while driving."
          ),
          $L(
            "When working for a long time with your head facing downward, anterior head shift may occur. Maintain a proper posture while working."
          ),
          $L(
            "When reading a newspaper or a book on the floor, or when using the mobile phone with your head facing downward, anterior head shift may occur. For consistent management, maintain a proper posture in everyday life."
          ),
          $L(
            "For consistent management, perform stretching every 30 minutes normally to prevent excessive tension on the neck."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "There is possibility of occurring with posterior head shift due to inappropriate pillow height. For consistent management, the height must be adjusted when the pillow height is inappropriate. Please check whether the pillow height is appropriate."
          ),
          $L(
            "Posterior head shift may occur when the stand at attention such as a military posture is maintained for long time. For consistent management, the chin must not be pulled excessively, and head posture must be maintained properly."
          ),
          $L(
            "For consistent management, perform stretching every 30 minutes normally to prevent excessive tension on the neck."
          ),
        ],
      },
      [FEEDBACK_TYPE.CONDITION]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "When head anterior shift is continued for long period, Forward Head Posture can be caused."
          ),
          $L(
            "When the head anterior shift is serious, there is possibility of progressing to Cervical Syndrome, so please consult with the specialist."
          ),
          $L(
            "There can be imbalance of muscles around the neck due to the head anterior shift."
          ),
          $L(
            "Imbalance of muscles around the head can occur due to head anterior shift to accompany myofascial pain syndrome."
          ),
          $L(
            "When head anterior shift is continued for long period, there is possibility of developing into herniated cervical disc."
          ),
          $L(
            "When head anterior shift is serious, there is possibility of accompanying thoracic outlet syndrome."
          ),
          $L(
            "When having severe muscle imbalance due to head anterior shift, tension headache and migraine can occur."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "When the head posterior shift is continued for long period, it can cause pain on the jaw joint (temporomandibular jointa) and disorder."
          ),
          $L(
            "When the head posterior shift is serious, there is possibility of accompanying forward head posture"
          ),
          $L(
            "When the head posterior shift is serious or continued for long period, there is possibility of progressing to Cervical Syndrome, so please consult with the specialist."
          ),
          $L(
            "Imbalance of muscles around the head can occur due to head posterior shift to accompany myofascial pain syndrome."
          ),
          $L(
            "When head posterior shift is continued for long period, herniated cervical disc can be caused."
          ),
          $L(
            "When having severe muscle imbalance due to head posterior shift, tension headache and migraine can occur."
          ),
        ],
      },
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "Head is slightly shifted to the front and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "SCM (Sternocleidomastoid) and suboccpital muscle are shortened on both sides, and there is possibility of the deep neck flexor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "There can be tension partially on the muscles around the neck to require proper management."
          ),
          $L("Adjust the neck alignment to perform stabilization exercise."),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "Head is slightly shifted to the back and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Erector spinae on the cervical spine is shortened, and there is possibility of the suboccpital muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L("Adjust the neck alignment to perform stabilization exercise."),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "Head is slightly shifted to the front and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "SCM (Sternocleidomastoid) and suboccpital muscle are shortened on both sides, and there is possibility of the deep neck flexor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "Head is slightly shifted to the back and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Erector spinae on the cervical spine is shortened, and there is possibility of the suboccpital muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
        ],
      },
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Anterior and posterior neck alignment is maintained within the normal range."
            ),
            $L("Angle of the neck and shoulder is within the normal range."),
            $L(
              "Anterior and posterior neck balance is in a proper condition. Please maintain the current condition through consistent management."
            ),
            $L(
              "Muscles around the neck are relatively showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
            ),
            $L(
              "Current condition must be maintained normally through exercise that helps consistent prevention."
            ),
            $L("Neck alignment is maintained properly."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Alignment of the neck and shoulder is slightly outside the normal range."
            ),
            $L(
              "Opinion of minor issue is shown on the anterior and posterior neck alignment."
            ),
            $L("Position of the head is tilted slightly to the front."),
            $L("There is minor anterior tilt on the position of the head."),
            $L("Neck is slightly tilted to the front from the central axis."),
            $L("Minor Forward Head Posture is suspected."),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Anterior and posterior neck alignment is considerably outside the normal range."
            ),
            $L(
              "Neck alignment is inappropriate to require consistent management and improvement of lifestyle."
            ),
            $L("Position of the head is tilted considerably to the front."),
            $L("There is definite anterior tilt on the position of the head."),
            $L("Forward Head Posture is suspected."),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Anterior and posterior neck alignment is maintained within the normal range."
            ),
            $L("Angle of the neck and shoulder is within the normal range."),
            $L(
              "Anterior and posterior neck balance is in a proper condition. Please maintain the current condition through consistent management."
            ),
            $L(
              "Muscles around the neck are relatively showing good balance. Please maintain the current condition through proper stretching and strengthening exercise."
            ),
            $L(
              "Current condition must be maintained normally through exercise that helps consistent prevention."
            ),
            $L("Neck alignment is maintained properly."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Alignment of the neck and shoulder is slightly outside the normal range."
            ),
            $L(
              "Opinion of minor issue is shown on the anterior and posterior neck alignment."
            ),
            $L("Position of the head is tilted slightly to the back."),
            $L("There is minor posterior tilt on the position of the head."),
            $L("Neck is slightly tilted to the back from the central axis."),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Anterior and posterior neck alignment is considerably outside the normal range."
            ),
            $L(
              "Neck alignment is inappropriate to require consistent management and improvement of lifestyle."
            ),
            $L("Position of the head is tilted considerably to the back."),
            $L("There is definite posterior tilt on the position of the head."),
          ],
        },
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "When working in front of a computer monitor lower than the eye level for a long time, the head can shift forward. The computer monitor must be adjusted according to the eye level."
            ),
            $L(
              "Anterior head shift can occur when watching a monitor or TV for a long time in a posture of shifting the head forward. Please check whether the head is not shifted forward normally."
            ),
            $L(
              "Anterior head shift may be caused from looking down on a smartphone or a book. Please adjust according to the eye level when looking at a smartphone or a book."
            ),
            $L(
              "Anterior head shift may be caused from playing games for a long time in an inappropriate posture. Maintain the proper posture."
            ),
            $L(
              "Anterior head shift may be caused from driving for a long time with the head shifted forward. Maintain a proper posture while driving."
            ),
            $L(
              "When working for a long time with your head facing downward, anterior head shift may occur. Maintain a proper posture while working."
            ),
            $L(
              "When reading a newspaper or a book on the floor, or when using the mobile phone with your head facing downward, anterior head shift may occur. Maintain a proper posture in everyday life."
            ),
            $L({
              key: "_Perform stretching every 30 minutes to prevent excessive tension on the neck in everyday life.",
              value:
                "Perform stretching every 30 minutes to prevent excessive tension on the neck in everyday life.",
            }),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Head is slightly shifted to the front and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "SCM (Sternocleidomastoid) and suboccpital muscle are shortened on both sides, and there is possibility of the deep neck flexor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Head is slightly shifted to the front and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "SCM (Sternocleidomastoid) and suboccpital muscle are shortened on both sides, and there is possibility of the deep neck flexor muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "There is possibility of occurring with posterior head shift due to inappropriate pillow height. When the pillow height is inappropriate, the height must be adjusted. Please check whether the pillow height is appropriate."
            ),
            $L(
              "Posterior head shift may occur when the stand at attention such as a military posture is maintained for long time. The chin must not be pulled excessively, and head posture must be maintained properly."
            ),
            $L({
              key: "_Perform stretching every 30 minutes to prevent excessive tension on the neck in everyday life.",
              value:
                "Perform stretching every 30 minutes to prevent excessive tension on the neck in everyday life.",
            }),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Head is slightly shifted to the back and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Erector spinae on the cervical spine is shortened, and there is possibility of the suboccpital muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Head is slightly shifted to the back and imbalance of muscles around the neck is estimated. When imbalance of the neck is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Erector spinae on the cervical spine is shortened, and there is possibility of the suboccpital muscle being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
        },
      },
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: {
        // detailType === "front"
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "For consistent management, high heels or elevator shoes can cause excessive anterior pelvic shift."
          ),
          $L(
            "For consistent management, excessive anterior pelvic shift can be caused on the abdominal obesity body type, and so maintaining the proper body weight is recommended."
          ),
          $L(
            "For consistent management, posture of stretching the back excessively can increase the anterior pelvic shift angle to require caution."
          ),
          $L(
            "To prevent the pelvis from tilting to the front in everyday life, please check whether the hip is pulled back to stretch the back excessively for long period."
          ),
          $L(
            "For consistent management, sitting with your back stretched excessively must be avoided in everyday life."
          ),
          $L(
            "For consistent management, please check whether you are normally maintaining a posture of stretching your back excessively."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "For consistent management, please stretch your back to sit in a proper posture when sitting on a chair or on a sofa normally."
          ),
          $L(
            "For consistent management, it is recommended to sit on a chair as possible instead of the floor or on a soft sofa."
          ),
          $L(
            "For consistent management, the habit of sitting on the edge for a long time can have possibility of posterior pelvic shift, so please sit in a proper posture."
          ),
          $L(
            "To prevent posterior pelvic shift, sitting in a slumped posture for long period must be avoided."
          ),
          $L(
            "For consistent management, caution is required on not bending the back excessively in everyday life."
          ),
          $L(
            "For consistent management, please sit normally in a proper posture instead of bending the back excessively."
          ),
          $L(
            "For consistent management, instead of maintaining one posture for long time, change the posture regularly to reduce the stress on the joint."
          ),
        ],
      },
      [FEEDBACK_TYPE.CONDITION]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "Hollow-back can be caused when the anterior pelvic shift condition is continued."
          ),
          $L(
            "When anterior pelvic tilt is continued, it can lead to herniated lumbar disc or stenosis."
          ),
          $L(
            "Myofascial pain syndrome can be accompanied from imbalance of surrounding structures due to anterior pelvic tilt."
          ),
          $L(
            "When anterior pelvic tilt is serious, there is possibility of occurring with lumbar strain."
          ),
          $L(
            "On prolonged anterior pelvic tilt, spondylolisthesis can be occurred."
          ),
          $L(
            "When the anterior pelvic tilt is continued for long period, Hip Joint Impingement Syndrome can be occurred."
          ),
          $L(
            "When anterior pelvic tilt is serious, there is possibility of developing into Facet Joint Syndrome."
          ),
          $L(
            "Excessive anterior pelvic tilt can cause Sacroiliac Joint Syndrome."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "When posterior pelvic tilt is continued, it can lead to herniated lumbar disc or stenosis."
          ),
          $L(
            "Myofascial pain syndrome can be accompanied from imbalance of surrounding structures due to posterior pelvic tilt."
          ),
          $L(
            "When posterior pelvic tilt is serious, there is possibility of occurring with lumbar strain."
          ),
          $L(
            "On prolonged posterior pelvic tilt, retrospondylolisthesis can be occurred."
          ),
          $L(
            "When the posterior pelvic tilt is continued for long period, Hip Joint Impingement Syndrome can be occurred."
          ),
          $L(
            "When posterior pelvic tilt is serious, there is possibility of developing into piriformis syndrome."
          ),
          $L(
            "Excessive posterior pelvic tilt can cause Sacroiliac Joint Syndrome."
          ),
        ],
      },
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "Pelvis is slightly tilted to the front and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Pelvis is tilted to the front to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Iliopsoas or erector spinae may be shortened due to increase in the anterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
          ),
          $L(
            "Pectoralis major and erector spinae are shortened, and there is possibility of the Rectus Abdominis and hamstring being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Hyperlordosis can be caused on the back when the anterior pelvic shift condition is continued."
          ),
          $L(
            "After proper alignment of the pelvis, activate the muscles around the pelvis through stabilization exercise."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "Pelvis is slightly tilted to the back and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Pelvis is tilted to the back to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Rectus Abdominis or hamstring may be shortened due to increase in the posterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
          ),
          $L(
            "Hamstring and Rectus Abdominis are shortened, and there is possibility of the Iliopsoas and erector spinae being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Kyphosiscan be caused when the posterior pelvic shift condition is continued."
          ),
          $L(
            "After proper alignment of the pelvis, activate the muscles around the pelvis through stabilization exercise."
          ),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "Pelvis is slightly tilted to the front and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Pelvis is tilted to the front to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Iliopsoas or erector spinae may be shortened due to increase in the anterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
          ),
          $L(
            "Pectoralis major and erector spinae are shortened, and there is possibility of the Rectus Abdominis and hamstring being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Hyperlordosis can be caused on the back when the anterior pelvic shift condition is continued."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "Pelvis is slightly tilted to the back and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Pelvis is tilted to the back to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Rectus Abdominis or hamstring may be shortened due to increase in the posterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
          ),
          $L(
            "Hamstring and Rectus Abdominis are shortened, and there is possibility of the Iliopsoas and erector spinae being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Kyphosiscan be caused when the posterior pelvic shift condition is continued."
          ),
        ],
      },
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Location of the pelvis is maintained properly within the normal range."
            ),
            $L(
              "Anterior and posterior pelvic tilt is within the normal range."
            ),
            $L(
              "Anterior and posterior pelvic balance is in a proper condition."
            ),
            $L("Pelvis is located properly in the centerline."),
            $L(
              "There is anterior and posterior balance of the pelvis with the centerline."
            ),
            $L("Center of the pelvis is within the centerline."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L("Pelvis is slighted tilted to the front."),
            $L("Pelvis shows minor anterior tilt."),
            $L("Pelvis is tilted slightly to the front."),
            $L(
              "Anterior pelvic tilt is slightly larger than the normal angle."
            ),
            $L("Pelvis alignment is slightly in an imbalance state."),
            $L(
              "Pelvis is tilted to the front, and observed with minor change in the body shape."
            ),
            $L({
              key: "_FRONT_Opinion on minor issue is observed in the pelvic tilt items.",
              value:
                "Opinion on minor issue is observed in the pelvic tilt items.",
            }),
          ],
          [SCORE_RED_KEY]: [
            $L("Pelvis is tilted considerably to the front."),
            $L("Pelvis shows anterior tilt."),
            $L("Pelvis is tilted to the front."),
            $L("Anterior pelvic tilt is larger than the normal angle."),
            $L("Pelvis alignment is in an imbalance state."),
            $L(
              "Pelvis is tilted to the front, and observed with change in the body shape."
            ),
            $L("Opinion on issue is observed in the pelvic tilt items."),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Location of the pelvis is maintained properly within the normal range."
            ),
            $L(
              "Anterior and posterior pelvic tilt is within the normal range."
            ),
            $L(
              "Anterior and posterior pelvic balance is in a proper condition."
            ),
            $L("Pelvis is located properly in the centerline."),
            $L(
              "There is anterior and posterior balance of the pelvis with the centerline."
            ),
            $L("Center of the pelvis is within the centerline."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L("Pelvis is slighted tilted to the back."),
            $L("Pelvis shows minor posterior tilt."),
            $L("Pelvis is tilted slightly to the back."),
            $L("Pelvic tilt is slightly smaller than the normal angle."),
            $L("Pelvis alignment is slightly in an imbalance state."),
            $L(
              "Pelvis is tilted to the back, and observed with minor change in the body shape."
            ),
            $L({
              key: "_FRONT_Opinion on minor issue is observed in the pelvic tilt items.",
              value:
                "Opinion on minor issue is observed in the pelvic tilt items.",
            }),
          ],
          [SCORE_RED_KEY]: [
            $L("Pelvis is tilted considerably to the back."),
            $L("Pelvis shows posterior tilt."),
            $L("Pelvis is tilted to the back."),
            $L("Pelvic tilt is smaller than the normal angle."),
            $L("Pelvis alignment is in an imbalance state."),
            $L(
              "Pelvis is tilted to the back, and observed with change in the body shape."
            ),
            $L("Opinion on issue is observed in the pelvic tilt items."),
          ],
        },
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "High heels or elevator shoes can cause excessive anterior pelvic shift."
            ),
            $L(
              "Excessive anterior pelvic shift can be caused on the abdominal obesity body type, and so maintaining the proper body weight is recommended."
            ),
            $L(
              "Posture of stretching the back excessively can increase the anterior pelvic shift angle to require caution."
            ),
            $L(
              "To prevent the pelvis from tilting to the front in everyday life, please check whether the hip is pulled back to stretch the back excessively for long period."
            ),
            $L(
              "In everyday life, sitting with your back stretched excessively must be avoided."
            ),
            $L(
              "Please check whether you are normally maintaining a posture of stretching your back excessively."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Pelvis is slightly tilted to the front and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Pelvis is tilted to the front to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Iliopsoas or erector spinae may be shortened due to increase in the anterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
            ),
            $L(
              "Pectoralis major and erector spinae are shortened, and there is possibility of the Rectus Abdominis and hamstring being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Hyperlordosis can be caused on the back when the anterior pelvic shift condition is continued."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Pelvis is slightly tilted to the front and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Pelvis is tilted to the front to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Iliopsoas or erector spinae may be shortened due to increase in the anterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
            ),
            $L(
              "Pectoralis major and erector spinae are shortened, and there is possibility of the Rectus Abdominis and hamstring being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Hyperlordosis can be caused on the back when the anterior pelvic shift condition is continued."
            ),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "When sitting on a chair or on a sofa normally, please stretch your back to sit in a proper posture."
            ),
            $L(
              "It is recommended to sit on a chair as possible instead of the floor or on a soft sofa."
            ),
            $L(
              "The habit of sitting on the edge for a long time can have possibility of posterior pelvic shift, so please sit in a proper posture."
            ),
            $L(
              "To prevent posterior pelvic shift, sitting in a slumped posture for long period must be avoided."
            ),
            $L(
              "Caution is required on not bending the back excessively in everyday life."
            ),
            $L(
              "Sitting in a crouching posture normally can increase the posterior pelvic shift angle, so caution is required."
            ),
            $L(
              "Please sit normally in a proper posture instead of bending the back excessively."
            ),
            $L(
              "Instead of maintaining one posture for long time, change the posture regularly to reduce the stress on the joint."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Pelvis is slightly tilted to the back and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Pelvis is tilted to the back to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Rectus Abdominis or hamstring may be shortened due to increase in the posterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
            ),
            $L(
              "Hamstring and Rectus Abdominis are shortened, and there is possibility of the Iliopsoas and erector spinae being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Kyphosiscan be caused when the posterior pelvic shift condition is continued."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Pelvis is slightly tilted to the back and imbalance of muscles around the pelvis is estimated. When imbalance of the pelvis is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Pelvis is tilted to the back to cause change in length of partial muscles on the hip located on the abdomen and back. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Rectus Abdominis or hamstring may be shortened due to increase in the posterior pelvic shift angle. Management is recommended for stretching on the shortened muscles for muscle balance."
            ),
            $L(
              "Hamstring and Rectus Abdominis are shortened, and there is possibility of the Iliopsoas and erector spinae being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Kyphosiscan be caused when the posterior pelvic shift condition is continued."
            ),
          ],
        },
      },
    },
    [MENUTYPE.KNEE_FLEXION]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: {
        // detailType === "front"
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "For consistent management, maintaining a sitting posture for long time in everyday life can give stress to the knee joint."
          ),
          $L(
            "For consistent management, instead of maintaining one posture for long time normally, it is recommended to get up from the seat every 30 minutes for stretching."
          ),
          $L(
            "For consistent management, ,maintaining a sitting posture for long time in everyday life can strain the knee joint and adjacent tissues."
          ),
          $L(
            "For consistent management, sedentary lifestyle in everyday life should be avoided as possible as it can have influence to the knee joint."
          ),
          $L(
            "For consistent management, it is recommended to sit on a chair instead of sitting on the floor in everyday life."
          ),
          $L(
            "For consistent management, sedentary lifestyle can have influence to the knee joint, so maintaining the cross-legged posture for long time should be avoided."
          ),
          $L(
            "For consistent management, do not cross your legs, and maintain a proper sitting posture in everyday life."
          ),
          $L(
            "For consistent management, hips and back must be in contact with the back of a chair when sitting normally."
          ),
          $L(
            "For consistent management, please adjust the height of the chair to have the feet in contact with the floor when sitting on a chair normally."
          ),
          $L(
            "For consistent management, the height of the chair must be adjusted to have the feet in contact with the floor when sitting on a chair normally."
          ),
          $L(
            "For consistent management, it is recommended to use a mop instead of getting on your knees to wipe the floor when performing housework such as mopping."
          ),
          $L(
            "For consistent management, you must rest regularly in a comfortable position to prevent excessive tension on the structures around the knee."
          ),
          $L(
            "For consistent management, it is recommended to get up from the seat every 30 minutes for stretching instead of maintaining one posture for long time normally."
          ),
          $L(
            "For consistent management, please change your posture regularly to reduce the strain on the knee joint."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "For consistent management, shoes with high heels can give stress to the knees, so wearing comfortable shoes is recommended."
          ),
          $L(
            "For consistent management, movement of having the belly sticking out excessively can cause excessive stretching, so please maintain proper spinal alignment."
          ),
          $L(
            "For consistent management, there can be excessive knee extension when the center of body weight is shifted forward."
          ),
          $L(
            "For consistent management, leaning on one leg in everyday life can have influence on the knee joint, so balancing the body weight while standing is recommended."
          ),
          $L(
            "For consistent management, please take caution on not concentrating the body weight only on one leg when standing normally."
          ),
          $L(
            "For consistent management, it is recommended to balance the body weight on both sides of the leg when walking."
          ),
          $L(
            "For consistent management, you must rest regularly in a comfortable position to prevent excessive tension on the structures around the knee."
          ),
          $L(
            "For consistent management, it is recommended to get up from the seat every 30 minutes for stretching instead of maintaining one posture for long time normally."
          ),
          $L(
            "For consistent management, please change your posture regularly to reduce the strain on the knee joint."
          ),
        ],
      },
      [FEEDBACK_TYPE.CONDITION]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "When knee flexion is continued for long period, it can also have influence on the adjacent joint to lead to plantar fasciitis."
          ),
          $L(
            "When knee flexion is continued for long period, it can also have influence on the adjacent joint to lead to Archilles tendinitis."
          ),
          $L(
            "When knee flexion is continued for long period, it can give stress to the knee joint to occur with related illnesses such as degenerative knee arthritis, so consistent management is required."
          ),
          $L(
            "When knee flexion is continued for long period, it can give stress to the knee joint to occur with related illnesses such as Meniscal Tear, so consistent management is required."
          ),
          $L(
            "Patellofemoral Pain Syndrome (PFPS) and myofascial pain syndrome can be accompanied due to imbalance of the surrounding muscles due to knee flexion, so consistent management is required."
          ),
          $L(
            "Hip Joint Impingement Syndrome and kyphosis can be occurred due to excessive knee flexion."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "When excessive knee extension is continued, it can progress to degenerative knee arthritis. When occurred with symptoms such as paint, consulting with the specialist may be required."
          ),
          $L(
            "When excessive knee extension is continued, there is possibility of occurring with Patellofemoral Pain Syndrome (PFPS). To prevent this, consistent exercise and management are required."
          ),
          $L(
            "Knee is extended excessively, and myofascial pain syndrome can be accompanied due to imbalance of the structure around the knee."
          ),
          $L(
            "When excessive knee extension is continued, there is possibility of progressing with back knee (genu recurvatum)."
          ),
          $L(
            "Stress on the tissues from excessive knee extension can also influence the adjacent joint to cause plantar fasciitis and Achilles tendinitis."
          ),
          $L(
            "When excessive knee extension is continued for long period, it can have influence on the spinal alignment to cause hollow-back."
          ),
          $L(
            "Stress on the tissues from excessive knee extension can also influence the adjacent joint to cause arthritis of the hip joint."
          ),
        ],
      },
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "Knee is slightly bent from the normal alignment to influence the walking posture. Straighten the knee alignment to correct the alignment through stabilization exercise."
          ),
          $L(
            "Knee in a slightly bent condition can have influence to normal walking. Straighten the knee alignment to correct the alignment through stabilization exercise."
          ),
          $L(
            "Hamstring is shortened, and there is possibility of the Quadriceps Femoris being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "The body can tilt forward when the knees are bent, so please maintain a proper spinal alignment."
          ),
          $L(
            "The position of the knee is slightly off the baseline, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be strained."
          ),
          $L(
            "When the knee flexion condition is continued, it can cause pain. Restore the healthy knee through consistent exercise."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "Misalignment of the knee can strain the adjacent knee. Please set the alignment through muscular strength exercise and stretching."
          ),
          $L(
            "When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be stressed."
          ),
          $L(
            "When the knee is slightly out of alignment to continue this condition, degenerative change or pain can be caused, so consistent management is required."
          ),
          $L(
            "Management is recommended for stretching on the shortened muscles around the knee, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Activate knee stabilizing muscles to increase the stability of your knees."
          ),
          $L(
            "Knee stabilization exercise can help you maintain healthy knees. Consistent management is required."
          ),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "Knee is slightly bent from the normal alignment to influence the walking posture. Straighten the knee alignment to correct the alignment through stabilization exercise."
          ),
          $L(
            "Knee in a slightly bent condition can have influence to normal walking. Straighten the knee alignment to correct the alignment through stabilization exercise."
          ),
          $L(
            "Hamstring is shortened, and there is possibility of the Quadriceps Femoris being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "The body can tilt forward when the knees are bent, so please maintain a proper spinal alignment."
          ),
          $L(
            "The position of the knee is slightly off the baseline, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be strained."
          ),
          $L(
            "When the knee flexion condition is continued, it can cause pain. Restore the healthy knee through consistent exercise."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "Misalignment of the knee can strain the adjacent knee. Please set the alignment through muscular strength exercise and stretching."
          ),
          $L(
            "When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be stressed."
          ),
          $L(
            "When the knee is slightly out of alignment to continue this condition, degenerative change or pain can be caused, so consistent management is required."
          ),
          $L(
            "Management is recommended for stretching on the shortened muscles around the knee, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Activate knee stabilizing muscles to increase the stability of your knees."
          ),
          $L(
            "Knee stabilization exercise can help you maintain healthy knees. Consistent management is required."
          ),
          $L(
            "Do you normally wear shoes with high heels. When the knee is shifted back, it can also influence the adjacent joint."
          ),
          $L(
            "When standing for a long time, how about bending your knees slightly? It will reduce the load on the knee."
          ),
          $L(
            "Do you have the habit of sticking out your belly excessively? Moving your body weight to the front can strain the knee."
          ),
        ],
      },
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L("Location of the knee is within the normal range."),
            $L("Knee is located within the normal range."),
            $L("Lateral knee alignment is in the proper condition."),
            $L("Knee is located within the lateral baseline."),
            $L("Knee angle is within the normal range."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L("Knee flexion angle shows minor deviation."),
            $L("Knee is located slightly in front of the lateral baseline."),
            $L("Knee is somewhat bent compared to the normal standard."),
            $L("Minor knee flexion is observed."),
            $L("Knee is slightly bent."),
            $L("Knee is slightly bent to suspect change in body shape."),
            $L(
              "There is possibility of change in body shape due to knee flexion."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L("Knee flexion angle shows considerable deviation."),
            $L(
              "Knee is located considerably in front of the lateral baseline."
            ),
            $L("Knee is definitely bent compared to the normal standard."),
            $L("Knee flexion is observed."),
            $L("Knee is definitely bent."),
            $L("Knee is bent to suspect change in body shape."),
            $L(
              "There is somewhat high possibility of having change in body shape due to considerable knee flexion."
            ),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L("Location of the knee is within the normal range."),
            $L("Knee is located within the normal range."),
            $L("Lateral knee alignment is in the proper condition."),
            $L("Knee is located within the lateral baseline."),
            $L("Knee angle is within the normal range."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Angle of the knee is deviating slightly from the normal range."
            ),
            $L("Knee is located slightly on back of the lateral baseline."),
            $L("Knee is somewhat stretched compared to the normal standard."),
            $L("Knee is slightly stretched."),
            $L(
              "Knee is stretched compared to the normal standard. There can be change in body shape accordingly."
            ),
            $L(
              "Knee is located on back of the lateral baseline to have possibility of change in body shape."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Angle of the knee is deviating considerably from the normal range."
            ),
            $L("Knee is located on back of the lateral baseline."),
            $L(
              "Knee is considerably stretched compared to the normal standard."
            ),
            $L("Knee is considerable stretched."),
            $L(
              "Knee is considerably stretched compared to the normal standard. There can be change in body shape accordingly."
            ),
            $L(
              "Knee is located considerably on back of the lateral baseline to have possibility of change in body shape."
            ),
          ],
        },
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Maintaining a sitting posture for long time in everyday life can strain the knee joint and adjacent tissues."
            ),
            $L(
              "Instead of maintaining one posture for long time normally, it is recommended to get up from the seat every 30 minutes for stretching."
            ),
            $L(
              "Maintaining a sitting posture for long time in everyday life can strain the knee joint and adjacent tissues."
            ),
            $L(
              "Sedentary lifestyle in everyday life should be avoided as possible as it can have influence to the knee joint."
            ),
            $L(
              "In everyday life, it is recommended to sit on a chair instead of sitting on the floor."
            ),
            $L(
              "Sedentary lifestyle can have influence to the knee joint, so maintaining the cross-legged posture for long time should be avoided."
            ),
            $L(
              "Do not cross your legs, and maintain a proper sitting posture in everyday life."
            ),
            $L(
              "Hips and back must be in contact with the back of a chair when sitting normally."
            ),
            $L(
              "When sitting on a chair normally, please adjust the height of the chair to have the feet in contact with the floor."
            ),
            $L(
              "When sitting on a chair normally, the height of the chair must be adjusted to have the feet in contact with the floor."
            ),
            $L(
              "When performing housework such as mopping, it is recommended to use a mop instead of getting on your knees to wipe the floor."
            ),
            $L({
              key: "_Instead of maintaining one posture for long time normally, it is recommended to get up from the seat every 30 minutes for stretching.",
              value:
                "Instead of maintaining one posture for long time normally, it is recommended to get up from the seat every 30 minutes for stretching.",
            }),
            $L(
              "Please change your posture regularly to reduce the strain on the knee joint."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Knee is slightly bent from the normal alignment to influence the walking posture. Straighten the knee alignment to correct the alignment through stabilization exercise."
            ),
            $L(
              "Knee in a slightly bent condition can have influence to normal walking. Straighten the knee alignment to correct the alignment through stabilization exercise."
            ),
            $L(
              "Hamstring is shortened, and there is possibility of the Quadriceps Femoris being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "The body can tilt forward when the knees are bent, so please maintain a proper spinal alignment."
            ),
            $L(
              "The position of the knee is slightly off the baseline, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be strained."
            ),
            $L(
              "When the knee flexion condition is continued, it can cause pain. Restore the healthy knee through consistent exercise."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Knee is slightly bent from the normal alignment to influence the walking posture. Straighten the knee alignment to correct the alignment through stabilization exercise."
            ),
            $L(
              "Knee in a slightly bent condition can have influence to normal walking. Straighten the knee alignment to correct the alignment through stabilization exercise."
            ),
            $L(
              "Hamstring is shortened, and there is possibility of the Quadriceps Femoris being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "The body can tilt forward when the knees are bent, so please maintain a proper spinal alignment."
            ),
            $L(
              "The position of the knee is slightly off the baseline, and imbalance of muscles around the knee is estimated. When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be strained."
            ),
            $L(
              "When the knee flexion condition is continued, it can cause pain. Restore the healthy knee through consistent exercise."
            ),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Shoes with high heels can give stress to the knees, so wearing comfortable shoes is recommended."
            ),
            $L(
              "Movement of having the belly sticking out excessively can cause excessive stretching, so please maintain proper spinal alignment."
            ),
            $L(
              "There can be excessive knee extension when the center of body weight is shifted forward."
            ),
            $L(
              "Leaning on one leg in everyday life can have influence on the knee joint, so balancing the body weight while standing is recommended."
            ),
            $L({
              key: "_In everyday life, please take caution on not concentrating the body weight only on one leg when standing.",
              value:
                "In everyday life, please take caution on not concentrating the body weight only on one leg when standing.",
            }),
            $L(
              "When walking, it is recommended to balance the body weight on both sides of the leg."
            ),
            $L({
              key: "_Instead of maintaining one posture for long time normally, it is recommended to get up from the seat every 30 minutes for stretching.",
              value:
                "Instead of maintaining one posture for long time normally, it is recommended to get up from the seat every 30 minutes for stretching.",
            }),
            $L(
              "Please change your posture regularly to reduce the strain on the knee joint."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "Misalignment of the knee can strain the adjacent knee. Please set the alignment through muscular strength exercise and stretching."
            ),
            $L(
              "When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be stressed."
            ),
            $L(
              "When the knee is slightly out of alignment to continue this condition, degenerative change or pain can be caused, so consistent management is required."
            ),
            $L(
              "Management is recommended for stretching on the shortened muscles around the knee, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Activate the knee stabilization muscles to increase the stability of the knee."
            ),
            $L(
              "Knee stabilization exercise can help you maintain healthy knees. Consistent management is required."
            ),
            $L(
              "Do you normally wear shoes with high heels. When the knee is shifted back, it can also influence the adjacent joint."
            ),
            $L(
              "When standing for a long time, how about bending your knees slightly? It will reduce the load on the knee."
            ),
            $L(
              "Do you have the habit of sticking out your belly excessively? Moving your body weight to the front can strain the knee."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "Misalignment of the knee can strain the adjacent knee. Please set the alignment through muscular strength exercise and stretching."
            ),
            $L(
              "When imbalance of the muscles around the knee is continued, adjacent joint and surrounding tissues can be stressed."
            ),
            $L(
              "When the knee is slightly out of alignment to continue this condition, degenerative change or pain can be caused, so consistent management is required."
            ),
            $L(
              "Management is recommended for stretching on the shortened muscles around the knee, and strengthening exercise on the stretched muscles for muscle balance."
            ),
            $L(
              "Activate the knee stabilization muscles to increase the stability of the knee."
            ),
            $L(
              "Knee stabilization exercise can help you maintain healthy knees. Consistent management is required."
            ),
            $L(
              "Do you normally wear shoes with high heels. When the knee is shifted back, it can also influence the adjacent joint."
            ),
            $L(
              "When standing for a long time, how about bending your knees slightly? It will reduce the load on the knee."
            ),
            $L(
              "Do you have the habit of sticking out your belly excessively? Moving your body weight to the front can strain the knee."
            ),
          ],
        },
      },
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: {
        // detailType === "front"
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "For consistent management, caution must be taken not to bend the body excessively to the front normally."
          ),
          $L(
            "For consistent management, please check whether the shoulders are curled normally."
          ),
          $L(
            "For consistent management, using the computer or smartphone for a long time in a slumped posture must be avoided."
          ),
          $L(
            "For consistent management, adjust the height of the desk and monitor properly when working on the computer."
          ),
          $L(
            "For consistent management, caution is required when using the pillow that is excessively high."
          ),
          $L(
            "For consistent management, you must rest regularly in a comfortable position to prevent excessive tension on the shoulders."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "For consistent management, caution must be taken not to tilt the body excessively to the back normally."
          ),
          $L(
            "For consistent management, caution must be taken not to tilt the shoulder excessively to the back normally."
          ),
          $L(
            "For consistent management, adjust the height of the desk and monitor properly when working on the computer."
          ),
          $L(
            "For consistent management, the height must be adjusted when the pillow height is inappropriate. Please check whether the pillow height is appropriate."
          ),
        ],
      },
      [FEEDBACK_TYPE.CONDITION]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "When both sides of the shoulder are located in front of the normal standard, it can accompany myofascial pain syndrome."
          ),
          $L(
            "When both sides of the shoulder show anterior shift from normal alignment, there is possibility of being round shoulder (curled shoulder)."
          ),
          $L(
            "When both sides of the shoulder show anterior shift from normal alignment, there is possibility of accompanying round shoulder."
          ),
          $L(
            "When both sides of the shoulder are continued with the curled condition, there is possibility of accompanying scapula alata, so consultation with the specialist is required."
          ),
          $L(
            "When both sides of the shoulder show anterior shift continuously for long period, there is possibility of developing into spinal disc disorder."
          ),
          $L(
            "When both sides of the shoulder show serious anterior shift, there is possibility of accompanying thoracic outlet syndrome."
          ),
          $L(
            "Prolonged rounder shoulder can lead to shoulder impingement syndrome."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "When both sides of the shoulder show posterior shift from normal alignment, there is possibility of accompanying flat back."
          ),
          $L(
            "When both sides of the shoulder are located in back of the normal standard, it can accompany myofascial pain syndrome."
          ),
          $L(
            "When both sides of the shoulder are continued with the curled condition to the back, there is possibility of accompanying scapula alata, so consultation with the specialist is required."
          ),
          $L(
            "When both sides of the shoulder show posterior shift continuously for long period, there is possibility of developing into spinal disc disorder."
          ),
        ],
      },
      [FEEDBACK_TYPE.MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "The shoulder is shifted slightly to the front, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Pectoralis major and pectoralis minor are shortened, and there is possibility of the rhomboid and lower trapezius being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Stabilization muscle can be activated around the shoulder to increase the stability of the shoulder."
          ),
          $L(
            "Healthy shoulder can be maintained through muscle strengthening exercise, stretching and stabilization exercise related to the shoulder."
          ),
          $L(
            "After proper alignment of the shoulder, activate the muscles around the shoulder through stabilization exercise."
          ),
          $L(
            "Adjust the shoulder alignment to perform stabilization exercise."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "The shoulder is shifted slightly to the back, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Erector spinae and rhomboid are shortened, and there is possibility of the serratus anterior being weakened. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
          $L(
            "Stabilization muscle can be activated around the shoulder to increase the stability of the shoulder."
          ),
          $L(
            "Healthy shoulder can be managed through muscle strengthening exercise, stretching and stabilization exercise related to the shoulder."
          ),
          $L(
            "After proper alignment of the shoulder, activate the muscles around the shoulder through stabilization exercise."
          ),
          $L("Align the shoulders and do the stabilization exercise."),
        ],
      },
      [FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT]: {
        [MENUTYPE_TEST.ANTERIOR]: [
          $L(
            "The shoulder is shifted slightly to the front, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Pectoralis major and pectoralis minor are shortened, and there is possibility of the rhomboid and lower trapezius being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
        ],
        [MENUTYPE_TEST.POSTERIOR]: [
          $L(
            "The shoulder is shifted slightly to the back, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
          ),
          $L(
            "Erector spinae and rhomboid are shortened, and there is possibility of the serratus anterior being weakened. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
          ),
        ],
      },
      [FEEDBACK_TYPE.SPECIFY_STATUS]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Alignment on both sides of the shoulder is maintained properly within the centerline range."
            ),
            $L("Both sides of the shoulder are in good balance."),
            $L("Location of the shoulder is within the normal range."),
            $L("Lateral position of the shoulder is maintained properly."),
            $L("Both sides of the shoulder are located in the vertical line."),
            $L("Shoulder tilt is within the normal range."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L("Both sides of the shoulder show minor anterior shift."),
            $L("Both sides of the shoulder are tilted slightly to the front."),
            $L("Shoulder is slightly curled to the front."),
            $L("Shoulder is somewhat shifted to the front."),
            $L("There are minor structural issues on the shoulder."),
            $L("Minor round shoulder is suspected."),
            $L(
              "There is shoulder anterior shift to show minor change in body shape."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L("Both sides of the shoulder show considerable anterior shift."),
            $L("Both sides of the shoulder are tilted to the front."),
            $L("Shoulder is considerably curled to the front."),
            $L("Shoulder is definitely shifted to the front."),
            $L("There are structural issues on the shoulder."),
            $L("Round shoulder is suspected."),
            $L(
              "There is shoulder anterior shift to show definite change in body shape."
            ),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Alignment on both sides of the shoulder is maintained properly within the centerline range."
            ),
            $L("Both sides of the shoulder are in good balance."),
            $L("Location of the shoulder is within the normal range."),
            $L("Lateral position of the shoulder is maintained properly."),
            $L("Both sides of the shoulder are located in the vertical line."),
            $L("Shoulder tilt is within the normal range."),
          ],
          [SCORE_YELLOW_KEY]: [
            $L("Both sides of the shoulder show minor posterior shift."),
            $L("Both sides of the shoulder are tilted slightly to the back."),
            $L("Shoulder is slightly tilted to the back."),
            $L("Shoulder is somewhat shifted to the back."),
            $L("There are minor structural issues on the shoulder."),
            $L(
              "There is shoulder posterior shift to show minor change in body shape."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L("Both sides of the shoulder show considerable posterior shift."),
            $L("Both sides of the shoulder are tilted to the back."),
            $L("Shoulder is considerably curled to the back."),
            $L("Shoulder is definitely shifted to the back."),
            $L("There are structural issues on the shoulder."),
            $L(
              "There is shoulder posterior shift to show definite change in body shape."
            ),
          ],
        },
      },
      [FEEDBACK_TYPE.OVERALL_FEEDBACK]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Caution must be taken not to bend the body excessively to the front normally."
            ),
            $L("Please check whether the shoulders are curled normally."),
            $L(
              "Using the computer or smartphone for a long time in a slumped posture must be avoided."
            ),
            $L(
              "When working on the computer, adjust the height of the desk and monitor properly."
            ),
            $L(
              "Caution is required when using the pillow that is excessively high."
            ),
            $L(
              "You must rest regularly in a comfortable position to prevent excessive tension on the shoulders."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "The shoulder is shifted slightly to the front, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Pectoralis major and pectoralis minor are shortened, and there is possibility of the rhomboid and lower trapezius being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "The shoulder is shifted slightly to the front, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Pectoralis major and pectoralis minor are shortened, and there is possibility of the rhomboid and lower trapezius being stretched. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          [SCORE_MINT_KEY]: [
            $L(
              "Caution must be taken not to tilt the body excessively to the back normally."
            ),
            $L(
              "Caution must be taken not to tilt the shoulder excessively to the back normally."
            ),
            $L(
              "When working on the computer, adjust the height of the desk and monitor properly."
            ),
            $L(
              "When the pillow height is inappropriate, the height must be adjusted. Please check whether the pillow height is appropriate."
            ),
          ],
          [SCORE_YELLOW_KEY]: [
            $L(
              "The shoulder is shifted slightly to the back, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Erector spinae and rhomboid are shortened, and there is possibility of the serratus anterior being weakened. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
          [SCORE_RED_KEY]: [
            $L(
              "The shoulder is shifted slightly to the back, and imbalance of muscles around the shoulder is estimated. When imbalance of the muscles around the shoulder is continued, stress can be given to the surrounding tissues including the adjacent joint."
            ),
            $L(
              "Erector spinae and rhomboid are shortened, and there is possibility of the serratus anterior being weakened. Management is recommended for stretching on the shortened muscles, and strengthening exercise on the stretched muscles for muscle balance."
            ),
          ],
        },
      },
    },
  };
};

export const getViewDetailsFeedbackGuide = () => {
  return {
    [MENUTYPE.NECK_TILT]: {
      [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
        $L(
          "Maintain the proper neck alignment, and sit on a chair in a proper posture."
        ),
        $L(
          "Perform stretching every 30 minutes to prevent excessive tension on the neck."
        ),
        $L("Avoid the habit of resting the chin to one side."),
      ],
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L(
          "Maintain the proper neck alignment, and sit on a chair in a proper posture."
        ),
        $L(
          "Perform stretching every 30 minutes to prevent excessive tension on the neck."
        ),
        $L("Avoid the habit of resting the chin to one side."),
      ],
      [FEEDBACK_TYPE.DISEASE]: [
        $L("Myofascial pain syndrome"),
        $L("Facial asymmetry"),
        $L("Knee joint dysfunction"),
        $L("Herniated cervical disc"),
        $L("Thoracic outlet syndrome"),
        $L("Tension headache and migraine"),
      ],
      [FEEDBACK_TYPE.EXERCISE]: {
        name: $L("Stretching of the sternocleidomastoid muscle"),
        relativeMuscle: $L("Sternocleidomastoid (SCM)"),
        effect: $L(
          "Proper neck alignment is maintained through stretching of the sternocleidomastoid muscle"
        ),
        exception: [
          $L("If you feel severe pain in your neck"),
          $L(
            "If you have structural damage in the neck area (ligaments, muscles, tendons, etc.)"
          ),
        ],
        cautions: [
          $L(
            "Caution is required on preventing the shoulder opposite from the stretching shoulder from going up."
          ),
          $L(
            "Exercise must be stopped immediately when having pain on the neck while stretching."
          ),
        ],
      },
    },
    [MENUTYPE.SHOULDER_TILT]: {
      [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
        $L(
          "Carrying a heavy load on one side can have influence on imbalance of left and right sides of the shoulder."
        ),
        $L(
          "When studying avoid the posture of leaning to one side on the desk."
        ),
        $L(
          "Height of the crutches or stick must be used according to your body shape."
        ),
      ],
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L(
          "가방을 한쪽으로 메는 것 보다는 양쪽 어깨에 무게가 균등하게 실리도록 메는 것이 좋습니다."
        ),
        $L(
          "Carrying a heavy load on one side can have influence on imbalance of left and right sides of the shoulder."
        ),
        $L(
          "Height of the crutches or stick must be used according to your body shape."
        ),
        $L(
          "When studying avoid the posture of leaning to one side on the desk."
        ),
        $L("직업 상 한쪽 어깨에만 짐이 치우치지 않도록 주의해주세요."),
      ],
      [FEEDBACK_TYPE.DISEASE]: [
        $L("Scoliosis"),
        $L("Myofascial pain syndrome"),
        $L("Thoracic outlet syndrome"),
      ],
      [FEEDBACK_TYPE.EXERCISE]: {
        name: $L("Levator scapulae stretch"),
        relativeMuscle: $L("Levator scapulae"),
        effect: $L(
          "Maintaining good shoulder alignment through levator scapulae stretch"
        ),
        exception: [
          $L("If you feel severe pain in your neck and shoulders"),
          $L("목, 어깨 주변에 구조적 손상이 있는 경우(인대, 근육, 힘줄 등)"),
        ],
        cautions: [
          $L(
            "Make sure you don't raise the other shoulder when stretching a shoulder."
          ),
          $L("머리를 아래방향으로 과도하게 누르지 않도록 주의합니다."),
          $L("Stop stretching immediately if you feel pain in your neck."),
        ],
      },
    },
    [MENUTYPE.PELVIC_TILT]: {
      [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
        $L({
          key: "_VIEW_DETAILIS_Legs should not be crossed when sitting, and proper sitting posture is required.",
          value:
            "Legs should not be crossed when sitting, and proper sitting posture is required.",
        }),
        $L(
          "Please take caution on not concentrating the body weight only on one leg when standing."
        ),
        $L({
          key: "_VIEW_DETAILIS_When walking, it is recommended to balance the body weight on both sides of the leg.",
          value:
            "When walking, it is recommended to balance the body weight on both sides of the leg.",
        }),
      ],
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L({
          key: "_VIEW_DETAILIS_Legs should not be crossed when sitting, and proper sitting posture is required.",
          value:
            "Legs should not be crossed when sitting, and proper sitting posture is required.",
        }),
        $L(
          "Please take caution on not concentrating the body weight only on one leg when standing."
        ),
        $L(
          "짝다리를 짚는 것은 골반 불균형에 영향을 줄 수 있으니 체중을 균등하게 싣고 서는 것이 좋습니다."
        ),
        $L(
          "Avoid leaning to one side or sitting with your body bent down at your desk."
        ),
        $L({
          key: "_VIEW_DETAILIS_When walking, it is recommended to balance the body weight on both sides of the leg.",
          value:
            "When walking, it is recommended to balance the body weight on both sides of the leg.",
        }),
      ],
      [FEEDBACK_TYPE.DISEASE]: [
        $L("Scoliosis"),
        $L("Lumbar sprain and herniated disc"),
        $L("Sacroiliac joint syndrome"),
        $L("Piriformis syndrome"),
      ],
      [FEEDBACK_TYPE.EXERCISE]: {
        name: $L("Back stretch/Side waist stretch"),
        relativeMuscle: $L("Quadratus Lumborum"),
        effect: $L(
          "Maintaining pelvic alignment through quadratus lumborum stretch"
        ),
        exception: [
          $L("When feeling sharp pain when stretching"),
          $L("When feeling pain in the pelvis or knee in the start position"),
          $L("If you have unstable lumbar spine"),
        ],
        cautions: [
          $L(
            "Stop stretching immediately if you feel pain in your hip or knee."
          ),
          $L(
            "Hold your knees if you have problem holding your pelvis with your hands."
          ),
        ],
      },
    },
    [MENUTYPE.PELVIC_ROTATION]: {
      [FEEDBACK_TYPE.LIFE_STYLE]: [
        $L("다리를 꼬지 않고 올바른 자세로 앉아주세요."),
        $L("Be careful not to stand by putting your weight on one leg."),
        $L(
          "Avoid leaning to one side or sitting with your body bent down at your desk."
        ),
        $L(
          "걸을 때 양쪽 다리에 균등하게 체중이 실리도록 하는 것이 권장됩니다."
        ),
        $L("다리를 한쪽으로 올리고 앉는 자세를 피해주세요."),
        $L("We recommend sitting on a chair instead of sitting on the floor."),
        $L("Avoid turning your body sideways while holding something heavy."),
        $L(
          "몸통을 회전하는 업무를 반복하고 난 후에는 근육의 이완을 위하여 꼭 스트레칭을 시행해주세요."
        ),
        $L(
          "통증이 있는 경우 몸통을 한쪽으로 회전하는 동작을 포함한 운동(예: 골프, 테니스 등)을 가급적 피해주세요."
        ),
      ],
      [FEEDBACK_TYPE.DISEASE]: [
        $L("Scoliosis"),
        $L("Lumbar sprain and herniated disc"),
        $L("고관절 충돌 증후군"),
        $L("Piriformis syndrome"),
        $L("관절염"),
      ],
      [FEEDBACK_TYPE.EXERCISE]: {
        name: $L("Left and right sit-ups/Crunches"),
        relativeMuscle: $L("Internal and external oblique muscles"),
        effect: $L(
          "Correcting pelvic tilt through internal and external oblique muscle exercises"
        ),
        exception: [
          $L("경추의 불안정 및 통증을 호소하는 경우"),
          $L("When feeling pain in your waist"),
        ],
        cautions: [
          $L(
            "윗몸 일으키기 동작을 처음 시작할 때 목 주변에 불편감이 있다면 베개를 베고 시작해주세요."
          ),
        ],
      },
    },
    [MENUTYPE.LEG_ALIGNMENT]: {
      [MENUTYPE_TEST.BOW_LEG]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "Sitting in a cross-legged posture should be avoided as possible as it can have influence to knee joint."
          ),
          $L("When sitting, try not to sit cross-legged as possible."),
          $L(
            "When leg deformation is continued even after the adolescent period, consultation with the specialist may be required."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "Sitting in a cross-legged posture should be avoided as possible as it can have influence to knee joint."
          ),
          $L("가급적 바닥보다는 의자나 소파에 앉는 것이 권장됩니다."),
          $L("When sitting, try not to sit cross-legged as possible."),
          $L(
            "When leg deformation is continued even after the adolescent period, consultation with the specialist may be required."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Arthritis"),
          $L("Meniscus"),
          $L("Ligament and muscle damage around the knee"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Bridge exercises"),
          relativeMuscle: $L("Gluteus Maximus"),
          effect: $L(
            "Stabilizing areas around pelvic joints by strengthening the gluteus maximus"
          ),
          exception: [$L("-")],
          cautions: [
            $L("Be careful not to overly extend your back."),
            $L(
              "Be careful not to raise your butt higher than it is necessary."
            ),
          ],
        },
      },
      [MENUTYPE_TEST.KNOCK_KNEE]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "Sitting in W-shaped leg posture should be avoided as possible as it can have influence to the knee joint."
          ),
          $L(
            "When sitting, try not to sit cross-legged as possible, and please sit in a proper posture."
          ),
          $L("When walking, please check whether walking on knock knees."),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "Sitting in W-shaped leg posture should be avoided as possible as it can have influence to the knee joint."
          ),
          $L(
            "좌식 생활 습관은 무릎 주변 조직에 스트레스를 줄 수 있어 가급적 바닥보다는 의자나 소파에 앉는 것이 권장됩니다."
          ),
          $L(
            "When sitting, try not to sit cross-legged as possible, and please sit in a proper posture."
          ),
          $L("When walking, please check whether walking on knock knees."),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Arthritis"),
          $L("Meniscus damage"),
          $L("Ligament and muscle damage around the knees"),
          $L("Plantar fasciitis"),
          $L("Hallux valgus"),
          $L("Flat foot"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Hip joint tightening exercise"),
          relativeMuscle:
            $L("Adductor Longus") +
            ", " +
            $L("Adductor Brevis") +
            ", " +
            $L("Adductor Magnus"),
          effect: $L(
            "Relieving the XX leg by doing hip joint muscle exercise to strengthen the stretched muscle"
          ),
          exception: [$L("-")],
          cautions: [
            $L(
              "You need to be careful not to hold too much tension around the neck and shoulders."
            ),
          ],
        },
      },
    },
    [MENUTYPE.HEAD_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "The computer monitor must be adjusted according to the eye level."
          ),
          $L(
            "Please adjust according to the eye level when looking at a smartphone or a book."
          ),
          $L(
            "Anterior head shift may be caused from playing games for a long time in an inappropriate posture. Maintain the proper posture."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "The computer monitor must be adjusted according to the eye level."
          ),
          $L("Check if your head is normally tilted forward."),
          $L(
            "Please adjust according to the eye level when looking at a smartphone or a book."
          ),
          $L(
            "Anterior head shift may be caused from playing games for a long time in an inappropriate posture. Maintain the proper posture."
          ),
          $L(
            "장시간 고개를 내밀고 운전할 때  머리가 전방으로 이동될수 있습니다. 운전할때 올바른 자세를 유지해 주세요."
          ),
          $L(
            "장시간 머리를 숙이고 업무를 하는경우 머리 전방이동이 일어날수 있습니다. 업무를 보는동안 올바른 자세를 유지해주세요."
          ),
          $L(
            "바닥에서 신문이나 책을 보거나, 고개를 숙이고 핸드폰을 하는경우 머리의 전방이동이 일어날수 있습니다. 일상생활 시 올바른 자세를 유지해 주세요."
          ),
          $L(
            "Stech you neck every 30 minutes to prevent stiffness in the neck."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Forward Head Posture"),
          $L("Cervical Syndrome"),
          $L("Imbalance of muscles around the neck"),
          $L("Myofascial pain syndrome"),
          $L("Herniated cervical disc"),
          $L("Thoracic outlet syndrome"),
          $L("Tension headache and migraine"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Neck stabilization exercises"),
          relativeMuscle:
            $L("Rectus Capitis Anterior/Lateralis") +
            ", " +
            $L("Longus Colli") +
            ", " +
            $L("Longus Capitis"),
          effect: $L(
            "Maintaining good head posture through neck stabilization exercises"
          ),
          exception: [$L("If you have a headache and feel dizzy")],
          cautions: [
            $L(
              "Be careful not to raise your shoulders and get rounded shoulders."
            ),
            $L("Be careful not to feel tense around shoulders."),
            $L("목을 구부리지 않도록 주의합니다."),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "There is possibility of occurring with posterior head shift due to inappropriate pillow height. Please check whether the pillow height is appropriate."
          ),
          $L(
            "Posterior head shift may occur when the stand at attention such as a military posture is maintained for long time. The chin must not be pulled excessively."
          ),
          $L(
            "Perform stretching every 30 minutes to prevent excessive tension on the neck."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "There is possibility of occurring with posterior head shift due to inappropriate pillow height. Please check whether the pillow height is appropriate."
          ),
          $L(
            "Posterior head shift may occur when the stand at attention such as a military posture is maintained for long time. The chin must not be pulled excessively."
          ),
          $L(
            "Perform stretching every 30 minutes to prevent excessive tension on the neck."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Pain on the jaw joint (temporomandibular jointa) and disorder"),
          $L("Forward Head Posture"),
          $L("Cervical Syndrome"),
          $L("Myofascial pain syndrome"),
          $L("Herniated cervical disc"),
          $L("Tension headache and migraine"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Neck stabilization exercises"),
          relativeMuscle:
            $L("Rectus Capitis Anterior/Lateralis") +
            ", " +
            $L("Longus Colli") +
            ", " +
            $L("Longus Capitis"),
          effect: $L(
            "Maintaining good head posture through neck stabilization exercises"
          ),
          exception: [$L("If you have a headache and feel dizzy")],
          cautions: [
            "어깨가 올라가거나 앞으로 말리지 않도록 주의합니다.",
            "어깨에 힘이 들어가지 않도록 주의합니다.",
            "목을 구부리면서 당기지 않도록 주의합니다.",
          ],
        },
      },
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "Using the computer or smartphone for a long time in a slumped posture must be avoided."
          ),
          $L(
            "When working on the computer, adjust the height of the desk and monitor properly."
          ),
          $L(
            "Caution is required when using the pillow that is excessively high."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L("Make sure you are not overly tilting the body forward."),
          $L("Check if your shoulders are curled."),
          $L(
            "Using the computer or smartphone for a long time in a slumped posture must be avoided."
          ),
          $L(
            "When working on the computer, adjust the height of the desk and monitor properly."
          ),
          $L(
            "Caution is required when using the pillow that is excessively high."
          ),
          $L(
            "어깨가 과도하게 긴장하지 않도록 주기적으로 편안한 자세로 휴식을 취해주세요."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Myofascial pain syndrome"),
          $L("Round shoulder (Curled shoulder)"),
          $L("Rounder shoulder"),
          $L("Scapula alata"),
          $L("Spinal disc disorder"),
          $L("Thoracic outlet syndrome"),
          $L("Shoulder impingement syndrome"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Chest muscle stretching"),
          relativeMuscle: $L("Chest muscles"),
          effect: $L("Fixing rounded shoulders by stretching chest muscles"),
          exception: [
            $L("When feeling sharp pain when stretching"),
            $L("If your fingers feel numb"),
          ],
          cautions: [
            $L("Stretch within the possible range of motion."),
            $L(
              "Stop stretching immediately if you feel pain in your shoulder joints."
            ),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "When working on the computer, adjust the height of the desk and monitor properly."
          ),
          $L(
            "When the pillow height is inappropriate, the height must be adjusted. Please check whether the pillow height is appropriate."
          ),
          $L(
            "Caution must be taken not to tilt the shoulder excessively to the back."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L("Make sure you are not overly tilting the body backward."),
          $L(
            "Caution must be taken not to tilt the shoulder excessively to the back."
          ),
          $L(
            "When working on the computer, adjust the height of the desk and monitor properly."
          ),
          $L(
            "When the pillow height is inappropriate, the height must be adjusted. Please check whether the pillow height is appropriate."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Flat back"),
          $L("Myofascial pain syndrome"),
          $L("Scapula alata"),
          $L("Spinal disc disorder"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Knee push-ups"),
          relativeMuscle: $L("Pectoralis major"),
          effect: $L("Adjust the computer monitor height to your eye level."),
          exception: [$L("-")],
          cautions: [
            $L(
              "Be careful not to bend your waist excessively while stretching."
            ),
            $L("Exercises within the range that does not cause shoulder pain."),
          ],
        },
      },
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "High heels or elevator shoes can cause excessive anterior pelvic shift."
          ),
          $L(
            "Excessive anterior pelvic shift can be caused on the abdominal obesity body type, and so maintaining the proper body weight is recommended."
          ),
          $L({
            key: "_VIEW_DETAILS_Posture of stretching the back excessively can increase the anterior pelvic shift angle to require caution.",
            value:
              "Posture of stretching the back excessively can increase the anterior pelvic shift angle to require caution.",
          }),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "High heels or elevator shoes can cause excessive anterior pelvic shift."
          ),
          $L(
            "Excessive anterior pelvic shift can be caused on the abdominal obesity body type, and so maintaining the proper body weight is recommended."
          ),
          $L({
            key: "_VIEW_DETAILS_Posture of stretching the back excessively can increase the anterior pelvic shift angle to require caution.",
            value:
              "Posture of stretching the back excessively can increase the anterior pelvic shift angle to require caution.",
          }),
          $L(
            "골반이 전방으로 기울어지는 것을 예방하기 위해 장시간 엉덩이를 뒤로 빼고 과도하게 허리를 펴지 않았는지 확인해주세요."
          ),
          $L("과도하게 허리를 펴고 앉는 자세를 피해주세요."),
          $L("Check if you normally straighten the waist excessively."),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Hollow-back"),
          $L("Herniated lumbar disc or stenosis"),
          $L("Myofascial pain syndrome"),
          $L("Lumbar sprain"),
          $L("Spondylolisthesis"),
          $L("Hip Joint Impingement Syndrome"),
          $L("Facet Joint Syndrome"),
          $L("Sacroiliac Joint Syndrome"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Iliopsoas stretch"),
          relativeMuscle: $L("Iliopsoas"),
          effect: $L(
            "Fixing the anterior pelvic tilt by stretching the shortened psoas muscle"
          ),
          exception: [$L("무릎에 통증이 있는 경우")],
          cautions: [
            $L("See if you feel pain in the back while stretching."),
            $L(
              "스트레칭을 할 때 골반이 틀어지지 않고 바른 정렬을 유지하도록 합니다."
            ),
            $L("Do not to bend your waist excessively while stretching."),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "When sitting on a chair or on a sofa, please stretch your back to sit in a proper posture."
          ),
          $L(
            "To prevent posterior pelvic shift, sitting in a slumped posture for long period must be avoided."
          ),
          $L(
            "Instead of maintaining one posture for long time, change the posture regularly to reduce the stress on the joint."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "When sitting on a chair or on a sofa, please stretch your back to sit in a proper posture."
          ),
          $L("가급적 바닥이나 푹신한 소파보다는 의자에 앉는 것이 권장됩니다."),
          $L(
            "장시간 걸터앉는 습관은 골반이 후방으로 기울어질 가능성이 있어 올바른 자세로 앉아주세요."
          ),
          $L(
            "To prevent posterior pelvic shift, sitting in a slumped posture for long period must be avoided."
          ),
          $L("Be careful not to bend the waist excessively in everyday life."),
          $L(
            "쪼그리고 앉는 자세는 골반의 후방 기울기를 증가시킬 수 있어 주의가 필요합니다."
          ),
          $L(
            "평상시에 허리를 과도하게 굽힌 자세 보다는 올바른 자세로 앉아주세요."
          ),
          $L(
            "Instead of maintaining one posture for long time, change the posture regularly to reduce the stress on the joint."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Herniated lumbar disc or stenosis"),
          $L("Myofascial pain syndrome"),
          $L("Lumbar sprain"),
          $L("Retrospondylolisthesis"),
          $L("Hip Joint Impingement Syndrome"),
          $L("Facet Joint Syndrome"),
          $L("Sacroiliac Joint Syndrome"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Superman workout"),
          relativeMuscle:
            $L("Longissimus") +
            ", " +
            $L("Iliocoastalis") +
            ", " +
            $L("Spinalis"),
          effect: $L(
            "Fixing backward pelvis tilt by doing lumbar extensor strengthening exercises"
          ),
          exception: [$L("See if you feel back pain while working out.")],
          cautions: [
            $L(
              "Be careful as excessively stretching the waist may cause pain."
            ),
            $L(
              "목이 과도하게 젖혀지거나 구부러지지 않고 척추와 일직선이 되도록 중립자세를 유지합니다."
            ),
          ],
        },
      },
    },
    [MENUTYPE.KNEE_FLEXION]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "Hips and back must be in contact with the back of a chair when sitting."
          ),
          $L(
            "The height of the chair must be adjusted to have the feet in contact with the floor."
          ),
          $L(
            "Please change your posture regularly to reduce the strain on the knee joint."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "한 자세를 오랫동안 유지하는 것 보다는 30분마다 자리에서 일어나 스트레칭을 해주세요."
          ),
          $L(
            "좌식 생활 습관은 무릎 관절에 영향을 줄 수 있어 가급적 피하는 것이 좋습니다."
          ),
          $L(
            "We recommend sitting on a chair instead of sitting on the floor."
          ),
          $L(
            "좌식 생활 습관은 무릎 관절에 영향을 줄 수 있어 오랜 시간 양반다리 자세를 피하는 것이 좋습니다."
          ),
          $L("다리를 꼬지 않고 올바른 자세로 앉아주세요."),
          $L(
            "Hips and back must be in contact with the back of a chair when sitting."
          ),
          $L(
            "The height of the chair must be adjusted to have the feet in contact with the floor."
          ),
          $L({
            key: "_Adjust the chair height so soles of your feet touch the floor.",
            value:
              "Adjust the chair height so soles of your feet touch the floor.",
          }),
          $L(
            "걸레질과 같은 집안일을 할 경우 무릎을 꿇기보다는 밀대를 사용하는 것이 좋습니다."
          ),
          $L(
            "한 자세를 오랫동안 유지하는 것 보다는 30분마다 자리에서 일어나 스트레칭을 해주세요."
          ),
          $L(
            "Please change your posture regularly to reduce the strain on the knee joint."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Plantar fasciitis"),
          $L("Achilles tendinitis"),
          $L("Degenerative knee arthritis"),
          $L("Meniscal Tear"),
          $L("Patellofemoral Pain Syndrome (PFPS)") +
            ", " +
            $L("Myofascial pain syndrome"),
          $L("Hip Joint Impingement Syndrome") + ", " + $L("Kyphosis"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("(옆으로 누워) 앞쪽 허벅지 스트레칭"),
          relativeMuscle: $L("Quadriceps Femoris"),
          effect: $L("Quadricep stretch"),
          exception: [
            $L("When feeling sharp pain when stretching"),
            $L("If you feel pain in your lower shoulder"),
            $L("다리를 뒤로 당길 시 허리의 통증으로 인해 동작이 제한되는 경우"),
          ],
          cautions: [
            $L(
              "Stop stretching immediately if you feel pain around your knee."
            ),
            $L("Be careful not to tilt the pelvis backward when stretching."),
            $L("Do not to bend your waist excessively when stretching."),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.CES_LIFE_STYLE]: [
          $L(
            "Shoes with high heels can give stress to the knees, so wearing comfortable shoes is recommended."
          ),
          $L(
            "Movement of having the belly sticking out excessively can cause excessive stretching, so please maintain proper spinal alignment."
          ),
          $L(
            "Leaning on one leg can have influence on the knee joint, so balancing the body weight while standing is recommended."
          ),
        ],
        [FEEDBACK_TYPE.LIFE_STYLE]: [
          $L(
            "Shoes with high heels can give stress to the knees, so wearing comfortable shoes is recommended."
          ),
          $L(
            "Movement of having the belly sticking out excessively can cause excessive stretching, so please maintain proper spinal alignment."
          ),
          $L(
            "Leaning on one leg can have influence on the knee joint, so balancing the body weight while standing is recommended."
          ),
          $L("Be careful not to stand by putting your weight on one leg."),
          $L(
            "걸을 때 양쪽 다리에 균등하게 체중이 실리도록 하는 것이 권장됩니다."
          ),
          $L(
            "한 자세를 오랫동안 유지하는 것 보다는 30분마다 자리에서 일어나 스트레칭을 해주세요."
          ),
          $L(
            "Change your posture regularly to reduce strain on your knee joints."
          ),
        ],
        [FEEDBACK_TYPE.DISEASE]: [
          $L("Degenerative knee arthritis"),
          $L("Patellofemoral Pain Syndrome (PFPS)"),
          $L("Myofascial pain syndrome"),
          $L("Back knee (genu recurvatum)"),
          $L("Plantar fasciitis") + ", " + $L("Achilles tendinitis"),
          $L("Hollow-back"),
          $L("Arthritis of the hip joint"),
        ],
        [FEEDBACK_TYPE.EXERCISE]: {
          name: $L("Towel pressing workouts"),
          relativeMuscle: $L("Quadriceps Femoris"),
          effect: $L(
            "Stabilizing areas around knee joints by activating waist muscles."
          ),
          exception: [$L("-")],
          cautions: [
            $L(
              "If you feel pain around your knee bones, lower the intensity of the workout."
            ),
            $L(
              "If you can't straighten your leg joint completely, try raising the towel upward."
            ),
          ],
        },
      },
    },
  };
};

export const getViewDetailFeedbackMuscleGuide = () => {
  return {
    [MENUTYPE.NECK_TILT]: {
      part: $L("Neck"),
      [FEEDBACK_TYPE.TIGHT]: {
        muscleArr: [$L("SCM(sternocleidomastoid)"), $L("Scalene")],
        feedback: $L(
          "{part} {position}이 높으면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.ELONGATED]: {
        muscleArr: [
          `${$L("Opposite side")} ${$L("SCM(sternocleidomastoid)")}`,
          `${$L("Opposite side")} ${$L("Scalene")}`,
        ],
        feedback: $L(
          "{part} {position}이 높으면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.STABILIZING]: {
        muscleArr: [
          $L("Rectus Capitis Anterior/Lateralis"),
          $L("Longus Colli"),
          $L("Longus Capitis"),
        ],
        feedback: $L(
          "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
        ),
      },
    },
    [MENUTYPE.SHOULDER_TILT]: {
      part: $L("Shoulder"),
      [FEEDBACK_TYPE.TIGHT]: {
        muscleArr: [$L("Upper Trapezius"), $L("Levator Scapular")],
        feedback: $L(
          "{part} {position}이 높으면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.ELONGATED]: {
        muscleArr: [$L("Lower Trapezius"), $L("Latissimus Dorsi")],
        feedback: $L(
          "{part} {position}이 높으면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.STABILIZING]: {
        muscleArr: [
          $L("Serratus Anterior"),
          $L("Supraspinatus"),
          $L("Infraspinatus"),
          $L("Teres Minor"),
          $L("Posterior Deltoid"),
        ],
        feedback: $L(
          "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
        ),
      },
    },
    [MENUTYPE.PELVIC_TILT]: {
      part: $L("Pelvic"),
      [FEEDBACK_TYPE.TIGHT]: {
        muscleArr: [
          $L("Quadratus Lumborum"),
          $L("Adductor Longus"),
          $L("Adductor Brevis"),
          $L("Adductor Magnus"),
        ],
        feedback: $L(
          "{part} {position}이 높으면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.ELONGATED]: {
        muscleArr: [
          $L("Gluteus Medius"),
          `${$L("Opposite side")} ${$L("Quadratus Lumborum")}`,
        ],
        feedback: $L(
          "{part} {position}이 높으면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.STABILIZING]: {
        muscleArr: [$L("Gluteus Medius"), $L("Transverse Abdominis")],
        feedback: $L(
          "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
        ),
      },
    },
    [MENUTYPE.PELVIC_ROTATION]: {
      // 왼쪽이 앞으로 나올 경우, detailKey가 오른쪽이라고 뜸
      part: $L("Pelvic"),
      [FEEDBACK_TYPE.TIGHT]: {
        muscleArr: {
          right: [
            `${$L("Right")} ${$L("Internal Oblique")}`,
            `${$L("Left")} ${$L("External Oblique")}`,
            `${$L("Right")} ${$L("Adductor Longus")}`,
            `${$L("Right")} ${$L("Adductor Brevis")}`,
          ],
          left: [
            `${$L("Left")} ${$L("Internal Oblique")}`,
            `${$L("Right")} ${$L("External Oblique")}`,
            `${$L("Left")} ${$L("Adductor Longus")}`,
            `${$L("Left")} ${$L("Adductor Brevis")}`,
          ],
        },
        feedback: $L(
          "{part} {position}이 앞으로 이동했다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.ELONGATED]: {
        muscleArr: {
          right: [
            `${$L("Right")} ${$L("External Oblique")}`,
            `${$L("Left")} ${$L("Internal Oblique")}`,
            `${$L("Right")} ${$L("Piriformis")}`,
          ],
          left: [
            `${$L("Left")} ${$L("External Oblique")}`,
            `${$L("Right")} ${$L("Internal Oblique")}`,
            `${$L("Left")} ${$L("Piriformis")}`,
          ],
        },
        feedback: $L(
          "{part} {position}이 앞으로 이동했다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
        ),
      },
      [FEEDBACK_TYPE.STABILIZING]: {
        muscleArr: [$L("Gluteus Medius"), $L("Transverse Abdominis")],
        feedback: $L(
          "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
        ),
      },
    },
    [MENUTYPE.LEG_ALIGNMENT]: {
      part: $L("Leg"),
      [MENUTYPE_TEST.BOW_LEG]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [
            $L("Adductor Longus"),
            $L("Adductor Brevis"),
            $L("Adductor Magnus"),
            $L("Gracilis"),
          ],
          feedback: $L(
            "{part}가 O자로 휘어졌다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [$L("Gluteus Maximus"), $L("Piriformis")],
          feedback: $L(
            "{part}가 O자로 휘어졌다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Gluteus Medius"),
            $L("Quadriceps Femoris"),
            $L("Rectus Femoris"),
            $L("Vastus Lateralis"),
            $L("Vastus Medialis"),
            $L("Vastus Intermedius"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
      [MENUTYPE_TEST.KNOCK_KNEE]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [$L("Tensor Fascia Latae"), $L("Biceps Femoris")],
          feedback: $L(
            "{part}가 X자로 휘어졌다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [
            $L("Adductor Longus"),
            $L("Adductor Brevis"),
            $L("Adductor Magnus"),
          ],
          feedback: $L(
            "{part}가 X자로 휘어졌다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Gluteus Medius"),
            $L("Quadriceps Femoris"),
            $L("Rectus Femoris"),
            $L("Vastus Lateralis"),
            $L("Vastus Medialis"),
            $L("Vastus Intermedius"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
    },
    [MENUTYPE.HEAD_SHIFT]: {
      part: $L("Neck"), // 목으로 표현 요청, 수정 X
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [
            $L("SCM(sternocleidomastoid)"),
            $L("Rectus Capitis Posterior Major/Minor"),
            $L("Obliquus Capitis Superior/Inferior"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [
            $L("Rectus Capitis Anterior/Lateralis"),
            $L("Longus Colli"),
            $L("Longus Capitis"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Rectus Capitis Anterior/Lateralis"),
            $L("Longus Colli"),
            $L("Longus Capitis"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [
            $L("Splenius Capitis"),
            $L("Longissimus Captis"),
            $L("Semispinalis Capitis"),
            $L("Splenius Cervicis"),
            $L("Semispinalis Cervicis"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [
            $L("Rectus Capitis Posterior Major/Minor"),
            $L("Obliquus Capitis Superior/Inferior"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Rectus Capitis Anterior/Lateralis"),
            $L("Longus Colli"),
            $L("Longus Capitis"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      part: $L("Shoulder"),
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [$L("Pectoralis Major/Minor"), $L("Serratus Anterior")],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [$L("Rhomboid Major/Minor"), $L("Lower Trapezius")],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Serratus Anterior"),
            $L("Supraspinatus"),
            $L("Infraspinatus"),
            $L("Teres Minor"),
            $L("Posterior Deltoid"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [
            $L("Rhomboid Major/Minor"),
            $L("Longissimus"),
            $L("Iliocoastalis"),
            $L("Spinalis"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [$L("Pectoralis Major/Minor"), $L("Serratus Anterior")],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Serratus Anterior"),
            $L("Supraspinatus"),
            $L("Infraspinatus"),
            $L("Teres Minor"),
            $L("Posterior Deltoid"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      part: $L("Pelvic"),
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [$L("Iliopsoas"), $L("Rectus Femoris")],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [$L("Gluteus Maximus"), $L("Rectus Abdominis")],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Transverse Abdominis"),
            $L("Pelvic floor muscle group"),
            $L("Diaphragm"),
            $L("Multifidus"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [
            $L("Biceps Femoris"),
            $L("Semimembranosus"),
            $L("Semitendinosus"),
            $L("Rectus Abdominis"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [
            $L("Iliopsoas"),
            $L("Longissimus"),
            $L("Iliocoastalis"),
            $L("Spinalis"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Transverse Abdominis"),
            $L("Pelvic floor muscle group"),
            $L("Diaphragm"),
            $L("Multifidus"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
    },
    [MENUTYPE.KNEE_FLEXION]: {
      part: $L("Knee"),
      [MENUTYPE_TEST.ANTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [
            $L("Biceps Femoris"),
            $L("Semimembranosus"),
            $L("Semitendinosus"),
            $L("Popliteus"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [
            $L("Quadriceps Femoris"),
            $L("Rectus Femoris"),
            $L("Vastus Lateralis"),
            $L("Vastus Medialis"),
            $L("Vastus Intermedius"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Quadriceps Femoris"),
            $L("Rectus Femoris"),
            $L("Vastus Lateralis"),
            $L("Vastus Medialis"),
            $L("Vastus Intermedius"),
            $L("Popliteus"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [FEEDBACK_TYPE.TIGHT]: {
          muscleArr: [
            $L("Quadriceps Femoris"),
            $L("Vastus Lateralis"),
            $L("Vastus Medialis"),
            $L("Vastus Intermedius"),
            $L("Soleus"),
            $L("Iliopsoas"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 단축되었을 가능성이 높습니다. 관련 근육 이완 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.ELONGATED]: {
          muscleArr: [
            $L("Biceps Femoris"),
            $L("Semimembranosus"),
            $L("Semitendinosus"),
            $L("Gastrocnemius"),
            $L("Rectus Femoris"),
            $L("Gluteus Maximus"),
          ],
          feedback: $L(
            "{part} {position}으로 기울어져있다면 {muscleArr}이 신장되었을 가능성이 높습니다. 관련 근육 강화 운동이 필요합니다."
          ),
        },
        [FEEDBACK_TYPE.STABILIZING]: {
          muscleArr: [
            $L("Quadriceps Femoris"),
            $L("Rectus Femoris"),
            $L("Vastus Lateralis"),
            $L("Vastus Medialis"),
            $L("Vastus Intermedius"),
            $L("Popliteus"),
          ],
          feedback: $L(
            "{part} 안정화를 위해서는 {muscleArr}을 강화하려는 노력이 필요합니다."
          ),
        },
      },
    },
  };
};

export const getSummaryFeedback = () => {
  return {
    GOOD: {
      mainFeedback: $L(
        "Your body is well balanced. Keep your body balance through muscle workout and stretching. Have you often hunched during the winter to keep warm? Hunching may have caused neck stiffness. Today, a workout that relieves tense neck muscles is recommended for you"
      ),
      EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
      ASSOCIATED_MUSCLES: $L("SCM(Sternocleidomastoid), Capital flexor group, etc."),
      EXERCISE_EFFECT: $L(
        "Moving the head relieves tense (stiff) neck muscles."
      ),
      EXERCISE_TIME: 597,
      VIDEO_INDEX: "NECK_01",
      VIDEO_THUMBNAIL: neck_01_01,
      IMAGE: [lg_head_shift_anterior_front, lg_head_shift_anterior_back],
    },
    ONE_PART_WEAK: {
      mainFeedback: $L(
        "Your body is well balanced. If you improve balance in {bodyPart}, you will have better body alignment."
      ),
      [MENUTYPE.NECK_TILT]: {
        subFeedback: $L(
          "Let’s strengthen the muscles associated with neck alignment."
        ),
        EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
        ASSOCIATED_MUSCLES: $L("SCM(sternocleidomastoid), Scalene, etc."),
        EXERCISE_EFFECT: $L(
          "Moving the head relieves tense (stiff) neck muscles."
        ),
        EXERCISE_TIME: 597,
        VIDEO_INDEX: "NECK_01",
        VIDEO_THUMBNAIL: neck_01_01,
        IMAGE: {
          LEFT: [lg_neck_tilt_front_tight_left, lg_neck_tilt_back_tight_left],
          RIGHT: [
            lg_neck_tilt_front_tight_right,
            lg_neck_tilt_back_tight_right,
          ],
        },
      },
      [MENUTYPE.SHOULDER_TILT]: {
        subFeedback: $L(
          "Let’s strengthen the muscles associated with shoulder alignment."
        ),
        EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
        ASSOCIATED_MUSCLES: $L("Upper Trapezius, Latissimus dorsi, etc."),
        EXERCISE_EFFECT: $L(
          "An exercise that relieves neck and shoulders. Good for people with a sore neck and shoulders."
        ),
        EXERCISE_TIME: 282,
        VIDEO_INDEX: "SHOULDER_04",
        VIDEO_THUMBNAIL: shoulder_04_14,
        IMAGE: {
          LEFT: [
            lg_shoulder_tilt_front_tight_high_left,
            lg_shoulder_tilt_back_high_left,
          ],
          RIGHT: [
            lg_shoulder_tilt_front_tight_high_right,
            lg_shoulder_tilt_back_high_right,
          ],
        },
      },
      [MENUTYPE.PELVIC_TILT]: {
        subFeedback: $L(
          "Let’s strengthen the muscles associated with pelvic alignment."
        ),
        EXERCISE_NAME: $L("Balance-enhancing exercises"),
        ASSOCIATED_MUSCLES: $L("Quadratus Lumborum, Hip Adductor Group, etc."),
        EXERCISE_EFFECT: $L(
          "It improves your balance. It improves flexibility of hip joints and strengthens the sides."
        ),
        EXERCISE_TIME: 228,
        VIDEO_INDEX: "PELVIC_01",
        VIDEO_THUMBNAIL: pelvic_01_05,
        IMAGE: {
          LEFT: [
            lg_lateral_pelvic_tilt_front_high_left,
            lg_lateral_pelvic_tilt_back_high_left,
          ],
          RIGHT: [
            lg_lateral_pelvic_tilt_front_tight_high_right,
            lg_lateral_pelvic_tilt_back_high_right,
          ],
        },
      },
      [MENUTYPE.LEG_ALIGNMENT]: {
        [MENUTYPE_TEST.BOW_LEG]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises for better balance"),
          ASSOCIATED_MUSCLES: $L("Hip Adductor Group, Gracilis, etc."),
          EXERCISE_EFFECT: $L("It improves the full body stability."),
          EXERCISE_TIME: 158,
          VIDEO_INDEX: "LEG_03",
          VIDEO_THUMBNAIL: leg_03_09,
          IMAGE: [lg_bow_leg_front, lg_bow_leg_back],
        },
        [MENUTYPE_TEST.KNOCK_KNEE]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises for better balance"),
          ASSOCIATED_MUSCLES: $L("Tensor Fascia Latae, Biceps femoris, etc."),
          EXERCISE_EFFECT: $L("It improves the full body stability."),
          EXERCISE_TIME: 158,
          VIDEO_INDEX: "LEG_03",
          VIDEO_THUMBNAIL: leg_03_09,
          IMAGE: [lg_knock_knee_front, lg_knock_knee_back],
        },
      },
      [MENUTYPE.HEAD_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with neck alignment."
          ),
          EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
          ASSOCIATED_MUSCLES: $L("SCM(Sternocleidomastoid), Capital flexor group, etc."),
          EXERCISE_EFFECT: $L(
            "Moving the head relieves tense (stiff) neck muscles."
          ),
          EXERCISE_TIME: 597,
          VIDEO_INDEX: "NECK_01",
          VIDEO_THUMBNAIL: neck_01_01,
          IMAGE: [lg_head_shift_anterior_front, lg_head_shift_anterior_back],
          // subFeedback: $L(
          //   "Let’s strengthen the muscles associated with neck alignment."
          // ),
          // EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
          // ASSOCIATED_MUSCLES: $L("SCM(Sternocleidomastoid), Suboccipitalis Group, etc."),
          // EXERCISE_EFFECT: $L(
          //   "An exercise great for neck stability. Good for people with rounded shoulders, a straight and stiff neck."
          // ),
          // EXERCISE_TIME: 358,
          // VIDEO_INDEX: "NECK_02",
          // VIDEO_THUMBNAIL: neck_02_12,
          // IMAGE: [lg_head_shift_anterior_front, lg_head_shift_anterior_back],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with neck alignment."
          ),
          EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
          ASSOCIATED_MUSCLES: $L("Cervical erector spinae muscle group, etc."),
          EXERCISE_EFFECT: $L(
            "Moving the head relieves tense (stiff) neck muscles."
          ),
          EXERCISE_TIME: 597,
          VIDEO_INDEX: "NECK_01",
          VIDEO_THUMBNAIL: neck_01_01,
          IMAGE: [lg_head_shift_posterior_front, lg_head_shift_posterior_back],
          // subFeedback: $L(
          //   "Let’s strengthen the muscles associated with neck alignment."
          // ),
          // EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
          // ASSOCIATED_MUSCLES: $L(
          //   "Cervical erector spinae muscle group, Suboccipitalis Group, etc."
          // ),
          // EXERCISE_EFFECT: $L(
          //   "An exercise great for neck stability. Good for people with rounded shoulders, a straight and stiff neck."
          // ),
          // EXERCISE_TIME: 358,
          // VIDEO_INDEX: "NECK_02",
          // VIDEO_THUMBNAIL: neck_02_12,
          // IMAGE: [lg_head_shift_posterior_front, lg_head_shift_posterior_back],
        },
      },
      [MENUTYPE.TRUNK_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with shoulder alignment."
          ),
          EXERCISE_NAME: $L("Shoulder-strengthening exercises for improved postures"),
          ASSOCIATED_MUSCLES: $L(
            "Pectoralis Major/Minor, Rhomboid Major/Minor, etc."
          ),
          EXERCISE_EFFECT: $L(
            "Motions good for strengthening shoulders and blood circulation. Straightens your back and relaxes your shoulders."
          ),
          EXERCISE_TIME: 420,
          VIDEO_INDEX: "SHOULDER_03",
          VIDEO_THUMBNAIL: shoulder_03_13,
          IMAGE: [
            lg_shoulder_shift_anterior_front,
            lg_shoulder_shift_anterior_back,
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with shoulder alignment."
          ),
          EXERCISE_NAME: $L(
            "Upper body strengthening exercises using resistance"
          ),
          ASSOCIATED_MUSCLES: $L(
            "Pectoralis major, Erector Spinae Muscle Group, etc."
          ),
          EXERCISE_EFFECT: $L(
            "Strengthens the upper body muscles and shoulders using palm resistance."
          ),
          EXERCISE_TIME: 525,
          VIDEO_INDEX: "SHOULDER_02",
          VIDEO_THUMBNAIL: shoulder_02_10,
          IMAGE: [
            lg_shoulder_shift_posterior_front,
            lg_shoulder_shift_posterior_back,
          ],
        },
      },
      [MENUTYPE.PELVIC_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with pelvic alignment."
          ),
          EXERCISE_NAME: $L("Exercises to improve hip joint flexibility"),
          ASSOCIATED_MUSCLES: $L("Lliopsoas, Gluteus maximus, etc."),
          EXERCISE_EFFECT: $L(
            "Motions necessary to balance your body that’s good for strengthening hip joints and abs."
          ),
          EXERCISE_TIME: 300,
          VIDEO_INDEX: "PELVIC_02",
          VIDEO_THUMBNAIL: pelvic_02_15,
          IMAGE: [
            lg_pelvic_shift_anterior_front,
            lg_pelvic_shift_anterior_back,
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with pelvic alignment."
          ),
          EXERCISE_NAME: $L("Exercises to enhance lower body balance"),
          ASSOCIATED_MUSCLES: $L("Hamstring Group, Rectus Abdominis, etc."),
          EXERCISE_EFFECT: $L(
            "Motions necessary to balance your body that’s good for strengthening hip joints and abs."
          ),
          EXERCISE_TIME: 257,
          VIDEO_INDEX: "PELVIC_03",
          VIDEO_THUMBNAIL: pelvic_03_16,
          IMAGE: [
            lg_pelvic_shift_posterior_front,
            lg_pelvic_shift_posterior_back,
          ],
        },
      },
      [MENUTYPE.KNEE_FLEXION]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Stretching exercises for your legs "),
          ASSOCIATED_MUSCLES: $L("Quadriceps Femoris, Popliteus, etc."),
          EXERCISE_EFFECT: $L(
            "Stretching improves the flexibility of your ankles and calves and facilitates blood circulation in the lower body."
          ),
          EXERCISE_TIME: 312,
          VIDEO_INDEX: "KNEE_01",
          VIDEO_THUMBNAIL: knee_01_04,
          IMAGE: [lg_knee_flexion_front, lg_knee_flexion_back],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "Let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises"),
          ASSOCIATED_MUSCLES: $L("Quadriceps Femoris, Hamstring Group, etc."),
          EXERCISE_EFFECT: $L(
            "Strengthens the butt and the lower body to prevent yourself from falling and improve a sense of balance."
          ),
          EXERCISE_TIME: 357,
          VIDEO_INDEX: "LEG_01",
          VIDEO_THUMBNAIL: leg_01_02,
          IMAGE: [lg_knee_extension_front, lg_knee_extension_back],
        },
      },
    },
    FRONT_WEAK: {
      mainFeedback: $L(
        "How about improving balance on the front side of the body? You need to improve balance in {bodyParts}. An imbalance in one part of the body may have affected other parts."
      ),
      [MENUTYPE.NECK_TILT]: {
        subFeedback: $L(
          "In particular, your neck may be tilted to one side, so let’s strengthen the muscles associated with neck alignment."
        ),
        EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
        ASSOCIATED_MUSCLES: $L("SCM(sternocleidomastoid), Scalene, etc."),
        EXERCISE_EFFECT: $L(
          "Moving the head relieves tense (stiff) neck muscles."
        ),
        EXERCISE_TIME: 597,
        VIDEO_INDEX: "NECK_01",
        VIDEO_THUMBNAIL: neck_01_01,
        IMAGE: {
          LEFT: [lg_neck_tilt_front_tight_left, lg_neck_tilt_back_tight_left],
          RIGHT: [
            lg_neck_tilt_front_tight_right,
            lg_neck_tilt_back_tight_right,
          ],
        },
      },
      [MENUTYPE.SHOULDER_TILT]: {
        subFeedback: $L(
          "In particular, your shoulders may be tilted to one side, so let’s strengthen the muscles associated with shoulder alignment."
        ),
        EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
        ASSOCIATED_MUSCLES: $L("Upper Trapezius, Latissimus dorsi, etc."),
        EXERCISE_EFFECT: $L(
          "An exercise that relieves neck and shoulders. Good for people with a sore neck and shoulders."
        ),
        EXERCISE_TIME: 282,
        VIDEO_INDEX: "SHOULDER_04",
        VIDEO_THUMBNAIL: shoulder_04_14,
        IMAGE: {
          LEFT: [
            lg_shoulder_tilt_front_tight_high_left,
            lg_shoulder_tilt_back_high_left,
          ],
          RIGHT: [
            lg_shoulder_tilt_front_tight_high_right,
            lg_shoulder_tilt_back_high_right,
          ],
        },
      },
      [MENUTYPE.PELVIC_TILT]: {
        subFeedback: $L(
          "In particular, your pelvis may be tilted to one side, so let’s strengthen the muscles associated with pelvic alignment."
        ),
        EXERCISE_NAME: $L("Balance-enhancing exercises"),
        ASSOCIATED_MUSCLES: $L("Quadratus Lumborum, Hip Adductor Group, etc."),
        EXERCISE_EFFECT: $L(
          "It improves your balance. It improves flexibility of hip joints and strengthens the sides."
        ),
        EXERCISE_TIME: 228,
        VIDEO_INDEX: "PELVIC_01",
        VIDEO_THUMBNAIL: pelvic_01_05,
        IMAGE: {
          LEFT: [
            lg_lateral_pelvic_tilt_front_high_left,
            lg_lateral_pelvic_tilt_back_high_left,
          ],
          RIGHT: [
            lg_lateral_pelvic_tilt_front_tight_high_right,
            lg_lateral_pelvic_tilt_back_high_right,
          ],
        },
      },
      [MENUTYPE.LEG_ALIGNMENT]: {
        [MENUTYPE_TEST.BOW_LEG]: {
          subFeedback: $L(
            "In particular, your legs may be bent outward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises for better balance"),
          ASSOCIATED_MUSCLES: $L("Hip Adductor Group, Gracilis, etc."),
          EXERCISE_EFFECT: $L("It improves the full body stability."),
          EXERCISE_TIME: 158,
          VIDEO_INDEX: "SHOULDER_01",
          VIDEO_THUMBNAIL: shoulder_01_07,
          IMAGE: [lg_bow_leg_front, lg_bow_leg_back],
        },
        [MENUTYPE_TEST.KNOCK_KNEE]: {
          subFeedback: $L(
            "In particular, your legs may be bent inward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises for better balance"),
          ASSOCIATED_MUSCLES: $L("Tensor Fascia Latae, Biceps femoris, etc."),
          EXERCISE_EFFECT: $L("It improves the full body stability."),
          EXERCISE_TIME: 158,
          VIDEO_INDEX: "LEG_03",
          VIDEO_THUMBNAIL: leg_03_09,
          IMAGE: [lg_knock_knee_front, lg_knock_knee_back],
        },
      },
    },
    SIDE_WEAK: {
      mainFeedback: $L(
        "How about improving balance on the sides of the body? You need to improve balance in {bodyParts}. An imbalance in one part of the body may have affected other parts."
      ),
      [MENUTYPE.HEAD_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          // subFeedback: $L(
          //   "In particular, your neck may be tilted forward, so let’s strengthen the muscles associated with neck alignment."
          // ),
          // EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
          // ASSOCIATED_MUSCLES: $L("SCM(Sternocleidomastoid), Suboccipitalis Group, etc."),
          // EXERCISE_EFFECT: $L(
          //   "An exercise great for neck stability. Good for people with rounded shoulders, a straight and stiff neck."
          // ),
          // EXERCISE_TIME: 358,
          // VIDEO_INDEX: "NECK_02",
          // VIDEO_THUMBNAIL: neck_02_12,
          subFeedback: $L(
            "Let’s strengthen the muscles associated with neck alignment."
          ),
          EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
          ASSOCIATED_MUSCLES: $L("SCM(Sternocleidomastoid), Capital flexor group, etc."),
          EXERCISE_EFFECT: $L(
            "Moving the head relieves tense (stiff) neck muscles."
          ),
          EXERCISE_TIME: 597,
          VIDEO_INDEX: "NECK_01",
          VIDEO_THUMBNAIL: neck_01_01,
          IMAGE: [lg_head_shift_anterior_front, lg_head_shift_anterior_back],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          // subFeedback: $L(
          //   "In particular, your neck may be tilted forward, so let’s strengthen the muscles associated with neck alignment."
          // ),
          // EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
          // ASSOCIATED_MUSCLES: $L("Cervical erector spinae muscle group, Suboccipitalis Group, etc."),
          // EXERCISE_EFFECT: $L(
          //   "An exercise great for neck stability. Good for people with rounded shoulders, a straight and stiff neck."
          // ),
          // EXERCISE_TIME: 358,
          // VIDEO_INDEX: "NECK_02",
          // VIDEO_THUMBNAIL: neck_02_12,
          subFeedback: $L(
            "Let’s strengthen the muscles associated with neck alignment."
          ),
          EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
          ASSOCIATED_MUSCLES: $L("Cervical erector spinae muscle group, etc."),
          EXERCISE_EFFECT: $L(
            "Moving the head relieves tense (stiff) neck muscles."
          ),
          EXERCISE_TIME: 597,
          VIDEO_INDEX: "NECK_01",
          VIDEO_THUMBNAIL: neck_01_01,
          IMAGE: [lg_head_shift_posterior_front, lg_head_shift_posterior_back],
        },
      },
      [MENUTYPE.TRUNK_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "In particular, your shoulders may be tilted forward, so let’s strengthen the muscles associated with shoulder alignment."
          ),
          EXERCISE_NAME: $L("Shoulder-strengthening exercises for improved postures"),
          ASSOCIATED_MUSCLES: $L(
            "Pectoralis Major/Minor, Rhomboid Major/Minor, etc."
          ),
          EXERCISE_EFFECT: $L(
            "Motions good for strengthening shoulders and blood circulation. Straightens your back and relaxes your shoulders."
          ),
          EXERCISE_TIME: 420,
          VIDEO_INDEX: "SHOULDER_03",
          VIDEO_THUMBNAIL: shoulder_03_13,
          IMAGE: [
            lg_shoulder_shift_anterior_front,
            lg_shoulder_shift_anterior_back,
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "In particular, your shoulders may be tilted forward, so let’s strengthen the muscles associated with shoulder alignment."
          ),
          EXERCISE_NAME: $L(
            "Upper body strengthening exercises using resistance"
          ),
          ASSOCIATED_MUSCLES: $L(
            "Pectoralis major, Erector Spinae Muscle Group, etc."
          ),
          EXERCISE_EFFECT: $L(
            "Strengthens the upper body muscles and shoulders using palm resistance."
          ),
          EXERCISE_TIME: 525,
          VIDEO_INDEX: "SHOULDER_02",
          VIDEO_THUMBNAIL: shoulder_02_10,
          IMAGE: [
            lg_shoulder_shift_posterior_front,
            lg_shoulder_shift_posterior_back,
          ],
        },
      },
      [MENUTYPE.PELVIC_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "In particular, your pelvis may be tilted forward, so let’s strengthen the muscles associated with pelvic alignment."
          ),
          EXERCISE_NAME: $L("Exercises to improve hip joint flexibility"),
          ASSOCIATED_MUSCLES: $L("Lliopsoas, Gluteus maximus, etc."),
          EXERCISE_EFFECT: $L(
            "Motions necessary to balance your body that’s good for strengthening hip joints and abs."
          ),
          EXERCISE_TIME: 300,
          VIDEO_INDEX: "PELVIC_02",
          VIDEO_THUMBNAIL: pelvic_02_15,
          IMAGE: [
            lg_pelvic_shift_anterior_front,
            lg_pelvic_shift_anterior_back,
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "In particular, your pelvis may be tilted forward, so let’s strengthen the muscles associated with pelvic alignment."
          ),
          EXERCISE_NAME: $L("Exercises to enhance lower body balance"),
          ASSOCIATED_MUSCLES: $L("Hamstring Group, Rectus Abdominis, etc."),
          EXERCISE_EFFECT: $L(
            "Motions necessary to balance your body that’s good for strengthening hip joints and abs."
          ),
          EXERCISE_TIME: 257,
          VIDEO_INDEX: "PELVIC_03",
          VIDEO_THUMBNAIL: pelvic_03_16,
          IMAGE: [
            lg_pelvic_shift_posterior_front,
            lg_pelvic_shift_posterior_back,
          ],
        },
      },
      [MENUTYPE.KNEE_FLEXION]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "In particular, your knees may be protruded forward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Stretching exercises for your legs "),
          ASSOCIATED_MUSCLES: $L("Quadriceps Femoris, Popliteus, etc."),
          EXERCISE_EFFECT: $L(
            "Stretching improves the flexibility of your ankles and calves and facilitates blood circulation in the lower body."
          ),
          EXERCISE_TIME: 312,
          VIDEO_INDEX: "KNEE_01",
          VIDEO_THUMBNAIL: knee_01_04,
          IMAGE: [lg_knee_flexion_front, lg_knee_flexion_back],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "In particular, your knees may be protruded forward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises"),
          ASSOCIATED_MUSCLES: $L("Quadriceps Femoris, Hamstring Group, etc."),
          EXERCISE_EFFECT: $L(
            "Strengthens the butt and the lower body to prevent yourself from falling and improve a sense of balance."
          ),
          EXERCISE_TIME: 357,
          VIDEO_INDEX: "LEG_01",
          VIDEO_THUMBNAIL: leg_01_02,
          IMAGE: [lg_knee_extension_front, lg_knee_extension_back],
        },
      },
    },
    MULTY_WEAK: {
      mainFeedback: $L(
        "How about improving balance in {bodyParts}. An imbalance in one part of the body may have affected other parts."
      ),
      [MENUTYPE.NECK_TILT]: {
        subFeedback: $L(
          "In particular, your neck may be tilted to one side, so let’s strengthen the muscles associated with neck alignment."
        ),
        EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
        ASSOCIATED_MUSCLES: $L("SCM(sternocleidomastoid), Scalene, etc."),
        EXERCISE_EFFECT: $L(
          "Moving the head relieves tense (stiff) neck muscles."
        ),
        EXERCISE_TIME: 597,
        VIDEO_INDEX: "NECK_01",
        VIDEO_THUMBNAIL: neck_01_01,
        IMAGE: {
          LEFT: [lg_neck_tilt_front_tight_left, lg_neck_tilt_back_tight_left],
          RIGHT: [
            lg_neck_tilt_front_tight_right,
            lg_neck_tilt_back_tight_right,
          ],
        },
      },
      [MENUTYPE.SHOULDER_TILT]: {
        subFeedback: $L(
          "In particular, your shoulders may be tilted to one side, so let’s strengthen the muscles associated with shoulder alignment."
        ),
        EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
        ASSOCIATED_MUSCLES: $L("Upper Trapezius, Latissimus dorsi, etc."),
        EXERCISE_EFFECT: $L(
          "An exercise that relieves neck and shoulders. Good for people with a sore neck and shoulders."
        ),
        EXERCISE_TIME: 282,
        VIDEO_INDEX: "SHOULDER_04",
        VIDEO_THUMBNAIL: shoulder_04_14,
        IMAGE: {
          LEFT: [
            lg_shoulder_tilt_front_tight_high_left,
            lg_shoulder_tilt_back_high_left,
          ],
          RIGHT: [
            lg_shoulder_tilt_front_tight_high_right,
            lg_shoulder_tilt_back_high_right,
          ],
        },
      },
      [MENUTYPE.PELVIC_TILT]: {
        subFeedback: $L(
          "In particular, your pelvis may be tilted to one side, so let’s strengthen the muscles associated with pelvic alignment."
        ),
        EXERCISE_NAME: $L("Balance-enhancing exercises"),
        ASSOCIATED_MUSCLES: $L("Quadratus Lumborum, Hip Adductor Group, etc."),
        EXERCISE_EFFECT: $L(
          "It improves your balance. It improves flexibility of hip joints and strengthens the sides."
        ),
        EXERCISE_TIME: 228,
        VIDEO_INDEX: "PELVIC_01",
        VIDEO_THUMBNAIL: pelvic_01_05,
        IMAGE: {
          LEFT: [
            lg_lateral_pelvic_tilt_front_high_left,
            lg_lateral_pelvic_tilt_back_high_left,
          ],
          RIGHT: [
            lg_lateral_pelvic_tilt_front_tight_high_right,
            lg_lateral_pelvic_tilt_back_high_right,
          ],
        },
      },
      [MENUTYPE.LEG_ALIGNMENT]: {
        [MENUTYPE_TEST.BOW_LEG]: {
          subFeedback: $L(
            "In particular, your legs may be bent outward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises for better balance"),
          ASSOCIATED_MUSCLES: $L("Hip Adductor Group, Gracilis, etc."),
          EXERCISE_EFFECT: $L("It improves the full body stability."),
          EXERCISE_TIME: 158,
          VIDEO_INDEX: "LEG_03",
          VIDEO_THUMBNAIL: leg_03_09,
          IMAGE: [lg_bow_leg_front, lg_bow_leg_back],
        },
        [MENUTYPE_TEST.KNOCK_KNEE]: {
          subFeedback: $L(
            "In particular, your legs may be bent inward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises for better balance"),
          ASSOCIATED_MUSCLES: $L("Tensor Fascia Latae, Biceps femoris, etc."),
          EXERCISE_EFFECT: $L("It improves the full body stability."),
          EXERCISE_TIME: 158,
          VIDEO_INDEX: "LEG_03",
          VIDEO_THUMBNAIL: leg_03_09,
          IMAGE: [lg_knock_knee_front, lg_knock_knee_back],
        },
      },
      [MENUTYPE.HEAD_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          // subFeedback: $L(
          //   "In particular, your neck may be tilted forward, so let’s strengthen the muscles associated with neck alignment."
          // ),
          // EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
          // ASSOCIATED_MUSCLES: $L("SCM(Sternocleidomastoid), Suboccipitalis Group, etc."),
          // EXERCISE_EFFECT: $L(
          //   "An exercise great for neck stability. Good for people with rounded shoulders, a straight and stiff neck."
          // ),
          // EXERCISE_TIME: 358,
          // VIDEO_INDEX: "NECK_02",
          // VIDEO_THUMBNAIL: neck_02_12,
          subFeedback: $L(
            "Let’s strengthen the muscles associated with neck alignment."
          ),
          EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
          ASSOCIATED_MUSCLES: $L("SCM(Sternocleidomastoid), Capital flexor group, etc."),
          EXERCISE_EFFECT: $L(
            "Moving the head relieves tense (stiff) neck muscles."
          ),
          EXERCISE_TIME: 597,
          VIDEO_INDEX: "NECK_01",
          VIDEO_THUMBNAIL: neck_01_01,
          IMAGE: [lg_head_shift_anterior_front, lg_head_shift_anterior_back],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          // subFeedback: $L(
          //   "In particular, your neck may be tilted backward, so let’s strengthen the muscles associated with neck alignment."
          // ),
          // EXERCISE_NAME: $L("Exercises for relaxing neck and shoulder"),
          // ASSOCIATED_MUSCLES: $L("Cervical erector spinae muscle group, Suboccipitalis Group, etc."),
          // EXERCISE_EFFECT: $L(
          //   "An exercise great for neck stability. Good for people with rounded shoulders, a straight and stiff neck."
          // ),
          // EXERCISE_TIME: 358,
          // VIDEO_INDEX: "NECK_02",
          // VIDEO_THUMBNAIL: neck_02_12,
          subFeedback: $L(
            "Let’s strengthen the muscles associated with neck alignment."
          ),
          EXERCISE_NAME: $L("Exercises to ease neck stiffness"),
          ASSOCIATED_MUSCLES: $L("Cervical erector spinae muscle group, etc."),
          EXERCISE_EFFECT: $L(
            "Moving the head relieves tense (stiff) neck muscles."
          ),
          EXERCISE_TIME: 597,
          VIDEO_INDEX: "NECK_01",
          VIDEO_THUMBNAIL: neck_01_01,
          IMAGE: [lg_head_shift_posterior_front, lg_head_shift_posterior_back],
        },
      },
      [MENUTYPE.TRUNK_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "In particular, your shoulders may be tilted forward, so let’s strengthen the muscles associated with shoulder alignment."
          ),
          EXERCISE_NAME: $L("Shoulder-strengthening exercises for improved postures"),
          ASSOCIATED_MUSCLES: $L(
            "Pectoralis Major/Minor, Rhomboid Major/Minor, etc."
          ),
          EXERCISE_EFFECT: $L(
            "Motions good for strengthening shoulders and blood circulation. Straightens your back and relaxes your shoulders."
          ),
          EXERCISE_TIME: 420,
          VIDEO_INDEX: "SHOULDER_03",
          VIDEO_THUMBNAIL: shoulder_03_13,
          IMAGE: [
            lg_shoulder_shift_anterior_front,
            lg_shoulder_shift_anterior_back,
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "In particular, your shoulders may be tilted backward, so let’s strengthen the muscles associated with shoulder alignment."
          ),
          EXERCISE_NAME: $L(
            "Upper body strengthening exercises using resistance"
          ),
          ASSOCIATED_MUSCLES: $L(
            "Pectoralis major, Erector Spinae Muscle Group, etc."
          ),
          EXERCISE_EFFECT: $L(
            "Strengthens the upper body muscles and shoulders using palm resistance."
          ),
          EXERCISE_TIME: 525,
          VIDEO_INDEX: "SHOULDER_02",
          VIDEO_THUMBNAIL: shoulder_02_10,
          IMAGE: [
            lg_shoulder_shift_posterior_front,
            lg_shoulder_shift_posterior_back,
          ],
        },
      },
      [MENUTYPE.PELVIC_SHIFT]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "In particular, your pelvis may be tilted forward, so let’s strengthen the muscles associated with pelvic alignment."
          ),
          EXERCISE_NAME: $L("Exercises to improve hip joint flexibility"),
          ASSOCIATED_MUSCLES: $L("Lliopsoas, Gluteus maximus, etc."),
          EXERCISE_EFFECT: $L(
            "Motions necessary to balance your body that’s good for strengthening hip joints and abs."
          ),
          EXERCISE_TIME: 300,
          VIDEO_INDEX: "PELVIC_02",
          VIDEO_THUMBNAIL: pelvic_02_15,
          IMAGE: [
            lg_pelvic_shift_anterior_front,
            lg_pelvic_shift_anterior_back,
          ],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "In particular, your pelvis may be tilted backward, so let’s strengthen the muscles associated with pelvic alignment."
          ),
          EXERCISE_NAME: $L("Exercises to enhance lower body balance"),
          ASSOCIATED_MUSCLES: $L("Hamstring Group, Rectus Abdominis, etc."),
          EXERCISE_EFFECT: $L(
            "Motions necessary to balance your body that’s good for strengthening hip joints and abs."
          ),
          EXERCISE_TIME: 257,
          VIDEO_INDEX: "PELVIC_03",
          VIDEO_THUMBNAIL: pelvic_03_16,
          IMAGE: [
            lg_pelvic_shift_posterior_front,
            lg_pelvic_shift_posterior_back,
          ],
        },
      },
      [MENUTYPE.KNEE_FLEXION]: {
        [MENUTYPE_TEST.ANTERIOR]: {
          subFeedback: $L(
            "In particular, your knees may be protruded forward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Stretching exercises for your legs "),
          ASSOCIATED_MUSCLES: $L("Quadriceps Femoris, Popliteus, etc."),
          EXERCISE_EFFECT: $L(
            "Stretching improves the flexibility of your ankles and calves and facilitates blood circulation in the lower body."
          ),
          EXERCISE_TIME: 312,
          VIDEO_INDEX: "KNEE_01",
          VIDEO_THUMBNAIL: knee_01_04,
          IMAGE: [lg_knee_flexion_front, lg_knee_flexion_back],
        },
        [MENUTYPE_TEST.POSTERIOR]: {
          subFeedback: $L(
            "In particular, you knees may be extended backward, so let’s strengthen the muscles associated with leg alignment."
          ),
          EXERCISE_NAME: $L("Leg-strengthening exercises"),
          ASSOCIATED_MUSCLES: $L("Quadriceps Femoris, Hamstring Group, etc."),
          EXERCISE_EFFECT: $L(
            "Strengthens the butt and the lower body to prevent yourself from falling and improve a sense of balance."
          ),
          EXERCISE_TIME: 357,
          VIDEO_INDEX: "LEG_01",
          VIDEO_THUMBNAIL: leg_01_02,
          IMAGE: [lg_knee_extension_front, lg_knee_extension_back],
        },
      },
    },
  };
};
