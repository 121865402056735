import React, { useRef, useCallback, useEffect, useState } from "react";
import classNames from 'classnames';
import css from "./AngleGuideCanvas.module.less";

const drawCircle = (ctx, x1, y1,color, radius = 8, lineWidth=2, opacity=1.0)=> {
	ctx.beginPath();
	ctx.globalAlpha = opacity;
	ctx.arc(x1, y1,radius, 0, 8 * Math.PI);
	ctx.fillStyle = color;
	ctx.lineWidth = lineWidth;
	ctx.fill();
	if(lineWidth > 0){
		ctx.stroke();
	}
	ctx.globalAlpha = 1.0;
	ctx.closePath();
};

const drawCenterPoint = (ctx, p)=>{
	drawCircle(ctx, p[0], p[1], "#35DFAE", 12, 0, 0.8);
	drawCircle(ctx, p[0], p[1], "#11A27E", 8, 0);
}
const drawBaseLine = (ctx, p1, p2)=>{
	ctx.beginPath();
	ctx.lineWidth = 2;
	ctx.strokeStyle = "#11A27E";
	ctx.lineCap = 'round';
	ctx.setLineDash([4, 4]);
	ctx.moveTo(p1[0], p1[1]);
	ctx.lineTo(p2[0], p2[1]);
	ctx.stroke();
	ctx.closePath();
	ctx.setLineDash([0, 0]);
}
// 중심점과 좌표 간의 각도 계산
const calculateAngle = (target, center) => {
	return Math.atan2(target[1] - center[1], target[0] - center[0]);
}

// coordinates 0 사용자포인트 , 1 센터 ,2 기준포인트
const drawArc = (context, coordinates, direction) =>{
	let center = coordinates[1];

	// 내부 원 반지름
	const innerRadius = 0;
	// 외부 원 반지름 계산 (중심에서 좌표 중 가장 먼 거리)
	let maxDistance = 0;
	for (const [x, y] of coordinates) {
		const distance = Math.sqrt((x - center[0]) ** 2 + (y - center[1]) ** 2);
		if (distance > maxDistance) {
			maxDistance = distance;
		}
	}

	// 그라디언트 호 생성
	const gradient = context.createRadialGradient(center[0], center[1], innerRadius, center[0], center[1], maxDistance);
	gradient.addColorStop(0, '#21FFCD'); // 내부 원에서의 색상
	gradient.addColorStop(1, '#21FFCD00'); // 외부 원에서의 색상

	context.fillStyle = gradient; // 그라디언트를 선의 스타일로 설정

	// 그라디언트 호 그리기
	context.beginPath();
	context.moveTo(center[0], center[1]);
	const angle = (calculateAngle(coordinates[0], center)+Math.PI*2)%(Math.PI*2);
	const angle2 = (calculateAngle(coordinates[2], center)+Math.PI*2)%(Math.PI*2);

	const firstAngle = Math.min(angle, angle2);
	const lastAngle = Math.max(angle, angle2);
	if(direction === 'positive'){
		context.arc(center[0], center[1], maxDistance, angle2, angle, false);
	}else if(direction === 'negative'){
		context.arc(center[0], center[1], maxDistance, angle2, angle, true);
	}else{ //auto
		if((lastAngle-firstAngle) < Math.PI){ //180도 보다 작을 경우
			context.arc(center[0], center[1], maxDistance, firstAngle, lastAngle);
		}else{ //180도 보다 큰 경우 반대로 그린다.
			context.arc(center[0], center[1], maxDistance, lastAngle, firstAngle);
		}
	}
	context.fill();
}
/**
 * @param points [[x1,y1], [x2,y2], [x3,y3]] 2,3 번이 기준선, 2번이 센터
 * @param direction : undefined - auto,  positive, nagative
 * @module AngleGuideCanvas
 * @returns AngleGuideCanvas
 */
const AngleGuideCanvas = ({points, cameraSize, className, direction, ...rest}) => {
	const canvasRef = useRef();
	const [canvasSize, setCanvasSize] = useState([0,0]);
	const clearDrawerTimer = useRef(null);

	useEffect(()=>{
        const w = canvasRef.current.getBoundingClientRect().width;
        const h = canvasRef.current.getBoundingClientRect().height;
        setCanvasSize([w,h]);
		return ()=>{
			if(clearDrawerTimer.current){
				clearTimeout(clearDrawerTimer.current);
				clearDrawerTimer.current = null;
			}
		}
    },[]);

	useEffect(() => {
		if(canvasSize[0] > 0 && cameraSize && cameraSize.width > 0){
			if(clearDrawerTimer.current){
				clearTimeout(clearDrawerTimer.current);
				clearDrawerTimer.current = null;
			}
			clearDrawerTimer.current = setTimeout(()=>{
				if(canvasRef.current){
					const ctx = canvasRef.current?.getContext('2d');
					if(ctx){
						ctx.clearRect(0, 0, canvasSize[0], canvasSize[1]);
					}
				}
			}, 1000);
			try{
				const convertedPoints = [];
				const scale = canvasSize[1] / cameraSize.height;
				const leftMargin = (cameraSize.width * scale - canvasSize[0]) / 2;
				if(!!points?.length){
					points.forEach((p)=>{
						const x = (cameraSize.width - p[0]) * scale - leftMargin; //mirror
						const y = p[1]*scale;
						convertedPoints.push([x,y]);
					})
				}

				const ctx = canvasRef.current.getContext('2d');
				ctx.clearRect(0, 0, canvasSize[0], canvasSize[1]);
				if(convertedPoints.length ===3){
					drawArc(ctx, convertedPoints, direction);
					drawBaseLine(ctx, convertedPoints[1], convertedPoints[2]);
					drawCenterPoint(ctx, convertedPoints[1]);
				}
			}catch(e){
				console.warn('AngleGuideCanvas draw exception ', e);
			}
		}
	},[points, canvasSize, cameraSize]);

	return (
		<canvas
			{...rest}
			ref={canvasRef}
			className={classNames(css.canvas,className)}
			width={canvasSize[0]}
			height={canvasSize[1]}
		/>
	);
}

export default AngleGuideCanvas;
