import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import css from "./SubTab.module.less";
import CommonButtonLayer from './CommonButtonLayer';
import {$L, convertNewlinesToBr} from '../../../../../utils/helperMethods';
import SwiperComponent from "../SwiperComponent";
import {IDEAL_IMAGES, MENUTYPE, MENUTYPE_TEST, TAB_DETAIL} from "../Constants";
import { addPanels } from '../../../../../features/panels/panelsSlice';
import { getContentList } from '../../../../../features/fitService/fitServiceSlice';
import * as Config from '../../../../../utils/Config';

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");
const SubTab = ({ date="", scoreArray, menuType, launchReportDetail, ...rest }) => {
  const dispatch = useDispatch();
  const {pictureType } = useSelector((state) => state.common.appStatus);
  const { testResults } = useSelector((state) => state.bodyAlignment);

  const onClickCommonBtn= useCallback((index)=>{
    console.log('onClickCommonBtn', index);
    if(index === 0){
      // testStart();

      // todo remove. content 유무 확인용
      let subCategory = '';
      let contentId = `bodyAlignment_${menuType}`;

      if (testResults?.[date] && testResults?.[date][TAB_DETAIL[menuType].mainkey]) {
        const detail = testResults?.[date][TAB_DETAIL[menuType].mainkey][TAB_DETAIL[menuType].testkey];

        if ([MENUTYPE.LEG_ALIGNMENT].includes(menuType)) {
          if (detail[0] === "out") {
            subCategory = MENUTYPE_TEST.BOW_LEG;
          } else {
            subCategory = MENUTYPE_TEST.KNOCK_KNEE;
          }
        } else if ([MENUTYPE.HEAD_SHIFT, MENUTYPE.TRUNK_SHIFT, MENUTYPE.PELVIC_SHIFT, MENUTYPE.KNEE_FLEXION].includes(menuType)) {
          if (detail[0] === "front") {
            subCategory = MENUTYPE_TEST.ANTERIOR;
          } else {
            subCategory = MENUTYPE_TEST.POSTERIOR;
          }
        }

        if (detail[3] !== 'danger') {
          subCategory =  'A';
        }
      }
      if (subCategory !== '') contentId += `_${subCategory}`;

      dispatch(getContentList({ type: 'recommend', subType: 'bodyAlignment', category: menuType, subCategory }));
      // dispatch(addPanels({ name: Config.panel_names.HOMECARE_RECOMMEND_WORKOUT, panelInfo: { contentId } }));
      dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { contentId } }));
    } else if(index === 1){
      if(launchReportDetail){
        launchReportDetail();
      }
    }
  },[launchReportDetail]);

  if(testResults?.[date]){
    return (
      <>
      <Container className={css.wrapping}>
        <SwiperComponent pictureType={pictureType} tabDetail={TAB_DETAIL[menuType]} date={date}/>
          <div className={css.idealposture}>
            <div className={css.swiperInnerImg}>
              <img src={IDEAL_IMAGES[menuType]} alt="" />
            </div>
            <div className={css.recordDate}>{$L("Ideal Posture")}</div>
          </div>
      </Container>
      <CommonButtonLayer buttonTitles={[$L('Workout'), $L('View Details')]} disabled={[false, false]} onClick={onClickCommonBtn}></CommonButtonLayer>
      </>
    )
  }else{
    return null;
  }

}

export default SubTab;