import React, { useMemo } from "react";
import css from "./ViewDetails.module.less";
import Wrapper from "../../components/Wrapper/Wrapper";
import { $L, durationText } from "../../../../utils/helperMethods";
import Title, { ICONS, TYPES } from "../../components/Title/Title";
import List from "../../components/List/List";
import ListItem from "../../components/ListItem/ListItem";
import { useSelector } from "react-redux";
import useBpm from "../../../../hooks/useBpm";

const age = 65;

const ViewDetails = () => {
  const { cesFeedbackData } = useSelector((state) => state.common.appStatus);
  const { maxCameraBpm } = useSelector((state) => state.camera);
  const { bpmStatusKey } = useBpm({ bpm: maxCameraBpm, age });

  const improvementList = useMemo(() => {
    return [
      $L("Walking"),
      $L("Sports activities such as riding a bicycle"),
      $L("Climbing up and down the stairs"),
      $L("Lowering an object from a high place"),
      $L("Standing in the bus or subway"),
      $L("Risk of falling"),
    ];
  }, []);

  const bpmFeedback = useMemo(() => {
    let intensity;
    switch (bpmStatusKey) {
      case "HIGH":
        intensity = $L("high-intensity");
        break;
      case "MEDIUM":
        intensity = $L("moderate intensity");
        break;
      default:
        intensity = $L("low-intensity");
        break;
    }
    return $L(
      "The exercise you experienced today was a {intensity} exercise for you."
    )
      .replace("{age}", age)
      .replace("{intensity}", intensity);
  }, [bpmStatusKey, age]);

  return (
    <Wrapper>
      <div className={css.pointContainer}>
        <Title className={css.title}>
          {$L("Impact of the Foot Health on Our Daily Life")}
        </Title>
        <List>
          {improvementList.map((item, index) => (
            <ListItem key={"impro_" + index}>{item}</ListItem>
          ))}
        </List>
      </div>
      <div className={css.exerciseContainer}>
        <Title type={TYPES.withIcon} icon={ICONS.check} className={css.title}>
          {$L("Today's Exercise Recommendation")}
        </Title>
        <img
          className={css.mediaItem}
          src={cesFeedbackData.VIDEO_THUMBNAIL}
          art=""
        />
        <List>
          <ListItem>
            <strong>{$L("Exercise Name")}</strong> :{" "}
            {cesFeedbackData.EXERCISE_NAME}
          </ListItem>
          <ListItem>
            <strong>{$L("Exercise Effect")}</strong> :{" "}
            {cesFeedbackData.EXERCISE_EFFECT}
          </ListItem>
          <ListItem>
            <strong>{$L("How to Exercise")}</strong> :{" "}
            {cesFeedbackData.HOW_TO_EXERCISE}
          </ListItem>
          <ListItem>
            <strong>{$L("Exercise Time")}</strong> :{" "}
            {durationText(cesFeedbackData.EXERCISE_TIME)}
          </ListItem>
        </List>
      </div>
      <div className={css.bpmContainer}>
        <Title type={TYPES.withIcon} icon={ICONS.check} className={css.title}>
          {$L("Briefing")}
        </Title>
        <div className={css.bpmFeedback}>{bpmFeedback}</div>
      </div>
      <div className={css.guideText}>
        {$L("☞ Set your age for tailored information")}
      </div>
    </Wrapper>
  );
};

export default ViewDetails;
