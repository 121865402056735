import React, {useState, useEffect, useCallback} from 'react';
import { useDispatch } from 'react-redux';
import {$L} from '../../../utils/helperMethods';
import SubBody from '../SubBody';
import BackgroundMusic from './BackgroundMusic';
import StepSound from './StepSound';
import * as Config from "../../../utils/Config";
import {updatePanel} from "../../../features/panels/panelsSlice";

const SubAudio = ({...rest}) => {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onTabChanged = useCallback(({index})=>{
    setSelectedTabIndex(index);
    dispatch(updatePanel({name: Config.panel_names.SETTING_PANEL, panelInfo: {selectedTab: index}}));
  },[]);

  useEffect(() => {
    console.log('selectedTabIndex ',selectedTabIndex);
  }, [selectedTabIndex]);

  const tabList = [
    { title: $L("Background Music"), children: <BackgroundMusic/> },
    { title: $L("Sound of footsteps"), children: <StepSound/> }
  ];

  return (
    <SubBody simpleButtonList={tabList.map(tab => tab.title)} onTabChanged={onTabChanged} {...rest}>
      {tabList[selectedTabIndex].children || null}
    </SubBody>
  );
};

export default SubAudio;
