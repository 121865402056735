import React, { useEffect, useMemo } from "react";
import css from "./BodyBalanceTab.module.less";
import { getEmptyResultsReportData, bodyBalanceMenu } from "../Constants";
import EmptyResultsReport from "../../../../../components/EmptyResultsReport/EmptyResultsReport";
import BodyBalanceResultsReport from "./BodyBalanceResultsReport/BodyBalanceResultsReport";
import {
  voiceGuideBodyBalance,
  voiceGuideEmpty,
} from "../feedback/feedbackBodyBalance";
import { $L } from "../../../../../utils/helperMethods";

const BodyBalanceTab = ({
  date = "",
  currentData,
  openMenuTestPopup,
  feedback,
  setFeedback,
  setTextDisabled,
  isEmpty = false,
}) => {
  const emptyResultsData =
    getEmptyResultsReportData()[bodyBalanceMenu.BODY_BALANCE_TAB];

  const resultData = useMemo(() => {
    const ret = {
      infoDesc: "",
      PrescriptionDesc: $L(
        "1. 폼롤러를 잡고 한발서기 시 TV를 보고 균형을 유지해주세요.{br} 2. 코어 운동을 추천 드립니다."
      ),
    };
    if (feedback?.bodyBalanceTab) {
      ret.infoDesc = feedback.bodyBalanceTab;
    }
    return ret;
  }, [feedback]);

  useEffect(() => {
    if (!isEmpty) {
      setTextDisabled(false);
      setFeedback(
        voiceGuideBodyBalance(bodyBalanceMenu.BODY_BALANCE_TAB, currentData)
      );
    } else {
      setTextDisabled(true);
      setFeedback(voiceGuideEmpty(bodyBalanceMenu.BODY_BALANCE_TAB));
    }
  }, [isEmpty, currentData]);

  if (!isEmpty) {
    return (
      <div className={css.reportSect}>
        <BodyBalanceResultsReport
          bodyBalanceResultsData={resultData}
          date={date}
          currentData={currentData}
          tabType={bodyBalanceMenu.BODY_BALANCE_TAB}
        />
      </div>
    );
  } else {
    return (
      <EmptyResultsReport
        openMenuTestPopup={openMenuTestPopup}
        partResult={true}
        bodyBalance={true}
        title={emptyResultsData.title}
        detail={emptyResultsData.detail}
        subheading={emptyResultsData.subheading}
        list={emptyResultsData.list}
      />
    );
  }
};

export default BodyBalanceTab;
