/**
 * TGoalHistory
 *
 * @module TGoalHistory
 */

import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import Spottable from "@enact/spotlight/Spottable";
import Spotlight from "@enact/spotlight";
import css from './TGoalHistory.module.less';
import TIcon from '../TIcon/TIcon';

const SpottableComponent = Spottable("div");

const TGoalComponent = ({ className, active = false, currentData, goalData, type, setFocusItem, spotlightId, ...rest }) => {
  const item = {
    day: {
      activeIcon: "dayActive",
      norIcon: "dayNor",
      unit: "Days",
    },
    calorie: {
      activeIcon: "calorieActive",
      norIcon: "calorieNor",
      unit: "Kcal",
    },
    time: {
      activeIcon: "timeActive",
      norIcon: "timeNor",
      unit: "Hr",
    },
    step: {
      activeIcon: "stepActive",
      norIcon: "stepNor",
      unit: "Step",
    },
    km: {
      activeIcon: "kmActive",
      norIcon: "kmNor",
      unit: "Km",
    },
    m: {
      activeIcon: "kmActive",
      norIcon: "kmNor",
      unit: "m",
    },
  };

  const itemRef = useRef(null);
  const onFocusHandler = useCallback(() => {
    setFocusItem(type);

    setTimeout(() => {
      Spotlight.focus(itemRef.current.node);
    }, 100);
  }, [type]);

  const separateNum = (number) => {
    // // case 1. Convert numbers based on current regional settings
    // return number.toLocaleString();

    // case 2. Use US regional settings and set the separator and decimal places through the options object.
    const options = {
      style: "decimal",
      useGrouping: true, // use to thousands separator(comma)
      minimumFractionDigits: 0,
    };

    return number.toLocaleString("en-US", options);
  };

  return (
    <SpottableComponent
      className={classNames(css.goalComponent, active && css.active)}
      onClick={onFocusHandler}
      ref={itemRef}
      data-webos-voice-intent={"Select"}
      data-webos-voice-label={type}
      spotlightId={spotlightId}
      {...rest}
    >
      {active ? (
        <>
          <TIcon
            style={{ marginRight: "8px" }}
            iconType={item[type]?.activeIcon}
          />
          <span style={{ marginRight: "4px" }} className={css.currentData}>
            {separateNum(currentData)}
          </span>
          <span className={css.goalInfo}>
            {`/${separateNum(goalData)}${item[type]?.unit}`}
          </span>
        </>
      ) : (
        <>
          <TIcon
            style={{ marginRight: "8px" }}
            iconType={item[type]?.norIcon}
            size="small"
          />
          {separateNum(currentData) + " " + item[type]?.unit}
        </>
      )}
    </SpottableComponent>
  );
}

export default TGoalComponent;
