import classNames from 'classnames';
import css from './TToolTip.module.less';
import React, { useCallback, useEffect, useRef } from 'react';
import * as Utils from '../../utils/helperMethods';
import { useSelector } from 'react-redux';

const POSITION = { bottom: 'bottom', top: 'top', left: 'left', right: 'right'};
const COLOR = { green : 'green', mineralGreen: 'mineralGreen' };
const TYPES = { square: "square" };

const TToolTip = ({children, className, color= '', position = 'bottom', relativeRef, relativePosition, isOpen, style, type }) => {
	const toolTipRef = useRef();
	const arrowRef = useRef();
  const { themeMode } = useSelector((state) => state.common.appStatus);

	// useEffect(() => {
		// const current = toolTipRef.current;
		// const parentElement = current.parentElement;

		const handleParentFocus = useCallback(() => {
			toolTipRef.current.style.display = "block";
			if (relativeRef && relativeRef.current && relativePosition) {
				const diff = Utils.getRectDiff(relativeRef.current, toolTipRef.current);

				if ((relativePosition === "right" && diff[relativePosition] < 0) || (relativePosition === "left" && diff[relativePosition] > 0)) {
					toolTipRef.current.style.left =  `calc(50% + ${diff[relativePosition]}px)`;
					arrowRef.current.style.left = `calc(50% - ${diff[relativePosition]}px)`;
				} else if ((relativePosition === "bottom" && diff[relativePosition] < 0) || (relativePosition === "top" && diff[relativePosition] > 0)) {
					toolTipRef.current.style.top =  `calc(50% + ${diff[relativePosition]}px)`;
					arrowRef.current.style.top = `calc(50% - ${diff[relativePosition]}px)`;
				}
			}
		},[]);

		const handleParentBlur = useCallback(() => {
			toolTipRef.current.style.display = "none";
			if (relativeRef && relativeRef.current && relativePosition) {
				if (relativePosition === "right" || relativePosition === "left") {
					toolTipRef.current.style.left = "50%";
					arrowRef.current.style.left = "50%"
				}else if (relativePosition === "bottom" || relativePosition === "top") {
					toolTipRef.current.style.top = "50%";
					arrowRef.current.style.top = "50%"
				}
			}
		},[]);

		// if(Spotlight.getCurrent() === parentElement){
		// 	handleParentFocus();
		// }
		// parentElement.addEventListener('focus', handleParentFocus);
		// parentElement.addEventListener('blur', handleParentBlur);
		// return ()=>{
		// 	parentElement.removeEventListener('focus', handleParentFocus)
		// 	parentElement.removeEventListener('blur', handleParentBlur)
		// }
// }, []);

useEffect(() => {
	if(isOpen) {
		handleParentFocus();
	} else {
		handleParentBlur();
	}
},[isOpen])


	return (
		<div
			className={classNames(
				css.toolTip,
				css[position],
				css[color],
        type && css[type],
        themeMode === "dark" && css.isDark,
				className
			)}
                        style={style && style}
			ref={toolTipRef}
		>
			{children}
			<div className={css.arrow} ref={arrowRef}/>
		</div>
	);
};


export default TToolTip;
export { POSITION, COLOR, TYPES };
