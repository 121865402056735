import React, { useMemo, useCallback } from "react";
import classNames from "classnames";
import css from "./TMediaVerticalItem.module.less";
import Spottable from '@enact/spotlight/Spottable';
import dummyImage from "../../../assets/dummyImage.png";
import * as Utils from '../../utils/helperMethods';

const SpottableComponent = Spottable("div");

const TMediaVerticalItem = ({index, onItemClick, itemInfo, ...rest}) => {
	const onClick = useCallback((ev) => {
		if(onItemClick){
			onItemClick({index, itemInfo, ev});
		}
	}, [index, itemInfo, onItemClick]);

  const itemDescription = useMemo(() => {
		let res= {title: "", brand: "", price: 0, thumbnailUrl: ""};
		if(itemInfo){
      res.title = Utils.convertUtf8ToUni(itemInfo.title);
      res.brand = Utils.convertUtf8ToUni(itemInfo.brand);
      res.price = itemInfo.price
			res.thumbnailUrl = itemInfo.thumbnailUrl;
      //todo
			if(index >= 0 && res.thumbnailUrl === 'dummy'){
        res.thumbnailUrl = dummyImage;
			}
		}
		return res;
	}, [index, itemInfo]);

  return (
    <SpottableComponent
      {...rest}
      className={classNames(css.tMediaItem)}
      onClick={onClick}
    >
      <div className={css.image} style={{backgroundImage: `url(${itemDescription.thumbnailUrl})`}}/>
      <div className={css.title}>{itemDescription.title}</div>
      <div className={css.brand}>{itemDescription.brand}</div>
      <div className={css.price}>{itemDescription.price}</div>
      <div className={css.focusCover}></div>
    </SpottableComponent>
  );
}

export default TMediaVerticalItem;