import LS2Request from "./LS2Request";
import appinfo from '../../webos-meta/appinfo.json';

const LUNA_FITTV_SERVICE = "luna://"+appinfo.id+".service"; //"luna://com.webos.app.fittv.service";

export const getConnectionStatus = ({ onSuccess, onFailure, onComplete }) => {
  if (process.env.REACT_APP_MODE === "DEBUG") {
    console.log("LUNA SEND getConnectionStatus");
    return "Some Hard Coded Mock Data";
  } else {
    return new LS2Request().send({
      service: "luna://com.webos.service.connectionmanager",
      method: "getStatus",
      subscribe: true,
      parameters: {},
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};

export const writeFile = (parameters, { onSuccess, onFailure, onComplete }) => {
  console.log("process.env.REACT_APP_MODE123==", process.env.REACT_APP_MODE);
  if (process.env.REACT_APP_MODE === "DEBUG") {

  } else {
    return new LS2Request().send({
      service: LUNA_FITTV_SERVICE,
      method: "writeFile",
      subscribe: false,
      parameters: parameters,
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};
export const writeBase64Image = (parameters, { onSuccess, onFailure, onComplete }) => {
  console.log("process.env.REACT_APP_MODE123==", process.env.REACT_APP_MODE);
  if (process.env.REACT_APP_MODE === "DEBUG") {

  } else {
    return new LS2Request().send({
      service: LUNA_FITTV_SERVICE,
      method: "writeBase64Image",
      subscribe: false,
      parameters: parameters,
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};
export const writeVideoBuffer = (parameters, { onSuccess, onFailure, onComplete }) => {
  if (typeof window === 'object' && !window.PalmSystem) {
    if(onSuccess){
      onSuccess({returnValue: true});
    }
  } else {
    return new LS2Request().send({
      service: LUNA_FITTV_SERVICE,
      method: "writeVideoBuffer",
      subscribe: false,
      parameters: parameters,
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};
export const copyFile = (parameters, { onSuccess, onFailure, onComplete }) => {
  if (typeof window === 'object' && !window.PalmSystem) {
    if(onSuccess){
      onSuccess({returnValue: true});
    }
  } else {
    return new LS2Request().send({
      service: LUNA_FITTV_SERVICE,
      method: "copyFile",
      subscribe: false,
      parameters: parameters,
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};
export const readFile = (parameters, { onSuccess, onFailure, onComplete }) => {
  if (process.env.REACT_APP_MODE === "DEBUG") {
    console.log("LUNA SEND getConnectionStatus");
    return "Some Hard Coded Mock Data";
  } else {
    return new LS2Request().send({
      service: LUNA_FITTV_SERVICE,
      method: "readFile",
      subscribe: false,
      parameters: parameters,
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};

export const getFileList = (parameters, { onSuccess, onFailure, onComplete }) => {
  if (process.env.REACT_APP_MODE === "DEBUG") {
    console.log("LUNA SEND getFileList");
    return "Some Hard Coded Mock Data";
  } else {
    return new LS2Request().send({
      service: LUNA_FITTV_SERVICE,
      method: "getFileList",
      subscribe: false,
      parameters: parameters,
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};

let getDeviceListHandle = null;
export const getDeviceList = ({ onSuccess, onFailure, onComplete }) => {
  if (typeof window === "object" && window.PalmSystem) {
    if (process.env.REACT_APP_MODE === "DEBUG") {
      console.log("LUNA SEND getSystemInfo");
      return "Some Hard Coded Mock Data";
    } else {
      if (getDeviceListHandle) {
        getDeviceListHandle.cancel();
        getDeviceListHandle = null;
      }
      getDeviceListHandle = new LS2Request().send({
        service: "luna://com.webos.service.attachedstoragemanager",
        method: "listDevices",
        subscribe: true,
        onSuccess,
        onFailure,
        onComplete,
      });
      return getDeviceListHandle;
    }
  } else {
    setTimeout(() => {
      onSuccess({ returnValue: true, devices: [{deviceStatus: "NORMAL", deviceType:'usb',subDevices: [{deviceUri:"tmp/usb/sda/sda1"}]}] });
      onComplete();
    }, 100);
  }
};

export const createToast = (message) => {
	if (typeof window === 'object' && !window.PalmSystem) {
    console.log("LUNA SEND createToast message", message);
		return;
	}
	return new LS2Request().send({
		service: 'luna://com.webos.notification',
		method: 'createToast',
		parameters:  {
			message: message,
			iconUrl: '',
			noaction: true
		},
		onSuccess: (res) => {
			console.log("LUNA SEND createToast success", message);
		},
		onFailure: (err) => {
			console.log("LUNA SEND createToast failed", err);
		}
	});
};