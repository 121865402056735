import axios from 'axios';
import {getUrl, AUTHORIZATION, URLS, THRESHOLD_AUTH_REFRESH} from './fitServiceConfig';
import * as HelperMethods from "../../utils/helperMethods";
import * as Config from "../../utils/Config";
import { addPanels, resetPanels } from '../panels/panelsSlice';
//todo


let tokenRefreshing = false;
export const tokenRefresh = async (dispatch, getState, autoLogin, callback) => {
	console.log('getState ', getState());
	const {serverType} = getState().common.localSettings;
	const url = getUrl(URLS.TOKEN_REFRESH, serverType);
	const webOSVersionReal = getState().common.appStatus.webOSVersionReal;
	const {authenticationResult, tokenRequiredTime, userId} = getState().common.localSettings;
	const deviceId = getState().common.appStatus.deviceId;

	// if(!userId){
	// 	console.warn('tokenRefresh : not logged');
	// 	if(callback){
	// 		callback(false);
	// 	}
	// 	return;
	// }
	// const currentTime = new Date();
	// const gap = (currentTime - tokenRequiredTime)/1000;
	// const ExpiredIn = Number(authenticationResult.ExpiresIn);
	// const limitTime = ExpiredIn - (ExpiredIn * THRESHOLD_AUTH_REFRESH);
	// if(!autoLogin && (gap < limitTime || tokenRefreshing)){
	// 	if(callback){
	// 		callback(false);
	// 	}
	// 	return;
	// }
	// const tokenInfo =
	// {
	// 	"authenticationResult": authenticationResult,
	// 	deviceInfo: {
	// 			"deviceId": deviceId,
	// 			"deviceName": "deviceName01",
	// 			"os": "webos",
	// 			"model": webOSVersionReal,
	// 			"serialNumber": "xxxx-xxxx-xxxx-xxxx"
	// 		}
	// 	, "userId" : userId
	// };
	// if(useQAServerURL !== 'prd'){
	// 	console.log('tokenInfo...', tokenInfo, gap, ExpiredIn);
	// }
	// try{
	// 	tokenRefreshing = true;
	// 	const response = await axios.put(url, tokenInfo, AUTHORIZATION);
	// 	console.log(' tokenRefresh response....', response);
	// 	Actions.AUTHORIZATION.headers.authorization = response.data.authenticationResult.AccessToken;
	// 	dispatch(CommonActions.changeLocalSettings({authenticationResult:response.data.authenticationResult, tokenRequiredTime: new Date()}));
	// 	console.log('토큰 갱신 완료...');
	// 	if(callback){
	// 		callback(true);
	// 	}
	// }catch(res) {
	// 	const error = res && res.toJSON ? res.toJSON() : {};
	// 	console.error('tokenRefresh', error, res);
	// 	if(error.message ){
	// 		if(error.message.indexOf('code 400')
	// 		|| error.message.indexOf('code 401')
	// 		|| error.message.indexOf('code 403')){
	// 			dispatch(Actions.logout());
	// 		}
	// 	}
	// 	if(callback){
	// 		callback(false);
	// 	}
	// };
	tokenRefreshing = false;
}

/**
 * type: 'get', 'put', 'post', 'delete'
 */

 export const TAxios = async (dispatch, getState, type, baseUrl, urlParams={}, params={}, onSuccess, onFail, noTokenRefresh = false) => {
	const {serverType, localURL} = getState().common.localSettings;
	const {userNumber} = getState().common.appStatus;
	const AUTHORIZATION = {headers:{}};
	//common params
	AUTHORIZATION.headers["X-Login-Session"] = userNumber;
	AUTHORIZATION.headers["Content-Type"] = "application/json";

	if (typeof window === 'object') {
		let url = "";
		if(Array.isArray(baseUrl)){
			url = getUrl(baseUrl[0], serverType, localURL);
			for(let i=1; i<baseUrl.length; i++){
				url += '/'+baseUrl[i];
      }
		}else{
			url = getUrl(baseUrl, serverType, localURL);
		}
		switch(type){
			case 'get':{
				const _urlparams = HelperMethods.createQueryString(urlParams);
				url +=  url ? `?${_urlparams}`: '';
				break;
			}
		}
		if(serverType !== 'prd'){
      console.log('TAxios ', type + ": " + url, serverType, params);
		}

		//tokenRefresh
		if(!noTokenRefresh){
			await tokenRefresh(dispatch, getState);
		}
		while(tokenRefreshing){
			await HelperMethods.wait(100);
		}

		let axiosIns = null;
		switch(type){
			case 'get':
				// axiosIns = axios[type](url, AUTHORIZATION);
				AUTHORIZATION.headers = {...AUTHORIZATION.headers, ...params};
				axiosIns = axios[type](url, AUTHORIZATION);
				break;
			case 'put':
			case 'post':
				axiosIns = axios[type](url, params, AUTHORIZATION);
				break;
			case 'delete':{
				const auth = Object.assign({}, AUTHORIZATION, {data: params});
				axiosIns = axios[type](url, auth);
				break;
			}
		}
		if(axiosIns){
			axiosIns.then((res)=>{
				if(serverType !== 'prd'){
					console.log('TAxios response', url, serverType, res);
				}
				if(onSuccess){
					onSuccess(res);
				}
			}).catch((res)=>{
				const error = res && res.toJSON ? res.toJSON() : {};
				console.error('TAxios ', url, error);
				if(error.message === 'Network Error'){
					dispatch(resetPanels());
					dispatch(addPanels({ name: Config.panel_names.ERROR_PANEL, panelInfo: {reason: "serverError"}}));
				}
				// else if (userNumber && error.message && error.message.indexOf('400')>=0){
				// 	dispatch(addPanels({ name: Config.panel_names.ERROR_PANEL, panelInfo: {reason: "400"}}));
				// }
				// else if(userNumber && error.message && error.message.indexOf('401')>=0){
				// 	dispatch(addPanels(Config.panel_names.ERROR_PANEL, {reason: "401"}));
				// }
				// else if(userNumber && error.message && error.message.indexOf('403')>=0){
				// 	dispatch(Actions.logout());
				// 	dispatch(resetPanels());
				// 	dispatch(addPanels(Config.panel_names.ERROR_PANEL, {reason: "403"}));
				// }
				else{
					//todo
				}
				if(onFail){
					onFail(res);
				}
			});
		}else{
			console.warn('TAxios invalid case type ', type);
		}
	}
};