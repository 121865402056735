import React, { useEffect, useCallback } from "react";
import JMediaList, { LIST_TYPE } from "../../../../components/JMediaList";
import dummyVideoList from "../../../../../assets/mock/dummyJVideoList.json";

const JMediaListLayout = () => {
  const onItemClick = useCallback(({ index, itemInfo, ev }) => {
    console.log("TMediaListLayout onItemClick", index, itemInfo, ev);
  }, []);
  return (
    <>
      <JMediaList
        title={"MediaList Large"}
        listType={LIST_TYPE.large}
        contents={dummyVideoList.data}
        onItemClick={onItemClick}
      />
    </>
  );
};

export default JMediaListLayout;
