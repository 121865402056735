import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Spotlight from "@enact/spotlight";
import { changeAppStatus } from "../../features/common/commonSlice";
import useUserCheck from "../../hooks/useUserCheck";
import css from "./EmptyResultsReport.module.less";
import TButton, {SIZES} from "../TButton/TButton"
import emptyTestImg from "../../../assets/emptyResultReport/empty_part.png"
import emptyMainImg from "../../../assets/emptyResultReport/empty_main.png";
import { $L, convertNewlinesToBr } from '../../utils/helperMethods';
import CameraPopup from "../../views/Camera/CameraPopup";

const EmptyResultsReport = ({ title, detail, subheading, list, bodyScan=false, overallResult, bodyBalance=false, partResult=false, onClick, openMenuTestPopup }) => {
  const dispatch = useDispatch();
  const { cameraList } = useSelector(state => state.camera);
  const { userCheck } = useUserCheck();
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);

  const openMenuTestPopupDummy = useCallback(() => {
    if(!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'test' } }));
    } else if(cameraList.length < 1 && !bodyBalance) {
      setIsConnectionPopupOpen(true);
    } else {
      openMenuTestPopup();
    }
  }, [userCheck]);

  useEffect(()=>{
    Spotlight.focus('emptyResultReportTestBtn');
  },[detail])

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  return(
    <div className={css.emptySect}>
      {overallResult &&
        <img className={css.emptyMainImg} src={emptyMainImg} alt="emptyMainImg"/>
      }
      {partResult &&
        <img className={css.emptyTestImg} src={emptyTestImg} alt="emptyTestImg"/>
      }
      <div className={classNames(overallResult? css.totalTextBox : css.partTextBox)}>
        <div className={css.title}>{convertNewlinesToBr(title)}</div>
        <div className={css.detail}>{convertNewlinesToBr(detail)}</div>
        {subheading && (
          <div className={css.subheading}>
            <span />{subheading}
          </div>
        )}
        <div className={css.detail}>{convertNewlinesToBr(list)}</div>
        <div className={css.testStart}><span/>{
          bodyScan? $L("Want to start the body scan?") : $L("Do you want to proceed and start the test now?")
        }</div>
          <TButton size={SIZES.test} spotlightId="emptyResultReportTestBtn" onClick={openMenuTestPopupDummy}>{bodyScan? $L('Body Scan'):$L('Test')}</TButton>
      </div>
      {isConnectionPopupOpen && <CameraPopup onClose={onCloseCameraPopup}/>}
    </div>
  )
}

export default EmptyResultsReport;