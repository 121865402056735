import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const BodyCheckUpIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 15.6553C28.933 15.6553 30.5 14.0883 30.5 12.1553C30.5 10.2223 28.933 8.65527 27 8.65527C25.067 8.65527 23.5 10.2223 23.5 12.1553C23.5 14.0883 25.067 15.6553 27 15.6553Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M31.4067 19.4199H22.6133V30.0501H31.4067V19.4199Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M18.6914 29.003L18.9179 27.6265C19.3286 25.1308 20.3355 22.7711 21.8531 20.7478C22.8425 19.4287 24.3952 18.6523 26.0442 18.6523H27.0049"
        stroke={convertThemeColor(iconType)}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M35.2696 29.002L35.0431 27.6255C34.6325 25.1299 33.6256 22.7702 32.1079 20.7468C31.1185 19.4277 29.5658 18.6514 27.9168 18.6514H26.9561"
        stroke={convertThemeColor(iconType)}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4589 27.8066H22.9665L22.7309 29.2804L21.7384 35.4883H25.2829L25.9514 31.3066H28.0605L28.6949 35.4883H32.235L31.2952 29.2941L31.0695 27.8066H29.565H24.4589ZM32.5384 37.4883H28.9984L29.9642 43.8543C30.1092 44.8099 31.0014 45.467 31.9569 45.322C32.9125 45.177 33.5696 44.2848 33.4246 43.3293L32.5384 37.4883ZM24.9631 37.4883H21.4187L20.487 43.3155C20.3345 44.2699 20.9845 45.1673 21.9388 45.3199C22.8932 45.4724 23.7906 44.8224 23.9432 43.8681L24.9631 37.4883Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M18.3234 33.5059H14C13.4477 33.5059 13 33.9536 13 34.5059C13 35.0582 13.4477 35.5059 14 35.5059H18.3234C18.8757 35.5059 19.3234 35.0582 19.3234 34.5059C19.3234 33.9536 18.8757 33.5059 18.3234 33.5059Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M25.55 33.5059H21.2266C20.6743 33.5059 20.2266 33.9536 20.2266 34.5059C20.2266 35.0582 20.6743 35.5059 21.2266 35.5059H25.55C26.1023 35.5059 26.55 35.0582 26.55 34.5059C26.55 33.9536 26.1023 33.5059 25.55 33.5059Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M32.7765 33.5059H28.4531C27.9008 33.5059 27.4531 33.9536 27.4531 34.5059C27.4531 35.0582 27.9008 35.5059 28.4531 35.5059H32.7765C33.3288 35.5059 33.7765 35.0582 33.7765 34.5059C33.7765 33.9536 33.3288 33.5059 32.7765 33.5059Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M39.9992 33.5059H35.6758C35.1235 33.5059 34.6758 33.9536 34.6758 34.5059C34.6758 35.0582 35.1235 35.5059 35.6758 35.5059H39.9992C40.5515 35.5059 40.9992 35.0582 40.9992 34.5059C40.9992 33.9536 40.5515 33.5059 39.9992 33.5059Z"
        fill={convertThemeColor(iconType)}
      />
    </svg>
  );
};

export default BodyCheckUpIcon;
