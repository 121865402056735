import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TSimpleButtonTab, { LIST_TYPE } from "../../../components/TSimpleButtonTab/TSimpleButtonTab";
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import TScroller from '../../../components/TScroller/TScroller';
import { $L, formatTime } from '../../../utils/helperMethods';
import css from './HPRecommendWorkout.module.less';
import dummyJVideoList from "../../../../assets/mock/dummyJVideoList.json";
//컴포넌트
import WeeklyWorkout, { DAYS } from './WeeklyWorkout/WeeklyWorkout';

import HPTestNotStarted from './HPTestNotStarted/HPTestNotStarted';
import HPTestCompleted from './HPTestCompleted/HPTestCompleted';
import TButton, { SIZES, TYPES } from '../../../components/TButton/TButton';
import Spotlight from '@enact/spotlight';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { BODY_SCAN_SCORE } from '../../../utils/Constants';
import { SCORE } from './BarItem/BarItem'
import { MENUTYPE, getTabMenuTitle, TAB_DETAIL } from '../Constants';
import { BODY_CHECKUP_TYPE } from '../../../utils/Constants';
import { getAiList, getBodyCheckUpData, getContent, getWeeklySolution } from '../../../features/fitService/fitServiceSlice';
import classNames from 'classnames';
import useContent from '../../../hooks/useContent';
import { addPanels, updatePanel } from '../../../features/panels/panelsSlice';
import { panel_names } from '../../../utils/Config';
import THorizontalScrollList, { ITEM_SIZE } from '../../../components/THorizontalScrollList/THorizontalScrollList';
import SMediaItem from '../../../components/SMediaList/SMediaItem';

const getSimpleButtonList = () => ([
  $L('AI recommendations'),
  $L('My Body Alignment'),
  $L("My Range Of Motion"),
  $L("My Body Balance"),
  $L("My Physical Test")
]);

const tests = ["ai", "bodyAlignment", "ROM", "bodyBalance", "physicalTest"];

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const HPRecommendWorkout = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const scrollTo = useRef(null);
  const { recommendLists } = useSelector((state) => state.content);
  const bodyAlignment = useSelector((state) => state.bodyAlignment);
  const rom = useSelector((state) => state.rom);
  const bodyBalance = useSelector((state) => state.bodyBalance);
  const physicalTest = useSelector((state) => state.physicalTest);
  // const aiLists = useSelector((state) => state.content.aiLists);
  const weeklySolution = useSelector(state => state.homecareProgram.weeklySolution);
  const { userNumber } = useSelector((state) => state.common.appStatus);

  const tabMenuTitle = getTabMenuTitle();
  const simpleButtonList = getSimpleButtonList();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const selectedTabIndexRef = useRef(selectedTabIndex);
  const [selectedPart, setSelectedPart] = useState('all');
  const [selectWeek, setSelectWeek] = useState(new Date().getDay());

  //todo ai media list
  const CONTENT_TYPE = useMemo(() => {
    return { type: "recommend", subType: tests[selectedTabIndex === 0 ? 1 : selectedTabIndex] }
  }, [selectedTabIndex]);

  const SELECT_CONTENT = useMemo(() => {
    return { type: "recommend", selectList: weeklySolution }
  }, [weeklySolution]);

  const { contentList } = useContent(CONTENT_TYPE);
  const { selectContentList } = useContent(SELECT_CONTENT);

  useEffect(() => {
    dispatch(getBodyCheckUpData({ type: BODY_CHECKUP_TYPE.BODY_ALIGNMENT, date: bodyAlignment.dateList[bodyAlignment.dateList.length - 1], syncFlag: "immediately" }));
    dispatch(getBodyCheckUpData({ type: BODY_CHECKUP_TYPE.ROM, date: rom.dateList[rom.dateList.length - 1], syncFlag: "immediately" }));
    dispatch(getBodyCheckUpData({ type: BODY_CHECKUP_TYPE.BODY_BALANCE, date: bodyBalance.dateList[bodyBalance.dateList.length - 1], syncFlag: "immediately" }));
    dispatch(getBodyCheckUpData({ type: BODY_CHECKUP_TYPE.PHYSICAL_TEST, date: physicalTest.dateList[physicalTest.dateList.length - 1], syncFlag: "immediately" }));
  }, []);

  const progressItem = useCallback((day) => {
    if (day) {
      return (
        <div className={css.progressItem}>
          <div>{$L("Overall progress")+" "}</div>
          <div className={css.percent}>{Math.round(day.playTime / day.totalTime) * 100 + '%'}</div>
          <div className={css.vr} />
          <div>{$L("Workout Time") + " "}</div>
          <div className={classNames(day.playTime === 0 ? css.grayTime : css.playTime)}>{formatTime(day.playTime) || 0}</div>
          <div className={css.grayTime}>{"/" + (formatTime(day.totalTime) || 0)}</div>
        </div>
      )
    }
    return <></>
  }, []);

  /**
   * ai
   * weekly
   * media:
   * today media list
   * today+1 media list
   * today+2 media list
   * {
    "title": "하지 유연성과 어깨 외회전을 개선하는 운동",
    "totalTime": 300,
    "progressTime": 122,
    "contentList": [
        "bodyAlignment_HEAD_SHIFT_A",
        "ROM_NECK_NeckExtension",
        "ROM_SHOULDER_ShoulderFlexion",
        "bodyAlignment_PELVIC_SHIFT_Pelvic_Tilt_5_9"
      ]
    },
   *
   * test
   * tab: overall, partlist(bodyParts)
   * bar: title, date, score
   * media: score, contents
   */

  const testInfo = useMemo(() => {
    switch (tests[selectedTabIndex]) {
      case 'ai':
        if (!!(bodyAlignment.testResults || rom.testResults || bodyBalance.testResults || physicalTest.testResults)) {
          return { results: { results: true } }
        } else return;
      case 'bodyAlignment':
        return {
          results: bodyAlignment.testResults || {},
          lastDate: Array.from(bodyAlignment.dateList).length > 0 ? Array.from(bodyAlignment.dateList).pop() : ''
        };
      case 'ROM':
        return {
          results: rom.testResults || {},
          lastDate: Array.from(rom.dateList).length > 0 ? Array.from(rom.dateList).pop() : ''
        };
      case 'bodyBalance':
        return {
          results: bodyBalance.testResults || {},
          lastDate: Array.from(bodyBalance.dateList).length > 0 ? Array.from(bodyBalance.dateList).pop() : ''
        };
      case 'physicalTest':
        return {
          results: physicalTest.testResults || {},
          lastDate: Array.from(physicalTest.dateList).length > 0 ? Array.from(physicalTest.dateList).pop() : ''
        };
    }
    return {};
  }, [selectedTabIndex, bodyAlignment, rom, bodyBalance, physicalTest]);

  const tabInfos = useMemo(() => {
    const sortIndex = {
      neck: 1,
      shoulder: 2,
      trunk: 3,
      hip: 4,
      knee: 5,
    };

    if (tests[selectedTabIndex] === "ai") {
      return;
    } else {
      const titleArray = tabMenuTitle?.[tests[selectedTabIndex]];
      const ret = [];
      const keys = Object.keys(MENUTYPE?.[tests[selectedTabIndex]]);

      for (let i = 1; i < keys.length; i++) {
        let type = keys[i];
        let score = 0;
        const detail = TAB_DETAIL?.[tests[selectedTabIndex]]?.[keys[i]];

        //score
        if (tests[selectedTabIndex] === "bodyAlignment") {
          // bodyAlignment BODY_SCAN_SCORE[alignmentA: string]: number
          const alignmentA = testInfo?.results?.[testInfo.lastDate]?.[detail.mainkey]?.[detail.testkey]?.[3];
          const alignmentB = testInfo?.results?.[testInfo.lastDate]?.[detail.mainkey]?.[detail.testkey2]?.[3];
          if (BODY_SCAN_SCORE[alignmentA] && !BODY_SCAN_SCORE[alignmentB]) {
            score = BODY_SCAN_SCORE[alignmentA];
          } else if (BODY_SCAN_SCORE[alignmentA] && BODY_SCAN_SCORE[alignmentB]) {
            score = Math.min(BODY_SCAN_SCORE[alignmentA], BODY_SCAN_SCORE[alignmentB]);
          }
        } else if (tests[selectedTabIndex] === "ROM") {
          // ROM romA: string
          let romA = testInfo?.results?.[testInfo.lastDate]?.[detail.mainkey]?.[detail.testkey]?.grade[0];
          let romB = testInfo?.results?.[testInfo.lastDate]?.[detail.mainkey]?.[detail.testkey]?.grade[1];
          if (romA && romB) {
            romA = (romA === 'a' ? 3 : (romA === 'b' ? 2 : 1));
            romB = (romB === 'a' ? 3 : (romB === 'b' ? 2 : 1));
            score = Math.min(romA, romB);
          }
          if (detail?.bodyPart) {
            type = MENUTYPE.ROM_main[detail?.bodyPart.toUpperCase()];
          }
        } else if (tests[selectedTabIndex] === "bodyBalance") {
          // bodyBalance balanceA: number
          const balanceA = testInfo?.results?.[testInfo.lastDate]?.[detail.mainkey]?.[detail.testkey];
          const balanceB = testInfo?.results?.[testInfo.lastDate]?.[detail.mainkey]?.[detail.testkey2];
          if (balanceA && balanceB) score = Math.min(balanceA, balanceB);
        } else if (tests[selectedTabIndex] === "physicalTest") {
          // physicalTest physicalA: number
          const physicalA = testInfo?.results?.[testInfo.lastDate]?.[detail.testkey]?.score || 0;
          score = physicalA;
        }

        //todo
        //contentId
        const contentId = tests[selectedTabIndex] === "ROM" ?
          recommendLists?.[tests[selectedTabIndex]]?.[detail.mainkey.toUpperCase()]?.[0]?.contentId || ''
          : recommendLists?.[tests[selectedTabIndex]]?.[keys[i]]?.[0]?.contentId || '';

        ret.push({
          type: type,
          sortIndex: sortIndex[detail?.bodyPart] || i,
          title: titleArray[keys[i]],
          detailTitle: '',
          score: score,
          bodyPart: detail?.bodyPart || '',
          contentId: contentId,
        });
      }
      if (ret.length !== 1) {
        ret.sort((a, b) => {
          if (a.type === MENUTYPE.OVERALL || b.type === MENUTYPE.OVERALL) {
            return 0;
          }

          if (a.score > b.score) {
            if (a.score === 0 || b.score === 0) {
              return -1
            }
            return 1;
          }
          if (a.score < b.score) {
            if (a.score === 0 || b.score === 0) {
              return 1;
            }
            return -1
          };
          if (a.score === b.score) {
            return b.sortIndex < a.sortIndex ? 1 : -1;
          }
        });
      }
      return ret;
    }
    /**
      title: "Leg Alignment"
      detailTitle: ""
      score: 9
      bodyPart: "leg"
      type: "LEG_ALIGNMENT"
      contentId: "bodyAlignment_LEG_ALIGNMENT_BOW_LEG"
     */
  }, [testInfo, selectedTabIndex, recommendLists]);

  const bodyParts = useMemo(() => {
    if (tabInfos) {
      const set = new Set(tabInfos.map((li) => li?.bodyPart));
      const arr = [...set] || [];
      return arr;
    }
  }, [tabInfos]);

  const isEmpty = useCallback((dateList) => {
    return !dateList || dateList.length <= 0;
  }, []);

  const testWorkouts = useMemo(() => {
    let testWorkouts = {
      "bodyAlignment": { list: [], isTested: !isEmpty(bodyAlignment.dateList) },
      "ROM": { list: [], isTested: !isEmpty(rom.dateList) },
      "bodyBalance": { list: [], isTested: !isEmpty(bodyBalance.dateList) },
      "physicalTest": { list: [], isTested: !isEmpty(physicalTest.dateList) },
    }

    dummyJVideoList.data.forEach(item => {
      testWorkouts[item.test === 'rom' ? 'ROM' : item.test].list.push(item);
    });

    return testWorkouts;
  }, [dummyJVideoList]);

  const label = useCallback((part, type = 'num') => {
    let score;
    if (part !== "all" && tabInfos) {
      const arr = tabInfos.filter((info) => info.bodyPart === part && !!info.score).map((el) => el.score);
      score = arr.length > 0 ? Math.min(...new Set(arr)) : 0;
    }
    return type === 'num' ? score : SCORE(tests[selectedTabIndex], score);
  }, [tabInfos, selectedTabIndex])

  const getScrollTo = useCallback((cbScrollTo) => {
    if (cbScrollTo) {
      scrollTo.current = cbScrollTo;
    }
  }, []);

  const todayFocus = useCallback(() => {
    setTimeout(() => {
      const itemHeight = document.getElementById(`mediaLists_0`)?.clientHeight;
      if (scrollTo && scrollTo.current) {
        scrollTo.current({ position: { y: (new Date().getDay() * itemHeight), x: 0 }, animate: false });
      }
    }, 0);
  }, []);

  const onItemClick = useCallback(({ index }) => {
    setSelectedTabIndex(index);
    setSelectedPart('all');
    if (index === 0) {
      setSelectWeek(new Date().getDay());
      todayFocus();
    }
  }, []);

  const onMediaClick = useCallback(({ itemInfo }) => {
    if (itemInfo) {
      dispatch(getContent({ contentId: itemInfo.contentId }));
    }
    dispatch(addPanels({ name: panel_names.CONTENT_PLAYER_START, panelInfo: [{ contentId: itemInfo?.contentId }] }));
    dispatch(updatePanel({ name: panel_names.HOMECARE_RECOMMEND_WORKOUT, panelInfo: { index: selectedTabIndex } }));
  }, [selectedTabIndex]);

  const onPartClick = useCallback((part) => {
    setSelectedPart(part);
  }, []);

  const onClickWeek = useCallback((idx) => {
    setSelectWeek(idx);
    if (scrollTo && scrollTo.current) {
      const itemHeight = idx === new Date().getDay()
        ? document.getElementById(`mediaLists_${idx + 1}`)?.clientHeight
        : document.getElementById(`mediaLists_${idx}`)?.clientHeight;
      const todayItemHeight = document.getElementById(`mediaLists_${new Date().getDay()}`)?.clientHeight;

      if (idx > new Date().getDay()) {
        scrollTo.current({ position: { y: ((idx - 1) * itemHeight + todayItemHeight), x: 0 }, animate: true });
      } else {
        scrollTo.current({ position: { y: (idx * itemHeight), x: 0 }, animate: true });
      }
    }
  }, []);

  const onClickPlay = useCallback((mediaList) => () => {
    const data = [];

    mediaList.forEach(el => {
      data.push({ contentId: el.contentId, count: el.count });
    });

    dispatch(addPanels({ name: panel_names.CONTENT_PLAYER_START, panelInfo: data }));
  }, []);

  const contentItem = useCallback(({ type = "", level, index = 0 }) => {
    let data = [];

    if (type === 'ai') {
      data = selectContentList[index];
    } else if (type !== 'ai' && type !== '' && contentList[type]?.length > 0) {
      const arr = [];

      for (let i = 0; i < contentList[type].length; i++) {
        const content = contentList[type][i];
        {/*
          *데모용*
          bodyAlignment: 위험, (예방, 관리) 등급 영상 따로 있음
          ROM: 등급 상관 없음
          bodyBalance: 영상 없음
          physicalTest: 등급 상관 없음
        */}
        if (tests[selectedTabIndex] === "bodyAlignment") {
          if (level === 'weak') {
            if (content.score === 'bad') arr.push(content);
          } else if (content.score !== 'bad') {
            arr.push(content);
          }
        } else {
          arr.push(content);
        }
      }

      data = arr;
    }

    return (
      <THorizontalScrollList
        itemSize={ITEM_SIZE.large}
        startGap={75}
      >
        {data.length > 0 && data.map((item, index) => (
          <SMediaItem
            key={`${type}_${index}`}
            itemSize={ITEM_SIZE.large}
            itemInfo={item}
            onClick={onMediaClick}
            secondSubText={!!(type === 'ai')}
            subTypeIcon={!!(type === 'ai')}
          />
        ))}
      </THorizontalScrollList>
    );
  }, [contentList, selectContentList, selectedTabIndex]);

  useEffect(() => {
    selectedTabIndexRef.current = selectedTabIndex;
  }, [selectedTabIndex]);

  useEffect(() => {
    if (panelInfo && panelInfo.selectedTabIndex) {
      setTimeout(() => {
        setSelectedTabIndex(panelInfo.selectedTabIndex);
        setSelectedPart(panelInfo.part);
      }, 0);
    }
  }, [panelInfo]);

  useEffect(() => {
    if (panelInfo?.lastFocusedId) {
      setTimeout(() => {
        if (panelInfo.scrollTop && scrollTo.current) {
          scrollTo.current({ position: { y: panelInfo.scrollTop }, animate: false });
        }
        Spotlight.focus(panelInfo.lastFocusedId);
      }, 0);
    }

    if (panelInfo?.index) {
      setTimeout(() => {
        setSelectedTabIndex(panelInfo.index);
      }, 0);
    }

    todayFocus();

    return () => {
      dispatch(updatePanel({ name: panel_names.HOMECARE_RECOMMEND_WORKOUT, panelInfo: Object.assign({}, panelInfo, { index: selectedTabIndexRef.current }) }));
    }
  }, []);

  const disabledItem = useMemo(() => {
    return [$L("My Body Balance")];
  }, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("AI운동매니저 추천 프로그램")} />
      <TBody className={css.body}>
        <TSimpleButtonTab
          className={css.tab}
          listType={LIST_TYPE.report}
          contents={simpleButtonList}
          selectedIndex={selectedTabIndex}
          onItemClick={onItemClick}
          maxItemCount={5}
          disabledItem={disabledItem}
        />
        <div className={css.workoutContainer}>
          {tests[selectedTabIndex] === 'ai'
            ?
            // AI
            <Container className={css.noScroll}>
              <div>
                <WeeklyWorkout
                  title={$L(
                    "AI 운동 매니저가 측정 및 설문 데이터를 기반으로 주간 AI 맞춤형 운동추천을 제안드려요!"
                  )}
                  onClick={onClickWeek}
                  selectWeek={selectWeek}
                />
              </div>
              <TScroller
                className={css.scroller}
                cbScrollTo={getScrollTo}
              >
                <div className={css.aiLayer}>
                  {weeklySolution?.length > 0 &&
                    weeklySolution.map((day, idx) => {
                      const today = new Date();
                      const currentYear = today.getFullYear();
                      const currentMonth = today.getMonth();
                      const currentDate = today.getDate();
                      const currentDay = today.getDay();
                      return (
                        <div
                          key={"weekItem_" + idx}
                          className={css.mediaLists}
                          id={`mediaLists_${idx}`}
                        >
                          <div className={css.titleLayer}>
                            <div>
                              <div>
                                <div
                                  className={classNames(
                                    css.day,
                                    idx === currentDay
                                      ? css.today
                                      : css.nextday
                                  )}
                                >
                                  {idx === currentDay ? (
                                    $L("Today")
                                  ) : (
                                    <>
                                      <span>{$L(DAYS[idx])}&nbsp;</span>
                                      <span>
                                        {new Date(
                                          currentYear,
                                          currentMonth,
                                          currentDate - currentDay + idx
                                        ).getDate()}
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div className={css.title}>{day?.title}</div>
                              </div>
                              {idx === new Date().getDay() &&
                                progressItem(day)}
                            </div>
                            {idx === new Date().getDay() ? (
                              <TButton
                                className={css.playButton}
                                size={SIZES.small}
                                onClick={onClickPlay(day.contentList)}
                              >
                                {$L("Start the workout")}
                              </TButton>
                            ) : (
                              progressItem(day)
                            )}
                          </div>
                          {contentItem({ type: "ai", index: idx })}
                        </div>
                      );
                    })}
                </div>
              </TScroller>
            </Container>
            : (
              userNumber && Object.keys(testInfo.results).length > 0
                ?
                // TEST
                <HPTestCompleted
                  testType={tests[selectedTabIndex === 0 ? 1 : selectedTabIndex]}
                  contentItem={contentItem}
                  selectedTabIndex={selectedTabIndex}
                  testInfo={testInfo}
                  tabInfos={tabInfos}
                  bodyParts={bodyParts}
                  selectedPart={selectedPart}
                  label={label}
                  onPartClick={onPartClick}
                />
                :
                // NOT TEST
                <HPTestNotStarted
                  testType={tests[selectedTabIndex === 0 ? 1 : selectedTabIndex]}
                  workouts={testWorkouts[tests[selectedTabIndex === 0 ? 1 : selectedTabIndex]].list}
                  selectedTabIndex={selectedTabIndex}
                />
            )}
        </div>
      </TBody>

    </TPanel>

  );
};

export default HPRecommendWorkout;
