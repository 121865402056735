import classNames from 'classnames';
import React from "react";
import css from './Title.module.less';

/**
 *
 * @param {type} 'normal', 'withIcon
 * {icon} 'check'
 * @returns
 */

const TYPES = { normal: "normal", withIcon: "withIcon" };
const ICONS = { check: "check"}

const Title = ({children, type, icon, className, ...rest}) => {
	return (
		<div className={classNames(
      css.title,
      css[type],
      className
      )} 
      {...rest}
    >
      {type === "withIcon" && icon && 
        <div className={classNames(css.icon, css[icon])}/>
      }
      <div className={css.text}>{children}</div>
		</div>
	);
};

export default Title;
export { TYPES, ICONS };
