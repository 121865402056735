import { createSlice } from "@reduxjs/toolkit";

export const appDataSlice = createSlice({
  name: "appData",
  initialState: {
    mainIndex: null,
  },
  reducers: {
    addMainIndex: (state, action) => {
      state.mainIndex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMainIndex, decrement, incrementByAmount } =
  appDataSlice.actions;

export default appDataSlice.reducer;
