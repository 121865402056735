import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
  FOOTTYPE_VALUE,
  ONE_LEG_BALANCE_VALUE
} from "../../../../../utils/Constants";
import { footTypeStatus } from "./constantsFeedback";

export const convertGrades = (currentData) => {
  const twoLegData = currentData?.twolegstance;
  const oneLegData = currentData?.onelegstanceR;

  let totalGrades = {
    footType: [],
    bodyBalanceTab: [],
    cop: [],
  };
  if (twoLegData) {
    totalGrades.footType = footTypeGrade(twoLegData);
    totalGrades.bodyBalanceTab = bodyBalanceTab(twoLegData);
  }
  if (oneLegData) {
    totalGrades.cop = cop(oneLegData);
  }
  const ret = {
    footType: null,
    bodyBalanceTab: null,
    cop: null,
  };

  for (const type in totalGrades) {
    const grades = totalGrades[type];
    let showingGrade = null;
    for (const grade in grades) {
      if (grades[grade] === SCORE_MINT_KEY) {
        if (
          showingGrade !== SCORE_RED_KEY &&
          showingGrade !== SCORE_YELLOW_KEY
        ) {
          showingGrade = SCORE_MINT_KEY;
        }
      } else if (grades[grade] === SCORE_YELLOW_KEY) {
        if (showingGrade !== SCORE_RED_KEY) {
          showingGrade = SCORE_YELLOW_KEY;
        }
      } else if (grades[grade] === SCORE_RED_KEY) {
        showingGrade = SCORE_RED_KEY;
      } else {
        showingGrade = null;
      }
    }

    ret[type] = showingGrade;
  }
  return ret;
};

const footTypeGrade = (twoLegData) => {
  const indexL = twoLegData.value_archIndex_L;
  const indexR = twoLegData.value_archIndex_R;

  const status = (data) => {
    if (data < FOOTTYPE_VALUE.flatMax) {
      return footTypeStatus.FLATFOOT;
    } else if (FOOTTYPE_VALUE.susflatMin <= data && data < FOOTTYPE_VALUE.susflatMax) {
      return footTypeStatus.SUS_FLATFOOT;
    } else if (FOOTTYPE_VALUE.normalMin <= data && data <= FOOTTYPE_VALUE.normalMax) {
      return footTypeStatus.COMMON;
    } else if (FOOTTYPE_VALUE.suscavusMin < data && data <= FOOTTYPE_VALUE.suscavusMax) {
      return footTypeStatus.SUS_LUMBAR;
    } else if (FOOTTYPE_VALUE.cavusMin < data) {
      return footTypeStatus.LUMBAR;
    }
  };

  const statusLable = (statusData) => {
    switch (statusData) {
      case footTypeStatus.FLATFOOT:
      case footTypeStatus.LUMBAR:
        return SCORE_RED_KEY;
      case footTypeStatus.SUS_FLATFOOT:
      case footTypeStatus.SUS_LUMBAR:
        return SCORE_YELLOW_KEY;
      default:
        return SCORE_MINT_KEY;
    }
  };

  const grades = [statusLable(status(indexL)), statusLable(status(indexR))];
  return grades;
};

const bodyBalanceTab = (twoLegData) => {
  const balanceRatioXData = twoLegData.value_balanceRatio_LR;
  const balanceRatioYData = twoLegData.value_balanceRatio_FFRF;

  const leftValue = (balanceRatioXData + 1) / 2;
  const rightValue = 1 - leftValue;
  const frontValue = (balanceRatioYData + 1) / 2;
  const rFrontValue = 1 - frontValue;

  const leftPercentage = Math.round(leftValue * 100);
  const rightPercentage = Math.round(rightValue * 100);
  const frontPercentage = Math.round(frontValue * 100);
  const rFrontPercentage = Math.round(rFrontValue * 100);

  // x축
  let xGrade, yGrade;
  if (rightPercentage >= 70 || rightPercentage <= 30) {
    xGrade = SCORE_RED_KEY;
  } else if (rightPercentage >= 60 || rightPercentage <= 40) {
    xGrade = SCORE_YELLOW_KEY;
  } else {
    xGrade = SCORE_MINT_KEY;
  }

  // y축
  if (rFrontPercentage >= 70 || rFrontPercentage <= 30) {
    yGrade = SCORE_RED_KEY;
  } else if (rFrontPercentage >= 60 || rFrontPercentage <= 40) {
    yGrade = SCORE_YELLOW_KEY;
  } else {
    yGrade = SCORE_MINT_KEY;
  }

  // return [xGrade, yGrade];
  //todo for CES
  return [SCORE_MINT_KEY, SCORE_MINT_KEY];
};

const cop = (oneLegData) => {
  const indexL = oneLegData.value_leftBalance;
  const indexR = oneLegData.value_rightBalance;

  const status = (data) => {
    if (data < ONE_LEG_BALANCE_VALUE.preventionMax) {
      return SCORE_MINT_KEY;
    } else if (ONE_LEG_BALANCE_VALUE.managementMin <= data && data <= ONE_LEG_BALANCE_VALUE.managementMax) {
      return SCORE_YELLOW_KEY;
    } else if (ONE_LEG_BALANCE_VALUE.dangerMin < data) {
      return SCORE_RED_KEY;
    }
  };

  const grades = [status(indexL), status(indexR)];

  return grades;
};
