import React, { useRef, useEffect, useCallback, useState } from "react";
import VirtualGridList from "@enact/sandstone/VirtualList";
import classNames from "classnames";
import ri from '@enact/ui/resolution';
import css from "./TopList.module.less";
import { useSelector, useDispatch } from "react-redux";
import TopListItem from "./TopListItem";
import * as HelperMethods from "../../../../utils/helperMethods";
import SpotlightIds from '../../../../utils/SpotlightIds';
import Scroller from '@enact/sandstone/Scroller/Scroller';

import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div" );

const TopList = ({className, title, selectedIndex, contents, onItemClick, disabledItem=[], ...rest}) => {
	const scrollTo = useRef(null);
	const [isRightDirection, setIsRightDirection] = useState(true);
	const cbScrollTo = useCallback((fn)=>{
		scrollTo.current = fn;
	},[]);

	const _onItemClick = useCallback(({index, ev, ...rest})=>{
		if(onItemClick){
			onItemClick({index, ev, ...rest});
		}
	},[onItemClick]);

	const _onItemFocus = useCallback(({ev, index}) => {
		const listItem = ev.target
		if(listItem && scrollTo.current) {
			if(!HelperMethods.isChildFullyShowing(listItem.parentNode.parentNode, listItem)){
				scrollTo.current({node: listItem, animate: true, align: isRightDirection ? "right" : "left"});
			}
		}
	},[isRightDirection])

	const onScrollStop = useCallback(() => {
		setIsRightDirection((direction) => !direction);
	}, []);

	return (
		<Scroller
			scrollMode='translate'
			focusableScrollbar={false}
			className={css.scroller}
			direction='horizontal'
			horizontalScrollbar='hidden'
			verticalScrollbar='hidden'
			onScrollStop={onScrollStop}
			overscrollEffectOn={{
					arrowKey: false,
					drag: false,
					pageKey: false,
					track: false,
					wheel: false
			}}
			cbScrollTo={cbScrollTo}
		>
			<Container className={css.container}>
				{contents && contents.length > 0 && contents.map((item, index) => (
					<TopListItem
						key={"item" + index}
						className={css.item}
						index={index}
						selected={index === selectedIndex}
						title={item.title}
						itemInfo={item}
						onItemClick={_onItemClick}
						disabled={disabledItem.includes(item.title)}
						onFocus={_onItemFocus}
						{...rest}
					/>
				))}
			</Container>
		</Scroller>
	);
}

export default TopList;
