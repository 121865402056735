import css from './PhysicalTestGate.module.less';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import TSimpleButtonTab, {LIST_TYPE} from '../../../components/TSimpleButtonTab/TSimpleButtonTab';
import { $L } from '../../../utils/helperMethods';
import SftMain from './SeniorFitnessTest/SftMain';

const simpleButtonList = [$L('SFT'), $L("SPPB"), $L("TUG")];

const PhysicalTestGate = ({...rest}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onItemClick = useCallback(({index})=>{
    setSelectedTabIndex(index);
  },[]);

  const disabledItem = useMemo(() => {
    return simpleButtonList.slice(1);
  },[]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Physical Test")}/>
      <TBody className={css.body}>
        {simpleButtonList && simpleButtonList.length > 0 &&
          <TSimpleButtonTab
            className={css.tab}
            listType={LIST_TYPE.header} contents={simpleButtonList} selectedIndex={selectedTabIndex}
            onItemClick={onItemClick}
            disabledItem={disabledItem}
          />
        }
        <div className={css.container}>
          {selectedTabIndex === 0 &&
            <SftMain/>
          }
        </div>
      </TBody>
    </TPanel>
  );
};

export default PhysicalTestGate;
