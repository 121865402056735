import { stringTimetoSec } from "../../utils/helperMethods";


const TYPES = {
  bodyAlignment: ['HEAD_SHIFT', 'LEG_ALIGNMENT', 'PELVIC_TILT', 'SHOULDER_TILT', 'PELVIC_ROTATION', 'NECK_TILT', 'TRUNK_SHIFT', 'PELVIC_SHIFT', 'KNEE_FLEXION'],
  ROM: ['NECK', 'SHOULDER', 'TRUNK', 'KNEE', 'HIP'],
  physicalTest: ['LOWER_BODY_STR', 'UPPER_BODY_STR', 'AEROBIC', 'LOWER_BODY_FLEX', 'STATIC_BALANCE', 'UP_AND_GO_TEST']
}

const VIDEO_LIST = {
  Neck_Tilt_1_2: {
    title: '목이 편해지는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Neck+Tilt+1-2_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Neck+Tilt+1-2_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_1-2_A.jpg',
    thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/sft/Neck+Tilt+1-2_A/img_thumbnail_sft_necktilt.png',
    playTime: 322,
    position: '앉아서',
    category: '상지 유연성|목 유연성',
    exerciseEffect: '목 균형|목 안정화'
  },
  Head_Shift_Shoulder_1_3: {
    title: '어깨가 시원해지는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Head+Shift%2C+Shoulder+1-3_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Head+Shift%2C+Shoulder+1-3_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_1-3_A.jpg',
    playTime: 149,
    position: '앉아서',
    category: '상지 유연성|어깨 유연성',
    exerciseEffect: '목 안정화'
  },
  Neck_Tilt_1_4: {
    title: '목이 편해지는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Neck+Tilt+1-4_A.mp4',
    playUrlB:
      "https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Neck+Tilt+1-4_B.mp4",
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_1-4_A.jpg',
    playTime: 243,
    position: '앉아서',
    category: '상지 유연성|목 유연성',
    exerciseEffect: '목 균형|목 안정화'
  },
  Pelvic_Rotation_1_5: {
    title: '허리가 편해지는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Rotation+1-5_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Rotation+1-5_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_1-5_A.jpg',
    playTime: 145,
    position: '앉아서',
    category: '상지 유연성|척추 유연성',
    exerciseEffect: '골반 기울기'
  },
  Head_Shift_1_6: {
    title: '척추 유연성에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Head+Shift+1-6_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Head+Shift+1-6_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_1-6_A.jpg',
    thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/sft/Head+Shift+1-6_A/img_thumbnail_sft_headshift.png',
    playTime: 287,
    position: '앉아서',
    category: '상지 유연성|척추 유연성',
    exerciseEffect: '목 안정화'
  },
  Leg_Alignment_1_10: {
    title: '튼튼한 다리를 만드는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Leg+Alignment+1-10_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Leg+Alignment+1-10_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_1-10_A.jpg',
    thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/sft/Leg+Alignment+1-10_A/img_thumbnail_sft_legalignment.png',
    playTime: 180,
    position: '런지',
    category: '하지근력|민첩성과 동적균형|낙상방지|튼튼한 무릎',
    exerciseEffect: 'OX 다리 교정'
  },
  Knee_Flexion_2_2: {
    title: '하체 유연성에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Knee+Flexion+2-2_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Knee+Flexion+2-2_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_2-2_A.jpg',
    thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/sft/Knee+Flexion+2-2_A/img_thumbnail_sft_kneeflexion.png',
    playTime: 256,
    position: '앉아서',
    category: '하지 유연성',
    exerciseEffect: '무릎 안정화|목 균형'
  },
  Pelvic_Tilt_2_4: {
    title: '하지 유연성에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Tilt+2-4_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Tilt+2-4_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_2-4_A.jpg',
    playTime: 173,
    position: '앉아서',
    category: '하지 유연성',
    exerciseEffect: '골반 기울기|골반 균형|OX 다리 교정'
  },
  Pelvic_Shift_3_5: {
    title: '하체 근력에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift+3-5_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift+3-5_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_3-5_A.jpg',
    playTime: 253,
    position: '누워서',
    category: '하지근력|민첩성과 동적균형|낙상방지',
    exerciseEffect: 'OX 다리 교정|무릎 안정화|골반 균형'
  },
  Shoulder_Tilt_Trunk_Shift_4_3: {
    title: '어깨가 시원해지는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Shoulder+Tilt%2C+Trunk+Shift+4-3_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Shoulder+Tilt%2C+Trunk+Shift+4-3_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_4-3_A.jpg',
    playTime: 339,
    position: '앉아서',
    category: '상지 유연성|목 유연성',
    exerciseEffect: '목 균형|어깨 균형'
  },
  Pelvic_Rotation_4_5: {
    title: '허리가 편해지는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Rotation+4-5_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Rotation+4-5_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_4-5_A.jpg',
    thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/sft/Pelvic+Rotation+4-5_A/img_thumbnail_sft_pelvicrotation.png',
    playTime: 328,
    position: '앉아서',
    category: '상지 유연성',
    exerciseEffect: '골반 균형'
  },
  Knee_Flexion_4_6: {
    title: '하체 근력에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Knee+Flexion+4-6_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Knee+Flexion+4-6_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_4-6_A.jpg',
    playTime: 717,
    position: '앉아서',
    category: '하지 근력|민첩성과 동적균형|심폐지구력|튼튼한 무릎',
    exerciseEffect: '무릎 안정화|OX다리'
  },
  Pelvic_Shift_Leg_Alignment_4_8: {
    title: '낙상 방지에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift%2C+Leg+Alignment+4-8_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift%2C+Leg+Alignment+4-8_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_4-6_A.jpg',
    playTime: 182,
    position: '앉아서',
    category: '하지근력|민첩성과 동적균형|외발서기'
  },
  Pelvic_Shift_5_4: {
    title: '신체 균형에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift+5-4_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift+5-4_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_5-4_A.jpg',
    thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/sft/Pelvic+Shift+5-4_A/img_thumbnail_sft_pelvicshift.png',
    playTime: 289,
    position: '누워서',
    category: '하지근력|민첩성과 동적균형|낙상방지',
    exerciseEffect: '골반 균형|OX 다리 교정'
  },
  Pelvic_Tilt_5_9: {
    title: '척추 유연성에 좋은 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Tilt+5-9_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Tilt+5-9_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_5-9_A.jpg',
    thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/sft/Pelvic+Tilt+5-9_A/img_thumbnail_sft_pelvictilt.png',
    playTime: 118,
    position: '앉아서',
    category: '상지 유연성',
    exerciseEffect: '목 안정화|골반 균형'
  },
  Leg_Alignment_5_12: {
    title: '튼튼한 다리를 만드는 운동',
    playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Leg+Alignment+5-12_A.mp4',
    playUrlB: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Leg+Alignment+5-12_B.mp4',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/TITLE_C_5-12_A.jpg',
    playTime: 153,
    position: '서서',
    category: '하지근력|민첩성과 동적균형|튼튼한 무릎|심폐지구력|낙상방지',
    exerciseEffect: 'OX 다리 교정'
  },
  Neck_workout_2K: {
    title: '가상홈트 2K',
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/2K/neck/01.+Neck+workout_2K(2560x1440)-1121.mpd',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/01.+Neck+workout_2K(2560x1440)-1121.jpg',
    playTime: 928,
    position: '서서',
    category: '상지 유연성|목 유연성',
    exerciseEffect: '목 균형|어깨 균형'
  },
  Neck_workout_4K: {
    title: '가상홈트 4K',
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/4K/neck/01.+Neck+workout_4K(3840x2160)-1121.mpd',
    thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/01.+Neck+workout_4K(3840x2160)-1121.jpg',
    playTime: 928,
    position: '서서',
    category: '상지 유연성|목 유연성',
    exerciseEffect: '목 균형|어깨 균형'
  },
  Senior_1_1: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-1/1~4%E1%84%8C%E1%85%AE+1-1.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-1/1~4%E1%84%8C%E1%85%AE+1-1.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-1+A.jpg',
    level: '초급',
    title: '상체 혈액순환에 좋은 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:02:09',
    position: '의자에앉아서',
    bodyPart: '손|손목|팔꿈치|어깨',
    exerciseEffect: '유연성|팔 혈액순환',
    equipment: '의자'
  },
  Senior_1_2: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-2/1~4%E1%84%8C%E1%85%AE+1-2.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-2/1~4%E1%84%8C%E1%85%AE+1-2.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-2+A.jpg',
    level: '초급',
    title: '목이 편해지는 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:04:51',
    position: '의자에앉아서',
    bodyPart: '목',
    exerciseEffect: '상지유연성 향상|목 좌우 기울기∙머리 앞뒤 이동 개선',
    equipment: '의자'
  },
  Senior_1_3: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-3/1~4%E1%84%8C%E1%85%AE+1-3.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-3/1~4%E1%84%8C%E1%85%AE+1-3.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-3+A.jpg',
    level: '초급',
    title: '어깨주변 근육 강화 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:03:11',
    position: '의자에앉아서',
    bodyPart: '어깨주변(회전근개)',
    exerciseEffect: '상지유연성 향상|머리 앞뒤 이동 개선',
    equipment: '의자',
    exceptionTarget: '오십견'
  },
  Senior_1_4: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-4/1~4%E1%84%8C%E1%85%AE+1-4.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-4/1~4%E1%84%8C%E1%85%AE+1-4.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-4+A.jpg',
    level: '초급',
    title: '목 유연성에 좋은 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:03:40',
    position: '의자에앉아서',
    bodyPart: '목',
    exerciseEffect: '상지유연성 향상|목 좌우 기울기∙머리 앞뒤 이동 개선',
    equipment: '의자'
  },
  Senior_1_5: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-5/1~4%E1%84%8C%E1%85%AE+1-5.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-5/1~4%E1%84%8C%E1%85%AE+1-5.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-5+A.jpg',
    level: '초급',
    title: '척추 유연성에 좋은 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:02:37',
    position: '의자에앉아서',
    bodyPart: '척추',
    exerciseEffect: '상지유연성 향상|골반 앞뒤 틀어짐 개선',
    equipment: '의자'
  },
  Senior_1_6: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-6/1~4%E1%84%8C%E1%85%AE+1-6.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-6/1~4%E1%84%8C%E1%85%AE+1-6.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-6+A.jpg',
    level: '초급',
    title: '굽은 등을 시원하게 펴주는 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:05:47',
    position: '의자에앉아서',
    bodyPart: '척추|코어',
    exerciseEffect: '상지유연성 향상|머리 앞뒤 이동 개선',
    equipment: '의자'
  },
  Senior_1_7: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-7/1~4%E1%84%8C%E1%85%AE+1-7.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-7/1~4%E1%84%8C%E1%85%AE+1-7.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-7+A.jpg',
    level: '초급',
    title: '낙상방지에 좋은 종아리와 발목 스트레칭',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:04:50',
    position: '의자잡고 서서',
    bodyPart: '종아리|발목',
    exerciseEffect: '민첩성∙평형성 향상|다리 휘어짐∙목 좌우 기울기 개선',
    equipment: '의자',
    exceptionTarget: '발목'
  },
  Senior_1_8: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-8/1~4%E1%84%8C%E1%85%AE+1-8.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-8/1~4%E1%84%8C%E1%85%AE+1-8.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-8.A.jpg',
    level: '초급',
    title: '허리가 편해지는 롤다운 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:04:05',
    position: '의자잡고 서서',
    bodyPart: '척추',
    exerciseEffect: '상지유연성 향상',
    equipment: '의자'
  },
  Senior_1_9: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-9/1~4%E1%84%8C%E1%85%AE+1-9.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-9/1~4%E1%84%8C%E1%85%AE+1-9.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-9+A.jpg',
    level: '중급',
    title: '하체 밸런스를 강화하는 고관절 운동 TOP3',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:04:22',
    position: '의자잡고서서|의자없이서서',
    bodyPart: '고관절|하체',
    exerciseEffect: '하지근력∙민첩성∙평형성 향상|다리 휘어짐 개선',
    equipment: '의자',
    exceptionTarget: '고관절|무릎'
  },
  Senior_1_10: {
    title: "하체 밸런스를 강화하는 고관절 운동 TOP3 고급편",
    playUrl:
      "https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-10/1~4%E1%84%8C%E1%85%AE+1-10.mpd",
    playUrlB:
      "https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-10/1~4%E1%84%8C%E1%85%AE+1-10.mpd",
    thumbnailUrl:
      "https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-10+A.jpg",
    level: '상급',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:02:26',
    position: '스텐딩',
    exerciseEffect: '하지근력∙민첩성∙평형성 향상|다리 휘어짐 개선',
    equipment: '매트'
  },
  Senior_1_11: {
    title: "햄스트링 유연성에 좋은 운동",
    playUrl:
      "https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-11/1~4%E1%84%8C%E1%85%AE+1-11.mpd",
    playUrlB:
      "https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-11/1~4%E1%84%8C%E1%85%AE+1-11.mpd",
    thumbnailUrl:
      "https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-11+A.jpg",
    level: '초급',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:02:16',
    position: '스텐딩',
    bodyPart: '상체',
    exerciseEffect: '상지유연성 향상|머리 앞뒤 이동 개선',
    equipment: '매트'
  },
  Senior_1_12: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+1-12/1~4%E1%84%8C%E1%85%AE+1-12.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+1-12/1~4%E1%84%8C%E1%85%AE+1-12.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+1-12+A.jpg',
    level: '초급',
    title: '전신이 시원해지는 스트레칭',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:03:56',
    position: '스텐딩',
    bodyPart: '햄스트링|상체',
    exerciseEffect: '하∙상지 유연성 향상|무릎 굽힘∙골반 앞뒤 기울기∙골반 앞뒤 틀어짐∙다리 휘어짐 개선',
    equipment: '매트'
  },
  Senior_2_1: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-1/1~4%E1%84%8C%E1%85%AE+2-1.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-1/1~4%E1%84%8C%E1%85%AE+2-1.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-1+A.jpg',
    level: '초급',
    title: '상/하체 혈액순환에 좋은 스트레칭 종합편',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:04:00',
    position: '의자에 앉아서',
    bodyPart: '팔|손|승모근|가슴|하체|어깨',
    exerciseEffect: '상지유연성 향상|목 좌우 기울기∙머리 앞뒤 이동 개선',
    equipment: '의자'
  },
  Senior_2_2: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-2/1~4%E1%84%8C%E1%85%AE+2-2.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-2/1~4%E1%84%8C%E1%85%AE+2-2.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-2+A.jpg',
    level: '초급',
    title: '의자에 앉아서 하는 햄스트링 스트레칭',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:03:06',
    position: '의자에 앉아서',
    bodyPart: '햄스트링',
    exerciseEffect: '하지유연성 향상|무릎 굽힘∙목 좌우 기울기 개선',
    equipment: '의자',
    exceptionTarget: '고관절|햄스트링 타이트'
  },
  Senior_2_3: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-3/1~4%E1%84%8C%E1%85%AE+2-3.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-3/1~4%E1%84%8C%E1%85%AE+2-3.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-3+A.jpg',
    level: '초급',
    title: '낙상방지에 좋은 하체 운동',
    category: '스트레칭|유산소',
    ageGroup: '45~65세',
    playTime: '00:04:50',
    position: '의자에 앉아서',
    bodyPart: '복근|종아리',
    exerciseEffect: '민첩성∙심폐지구력 향상|다리 휘어짐 개선',
    equipment: '의자'
  },
  Senior_2_4: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-4/1~4%E1%84%8C%E1%85%AE+2-4.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-4/1~4%E1%84%8C%E1%85%AE+2-4.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-4+A.jpg',
    level: '초급',
    title: '고관절, 엉덩이, 햄스트링 스트레칭',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:03:05',
    position: '의자에 앉아서',
    bodyPart: '고관절|척추|둔부|햄스트링',
    exerciseEffect: '하지유연성 향상|골반 앞뒤 틀어짐∙골반 좌우 기울기∙다리 휘어짐 개선',
    equipment: '의자',
    exceptionTarget: '고관절|햄스트링 타이트'
  },
  Senior_2_5: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-5/1~4%E1%84%8C%E1%85%AE+2-5.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-5/1~4%E1%84%8C%E1%85%AE+2-5.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-5+A.jpg',
    level: '중급',
    title: '하체근력 강화가 될 수 밖에 없는 운동',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:07:05',
    position: '의자에 앉아서',
    bodyPart: '하체|종아리|고관절|둔부',
    exerciseEffect: '하지근력∙하지유연성∙민첩성 향상|다리 휘어짐 개선',
    equipment: '의자',
    exceptionTarget: '무릎'
  },
  Senior_2_6: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-6/1~4%E1%84%8C%E1%85%AE+2-6.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-6/1~4%E1%84%8C%E1%85%AE+2-6.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-6+A.jpg',
    level: '초급',
    title: '튼튼한 다리를 만들어주는 운동',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:04:57',
    position: '의자에 앉아서',
    bodyPart: '하체|종아리|고관절|둔부',
    exerciseEffect: '하지근력∙민첩성 향상|다리 휘어짐 개선',
    equipment: '의자',
    exceptionTarget: '무릎'
  },
  Senior_2_7: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-7/1~4%E1%84%8C%E1%85%AE+2-7.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-7/1~4%E1%84%8C%E1%85%AE+2-7.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-7+A.jpg',
    level: '초급',
    title: '하체 균형을 키우는 의자 스쿼트',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:06:23',
    position: '의자잡고 서서',
    bodyPart: '하체|종아리',
    exerciseEffect: '하지근력∙민첩성∙평형성 향상|무릎 굽힘∙다리 휘어짐∙골반 좌우 기울기 개선',
    equipment: '의자',
    exceptionTarget: '무릎|고관절'
  },
  Senior_2_8: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-8/1~4%E1%84%8C%E1%85%AE+2-8.mpd',
    playUrlB: 'https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-8/1~4%E1%84%8C%E1%85%AE+2-8.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-8+A.jpg',
    level: '중급',
    title: '밸런스 향상에 좋은 레그스윙',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:03:04',
    position: '의자잡고 서서',
    bodyPart: '복근|하체',
    exerciseEffect: '하지근력∙민첩성∙심폐지구력∙평형성 향상|다리 휘어짐 개선',
    equipment: '의자',
    exceptionTarget: '빈혈|고관절|밸런스 떨어지는 분'
  },
  Senior_2_9: {
    title: "밸런스 향상에 좋은 제자리 걷기 운동",
    playUrl:
      "https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-9/1~4%E1%84%8C%E1%85%AE+2-9.mpd",
    playUrlB:
      "https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-9/1~4%E1%84%8C%E1%85%AE+2-9.mpd",
    thumbnailUrl:
      "https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-9+A.jpg",
    level: '초급',
    category: '스트레칭|유산소',
    ageGroup: '45~65세',
    playTime: '00:03:25',
    position: '정면보고서서',
    bodyPart: '하체',
    exerciseEffect: '민첩성∙심폐지구력∙평형성 향상',
    equipment: '매트',
    exceptionTarget: '무릎'
  },
  Senior_2_10: {
    title: "어깨, 옆구리, 목이 시원해지는 스트레칭",
    playUrl:
      "https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+2-10/1~4%E1%84%8C%E1%85%AE+2-10.mpd",
    playUrlB:
      "https://d16h06llvpeyov.cloudfront.net/hometraining/1~4%EC%A3%BC+2-10/1~4%E1%84%8C%E1%85%AE+2-10.mpd",
    thumbnailUrl:
      "https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+2-10+A.jpg",
    level: '초급',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:01:41',
    position: '정면보고서서',
    bodyPart: '옆구리|목',
    exerciseEffect: '상지유연성 향상|머리 앞뒤 이동 개선',
    equipment: '매트'
  },
  Senior_3_1: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-1/1~4%E1%84%8C%E1%85%AE+3-1.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-1+A.jpg',
    level: '초급',
    title: '매트에 앉아서 하는 목과 어깨 스트레칭',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:06:48',
    position: '매트에 앉아서',
    bodyPart: '목|어깨',
    exerciseEffect: '상지유연성 향상|목 좌우 기울기∙머리 앞뒤 이동∙어깨 좌우 기울기 개선',
    equipment: '매트'
  },
  Senior_3_2: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-2/1~4%E1%84%8C%E1%85%AE+3-2.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-2+A.jpg',
    level: '초급',
    title: '누워서 하는 단단한 배 만들기 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:05:32',
    position: '매트에 누워서',
    bodyPart: '고관절',
    exerciseEffect: '민첩성 향상|골반 앞뒤 틀어짐∙골반 좌우 기울기∙골반 앞뒤 기울기 개선',
    equipment: '매트',
    exceptionTarget: '고관절'
  },
  Senior_3_3: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-3/1~4%E1%84%8C%E1%85%AE+3-3.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-3+A.jpg',
    level: '중급',
    title: '누워서 하는 하체근력 강화 운동',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:04:51',
    position: '매트에 누워서',
    bodyPart: '하체',
    exerciseEffect: '하지근력∙평형성 향상|무릎 굽힘∙다리 휘어짐 개선',
    equipment: '매트',
    exceptionTarget: '허리'
  },
  Senior_3_4: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-4/1~4%E1%84%8C%E1%85%AE+3-4.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-4+A.jpg',
    level: '중급',
    title: '누워서 하는 복근 강화 운동',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:05:46',
    position: '매트에 누워서',
    bodyPart: '햄스트링',
    exerciseEffect: '민첩성∙하지유연성 향상|골반 앞뒤 틀어짐∙골반 앞뒤 기울기∙무릎 굽힘 개선',
    equipment: '매트',
    exceptionTarget: '햄스트링'
  },
  Senior_3_5: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-5/1~4%E1%84%8C%E1%85%AE+3-5.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-5+A.jpg',
    level: '중급',
    title: '엉덩이 근육 강화에 좋은 업-다운-써클',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:03:30',
    position: '매트에  옆으로 누워서',
    bodyPart: '둔근',
    exerciseEffect: '하지근력∙민첩성∙평형성 향상|다리 휘어짐∙무릎 굽힘∙골반 좌우 기울기∙골반 앞뒤 기울기 개선',
    equipment: '매트',
    exceptionTarget: '고관절'
  },
  Senior_3_6: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-6/1~4%E1%84%8C%E1%85%AE+3-6.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-6+A.jpg',
    level: '상급',
    title: '허리가 편해지는 슈퍼맨 운동',
    category: '스트레칭|근력',
    ageGroup: '45~65세',
    playTime: '00:04:37',
    position: '의자잡고 서서',
    bodyPart: '척추신전근|어깨',
    exerciseEffect: '하지근력∙상지근력∙민첩성 향상|어깨 앞뒤 이동∙골반 앞뒤 기울기∙골반 좌우 기울기 개선',
    equipment: '매트',
    exceptionTarget: '척추|어깨'
  },
  Senior_3_7: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-7/1~4%E1%84%8C%E1%85%AE+3-7.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-7+A.jpg',
    level: '중급',
    title: '밸런스 향상에 좋은 옆구리 강화 운동',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:03:28',
    position: '매트에 엎드려서',
    bodyPart: '복근',
    exerciseEffect: '민첩성∙평형성 향상|골반 좌우 기울기 개선',
    equipment: '매트'
  },
  Senior_3_8: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-8/1~4%E1%84%8C%E1%85%AE+3-8.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-8+A.jpg',
    level: '초급',
    title: '스트레스 해소에 좋은 펀칭 유산소',
    category: '유산소',
    ageGroup: '45~65세',
    playTime: '00:03:30',
    position: '정면보고서서',
    bodyPart: '팔',
    exerciseEffect: '상지근력∙심폐지구력 향상',
    equipment: '매트'
  },
  Senior_3_9: {
    playUrl: 'https://d16h06llvpeyov.cloudfront.net/workOut/1~4%EC%A3%BC+3-9/1~4%E1%84%8C%E1%85%AE+3-9.mpd',
    thumbnailUrl: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/workout/ORIGINAL/1~4%E1%84%8C%E1%85%AE+3-9+A.jpg',
    level: '초급',
    title: '기지개쭉-어깨으쓱 상체 스트레칭',
    category: '스트레칭',
    ageGroup: '45~65세',
    playTime: '00:01:19',
    position: '정면보고서서',
    bodyPart: '어깨|목',
    exerciseEffect: '상지유연성 향상|어깨 좌우 기울기 개선',
    equipment: '매트'
  }
};

const METADATA_INFO = {
  /**
   * bodyAlignment { [MENUTYPE]: [MENUTYPE_TEST] }
   */
  NECK_TILT: {
    NECK_TILT: {
      title: '목빗근 스트레칭',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Neck+tilt.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Neck+tilt.jpg',
      thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/body+Alignment/Neck+tilt/img_thumbnail_alignment_necktilt.png',
      playTime: 18,
      position: '앉아서',
      exerciseEffect: '목빗근 스트레칭을 통해 바른 목 정렬 유지',
      muscle: '목빗근',
      exceptionTarget: '목에 심한 통증이 있는 경우|목 주변에 구조적 손상이 있는 경우(인대, 근육, 힘줄 등)',
      caution: '스트레칭 하는 반대편 어깨가 올라가지 않도록 주의합니다.|스트레칭 중 목에 통증이 있는 경우 즉시 중단합니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Neck_Tilt_1_2,
    /* sample start */
    Neck_workout_2K: VIDEO_LIST.Neck_workout_2K,
    Neck_workout_4K: VIDEO_LIST.Neck_workout_4K,
    /* // sample end */
    Neck_Tilt_1_4: VIDEO_LIST.Neck_Tilt_1_4,
    Knee_Flexion_2_2: VIDEO_LIST.Knee_Flexion_2_2,
    Shoulder_Tilt_Trunk_Shift_4_3: VIDEO_LIST.Shoulder_Tilt_Trunk_Shift_4_3,
    Senior_1_2: VIDEO_LIST.Senior_1_2,
    Senior_1_4: VIDEO_LIST.Senior_1_4,
    Senior_1_7: VIDEO_LIST.Senior_1_7,
    Senior_2_1: VIDEO_LIST.Senior_2_1,
    Senior_3_1: VIDEO_LIST.Senior_3_1
  },
  HEAD_SHIFT: {
    ANTERIOR: {
      title: '목 안정화 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Head+shift+(Anterior).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Head+shift+(Anterior).jpg',
      playTime: 13,
      position: '엎드려서',
      exerciseEffect: '목 안정화 운동을 통해 바른 머리 자세 유지',
      muscle: '전,측 두직근|경장근|두장근',
      exceptionTarget: '두통 및 어지러움증이 있는 경우',
      caution: '어깨가 올라가거나 앞으로 말리지 않도록 주의합니다.|어깨에 힘이 들어가지 않도록 주의합니다.|목을 구부리지 않도록 주의합니다.',
      score: 'bad'
    },
    POSTERIOR: {
      title: '목 안정화 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Head+shift+(Posterior).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Head+shift+(Posterior).jpg',
      playTime: 13,
      position: '누워서',
      exerciseEffect: '목 안정화 운동을 통해 바른 머리 자세 유지',
      muscle: '전,측 두직근|경장근|두장근',
      exceptionTarget: '두통 및 어지러움증이 있는 경우',
      caution: '어깨가 올라가거나 앞으로 말리지 않도록 주의합니다.|어깨에 힘이 들어가지 않도록 주의합니다.|목을 구부리면서 당기지 않도록 주의합니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Neck_Tilt_1_2,
    Head_Shift_Shoulder_1_3: VIDEO_LIST.Head_Shift_Shoulder_1_3,
    Neck_Tilt_1_4: VIDEO_LIST.Neck_Tilt_1_4,
    Head_Shift_1_6: VIDEO_LIST.Head_Shift_1_6,
    Pelvic_Tilt_5_9: VIDEO_LIST.Pelvic_Tilt_5_9,
    Senior_1_2: VIDEO_LIST.Senior_1_2,
    Senior_1_3: VIDEO_LIST.Senior_1_3,
    Senior_1_4: VIDEO_LIST.Senior_1_4,
    Senior_1_6: VIDEO_LIST.Senior_1_6,
    Senior_1_11: VIDEO_LIST.Senior_1_11,
    Senior_2_1: VIDEO_LIST.Senior_2_1,
    Senior_2_2: VIDEO_LIST.Senior_2_2,
    Senior_2_10: VIDEO_LIST.Senior_2_10,
    Senior_3_1: VIDEO_LIST.Senior_3_1
  },
  SHOULDER_TILT: {
    SHOULDER_TILT: {
      title: '어깨올림근 스트레칭',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Shoulder+tilt.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Shoulder+tilt.jpg',
      thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/body+Alignment/Shoulder+tilt/img_thumbnail_alignment_shouldertilt.png',
      playTime: 16,
      position: '앉아서',
      exerciseEffect: '어깨 올림근 스트레칭을 통해 바른 어깨 정렬 유지',
      muscle: '어깨올림근',
      exceptionTarget: '목, 어깨에 심한 통증이 있는 경우|목, 어깨 주변에 구조적 손상이 있는 경우(인대, 근육, 힘줄 등)',
      caution: '스트레칭 하는 반대쪽 어깨가 올라가지 않도록 주의합니다.|머리를 아래방향으로 과도하게 누르지 않도록 주의합니다.|스트레칭 중 목에 통증이 있는 경우 즉시 중단합니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Shoulder_Tilt_Trunk_Shift_4_3,
    Senior_3_1: VIDEO_LIST.Senior_3_1,
    Senior_3_9: VIDEO_LIST.Senior_3_9
  },
  TRUNK_SHIFT: { // SHOULDER_SHIFT
    ANTERIOR: {
      title: '가슴근 스트레칭',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Shoulder+Shift+(Anterior).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Shoulder+Shift+(Anterior).jpg',
      playTime: 28,
      position: '엎드려서',
      exerciseEffect: '가슴근 스트레칭을 통한 어깨 전방 기울기 완화',
      muscle: '가슴근',
      exceptionTarget: '스트레칭 시 날카로운 통증이 있는 경우|손가락에 저린 증상이 나타나는 경우',
      caution: '스트레칭 시 가능한 범위 내에서 시행합니다.|스트레칭 중 어깨 관절에 통증이 있는 경우 즉시 중단합니다.',
      score: 'bad'
    },
    POSTERIOR: {
      title: '무릎대고 팔굽혀펴기',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Shoulder+Shift+(Posterior).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Shoulder+Shift+(Posterior).jpg',
      playTime: 26,
      position: '엎드려서',
      exerciseEffect: '큰가슴근 강화운동을 통한 어깨 후방 기울기 완화',
      muscle: '큰가슴근',
      caution: '운동을 하는 동안 허리가 과도하게 젖혀지지 않도록 주의합니다.|어깨에 통증이 발생하지 않는 범위 내에서 운동을 시행합니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Shoulder_Tilt_Trunk_Shift_4_3,
    Senior_3_1: VIDEO_LIST.Senior_3_1,
    Senior_3_6: VIDEO_LIST.Senior_3_6
  },
  PELVIC_TILT: {
    PELVIC_TILT: {
      title: '허리 근육 스트레칭 / 옆구리 스트레칭',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+tilt.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Pelvic+tilt.jpg',
      playTime: 15,
      position: '앉아서',
      exerciseEffect: '요방형근 스트레칭을 통한 골반 정렬 유지',
      muscle: '요방형근',
      exceptionTarget: '스트레칭 시 날카로운 통증이 있는 경우|시작 자세에서 골반이나 무릎에 통증이 발생하는 경우|요추의 불안정성이 있는 경우',
      caution: '스트레칭 중 고관절 또는 무릎 통증이 있는 경우 즉시 중단합니다.|손으로 골반을 잡기 어려울 경우 무릎을 잡습니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Pelvic_Tilt_2_4,
    Pelvic_Shift_3_5: VIDEO_LIST.Pelvic_Shift_3_5,
    Pelvic_Shift_5_4: VIDEO_LIST.Pelvic_Shift_5_4,
    Pelvic_Tilt_5_9: VIDEO_LIST.Pelvic_Tilt_5_9,
    Senior_2_4: VIDEO_LIST.Senior_2_4,
    Senior_2_7: VIDEO_LIST.Senior_2_7,
    Senior_3_2: VIDEO_LIST.Senior_3_2,
    Senior_3_5: VIDEO_LIST.Senior_3_5,
    Senior_3_6: VIDEO_LIST.Senior_3_6,
    Senior_3_7: VIDEO_LIST.Senior_3_7
  },
  PELVIC_ROTATION: {
    PELVIC_ROTATION: {
      title: '좌우 윗몸 일으키기 / 크런치 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Rotation.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Pelvic+Rotation.jpg',
      thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/body+Alignment/Pelvic+Rotation/img_thumbnail_alignment_pelvicrotation.png',
      playTime: 21,
      position: '누워서',
      exerciseEffect: '내,외 복사근 강화운동을 통한 골반의 정렬 회복',
      muscle: '내,외 복사근',
      exceptionTarget: '경추의 불안정 및 통증을 호소하는 경우|허리에 통증이 발생하는 경우',
      caution: '윗몸 일으키기 동작을 처음 시작할 때 목 주변에 불편감이 있다면 베개를 베고 시작해주세요.',
      score: 'bad'
    },
    A: VIDEO_LIST.Pelvic_Rotation_1_5,
    Pelvic_Tilt_2_4: VIDEO_LIST.Pelvic_Tilt_2_4,
    Pelvic_Rotation_4_5: VIDEO_LIST.Pelvic_Rotation_4_5,
    Pelvic_Shift_5_4: VIDEO_LIST.Pelvic_Shift_5_4,
    Senior_1_5: VIDEO_LIST.Senior_1_5,
    Senior_1_12: VIDEO_LIST.Senior_1_12,
    Senior_2_4: VIDEO_LIST.Senior_2_4,
    Senior_3_2: VIDEO_LIST.Senior_3_2,
    Senior_3_4: VIDEO_LIST.Senior_3_4
  },
  PELVIC_SHIFT: {
    ANTERIOR: {
      title: '장요근 스트레칭',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift+(Anterior).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Pelvic+Shift+(Anterior).jpg',
      thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/body+Alignment/Pelvic+Shift+(Anterior)/img_thumbnail_alignment_pelvicshift.png',
      playTime: 13,
      position: '런지',
      exerciseEffect: '단축된 장요근 스트레칭을 통한 골반 전방 기울기 완화',
      muscle: '장요근',
      exceptionTarget: '무릎에 통증이 있는 경우',
      caution: '스트레칭을 할 때 요통이 발생하지 않는 지 주의가 필요합니다.|스트레칭을 할 때 골반이 틀어지지 않고 바른 정렬을 유지하도록 합니다.|스트레칭을 할 때 허리가 과도하게 젖혀지지 않도록 주의합니다.',
      score: 'bad'
    },
    POSTERIOR: {
      title: '슈퍼맨 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Pelvic+Shift+(Posterior).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Pelvic+Shift+(Posterior).jpg',
      playTime: 15,
      position: '엎드려서',
      exerciseEffect: '허리 폄근 강화운동을 통한 골반 후방 기울기 완화',
      muscle: '최장근|장늑근|극근',
      caution: '운동하는 동안 요통이 발생하지 않는지 주의가 필요합니다.|허리를 과도하게 펴면 통증이 발생할 수 있어 주의가 필요합니다.|목이 과도하게 젖혀지거나 구부러지지 않고 척추와 일직선이 되도록 중립자세를 유지합니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Pelvic_Shift_3_5,
    Pelvic_Rotation_4_5: VIDEO_LIST.Pelvic_Rotation_4_5,
    Pelvic_Shift_Leg_Alignment_4_8: VIDEO_LIST.Pelvic_Shift_Leg_Alignment_4_8,
    Pelvic_Shift_5_4: VIDEO_LIST.Pelvic_Shift_5_4,
    Pelvic_Tilt_5_9: VIDEO_LIST.Pelvic_Tilt_5_9,
    Senior_1_12: VIDEO_LIST.Senior_1_12,
    Senior_3_2: VIDEO_LIST.Senior_3_2,
    Senior_3_4: VIDEO_LIST.Senior_3_4,
    Senior_3_5: VIDEO_LIST.Senior_3_5,
    Senior_3_6: VIDEO_LIST.Senior_3_6
  },
  LEG_ALIGNMENT: {
    BOW_LEG: {
      title: '브릿지 운동 / 교각 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Leg+Alignment+(Bow+Leg).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Leg+Alignment+(Bow+Leg).jpg',
      playTime: 16,
      position: '누워서',
      exerciseEffect: '대둔근 강화를 통한 골반 관절 주변 안정화',
      muscle: '대둔근',
      caution: '허리가 과도하게 펴지지 않도록 주의합니다.|엉덩이를 필요 이상으로 높이 들지 않도록 주의합니다.',
      score: 'bad'
    },
    KNOCK_KNEE: {
      title: '고관절 모음근 조이기 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Leg+Alignment+(Knock+Knee).mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Leg+Alignment+(Knock+Knee).jpg',
      playTime: 27,
      position: '누워서',
      muscle: '장내전근|단내전근|대내전근',
      exceptionTarget: '신장된 고관절 모음근 강화운동을 통한 X다리 완화',
      caution: '목, 어깨 등에 과도한 긴장이 생기지 않도록 주의가 필요합니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Leg_Alignment_1_10,
    Pelvic_Tilt_2_4: VIDEO_LIST.Pelvic_Tilt_2_4,
    Pelvic_Shift_3_5: VIDEO_LIST.Pelvic_Shift_3_5,
    Knee_Flexion_4_6: VIDEO_LIST.Knee_Flexion_4_6,
    Pelvic_Shift_Leg_Alignment_4_8: VIDEO_LIST.Pelvic_Shift_Leg_Alignment_4_8,
    Pelvic_Shift_5_4: VIDEO_LIST.Pelvic_Shift_5_4,
    Leg_Alignment_5_12: VIDEO_LIST.Leg_Alignment_5_12,
    Senior_1_7: VIDEO_LIST.Senior_1_7,
    Senior_1_9: VIDEO_LIST.Senior_1_9,
    Senior_1_10: VIDEO_LIST.Senior_1_10,
    Senior_1_12: VIDEO_LIST.Senior_1_12,
    Senior_2_3: VIDEO_LIST.Senior_2_3,
    Senior_2_4: VIDEO_LIST.Senior_2_4,
    Senior_2_5: VIDEO_LIST.Senior_2_5,
    Senior_2_6: VIDEO_LIST.Senior_2_6,
    Senior_2_7: VIDEO_LIST.Senior_2_7,
    Senior_2_8: VIDEO_LIST.Senior_2_8,
    Senior_3_3: VIDEO_LIST.Senior_3_3,
    Senior_3_5: VIDEO_LIST.Senior_3_5
  },
  KNEE_FLEXION: {
    KNEE_FLEXION: {
      title: '(옆으로 누워) 앞쪽 허벅지 스트레칭',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/bodyAlignment/Knee+Flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/bodyAlignment/Knee+Flexion.jpg',
      playTime: 12,
      position: '앉아서',
      exerciseEffect: '대퇴사두근 스트레칭',
      muscle: '대퇴사두근',
      exceptionTarget: '스트레칭 시 날카로운 통증이 있는 경우|아래쪽 어깨에 통증이 있는 경우|다리를 뒤로 당길 시 허리의 통증으로 인해 동작이 제한되는 경우',
      caution: '스트레칭 시 무릎 주변에 통증이 있다면 즉시 중단해주세요.|스트레칭 시 골반이 뒤로 치우치지 않도록 주의합니다.|스트레칭 시 허리가 과도하게 젖혀지지 않도록 합니다.',
      score: 'bad'
    },
    A: VIDEO_LIST.Knee_Flexion_2_2,
    Pelvic_Shift_3_5: VIDEO_LIST.Pelvic_Shift_3_5,
    Knee_Flexion_4_6: VIDEO_LIST.Knee_Flexion_4_6,
    Senior_1_12: VIDEO_LIST.Senior_1_12,
    Senior_2_2: VIDEO_LIST.Senior_2_2,
    Senior_2_7: VIDEO_LIST.Senior_2_7,
    Senior_3_3: VIDEO_LIST.Senior_3_3,
    Senior_3_4: VIDEO_LIST.Senior_3_4,
    Senior_3_5: VIDEO_LIST.Senior_3_5
  },
  /**
   * ROM { [type]: [testName] }
   */
  NECK: {
    NeckLateralFlexion: {
      title: '목 가쪽 굽힘 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Neck+lateral+flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Neck+lateral+flexion.jpg',
      playTime: 18,
      position: '앉아서',
      exerciseEffect: '목 가쪽 굽힘 가동범위 회복',
      exceptionTarget: '목 디스크가 심한 경우|목 관절의 불안정성이 있는 경우',
      caution: '무리한 운동은 목 관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요.',
      score: 'bad'
    },
    NeckFlexion: {
      title: '목 굽힘 운동/고개 끄덕이기',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Neck+flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Neck+flexion.jpg',
      playTime: 18,
      position: '앉아서',
      exerciseEffect: '목 굽힘 가동범위 회복',
      exceptionTarget: '목 디스크가 심한 경우|목 관절의 불안정성이 있는 경우',
      caution: '무리한 운동은 목 관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요.',
      score: 'bad'
    },
    NeckExtension: {
      title: '목 폄 운동/하늘보기 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Neck+extension.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Neck+extension.jpg',
      thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/body+Alignment/Neck+extension/img_thumbnail_alignment_neckextension.png',
      playTime: 17,
      position: '앉아서',
      exerciseEffect: '목 폄 가동범위 회복',
      exceptionTarget: '목 관절의 불안정성이 있는 경우',
      caution: '무리한 운동은 목 관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요.',
      score: 'bad'
    }
  },
  SHOULDER: {
    ShoulderAbduction: {
      title: '어깨 벌림 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Shoulder+abduction.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Shoulder+abduction.jpg',
      playTime: 18,
      position: '누워서',
      exerciseEffect: '어깨 벌림 가동범위 회복',
      exceptionTarget: '어깨 관절 불안정성이 있는 경우|어깨 통증이 있는 경우',
      caution: '무리한 운동은 어깨관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요',
      score: 'bad'
    },
    ShoulderInternalRotation: {
      title: '어깨 안쪽 돌림 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Shoulder+internal+rotation.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Shoulder+internal+rotation.jpg',
      playTime: 17,
      position: '누워서',
      exerciseEffect: '어깨 안쪽 돌림 가동범위 회복',
      exceptionTarget: '어깨 관절 불안정성이 있는 경우|어깨 통증이 있는 경우',
      caution: '무리한 운동은 어깨관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요',
      score: 'bad'
    },
    ShoulderExternalRotation: {
      title: '어깨 바깥 돌림 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Shoulder+external+rotation.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Shoulder+external+rotation.jpg',
      playTime: 16,
      position: '누워서',
      exerciseEffect: '어깨 바깥 돌림 가동범위 회복',
      exceptionTarget: '어깨 관절 불안정성이 있는 경우|어깨 통증이 있는 경우',
      caution: '무리한 운동은 어깨관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요',
      score: 'bad'
    },
    ShoulderFlexion: {
      title: '어깨 굽힘 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Shoulder+flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Shoulder+flexion.jpg',
      playTime: 18,
      position: '누워서',
      exerciseEffect: '어깨 굽힘 가동범위 회복',
      exceptionTarget: '어깨 관절 불안정성이 있는 경우|어깨 통증이 있는 경우',
      caution: '손 깍지 껴는 것이 어려운 경우에는 수건 등을 잡고 운동하세요.|무리한 운동은 어깨관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요',
      score: 'bad'
    },
    ShoulderExtension: {
      title: '어깨 신전 운동/등 뒤로 깍지 껴기',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Shoulder+extension.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Shoulder+extension.jpg',
      playTime: 16,
      position: '엎드려서',
      exerciseEffect: '어깨 신전 가동범위 회복',
      exceptionTarget: '어깨 관절 불안정성이 있는 경우|어깨 통증이 있는 경우|어깨 신전 가동범위 제한이 심한 경우',
      caution: '손 깍지 껴는 것이 어려운 경우에는 수건 등을 잡고 운동하세요.|무리한 운동은 어깨관절의 손상을 유발할 수 있으니 가동범위를 서서히 넓혀주세요.|운동할 때 팔꿈치가 구부러지지 않도록 주의해주세요.',
      score: 'bad'
    }
  },
  TRUNK: {
    TrunkLateralFlexion: {
      title: '몸통 가쪽 굽힘/허벅지 쓸기',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Trunk+lateral+flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Trunk+lateral+flexion.jpg',
      thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/body+Alignment/Trunk+lateral+flexion/img_thumbnail_alignment_trunklateralflexion.png',
      playTime: 17,
      position: '서서',
      exerciseEffect: '몸통 가쪽 굽힘 가동범위 회복',
      exceptionTarget: '체간의 불안정성으로 움직일 시 통증이 있는 경우|허리 수술로 인한 관절가동범위 제한 환자',
      caution: '옆으로 구부린 후 올라올 때 허리에 통증이 있다면 범위를 줄여서 시행해 주세요.|동작을 할 때 엉덩이가 바깥쪽으로 치우치지 않도록 주의하세요.',
      score: 'bad'
    },
    TrunkExtension: {
      title: '몸통 폄 운동/하늘 보기 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Trunk+extension.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Trunk+extension.jpg',
      thumbnailUrl2: 'https://d16h06llvpeyov.cloudfront.net/thumbnails/body+Alignment/Trunk+extension/img_thumbnail_alignment_trunkextension.png',
      playTime: 19,
      position: '서서',
      exerciseEffect: '몸통 폄 가동범위 회복',
      exceptionTarget: '체간의 불안정성으로 움직일 시 통증이 있는 경우|허리 수술로 인한 관절가동범위 제한 환자|허리 신전 시 경추의 통증이 있는 경우',
      caution: '동작을 할 때 통증이 있다면 운동을 중단해주세요.|과도한 허리 펴기는 뒤로 넘어질 위험이 있습니다.',
      score: 'bad'
    },
    TrunkFlexion: {
      title: '몸통 굽힘 운동/몸통 숙여 바닥 짚기',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Trunk+flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Trunk+flexion.jpg',
      playTime: 19,
      position: '서서',
      exerciseEffect: '몸통 굽힘 가동범위 회복',
      exceptionTarget: '체간의 불안정성으로 움직일 시 통증이 있는 경우|허리 수술로 인한 관절가동범위 제한 환자|허리 디스크 질환이 있는 경우',
      caution: '햄스트링 근육이 짧은 경우 관련 근육 스트레칭을 먼저 시행해 주세요.',
      score: 'bad'
    }
  },
  HIP: {
    HipFlexion: {
      title: '엉덩관절 굽힘 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Hip+flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Hip+flexion.jpg',
      playTime: 15,
      position: '누워서',
      exerciseEffect: '엉덩관절 굽힘 관절가동범위 증진',
      exceptionTarget: '엉덩관절에 관절염이 심한경우|날카로운 신경통이 있는 경우|엉덩관절에 구조적으로 문제가 있는 경우|엉덩관절 불안정성이 있는 경우',
      caution: '통증이 나타나기 전 범위에서 서서히 범위를 늘려갑니다.|무릎이 안쪽이나 바깥쪽으로 벗어나지 않도록 주의합니다.|반대쪽 다리가 바닥에서 들리지 않도록 주의합니다.|무리한 운동은 엉덩관절에 손상을 유발할 수 있으니, 가동범위를 점진적으로 넓혀주세요.',
      score: 'bad'
    },
    HipExtension: {
      title: '엉덩 관절 폄 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Hip+extension.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Hip+extension.jpg',
      playTime: 16,
      position: '런지',
      exerciseEffect: '엉덩관절 폄 관절가동범위 증진',
      exceptionTarget: '엉덩관절에 관절염이 심한경우|날카로운 신경통이 있는 경우|엉덩관절에 구조적으로 문제가 있는 경우|엉덩관절 불안정성이 있는 경우',
      caution: '통증이 나타나기 전 범위에서 서서히 범위를 늘려갑니다.|앞쪽에 있는 발 뒤꿈치가 바닥에서 떨어지지 않도록 주의합니다.|운동을 할 때 골반이 틀어지지 않고 정면을 유지할 수 있도록 합니다.|무리한 운동은 엉덩관절에 손상을 유발할 수 있으니, 가동범위를 점진적으로 넓혀주세요.',
      score: 'bad'
    }
  },
  KNEE: {
    KneeFlexion: {
      title: '무릎 굽힘 운동',
      playUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/uploads/ROM/Knee+flexion.mp4',
      thumbnailUrl: 'https://t-win-mediaconvert.s3.ap-northeast-2.amazonaws.com/thumbnails/ROM/Knee+flexion.jpg',
      playTime: 18,
      position: '누워서',
      exerciseEffect: '무릎관절 굽힘 가동범위 증진',
      exceptionTarget: '무릎 관절염이 심한 경우|날카로운 신경통이 있는 경우|무릎관절에 구조적으로 문제가 있는 경우|무릎관절에 불안정성이 있는 경우',
      caution: '통증이 나타나기 전 범위에서 서서히 범위를 늘려갑니다.|무릎이 안쪽이나 바깥쪽으로 벗어나지 않도록 주의합니다.|반대쪽 다리가 바닥에서 들리지 않도록 주의합니다.|무리한 운동은 무릎관절에 손상을 유발할 수 있으니, 가동범위를 점진적으로 넓혀주세요.',
      score: 'bad'
    }
  },
  /**
   * physicalTest { [physicalTestInfo.type] }
   */
  LOWER_BODY_STR: {
    Leg_Alignment_1_10: VIDEO_LIST.Leg_Alignment_1_10,
    Pelvic_Shift_3_5: VIDEO_LIST.Pelvic_Shift_3_5,
    Knee_Flexion_4_6: VIDEO_LIST.Knee_Flexion_4_6,
    Pelvic_Shift_Leg_Alignment_4_8: VIDEO_LIST.Pelvic_Shift_Leg_Alignment_4_8,
    Pelvic_Shift_5_4: VIDEO_LIST.Pelvic_Shift_5_4,
    Leg_Alignment_5_12: VIDEO_LIST.Leg_Alignment_5_12,
    A: VIDEO_LIST.Leg_Alignment_5_12,
    Senior_1_9: VIDEO_LIST.Senior_1_9,
    Senior_2_6: VIDEO_LIST.Senior_2_6,
    Senior_2_5: VIDEO_LIST.Senior_2_5,
    Senior_2_7: VIDEO_LIST.Senior_2_7,
    Senior_2_8: VIDEO_LIST.Senior_2_8,
    Senior_3_3: VIDEO_LIST.Senior_3_3,
    Senior_3_5: VIDEO_LIST.Senior_3_5,
    Senior_3_6: VIDEO_LIST.Senior_3_6
  },
  UPPER_BODY_STR: {
    Shoulder_Tilt_Trunk_Shift_4_3: VIDEO_LIST.Shoulder_Tilt_Trunk_Shift_4_3,
    A: VIDEO_LIST.Shoulder_Tilt_Trunk_Shift_4_3,
    Senior_3_6: VIDEO_LIST.Senior_3_6,
    Senior_3_8: VIDEO_LIST.Senior_3_8
  },
  AEROBIC: {
    Knee_Flexion_4_6: VIDEO_LIST.Knee_Flexion_4_6,
    Leg_Alignment_5_12: VIDEO_LIST.Leg_Alignment_5_12,
    A: VIDEO_LIST.Knee_Flexion_4_6,
    Senior_2_3: VIDEO_LIST.Senior_2_3,
    Senior_2_8: VIDEO_LIST.Senior_2_8,
    Senior_2_9: VIDEO_LIST.Senior_2_9,
    Senior_3_8: VIDEO_LIST.Senior_3_8
  },
  LOWER_BODY_FLEX: {
    Knee_Flexion_2_2: VIDEO_LIST.Knee_Flexion_2_2,
    Pelvic_Tilt_2_4: VIDEO_LIST.Pelvic_Tilt_2_4,
    A: VIDEO_LIST.Knee_Flexion_2_2,
    Senior_1_12: VIDEO_LIST.Senior_1_12,
    Senior_2_2: VIDEO_LIST.Senior_2_2,
    Senior_2_4: VIDEO_LIST.Senior_2_4,
    Senior_2_5: VIDEO_LIST.Senior_2_5,
    Senior_3_4: VIDEO_LIST.Senior_3_4
  },
  STATIC_BALANCE: {
    Neck_Tilt_1_2: VIDEO_LIST.Neck_Tilt_1_2,
    Head_Shift_Shoulder_1_3: VIDEO_LIST.Head_Shift_Shoulder_1_3,
    Neck_Tilt_1_4: VIDEO_LIST.Neck_Tilt_1_4,
    Pelvic_Rotation_1_5: VIDEO_LIST.Pelvic_Rotation_1_5,
    Head_Shift_1_6: VIDEO_LIST.Head_Shift_1_6,
    Shoulder_Tilt_Trunk_Shift_4_3: VIDEO_LIST.Shoulder_Tilt_Trunk_Shift_4_3,
    Pelvic_Rotation_4_5: VIDEO_LIST.Pelvic_Rotation_4_5,
    Pelvic_Tilt_5_9: VIDEO_LIST.Pelvic_Tilt_5_9,
    A: VIDEO_LIST.Pelvic_Rotation_1_5,
    Senior_1_1: VIDEO_LIST.Senior_1_1,
    Senior_1_2: VIDEO_LIST.Senior_1_2,
    Senior_1_3: VIDEO_LIST.Senior_1_3,
    Senior_1_4: VIDEO_LIST.Senior_1_4,
    Senior_1_5: VIDEO_LIST.Senior_1_5,
    Senior_1_6: VIDEO_LIST.Senior_1_6,
    Senior_1_7: VIDEO_LIST.Senior_1_7,
    Senior_1_8: VIDEO_LIST.Senior_1_8,
    Senior_1_9: VIDEO_LIST.Senior_1_9,
    Senior_1_10: VIDEO_LIST.Senior_1_10,
    Senior_1_11: VIDEO_LIST.Senior_1_11,
    Senior_1_12: VIDEO_LIST.Senior_1_12,
    Senior_2_1: VIDEO_LIST.Senior_2_1,
    Senior_2_7: VIDEO_LIST.Senior_2_7,
    Senior_2_8: VIDEO_LIST.Senior_2_8,
    Senior_2_9: VIDEO_LIST.Senior_2_9,
    Senior_2_10: VIDEO_LIST.Senior_2_10,
    Senior_3_1: VIDEO_LIST.Senior_3_1,
    Senior_3_3: VIDEO_LIST.Senior_3_3,
    Senior_3_5: VIDEO_LIST.Senior_3_5,
    Senior_3_7: VIDEO_LIST.Senior_3_7,
    Senior_3_9: VIDEO_LIST.Senior_3_9
  },
  UP_AND_GO_TEST: {
    Leg_Alignment_1_10: VIDEO_LIST.Leg_Alignment_1_10,
    Pelvic_Shift_3_5: VIDEO_LIST.Pelvic_Shift_3_5,
    Knee_Flexion_4_6: VIDEO_LIST.Knee_Flexion_4_6,
    Pelvic_Shift_Leg_Alignment_4_8: VIDEO_LIST.Pelvic_Shift_Leg_Alignment_4_8,
    Pelvic_Shift_5_4: VIDEO_LIST.Pelvic_Shift_5_4,
    Leg_Alignment_5_12: VIDEO_LIST.Leg_Alignment_5_12,
    A: VIDEO_LIST.Pelvic_Shift_Leg_Alignment_4_8,
    Senior_1_7: VIDEO_LIST.Senior_1_7,
    Senior_1_9: VIDEO_LIST.Senior_1_9,
    Senior_1_10: VIDEO_LIST.Senior_1_10,
    Senior_2_3: VIDEO_LIST.Senior_2_3,
    Senior_2_5: VIDEO_LIST.Senior_2_5,
    Senior_2_6: VIDEO_LIST.Senior_2_6,
    Senior_2_7: VIDEO_LIST.Senior_2_7,
    Senior_2_8: VIDEO_LIST.Senior_2_8,
    Senior_2_9: VIDEO_LIST.Senior_2_9,
    Senior_3_2: VIDEO_LIST.Senior_3_2,
    Senior_3_4: VIDEO_LIST.Senior_3_4,
    Senior_3_5: VIDEO_LIST.Senior_3_5,
    Senior_3_6: VIDEO_LIST.Senior_3_6,
    Senior_3_7: VIDEO_LIST.Senior_3_7
  },
};

export const makeContent = (subType, category, subCategory, data) => {
  const obj = JSON.parse(JSON.stringify(data));
  const metadata = (subCategory && METADATA_INFO[category][subCategory]) || METADATA_INFO[category][category];

  obj.contentId = `${subType}_${category}`;
  if (subCategory) obj.contentId += `_${subCategory}`;

  obj.title = metadata.title || (subCategory ? `${category}_${subCategory}` : category);
  obj.playUrl = metadata.playUrl;
  if (metadata.playUrlB) obj.playUrlB = metadata.playUrlB;
  obj.thumbnailUrl = metadata.thumbnailUrl;
  if (metadata.thumbnailUrl2) obj.thumbnailUrl2 = metadata.thumbnailUrl2;
  obj.playTime = typeof metadata.playTime === 'string' ? stringTimetoSec(metadata.playTime) : metadata.playTime;
  obj.subType = subType;

  // metadata
  obj.metadata.category = metadata.category || '스트레칭';
  if (metadata.equipment) obj.metadata.equipment = metadata.equipment;
  obj.metadata.bodyPart = metadata.bodyPart || '상체|하체|코어|어깨/팔|등|엉덩이';
  obj.metadata.ageGroup = metadata.ageGroup || '65-75';
  if (metadata.position) obj.metadata.position = metadata.position;
  if (metadata.exerciseEffect) obj.metadata.exerciseEffect = metadata.exerciseEffect;
  if (metadata.exceptionTarget) obj.metadata.exceptionTarget = metadata.exceptionTarget;
  if (metadata.caution) obj.metadata.caution = metadata.caution;
  obj.level = metadata.level || '초급';
  if (metadata.score) obj.score = metadata.score;

  return obj;
}

export const makeContentAll = (subType, data) => {
  const obj = {};

  TYPES[subType].forEach(category => {
    const arr = [];

    Object.keys(METADATA_INFO[category]).forEach(subCategory => {
      const obj = makeContent(subType, category, category === subCategory ? null : subCategory, data);
      arr.push(obj);
    });

    obj[category] = arr;
  });

  return obj;
}