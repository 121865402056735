import { ADAPTER_ADDRESS, ADDRESS, DEFAULT_INTERVAL, convertFeaturesFromFloat } from "./dummyDefault";
/*
양발서기
sequence->value : Signature(1byte), Version(1byte), MessageId(1byte), NRP(2byte), payload(...)
payload: type(1byte), length(2byte), data
sequence->interval: option
*/
export default {
  "interval": DEFAULT_INTERVAL, //default interval
  "loop": false,
  "common": {
    "adapterAddress": ADAPTER_ADDRESS,
    "address": ADDRESS,
    "returnValue": true,
    "subscribed": false
  },
  "sequence": [
    //Report message
    {"value": [0xA5, 0, 0x0, 0, 0, 0x20, 2, 0, 0x02, 0x01]}, //not ready
    {"value": [0xA5, 0, 0x1, 0, 0, 0x20, 2, 0, 0x02, 0x02]}, //not ready
    {"value": [0xA5, 0, 0x2, 0, 0, 0x20, 2, 0, 0x02, 0x03]}, //not ready
    {"value": [0xA5, 0, 0x3, 0, 0, 0x20, 2, 0, 0x02, 0x04]}, //not ready
    {"value": [0xA5, 0, 0x4, 0, 0, 0x20, 2, 0, 0x02, 0x05]}, //not ready
    {"value": [0xA5, 0, 0x5, 0, 0, 0x20, 2, 0, 0x02, 0x06]}, //not ready
    {"value": [0xA5, 0, 0x6, 0, 0, 0x20, 2, 0, 0x02, 0x07]}, //not ready
    {"value": [0xA5, 0, 0x7, 0, 0, 0x20, 2, 0, 0x02, 0x08]}, //not ready
    {"value": [0xA5, 0, 0x8, 0, 0, 0x20, 2, 0, 0x02, 0x09]}, //not ready
    {"value": [0xA5, 0, 0x9, 0, 0, 0x20, 2, 0, 0x01, 0x00]}, //ready
    //(115,59), (194, 59), (110, 251), (196, 251)
    {"value": [0xA5, 0, 0x10, 0, 0, 0x23, 32, 0, ...convertFeaturesFromFloat(2, [153, 34, 170, 272, 119, 102, 187, 34, 476, 34, 510, 272, 476, 34, 544, 51], 1)], "interval": 1000}, //left, right position
    //(445,59), (524, 59), (440, 251), (526, 251)
    {"value": [0xA5, 0, 0x12, 0, 0, 0x20, 2, 0, 0x03, 0x00], "interval": 1000}, //check start
    {"value": [0xA5, 0, 0x13, 0, 0, 0x20, 2, 0, 0x04, 0x00], "interval": 5000}, //check end
    //raw data 800byte
    {"value": [0xA5, 0, 0x14, 1, 0, 0x21, 0x90, 0x01,
      ...convertFeaturesFromFloat(1, [0,8,11,0,1,1,2,7,0,14,7,20,26,0,1,13,4,0,6,8,10,0,5,9,20,40,32,29,6,0,7,0,1,0,0,3,4,0,0,0,
        0,3,1,7,1,0,1,10,0,6,77,1126,59,14,19,0,10,0,0,0,12,0,1,5,29,32,14,33,2,8,0,35,0,3,0,0,6,0,0,12,
        4,7,3,0,10,2,0,4,5,38,1176,248,755,1156,9,12,2,2,1,8,4,8,15,3,277,405,1027,24,16,8,13,0,0,0,7,0,0,1,1,5,
        0,4,2,3,3,4,4,6,2,429,140,143,214,507,14,9,1,0,0,0,13,27,24,35,1541,600,75,325,20,6,8,2,0,6,5,0,0,0,1,0,
        0,0,0,0,0,1,3,7,6,52,1147,1789,1444,1033,34,5,19,14,7,8,19,20,20,42,748,339,215,649,113,1,4,0,0,0,0,0,0,0,0,0,
        4,2,5,1,1,5,2,5,24,1008,1601,1971,1733,1335,57,27,24,6,16,37,17,23,35,319,1400,1605,1596,411,88,10,0,2,1,2,0,0,1,2,0,1,
        0,3,12,7,0,2,7,2,115,1298,1464,1575,1601,558,35,5,12,12,21,15,29,29,36,374,1480,1591,1677,1402,64,31,0,0,0,0,0,0,0,1,4,24,
        0,1,5,5,0,1,11,20,52,1199,1291,1260,952,73,27,14,7,13,13,15,29,33,25,68,1182,1365,1524,1442,178,19,6,0,0,0,0,0,0,1,10,0,
        2,0,6,0,0,2,3,15,18,1026,1147,787,173,37,2,12,10,0,2,7,20,11,26,45,767,1149,1385,1307,80,22,0,0,9,2,0,0,16,0,0,0,
        0,0,8,8,0,2,1,9,33,671,1094,597,64,49,7,7,0,1,17,4,8,12,26,45,550,1189,1280,628,23,10,0,0,0,0,0,0,0,0,0,0], 0.1)
     ], "interval": 100},
     {"value": [0xA5, 0, 0x14, 0, 0, /* no type and len */
     ...convertFeaturesFromFloat(1, [0, 6, 0, 0, 4, 6, 9, 10, 18, 249, 1094, 624, 60, 21, 19, 0, 0,
      36, 14, 0, 10, 20, 24, 86, 512, 1248, 1351, 78, 18, 3, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 4, 0, 4, 0, 0, 6, 4, 9, 16, 31, 1055, 886, 110, 20, 18, 0, 6, 0, 4, 11,
      8, 7, 21, 90, 756, 1216, 879, 21, 7, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4,
      0, 0, 2, 8, 15, 15, 18, 1172, 1455, 648, 44, 23, 8, 5, 3, 10, 7, 9, 8, 28,
      486, 1239, 1258, 63, 10, 8, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 7,
      0, 3, 2, 8, 23, 1310, 1840, 1258, 31, 20, 11, 9, 10, 9, 7, 16, 30, 79, 1679,
      1597, 628, 28, 13, 38, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 9, 8, 0, 0, 1,
      5, 13, 17, 1548, 1927, 1465, 38, 26, 9, 14, 9, 14, 5, 31, 0, 912, 2019, 1780,
      248, 1, 3, 23, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 6, 0, 5, 4, 2, 7, 11,
      19, 1110, 1899, 1190, 29, 21, 19, 5, 8, 18, 6, 17, 45, 1484, 2041, 1762, 83,
      21, 27, 8, 14, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 3, 4, 5, 1, 0, 8, 2, 6, 14,
      43, 444, 60, 14, 10, 11, 0, 0, 13, 3, 11, 13, 265, 1349, 298, 25, 4, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 5, 10, 5, 10, 11, 19, 28, 16, 14,
      2, 3, 0, 0, 12, 0, 0, 7, 8, 36, 28, 22, 3, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 8, 0, 5, 4, 7, 10, 10, 21, 21, 13, 1, 4, 0, 10, 10, 0, 3, 0, 11,
      0, 25, 16, 7, 0, 16, 8, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 8,
      1, 9, 7, 11, 39, 6, 10, 0, 0, 1, 0, 0, 7, 15, 0, 4, 18, 4, 7, 10, 4, 6, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0], 0.1)
     ], "interval": 100},
    //feature
    {"value": [0xA5, 0, 0x15, 0, 0, 0x22, 38, 0, ...convertFeaturesFromFloat([2,2,2,2,2,2,2,2,2,2,2,2],
        [0.513, 0.566, 24.198, 30.726, 22.95, 22.126, 54.924, 45.076, 47.147, 52.853, 5.92, 2.277], 1000)], "interval": 5000},

  ]
}