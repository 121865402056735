import css from './MeasurementDate.module.less';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addPanels } from "../../../features/panels/panelsSlice";
import { changeThemeModeStatus } from "../../../features/common/commonSlice";

import { $L } from '../../../utils/helperMethods';
import * as HelperMethods from '../../../utils/helperMethods';
import * as Config from "../../../utils/Config";

import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import TButton, { SIZES } from '../../../components/TButton/TButton';
import TCalendar from '../../../components/TCalendar/TCalendar';
import TInput from '../../../components/TInput/TInput';

const NOW = new Date();

const MeasurementDate = ({...rest}) => {
  const dispatch = useDispatch();
  const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");
  const [valid, setValid] = useState(false);
  const [selectedDateStr, setSelectedDateStr] = useState(HelperMethods.convertDateToString2(NOW));

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  useEffect(()=>{
		if(password1 && password2 && password1 === password2){
			setValid(true);
		}else{
			setValid(false);
		}
	},[password1, password2]);


  const onPassword1Change = useCallback((e) => {
		setPassword1(e.value);
	}, []);

	const onPassword2Change = useCallback((e) => {
		setPassword2(e.value);
	}, []);

  const onChangeDate = useCallback(({selectedDate}) => {
    setSelectedDateStr(selectedDate);
  }, []);

  const onClickPreview = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.PREVIEW_PANEL }));
  }, [dispatch]);

  const onClickNextBtn = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.SHARE_METHOD }));
  },[dispatch]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Measured date")} />
      <TBody className={css.body}>
        <TCalendar className={css.calendar}  onSelectedDate={onChangeDate}/>
        <div className={css.inputSection}>
          <div className={css.title}>
            {$L("Select the shared measurement date.")}
          </div>
          <div className={css.date}>{$L(`${selectedDateStr}`)}</div>
          <div className={css.hr}/>
          <div className={css.inputContainer}>
            <div className={css.inputTitle}>
              {$L("Insert the PIN to check the shared report.")}
            </div>
            <TInput
              className={css.input}
              autoFocus
              type={"text"}
              dismissOnEnter
              value={password1}
              onChange={onPassword1Change}
              placeholder={$L('Enter PIN number')}
            />
            <TInput
              className={css.input}
              autoFocus
              type={"text"}
              dismissOnEnter
              value={password2}
              onChange={onPassword2Change}
              placeholder={$L('Confirm PIN number')}
            />
          </div>
        </div>
        <div className={css.buttonArea}>
          <TButton size={SIZES.xLarge} onClick={onClickPreview}>{$L("Preview")}</TButton>
          <TButton size={SIZES.xLarge} disabled={!valid} onClick={onClickNextBtn}>{$L("Next")}</TButton>
        </div>
      </TBody>

    </TPanel>
  );
};

export default MeasurementDate;