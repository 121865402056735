import { $L } from "../../../../utils/helperMethods";
import idealHeadShift from "../../../../../assets/bodyScan/idealimage/idealHeadShift.png";
import idealLegAlignment from "../../../../../assets/bodyScan/idealimage/idealLegAlignment.png";
import idealPelvicTilt from "../../../../../assets/bodyScan/idealimage/idealPelvicTilt.png";
import idealShoulderTilt from "../../../../../assets/bodyScan/idealimage/idealShoulderTilt.png";
import idealPelvicRotation from "../../../../../assets/bodyScan/idealimage/idealPelvicRotation.png";
import idealNeckTilt from "../../../../../assets/bodyScan/idealimage/idealNeckTilt.png";
import idealTrunkShift from "../../../../../assets/bodyScan/idealimage/idealTrunkShift.png";
import idealPelvicShift from "../../../../../assets/bodyScan/idealimage/idealPelvicShift.png";
import idealKneeFlexion from "../../../../../assets/bodyScan/idealimage/idealKneeFlexion.png";
import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
} from "../../../../utils/Constants";

export const MENUTYPE = {
  OVERALL: "OVERALL",
  HEAD_SHIFT: "HEAD_SHIFT",
  NECK_TILT: "NECK_TILT",
  SHOULDER_TILT: "SHOULDER_TILT",
  TRUNK_SHIFT: "TRUNK_SHIFT",
  PELVIC_TILT: "PELVIC_TILT",
  PELVIC_SHIFT: "PELVIC_SHIFT",
  PELVIC_ROTATION: "PELVIC_ROTATION",
  LEG_ALIGNMENT: "LEG_ALIGNMENT",
  KNEE_FLEXION: "KNEE_FLEXION",
};
export const MENUTYPE_TEST = {
  BOW_LEG: "BOW_LEG",
  KNOCK_KNEE: "KNOCK_KNEE",
  SHOULDER_SHIFT: "SHOULDER_SHIFT",
  ANTERIOR: "ANTERIOR",
  POSTERIOR: "POSTERIOR",
};
export const getTabMenuTitle = () => ({
  [MENUTYPE.OVERALL]: [$L("Overall"), ""], //Overall
  [MENUTYPE.HEAD_SHIFT]: [
    $L("Head{br}Shift"),
    $L("Head Shift") + " " + $L("View Details"),
  ],
  [MENUTYPE.LEG_ALIGNMENT]: [
    $L("Leg{br}Alignment"),
    $L("Leg Alignment") + " " + $L("View Details"),
  ],
  [MENUTYPE.PELVIC_TILT]: [
    $L("Pelvic{br}Tilt"),
    $L("Pelvic Tilt") + " " + $L("View Details"),
  ],
  [MENUTYPE.SHOULDER_TILT]: [
    $L("Shoulder{br}Tilt"),
    $L("Shoulder Tilt") + " " + $L("View Details"),
  ],
  [MENUTYPE.PELVIC_ROTATION]: [
    $L("Pelvic{br}Rotation"),
    $L("Pelvic Rotation") + " " + $L("View Details"),
  ],
  [MENUTYPE.NECK_TILT]: [
    $L("Neck{br}Tilt"),
    $L("Neck Tilt") + " " + $L("View Details"),
  ],
  [MENUTYPE.TRUNK_SHIFT]: [
    $L("Shoulder{br}Shift"),
    $L("Shoulder Shift") + " " + $L("View Details"),
  ],
  [MENUTYPE.PELVIC_SHIFT]: [
    $L("Pelvic{br}Shift"),
    $L("Pelvic Shift") + " " + $L("View Details"),
  ],
  [MENUTYPE.KNEE_FLEXION]: [
    $L("Knee{br}Flex/Ext"),
    $L("Knee Flexion/Extension") + " " + $L("View Details"),
  ],
});

export const TAB_DETAIL = {
  [MENUTYPE.HEAD_SHIFT]: {
    mainkey: "side",
    testkey: "head_shift",
    type: "upperbody",
    bodyPart: "head",
  },
  [MENUTYPE.LEG_ALIGNMENT]: {
    mainkey: "front",
    testkey: "leg_alignment_left",
    testkey2: "leg_alignment_right",
    type: "lowerbody",
    bodyPart: "leg",
  },
  [MENUTYPE.PELVIC_TILT]: {
    mainkey: "front",
    testkey: "pelvic_tilt",
    type: "body",
    bodyPart: "pelvic",
  },
  [MENUTYPE.SHOULDER_TILT]: {
    mainkey: "front",
    testkey: "shoulder_tilt",
    type: "upperbody",
    bodyPart: "shoulder",
  },
  [MENUTYPE.PELVIC_ROTATION]: {
    mainkey: "front",
    testkey: "pelvic_rotation",
    type: "body",
    bodyPart: "pelvic",
  },
  [MENUTYPE.NECK_TILT]: {
    mainkey: "front",
    testkey: "neck_tilt",
    type: "upperbody",
    bodyPart: "neck",
  },
  [MENUTYPE.TRUNK_SHIFT]: {
    mainkey: "side",
    testkey: "trunk_shift",
    type: "trunkbody",
    bodyPart: "shoulder",
  },
  [MENUTYPE.PELVIC_SHIFT]: {
    mainkey: "side",
    testkey: "pelvic_shift",
    type: "body",
    bodyPart: "pelvic",
  },
  [MENUTYPE.KNEE_FLEXION]: {
    mainkey: "side",
    testkey: "knee_flexion",
    type: "lowerbody",
    bodyPart: "leg",
  },
};

export const IDEAL_IMAGES = {
  [MENUTYPE.HEAD_SHIFT]: idealHeadShift,
  [MENUTYPE.LEG_ALIGNMENT]: idealLegAlignment,
  [MENUTYPE.PELVIC_TILT]: idealPelvicTilt,
  [MENUTYPE.SHOULDER_TILT]: idealShoulderTilt,
  [MENUTYPE.PELVIC_ROTATION]: idealPelvicRotation,
  [MENUTYPE.NECK_TILT]: idealNeckTilt,
  [MENUTYPE.TRUNK_SHIFT]: idealTrunkShift,
  [MENUTYPE.PELVIC_SHIFT]: idealPelvicShift,
  [MENUTYPE.KNEE_FLEXION]: idealKneeFlexion,
};

export const getEmptyResultsReportData = (menuType) => {
  const data = {
    [MENUTYPE.OVERALL]: {
      title: $L(
        "체형 진단은 당신의 체형을 더 젊게 하고 최적의 건강 상태로 유지할 수 있도록 하여 몸과 마음이 활기찬 노후생활을 보낼 수 있게 해요."
      ),
      detail: $L(
        "우리 몸은 특정 자세나 운동을 할 때 올바른 자세와 체형을 유지하는 것이 중요해요. 이는 부상 예방과 운동 효과를 극대화하기 위해 필수적이에요. {br}올바른 자세와 체형을 유지하는 것은 운동을 효과적으로 수행하고 부상을 예방하는 데 도움이 되요. 이는 운동을 하는 것뿐만 아니라 일상적인 활동에서도 중요해요."
      ),
    },
  };
  return data[menuType] ? data[menuType] : {};
};

export const getOverallFeedback = () => {
  return {
    [MENUTYPE.NECK_TILT]: {
      [SCORE_MINT_KEY]: $L("Neck tilt is good."),
      [SCORE_YELLOW_KEY]: $L(
        "Due to <b>difference in the neck tilt, tension headache and migraine</b> can occur."
      ),
      [SCORE_RED_KEY]: $L(
        "<b>Herniated cervical disc</b> can be caused when the <b>difference in the neck tilt</b> is continued."
      ),
    },
    [MENUTYPE.SHOULDER_TILT]: {
      [SCORE_MINT_KEY]: $L("Shoulder tilt is good."),
      [SCORE_YELLOW_KEY]: $L(
        "There can be <b>imbalance of muscles</b> around the shoulder due to the <b>shoulder tilt</b>."
      ),
      [SCORE_RED_KEY]: $L(
        "<b>Scoliosis</b> can be caused when the <b>difference in the shoulder tilt</b> is continued."
      ),
    },
    [MENUTYPE.PELVIC_TILT]: {
      [SCORE_MINT_KEY]: $L("Pelvic tilt is good."),
      [SCORE_YELLOW_KEY]: $L(
        "There can be <b>imbalance of muscles</b> around the pelvic due to the <b>pelvic tilt</b>."
      ),
      [SCORE_RED_KEY]: $L(
        "<b>Herniated lumbar disc</b> can be caused when the <b>difference in the pelvic tilt</b> is continued."
      ),
    },
    // NOT USED
    // [MENUTYPE.PELVIC_ROTATION]: {
    //   [SCORE_MINT_KEY]: $L(""),
    //   [SCORE_YELLOW_KEY]: $L(""),
    //   [SCORE_RED_KEY]: $L(""),
    // },
    [MENUTYPE.LEG_ALIGNMENT]: {
      [MENUTYPE_TEST.BOW_LEG]: {
        [SCORE_MINT_KEY]: $L("Leg alignment is good."),
        [SCORE_YELLOW_KEY]: $L(
          "When the <b>leg deformation</b> is continued for long period, <b>arthritis</b> can occur."
        ),
        [SCORE_RED_KEY]: $L(
          "When the <b>leg deformation</b> is continued for long period, <b>arthritis</b> can occur."
        ),
      },
      [MENUTYPE_TEST.KNOCK_KNEE]: {
        [SCORE_MINT_KEY]: $L("Leg alignment is good."),
        [SCORE_YELLOW_KEY]: $L(
          "When the <b>leg deformation</b> is continued for long period, <b>arthritis</b> can occur."
        ),
        [SCORE_RED_KEY]: $L(
          "When the <b>leg deformation</b> is continued for long period, <b>arthritis</b> can occur."
        ),
      },
    },
    [MENUTYPE.HEAD_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [SCORE_MINT_KEY]: $L("Anterior and posterior neck alignment is good."),
        [SCORE_YELLOW_KEY]: $L(
          "When <b>head anterior shift</b> is continued for long period, <b>turtle neck syndrome</b> can be caused."
        ),
        [SCORE_RED_KEY]: $L(
          "When <b>head anterior shift</b> is continued for long period, <b>turtle neck syndrome</b> can be caused."
        ),
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [SCORE_MINT_KEY]: $L("Anterior and posterior neck alignment is good."),
        [SCORE_YELLOW_KEY]: $L(
          "When <b>head posterior shift</b> is continued for long period, <b>straight neck syndrome</b> can be caused."
        ),
        [SCORE_RED_KEY]: $L(
          "When <b>head posterior shift</b> is continued for long period, <b>straight neck syndrome</b> can be caused."
        ),
      },
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [SCORE_MINT_KEY]: $L(
          "Anterior and posterior shoulder alignment is good."
        ),
        [SCORE_YELLOW_KEY]: $L(
          "There is possibility of being <b>round shoulder</b> due to the <b>shoulder anterior shift</b>."
        ),
        [SCORE_RED_KEY]: $L(
          "There is possibility of being <b>round shoulder</b> due to the <b>shoulder anterior shift</b>."
        ),
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [SCORE_MINT_KEY]: $L(
          "Anterior and posterior shoulder alignment is good."
        ),
        [SCORE_YELLOW_KEY]: $L(
          "There is possibility of being <b>flat back</b> due to the <b>shoulder posterior shift</b>."
        ),
        [SCORE_RED_KEY]: $L(
          "There is possibility of being <b>flat back</b> due to the <b>shoulder posterior shift</b>."
        ),
      },
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [SCORE_MINT_KEY]: $L("Anterior and posterior pelvic tilt is good."),
        [SCORE_YELLOW_KEY]: $L(
          "<b>Hollow-back</b> can be caused when the <b>anterior pelvic shift</b> condition is continued."
        ),
        [SCORE_RED_KEY]: $L(
          "<b>Hollow-back</b> can be caused when the <b>anterior pelvic shift</b> condition is continued."
        ),
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [SCORE_MINT_KEY]: $L("Anterior and posterior pelvic tilt is good."),
        [SCORE_YELLOW_KEY]: $L(
          "On prolonged <b>posterior pelvic tilt, retrospondylolisthesis</b> can be occurred."
        ),
        [SCORE_RED_KEY]: $L(
          "On prolonged <b>posterior pelvic tilt, retrospondylolisthesis</b> can be occurred."
        ),
      },
    },
    [MENUTYPE.KNEE_FLEXION]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        [SCORE_MINT_KEY]: $L("Lateral knee alignment is good."),
        [SCORE_YELLOW_KEY]: $L(
          "When excessive <b>knee Flexion</b> is continued, it can progress to <b>degenerative knee arthritis</b>."
        ),
        [SCORE_RED_KEY]: $L(
          "When excessive <b>knee Flexion</b> is continued, it can progress to <b>degenerative knee arthritis</b>."
        ),
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        [SCORE_MINT_KEY]: $L("Lateral knee alignment is good."),
        [SCORE_YELLOW_KEY]: $L(
          "When excessive <b>knee extension</b> is continued, it can progress to <b>degenerative knee arthritis</b>."
        ),
        [SCORE_RED_KEY]: $L(
          "When excessive <b>knee extension</b> is continued, it can progress to <b>degenerative knee arthritis</b>."
        ),
      },
    },
  };
};

export const getBarItem = () => {
  return {
    [MENUTYPE.NECK_TILT]: {
      partName: $L("Neck"),
      replacePartName: $L("Neck"),
      positionType: "LR",
      titleDisabled: true,
    },
    [MENUTYPE.HEAD_SHIFT]: {
      partName: $L("Neck"),
      replacePartName: $L("Neck"),
      positionType: "FB",
      titleDisabled: true,
    },
    [MENUTYPE.SHOULDER_TILT]: {
      partName: $L("Shoulder"),
      replacePartName: $L("Shoulders"),
      positionType: "LRH",
      titleDisabled: true,
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      partName: $L("Shoulder"),
      replacePartName: $L("Shoulders"),
      positionType: "FB",
      titleDisabled: true,
    },
    [MENUTYPE.PELVIC_TILT]: {
      partName: $L("Pelvis"),
      replacePartName: $L("Pelvis"),
      positionType: "LRH",
      titleDisabled: true,
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      partName: $L("Pelvis"),
      replacePartName: $L("Pelvis"),
      positionType: "FB",
      titleDisabled: true,
    },
    [MENUTYPE.LEG_ALIGNMENT]: {
      partName: $L("Leg"),
      replacePartName: $L("Leg"),
      positionType: "LEG",
      titleDisabled: true,
    },
    [MENUTYPE.KNEE_FLEXION]: {
      partName: $L("Knee"),
      replacePartName: $L("Knees"),
      positionType: "FB",
      titleDisabled: true,
    },
  };
};
