import React, { useMemo, useCallback, useState, useRef } from "react";
import classNames from "classnames";
import css from "./TopListItem.module.less";
import compose from 'ramda/src/compose';

import * as Config from "../../../../utils/Config";
import * as SoundEffect from "../../../../utils/SoundEffect";

import { Job } from "@enact/core/util";
import Spottable from '@enact/spotlight/Spottable';
import {Marquee, MarqueeController} from '@enact/sandstone/Marquee';


const SpottableComponent = Spottable("div");

const ItemBase = ({index, selected, width, title, onItemClick, onFocus, itemInfo, disabled, className, ...rest}) => {
  const [pressed, setPressed] = useState(false);
	const _onClick = useCallback((ev) => {
    if (disabled) {
      return;
    }
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressed(true);
    clearPressedJob.current.start(() => {
      if(onItemClick){
        onItemClick({index, itemInfo, ev});
      }
    });

	}, [index, itemInfo, onItemClick, disabled]);

  const _onFocus = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    if (onFocus) {
      onFocus({ev, index});
    }
  }, [index, onFocus])

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        css.tItem,
        className,
        selected && css.selected,
        disabled && css.disabled,
        pressed && css.pressed,

      )}
      style={{width: width+'px'}}
      onClick={_onClick}
      onFocus={_onFocus}
    >
      {/* <Marquee className={css.title} alignment={'center'} marqueeOn={"focus"}>{title}</Marquee> */}
      <div className={css.title}>{title}</div>
    </SpottableComponent>
  );
}

const ImageItemDecorator = compose(
	MarqueeController({marqueeOnFocus: true})
);
const TopListItem = ImageItemDecorator(ItemBase);
export default TopListItem;