import React, { useMemo, useCallback } from "react";
import classNames from "classnames";
import css from "./TMediaItem.module.less";
import compose from 'ramda/src/compose';
import Spottable from '@enact/spotlight/Spottable';
import {Marquee, MarqueeController} from '@enact/sandstone/Marquee';
import { useSelector, useDispatch } from "react-redux";
import dummyImage from "../../../assets/dummyImage.png";
import * as Utils from '../../utils/helperMethods';
import CustomImage from "../CustomImage";
import {LIST_TYPE} from "./TMediaList";

const SpottableComponent = Spottable("div");

const MediaItemBase = ({index, listType=LIST_TYPE.large, width, title, onItemClick, itemInfo, ...rest}) => {
  const dispatch = useDispatch();

  const getRef = useCallback((ref)=>{
    //adjust list height
    try{
      const height = ref.node.getBoundingClientRect().height;
      const listHeight = ref.node.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect()?.height;
      if(!listHeight && height){
        ref.node.parentNode.parentNode.parentNode.parentNode.style.height = height+'px';
      }
    }catch(e){
      console.log('MediaItemBase list adjusting failed');
    }
  },[]);
	const onClick = useCallback((ev) => {
		if(onItemClick){
			onItemClick({index, itemInfo, ev});
		}
		// ev.stopPropagation();
		// ev.preventDefault();
	}, [index, itemInfo, onItemClick]);


  const itemDescription = useMemo(() => {
		let res= {title: "", subTitle: "", thumbnailUrl: ""};
		if(itemInfo){
      res.title = Utils.convertUtf8ToUni(itemInfo.title);
      res.subTitle = Utils.convertUtf8ToUni(itemInfo.subTitle);
			res.thumbnailUrl = itemInfo.thumbnailUrl;
      //todo
			if(index >= 0 && res.thumbnailUrl === 'dummy'){
        res.thumbnailUrl = dummyImage;
			}
		}
		return res;
	}, [index, itemInfo]);

  return (
    <SpottableComponent
      {...rest}
      className={classNames(css.tMediaItem, css[listType])}
      style={{width: width+'px'}}
      onClick={onClick}
      ref={getRef}>
        <CustomImage src={itemDescription.thumbnailUrl} className={css.image} animationSpeed="normal" delay={0}/>
        <Marquee className={css.title} marqueeOn={"focus"}>{itemDescription.title}</Marquee>
        <Marquee className={classNames(css.title, css.subTitle)} marqueeOn={"focus"}>{itemDescription.subTitle}</Marquee>
        <div className={css.focusCover}></div>
    </SpottableComponent>
  );
}

const ImageItemDecorator = compose(
	MarqueeController({marqueeOnFocus: true})
);
const TMediaItem = ImageItemDecorator(MediaItemBase);
export default TMediaItem;