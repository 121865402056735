import { connect } from 'react-redux';
import { Spotlight } from '@enact/spotlight';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';
import classNames from 'classnames';
import Picker from '@enact/sandstone/Picker';
import RangePicker from '@enact/sandstone/RangePicker';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
// import { isRtlLocale } from '@enact/i18n/locale';
import css from './TAlarmPicker.module.less';
import * as ilibData from '../../utils/ilibData';
import { $L } from '../../utils/helperMethods';
const Container = SpotlightContainerDecorator({ enterTo: 'last-focused' }, 'div');

function TAlarmPicker({alarmTimerHour=0, alarmTimerMinute=0, onChange}) {
  const [state, setState] = useState({
    hours: 0,   // 12 am
    minutes: 0,
    meridiem: 0 // am
  });

  const clockPref = ilibData.getClockPref();
  const timeOrder = ilibData.getTimeOrder();
  // const isRtl = isRtlLocale();

  const updateStateFromProps = useCallback(({ alarmTimerHour, alarmTimerMinute }) => {
    setState((prevState) => ({
      ...prevState,
      hours: alarmTimerHour,
      meridiem: alarmTimerHour < 12 ? 0 : 1,
      minutes: alarmTimerMinute
    }));
  }, []);

  useEffect(() => {
    updateStateFromProps({
      alarmTimerHour,
      alarmTimerMinute
    });
  }, []);

  // useEffect(() => {
  //   Spotlight.focus('[data-component-id=onOffPicker]');
  // }, []);

  const onChangeHour = useCallback(({ value }) => {
    const nextState = {
      ...state,
      hours: value,
      meridiem: value < 12 ? 0 : 1,
    };
    setState(nextState);
    if (onChange) {
      onChange({ value: nextState });
    }
  }, [state, onChange]);

  const onChangeMinute = useCallback(({ value }) => {
    const nextState = {
      ...state,
      minutes: value
    };
    setState(nextState);
    if (onChange) {
      onChange({ value: nextState });
    }
    
  }, [state, onChange]);

  const onChangeMeridiem = useCallback(({ value }) => {
    const nextState = {
      ...state,
      hours: state.hours % 12 + (value === 1 ? 12 : 0),
      meridiem: value,
    };
    setState(nextState);
    if (onChange) {
      onChange({ value: nextState });
    }
  }, [state, onChange]);

  const { hours, minutes, meridiem } = state;

  let hoursString = [];
  if (clockPref === '12') {
    hoursString = [
      "12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11",
      "12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11",
    ];
  } else {
    hoursString = [
      "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", 
      "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"
    ];
  }

  // let order = [];
  // if (isRtl) {
  //   order = ['a', 'h', 'm'];
  // } else {
  //   order = timeOrder;
  // }

  return (
    <Container className={css.container}>
      {timeOrder.map((picker, index) => {
        switch (picker) {
          case "h":
            return (
              <div className={css.timePickerWrapper} key={index}>
                <Picker className={css.timePicker}
                  orientation={"vertical"} wrap joined noAnimation onChange={onChangeHour} value={hours}>
                  {hoursString}
                </Picker>
                <div className={css.text}>
                  {$L("시")}
                </div>
              </div>
            );
            case "m":
              return (
                <div className={css.timePickerWrapper} key={index}>
                  <RangePicker className={css.timePicker}
                    orientation={"vertical"} wrap joined noAnimation padded min={0} max={59} onChange={onChangeMinute} value={minutes}/>
                  <div className={css.text}>
                    {$L("분")}
                  </div>
                </div>
              );
            case "a":
              if (clockPref === '12') {
                return (
                  <div className={css.timePickerWrapper} key={index}>
                    <Picker data-component-id={'meridiemPicker'} className={css.timePicker}
                      orientation={"vertical"} wrap joined noAnimation onChange={onChangeMeridiem} value={meridiem}>
                      {[$L("오전"), $L("오후")]}
                    </Picker>
                    {/* <div className={css.text}>
                      {$L("Meridiem")}
                    </div> */}
                  </div>
                );
              } else {
                return null;
              }
            default:
              return null;
          }
        })}
      </Container>
    );
};



export default TAlarmPicker