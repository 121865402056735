import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { $L } from "../../../utils/helperMethods";
import css from "./FitnessRecord.module.less";
import classNames from "classnames";
import TScroller from "../../../components/TScroller/TScroller";
import Activity from "./Activity/Activity";
import Subscription from "./Subscription/Subscription";
import dummyLogo from "../../../../assets/FitnessManager/img_report_center_thumb.png";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import * as SoundEffect from "../../../utils/SoundEffect";
import * as Config from "../../../utils/Config";
import { Job } from "@enact/core/util";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);
const SpottableComponent = Spottable("div");

// dummy server data
const FITNESS_ACTIVITY = [
  {
    id: "date",
    data: 21,
    goal: 25,
  },

  {
    id: "time",
    data: 60000,
    goal: 72000,
  },

  {
    id: "calorie",
    data: 5765,
    goal: 6000,
  },
]
// dummy data
const getMockData = () => (
  [
    {
      title: $L("Subscribed programs"),
      subtitle: $L("Senior Health Promotion Vitality Project, Stretching for a Healthy Day!"),
      logo: dummyLogo,
      progress: { my: 28, progress: 68 },
    },
    {
      title: $L("Recommended Exercise"),
      subtitle: $L("Senior Health Promotion Vitality Project, Stretching for a Healthy Day!"),
      logo: dummyLogo,
      progress: { my: 50 },
    },
    {
      title: $L("Walking"),
      subtitle: $L("Jeju Olle Trail Course"),
      logo: dummyLogo,
      progress: { my: 50 },
    },
    {
      title: $L("Hiking"),
      subtitle: $L("Challenge to the Summit of Hallasan! A Journey Together Toward the High Peak"),
      logo: dummyLogo,
      progress: { my: 50 },
    },
  ]
);

const FitnessRecord = () => {
  const centerList = useSelector((state) => state.centerData.centerList);
  const [isPressed, setIsPressed] = useState(false);
  const scrollTo = useRef(null);
  const mockData = useMemo(() => {
    return getMockData();
  }, []);
  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  const clearPressedJob = useRef(
    new Job((func) => {
      setIsPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const onClick = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setIsPressed(true);
    clearPressedJob.current.start(() => {
      // todo: onClick event
    });
  }, []);

  return (
    <TScroller className={css.scroller} cbScrollTo={getScrollTo}>
      <>
      {/* activity */}
      <div className={css.activityCotainer}>
        <div className={css.title}>{$L("Recommended exercises in the past month")}</div>
        <div className={css.list}>
          {FITNESS_ACTIVITY.map(item => (
            <Activity key={item.id} itemInfo={item}/>
          ))}
        </div>
      </div>
      {/* subscription */}
      <div className={css.subscriptionCotainer}>
        <Container className={css.titleLayer}>
          <div className={css.title}>{$L("Progress of the exercise")}</div>
          <SpottableComponent
            className={classNames(css.totalRecordBtn, isPressed && css.pressed)}
            onClick={onClick}
          >
            {$L("View full history")}
          </SpottableComponent>
        </Container>
        <div className={css.list}>
          {mockData &&
            mockData.length > 0 &&
            mockData.map((item, index) => (
            <Subscription key={"subscription" + index} item={item}/>
          ))}
        </div>
      </div>
      </>
  </TScroller>
  );
};

export default FitnessRecord;
