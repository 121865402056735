import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { $L, convertNewlinesToBr } from '../../../utils/helperMethods';
import TPopUp from '../../../components/TPopUp/TPopUp';
import { ACTIVITY_SEQUENCE } from './Constants';
import css from './ActivityPopup.module.less';
import { postSetting } from '../../../features/fitService/fitServiceSlice';
import useGesture from '../../../hooks/useGesture';
import img_activation_movement from '../../../../assets/inHomeOutdoorActivity/Group 2085666986.svg';
import img_selection from '../../../../assets/inHomeOutdoorActivity/Group 2085666972.svg';
import img_screen_movement from '../../../../assets/inHomeOutdoorActivity/Group 2085666973.svg';

const GesturePopup = ({ onClick }) => {
  const { gestureInitialize, gestureRelease } = useGesture();
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const [gestureState, setGestureState] = useState(true);

  useEffect(() => {
    // dispatch(postSetting({ gestureYn: true }));
    gestureInitialize();
  }, []);

  const _onClick = useCallback((idx) => {
    if (!gestureState) {
      // dispatch(postSetting({ gestureYn: false }));
      gestureRelease();
    }

    if (onClick) {
      if (idx === 1 || cesShowMode) {
        onClick(ACTIVITY_SEQUENCE.SCENERY)();
      } else {
        onClick(ACTIVITY_SEQUENCE.ACTIVITY)();
      }
    }
  }, [onClick, gestureState, cesShowMode]);

  const onClickCheck = useCallback(() => {
    setGestureState(!gestureState);
  }, [gestureState]);

  const GUIDE_IMG = [
    { img: img_activation_movement, title: $L("Activation Movement"), desc: $L("Open Palm"), width: '91px' },
    { img: img_selection, title: $L("Selection"), desc: $L("2 Seconds Pointing"), width: '129px' },
    { img: img_screen_movement, title: $L("Screen Movement"), desc: $L("Index Finger Movement"), width: '145px' }
  ];

  const options = useMemo(() => {
    const opts = {};

    opts.button1text = cesShowMode ? $L("Enjoy") : $L("Next course")
    if (!cesShowMode) {
      opts.button2text = $L("Enjoy");
    }

    return opts;
  }, [cesShowMode]);

  return (
    <TPopUp
      kind="textPopUp"
      title={$L("You have arrived!")}
      text={convertNewlinesToBr($L("Take a moment to enjoy the stunning view and relax. (Support 360° view){br}Use gestures to get an immersive experience. {br}You will get [rewards] for completing all missions."))}
      onClick={_onClick}
      hasCheck={true}
      onCheck={gestureState}
      CheckTitle={$L("Using gestures")}
      onClickCheck={onClickCheck}
      {...options}
    >
      <div className={css.gestureContainer}>
        <div className={css.alert}><span/>{$L("Unfold your hand towards the camera and wait 1-2 seconds to use the gesture feature.")}</div>
        <div className={css.guideImgContainer}>
          {GUIDE_IMG.map((guide, idx) => <div key={`guideImg-${idx}`} className={css.guide}>
            <img src={guide.img} style={{ '--imgWidth': guide.width }} />
            <div>
              <div className={css.title}>{guide.title}</div>
              <div className={css.desc}>{guide.desc}</div>
            </div>
          </div>)}
        </div>
      </div>
    </TPopUp>
  );
};

export default GesturePopup;
