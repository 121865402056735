import React, {useState, useEffect, useMemo, useCallback} from 'react';

import SmoodShowingAnimation from "../../components/SmoodShowingAnimation";
import css from './SubBody.module.less';
import TChips from '../../components/TChips/TChips';

import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div" );

const SubBody = ({simpleButtonList=[], onTabChanged, selectedTab, title, itemRenderer, spotlightId, children,...rest}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onItemClick = useCallback(({index})=>{
    if(onTabChanged){
      onTabChanged({index});
    }
  },[onTabChanged]);

  useEffect(()=>{
    setSelectedIndex(selectedTab);
  },[selectedTab]);

  delete rest.selectedTab;
  return (
    <div className={css.body} {...rest}>
      <Container className={css.tabArea} spotlightId={spotlightId}>
        <div className={css.titleContainer}>
          {title && <div className={css.title}>{title}</div>}
          {title && itemRenderer && <div className={css.vr}/>}
          {itemRenderer && itemRenderer()}
        </div>
        {simpleButtonList && simpleButtonList.length > 0 &&
        <TChips
          className={css.tab}
          contents={simpleButtonList}
          selectedIndex={selectedIndex}
          onItemClick={onItemClick}
        />
        }
      </Container>
      {children}
    </div>
  );
};

export default SmoodShowingAnimation(SubBody);
