import React, { useEffect, useCallback } from "react";
import TMediaList, {LIST_TYPE} from "../../../../components/TMediaList";
import dummyVideoList from "../../../../../assets/mock/dummyVideoList.json";

const TMediaListLayout = () => {
    const onItemClick = useCallback(({index, itemInfo, ev})=>{
        console.log('TMediaListLayout onItemClick', index, itemInfo, ev);
    },[]);
    return (
        <>
        <TMediaList title={'MediaList Large'}
            listType={LIST_TYPE.large}
            contents={dummyVideoList.data}
            onItemClick={onItemClick}
        />
        <TMediaList title={'MediaList medium'}
            listType={LIST_TYPE.medium}
            contents={dummyVideoList.data}
            onItemClick={onItemClick}
        />
        <TMediaList title={'MediaList small'}
            listType={LIST_TYPE.small}
            contents={dummyVideoList.data}
            onItemClick={onItemClick}
        />
        </>
    );
};

export default TMediaListLayout;
