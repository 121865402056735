import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import css from "./HPTestNotStarted.module.less";
import { $L } from "../../../../utils/helperMethods";
import TScroller from '../../../../components/TScroller/TScroller';
import TDropdown from "../../../../components/TDropDown/TDropDown";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import useContent from "../../../../hooks/useContent";
import SpotlightIds from "../../../../utils/SpotlightIds";
import { getContent } from "../../../../features/fitService/fitServiceSlice";
import Spotlight from "@enact/spotlight";
import * as Config from "../../../../utils/Config";
import { addPanels, updatePanel } from "../../../../features/panels/panelsSlice";
import { getTabMenuTitle } from "../../Constants";
import TNudeTab from "../../../../components/TNudeTab/TNudeTab";
import TButton, { COLOR } from "../../../../components/TButton/TButton";
import useScrollTo from "../../../../hooks/useScrollTo";
import THorizontalScrollList, { ITEM_SIZE } from "../../../../components/THorizontalScrollList/THorizontalScrollList";
import SMediaItem from "../../../../components/SMediaList/SMediaItem";

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

let tabMenuTitle = getTabMenuTitle();
const HPTestNotStarted = ({ className, testType, workouts=[], selectedTabIndex, ...rest }) => {
  const dispatch = useDispatch();
  const { profileNick } = useSelector((state) => state.common.appStatus);
  const { getScrollTo, scrollTop } = useScrollTo();
  const [selectedId, setSelectedId] = useState(0);
  const [selectOderIndex, setSelectOderIndex] = useState(-1);
  const { contentList } = useContent({ type: 'recommend', subType: testType });
  const order = [$L("From the newest"), $L("In alphabetical order")];

  const onItemClick = useCallback(({ itemInfo }) => {
    const current = Spotlight.getCurrent();

    if (itemInfo) {
      dispatch(getContent({ contentId: itemInfo.contentId })); // focus? click?
    }
    dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { contentId: itemInfo?.contentId } }));
    dispatch(updatePanel({ name: Config.panel_names.HOMECARE_RECOMMEND_WORKOUT, panelInfo: { index: selectedTabIndex || 0, lastFocusedId: current.getAttribute('data-spotlight-id') || '' } }));
  }, [selectedTabIndex]);

  const onDropDownSelect = useCallback(({selected}) => {
    setSelectedId(selected);
  }, []);

  const onClickSimpleButton = useCallback(({ index }) => {
    // const data = [...centerList]; // 데이터 복사
    // if (index === 0) {
    //   setSelectOderIndex(0);
    //   setcenters(centerList); // todo server에서 최신순으로 내려주는지 확인
    // } else if (index === 1) {
    //   setSelectOderIndex(1);
    //   setcenters(data.sort((a, b) => a.title.localeCompare(b.title)))
    // } else {
    //   setSelectOderIndex(-1);
    //   setcenters(centerList);
    // }
  }, [selectOderIndex]);

  const CONTENT_CATEGORY_LIST_BASE = useMemo(() => {
    const arr = [{ title: "", dropDownMenu: $L("Overall"), category: "" }];

    Object.keys(contentList).forEach(key => {
      const menuTitle = (tabMenuTitle[testType] && tabMenuTitle[testType][key]?.split('{br}').join(' ')) || key;
      arr.push({ title: $L(menuTitle.toLowerCase().replace(/\b[a-z]/g, char => char.toUpperCase())) + $L('에 좋은 운동'), dropDownMenu: menuTitle, category: key });
    });

    return arr;
  }, [contentList]);

  const CONTENT_CATEGORY_LIST = useMemo(() => {
    return CONTENT_CATEGORY_LIST_BASE.slice(1).filter((category, idx) => {
      return [0, null, idx + 1].includes(selectedId);
    })
  }, [selectedId, contentList]);

  const gotoBodyCheckup = useCallback(() => {
    const itemConfig = {
      "bodyAlignment": { name: Config.panel_names.BODY_ALIGNMENT_REPORT },
      "ROM": { name: Config.panel_names.ROM_REPORT },
      "bodyBalance": { name: Config.panel_names.BODY_BALANCE_REPORT },
      "physicalTest": { name: Config.panel_names.PHYSICAL_TEST_GATE },
    }

    dispatch(addPanels(itemConfig?.[testType]));
  }, [testType, dispatch]);

  useEffect(() => {
    setSelectedId(0);
    scrollTop();
  }, [testType]);

	return (
    <TScroller className={css.scroller} cbScrollTo={getScrollTo}>
      <div className={css.content}>
        <div className={css.descLayer}>
          <div className={css.desc}>
            {
              $L("Get yourself measured and tested and the AI exercise manager will recommend a weekly exercise solution based on your data.").replace("{NICKNAME}", profileNick || "고객님")
            }
          </div>
          <TButton
            className={css.button}
            color={COLOR.black}
            onClick={gotoBodyCheckup}
            onFocus={scrollTop}
          >
            {$L("Get a Body Analysis now")}
          </TButton>
        </div>
        <Container className={css.topLayer}>
          <TDropdown
            placeholder={$L("Select a category")}
            width={464}
            direction="below"
            selected={selectedId}
            onSelect={onDropDownSelect}
          >
            {CONTENT_CATEGORY_LIST_BASE.map(category => category.dropDownMenu)}
          </TDropdown>
          <TNudeTab
            className={css.order}
            labelArr={order}
            selectedTabIndex={selectOderIndex}
            setSelectedTabIndex={setSelectOderIndex}
            onClick={onClickSimpleButton}
          />
        </Container>
        {CONTENT_CATEGORY_LIST.map((category, index) =>
          contentList[category.category] && contentList[category.category].length > 0 && (
            <React.Fragment key={"mediaList" + index}>
              {/* title 수정 */}
              <div className={css.title}>{category.title}</div>
              <div className={css.mediaList}>
                <THorizontalScrollList
                  itemSize={ITEM_SIZE.large}
                  startGap={75}
                >
                  {contentList[category.category].map((item, index) => (
                    <SMediaItem
                      key={`testNotStarted_${item.contentId}`}
                      itemSize={ITEM_SIZE.large}
                      itemInfo={item}
                      onClick={onItemClick}
                    />
                  ))}
                </THorizontalScrollList>
              </div>
            </React.Fragment>
          )
        )}
      </div>
    </TScroller>
	)
}

export default HPTestNotStarted