//Sequired domain
//todo
import { useSelector } from "react-redux";

export const AUTHORIZATION = {	headers: {"x-api-key": "dummy"}};
export const FIT_BASEURL_PRD = "https://dummy/";
export const FIT_BASEURL_QA = "https://qa.dummy/";
export const FIT_BASEURL_DEV = "https://dev-kic-fittv.lgeappstv.com/";
export const FIT_BASEURL_TWIN = "http://3.39.108.150:8788/";
export const THRESHOLD_AUTH_REFRESH = 0.2; // Percent

export const DEFAULT_SERVERTYPE = 'dev'; //prd, qa, dev, twin, local

export const URLS = {
    //body alignment
    BODY_ALIGNMENT_DATELIST: "v1/bodyCheckup/alignment/dateList",
    BODY_ALIGNMENT: "v1/bodyCheckup/alignment",

    //physicalTest
    PHYSICALTEST_DATELIST: "v1/bodyCheckup/sft/dateList",
    PHYSICALTEST: "v1/bodyCheckup/sft",

    //ROM
    ROM_DATELIST: "v1/bodyCheckup/rom/dateList",
    ROMTEST: "v1/bodyCheckup/rom",

    //BODY Balance
    BODY_BALANCE_DATELIST: "v1/bodyCheckup/balancing/dateList",
    BODY_BALANCE: "v1/bodyCheckup/balancing",


    SURVEY: "v1/user/survey",
    POLICY: "v1/user/policy",
    TOKEN_REFRESH: 'v22/tokenRefresh', //todo

    // activity
    ACTIVITY_MAIN: "v1/activity/main",
    ACTIVITY_THEME_LIST: "v1/activity/theme",
    ACTIVITY_CONTENT_LIST: "v1/activity/content/theme",
    ACTIVITY_CONTENT: "v1/activity/content",
    ACTIVITY_LIKE_LIST: "v1/activity/like",
    ACTIVITY_CONTINUE_LIST: "v1/activity/continue",
    ACTIVITY_RECOMMEND_LIST: "v1/activity/recommend",
    ACTIVITY_STROLL_TODAY: "v1/activity/strolljogging/today",
    ACTIVITY_STROLL_HISTORY_DATELIST: "v1/activity/strolljogging/history/dateList",
    ACTIVITY_STROLL_HISTORY_SUMMARY: "v1/activity/strolljogging/history/summary",
    ACTIVITY_STROLL_HISTORY_LIST: "v1/activity/strolljogging/history/list",
    ACTIVITY_STROLL_LIKE: "v1/activity/strollJogging/like", // post
    ACTIVITY_STROLL: "v1/activity/strollJogging", // post

    // IMAGE
    UPLOAD_IMAGE: "v1/s3/upload/image", //post
    DELETE_FILE: "v1/s3/delete/file",   //delete

    //SETTING
    SETTING: "v1/user/setting",
    SETTING_BGM_LIST: "v22/setting/bgm-list", //?sort=wdate, text, time
    SETTING_BGM: "v22/setting/bgm",
    SETTING_FOOTPRINT_LIST: "v22/setting/footprint-list",
    SETTING_FOOTPRINT: "v22/setting/footprint",
    SETTING_CENTER: "v22/setting/center",
    SETTING_GUIDE: "v22/setting/guide",
}
export const getUrl = (endStr, serverType, localURL) => {
    let base ="";
    if(serverType === 'prd'){
        base = FIT_BASEURL_PRD;
    }else if(serverType === 'qa'){
        base = FIT_BASEURL_QA;
    }else if (serverType === 'dev') {
        base = FIT_BASEURL_DEV;
    }else if (serverType === 'twin'){
        base = FIT_BASEURL_TWIN;
    } else if(serverType === 'local'){
      base = localURL;
    }
    return base+endStr;
}