import classNames from 'classnames';
import Spottable from '@enact/spotlight/Spottable';
import React from 'react';
import css from './TCheckbox.module.less';

/**
 * TCheckbox
 *
 * @module TCheckbox
 */

const SpottableComponent = Spottable('div');

const TCheckbox = ({className, children, selected, onToggle, ...rest}) => {

	return (
		<SpottableComponent
				{...rest}
				className={classNames(className, css.tcheckbox, selected && css.selected)}
				onClick={onToggle}
				data-webos-voice-intent={'Select'}
				data-webos-voice-label={children}
			>
		</SpottableComponent>
	);
};

export default TCheckbox;