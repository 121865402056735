import React, { useState, useCallback } from "react";
import TSimpleButtonTab, {LIST_TYPE} from "../../../../components/TSimpleButtonTab";
import dummyVideoList from "../../../../../assets/mock/dummyVideoList.json";
import css from './DetailLayout.module.less';
import { $L } from "../../../../utils/helperMethods";

const TSimpleButtonTabLayout = () => {
    const [selectedIndexSmall, setSelectedIndexSmall] = useState(0);
    const [selectedIndexMedium, setSelectedIndexMedium] = useState(0);
    const [selectedIndexLarge, setSelectedIndexLarge] = useState(0);
    const [selectedIndexHeader, setSelectedIndexHeader] = useState(0);

    const onItemClickSmall = useCallback(({index,...rest})=>{
        console.log('TSimpleButtonTabLayout onItemClickSmall', index, rest);
        setSelectedIndexSmall(index);
    },[]);
    const onItemClickMedium = useCallback(({index,...rest})=>{
        console.log('TSimpleButtonTabLayout onItemClickMedium', index, rest);
        setSelectedIndexMedium(index);
    },[]);
    const onItemClickLarge = useCallback(({index,...rest})=>{
        console.log('TSimpleButtonTabLayout onItemClickLarge', index, rest);
        setSelectedIndexLarge(index);
    },[]);
    const onItemClickHeader = useCallback(({index,...rest})=>{
        console.log('TSimpleButtonTabLayout onItemClickHeader', index, rest);
        setSelectedIndexHeader(index);
    },[]);
    return (
        <div className={css.alignVertical}>
            <TSimpleButtonTab
                listType={LIST_TYPE.small}
        contents={[$L("Overall"), $L("Quarterly"), $L("Monthly"), $L("Weekly")]}
                onItemClick={onItemClickSmall}
                selectedIndex={selectedIndexSmall}
            />
            <TSimpleButtonTab
                listType={LIST_TYPE.medium}
        contents={[$L("Overall"), $L("Quarterly"), $L("Monthly"), $L("Weekly")]}
                onItemClick={onItemClickMedium}
                selectedIndex={selectedIndexMedium}
            />
            <TSimpleButtonTab
                listType={LIST_TYPE.large}
        contents={[$L("Smart Mat"), $L("Camera")]}
                onItemClick={onItemClickLarge}
                selectedIndex={selectedIndexLarge}
            />
            <TSimpleButtonTab
                listType={LIST_TYPE.header}
                contents={["프로필", "바디 프로필 Long Name", "운동 아바타", "커머스"]}
                onItemClick={onItemClickHeader}
                selectedIndex={selectedIndexHeader}
                maxItemCount={4}
            />
            <TSimpleButtonTab
                listType={LIST_TYPE.header}
                contents={["프로필", "바디 프로필 Long Name", "운동 아바타", "커머스"]}
                onItemClick={onItemClickHeader}
                selectedIndex={selectedIndexHeader}
                maxItemCount={5}
            />
            <TSimpleButtonTab
                listType={LIST_TYPE.header}
                contents={["프로필", "바디 프로필 Long Name", "운동 아바타", "커머스"]}
                onItemClick={onItemClickHeader}
                selectedIndex={selectedIndexHeader}
                maxItemCount={6}
            />
        </div>
    );
};

export default TSimpleButtonTabLayout;
