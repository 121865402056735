/**
 * PIPCamera
 *
 * @module PIPCamera
 */
import classNames from "classnames";
import css from "./PIPCamera.module.less";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Config from "../../utils/Config";
import { updateCameraSize } from "../../features/camera/cameraSlice";
import * as helperMethods from "../../utils/helperMethods";
import { popPanel } from "../../features/panels/panelsSlice";
import { CAMERA_USING_PANELS } from "../../utils/Constants";
const constraints =
  typeof window === "object"
    ? window.constraints = {
        audio: false,
        video: {
          width: { min: 1024, ideal: 1280, max: 1920 },
          height: { min: 576, ideal: 720, max: 1080 }
          // width: { min: 1024, ideal: 1152, max: 1920 },
          // height: { min: 529, ideal: 720, max: 1080 },
        }
      }
    : {};

//recording static data
let savingPath = "";
let recordedBlobs = [];
const VIDEO_TIMESLICE = 10000;
// const VIDEO_BITRATE = 100000;
const VIDEO_BITRATE = 5000000; //5Mbps : youtube recommend 720p bitrate

//size big, medium, small, tiny(7:3)
//position lt, rt, lb, rb
const PIPCamera = ({size = "small", onCameraReady, webcamRef, transparency=false}) => {
  const cameraPermissionRequired = useSelector((state) => state.common.appStatus.cameraPermissionRequired);
  const cameraRecordingActivated = useSelector((state) => state.common.localSettings.cameraRecordingActivated);
  const {activatedCameraId} = useSelector(state => state.camera);
  const [hideVideo, setHideVideo] = useState(true);
  const [canvasSize, setCanvasSize] = useState({ width: "", height: "" });
  const recorder = useRef(null);
  const dispatch = useDispatch();

  const stopStreamedVideo = useCallback(() => {
    setHideVideo(true);
    if(recorder.current){
      recorder.current.stop();
    }
    if (typeof window === "object" && window.stream) {
      const tracks = window.stream.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });
      window.stream = null;

      const video = document.querySelector(`[data-spotlight-id="pipcamera"]`);
      if (video) {
        video.srcObject = null;
      }
    }
  }, []);

  const handleError = useCallback(
    (error) => {
      console.error("PIPCamera error", error);
      if (typeof window === "object" && !window.PalmSystem){
        setHideVideo(false);
        setCanvasSize({ width: 1920, height: 1080 });
        if(onCameraReady){
          setTimeout(onCameraReady, 0);
        }
        return;
      }
      stopStreamedVideo();
      CAMERA_USING_PANELS.map((panelName)=>{
        dispatch(popPanel(panelName));
      });
    },
    [stopStreamedVideo, onCameraReady]
  );

  const handleSuccess = useCallback(async (stream) => {
      const video = document.querySelector(`[data-spotlight-id="pipcamera"]`);
      console.log('PIPCamera stream is active=', stream )
      if (typeof window === "object") {
        window.stream = stream; // make variable available to browser console
      }
      if (video) {
        video.srcObject = stream;
        setHideVideo(false);
        setCanvasSize({ width: video.width, height: video.height });
        if(stream.active && onCameraReady){
          setTimeout(onCameraReady, 0);
        }
      } else {
        stopStreamedVideo();
      }
      //recording
      if(typeof window === "object" && cameraRecordingActivated){
        // const options = {
        //   audioBitsPerSecond: 0,
        //   videoBitsPerSecond: VIDEO_BITRATE
        // };
        savingPath = "";
        // recorder.current = new window.MediaRecorder(stream, options);
        recorder.current = new window.MediaRecorder(stream);
        const fileHandle = await window.showSaveFilePicker();
        const writableStream = await fileHandle.createWritable();
        recorder.current.ondataavailable = async (e) => {
          // const recordedBlob = new window.Blob([e.data], {type: 'video/webm' });
          // recordedBlobs.push(recordedBlob);
          await writableStream.write(e.data);

          if(recorder.current.state == 'inactive'){
            await writableStream.close();
            // const videotag = document.querySelector('video');
            // const li = document.createElement('li');
            // videotag.parentNode.parentNode.parentNode.appendChild(li);
            // for(let i=0; i<recordedBlobs.length; i++){
            //   let url = window.URL.createObjectURL(recordedBlobs[i]);
            //   const hf = document.createElement('a');
            //   hf.download = `aaa(${i}).mp4`;
            //   hf.href = url;
            //   hf.innerHTML = `donwload ${hf.download}`;
            //   li.appendChild(hf);
            // }
          }
        };
        setTimeout(() => {
          recordedBlobs=[];
          recorder.current.start(VIDEO_TIMESLICE); //timeslice :milliseconds
        }, 1000);
      }

    },[stopStreamedVideo, onCameraReady, cameraRecordingActivated]);


  useEffect(() => {
    stopStreamedVideo();

    const _constraints = helperMethods.cloneObject(constraints);
    if (cameraPermissionRequired === undefined || cameraPermissionRequired) {
      //todo
      // if(activatedCameraId){
      //   _constraints.video.deviceId = activatedCameraId;
      // }
      if(transparency){
       _constraints.video.transparency= true;
      }
      if (typeof window === "object" && typeof window.navigator === 'object' && window.navigator.mediaDevices) {
        window.navigator.mediaDevices
          .getUserMedia(_constraints)
          .then(handleSuccess)
          .catch(handleError);
      }else if (typeof window === "object" && !window.PalmSystem){
        setHideVideo(false);
        setCanvasSize({ width: 1920, height: 1080 });
        if(onCameraReady){
          setTimeout(onCameraReady, 0);
        }
        return;
      }
    }
    return () => {
      stopStreamedVideo();
    };
  }, [cameraPermissionRequired, activatedCameraId, transparency]);

  const onPlay = useCallback((ev) => {
    dispatch(updateCameraSize({width: ev.target.videoWidth, height: ev.target.videoHeight}));
  },[dispatch]);

  return (
    <div
      className={classNames(
        // css.covercam,
        hideVideo ? css.hide : null,
        css[size]
        // css[position]
      )}
    >
      <video
        ref={webcamRef}
        data-spotlight-id="pipcamera"
        className={classNames(css.camera, Config.DATA_VALIDATION_TEST ? css.noMirror: null)}
        playsInline
        autoPlay
        style={{ marginLeft: "auto", marginRight: "auto", left: 0, right: 0 }}
        onPlay={onPlay}
      />
      {/* for capture image */}
      <canvas
        data-spotlight-id="pipcamera_canvas"
        style={{ display: "none" }}
        width={canvasSize.width}
        height={canvasSize.height}
      />
    </div>
  );
};
export const setSavingVideoPath = (path) => {
  savingPath = path;
}
export const getSavingVideoPath = () => {
  return savingPath;
}
export const getLastRecordedVideo = async (index=0) => {
  console.log('getLastRecordedVideo ++', index);
  if(recordedBlobs && recordedBlobs.length > 0){
    if(!recordedBlobs[index]){
      recordedBlobs=[];//clear
      console.log('getLastRecordedVideo -- clear -1');
      return {index, buffer: null, nextIndex: -1};
    }
    let buffer = null;
    await recordedBlobs[index].arrayBuffer().then((b)=>{
      buffer = b;
    });
    console.log('getLastRecordedVideo --', index, buffer);
    return {index, buffer, nextIndex: index+1};
  }else{
    console.log('getLastRecordedVideo -- -1');
    return {index, buffer: null, nextIndex: -1};
  }
}
export default PIPCamera;
