import classNames from "classnames";
import css from "./TFixBarItem.module.less";

import { useEffect, useState, useMemo, useCallback } from "react";

import { $L } from "../../utils/helperMethods";

import EmojiGreen from "../../../assets/TBarItem/fluent_emoji_green.svg";
import EmojiYellow from "../../../assets/TBarItem/fluent_emoji_yellow.svg";
import EmojiRed from "../../../assets/TBarItem/fluent_emoji_red.svg";

const TYPE_FIXBAR = {
  ROM: "ROM",
  SFT: "SFT",
};

const TFixBarItem = ({
  type = TYPE_FIXBAR.ROM,
  value,
  grade,
  unit = "°",
  className,
  degree,
  minValue,
  isError,
  ...rest
}) => {
  const convertRange = useMemo(() => {
    let gradeRange,
      gradeColor = [];
    if (type === TYPE_FIXBAR.ROM) {
      gradeRange = [$L("Weak"), $L("Borderline"), $L("Good")];
      gradeColor = ["RED", "YELLOW", "GREEN"];
    } else if (type === TYPE_FIXBAR.SFT) {
      gradeRange = ["D", "C", "B", "A", "S"];
      gradeColor = ["RED", "YELLOW", "YELLOW", "GREEN", "GREEN"];
    }
    return { gradeRange, gradeColor };
  }, [type]);

  const reverseDegree = useMemo(() => {
    return [...degree].reverse();
  }, [degree]);

  const calcPercentage = useMemo(() => {
    if (!isError && degree.length > 0 && value) {
      let totalPercentage = 0;
      let calcValue,
        baseValue,
        ret = 0;
      if (type === TYPE_FIXBAR.ROM) {
        if (value > degree[1]) {
          totalPercentage += 66.67;
          calcValue = value - degree[1];
          baseValue = degree[0] - degree[1];
        } else if (value > degree[2]) {
          totalPercentage += 33.33;
          calcValue = value - degree[2];
          baseValue = degree[1] - degree[2];
        } else {
          calcValue = value;
          baseValue = degree[2];
        }
        ret = ((calcValue / baseValue) * 100) / 3;
      } else if (type === TYPE_FIXBAR.SFT) {
        const isIncresedData = degree[0] > degree[1];

        if (isIncresedData) {
          if (value > degree[1]) {
            totalPercentage += 80;
            calcValue = value - degree[1];
            baseValue = degree[0] - degree[1];
          } else if (value > degree[2]) {
            totalPercentage += 60;
            calcValue = value - degree[2];
            baseValue = degree[1] - degree[2];
          } else if (value > degree[3]) {
            totalPercentage += 40;
            calcValue = value - degree[3];
            baseValue = degree[2] - degree[3];
          } else if (value > degree[4]) {
            totalPercentage += 20;
            calcValue = value - degree[4];
            baseValue = degree[3] - degree[4];
          } else {
            const defaultValue = minValue ? minValue : 0;
            calcValue = value < defaultValue ? 0 : value;
            baseValue = degree[4];
          }
          ret = ((calcValue / baseValue) * 100) / 5;
        } else {
          if (value < degree[1]) {
            totalPercentage += 80;
            calcValue = value - degree[1];
            baseValue = degree[0] - degree[1];
          } else if (value < degree[2]) {
            totalPercentage += 60;
            calcValue = value - degree[2];
            baseValue = degree[1] - degree[2];
          } else if (value < degree[3]) {
            totalPercentage += 40;
            calcValue = value - degree[3];
            baseValue = degree[2] - degree[3];
          } else if (value < degree[4]) {
            totalPercentage += 20;
            calcValue = value - degree[4];
            baseValue = degree[3] - degree[4];
          } else {
            const defaultValue = minValue ? minValue : 0;
            calcValue = value > defaultValue ? 0 : value;
            baseValue = degree[4];
          }
          ret = ((calcValue / baseValue) * 100) / 5;
        }
      }
      totalPercentage += ret;

      return Math.min(totalPercentage, 100);
    } else {
      return 0;
    }
  }, [value, isError, degree, type]);

  const [length, setLength] = useState(calcPercentage);

  const GRADES = useCallback(() => {
    if (isError) {
      return EmojiRed;
    } else {
      if (type === TYPE_FIXBAR.ROM) {
        switch (grade) {
          case "a":
            return EmojiGreen;
          case "b":
            return EmojiYellow;
          default:
            return EmojiRed;
        }
      } else if (type === TYPE_FIXBAR.SFT) {
        switch (grade) {
          case "s":
          case "a":
            return EmojiGreen;
          case "b":
          case "c":
            return EmojiYellow;
          default:
            return EmojiRed;
        }
      }
    }
  }, [isError, grade, type]);

  useEffect(() => {
    setLength(calcPercentage);
  }, [calcPercentage]);

  return (
    <div className={classNames(css.bar, className ? className : null)}>
      <div
        className={classNames(
          css.fixedBar,
          type === TYPE_FIXBAR.SFT && css.SFT
        )}
      >
        {convertRange.gradeRange.map((item, index) => (
          <div
            key={`gradeRange_${index}`}
            className={classNames(
              css.fixedBarSection,
              convertRange.gradeColor[index] === "GREEN"
                ? css.fixedBarGreen
                : convertRange.gradeColor[index] === "YELLOW"
                ? css.fixedBarYellow
                : css.fixedBarRed
            )}
          >
            <span>{item}</span>
          </div>
        ))}
      </div>
      <div
        className={css.marker}
        style={{
          left: `calc(${length}% - 19px)`,
          backgroundImage: `url(${GRADES()})`,
        }}
      />
      <div className={css.degree}>
        <span>{(minValue ? minValue : 0) + unit}</span>
        {reverseDegree.map((item, index) => {
          return <span key={`degree_${index}`}>{item + unit}</span>;
        })}
      </div>
    </div>
  );
};

export default TFixBarItem;
export { TYPE_FIXBAR };
