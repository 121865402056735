import React, { useMemo } from "react";
import css from "./TabLable.module.less";
import {
  BODY_SCAN_SCORE,
  BODY_SCAN_COLOR,
  SFT_SCORE_MANAGE_TOP,
  SFT_SCORE_DANGER_TOP,
  BBT_SCORE_MANAGE_TOP,
  BBT_SCORE_DANGER_TOP,
  ROM_COLOR,
  ROM_SCORE,
  SCORE_MINT_KEY,
  SCORE_YELLOW_KEY,
  SCORE_RED_KEY,
} from "../../utils/Constants";
/**
 *
 * @param selectedTabIndex
 * @type "PhysicalTest, BodyAlignmentScan, Body Balance"
 * @returns selectedTab label color
 */
const TabLabel = ({
  type = "BodyAlignment",
  selectedTabIndex,
  index,
  score,
}) => {
  const bottomStyle = useMemo(() => {
    // previous color value
    // green: #71B1A9
    // yellow: #FABB1D
    // red: #C04554

    let color;
    if (type === "PhysicalTest") {
      switch (score) {
        case "s":
        case "a":
          color = BODY_SCAN_COLOR.fine; //green
          break;
        case "b":
        case "c":
          color = BODY_SCAN_COLOR.manage; //yellow
          break;
        default:
          color = BODY_SCAN_COLOR.danger; //red
          break;
      }
    } else if (type === "BodyAlignment") {
      switch (score) {
        case BODY_SCAN_SCORE.danger:
          color = BODY_SCAN_COLOR.danger;
          break;
        case BODY_SCAN_SCORE.manage:
          color = BODY_SCAN_COLOR.manage;
          break;
        case BODY_SCAN_SCORE.fine:
          color = BODY_SCAN_COLOR.fine;
          break;
      }
    } else if (type === "ROM") {
      switch (score) {
        case ROM_SCORE.c:
          color = ROM_COLOR.c;
          break;
        case ROM_SCORE.b:
          color = ROM_COLOR.b;
          break;
        case ROM_SCORE.a:
          color = ROM_COLOR.a;
          break;
      }
    } else {
      switch (score) {
        case SCORE_MINT_KEY:
          color = BODY_SCAN_COLOR.fine;
          break;
        case SCORE_YELLOW_KEY:
          color = BODY_SCAN_COLOR.manage;
          break;
        case SCORE_RED_KEY:
          color = BODY_SCAN_COLOR.danger;
          break;
        default:
          break;
      }
    }
    return { backgroundColor: color };
  }, [type, score]);

  if (index === 0 || selectedTabIndex === index || (!score && score !== 0)) {
    return null;
  }
  return <div className={css.dot} style={bottomStyle} />;
};

export default TabLabel;