import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const HomecareProgramIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7713 21.7116C29.8412 21.7116 31.5192 20.0336 31.5192 17.9637C31.5192 15.8938 29.8412 14.2158 27.7713 14.2158C25.7014 14.2158 24.0234 15.8938 24.0234 17.9637C24.0234 20.0336 25.7014 21.7116 27.7713 21.7116Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M32.4787 25.7412H23.0625V37.1242H32.4787V25.7412Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0645 34.5967H23.3589L23.0896 36.2809L22.116 42.3708C22.0674 42.6745 22.3021 42.9497 22.6097 42.9497H25.6478C25.8935 42.9497 26.1028 42.7712 26.1416 42.5286L26.7702 38.5967H28.8127L29.4087 42.5247C29.4458 42.7691 29.6559 42.9497 29.903 42.9497H32.9374C33.2434 42.9497 33.4776 42.6772 33.4317 42.3747L32.5095 36.2967L32.2516 34.5967H30.5322H25.0645Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M19 36.0027L19.0214 34.3034C19.0528 31.8044 19.8651 29.3778 21.3444 27.3634C22.4729 25.8266 24.2656 24.9189 26.1722 24.9189H27.9732"
        stroke={convertThemeColor(iconType)}
        strokeWidth="3.5"
        strokeLinecap="round"
      />
      <path
        d="M37.9454 14.8367L37.5153 17.0649C37.0968 19.2333 36.0973 21.2472 34.6236 22.8919C33.4669 24.1828 31.8155 24.9204 30.0823 24.9204H27.9722"
        stroke={convertThemeColor(iconType)}
        strokeWidth="3.5"
        strokeLinecap="round"
      />
      <path
        d="M43.2244 13.1309L42.0869 12.9127C41.6324 12.8255 41.1933 13.1233 41.1061 13.5778L40.3628 17.4531C40.2756 17.9076 40.5734 18.3467 41.0279 18.4339L42.1583 18.6507C42.4682 18.7101 42.7884 18.5921 42.9682 18.3329C43.9928 16.8563 44.2798 15.4776 43.8556 13.7162C43.7826 13.4131 43.5305 13.1896 43.2244 13.1309Z"
        fill={convertThemeColor(iconType)}
      />
      <path
        d="M33.6815 11.2944L34.8183 11.5152C35.2726 11.6034 35.5693 12.0432 35.4811 12.4975L34.7281 16.3756C34.6399 16.8299 34.2001 17.1266 33.7458 17.0384L32.616 16.819C32.3062 16.7589 32.0529 16.53 31.9825 16.2224C31.5812 14.4691 31.828 13.0814 32.8776 11.603C33.0582 11.3488 33.3754 11.235 33.6815 11.2944Z"
        fill={convertThemeColor(iconType)}
      />
    </svg>
  );
};

export default HomecareProgramIcon;
