import classNames from "classnames";
import { useEffect, useState, useCallback } from "react";
import css from "./TStepperTest.module.less";
import { $L } from "../../utils/helperMethods";

const TStepperTest = ({
  order = [],
  numberMy = 0,
  numberProgram = 0,
  type = "horizontal",
  className,
  ...rest
}) => {
  const [lengthMy, setLengthMy] = useState(0);
  const [lengthProgram, setLengthProgram] = useState(0);

  const getLength = useCallback(() => {
    setLengthMy(numberMy);
    setLengthProgram(numberProgram);
  }, [numberMy, numberProgram]);

  useEffect(() => {
    getLength();
  }, [getLength]);

  return (
    <div
      className={classNames(
        css.stepper,
        css[type],
        className ? className : null
      )}
    >
      <div className={css.fixedBar} />
      {type === "horizontal" && (
        <>
          <div
            className={css.stepBarProgram}
            style={{ width: lengthProgram + "%" }}
          >
            <div className={css.progressProgram}>
              <span>{$L("Progress")}</span>
              <span>{`${lengthProgram}%`}</span>
            </div>
          </div>
          <div className={css.stepBarMy} style={{ width: lengthMy + "%" }}>
            <div className={css.progressMy}>
              <span>나</span>
              <span>{`${lengthMy}%`}</span>
            </div>
          </div>
        </>
      )}
      {order && order.length > 0 ? (
        order.map((text, index) => {
          const indexNum = (100 / (order.length - 1)) * index;
          return (
            <div
              key={index}
              className={classNames(
                css.step,
                indexNum <= numberMy
                  ? css.activeMy
                  : indexNum <= numberProgram
                  ? css.activeProgram
                  : null
              )}
            >
              <div className={classNames(css.circle)} />
              <div className={css.text}>{text}</div>
            </div>
          );
        })
      ) : (
        <div>{$L("No Order")}</div>
      )}
    </div>
  );
};

export default TStepperTest;
