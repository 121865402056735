import css from "./MainSection.module.less";
import { useMemo, useRef, useState, useCallback } from "react";

import classNames from "classnames/bind";

import {$L, convertNewlinesToBr} from '../../../utils/helperMethods';
import * as Config from '../../../utils/Config';
import * as SoundEffect from "../../../utils/SoundEffect";

import { Job } from "@enact/core/util";
import Spottable from "@enact/spotlight/Spottable";

import HOMECARE_GATE_ROUTIN from '../../../../assets/HomecareProgram/MainSection/img_homecare_gate_routin.png';
import HOMECARE_GATE_RECOMMEND from '../../../../assets/HomecareProgram/MainSection/img_homecare_gate_recommand.png';

const SpottableComponent = Spottable('div');

const SectionsData = () => ({
  "routine":
  {
    image: HOMECARE_GATE_ROUTIN,
    sectionTitle: $L("O2O 정규프로그램"),
    description: convertNewlinesToBr(
      $L(
        "A health project. Enjoy stretching with confidence and stability. You will discover happiness and well-being in this journey to better health accompanied by health care professionals."
      )
    ),
  },
  "recommend":
  {
    image: HOMECARE_GATE_RECOMMEND,
    sectionTitle: $L("AI 맞춤형 운동추천"),
    description: convertNewlinesToBr(
      $L(
        "Here are {br}personalized workout recommendations made based on your data obtained in a My Body Analysis."
      )
    ),
  },
});

/**
*
* @param {type} 'routine' , 'recommend'
* @returns
*/

const MainSection = ({type, onClick, onFocus})=>{
  const [pressed, setPressed] = useState(false);
  const clearPressedJob = useRef(new Job((func) => {
    setPressed(false);
    setTimeout(func, Config.BUTTON_PRESS_DELAY/2);
  }, Config.BUTTON_PRESS_DELAY));

  const sectionData = useMemo(()=>{
    return SectionsData()[type];
  },[type]);

	const _onClick = useCallback((ev)=>{
    setPressed(true);
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    clearPressedJob.current.start(()=>{
      if(onClick){
        onClick(ev);
      }
    });
	},[onClick]);

  const _onFocus = useCallback(() => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
  }, []);

  const _onBlur = useCallback((ev)=>{
    setPressed(false);
    clearPressedJob.current.stop();
	},[]);

  return (
    <SpottableComponent
      className={classNames(css.button, css[type], pressed && css.pressed)}
      data-webos-voice-intent={'Select'}
      data-webos-voice-label={null}
      onClick={_onClick}
      onFocus={_onFocus}
      onBlur={_onBlur}
    >
      <div className={css.image} style={{backgroundImage: `url(${sectionData.image})`}}/>
      <div className={css.box}>
        <div className={css.title}>{sectionData.sectionTitle}</div>
        <div className={css.description}>{sectionData.description}</div>
      </div>
    </SpottableComponent>
  );
}
export default MainSection;