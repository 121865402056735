import React, { useEffect, useRef } from "react";
import css from "./GoodSignAnimation.module.less";
import img_scan_finish_good from "../../../assets/goodAnimation/img_scan_finish_good.png"

const GoodSignAnimation = ({onAnimationEnd}) => {
  const googSingEndRef = useRef(null);

  useEffect(() => {
    googSingEndRef.current = setTimeout(() => {
      onAnimationEnd();
    }, 3000);
    return(() => {
      clearTimeout(googSingEndRef.current);
    })
  },[]);

  return (
    <div className={css.container}>
      <img src={img_scan_finish_good} alt="scan finish good"/>
    </div>
  );
};

export default GoodSignAnimation;
