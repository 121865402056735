import { $L } from '../../../../utils/helperMethods';
import { DATAKEY } from "../../../../features/bodyBalance/bodyBalanceSlice";
import ready from "../../../../../assets/video/bodyBalance/ready.mp4";
import ready_Img from "../../../../../assets/video/bodyBalance/ready.png";
import twoLegStandGuideImg from "../../../../../assets/video/bodyBalance/twoLegStand.png";
import singleLegStandLImg from "../../../../../assets/video/bodyBalance/singleLegStandL.png";
import singleLegStandRImg from "../../../../../assets/video/bodyBalance/singleLegStandR.png";
import twoLegStandGuide from "../../../../../assets/video/bodyBalance/twoLegStand.mp4";
import singleLegStandL from "../../../../../assets/video/bodyBalance/singleLegStandL.mp4";
import singleLegStandR from "../../../../../assets/video/bodyBalance/singleLegStandR.mp4";

// 테스트 타입
export const BBT_TYPE = {
  TWO_LEG_STANCE: 0, //양발서기 (족부형상, 바디밸런스)
  SINGLE_LEG_STANCE_L: 1,  //외발서기 (CoP)
  SINGLE_LEG_STANCE_R: 2,  //외발서기 (CoP)
  // WORK_MODE: 2,  //보행분석
}

// 시퀀스
export const BBT_SEQUENCE = {
  SEQUENCE_INIT: 0, //whole sequence
  GUIDE_START: 1,
  GUIDE_END: 2,
  TEST_START: 3,
  TEST_POSINING: 4,
  TEST_MATCHED: 5,
  TEST_END: 6,
  SEQUENCE_FINISHED: 100 //whole sequence
}

//  guideTextImg : 테스트 전 노출 이미지
// [0] : 가이드 시작, [1] : 가이드 끝 -> 테스트 시작

const notOnTVTime = (typeof window === 'object' && !window.PalmSystem) ? 5 : -1

let BBT_INFO = null;
export const INTRO_INFO = {
  guideVideo: ready,
}
export const INTRO_AVATAR = ready_Img;

export const getBBT_INFO = () => {
  if(BBT_INFO){
    return BBT_INFO;
  }
  BBT_INFO = [];
  BBT_INFO[BBT_TYPE.TWO_LEG_STANCE] = {
    type: BBT_TYPE.TWO_LEG_STANCE,
    resultName: DATAKEY.TWO_LEG_STANCE,
    preCountVoice: "M4",
    guideText: [$L("Location guide"), $L("Two-legged Stand")], //guide, test
    guideVideo: twoLegStandGuide,
    testDetail: "(" + $L("Flat Foot") + ", " + $L("Medium-arched foot") + ", " + $L("Hollow Foot") + ")",
    testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
    bleMode: 'bodycheck1' // mode 1: 양발서기, result - json 형식
  }

  BBT_INFO[BBT_TYPE.SINGLE_LEG_STANCE_L] = {
    type: BBT_TYPE.SINGLE_LEG_STANCE_L,
    resultName: DATAKEY.ONE_LEG_STANCE_L,
    preCountVoice: "M4",
    guideText: [$L("Location guide"), $L("Single leg balance (left)")], //guide, test
    guideVideo: singleLegStandL,
    testDetail: "(" + $L("Common") + ", " + $L("Adduction") + ", " + $L("Abduction") + ")",
    testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
    bleMode: 'bodycheck2Left' // mode 1: 한발서기(왼/오), result - json 형식
  }

  BBT_INFO[BBT_TYPE.SINGLE_LEG_STANCE_R] = {
    type: BBT_TYPE.SINGLE_LEG_STANCE_R,
    resultName: DATAKEY.ONE_LEG_STANCE_R,
    preCountVoice: "M4",
    guideText: [$L("Location guide"), $L("Single leg balance (right)")], //guide, test
    guideVideo: singleLegStandR,
    testDetail: "(" + $L("Common") + ", " + $L("Adduction") + ", " + $L("Abduction") + ")",
    testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
    bleMode: 'bodycheck2Right' // mode 1: 한발서기(왼/오), result - json 형식
  }
  // todo
  // BBT_INFO[BBT_TYPE.WORK_MODE] = {
  //   type: BBT_TYPE.WORK_MODE,
  //   resultName: "walkInPlace",
  //   preCountVoice: "M15",
  //   guideText: [$L("Walk in Place Test Guide"), $L("Walk in Place")], //guide, test
  //   guideTextImg: [null, null],
  //   guideVideo: "",
  //   // guideAudio: "SFT6",
  //   guideAudio: "",
  //   testDetail: $L("(정상, 이상유무 판단)"),
  //   testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
  //   engineParams: {mode: "bodycheck3", featureList: (typeof window === 'object' && !window.PalmSystem)? dummyWalking : null} // mode 3: 보행분석, result - json 형식
  // }
  return BBT_INFO;
}

let BBT_ONTIME = null;
export const getBBT_ONTIME = () => {
  if(BBT_ONTIME){
    return BBT_ONTIME;
  }
  BBT_ONTIME = [];
  BBT_ONTIME[BBT_TYPE.TWO_LEG_STANCE] = {
    0.1: { avatarImage: null, sequence: BBT_SEQUENCE.SEQUENCE_INIT},
    5.8: { guideMsg: $L("We'll now <b>measure posture when standing on two feet</b>. Look at the character and follow along.") },
    "end": { sequence: BBT_SEQUENCE.TEST_START, avatarImage: twoLegStandGuideImg }
  };

  BBT_ONTIME[BBT_TYPE.SINGLE_LEG_STANCE_L] = {
    0.1: { avatarImage: null, sequence: BBT_SEQUENCE.SEQUENCE_INIT, guideMsg: $L("We'll now <b>measure posture when standing on your left foot</b>. Look at the character and follow along.") },
    "end": { sequence: BBT_SEQUENCE.TEST_START, avatarImage: singleLegStandLImg }
  };
  BBT_ONTIME[BBT_TYPE.SINGLE_LEG_STANCE_R] = {
    0.1: { avatarImage: null, sequence: BBT_SEQUENCE.GUIDE_START, guideMsg:  $L("We'll now <b>measure posture when standing on your right foot</b>. Look at the character and follow along.")},
    "end": { sequence: BBT_SEQUENCE.TEST_START, avatarImage: singleLegStandRImg }
  };
  return BBT_ONTIME;
}



export const initBBTOnTime = (bbtType) => {
  const testOnTime = BBT_ONTIME[bbtType];
  const keys = Object.keys(testOnTime);
  for (let i = 0; i < keys.length; i++) {
    testOnTime[keys[i]].excuted = false;
  }
};
export const initAllBBTOnTime = () => {
  const typeKeys = Object.keys(BBT_ONTIME);
  for (let i = 0; i < typeKeys.length; i++) {
    initBBTOnTime(typeKeys[i]);
  }
};