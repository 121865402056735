import comlinkJs from "!!raw-loader!./comlink.js";
import workerCodeXFit from "!!raw-loader!./WorkerXFit.js";
import workerCodeHit from "!!raw-loader!./WorkerHit.js";
import WorkerCodeTest from "!!raw-loader!./WorkerCodeTest.js";
import WorkerCanvas from "!!raw-loader!./WorkerCanvas.js";

/* xFitEngine */
import utils from "!!raw-loader!../../../assets/xFitEngine/core/utils.js";
import calc_degree from "!!raw-loader!../../../assets/xFitEngine/core/calc_degree.js";
import engine_bodytype from "!!raw-loader!../../../assets/xFitEngine/core/engine_bodytype.js";
import engine_sft from "!!raw-loader!../../../assets/xFitEngine/core/engine_sft.js";
import check_location from "!!raw-loader!../../../assets/xFitEngine/core/check_location.js";
import xfitengine from "!!raw-loader!../../../assets/xFitEngine/XfitEngine.js";
import engine_rom from "!!raw-loader!../../../assets/xFitEngine/core/engine_rom";
import engine_scoring from "!!raw-loader!../../../assets/xFitEngine/core/engine_scoring";

/* hitEngine */
import WalkAnalyzer from "!!raw-loader!../../../assets/HitEngine/core/WalkAnalyzer.js";
import BalanceAnalyzer from "!!raw-loader!../../../assets/HitEngine/core/BalanceAnalyzer.js";
import HitAnalyzer from "!!raw-loader!../../../assets/HitEngine/HitAnalyzer.js";
import ClimbingAnalyzer from "!!raw-loader!../../../assets/HitEngine/core/ClimbingAnalyzer"

import * as Comlink from 'comlink';
import { cloneObject } from "../helperMethods";

export const WORKER_ID = {
    XFIT: "xfit",
    HIT: "hit",
    TEST: "test",
    CANVAS: "canvas",
};
export const WORKER_FUNC = {
    [WORKER_ID.XFIT]: workerCodeXFit,
    [WORKER_ID.HIT]: workerCodeHit,
    [WORKER_ID.TEST]: WorkerCodeTest,
    [WORKER_ID.CANVAS]: WorkerCanvas
};
export const WORKER_STATE = {
    NONE: 'none',
    IDLE: 'idle',
    WORKING: 'working',
}
class WebWorkerUtil {
    constructor () {
        this.workers = {};
        this.comlinks = {}
        this.workersState = {};
        this.onResponses = {};
        this.localEngine = {};
        this.useLocalEngine = {};
        this.inputData = {};
        this.valueKeys = {};
    }
    makeWorker = async (workerId, onResponse, workOnUiThread = false) => {
        if(workerId && window){
            this.onResponses[workerId] = onResponse;
            const xFitscriptStrings = [utils, calc_degree, engine_bodytype, engine_rom, engine_scoring, engine_sft, check_location, xfitengine];
            const hitScriptStrings = [WalkAnalyzer, BalanceAnalyzer, ClimbingAnalyzer, HitAnalyzer];
            if(workOnUiThread){
                this.useLocalEngine[workerId] = true;
                if(!this.localEngine[workerId]){
                    this.workersState[workerId] = WORKER_STATE.IDLE;
                    if(workerId === WORKER_ID.XFIT ){
                        for(let i = 0; i<xFitscriptStrings.length; i++){
                            let script = document.createElement('script');
                            script.innerText=xFitscriptStrings[i];
                            document.body.appendChild(script);
                        }
                        this.localEngine[workerId] = typeof window === 'object' && new window.XfitEngine();
                    }
                    if(workerId === WORKER_ID.HIT){
                        for(let i = 0; i<hitScriptStrings.length; i++){
                            let script = document.createElement('script');
                            script.innerText=hitScriptStrings[i];
                            document.body.appendChild(script);
                        }
                        this.localEngine[workerId] = typeof window === 'object' && new window.HitAnalyzer();
                    }
                }
            }else {
                this.useLocalEngine[workerId] = false;
                if(!this.comlinks[workerId] && typeof window === 'object'){
                    console.log('WebWorkerUtil makeWorker ', workerId);
                    const scriptFunc = WORKER_FUNC[workerId];
                    let code = scriptFunc.toString();
                    const comlinkJsCode = comlinkJs.toString();
                    const blob = new window.Blob([comlinkJsCode, code], { type: 'application/javascript' });
                    const script = window.URL.createObjectURL(blob);
                    const worker =  new window.Worker(script);
                    this.workers[workerId] = worker;
                    const MyClass = Comlink.wrap(worker);
                    this.comlinks[workerId] = await new MyClass();
                    this.workersState[workerId] = WORKER_STATE.IDLE;

                    if(workerId === WORKER_ID.XFIT ){
                        /* xFit Engine Load */
                        const scripts = [];
                        xFitscriptStrings.forEach(element => {
                            const blob = new window.Blob([element], { type: 'application/javascript' });
                            const script = window.URL.createObjectURL(blob);
                            scripts.push(script);
                        });
                        await this.comlinks[workerId]?.onmessage({type:"loadEngine", scripts: scripts}, Comlink.proxy(onResponse));
                    }
                    if(workerId === WORKER_ID.HIT){
                        const scripts = [];
                        hitScriptStrings.forEach(element => {
                            const blob = new window.Blob([element], { type: 'application/javascript' });
                            const script = window.URL.createObjectURL(blob);
                            scripts.push(script);
                        });
                        await this.comlinks[workerId]?.onmessage({type:"loadEngine", scripts: scripts}, Comlink.proxy(onResponse));
                    }
                }
            }
            console.log('makeWorker workerId ', workerId);
            return workerId;
        }else{
            return null;
        }
    }
    //only for localengine
    hitCallback = async (ev) => {
        console.log('hitCallback', ev);
        if(this.onResponses[WORKER_ID.HIT]){
          await this.onResponses[WORKER_ID.HIT]({type: "response", value: ev});
        }
    }

    setIntputData = ({ key, replaceKey, valueKey, setFunc, res }) => {
        if (replaceKey) {
            this.inputData[key] = { replaceKey, setFunc, res: null };
            this.valueKeys[valueKey] = key;
        } else if (this.inputData[key] && res !== undefined) {
            this.inputData[key].res = res;
        }
    }

    /**
     * @param {*} workerId
     * @param {*} message {type: "process", ...rest} : type must be set
     * @param {*} force
     */
    postMessage = async (workerId, message, force = false) => {
        if(!force && this.getState(workerId) === WORKER_STATE.WORKING){
            console.warn('WebWorkerUtil working', workerId);
        }else{
            const responseFunc = this.onResponses[workerId];
            const newMessage = cloneObject(message);
            const inputData = this.inputData[message.type];
            if (inputData && inputData.replaceKey) {
                newMessage.type = inputData.replaceKey;
            }
            if (newMessage.type.startsWith('init')) {
                if (workerId === WORKER_ID.HIT) {
                    this.inputData = {};
                    this.valueKeys = {};
                }
                if (newMessage.setOption) {
                    this.setIntputData(newMessage.setOption);
                    delete newMessage.setOption;
                }
            }
            if (inputData && inputData.res && inputData.setFunc && newMessage.type === inputData.replaceKey) {
                newMessage.value.push(...inputData.setFunc(inputData.res));
            }

            // console.time('WebWorkerUtil postMessage ' + newMessage.type);

            if(this.useLocalEngine[workerId]){
                this.workersState[workerId] = WORKER_STATE.WORKING;
                let res = null;

                if(newMessage.type.startsWith('init')){
                    if(newMessage.value){
                        newMessage.value = [this.hitCallback, ...newMessage.value]
                    }else{
                        newMessage.value = [this.hitCallback];
                    }
                }

                if(newMessage.value){
                    res = await this.localEngine[workerId]?.[newMessage.type](...newMessage.value);
                }else{
                    res = await this.localEngine[workerId]?.[newMessage.type]();
                }

                if(responseFunc){
                    if(res){
                        responseFunc({type: newMessage.type, value:res});
                    }
                }
                if (this.valueKeys[message.type]) {
                    if (this.inputData[this.valueKeys[message.type]]) {
                        this.setIntputData({ key: this.valueKeys[message.type], res: res });
                    }
                }
                this.workersState[workerId] = WORKER_STATE.IDLE;
            }
            else if(this.comlinks[workerId] && this.comlinks[workerId]?.onmessage){
                this.workersState[workerId] = WORKER_STATE.WORKING;
                await this.comlinks[workerId]?.onmessage(newMessage, Comlink.proxy(responseFunc));
                this.workersState[workerId] = WORKER_STATE.IDLE;
            }
            // console.timeEnd('WebWorkerUtil postMessage ' + newMessage.type);
        }
    }
    getState = (workerId) => {
        return this.workersState[workerId] ? this.workersState[workerId] : WORKER_STATE.NONE;
    }
    //may be useless
    terminate = (workerId) => {
        if(this.useLocalEngine[workerId]){
            //do nothing
        }else{
            this.workers[workerId]?.terminate();
            this.workers[workerId] = null;
            this.workersState[workerId] = null;
            this.comlinks[workerId] = null;
        }
        this.inputData = {};
        this.valueKeys = {};
    }
    setCallback = (workerId, callback) => {
        this.onResponses[workerId] = callback;
    }
}
const mWebWorkerUtil = new WebWorkerUtil();
export default mWebWorkerUtil;