import classNames from "classnames";
import css from "./MonthGoal.module.less";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { $L, transSecondsToHMS, numberWithCommas } from "../../../../utils/helperMethods";
import * as Config from "../../../../utils/Config";
import TGGraph from "../../../../components/TGGraph/TGGraph";
import ICON_DAY from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_day.png"
import ICON_TIME from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_time.png"
import ICON_CALORIE from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_calorie.png"

const getGoalData = (itemInfo, type) => {
  switch (type) {
    case "date":
      return {
        title: $L("Number of days you worked out"),
        user: itemInfo.user,
        goal: $L("{num}일").replace("{num}", itemInfo.goal),
        progress: Math.floor(itemInfo.user / itemInfo.goal * 100),
        icon: ICON_DAY,
        expertRecommendation: $L("월 {num}일").replace("{num}", 5),
        userAverage: $L("월 {num}일").replace("{num}", 5),
      };
    case "time":
      return {
        title: $L("Workout Time"),
        user: transSecondsToHMS(itemInfo.user, false, true),
        goal: transSecondsToHMS(itemInfo.goal, false, true),
        progress: Math.floor((itemInfo.user / itemInfo.goal) * 100),
        icon: ICON_TIME,
        expertRecommendation: $L("월 {num}").replace("{num}", "14:30"),
        userAverage: $L("월 {num}").replace("{num}", "5:40"),
      };
    case "calorie":
      return {
        title: $L("Calories burned"),
        user: numberWithCommas(itemInfo.user),
        goal: `${numberWithCommas(itemInfo.goal)}Kcal`,
        progress: Math.floor(itemInfo.user / itemInfo.goal * 100),
        icon: ICON_CALORIE,
        expertRecommendation: $L("월 {num}Kcal").replace("{num}", "2,333"),
        userAverage: $L("월 {num}Kcal").replace("{num}", "2,333")
      };
    default:
      return {};
  }
};

const MonthGoal = ({className, type, itemInfo, ...rest }) => {
  const goalData = useMemo(() => {
    return getGoalData(itemInfo, type);
  }, [itemInfo, type]);

  return (
    <div
      className={classNames(
        className,
        css.goalContainer,
      )}
      {...rest}
    >
      <div className={css.dataContainer}>
        <div className={css.graphBox}>
          <TGGraph
            className={css.graph}
            size={104}
            lineWidth={14}
            primaryPercentage={goalData.progress}
            isDark={true}
          />
          <div className={css.icon} style={{backgroundImage: `url(${goalData.icon})`}}/>
        </div>
        <div className={css.goalBox}>
          <div className={css.title}>{goalData.title}</div>
          <div className={css.data}>
            <span>{goalData.user}</span>
            <span>{`/${goalData.goal}`}</span>
          </div>
        </div>
      </div>
      <div className={css.hr} />
      <div className={css.infoBox}>
        <div className={css.title}>{$L("Expert recommendations")}</div>
        <div className={css.data}>{goalData.expertRecommendation}</div>
      </div>
      <div className={css.infoBox}>
        <div className={css.title}>{$L("User average")}</div>
        <div className={css.data}>{goalData.userAverage}</div>
      </div>
    </div>
  );
};

export default MonthGoal;