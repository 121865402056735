import React, { useCallback, useEffect, useMemo } from "react";
import Spotlight from "@enact/spotlight";
import IHOAPopupPanel from "../IHOAPopupPanel";
import TButton, { SIZES } from "../../../components/TButton/TButton";
import TGoalHistory from "../../../components/TGoalHistory/TGoalHistory";
import { $L, transTimeSecToText } from "../../../utils/helperMethods";
import css from "./ActivityPopup.module.less";
import classNames from "classnames";
import { RECORD_INFO } from "./Constants";

const ActivityReportPopup = ({ contentInfo, reportData, onClick, onClose, ...props }) => {
  useEffect(()=>{
    setTimeout(() => Spotlight.focus('activityReportPopup_firstSpot'), 0);
  },[])

  const _onClick = useCallback((idx) => (ev) => {
    if (onClick) onClick(idx)(ev);
  }, [onClick]);

  const _onClose = useCallback((ev) => {
    if(onClose) onClose(ev);
  }, [onClose]);

  const GOAL_INFO = useMemo(() => {
    const isHeight = reportData.progressHeight !== undefined;

    return [
      { type: 'calorie', currentData: reportData.progressCalories, goalData: contentInfo.calories },
      { type: isHeight ? 'm' : 'km', currentData: isHeight ? reportData.progressHeight : reportData.progressDistance, goalData: contentInfo.distance * (isHeight ? 1000 : 1) },
      { type: 'step', currentData: reportData.progressStep, goalData: contentInfo.step },
    ]
  }, [contentInfo, reportData]);

  return (
    <IHOAPopupPanel onClose={_onClose} {...props}>
      <div className={css.finishedContainer}>
        <div className={classNames(css.box, css.goalRecord)}>
          <div className={css.title}>{$L("Records of goals reached")}</div>
          <TGoalHistory
            gSize={200}
            items={GOAL_INFO}
            className={css.goalHistory}
            defaultFocus={'calorie'}
          />
        </div>
        <div className={classNames(css.box, css.myRecord)}>
          <div className={css.title}>{$L("Record of this workout")}</div>
          <div className={css.recordInfo}>
            {RECORD_INFO().map(info => <div key={`record-${info.key}`} className={css.record}>
              <div className={css.label}><img src={info.icon}/><span>{info.label}</span></div>
              <div className={css.value}>{info.key.toLowerCase().includes('time') ? transTimeSecToText(reportData[info.key], true) : reportData[info.key].toLocaleString()}{info.unit}</div>
            </div>)}
          </div>
        </div>
      </div>
      <div className={css.btnWrapper}>
        <TButton spotlightId="activityReportPopup_firstSpot" size={SIZES.xLarge} className={css.btn} onClick={_onClick()}>{$L("Verified")}</TButton>
      </div>
    </IHOAPopupPanel>
  );
};

export default ActivityReportPopup;