import { useCallback, useRef, useState } from 'react';
import TScroller from '../../../../components/TScroller/TScroller';
import TButton from '../../../../components/TButton/TButton';

const TScrollLayout = () => {
  const scrollTo = useRef(null);
  const [scrollMode, setScrollMode] = useState('translate');

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  const scrollToTop = useCallback(() => {
    if (scrollTo && scrollTo.current) {
      setScrollMode('native');
      scrollTo.current({ position: { y: 0 }, animate: true });
    }
	}, []);

  const onScrollStop = useCallback(() => {
    if (scrollMode === 'native') {
      setScrollMode('translate');
    }
  }, [scrollMode]);

  return (
    <TScroller cbScrollTo={getScrollTo} style={{ height: '500px', color: '#212121' }} scrollMode={scrollMode} onScrollStop={onScrollStop}>
      <div style={{ padding: '16px' }}>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>1. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>2. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>3. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>4. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>5. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>6. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>7. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>8. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>9. TEST TEST TEST TEST TEST</div>
        <div style={{ padding: '16px', borderBottom: '1px solid black' }}>10. TEST TEST TEST TEST TEST</div>
        <TButton onClick={scrollToTop} style={{ marginTop: '16px' }}>Top</TButton>
      </div>
    </TScroller>
  );
};

export default TScrollLayout;
