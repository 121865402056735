import React, { useMemo } from "react";
import FootTypeArchBarItem from "../../../../../components/FootTypeArchBarItem/FootTypeArchBarItem";

const FootTypeResults = ({ currentData, style }) => {
  const archIndexData = useMemo(() => {
    const ret = { leftarchIndex: 0, rightarchIndex: 1 };
    if (currentData?.twolegstance) {
      ret.leftarchIndex = currentData?.twolegstance?.value_archIndex_L;
      ret.rightarchIndex = currentData?.twolegstance?.value_archIndex_R;
    }
    return ret;
  }, [currentData]);

  return (
    <FootTypeArchBarItem
      archIndexLData={archIndexData.leftarchIndex}
      archIndexRData={archIndexData.rightarchIndex}
      style={style}
    />
  );
};

export default FootTypeResults;