import css from "./NoticeItem.module.less";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { $L } from "../../../../utils/helperMethods";
import * as Config from "../../../../utils/Config";

import { Job } from "@enact/core/util";
import Spottable from "@enact/spotlight/Spottable";

const SpottableComponent = Spottable("div");

const NoticeItem = ({className, title, text, date, isRead=false, onClick, ...rest }) => {
  const [pressed, setPressed] = useState(false);

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback((ev) => {
    setPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick(ev);
      }
    });
  }, [onClick]);


  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        className,
        css.noticeItem,
        pressed && css.pressed,
      )}
      onClick={_onClick}
    >
      <div className={css.content}>
        <div className={css.wrap}>
          {isRead && <div className={css.tag}>NEW</div>}
          <div className={css.title}>{title}</div>
        </div>
        <div className={css.text}>{text}</div>
      </div>
      <div className={css.date}>{date}</div>

    </SpottableComponent>
  );
};

export default NoticeItem;