import classNames from "classnames";
import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Job } from "@enact/core/util";
import { useSelector, useDispatch } from "react-redux";
import Spotlight from "@enact/spotlight";
import css from "./TabLayout.module.less";
import { $L } from "../../utils/helperMethods";
import { panel_names } from "../../utils/Config";
import { resetPanels } from "../../features/panels/panelsSlice";
import TabItem from "./TabItem";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import { Cancelable } from "@enact/ui/Cancelable";
import Skinnable from "@enact/sandstone/Skinnable";

import ICON_NORMAL_BODY_CHECK_UP from "../../../assets/icon/normal_body_check_up_icon.svg";
import ICON_NORMAL_HOME from "../../../assets/icon/normal_home_icon.svg";
import ICON_NORMAL_HOMECARE_PROGRAM from "../../../assets/icon/normal_homecare_program_icon.svg";
import ICON_NORMAL_IN_HOME_OUTDOOR_ACTIVITY from "../../../assets/icon/normal_in_home_outdoor_activity_icon.svg";
import ICON_NORMAL_MY from "../../../assets/icon/normal_my_icon.svg";
import ICON_NORMAL_RECORD from "../../../assets/icon/normal_record_icon.svg";
import ICON_NORMAL_REPORT from "../../../assets/icon/normal_report_icon.svg";
import ICON_NORMAL_SETTING from "../../../assets/icon/normal_setting_icon.svg";

import ICON_EXPANDED_BODY_CHECK_UP from "../../../assets/icon/expanded_body_check_up_icon.svg";
import ICON_EXPANDED_HOME from "../../../assets/icon/expanded_home_icon.svg";
import ICON_EXPANDED_HOMECARE_PROGRAM from "../../../assets/icon/expanded_homecare_program_icon.svg";
import ICON_EXPANDED_IN_HOME_OUTDOOR_ACTIVITY from "../../../assets/icon/expanded_in_home_outdoor_activity_icon.svg";
import ICON_EXPANDED_MY from "../../../assets/icon/expanded_my_icon.svg";
import ICON_EXPANDED_RECORD from "../../../assets/icon/expanded_record_icon.svg";
import ICON_EXPANDED_REPORT from "../../../assets/icon/expanded_report_icon.svg";
import ICON_EXPANDED_SETTING from "../../../assets/icon/expanded_setting_icon.svg";

import ICON_FOCUSED_BODY_CHECK_UP from "../../../assets/icon/focused_body_check_up_icon.svg";
import ICON_FOCUSED_HOME from "../../../assets/icon/focused_home_icon.svg";
import ICON_FOCUSED_HOMECARE_PROGRAM from "../../../assets/icon/focused_homecare_program_icon.svg";
import ICON_FOCUSED_IN_HOME_OUTDOOR_ACTIVITY from "../../../assets/icon/focused_in_home_outdoor_activity_icon.svg";
import ICON_FOCUSED_MY from "../../../assets/icon/focused_my_icon.svg";
import ICON_FOCUSED_RECORD from "../../../assets/icon/focused_record_icon.svg";
import ICON_FOCUSED_REPORT from "../../../assets/icon/focused_report_icon.svg";
import ICON_FOCUSED_SETTING from "../../../assets/icon/focused_setting_icon.svg";

import ICON_SELECTED_BODY_CHECK_UP from "../../../assets/icon/selected_body_check_up_icon.svg";
import ICON_SELECTED_HOME from "../../../assets/icon/selected_home_icon.svg";
import ICON_SELECTED_HOMECARE_PROGRAM from "../../../assets/icon/selected_homecare_program_icon.svg";
import ICON_SELECTED_IN_HOME_OUTDOOR_ACTIVITY from "../../../assets/icon/selected_in_home_outdoor_activity_icon.svg";
import ICON_SELECTED_MY from "../../../assets/icon/selected_my_icon.svg";
import ICON_SELECTED_RECORD from "../../../assets/icon/selected_record_icon.svg";
import ICON_SELECTED_REPORT from "../../../assets/icon/selected_report_icon.svg";
import ICON_SELECTED_SETTING from "../../../assets/icon/selected_setting_icon.svg";

import HomeIcon from "./iconComponents/HomeIcon";
import BodyCheckUpIcon from "./iconComponents/BodyCheckUpIcon";
import HomecareProgramIcon from "./iconComponents/HomecareProgramIcon";
import InHomeOutdoorActivityIcon from "./iconComponents/InHomeOutdoorActivityIcon";
import MyIcon from "./iconComponents/MyIcon";
import RecordIcon from "./iconComponents/RecordIcon";
import ReportIcon from "./iconComponents/ReportIcon";
import SettingIcon from "./iconComponents/SettingIcon";
import { sendBroadCast } from "../../features/common/commonSlice";
import * as bodyAlignmentSlice from "../../features/bodyAlignment/bodyAlignmentSlice";
import * as bodyBalanceSlice from "../../features/bodyBalance/bodyBalanceSlice";
import * as physicalTestSlice from "../../features/physicalTest/physicalTestSlice";
import * as romSlice from "../../features/rom/romSlice";

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);
const MainContainer = SpotlightContainerDecorator(
  { enterTo: "last-focused", continue5WayHold: true },
  "div"
);
const CancelableDiv = Cancelable(
  { modal: true, onCancel: "handleCancel" },
  Skinnable(Container)
);

class TabMenuItem {
  constructor(icons = "", title = "", target, children = []) {
    this.icons = icons;
    this.title = title;
    this.target = target;
    this.children = []; //TabMenuItem
    if (children && children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        const tabmenu = new TabMenuItem(
          children[i].icons,
          children[i].title,
          children[i].target,
          children[i].children
        );
        this.children.push(tabmenu);
      }
    }
  }
  hasChildren = () => {
    return this.children.length > 0;
  };
  getChildren = () => {
    return this.children;
  };
}

const COLLABSED_MAIN = 0;
const ACTIVATED_MAIN = 1;
const ACTIVATED_SUB = 2;
const EXTRA_AREA = 3;

const PANELS_HAS_TAB = [
  panel_names.BODY_CHECK_UP_MAIN,
  panel_names.DEBUG_PANEL,
  panel_names.HOMECARE_PROGRAM_MAIN,
  panel_names.BODY_ALIGNMENT_REPORT,
  panel_names.ROM_REPORT,
  panel_names.BODY_BALANCE_REPORT,
  panel_names.PHYSICAL_TEST_GATE,
  panel_names.PHYSICAL_TEST_REPORT,
  panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN,
  panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN,
  panel_names.HOMECARE_WORKOUT_OPTIONS,
  panel_names.HOMECARE_CENTER_INFO,
  panel_names.HOMECARE_RECOMMEND_WORKOUT,
  panel_names.HOMECARE_ROUTINE_PROGRAM,
  panel_names.MY_PANEL,
  panel_names.REPORT_PANEL,
  panel_names.FITNESS_MANAGER,
  panel_names.SETTING_PANEL,
  panel_names.MEASURMENT_DATE,
  panel_names.SHARE_METHOD,
];

const deActivateTabJobFunc = (func) => {
  func();
};

let deActivateTabJob = new Job(deActivateTabJobFunc, 2000);

const clearPanelSwitching = (ref) => {
  if (ref) {
    ref.current = false;
  }
};
let panelSwitchingJob = new Job(clearPanelSwitching, 500);

const TabLayout = ({ topPanelName, onTabActivated }) => {
  const dispatch = useDispatch();
  const { cursorVisible, themeMode } = useSelector(
    (state) => state.common.appStatus
  );
  const panels = useSelector(state => state.panels.panels);
  const [tabs, setTabs] = useState([]);
  const [tabFocused, setTabFocused] = useState([false, false, false]); //COLLABSED_MAIN, ACTIVATED_MAIN, ACTIVATED_SUB
  const [mainExpanded, setMainExpanded] = useState(false);
  const [mainSelectedIndex, setMainSelectedIndex] = useState(-1);
  const panelSwitching = useRef(null);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);

  const TEST_TAB_INFO = useMemo(() => {
    return [
      {
        icons: HomeIcon,
        title: $L("Home"),
        target: [],
      },
      {
        icons: BodyCheckUpIcon,
        title: $L("My Body Analysis"),
        target: [{ name: panel_names.BODY_CHECK_UP_MAIN }],
        // children: [
        //   {
        //     title: $L("Body Alignment"),
        //     target: [
        //       { name: panel_names.BODY_CHECK_UP_MAIN },
        //       { name: panel_names.BODY_ALIGNMENT_REPORT },
        //     ],
        //   },
        //   {
        //     title: $L("ROM"),
        //     target: [
        //       { name: panel_names.BODY_CHECK_UP_MAIN },
        //       { name: panel_names.ROM_REPORT },
        //     ],
        //   },
        //   {
        //     title: $L("Body Balance"),
        //     target: [
        //       { name: panel_names.BODY_CHECK_UP_MAIN },
        //       { name: panel_names.BODY_BALANCE_REPORT },
        //     ],
        //   },
        //   {
        //     title: $L("Physical Test"),
        //     target: [
        //       { name: panel_names.BODY_CHECK_UP_MAIN },
        //       { name: panel_names.PHYSICAL_TEST_REPORT },
        //     ],
        //   },
        // ],
      },
      {
        icons: HomecareProgramIcon,
        title: $L("My Workout Program"),
        target: [{ name: panel_names.HOMECARE_PROGRAM_MAIN }],
        // children: [
        //   {
        //     title: $L("O2O 정규프로그램"),
        //     target: [
        //       { name: panel_names.HOMECARE_PROGRAM_MAIN },
        //       { name: panel_names.HOMECARE_WORKOUT_OPTIONS },
        //     ],
        //   },
        //   {
        //     title: $L("AI 맞춤형 운동추천"),
        //     target: [
        //       { name: panel_names.HOMECARE_PROGRAM_MAIN },
        //       { name: panel_names.HOMECARE_RECOMMEND_WORKOUT },
        //     ],
        //   },
        // ],
      },
      {
        icons: InHomeOutdoorActivityIcon,
        title: $L("Outdoor Activity Indoors"),
        target: [{ name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN }],
        // children: [
        //   {
        //     title: $L("Walking / Jogging"),
        //     target: [
        //       { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
        //       {
        //         name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN,
        //       },
        //     ],
        //   },
        //   {
        //     title: $L("클라이밍"),
        //     target: [
        //       { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
        //       { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
        //     ],
        //   },
        //   {
        //     title: $L("트레이닝 코치"),
        //     target: [
        //       { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
        //       { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
        //     ],
        //   },
        //   {
        //     title: $L("골프 코칭"),
        //     target: [
        //       { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
        //       { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
        //     ],
        //   },
        // ],
      },
      {}, //divider
      {
        icons: MyIcon,
        title: $L("My"),
        target: [{ name: panel_names.MY_PANEL }],
      },
      {
        icons: RecordIcon,
        title: $L("AI FiT Manager"),
        target: [{ name: panel_names.FITNESS_MANAGER }],
      },
      {
        icons: ReportIcon,
        title: $L("FiT Report"),
        target: [{ name: panel_names.REPORT_PANEL }],
      },
      {
        icons: SettingIcon,
        title: $L("Settings"),
        target: [{ name: panel_names.SETTING_PANEL }],
      },
    ];
  }, []);

  const TAB_INFO = useMemo(() => {
    return [
      {
        icons: [
          ICON_NORMAL_HOME,
          ICON_EXPANDED_HOME,
          ICON_FOCUSED_HOME,
          ICON_SELECTED_HOME,
        ],
        title: $L("Home"),
        target: [],
      },
      {
        icons: [
          ICON_NORMAL_BODY_CHECK_UP,
          ICON_EXPANDED_BODY_CHECK_UP,
          ICON_FOCUSED_BODY_CHECK_UP,
          ICON_SELECTED_BODY_CHECK_UP,
        ],
        title: $L("My Body Analysis"),
        children: [
          {
            title: $L("Body Alignment"),
            target: [
              { name: panel_names.BODY_CHECK_UP_MAIN },
              { name: panel_names.BODY_ALIGNMENT_REPORT },
            ],
          },
          {
            title: $L("Range Of Motion"),
            target: [
              { name: panel_names.BODY_CHECK_UP_MAIN },
              { name: panel_names.ROM_REPORT },
            ],
          },
          {
            title: $L("Body Balance"),
            target: [
              { name: panel_names.BODY_CHECK_UP_MAIN },
              { name: panel_names.BODY_BALANCE_REPORT },
            ],
          },
          {
            title: $L("Physical Test"),
            target: [
              { name: panel_names.BODY_CHECK_UP_MAIN },
              { name: panel_names.PHYSICAL_TEST_REPORT },
            ],
          },
        ],
      },
      {
        icons: [
          ICON_NORMAL_HOMECARE_PROGRAM,
          ICON_EXPANDED_HOMECARE_PROGRAM,
          ICON_FOCUSED_HOMECARE_PROGRAM,
          ICON_SELECTED_HOMECARE_PROGRAM,
        ],
        title: $L("My Workout Program"),
        children: [
          {
            title: $L("O2O 정규프로그램"),
            target: [
              { name: panel_names.HOMECARE_PROGRAM_MAIN },
              { name: panel_names.HOMECARE_ROUTINE_PROGRAM },
              // { name: panel_names.HOMECARE_WORKOUT_OPTIONS },
            ],
          },
          {
            title: $L("AI 맞춤형 운동추천"),
            target: [
              { name: panel_names.HOMECARE_PROGRAM_MAIN },
              { name: panel_names.HOMECARE_RECOMMEND_WORKOUT },
            ],
          },
        ],
      },
      {
        icons: [
          ICON_NORMAL_IN_HOME_OUTDOOR_ACTIVITY,
          ICON_EXPANDED_IN_HOME_OUTDOOR_ACTIVITY,
          ICON_FOCUSED_IN_HOME_OUTDOOR_ACTIVITY,
          ICON_SELECTED_IN_HOME_OUTDOOR_ACTIVITY,
        ],
        title: $L("Outdoor Activity Indoors"),
        children: [
          {
            title: $L("Walking / Jogging"),
            target: [
              { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
              {
                name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN,
              },
            ],
          },
          {
            title: $L("Hiking"),
            target: [
              { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
              { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
            ],
          },
          {
            title: $L("Training"),
            target: [
              { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
              { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
            ],
          },
          {
            title: $L("Golf"),
            target: [
              { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
              { name: panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN },
            ],
          },
        ],
      },
      {}, //divider
      {
        icons: [
          ICON_NORMAL_MY,
          ICON_EXPANDED_MY,
          ICON_FOCUSED_MY,
          ICON_SELECTED_MY,
        ],
        title: $L("My"),
        target: [{ name: panel_names.MY_PANEL }],
      },
      {
        icons: [
          ICON_NORMAL_RECORD,
          ICON_EXPANDED_RECORD,
          ICON_FOCUSED_RECORD,
          ICON_SELECTED_RECORD,
        ],
        title: $L("AI FiT Manager"),
        target: [{ name: panel_names.FITNESS_MANAGER }],
      },
      {
        icons: [
          ICON_NORMAL_REPORT,
          ICON_EXPANDED_REPORT,
          ICON_FOCUSED_REPORT,
          ICON_SELECTED_REPORT,
        ],
        title: $L("FiT Report"),
        target: [{ name: panel_names.REPORT_PANEL }],
      },
      {
        icons: [
          ICON_NORMAL_SETTING,
          ICON_EXPANDED_SETTING,
          ICON_FOCUSED_SETTING,
          ICON_SELECTED_SETTING,
        ],
        title: $L("Settings"),
        target: [{ name: panel_names.SETTING_PANEL }],
      },
    ];
  }, []);

  const makeTabmenu = useCallback(() => {
    const t = [];

    // for (let i = 0; i < TAB_INFO.length; i++) {
    //   const tabmenu = new TabMenuItem(
    //     TAB_INFO[i].icons,
    //     TAB_INFO[i].title,
    //     TAB_INFO[i].target,
    //     TAB_INFO[i].children
    //   );
    //   t.push(tabmenu);
    // }
    for (let i = 0; i < TEST_TAB_INFO.length; i++) {
      const tabmenu = new TabMenuItem(
        TEST_TAB_INFO[i].icons,
        TEST_TAB_INFO[i].title,
        TEST_TAB_INFO[i].target,
        TEST_TAB_INFO[i].children
      );
      t.push(tabmenu);
    }

    return t;
  }, []);

  useEffect(() => {
    setTabs(makeTabmenu());
  }, []);

  const deActivateTab = useCallback(() => {
    setTabFocused([false, false, false, false]);
    setMainSelectedIndex(-1);
    setMainExpanded(false);
  }, []);

  const onTabHasFocus = useCallback(
    (type) => (ev) => {
      switch (type) {
        case COLLABSED_MAIN:
        case ACTIVATED_MAIN: {
          if (!cursorVisible) {
            //keyboard or touch only
            const parent = ev?.target?.parentNode;
            const children = parent?.childNodes;
            const index = Array.prototype.indexOf.call(children, ev?.target);
            setMainExpanded(false);
            setMainSelectedIndex(index);
          } else {
            if (!panelSwitching.current) {
              setMainExpanded(false);
            }
          }
          break;
        }
        case ACTIVATED_SUB: {
          setMainExpanded(false);
          break;
        }
        case EXTRA_AREA: {
          if (cursorVisible) {
            deActivateTabJob.start(deActivateTab);
            return;
          }
        }
      }
      setTabFocused((prevState) => {
        const prev = [...prevState];
        prev[type] = true;
        return prev;
      });
    },
    [cursorVisible, deActivateTab]
  );
  const onTabBlur = useCallback(
    (type) => (ev) => {
      switch (type) {
        case ACTIVATED_MAIN: {
          if (!cursorVisible) {
            //keyboard or touch only
            setMainExpanded(false);
          }
          break;
        }
        case ACTIVATED_SUB: {
          if (!cursorVisible) {
          }
          break;
        }
        case EXTRA_AREA: {
          if (cursorVisible) {
            deActivateTabJob.stop();
            return;
          }
        }
      }
      setTabFocused((prevState) => {
        const prev = [...prevState];
        prev[type] = false;
        return prev;
      });
    },
    [cursorVisible]
  );

  const onClickItemMain = useCallback(
    ({ index, target }) => {
      if(cesShowMode && index === 0){
        // home 클릭 -> 리덕스 초기화 (cesMode)
        console.log('onClickItemMain index 0', index, target);
        dispatch(bodyAlignmentSlice.clearTestResults());
        dispatch(bodyBalanceSlice.clearTestResults());
        dispatch(physicalTestSlice.clearTestResults());
        dispatch(romSlice.clearTestResults());
      }
      setMainSelectedIndex(index);
      if (target) {
        if (cesShowMode && [1, 3].includes(index)) {
          dispatch(sendBroadCast({ type: "cesTermsAgree", moreInfo: { target } }));
        } else {
          dispatch(resetPanels(target));
          deActivateTab();
          panelSwitching.current = true;
          panelSwitchingJob.start(panelSwitching);
          dispatch(sendBroadCast({type: "gnbButtonClicked", moreInfo:{target}}));
        }
      } else if (cursorVisible) {
        setMainExpanded(false);
      }

    },
    [dispatch, cursorVisible, deActivateTab]
  );

  const onClickSubItem = useCallback(
    ({ index, target }) => {
      if (target) {
        dispatch(resetPanels(target));
        deActivateTab();
        panelSwitching.current = true;
        panelSwitchingJob.start(panelSwitching);
      }
    },
    [dispatch, deActivateTab]
  );

  const onClickExtraArea = useCallback(
    ({ index, target }) => {
      deActivateTabJob.startAfter(100, deActivateTab);
    },
    [dispatch, deActivateTab]
  );

  const tabActivated = useMemo(() => {
    // return mainExpanded || mainSelectedIndex >= 0;
    return false;
  }, [mainExpanded, mainSelectedIndex]);

  const showTab = useMemo(() => {
    if (!topPanelName || PANELS_HAS_TAB.indexOf(topPanelName) >= 0) {
      return true;
    }
    return false;
  }, [topPanelName]);
  const showSubTab = useMemo(() => {
    if (
      tabActivated &&
      tabs[mainSelectedIndex] &&
      tabs[mainSelectedIndex].hasChildren()
    ) {
      return true;
    }
    return false;
  }, [tabActivated, tabs, mainSelectedIndex]);
  const backKeyHandler = useCallback(
    (ev) => {
      if (tabActivated) {
        deActivateTab();
        ev?.stopPropagation();
        ev?.preventDefault();
        return true;
      }
    },
    [tabActivated, deActivateTab]
  );

  useEffect(() => {
    if (tabActivated) {
      setTimeout(() => {
        Spotlight.focus("activatedMain");
      }, 0);
    }
    if (onTabActivated) {
      onTabActivated(tabActivated && showTab);
    }
  }, [tabActivated, showTab]);

  useEffect(() => {
    // if(showSubTab){
    //   setTimeout(() => {
    //     const sub = Spotlight.focus('activatedSub');
    //   }, 0);
    // }
  }, [showSubTab, mainSelectedIndex]);

  if (!showTab) {
    return null;
  }
  return (
    <>
      {/* collabsed Main */}
      <Container
        className={classNames(
          css.tabWrap,
          themeMode === "dark" && css.isDark,
          themeMode === "transparent" && css.isTransparent
        )}
        onFocus={onTabHasFocus(COLLABSED_MAIN)}
        onMouseOver={onTabHasFocus(COLLABSED_MAIN)}
        onBlur={onTabBlur(COLLABSED_MAIN)}
        onMouseLeave={onTabBlur(COLLABSED_MAIN)}
      >
        {tabs.map((item, index) => {
          return (
            <TabItem
              key={"tabitem" + index}
              {...item}
              expanded={false}
              // selected={mainSelectedIndex === index}
              selected={(panels.length === 0 && index === 0) || (Array.isArray(item.target) && item.target[0]?.name && panels[0]?.name === item.target[0].name)}
              index={index}
              isMainItem={true}
              onClick={onClickItemMain}
            />
          );
        })}
      </Container>
      {
        <CancelableDiv
          spotlightId="activatedMain"
          className={classNames(
            css.expandedRootContainer,
            !tabActivated && css.hide
          )}
          spotlightRestrict="self-only"
          handleCancel={backKeyHandler}
        >
          {/* expanded Main */}
          {/* <MainContainer
            className={classNames(
              css.tabWrap,
              mainExpanded && css.expanded,
              themeMode === "dark" && css.isDark,
              themeMode === "transparent" && css.isTransparent
            )}
            onFocus={onTabHasFocus(ACTIVATED_MAIN)}
            onMouseOver={onTabHasFocus(COLLABSED_MAIN)}
            onBlur={onTabBlur(ACTIVATED_MAIN)}
            onMouseLeave={onTabBlur(ACTIVATED_MAIN)}
          >
            {tabActivated &&
              tabs.map((item, index) => {
                return (
                  <TabItem
                    key={"tabitemExpanded" + index}
                    {...item}
                    expanded={mainExpanded}
                    // selected={mainSelectedIndex === index}
                    selected={(panels.length === 0 && index === 0) || (Array.isArray(item.target) && item.target[0]?.name && panels[0]?.name === item.target[0].name)}
                    index={index}
                    onClick={onClickItemMain}
                    deActivateTab={deActivateTab}
                  />
                );
              })}
          </MainContainer> */}
          {/* Sub */}
          {
            <Container
              spotlightId="activatedSub"
              className={classNames(
                css.tabWrap,
                css.secondDepthLayout,
                !showSubTab && css.hide,
                themeMode === "dark" && css.isDark
              )}
              onFocus={onTabHasFocus(ACTIVATED_SUB)}
              onMouseOver={onTabHasFocus(ACTIVATED_SUB)}
              onBlur={onTabBlur(ACTIVATED_SUB)}
              onMouseLeave={onTabBlur(ACTIVATED_SUB)}
            >
              {showSubTab &&
                tabs[mainSelectedIndex].children.map((item, index) => {
                  return (
                    <TabItem
                      key={"tabitemSubmenu" + index}
                      onClick={onClickSubItem}
                      {...item}
                      expanded={true}
                      index={index}
                      isSubItem={true}
                      mainExpanded={mainExpanded}
                      deActivateTab={deActivateTab}
                    />
                  );
                })}
            </Container>
          }
          {/* Extra Area */}
          {tabActivated && (
            <Container
              className={classNames(css.tabWrap, css.extraArea)}
              onClick={onClickExtraArea}
              onFocus={onTabHasFocus(EXTRA_AREA)}
              onMouseOver={onTabHasFocus(EXTRA_AREA)}
              onBlur={onTabBlur(EXTRA_AREA)}
              onMouseLeave={onTabBlur(EXTRA_AREA)}
            />
          )}
        </CancelableDiv>
      }
    </>
  );
};

export default TabLayout;
