import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $L, dateDiff, transTimeSecToText } from "../utils/helperMethods";
import { postActivityStrollLike } from "../features/fitService/fitServiceSlice";
import useUserCheck from "./useUserCheck";

const useContent = ({ type='', subType='', selectList=[], mode='' }) => {
  const dispatch = useDispatch();
  const content = useSelector(state => state.content);
  const { likeList, continueList, recommendList } = useSelector(state => state.activity);
  const { progressInfos } = useSelector(state => state.activityWalking);
  const { userCheck } = useUserCheck();

  const contentLists = useMemo(() => {
    const lists = content[`${type}Lists`] || {};
    return lists[subType === 'rom' ? 'ROM' : subType] || {};
  }, [content, subType]);

  const themeList = useMemo(() => {
    return (content.themeList && content.themeList[subType]) || [];
  }, [content]);

  const likeIdList = useMemo(() => {
    return (likeList[subType] && likeList[subType].map(el => el.contentId)) || [];
  }, [likeList]);

  const getContentInfo = useCallback((content) => {
    if(content) {
      const item = {
        type,
        subType: content.subType || subType,
        contentId: content.contentId,
        title: content.title,
        thumbnailUrl: (mode === 'landing' && content.thumbnailUrl2) ? content.thumbnailUrl2 : content.thumbnailUrl,
        playTime: transTimeSecToText(content.playTime, true),
        icons: {}
      }

      if (content.createdate || content.creatdate) {
        item.icons.isNew = dateDiff(content.createdate || content.creatdate) >= -7;
      }

      if (type === 'activity') {
        item.dataType = 'hiking';
        item.description = content.description;
        item.distance = content.activity?.distance || content.distance || 0;
        item.progressDistance = content.history?.progressDistance || 0;
        item.trackType = content.trackType || content.activity?.trackType || '';
        item.calories = `${content.activity?.calories || 0}kcal`;

        if (userCheck) {
          item.icons.isLike = likeIdList?.includes(content.contentId) || false;
        }

        if (progressInfos[item.contentId] !== undefined && progressInfos[item.contentId].progressDistance) {
          item.progressDistance = progressInfos[item.contentId].progressDistance;
        }

        if (item.progressDistance) {
          item.currentPlayTime = transTimeSecToText(Math.round(item.progressDistance / item.distance * content.playTime), true);
        }

        item.title = $L(item.title);
      } else if (type === 'recommend') {
        item.dataType = 'workout';
        item.category = content.metadata?.workoutDetail;
        item.calories = `${content.metadata?.calories}kcal`;
        item.level = content.metadata?.level;
        item.playCount = 2;
        if (content.score) item.score = content.score;
      }

      return item;
    } else return {};
  }, [likeIdList, progressInfos, userCheck]);

  const curLikeList = useMemo(() => {
    return likeList[subType]?.map(el => getContentInfo(el)) || [];
  }, [likeList, getContentInfo]);

  const curContinueList = useMemo(() => {
    return continueList[subType]?.map(el => getContentInfo(Object.assign({}, el.content, { history: el.history }))) || [];
  }, [continueList, getContentInfo]);

  const curRecommendList = useMemo(() => {
    return recommendList[subType]?.map(el => getContentInfo(Object.assign({}, el.content, { history: el.history }))) || [];
  }, [recommendList, getContentInfo]);

  const contentList = useMemo(() => {
    const newContentList = {};

    Object.keys(contentLists).forEach(category => {
      const items = [];

      if (contentLists[category] && Array.isArray(contentLists[category]) && contentLists[category].length > 0) {
        for (let i = 0; i < contentLists[category].length; i++) {
          const content = contentLists[category][i];
          if (content) items.push(getContentInfo(content));
        }
      }

      newContentList[category] = items;
    });

    return newContentList;
  }, [contentLists, getContentInfo]);

  // todo api 구조 따라서 변경 예정
  const selectContentList = useMemo(() => {
    const arr = [];
    if(selectList.length > 0 && content?.contentInfos) {
      if(mode === 'routine') {
        // aiList [{ contentList: [] }]
        selectList.forEach(el => {
          const contentIds = el.contentList;
          const items = [];

          contentIds.forEach(id => {
            const contentInfo = content.contentInfos?.[id];
            items.push(getContentInfo(contentInfo));
          });

          arr.push(items);
        });
      } else if (mode === 'landing') {
        selectList.forEach(el => {
          const contentInfo = content.contentInfos?.[el];
          arr.push(getContentInfo(contentInfo));
        });
      } else {
        // weeklySolution [{ }]
        selectList.forEach(el => {
          const contentArr = el.contentList;
          const items = [];

          contentArr.forEach(obj => {
            const contentInfo = content.contentInfos?.[obj?.contentId];
            items.push(getContentInfo(contentInfo));
          });

          arr.push(items);
        });
      }
    }
    return arr;
  }, [content, selectList]);

  const addLike = useCallback((contentId) => {
    dispatch(postActivityStrollLike({ type: subType, contentId, likeYn: true }));
  }, [dispatch]);

  const removeLike = useCallback((contentId) => {
    dispatch(postActivityStrollLike({ type: subType, contentId, likeYn: false }));
  }, [dispatch]);

  return { contentList, selectContentList, themeList, likeIdList, likeList: curLikeList, continueList: curContinueList, recommendList: curRecommendList, addLike, removeLike, getContentInfo };
};

export default useContent;
