import React, { useMemo, useCallback, useState, useRef } from "react";
import classNames from "classnames";
import css from "./PhysicalTestItem.module.less";

import * as Config from "../../../utils/Config";
import * as SoundEffect from "../../../utils/SoundEffect";
import { $L } from "../../../utils/helperMethods";

import { Job } from "@enact/core/util";
import Spottable from '@enact/spotlight/Spottable';

import PHYSICAL_LIST_IMAGE_01 from "../../../../assets/landingPage/physicalTestList/img_module_lowerstrength.png"
import PHYSICAL_LIST_IMAGE_02 from "../../../../assets/landingPage/physicalTestList/img_module_upperstrength.png"
import PHYSICAL_LIST_IMAGE_03 from "../../../../assets/landingPage/physicalTestList/img_module_lowerflexibility.png"
import PHYSICAL_LIST_IMAGE_04 from "../../../../assets/landingPage/physicalTestList/img_module_cardiorespiratoryendurance.png"
import PHYSICAL_LIST_IMAGE_05 from "../../../../assets/landingPage/physicalTestList/img_module_balance.png"
import PHYSICAL_LIST_IMAGE_06 from "../../../../assets/landingPage/physicalTestList/img_module_agility.png"

const SpottableComponent = Spottable("div");

/**
*
* @param {type} 'lowerBodyStrength', 'upperBodyStrength', 'lowerBodyFlexibility', 'endurance', 'balance', 'agility
* @returns
*/

const ListData = () => ({
  'lowerBodyStrength': {
    title: $L("Lower Body Strength"),
    image: PHYSICAL_LIST_IMAGE_01
  },
  'upperBodyStrength': {
    title: $L("Upper Body Strength"),
    image: PHYSICAL_LIST_IMAGE_02
  },
  'lowerBodyFlexibility': {
    title: $L("Lower Body Flexibility"),
    image: PHYSICAL_LIST_IMAGE_03
  },
  'endurance': {
    title: $L("Endurance"),
    image: PHYSICAL_LIST_IMAGE_04
  },
  'balance': {
    title: $L("Balance"),
    image: PHYSICAL_LIST_IMAGE_05
  },
  'agility': {
    title: $L("Agility"),
    image: PHYSICAL_LIST_IMAGE_06
  },
})

const PhysicalTestItem = ({type, onClick, onFocus, className, ...rest}) => {
  const [pressed, setPressed] = useState(false);
  
  const listData = useMemo(()=>{
    return ListData()[type];
  },[type]);

	const _onClick = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressed(true);
    clearPressedJob.current.start(() => {
      if(onClick){
        onClick(ev);
      }
    });

	}, [onClick]);

  const _onFocus = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    if (onFocus) {
      onFocus(ev);
    }
  }, [onFocus])

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        css.item,
        css[type],
        className,
        pressed && css.pressed,
        
      )}
      onClick={_onClick}
      onFocus={_onFocus}
    >
      <div className={css.title}>{listData.title}</div>
      <div className={css.image} style={{backgroundImage: `url(${listData.image})`}}/>
        
    </SpottableComponent>
  );
}

export default PhysicalTestItem;