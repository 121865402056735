
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Alert from "@enact/sandstone/Alert";
import css from './AIManagerFeedback.module.less';
import TButton, { SIZES } from '../TButton/TButton';
import { $L } from '../../utils/helperMethods';
import * as Config from '../../utils/Config';
import { addPanels, popPanel } from '../../features/panels/panelsSlice';
import TGGraph from '../TGGraph/TGGraph';
import IMG_COMPLETE_01 from "../../../assets/images/img_complete_01.svg"

const MAX_SUCCESS_COUNT = 20;

const AIManagerFeedback = ({ onClose, panelInfo }) => {
	const dispatch = useDispatch();
	const [managerAtHome, setManagerAtHome] = useState(false);

	const info = useMemo(() => {
		const obj = {};
		if (panelInfo) {
			if (panelInfo.enterFrom === 'workout' || panelInfo.enterFrom === 'bodyAlignment_summary') {
				obj.mode = 'workout';
				obj.percent = ((panelInfo.userTryCount / MAX_SUCCESS_COUNT) * 100).toFixed(0);
				if(obj.percent > 100){
					obj.percent = 100;
				}
				obj.description1 = $L("You completed");
				obj.description2 = $L("<b>{percent}%</b> of the workout mission today.").replace("{percent}", obj.percent);
				let successRate = 0;
				let successCount = panelInfo.successCount;
				if(successCount > panelInfo.userTryCount){
					successCount = panelInfo.userTryCount;
				}
				if(panelInfo.userTryCount > 0){
					successRate = (successCount/panelInfo.userTryCount)*100;
				}
				obj.detail = [
					{
						title: $L("Total trials"),
						value: panelInfo?.userTryCount
					},
					{
						title: $L("Successful trials"),
						value: successCount
					},
					{
						title: $L("Success rate"),
						value: successRate.toFixed(0)+"%"
					},
					{
						title: $L("Your Maximum Range"),
						value: `${panelInfo?.maxRange || 0}°`
					},
				];
				obj.effect = $L("If you continue this exercise for a week, it will aid the comfort of your back and the flexibility of your spine.");
			} else if (panelInfo.enterFrom === 'hiking' || panelInfo.enterFrom === 'bodyBalance_summary') {
				const reportData = panelInfo?.reportData || {};

				obj.mode = 'activity';
				obj.percent = reportData.percent ? reportData.percent: 0;
				obj.percent = obj.percent?.toFixed(0);
				obj.description1 = $L("Congratulations!");
				obj.description2 = $L("You completed <b>{percent}%</b> of today's hike!").replace("{percent}", obj.percent);
				obj.detail = [
					{
						title: $L("Total steps"),
						value: `${reportData?.totalStep || 0}`
					},
					{
						title: $L("Successful steps"),
						value: `${reportData?.successfulStep || 0}`
					},
					{
						title: $L("Success rate (Left)"),
						value: `${reportData?.successfulRateL || 0}%`
					},
					{
						title: $L("Success rate (Right)"),
						value: `${reportData?.successfulRateR || 0}%`
					},
				];
				obj.effect = $L("Well done! Keep it up and you'll strengthen your legs and improve your balance!");
			}

			if (panelInfo.enterFrom === 'bodyAlignment_summary' || panelInfo.enterFrom === 'bodyBalance_summary') {
				obj.from = 'summary';
			} else if (panelInfo.enterFrom === 'workout' || panelInfo.enterFrom === 'hiking') {
				obj.from = 'landing';
			}
		}

		return obj;
	}, [panelInfo]);

	const _onClose = useCallback(() => {
		if (onClose) {
      onClose();
    } else {
			dispatch(popPanel(Config.panel_names.PREVIEW_PANEL));
			dispatch(popPanel());
		}
	}, [onClose]);


	const onClick = useCallback(() => {
		if (info?.mode === 'workout') {
			setManagerAtHome(true);
			if (info?.from === 'landing') {
				dispatch(popPanel(Config.panel_names.PREVIEW_PANEL));
			}
		} else if (info?.mode === 'activity') {
			if (info?.from === 'landing') {
				dispatch(popPanel(Config.panel_names.PREVIEW_PANEL));
			} else {
				dispatch(popPanel(Config.panel_names.BODY_BALANCE_REPORT));
			}
			dispatch(popPanel());
		}
	}, [info]);

	const gotoAiManager = useCallback(() => {
		dispatch(popPanel());
		dispatch(popPanel(Config.panel_names.BODY_ALIGNMENT_REPORT));
		dispatch(popPanel(Config.panel_names.BODY_ALIGNMENT_SUMMARY));
		dispatch(addPanels({ name: Config.panel_names.FITNESS_MANAGER, panelInfo: { selectedTabIndex: 2 }}));
	}, []);

	// const onClick = useCallback(() => {
	// 	if (info?.from === 'landing') {
	// 		dispatch(popPanel(Config.panel_names.PREVIEW_PANEL));
	// 	} else {
	// 		if (info?.mode === 'workout') {
	// 			dispatch(popPanel(Config.panel_names.BODY_ALIGNMENT_REPORT));
	// 		} else if (info?.mode === 'activity') {
	// 			dispatch(popPanel(Config.panel_names.BODY_BALANCE_REPORT));
	// 		}
	// 	}
	// 	dispatch(popPanel());
  // }, [info]);

	return (
		<Alert
			open
			type={"fullscreen"}
			className={css.popup}
			onClose={_onClose}
		>
			<div className={css.topContainer}>
				<div className={css.goalHistory}>
					<TGGraph
						size={270}
						primaryPercentage={info.percent}
						isDark={true}
					/>
					<div className={css.goalPercentage}>
						{info.percent}
						{/* {Math.round(goalInfo.goalPercentage)} */}
						<span>%</span>
					</div>
				</div>
				<div className={css.aiContainer}>
					<div className={css.aiTitle}>
						{$L("AI FiT Manager's Briefing")}
					</div>
					<div
						className={css.aiFeedback}
						dangerouslySetInnerHTML={{
							__html: info?.description1,
						}}
					/>
					<div
						className={css.aiFeedback}
						dangerouslySetInnerHTML={{
							__html: info?.description2,
						}}
					/>
					<hr/>
					{info?.detail.length > 0 && info.detail.map((li, idx) => (
						<div key={`feedback_${idx}`} className={`${css.detail} ${idx === info.detail.length - 1 ? css.noMarginBottom : ''}`}>
							{li.title}
							<div>
								{li.value}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className={css.textContainer}>
				{info?.effect}
			</div>
			<div className={css.btnContainer}>
				<TButton size={SIZES.xLarge} onClick={onClick}>
					{$L("Next")}
				</TButton>
			</div>
			{managerAtHome &&
				<Alert
					open
					type={"fullscreen"}
					className={css.popup}
					onClose={_onClose}
				>
					<div className={css.gotoAiManager}>
						<img src={IMG_COMPLETE_01}/>
						<div className={css.text}>
							{$L("Get a sneak peek of our AI FiT Manager feature for home use!")}
						</div>
						<div className={css.btnContainer}>
							<TButton size={SIZES.xLarge} onClick={gotoAiManager}>
								{$L("Next")}
							</TButton>
						</div>
					</div>
				</Alert>
			}
		</Alert>
	);
};

export default AIManagerFeedback;
