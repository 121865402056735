import TCheckboxItem from "../../../../components/TCheckboxItem";
import TIconButton from '../../../../components/TIconButton';
const TCheckboxItemLayout = () => {
    return (
        <>
        <div style={{width: "300px"}}>
            <TCheckboxItem title={"short size checkbox item with marquee"}>
                <TIconButton iconType="rightArrow" size="small"/>
            </TCheckboxItem>
        </div>
        <TCheckboxItem title={"large size checkbox item"}></TCheckboxItem>
        <TCheckboxItem title={"large size checkbox item width Icon"}>
            <TIconButton iconType="rightArrow" size="small"/>
        </TCheckboxItem>
        <TCheckboxItem title={"large size checkbox item width Icon"}>
            <TIconButton iconType="play" size="small"/>
        </TCheckboxItem>
        <TCheckboxItem title={"large size checkbox item inline"} inline={true} selected={true}>
            <TIconButton iconType="rightArrow" size="small"/>
        </TCheckboxItem>
        </>
    );
};

export default TCheckboxItemLayout;
