import LS2Request from "./LS2Request";

const CAMERA_SERVICE = "luna://com.webos.service.camera2";

let handle = null;
export const cancelGetEventNotification = () => {
  if (handle) {
    handle.cancel();
    handle = null;
  }
};
export const getEventNotification = ({ params={}, onSuccess, onFailure, onComplete, subscribe=true }) => {
  cancelGetEventNotification();
  if (process.env.REACT_APP_MODE === "DEBUG") {
    console.log("LUNA SEND getEventNotification");
    return "Some Hard Coded Mock Data";
  } else {
    // console.log('getEventNotification.................', params);
    handle = new LS2Request().send({
      service: CAMERA_SERVICE,
      method: "getEventNotification",
      subscribe: subscribe,
      parameters: params,
      onSuccess,
      onFailure,
      onComplete,
    });
    return handle;
  }
};

//Enable Pose Detection
export const setSolutions = ({params={}, onSuccess, onFailure, onComplete }) => {
  if (process.env.REACT_APP_MODE === "DEBUG") {
    console.log("LUNA SEND setSolutions");
    return "Some Hard Coded Mock Data";
  } else {
    return new LS2Request().send({
      service: CAMERA_SERVICE,
      method: "setSolutions",
      subscribe: false,
      parameters: params,
      onSuccess,
      onFailure,
      onComplete,
    });
  }
};

//setCameraPermission
// export const setCameraPermission = ({ onSuccess, onFailure, onComplete }) => {
//   cancelGetEventNotification();
//   if (process.env.REACT_APP_MODE === "DEBUG") {
//     console.log("LUNA SEND disablePoseDetection");
//     return "Some Hard Coded Mock Data";
//   } else {
//     return new LS2Request().send({
//       service: CAMERA_SERVICE,
//       method: "setPermission",
//       subscribe: false,
//       parameters: {
//         appId: "com.twin.app.fittv",
//       },
//       onSuccess,
//       onFailure,
//       onComplete,
//     });
//   }
// };

export const gestureInitialize = (id, { onSuccess, onFailure, onComplete }) => {
  if (typeof window === "object" && window.PalmSystem) {
    return new LS2Request().send({
      service: 'luna://com.webos.service.gesture',
      method: "initialize",
      subscribe: false,
      parameters: { id },
      onSuccess,
      onFailure,
      onComplete,
    });
  }else{
    setTimeout(() => {
      const res = {};
      onSuccess(res);
      onComplete && onComplete();
    }, 100);
  }
};

export const gestureRelease = ({ onSuccess, onFailure, onComplete }) => {
  if (typeof window === "object" && window.PalmSystem) {
    return new LS2Request().send({
      service: 'luna://com.webos.service.gesture',
      method: "release",
      subscribe: false,
      parameters: {},
      onSuccess,
      onFailure,
      onComplete,
    });
  } else {
    setTimeout(() => {
      const res = {};
      onSuccess(res);
      onComplete && onComplete();
    }, 100);
  }
};