import { $L } from "../../utils/helperMethods";

export const MENUTYPE = {
  // bodyAlignment
  bodyAlignment: {
    OVERALL: "OVERALL",
    HEAD_SHIFT: "HEAD_SHIFT",
    LEG_ALIGNMENT: "LEG_ALIGNMENT",
    PELVIC_TILT: "PELVIC_TILT",
    SHOULDER_TILT: "SHOULDER_TILT",
    PELVIC_ROTATION: "PELVIC_ROTATION",
    NECK_TILT: "NECK_TILT",
    TRUNK_SHIFT: "TRUNK_SHIFT",
    PELVIC_SHIFT: "PELVIC_SHIFT",
    KNEE_FLEXION: "KNEE_FLEXION",
  },

  // ROM
  ROM_main: {
    OVERALL: "OVERALL",
    NECK: "NECK",
    SHOULDER: "SHOULDER",
    TRUNK: "TRUNK",
    HIP: "HIP",
    KNEE: "KNEE",
  },

  ROM: {
    OVERALL: "OVERALL",
    //neck
    NECK_LATERAL_FLEXION: "NECK_LATERAL_FLEXION",
    NECK_FLEXION_EXTENSION: "NECK_FLEXION_EXTENSION",
    //shoulder
    SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT: "SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT",
    SHOULDER_FLEXION_EXTENSION_RIGHT: "SHOULDER_FLEXION_EXTENSION_RIGHT",
    SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT: "SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT",
    SHOULDER_FLEXION_EXTENSION_LEFT: "SHOULDER_FLEXION_EXTENSION_LEFT",
    SHOULDER_ABDUCTION: "SHOULDER_ABDUCTION",
    //trunk
    TRUNK_LATERAL_FLEXION: "TRUNK_LATERAL_FLEXION",
    TRUNK_FLEXION_EXTENSION: "TRUNK_FLEXION_EXTENSION",
    //hip
    HIP_FLEXION_EXTENSION_RIGHT: "HIP_FLEXION_EXTENSION_RIGHT",
    HIP_FLEXION_EXTENSION_LEFT: "HIP_FLEXION_EXTENSION_LEFT",
    //knee
    KNEE_FLEXION_LEFT: "KNEE_FLEXION_LEFT",
    KNEE_FLEXION_RIGHT: "KNEE_FLEXION_RIGHT",
  },

  // bodyBalance
  bodyBalance: {
    FOOT_TYPE: "FOOT_TYPE",
    BODY_BALANCE_TAB: "BODY_BALANCE_TAB",
    COP: "COP",
    // WALKING_PATH: "WALKING_PATH",
    // WALK_IN_PLACE: "WALK_IN_PLACE",
  },

  // physicalTest
  physicalTest: {
    LOWER_BODY_STR: "LOWER_BODY_STR",
    UPPER_BODY_STR: "UPPER_BODY_STR",
    AEROBIC: "AEROBIC",
    LOWER_BODY_FLEX: "LOWER_BODY_FLEX",
    STATIC_BALANCE: "STATIC_BALANCE",
    UP_AND_GO_TEST: "UP_AND_GO_TEST",
    //todo
    // ONE_LEGGED_STAND: "ONE_LEGGED_STAND",
    // POWER_WALKING: "POWER_WALKING"
  },
};

export const getTabMenuTitle = () => ({
  // bodyAlignment
  bodyAlignment: {
    [MENUTYPE.bodyAlignment.OVERALL]: $L("Overall"),
    [MENUTYPE.bodyAlignment.HEAD_SHIFT]: $L("Head Shift"),
    [MENUTYPE.bodyAlignment.LEG_ALIGNMENT]: $L("Leg Alignment"),
    [MENUTYPE.bodyAlignment.PELVIC_TILT]: $L("Pelvic Tilt"),
    [MENUTYPE.bodyAlignment.SHOULDER_TILT]: $L("Shoulder Tilt"),
    [MENUTYPE.bodyAlignment.PELVIC_ROTATION]: $L("Pelvic Rotation"),
    [MENUTYPE.bodyAlignment.NECK_TILT]: $L("Neck Tilt"),
    [MENUTYPE.bodyAlignment.TRUNK_SHIFT]: $L("Shoulder Shift"),
    [MENUTYPE.bodyAlignment.PELVIC_SHIFT]: $L("Pelvic Shift"),
    [MENUTYPE.bodyAlignment.KNEE_FLEXION]: $L("Knee Flexion/Extension"),
  },

  // ROM
  ROM_main: {
    [MENUTYPE.ROM_main.OVERALL]: $L("Overall"),
    [MENUTYPE.ROM_main.NECK]: $L("Neck"),
    [MENUTYPE.ROM_main.SHOULDER]: $L("Shoulder"),
    [MENUTYPE.ROM_main.TRUNK]: $L("Trunk"),
    [MENUTYPE.ROM_main.HIP]: $L("Hip"),
    [MENUTYPE.ROM_main.KNEE]: $L("Knee"),
  },

  ROM: {
    [MENUTYPE.ROM.OVERALL]: $L("Overall"),
    //neck
    [MENUTYPE.ROM.NECK_LATERAL_FLEXION]: $L("Neck Lateral Flexion"),
    [MENUTYPE.ROM.NECK_FLEXION_EXTENSION]: $L("Neck Flexion") + " / " + $L("Neck Extension"),
    //shoulder
    [MENUTYPE.ROM.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT]: $L("Shoulder External Rotation") + " / " + $L("Shoulder Internal Rotation") + " (" + $L("Right") + ")",
    [MENUTYPE.ROM.SHOULDER_FLEXION_EXTENSION_RIGHT]: $L("Shoulder Flexion") + " / " + $L("Shoulder Extension") + " (" + $L("Right") + ")",
    [MENUTYPE.ROM.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT]: $L("Shoulder External Rotation") + " / " + $L("Shoulder Internal Rotation") + " (" + $L("Left") + ")",
    [MENUTYPE.ROM.SHOULDER_FLEXION_EXTENSION_LEFT]: $L("Shoulder Flexion") + " / " + $L("Shoulder Extension") + " (" + $L("Left") + ")",
    [MENUTYPE.ROM.SHOULDER_ABDUCTION]: $L("Shoulder Abduction") + " (" + $L("Left") + "/" + $L("Right") + ")",
    //trunk
    [MENUTYPE.ROM.TRUNK_LATERAL_FLEXION]: $L("Trunk Left/Right Lateral Flexion"),
    [MENUTYPE.ROM.TRUNK_FLEXION_EXTENSION]: $L("Trunk Flexion") + " / " + $L("Trunk Extension"),
    //hip
    [MENUTYPE.ROM.HIP_FLEXION_EXTENSION_RIGHT]: $L("Hip Flexion") + " / " + $L("Hip Extension") + " (" + $L("Right") + ")",
    [MENUTYPE.ROM.HIP_FLEXION_EXTENSION_LEFT]: $L("Hip Flexion") + " / " + $L("Hip Extension") + " (" + $L("Left") + ")",
    //knee
    [MENUTYPE.ROM.KNEE_FLEXION_LEFT]: $L("Knee Flexion") + " (" + $L("Left") + ")",
    [MENUTYPE.ROM.KNEE_FLEXION_RIGHT]: $L("Knee Flexion") + " (" + $L("Right") + ")",
  },

  // bodyBalance
  bodyBalance: {
    [MENUTYPE.bodyBalance.FOOT_TYPE]: $L("Foot Shape"),
    [MENUTYPE.bodyBalance.BODY_BALANCE_TAB]: $L("Two-Leg Balance"),
    [MENUTYPE.bodyBalance.COP]: $L("One-Leg Balance"),
    // [MENUTYPE.bodyBalance.WALKING_PATH]: $L("Gait"),
    // [MENUTYPE.bodyBalance.WALK_IN_PLACE]: $L("Walk in place"),
  },

  // physicalTest
  physicalTest: {
    [MENUTYPE.physicalTest.LOWER_BODY_STR]: $L("Muscle Strength Of The Lower Extremities"),
    [MENUTYPE.physicalTest.UPPER_BODY_STR]: $L("Upper Limb Muscle Strength"),
    [MENUTYPE.physicalTest.AEROBIC]: $L("Upper Extremity Flexibility"),
    [MENUTYPE.physicalTest.LOWER_BODY_FLEX]: $L("Flexibility Of The Lower Extremities"),
    [MENUTYPE.physicalTest.STATIC_BALANCE]: $L("Agility And Motion Balance"),
    [MENUTYPE.physicalTest.UP_AND_GO_TEST]: $L("Cardiopulmonary Endurance"),
    // [MENUTYPE.physicalTest.ONE_LEGGED_STAND]: $L("One Legged Stand"),
    // [MENUTYPE.physicalTest.POWER_WALKING]: $L("Power Walking")
  },
});

export const TAB_DETAIL = {
  // bodyAlignment
  bodyAlignment: {
    [MENUTYPE.bodyAlignment.HEAD_SHIFT]: {
      mainkey: "side",
      testkey: "head_shift",
      type: "upperbody",
      bodyPart: "head",
    },
    [MENUTYPE.bodyAlignment.LEG_ALIGNMENT]: {
      mainkey: "front",
      testkey: "leg_alignment_left",
      testkey2: "leg_alignment_right",
      type: "lowerbody",
      bodyPart: "leg",
    },
    [MENUTYPE.bodyAlignment.PELVIC_TILT]: {
      mainkey: "front",
      testkey: "pelvic_tilt",
      type: "body",
      bodyPart: "pelvic",
    },
    [MENUTYPE.bodyAlignment.SHOULDER_TILT]: {
      mainkey: "front",
      testkey: "shoulder_tilt",
      type: "upperbody",
      bodyPart: "shoulder",
    },
    [MENUTYPE.bodyAlignment.PELVIC_ROTATION]: {
      mainkey: "front",
      testkey: "pelvic_rotation",
      type: "body",
      bodyPart: "pelvic",
    },
    [MENUTYPE.bodyAlignment.NECK_TILT]: {
      mainkey: "front",
      testkey: "neck_tilt",
      type: "upperbody",
      bodyPart: "neck",
    },
    [MENUTYPE.bodyAlignment.TRUNK_SHIFT]: {
      mainkey: "side",
      testkey: "trunk_shift",
      type: "trunkbody",
      bodyPart: "shoulder",
    },
    [MENUTYPE.bodyAlignment.PELVIC_SHIFT]: {
      mainkey: "side",
      testkey: "pelvic_shift",
      type: "body",
      bodyPart: "pelvic",
    },
    [MENUTYPE.bodyAlignment.KNEE_FLEXION]: {
      mainkey: "side",
      testkey: "knee_flexion",
      type: "lowerbody",
      bodyPart: "leg",
    },
  },

  // ROM  // ROM_HIP_HipFlexion
  ROM: {
    //neck
    [MENUTYPE.ROM.NECK_LATERAL_FLEXION]: {
      mainkey: "neck",
      testkey: "neckLateralFlexion",
      bodyPart: "neck",
    },
    [MENUTYPE.ROM.NECK_FLEXION_EXTENSION]: {
      mainkey: "neck",
      testkey: "neckFlexionExtension",
      bodyPart: "neck",
    },
    //shoulder
    [MENUTYPE.ROM.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT]: {
      mainkey: "shoulder",
      testkey: "shoulderExternalInternalRotationLeft",
      bodyPart: "shoulder",
    },
    [MENUTYPE.ROM.SHOULDER_FLEXION_EXTENSION_RIGHT]: {
      mainkey: "shoulder",
      testkey: "shoulderFlexionExtensionRight",
      bodyPart: "shoulder",
    },
    [MENUTYPE.ROM.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT]: {
      mainkey: "shoulder",
      testkey: "shoulderExternalInternalRotationLeft",
      bodyPart: "shoulder",
    },
    [MENUTYPE.ROM.SHOULDER_FLEXION_EXTENSION_LEFT]: {
      mainkey: "shoulder",
      testkey: "shoulderFlexionExtensionLeft",
      bodyPart: "shoulder",
    },
    [MENUTYPE.ROM.SHOULDER_ABDUCTION]: {
      mainkey: "shoulder",
      testkey: "shoulderAbduction",
      bodyPart: "shoulder",
    },
    //trunk
    [MENUTYPE.ROM.TRUNK_LATERAL_FLEXION]: {
      mainkey: "trunk",
      testkey: "trunkLateralFlexion",
      bodyPart: "trunk",
    },
    [MENUTYPE.ROM.TRUNK_FLEXION_EXTENSION]: {
      mainkey: "trunk",
      testkey: "trunkFlexionExtensionRight",
      bodyPart: "trunk",
    },
    //hip
    [MENUTYPE.ROM.HIP_FLEXION_EXTENSION_RIGHT]: {
      mainkey: "hip",
      testkey: "hipFlexionExtensionRight",
      bodyPart: "hip",
    },
    [MENUTYPE.ROM.HIP_FLEXION_EXTENSION_LEFT]: {
      mainkey: "hip",
      testkey: "hipFlexionExtensionLeft",
      bodyPart: "hip",
    },
    //knee
    [MENUTYPE.ROM.KNEE_FLEXION_LEFT]: {
      mainkey: "knee",
      testkey: "kneeFlexionLeft",
      bodyPart: "knee",
    },
    [MENUTYPE.ROM.KNEE_FLEXION_RIGHT]: {
      mainkey: "knee",
      testkey: "kneeFlexionRight",
      bodyPart: "knee",
    },
  },

  // bodyBalance
  bodyBalance: {
    [MENUTYPE.bodyBalance.FOOT_TYPE]: {
      mainkey: "twolegstance",
      testkey: "archIndex_L",
      testkey2: "archIndex_R"
      // archIndex_L: 족부형상 왼발, archIndex_R: 족부형상 오른발
    },
    [MENUTYPE.bodyBalance.BODY_BALANCE_TAB]: {
      mainkey: "twolegstance",
      testkey: "balanceRatio_FFRF",
      testkey2: "balanceRatio_LR"
      // balanceRatio_FFRF: 양발균형 y축(앞뒤), balanceRatio_LR: 양발균형 x축(좌우)
    },
    [MENUTYPE.bodyBalance.COP]: {
      mainkey: "onelegstanceR", // onelegstanceL 삭제예정
      testkey: "leftBalance",
      testkey2: "rightBalance"
      // leftBalance: 외발서기 왼발, rightBalance: 외발서기 오른발
    },
    // [MENUTYPE.bodyBalance.WALKING_PATH]: {
    //   testkey: "walking"
    // },
    // [MENUTYPE.bodyBalance.WALK_IN_PLACE]: {
    //   testkey: "walking"
    // },
  },

  // physicalTest
  physicalTest: {
    [MENUTYPE.physicalTest.LOWER_BODY_STR]: {
      testkey: "lowerStrength"
    },
    [MENUTYPE.physicalTest.UPPER_BODY_STR]: {
      testkey: "upperStrength"
    },
    [MENUTYPE.physicalTest.AEROBIC]: {
      testkey: "stepInPlace"
    },
    [MENUTYPE.physicalTest.LOWER_BODY_FLEX]: {
      testkey: "lowerFlexibility"
    },
    [MENUTYPE.physicalTest.STATIC_BALANCE]: {
      testkey: "balance"
    },
    [MENUTYPE.physicalTest.UP_AND_GO_TEST]: {
      testkey: "upandgo"
    },
    // [MENUTYPE.physicalTest.ONE_LEGGED_STAND]: {
    //   testkey: "onlegstand"
    // },
    // [MENUTYPE.physicalTest.POWER_WALKING]: {
    //   testkey: "powerwalking"
    // }
  },
};

export const SUBTYPE_TITLE = {
  bodyAlignment: $L("Body Alignment"),
  ROM: $L("Range Of Motion"),
  bodyBalance: $L("Body Balance"),
  physicalTest: $L("Physical Test")
}
