import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Job } from "@enact/core/util";
import { useDispatch, useSelector } from 'react-redux';
import css from "./SelectBgmPanel.module.less";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TButton, {ICONS} from "../../../components/TButton";
import TBody from "../../../components/TBody/TBody";
import TPanel from "../../../components/TPanel/TPanel";
import THeader from "../../../components/THeader";
import TCheckboxItem from "../../../components/TCheckboxItem";
import TIconButton from "../../../components/TIconButton";
import {$L} from '../../../utils/helperMethods';
import TScroller from '../../../components/TScroller';
import { popPanel } from "../../../features/panels/panelsSlice";
import TSimpleButtonTab, {LIST_TYPE} from "../../../components/TSimpleButtonTab/TSimpleButtonTab";
import { getBgmList, postBgm, postFootprint } from "../../../features/fitService/fitServiceSlice";
const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, Spottable("div"));

const BGM_SORT=['wdate', 'text', 'time'];

let focusActionJob = new Job((func, ...arg) => {
	func(...arg);
}, 100);

const SelectBgmPanel = ({panelInfo={}}) => {
	const dispatch = useDispatch();
	const {bgmList, music:settingsMusic, footprintList, sound, soundpath} = useSelector(state => state.fitService.settings);
	const [checked, setChecked] = useState([]);//seq list
	const [selectedSortIndex, setSelectedSortIndex] = useState(0);
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const [isPlaying, setIsPlaying] = useState(false);

	console.log('panelInfo ', panelInfo);
	useEffect(()=>{
		if(panelInfo.type === 'bgm'){
			dispatch(getBgmList({sort: BGM_SORT[selectedSortIndex]}));
		}
	},[dispatch, selectedSortIndex]);

	useEffect(()=>{
		const list=[];
		if(panelInfo.type === 'bgm'){
			if(settingsMusic){
				settingsMusic.forEach((m)=>{
					list.push(m.seq);
				});
			}
		}else{
			list.push(Number(sound));
		}
		setChecked(list);
	},[panelInfo, settingsMusic, sound]);

	const soundList = useMemo(()=>{
		let list = [];
		if(panelInfo.type === 'bgm'){
			list = bgmList;
		}else{
			list = footprintList;
		}
		return list;
	},[panelInfo, bgmList, footprintList]);

	const onSortOptionClick = useCallback(({index, ...rest})=>{
		setSelectedSortIndex(index);
	},[]);

	const onToggle = useCallback((index) => () => {
		if(index === 'all'){
			const newArray = [];
			if(soundList.length !== checked.length){
				soundList.forEach((data)=>{
					newArray.push(data.seq);
				});
			}
			setChecked(newArray);
		}else{
			const newArray = [...checked];
			const selectedBgm = soundList[index];
			if(panelInfo.type === 'bgm'){
				const indexToRemove = newArray.indexOf(selectedBgm.seq);
				if(indexToRemove >=0){
					newArray.splice(indexToRemove, 1);
				}else{
					newArray.push(selectedBgm.seq);
				}
				setChecked(newArray);
			}else{
				setChecked([selectedBgm.seq]);
			}
		}
	}, [soundList, checked, panelInfo]);

	const togglePlay = useCallback((ev) => {
		setIsPlaying(!isPlaying);
		ev.stopPropagation();
	}, [isPlaying]);

	const onAudioEnded = useCallback(() => {
		setIsPlaying(false);
	}, []);

	const focusAction = useCallback((index, focused) => {
		setFocusedIndex(focused ? index:-1);
		if(!focused || focusedIndex!==index){
			setIsPlaying(false);
		}
	}, [focusedIndex]);

	const onItemFocus = useCallback((index)=>()=>{
		focusActionJob.start(focusAction, index, true);
	},[focusAction]);

	const onItemBlur = useCallback((index)=>()=>{
		focusActionJob.start(focusAction, index, false);
	},[focusAction]);

	const onCancel =  useCallback(()=>{
		dispatch(popPanel());
	},[dispatch]);

	const onSave =  useCallback(()=>{
		if(panelInfo.type === 'bgm'){
			const music = [];
			soundList.forEach((m)=>{
				if(checked.indexOf(m.seq)>=0){
					music.push(m);
				}
			});
			dispatch(postBgm({music}));
		}else{
			dispatch(postFootprint({sound: Number(checked[0])}));
		}
		dispatch(popPanel());
	},[dispatch, soundList, checked, panelInfo]);

	return (
		<TPanel className={css.bodyAlignmentSummary}>
			<THeader title={panelInfo.type === 'bgm'?$L("재생리스트"): $L("Music list")}/>
			<TBody>
				{panelInfo.type === 'bgm' &&
					<Container className={css.flexBox}>
						<TButton className={css.selectAllBtn} icon={ICONS.bell} onClick={onToggle("all")}>{$L("Select all")}</TButton>
						<TSimpleButtonTab
							listType={LIST_TYPE.large}
							contents={[$L("From the newest"), $L("In alphabetical order"), $L("In the order played")]}
							onItemClick={onSortOptionClick}
							selectedIndex={selectedSortIndex}
						/>
					</Container>
				}
				<TScroller className={css.tscroller} focusableScrollbar={false}>
					{soundList.map((list, index)=>{
						return(
							<div key={index}>
								<TCheckboxItem
									title={list.title}
									selected={checked.indexOf(list.seq)>=0}
									onToggle={onToggle(index)}
									onFocus={onItemFocus(index)}
									onBlur={onItemBlur(index)}
								>
									{focusedIndex === index &&
										<TIconButton iconType={isPlaying ? "pause":"play"} size="small" onClick={togglePlay}/>
									}
								</TCheckboxItem>

							</div>
						);
					})}
				</TScroller>
				<Container className={css.bottomLayout}>
					<TButton onClick={onCancel}>{$L("Cancel")}</TButton>
					<TButton onClick={onSave} disabled={!checked || checked.length <=0}>{$L("Save")}</TButton>
				</Container>
				<audio src={focusedIndex >=0 && isPlaying ? soundList[focusedIndex].filepath:""} autoPlay={true} controls={false} onEnded={onAudioEnded}/>
			</TBody>
	</TPanel>
	);
}

export default SelectBgmPanel;