import React, { useEffect, useMemo, useRef } from "react";
import css from "./PTSubTabContainer.module.less";
import classnames from "classnames";
import { $L } from "../../../../utils/helperMethods";
import PTSubTabGraph from "../PTSubTabGraph/PTSubTabGraph";
import { MENUTYPE } from "../Constants";
import img_sft_agility from "../../../../../assets/physicalTest/img_sft_agility.png";
import img_sft_cardiopulmonary from "../../../../../assets/physicalTest/img_sft_cardiopulmonary.png";
import img_sft_lower from "../../../../../assets/physicalTest/img_sft_chair.png";
import img_sft_lowerflexibility from "../../../../../assets/physicalTest/img_sft_lowerflexibility.png";
import img_sft_upper from "../../../../../assets/physicalTest/img_sft_dumbell.png";
import img_sft_upperflexibility from "../../../../../assets/physicalTest/img_sft_upperflexibility.png";
import TFixBarItem, {
  TYPE_FIXBAR,
} from "../../../../components/TFixBarItem/TFixBarItem";
import { useSelector } from "react-redux";
import { SFT_DATA, USER_GENDER, getSFT_UNIT } from "./constantsSubTab";

const PTSubTabContainer = ({ physicalTestInfo, selectedDate, feedback }) => {
  const titleRef = useRef(null);
  const { sex } = useSelector((state) => state.fitService.survey);

  const convertScore = useMemo(() => {
    if (
      (physicalTestInfo.score === 0 || physicalTestInfo.score) &&
      physicalTestInfo.tabName
    ) {
      const tabName = physicalTestInfo.tabName;
      const testkey = physicalTestInfo.testkey;
      const grade = physicalTestInfo.grade;
      const value = physicalTestInfo.value;
      const userGender = sex === 0 ? USER_GENDER.MALE : USER_GENDER.FEMALE;
      const minValue = physicalTestInfo.minValue
        ? physicalTestInfo.minValue
        : SFT_DATA[testkey][userGender].minValue;
      const boundary = physicalTestInfo.boundary
        ? physicalTestInfo.boundary
        : SFT_DATA[testkey][userGender].data;

      let gradeColor;

      const unit = getSFT_UNIT()[testkey];

      switch (grade) {
        case "s":
        case "a":
          gradeColor = "#44A280";
          break;
        case "b":
        case "c":
          gradeColor = "#F9B811";
          break;
        case "d":
          gradeColor = "#F44B4A";
          break;
        default:
          break;
      }

      const imgPath = {
        [MENUTYPE.UP_AND_GO_TEST]: img_sft_agility,
        [MENUTYPE.AEROBIC]: img_sft_cardiopulmonary,
        [MENUTYPE.LOWER_BODY_FLEX]: img_sft_lowerflexibility,
        [MENUTYPE.LOWER_BODY_STR]: img_sft_lower,
        [MENUTYPE.UPPER_BODY_STR]: img_sft_upper,
        [MENUTYPE.STATIC_BALANCE]: img_sft_upperflexibility,
      };

      return {
        tabName,
        grade,
        value,
        gradeColor,
        testkey,
        userGender,
        unit,
        boundary,
        minValue,
        imgPath: imgPath[physicalTestInfo.type],
      };
    } else {
      return {};
    }
  }, [physicalTestInfo, sex]);

  useEffect(() => {
    if (titleRef.current) {
      const itemEl = titleRef.current;
      const pointEl = itemEl.querySelector("strong");
      pointEl.style.color = convertScore.gradeColor;
    }
  }, [convertScore]);

  return (
    <div className={css.container}>
      <PTSubTabGraph
        testKey={physicalTestInfo.testkey}
        selectedDate={selectedDate}
      />
      <div className={css.subTabSummary}>
        <div className={css.imgBox}>
          <img
            className={css.scoreImg}
            src={convertScore?.imgPath}
            alt="score_img"
          />
        </div>

        <div className={css.gradeContainer}>
          <div className={css.gradeLayer}>
            <div className={css.gradeBox}>
              <div
                className={css.tooltipBox}
                style={{
                  backgroundColor:
                  convertScore.gradeColor !== null && convertScore.gradeColor,
                }}
              >
                <span>{$L("Grade {grade}").replace("{grade}", convertScore.grade?.toUpperCase())}</span>
              </div>
              <div className={css.currentScore}>
                {`${physicalTestInfo.score}${$L("points")}`}
              </div>
            </div>
            {feedback && (
              <div className={css.textArea}>
                <p className={classnames(css.text)}>{feedback.title}</p>
                {/* <p className={classnames(css.text)}>{feedback.subtitle}</p> */}
              </div>
            )}
          </div>
          <div className={css.ratingBox}>
            <div className={css.rating}>
              <div>
                <span />
                {$L("Measurement management level")}
              </div>
              <div>
                {physicalTestInfo.score === null
                  ? $L("Not Tested")
                  : convertScore.value + convertScore.unit}
              </div>
            </div>
            <TFixBarItem
              type={TYPE_FIXBAR.SFT}
              className={css.tBar}
              value={convertScore.value}
              grade={convertScore.grade}
              degree={convertScore.boundary}
              unit={convertScore.unit}
              minValue={convertScore.minValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PTSubTabContainer;
