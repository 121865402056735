import React from "react";
import TChart from "../../components/TChart/TChart";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import css from "./ChartTest.module.less";

const ChartTest = () => {
  const Value = [
    { average: 75, mySelf: 25 },
    { average: 50, mySelf: 25 },
    { average: 95, mySelf: 45 },
    { average: 23.3, mySelf: 12.1 },
    { average: 99, mySelf: 12 },
    { average: 75, mySelf: 25 },
    { average: 55, mySelf: 12 },
    { average: 85, mySelf: 55 },
    { average: 75, mySelf: 25 },
    { average: 77, mySelf: 19 },
  ];

  return (
    <TPanel>
      <THeader title={"Chart Test"} />
      <TBody className={css.body}>
        <TChart chartList={Value} />
      </TBody>
    </TPanel>
  );
};

export default ChartTest;
