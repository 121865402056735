
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TIconButton, { ICON_TYPES } from "../TIconButton/TIconButton";
import TToolTip from "../TToolTip/TToolTip";
import * as TTSService from "../../lunaSend/TTSService";
import css from "./TutorialButton.module.less";
import { $L } from "../../utils/helperMethods";
import TextPopup from "../TextPopup/TextPopup";
import Spotlight from "@enact/spotlight";

const TutorialButton = ({ className, isLandingPage=false, ...rest }) => {
    const [textPopupOpened, setTextPopupOpened] = useState(false);
    const positionRef = useRef();
    const tutorialText = useMemo(()=>{
        if(isLandingPage){
            return $L("LG Intelli-FiT is a service that designs the lifestyle customer experience of the space in front of the TV. If you go into \"My Body Analysis\", you can get a service that analyzes body shape, range of motion and various physical abilities such as muscle strength, endurance, flexibility, and body balance, and monitors changes in that condition. Based on the analyzed body data, \"My Workout\" provides personalized exercise content by an AI exercise manager, manages your exercise performance, and guides you to exercise coaching and health habits. \"Outdoor Activity Indoors\" offers outdoor exercise experiences such as walking, jogging, and golf coaching at home.");
        }else{
            return $L("\"Body Alignment\" analyzes which areas of the body are tilted through camera shots. You can also get recommended exercises for body parts that need posture correction using AI technology.");
        }
    },[]);

    const _onClick = useCallback(()=>{
        TTSService.speak(tutorialText);
        setTextPopupOpened(true);
    },[textPopupOpened, tutorialText]);

    const onCloseTextPopup = useCallback(()=>{
        setTextPopupOpened(false);
        TTSService.stop();
        setTimeout(()=>{
            Spotlight.focus('tutorialButton');
        },0);
    },[]);

    useEffect(()=>{
        return ()=>{
            TTSService.stop();
        }
    },[]);
    return(
        <>
            <div className={css.positionBox} ref={positionRef}>
                <TIconButton  className={css.tutorialButton} iconType={ICON_TYPES.question} spotlightId={"tutorialButton"}
                    itemRenderer={
                        (isTooltip) => <TToolTip isOpen={isTooltip} position="top" relativeRef={positionRef} relativePosition="right">{$L("Tutorial")}</TToolTip>
                    }
                    onClick={_onClick}
                />
            </div>
            <TextPopup
                open={textPopupOpened}
                onClose={onCloseTextPopup}
                children={tutorialText}
            />
        </>

    );
}
export default TutorialButton;