const keypoints = {
  id: 1,
  bbox: {
    width: 360,
    y: 421,
    score: 45612.0,
    x: 677,
    height: 298,
  },
  joints2D: [
    [705, 609],//0
    [720, 659],
    [697, 761],
    [555, 670],
    [720, 544],
    [585, 659],
    [555, 715],
    [652, 609],
    [517, 590],
    [495, 590],
    [435, 100],//10
    [532, 554],
    [690, 250],
    [577, 646],
    [600, 250],
    [690, 744],
    [562, 670],
    [465, 410],
    [660, 590],
    [525, 590],
    [510, 590],//20
    [517, 628],
    [510, 646],
    [510, 655],
    [517, 655],
    [517, 680],
    [525, 655],
    [517, 655],
    [517, 655],
    [525, 655],
    [532, 655],//30
    [495, 600],
    [690, 722],
    [600, 612],
    [585, 508],
    [495, 150],//35
    [480, 150],//36
    [602, 170],//37
    [550, 140],
    [532, 670],
    [547, 646]
  ],
  joints3D: [
    {
      z: 0.0,
      y: 0.0,
      x: 0.0,
    },
    {
      z: 0.0,
      y: 0.016,
      x: 0.1177,
    },
    {
      z: 0.3638,
      y: -0.1177,
      x: 0.3156,
    },
    {
      z: 0.3852,
      y: 0.0909,
      x: 0.4547,
    },
    {
      z: 0.0,
      y: -0.016,
      x: -0.123,
    },
    {
      z: 0.3905,
      y: -0.1872,
      x: 0.0963,
    },
    {
      z: 0.4975,
      y: -0.0428,
      x: 0.4173,
    },
    {
      z: -0.0963,
      y: -0.1605,
      x: -0.0749,
    },
    {
      z: -0.1712,
      y: -0.3745,
      x: -0.0802,
    },
    {
      z: -0.1498,
      y: -0.4654,
      x: -0.0909,
    },
    {
      z: -0.2247,
      y: -0.5243,
      x: -0.0909,
    },
    {
      z: -0.1872,
      y: -0.3424,
      x: -0.2354,
    },
    {
      z: 0.0,
      y: -0.1765,
      x: -0.2996,
    },
    {
      z: 0.0053,
      y: -0.4333,
      x: 0.0267,
    },
    {
      z: -0.123,
      y: -0.3049,
      x: 0.0856,
    },
    {
      z: -0.0642,
      y: -0.0588,
      x: 0.2835,
    },
    {
      z: -0.0053,
      y: -0.2675,
      x: 0.3691,
    },
    {
      z: -0.1444,
      y: 0.0428,
      x: -0.0321,
    },
    {
      z: -0.1712,
      y: -0.0802,
      x: -0.0642,
    },
    {
      z: -0.2033,
      y: -0.1765,
      x: -0.0749,
    },
    {
      z: -0.2193,
      y: -0.3798,
      x: -0.0695,
    },
    {
      z: -0.0588,
      y: -0.3103,
      x: 0.3424,
    },
    {
      z: -0.0374,
      y: -0.337,
      x: 0.3531,
    },
    {
      z: -0.0214,
      y: -0.321,
      x: 0.3424,
    },
    {
      z: -0.0053,
      y: -0.3156,
      x: 0.3477,
    },
    {
      z: -0.0053,
      y: -0.3049,
      x: 0.3584,
    },
    {
      z: -0.107,
      y: -0.5671,
      x: 0.214,
    },
    {
      z: -0.1177,
      y: -0.6099,
      x: 0.1979,
    },
    {
      z: -0.1337,
      y: -0.6045,
      x: 0.1926,
    },
    {
      z: -0.1391,
      y: -0.5992,
      x: 0.1765,
    },
    {
      z: -0.1391,
      y: -0.5885,
      x: 0.1658,
    },
    {
      z: 0.535,
      y: 0.0374,
      x: 0.5082,
    },
    {
      z: 0.5189,
      y: 0.0642,
      x: 0.5671,
    },
    {
      z: 0.5082,
      y: -0.0642,
      x: 0.4066,
    },
    {
      z: 0.4387,
      y: -0.0321,
      x: 0.3959,
    },
    {
      z: -0.1177,
      y: -0.6152,
      x: -0.0963,
    },
    {
      z: -0.1712,
      y: -0.6206,
      x: -0.1605,
    },
    {
      z: -0.1391,
      y: -0.5617,
      x: -0.016,
    },
    {
      z: -0.2728,
      y: -0.5564,
      x: -0.1712,
    },
    {
      z: -0.0535,
      y: -0.3103,
      x: 0.3852,
    },
    {
      z: -0.123,
      y: -0.5564,
      x: 0.1605,
    },
  ],
  joints3DPosition: {
    z: 3.7905,
    y: 0.4307,
    x: -1.0337,
  },
};

export default keypoints;