import { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { popPanel } from "../features/panels/panelsSlice";
import * as lunaSend from "../lunaSend";
import { panel_names } from "../utils/Config";
import { $L, cloneObject } from "../utils/helperMethods";
import usePrevious from "./usePrevious";

const RETRYCOUNT_MAX = 5;

const useSetSolution = () => {
  const dispatch = useDispatch();
  const [bgWorking, setBgWorking] = useState(false);
  const bgWorkingRef = usePrevious(bgWorking);
  const [autoPTZWorking, setAutoPTZWorking] = useState(false);
  const autoPTZWorkingRef = usePrevious(autoPTZWorking);

  const bgRetryRef = useRef(null);
  const autoPTZRetryRef = useRef(null);

  const bgRetryCount = useRef(null);
  const autoPTZRetryCount = useRef(null);

  const cameraSize = useSelector((state) => state.camera.cameraSize);
  const cameraSizeRef = usePrevious(cameraSize);

  const activateBGSegFailed = useCallback(() => {
    if (bgRetryCount.current >= RETRYCOUNT_MAX) {
      if (typeof window === "object" && window.PalmSystem) {
        bgRetryCount.current = 0;
        lunaSend.createToast($L("Camera settings error."));
        dispatch(popPanel(panel_names.HOMECARE_WORKOUT_PLAYER));
      } else {
        console.error('useSetSolution activateBGSegFailed 카메라 설정에 실패하였습니다.');
      }
      return;
    }
    bgRetryCount.current++;
    bgRetryRef.current = setTimeout(() => {
      _activeBGSegmentation();
    },1000);
  },[]);

  const activateFailed = useCallback(() => {
    lunaSend.createToast($L("Camera settings error."));
    console.error('useSetSolution 카메라 설정에 실패하였습니다.');
  },[]);

  const activeBGSegmentation = () => {
    bgRetryCount.current = 0;
    _activeBGSegmentation();
  }
  const _activeBGSegmentation = () => {
    if(bgRetryRef.current){
      clearTimeout(bgRetryRef.current);
      bgRetryRef.current = null;
    }
    console.log("useSetSolution activeBGSegmentation bgRetryCount : ", bgRetryCount.current);

    const size = cloneObject(cameraSizeRef.current);
    if(!size.width){
      size.width = 1280;
      size.height = 720
    }
    const params = {
      id: "camera1",
      solutions: [
        {
          name: "BGSegmentation",
          params: {
            enable: true,
            modelParam: {
              origImgRoiX: (size.width-size.width*0.5)/2 -1,
              origImgRoiY: 0,
              origImgRoiWidth: size.width*0.5+2,
              origImgRoiHeight: size.height,
            }
          }
        }
      ]
    };
    if(!bgWorkingRef.current){
      lunaSend.setSolutions({
        params,
        onSuccess: (res) => {
          setBgWorking(true);
          console.log("activeBGSegmentation BGSegmentation onSuccess=", res);
        },
        onFailure: (err) => {
          console.error("activeBGSegmentation BGSegmentation onFailure=", err);
          if(typeof window === "object" && window.PalmSystem){
            activateBGSegFailed();
          }else{
            setBgWorking(true);
          }
        },
        onComplete: (res) => {
          console.log("activeBGSegmentation BGSegmentation onComplete=", res);
        },
      });
    }
  };

  const deActiveBGSegmentation = () => {
    if(bgRetryRef.current){
      clearTimeout(bgRetryRef.current);
      bgRetryRef.current = null;
    }
    bgRetryCount.current = RETRYCOUNT_MAX;
    const params = {
      id: "camera1",
      solutions: [{name: "BGSegmentation", params: {enable: false}}]
    };
    console.log("useSetSolution deActiveBGSegmentation bgWorkingRef.current", bgWorkingRef.current);
    lunaSend.setSolutions({
      params,
      onSuccess: (res) => {
        console.log("useSetSolution lunaSend.deActiveBGSegmentation onSuccess=", res);
      },
      onFailure: (err) => {
        console.error("useSetSolution lunaSend.deActiveBGSegmentation onFailure=", err);
      }
    });
    setBgWorking(false);
  }

  const activeAutoPTZ = ({ autoPtz=false }) => {
    if(autoPTZRetryRef.current){
      clearTimeout(autoPTZRetryRef.current);
      autoPTZRetryRef.current = null;
    }
    console.log("useSetSolution activeAutoPTZ bgRetryCount : ", autoPTZRetryCount.current);

    autoPTZRetryCount.current = 0;
    const params = {
      id: "camera1",
      solutions: [
        {
          name: "PoseDetection",
          params: {
            enable: true,
            autoPtz: autoPtz
          }
        }
      ]
    };
    if(!autoPTZWorkingRef.current){
      lunaSend.setSolutions({
        params,
        onSuccess: (res) => {
          setAutoPTZWorking(true);
          console.log("useSetSolution activeAutoPTZ onSuccess=", res);
        },
        onFailure: (err) => {
          console.error("useSetSolution activeAutoPTZ onFailure=", err);
          if(typeof window === "object" && window.PalmSystem){
            activateFailed();
          }else{
            setAutoPTZWorking(true);
          }
        },
        onComplete: (res) => {
          console.log("useSetSolution activeAutoPTZ onComplete=", res);
        },
      });
    }
  };

  const deActiveAutoPTZ = () => {
    if(autoPTZRetryRef.current){
      clearTimeout(autoPTZRetryRef.current);
      autoPTZRetryRef.current = null;
    }
    autoPTZRetryCount.current = 0;
    const params = {
      id: "camera1",
      solutions: [{name: "PoseDetection", params: {enable: false}}]
    };
    if(autoPTZWorkingRef.current){
      lunaSend.setSolutions({
        params,
        onSuccess: (res) => {
          console.log("useSetSolution deActiveAutoPTZ onSuccess=", res);
        },
        onFailure: (err) => {
          console.error("useSetSolution deActiveAutoPTZ onFailure=", err);
        }
      });
    }
    setAutoPTZWorking(false);
  }

  return {
    activeBGSegmentation,
    deActiveBGSegmentation,
    activeAutoPTZ,
    deActiveAutoPTZ,
  };
};

export default useSetSolution;
