import classNames from "classnames";
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Spottable from "@enact/spotlight/Spottable";
import compose from "ramda/src/compose";
import { Marquee, MarqueeController } from "@enact/sandstone/Marquee";
import { Job } from "@enact/core/util";
import css from "./TAccordion.module.less";
import { $L, formatTime } from "../../utils/helperMethods";
import * as Config from "../../utils/Config";

/**
 * TAccordion
 * data형식을 맞춰 사용해야함
 * default : 날짜 점수 or 타이틀
 * focuesd: 날짜 타이틀 서브타이틀 나머지데이터
 *
 * @module TAccordion
 */

const SpottableComponent = Spottable("div");

const TAccordionItemBase = ({
  data,
  clickedItemIndexs = [],
  handleClickedItem,
  className,
  unit,
  noMarquee,
  ...rest
}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);
  const [pressed, setPressed] = useState(false);

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback((index) => {
    setPressed(true);
    clearPressedJob.current.start(() => {
      if (handleClickedItem) {
        if (clickedItemIndexs.includes(index)) {
          handleClickedItem({ clickedItemIndexs: clickedItemIndexs.filter((item) => item !== index) });
        } else {
          handleClickedItem({ clickedItemIndexs: [...clickedItemIndexs, index] });
        }
      }
    });
  }, [clickedItemIndexs, handleClickedItem]);

  return (
    <>
      {data?.map((content, index) => (
        <SpottableComponent
          {...rest}
          key={`item-${index}`}
          className={classNames(css.item, themeMode === "dark" && css.isDark, pressed && css.pressed, className)}
          onClick={() => _onClick(index)}
        >
          <div className={css.contentContainer}>
            <div className={css.dateContainer}>
              <div className={css.dateIton} />
              <div className={css.dateText}>{content.date}</div>
            </div>
            {!clickedItemIndexs.includes(index)
            ?
            <>
              <div className={css.mainContainer}>
                {noMarquee
                ?
                <div className={classNames(css.title, css.brown)}>
                  {content.data.painScore
                    ? `${content.data.painScore}${$L(unit)}`
                    : content.title}
                </div>
                :
                <Marquee className={css.title} marqueeOn={"focus"}>
                  {content.data.painScore
                    ? `${content.data.painScore}${$L(unit)}`
                    : content.title}
                </Marquee>
                }
              </div>
              <div className={css.arrowIcon}/>
            </>
            :
            <>
              <div className={css.mainContainer}>
                {noMarquee
                ?
                <div className={css.title}>{data[index].title}</div>
                :
                <Marquee className={css.title}>{data[index].title}</Marquee>
                }
                <div className={css.subTitle}>{data[index].subTitle}</div>
                <div className={css.dataContainer}>
                  {content.data && (
                    Object.keys(content.data).map((key) => (
                      <React.Fragment key={key}>
                        <div className={classNames(css.dataIcon, css[key])}/>
                        <div className={css.data}>
                          { key === "time" ? formatTime(content.data[key]) : content.data[key] }
                        </div>
                      </React.Fragment>
                    ))
                  )}
                </div>
              </div>
              <div className={classNames(css.arrowIcon, css.close)}/>
            </>
            }
          </div>
        </SpottableComponent>
      ))}
    </>
  );
};
const ItemDecorator = compose(MarqueeController({ marqueeOnFocus: true }));
const TAccordion = ItemDecorator(TAccordionItemBase);
export default TAccordion;
