import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import css from "./TermsConditionsDetail.module.less";
import TScroller from "../../components/TScroller/TScroller";
import TButton, { SIZES } from "../../components/TButton/TButton";
import Spotlight from "@enact/spotlight";
import dummyTerms from '../../../assets/mock/dummyTerms.json'
import TPanel from "../../components/TPanel/TPanel";
import Alert from '@enact/sandstone/Alert';
import { popPanel } from "../../features/panels/panelsSlice";
import { $L } from "../../utils/helperMethods";

const TermsConditionsDetail = ({ type='full', index, open, onClose, setChecked, ...rest }) => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();
  const getScrollTo = useCallback((cbScrollTo) => {
      scrollTo.current = cbScrollTo;
  }, [index]);

  useEffect(()=>{
    if (scrollTo && scrollTo.current) {
      scrollTo.current({position: {y: 0}, animate: false});
    }
  }, [index]);

  let title, date, detail;
  switch (index) {
    case 1:
      title= $L("[Required fields] Terms and conditions agreement for using the camera app");
      date= $L("개정일: 2023년 5월 20일 | 적용일 : 2023년 6월 1일")
      detail = dummyTerms[index];
      break;
    case 2:
      title= $L("[Required fields] Terms and conditions agreement for using your health information");
      date= $L("개정일: 2023년 5월 20일 | 적용일 : 2023년 6월 1일")
      detail = dummyTerms[index];
      break;
    default:
      break;
  }

  const _onClose = useCallback((ev) => {
    if(onClose){
      onClose(ev);
      if(ev && ev.target) {
        setChecked(prevChecked => {
          const newChecked = [...prevChecked];
          newChecked[index] = true;
          if (newChecked.slice(1).every(val => val)) {
            newChecked[0] = true;
          }
          return newChecked;
        });
      }
    }
  }, [onClose]);

  useEffect(()=>{
    Spotlight.focus('termsCondetionDetailConfirm');
  },[]);

  return (
    type === 'full'?
    <Alert {...rest} className={css.panel}
      open
      onClose={_onClose}
      type={"fullscreen"}
    >
      <div className={css.title}>{title}</div>
      <div className={css.date}>{date}</div>
      <TScroller className={css.tScroller} cbScrollTo={getScrollTo} >
        <div className={css.terms}>
          <div dangerouslySetInnerHTML={{ __html: detail }}></div>
          <div className={css.shadow}></div>
          <div className={css.btnBox}>
            <TButton size={SIZES.xLarge} spotlightId="termsCondetionDetailConfirm" onClick={_onClose} >{$L("Verified")}</TButton>
          </div>
        </div>
      </TScroller>
    </Alert>
    :
    <TScroller className={css.halfScroller} cbScrollTo={getScrollTo} >
      <div className={css.terms}>
        <div className={css.topShadow}></div>
        <div className={css.title}>{title}</div>
        <div dangerouslySetInnerHTML={{ __html: detail }}></div>
        <div className={css.shadow}></div>
      </div>
    </TScroller>
  );
};

export default TermsConditionsDetail;