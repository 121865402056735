// muscle image ==========================================
// head_shift
import head_shift_back_danger from "./img_viewdetail_headshift_back_01.png";
import head_shift_back_manage from "./img_viewdetail_headshift_back_02.png";
import head_shift_back_fine from "./img_viewdetail_headshift_back_03.png";
import head_shift_front_danger from "./img_viewdetail_headshift_front_01.png";
import head_shift_front_manage from "./img_viewdetail_headshift_front_02.png";
import head_shift_front_fine from "./img_viewdetail_headshift_front_03.png";

// knee_flexion
import knee_flexion_back_danger from "./img_viewdetail_kneeflexion_back_01.png";
import knee_flexion_back_manage from "./img_viewdetail_kneeflexion_back_02.png";
import knee_flexion_back_fine from "./img_viewdetail_kneeflexion_back_03.png";
import knee_flexion_front_danger from "./img_viewdetail_kneeflexion_front_01.png";
import knee_flexion_front_manage from "./img_viewdetail_kneeflexion_front_02.png";
import knee_flexion_front_fine from "./img_viewdetail_kneeflexion_front_03.png";

// leg_alignment-X
import leg_alignment_x_back_danger from "./img_viewdetail_legalignmentx_back_01.png";
import leg_alignment_x_back_manage from "./img_viewdetail_legalignmentx_back_02.png";
import leg_alignment_x_back_fine from "./img_viewdetail_legalignmentx_back_03.png";
import leg_alignment_x_front_danger from "./img_viewdetail_legalignmentx_front_01.png";
import leg_alignment_x_front_manage from "./img_viewdetail_legalignmentx_front_02.png";
import leg_alignment_x_front_fine from "./img_viewdetail_legalignmentx_front_03.png";
// leg_alignment-O
import leg_alignment_o_back_danger from "./img_viewdetail_legalignmento_back_01.png";
import leg_alignment_o_back_manage from "./img_viewdetail_legalignmento_back_02.png";
import leg_alignment_o_back_fine from "./img_viewdetail_legalignmento_back_03.png";
import leg_alignment_o_front_danger from "./img_viewdetail_legalignmento_front_01.png";
import leg_alignment_o_front_manage from "./img_viewdetail_legalignmento_front_02.png";
import leg_alignment_o_front_fine from "./img_viewdetail_legalignmento_front_03.png";

// neck_tilt
import neckTilt_front_danger from "./img_viewdetail_necktilt_front_01.png";
import neckTilt_front_manage from "./img_viewdetail_necktilt_front_02.png";
import neckTilt_front_fine from "./img_viewdetail_necktilt_front_03.png";
import neckTilt_back_common from "./img_viewdetail_necktilt_back.png";

// pelvic_rotation
import pelvic_rotation_back_danger from "./img_viewdetail_pelvicrotation_back_01.png";
import pelvic_rotation_back_manage from "./img_viewdetail_pelvicrotation_back_02.png";
import pelvic_rotation_back_fine from "./img_viewdetail_pelvicrotation_back_03.png";
import pelvic_rotation_front_danger from "./img_viewdetail_pelvicrotation_front_01.png";
import pelvic_rotation_front_manage from "./img_viewdetail_pelvicrotation_front_02.png";
import pelvic_rotation_front_fine from "./img_viewdetail_pelvicrotation_front_03.png";

// pelvic_shift - front
import pelvic_shift_front_back_danger from "./img_viewdetail_pelvicshiftfront_back_01.png";
import pelvic_shift_front_back_manage from "./img_viewdetail_pelvicshiftfront_back_02.png";
import pelvic_shift_front_back_fine from "./img_viewdetail_pelvicshiftfront_back_03.png";
import pelvic_shift_front_front_danger from "./img_viewdetail_pelvicshiftfront_front_01.png";
import pelvic_shift_front_front_manage from "./img_viewdetail_pelvicshiftfront_front_02.png";
import pelvic_shift_front_front_fine from "./img_viewdetail_pelvicshiftfront_front_03.png";
// pelvic_shift - back
import pelvic_shift_back_back_danger from "./img_viewdetail_pelvicshiftback_back_01.png";
import pelvic_shift_back_back_manage from "./img_viewdetail_pelvicshiftback_back_02.png";
import pelvic_shift_back_back_fine from "./img_viewdetail_pelvicshiftback_back_03.png";
import pelvic_shift_back_front_danger from "./img_viewdetail_pelvicshiftback_front_01.png";
import pelvic_shift_back_front_manage from "./img_viewdetail_pelvicshiftback_front_02.png";
import pelvic_shift_back_front_fine from "./img_viewdetail_pelvicshiftback_front_03.png";

// pelvic_tilt
import pelvic_tilt_back_danger from "./img_viewdetail_pelvictilt_back_01.png";
import pelvic_tilt_back_manage from "./img_viewdetail_pelvictilt_back_02.png";
import pelvic_tilt_back_fine from "./img_viewdetail_pelvictilt_back_03.png";
import pelvic_tilt_front_danger from "./img_viewdetail_pelvictilt_front_01.png";
import pelvic_tilt_front_manage from "./img_viewdetail_pelvictilt_front_02.png";
import pelvic_tilt_front_fine from "./img_viewdetail_pelvictilt_front_03.png";

// shoulder_tilt
import shoulder_tilt_front_common from "./img_viewdetail_shouldertilt_front.png";
import shoulder_tilt_back_danger from "./img_viewdetail_shouldertilt_back_01.png";
import shoulder_tilt_back_manage from "./img_viewdetail_shouldertilt_back_02.png";
import shoulder_tilt_back_fine from "./img_viewdetail_shouldertilt_back_03.png";

// trunk_shift
import trunk_shift_back_danger from "./img_viewdetail_trunkshift_back_01.png";
import trunk_shift_back_manage from "./img_viewdetail_trunkshift_back_02.png";
import trunk_shift_back_fine from "./img_viewdetail_trunkshift_back_03.png";
import trunk_shift_front_danger from "./img_viewdetail_trunkshift_front_01.png";
import trunk_shift_front_manage from "./img_viewdetail_trunkshift_front_02.png";
import trunk_shift_front_fine from "./img_viewdetail_trunkshift_front_03.png";

// muscle detail image ==========================================
// neck_tilt__LEFT
import neck_tilt_back_tight_LEFT from "./muscleDetail/lg_neck_tilt_back_tight_LEFT.jpg";
import neck_tilt_back_elongated_LEFT from "./muscleDetail/lg_neck_tilt_back_elongated_LEFT.jpg";
import neck_tilt_back_stabilizing_LEFT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import neck_tilt_front_tight_LEFT from "./muscleDetail/lg_neck_tilt_front_tight_LEFT.jpg";
import neck_tilt_front_elongated_LEFT from "./muscleDetail/lg_neck_tilt_front_elongated_LEFT.jpg";
import neck_tilt_front_stabilizing_LEFT from "./muscleDetail/lg_neck_tilt_front_stabilizing_LEFT.jpg";
// neck_tilt__RIGHT
import neck_tilt_back_tight_RIGHT from "./muscleDetail/lg_neck_tilt_back_tight_RIGHT.jpg";
import neck_tilt_back_elongated_RIGHT from "./muscleDetail/lg_neck_tilt_back_elongated_RIGHT.jpg";
import neck_tilt_back_stabilizing_RIGHT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import neck_tilt_front_tight_RIGHT from "./muscleDetail/lg_neck_tilt_front_tight_RIGHT.jpg";
import neck_tilt_front_elongated_RIGHT from "./muscleDetail/lg_neck_tilt_front_elongated_RIGHT.jpg";
import neck_tilt_front_stabilizing_RIGHT from "./muscleDetail/lg_neck_tilt_front_stabilizing_RIGHT.jpg";

// shoulder_tilt__LEFT
import shoulder_tilt_back_tight_LEFT from "./muscleDetail/lg_shoulder_tilt_back_tight_LEFT.jpg";
import shoulder_tilt_back_elongated_LEFT from "./muscleDetail/lg_shoulder_tilt_back_elongated_LEFT.jpg";
import shoulder_tilt_back_stabilizing_LEFT from "./muscleDetail/lg_shoulder_tilt_back_stabilizing_LEFT.jpg";
import shoulder_tilt_front_tight_LEFT from "./muscleDetail/lg_shoulder_tilt_front_tight_LEFT.jpg";
import shoulder_tilt_front_elongated_LEFT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import shoulder_tilt_front_stabilizing_LEFT from "./muscleDetail/lg_shoulder_tilt_front_stabilizing_LEFT.jpg";
// shoulder_tilt__RIGHT
import shoulder_tilt_back_tight_RIGHT from "./muscleDetail/lg_shoulder_tilt_back_tight_RIGHT.jpg";
import shoulder_tilt_back_elongated_RIGHT from "./muscleDetail/lg_shoulder_tilt_back_elongated_RIGHT.jpg";
import shoulder_tilt_back_stabilizing_RIGHT from "./muscleDetail/lg_shoulder_tilt_back_stabilizing_RIGHT.jpg";
import shoulder_tilt_front_tight_RIGHT from "./muscleDetail/lg_shoulder_tilt_front_tight_RIGHT.jpg";
import shoulder_tilt_front_elongated_RIGHT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import shoulder_tilt_front_stabilizing_RIGHT from "./muscleDetail/lg_shoulder_tilt_front_stabilizing_RIGHT.jpg";

// pelvic_tilt__LEFT
import pelvic_tilt_back_tight_LEFT from "./muscleDetail/lg_lateral_pelvic_tilt_back_tight_LEFT.jpg";
import pelvic_tilt_back_elongated_LEFT from "./muscleDetail/lg_lateral_pelvic_tilt_back_elongated_LEFT.jpg";
import pelvic_tilt_back_stabilizing_LEFT from "./muscleDetail/lg_lateral_pelvic_tilt_back_stabilizing_LEFT.jpg";
import pelvic_tilt_front_tight_LEFT from "./muscleDetail/lg_lateral_pelvic_tilt_front_tight_LEFT.jpg";
import pelvic_tilt_front_elongated_LEFT from "./muscleDetail/lg_lateral_pelvic_tilt_front_elongated_LEFT.jpg";
import pelvic_tilt_front_stabilizing_LEFT from "./muscleDetail/lg_lateral_pelvic_tilt_front_stabilizing_LEFT.jpg";
// pelvic_tilt__RIGHT
import pelvic_tilt_back_tight_RIGHT from "./muscleDetail/lg_lateral_pelvic_tilt_back_tight_RIGHT.jpg";
import pelvic_tilt_back_elongated_RIGHT from "./muscleDetail/lg_lateral_pelvic_tilt_back_elongated_RIGHT.jpg";
import pelvic_tilt_back_stabilizing_RIGHT from "./muscleDetail/lg_lateral_pelvic_tilt_back_stabilizing_RIGHT.jpg";
import pelvic_tilt_front_tight_RIGHT from "./muscleDetail/lg_lateral_pelvic_tilt_front_tight_RIGHT.jpg";
import pelvic_tilt_front_elongated_RIGHT from "./muscleDetail/lg_lateral_pelvic_tilt_front_elongated_RIGHT.jpg";
import pelvic_tilt_front_stabilizing_RIGHT from "./muscleDetail/lg_lateral_pelvic_tilt_front_stabilizing_RIGHT.jpg";

// pelvic_rotation__LEFT
import pelvic_rotation_back_tight_LEFT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import pelvic_rotation_back_elongated_LEFT from "./muscleDetail/lg_pelvic_rotation_back_elongated_LEFT.jpg";
import pelvic_rotation_back_stabilizing_LEFT from "./muscleDetail/lg_pelvic_rotation_back_stabilizing_LEFT.jpg";
import pelvic_rotation_front_tight_LEFT from "./muscleDetail/lg_pelvic_rotation_front_tight_LEFT.jpg";
import pelvic_rotation_front_elongated_LEFT from "./muscleDetail/lg_pelvic_rotation_front_elongated_LEFT.jpg";
import pelvic_rotation_front_stabilizing_LEFT from "./muscleDetail/lg_pelvic_rotation_front_stabilizing_LEFT.jpg";
// pelvic_rotation__RIGHT
import pelvic_rotation_back_tight_RIGHT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import pelvic_rotation_back_elongated_RIGHT from "./muscleDetail/lg_pelvic_rotation_back_elongated_RIGHT.jpg";
import pelvic_rotation_back_stabilizing_RIGHT from "./muscleDetail/lg_pelvic_rotation_back_stabilizing_RIGHT.jpg";
import pelvic_rotation_front_tight_RIGHT from "./muscleDetail/lg_pelvic_rotation_front_tight_RIGHT.jpg";
import pelvic_rotation_front_elongated_RIGHT from "./muscleDetail/lg_pelvic_rotation_front_elongated_RIGHT.jpg";
import pelvic_rotation_front_stabilizing_RIGHT from "./muscleDetail/lg_pelvic_rotation_front_stabilizing_RIGHT.jpg";

// leg_alignment-O(Bow Leg)
import leg_alignment_o_back_tight from "./muscleDetail/dummy_muscleDetail.png"; // no file
import leg_alignment_o_back_elongated from "./muscleDetail/lg_bow_leg_back_elongated.jpg";
import leg_alignment_o_back_stabilizing from "./muscleDetail/lg_bow_leg_back_stabilizing.jpg";
import leg_alignment_o_front_tight from "./muscleDetail/lg_bow_leg_front_tight.jpg";
import leg_alignment_o_front_elongated from "./muscleDetail/dummy_muscleDetail.png"; // no file
import leg_alignment_o_front_stabilizing from "./muscleDetail/lg_bow_leg_front_stabilizing.jpg";

// leg_alignment-X(Knock Knee)
import leg_alignment_x_back_tight from "./muscleDetail/lg_knock_knee_back_tight.jpg";
import leg_alignment_x_back_elongated from "./muscleDetail/dummy_muscleDetail.png"; // no file
import leg_alignment_x_back_stabilizing from "./muscleDetail/lg_knock_knee_back_stabilizing.jpg";
import leg_alignment_x_front_tight from "./muscleDetail/lg_knock_knee_front_tight.jpg";
import leg_alignment_x_front_elongated from "./muscleDetail/lg_knock_knee_front_elongated.jpg";
import leg_alignment_x_front_stabilizing from "./muscleDetail/lg_knock_knee_front_stabilizing.jpg";

// head_shift-Anterior
import head_shift_anterior_back_tight from "./muscleDetail/lg_head_shift_anterior_back_tight.jpg";
import head_shift_anterior_back_elongated from "./muscleDetail/dummy_muscleDetail.png"; // no file
import head_shift_anterior_back_stabilizing from "./muscleDetail/dummy_muscleDetail.png"; // no file
import head_shift_anterior_front_tight from "./muscleDetail/lg_head_shift_anterior_front_tight.jpg";
import head_shift_anterior_front_elongated from "./muscleDetail/lg_head_shift_anterior_front_elongated.jpg";
import head_shift_anterior_front_stabilizing from "./muscleDetail/lg_head_shift_anterior_front_stabilizing.jpg";

// head_shift-Posterior
import head_shift_posterior_back_tight from "./muscleDetail/lg_head_shift_posterior_back_tight.jpg";
import head_shift_posterior_back_elongated from "./muscleDetail/lg_head_shift_posterior_back_elongated.jpg";
import head_shift_posterior_back_stabilizing from "./muscleDetail/dummy_muscleDetail.png"; // no file
import head_shift_posterior_front_tight from "./muscleDetail/dummy_muscleDetail.png"; // no file
import head_shift_posterior_front_elongated from "./muscleDetail/dummy_muscleDetail.png"; // no file
import head_shift_posterior_front_stabilizing from "./muscleDetail/lg_head_shift_posterior_front_stabilizing.jpg";

// shoulder_shift-Anterior
import shoulder_shift_anterior_back_tight from "./muscleDetail/dummy_muscleDetail.png"; // no file
import shoulder_shift_anterior_back_elongated from "./muscleDetail/lg_shoulder_shift_anterior_back_elongated.jpg";
import shoulder_shift_anterior_back_stabilizing from "./muscleDetail/lg_shoulder_shift_anterior_back_stabilizing.jpg";
import shoulder_shift_anterior_front_tight from "./muscleDetail/lg_shoulder_shift_anterior_front_tight.jpg";
import shoulder_shift_anterior_front_elongated from "./muscleDetail/dummy_muscleDetail.png"; // no file
import shoulder_shift_anterior_front_stabilizing from "./muscleDetail/lg_shoulder_shift_anterior_front_stabilizing.jpg";

// shoulder_shift-Posterior
import shoulder_shift_posterior_back_tight from "./muscleDetail/lg_shoulder_shift_posterior_back_tight.jpg";
import shoulder_shift_posterior_back_elongated from "./muscleDetail/lg_shoulder_shift_posterior_back_elongated.jpg";
import shoulder_shift_posterior_back_stabilizing from "./muscleDetail/lg_shoulder_shift_posterior_back_stabilizing.jpg";
import shoulder_shift_posterior_front_tight from "./muscleDetail/dummy_muscleDetail.png"; // no file
import shoulder_shift_posterior_front_elongated from "./muscleDetail/lg_shoulder_shift_posterior_front_elongated.jpg";
import shoulder_shift_posterior_front_stabilizing from "./muscleDetail/lg_shoulder_shift_posterior_front_stabilizing.jpg";

// pelvic_shift-Anterior
import pelvic_shift_anterior_back_tight from "./muscleDetail/dummy_muscleDetail.png"; // no file
import pelvic_shift_anterior_back_elongated from "./muscleDetail/lg_pelvic_shift_anterior_back_elongated.jpg";
import pelvic_shift_anterior_back_stabilizing from "./muscleDetail/lg_pelvic_shift_anterior_back_stabilizing.jpg";
import pelvic_shift_anterior_front_tight from "./muscleDetail/lg_pelvic_shift_anterior_front_tight.jpg";
import pelvic_shift_anterior_front_elongated from "./muscleDetail/lg_pelvic_shift_anterior_front_elongated.jpg";
import pelvic_shift_anterior_front_stabilizing from "./muscleDetail/lg_pelvic_shift_anterior_front_stabilizing.jpg";

// pelvic_shift-Posterior
import pelvic_shift_posterior_back_tight from "./muscleDetail/lg_pelvic_shift_posterior_back_tight.jpg";
import pelvic_shift_posterior_back_elongated from "./muscleDetail/lg_pelvic_shift_posterior_back_elongated.jpg";
import pelvic_shift_posterior_back_stabilizing from "./muscleDetail/lg_pelvic_shift_posterior_back_stabilizing.jpg";
import pelvic_shift_posterior_front_tight from "./muscleDetail/lg_pelvic_shift_posterior_front_tight.jpg";
import pelvic_shift_posterior_front_elongated from "./muscleDetail/lg_pelvic_shift_posterior_front_elongated.jpg";
import pelvic_shift_posterior_front_stabilizing from "./muscleDetail/lg_pelvic_shift_posterior_front_stabilizing.jpg";

// knee_flexion-Anterior
import knee_flexion_anterior_back_tight from "./muscleDetail/lg_knee_flexion_back_tight.jpg";
import knee_flexion_anterior_back_elongated from "./muscleDetail/dummy_muscleDetail.png"; // no file
import knee_flexion_anterior_back_stabilizing from "./muscleDetail/lg_knee_flexion_back_stabilizing.jpg";
import knee_flexion_anterior_front_tight from "./muscleDetail/dummy_muscleDetail.png"; // no file
import knee_flexion_anterior_front_elongated from "./muscleDetail/lg_knee_flexion_front_elongated.jpg";
import knee_flexion_anterior_front_stabilizing from "./muscleDetail/lg_knee_flexion_front_stabilizing.jpg";

// knee_flexion-Posterior
import knee_flexion_posterior_back_tight from "./muscleDetail/lg_knee_extension_back_tight.jpg";
import knee_flexion_posterior_back_elongated from "./muscleDetail/lg_knee_extension_back_elongated.jpg";
import knee_flexion_posterior_back_stabilizing from "./muscleDetail/lg_knee_extension_back_stabilizing.jpg";
import knee_flexion_posterior_front_tight from "./muscleDetail/lg_knee_extension_front_tight.jpg";
import knee_flexion_posterior_front_elongated from "./muscleDetail/lg_knee_extension_front_elongated.jpg";
import knee_flexion_posterior_front_stabilizing from "./muscleDetail/lg_knee_extension_front_stabilizing.jpg";

// muscle silhouette image ==========================================
// head_shift
import silhouette_head_shift_anterior_front_good from "./muscleSilhouette/lg_head_shift_anterior_front_good.jpg";
import silhouette_head_shift_anterior_back_good from "./muscleDetail/dummy_muscleDetail.png"; // no file
import silhouette_head_shift_anterior_front_bad from "./muscleSilhouette/lg_head_shift_anterior_front_bad.jpg";
import silhouette_head_shift_anterior_back_bad from "./muscleSilhouette/lg_head_shift_anterior_back_bad.jpg";
import silhouette_head_shift_posterior_front_good from "./muscleSilhouette/lg_head_shift_posterior_front_good.jpg";
import silhouette_head_shift_posterior_back_good from "./muscleDetail/dummy_muscleDetail.png"; // no file
import silhouette_head_shift_posterior_front_bad from "./muscleDetail/dummy_muscleDetail.png"; // no file
import silhouette_head_shift_posterior_back_bad from "./muscleSilhouette/lg_head_shift_posterior_back_bad.jpg";

// leg_alignment
import silhouette_bow_leg_front_good from "./muscleSilhouette/lg_bow_leg_front_good.jpg";
import silhouette_bow_leg_back_good from "./muscleSilhouette/lg_bow_leg_back_good.jpg";
import silhouette_bow_leg_front_bad from "./muscleSilhouette/lg_bow_leg_front_bad.jpg";
import silhouette_bow_leg_back_bad from "./muscleSilhouette/lg_bow_leg_back_bad.jpg";
import silhouette_knock_knee_front_good from "./muscleSilhouette/lg_knock_knee_front_good.jpg";
import silhouette_knock_knee_back_good from "./muscleSilhouette/lg_knock_knee_back_good.jpg";
import silhouette_knock_knee_front_bad from "./muscleSilhouette/lg_knock_knee_front_bad.jpg";
import silhouette_knock_knee_back_bad from "./muscleSilhouette/lg_knock_knee_back_bad.jpg";

// pelvic_tilt
import silhouette_lateral_pelvic_tilt_front_good_LEFT from "./muscleSilhouette/lg_lateral_pelvic_tilt_front_good_LEFT.jpg";
import silhouette_lateral_pelvic_tilt_back_good_LEFT from "./muscleSilhouette/lg_lateral_pelvic_tilt_back_good_LEFT.jpg";
import silhouette_lateral_pelvic_tilt_front_good_RIGHT from "./muscleSilhouette/lg_lateral_pelvic_tilt_front_good_RIGHT.jpg";
import silhouette_lateral_pelvic_tilt_back_good_RIGHT from "./muscleSilhouette/lg_lateral_pelvic_tilt_back_good_RIGHT.jpg";
import silhouette_lateral_pelvic_tilt_front_bad_LEFT from "./muscleSilhouette/lg_lateral_pelvic_tilt_front_bad_LEFT.jpg";
import silhouette_lateral_pelvic_tilt_back_bad_LEFT from "./muscleSilhouette/lg_lateral_pelvic_tilt_back_bad_LEFT.jpg";
import silhouette_lateral_pelvic_tilt_front_bad_RIGHT from "./muscleSilhouette/lg_lateral_pelvic_tilt_front_bad_RIGHT.jpg";
import silhouette_lateral_pelvic_tilt_back_bad_RIGHT from "./muscleSilhouette/lg_lateral_pelvic_tilt_back_bad_RIGHT.jpg";

// neck_tilt
import silhouette_neck_tilt_front_good_LEFT from "./muscleSilhouette/lg_neck_tilt_front_good_LEFT.jpg";
import silhouette_neck_tilt_back_good_LEFT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import silhouette_neck_tilt_front_good_RIGHT from "./muscleSilhouette/lg_neck_tilt_front_good_RIGHT.jpg";
import silhouette_neck_tilt_back_good_RIGHT from "./muscleDetail/dummy_muscleDetail.png"; // no file
import silhouette_neck_tilt_front_bad_LEFT from "./muscleSilhouette/lg_neck_tilt_front_bad_LEFT.jpg";
import silhouette_neck_tilt_back_bad_LEFT from "./muscleSilhouette/lg_neck_tilt_back_bad_LEFT.jpg";
import silhouette_neck_tilt_front_bad_RIGHT from "./muscleSilhouette/lg_neck_tilt_front_bad_RIGHT.jpg";
import silhouette_neck_tilt_back_bad_RIGHT from "./muscleSilhouette/lg_neck_tilt_back_bad_RIGHT.jpg";

// shoulder_tilt
import silhouette_shoulder_tilt_front_good_LEFT from "./muscleSilhouette/lg_shoulder_tilt_front_good_LEFT.jpg";
import silhouette_shoulder_tilt_back_good_LEFT from "./muscleSilhouette/lg_shoulder_tilt_back_good_LEFT.jpg";
import silhouette_shoulder_tilt_front_good_RIGHT from "./muscleSilhouette/lg_shoulder_tilt_front_good_RIGHT.jpg";
import silhouette_shoulder_tilt_back_good_RIGHT from "./muscleSilhouette/lg_shoulder_tilt_back_good_RIGHT.jpg";
import silhouette_shoulder_tilt_front_bad_LEFT from "./muscleSilhouette/lg_shoulder_tilt_front_bad_LEFT.jpg";
import silhouette_shoulder_tilt_back_bad_LEFT from "./muscleSilhouette/lg_shoulder_tilt_back_bad_LEFT.jpg";
import silhouette_shoulder_tilt_front_bad_RIGHT from "./muscleSilhouette/lg_shoulder_tilt_front_bad_RIGHT.jpg";
import silhouette_shoulder_tilt_back_bad_RIGHT from "./muscleSilhouette/lg_shoulder_tilt_back_bad_RIGHT.jpg";

// pelvic_rotation
import silhouette_pelvic_rotation_front_good_LEFT from "./muscleSilhouette/lg_pelvic_rotation_front_good_LEFT.jpg";
import silhouette_pelvic_rotation_back_good_LEFT from "./muscleSilhouette/lg_pelvic_rotation_back_good_LEFT.jpg";
import silhouette_pelvic_rotation_front_good_RIGHT from "./muscleSilhouette/lg_pelvic_rotation_front_good_RIGHT.jpg";
import silhouette_pelvic_rotation_back_good_RIGHT from "./muscleSilhouette/lg_pelvic_rotation_back_good_RIGHT.jpg";
import silhouette_pelvic_rotation_front_bad_LEFT from "./muscleSilhouette/lg_pelvic_rotation_front_bad_LEFT.jpg";
import silhouette_pelvic_rotation_back_bad_LEFT from "./muscleSilhouette/lg_pelvic_rotation_back_bad_LEFT.jpg";
import silhouette_pelvic_rotation_front_bad_RIGHT from "./muscleSilhouette/lg_pelvic_rotation_front_bad_RIGHT.jpg";
import silhouette_pelvic_rotation_back_bad_RIGHT from "./muscleSilhouette/lg_pelvic_rotation_back_bad_RIGHT.jpg";

// trunk_shift
import silhouette_shoulder_shift_anterior_front_good from "./muscleSilhouette/lg_shoulder_shift_anterior_front_good.jpg";
import silhouette_shoulder_shift_anterior_back_good from "./muscleSilhouette/lg_shoulder_shift_anterior_back_good.jpg";
import silhouette_shoulder_shift_anterior_front_bad from "./muscleSilhouette/lg_shoulder_shift_anterior_front_bad.jpg";
import silhouette_shoulder_shift_anterior_back_bad from "./muscleSilhouette/lg_shoulder_shift_anterior_back_bad.jpg";
import silhouette_shoulder_shift_posterior_front_good from "./muscleSilhouette/lg_shoulder_shift_posterior_front_good.jpg";
import silhouette_shoulder_shift_posterior_back_good from "./muscleSilhouette/lg_shoulder_shift_posterior_back_good.jpg";
import silhouette_shoulder_shift_posterior_front_bad from "./muscleSilhouette/lg_shoulder_shift_posterior_front_bad.jpg";
import silhouette_shoulder_shift_posterior_back_bad from "./muscleSilhouette/lg_shoulder_shift_posterior_back_bad.jpg";

// pelvic_shift
import silhouette_pelvic_shift_anterior_front_good from "./muscleSilhouette/lg_pelvic_shift_anterior_front_good.jpg";
import silhouette_pelvic_shift_anterior_back_good from "./muscleSilhouette/lg_pelvic_shift_anterior_back_good.jpg";
import silhouette_pelvic_shift_anterior_front_bad from "./muscleSilhouette/lg_pelvic_shift_anterior_front_bad.jpg";
import silhouette_pelvic_shift_anterior_back_bad from "./muscleSilhouette/lg_pelvic_shift_anterior_back_bad.jpg";
import silhouette_pelvic_shift_posterior_front_good from "./muscleSilhouette/lg_pelvic_shift_posterior_front_good.jpg";
import silhouette_pelvic_shift_posterior_back_good from "./muscleSilhouette/lg_pelvic_shift_posterior_back_good.jpg";
import silhouette_pelvic_shift_posterior_front_bad from "./muscleSilhouette/lg_pelvic_shift_posterior_front_bad.jpg";
import silhouette_pelvic_shift_posterior_back_bad from "./muscleSilhouette/lg_pelvic_shift_posterior_back_bad.jpg";

// knee_flexion
import silhouette_knee_flexion_front_good from "./muscleSilhouette/lg_knee_flexion_front_good.jpg";
import silhouette_knee_flexion_back_good from "./muscleSilhouette/lg_knee_flexion_back_good.jpg";
import silhouette_knee_flexion_front_bad from "./muscleSilhouette/lg_knee_flexion_front_bad.jpg";
import silhouette_knee_flexion_back_bad from "./muscleSilhouette/lg_knee_flexion_back_bad.jpg";
import silhouette_knee_extension_front_good from "./muscleSilhouette/lg_knee_extension_front_good.jpg";
import silhouette_knee_extension_back_good from "./muscleSilhouette/lg_knee_extension_back_good.jpg";
import silhouette_knee_extension_front_bad from "./muscleSilhouette/lg_knee_extension_front_bad.jpg";
import silhouette_knee_extension_back_bad from "./muscleSilhouette/lg_knee_extension_back_bad.jpg";

import {
  SCORE_MINT_KEY,
  SCORE_YELLOW_KEY,
  SCORE_RED_KEY,
} from "../../../src/utils/Constants";
import { $L } from "../../../src/utils/helperMethods";
import { FEEDBACK_TYPE } from "../../../src/views/BodyCheckUp/BodyAlignment/feedback/ConstantsFeedback";
import {
  MENUTYPE,
  MENUTYPE_TEST,
} from "../../../src/views/BodyCheckUp/BodyAlignment/BodyAlignmentReport/Constants";

//[front, back]
export const MUSCLE_IMGE = {
  head_shift: {
    [SCORE_MINT_KEY]: [head_shift_front_fine, head_shift_back_fine],
    [SCORE_YELLOW_KEY]: [head_shift_front_manage, head_shift_back_manage],
    [SCORE_RED_KEY]: [head_shift_front_danger, head_shift_back_danger],
  },
  leg_alignment_left: {
    x: {
      [SCORE_MINT_KEY]: [leg_alignment_x_front_fine, leg_alignment_x_back_fine],
      [SCORE_YELLOW_KEY]: [
        leg_alignment_x_front_manage,
        leg_alignment_x_back_manage,
      ],
      [SCORE_RED_KEY]: [
        leg_alignment_x_front_danger,
        leg_alignment_x_back_danger,
      ],
    },
    o: {
      [SCORE_MINT_KEY]: [leg_alignment_o_front_fine, leg_alignment_o_back_fine],
      [SCORE_YELLOW_KEY]: [
        leg_alignment_o_front_manage,
        leg_alignment_o_back_manage,
      ],
      [SCORE_RED_KEY]: [
        leg_alignment_o_front_danger,
        leg_alignment_o_back_danger,
      ],
    },
  },
  pelvic_tilt: {
    [SCORE_MINT_KEY]: [pelvic_tilt_front_fine, pelvic_tilt_back_fine],
    [SCORE_YELLOW_KEY]: [pelvic_tilt_front_manage, pelvic_tilt_back_manage],
    [SCORE_RED_KEY]: [pelvic_tilt_front_danger, pelvic_tilt_back_danger],
  },
  shoulder_tilt: {
    [SCORE_MINT_KEY]: [shoulder_tilt_front_common, shoulder_tilt_back_fine],
    [SCORE_YELLOW_KEY]: [shoulder_tilt_front_common, shoulder_tilt_back_manage],
    [SCORE_RED_KEY]: [shoulder_tilt_front_common, shoulder_tilt_back_danger],
  },
  pelvic_rotation: {
    [SCORE_MINT_KEY]: [pelvic_rotation_front_fine, pelvic_rotation_back_fine],
    [SCORE_YELLOW_KEY]: [
      pelvic_rotation_front_manage,
      pelvic_rotation_back_manage,
    ],
    [SCORE_RED_KEY]: [
      pelvic_rotation_front_danger,
      pelvic_rotation_back_danger,
    ],
  },
  neck_tilt: {
    [SCORE_MINT_KEY]: [neckTilt_front_fine, neckTilt_back_common],
    [SCORE_YELLOW_KEY]: [neckTilt_front_manage, neckTilt_back_common],
    [SCORE_RED_KEY]: [neckTilt_front_danger, neckTilt_back_common],
  },
  trunk_shift: {
    [SCORE_MINT_KEY]: [trunk_shift_front_fine, trunk_shift_back_fine],
    [SCORE_YELLOW_KEY]: [trunk_shift_front_manage, trunk_shift_back_manage],
    [SCORE_RED_KEY]: [trunk_shift_front_danger, trunk_shift_back_danger],
  },
  pelvic_shift: {
    front: {
      [SCORE_MINT_KEY]: [
        pelvic_shift_front_front_fine,
        pelvic_shift_front_back_fine,
      ],
      [SCORE_YELLOW_KEY]: [
        pelvic_shift_front_front_manage,
        pelvic_shift_front_back_manage,
      ],
      [SCORE_RED_KEY]: [
        pelvic_shift_front_front_danger,
        pelvic_shift_front_back_danger,
      ],
    },
    back: {
      [SCORE_MINT_KEY]: [
        pelvic_shift_back_front_fine,
        pelvic_shift_back_back_fine,
      ],
      [SCORE_YELLOW_KEY]: [
        pelvic_shift_back_front_manage,
        pelvic_shift_back_back_manage,
      ],
      [SCORE_RED_KEY]: [
        pelvic_shift_back_front_danger,
        pelvic_shift_back_back_danger,
      ],
    },
  },
  knee_flexion: {
    [SCORE_MINT_KEY]: [knee_flexion_front_fine, knee_flexion_back_fine],
    [SCORE_YELLOW_KEY]: [knee_flexion_front_manage, knee_flexion_back_manage],
    [SCORE_RED_KEY]: [knee_flexion_front_danger, knee_flexion_back_danger],
  },
};

//todo
export const MUSCLE_DEASESES = {
  neck_tilt: [
    "Congenital torticollis",
    "Mucopolysaccharidosis",
    "Facial asymmetry",
    "TM joint dysfunction",
  ],
  shoulder_tilt: ["Scoliosis"],
  pelvic_tilt: ["Scoliosis", "Low back pain", "Sacroiliac Joint Syndrome"],
  pelvic_rotation: [
    "Scoliosis",
    "Back pain & Herniated disc (HD)",
    "Femoroacetabular impingement",
    "Sciatica",
    "Arthritis, Chondromalacia, Flat foot on one side",
  ],
  leg_alignment_left: [
    "Osteo Arthritis",
    "Degenerative arthritis",
    "Knee joint pain",
  ],
  head_shift: [
    "Neck tension & pain",
    "Text Neck",
    "Cervical herniated nucleus",
    "Migraine & Tension headache",
  ],
  //back
  trunk_shift: [
    "Round Shoulder",
    "Sway Back",
    "Thoracic outlet syndrome",
    "Limited ROM of shoulder joint",
  ],
  pelvic_shift: [
    "Back pain & Herniated disc (HD)",
    "Lumbar spondylolisthesis",
    "Facet Joint Syndrome",
  ],
  knee_flexion: ["Degenerative arthritis", "Knee joint pain", "Kyphosis"],
};
//todo translate
export const MUSCLE_IMPROVEMENT = {
  neck_tilt: [
    $L("Don't put your chin on your hand."),
    $L("When lying down, do not put your neck on your side."),
  ],
  shoulder_tilt: [
    "Do not lift the bag on one shoulder.",
    "Do not carry heavy bags.",
    "Do not study or work while leaning on one side of the desk.",
  ],
  pelvic_tilt: [
    "Do not cross your legs.",
    "Do not stand for a long time with your legs crossed.",
    "When you sit on a chair, pull your butt as much as possible to the inside of the chair and sit in the right position.",
    "When you work standing up, put weight on small chairs one by one under your feet.",
    "Wear low-heeled shoes.",
  ],
  pelvic_rotation: [
    "Do not cross your legs.",
    "Do not stand for a long time with your legs crossed.",
    "Do not stand for a long time leaning to one side.",
    "Walk with your back straight.",
    "Wear shoes with appropriate heels.",
  ],
  leg_alignment_left: [
    "Do not put your weight on one side of your foot when you are standing.",
    "Do not cross your legs.",
    "Do not walk on the inside leg.",
    "Do not cross your legs or cross your legs when sitting.",
  ],

  //right
  head_shift: [
    "Do not watch TV for a long time with your head tilted forward.",
    "Do not look down at your smartphone or book.",
    "Use a pillow that is not too high.",
    "Avoid smartphones for a long time and loosen muscles with frequent neck exercises.",
    "Keep your shoulders straight and your computer at eye level.",
  ],
  trunk_shift: [
    "When using a computer for a long time, straighten your spine through stretching.",
    "Use a pillow that is not high",
    "Do not sit in a crooked position for a long time.",
    "Don't use your waist when you lift things, use your legs.",
  ],
  pelvic_shift: [
    "When standing, apply weight to both feet and support the body.",
    "Do not cross your legs.",
    "When sitting in a chair, sit upright without bending your back excessively.",
    "Don't walk with a slingshot.",
    "When walking, raise your eyes by raising your neck, pull your chin, and walk with your waist up.",
    "Don't wear high heels or tall shoes often.",
    "Do not lead a sedentary life for a long time.",
  ],
  knee_flexion: [
    "Don't squat when you sit. (W position)",
    "Don't cross your legs.",
    "Do not cross your legs when sitting.",
    "Don't stay seated for a long time.",
  ],
};

export const MUSCLE_SILHOUETTE_IMAGE = {
  [MENUTYPE.HEAD_SHIFT]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_head_shift_anterior_front_good,
        silhouette_head_shift_anterior_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_head_shift_anterior_front_bad,
        silhouette_head_shift_anterior_back_bad,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_head_shift_posterior_front_good,
        silhouette_head_shift_posterior_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_head_shift_posterior_front_bad,
        silhouette_head_shift_posterior_back_bad,
      ],
    },
  },
  [MENUTYPE.LEG_ALIGNMENT]: {
    [MENUTYPE_TEST.BOW_LEG]: {
      [SCORE_MINT_KEY]: [
        silhouette_bow_leg_front_good,
        silhouette_bow_leg_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_bow_leg_front_bad,
        silhouette_bow_leg_back_bad,
      ],
    },
    [MENUTYPE_TEST.KNOCK_KNEE]: {
      [SCORE_MINT_KEY]: [
        silhouette_knock_knee_front_good,
        silhouette_knock_knee_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_knock_knee_front_bad,
        silhouette_knock_knee_back_bad,
      ],
    },
  },
  [MENUTYPE.PELVIC_TILT]: {
    [SCORE_MINT_KEY]: {
      LEFT: [
        silhouette_lateral_pelvic_tilt_front_good_LEFT,
        silhouette_lateral_pelvic_tilt_back_good_LEFT,
      ],
      RIGHT: [
        silhouette_lateral_pelvic_tilt_front_good_RIGHT,
        silhouette_lateral_pelvic_tilt_back_good_RIGHT,
      ],
    },
    [SCORE_RED_KEY]: {
      LEFT: [
        silhouette_lateral_pelvic_tilt_front_bad_LEFT,
        silhouette_lateral_pelvic_tilt_back_bad_LEFT,
      ],
      RIGHT: [
        silhouette_lateral_pelvic_tilt_front_bad_RIGHT,
        silhouette_lateral_pelvic_tilt_back_bad_RIGHT,
      ],
    },
  },
  [MENUTYPE.SHOULDER_TILT]: {
    [SCORE_MINT_KEY]: {
      LEFT: [
        silhouette_shoulder_tilt_front_good_LEFT,
        silhouette_shoulder_tilt_back_good_LEFT,
      ],
      RIGHT: [
        silhouette_shoulder_tilt_front_good_RIGHT,
        silhouette_shoulder_tilt_back_good_RIGHT,
      ],
    },
    [SCORE_RED_KEY]: {
      LEFT: [
        silhouette_shoulder_tilt_front_bad_LEFT,
        silhouette_shoulder_tilt_back_bad_LEFT,
      ],
      RIGHT: [
        silhouette_shoulder_tilt_front_bad_RIGHT,
        silhouette_shoulder_tilt_back_bad_RIGHT,
      ],
    },
  },
  [MENUTYPE.NECK_TILT]: {
    [SCORE_MINT_KEY]: {
      LEFT: [
        silhouette_neck_tilt_front_good_LEFT,
        silhouette_neck_tilt_back_good_LEFT,
      ],
      RIGHT: [
        silhouette_neck_tilt_front_good_RIGHT,
        silhouette_neck_tilt_back_good_RIGHT,
      ],
    },
    [SCORE_RED_KEY]: {
      LEFT: [
        silhouette_neck_tilt_front_bad_LEFT,
        silhouette_neck_tilt_back_bad_LEFT,
      ],
      RIGHT: [
        silhouette_neck_tilt_front_bad_RIGHT,
        silhouette_neck_tilt_back_bad_RIGHT,
      ],
    },
  },
  [MENUTYPE.PELVIC_ROTATION]: {
    [SCORE_MINT_KEY]: {
      LEFT: [
        silhouette_pelvic_rotation_front_good_LEFT,
        silhouette_pelvic_rotation_back_good_LEFT,
      ],
      RIGHT: [
        silhouette_pelvic_rotation_front_good_RIGHT,
        silhouette_pelvic_rotation_back_good_RIGHT,
      ],
    },
    [SCORE_RED_KEY]: {
      LEFT: [
        silhouette_pelvic_rotation_front_bad_LEFT,
        silhouette_pelvic_rotation_back_bad_LEFT,
      ],
      RIGHT: [
        silhouette_pelvic_rotation_front_bad_RIGHT,
        silhouette_pelvic_rotation_back_bad_RIGHT,
      ],
    },
  },
  [MENUTYPE.TRUNK_SHIFT]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_shoulder_shift_anterior_front_good,
        silhouette_shoulder_shift_anterior_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_shoulder_shift_anterior_front_bad,
        silhouette_shoulder_shift_anterior_back_bad,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_shoulder_shift_posterior_front_good,
        silhouette_shoulder_shift_posterior_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_shoulder_shift_posterior_front_bad,
        silhouette_shoulder_shift_posterior_back_bad,
      ],
    },
  },
  [MENUTYPE.PELVIC_SHIFT]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_pelvic_shift_anterior_front_good,
        silhouette_pelvic_shift_anterior_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_pelvic_shift_anterior_front_bad,
        silhouette_pelvic_shift_anterior_back_bad,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_pelvic_shift_posterior_front_good,
        silhouette_pelvic_shift_posterior_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_pelvic_shift_posterior_front_bad,
        silhouette_pelvic_shift_posterior_back_bad,
      ],
    },
  },
  [MENUTYPE.KNEE_FLEXION]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_knee_flexion_front_good,
        silhouette_knee_flexion_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_knee_flexion_front_bad,
        silhouette_knee_flexion_back_bad,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [SCORE_MINT_KEY]: [
        silhouette_knee_extension_front_good,
        silhouette_knee_extension_back_good,
      ],
      [SCORE_RED_KEY]: [
        silhouette_knee_extension_front_bad,
        silhouette_knee_extension_back_bad,
      ],
    },
  },
};

export const MUSCLE_DETAIL_IMAGE = {
  [MENUTYPE.HEAD_SHIFT]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        head_shift_anterior_front_tight,
        head_shift_anterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        head_shift_anterior_front_elongated,
        head_shift_anterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        head_shift_anterior_front_stabilizing,
        head_shift_anterior_back_stabilizing,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        head_shift_posterior_front_tight,
        head_shift_posterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        head_shift_posterior_front_elongated,
        head_shift_posterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        head_shift_posterior_front_stabilizing,
        head_shift_posterior_back_stabilizing,
      ],
    },
  },
  [MENUTYPE.LEG_ALIGNMENT]: {
    [MENUTYPE_TEST.BOW_LEG]: {
      [FEEDBACK_TYPE.TIGHT]: [
        leg_alignment_o_front_tight,
        leg_alignment_o_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        leg_alignment_o_front_elongated,
        leg_alignment_o_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        leg_alignment_o_front_stabilizing,
        leg_alignment_o_back_stabilizing,
      ],
    },
    [MENUTYPE_TEST.KNOCK_KNEE]: {
      [FEEDBACK_TYPE.TIGHT]: [
        leg_alignment_x_front_tight,
        leg_alignment_x_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        leg_alignment_x_front_elongated,
        leg_alignment_x_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        leg_alignment_x_front_stabilizing,
        leg_alignment_x_back_stabilizing,
      ],
    },
  },
  [MENUTYPE.PELVIC_TILT]: {
    [FEEDBACK_TYPE.TIGHT]: {
      LEFT: [pelvic_tilt_front_tight_LEFT, pelvic_tilt_back_tight_LEFT],
      RIGHT: [pelvic_tilt_front_tight_RIGHT, pelvic_tilt_back_tight_RIGHT],
    },
    [FEEDBACK_TYPE.ELONGATED]: {
      LEFT: [pelvic_tilt_front_elongated_LEFT, pelvic_tilt_back_elongated_LEFT],
      RIGHT: [
        pelvic_tilt_front_elongated_RIGHT,
        pelvic_tilt_back_elongated_RIGHT,
      ],
    },
    [FEEDBACK_TYPE.STABILIZING]: {
      LEFT: [
        pelvic_tilt_front_stabilizing_LEFT,
        pelvic_tilt_back_stabilizing_LEFT,
      ],
      RIGHT: [
        pelvic_tilt_front_stabilizing_RIGHT,
        pelvic_tilt_back_stabilizing_RIGHT,
      ],
    },
  },
  [MENUTYPE.SHOULDER_TILT]: {
    [FEEDBACK_TYPE.TIGHT]: {
      LEFT: [shoulder_tilt_front_tight_LEFT, shoulder_tilt_back_tight_LEFT],
      RIGHT: [shoulder_tilt_front_tight_RIGHT, shoulder_tilt_back_tight_RIGHT],
    },
    [FEEDBACK_TYPE.ELONGATED]: {
      LEFT: [
        shoulder_tilt_front_elongated_LEFT,
        shoulder_tilt_back_elongated_LEFT,
      ],
      RIGHT: [
        shoulder_tilt_front_elongated_RIGHT,
        shoulder_tilt_back_elongated_RIGHT,
      ],
    },
    [FEEDBACK_TYPE.STABILIZING]: {
      LEFT: [
        shoulder_tilt_front_stabilizing_LEFT,
        shoulder_tilt_back_stabilizing_LEFT,
      ],
      RIGHT: [
        shoulder_tilt_front_stabilizing_RIGHT,
        shoulder_tilt_back_stabilizing_RIGHT,
      ],
    },
  },
  [MENUTYPE.PELVIC_ROTATION]: {
    [FEEDBACK_TYPE.TIGHT]: {
      LEFT: [pelvic_rotation_front_tight_LEFT, pelvic_rotation_back_tight_LEFT],
      RIGHT: [
        pelvic_rotation_front_tight_RIGHT,
        pelvic_rotation_back_tight_RIGHT,
      ],
    },
    [FEEDBACK_TYPE.ELONGATED]: {
      LEFT: [
        pelvic_rotation_front_elongated_LEFT,
        pelvic_rotation_back_elongated_LEFT,
      ],
      RIGHT: [
        pelvic_rotation_front_elongated_RIGHT,
        pelvic_rotation_back_elongated_RIGHT,
      ],
    },
    [FEEDBACK_TYPE.STABILIZING]: {
      LEFT: [
        pelvic_rotation_front_stabilizing_LEFT,
        pelvic_rotation_back_stabilizing_LEFT,
      ],
      RIGHT: [
        pelvic_rotation_front_stabilizing_RIGHT,
        pelvic_rotation_back_stabilizing_RIGHT,
      ],
    },
  },
  [MENUTYPE.NECK_TILT]: {
    [FEEDBACK_TYPE.TIGHT]: {
      LEFT: [neck_tilt_front_tight_LEFT, neck_tilt_back_tight_LEFT],
      RIGHT: [neck_tilt_front_tight_RIGHT, neck_tilt_back_tight_RIGHT],
    },
    [FEEDBACK_TYPE.ELONGATED]: {
      LEFT: [neck_tilt_front_elongated_LEFT, neck_tilt_back_elongated_LEFT],
      RIGHT: [neck_tilt_front_elongated_RIGHT, neck_tilt_back_elongated_RIGHT],
    },
    [FEEDBACK_TYPE.STABILIZING]: {
      LEFT: [neck_tilt_front_stabilizing_LEFT, neck_tilt_back_stabilizing_LEFT],
      RIGHT: [
        neck_tilt_front_stabilizing_RIGHT,
        neck_tilt_back_stabilizing_RIGHT,
      ],
    },
  },
  [MENUTYPE.TRUNK_SHIFT]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        shoulder_shift_anterior_front_tight,
        shoulder_shift_anterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        shoulder_shift_anterior_front_elongated,
        shoulder_shift_anterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        shoulder_shift_anterior_front_stabilizing,
        shoulder_shift_anterior_back_stabilizing,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        shoulder_shift_posterior_front_tight,
        shoulder_shift_posterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        shoulder_shift_posterior_front_elongated,
        shoulder_shift_posterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        shoulder_shift_posterior_front_stabilizing,
        shoulder_shift_posterior_back_stabilizing,
      ],
    },
  },
  [MENUTYPE.PELVIC_SHIFT]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        pelvic_shift_anterior_front_tight,
        pelvic_shift_anterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        pelvic_shift_anterior_front_elongated,
        pelvic_shift_anterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        pelvic_shift_anterior_front_stabilizing,
        pelvic_shift_anterior_back_stabilizing,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        pelvic_shift_posterior_front_tight,
        pelvic_shift_posterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        pelvic_shift_posterior_front_elongated,
        pelvic_shift_posterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        pelvic_shift_posterior_front_stabilizing,
        pelvic_shift_posterior_back_stabilizing,
      ],
    },
  },
  [MENUTYPE.KNEE_FLEXION]: {
    [MENUTYPE_TEST.ANTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        knee_flexion_anterior_front_tight,
        knee_flexion_anterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        knee_flexion_anterior_front_elongated,
        knee_flexion_anterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        knee_flexion_anterior_front_stabilizing,
        knee_flexion_anterior_back_stabilizing,
      ],
    },
    [MENUTYPE_TEST.POSTERIOR]: {
      [FEEDBACK_TYPE.TIGHT]: [
        knee_flexion_posterior_front_tight,
        knee_flexion_posterior_back_tight,
      ],
      [FEEDBACK_TYPE.ELONGATED]: [
        knee_flexion_posterior_front_elongated,
        knee_flexion_posterior_back_elongated,
      ],
      [FEEDBACK_TYPE.STABILIZING]: [
        knee_flexion_posterior_front_stabilizing,
        knee_flexion_posterior_back_stabilizing,
      ],
    },
  },
};
