import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spotlight from '@enact/spotlight';
import css from "./CameraSetting.module.less";
import TButton from '../../../components/TButton';
import SmoodShowingAnimation from "../../../components/SmoodShowingAnimation";
import { $L } from '../../../utils/helperMethods';
import TScroller from '../../../components/TScroller';
import TSettingSection from '../../../components/TSettingSection';
import SpotlightIds from '../../../utils/SpotlightIds';
import { changeActivatedCameraId } from "../../../features/camera/cameraSlice";

const CameraSetting = ({...rest}) => {
  const dispatch = useDispatch();
  const {cameraList, activatedCameraId, updatingCameraList} = useSelector(state => state.camera);

  const itemRenderer = useCallback(()=>{
    if(updatingCameraList){
      return (
        <TButton spotlightDisabled disabled size="small">
          {$L("Searching...")}
        </TButton>
      );
    }else{
      return null;
    }
  },[updatingCameraList]);

  const onClick = useCallback((idx)=>()=>{
    dispatch(changeActivatedCameraId(cameraList[idx].deviceId));
  },[dispatch, cameraList]);

  useEffect(()=>{
    if(!Spotlight.getCurrent()){
      Spotlight.focus(SpotlightIds.SETTINGS_MAIN_TOPLIST);
      Spotlight.focus(SpotlightIds.SETTINGS_SUB_CONNECTION);
      Spotlight.focus(SpotlightIds.SETTINGS_SUB_CAMERA_SCROLLER);
    }
  },[activatedCameraId, cameraList]);

  return (
    <div className={css.container} {...rest}>
      {/* <TSettingSection
          className={css.listHeader}
          type="underLine"
          text={$L("Camera list")}
          itemRenderer={itemRenderer}
      /> */}
        <TScroller className={css.tscroller} spotlightId={SpotlightIds.SETTINGS_SUB_CAMERA_SCROLLER}>
          {cameraList.map((item, idx)=>{
            return (
              <TSettingSection
                key={"camerasetting"+idx}
                className={css.setting}
                text={item.label}
                subText={activatedCameraId === item.deviceId ? (
                  <div className={css.subText}>
                    <span>{$L("Connecting...")}</span>
                  </div>
                ) : null}
                button1={{ text: activatedCameraId === item.deviceId ? $L(""): $L("Connection") }}
                onClick={onClick(idx)}
              />
            )
          })}
        </TScroller>
    </div>
  );
};

export default SmoodShowingAnimation(CameraSetting);
