import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";
import classNames from "classnames";
import { $L } from '../../../utils/helperMethods';
import css from './HPRoutineProComposition.module.less';
import { addPanels, popPanel } from '../../../features/panels/panelsSlice';
import { changeAppStatus } from '../../../features/common/commonSlice';
import { panel_names } from '../../../utils/Config';
import TPanel from '../../../components/TPanel';
import THeader from "../../../components/THeader";
import TBody from '../../../components/TBody';
import TScroller from "../../../components/TScroller/TScroller";
import TButton, { COLOR, SIZES } from '../../../components/TButton/TButton';
import TIconButton, { ICON_TYPES } from '../../../components/TIconButton/TIconButton';
import TTimeLine from './TTimeLine/TTimeLine';
import BarItem from "./BarItem/BarItem";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import useUserCheck from '../../../hooks/useUserCheck';

import upload from "../../../../assets/icon/upload_Icon.png";
import age from "../../../../assets/icon/age_Icon.png";
import time from "../../../../assets/icon/time_Icon.png";
import test from "../../../../assets/icon/test_Icon.png";
import level from "../../../../assets/icon/level_Icon.png";
import dummyImage from "../../../../assets/HomecareProgram/dummyImage.png";
import ArrowUp from "../../../../assets/HomecareProgram/ic_arrow_up.svg";
import ArrowDown from "../../../../assets/HomecareProgram/ic_arrow_down.svg";
// todo remove demo
import dummy_pro_composition from  "../../../../assets/images/dummy_pro_composition.png"

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);

const SpottableComponent = Spottable("div");

const HPRoutineProComposition = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const scrollTo = useRef(null);
  const { userCheck } = useUserCheck();
  const weeklyList = useSelector((state) => state.WeeklyStatus.weeklyList);
  const [scrollMode, setScrollMode] = useState('translate');
  const [expanded, setExpanded] = useState(Array(weeklyList.length).fill(false));

  const toggleExpanded = useCallback((index) => {
    setExpanded(prevExpandedList => {
      const updatedList = prevExpandedList.map((item, idx) => (idx === index ? !item : item));
      return updatedList;
    });
  }, []);

  const proSituationDummy = useMemo(() => {
    const dummy = {
      tag: 'NEW',
      title: $L("스마트 시니어를 위한 건강 예방 프로그램"),
      img: dummy_pro_composition,
      str: $L("약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다. "),
      conditions: [
        { title: $L("2023.12.11"), img: upload },
        { title: $L("스트레칭"), img: test },
        { title: $L("시니어"), img: age },
        { title: $L("5~30분 운동"), img: time },
        { title: $L("초급 · 중강도"), img: time }
      ]
    };
    return dummy;
  }, []);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

	const scrollToTop = useCallback(() => {
    const target = document.querySelector(`[data-spotlight-id="proComposition_scroller"]`)?.childNodes[1]?.childNodes[0]?.childNodes[0];

    if (target) {
      if (scrollTo && scrollTo.current) {
        Spotlight.focus(target);
        setScrollMode('native');
        scrollTo.current({ position: { y: 0 }, animate: true });
      }
    }
	}, []);

  const onScrollStop = useCallback(() => {
    if (scrollMode === 'native') {
      setScrollMode('translate');
    }
  }, [scrollMode]);

  const gotoProgram = useCallback(() => {
    if(!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'genaral' } }));
    } else {
      // dispatch(addPanels({ name: panel_names.HOMECARE_ROUTINE_PROGRAM, panelInfo: panelInfo }));
      dispatch(popPanel());
    }
	},[]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("프로그램 구성")}/>
      <TBody className={css.tBody}>
        <TScroller spotlightId='proComposition_scroller' className={css.tScroller} cbScrollTo={getScrollTo} scrollMode={scrollMode} onScrollStop={onScrollStop}>
          <div className={css.programSituation}>
            <Container className={css.contentContainer}>
              <div className={css.programSituationTitle}>{$L("프로그램 현황")}</div>
              <div className={css.flexBox}>
                <img src={proSituationDummy.img} className={css.course} />
                <div className={css.programSituationInfo}>
                  <div className={css.customContTitSect}>
                    {/* <span className={css.tag}>{proSituationDummy.tag}</span> */}
                    <div className={css.customContTit}>{proSituationDummy.title}</div>
                  </div>
                  <div className={css.courseStr}>{proSituationDummy.str}</div>
                  <div className={css.summaryFlexBox}>
                    {proSituationDummy.conditions.map((condition, index) => {
                      return (
                        <div className={css.align} key={index}>
                          <span style={{ backgroundImage: `url(${condition.img})` }}></span>
                          {condition.title}
                        </div>
                      );
                    })}
                  </div>
                  <TButton size={SIZES.large} color={COLOR.brown} onClick={gotoProgram}>{$L("Start the program")}</TButton>
                </div>
              </div>
            </Container>
            {weeklyList && weeklyList.length > 0 && weeklyList.map((weekly, index) => (
              <SpottableComponent className={css.WeeklyStatusBox} key={`weekly-${index}`} onClick={() => toggleExpanded(index)}>
                <img className={css.arrow} src={expanded[index] ? ArrowUp : ArrowDown} alt="" />
                <div className={expanded[index] ? classNames(css.statusCollapsed, css.marginTop) : css.statusCollapsed}>
                  <div className={css.weekly}>
                    <div className={css.weeklyTitle}>{weekly.title}</div>
                    <div className={css.exerciseStatus}>{weekly.status}</div>
                  </div>
                  <div className={css.weeklyInfo}>
                    <div className={css.weeklyInfoTitle}>{weekly.infoTitle}</div>
                    <div className={css.weeklyInfoCont}>{weekly.infoCont}</div>
                    <div className={css.completedExercise}>
                      <div className={css.completedExerciseCont}>{$L("완료한 운동")} <span>{weekly.completedExercise}</span>/3</div>
                      <BarItem className={css.bar} completedExercise={weekly.completedExercise} />
                    </div>
                  </div>
                </div>

                {expanded[index] && weekly.dailyList && weekly.dailyList.length > 0 && weekly.dailyList.map((daily, idx) => (
                  <TTimeLine key={`dailyList_${idx}`} className={css.timeLine} daily={daily} idx={idx} />
                ))}
              </SpottableComponent>
            ))}
            <TIconButton iconType={ICON_TYPES.top} className={css.topBtn} onClick={scrollToTop} />
          </div>
        </TScroller>
      </TBody>
    </TPanel>
  );
};

export default HPRoutineProComposition;