import css from './MyPanel.module.less';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TPanel from '../../components/TPanel/TPanel';
import THeader from '../../components/THeader';
import TBody from '../../components/TBody/TBody';
import TSimpleButtonTab, {LIST_TYPE} from '../../components/TSimpleButtonTab/TSimpleButtonTab';
import TScroller from '../../components/TScroller/TScroller';
import { $L } from '../../utils/helperMethods';
import MyProfile from './MyProfile/MyProfile';
import MyBodyProfile from './MyBodyProfile/MyBodyProfile';
import MyAvatar from './MyAvatar/MyAvatar';
import MyCommerce from './MyCommerce/MyCommerce';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { changeThemeModeStatus } from "../../features/common/commonSlice";
import MySureveyInfo from './MySureveyInfo/MySurveyInfo';

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const getSimpleButtonList = () => {
  return [$L("Profile"), $L("Body Profile"), $L("설문정보"), $L("Avatar")];
};
let simpleButtonList = null;

const MyPanel = ({panelInfo, ...rest}) => {
  if (!simpleButtonList) {
    simpleButtonList = getSimpleButtonList();
  }
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const scrollTo = useRef();
  const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, []);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

	useEffect(() => {
		if (scrollTo && scrollTo.current) {
			scrollTo.current({ position: { y: 0 }, animate: false });
		}
	}, [selectedTabIndex]);

  useEffect(() => {
    if(panelInfo.selectedTabIndex) {
      setSelectedTabIndex(panelInfo.selectedTabIndex);
    }
  }, [panelInfo]);

  const onItemClick = useCallback(({index})=>{
    setSelectedTabIndex(index);
  },[]);

  const disabledItem = useMemo(() => {
    return [$L("Body Profile")];
  },[]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("My")} />
      <TBody className={css.body}>
        {simpleButtonList && simpleButtonList.length > 0 &&
          <TSimpleButtonTab
            className={css.tab}
            listType={LIST_TYPE.header} contents={simpleButtonList} selectedIndex={selectedTabIndex}
            onItemClick={onItemClick}
            disabledItem ={disabledItem}
          />
        }
        {selectedTabIndex === 0 &&
          <MyProfile/>
        }
        {selectedTabIndex === 1 &&
          <MyBodyProfile/>
        }
        {selectedTabIndex === 2 &&
          <MySureveyInfo/>
        }
        {selectedTabIndex === 3 &&
          <MyAvatar/>
        }


      </TBody>

    </TPanel>
  );
};

export default MyPanel;
