import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spotlight from "@enact/spotlight";
import css from "./BodyBalanceDetailsReport.module.less";
import * as Config from "../../../../utils/Config";
import { addPanels, popPanel } from "../../../../features/panels/panelsSlice";
import THeader from "../../../../components/THeader/THeader";
import TPanel from "../../../../components/TPanel/TPanel";
import TBody from "../../../../components/TBody/TBody";
import { bodyBalanceMenu } from "../BodyBalanceReport/Constants";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

//component
import FootTypeDetails from "../BodyBalanceReport/TabContents/FootTypeDetails";
import BodyBalanceTabDetails from "../BodyBalanceReport/TabContents/BodyBalanceTabDetails";
import SmartMatPopup from "../../../SmartMat/SmartMatPopup";
import { changeAppStatus } from "../../../../features/common/commonSlice";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const BodyBalanceDetailsReport = ({
  panelInfo,
  renderDetailsReport,
  ...rest
}) => {
  const selectedTabIndex = panelInfo.selectedTabIndex;
  console.log("selectedTabIndex", selectedTabIndex);
  const menuType = panelInfo.menuType;
  const menuTitle = panelInfo.menuTitle;
  const currentData = panelInfo.currentData;
  const dispatch = useDispatch();
  const [showSmartMatPopup, setShowSmartMatPopup] = useState(false);
  const gattConnectingStatus = useSelector(
    (state) => state.ble.gattConnectingStatus
  );
  const connectStatus = gattConnectingStatus.connectStatus;
  const connectStatusRef = useRef(connectStatus);

  const BODYBALANCE_TEST_ACTIONS = {
    [bodyBalanceMenu.FOOT_TYPE]: 0,
    [bodyBalanceMenu.BODY_BALANCE_TAB]: 0,
    [bodyBalanceMenu.COP]: 1,
  };

  useEffect(() => {
    connectStatusRef.current = connectStatus;
  }, [connectStatus]);

  useEffect(() => {
    Spotlight.focus("testBtn");
  }, []);

  const detailsComponentToRender = useCallback(() => {
    switch (menuType) {
      case bodyBalanceMenu.FOOT_TYPE:
        return (
          <FootTypeDetails
            currentData={panelInfo.currentData}
            checkConnectionMat={checkConnectionMat}
          />
        );
      case bodyBalanceMenu.BODY_BALANCE_TAB:
        return (
          <BodyBalanceTabDetails
            tabType={bodyBalanceMenu.BODY_BALANCE_TAB}
            checkConnectionMat={checkConnectionMat}
          />
        );
      case bodyBalanceMenu.COP:
        return (
          <BodyBalanceTabDetails
            tabType={bodyBalanceMenu.COP}
            checkConnectionMat={checkConnectionMat}
          />
        );
      default:
        return;
    }
  }, [menuType, currentData]);

  const detailsTitleToRender = useCallback(() => {
    switch (menuType) {
      case bodyBalanceMenu.FOOT_TYPE:
        return menuTitle;
      case bodyBalanceMenu.BODY_BALANCE_TAB:
        return menuTitle;
      case bodyBalanceMenu.COP:
        return menuTitle;
      default:
        return;
    }
  }, [menuType]);

  const goToBodyBalanceTest = useCallback(() => {
    const action = BODYBALANCE_TEST_ACTIONS[menuType];
    console.log("action", action);
    if (typeof action === "function") {
      action();
    } else if (typeof action === "number") {
      dispatch(
        addPanels({
          name: Config.panel_names.BODY_BALANCE_TEST,
          panelInfo: { bbtList: [action] },
        })
      );
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
    }
  }, [menuType, selectedTabIndex]);

  const checkConnectionMat = useCallback(() => {
    console.log("checkConnectionMat", connectStatus);
    if (connectStatusRef.current !== "connected") {
      setShowSmartMatPopup(true);
    } else {
      goToBodyBalanceTest();
    }
  }, [connectStatus]);

  const closeSmartMatPopup = useCallback(() => {
    setShowSmartMatPopup(false);
  }, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={detailsTitleToRender()} iconType="back" />
      <TBody className={css.tBody}>
        <Container
          className={css.detailsReportSect}
          style={
            menuType === bodyBalanceMenu.FOOT_TYPE
              ? {
                  marginTop: "86px",
                  borderRadius: "0 30px 30px 30px",
                  height: "788px",
                }
              : {}
          }
        >
          {/* 각각 컴포넌트 import */}
          {detailsComponentToRender()}
        </Container>
        <div className={css.medicalCenter} />
        {/* SmartMat Popup */}
        {showSmartMatPopup && <SmartMatPopup onClose={closeSmartMatPopup} />}
      </TBody>
    </TPanel>
  );
};

export default BodyBalanceDetailsReport;
