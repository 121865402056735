import {$L} from "../utils/helperMethods"

let surveyData = null;
let resetAnswerData = null;

export const getSurveyData = () => {
  if(!surveyData){
    surveyData = [
      {
        id: "age",
        part: "basic",
        title: $L("Current Age"),
        answer_form: "dropdown",
        answer_max: 1,
        sub_yn: "N",
        direction: "horizontal",
        article: Array.from({ length: 101 }, (_, index) => ({ seq: index, article_title: `${index}세` })),
      },

      {
        id: "sex",
        part: "basic",
        title: $L("Gender"),
        answer_form: "radio",
        answer_max: 1,
        sub_yn: "N",
        direction: "horizontal",
        article: [
          { seq: 0, article_title: $L("Male") },
          { seq: 1, article_title: $L("Female") },
        ],
      },
      {
        id: "height",
        part: "basic",
        title: $L("Height"),
        answer_form: "dropdown",
        answer_max: 1,
        sub_yn: "N",
        direction: "horizontal",
        article: Array.from({ length: 141 }, (_, index) => ({ seq: index, article_title: `${60+index}cm` })),
      },
      {
        id: "weight",
        part: "basic",
        title: $L("Weight"),
        answer_form: "dropdown",
        answer_max: 1,
        sub_yn: "N",
        direction: "horizontal",
        article: Array.from({ length: 181 }, (_, index) => ({ seq: index, article_title: `${20+index}kg` })),
      },
      {
        id: "disease",
        part: "body",
        title: $L(`1. 현재 [고객명]님께서 보유하고 있는 질환을 선택해주세요.`),
        answer_form: "checkbox",
        answer_max: 5,
        sub_yn: "N",
        none_seq: 4,
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("Hypertension") },
          { seq: 1, article_title: $L("Cardiovascular (myocardial infarction/angina)")},
          { seq: 2, article_title: $L("Respiratory diseases (asthma, chronic obstructive pulmonary disease)")},
          { seq: 3, article_title: $L("Diabetes") },
          { seq: 4, article_title: $L("N/A")},
        ],

      },
      {
        id: "disability",
        part: "body",
        title:
          "2. " +
          $L(
            "Do you have any other medical conditions that limit your physical activity?"
          ),
        answer_form: "radio",
        answer_max: 1,
        sub_yn: "N",
        direction: "horizontal",
        article: [
          { seq: 0, article_title: $L("Yes")},
          { seq: 1, article_title: $L("No")},
        ],
      },
      {
        id: "pain",
        part: "body",
        title: "3. " + $L("Select the area where you feel pain. (up to 3 areas)"),
        answer_form: "checkbox",
        answer_max: 3,
        sub_yn: "Y",
        none_seq: 13,
        direction: "vertical",
        article: [
          {
            seq: 0,
            article_id: "neck",
            article_title: $L("Neck"),
            sub_title: "[" + $L("Neck") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "neck_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "neck_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "neck_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 1,
            article_id: "shoulder",
            article_title: $L("Shoulder"),
            sub_title:  "[" + $L("Shoulder") + "]" + $L("Enter your pain-related information."),
            isActive: true,
            sub_survey: [
              {
                id: "shoulder_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "shoulder_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "shoulder_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 2,
            article_id: "elbow",
            article_title: $L("Elbow"),
            sub_title:  "[" + $L("Elbow") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "elbow_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "elbow_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "elbow_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]

          },
          {
            seq: 3,
            article_id: "wrist",
            article_title: $L("Wrist"),
            sub_title:  "[" + $L("Wrist") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "wrist_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "wrist_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "wrist_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 4,
            article_id: "back",
            article_title: $L("Back"),
            sub_title: "[" + $L("Back") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "back_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "back_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "back_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 5,
            article_id: "waist",
            article_title: $L("Trunk"),
            sub_title:  "[" + $L("Trunk") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "waist_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "waist_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "waist_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 6,
            article_id: "pelvis",
            article_title: $L("Pelvic"),
            sub_title:  "[" + $L("Pelvic") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "pelvis_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "pelvis_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "pelvis_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 7,
            article_id: "hips",
            article_title: $L("hips"),
            sub_title:  "[" + $L("hips") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "hips_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "hips_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "hips_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 8,
            article_id: "thigh",
            article_title: $L("thigh"),
            sub_title:  "[" + $L("thigh") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "thigh_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "thigh_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "thigh_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 9,
            article_id: "calf",
            article_title: $L("calf"),
            sub_title:  "[" + $L("calf") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "calf_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "calf_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "calf_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 10,
            article_id: "ankle",
            article_title: $L("Ankles"),
            sub_title: "[" + $L("Ankles") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "ankle_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "ankle_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "ankle_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 11,
            article_id: "sole",
            article_title: $L("Sole"),
            sub_title: "[" + $L("Sole") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "sole_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "sole_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "sole_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 12,
            article_id: "knee",
            article_title: $L("Knee"),
            sub_title:  "[" + $L("Knee") + "]" + $L("Enter your pain-related information."),
            sub_survey: [
              {
                id: "knee_surgery",
                title: $L("Did you have a musculoskeletal surgery in the past year?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "knee_treatment",
                title: $L("Did you get a musculoskeletal treatment (drug/procedure) in the past three months?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "horizontal",
                article: [
                  {seq: 0, article_title: $L("Yes")},
                  {seq: 1, article_title: $L("No")}
                ]
              },
              {
                id: "knee_intensity",
                title: $L("Do you feel any pain that affects your everyday life? (Choose between 0 through 10)"),
                answer_form: "slider",
                answer_max: 1,
                direction: "vertical",
                article: []
              },
            ]
          },
          {
            seq: 13,
            article_title: $L("N/A"),
          },
        ],
      },
      {
        id: "purpose",
        part: "exercise",
        title: $L(`1. [고객명]님의 운동목적은 무엇인가요?`),
        answer_form: "checkbox",
        answer_max: 8,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("Diet") },
          { seq: 1, article_title: $L("Physique Management (beauty)")},
          { seq: 2, article_title: $L("Strengthening muscles") },
          { seq: 3, article_title: $L("Maintaining health (vigor)") },
          { seq: 4, article_title: $L("Posture correction")},
          { seq: 5, article_title: $L("Management of chronic conditions")},
          {
            seq: 6,
            article_title: $L("Rehabilitation for musculoskeletal disorders"),
          },
          {
            seq: 7,
            article_title: $L("Others (Sense of achievement/Fun/Socializing)"),
          },
        ],
      },
      {
        id: "interest",
        part: "exercise",
        title:
          "2. " +
          $L("Which body part are you interested in to become healthier?"),
        answer_form: "checkbox",
        answer_max: 7,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("Full-body")},
          { seq: 1, article_title: $L("팔") },
          { seq: 2, article_title: $L("Chest")},
          { seq: 3, article_title: $L("Abs") },
          { seq: 4, article_title: $L("Legs (thighs, calves)") },
          { seq: 5, article_title: $L("Back")},
          { seq: 6, article_title: $L("엉덩이")},
        ],
      },
      {
        id: "preference",
        part: "exercise",
        title: "3. " + $L("Select your preferred exercise."),
        answer_form: "checkbox",
        answer_max: 8,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("스트레칭")},
          { seq: 1, article_title: $L("Cardiovascular workouts")},
          { seq: 2, article_title: $L("Muscle workouts") },
          { seq: 3, article_title: $L("요가, 필라테스")},
          { seq: 4, article_title: $L("댄스")},
          { seq: 5, article_title: $L("Treadmill")},
          { seq: 6, article_title: $L("Spinning")},
          {
            seq: 7,
            article_title: $L(
              "Rehabilitation exercises for chronic musculoskeletal disorders"
            ),
          },
        ],
      },
      {
        id: "desire",
        part: "exercise",
        title: "4. " + $L("How do you stay motivated?"),
        answer_form: "checkbox",
        answer_max: 8,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("Gaining confidence")},
          { seq: 1, article_title: $L("Relieving stress") },
          { seq: 2, article_title: $L("Muscle workouts") },
          { seq: 3, article_title: $L("Energy activation")},
        ],
      },
      {
        id: "frequency",
        part: "exercise",
        title: "5. " + $L("What is your target number of weekly exercise?"),
        answer_form: "dropdown",
        placeholder: $L("Select number of times"),
        answer_max: 1,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("1회")},
          { seq: 1, article_title: $L("2회")},
          { seq: 2, article_title: $L("3회")},
          { seq: 3, article_title: $L("4회")},
          { seq: 4, article_title: $L("5회")},
          { seq: 5, article_title: $L("6회")},
          { seq: 6, article_title: $L("7회")},
          { seq: 7, article_title: $L("8회")},
          { seq: 8, article_title: $L("9회")},
          { seq: 9, article_title: $L("10회")},
        ],
      },
      {
        id: "time",
        part: "exercise",
        title: "6. " + $L("How long do you exercise in a day?"),
        answer_form: "radio",
        answer_max: 1,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("15분")},
          { seq: 1, article_title: $L("30분")},
          { seq: 2, article_title: $L("45분")},
          { seq: 3, article_title: $L("60분")},
        ],
      },
      {
        id: "intensity",
        part: "exercise",
        title:  "7. " + $L("What is the target intensity of your workout?"),
        answer_form: "none",
        answer_max: 1,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0,
            id: "intensity_low",
            title: $L("Low-intensity"),
            answer_form: "dropdown",
            answer_max: 1,
            direction: "horizontal",
            article: Array.from({ length: 11 }, (_, index) => ({ seq: index, article_title: `${index * 10}%` })),
            description: $L("Low-intensity (2.9 MET or lower): Waking (1.6-3.2 km/h), walking in water"),
          },
          { seq: 1,
            id: "intensity_mid",
            title: $L("Medium-intensity"),
            answer_form: "dropdown",
            answer_max: 1,
            direction: "horizontal",
            article: Array.from({ length: 11 }, (_, index) => ({ seq: index, article_title: `${index * 10}%` })),
            description: $L("Medium-intensity (3-5.9 MET): Walking (4.8-6.4 km/h), bike"),
          },
          { seq: 2,
            id: "intensity_high",
            title: $L("고강도"),
            answer_form: "dropdown",
            answer_max: 1,
            direction: "horizontal",
            article: Array.from({ length: 11 }, (_, index) => ({ seq: index, article_title: `${index * 10}%` })),
            description: $L(
              "High-intensity (XX MET or higher): Running (7 km/h or faster), swimming, jumping ropes, soccer"
            ),
          },
        ],
      },
      {
        id: "level",
        part: "exercise",
        title: "8. " + $L("What is your current workout intensity?"),
        answer_form: "radio",
        answer_max: 1,
        sub_yn: "N",
        direction: "vertical",
        article: [
          { seq: 0, article_title: $L("30분 이상 주 1회")},
          { seq: 1, article_title: $L("30분 이상 주 2회")},
          { seq: 2, article_title: $L("30분 이상 주 3회")},
          { seq: 3, article_title: $L("하지 않습니다.")},
        ],
      },
      {
        id: "smoking",
        part: "life",
        title: "1. " + $L("Have you smoked before?"),
        answer_form: "radio",
        answer_max: 1,
        sub_yn: "Y",
        direction: "vertical",
        article: [
          {
            seq: 0,
            article_title: $L("없음")
          },
          {
            seq: 1,
            article_id: "smoke_past",
            article_title: $L("I have smoked before"),
            sub_title:  $L("Enter your smoking-related information."),
            sub_survey: [
              {
                id: "smoking_count_past",
                title: $L("How many cigarettes did you smoke today?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "vertical",
                article: [
                  {seq: 0, article_title: $L("5 pieces or less")},
                  {seq: 1, article_title: $L("5-10 pieces")},
                  {seq: 2, article_title: $L('10-20 pieces')},
                  {seq: 3, article_title: $L("20 pieces or more")}
                ]
              },
            ]
          },
          {
            seq: 2,
            article_id: "smoke_curr",
            article_title: $L("Currently smoking"),
            sub_title:  $L("Enter your smoking-related information."),
            sub_survey: [
              {
                id: "smoking_count_present",
                title: $L("하루에 몇 개비의 담배를 피우시나요?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "vertical",
                article: [
                  {seq: 0, article_title: $L("5 pieces or less")},
                  {seq: 1, article_title: $L("5-10 pieces")},
                  {seq: 2, article_title: $L('10-20 pieces')},
                  {seq: 3, article_title: $L("20 pieces or more")}
                ]
              },
            ]
          },
        ],
      },
      {
        id: "drinking",
        part: "life",
        title: "2. " + $L("Have you drunk alcohol before?"),
        answer_form: "radio",
        answer_max: 1,
        sub_yn: "Y",
        direction: "vertical",
        article: [
          {
            seq: 0,
            article_id: "drink_curr",
            article_title: $L("Yes"),
            sub_title:  $L("Enter your drinking-related information."),
            sub_survey: [
              {
                id: "drinking_count_present",
                title: $L("일주일에 음주를 몇번 하시나요?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "vertical",
                article: [
                  {seq: 0, article_title: $L('1번')},
                  {seq: 1, article_title: $L('2번')},
                  {seq: 2, article_title: $L('3번')},
                  {seq: 3, article_title: $L('4번')},
                  {seq: 4, article_title: $L('5번')},
                ]
              },
            ]
          },
          {
            seq: 1,
            article_id: "drink_past",
            article_title: $L("I have drunk before"),
            sub_title:  $L("Enter your drinking-related information."),
            sub_survey: [
              {
                id: "drinking_count_past",
                title: $L("How many times did you drink in the past week?"),
                answer_form: "radio",
                answer_max: 1,
                direction: "vertical",
                article: [
                  {seq: 0, article_title: $L('1번')},
                  {seq: 1, article_title: $L('2번')},
                  {seq: 2, article_title: $L('3번')},
                  {seq: 3, article_title: $L('4번')},
                  {seq: 4, article_title: $L('5번')},
                ]
              },
            ]
          },
          {
            seq: 2,
            article_title: $L("No")
          },
        ],
      },
    ]
  }
  return surveyData
}

export const getResetAnswerData = () => {
  if(!resetAnswerData) {
    resetAnswerData = {
      sex: 0,
      height: 172,
      weight: 74,
      disease: [],
      disability: null,
      pain: [],
      neck_surgery: null,
      neck_treatment: null,
      neck_intensity: 0,
      shoulder_surgery: null,
      shoulder_treatment: null,
      shoulder_intensity: 0,
      elbow_surgery: null,
      elbow_treatment: null,
      elbow_intensity: 0,
      wrist_surgery: null,
      wrist_treatment: null,
      wrist_intensity: 0,
      back_surgery: null,
      back_treatment: null,
      back_intensity: 0,
      waist_surgery: null,
      waist_treatment: null,
      waist_intensity: 0,
      pelvis_surgery: null,
      pelvis_treatment: null,
      pelvis_intensity: 0,
      hips_surgery: null,
      hips_treatment: null,
      hips_intensity: 0,
      thigh_surgery: null,
      thigh_treatment: null,
      thigh_intensity: 0,
      calf_surgery: null,
      calf_treatment: null,
      calf_intensity: 0,
      ankle_surgery: null,
      ankle_treatment: null,
      ankle_intensity: 0,
      sole_surgery: null,
      sole_treatment: null,
      sole_intensity: 0,
      purpose: [],
      interest: [],
      preference: [],
      desire: [],
      frequency: null,
      time: null,
      intensity_low: null,
      intensity_mid: null,
      intensity_high: null,
      level: null,
      smoking: null,
      smoking_count_past: null,
      smoking_count_present: null,
      drinking: null,
      drinking_count_present: null,
      drinking_count_past: null,
      lastpage: 0
    }
  }

  return resetAnswerData
}