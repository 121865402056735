import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { useSelector,useDispatch } from "react-redux";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import classNames from "classnames";
import css from "./HPWorkoutPlanner.module.less";
import { $L, dateDiff, formatTime } from "../../../utils/helperMethods";
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TScroller from '../../../components/TScroller/TScroller';
import TButton, { SIZES, TYPES } from "../../../components/TButton/TButton";
import { popPanel } from "../../../features/panels/panelsSlice";
import TDropDown from "../../../components/TDropDown/TDropDown";
import TNudeTab from "../../../components/TNudeTab/TNudeTab";
import { getWeeklySolution, postWeeklySolution } from "../../../features/fitService/fitServiceSlice";
import SMediaItem, { TYPES as STYPES } from "../../../components/SMediaList/SMediaItem";
import THorizontalScrollList, { ITEM_SIZE } from "../../../components/THorizontalScrollList/THorizontalScrollList";

const Container = SpotlightContainerDecorator({ enterTo: 'default-element'}, 'div');

const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const LISTS_BASE = [
  {
    id: "all",
    title: $L("All Workout"),
  },
  {
    id: "bodyAlignment",
    title: $L("Body Alignment"),
  },
  {
    id: "ROM",
    title: $L("Range Of Motion"),
  },
  // {
  //   id: "bodyBalance",
  //   title: $L("Body Balance"),
  // },
  {
    id: "physicalTest",
    title: $L("Physical Test"),
  },
];

const HPWorkoutPlanner = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const scrollTo = useRef();
  const contentInfos = useSelector(state => state.content.contentInfos);
  const { weeklySolution } = useSelector(state => state.homecareProgram);
  const sortLists = useRef();
  const [selectday, setSelectDay] = useState(0);
  const [filter, setFilter] = useState(0); // 전체 운동
  const bodyPart = [$L("Body Parts"), $L("Neck"), $L("Shoulder"), $L("Trunk"), $L("Hip"), $L("Knee"), $L("Leg")]; // todo
  const [bodyFilter, setBodyFilter] = useState(0); // 신체 부위
  const order = [$L("From the newest"), $L("In alphabetical order")];
  const [selectOderIndex, setSelectOderIndex] = useState(0);
  const [lists, setLists] = useState([]);
  const [plan, setPlan] = useState(weeklySolution);

  useEffect(() => {
    dispatch(getWeeklySolution());
  },[]);

  useEffect(() => {
    sortLists.current = lists;
  },[lists]);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  const weekly = useMemo(() => {
    const dateList = [];
    if (plan.length > 0) {
      for (let i = 0; i < 7; i++) {
        dateList.push({
          day: DAYS[i],
          contentList: plan?.[i]?.contentList,
          totalTime: plan?.[i]?.totalTime,
          totalCount: plan?.[i]?.totalCount,
          recommendTime: plan?.[i]?.recommendTime,
        })
      }
    }
    return dateList;
  },[plan]);

  const initLists = useMemo(() => {
    const arr = LISTS_BASE.slice(1);
    const contentID = arr.map((i) => {
      return contentInfos && Object.keys(contentInfos).filter(el => el.startsWith(i.id));
    });

    const contents = contentID.map((con) => {
      return con.map((c) => {
        return contentInfos && contentInfos[c];
      });
    });

    arr.map((li, idx) => {
      // return li.content = contents[idx];
      const items = [];
      if (contents[idx] && Array.isArray(contents[idx]) && contents[idx].length > 0) {
        for (let i = 0; i < contents[idx].length; i++) {
          const content = contents[idx][i];

          const item = {
            contentId: content.contentId,
            title: content.title,
            thumbnailUrl: content.thumbnailUrl,
            playTime: formatTime(content.playTime),
            icons: { isNew: dateDiff(content.createdate || content.creatdate) >= -7, isLike: false },
            calories: `${content.metadata?.calories}Kcal`,
            dataType: 'workout',
          }

          item.category = content.metadata?.workoutDetail;

          items.push(item);
        }
      }
      return li.content = items;
    });
    setLists(arr);
    return arr;
  },[contentInfos]);

  const onClickWeek = useCallback((idx) => () => {
    setSelectDay(idx);
  },[]);

  const onClickCancel = useCallback(() => {
    // todo
    dispatch(popPanel());
  },[]);

  const onClickSubmit = useCallback(() => {
    // onItemClick 완성되면 postWeeklySolution
    // dispatch(postWeeklySolution({ data: plan }));
    dispatch(popPanel());
  },[dispatch, plan]);

  const onSelectFilter = useCallback(({ selected }) => {
    setFilter(selected);
    // selected > 0 ? lists[selected] : lists.slice(1);
    if (selected > 0) {
      setLists([initLists[selected-1]]);
    } else {
      setLists(initLists);
    }
  }, [initLists]);

  const onSelectBodyFilter = useCallback(({ selected }) => {
    setBodyFilter(selected);
  }, []);

  const onClickSort = useCallback(({ index }) => {
    if (index === 0) {
      setSelectOderIndex(0);
      // 최신순 todo server createdate 값 추가 요청 필요
      // data.length > 0 && data.forEach(el => {
      //   el?.content.sort((a, b) => a.createdate.localeCompare(b.createdate));
      // });
    setLists(sortLists.current);
    } else if (index === 1) {
      // 가나다순
      setSelectOderIndex(1);
      sortLists.current.length > 0 && sortLists.current.forEach(el => {
        el?.content.sort((a, b) => a.title.localeCompare(b.title));
      });
      setLists(sortLists.current);
    }
  }, [lists]);

  const onItemClick = useCallback(({ index, itemInfo, ev }) => {
    // console.log('HPWorkoutPlanner onItemClick index,', index, 'itemInfo', itemInfo, 'ev', ev);
    // 더하기 빼기 완료
    // todo 더하고 뺄때마다 카운트 변경
    // todo 선택, index

    if(ev?.target?.id === "countMinus") {
      setPlan((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        const curSelect = newData[selectday].contentList.filter(content => content.contentId === itemInfo.contentId)[0];
        if(curSelect && curSelect.count > 1) {
          curSelect.count -= 1;
        } else {
          const index = newData[selectday].contentList.indexOf(newData[selectday].contentList.filter(el => el.contentId === itemInfo.contentId)[0]);
          newData[selectday].contentList.splice(index, 1);
        }
        return newData;
      });
    } else if (ev?.target?.id === "countPlus") {
      setPlan((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        const curSelect = newData[selectday].contentList.filter(content => content.contentId === itemInfo.contentId)[0];
        if(curSelect) {
          curSelect.count += 1;
        } else {
          newData[selectday].contentList.push({ contentId: itemInfo.contentId, count: 1});
        }
        return newData;
      });
    } else {
      // 목록 중에 있으면 해제
      // 목록 중에 없으면 선택
    }
  },[plan, selectday]);

	return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Revise workout plans")} />
      {/* <TBody className={css.tbody}> */}
        <div className={css.weeklyList}>
          {weekly.length > 0 && weekly.map((w, idx) =>
            <TButton
              key={'planner_'+w.day}
              type={TYPES.square}
              className={classNames(css.weekly, selectday === idx && css.select)}
              onClick={onClickWeek(idx)}
            >
              <div className={css.day}>{w.day}</div>
              <div className={css.vr}/>
              <div className={css.count}>{$L("선택 운동") + " " + w.totalCount + $L("개")}</div>
              <div className={css.time}>{formatTime(w.totalTime)+ "/" + formatTime(w.recommendTime)}</div>
            </TButton>
          )}
        </div>
        <TScroller className={css.scroller} cbScrollTo={getScrollTo} verticalScrollbar={"hidden"}>
          <div className={css.container}>
            <Container className={css.filterSection}>
              <div>
                <TDropDown
                  placeholder={$L("All Workout")}
                  direction="below"
                  size="small"
                  width="small"
                  selected={filter}
                  onSelect={onSelectFilter}
                >
                  {Array.from(LISTS_BASE, (li) => li.title)}
                </TDropDown>
                <TDropDown
                  placeholder={$L("Body Parts")}
                  direction="below"
                  size="small"
                  width="small"
                  selected={bodyFilter}
                  onSelect={onSelectBodyFilter}
                >
                  {bodyPart}
                </TDropDown>
              </div>
              <div>
                <TNudeTab
                  className={css.order}
                  size={'small'}
                  labelArr={order}
                  selectedTabIndex={selectOderIndex}
                  setSelectedTabIndex={setSelectOderIndex}
                  onClick={onClickSort}
                />
              </div>
            </Container>
            {lists.length > 0 && lists.map((li, idx) =>
              <Container
                key={'planner_'+li?.id}
                className={css.category}
              >
                <div className={css.categoryTitle}>{li?.title}</div>
                <THorizontalScrollList
                  itemSize={ITEM_SIZE.large}
                  startGap={75}
                >
                  {li?.content &&
                  li?.content.length > 0 &&
                  li?.content.map((item, index) => (
                    <SMediaItem
                      key={`planner_${li?.title}_${index}`}
                      itemSize={ITEM_SIZE.large}
                      itemInfo={item}
                      onClick={onItemClick}
                      mode={"edit"}
                      // checkProp={true}
                      type={STYPES.withCheckIndex}
                    />
                  ))}
                </THorizontalScrollList>
              </Container>
            )}
          {/* <div className={css.desc}>{$L("Select the workout on the date you'd like to plan a workout on and tap Save.")}</div> */}
            <Container className={css.btnArea} >
              <TButton size={SIZES.xLarge} type={TYPES.report} onClick={onClickCancel}>{$L("Cancel")}</TButton>
              <TButton size={SIZES.xLarge} type={TYPES.report} onClick={onClickSubmit}>{$L("Save")}</TButton>
            </Container>
          </div>
        </TScroller>
      {/* </TBody> */}
    </TPanel>

	)
}

export default HPWorkoutPlanner