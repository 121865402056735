import React, { useRef, useMemo, useEffect, useCallback } from "react";
import classNames from "classnames";
import css from "./FootPositionRenderer.module.less";
import ScanAnimator from "./ScanAnimator";

const BLE_MAT_WH=[666,333];
const ANGLE_THRESHOLD = 0;
const MAXANGLE = 90;
const SIZE_LIMIT = BLE_MAT_WH[1] * 0.3;  //발사이즈가 매트크기의 30% 이하일 경우 각도 반영 안함.
// 0 : topX, 1: topY, 2: bottomX, 3: bottomY, 4: leftX, 5: leftY, 6: rightX, 7: rightY
const getCenterPoint = (position)=>{
  // average X
  const xAvg = (position[4]+position[6])/2;//left right 의  x 평균
  // average Y
  const yAvg = (position[1]+position[3])/2; //top bottom 의  y 평균
  const width = position[6] - position[4];
  const height = position[3] - position[1];
 return [xAvg, yAvg, width, height];
};

const calculateAngle = (position, index) => {
  const top = [position[0], position[1]];//top
  const bottom = [position[2], position[3]];//bottom
  const radians =  Math.atan2(top[1] - bottom[1], top[0] - bottom[0]);
  let degree =  radians * (180 / Math.PI);
  let adjust = ANGLE_THRESHOLD;
  // if(index ===1){//오른발
  //   adjust = ANGLE_THRESHOLD *2;
  // }
  degree = degree + 90 + adjust;
  if(Math.abs(degree) >= MAXANGLE){
    degree = 0;
  }
  return degree;
}

const FootPositionRenderer = ({matReportMessage, footPosition, testType, feedbackEnable=true, showScanner=false}) => {
  const leftFootRef = useRef(null);
  const rightFootRef = useRef(null);
  const footLayoutRef = useRef(null);

  useEffect(() => {
    const footLayoutWidth = footLayoutRef.current.getBoundingClientRect().width;
    const footLayoutHeight = footLayoutRef.current.getBoundingClientRect().height;
    const scaleW = footLayoutWidth/(BLE_MAT_WH[0]/2);
    const scaleH = footLayoutHeight/BLE_MAT_WH[1];
    try{
      [footPosition?.left, footPosition?.right].map((position, index)=>{
        const node = index === 0 ? leftFootRef.current:rightFootRef.current;
        const centerPoint = getCenterPoint(position);
        let angle = calculateAngle(position, index);
        if(index === 1){
          centerPoint[0] = centerPoint[0] - BLE_MAT_WH[0]/2; //right foot margin
        }
        const convertedCenter = [0,0];
        convertedCenter[0] = centerPoint[0] * scaleW;
        convertedCenter[1] = centerPoint[1] * scaleH;

        if(isNaN(convertedCenter[0]) || isNaN(convertedCenter[1]) || convertedCenter[0] === 0 || convertedCenter[1] === 0){
          node.style.opacity = 0;
          node.style.transition = "initial";
          node.style.transform= "initial";
          node.style.top = "initial";
          node.style.left = "initial";
        }else{
          if(centerPoint[2] < SIZE_LIMIT && centerPoint[3] < SIZE_LIMIT){
            node.style.transform= "initial";
          }else{
            node.style.transform= `rotate(${angle}deg)`;
          }
          node.style.transition = "transform, left, top 100ms ease-in-out";
          node.style.left = convertedCenter[0]+'px';
          node.style.top = convertedCenter[1]+'px';
          node.style.opacity = 1;
        }
      });
    }catch(e){
      leftFootRef.current.style.opacity = 0;
      leftFootRef.current.style.top = "initial";
      leftFootRef.current.style.left = "initial";
      rightFootRef.current.style.opacity = 0;
      rightFootRef.current.style.top = "initial";
      rightFootRef.current.style.left = "initial";
    }
  }, [footPosition]);

  useEffect(()=>{
    if((matReportMessage === 'start' || matReportMessage === 'finished') && testType === 'bodycheck2Left'){
      rightFootRef.current.style.opacity = 0;
    }else if((matReportMessage === 'start' || matReportMessage === 'finished') && testType === 'bodycheck2Right'){
      leftFootRef.current.style.opacity = 0;
    }
  }, [matReportMessage])

  const status = useMemo(()=>{
    let ret = 'normal';
    if(feedbackEnable){
      switch(matReportMessage?.matStatus){
        case 'not ready':
          ret = 'missmatched';
          break;
        case 'ready':
        case 'start':
        case 'finish':
          ret = 'matched';
          break;
      }
    }
    return ret;
  },[matReportMessage, feedbackEnable]);


  return (
    <div className={classNames(css.wrapper, css[status])}>
       <div className={css.matlayout}>
          <div ref={footLayoutRef} className={css.leftFootLayout}>
            <div ref={leftFootRef} className={css.leftFoot} />
          </div>
          <div className={css.rightFootLayout}>
            <div ref={rightFootRef}className={css.rightFoot}/>
          </div>
        </div>
        {showScanner &&
          <ScanAnimator/>
        }
    </div>
  );
};

export default FootPositionRenderer;