import { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import css from './TPopUp.module.less';
import Alert from '@enact/sandstone/Alert';
import ri from '@enact/ui/resolution';
import TButton, { SIZES } from '../TButton/TButton';
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';
import { $L } from '../../utils/helperMethods';
import { useSelector } from 'react-redux';

const Container = SpotlightContainerDecorator({enterTo: "default-element", preserveId: true}, Spottable('div'));
const SpottableComponent = Spottable('div');

const KINDS = ["textPopUp", "calendarPopUp", "imagePopUp", "termsPopup", "smartMatPopUp", "connectedPopup", "testSelectPopup", "starratingPopup"];

const TPopUp = ({
	kind,
	title,
	text,
	text1,
	subText,
	connectedText,
	image,
	button1text,
	button2text,
	button2disabled,
	hasCheck,
	CheckTitle='',
	onCheck,
	onClick,
	onClose,
	onClickCheck,
	children,
	className,
	...rest}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);
	useEffect(() => {
		if (KINDS.indexOf(kind) < 0) {
				console.error('TPopUp kind error');
		}
	}, [kind]);

	useEffect(() => {
		setTimeout(() => {
			Spotlight.focus('tPopupBtn1');
			Spotlight.focus('tPopupBtn2');
		}, 0);
	}, []);

  const _onClose = useCallback((ev) => {
    console.log('onClose', ev);
		if(onClose){
			onClose(ev);
		}
  }, [onClose]);

	const onClickBtn = useCallback((index)=>(ev) => {
		if(onClick){
			onClick(index, ev);
		}
		if(index === 0){
      _onClose(ev);
    }
  }, [onClick, _onClose]);

	// const buttonWidth = useMemo(() => (button2text ? ri.scale(500) : ri.scale(1040)), [button2text]);
	const hasText = title || text || subText || text1;
	const hasButton = button1text || button2text;

	return (
		<Alert {...rest}
			className={classNames(
				css.tPopUp,
				css[kind],
				className ? className : null,
			)}
			open
			onClose={_onClose}
			type={"overlay"}
		>
      <Container
        className={classNames(css.info, themeMode === "dark" && css.isDark)}
      >
				{hasText &&
					<div className={css.textLayer}>
						{title &&  <div className={css.title}>{title}</div>}
						{text && <div className={css.text}>{text}</div>}
						{text1 && <div className={css.text}>{text1}</div>}
						{subText &&
						(Array.isArray(subText) ?
							<ol>
								{subText.map((text, idx) => <li key={idx} className={css.subText}>{text}</li>)}
							</ol>
						: <div className={css.subText}>{subText}</div>)}
					</div>
				}
				{children}
				{(kind === "imagePopUp" || "smartMatPopUp") && image && <img src={image}/>}
				{hasCheck &&
					<div className={css.checkArea}>
            <SpottableComponent
              spotlightId={"skip"}
              className={classNames(css.check, onCheck ? css.onCheck : null)}
              onClick={onClickCheck}
            >
              {CheckTitle ? CheckTitle : $L("Don't show this again")}
            </SpottableComponent>
					</div>
				}
				{hasButton &&
					<div className={css.buttonLayer}>
						{button1text && <TButton spotlightId='tPopupBtn1' className={css.button1} size={SIZES.popup} onClick={onClickBtn(0)}>{button1text}</TButton>}
						{button2text && <TButton spotlightId='tPopupBtn2' className={css.button2} size={SIZES.popup} disabled={button2disabled} onClick={onClickBtn(1)}>{button2text}</TButton>}
					</div>
				}
				{connectedText &&
					<div className={css.connectPopup}>
						<div className={css.connectedText}>{connectedText}</div>
					</div>
				}
			</Container>
		</Alert>
	);
};

export default TPopUp;