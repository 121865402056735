import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { $L, getWeekNumber } from "../../../utils/helperMethods";
import css from "./FitnessPainHistory.module.less";
import TButton from "../../../components/TButton/TButton";
import TDropdown from "../../../components/TDropDown/TDropDown";
import TAccordion from "../../../components/TAccordion/TAccordion";
import TSimpleDatePicker from "../../../components/TSimpleDatePicker/TSimpleDatePicker";
import TSimpleButtonTab from "../../../components/TSimpleButtonTab/TSimpleButtonTab";
import TScroller from "../../../components/TScroller/TScroller";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import ExerciseTimeGraph from "../../BodyCheckUp/PhysicalTest/PTSubTabGraph/ExerciseTimeGraph";
import { CONSTANTS_MONTH } from "../../../utils/Constants";
import classNames from "classnames";
import TLineGraph from "../../../components/TLineGraph/TLineGraph";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, Spottable("div"));
const SpottableComponent = Spottable("div");

const mock = [
  {
    date: "2023-06-24",
    title: "스마트 시니어를 위한 건강 예방 프로그램",
    subTitle: "약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다. 약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다.",
    data: {
      time: 60,
      bodyPart: "어깨",
      painScore: "9",
    },
  },
  {
    date: "2023-09-21",
    title: "전신 안정성 강화 운동 (부상방지,안정적인 몸 만들기)",
    subTitle: "시니어 건강 증진 활력 프로젝트 오늘도 건강하게 스트레칭!",
    data: {
      time: 100,
      bodyPart: "어깨",
      painScore: "3",
    },
  },
  {
    date: "2023-11-02",
    title: "전신 안정성 강화 운동 (부상방지,안정적인 몸 만들기)",
    subTitle: "시니어 건강 증진 활력 프로젝트 오늘도 건강하게 스트레칭!",
    data: {
      time: 108,
      bodyPart: "어깨",
      painScore: "2",
    },
  },
  {
    date: "2023-12-28",
    title: "전신 안정성 강화 운동 (부상방지,안정적인 몸 만들기)",
    subTitle: "시니어 건강 증진 활력 프로젝트 오늘도 건강하게 스트레칭!",
    data: {
      time: 150,
      bodyPart: "어깨",
      painScore: "1",
    },
  },
  {
    date: "2024-01-02",
    title: "전신 안정성 강화 운동 (부상방지,안정적인 몸 만들기)",
    subTitle: "시니어 건강 증진 활력 프로젝트 오늘도 건강하게 스트레칭!",
    data: {
      time: 30,
      bodyPart: "어깨",
      painScore: "6",
    },
  },
  {
    date: "2024-01-01",
    title: "전신 안정성 강화 운동 (부상방지,안정적인 몸 만들기)",
    subTitle: "시니어 건강 증진 활력 프로젝트 오늘도 건강하게 스트레칭!",
    data: {
      time: 29,
      bodyPart: "어깨",
      painScore: "4",
    },
  },
];

const mockData = {
  // temp value
  quarterly: [ "5", "3", "7", "0", "2", "5", "6", "2" ],
  monthly: [ "4", "3", "7", "1", "5", "4", "6", "2" ],
  weekly: [ "8", "3", "7", "1", "2", "10", "6", "2" ],
}

const FitnessPainHistory = () => {
  const scrollTo = useRef(null);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  //Date
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState("quarterly");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const NOW = new Date();
  const [year, setYear] = useState(NOW.getFullYear());
  const [month, setMonth] = useState(NOW.getMonth() + 1);

  //Category dropDown
  const [selectedId, setSelectedId] = useState(null);
  const dropDownMenu = [$L("Pain"), $L("Heart Rate")];

  //PainList
  const [clickedItemIndexs, setClickedItemIndexs] = useState([]);

  const handleDateRange = useCallback(
    ({ first, last }) => {
      const date = new Date(first);
      const newYear = date.getFullYear();
      const newMonth = date.getMonth() + 1;
      if (year !== newYear || month !== newMonth) {
        setYear(newYear);
        setMonth(newMonth);
      }
    },
    [year, month]
  );

  const onSelectedDate = useCallback(({ selectedDate }) => {
    setSelectedDate(selectedDate);
  }, []);

  const updateSelectedInterval = useCallback(({ index }) => {
    const periodMappings = {
      0: "quarterly",
      1: "monthly",
      2: "weekly",
    };
    setSelectedPeriodIndex(index);
    setSelectedPeriod(periodMappings[index]);
    scrollTop();
  }, []);

  const onSelectId = useCallback(({ selected }) => {
    setSelectedId(selected);
    scrollTop();
  }, []);

  const convertData = useMemo(() => {
    const currentDate = new Date(selectedDate);
    const year = currentDate.getFullYear().toString().slice(2);
    const month = currentDate.getMonth() + 1;

    let ret = {
      quarterly: {},
      monthly: {},
      weekly: {},
    };

    // 분기 계산
    let countQuarter = Math.floor((month - 1) / 3) + 1;
    let currentYear = year;
    const quarterlyKeys = [];

    // 월간 계산
    let countMonth = month;
    const monthlyKeys = [];

    // 주간 계산
    let countDate = currentDate.getDate();
    const weeklyKeys = [];

    for (let i = 0; i < 8; i++) {
      // quarter
      if (countQuarter - 1 < 0) {
        currentYear -= 1;
        quarterlyKeys.unshift(`4Q ${currentYear}`);
        countQuarter = 3;
      } else {
        quarterlyKeys.unshift(`${countQuarter}Q ${currentYear}`);
        countQuarter -= 1;
      }

      // month
      if (countMonth - 1 < 0) {
        monthlyKeys.unshift(CONSTANTS_MONTH[CONSTANTS_MONTH.length - 1]);
        countMonth = CONSTANTS_MONTH.length - 1;
      } else {
        monthlyKeys.unshift(CONSTANTS_MONTH[countMonth - 1]);
        countMonth -= 1;
      }

      // week
      const calcDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        countDate
      );
      weeklyKeys.unshift(getWeekNumber(calcDate));
      countDate -= 7;
    }

    for (let i = 0; i < 8; i++) {
      ret.quarterly[quarterlyKeys[i]] = mockData.quarterly[i];
      ret.monthly[monthlyKeys[i]] = mockData.monthly[i];
      ret.weekly[weeklyKeys[i]] = mockData.weekly[i];
    }

    return ret;
  }, [mockData, selectedDate]);

  const pickData = useMemo(() => {
    const label = Object.keys(convertData[selectedPeriod]);
    // const values = Object.values(convertData[selectedPeriod]);

    // let score = [];
    // let time = [];
    // values.map((item) => {
    //   score.push(item.score);
    //   time.push(item.time);
    // });

    const convertLabel = [];
    label.map((item, index) => {
      let translateLabel;
      if (selectedPeriod === "quarterly") {
        const splitLabel = item.replace("Q", "").split(" ");

        translateLabel = $L("{quarter}Q {year}")
          .replace("{quarter}", splitLabel[0])
          .replace("{year}", splitLabel[1]);

        if (index !== 0 && label[index - 1].includes(splitLabel[1])) {
          translateLabel = $L("{quarter}Q").replace("{quarter}", splitLabel[0]);
        }

        convertLabel.push(translateLabel);
      } else if (selectedPeriod === "monthly") {
        const convertMonth = $L(label[index]);
        convertLabel.push(convertMonth);
      } else if (selectedPeriod === "weekly") {
        const splitLabel = item.replace("W", "").split(" ");
        const convertMonth = $L(splitLabel[0]);

        translateLabel = $L("{month} W{week}")
          .replace("{month}", convertMonth)
          .replace("{week}", splitLabel[1]);

        convertLabel.push(translateLabel);
      }
    });

    return { label: convertLabel };
    // return { label: convertLabel, score, time };
  }, [convertData, selectedPeriod]);

  const handleClickedItem = useCallback(({ clickedItemIndexs }) => {
    setClickedItemIndexs(clickedItemIndexs);
  }, [clickedItemIndexs] );

  const scrollTop = useCallback(() => {
    if (scrollTo && scrollTo.current) {
      scrollTo.current({ position: { y: 0 }, animate: true });
    }
  }, []);

  return (
    <Container>
      <div className={css.container}>
        <Container>
          <div className={css.dateWrap}>
            <TSimpleDatePicker
              period={selectedPeriod}
              selectedDate={selectedDate}
              onSelectedDate={onSelectedDate}
              handleDateRange={handleDateRange}
              size="small"
              width="small"
            />
            <TSimpleButtonTab
              listType={"small"}
              contents={[$L("Quarterly"), $L("Monthly"), $L("Weekly")]}
              onItemClick={updateSelectedInterval}
              selectedIndex={selectedPeriodIndex}
            />
          </div>
          <div className={css.filterSection}>
            <TDropdown
              placeholder={$L("Pain")}
              direction="below"
              size="small"
              width="small"
              selected={selectedId}
              onSelect={onSelectId}
            >
              {dropDownMenu}
            </TDropdown>
          </div>
        </Container>
        <div>
          <TScroller
            className={css.scroller}
            cbScrollTo={getScrollTo}
            spotlightId={"FitnessPainHistory_tScroller"}
            verticalScrollbar={"hidden"}
          >
            <SpottableComponent
              className={css.contentContainer}
              key={`painHistoryChart`}
            >
              <TLineGraph
                width={1394}
                height={312}
                label={pickData.label}
                data={mockData[selectedPeriod]}
                maxRange={10}
                yRange={2}
                yLabel={true}
                unit={"point"}
              />
            </SpottableComponent>
            <TAccordion
              data={mock}
              clickedItemIndexs={clickedItemIndexs}
              handleClickedItem={handleClickedItem}
              unit={"point"}
              noMarquee={true}
            ></TAccordion>
          </TScroller>
        </div>
      </div>
    </Container>
  );
};

export default FitnessPainHistory;
