

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import css from './ErrorPanel.module.less';

import TButton, { SIZES } from '../../components/TButton';
import TPanel from '../../components/TPanel/TPanel';

import Spotlight from '@enact/spotlight';
import { resetPanels } from '../../features/panels/panelsSlice';
import { changeThemeModeStatus } from "../../features/common/commonSlice";
import { $L } from '../../utils/helperMethods';
import TBody from '../../components/TBody/TBody';
import useDebugKey from '../../hooks/useDebugKey';
import useGesture from '../../hooks/useGesture';

const ErrorPanel = ({ panelInfo, ...rest }) => {
	const dispatch = useDispatch();
	useDebugKey({});
	const { gestureRelease } = useGesture();

	useEffect(() => {
		Spotlight.focus('errorBtn1');
		Spotlight.focus('errorBtn2');
	}, []);

	useEffect(() => {
		dispatch(changeThemeModeStatus("dark"));
		return () => dispatch(changeThemeModeStatus("light"));
	}, []);

	const onClickBtn = useCallback((ev) => () => {
		if (ev === 0) {
			if (typeof window === "object" && window.PalmSystem) {
				gestureRelease();
				window.close();
			}
		} else {
			if (typeof window === 'object') {
				window.location.reload();
			}
		}
		// if(panelInfo.reason !== 'login' && initService){
		// 	initService();
		// }
		// dispatch(PanelActions.popPanel(PanelActions.PANEL_NAME.errorpanel));
	}, []);

	const textInfo = useMemo(() => {
		const res = { title: "", subTitle: "" };
		// if(panelInfo.reason === 'login'){
		// 	res.title = $L('로그인에 실패 하였습니다.');
		// 	res.subTitle = $L('Check the authentication number and try again.');
		// }
		if (panelInfo.reason === '401') {
      res.title = $L("Network connection failed.");
			res.subTitle = $L('Unauthorized access');
		} else if (panelInfo.reason === '403') {
      res.title = $L("You are already logged in on a difference device.");
      res.subTitle = $L(
        "You are already logged in on a difference device and you have been automatically logged out to protect your privacy."
      );
		} else {
      res.title = $L({
        key: "_SERVER_Network connection failed.",
        value: "Network connection failed.",
      });
			res.subTitle = $L('Check server connection.');
		}
		return res;
	}, [panelInfo]);
	return (
		<TPanel {...rest}>
			<TBody className={css.body}>
				<div className={css.icon} />
				<div className={css.title}>{textInfo.title}</div>
				<div className={css.subTitle}>{textInfo.subTitle}</div>
				<div className={css.btnLayer}>
					<TButton spotlightId='errorBtn1' size={SIZES.xLarge} onClick={onClickBtn(0)}>{$L("Quit")} </TButton>
					<TButton spotlightId='errorBtn2' size={SIZES.xLarge} onClick={onClickBtn(1)}>{$L("Retry")} </TButton>
				</div>
			</TBody>
		</TPanel>
	);
};

export default ErrorPanel;
