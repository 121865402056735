import classNames from "classnames";
import css from "./GGraphItem.module.less";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { $L, transSecondsToHMS, numberWithCommas } from "../../../../utils/helperMethods";

import TGGraph from "../../../../components/TGGraph/TGGraph";
import ICON_DAY from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_day.png"
import ICON_TIME from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_time.png"
import ICON_CALORIE from"../../../../../assets/FitnessManager/icon/ic_workoutmanager_calorie.png"

const getGoalData = (itemInfo, type) => {
  const data = itemInfo || { user: 0, plan: 0 };

  switch (type) {
    case "date":
      return {
        title: $L("Number of days you worked out"),
        user: data.user,
        plan: data.plan,
        progress: Math.floor(data.user / data.plan * 100) || 0,
        icon: ICON_DAY,
      };
    case "time":
      return {
        title: $L("Workout Time"),
        user: data.user ? transSecondsToHMS(data.user, false, true) : 0,
        plan: data.plan ? transSecondsToHMS(data.plan, false, true) : 0,
        progress: Math.floor((data.user / data.plan) * 100) || 0,
        icon: ICON_TIME,
      };
    case "calorie":
      return {
        title: $L("Calories burned"),
        user: data.user ? numberWithCommas(data.user) : 0,
        plan: data.plan ? numberWithCommas(data.plan) : 0,
        progress: Math.floor(data.user / data.plan * 100) || 0,
        icon: ICON_CALORIE,
      };
    default:
      return {};
  }
};

const GGraphItem = ({className, type, itemInfo, ...rest }) => {
  const goalData = useMemo(() => {
    return getGoalData(itemInfo, type);
  }, [itemInfo, type]);

  return (
    <div
      className={classNames(
        className,
        css.goalContainer,
      )}
      {...rest}
    >
      <TGGraph
        size={104}
        lineWidth={14}
        primaryPercentage={goalData.progress}
        isDark={true}
      />
      <div className={css.dataContainer}>
        <div className={css.titleWrapper}>
          <div className={css.icon} style={{backgroundImage: `url(${goalData.icon})`}}/>
          <div className={css.title}>{goalData.title}</div>
        </div>
        {(goalData.user === 0 && goalData.plan === 0) && 
          <div className={css.text}>{$L("There is no record of exercise yet.")}</div>
        }
        {(goalData.user !== 0 || goalData.plan !== 0) && 
          <div className={css.dataWrapper}>
            <div className={css.user}>{goalData.user}</div>
            <div className={css.plan}>{`/${goalData.plan}`}</div>
            <div className={css.vr}/>
            <div className={css.progress}>{`${goalData.progress}%`}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default GGraphItem;