import { useCallback, useEffect, useState } from "react";
import { $L } from "../../utils/helperMethods";
import CameraPopup from "../../views/Camera/CameraPopup";
import SmartMatPopup from "../../views/SmartMat/SmartMatPopup";
import TPopUp from "../TPopUp/TPopUp";
import css from "./TCesTermsAgree.module.less";
import classNames from 'classnames';
import { useDispatch, useSelector } from "react-redux";
import * as Config from "../../utils/Config";
import useBLE from "../../hooks/useBLE";
import { changeAppStatus, sendBroadCast } from "../../features/common/commonSlice";
import TScroller from "../TScroller/TScroller";
import { addPanels, resetPanels } from "../../features/panels/panelsSlice";

const cesConsentContent = $L(`The LG Intelli-FiT Exhibition (“LG Intelli-FiT”) provided by LG Electronics system will collect information about your body characteristics via cameras and pressure-sensing floor mats. LG Intelli-FiT analyzes the information collected to assess physical characteristics, including body alignment and body balance.
            <br><br>
            You can choose to have LG Intelli-FiT provide you with feedback and workout recommendations based on the analysis. This information, along with summaries of the measurements made, will be presented on the TV screen and will be viewable by anyone in the booth. You can choose to download the information to your mobile device via a QR code on the screen, but only while you are in the booth. Some participants may be able to receive feedback via voice within the booth
            <br><br>
            LG Intelli-FiT will store images locally. Abstract silhouettes derived from the images will be transferred to a cloud server along with feedback and workout recommendations so that you may download them via a QR code to your mobile device.
            <span>After you have downloaded the reports and returned to the application’s home screen on the TV screen, the data stored locally and on the cloud server will all be deleted.</span>
            Neither you nor LG Electronics will be able to access the information.  And apart from the temporary collection of images, LG Intelli-FiT does not collect information that identifies you.
            <br><br>
            <span>LG INTELLI-FIT IS FOR CONSUMER EDUCATIONAL USE ONLY. LG INTELLI-FIT’S RECOMMENDATIONS AND FEEDBACK SHOULD NOT BE CONSIDERED, OR USED AS A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE OR TREATMENT.</span>
            <br><br>
            <span>WHILE LG INTELLI-FIT PROVIDES INFORMATION, WE CANNOT AND DO NOT DIAGNOSE YOUR HEALTH CONDITIONS.</span>
            <br><br>
            <span>DON’T USE LG INTELLI-FIT AS A SUBSTITUTE FOR CONSULTING WITH YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER.</span>
            <br><br>
            If you rely on information provided to you via LG Intelli-FiT, you do so solely at your own risk. We are not responsible for the accuracy, reliability, availability, effectiveness, or correct use of information you receive through LG Intelli-FiT. Use of LG Intelli-FiT should not replace your good judgment and common sense.
            <br><br>
            By pressing [AGREE], you acknowledge that you have read and agreed to this Notice and Consent.`);

const TCesTermsAgree = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { themeMode } = useSelector((state) => state.common.appStatus);
  const broadcast = useSelector((state) => state.common.broadcast);
  const { cameraList } = useSelector(state => state.camera);
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);
  const [showSmartMatPopup, setShowSmartMatPopup] = useState(false);
  const [showCesTermsAgree, setShowCesTermsAgree] = useState(null);
  const { bleConnectStatusRef } = useBLE({});

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  const closeSmartMatPopup = useCallback(() => {
    setShowSmartMatPopup(false);
  }, []);

  useEffect(() => {
    if (broadcast?.type === "cesTermsAgree" && broadcast?.moreInfo && broadcast?.moreInfo?.target) {
      setShowCesTermsAgree(JSON.parse(JSON.stringify(broadcast.moreInfo.target)));
    }
  }, [broadcast]);

  const onClickFunc = useCallback((target) => {
    if (Array.isArray(target)) {
      dispatch(resetPanels(target));
      dispatch(sendBroadCast({type: "gnbButtonClicked", moreInfo:{target}}));
    } else {
      if (typeof target !== 'object' || !target.name) {
        if (target.name === '') {
          dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'general' } }));
        }
        return;
      }

      if (cameraList.length < 1) {
        setIsConnectionPopupOpen(true);
      } else {
        if(target.name === Config.panel_names.BODY_CHECK_UP_MAIN || target.name === Config.panel_names.SELECT_WORKOUT) {
          dispatch(addPanels(target));
        }
        if(target.name === Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER){
          if(bleConnectStatusRef.current !== "connected"){
            setShowSmartMatPopup(true);
          }else{
            dispatch(addPanels(target));
          }
        }
      }
    }
  }, [dispatch, showCesTermsAgree, cameraList, bleConnectStatusRef]);

  const onClickTerms = useCallback((index) => {
		if (index) { //start
      onClickFunc(showCesTermsAgree);
		}
    setShowCesTermsAgree(null);
	}, [dispatch, showCesTermsAgree, onClickFunc]);

  return (
    <>
      {isConnectionPopupOpen &&
        <CameraPopup onClose={onCloseCameraPopup}/>
      }
      {showSmartMatPopup &&
        <SmartMatPopup onClose={closeSmartMatPopup} />
      }
      {showCesTermsAgree !== null &&
        <TPopUp
          kind="textPopUp"
          button1text={$L("Cancel").toUpperCase()}
          button2text={$L("Agree").toUpperCase()}
          onClick={onClickTerms}
          className={css.tPopup}
          style={{width: "1160px"}}
        >
          <TScroller
            className={css.scroller}
            // verticalScrollbar="hidden"
          >
            <div className={classNames(css.scrollerInnerContainer, themeMode === "dark" && css.isDark )}>
              <div className={css.title}>{"CES 2024 LG Intelli-FiT Exhibition Notice and Consent"}</div>
              <div className={css.text} dangerouslySetInnerHTML={{ __html: cesConsentContent }}/>
            </div>
          </TScroller>
        </TPopUp>
      }
    </>
  );
};

export default TCesTermsAgree;