import css from "./BodyBalanceReport.module.less";
import classNames from "classnames";
import { useMemo, useEffect } from "react";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import { useDispatch, useSelector } from "react-redux";
import { $L } from "../../../../utils/helperMethods";
import BodyBalanceTabResults from "../../../BodyCheckUp/BodyBalance/BodyBalanceReport/TabContents/BodyBalanceTabResults";
import FootTypeResults from "../../../BodyCheckUp/BodyBalance/BodyBalanceReport/TabContents/FootTypeResults";
import Wrapper, {TYPES as WTYPES} from "../../components/Wrapper/Wrapper";
import TCanvasMatRaw from "../../../../components/TCanvasMatRaw/TCanvasMatRaw";
import { getBodyCheckUpData } from "../../../../features/fitService/fitServiceSlice";
import { BODY_CHECKUP_TYPE } from "../../../../utils/Constants";
import Header from "../../components/Header/Header";
import ViewDetails from "./ViewDetails";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const BodyBalanceReport = ({ className, dateList, ...rest }) => {
  const dispatch = useDispatch();
  const { testResults } = useSelector((state) => state.bodyBalance);

  const isEmpty = useMemo(() => !dateList || dateList.length <= 0, [dateList]);

  const currentData = useMemo(() => {
    if (!isEmpty) {
      return testResults[dateList[dateList.length - 1]];
    }
    return {};
  }, [testResults, dateList, isEmpty]);

  useEffect(() => {
    if (dateList && dateList.length > 0) {
      dispatch(
        getBodyCheckUpData({
          type: BODY_CHECKUP_TYPE.BODY_BALANCE,
          date: dateList[dateList.length - 1],
          syncFlag: "immediately",
        })
      );
    }
  }, [dateList]);

  const footTypeStyle = {
    footArchBarItemSectStyle: {
      width: "100%",
      margin: "0",
      paddingBottom: "44px",
      border: "none"
    },
    fixedBarStyle: {
      height: "10px",
    },
    footArchInfoWrapStyle: {
      height: "44px",
      fontSize: "14px",
      marginBottom: "20px",
      lineHeight: "1.1",
    },
    footArchInfoWrapTextStyle: {flexDirection: "column"},
    footArchIndexStyle: { fontSize: "12px" },
    footLevelStyle: {display: "none"},
    footStepFlexStyle: {
      width: "36px",
      fontSize: "12px",
    },
    footStepFlexInfoStyle: {
      height: "25px"
    },
    footStepFlexImgStyle: { display: "none" },
    footStepStyle: { display: "none" }
  };

  const bodyBalanceTabStyle = {
    bbtCoordinatePlaneWrapStyle: {
      top: "-25px",
    },
    bbtCoordinatePlaneStyle: {
      transform: "scale(0.72)",
    },
    bbtInfoWrapStyle: {
      fontSize: "14px",
      padding: "0 20px",
      boxSizing: "border-box",
      bottom: "26px",
    },
  };

  return (
    <>
      <Header>{$L("Body Balance")}</Header>
      <Container {...rest} className={classNames(css.bodyBalanceReport, className)}>
        <Wrapper title={$L("Two-Leg Balance")} className={css.coordinatesWrapper}>
          <BodyBalanceTabResults
            currentData={currentData}
            style={bodyBalanceTabStyle}
          />
          <div className={css.divider} />
        </Wrapper>
        <Wrapper title={$L("Foot Shape")} type={WTYPES.withIcon} className={css.footShapeWrapper}>
          <TCanvasMatRaw
            className={css.tCanvasMatRaw}
            rawData={currentData?.twolegstance?.rawData}
            width={40}
            height={20}
            date={dateList[dateList.length - 1]}
            type="twoleg"
          />
          <div className={css.footTypesWrap}>
            <FootTypeResults currentData={currentData} style={footTypeStyle} />
          </div>
        </Wrapper>
        <ViewDetails />
      </Container>
    </>
  );
};

export default BodyBalanceReport;
