import React, { useCallback, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import css from "./TSimpleButtonItem.module.less";
import compose from 'ramda/src/compose';
import Spottable from '@enact/spotlight/Spottable';
import {Marquee, MarqueeController} from '@enact/sandstone/Marquee';
import { useSelector, useDispatch } from "react-redux";
import TabLabel from "../TabLable/TabLable";
import { Job } from "@enact/core/util";
import * as Config from '../../utils/Config';
import { convertNewlinesToBr } from '../../utils/helperMethods';

const SpottableComponent = Spottable("div");

const ItemBase = ({panelInfo, selected, disabled, spotlightDisabled, selectedTabIndex, listType, children, onClick, className, maxItemCount, score, index, type, noMarquee,...rest}) => {
  const [pressed, setPressed] = useState(false);
  const [blur, setBlur] = useState(true);
  const { themeMode } = useSelector((state) => state.common.appStatus);

  const nodeRef = useRef();
  useEffect(()=>{
    if(listType === 'header' || listType === 'report' || listType === 'detailTest' || listType === 'leftRightTab'){
      nodeRef.current.node.style.setProperty('--maxItemCount', maxItemCount);
    }
  },[listType]);

  const _onClick = useCallback((ev) => {
    if (onClick && !disabled) {
      onClick(ev);
      setPressed(true);
      clearPressedJob.current.start();
    }
	}, [onClick, disabled]);

  const clearPressedJob = useRef(new Job((func) => {
    setPressed(false);
    setTimeout(func, Config.TBUTTON_PRESS_DELAY);
  }, Config.TBUTTON_PRESS_DELAY));

  const _onBlur = useCallback(()=>{
    setPressed(false);
    clearPressedJob.current.stop();
    setBlur(true);
  },[pressed]);

  const _onFocus = useCallback(()=>{
    setBlur(false);
  },[blur]);

  return (
    <>
      <SpottableComponent
        {...rest}
        ref={nodeRef}
        className={classNames(
          css.tItem, 
          selected && css.selected, 
          pressed && css.pressed, 
          disabled && css.disabled, 
          css[listType],
          themeMode === "dark" && css.isDark,
          className
        )}
        onClick={_onClick}
        spotlightDisabled={spotlightDisabled}
        // onBlur={_onBlur}
      >
          {noMarquee?
            <div className={css.title}>{convertNewlinesToBr(children)}</div>
            :
            <Marquee className={css.title} alignment={'center'} marqueeOn={!blur ? "focus" : undefined} onFocus={_onFocus} onBlur={_onBlur}>{children}</Marquee>
          }
          <TabLabel type={type} selectedTabIndex={selectedTabIndex} index={index} score={score}/>
      </SpottableComponent>
    </>
  );
}

const ImageItemDecorator = compose(
	MarqueeController({marqueeOnFocus: true}),
);

const TSimpleButtonItem = ImageItemDecorator(ItemBase);
export default TSimpleButtonItem;