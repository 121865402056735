import classNames from 'classnames';
import css from "./SurveyPanel.module.less";

import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';

// import { getSurveyData } from "../../utils/surveyData";
import { $L } from '../../utils/helperMethods';

import { popPanel } from "../../features/panels/panelsSlice";
import { changeThemeModeStatus } from "../../features/common/commonSlice";
import { postSurvey, updateSurvey } from '../../features/fitService/fitServiceSlice';

import TPanel from "../../components/TPanel/TPanel";
import TBody from "../../components/TBody/TBody";
import THeader from "../../components/THeader/THeader";
import TButton, {SIZES, COLOR} from "../../components/TButton/TButton";
import TToast from '../../components/TToast/TToast';
import TPopUp from '../../components/TPopUp/TPopUp';
import TScroller from '../../components/TScroller/TScroller';
import Question from './Question/Question';
import Stepper from './Stepper/Stepper';
import CheckMark from './CheckMark/CheckMark';

import Spotlight from '@enact/spotlight';
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import { getSurveyData } from '../MyPanel/MySureveyInfo/surveyInfo';

const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

const SurveyPanel = ({panelInfo, ...rest}) => {
  const dispatch = useDispatch();
  const surveyData = getSurveyData();
  const {isToasted, message} = useSelector((state) => state.common.appStatus.toast);
  const { profileNick } = useSelector((state) => state.common.appStatus);
  const [page, setPage] = useState(0);
  const [showSurveyStopPopup, setShowSurveyStopPopup] = useState(false);
  const answer = useSelector((state) => state.fitService.survey);
  const [disabled, setDisabled] = useState(false);
  const scrollTo = useRef();
  const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, []);

	useEffect(() => {
		if (page < order.length-1 && scrollTo && scrollTo.current) {
			scrollTo.current({ position: { y: 0 }, animate: false });
		}
	}, [page]);

  const order = useMemo(() => {
    return [
      {id: 'basic', type: 'basic', title: "기본정보"},
      {id: 'body', type: 'body', title: "바디정보"},
      {id: 'exercise', type: 'exercise', title: "운동정보"},
      {id: 'life', type: 'life', title: "생활정보"},
      { id: "complete", type: "complete", title: "Finished" },
    ];
  }, []);

  useEffect(() => {
    setTimeout(() => {
      Spotlight.focus(order[page].id);
    }, 0);
  }, [page]);

  useEffect(() => {
  if (answer) {
    setPage(answer.lastpage === 4 ? 0 : answer.lastpage);
  }
  }, []);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  useEffect(()=> {
    setDisabled(answersFilled(currPageInfo))
  }, [page, answer])

  const currPageInfo = useMemo(() => {
    if(0 <= page && page < order.length - 1){
      return surveyData[order[page].id].question;
    }
    return [];
  }, [page]);

  const onPrevClick = useCallback(() => {
    if (page === 0) {
      dispatch(popPanel());
    }else {
      setPage(page-1);
    }
  }, [page]);

  const onNextClick = useCallback(() => {
    if(page < order.length-1){
      setPage(page+1);
    }
  }, [page]);

  const onClickStopSurvey = useCallback(() => {
    setShowSurveyStopPopup(!showSurveyStopPopup)
  }, [showSurveyStopPopup]);


  const onClickPopUpBtn = useCallback((ev) => {
    if (ev === 1) {
      const page = findUnansweredPage()
      if (page) {
        dispatch(updateSurvey({"lastpage": page}))
      }
      dispatch(postSurvey());
      if (typeof window === 'object' && window.PalmSystem) {
        dispatch(popPanel());
      }else if(typeof window === 'object'){
        dispatch(popPanel());
      }
    }
    setShowSurveyStopPopup(!showSurveyStopPopup)
  }, [showSurveyStopPopup, answer])

  const onClickStartBtn = useCallback(() => {
    dispatch(updateSurvey({"lastpage": page}))
    dispatch(postSurvey());
    dispatch(popPanel());
  },[dispatch, page])

  const selectedArticles = useCallback((question) => {
    if (!answer) {
      return []
    }
    const filteredArticles = question.article.filter(item => {
      if (question.answer_form === "checkbox") {
        return answer?.[question.id]?.includes(item.seq);
      } else {
        return answer?.[question.id] === item.seq;
      }
    });

    return filteredArticles;
  }, [answer])

  const answersFilled = useCallback((info) => {
    // question이 null값인지 확인.
    return info.some(question => {
      if (question.answer_form === "none") {
        return answersFilled(question.article)
      }

      const isAnswerNull = answer?.[question.id] === null;
      const isAnswerEmptyArray = Array.isArray(answer?.[question.id]) && answer?.[question.id].length === 0;
      if (isAnswerNull || isAnswerEmptyArray) { return true;
      }

      // sub question이 null값인지 확인.
      if (question.sub_yn === 'Y') {
        return selectedArticles(question).some(item => {
          return item.sub_survey && answersFilled(item.sub_survey);
        });
      }
      return false;
    });
  }, [answer]);

  const findUnansweredPage = useCallback(() => {
    for (let i = 0; i < order.length - 1; i++) {
      if (answersFilled(surveyData[order[i].id].question)) {
        return i;
      }
    }
  }, [answer]);

  const onFocusButtonContainer = useCallback(() => {
    if (scrollTo && scrollTo.current) {
			scrollTo.current({ align: 'bottom', animate: true });
		}
  }, []);

  const description = useMemo(() => {
    return $L(`
      <h1>${order[page].title}를 입력해주세요.</h1>
      <p>${profileNick}님을 위한 맞춤 서비스를 제공하기 위해 설문 작성을 끝까지 완료해주시기 바랍니다.</p>
    `);
  }, [profileNick, page]);

  const completeMessage = useMemo(() => {
    return $L(`
      <span>수고하셨습니다!</span>
      <span>
        ${profileNick}님을 위한 모든 건강정보 입력이 완료되었습니다.
        <br>
        LG 인텔리핏과 함께 건강한 하루를 시작하세요!
      </span>
    `);
  }, [profileNick]);

  const backKeyHandler = useCallback((ev) => {
    if (ev) ev.stopPropagation();
  },[])

  const isLastPage = useMemo(() => {
    return page === order.length - 2;
  }, [page]);

  return (
    <TPanel {...rest} className={css.panel} handleCancel={backKeyHandler}>
      <THeader title={$L("Health Information")} iconType="none" />
      <TBody className={css.body}>
        {page < order.length-1 ? (
          <>
            <div className={css.description} dangerouslySetInnerHTML={{__html: description}}/>
            <Stepper className={css.stepper} length={order.length} number={page}/>
            <TScroller
              className={css.scroller}
              spotlightId={order[page].id}
              cbScrollTo={getScrollTo}
              verticalScrollbar={page === 0 ? "hidden" : "auto"}
            >
              <div className={css.innerContainer}>
                <div className={classNames(css[order[page].type])}>
                {currPageInfo.map((question, index) =>
                  <Question
                    key={"question" + index}
                    className={css.question}
                    question={question}
                    articles={question.sub_yn === "Y" ? selectedArticles(question) : []}
                  />
                )}
                </div>
                <div className={css.buttonContainer} onFocus={onFocusButtonContainer}>
                  <div className={css.instruction}>
                    {isLastPage ? $L('This is the last information.') : $L('Fill out the fields and tap Next.')}
                  </div>
                  <div className={css.buttonArea}>
                    <TButton size={SIZES.xLarge} onClick={onClickStopSurvey} className={css.laterBtn}>
                      {$L("Do it later")}
                    </TButton>
                    <TButton size={SIZES.xLarge} className={css.prevBtn} onClick={onPrevClick}>
                        {$L("Previous page")}
                    </TButton>
                    <TButton size={SIZES.xLarge} className={css.nextBtn} disabled={disabled} onClick={onNextClick}>
                        {$L("Next")}
                    </TButton>
                  </div>
                </div>
              </div>
            </TScroller>
          </>
        ) : (
          <Container
            className={css.lastContainer}
            spotlightId={order[page].id}
          >
            <CheckMark className={css.mark}/>
            <div className={css.message} dangerouslySetInnerHTML={{__html: completeMessage}}/>
            <TButton size={SIZES.xLarge} onClick={onClickStartBtn}>
              {$L("Start LG Intelli-FiT")}
            </TButton>
          </Container>
        )}
        {showSurveyStopPopup &&
          <TPopUp
            kind="textPopUp"
            title={$L("Do you want to stop the survey?")}
            text={$L("Fields you've filled out will be automatically saved. You can log in again or continue filling out by tapping My>Health Information.")}
            button1text={$L("Cancel")}
            button2text={$L("Stop")}
            onClick={onClickPopUpBtn}
          />
        }
        {isToasted && <TToast text={message} />}
      </TBody>

    </TPanel>
  );
}
export default SurveyPanel