/**
 * TBarGraph
 *
 * @module TBarGraph
 */
import React, { useMemo, useState, useCallback } from "react";
import classNames from "classnames";
import css from "./TBarGraph.module.less";
import {SFT_SCORE_MANAGE_TOP, SFT_SCORE_DANGER_TOP} from "../../utils/Constants";
import { useSelector } from "react-redux";
import * as Config from "../../utils/Config";
import { $L } from "../../utils/helperMethods";

const TBarGraph = ({...props}) => {

  const [animationEnded, setAnimationEnded] = useState(false);
  const panels = useSelector((state) => state.panels.panels);
  const graphInfo = [
    {
      name: 'forward head posture',
      score: 100
    },
    {
      name: 'ShoulderBalance',
      score: 25
    },
    {
      name: 'Central balance',
      score: 15
    },
    {
      name: 'Pelvic Balance',
      score: 70
    },
    {
      name: 'Knee Balance',
      score: 60
    },
  ]

  // const
  // const score = 40;
  const scores = graphInfo.map(info => info.score);
  // console.log(scores)

const gStyle = useMemo (() => {
  return scores.map((score, idx) => {
    let ret = {color:'', maxWidth: ''};
    if(score > 70) {
      ret.color = "linear-gradient(to right, #CEEAE5, #34A29B)"; //green
    } else if (score > 30) {
      ret.color = "linear-gradient(to right, #FFEAD2, #FF8616)"; //yellow
    } else {
      ret.color = "linear-gradient(to right, #FFE3E9, #FF4F79)"; //red
    }
    ret.maxWidth = 45+score/2 + "%";
    return ret;
  });
}, [scores]);

  const gStyleColor = gStyle.map(style => style.color);
  const gStyleWidth = gStyle.map(sty => sty.maxWidth);

  console.log(gStyle);
  console.log(gStyleColor);

  const currentPanelName = useMemo (() => {
    return panels[panels.length-1]?.name;
  },[panels]);
  //aniEnd
  const onAanimationEnd = useCallback (() => {
    setAnimationEnded(true);
  }, []);

  return (
    <>
      {graphInfo.map((info, idx) => (
        <div key={`barGraph-${idx}`} className={currentPanelName !== Config.panel_names.PHYSICAL_TEST_REPORT ? css.graphBox : css.overallGraphBox}>
          <div className={css.physicalWord}>{info.name}</div>
          <div className={css.BarFullSize}>
            <div
              className={classNames(css.commonGraphBg, css.ani)}
              style={{background: gStyleColor[idx], maxWidth: gStyleWidth[idx]}}
              onAnimationEnd={onAanimationEnd}
            >
            </div>
          </div>
        </div>
      ))}
      <div className={css.barType}>
        <div>{$L("Weak")}</div>
        <div>{$L("Borderline")}</div>
        <div>{$L("Good")}</div>
      </div>
    </>
  );
}

export default TBarGraph;

