import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
} from "../../../../../utils/Constants";
import { $L } from "../../../../../utils/helperMethods";
import { bodyBalanceMenu } from "../Constants";

export const footTypeStatus = {
  LUMBAR: "LUMBAR",
  SUS_LUMBAR: "SUS_LUMBAR",
  COMMON: "COMMON",
  SUS_FLATFOOT: "SUS_FLATFOOT",
  FLATFOOT: "FLATFOOT",
  TWO_FLATFOOT: "TWO_FLATFOOT",
  ONE_FLATFOOT: "ONE_FLATFOOT",
  TWO_COMMON: "TWO_COMMON",
  TWO_LUMBAR: "TWO_LUMBAR",
  ONE_LUMBAR: "ONE_LUMBAR",
};

export const bodyBalanceTabStatus = {
  PERFECT: "PERFECT",
  TILT_FB: "TILT_FB",
  TILT_LR: "TILT_LR",
  TILT_ALL: "TILT_ALL",
};

export const feedbackFootType = () => {
  return {
    [footTypeStatus.LUMBAR]: $L(
      "{position} has high pressure on the forefoot or hindfoot, and has possibility of having hollow foot. In this case, stress is increased on the ball and heel on the foot."
    ),
    [footTypeStatus.SUS_LUMBAR]: $L(
      "{position} has slightly high pressure on the forefoot or hindfoot, and has possibility of progression to hollow foot."
    ),
    [footTypeStatus.COMMON]: $L(
      "{position} has equal pressure distribution on the sole, and therefore, your foot is normal."
    ),
    [footTypeStatus.SUS_FLATFOOT]: $L(
      "{position} has slightly high pressure on the mid-foot, and has possibility of progression to flat foot."
    ),
    [footTypeStatus.FLATFOOT]: $L(
      "{position} has high pressure on the mid-foot, and has possibility of having flat foot. In this case, the stress is increased on the heel, mid-foot and big toe."
    ),
  };
};

export const feedbackFootTypeVoice = () => {
  return {
    [footTypeStatus.TWO_LUMBAR]:
      $L("양쪽 발 모두 요족이 의심됩니다. ") +
      $L(
        "Hollow foot is having the dorsum of the foot is higher than normal, and in other words, it refers to having high arch of the sole to convex upward when seen from the side. Due to the high arch, the body weight cannot be distributed evenly on the sole, and concentrated on the heel and ball to result in various symptoms."
      ),
    [footTypeStatus.TWO_COMMON]:
      $L("양쪽발 모두 ") +
      $L("Pressure distribution on the sole is even, and normal."),
    [footTypeStatus.TWO_FLATFOOT]:
      $L("양쪽 발 모두 평발이 의심됩니다. ") +
      $L(
        "Flat foot is not a name of a disorder, but a term describing the shape of the foot. Therefore, it includes the case difficult to say it is a disorder due to not having any functional issues, and the case of having serious deformation accompanied by stiffness and functional disorder. Flat foot refers to having no inward arch on the sole to have the sole to be flat, and normally, we are born in flat foot to maintain the flat foot until the early childhood, and arch is created on the foot at about age 5~6 to be completed after the age of 6~8."
      ),
    [footTypeStatus.ONE_LUMBAR]:
      $L("The {position} is ") +
      $L("요족이 의심됩니다. ") +
      $L(
        "Hollow foot is having the dorsum of the foot is higher than normal, and in other words, it refers to having high arch of the sole to convex upward when seen from the side. Due to the high arch, the body weight cannot be distributed evenly on the sole, and concentrated on the heel and ball to result in various symptoms."
      ),
    [footTypeStatus.COMMON]:
      $L("The {position} is ") +
      $L("Pressure distribution on the sole is even, and normal."),
    [footTypeStatus.ONE_FLATFOOT]:
      $L("The {position} is ") +
      $L("평발이 의심됩니다. ") +
      $L(
        "Flat foot is not a name of a disorder, but a term describing the shape of the foot. Therefore, it includes the case difficult to say it is a disorder due to not having any functional issues, and the case of having serious deformation accompanied by stiffness and functional disorder. Flat foot refers to having no inward arch on the sole to have the sole to be flat, and normally, we are born in flat foot to maintain the flat foot until the early childhood, and arch is created on the foot at about age 5~6 to be completed after the age of 6~8."
      ),
  };
};

export const feedbackBodyBalanceTab = () => {
  return {
    [bodyBalanceTabStatus.PERFECT]: $L(
      "Your body balance is good. Maintaining the current condition is recommended through regular core strengthening and balance exercises."
    ),
    [bodyBalanceTabStatus.TILT_FB]: $L(
      "The center of the body is tilted {yPosition} by {yValue}%."
    ),
    [bodyBalanceTabStatus.TILT_LR]: $L(
      "The center of the body is tilted {xPosition} by {xValue}%."
    ),
    [bodyBalanceTabStatus.TILT_ALL]: $L(
      "The center of the body is tiled {xPosition} by {xValue}% and tilted {yPosition} by {yValue}%."
    ),
  };
};

export const feedbackBodyBalanceTabVoice = $L(
  "균형능력은 최소한의 흔들림으로 신체의 중심을 유지하는 능력이에요. 균형을 유지하는 능력은 일상생활을 수행하는데 있어서 가장 기본이 되는 필수 요소에요. 균형능력은 시각 정보, 전정계, 고유수용성 감각(자신의 신체 위치, 자세, 평형 및 움직임에 대한 정보를 파악해 중추신경계로 전달하는 감각), 근피로도 등의 영향을 많이 받아요. 또 아무리 신경 기능이 뛰어나도 그 명령을 받아 움직임을 만들어내야 하는 근육에 힘이 부족하면 적절하게 균형을 유지할 수 없으므로 하체와 중심 근육(core muscle)을 건강하게 유지해야 해요."
);

export const feedbackCoP = () => {
  return {
    [SCORE_MINT_KEY]: $L(
      "{position} 한쪽으로 섰을 때 흔들림이 거의 없고 안정적인 상태입니다."
    ),
    [SCORE_YELLOW_KEY]: $L(
      "{position} 한쪽으로 섰을 때 흔들림이 약간 심한 수준입니다. 평소 양발의 균형을 잡으려는 노력을 하는 것이 중요합니다."
    ),
    [SCORE_RED_KEY]: $L(
      "{position} 한쪽으로 섰을 때 흔들림이 심한 수준입니다. 평소 양발의 균형을 잡으려는 노력을 하는 것이 중요합니다."
    ),
  };
};

export const feedbackCoPVoice = $L(
  "외발서기도 신체 균형능력을 파악할 수 있어요. 외발서기로 균형을 평가하면 양발서기에서 보지 못하는 동적 상황에서의 발의 형태 변화도 감지할 수 있어요. 즉, 종아리 근육 및 엉덩이 근육이 약해도 양발서기 때는 정상으로 나올 수 있지만 외발서기 시 다른 결과가 나올 수 있어요. 또한 좌우 비교를 통해 불균형을 확인할 수도 있습니다."
);

export const feedbackViewDetails = () => {
  return {
    [bodyBalanceMenu.FOOT_TYPE]: {
      [footTypeStatus.FLATFOOT]: {
        status: $L(
          "Flat foot is not a name of a disorder, but a term describing the shape of the foot. Therefore, it includes the case difficult to say it is a disorder due to not having any functional issues, and the case of having serious deformation accompanied by stiffness and functional disorder. Flat foot refers to having no inward arch on the sole to have the sole to be flat, and normally, we are born in flat foot to maintain the flat foot until the early childhood, and arch is created on the foot at about age 5~6 to be completed after the age of 6~8."
        ),
        diseases: [
          $L("Weakened butt muscles"),
          $L("Tense and weakened calf muscles "),
          $L("Plantar fasciitis"),
          $L("Achilles tendonitis"),
          $L("Sprained ankle"),
          $L("Metatarsalgia"),
          $L("Bunion"),
        ],
        lifeStyle: [
          $L("Massage your soles and calf muscles sufficiently while moving"),
          $L("Insole recommendations"),
          $L("Cal muscle stretch"),
          $L("Calf muscle strengthening exercises"),
          $L("Butt muscle strengthening exercises"),
          $L("Toe curls"),
        ],
        exercise: [
          $L("Cal muscle stretch"),
          $L("Heel raises (tiptoeing)"),
          $L("Spreading legs apart while lying on the back or standing"),
          $L("Toe curls (towel exercise)"),
          $L("A single leg balance exercise"),
        ],
      },
      [footTypeStatus.COMMON]: {
        status: $L("Pressure distribution on the soles is even and normal."),
        diseases: [
          $L("The result is normal. Maintain your current foot health."),
        ],
        lifeStyle: [
          $L("Stretching is recommended in the mornings/evenings."),
          $L("Recommended leg muscle strengthening workouts"),
        ],
        exercise: [
          $L("Cal muscle stretch"),
          $L("Squats"),
          $L("A single leg balance exercise"),
        ],
      },
      [footTypeStatus.LUMBAR]: {
        status: $L(
          "Hollow foot is having the dorsum of the foot is higher than normal, and in other words, it refers to having high arch of the sole to convex upward when seen from the side. Due to the high arch, the body weight cannot be distributed evenly on the sole, and concentrated on the heel and ball to result in various symptoms."
        ),
        diseases: [
          $L("Weakened butt muscles"),
          $L("Tense and weakened calf muscles "),
          $L("Plantar fasciitis"),
          $L("Achilles tendonitis"),
          $L("Sprained ankle"),
          $L("Metatarsalgia"),
        ],
        lifeStyle: [
          $L("Massage your soles and calf muscles sufficiently while moving"),
          $L("Insole recommendations"),
          $L("Cal muscle stretch"),
          $L("Butt muscle strengthening exercises"),
          $L("Calf muscle strengthening exercises"),
        ],
        exercise: [
          $L("Cal muscle stretch"),
          $L("Heel raises (tiptoeing)"),
          $L("Spreading legs apart while lying on the back or standing"),
          $L("Toe curls (towel exercise)"),
          $L("A single leg balance exercise"),
        ],
      },
    },
    [bodyBalanceMenu.COP]: {
      mainTitle: $L("What is single leg balance?"),
      status: $L(
        "외발서기도 신체 균형능력을 파악할 수 있어요. 외발서기로 균형을 평가하면 양발서기에서 보지 못하는 동적 상황에서의 발의 형태 변화도 감지할 수 있어요. 즉, 종아리 근육 및 엉덩이 근육이 약해도 양발서기 때는 정상으로 나올 수 있지만 외발서기 시 다른 결과가 나올 수 있어요. 또한 좌우 비교를 통해 불균형을 확인할 수도 있습니다."
      ),
      diseases: [
        $L("Sports activities like riding a bike"),
        $L("Climbing up/down stairs"),
        $L("Lowering something down from a high place"),
        $L("Moving inside a wet bathhouse"),
        $L("Standing in a bus or subway"),
        $L("Fall hazard"),
      ],
      exercise: [
        {
          title: $L("한 발 서기(One-Legged Stand)"),
          desc: $L(
            "의자 등받이나 단단한 물체를 잡고 서서 몸을 고정시킨다. 한 쪽 발을 종아리 높이 정도로 올리고 10초간 버틴다. 10회~15회 반복한 후 반대쪽 다리도 실시한다. 시간이 지나면서 균형감각이 좋아지면 손으로 지지하지 않고도 이 자세를 유지할 수 있게 된다."
          ),
        },
        {
          title: $L("체중 이동(Weight Shifts)"),
          desc: $L(
            "양 발을 엉덩이 너비로 벌리고 선다. 천천히 몸을 옆으로 기울여 한 쪽 다리에 모든 체중이 실리게 한다. 이때 다른 쪽 다리는 땅에서 떨어지게 한다. 최대 30초간 유지한 후, 반대쪽도 실시한다."
          ),
        },
        {
          title: $L("Yoga and Pilates"),
          desc: $L(
            "몸의 균형을 유지하기 위해서는 서고, 걷고, 움직이는 동안 몸을 안정적으로 잡아줄 근육이 필요하다. 이를 '코어근육(core muscles)'이라고 하는데, 요가나 필라테스에는 이 코어근육을 늘리고 강화시키는 데 도움이 되는 동작들이 포함되어 있다."
          ),
        },
        {
          title: $L("Heel-to-toe walk"),
          desc: $L(
            "한 쪽 발뒤꿈치가 반대쪽 발 발가락에 닿게 하면서 일직선으로 천천히 걷는다. 20보 정도 걷되, 몸의 균형이 불안정하면 벽을 이용해 지지한다."
          ),
        },
        {
          title: $L("백 레그 레이즈(Back-Leg Raises)"),
          desc: $L(
            "허리와 엉덩이 힘을 기를 수 있는 운동이다. 의자를 잡고 서서 한 쪽 다리를 뒤로 들어 올린다. 이 때 무릎을 굽히거나 발끝이 바깥쪽을 향하지 않도록 한다. 바닥에 지지하고 있는 다리는 살짝 구부린다. 1초간 자세를 유지한다. 이 동작을 10회~15회 반복한 후 반대쪽도 똑같이 실시한다."
          ),
        },
        {
          title: $L("Knee curls"),
          desc: $L(
            "의자 등받이를 잡고 바닥에 지지하는 다리를 살짝 구부린다. 다른 쪽 다리는 뒤쪽으로 똑바로 들어 올린 후 뒤꿈치를 엉덩이 쪽으로 가져간다. 엉덩이는 움직이지 않는다. 1초간 유지하고, 발을 천천히 바닥으로 내린다. 동작을 10회~15회 반복한 후 반대쪽 다리로 똑같이 실시한다."
          ),
        },
        {
          title: $L("Toe stand"),
          desc: $L(
            "이 운동은 종아리와 발목 근육을 강화시켜 균형감각을 향상시키는 데 도움이 된다. 넘어지지 않도록 의자나 벽을 잡고, 다리는 어깨 너비로 벌리고 선다. 양 발 뒤쪽을 들어 올려 발끝으로 서서 1초간 유지한 후 발을 내린다. 10회~15회 실시한 후 휴식을 취한다. 한 세트 더 반복한다."
          ),
        },
        {
          title: $L("스쿼트(Squats)"),
          desc: $L(
            "스쿼트를 하면 다리와 골반 근육을 강화하는 데 도움이 된다. 다리는 엉덩이보다 약간 더 넓게 벌리고 서고, 발가락은 앞쪽을 향하게 한다. 의자에 앉는 것처럼 무릎을 구부리고 엉덩이는 뒤쪽으로 보낸다. 체중은 뒤꿈치에 싣도록 하고, 양팔은 앞으로 하거나 허벅지에 올린다. 다시 일어선 후, 동작을 10회 반복한다."
          ),
        },
        {
          title: $L("백 익스텐션(Back Extension)"),
          desc: $L(
            "바닥에 배를 대고 엎드려 이마가 바닥을 향하게 한다. 팔은 몸 옆에 두고 손바닥이 위를 향하게 한다. 머리와 팔을 천천히 들어 바닥에서 3cm~5cm 정도 떨어뜨린다. 이 때 시선은 계속 아래를 향하게 한다. 고개를 들면 목에 무리가 갈 수 있다. 몇 초간 유지한 후 부드럽게 내린다. 10세트 반복한다."
          ),
        },
      ],
    },
    [bodyBalanceMenu.BODY_BALANCE_TAB]: {
      mainTitle: $L("What is the balance between two feet?"),
      status: $L(
        "Balance is an ability that maintains the body centered with minimum shaking of the body. The ability to maintain the balance is an essential factor that becomes the very basic in performing the everyday activities. Balance is greatly impacted by the visual information, proprioceptive sensibility (sense that identifies the information on one's body position, posture, equilibrium and movement to transmit the information to the central nervous system) and muscle fatigue, etc. Also, even when having outstanding nerve functions, proper balance cannot be maintained when lacking the strength on the muscles that must receive the command to make the movement. Therefore, the lower body and core muscles must be maintained healthy."
      ),
      diseases: [
        $L("Sports activities like riding a bike"),
        $L("Climbing up/down stairs"),
        $L("Lowering something down from a high place"),
        $L("Moving inside a wet bathhouse"),
        $L("Standing in a bus or subway"),
        $L("Fall hazard"),
      ],
      exercise: [
        {
          title: $L("한 발 서기(One-Legged Stand)"),
          desc: $L(
            "의자 등받이나 단단한 물체를 잡고 서서 몸을 고정시킨다. 한 쪽 발을 종아리 높이 정도로 올리고 10초간 버틴다. 10회~15회 반복한 후 반대쪽 다리도 실시한다. 시간이 지나면서 균형감각이 좋아지면 손으로 지지하지 않고도 이 자세를 유지할 수 있게 된다."
          ),
        },
        {
          title: $L("체중 이동(Weight Shifts)"),
          desc: $L(
            "양 발을 엉덩이 너비로 벌리고 선다. 천천히 몸을 옆으로 기울여 한 쪽 다리에 모든 체중이 실리게 한다. 이때 다른 쪽 다리는 땅에서 떨어지게 한다. 최대 30초간 유지한 후, 반대쪽도 실시한다."
          ),
        },
        {
          title: $L("Yoga and Pilates"),
          desc: $L(
            "몸의 균형을 유지하기 위해서는 서고, 걷고, 움직이는 동안 몸을 안정적으로 잡아줄 근육이 필요하다. 이를 '코어근육(core muscles)'이라고 하는데, 요가나 필라테스에는 이 코어근육을 늘리고 강화시키는 데 도움이 되는 동작들이 포함되어 있다."
          ),
        },
        {
          title: $L("Heel-to-toe walk"),
          desc: $L(
            "한 쪽 발뒤꿈치가 반대쪽 발 발가락에 닿게 하면서 일직선으로 천천히 걷는다. 20보 정도 걷되, 몸의 균형이 불안정하면 벽을 이용해 지지한다."
          ),
        },
        {
          title: $L("백 레그 레이즈(Back-Leg Raises)"),
          desc: $L(
            "허리와 엉덩이 힘을 기를 수 있는 운동이다. 의자를 잡고 서서 한 쪽 다리를 뒤로 들어 올린다. 이 때 무릎을 굽히거나 발끝이 바깥쪽을 향하지 않도록 한다. 바닥에 지지하고 있는 다리는 살짝 구부린다. 1초간 자세를 유지한다. 이 동작을 10회~15회 반복한 후 반대쪽도 똑같이 실시한다."
          ),
        },
        {
          title: $L("무릎 구부리기(Knee Curl)"),
          desc: $L(
            "의자 등받이를 잡고 바닥에 지지하는 다리를 살짝 구부린다. 다른 쪽 다리는 뒤쪽으로 똑바로 들어 올린 후 뒤꿈치를 엉덩이 쪽으로 가져간다. 엉덩이는 움직이지 않는다. 1초간 유지하고, 발을 천천히 바닥으로 내린다. 동작을 10회~15회 반복한 후 반대쪽 다리로 똑같이 실시한다."
          ),
        },
        {
          title: $L("Toe stand"),
          desc: $L(
            "이 운동은 종아리와 발목 근육을 강화시켜 균형감각을 향상시키는 데 도움이 된다. 넘어지지 않도록 의자나 벽을 잡고, 다리는 어깨 너비로 벌리고 선다. 양 발 뒤쪽을 들어 올려 발끝으로 서서 1초간 유지한 후 발을 내린다. 10회~15회 실시한 후 휴식을 취한다. 한 세트 더 반복한다."
          ),
        },
        {
          title: $L("스쿼트(Squats)"),
          desc: $L(
            "스쿼트를 하면 다리와 골반 근육을 강화하는 데 도움이 된다. 다리는 엉덩이보다 약간 더 넓게 벌리고 서고, 발가락은 앞쪽을 향하게 한다. 의자에 앉는 것처럼 무릎을 구부리고 엉덩이는 뒤쪽으로 보낸다. 체중은 뒤꿈치에 싣도록 하고, 양팔은 앞으로 하거나 허벅지에 올린다. 다시 일어선 후, 동작을 10회 반복한다."
          ),
        },
        {
          title: $L("백 익스텐션(Back Extension)"),
          desc: $L(
            "바닥에 배를 대고 엎드려 이마가 바닥을 향하게 한다. 팔은 몸 옆에 두고 손바닥이 위를 향하게 한다. 머리와 팔을 천천히 들어 바닥에서 3cm~5cm 정도 떨어뜨린다. 이 때 시선은 계속 아래를 향하게 한다. 고개를 들면 목에 무리가 갈 수 있다. 몇 초간 유지한 후 부드럽게 내린다. 10세트 반복한다."
          ),
        },
      ],
    },
  };
};

export const getCesFeedbackOverall = () => {
  return {
    [bodyBalanceMenu.FOOT_TYPE]: {
      [footTypeStatus.LUMBAR]: $L(
        "Hollow foot is having the dorsum of the foot is higher than normal, and in other words, it refers to having high arch of the sole to convex upward when seen from the side. Due to the high arch, the body weight cannot be distributed evenly on the sole, and concentrated on the heel and ball to result in various symptoms."
      ),
      [footTypeStatus.SUS_LUMBAR]: $L(
        "Hollow foot is having the dorsum of the foot is higher than normal, and in other words, it refers to having high arch of the sole to convex upward when seen from the side. Due to the high arch, the body weight cannot be distributed evenly on the sole, and concentrated on the heel and ball to result in various symptoms."
      ),
      [footTypeStatus.COMMON]: $L(
        "Pressure distribution on the sole is even, and normal."
      ),
      [footTypeStatus.SUS_FLATFOOT]: $L(
        "Flat foot is not a name of a disorder, but a term describing the shape of the foot. Therefore, it includes the case difficult to say it is a disorder due to not having any functional issues, and the case of having serious deformation accompanied by stiffness and functional disorder. Flat foot refers to having no inward arch on the sole to have the sole to be flat, and normally, we are born in flat foot to maintain the flat foot until the early childhood, and arch is created on the foot at about age 5~6 to be completed after the age of 6~8."
      ),
      [footTypeStatus.FLATFOOT]: $L(
        "Flat foot is not a name of a disorder, but a term describing the shape of the foot. Therefore, it includes the case difficult to say it is a disorder due to not having any functional issues, and the case of having serious deformation accompanied by stiffness and functional disorder. Flat foot refers to having no inward arch on the sole to have the sole to be flat, and normally, we are born in flat foot to maintain the flat foot until the early childhood, and arch is created on the foot at about age 5~6 to be completed after the age of 6~8."
      ),
    },
    [bodyBalanceMenu.BODY_BALANCE_TAB]: $L(
      "Balance is an ability that maintains the body centered with minimum shaking of the body. The ability to maintain the balance is an essential factor that becomes the very basic in performing the everyday activities. Balance is greatly impacted by the visual information, proprioceptive sensibility (sense that identifies the information on one's body position, posture, equilibrium and movement to transmit the information to the central nervous system) and muscle fatigue, etc. Also, even when having outstanding nerve functions, proper balance cannot be maintained when lacking the strength on the muscles that must receive the command to make the movement. Therefore, the lower body and core muscles must be maintained healthy."
    ),
  };
};
