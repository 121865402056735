import { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import css from './TAutoPlayer.module.less';
import TButton from '../TButton/TButton';
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';
import { Marquee, MarqueeController } from "@enact/sandstone/Marquee";
import { $L, transSecToText } from '../../utils/helperMethods';

const Container = SpotlightContainerDecorator({enterTo: "default-element", preserveId: true}, Spottable('div'));

const ItemBase = ({ type='play', setAutoplay, setPlay, onClose, count, retryPrevious, nextTitle, nextDetail, nextDuration, nextThurm }) => {

  // const [countdown, setCountdown] = useState(count);

	const _onClose = useCallback(() => {
    if(onClose){
      onClose();
    }
  }, [onClose]);

	const stopAutoPlay = useCallback(() => {
		setAutoplay(false);
		setPlay(false);
	}, []);

	const retryTest = useCallback(() => {
		if(retryPrevious) retryPrevious();
	}, []);

  useEffect(() => {
    Spotlight.focus('autoPlayerNextStart');
		// const interval = setInterval(() => {
		// 	setCountdown((prevCountdown) => prevCountdown - 1);
		// }, 1000);

		// const timeout = setTimeout(() => {
		// 	_onClose();
		// }, (count*1000));

		// return () => {
		// 	clearInterval(interval);
		// 	clearTimeout(timeout);
		// };

  }, []);

	return (
		<Container className={css.container}>
			<div className={css.imgLayer} style={{backgroundImage: `url(${nextThurm})`}}>
				<div className={css.countdown}><span>{count}</span>{type === "play" ? $L("초 후에 다음 운동이 진행됩니다.") : $L("초 후에 다음 테스트가 진행됩니다.")}</div>
				<TButton className={css.autoPlayStop} onClick={stopAutoPlay}><span/>{$L("Stop automatic play")}</TButton>
			</div>
			<div className={css.textContainer}>
				<div className={css.textLayer}>
					<Marquee className={css.nextTitle} marqueeOn={"focus"}>{nextTitle}</Marquee>
					<div className={css.timeLayer}>
						<div className={css.nextDetail}>{nextDetail}</div><span> | </span>
						<div className={css.nextDuration}>
							{transSecToText(nextDuration)}
						</div>
					</div>
					<div className={css.flexBox}>
						<TButton spotlightId="autoPlayerNextStart" className={css.testBtn} onClick={_onClose}>{type === "play" ? $L("Start the workout now") : $L("Start the test now") }</TButton>
						<TButton className={css.retryBtn} onClick={retryTest}>{type === "play" ? $L("Repeat the previous workout") : $L("Repeat the previous test")}</TButton>
					</div>
				</div>
			</div>
		</Container>
	);
};

const ImageItemDecorator = compose(MarqueeController({ marqueeOnFocus: true }));
const TAutoPlayer = ImageItemDecorator(ItemBase);
export default TAutoPlayer;
