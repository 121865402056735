import React, { useCallback, useEffect } from "react";
import useActivatePositionDetection from "../../hooks/useActivatePositionDetection";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody";
import TButton from "../../components/TButton/TButton";
import PIPCamera from "../../components/PIPCamera/PIPCamera";

/**
 * @module ScanTest
 */

const ScanTest = ({...rest}) => {
const { activatePositionDetection, deActivatePositionDetection} = useActivatePositionDetection();
	useEffect(() => {
		return () => {
			deActivatePositionDetection();
		};
	}, []);
	const scanTest = useCallback(()=>{
		activatePositionDetection({ isDetectionDelayed: false, testMode: true });
	},[]);

	return (
	<TPanel {...rest}>
		<THeader title={"Scan Test Panel"}/>
		<TBody>
			<TButton onClick={scanTest}>Scan Test</TButton>
			<PIPCamera size="small"/>
		</TBody>
	</TPanel>
  );
}

export default ScanTest;