import React, { useMemo, useState } from "react";
import css from "./PTSubTabGraph.module.less";
import classNames from "classnames";
import PTGraphTooltip from "../../../../../assets/physicalTest/ic_Physical Test_graph_tooltip.svg";
import TLineChart from "../../../../components/TLineChart/TLineChart";

const ExerciseTimeGraph = ({ label, data, period }) => {
  const [coordinates, setCoordinates] = useState([]);

  const convertData = useMemo(() => {
    let ret = [];
    if (period === "weekly") {
      data.map((item) => ret.push(Math.round(Number(item)) + "m"));
    } else {
      data.map((item) => ret.push(Math.round(Number(item) / 60) + "h"));
    }

    return ret;
  }, [data, period]);

  const maxRange = useMemo(() => {
    const accConstant = 30;
    const maxData = Math.max(...data);
    const maxRange = accConstant * Math.ceil(maxData / accConstant);
    return maxRange;
  }, [data]);

  return (
    <>
      <div className={css.container}>
        <div className={css.graphArea}>
          <div style={{ bottom: "220px" }} className={css.graphLine}>
            {/* <div>
              <div className={css.yLabel}></div>
            </div> */}
          </div>
          <div style={{ bottom: "176px" }} className={css.graphLine}>
            {/* <div>
              <div className={css.yLabel}>{convertTimeToText(120)}</div>
            </div> */}
          </div>
          <div style={{ bottom: "132px" }} className={css.graphLine}>
            {/* <div>
              <div className={css.yLabel}></div>
            </div> */}
          </div>
          <div style={{ bottom: "88px" }} className={css.graphLine}>
            {/* <div>
              <div className={css.yLabel}>{convertTimeToText(60)}</div>
            </div> */}
          </div>
          <div style={{ bottom: "44px" }} className={css.graphLine}>
            {/* <div>
              <div className={css.yLabel}></div>
            </div> */}
          </div>
          <div
            style={{ bottom: "0", borderBottomStyle: "solid" }}
            className={classNames(css.graphLine)}
          />
          <div className={css.graphContainer}>
            <div className={css.lineGraphBox}>
              <TLineChart
                className={css.lineGraph}
                onLineChartCoordinates={setCoordinates}
                width={384.5}
                height={220}
                maxRange={maxRange}
                dataPointDiameter={6}
                monthlyExerciseData={data}
              />
              {coordinates.length > 0 &&
                coordinates.map((item, index) => (
                  <div
                    className={css.ordinator}
                    key={`coordinates-${index}`}
                    style={{ top: item.y, left: item.x }}
                  >
                    <div style={{ position: "relative" }}>
                      <div className={css.timeTextBox}>
                        {convertData[index]}
                        <img src={PTGraphTooltip} alt="tooltip_arrow" />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={css.labelArea}>
          {label.map((item, index) => (
            <div key={`label-${index}`} className={css.label}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExerciseTimeGraph;
