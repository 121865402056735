import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { $L } from '../../../utils/helperMethods';
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from '@enact/spotlight/Spottable';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination,Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css'
import SubBody from '../SubBody';
import css from "./SubHelp.module.less";
import TButton from '../../../components/TButton/TButton';

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const SpottableComponent = Spottable('div');

const SubHelp = ({ spotlightId, ...rest }) => {

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handlePaginationClick = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index); // 해당 페이지로 슬라이드
      setActiveIndex(index); // 활성화된 페이지 인덱스 업데이트
    }
  };

  const swiperParams = {
    on: {
      slideChange: (swiper) => {
        setActiveIndex(swiper.realIndex);
      },
    },
    touchRatio: 0 //드래그 금지
  };

  const swiperRef = React.useRef(null);

  const HELP_CONTENT_LIST = [
    {
      title: $L("You can manage your health."),
      cont: [
        {
          desc: $L("LG Intelli-FiT opens up an opportunity to become healthier.")
        },
        {
          desc: $L(
            "Do a body checkup to find out your current health status."
          ),
        },
        {
          desc: $L("그리고 My Workout Program과 In home")
        },
        {
          desc: $L("Feel the change in your body by exercising with Outdoor Activity Indoors.")
        }
      ]
    },
    {
      title: $L("My Body Analysis"),
      cont: [
        {
          type: $L("Body Alignment"),
          desc: $L("We recommend visiting a nearby hospital for an accurate assessment.")
        },
        {
          type: $L("Range Of Motion"),
          desc: $L(
            "You can get your joint checked from a medical professional by measuring the range of motion of a joint."
          ),
        },
        {
          type: $L("Body Balance"),
          desc: $L("Use the Smart Mat to assess your balance, foot shape, and get checked for geriatric diseases.")
        },
        {
          type: $L("Physical Test"),
          desc: $L("We provide a self-diagnosis program by dividing the physical capability into high, medium, and low by age group.")
        }
      ]
    },
    {
      title: $L("My Workout Program"),
      cont: [
        {
          type: $L("O2O 정규프로그램"),
          desc: $L("You can join offline programs offered at our global fitness centers at home online.")
        },
        {
          type: $L("AI 맞춤형 운동추천"),
          desc: $L(
            "Here are personalized workout recommendations made based on your data obtained in a My Body Analysis."
          ),
        },
      ],
    },
    {
      title: $L("Outdoor Activity Indoors"),
      cont: [
        {
          type: $L("Walking / Jogging"),
          desc: $L("You can take a walk and tour the world's famous cities from home.")
        },
        {
          type: $L("Hiking"),
          desc: $L("Climb mountains and buildings around the world and enjoy fascinating views of nature and cities.")
        },
        {
          type: $L("Training"),
          desc: $L("You can experience online fitness with coaching from a trainer.")
        },
        {
          type: $L("Golf"),
          desc: $L({
            key: "_You can correct your swing posture and get extra distance with golf coaching.",
            value:
              "You can correct your swing posture and get extra distance with golf coaching.",
          }),
        }
      ]
    }
  ]

  SwiperCore.use([
    Pagination
  ]);

  return (
    <SubBody>
      <div className={css.helpcontainer}>
        <Container className={css.paginationWrap}
          spotlightId={"helpButtonActivity"}
        >
          {HELP_CONTENT_LIST.map((el, idx) => {
            return (
              <TButton
                key={"helpSwiperBtn" + idx}
                className={activeIndex === idx ? classNames(css.helpSwiperBtn, css.activePage) : css.helpSwiperBtn}
                onClick={() => handlePaginationClick(idx)}
              />
            )
          })}
        </Container>
        <Swiper ref={swiperRef} {...swiperParams} className={css.swiper}>
          {HELP_CONTENT_LIST.map((helpEl, idx) => {
            return (
              <SwiperSlide key={"helpSwiper" + idx} className={css.swiperSlide}>
                <div className={css.helpSliderInner}>
                  <div className={css.helpTitle}>{helpEl.title}</div>
                  <div className={css.helpCont}>
                    <div className={css.helpFlex}>
                      <div className={css.helpType}>
                        {helpEl.cont.map((helpCont, idx) => {
                          return (
                            <div key={"helpCont" + idx} className={css.type}>{helpCont.type}</div>
                          )
                        })}
                      </div>
                      <div className={css.helpDesc}>
                        {helpEl.cont.map((helpCont, idx) => {
                          return (
                            <div key={"helpDesc" + idx} className={css.desc}>{helpCont.desc}</div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>

      </div>
    </SubBody>
  );
};

export default SubHelp;
