import React, { useCallback, useState, useMemo, useRef, useEffect } from "react";
import "animate.css";
import css from "./ROMTest.module.less";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { $L, cloneObject } from '../../../../utils/helperMethods';
import { Job } from "@enact/core/util";
import * as Config from "../../../../utils/Config";
import bellyPoint from "../../../../../assets/bodyScan/point.png";
import TShakaPlayer from "../../../../components/TShakaPlayer/TShakaPlayer";
import PIPCamera from "../../../../components/PIPCamera/PIPCamera";
import { addPanels, popPanel, updatePanel } from "../../../../features/panels/panelsSlice";
import { changeAppStatus } from "../../../../features/common/commonSlice";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import useActivatePositionDetection from "../../../../hooks/useActivatePositionDetection";
import Cancelable from "@enact/ui/Cancelable";
import { ROM_TYPE, SUB_TYPE, ROM_SEQUENCE, INTRO_INFO, INTRO_AVATAR, ROM_ONTIME, initROMOnTime, SUB_TEST_SEQUENCE, getROM_INFO, getROM_SUB_TEST_INFO, getROM_POSITINO_INFO, 
	getSUB_ONTIME, GUIDE_START_TIME, ROM_POSITION_TYPE, initAllROMOnTime, initAllSubOnTime } from "./Constants";
import WebWorkerUtil, { WORKER_ID } from "../../../../utils/WebWorker/WebWorkerUtil";
import Spotlight from "@enact/spotlight";
import { DATAKEY, setCurrentTestingName, setCurrentSubTestingName, clearCurrentTestingStatus, updateCurrentTestingStatus, updateTestResults } from "../../../../features/rom/romSlice";
import { BELLY_CHECKING_INDEX, BellySmoother } from "../../../../utils/Constants";

//component
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import TStepper from "../../../../components/TStepper/TStepper";
import TestSelectPopup from "../../../../components/TestSelectPopup/TestSelectPopup";
import TestPartCompletePopup from "../../../../components/TestPartCompletePopup/TestPartCompletePopup";
import BodyPositionViewer from "../../../../components/BodyPositionViewer";
import TestEndCompletePopup from "../../../../components/TestEndCompletePopup/TestEndCompletePopup";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import TestGuideSignMsg from "../../../../components/TestGuideSignMsg/TestGuideSignMsg";
import AngleGuideCanvas from "./AngleGuideCanvas/AngleGuideCanvas";
import useROM from "../../../../hooks/useROM";
import BodyCheckUpCountdownTimer from "../../BodyCheckUpCountdownTimer/BodyCheckUpCountdownTimer";
import TTestClosePopup from "../../../../components/TTestClosePopup/TTestClosePopup";
import BodyPositionCorrectionSign from "../../BodyAlignment/BodyPositionCorrectionSign/BodyPositionCorrectionSign";
import * as TTSService from "../../../../lunaSend/TTSService";
import TIconButton from "../../../../components/TIconButton/TIconButton";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const clearGuideTextJob = new Job((setGuideText, setVoiceGuide, voiceCode = "") => {
	setGuideText({ text: "" });
	setVoiceGuide && setVoiceGuide(voiceCode);
}, 0);

const resumeTestJob = new Job((resumeTest) => {
	resumeTest();
}, 0);

const CancelableDiv = Cancelable({ modal: true, onCancel: 'handleCancel' }, 'div');

const ButtonContainer = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const DEFAULT_ROM_LIST = [
	ROM_TYPE.NECK,
	ROM_TYPE.SHOULDER,
	ROM_TYPE.TRUNK,
	ROM_TYPE.KNEE,
	ROM_TYPE.HIP,
];

let ROM_INFO = null;
let ROM_POSITION_INFO = null;
let ROM_SUB_TEST_INFO = null;
let SUB_ONTIME = null;
const ROMTest = ({ panelInfo, ...rest }) => {
	if (!ROM_INFO) {
		ROM_INFO = getROM_INFO();
	}
	if(!SUB_ONTIME) {
		SUB_ONTIME = getSUB_ONTIME();
	}
	if (!ROM_SUB_TEST_INFO) {
		ROM_SUB_TEST_INFO = getROM_SUB_TEST_INFO();
	}
	if (!ROM_POSITION_INFO) {
		ROM_POSITION_INFO = getROM_POSITINO_INFO();
	}
	const dispatch = useDispatch();
	const mWorker = useRef(null);
	const bellySmootherRef = useRef([]);
	const finishDelayRef = useRef(null);
	const PTVideoPlayerRef = useRef();
	const { saveTestResults } = useROM();
	const mountedTime = useRef(null);
	const [playVideo, setPlayVideo] = useState(false);
	const [romDegreeValue, setRomDegreeValue] = useState(0);
	const [abductionValueLeft, setAbductionValueLeft] = useState(0);
	const [abductionValueRight, setAbductionValueRight] = useState(0);
	const [angleGuideCanvasDirection, setAngleGuideCanvasDirection] = useState('auto');
	const initialCheckedState = Array(ROM_INFO.length).fill(true);
	const romSequenceRef = useRef(-1);
	const [isTimeUp, setIsTimeUp] = useState(true);
	const [romSequence, setRomSequence] = useState(-1);
	const subTestSequenceRef = useRef(SUB_TEST_SEQUENCE.SEQUENCE_INIT);
	const [subTestSequence, setSubTestSequence] = useState(SUB_TEST_SEQUENCE.SEQUENCE_INIT);
	const [retry, setRetry] = useState(false);
	const [showTestPartComplete, setShowTestPartComplete] = useState(false);
	const [showTestEndComplete, setShowTestEndComplete] = useState(false);
	const [forceMsg, setForceMsg] = useState("");
	const [guideDegree, setGuideDegree] = useState(0);
	const [testTitle, setTestTitle] = useState("");
	const { isAppForeground, showLoadingPanel } = useSelector((state) => state.common.appStatus);
	const { cameraList, cameraSize } = useSelector(state => state.camera);
	const { cameraPosition, skipVideoGuide, showBodyCoordinations, xFitBodyBellySmootherQueueSize, cesShowMode } = useSelector((state) => state.common.localSettings);
	const [avatarImage, setAvatarImage] = useState(null);
	const [currentRomIndex, setCurrentRomIndex] = useState(0);
	const [currentSubTestIndex, setCurrentSubTestIndex] = useState(0);
	const [guideText, setGuideText] = useState({ text: "" });
	const [isCountDownStarted, setIsCountDownStarted] = useState(false);
	const [loopVoiceGuide, setLoopVoiceGuide] = useState("");
	const [voiceGuide, setVoiceGuide] = useState("");
	const { activatePositionDetection, deActivatePositionDetection, bodyPositionLiveData, cancelGetEventNotification } = useActivatePositionDetection();
	const [subRomScore, setSubRomScore] = useState({});
	const [angleBaseLine, setAngleBaseLine] = useState([]);
	const [isTestClose, setTestClose] = useState(false); //test 중에 뒤로가기
	const [bodyPositionCorrectionDirection, setBodyPositionCorrectionDirection] = useState({});
	const romTestCurrent = useSelector(state => state.rom.current);
	const broadcast = useSelector((state) => state.common.broadcast);
	const [lastProcessedTime, setLastProcessedTime] = useState(0);
	const [guideVoice, setGuideVoice] = useState("");

	const romListByPosition  = useMemo(() => {
		console.log('yhcho romListByPosition panelInfo?.romList', panelInfo?.romList);
		let romList = panelInfo?.romList ? panelInfo?.romList: DEFAULT_ROM_LIST;
		const list = [];
		initAllROMOnTime();
		for(let i=0; i<Object.keys(ROM_POSITION_TYPE).length; i++){
			list.push(cloneObject(ROM_POSITION_INFO[i]));
			list[i].test = [];
		}
		romList.forEach((r)=>{
			switch(r){
				case ROM_TYPE.NECK:
					list[ROM_POSITION_TYPE.FRONT].test.push(SUB_TYPE.NECK_LATERAL_FLEXION);
					list[ROM_POSITION_TYPE.RIGHT].test.push(SUB_TYPE.NECK_FLEXION_EXTENSION);
					break;
				case ROM_TYPE.SHOULDER:
					list[ROM_POSITION_TYPE.FRONT].test.push(SUB_TYPE.SHOULDER_ABDUCTION);
					list[ROM_POSITION_TYPE.RIGHT].test.push(SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT);
					list[ROM_POSITION_TYPE.RIGHT].test.push(SUB_TYPE.SHOULDER_FLEXION_EXTENSION_RIGHT);
					list[ROM_POSITION_TYPE.LEFT].test.push(SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT);
					list[ROM_POSITION_TYPE.LEFT].test.push(SUB_TYPE.SHOULDER_FLEXION_EXTENSION_LEFT);
					break;
				case ROM_TYPE.TRUNK:
					list[ROM_POSITION_TYPE.FRONT].test.push(SUB_TYPE.TRUNK_LATERAL_FLEXION);
					list[ROM_POSITION_TYPE.RIGHT].test.push(SUB_TYPE.TRUNK_FLEXION_EXTENSION);
					break;
				case ROM_TYPE.HIP:
					list[ROM_POSITION_TYPE.RIGHT].test.push(SUB_TYPE.HIP_FLEXION_EXTENSION_RIGHT);
					list[ROM_POSITION_TYPE.LEFT].test.push(SUB_TYPE.HIP_FLEXION_EXTENSION_LEFT);
					break;
				case ROM_TYPE.KNEE:
					list[ROM_POSITION_TYPE.RIGHT].test.push(SUB_TYPE.KNEE_FLEXION_RIGHT);
					list[ROM_POSITION_TYPE.LEFT].test.push(SUB_TYPE.KNEE_FLEXION_LEFT);
					break;
			}
		});
		console.log('yhcho romListByPosition', list);
		return list.filter((l)=> l.test.length>0);
	}, [panelInfo]);

	// Guide-test Title
	const renderGuideText = useCallback(() => {
		if (guideText.text) {
			return (
				<div>
					{romListByPosition.length !== 1 && romSequence !== ROM_SEQUENCE.TEST_START &&
						<div className={css.testNo}>{$L("Test").toUpperCase()} {currentRomIndex + 1}</div>
					}
					<div className={css.guideText}>{guideText.text}</div>
				</div>
			)
		}
		return null;
	}, [guideText, romListByPosition, currentRomIndex]);

	const stepperList = useMemo(() => {
		const ret = [];
		ret.push($L('Start'));
		for (let i = 0; i < romListByPosition.length; i++) {
			ret.push(romListByPosition[i].guideText);
		}
		ret.push($L('Finished'));
		console.log('yhcho stepperList ', ret);
		return ret;
	}, [romListByPosition]);

	useEffect(() => {
		const canvasSize = { w: 960, h: 1080 };
		const scale = canvasSize.h / cameraSize.height;
		const leftMargin = (cameraSize.width * scale - canvasSize.w) / 2;
		if (!!bodyPositionLiveData?.joints2D?.length) {
			BELLY_CHECKING_INDEX.forEach((value, index) => {
				const bellyCoordination = bodyPositionLiveData.joints2D[value];
				const reversedCameraBellyX = canvasSize.w - (bellyCoordination[0] * scale - leftMargin);
				const reversedCameraBellyY = bellyCoordination[1] * scale;
				if (!bellySmootherRef.current[index]) {
					bellySmootherRef.current[index] = new BellySmoother(xFitBodyBellySmootherQueueSize);
				}
				bellySmootherRef.current[index].pushValue(reversedCameraBellyX, reversedCameraBellyY);
			});
		}
	}, [bodyPositionLiveData, cameraSize]);

	//after 3,2,1
	const countdownCompleted = () => {
		setIsTimeUp(false); // show progress
		setSubTestSequence(SUB_TEST_SEQUENCE.TEST_START); // start subTest
		setIsCountDownStarted(false);
	};

	//after counter complete
	const onTimeCounterComplete = useCallback(() => {
		setTimeout(() => {
			setIsTimeUp(true);
		}, 0);
	}, [romSequence]);

	const doSequancialAction = useCallback((action, key, isSubAction) => {
		if (action && (!action.excuted)) {
			console.log('ROM doSequancialAction[action,key,romSequenceRef]:', action, key);
			action.excuted = true;
			if (typeof action.avatarImage !== "undefined") {
				setAvatarImage(action.avatarImage);
			}
			if (typeof action.angleGuideCanvasDirection !== "undefined") {
				setAngleGuideCanvasDirection(action.angleGuideCanvasDirection);
			}
			if (action.voiceGuide) {
				setVoiceGuide(action.voiceGuide);
			}
			if (typeof action.loopVoiceGuide !== "undefined") {
				setLoopVoiceGuide(action.loopVoiceGuide);
			}
			if (action.timer) {
				setIsTimeUp(false);
			}
			if (action.videoPause) {
				PTVideoPlayerRef.current.pause();
			}
			if (action.guideMsg) {
				setForceMsg($L("I'll do it first. You can follow along."));
			}
			if (action.testMsg) {
				setForceMsg($L("Move as much as you can."));
				setGuideDegree(action.degree);
			}
			if (action.noMsg) {
				setForceMsg("");
			}
			if (action.testTitle) {
				setTestTitle(action.testTitle);
			}
			if (action.sequence) {
				if (isSubAction) {
					setSubTestSequence(action.sequence);
				} else {
					setRomSequence(action.sequence);
				}
			}
		}
	}, []);

	const showPIPCamera = useMemo(() => {
		if (!isAppForeground) {
			return false;
		}
		if (typeof window === 'object' && window.PalmSystem) {
			return cameraList && cameraList.length > 0;;
		} else {
			return true;
		}
	}, [isAppForeground, cameraList]);

	const currentRomInfo = useMemo(() => {
		return romListByPosition[currentRomIndex] ? romListByPosition[currentRomIndex] : {};
	}, [currentRomIndex, romListByPosition]);

	const currentSubTestInfo = useMemo(() => {
		if (romSequence === ROM_SEQUENCE.SEQUENCE_INIT) {
			return INTRO_INFO;
		}
		const subTestKey = currentRomInfo.test[currentSubTestIndex];
		return ROM_SUB_TEST_INFO[subTestKey] ? ROM_SUB_TEST_INFO[subTestKey] : {};
	}, [currentRomInfo, currentSubTestIndex, romSequence]);

	const guideVideo  = useMemo(() => {
		if (romSequence <= ROM_SEQUENCE.SEQUENCE_INIT) {
			return INTRO_INFO.guideVideo;
		}
		if(romSequence < ROM_SEQUENCE.TEST_START){
			return currentRomInfo.guideVideo;
		}
		const subTestKey = currentRomInfo.test[currentSubTestIndex];
		if(ROM_SUB_TEST_INFO[subTestKey]){
			return ROM_SUB_TEST_INFO[subTestKey].guideVideo;
		}
		return "";
	}, [currentRomInfo, currentSubTestIndex, romSequence]);

	const currentRomOnTime = useMemo(() => {
		return ROM_ONTIME[romListByPosition[currentRomIndex]?.type] ? ROM_ONTIME[romListByPosition[currentRomIndex]?.type] : {};
	}, [currentRomIndex, romListByPosition]);

	const currentSubTestOnTime = useMemo(() => {
		const subTestKey = currentRomInfo.test[currentSubTestIndex];
		return SUB_ONTIME[subTestKey] ? SUB_ONTIME[subTestKey] : {};
	}, [currentRomInfo, currentSubTestIndex]);

	const onCameraReady = () => {
		dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
		activatePositionDetection({ isDetectionDelayed: false });
		setTimeout(() => {
			setRomSequence(ROM_SEQUENCE.SEQUENCE_INIT);
			// delay: MIN_SHOWING_TIME+HIDING_TIME in loadingpanel
		}, (4000 - (new Date() - mountedTime.current)));
	};

	useEffect(() => {
		Spotlight.focus("romRetryPreviousTest");
		Spotlight.focus("RomTestSkipBtn");
	}, [currentRomIndex])


	useEffect(() => {
		// dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
		mountedTime.current = new Date();

		WebWorkerUtil.makeWorker(WORKER_ID.XFIT, onResponseWorker).then((workerId) => {
			mWorker.current = workerId;
		});
		setTimeout(() => {
			TTSService.stop();
		}, 1000);

		return () => {
			dispatch(clearCurrentTestingStatus());
			deActivatePositionDetection();
			dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
		};
	}, []);

	useEffect(() => {
		if (!isTimeUp && bodyPositionLiveData !== undefined && !!bodyPositionLiveData?.id) {
			if (currentSubTestInfo.engineParams.direction === "front") {
				WebWorkerUtil.postMessage(mWorker.current, { type: "checkLocation", value: [bodyPositionLiveData, [cameraSize.width, cameraSize.height]] }, true);
			} else {
				WebWorkerUtil.postMessage(mWorker.current, { type: "checkLocationSide", value: [bodyPositionLiveData, [cameraSize.width, cameraSize.height]] }, true);
			}
			WebWorkerUtil.postMessage(mWorker.current, { type: "process", value: [bodyPositionLiveData] }, true);
		}
	}, [bodyPositionLiveData]);

	const onResponseWorker = useCallback((e) => {
		console.log('ROM onResponseWorker', e);
		if (e.type === 'checkLocation' || e.type === 'checkLocationSide') {
			const bodyAdjustmentDirection = e.value;
			setBodyPositionCorrectionDirection(bodyAdjustmentDirection);
		}
		if (e.type === 'getRomScore') {
			const romScore = { ...e.value };
			dispatch(updateCurrentTestingStatus({ [currentSubTestInfo.file]: romScore }));
			setSubRomScore(romScore);
		}
		if (e.type === 'process') {
			const processResult = e.value;
			console.log('processResult', processResult, processResult.value, currentSubTestInfo.guideMsg);
			setAngleBaseLine(processResult.baseline);
			setRomDegreeValue(processResult.value === -888 ? 0 : processResult.value);
			if (currentSubTestInfo.file === 'shoulderAbduction') {
				setAbductionValueLeft(processResult.value[1] === -999 ? 0 : processResult.value[1]);
				setAbductionValueRight(processResult.value[0] === -999? 0 :processResult.value[0]);
			}
			if(Date.now() - lastProcessedTime > 3000){
				setForceMsg("");
				if(processResult.value === -888) {
					console.log('processResult.value === -888, guideMsg', currentSubTestInfo.guideMsg);
					setTimeout(()=>{
						setForceMsg(currentSubTestInfo.guideMsg);
						setGuideVoice(currentSubTestInfo.voiceGuide)
					}, 0);
				}
				setLastProcessedTime(Date.now());
			}
		}
	}, [currentRomInfo, currentSubTestInfo, lastProcessedTime]);

	useEffect(()=>{
		setLastProcessedTime(0);
	},[currentSubTestInfo]);

	useEffect(() => {
		if (abductionValueLeft == -999 || abductionValueRight == -999) {
			setLoopVoiceGuide("SCH_BUTTON_10");
		} else {
			setLoopVoiceGuide("");
		}
	}, [abductionValueLeft, abductionValueRight])

	useEffect(() => {
		WebWorkerUtil.setCallback(mWorker.current, onResponseWorker);
	}, [onResponseWorker]);

	useEffect(() => {
		if (cameraList && cameraList.length < 1) {
			dispatch(popPanel(Config.panel_names.ROM_TEST));
		}
	}, [cameraList]);

	useEffect(() => {
		if(broadcast?.type === 'positionLiveDataCleared'){
			BELLY_CHECKING_INDEX.forEach((_, index) => {
				bellySmootherRef.current[index]?.clearQueue();
			});
			onTestPause();
			setTestClose(true);
		}
	}, [broadcast]);

	useEffect(() => {
		romSequenceRef.current = romSequence;
		subTestSequenceRef.current = subTestSequence;
		console.log("ROMtest romSequence ", "* romSequence : ", romSequence, "* currentSubTestInfo.title : ", currentSubTestInfo.title, "* subTestSequence : ", subTestSequence);
		//knne 100 0 kneeFlexion 100
		switch (romSequence) {
			case ROM_SEQUENCE.SEQUENCE_INIT: {
				setForceMsg("");
				setPlayVideo(true);
				if(skipVideoGuide){
					setRomSequence(ROM_SEQUENCE.GUIDE_START);
				}else{
					PTVideoPlayerRef.current?.play();
				}
				break;
			}
			case ROM_SEQUENCE.GUIDE_START: {
				setGuideText({ text: currentRomInfo.guideText });
				clearGuideTextJob.startAfter(3000, setGuideText);
				initROMOnTime(romListByPosition[currentRomIndex]?.type);
				// console.log('ROM_SEQUENCE.GUIDE_START', "romList[currentRomIndex :", romList[currentRomIndex]);
				if(skipVideoGuide){
					PTVideoPlayerRef.current?.pause();
					setTimeout(() => {
						setRomSequence(ROM_SEQUENCE.GUIDE_END);
					}, 1000);
				}else{
					PTVideoPlayerRef.current?.play();
				}
				break;
			}
			case ROM_SEQUENCE.GUIDE_END: {
				setRomSequence(ROM_SEQUENCE.TEST_START);
				break;
			}
			case ROM_SEQUENCE.TEST_START: {
				if (typeof window === 'object' && !window.PalmSystem) {
					setAngleBaseLine([[500, 400], [550, 276], [500, 200]]);
				}
				//todo sub Guide start > end > test start > end
				switch (subTestSequence) {
					case SUB_TEST_SEQUENCE.SEQUENCE_INIT: {
						initAllSubOnTime(currentSubTestInfo.type);
						setIsTimeUp(false);
						if (PTVideoPlayerRef.current) {
								PTVideoPlayerRef.current?.play();
								// setGuideText({ text: currentSubTestInfo.position });
								clearGuideTextJob.startAfter(4000, setGuideText, setVoiceGuide, "");
							}
						break;
					}
					case SUB_TEST_SEQUENCE.GUIDE_START: {
						setIsTimeUp(false);
						dispatch(setCurrentTestingName(currentSubTestInfo.resultName));
						WebWorkerUtil.postMessage(mWorker.current, { type: "init" }, true);
						WebWorkerUtil.postMessage(mWorker.current, { type: "setParam", value: ["rom", currentSubTestInfo.engineParams.index, currentSubTestInfo.engineParams.direction] }, true);
						if(currentSubTestInfo.file !== 'trunkLateralFlexion' || currentSubTestInfo.file !== 'trunkFlexionExtensionRight'){
							WebWorkerUtil.postMessage(mWorker.current, { type: "setBodyAnchorPoint", value: [currentSubTestInfo.engineParams.direction, bodyPositionLiveData] }, true);
						}
						clearGuideTextJob.startAfter(2500, setGuideText, setVoiceGuide, currentSubTestInfo.preCountVoice);
						if (PTVideoPlayerRef.current) {
							PTVideoPlayerRef.current?.play();
						}
						break;
					}
					case SUB_TEST_SEQUENCE.GUIDE_END: {
						setTimeout(() => {
							setIsTimeUp(true);
							setSubTestSequence(SUB_TEST_SEQUENCE.TEST_START);
						}, 0)
						break;
					}
					case SUB_TEST_SEQUENCE.TEST_START: {
						setLoopVoiceGuide("");
						dispatch(setCurrentSubTestingName(currentSubTestInfo.title));
						setRomDegreeValue(0);
						break;
					}
					case SUB_TEST_SEQUENCE.GET_SCORE: {
						setForceMsg("");
						setLoopVoiceGuide("");
						WebWorkerUtil.postMessage(mWorker.current, { type: "getRomScore" }, true);
						setTimeout(() => {
							setSubTestSequence(SUB_TEST_SEQUENCE.TEST_END);
						}, 2500);
						break;
					}
					case SUB_TEST_SEQUENCE.TEST_END: {
						setForceMsg("");
						setLoopVoiceGuide("");
						setTimeout(() => {
							if (currentSubTestIndex + 1 >= currentRomInfo.test.length) {
								setCurrentSubTestIndex(0);
								setRomSequence(ROM_SEQUENCE.TEST_END);
							} else {
								setCurrentSubTestIndex(currentSubTestIndex + 1);
							}
							setSubTestSequence(SUB_TEST_SEQUENCE.SEQUENCE_INIT);
						}, 0)
						break;
					}
				}
				break;
			}
			case ROM_SEQUENCE.TEST_END: {
				// final test
				if (currentRomIndex + 1 >= romListByPosition.length) {
					setVoiceGuide("M10"); // todo 멘트 변경 (현재 멘트 Great job! You've completed scanning!)
					setShowTestEndComplete(true);
					finishDelayRef.current = setTimeout(() => {
						setRomSequence(ROM_SEQUENCE.SEQUENCE_FINISHED);
					}, 4800);
				} else { // 마지막 테스트 아니면...
					setShowTestPartComplete(true);
				}
				break;
			}
			case ROM_SEQUENCE.SEQUENCE_FINISHED: {
				saveTestResults();
				if (!retry) {
					setTimeout(() => {
						dispatch(popPanel());
						if (cesShowMode) {
							dispatch(addPanels({ name: Config.panel_names.ROM_REPORT }));
						}
					}, 0);
				}
				break;
			}
		}
  }, [romSequence, subTestSequence, cesShowMode, dispatch]);

	const onVideoProgress = useCallback((ev) => {
		const current = ev;
		let isSubTestCase = romSequence >= ROM_SEQUENCE.TEST_START;
		// trigger
		if (!PTVideoPlayerRef.current.paused() && romSequence !== ROM_SEQUENCE.SEQUENCE_INIT) {
			let matched = -1;
			const onTimeTable = isSubTestCase ? currentSubTestOnTime : currentRomOnTime;
			const keys = Object.keys(onTimeTable);
			for (let i = 0; i < keys.length; i++) {
				if (Number(current) >= Number(keys[i]) && !onTimeTable[keys[i]].excuted) {
					matched = keys[i];
					break;
				}
			}
			const action = onTimeTable[matched];
			doSequancialAction(action, matched, isSubTestCase);
		}
	}, [currentRomOnTime, currentSubTestOnTime, romSequence, doSequancialAction]);

	const onVideoEnd = useCallback(() => {
		// console.log('onVideoEnd subAction', subTestSequence, currentSubTestOnTime, romSequence, currentRomOnTime);
		if (romSequence === ROM_SEQUENCE.SEQUENCE_INIT) {
			setRomSequence(ROM_SEQUENCE.GUIDE_START);
			setAvatarImage(INTRO_AVATAR);
		}else if (romSequence >= ROM_SEQUENCE.TEST_START) {
			const subAction = currentSubTestOnTime["end"];
			doSequancialAction(subAction, "end", true);
		} else {
			const action = currentRomOnTime["end"];
			doSequancialAction(action, "end", false);
		}
	}, [currentRomOnTime, currentSubTestOnTime, romSequence, subTestSequence, doSequancialAction]);

	const onVoiceGuideEnd = useCallback((ev) => {
		// console.log("ROM onVoiceGuideEnd", "ev:", ev, "currentRomInfo :", currentRomInfo, "currentSubTestInfo.preCountVoice :", currentSubTestInfo.preCountVoice);
	}, [currentRomInfo, currentSubTestInfo, onVideoEnd]);

	const handleTestStart = useCallback(({ checked }) => {
		console.log('ROM handleTestStart', romSequenceRef.current, checked, currentSubTestInfo);
		setRetry(false);
		setShowTestPartComplete(false);
		setShowTestEndComplete(false);
		if (!checked) {
			if (currentRomIndex + 1 >= romListByPosition.length) {
				setRomSequence(ROM_SEQUENCE.SEQUENCE_FINISHED);
			} else {
				let nextIndex = currentRomIndex + 1;
				setCurrentRomIndex(nextIndex);
				setRomSequence(ROM_SEQUENCE.GUIDE_START);
			}
		}
		if (checked) {
			setCurrentRomIndex(0);
			setAvatarImage(null);
			dispatch(updatePanel({ name: Config.panel_names.ROM_TEST, panelInfo: { romList: checked } }));
			setRomSequence(ROM_SEQUENCE.SEQUENCE_INIT);
		}
	}, [dispatch, currentRomIndex, romListByPosition, romSequence]);

	// retry previous test (testPartCompletePopup, 이전 테스트하기)
	const retryTest = useCallback(() => {
		PTVideoPlayerRef.current.seekTo(GUIDE_START_TIME);
		setShowTestPartComplete(false);
		setRomSequence(ROM_SEQUENCE.GUIDE_START);
	}, []);

	// 이전 테스트 다시 할 수 있는 팝업
	const retrySelectedTest = useCallback(() => {
		setShowTestPartComplete(false);
		setRetry(true);
		clearTimeout(finishDelayRef.current);
	}, []);

	// 현재 테스트 스킵
	const skipTest = useCallback(() => {
		setSubTestSequence(SUB_TEST_SEQUENCE.TEST_END);
	}, [subTestSequence]);

	const seekToPrevious = useCallback((sec) => {
		if (PTVideoPlayerRef.current) {
			PTVideoPlayerRef.current.seekTo(sec);
			setSubTestSequence(SUB_TEST_SEQUENCE.SEQUENCE_INIT);
		}
	}, [currentSubTestOnTime, doSequancialAction]);

	const onTestPause = useCallback((e) => {
		if (PTVideoPlayerRef.current) {
			PTVideoPlayerRef.current.pause();
		}
		setIsTimeUp(true);
		setRetry(false);
		setShowTestPartComplete(false);
		setShowTestEndComplete(false);
		setPlayVideo(false);
		setForceMsg("");
		setGuideDegree(0);
		setGuideText({ text: "" });
		setLoopVoiceGuide("");
		setVoiceGuide("");
	}, []);

	const resumeTest = useCallback(() => {
		Spotlight.focus("#shakaPlayer");
		setPlayVideo(true);
		seekToPrevious(GUIDE_START_TIME);
	}, []);

	const onTestResume = useCallback(() => {
		resumeTestJob.startAfter(500, resumeTest);
	}, [resumeTestJob]);

	const backKeyHandler = useCallback((ev) => {
		dispatch(popPanel());
		if (ev) {
			ev?.stopPropagation();
			ev?.preventDefault();
		}
	},[dispatch]);

	const pauseHandler = useCallback((ev) => {
		if (ev) ev.stopPropagation();
		if (isTestClose) {
			if (PTVideoPlayerRef) {
				onTestResume();
			}
			setTestClose(false);
		} else {
			if (romSequenceRef.current !== ROM_SEQUENCE.SEQUENCE_INIT
				&& subTestSequenceRef.current !== SUB_TEST_SEQUENCE.SEQUENCE_INIT
			) {
				setTestClose(true);
				onTestPause();
			}
		}
	}, [dispatch, isTestClose]);

	const onClickTestClose = useCallback((index) => {
		if (!index) {
			if (PTVideoPlayerRef.current) onTestResume();
		}
		setTestClose(false);
	}, []);

	// shoulderAbduction - angleBaseLine
	const convertedAngleBaseLine = useMemo(() => {
		const currentTime = PTVideoPlayerRef?.current?.getCurrentTime();
		if (currentTime !== undefined) {
			if (currentSubTestInfo.file === 'shoulderAbduction' && currentTime < 14.0) {
				return angleBaseLine?.slice(3, 6); // L
			} else if (currentSubTestInfo.file === 'shoulderAbduction' && currentTime >= 14.0) {
				return angleBaseLine?.slice(0, 3); // R
			}
		}
		return angleBaseLine;
	}, [angleBaseLine])

	// shoulderAbduction - testValue
	const testDegreeValue = useMemo(() => {
		const currentTime = PTVideoPlayerRef?.current?.getCurrentTime();
		if (currentTime !== undefined) {
			if (currentSubTestInfo.file === 'shoulderAbduction' && currentTime < 14.0) {
				return abductionValueLeft;
			} else if (currentSubTestInfo.file === 'shoulderAbduction' && currentTime >= 14.0) {
				return abductionValueRight;
			}
		}
		return romDegreeValue;
	}, [currentSubTestInfo, abductionValueLeft, abductionValueRight, romDegreeValue])

	const renderRomScore = useCallback(() => {
		const isKnee = (currentSubTestInfo.type === SUB_TYPE.KNEE_FLEXION_LEFT
			|| currentSubTestInfo.type === SUB_TYPE.KNEE_FLEXION_RIGHT);
		if (!subRomScore?.grade) {
			return null;
		}
		const romScoreElements = Object.keys(subRomScore.grade).map((index) => {
			let gradeValue = subRomScore.grade[index];
			let testValue = subRomScore.value[index];
			const romScoreTitle = currentSubTestInfo.romScoreTitle[index];
			if (index == 1 && isKnee) {
				return null;
			}
			return (
				<div key={index} className={classNames(css.flexBox)}>
					<div className={classNames(css.subGrade, gradeValue === 'c' ? css.danger : gradeValue === 'b' ? css.manage : css.prevention)}>
						{gradeValue === 'c' ? $L("Weak") : gradeValue === 'b' ? $L("Borderline") : $L("Good")}
					</div>
					<div className={css.title}>{romScoreTitle}</div>
					<div className={css.info}>
						<div className={css.value}><span /> {testValue}°</div>
					</div>
				</div>
			);
		})
		return (
			<div className={classNames(css.averageRomScore, isKnee && css.knee)}>
				<div className={classNames(css.grade)}>
					<div className={css.flexContainer}>{romScoreElements}</div>
				</div>
			</div>
		);
	}, [subRomScore, currentSubTestInfo, romTestCurrent]);

	const stepperNumber = useMemo(() => {
		if (romSequence === ROM_SEQUENCE.SEQUENCE_INIT) {
			return 0;
		}
		return romSequenceRef.current === ROM_SEQUENCE.SEQUENCE_FINISHED ? stepperList.length - 1 : currentRomIndex + 1;
	}, [romSequence, stepperList, currentRomIndex]);

	const onBodyCorrectionSign = useCallback((ret) => {
		if (ret.instruction) {
			setForceMsg(ret.instruction);
		}
	}, []);

	const retryPreviousTest = useCallback(() => {
		if (currentRomIndex > 0 && currentSubTestIndex === 0) {
			onTestPause();
			let mainTestIndex = currentRomIndex - 1;
			// 마지막 서브 테스트 인덱스
			let subTestIndex = romListByPosition[mainTestIndex].test.length - 1;
			setPlayVideo(true);
			setCurrentRomIndex(mainTestIndex);
			setCurrentSubTestIndex(subTestIndex);
			setRomSequence(ROM_SEQUENCE.GUIDE_START);
		} else {
			onTestPause();
			setPlayVideo(true); // bgm play
			let subTestIndex = currentSubTestIndex - 1;
			setCurrentSubTestIndex(subTestIndex);
			setSubTestSequence(SUB_TEST_SEQUENCE.SEQUENCE_INIT);
		}
	}, [romListByPosition, currentRomIndex, currentSubTestIndex]);


	return (
		<CancelableDiv className={css.container} handleCancel={backKeyHandler}>

			{/* Stepper - horizon */}
			{romListByPosition.length > 1 &&
				<TStepper className={css.stepperHorizon} order={stepperList} number={stepperNumber} type={"horizontal"} />
			}

			{/* guide-test title */}
			<div className={classNames(css.workoutTitleContainer,
				"animate__animated",
				{
					animate__fadeIn: (guideText.img || guideText.text),
					animate__fadeOut: !(guideText.img || guideText.text)
				})}>
				{renderGuideText()}
			</div>

			{/* Guide text */}
			<TestGuideSignMsg timeout={3000} message={forceMsg} voice={guideVoice}/>

			{/* 3,2,1 */}
			<BodyCheckUpCountdownTimer
				isCountDownStarted={isCountDownStarted}
				countdownCompleted={countdownCompleted}
			/>

			<section>
				{/* left - TshakaPlayer */}
				<div className={css.avatar}>
					<TShakaPlayer
						playerRef={PTVideoPlayerRef}
						className={css["shaka-player"]}
						src={guideVideo}
						onProgress={onVideoProgress}
						onEnded={onVideoEnd}
					/>
					{!showLoadingPanel.show && (
						<img
							width={"100%"}
							height={"100%"}
							style={{
								opacity: avatarImage ? "1.0" : "0",
								position: "absolute",
							}}
							src={avatarImage}
							alt={"videoPlay"}
						/>
					)}
					{/* ProgressBar (main/subTest title, step, timer) */}
					{!isTimeUp && romSequence !== ROM_SEQUENCE.SEQUENCE_INIT && romSequence !== ROM_SEQUENCE.GUIDE_START && subTestSequence !== SUB_TEST_SEQUENCE.TEST_END && !isTestClose &&
						<div className={css.counterContainer}>
							<ProgressBar
								onTimeCounterComplete={onTimeCounterComplete}
								initialTime={currentSubTestInfo.testTimeSec}
								mainTest={currentRomInfo.guideText}
								subTest={currentSubTestInfo.title}
								currentStep={currentSubTestIndex}
								totalSteps={currentRomInfo.test.length - 1}
								currentSubTest={subTestSequenceRef.current} />
						</div>
					}
					{/* subTest guide degree
					{!isTimeUp && subTestSequence == SUB_TEST_SEQUENCE.TEST_START && !isTestClose &&
						<div className={css.degreeContainer}>
							<span />{guideDegree}°
						</div>
					} */}
					<div className={css.labelContainer}>
						<div className={classNames(css.label)}>{"L"}</div>
						<div className={classNames(css.label, css.isRight)}>{"R"}</div>
					</div>
				</div>

				{/* right - Camera */}
				<div className={css.user}>
					{showPIPCamera &&
						<>
							<PIPCamera
								size="medium"
								onCameraReady={onCameraReady}
							/>
							{/* positionViewer */}
							{showBodyCoordinations && (romSequence !== ROM_SEQUENCE.GUIDE_END && romSequence !== ROM_SEQUENCE.TEST_END) &&
								<BodyPositionViewer bodyPositionLiveData={bodyPositionLiveData} cameraSize={cameraSize} />
							}
							{/* Angle Guide */}
							<AngleGuideCanvas points={convertedAngleBaseLine} cameraSize={cameraSize} direction={angleGuideCanvasDirection} />
							{/* pointer */}
							{subTestSequence === SUB_TEST_SEQUENCE.TEST_START &&
								<div style={{ position: "absolute", width: "100%", height: "1080px", zIndex: 110 }}>
									{BELLY_CHECKING_INDEX.map((bIndex, index) => {
										const point = bellySmootherRef.current[index] ? bellySmootherRef.current[index].getAverageData() : [];
										const style = point[0] ? { left: point[0] + "px", top: point[1] + "px" } : {};
										return (
											<img
												key={index}
												src={bellyPoint}
												alt="bellyPoint"
												className={classNames(css.bellyPoint, point.length <= 0 && css.hide)}
												style={style}
											/>
										);
									})}
								</div>
							}
							{/* bodyPosition guide */}
							{romSequence !== ROM_SEQUENCE.GUIDE_START && subTestSequence !== SUB_TEST_SEQUENCE.TEST_END &&
								<BodyPositionCorrectionSign
									bodyPositionCorrectionDirection={bodyPositionCorrectionDirection}
									onBodyCorrectionSign={onBodyCorrectionSign}
									feedbackEnable={subTestSequence === SUB_TEST_SEQUENCE.TEST_START}
									romTest={true}
									showOutline={false}
								/>
							}
							{/* current user degree data */}
							{!isTimeUp && subTestSequence === SUB_TEST_SEQUENCE.TEST_START && !isTestClose &&
								<div className={css.realAngleContainer}>
									<div className={css.subTitle}>{testTitle}</div>
									<div>
										<span />{testDegreeValue?.toFixed(1)}°
									</div>
								</div>
							}
							{/* current romScore */}
							{Object.keys(subRomScore).length > 0 && isTimeUp && subTestSequence === SUB_TEST_SEQUENCE.GET_SCORE &&
								renderRomScore()
							}
						</>
					}
				</div>
			</section>

			{/* button continer */}
			<ButtonContainer className={classNames(showLoadingPanel.show && css.hide)}>
				{/* retry previous Test */}
				{!(currentRomIndex === 0 && currentSubTestIndex === 0) && (romSequenceRef.current !== ROM_SEQUENCE.SEQUENCE_INIT &&
					subTestSequenceRef.current !== SUB_TEST_SEQUENCE.SEQUENCE_INIT) &&
					!showTestPartComplete &&
					<div className={css.retryTestContainer}>
						<TButton
							withMarquee={true}
							className={css.retryBtn}
							spotlightId="romRetryPreviousTest"
							onClick={retryPreviousTest}
						>
							{$L("Repeat the previous test")}
						</TButton>
					</div>
				}
				{/* pause Btn */}
				{romSequenceRef.current !== ROM_SEQUENCE.SEQUENCE_INIT &&
					subTestSequenceRef.current !== SUB_TEST_SEQUENCE.SEQUENCE_INIT &&
					!showTestPartComplete &&
					<TIconButton
						className={classNames(css.pauseBtn, showLoadingPanel.show && css.hide, isTimeUp && css.movePosition, subTestSequence !== SUB_TEST_SEQUENCE.TEST_START && css.movePosition)}
						iconType="testPause"
						onClick={pauseHandler}
					/>
				}

				{/* test skip Btn */}
				{!isTimeUp && subTestSequence === SUB_TEST_SEQUENCE.TEST_START &&
					<div className={css.testSkip}>
						<TButton className={classNames(css.skipBtn, showLoadingPanel.show && css.hide)} spotlightId="RomTestSkipBtn" onClick={skipTest}>
							{$L("Skip the test")}
							{/* <span /> */}
						</TButton>
					</div>
				}
			</ButtonContainer>

			{/* AudioPlayer */}
			<AudioPlayer srcTypeStr={voiceGuide} onEnded={onVoiceGuideEnd} />

			{/* 경고음 */}
			{loopVoiceGuide && <AudioPlayer srcTypeStr={loopVoiceGuide} loop />}

			{/* 배경음악 */}
			{playVideo && <AudioPlayer srcTypeStr={"ROMBGM"} loop volume={Config.BODY_TEST_BG_VOLUME} />}
			{/* {loopVoiceGuide && <AudioPlayer loopVoiceGuide={loopVoiceGuide} loop autoPlay />} */}

			{/* testPartComplete */}
			{showTestPartComplete &&
				<TestPartCompletePopup
					currentTestTitle={romListByPosition[currentRomIndex + 1].guideText}
					retryPreviousTest={retryTest}
					onClose={handleTestStart}
				/>
			}

			{/* testSelectPopup */}
			{retry &&
				<TestSelectPopup testInfos={ROM_INFO} initialCheckedState={initialCheckedState} romTest={true} onClose={handleTestStart} />
			}

			{/* after final test -> TestEnd popup */}
			{showTestEndComplete && romSequence >= ROM_SEQUENCE.TEST_END && currentRomIndex + 1 >= romListByPosition.length && //전체 테스트 중 마지막일때
				<TestEndCompletePopup
					open
					retryPreviousTest={retrySelectedTest}
					onClose={handleTestStart}
					testType={romListByPosition.length === 1 ? "single" : "total"}
				/>
			}
			{isTestClose && <TTestClosePopup onClick={onClickTestClose} />}
		</CancelableDiv>
	)
};
export default ROMTest;