import { $L } from "../../../utils/helperMethods";
import { DATAKEY } from "../../../features/physicalTest/physicalTestSlice";
export const MENUTYPE = {
	OVERALL: "OVERALL",
	UPPER_BODY_STR: "UPPER_BODY_STR",
	STATIC_BALANCE: "STATIC_BALANCE",
	LOWER_BODY_STR: "LOWER_BODY_STR",
	LOWER_BODY_FLEX: "LOWER_BODY_FLEX",
	AEROBIC: "AEROBIC",
	UP_AND_GO_TEST: "UP_AND_GO_TEST",
	//todo
	// ONE_LEGGED_STAND: "ONE_LEGGED_STAND",
	// POWER_WALKING: "POWER_WALKING"
};

export const getTabMenuTitle = () => ({
	[MENUTYPE.OVERALL]: [$L("Overall"), $L("Overall")], //Overall
	[MENUTYPE.LOWER_BODY_STR]: [
		$L("Lower Body Strength"),
		$L("Lower Body{br}Strength"),
	], // 하지 근력
	[MENUTYPE.UPPER_BODY_STR]: [
		$L("Upper Body Strength"),
		$L("Upper Body{br}Strength"),
	], // 상지 근력
	[MENUTYPE.AEROBIC]: [
		$L("Cardiopulmonary Endurance"),
		$L("Cardiopulmonary{br}Endurance"),
	], // 심폐지구력
	[MENUTYPE.LOWER_BODY_FLEX]: [
		$L("Lower Body Flexibility"),
		$L("Lower Body{br}Flexibility"),
	], // 하지 유연성
	[MENUTYPE.STATIC_BALANCE]: [$L("Balance"), $L("Balance")], // 평형성(상지 유연성)
	[MENUTYPE.UP_AND_GO_TEST]: [
		$L("Agility & Dynamic Balance"),
		$L("Agility &{br}Dynamic Balance"),
	], // 민첩성 및 동적균형
	[MENUTYPE.ONE_LEGGED_STAND]: [$L("One Legged Stand"), $L("One Legged Stand")],
	[MENUTYPE.POWER_WALKING]: [$L("Power Walking"), $L("Power Walking")],
});

export const TAB_DETAIL = {
	[MENUTYPE.LOWER_BODY_STR]: {
		testkey: DATAKEY.LOWER_BODY_STR,
	},
	[MENUTYPE.UPPER_BODY_STR]: {
		testkey: DATAKEY.UPPER_BODY_STR,
	},
	[MENUTYPE.AEROBIC]: {
		testkey: DATAKEY.AEROBIC,
	},
	[MENUTYPE.LOWER_BODY_FLEX]: {
		testkey: DATAKEY.LOWER_BODY_FLEX,
	},
	[MENUTYPE.STATIC_BALANCE]: {
		testkey: DATAKEY.STATIC_BALANCE,
	},
	[MENUTYPE.UP_AND_GO_TEST]: {
		testkey: DATAKEY.UP_AND_GO_TEST,
	},
	[MENUTYPE.ONE_LEGGED_STAND]: {
		testkey: DATAKEY.ONE_LEGGED_STAND,
	},
	[MENUTYPE.POWER_WALKING]: {
		testkey: DATAKEY.POWER_WALKING,
	},
};

export const getEmptyResultsReportData = (menuType) => {
	const data = {
		[MENUTYPE.OVERALL]: {
			title: $L(
				"체형 진단은 당신의 체형을 더 젊게 하고 최적의 건강 상태로 유지할 수 있도록 하여 몸과 마음이 활기찬 노후생활을 보낼 수 있게 해요."
			),
			detail: $L(
				"우리 몸은 특정 자세나 운동을 할 때 올바른 자세와 체형을 유지하는 것이 중요해요. 이는 부상 예방과 운동 효과를 극대화하기 위해 필수적이에요. {br}올바른 자세와 체형을 유지하는 것은 운동을 효과적으로 수행하고 부상을 예방하는 데 도움이 되요. 이는 운동을 하는 것뿐만 아니라 일상적인 활동에서도 중요해요."
			),
		},
	};
	return data[menuType] ? data[menuType] : {};
};
