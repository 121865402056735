import { createSlice } from "@reduxjs/toolkit";
import * as HelperMethods from "../../utils/helperMethods";

export const DATAKEY={
  LOWER_BODY_STR: "lowerStrength",
  UPPER_BODY_STR: "upperStrength",
  AEROBIC: "stepInPlace",
  LOWER_BODY_FLEX: "lowerFlexibility",
  STATIC_BALANCE: "balance",
  UP_AND_GO_TEST: "upandgo",
  ONE_LEGGED_STAND: "onlegstand",
  POWER_WALKING: "powerwalking"
}

//tab아이콘
const notOnTv = (typeof window === 'object' && !window.PalmSystem);
export const physicalTest = createSlice({
  name: "physicalTest",
  initialState: {
    current:{
      testing: "", //LOWER_BODY_STR
      [DATAKEY.LOWER_BODY_STR]:{},
      [DATAKEY.UPPER_BODY_STR]:{},
      [DATAKEY.AEROBIC]:{},
      [DATAKEY.LOWER_BODY_FLEX]:{},
      [DATAKEY.STATIC_BALANCE]:{},
      [DATAKEY.UP_AND_GO_TEST]:{},
      [DATAKEY.ONE_LEGGED_STAND]:{},
      [DATAKEY.POWER_WALKING]:{}
    },
    dateList: [],
    testResultUpdated: 0, //ui update count
    testResults: {
      "2023-10-18":{[DATAKEY.LOWER_BODY_STR] :{test_id: "0", score: 95},
      [DATAKEY.UPPER_BODY_STR]:{test_id: "1", score: 95},
      [DATAKEY.AEROBIC]:{test_id: "2", score: 10 },
      [DATAKEY.LOWER_BODY_FLEX]:{test_id: "3", score: 10 },
      [DATAKEY.STATIC_BALANCE]:{test_id: "4", score: 10 },
      [DATAKEY.UP_AND_GO_TEST]:{test_id: "5", score: 10 },
      [DATAKEY.ONE_LEGGED_STAND]:{test_id: "6", score: 10 },
      [DATAKEY.POWER_WALKING]:{test_id: "7", score: 10 }},
      "2023-10-19":{[DATAKEY.LOWER_BODY_STR] :{test_id: "0", score: 95},
        [DATAKEY.UPPER_BODY_STR]:{test_id: "1", score: 95},
        [DATAKEY.AEROBIC]:{test_id: "2", score: 10 },
        [DATAKEY.LOWER_BODY_FLEX]:{test_id: "3", score: 10 },
        [DATAKEY.STATIC_BALANCE]:{test_id: "4", score: 10 },
        [DATAKEY.UP_AND_GO_TEST]:{test_id: "5", score: 10 },
        [DATAKEY.ONE_LEGGED_STAND]:{test_id: "6", score: 10 },
        [DATAKEY.POWER_WALKING]:{test_id: "7", score: 10 }}
      //should be match : src\views\PhysicalTest\PhysicalTestCapture\Constants.js - resultName
      // lowerStrength: notOnTv ? { test_id: "1", score: 95 } : {},
      // upperStrength: notOnTv ? { test_id: "2", score: 10 } : {},
    },
    //todo remove
    physicalTestInfo: [
      {
        id: 0,
        score: 87,
        tabName: "Overall",
        tempGraphData: [
          5, 0, 10, 15, 15, 10, 17, 7, 8, 12, 21, 12, 5, 0, 5, 9, 3, 2, 0, 5, 9,
          1, 2, 20, 17, 15, 15, 10, 7, 5,
        ],
        tabData: [
          {
            tempDate: "11/15",
            score: 95,
          },
          {
            tempDate: "11/28",
            score: 95,
          },
        ],
      },
      {
        id: 1,
        score: 45,
        tabName: "Muscle Strength Of The Lower Extremities",
        tempGraphData: [
          5, 0, 10, 16, 15, 10, 17, 7, 8, 12, 21, 12, 5, 0, 5, 9, 3, 2, 0, 5, 9,
          1, 2, 20, 17, 15, 15, 10, 7, 5,
        ],
        tabData: [
          {
            tempDate: "11/15",
            score: 30,
          },
          {
            tempDate: "11/28",
            score: 45,
          },
        ],
      },
      {
        id: 2,
        score: 85,
        tabName: "Cardiopulmonary Endurance",
        tempGraphData: [
          9, 3, 6, 7, 13, 4, 7, 5, 15, 13, 4, 9, 13, 16, 15, 10, 17, 7, 5, 0,
          10, 15, 3, 2, 0, 5, 9, 3, 2, 8,
        ],
        tabData: [
          {
            tempDate: "11/15",
            score: 85,
          },
          {
            tempDate: "11/28",
            score: 85,
          },
        ],
      },
      {
        id: 3,
        score: 80,
        tabName: "Upper Extremity Flexibility",
        tempGraphData: [
          3, 4, 8, 3, 13, 5, 8, 15, 4, 16, 12, 1, 7, 1, 10, 15, 7, 8, 12, 21,
          12, 5, 1, 2, 7, 5, 0, 10, 15, 13, 7,
        ],
        tabData: [
          {
            tempDate: "11/15",
            score: 80,
          },
          {
            tempDate: "11/28",
            score: 80,
          },
        ],
      },
      // {
      //   id: 4,
      //   score: 58,
      //   tabName: "Dynamic balance",
      //   tempGraphData: [13, 5, 7, 15, 4, 16, 1, 2, 7, 5, 0, 10, 15, 9,
      //     12, 21, 12, 5, 1, 2, 9, 1, 3, 13, 5, 8, 15, 7, 8, 12],
      // },
      {
        id: 4,
        score: 35,
        tabName: "Upper Limb Muscle Strength",
        tempGraphData: [
          7, 13, 4, 8, 5, 15, 17, 7, 8, 12, 21, 12, 5, 4, 5, 9, 3, 2, 0, 5, 9,
          1, 2, 18, 17, 8, 5, 15, 17,
        ],
        tabData: [
          {
            tempDate: "11/15",
            score: 25,
          },
          {
            tempDate: "11/28",
            score: 35,
          },
        ],
      },
      {
        id: 5,
        score: 70,
        tabName: "Flexibility Of The Lower Extremities",
        tempGraphData: [
          10, 1, 2, 20, 17, 15, 17, 7, 16, 1, 2, 7, 5, 8, 5, 9, 3, 2, 0, 5, 9,
          1, 2, 20, 17, 15, 15, 10, 7, 5,
        ],
        tabData: [
          {
            tempDate: "11/15",
            score: 65,
          },
          {
            tempDate: "11/28",
            score: 70,
          },
        ],
      },
      {
        id: 6,
        // score: 15,
        score: 10,
        tabName: "Agility And Motion Balance",
        tempGraphData: [
          10, 1, 2, 20, 17, 15, 17, 7, 16, 1, 2, 7, 5, 8, 5, 9, 3, 2, 0, 5, 9,
          1, 2, 20, 17, 15, 15, 10, 7, 5,
        ],
        tabData: [
          {
            tempDate: "11/15",
            score: 10,
          },
          {
            tempDate: "11/28",
            score: 10,
          },
        ],
      },
    ],
  },
  reducers: {
    addContentsInfos: (state, action) => {
      state.themcontentsInfos = [...state.physicalTestInfo, action.payload];
    },
    setCurrentTestingName: (state, action) => {
      state.current.testing = action.payload;
    },
    clearCurrentTestingStatus: (state, action) => {
      if(!action.payload){
        state.current = {testing: ""};
      }else{
        if(action.payload instanceof Array){
          action.payload.forEach(element => {
            state.current[element] = {};
            state.current = {...state.current};
          });
        }else{
          state.current[action.payload] = {};
          state.current = {...state.current};
        }
      }
    },
    updateCurrentTestingStatus: (state, action) => {
      if(state.current.testing){
        state.current[state.current.testing] = Object.assign({}, state.current[state.current.testing], action.payload);
        state.current = {...state.current};
      }
    },
    updateDateList: (state, action) => {
      state.dateList = action.payload;
      state.dateList.sort();
    },
    clearTestResults: (state, action) => {
      state.dateList = [];
      state.testResults = {};
    },
    updateTestResults: (state, action) => {
      const {date, value} = action.payload;
      //override
      if(value.physicalTestHistoryId && state.testResults?.[date]?.physicalTestHistoryId === value.physicalTestHistoryId){
        console.log('ignore date update');
        return;
      }
      if(state.dateList.indexOf(date) < 0){
        state.dateList.push(date);
      }
      state.testResults[date] = Object.assign({}, state.testResults[date], value);
      state.testResultUpdated = state.testResultUpdated +1;
    }
  }
});

export const { addContentsInfos, setCurrentTestingName, clearCurrentTestingStatus, updateCurrentTestingStatus, updateDateList, clearTestResults, updateTestResults } = physicalTest.actions;

export default physicalTest.reducer;
