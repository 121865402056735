import { useState, useCallback } from "react";
import TSlider from '../../../../components/TSlider/TSlider';

const TSliderLayout = () => {
  const [value, setValue] = useState(null)
  const onChange = useCallback((value) => {
    console.log(value)
    setValue(value)
}, []);

  return (
      <div style={{background: "black", height: "50px"}}>
        <TSlider onChange={onChange} value={value} />
      </div>
  );
};

export default TSliderLayout;