import { createSlice } from "@reduxjs/toolkit";

export const activity = createSlice({
  name: "activity",
  initialState: {
    lastTestTime: {},
    likeList: {}, // { [type]: [] }
    continueList: {}, // { [type]: [] }
    recommendList: {} // { [type]: [] }
  },
  reducers: {
    updateLastTestTime: (state, action) => {
      state.lastTestTime = action.payload;
    },
    updateLikeList: (state, action) => {
      const { type, data, append } = action.payload;

      if (append && state.likeList[type]) {
        state.likeList[type] = state.likeList[type].concat(data);
      } else {
        state.likeList[type] = data;
      }
    },
    updateContinueList: (state, action) => {
      const { type, data } = action.payload;

      state.continueList[type] = data;
    },
    updateRecommendList: (state, action) => {
      const { type, data } = action.payload;

      state.recommendList[type] = data;
    }
  }
});

export const {
  updateLastTestTime,
  updateLikeList,
  updateContinueList,
  updateRecommendList
} = activity.actions;

export default activity.reducer;
