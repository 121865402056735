import { $L } from "../../../utils/helperMethods"

export const getSectionsData = () => {
  return {
    walking: {
      sectionTitle: $L("Walking / Jogging"),
      subTitle: {
        logout: {
          normal: $L("Take a walk and tour the world's famous cities from home."),
          focused: $L("Log in now to tour around world's most famous cities from home.")
        },
        loginNoData: {
          normal: $L("You haven't tried Walking or Jogging yet. Would you like to start now?"),
          focused: $L("Start your morning in world's most sought after walking and jogging trails.")
        },
        login: {
          normal: $L("Feel the nature with different themes on a mat."), // TBD 최근 산책/조깅 코스 + 거리
          focused: $L("Feel the nature with different themes on a mat."), // TBD 데이터에 대한 평가 & 동기부여 문구 (TBD)
        },
        default: {
          normal: $L("Feel the nature with different themes on a mat."),
          focused: $L("Feel the nature with different themes on a mat."),
        }
      }
    },
    hiking: {
      sectionTitle: $L("Hiking"),
      subTitle: {
        logout: {
          normal: $L("Climb up a mountain or a building to enjoy a stunning landscape or a cityscape."),
          focused: $L("Log in now to enjoy a stunning landscape of greatest mountains of the world or a cityscape.")
        },
        loginNoData: {
          normal: $L("You haven't tried Hiking yet. Would you like to start now?"),
          focused: $L(
            "Discover the world of hiking while gazing over the majestic view."
          ),
        },
        login: {
          normal: $L(
            "Discover the world of hiking while gazing over the majestic view."
          ), // TBD 최근 산책/조깅 코스 + 높이
          focused: $L(
            "Discover the world of hiking while gazing over the majestic view."
          ), // TBD 데이터에 대한 평가 & 동기부여 문구 (TBD)
        },
        default: {
          normal: $L(
            "Discover the world of hiking while gazing over the majestic view."
          ),
          focused: $L(
            "Discover the world of hiking while gazing over the majestic view."
          ),
        }
      }
    },
    trainingCoaching: {
      sectionTitle: $L("Training"),
      subTitle: {
        logout: {
          normal: $L("Experience online fitness with coaching from a trainer."),
          focused: $L("Log in now to experience online fitness with coaching from a trainer.")
        },
        loginNoData: {
          normal: $L("You haven't met a Training Coach yet. Would you like to start now?"),
          focused: $L(
            "Follow along celebrity trainers in Korea and abroad to level up your workouts."
          ),
        },
        login: {
          normal: $L(
            "Follow along celebrity trainers in Korea and abroad to level up your workouts."
          ), // TBD 최근 트레이닝 운동 + 시간
          focused: $L(
            "Follow along celebrity trainers in Korea and abroad to level up your workouts."
          ), // TBD 데이터에 대한 평가 & 동기부여 문구 (TBD)
        },
        default: {
          normal: $L(
            "Follow along celebrity trainers in Korea and abroad to level up your workouts."
          ),
          focused: $L(
            "Follow along celebrity trainers in Korea and abroad to level up your workouts."
          ),
        }
      }
    },
    golfCoaching: {
      sectionTitle: $L("Golf"),
      subTitle: {
        logout: {
          normal: $L("Swing powerfully in world-famous driving courses."),
          focused: $L("Log in now to swing powerfully in world-famous driving courses.")
        },
        loginNoData: {
          normal: $L("You haven't tried Golf Coaching yet. Would you like to start now?"),
          focused: $L(
            "You can correct your swing posture and get extra distance with golf coaching."
          ),
        },
        login: {
          normal: $L("Test your posture and get professional coaching"), // TBD 최근 골프 코스 + 비거리
          focused: $L("Test your posture and get professional coaching") // TBD 데이터에 대한 평가 & 동기부여 문구 (TBD)
        },
        default: {
          normal: $L("Test your posture and get professional coaching"),
          focused: $L("Test your posture and get professional coaching")
        }
      }
    }
  }
}
