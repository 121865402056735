import tempPreviousIllust1 from "./tempPreviousIllust1.png"
import tempPreviousIllust2 from "./tempPreviousIllust2.png"
import tempPreviousIllust3 from "./tempPreviousIllust3.png"
import tempPreviousPhoto1 from "./tempPreviousPhoto1.png";
import tempPreviousPhoto2 from "./tempPreviousPhoto2.png";
import tempPreviousPhoto3 from "./tempPreviousPhoto3.png";
//overall (photo, illust)
//name: Illust4, Photo3 -> only 10/28 img
import tempHeadShiftPhoto0 from "./tempHeadShiftPhoto0.png"
import tempHeadShiftPhoto1 from "./tempHeadShiftPhoto1.png"
import tempHeadShiftPhoto2 from "./tempHeadShiftPhoto2.png"
import tempHeadShiftPhoto3 from "./tempHeadShiftPhoto3.png"
import tempHeadShiftIllust0 from "./tempHeadShiftIllust0.png"
import tempHeadShiftIllust1 from "./tempHeadShiftIllust1.png"
import tempHeadShiftIllust2 from "./tempHeadShiftIllust2.png"
import tempHeadShiftIllust4 from "./tempHeadShiftIllust4.png"
import tempLegAlignmentPhoto0 from "./tempLegAlignmentPhoto0.png"
import tempLegAlignmentPhoto1 from "./tempLegAlignmentPhoto1.png"
import tempLegAlignmentPhoto2 from "./tempLegAlignmentPhoto2.png"
import tempLegAlignmentPhoto3 from "./tempLegAlignmentPhoto3.png"
import tempLegAlignmentIllust0 from "./tempLegAlignmentIllust0.png"
import tempLegAlignmentIllust1 from "./tempLegAlignmentIllust1.png"
import tempLegAlignmentIllust2 from "./tempLegAlignmentIllust2.png"
import tempLegAlignmentIllust4 from "./tempLegAlignmentIllust4.png"
import tempPelvicTiltPhoto0 from "./tempPelvicTiltPhoto0.png"
import tempPelvicTiltPhoto1 from "./tempPelvicTiltPhoto1.png"
import tempPelvicTiltPhoto2 from "./tempPelvicTiltPhoto2.png"
import tempPelvicTiltPhoto3 from "./tempPelvicTiltPhoto3.png"
import tempPelvicTiltIllust0 from "./tempPelvicTiltIllust0.png"
import tempPelvicTiltIllust1 from "./tempPelvicTiltIllust1.png"
import tempPelvicTiltIllust2 from "./tempPelvicTiltIllust2.png"
import tempPelvicTiltIllust4 from "./tempPelvicTiltIllust4.png"
import tempShoulderTiltPhoto0 from "./tempShoulderTiltPhoto0.png"
import tempShoulderTiltPhoto1 from "./tempShoulderTiltPhoto1.png"
import tempShoulderTiltPhoto2 from "./tempShoulderTiltPhoto2.png"
import tempShoulderTiltPhoto3 from "./tempShoulderTiltPhoto3.png"
import tempShoulderTiltIllust0 from "./tempShoulderTiltIllust0.png"
import tempShoulderTiltIllust1 from "./tempShoulderTiltIllust1.png"
import tempShoulderTiltIllust2 from "./tempShoulderTiltIllust2.png"
import tempShoulderTiltIllust4 from "./tempShoulderTiltIllust4.png"
import tempPelvicRotationPhoto0 from "./tempPelvicRotationPhoto0.png"
import tempPelvicRotationPhoto1 from "./tempPelvicRotationPhoto1.png"
import tempPelvicRotationPhoto2 from "./tempPelvicRotationPhoto2.png"
import tempPelvicRotationPhoto3 from "./tempPelvicRotationPhoto3.png"
import tempPelvicRotationIllust0 from "./tempPelvicRotationIllust0.png"
import tempPelvicRotationIllust1 from "./tempPelvicRotationIllust1.png"
import tempPelvicRotationIllust2 from "./tempPelvicRotationIllust2.png"
import tempPelvicRotationIllust4 from "./tempPelvicRotationIllust4.png"
import tempNeckTiltPhoto0 from "./tempNeckTiltPhoto0.png"
import tempNeckTiltPhoto1 from "./tempNeckTiltPhoto1.png"
import tempNeckTiltPhoto2 from "./tempNeckTiltPhoto2.png"
import tempNeckTiltPhoto3 from "./tempNeckTiltPhoto3.png"
import tempNeckTiltIllust0 from "./tempNeckTiltIllust0.png"
import tempNeckTiltIllust1 from "./tempNeckTiltIllust1.png"
import tempNeckTiltIllust2 from "./tempNeckTiltIllust2.png"
import tempNeckTiltIllust4 from "./tempNeckTiltIllust4.png"
import tempTrunkShiftPhoto0 from "./tempTrunkShiftPhoto0.png"
import tempTrunkShiftPhoto1 from "./tempTrunkShiftPhoto1.png"
import tempTrunkShiftPhoto2 from "./tempTrunkShiftPhoto2.png"
import tempTrunkShiftPhoto3 from "./tempTrunkShiftPhoto3.png"
import tempTrunkShiftIllust0 from "./tempTrunkShiftIllust0.png"
import tempTrunkShiftIllust1 from "./tempTrunkShiftIllust1.png"
import tempTrunkShiftIllust2 from "./tempTrunkShiftIllust2.png"
import tempTrunkShiftIllust4 from "./tempTrunkShiftIllust4.png"
import tempPelvicShiftPhoto0 from "./tempPelvicShiftPhoto0.png"
import tempPelvicShiftPhoto1 from "./tempPelvicShiftPhoto1.png"
import tempPelvicShiftPhoto2 from "./tempPelvicShiftPhoto2.png"
import tempPelvicShiftPhoto3 from "./tempPelvicShiftPhoto3.png"
import tempPelvicShiftIllust0 from "./tempPelvicShiftIllust0.png"
import tempPelvicShiftIllust1 from "./tempPelvicShiftIllust1.png"
import tempPelvicShiftIllust2 from "./tempPelvicShiftIllust2.png"
import tempPelvicShiftIllust4 from "./tempPelvicShiftIllust4.png"
import tempKneeFlexionPhoto0 from "./tempKneeFlexionPhoto0.png"
import tempKneeFlexionPhoto1 from "./tempKneeFlexionPhoto1.png"
import tempKneeFlexionPhoto2 from "./tempKneeFlexionPhoto2.png"
import tempKneeFlexionPhoto3 from "./tempKneeFlexionPhoto3.png"
import tempKneeFlexionIllust0 from "./tempKneeFlexionIllust0.png"
import tempKneeFlexionIllust1 from "./tempKneeFlexionIllust1.png"
import tempKneeFlexionIllust2 from "./tempKneeFlexionIllust2.png"
import tempKneeFlexionIllust4 from "./tempKneeFlexionIllust4.png"
//Detail
import tempHeadShiftDetailPhoto1 from "./tempHeadShiftDetailPhoto1.png"
import tempHeadShiftDetailPhoto2 from "./tempHeadShiftDetailPhoto2.png"
import tempKneeFlexionDetailPhoto1 from "./tempKneeFlexionDetailPhoto1.png"
import tempKneeFlexionDetailPhoto2 from "./tempKneeFlexionDetailPhoto2.png"
import tempLegAlignmentDetailPhoto1 from "./tempLegAlignmentDetailPhoto1.png"
import tempLegAlignmentDetailPhoto2 from "./tempLegAlignmentDetailPhoto2.png"
import tempNeckTiltDetailPhoto1 from "./tempNeckTiltDetailPhoto1.png"
import tempNeckTiltDetailPhoto2  from "./tempNeckTiltDetailPhoto2.png"
import tempPelvicRotateDetailPhoto1 from "./tempPelvicRotateDetailPhoto1.png"
import tempPelvicRotateDetailPhoto2 from "./tempPelvicRotateDetailPhoto2.png"
import tempPelvicShiftDetailPhoto1 from "./tempPelvicShiftDetailPhoto1.png"
import tempPelvicShiftDetailPhoto2 from "./tempPelvicShiftDetailPhoto2.png"
import tempPelvicTiltDetailPhoto1 from "./tempPelvicTiltDetailPhoto1.png"
import tempPelvicTiltDetailPhoto2 from "./tempPelvicTiltDetailPhoto2.png"
import tempshoulderTiltDetailPhoto1 from "./tempshoulderTiltDetailPhoto1.png"
import tempshoulderTiltDetailPhoto2 from "./tempshoulderTiltDetailPhoto2.png"
import tempTrunkShiftDetailPhoto1 from "./tempTrunkShiftDetailPhoto1.png"
import tempTrunkShiftDetailPhoto2 from "./tempTrunkShiftDetailPhoto2.png"


export { tempPreviousIllust1, tempPreviousIllust2, tempPreviousIllust3,
tempPreviousPhoto1, tempPreviousPhoto2, tempPreviousPhoto3,
tempHeadShiftPhoto0, tempHeadShiftPhoto1, tempHeadShiftPhoto2,
tempHeadShiftIllust0, tempHeadShiftIllust1, tempHeadShiftIllust2,
tempLegAlignmentPhoto0, tempLegAlignmentPhoto1,
tempLegAlignmentPhoto2, tempLegAlignmentIllust0, tempLegAlignmentIllust1,
tempLegAlignmentIllust2, tempPelvicTiltPhoto0,
tempPelvicTiltPhoto1, tempPelvicTiltPhoto2, tempPelvicTiltIllust0,
tempPelvicTiltIllust1, tempPelvicTiltIllust2, 
tempShoulderTiltPhoto0, tempShoulderTiltPhoto1, tempShoulderTiltPhoto2,
tempShoulderTiltIllust0, tempShoulderTiltIllust1, tempShoulderTiltIllust2,
 tempPelvicRotationPhoto0, tempPelvicRotationPhoto1,
tempPelvicRotationPhoto2, tempPelvicRotationIllust0, tempPelvicRotationIllust1,
tempPelvicRotationIllust2,  tempNeckTiltPhoto0,
tempNeckTiltPhoto1, tempNeckTiltPhoto2, tempNeckTiltIllust0,
tempNeckTiltIllust1, tempNeckTiltIllust2, 
tempTrunkShiftPhoto0, tempTrunkShiftPhoto1, tempTrunkShiftPhoto2,
tempTrunkShiftIllust0, tempTrunkShiftIllust1, tempTrunkShiftIllust2,
 tempPelvicShiftPhoto0, tempPelvicShiftPhoto1,
tempPelvicShiftPhoto2, tempPelvicShiftIllust0, tempPelvicShiftIllust1,
tempPelvicShiftIllust2,  tempKneeFlexionPhoto0,
tempKneeFlexionPhoto1, tempKneeFlexionPhoto2, tempKneeFlexionIllust0,
tempKneeFlexionIllust1, tempKneeFlexionIllust2, 
tempHeadShiftDetailPhoto1, tempHeadShiftDetailPhoto2,
tempKneeFlexionDetailPhoto1, tempKneeFlexionDetailPhoto2,
tempLegAlignmentDetailPhoto1, tempLegAlignmentDetailPhoto2,
tempNeckTiltDetailPhoto1, tempNeckTiltDetailPhoto2,
tempPelvicRotateDetailPhoto1, tempPelvicRotateDetailPhoto2,
tempPelvicShiftDetailPhoto1, tempPelvicShiftDetailPhoto2,
tempPelvicTiltDetailPhoto1, tempPelvicTiltDetailPhoto2,
tempshoulderTiltDetailPhoto1, tempshoulderTiltDetailPhoto2,
tempTrunkShiftDetailPhoto1, tempTrunkShiftDetailPhoto2,
//10/28 change image
tempHeadShiftPhoto3,
tempHeadShiftIllust4,
tempLegAlignmentPhoto3,tempLegAlignmentIllust4,
tempPelvicTiltPhoto3,tempPelvicTiltIllust4,tempShoulderTiltPhoto3,
tempShoulderTiltIllust4,tempPelvicRotationPhoto3,tempPelvicRotationIllust4,
tempNeckTiltPhoto3,tempNeckTiltIllust4,tempTrunkShiftPhoto3,tempTrunkShiftIllust4,
tempPelvicShiftPhoto3,tempPelvicShiftIllust4,tempKneeFlexionPhoto3,tempKneeFlexionIllust4
}