import css from "./Avatar.module.less";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";

import AVATAR_WHITE from "../../../../../assets/myPanel/avatar/img_my_avatar_white.png"
import AVATAR_RED from "../../../../../assets/myPanel/avatar/img_my_avatar_red.png"
import AVATAR_GREEN from "../../../../../assets/myPanel/avatar/img_my_avatar_green.png"
import AVATAR_YELLOW from "../../../../../assets/myPanel/avatar/img_my_avatar_yellow.png"
import AVATAR_BLUE from "../../../../../assets/myPanel/avatar/img_my_avatar_blue.png"
import AVATAR_BLACK from "../../../../../assets/myPanel/avatar/img_my_avatar_black.png"

import { $L } from "../../../../utils/helperMethods";
import * as Config from "../../../../utils/Config";
import TButton, {SIZES, ICONS, TYPES} from "../../../../components/TButton/TButton";

import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";


const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");
const SpottableComponent = Spottable("div");

const AVATAR_COLOR = [
  {
    color: "white",
    image: AVATAR_WHITE,
    title: $L("하양")
  },
  {
    color: "red",
    image: AVATAR_RED,
    title: $L("빨강")
  },
  {
    color: "green",
    image: AVATAR_GREEN,
    title: $L("초록")
  },
  {
    color: "yellow",
    image: AVATAR_YELLOW,
    title: $L("노랑")
  },
  {
    color: "blue",
    image: AVATAR_BLUE,
    title: $L("파랑")
  },
  {
    color: "black",
    image: AVATAR_BLACK,
    title: $L("검정")
  },
];

const Avatar = ({ className, ...rest }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const _onClick = useCallback((index) => (ev) => {
    setSelectedIndex(index);
  }, []);

  const _onFocus = useCallback((index) => (ev) => {
    setSelectedIndex(index);
  }, []);

  return (
    <Container className={classNames(css.avatarContainer, className)}>
      <div className={css.image} style={{backgroundImage: `url(${AVATAR_COLOR[selectedIndex].image})`}}></div>
      <div className={css.colorContainer}>
      {AVATAR_COLOR.map((item, index) =>
        <div key={"color"+index} className={css.box}>
          <SpottableComponent
            className={classNames(css.color, css[item.color], selectedIndex === index && css.selected)}
            onClick={_onClick(index)}
            onFocus={_onFocus(index)}
          />
          <div className={css.title}>{item.title}</div>
        </div>
      )}
      </div>
    </Container>
  );
};

export default Avatar;