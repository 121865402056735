import { useEffect, useCallback } from "react";
import css from "./ContentControllerModal.module.less";
import { memoize } from "@enact/core/util";
import Spotlight from "@enact/spotlight";
import Slider from "@enact/sandstone/Slider"
import DurationFmt from 'ilib/lib/DurationFmt';
import Times from '../../../components/Times/Times';
import TIconButton from "../../../components/TIconButton/TIconButton";
import THeader from "../../../components/THeader/THeader";
import { useDispatch } from "react-redux";
import { changeThemeModeStatus } from "../../../features/common/commonSlice";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const memoGetDurFmt = memoize((/* locale */) => new DurationFmt({length: 'long', style: 'clock', useNative: false}));
const getDurFmt = () => {
  if (typeof window === 'undefined') return null;

	return memoGetDurFmt();
};
const DUR_FMT = getDurFmt();
const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);

const ContentControllerModal = ({ playerRef, currentTime, duration, onPause, onSeek, children, ...rest }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeThemeModeStatus('dark'));

    setTimeout(() => {
      Spotlight.focus("pausePlayToggle");
    }, 0);

    return () => {
      dispatch(changeThemeModeStatus('light'));
    }
  }, []);

  const _onPause = useCallback(() => {
    if (playerRef && playerRef.current && !playerRef.current.paused()) {
      playerRef.current.pause();
    }

    if (onPause) onPause();
  }, [onPause]);

  const onChangeProgress = useCallback((ev) => {
    if (playerRef && playerRef.current) {
      playerRef.current.seekTo(ev.value);
    }
    if (onSeek) onSeek(ev);
  },[onSeek]);

  return (
    <div className={css.popupPanel} {...rest}>
      <THeader iconType="back" useTool={false}/>
      <Container>
        <TIconButton
          spotlightId="pausePlayToggle"
          className={css.playIcon}
          onClick={_onPause}
        />
        <div className={css.container}>
          <Slider
            className={css.progressBar}
            activateOnSelect={false}
            knobStep={1}
            onChange={onChangeProgress}
            value={Math.floor(currentTime)}
            min={0}
            max={Math.floor(duration)}
            noFill={false}
          />
          <div className={css.timeContainer}>
            <Times noCurrentTime total={currentTime} formatter={DUR_FMT} />
            <Times noTotalTime current={duration} formatter={DUR_FMT} />
          </div>
          <div className={css.bottomButtons}>
            {children}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContentControllerModal;
