import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { $L } from '../../../utils/helperMethods';
import css from "./LicenseDetail.module.less";
import TScroller from "../../../components/TScroller/TScroller";
import TButton from "../../../components/TButton/TButton";
import Spotlight from "@enact/spotlight";
import dummyTerms from '../../../../assets/mock/dummyTerms.json'
import dummyLicense from '../../../../assets/mock/dummyLicense.json';
import TPanel from "../../../components/TPanel/TPanel";
import Alert from '@enact/sandstone/Alert';
import { popPanel } from "../../../features/panels/panelsSlice";

const LicenseDetail = ({ index, open, onClose, ...rest }) => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();
  const getScrollTo = useCallback((cbScrollTo) => {
      scrollTo.current = cbScrollTo;
  }, [index]);

  useEffect(()=>{
    if (scrollTo && scrollTo.current) {
      scrollTo.current({position: {y: 0}, animate: false});
    }
  }, [index]);

  const licenseData = dummyLicense.data[index];
  const licenseParahData = licenseData.paragraph;
  console.log(licenseParahData);

  const _onClose = useCallback((ev) => {
    if(onClose){
      onClose(ev)
    }
  }, [onClose]);

  useEffect(()=>{
    Spotlight.focus('licenseDetailConfirm');
  },[])

  return (
    <Alert {...rest} className={css.panelLicenseDetail}
      open
      onClose={_onClose}
      type={"fullscreen"}
    >
      <div className={css.title} dangerouslySetInnerHTML={{ __html: licenseData.title}} />
      <div className={css.date} dangerouslySetInnerHTML={{ __html: licenseData.date}} />
      <TScroller className={css.tScroller} cbScrollTo={getScrollTo} >
        {licenseParahData.map((el, idx) => {
          return (
            <div key={"licenseParahData" + idx} className={css.paragraph}>
              <div className={css.paraTitle} dangerouslySetInnerHTML={{ __html: el.paraTitle}} />
              <div className={css.desc} dangerouslySetInnerHTML={{ __html: el.desc}} />
            </div>
          )
        })}
      </TScroller>
      <div className={css.btnBox}>
        <TButton type ="normal" spotlightId="licenseDetailConfirm" onClick={_onClose}>{$L("Verified")}</TButton>
      </div>
    </Alert>
  );
};

export default LicenseDetail;