import React, { useCallback, useState, useEffect } from 'react';
import { InputField } from '@enact/sandstone/Input';
import { useDispatch } from 'react-redux';
import Cancelable from '@enact/ui/Cancelable';
import { Panel } from '@enact/sandstone/Panels';
import TButton from "../../components/TButton/TButton";
import * as Config from "../../utils/Config";
import { popPanel, addPanels } from "../../features/panels/panelsSlice";
import { changeLocalSettings } from "../../features/common/commonSlice";
import css from './LoginPanel.module.less';

const MAX_NAME_LENGTH = 15;
const MAX_AGE_LENGTH = 3;
const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const LoginPanel = ({...rest}) => {
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [age, setAge] = useState("");
	const [gender, setGender] = useState("");
	const [valid, setValid] = useState(false);

	const handleCancel = useCallback((ev) => {
		if(ev) ev.stopPropagation();
	}, []);

	const onNameChange = useCallback((e)=>{
		if(e.value && e.value.length > MAX_NAME_LENGTH){
			setName(e.value.slice(0, MAX_NAME_LENGTH));
		}else{
			setName(e.value);
		}
	},[]);

	const onAgeChange = useCallback((e)=>{
		if(e.value && e.value.length > MAX_AGE_LENGTH){
			setAge(e.value.slice(0, MAX_AGE_LENGTH));
		}else{
			setAge(e.value);
		}
	},[]);

	const onSelectGender = useCallback((_gender)=>()=>{
		setGender(_gender);
	},[]);

	const onLogin = useCallback(()=>{
		dispatch(changeLocalSettings({ userName: name, userAge: age, userGender: gender }));
		dispatch(popPanel());
		dispatch(addPanels({ name: Config.panel_names.TERMS_PANEL }));
	},[dispatch, name, age, gender]);


	useEffect(()=>{
		if(gender && name && Number(age) > 0){
			setValid(true);
		}else{
			setValid(false);
		}
	},[name, age, gender]);

	return (
		<CancelablePanel {...rest} className={css.panel} handleCancel={handleCancel}>
			{"Login"}
			<div className={css.loginBG}/>
			<div className={css.inputLayer}>
				<div className={css.inputItem}>
					<div className={css.inputTitle}>{"Name"}</div>
					<div className={css.inputBox}>
						<InputField
							className={css.inputField}
							autoFocus
							type={"text"}
							dismissOnEnter
							value={name}
							maxLength={MAX_NAME_LENGTH}
							onChange={onNameChange}
							placeholder={'Name'} />
					</div>
				</div>
				<div className={css.inputItem}>
					<div className={css.inputTitle}>{"Age"}</div>
					<div className={css.inputBox}>
						<InputField
							className={css.inputField}
							autoFocus
							type={"number"}
							dismissOnEnter
							value={age}
							maxLength={MAX_AGE_LENGTH}
							onChange={onAgeChange}
							placeholder={'Age'} />
					</div>
				</div>
				<div className={css.inputItem}>
					<TButton className={css.genderBtn} selected={gender==='male'} onClick={onSelectGender('male')}>Male</TButton>
					<TButton className={css.genderBtn} selected={gender==='female'} onClick={onSelectGender('female')}>Female</TButton>
				</div>
			</div>
			<TButton className={css.loginButton} disabled={!valid} onClick={onLogin}>Login</TButton>
		</CancelablePanel>
	);
};

export default LoginPanel;
