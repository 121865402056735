import React, { useEffect, useMemo, useState } from "react";
import css from "./PTSubTabGraph.module.less";
import classNames from "classnames";
import { $L } from "../../../../utils/helperMethods";
import PTGraphTooltip from "../../../../../assets/physicalTest/ic_Physical Test_graph_tooltip.svg";

const PhysicalScoreGraph = ({ label, data }) => {
  const [scorePercent, setScorePercent] = useState(["0", "0", "0", "0"]);

  const convertPercentage = useMemo(() => {
    const scoreRange = [95, 85, 60, 40, 20];

    const newData = [];
    data?.map((item, index) => {
      let totalPercentage = 0;
      let ret, calcValue, baseValue;

      if (item > scoreRange[1]) {
        totalPercentage += 80;
        calcValue = item - scoreRange[1];
        baseValue = scoreRange[0] - scoreRange[1];
      } else if (item > scoreRange[2]) {
        totalPercentage += 60;
        calcValue = item - scoreRange[2];
        baseValue = scoreRange[1] - scoreRange[2];
      } else if (item > scoreRange[3]) {
        totalPercentage += 40;
        calcValue = item - scoreRange[3];
        baseValue = scoreRange[2] - scoreRange[3];
      } else if (item > scoreRange[4]) {
        totalPercentage += 20;
        calcValue = item - scoreRange[4];
        baseValue = scoreRange[3] - scoreRange[4];
      } else {
        calcValue = item;
        baseValue = scoreRange[4];
      }
      ret = ((calcValue / baseValue) * 100) / 5;
      totalPercentage += ret;
      const retObj = {
        score: data[index],
        percentage: Math.min(totalPercentage, 100),
      };
      newData.push(retObj);
    });

    return newData;
  }, [data]);

  useEffect(() => {
    data && setScorePercent(convertPercentage);
  }, [data]);

  return (
    <div className={css.container}>
      <div className={css.graphArea}>
        <div style={{ bottom: "220px" }} className={css.graphLine}>
          {/* <div>
            <div className={css.yLabel}>{$L("95점(S)")}</div>
          </div> */}
        </div>
        <div style={{ bottom: "176px" }} className={css.graphLine}>
          {/* <div>
            <div className={css.yLabel}>{$L("85점(A)")}</div>
          </div> */}
        </div>
        <div style={{ bottom: "132px" }} className={css.graphLine}>
          {/* <div>
            <div className={css.yLabel}>{$L("60점(B)")}</div>
          </div> */}
        </div>
        <div style={{ bottom: "88px" }} className={css.graphLine}>
          {/* <div>
            <div className={css.yLabel}>{$L("40점(C)")}</div>
          </div> */}
        </div>
        <div style={{ bottom: "44px" }} className={css.graphLine}>
          {/* <div>
            <div className={css.yLabel}>{$L("20점(D)")}</div>
          </div> */}
        </div>
        <div
          style={{ bottom: "0", borderBottomStyle: "solid" }}
          className={classNames(css.graphLine)}
        />
        <div className={css.scoreContainer}>
          {scorePercent.map((item, index) => {
            return (
              <div key={`score-${index}`} className={css.scoreBox}>
                <div
                  className={css.score}
                  style={{ height: `${item.percentage}%` }}
                >
                  <div className={css.scoreTextBox}>
                    {$L(`${item.score}점`)}
                    <img src={PTGraphTooltip} alt="tooltip_arrow" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={css.labelArea}>
        {label.map((item, index) => (
          <div key={`label-${index}`} className={css.label}>
            {$L(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhysicalScoreGraph;
