import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import css from './IHOAWJFullActivity.module.less';
import * as HelperMethods from '../../../../utils/helperMethods';
import { $L } from '../../../../utils/helperMethods';
import TPanel from '../../../../components/TPanel/TPanel';
import THeader from "../../../../components/THeader";
import TBody from '../../../../components/TBody';
import TSimpleDatePicker from '../../../../components/TSimpleDatePicker/TSimpleDatePicker';
import TSimpleButtonTab, {LIST_TYPE} from "../../../../components/TSimpleButtonTab";
import TSummary, { SUMMARY_SIZE } from '../../../../components/TSummary';
import TTimeLine from '../../../../components/TTimeLine';
import TScroller from '../../../../components/TScroller/TScroller';
import { getActivityStrollHistoryList, getActivityStrollHistorySummary } from '../../../../features/fitService/fitServiceSlice';
import TIconButton, { ICON_TYPES } from '../../../../components/TIconButton/TIconButton';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import track_cycloid from "../../../../../assets/icon/track_cycloid.svg";
import track_line from "../../../../../assets/icon/track_line.svg";
import track_height from "../../../../../assets/icon/track_height.svg";

const TRACK_IMG = {
	cycloid: track_cycloid,
	line: track_line,
	height: track_height
};

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  Spottable("div")
);

const IHOAWJFullActivity = ({ ...rest }) => {
	const scrollTo = useRef(null);
  const dispatch = useDispatch();
  const { dateList, activityHistorySummary, activityHistory } = useSelector(state => state.activityWalking);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [_period, setPeriod] = useState('total');
  const [scrollMode, setScrollMode] = useState('translate');

  const SUMMARY_INFO = useMemo(() => {
    const arr = [
      { key: 'totalDistance', label: $L("Distance"), value: 0, type: 'distance' },
      {
        key: "totalStep",
        label: $L("Number of steps taken"),
        value: 0,
        type: "steps",
      },
      { key: "totalTime", label: $L("hours"), value: 0, type: "time" },
      {
        key: "totalCalories",
        label: $L({ key: "_Calories burned", value: "Calories burned" }),
        value: 0,
        type: "calorie",
      },
    ];

    return arr.map(info => {
      info.value = activityHistorySummary[info.key] || 0;
      return info;
    })
  }, [activityHistorySummary]);

  const CATEGORY_NAMEMAP = {
    walking: $L("Walking courses"),
    jogging: $L("Jogging courses"),
    hiking: $L("Hiking")
  }

  const history = useMemo(() => {
    const arr = [];

    if (Array.isArray(activityHistory) && activityHistory.length > 0) {
      activityHistory.forEach(history => {
        arr.push({
          title: history.content?.title || '',
          image: history.content?.thumbnailUrl || '',
          trackType: history.content.trackType || '',
          category: CATEGORY_NAMEMAP[history.content.contentId === 4 ? 'jogging' : history.content?.subType] || '',
          date: history.createdate,
          summary: [
            { label: $L("Distance"), value: history.totalDistance || 0, type: 'distance' },
            { label: $L("Number of steps taken"), value: history.totalStep || 0, type: 'steps' },
            { label: $L("hours"), value: history.totalTime || 0, type: "time" },
            { label: $L("Calories"), value: history.totalCalories || 0, type: 'calorie' }
          ]
        });
      });
    }

    return arr;
  }, [activityHistory]);

  const _handleDateRange = useCallback((dateRange) => {
    console.log('dateRange...', dateRange);
    let strStartDate = HelperMethods.convertDateToString2(dateRange.first);
    let strEndDate = HelperMethods.convertDateToString2(dateRange.last);

    if (strStartDate === strEndDate) {
      strStartDate = HelperMethods.convertDateToString2(dateList[0]);
    }

    if (startDate !== strStartDate && endDate !== strEndDate) {
      setStartDate(startDate);
      setEndDate(endDate);
      dispatch(getActivityStrollHistorySummary({ startDate: strStartDate, endDate: strEndDate }));
      dispatch(getActivityStrollHistoryList({ startDate: strStartDate, endDate: strEndDate }));
    }
  }, [dateList, startDate, endDate]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const onItemClick = useCallback(({ index,...rest }) => {
    console.log('TSimpleButtonTabLayout onItemClick', index, rest);
    setSelectedIndex(index);
    setPeriod(() => {
      switch (index) {
        case 0:
          return 'total';
        case 1:
          return 'quarterly';
        case 2:
          return 'monthly';
        case 3:
          return 'weekly';
      }
    });
  }, []);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

	const scrollToTop = useCallback(() => {
    const target = document.querySelector(`[data-spotlight-id="fullActivity_scroller"]`)?.childNodes[1]?.childNodes[0]?.childNodes[0];

    if (target) {
      if (scrollTo && scrollTo.current) {
        Spotlight.focus(target);
        setScrollMode('native');
        scrollTo.current({ position: { y: 0 }, animate: true });
      }
    }
	}, []);

  const onScrollStop = useCallback(() => {
    if (scrollMode === 'native') {
      setScrollMode('translate');
    }
  }, [scrollMode]);

  const onSpotlightHandler = useCallback((ev) => {
    const target = document.querySelector(`[data-spotlight-id="fullActivity_scroller"]`)?.childNodes[1]?.childNodes[0]?.childNodes[0];
    if (target) {
      Spotlight.focus(target);
    }
    ev.stopPropagation();
  }, []);

  const itemRenderer = useCallback((item) => {
    if (!item) return;

    return (
      <div className={css.infoBox}>
        <div className={css.infoWrapper}>
          <div className={css.image} style={{ backgroundImage: `url(${item.image})` }}>
            {item.trackType && <img src={TRACK_IMG[item.trackType]} className={css.courseImage} />}
          </div>
          <div className={css.info}>
            {item.category && <div className={css.category}>{item.category}</div>}
            <div className={css.title}>{$L(item.title)}</div>
            <TSummary items={item.summary} size={SUMMARY_SIZE.lite} />
          </div>
        </div>
        {item.desc && <>
          <hr/>
          <div className={css.desc} dangerouslySetInnerHTML={{ __html: item.desc }} />
        </>}
      </div>
    );
  }, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("See all activities")} iconType='back' />
      <TBody className={css.tBody}>
        <TScroller spotlightId='fullActivity_scroller' className={css.tScroller} cbScrollTo={getScrollTo} scrollMode={scrollMode} onScrollStop={onScrollStop}>
          <div>
            <Container className={css.dateWrap} onSpotlightDown={onSpotlightHandler}>
              <TSimpleDatePicker
                size="small"
                width="small"
                period={_period}
                handleDateRange={_handleDateRange}
                disabled={_period === 'total'}
              />
              <TSimpleButtonTab
                listType={LIST_TYPE.small}
                contents={[$L("Overall"), $L("Quarterly"), $L("Monthly"), $L("Weekly")]}
                onItemClick={onItemClick}
                selectedIndex={selectedIndex}
              />
            </Container>
            <div className={css.fullActivityContainer}>
              <div className={css.fullActivityTitle}>{$L("Activity status")}</div>
              <TSummary items={SUMMARY_INFO} size={SUMMARY_SIZE.large} className={css.tSummary}/>
            </div>
            <div className={css.fullActivityContainer}>
              <div className={css.fullActivityTitle}>{$L("Activity records")}</div>
              <TTimeLine id="activityTimeline" items={history} itemRenderer={itemRenderer}/>
            </div>
            {(Array.isArray(history) && history.length > 0) && <TIconButton iconType={ICON_TYPES.top} className={css.topBtn} onClick={scrollToTop} onSpotlightUp={onSpotlightHandler}/>}
          </div>
        </TScroller>
      </TBody>
    </TPanel>
  );
};

export default IHOAWJFullActivity;