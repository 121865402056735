import React, { useRef, useEffect, useCallback, useState, useMemo } from "react";
import {useDispatch, useSelector} from 'react-redux';

import classNames from "classnames";
import css from "./PhysicalTestList.module.less";

import * as HelperMethods from "../../../utils/helperMethods";
import * as Config from "../../../utils/Config";
import { $L } from "../../../utils/helperMethods";
import { addPanels } from "../../../features/panels/panelsSlice";
import PhysicalTestItem from "./PhysicalTestItem";

import Scroller from '@enact/sandstone/Scroller/Scroller';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div" );

const ITEM_SIZE = {
	WIDTH: 253,
	SPACING: 20
}

const PhysicalTestList = ({className, ...rest}) => {
	const dispatch = useDispatch();
  const scrollTo = useRef(null);
	const positionX = useRef(0);

  const ITEMS = useMemo(() => {
    return [
      {
        type: "lowerBodyStrength",
        target: {
          path: Config.panel_names.WORKOUT_PANEL,
          panelInfo: {
            title: $L("Lower Body Strength"),
            type: "physicalTest",
            subType: "LOWER_BODY_STR",
          },
        },
      },
      {
        type: "upperBodyStrength",
        target: {
          path: Config.panel_names.WORKOUT_PANEL,
          panelInfo: {
            title: $L("Upper Body Strength"),
            type: "physicalTest",
            subType: "UPPER_BODY_STR",
          },
        },
      },
      {
        type: "lowerBodyFlexibility",
        target: {
          path: Config.panel_names.WORKOUT_PANEL,
          panelInfo: {
            title: $L("Lower Body Flexibility"),
            type: "physicalTest",
            subType: "LOWER_BODY_FLEX",
          },
        },
      },
      {
        type: "endurance",
        target: {
          path: Config.panel_names.WORKOUT_PANEL,
          panelInfo: {
            title: $L("Cardiopulmonary Endurance"),
            type: "physicalTest",
            subType: "AEROBIC",
          },
        },
      },
      {
        type: "balance",
        target: {
          path: Config.panel_names.WORKOUT_PANEL,
          panelInfo: {
            title: $L("Balance"),
            type: "physicalTest",
            subType: "STATIC_BALANCE",
          },
        },
      },
      {
        type: "agility",
        target: {
          path: Config.panel_names.WORKOUT_PANEL,
          panelInfo: {
            title: $L("Agility & Dynamic Balance"),
            type: "physicalTest",
            subType: "UP_AND_GO_TEST",
          },
        },
      },
    ];
  });

	const cbScrollTo = useCallback((fn)=>{
		scrollTo.current = fn;
	},[]);

  const _onClick = useCallback(
    (item) => (ev) => {
      if (item.target) {
        dispatch(
          addPanels({
            name: item.target.path,
            panelInfo: item.target.panelInfo,
          })
        );
      }
    },
    []
  );

	const _onFocus = useCallback((ev) => {
		const listItem = ev.target;
		if (listItem && scrollTo.current) {
			if (!HelperMethods.isChildFullyShowing(listItem.parentNode.parentNode, listItem)) {
				const { left } = HelperMethods.getRectDiff(listItem.parentNode.parentNode, listItem);
				const itemSize = ITEM_SIZE.WIDTH + ITEM_SIZE.SPACING;
				const newX = left < 0 ? positionX.current + itemSize : positionX.current - itemSize;
				scrollTo.current({ position: { x: newX }, animate: true });
				positionX.current = newX;
			}
		}
	},[]);


	return (
		<div className={classNames(className, css.physicalTest)}>
      <div className={css.title}>
        {$L("신체능력평가 기반 추천 카테고리")}
      </div>
			<Scroller
				scrollMode='translate'
				focusableScrollbar={false}
				noScrollByWheel={true}
				className={css.scroller}
				direction='horizontal'
				horizontalScrollbar='hidden'
				verticalScrollbar='hidden'
				overscrollEffectOn={{
						arrowKey: false,
						drag: false,
						pageKey: false,
						track: false,
						wheel: false
				}}
				cbScrollTo={cbScrollTo}
			>
				<Container className={css.container}>
					{ITEMS.map((item, index) => (
						<PhysicalTestItem
							key={"item" + index}
							className={css.item}
							type={item.type}
							onClick={_onClick(item)}
							onFocus={_onFocus}
						/>
					))}
				</Container>
			</Scroller>
		</div>

	);
}

export default PhysicalTestList;