import css from "./BodyAlignmentReportDetail.module.less";
import { useState, useCallback, useMemo, useEffect } from "react";
import { MUSCLE_IMGE } from "../../../../../assets/images/bodyAlignmentDetail/muscle";
import TPanel from "../../../../components/TPanel/TPanel";
import THeader from "../../../../components/THeader/THeader";
import TBody from "../../../../components/TBody/TBody";
import Canvas from "../../../../components/Canvas/Canvas";
import { addPanels } from "../../../../features/panels/panelsSlice";
import CustomImage from "../../../../components/CustomImage/CustomImage";
import * as Config from "../../../../utils/Config";
import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
} from "../../../../utils/Constants";
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import { $L, convertNewlinesToBr } from "../../../../utils/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import dummyImage from "../../../../../assets/images/bodyAlignmentDetail/muscleDetail/dummy_muscleDetail.png";
import {
  getViewDetailsFeedback,
  getViewDetailsFeedbackMuscle,
  getViewDetailsFeedbackSilhouette,
} from "../feedback/feedbackBodyAlignment";
import { FEEDBACK_TYPE } from "../feedback/ConstantsFeedback";
import { pictogramType } from "../feedback/pictogram/pictogram";
import useContent from "../../../../hooks/useContent";
import JMediaList, {
  LIST_TYPE,
} from "../../../../components/JMediaList/JMediaList";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TVerticalPagenator from "../../../../components/TVerticalPagenator/TVerticalPagenator";
import SMediaList, { TYPES } from "../../../../components/SMediaList/SMediaList";
import THorizontalScrollList from "../../../../components/THorizontalScrollList/THorizontalScrollList";
import { changeAppStatus } from "../../../../features/common/commonSlice";
import SMediaItem from "../../../../components/SMediaList/SMediaItem";
import CameraPopup from "../../../Camera/CameraPopup";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

/**
 * @module BodyAlignmentReportDetail
 */

function BodyAlignmentReportDetail({ panelInfo }) {
  const dispatch = useDispatch();
  const { title, tabDetail, menuType, selectedDate } = panelInfo;
  const { testResults } = useSelector((state) => state.bodyAlignment);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const [isFront, setIsFront] = useState(true);
  const [isFrontTight, setFrontTight] = useState(true);
  const [isFrontElongated, setFrontElongated] = useState(true);
  const [isFrontStabilizing, setFrontStabilizing] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const { cameraList } = useSelector(state => state.camera);
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);

  const CONTENT_TYPE = { type: "recommend", subType: "bodyAlignment" };
  const { contentList } = useContent(CONTENT_TYPE);
  console.log("BodyAlignmentReportDetail", panelInfo);

  const currentData = useMemo(() => {
    if (selectedDate) {
      return testResults[selectedDate];
    }
    return {};
  }, [testResults]);

  const decoration = useMemo(() => {
    const ret = {
      silhouette: [],
      feedback: {},
      muscleFeedback: [],
      pictogram: [],
      color: "",
      status: "",
      constant_status: "",
    };
    let currentDataKey = currentData[tabDetail.mainkey][tabDetail.testkey];
    let detailKey = currentDataKey[0]; // detailKey
    if (menuType && detailKey) {
      ret.feedback = getViewDetailsFeedback(menuType, detailKey, cesShowMode);
      ret.muscleFeedback = getViewDetailsFeedbackMuscle(menuType, detailKey);
      ret.pictogram = pictogramType[menuType];
    }
    let score = currentDataKey[3];
    if (score) {
      switch (score) {
        case SCORE_MINT_KEY:
          ret.color = "#71B1A9";
          ret.status = $L("Good");
          ret.constant_status = "GOOD";
          break;
        case SCORE_YELLOW_KEY:
          ret.color = "#EEBB6F";
          ret.status = $L("Borderline");
          ret.constant_status = "BORDERLINE";
          break;
        case SCORE_RED_KEY:
          ret.color = "#C04554";
          ret.status = $L("Weak");
          ret.constant_status = "WEAK";
          break;
      }

      if (menuType && detailKey) {
        ret.silhouette = getViewDetailsFeedbackSilhouette(
          menuType,
          detailKey,
          score
        );
      }
    }
    return ret;
  }, [tabDetail, menuType, currentData, cesShowMode]);

  const [checkMediaList, setCheckMediaList] = useState([]);

  const onItemClick = useCallback(
    ({ itemInfo }) => {
      // case: 선택한 리스트를 목록으로 만드는 케이스
      if (itemInfo) {
        if (checkMediaList.includes(itemInfo)) {
          const popList = [...checkMediaList].filter(
            (item) => item !== itemInfo
          );
          setCheckMediaList(popList);
        } else {
          setCheckMediaList((prev) => [...prev, itemInfo]);
        }
      }
    },
    [checkMediaList]
  );
  const goToBodyScan = useCallback(() => {
    if(cameraList.length < 1){
      setIsConnectionPopupOpen(true);
    }else{
      dispatch(addPanels({ name: Config.panel_names.BODY_ALIGNMENT_SCAN }));
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
    }
  }, [dispatch]);

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  const goToWorkout = useCallback(() => {
    const data = [];

    checkMediaList.forEach((el) => {
      data.push({ contentId: el.contentId });
    });

    dispatch(
      addPanels({
        name: Config.panel_names.CONTENT_PLAYER_START,
        panelInfo: data,
      })
    );
  }, [dispatch, checkMediaList]);

  const onClickMuscleDetail = useCallback((index, isFront) => {
    switch (index) {
      case 0:
        setFrontTight(isFront);
        break;
      case 1:
        setFrontElongated(isFront);
        break;
      default:
        setFrontStabilizing(isFront);
        break;
    }
  }, []);

  const onPageChanged = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  const curContentList = useMemo(() => {
    const arr = [];

    for (let i = 0; i < contentList[menuType].length; i++) {
      const content = contentList[menuType][i];

      if (
        decoration.constant_status === "WEAK" ||
        decoration.constant_status === "BORDERLINE"
      ) {
        if (content.score === "bad") arr.push(content);
      } else if (content.score !== "bad") {
        arr.push(content);
      }
    }

    return arr;
  }, [decoration, menuType, decoration]);

  const PAGENATE = useMemo(() => {
    let arr = [];
    if (cesShowMode) {
      arr = ["BODY_ALIGNMENT_REPORT_DETAIL_0"];
    } else {
      if (decoration.constant_status === "GOOD") {
        arr = [
          "BODY_ALIGNMENT_REPORT_DETAIL_0",
          "BODY_ALIGNMENT_REPORT_DETAIL_2",
        ];
      } else {
        arr = [
          "BODY_ALIGNMENT_REPORT_DETAIL_0",
          "BODY_ALIGNMENT_REPORT_DETAIL_1",
          "BODY_ALIGNMENT_REPORT_DETAIL_2",
        ];
      }
    }
    return arr;
  }, [decoration, cesShowMode]);

  useEffect(() => {
    if (checkMediaList.length === 0) {
      setCheckMediaList([curContentList[0]]);
    }
  }, [curContentList, menuType]);

  useEffect(() => {
    decoration.silhouette?.[0] === dummyImage && setIsFront(false);
  }, [decoration]);

  return (
    <TPanel className={css.bodyAlignmentReportDetail}>
      <THeader title={title} />
      <TBody className={css.body}>
        <TVerticalPagenator
          className={css.scroller}
          pageSpotIds={PAGENATE}
          onPageChanged={onPageChanged}
          visible={cesShowMode ? "hidden" : "visible"}
        >
          {/* page: BODY_ALIGNMENT_REPORT_DETAIL_0 */}
          <Container
            className={css.summary}
            spotlightId={"BODY_ALIGNMENT_REPORT_DETAIL_0"}
          >
            <div className={css.summaryImageWapper}>
              <div className={css.innerImg}>
                <Canvas
                  bodyType={tabDetail.mainkey}
                  type={tabDetail.type}
                  className={css.canvas}
                  testkeys={[tabDetail.testkey, tabDetail.testkey2]}
                  testResult={currentData}
                  isIllust={false}
                />
              </div>
              <div className={css.content}>
                <div className={css.userTitle}>
                  {$L("Grade {grade}").replace("{grade}", decoration.status)}
                </div>
              </div>
            </div>
            <div className={css.summaryImageWapper}>
              <div className={css.innerImg}>
                <div>
                  <CustomImage
                    src={
                      isFront
                        ? decoration.silhouette?.[0]
                        : decoration.silhouette?.[1]
                    }
                  />
                </div>
                <div className={css.buttonLayer}>
                  <TButton
                    className={classNames(
                      css.switchBtn,
                      isFront && css.selected
                    )}
                    size={SIZES.tiny}
                    selected={isFront}
                    onClick={() => setIsFront(true)}
                    disabled={decoration.silhouette?.[0] === dummyImage}
                    // onFocus={onFocusTopBtn}
                  >
                    {$L("Front")}
                  </TButton>
                  <TButton
                    className={classNames(
                      css.switchBtn,
                      !isFront && css.selected
                    )}
                    size={SIZES.tiny}
                    selected={!isFront}
                    onClick={() => setIsFront(false)}
                    disabled={decoration.silhouette?.[1] === dummyImage}
                    // onFocus={onFocusTopBtn}
                  >
                    {$L({ key: "_detail_back", value: "Back" })}
                  </TButton>
                </div>
              </div>
              <div className={css.content}>
                <div className={css.muscleTitle}>
                  {convertNewlinesToBr(
                    decoration.constant_status === "GOOD"
                      ? $L({
                          key: "_good_muscle_status_example",
                          value: "Muscle Status Example",
                        })
                      : $L({
                          key: "_bad_muscle_status_example",
                          value: "Muscle Status Example",
                        })
                  )}
                </div>
                <div className={css.muscleSubText}>
                  <span className={css.withCircle}>{$L("Contraction")}</span>
                  <span className={classNames(css.withCircle, css.blueCicle)}>
                    {$L("Relaxation")}
                  </span>
                </div>
              </div>
            </div>
            <div className={css.summaryTextLayer}>
              <div style={{ marginBottom: "55px" }}>
                <div className={css.impPoint}>{$L("Improvement Point")}</div>
                <div className={css.dotTextLayer}>
                  {decoration.feedback[FEEDBACK_TYPE.LIFE_STYLE].map(
                    (item, index) => {
                      return (
                        <div key={"impro_" + index} className={css.dotTextArea}>
                          <span className={css.dotPoint} />
                          <div className={css.dotText}>{item}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div>
                <div className={classNames(css.impPoint, css.diseasTitle)}>
                  {!cesShowMode && decoration.constant_status === "GOOD"
                    ? $L("Muscle Wellness").replace(
                        "{grade}",
                        decoration.status
                      )
                    : $L("Affected Disease")}
                </div>
                {!cesShowMode && decoration.constant_status === "GOOD" ? (
                  <div className={css.dotText}>
                    {decoration.muscleFeedback[2].subtitle}
                  </div>
                ) : (
                  <div className={css.diseaseWrapper}>
                    {decoration.feedback[FEEDBACK_TYPE.DISEASE].map(
                      (item, index) => {
                        return (
                          <div key={"disease" + index} className={css.disease}>
                            {item}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            </div>
          </Container>

          {/* page: BODY_ALIGNMENT_REPORT_DETAIL_1 */}
          {!cesShowMode && decoration.constant_status !== "GOOD" && (
            <Container
              className={css.musclePicBox}
              spotlightId={"BODY_ALIGNMENT_REPORT_DETAIL_1"}
            >
              <div className={css.mainTitle}>
                {$L("Muscle Wellness").replace("{grade}", decoration.status)}
              </div>
              <div className={css.wellnessLayer}>
                {decoration.muscleFeedback.map((item, index) => {
                  const isFrontTarget =
                    index === 0
                      ? isFrontTight
                      : index === 1
                      ? isFrontElongated
                      : isFrontStabilizing;
                  const targetIndex = isFrontTarget ? 0 : 1;

                  let disabledImg = {
                    left: false,
                    right: false,
                  };
                  if (item.image[0] === dummyImage) {
                    disabledImg.left = true;
                    isFrontTarget && onClickMuscleDetail(index, false);
                  }
                  if (item.image[1] === dummyImage) {
                    disabledImg.right = true;
                  }

                  return (
                    <div key={"moreInfo" + index} className={css.moreInfo}>
                      <div className={css.innerImg}>
                        <img src={item.image[targetIndex]} />
                        <div className={css.buttonLayer}>
                          <TButton
                            className={classNames(
                              css.switchBtn,
                              isFrontTarget && css.selected
                            )}
                            // onFocus={onFocusSubBtn}
                            size={SIZES.tiny}
                            selected={isFrontTarget}
                            onClick={() => onClickMuscleDetail(index, true)}
                            disabled={disabledImg.left}
                          >
                            {$L("Front")}
                          </TButton>
                          <TButton
                            className={classNames(
                              css.switchBtn,
                              !isFrontTarget && css.selected
                            )}
                            size={SIZES.tiny}
                            // onFocus={onFocusSubBtn}
                            selected={!isFrontTarget}
                            onClick={() => onClickMuscleDetail(index, false)}
                            disabled={disabledImg.right}
                          >
                            {$L({ key: "_detail_back", value: "Back" })}
                          </TButton>
                        </div>
                      </div>
                      <div className={css.moreText}>
                        <div className={css.title}>{item.title}</div>
                        <div className={css.detail}>{item.subtitle}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Container>
          )}

          {/* page: BODY_ALIGNMENT_REPORT_DETAIL_2 */}
          {!cesShowMode && (
          <Container
            className={css.exerciseLayer}
            spotlightId={"BODY_ALIGNMENT_REPORT_DETAIL_2"}
          >
            <div className={css.mainTitle} style={{ padding: "0 60px" }}>
              <span className={css.icon} />
              {$L("Recommend Exercise")}
            </div>
            <div className={css.pictogramLayer}>
              {curContentList.map(
                (item, index) =>
                  index < 3 && (
                    <div key={"content_" + index} className={css.pictogram}>
                      <span className={css.picIcon}>#</span>
                      {item.title}
                    </div>
                  )
              )}
            </div>
            <div className={css.exerciseFeedbackLayer}>
              <div className={css.exerciseFeedbackBox}>
                <div className={css.exerciseBox}>
                  <span className={css.dotPoint} />
                  <span>{`${$L("Workout Name")} : ${
                    decoration.feedback[FEEDBACK_TYPE.EXERCISE].name
                  }`}</span>
                </div>
                <div className={css.exerciseBox}>
                  <span className={css.dotPoint} />
                  <span>{`${$L("Muscles used")} : ${
                    decoration.feedback[FEEDBACK_TYPE.EXERCISE].relativeMuscle
                  }`}</span>
                </div>
                <div className={css.exerciseBox}>
                  <span className={css.dotPoint} />
                  <span>{`${$L("Workout effects")} : ${
                    decoration.feedback[FEEDBACK_TYPE.EXERCISE].effect
                  }`}</span>
                </div>
                <div className={css.exerciseBox}>
                  <span className={css.dotPoint} />
                  <span>{`${$L("Exceptions")} : ${decoration.feedback[
                    FEEDBACK_TYPE.EXERCISE
                  ].exception.join(", ")}`}</span>
                </div>
                {/* <div className={css.exerciseBox}>
                  <span className={css.dotPoint} />
                  <span>{`${$L("Caution")} : ${decoration.feedback[
                    FEEDBACK_TYPE.EXERCISE
                  ].cautions.join(" ")}`}</span>
                </div> */}
              </div>
              <THorizontalScrollList startGap={60}>
              {curContentList && curContentList.length > 0 && curContentList.map((item, index) => (
                <SMediaItem
                  key={`content_${index}`}
                  type={TYPES.withCheck}
                  itemInfo={item}
                  onClick={onItemClick}
                />
              ))}
              </THorizontalScrollList>
            </div>
            <Container className={css.bottomButtonLayer}>
              <TButton
                className={css.button}
                size={SIZES.test}
                onClick={goToWorkout}
                disabled={checkMediaList.length === 0}
              >
                {$L("Workout")}
              </TButton>
              <TButton
                className={css.button}
                size={SIZES.test}
                onClick={goToBodyScan}
              >
                {$L("Body Scan")}
              </TButton>
            </Container>
          </Container>
          )}
        </TVerticalPagenator>
        <div className={css.notification}>
          {$L(
            "Measuring body alignment with a camera may be affected by the work environment(clothing, etc.)."
          )}
        </div>
        <div className={css.medicalCenter} />
      </TBody>
      {isConnectionPopupOpen && <CameraPopup onClose={onCloseCameraPopup}/>}
    </TPanel>
  );
}

export default BodyAlignmentReportDetail;
