import TToast from '../../../../components/TToast/TToast';
import { useDispatch, useSelector } from 'react-redux';
import { changeToastStatus } from "../../../../features/common/commonSlice";
import TButton from '../../../../components/TButton/TButton';

const TToastLayout = () => {
  const dispatch = useDispatch();
  const {isToasted, message} = useSelector((state) => state.common.appStatus.toast);
  const onClickToastButton = () => {
    dispatch(changeToastStatus({ isToasted: true, message: "TEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEX" }));
  };
  return (
    <>
      <TButton onClick={onClickToastButton}> toast button </TButton>
      {isToasted && <TToast text={message}/>}
      
    </>
  );
};

export default TToastLayout;