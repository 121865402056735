import React, {useCallback, useMemo, useRef} from 'react';
import css from './InHomeOutdoorMain.module.less';
import {useDispatch, useSelector} from 'react-redux';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import Scroller from '@enact/sandstone/Scroller/Scroller';
import {addPanels} from '../../features/panels/panelsSlice';
import MainSection from "./MainSection";
import THeader from "../../components/THeader";
import TPanel from '../../components/TPanel/TPanel';
import TBody from '../../components/TBody';
import * as HelperMethods from "../../utils/helperMethods";
import {$L} from '../../utils/helperMethods';
import * as Config from '../../utils/Config';
import * as SoundEffect from "../../utils/SoundEffect";

// import Spritesheet from "react-responsive-spritesheet";
// import gamerSprites from "../../../assets/inHomeOutdoorActivity/8frame_sec/gamer_sprite_image.png";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div" );

// const spriteData = {
//   sprite: {
//     image: gamerSprites,
//     widthFrame: 356,
//     heightFrame: 398,
//     steps: 54,
//     fps: 8,
//   },
// };

const InHomeOutDoorMain = ({...rest}) => {
	const dispatch = useDispatch();
	const scrollTo = useRef(null);
	const { dateList } = useSelector(state => state.activityWalking);
	const { cesShowMode } = useSelector((state) => state.common.localSettings);
  // const [spriteInstance, setSpriteInstance] = useState(null);

  // useEffect(() => {
  //   if (spriteInstance) {
  //     spriteInstance.setDirection("forward");
  //     spriteInstance.goToAndPause(0);
  //     spriteInstance.goToAndPlay(1);
  //   }
  //   return () => {
  //     spriteInstance?.pause();
  //   };
  // }, [spriteInstance]);

	const ITEMS = useMemo(() => {
		const ITEMS_BASE = [
			{ type: 'walking', dataKey: 'strollAndJogging', target: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN, daysAgo: null, list: dateList },
			{ type: 'hiking', dataKey: 'hiking', target: null, daysAgo: null, list: [] },
			{ type: 'golfCoaching', dataKey: 'golfCoaching', target: null, daysAgo: null, list: [] },
			{ type: 'trainingCoaching', dataKey: 'training', target: null, daysAgo: null, list: [] }
		];

		return ITEMS_BASE.map(item => {
			if (Array.isArray(item.list) && item.list.length > 0) {
				item.daysAgo = HelperMethods.convertDateToDaysAgo(item.list[item.list.length - 1]);
			}

			return item;
		});
	}, [dateList]);

	const scrollToFunc = useCallback((index, listItem)=>{
		if(!HelperMethods.isChildFullyShowing(listItem.parentNode.parentNode, listItem)){
			scrollTo.current({node: listItem, animate: true, align: index === 0 ? 'left': 'right'});
		}
	},[]);

	const onClickFunc = useCallback((index, panel) => (ev) => {
		SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
		const listItem = ev.target;
		if(listItem && scrollTo.current){
			scrollToFunc(index, listItem);
		}

		if (panel.target) {
			dispatch(addPanels({ name: panel.target }));
		} else if (panel.type === 'hiking') {
			const contentId = cesShowMode ? 10 : 6;
			// if (cesShowMode) {
			// 	dispatch(addPanels({ name: Config.panel_names.PREVIEW_PANEL }));
			// }
			// dispatch(addPanels({ name: cesShowMode ? Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER : Config.panel_names.CONTENT_PLAYER_START, panelInfo: { type: 'activity', subType: 'hiking', contentId, init: true } }));
			dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { type: 'activity', subType: 'hiking', contentId, init: true } }));
		}
	},[dispatch, cesShowMode]);
	const onFocus = useCallback((index) => (ev) => {
		SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
		const listItem = ev.target;
		if(listItem && scrollTo.current){
			scrollToFunc(index, listItem);
		}
	},[dispatch]);

	const cbScrollTo = useCallback((fn)=>{
		scrollTo.current = fn;
	},[]);

  // const [isPaused, setIsPaused] = useState(false);
  // const onPause = useCallback(() => {
  //   setIsPaused(true);
  // }, []);

  // const getInstance = useCallback((instance) => {
  //   setSpriteInstance(instance);
  // }, []);

	return (
		<TPanel className={css.panel} {...rest}>
			<THeader title={$L("Outdoor Activity Indoors")}/>
			<TBody className={css.tBody}>
				<div className={css.title}>{$L("Turn the space in front of your TV into a space for trying out a variety of outdoor activities.")}</div>
        {/* <div className={css.imageContainer}>
          <Spritesheet
            image={spriteData.sprite.image}
            widthFrame={spriteData.sprite.widthFrame}
            heightFrame={spriteData.sprite.heightFrame}
            steps={spriteData.sprite.steps}
            fps={spriteData.sprite.fps}
            autoplay={false}
            onPause={onPause}
            getInstance={getInstance}
          />
        </div> */}
				<Scroller
						scrollMode='translate'
						focusableScrollbar={false}
						className={css.mainBtnInnerContainer}
						direction='horizontal'
						horizontalScrollbar='hidden'
						verticalScrollbar='hidden'
						overscrollEffectOn={{
								arrowKey: false,
								drag: false,
								pageKey: false,
								track: false,
								wheel: false
						}}
						cbScrollTo={cbScrollTo}
				>

					<Container className={css.sectionContainer}>

						{ITEMS.map((item, i) => (
							<MainSection
								key={item.type}
								type={item.type}
								daysAgo={item.daysAgo}
								onClick={onClickFunc(i, item)}
								onFocus={onFocus(i)} index={i}
							/>
						))}
					</Container>
				</Scroller>


			</TBody>
		</TPanel>
	);
};

export default InHomeOutDoorMain;
