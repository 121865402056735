import React, {useRef, useCallback, useState, useEffect} from 'react';
import classNames from "classnames";
import {useDispatch} from 'react-redux';
import {Job} from '@enact/core/util';
import Cancelable from '@enact/ui/Cancelable';
import {Panel} from '@enact/sandstone/Panels';
import Spritesheet from "react-responsive-spritesheet";
import { $L } from '../../utils/helperMethods';
import sprites from "../../../assets/loadingStatusAnimation/13frame_sec/css_sprites.png";
import tips1 from "../../../assets/images/loading/tips1.png";
import tips2 from "../../../assets/images/loading/tips2.png";
import tips3 from "../../../assets/images/loading/tips3.png";
import tips4 from "../../../assets/images/loading/tips4.png";
import loading_tip from "../../../assets/images/loading/loading_tip.png";
import launching from "../../../webos-meta/splash.png";
import PreloadImage from "../../components/PreloadImage/PreloadImage";
import CustomImage from "../../components/CustomImage/CustomImage";
import {changeAppStatus} from "../../features/common/commonSlice";
import css from './LoadingPanel.module.less';

const MIN_SHOWING_TIME= {launching: 2000, wait: 1000, analyzing: 1000, tips: 3000, default: 1000};
const MAX_SHOWING_TIME= 20000;
const MAX_SHOWING_TIME_UNLIMITED= Number.MAX_SAFE_INTEGER;
const DUMMY_DELAY= 1000;
const HIDING_TIME = 1000;
const TEXT_INTERVAL = 500;
const TIPS=[tips1, tips2, tips3, tips4];
const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const LoadingText = ({ str="", className }) => {
	const interverRef = useRef();
	const [loadingSign, setLoadingSign] = useState(str);
	const indexRef = useRef(0);
	useEffect(()=>{
		interverRef.current = setInterval(() => {
			let preStr='', postStr='';
			for(let i=0; i<indexRef.current; i++){
				preStr+='\u00b7 ';
				postStr+=' \u00b7';
			}
			setLoadingSign(preStr+str+postStr);
			indexRef.current= (indexRef.current+1)%4;
		}, TEXT_INTERVAL);
		return ()=>{
			if(interverRef.current){
				clearInterval(interverRef.current);
				interverRef.current = null;
			}
			indexRef.current = 0;
			setLoadingSign("");
		}
	},[str]);

	return (<div className={classNames(css.loadingSign, className)}>
		{loadingSign}
	</div>)
}

const TipText = ({ str="", className }) => {
	return (
	<div className={classNames(css.tipText, className)}>
		<div className={css.today}>{$L("Today's Tip")}</div>
		<div className={css.text}>{str}</div>
	</div>
	)
}

let minShowingTimeJob, hidingJob, maxShowingTimeJob;
/**
 * Animation generator
 * https://www.toptal.com/developers/css/sprite-generator
 *
 * @param {*} param0
 * @returns
 */
const LoadingPanel = ({showLoadingPanel, ...rest}) => {
	const dispatch = useDispatch();
	const [showingStatus, setShowingStatus] = useState({showing: true, hiding: false});
	const [loadingSign, setLoadingSign] = useState("");
	const [loadingType, setLoadingType] = useState("wait");
	const [loadingImage, setLoadingImage] = useState("");
	const [loadingTip, setLoadingTip] = useState("");
	const [readyToAni, setReadyToAni] = useState(false);
	const [readyToHide, setReadyToHide] = useState(false);
	const animationRef = useRef();

	useEffect(() => {
		return () => {
			if(minShowingTimeJob)minShowingTimeJob.stop();
			if(hidingJob)hidingJob.stop();
			if(maxShowingTimeJob)maxShowingTimeJob.stop();
		};
	}, []);

	useEffect(() => {
		if(readyToHide && !showLoadingPanel.show){
			setTimeout(() => {
				setShowingStatus({showing: false, hiding: true});
			}, DUMMY_DELAY);
		}
	}, [readyToHide, showLoadingPanel]);

	useEffect(() => {
		if(!showingStatus.showing){
			if(showingStatus.hiding){
				if(!hidingJob){
					hidingJob = new Job(() => {
						setShowingStatus({showing: false, hiding: false});
					}, HIDING_TIME);
				}
				hidingJob.start();
			}else{
				setReadyToHide(false);
				setReadyToAni(false);
				minShowingTimeJob.stop();
				hidingJob.stop();
				maxShowingTimeJob.stop();
				dispatch(changeAppStatus({showLoadingPanel: {show: false}}));
			}
		}
	}, [showingStatus]);
	useEffect(() => {
		if(showLoadingPanel.show){
			if(!maxShowingTimeJob){
				maxShowingTimeJob = new Job(() => {
					setShowingStatus({showing: false, hiding: true});
				}, showLoadingPanel.type === 'wait' ? MAX_SHOWING_TIME_UNLIMITED:  MAX_SHOWING_TIME);
			}
			maxShowingTimeJob.start();
			setShowingStatus({showing: true, hiding: false});
			let signText="", img="", tipText="";
			switch(showLoadingPanel.type){
				case "launching":{
					signText=$L("Loading");
					img = launching;
					break;
				}
				case "wait":{
					signText=$L("Please wait");
					img = loading_tip;
					// img = sprites;
					break;
				}
				case 'tips':{
					signText=$L("Loading");
					img = loading_tip;
					tipText = $L("Pilates assists in sculpting a comprehensive physique without excessive strain. It's a convenient and enjoyable exercise that you can do consistently, whenever you like, and for the long term.");
					// const randomImgNum = Math.floor(Math.random() * 4);
					// img = TIPS[randomImgNum];
					break;
				}
				case "analyzing":{
					signText=$L("Analyzing");
					img = sprites;
					break;
				}
			}
			setLoadingSign(signText);
			setLoadingImage(img);
			setLoadingType(showLoadingPanel.type);
			setLoadingTip(tipText);
		}
	}, [showLoadingPanel]);

	const handleCancel = useCallback((ev) => {
		if(ev) ev.stopPropagation();
	}, []);

	const onPreImageLoadComplete = useCallback(()=>{
		setReadyToAni(true);
		if(minShowingTimeJob){
			minShowingTimeJob.stop();
		}
		let minTime = MIN_SHOWING_TIME[loadingType];
		if(!minTime){
			minTime =  MIN_SHOWING_TIME.default;
		}
		minShowingTimeJob = new Job(() => {
			setReadyToHide(true);
		}, minTime);

		if(showingStatus.showing){
			minShowingTimeJob.start();
		}
	  },[showingStatus, loadingType]);

	const renderImages = useCallback(()=>{
		if(loadingType === 'tips' || loadingType === 'wait'){
			return (
				<>
					<CustomImage className={css.fullpageImage} src={loadingImage} animationSpeed={'normal'}/>
					<LoadingText str={loadingSign} className={css[loadingType]}/>
					{loadingTip &&
					<TipText str={loadingTip} />
					}
				</>
			);
		}else if(loadingType === 'launching'){
			return (
				<>
          <img
            className={classNames(css.fullpageImage, css.absolute)}
            src={loadingImage}
            animationSpeed={"normal"}
          />
          <LoadingText str={loadingSign} className={css[loadingType]} />
          {loadingTip && <TipText str={loadingTip} />}
				</>
			);
		}else if(loadingType){
			return (
				<>
					<Spritesheet
						className={css.spriteCommon}
						ref={animationRef}
						image={loadingImage}
						widthFrame={580}
						heightFrame={430}
						steps={30}
						fps={15}
						autoplay
						loop
					/>
					<LoadingText str={loadingSign} className={css[loadingType]}/>
				</>
			);
		}
		return null;
	},[loadingType, loadingImage, loadingSign]);

	const hidingStyle = showingStatus.hiding ? {"transition": "opacity "+HIDING_TIME+"ms ease", "opacity": 0}:{};

	if(showingStatus.showing || showingStatus.hiding){
		return (
      <CancelablePanel
        {...rest}
        className={classNames(
          css.panel,
          loadingType === "launching" && css.launching
        )}
        style={hidingStyle}
        handleCancel={handleCancel}
      >
				  <PreloadImage preloadImages={[loadingImage]} onLoadComplete={onPreImageLoadComplete}/>
				{readyToAni &&
					<div className={css.container}>
						{renderImages()}
					</div>
				}
				<div className={css.text1}>font loading...</div>
				<div className={css.text2}>font loading...</div>
				<div className={css.text3}>font loading...</div>
				<div className={css.text4}>font loading...</div>
				<div className={css.text5}>font loading...</div>
				<div className={css.text6}>font loading...</div>
				<div className={css.text7}>font loading...</div>
			</CancelablePanel>
		);
	}else{
		return null;
	}
};

export default LoadingPanel;
