import LS2Request from "./LS2Request";
import * as Config from "../utils/Config";
import appinfo from '../../webos-meta/appinfo.json';
//W6.0
export const getHttpHeaderForServiceRequest = (webOSVersion, language, {onSuccess, onFailure, onComplete}) => {
  if (webOSVersion === "local") {
    if (Config.SUPPORT_LOGIN || Config.USE_DUMMY) {
      if (language === "ko") {
        onSuccess({
          "X-User-Number": Config.DEBUG_WINDOW_ACCOUNTID_KR,
        });
      } else {
        onSuccess({
          "X-User-Number": Config.DEBUG_WINDOW_ACCOUNTID_US,
        });
      }
    } else {
      onFailure();
    }
    onComplete();
  } else {
    if (process.env.REACT_APP_MODE === "DEBUG") {
      console.log("LUNA SEND getHttpHeaderForServiceRequest");
      return "Some Hard Coded Mock Data";
    } else {
      return new LS2Request().send({
        service: "luna://com.webos.service.sdx",
        method: "getHttpHeaderForServiceRequest",
        subscribe: false,
        parameters: {},
        onSuccess,
        onFailure,
        onComplete,
      });
    }
  }
};

export const getSystemSettings = (parameters, {onSuccess, onFailure, onComplete}) => {
  if (typeof window === "object" && window.PalmSystem) {
    if (process.env.REACT_APP_MODE === "DEBUG") {
      console.log("LUNA SEND getSystemSettings");
      return "Some Hard Coded Mock Data";
    } else {
      return new LS2Request().send({
        service: "luna://com.webos.settingsservice",
        method: "getSystemSettings",
        subscribe: true,
        parameters: parameters,
        onSuccess,
        onFailure,
        onComplete,
      });
    }
  } else if(typeof window === "object"){
    const language = (typeof window.navigator === 'object') ? (window.navigator.language || window.navigator.userLanguage) : "en-US";
    const res = {
      settings: {
        smartServiceCountryCode2: language.split('-')[1],
        captionEnable: true,
      },
      returnValue: true,
    };
    onSuccess(res);
    onComplete(res);
  }
};
export const getSystemInfo = (parameters, {onSuccess, onFailure, onComplete}) => {
  if (typeof window === "object" && window.PalmSystem) {
    if (process.env.REACT_APP_MODE === "DEBUG") {
      console.log("LUNA SEND getSystemInfo");
      return "Some Hard Coded Mock Data";
    } else {
      return new LS2Request().send({
        service: "luna://com.webos.service.tv.systemproperty",
        method: "getSystemInfo",
        subscribe: false,
        parameters: parameters,
        onSuccess,
        onFailure,
        onComplete,
      });
    }
  } else {
    onSuccess({ returnValue: true, sdkVersion: "local" });
    onComplete();
  }
};
export const getDeviceId = (parameters, {onSuccess, onFailure, onComplete}) => {
  if(typeof window === "object" && window.PalmSystem){
    if(process.env.REACT_APP_MODE === "DEBUG") {
      console.log("LUNA SEND getDeviceId");
      return "Some Hard Coded Mock Data";
    }else{
      return new LS2Request().send({
        service: "luna://com.webos.service.sm",
        method: "deviceid/getIDs",
        subscribe: false,
        parameters: parameters,
        onSuccess,
        onFailure,
        onComplete,
      });
    }
  } else {
    onSuccess({ returnValue: true, idList: [{ idValue: Config.DEBUG_WINDOW_DEVICEID, idtype: "LGUDID" }],
    });
    onComplete();
  }
};
export const getLoginUserData = (parameters, {onSuccess, onFailure, onComplete}) => {
  if(typeof window === "object" && window.PalmSystem){
    if(process.env.REACT_APP_MODE === "DEBUG") {
      console.log("getLoginUserData");
      return "Mock Data";
    }else{
      return new LS2Request().send({
        service: "luna://com.webos.service.accountmanager",
        method: "getLoginUserData",
        subscribe: true,
        parameters: parameters,
        onSuccess,
        onFailure,
        onComplete,
      });
    }
  }
};
export const launchMembershipApp = ({onSuccess, onFailure, onComplete}) => {
  if(typeof window === "object" && window.PalmSystem){
    if (process.env.REACT_APP_MODE === "DEBUG") {
      console.log("LUNA SEND launchMembershipApp");
      return "Some Hard Coded Mock Data";
    } else {
      return new LS2Request().send({
        service: 'luna://com.webos.applicationManager',
        method: 'launchDefaultApp',
        subscribe: false,
        parameters:{"category":"MembershipApp", params: {query:"", appReturn: {appId: appinfo.id}}},
        onSuccess,
        onFailure,
        onComplete
      });
    }
  } else {
    onSuccess({ returnValue: true });
    onComplete();
  }
};