import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import css from "./TTimeProgressBar.module.less";
import * as Config from "../../utils/Config";
import Spottable from "@enact/spotlight/Spottable";
import ProgressBar from "@enact/sandstone/ProgressBar";

const SpottableComponent = Spottable("div");

const TTimeProgressBar = ({
  progress,
  total,
  className,
  ...rest
 }) => {

  return (
    <ProgressBar
      className={classNames(css.tTimeProgressBar, className)}
      progress={(progress/total)}
      backgroundProgress={1}
    />

  );
};

export default TTimeProgressBar;
