import React, { useCallback, useState } from 'react';
import css from './DeviceStatus.module.less';
import classNames from 'classnames';
import TButton from '../TButton/TButton';
import { $L } from '../../utils/helperMethods';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { useSelector, useDispatch } from 'react-redux';
import { gattConnect, gattDisconnect } from '../../features/ble/bleSlice';

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");
const DeviceStatus = ({ className, deviceName, codeNumber, isConnecting, isLastItem, isSleeping, batteryPercentage, onConnectionToggle }) => {
  const dispatch = useDispatch();
  const gattConnectingStatus = useSelector(state => state.ble.gattConnectingStatus);
  const [connected, setConnected] = useState(false);
  const handleToggleConnection = useCallback( (codeNumber) => async () => {
    try {
      setConnected((prevState) => !prevState);
      await new Promise((resolve) => setTimeout(resolve, 0));
      if(connected){
        dispatch(gattDisconnect());
      }else{
        dispatch(gattConnect(codeNumber));
      }
    }catch (error) {
      console.error('handleToggleConnection error', error);
      setConnected((prevState) => !prevState);
    }
  },[connected]);

  return (
    <Container className={classNames(css.container, className, isLastItem? css.noBorder : '')}>
      <div className={css.deviceInfo}>
        <div>
          <div className={css.deviceNameCode}>
            <div>{deviceName} ({codeNumber})</div>
          </div>
        </div>
        {(isConnecting && gattConnectingStatus.connectStatus === 'connected') && (
          <div className={classNames(css.status)}>{$L("Connecting...")}</div>
        )}
        {codeNumber === gattConnectingStatus.address && gattConnectingStatus.connectStatus === 'connected'?
          <div className={css.batteryInfo}>{batteryPercentage}%</div> : null
        }
      </div>
      <div className={css.buttonWrapper}>
        <TButton type={"normal"} className={css.button} onClick={handleToggleConnection(codeNumber)}>
          {isConnecting && gattConnectingStatus.connectStatus === 'connected' ?
            $L("Disconnect") : $L("Connect")
          }
        </TButton>
      </div>
    </Container>
  );
};

export default DeviceStatus;