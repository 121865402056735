import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from "./SmartMatPopup.module.less";
import TButton, {TYPES, SIZES, ICONS} from '../../components/TButton/TButton';
import TPopUp from '../../components/TPopUp/TPopUp';
import { $L, convertNewlinesToBr } from '../../utils/helperMethods';
import GuideImage from "../../../assets/testPopup/img_popup_mat_connect.png";
import DeviceStatus from '../../components/DeviceStatus/DeviceStatus';
import { scan, cancelScanAndGetDevice } from "../../features/ble/bleSlice";
import TScroller from '../../components/TScroller/TScroller';

const SmartMatPopup = ({onClose}) => {
  const dispatch = useDispatch();
  const bleList = useSelector(state => state.ble.bleDeviceList);
  const gattConnectingStatus = useSelector(state => state.ble.gattConnectingStatus);
  const connectStatus = gattConnectingStatus.connectStatus;
  const bleAddress = gattConnectingStatus.address;
  const [isSmartMatPopupOpen, setIsSmartMatPopupOpen] = useState(true);
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);

  const isLastItem = (index) => index === bleList.length - 1;

  const handlePopUp = useCallback((index)=>{
    if(index === 1){
      setIsSmartMatPopupOpen(false);
      setIsConnectionPopupOpen(true);
      dispatch(scan());
    }
  },[]);

  const _onClose = useCallback(()=>{
    if(onClose){
      onClose();
    }
    dispatch(cancelScanAndGetDevice());
  },[cancelScanAndGetDevice]);

  const searchBleDevices = useCallback(() => {
    dispatch(scan());
  }, [dispatch]);

    return (
      <>
        {connectStatus !== 'connected' && isSmartMatPopupOpen &&
          <TPopUp
            kind="smartMatPopUp"
            title={$L("How to connect to the Smart Mat")}
            text={$L("Turn on the Smart Mat.")}
            text1={convertNewlinesToBr($L("Once the Smart Mat is connected, {br} you can use Body Balancing and Virtual Walk/Jogging. {br} We also recommend personalized workouts."))}
            image={GuideImage}
            button1text={$L("Cancel connection")}
            button2text={$L("Connect now")}
            onClick={handlePopUp}
            onClose={_onClose}
          />
        }
        {isConnectionPopupOpen &&
          <TPopUp
            kind="smartMatPopUp"
            title={$L("Connect to the Smart Mat")}
            text={$L("Select the Smart Mat you want to connect to.")}
            button1text={$L("Verified")}
            onClose={_onClose}
          >
            <div className={css.matList}>
              <div className={css.matTitle}>{$L("Mat list")}</div>
              <TButton
                disabled={connectStatus === 'searching'}
                spotlightId="deviceSearchingBtn"
                className={css.searchDevices}
                size={SIZES.small}
                type={TYPES.withIcon}
                icon={connectStatus === 'searching' ? ICONS.loading : ICONS.refresh_nor}
                onClick={searchBleDevices}
              >
              {connectStatus === "searching"
                ? $L("Searching...")
                : $L("Search a device")}
              </TButton>
            </div>
            {(connectStatus !== 'connected'|| connectStatus !== 'searched') && bleList.length <= 0 &&
              <div className={css.searchFailed}>
                <div className={css.notSearch}>{$L("Smart Mat not found.")}</div>
                <div className={css.retrySearch}>{convertNewlinesToBr($L("Check if you have a Smart Mat and the connection. {br} To search again, tap the [Search a device] on the upper right corner."))}</div>
              </div>
            }
            {bleList.length > 0 &&
              <TScroller className={css.tscroller}>
                {bleList.map((list, idx)=>{
                  return (
                    <DeviceStatus
                      key={idx}
                      deviceName={list.name}
                      codeNumber={list.address}
                      isConnecting={list.address === bleAddress && connectStatus === 'connected'}
                      isSleeping={true}
                      batteryPercentage={list.address === bleAddress && connectStatus === 'connected'? 80 : null}
                      isLastItem={isLastItem(idx)}
                    />
                  )
                })}
              </TScroller>
            }
          </TPopUp>
        }
      </>
    );
};

export default SmartMatPopup;
