import classNames from "classnames";
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { $L } from "../../../../utils/helperMethods";
import css from "./SCheckbox.module.less";
import TButton, {TYPES ,ICONS, SIZES} from "../../../../components/TButton/TButton";
import { updateSurvey } from "../../../../features/fitService/fitServiceSlice";

const SCheckbox = ({question, className}) => {
  const dispatch = useDispatch()
  const answer = useSelector((state) => state.fitService.survey);

  const _onClick = useCallback((item) =>(ev) => {
    // 이미 선택된 경우 선택 해제
    if (answer?.[question.id]?.includes(item.seq)) {
      const filteredAnswer = answer[question.id].filter(selectedSeq => selectedSeq !== item.seq)
      dispatch(updateSurvey({[question.id]: filteredAnswer}))
      return;
    }
    // 해당 없음 버튼을 클릭 or 해당 없음 버튼이 존재하는 경우
    if (answer?.[question.id]?.includes(question.none_seq) || question.none_seq === item.seq) {
      dispatch(updateSurvey({[question.id]: [item.seq]}));
      return;
    }
    // 최대 선택 가능한 개수와 같을 경우
    if (answer?.[question.id]?.length === question.answer_max){
      return;
    }
    // 선택되지 않은 경우 선택
    dispatch(updateSurvey({[question.id]: [...answer[question.id], item.seq]}))

  }, [dispatch, answer, question])

	return (
    answer &&
    <div className={classNames(css.checkbox, className)}>
      {question.article.map((item, index) => (
        <TButton
          className={css.item}
          key={"checkbox" + index}
          selected={answer?.[question.id]?.includes(item.seq)}
          disabled={answer?.[question.id]?.length === question?.answer_max && !answer?.[question.id]?.includes(item.seq)}
          onClick={_onClick(item)}
          size={SIZES.small}
          // type={TYPES.withIcon}
          // icon={answer.includes(item.seq) ? ICONS.check: null}
        >
          {$L(item.article_title)}
        </TButton>
      ))}
    </div>
	);
};

export default SCheckbox;