import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {$L} from '../../../utils/helperMethods';
import TSettingSection from '../../../components/TSettingSection/TSettingSection';
import SmoodShowingAnimation from "../../../components/SmoodShowingAnimation/SmoodShowingAnimation";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { postSetting } from '../../../features/fitService/fitServiceSlice';

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused' }, 'div');

const MotivationSet = ({...rest}) => {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.fitService.settings);

  const SETTING_INFO = [
    {
      key: 'motivationSetYn',
      title: $L("Set motivation"),
      detail: $L("You can turn on or off the cheering message while walking, jogging, or hiking if you become too far away from the pacemaker."),
      button1: { text: $L("ON"), value: true },
      button2: { text: $L("OFF"), value: false }
    }
  ];

  const _onClick = useCallback((key) => (value) => {
    dispatch(postSetting({ [key]: value }));
  }, []);

  return (
    <div {...rest}>
      {SETTING_INFO.map((info, idx) => <Container key={idx}>
        <TSettingSection
          type="underLine"
          spotlightId={`MotivationSet_${info.key}`}
          text={info.title}
          subText={info.detail}
          button1={info.button1}
          button2={info.button2}
          value={settings[info.key]}
          onClick={_onClick(info.key)}
        />
      </Container>)}
    </div>
  );
};

export default SmoodShowingAnimation(MotivationSet);
