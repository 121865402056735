import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import css from "./FootTypeDetails.module.less";
import { $L } from "../../../../../utils/helperMethods";

import FootTypeDetailFlat from "../../../../../../assets/bodyBalance/img_balance_detail_flat.png";
import FootTypeDetailCavus from "../../../../../../assets/bodyBalance/img_balance_detail_cavus.png";
import FootTypeDetailNormal from "../../../../../../assets/bodyBalance/img_balance_detail_normal.png";
import TSimpleButtonTab, {
  LIST_TYPE as tabType,
} from "../../../../../components/TSimpleButtonTab/TSimpleButtonTab";
import { bodyBalanceMenu } from "../Constants";
import { bodyBalanceViewDetails } from "../feedback/feedbackBodyBalance";
import useContent from "../../../../../hooks/useContent";
import JMediaList, {
  LIST_TYPE,
} from "../../../../../components/JMediaList/JMediaList";
import TButton, { SIZES } from "../../../../../components/TButton/TButton";
import SMediaList, {
  ITEM_SIZE,
  TYPES,
} from "../../../../../components/SMediaList/SMediaList";
import classNames from "classnames";
import { footTypeStatus } from "../feedback/constantsFeedback";
import TVerticalPagenator from "../../../../../components/TVerticalPagenator/TVerticalPagenator";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import SMediaItem from "../../../../../components/SMediaList/SMediaItem";
import THorizontalScrollList from "../../../../../components/THorizontalScrollList/THorizontalScrollList";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const PAGENATE = [
  "FOOT_TYPE_DETAILS_0",
  "FOOT_TYPE_DETAILS_1",
  "FOOT_TYPE_DETAILS_2",
  "FOOT_TYPE_DETAILS_3",
];

const FootTypeDetails = ({ currentData, checkConnectionMat, ...rest }) => {
  const { contentList } = useContent({
    type: "recommend",
    subType: "bodyAlignment", // 임시본
  });
  const parentRef = useRef(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const [feedback, setFeedback] = useState({});

  const onItemClick = useCallback(({ index }) => {
    setSelectedTabIndex(index);
  }, []);

  const positionData = useMemo(() => {
    let res;
    let img;
    if (selectedTabIndex === 0) {
      res = feedback?.left;
    } else {
      res = feedback?.right;
    }
    switch (res?.key) {
      case footTypeStatus.FLATFOOT:
        img = FootTypeDetailFlat;
        break;
      case footTypeStatus.LUMBAR:
        img = FootTypeDetailCavus;
        break;
      default:
        img = FootTypeDetailNormal;
        break;
    }
    return { ...res, footTypeImg: img };
  }, [feedback, selectedTabIndex]);

  const onPageChanged = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  useEffect(() => {
    setFeedback(bodyBalanceViewDetails(bodyBalanceMenu.FOOT_TYPE, currentData));
  }, [currentData]);

  useEffect(() => {
    if (parentRef && parentRef.current) {
      parentRef.current({ position: { y: 0 }, animate: false });
    }
  }, [selectedTabIndex]);

  return (
    <>
      <TSimpleButtonTab
        className={css.tab}
        listType={tabType.report}
        contents={[$L("Left Foot"), $L("Right Foot")]}
        selectedIndex={selectedTabIndex}
        maxItemCount={5}
        onItemClick={onItemClick}
      />
      <TVerticalPagenator
        className={css.scroller}
        pageSpotIds={PAGENATE}
        onPageChanged={onPageChanged}
        visible={"visible"}
        parentRef={parentRef}
      >
        {/* page: FOOT_TYPE_DETAILS_0 */}
        <Container className={css.infoBox} spotlightId={PAGENATE[0]}>
          <div className={css.info}>
            <img src={positionData?.footTypeImg} alt="footType" />
            <div className={css.footTypeInfo}>
              <div className={css.title}>{`${
                selectedTabIndex === 0 ? $L("Left Foot") : $L("Right Foot")
              } ${positionData?.footType}`}</div>
              <div
                className={css.desc}
                dangerouslySetInnerHTML={{ __html: positionData?.status }}
              />
            </div>
          </div>
        </Container>

        {/* page: FOOT_TYPE_DETAILS_1 */}
        <Container className={css.diseasesListBox} spotlightId={PAGENATE[1]}>
          <div className={css.title} style={{ padding: "0 60px" }}>
            {$L("Impact in daily life")}
          </div>
          <div className={css.diseasesList}>
            {positionData?.diseases?.map((item, index) => (
              <div key={`disease-${index}`} className={css.desc}>
                {item}
              </div>
            ))}
            {positionData?.diseases?.length > 1 && (
              <div className={css.divider} />
            )}
            {positionData?.diseases?.length > 2 && (
              <div className={classNames(css.divider, css.second)} />
            )}
          </div>
        </Container>

        {/* page: FOOT_TYPE_DETAILS_2 */}
        <Container className={css.exerciseLayer} spotlightId={PAGENATE[2]}>
          <div className={css.title} style={{ padding: "0 60px" }}>
            <span className={css.icon} />
            {$L("Recommend Exercise")}
          </div>
          <div className={css.pictogramLayer}>
            {positionData?.exercise?.map((item, index) => (
              <div key={"pictogram_" + index} className={css.pictogram}>
                <span
                  className={css.picIcon}
                  // style={{
                  //   backgroundImage: `url(${pic.icon})`,
                  // }}
                >
                  #
                </span>
                {item}
              </div>
            ))}
          </div>
          <THorizontalScrollList startGap={60}>
          {contentList["KNEE_FLEXION"] && contentList["KNEE_FLEXION"].length > 0 && contentList["KNEE_FLEXION"].map((item, index) => (
            <SMediaItem
              key={`content_${index}`}
              type={TYPES.withCheck}
              itemInfo={item}
              // onClick={onItemClick}
            />
          ))}
          </THorizontalScrollList>
        </Container>

        {/* page: FOOT_TYPE_DETAILS_3 */}
        <Container className={css.bottomButtonLayer} spotlightId={PAGENATE[3]}>
          <TButton className={css.button} size={SIZES.test} disabled>
            {$L("Workout")}
          </TButton>
          <TButton
            className={css.button}
            size={SIZES.test}
            onClick={checkConnectionMat}
          >
            {$L("Test")}
          </TButton>
        </Container>
      </TVerticalPagenator>
    </>
  );
};

export default FootTypeDetails;
