/**
 * TGGraph
 *
 * @module TGGraph
 */
import classNames from 'classnames';
import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react';
import css from './TTrack.module.less';
import startIcon from "../../../assets/icon/ic_inhome_track_start.svg";
import goalIcon from "../../../assets/icon/ic_inhome_track_goal.svg";
import myIcon from "../../../assets/icon/ic_inhome_track_my.svg";
import speedIcon from "../../../assets/icon/ic_inhome_track_speed.svg";
import mapLineImg from "../../../assets/icon/img_map_line.svg";
import mapHeightImg from "../../../assets/icon/img_map_height.svg";
import mapCycloidImg from "../../../assets/icon/img_map_cycloid.svg";
import { clampingNumber } from '../../utils/helperMethods';

// const BACKGROUND_COLOR = '#FFFFFF33';
const PROGRESS_COLOR = '#F4CCA4';
const GUIDE_PROGRESS_COLOR = '#FEFEFE99';

export const TRACK_TYPE = {
  cycloid: 'cycloid',
  line: 'line',
  height: 'height'
}

const TRACK_IMG = {
  [TRACK_TYPE.cycloid]: mapCycloidImg,
  [TRACK_TYPE.line]: mapLineImg,
  [TRACK_TYPE.height]: mapHeightImg
}

const HEIGHT_ANGLE = -61.95;

const toRadian = (d) => (d * Math.PI) / 180;
const DEFAULT_ROTATE = 31.95; // 오른쪽 바라보도록 각도 조정

const WIDTH = 268;
const HEIGHT = 147;

const TTrack = ({ type, size, scale = 1, percentage = 0, guidePercentage = 0, increase = 0.1, isAnimate = true, hotSpot, className, ...rest }) => {
  const canvasRef = useRef(null);
  const canvasIdRef = useRef(null);
  const guideCanvasRef = useRef(null);
  const guideCanvasIdRef = useRef(null);
  const [startIconX, setStartIconX] = useState(0);
  const [startIconY, setStartIconY] = useState(0);
  const [myIconX, setMyIconX] = useState(0);
  const [myIconY, setMyIconY] = useState(0);
  const [myIconRotate, setMyIconRotate] = useState(0);
  const [speedIconX, setSpeedIconX] = useState(0);
  const [speedIconY, setSpeedIconY] = useState(0);
  const [speedIconScale, setSpeedIconScale] = useState(1);

  const CANVAS_WIDTH = WIDTH * scale;
  const CANVAS_HEIGHT = HEIGHT * scale;
  const LINE_WIDTH = 8 * scale;
  const TRACK_MARGIN = {
    [TRACK_TYPE.cycloid]: 25,
    [TRACK_TYPE.line]: 21,
    [TRACK_TYPE.height]: 21
  }[type] * scale;

  // TRACK_TYPE.cycloid 관련 변수
  const trackWidth = CANVAS_WIDTH - (TRACK_MARGIN * 2);
  const topYPos = (18 * scale);
  const bottomYPos = CANVAS_HEIGHT - (18 * scale);
  const rx = 55.5 * scale;

  const trackWidthStLine = trackWidth - (rx * 2); // 반원을 제외한 직선 길이
  const halfCircle = Math.PI * rx; // 반원 둘레
  const firstEnd =  trackWidthStLine / 2; // 시작 위치 - 오른쪽 반원 이전까지의 거리
  const secondEnd = firstEnd + halfCircle; // firstEnd + 반원 둘레
  const thirdEnd = secondEnd + trackWidthStLine; // secondEnd + 왼쪽 반원 이전까지의 거리
  const fourthEnd = thirdEnd + halfCircle; // thirdEnd + 왼쪽 반원 둘레

  const leftCenterX = TRACK_MARGIN + rx; // 왼쪽 반원 중심 x
  const rightCenterX = TRACK_MARGIN + trackWidth - rx; // 오른쪽 반원 중심 x
  const centerY = CANVAS_HEIGHT / 2; // 반원 중심 y

  // progress에 따른 좌표
  const getEndPos = useCallback((progress) => {
    if (type === TRACK_TYPE.cycloid) {
      const endXCoordinate = progress / 100 * ((trackWidthStLine * 2) + (2 * halfCircle)); // 현재 이동된 거리

      let obj = {
        xPos: (CANVAS_WIDTH / 2) + ((trackWidthStLine / 2) * Math.min((endXCoordinate / firstEnd), 1)),
        yPos: bottomYPos,
        angle: 90,
        endXCoordinate
      }
      obj.firstPos = { angle: obj.angle, xPos: obj.xPos, yPos: obj.yPos };

      if (endXCoordinate > firstEnd) {
        obj.angle = 90 - (180 * Math.min(((endXCoordinate - firstEnd) / halfCircle), 1));
        obj.xPos = rightCenterX + rx * Math.cos(toRadian(obj.angle));
        obj.yPos = centerY + rx * Math.sin(toRadian(obj.angle));
        obj.secondPos = { angle: obj.angle, xPos: obj.xPos, yPos: obj.yPos };

        if (endXCoordinate > secondEnd) {
          obj.xPos = TRACK_MARGIN + rx + (trackWidthStLine * Math.max(1 - ((endXCoordinate - secondEnd) / trackWidthStLine), 0));
          obj.yPos = topYPos;
          obj.thirdPos = { angle: obj.angle, xPos: obj.xPos, yPos: obj.yPos };

          if (endXCoordinate > thirdEnd) {
            obj.angle = -90 - (180 * Math.min(((endXCoordinate - thirdEnd) / halfCircle), 1));
            obj.xPos = leftCenterX + rx * Math.cos(toRadian(obj.angle));
            obj.yPos = centerY + rx * Math.sin(toRadian(obj.angle));
            obj.fourthPos = { angle: obj.angle, xPos: obj.xPos, yPos: obj.yPos };

            if (endXCoordinate > fourthEnd) {
              obj.xPos = (TRACK_MARGIN + rx) + ((trackWidthStLine / 2) * Math.min(((endXCoordinate - fourthEnd) / (trackWidthStLine / 2)), 1));
              obj.yPos = bottomYPos;
            }
          }
        }
      }

      return obj;
    } else if (type === TRACK_TYPE.line) {
      const endXCoordinate = progress / 100 * (CANVAS_WIDTH - (TRACK_MARGIN * 2));

      return { xPos: TRACK_MARGIN + endXCoordinate, yPos: CANVAS_HEIGHT / 2, endXCoordinate };
    } else if (type === TRACK_TYPE.height) {
      const startX = TRACK_MARGIN + scale;
      const startY = CANVAS_HEIGHT - (20 * scale);
      const hypotenuse = Math.sqrt(((83 * scale) - startX)**2 +  ((startY) - (14 * scale))**2);
      const endXCoordinate = hypotenuse / 100 * progress;
      const curX = endXCoordinate * Math.cos(toRadian(HEIGHT_ANGLE));
      const curY = endXCoordinate * Math.sin(toRadian(HEIGHT_ANGLE))

      return { xPos: startX + curX, yPos: startY + curY, endXCoordinate };
    }

    return { xPos: TRACK_MARGIN, yPos: CANVAS_HEIGHT / 2 };
  }, []);

  // hotSpot TBD
  const drawHotSpot = useCallback(() => {
    if (canvasRef && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      if (Array.isArray(hotSpot) && hotSpot.length > 0) {
        hotSpot.forEach(spot => {
          const spotPos = getEndPos(spot);
          ctx.beginPath();
          ctx.fillStyle = "white";
          ctx.arc(spotPos.xPos, spotPos.yPos, LINE_WIDTH, 0, 2 * Math.PI);
          ctx.fill();
        });
      }
    }
  }, []);

  // 캔버스 초기화
  const clearCanvas = useCallback((canvas, guide) => {
    canvas._percentage = 0;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = LINE_WIDTH;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeStyle = guide ? GUIDE_PROGRESS_COLOR : PROGRESS_COLOR;

    const startPos = getEndPos(0);
    setStartIconX(startPos.xPos);
    setStartIconY(startPos.yPos);

    setMyIconX(startPos.xPos);
    setMyIconY(startPos.yPos);
    setMyIconRotate((guide && type === TRACK_TYPE.height) ? HEIGHT_ANGLE : 0);
    setSpeedIconX(startPos.xPos);
    setSpeedIconY(startPos.yPos);

    if (!guide) drawHotSpot();
  }, []);

  // progress 진행률만큼 track 그리기
  const drawProgress = useCallback((canvas, progress, guide) => {
    const ctx = canvas.getContext('2d');

    if (progress < 0 || progress > 100) return; // FIXME: 0미만 값, 100초과 값에 대한 처리 필요하다면 따로 해주기
    const startPos = getEndPos(0);
    const endPos = getEndPos(progress);

    ctx.clearRect(0, 0, canvas.width, canvas.height); // canvas 초기화
    if (!guide) drawHotSpot();
    ctx.beginPath();

    // icon 위치 지정
    if (guide) {
      setSpeedIconX(endPos.xPos);
      setSpeedIconY(endPos.yPos);
    } else {
      setMyIconX(endPos.xPos);
      setMyIconY(endPos.yPos);
    }

    // draw
    if (type === TRACK_TYPE.cycloid) {
      if (guide) setSpeedIconScale(1);
      else setMyIconRotate(0);

      ctx.moveTo(startPos.xPos, startPos.yPos); // 시작 위치
      ctx.lineTo(endPos.firstPos.xPos, endPos.firstPos.yPos);

      if (endPos.endXCoordinate > firstEnd) {
        if (guide) setSpeedIconScale(endPos.secondPos.angle < 0 ? -1 : 1); // setSpeedIconScale(angle / 90);
        else setMyIconRotate(endPos.secondPos.angle - 90);

        ctx.arc(rightCenterX, centerY, rx, toRadian(90), toRadian(endPos.secondPos.angle), true);
        if (endPos.endXCoordinate > secondEnd) {
          if (guide) setSpeedIconScale(-1);
          else setMyIconRotate(-180);

          ctx.lineTo(endPos.thirdPos.xPos, endPos.thirdPos.yPos);
          if (endPos.endXCoordinate > thirdEnd) {
            if (guide) setSpeedIconScale(endPos.fourthPos.angle < -180 ? 1 : -1); // setSpeedIconScale((angle + 180) / -90);
            else setMyIconRotate(endPos.fourthPos.angle - 90);

            ctx.arc(leftCenterX, centerY, rx, toRadian(-90), toRadian(endPos.fourthPos.angle), true);
            if (endPos.endXCoordinate > fourthEnd) {
              if (guide)setSpeedIconScale(1);
              else setMyIconRotate(0);

              ctx.lineTo(endPos.xPos, endPos.yPos);
            }
          }
        }
      }
      ctx.stroke();
    } else if (type === TRACK_TYPE.line) {
      if (guide) setSpeedIconScale(1);
      else setMyIconRotate(0);

      ctx.moveTo(startPos.xPos, startPos.yPos);
      ctx.lineTo(endPos.xPos, endPos.yPos);
      ctx.stroke();
    } else if (type === TRACK_TYPE.height) {
      if (guide) setSpeedIconScale(1);
      else setMyIconRotate(HEIGHT_ANGLE);

      ctx.moveTo(startPos.xPos, startPos.yPos);
      ctx.lineTo(endPos.xPos, endPos.yPos);
      ctx.stroke();
    }
  }, []);

  const animate = useCallback((canvas, canvasId, percent, guide) => {
    if (percent === 0) clearCanvas(canvas, guide);

    if (typeof window === "object" && isAnimate) {
      if (canvas._percentage >= percent) {
        // console.log(`[${canvasId.current}] cancel animate...`);
        window.cancelAnimationFrame(canvasId.current);
        return;
      }

      canvas._percentage += increase;
      drawProgress(canvas, Math.min(canvas._percentage, percent), guide);
      // console.log(`[${canvasId.current}] animate... ${canvas._percentage} --> ${Math.min(canvas._percentage, percent)}`);
      if(typeof window === "object"){
        canvasId.current = window.requestAnimationFrame(() => animate(canvas, canvasId, percent, guide));
      }
    } else {
      canvas._percentage = percent;
      drawProgress(canvas, percent, guide);
    }
  }, [isAnimate]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const guideCanvas = guideCanvasRef.current;

    if (canvas) {
      clearCanvas(canvas);
    }
    if (guideCanvas) {
      clearCanvas(guideCanvas, true);
    }

    return () => {
      if (typeof window === "object") {
        if (canvasIdRef && canvasIdRef.current) {
          window.cancelAnimationFrame(canvasIdRef.current);
        }
        if (guideCanvasIdRef && guideCanvasIdRef.current) {
          window.cancelAnimationFrame(guideCanvasIdRef.current);
        }
      }
    }
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      animate(canvas, canvasIdRef, clampingNumber(percentage));
    }
  }, [percentage, animate]);

  useEffect(() => {
    const canvas = guideCanvasRef.current;

    if (canvas) {
      animate(canvas, guideCanvasIdRef, clampingNumber(guidePercentage), true);
    }
  }, [guidePercentage, animate]);

  const iconScaleStyle = useMemo(() => {
    return { transform: `translate(-50%, -50%) scale(${scale})` };
  }, [scale]);

  const myIconScaleStyle = useMemo(() => {
    return { transform: `translate(-50%, -50%) rotate(${DEFAULT_ROTATE + myIconRotate}deg) scale(${scale})`, top: myIconY, left: myIconX };
  }, [scale, myIconX, myIconY, myIconRotate]);

  const goalIconScaleStyle = useMemo(() => {
    const obj = { transform: `translate(-50%, -50%) scale(${scale})` };
    const iconSizeDiff = (2 * scale); // startIcon, goalIcon이 중앙 정렬되었을 때 goalIcon - startIcon 좌측 상단 여백 값

    obj.top = startIconY - iconSizeDiff - (12 * scale);
    if (type === TRACK_TYPE.cycloid) {
      obj.left = startIconX + iconSizeDiff + (4 * scale);
    } else if (type === TRACK_TYPE.line) {
      obj.left = (CANVAS_WIDTH - startIconX) + iconSizeDiff + (4 * scale);
    }

    return obj;
  }, [scale, type, startIconX, startIconY]);

  const speedIconScaleStyle = useMemo(() => {
    return { transform: `translate(-50%, -50%) scale(${scale}) scaleX(${speedIconScale})`, top: speedIconY, left: speedIconX };
  }, [scale, speedIconX, speedIconY, speedIconScale]);

  return (
    <div className={classNames(css.trackContainer, className)} {...rest}>
      {type !== TRACK_TYPE.height && <img src={goalIcon} className={css.goalIcon} style={goalIconScaleStyle}/>}
      <img src={startIcon} className={css.startIcon} style={Object.assign({}, iconScaleStyle, { top: startIconY, left: startIconX })}/>
      {type === TRACK_TYPE.line && <img src={startIcon} className={css.startIcon} style={Object.assign({}, iconScaleStyle, { top: startIconY, left: CANVAS_WIDTH - startIconX })}/>}
      {(canvasRef && canvasRef.current && canvasRef.current._percentage && canvasRef.current._percentage > 0 && canvasRef.current._percentage < 100) ? <img src={myIcon} className={css.myIcon} style={myIconScaleStyle}/> : null}
      {(guideCanvasRef && guideCanvasRef.current && guideCanvasRef.current._percentage && guideCanvasRef.current._percentage > 0 && guideCanvasRef.current._percentage < 100) ? <img src={speedIcon} className={css.speedIcon} style={speedIconScaleStyle}/> : null}
      <canvas ref={canvasRef} className={css.canvas} width={CANVAS_WIDTH} height={CANVAS_HEIGHT}></canvas>
      <canvas ref={guideCanvasRef} className={css.guideCanvas} width={CANVAS_WIDTH} height={CANVAS_HEIGHT}></canvas>
      <img src={TRACK_IMG[type]} style={{ position: 'absolute', top: 0, left: 0, transform: `scale(${scale})`, transformOrigin: 'top left' }}/>
    </div>
  );
};

export default TTrack;