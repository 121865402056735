import { useState, useCallback } from "react";
import css from "./BodyCheckUpCountdownTimer.module.less";
import CaptureCountDownTimer from "../../../components/CountDownTimer/CountDownTimer";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";


const BodyCheckUpCountdownTimer = ({
  isCountDownStarted,
  countdownCompleted,
  hasStart,
  onTick
}) => {
  const [audioSource, setAudioSource] = useState("");

  const onAudioEnded = useCallback(() => {
     setAudioSource("");
  }, []);

  const _onTick = useCallback((ev) => {
    if (ev === 3) {
      setAudioSource("M5_3_2_1");
    }
    if(onTick){
      onTick(ev);
    }
 }, [onTick]);

 const _countdownCompleted = useCallback((ev) => {
  if(countdownCompleted){
    countdownCompleted();
  }
}, [countdownCompleted]);

return (
    <div className={css.bodycheckupCountdownTimer}>
      <div>
        {isCountDownStarted && (
          <CaptureCountDownTimer
            hideWhenFinish={true}
            onZeroFired={true}
            istring={"{sec}"}
            count={2}
            cancelDisabled={true}
            hasStart={hasStart}
            onFire={_countdownCompleted}
            onTick={_onTick}
          />
        )}
        <AudioPlayer srcTypeStr={audioSource} onEnded={onAudioEnded} />
      </div>
    </div>
  );
};

export default BodyCheckUpCountdownTimer;
