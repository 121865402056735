import css from './MobileSend.module.less';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { popPanel, addPanels } from "../../../features/panels/panelsSlice";
import {
  changeThemeModeStatus,
  changeToastStatus,
} from "../../../features/common/commonSlice";

import { $L } from '../../../utils/helperMethods';

import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader/THeader';
import TBody from '../../../components/TBody/TBody';
import TButton, { SIZES as BSIZES } from '../../../components/TButton/TButton';
import TInput, { BORDER, COLOR as ICOLOR } from '../../../components/TInput/TInput';
import TDropdown, { SIZES, COLOR as DCOLOR } from '../../../components/TDropDown/TDropDown';
import TScroller from '../../../components/TScroller/TScroller';
import TIconButton from '../../../components/TIconButton/TIconButton';
import TToast from '../../../components/TToast/TToast';

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import Spotlight from '@enact/spotlight';
import { getCountyCodes } from '../../../utils/countyCodes';
import TListItemButton from '../../../components/TListItemButton/TListItemButton';

const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

const MobileSend = ({ panelInfo,...rest }) => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.common.toast);
  const [mobile, setMobile] = useState(null);
  const [selected, setSelected] = useState(206); //KR
  const [mobileList, setMobileList] = useState(["+82 0107890123", "+82 0107890124", "+82 0107890125", "+82 0107890126", "+82 0107890127"]);
  const recentMobileList = ["+82 0107290123", "+82 0107290124", "+82 0107290125"];
  const countryCodes = getCountyCodes();
  const maxList = 10

  useEffect(() => {
    Spotlight.focus("mobileSendSpot");
  }, []);

  // useEffect(() => {
	// 	if(mobile && ismobileAddress(mobile)){
	// 		setValid(true);
	// 	}else{
	// 		setValid(false);
	// 	}
	// },[mobile]);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  const onMobileChange = useCallback((e) => {
		setMobile(e.value);
	}, []);

  const onDeactivateInput = useCallback((e) => {
    console.log(e)
	}, []);

  const onClickPlusBtn = useCallback(() => {
    if (mobileList.includes(mobile)) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("mobile already exists");
      return;
    }

    if (mobileList.length === maxList) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("입력 가능 횟수를 초과");
      return;
    }

    if (mobile) {
      const countryCode = countryCodes[selected].split("+")[1];
      const formattedMobile = `+${countryCode} ${mobile}`;
      setMobileList([formattedMobile, ...mobileList]);
      setMobile("");
    } else {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("유효하지 않는 이메일");
    }
  }, [mobileList, mobile]);

  const onClickRecentMobile = useCallback((index) => (ev) => {
    if (mobileList.includes(recentMobileList[index])) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("mobile already exists");
      return;
    }

    if (mobileList.length === maxList) {
      // dispatch(changeToastStatus({ toast: true }));
      console.log("입력 가능 횟수를 초과");
      return;
    }

    setMobileList([recentMobileList[index], ...mobileList]);

  }, [mobileList, recentMobileList]);


  const onClickMinusBtn = useCallback((index) => (e) => {
    const updatedMobileList = mobileList.filter((_, i) => i !== index);
    setMobileList(updatedMobileList);
  }, [mobileList]);

  const onClickPrevBtn = useCallback(() => {
    dispatch(popPanel());
  },[dispatch]);

  const onClickNextBtn = useCallback(() => {

  },[]);

  const onSelectCountry = useCallback(({ selected }) => {
    setSelected(selected);
  }, []);

  const instruction = useMemo(()=>{
    return $L(`
              <span>모바일 공유를 위해 휴대폰 번호를 입력해주세요. </span>
              <span>
                입력하신 휴대폰 번호로 카카오톡 메세지가 발송되며, 리포트를 다운받으실 수 있어요.
                <br>
                만일, 카카오톡 메세지 발송이 실패될 경우 SMS로 발송됩니다.
              </span>
  `)}, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Send to your phone")} />
      <TBody className={css.body}>
      <div className={css.instruction} dangerouslySetInnerHTML={{ __html: instruction }}/>
        <div className={css.inputSection}>
          <div className={css.inputConainer}>
            <div className={css.mobile}>
              <TDropdown
                width={432}
                size={SIZES.large}
                onSelect={onSelectCountry}
                color={DCOLOR.transparent}
                selected={selected}
              >
                {countryCodes}
              </TDropdown>
              <div className={css.vr}/>
              <TInput
                border={BORDER.none}
                color={ICOLOR.transparent}
                spotlightId={"mobileSendSpot"}
                className={css.input}
                autoFocus
                dismissOnEnter
                value={mobile}
                onChange={onMobileChange}
                type={"number"}
                onDeactivate={onDeactivateInput}
                placeholder={$L('Enter your phone number')}
              />
            </div>
            <TIconButton iconType="plus" size={"small"} onClick={onClickPlusBtn} />
          </div>
          <Container className={css.recentContainer}>
            <div className={css.title}>{$L('Recent list')}</div>
            <div className={css.list}>
              {recentMobileList.map((mobile, index) => (
                <TListItemButton
                  onClick={onClickRecentMobile(index)}
                  key={"recent"+index}
                  className={css.mobile}
                >
                  {mobile}
                </TListItemButton>
              ))}
            </div>
          </Container>
        </div>
        <Container className={css.recipientContainer}>
          <div className={css.title}>{`${$L('Recipient')} ${mobileList.length}/10`} </div>
          <div className={css.hr}/>
          <TScroller className={css.scroller}>
            <div className={css.list}>
              {mobileList.map((mobile, index) => (
                <TListItemButton className={css.recipient} key={"recipient"+index} onClick={onClickMinusBtn(index)}>
                  <div className={css.mobile}>{mobile}</div>
                  <div className={css.deleteIcon}/>
                </TListItemButton>
              ))}
            </div>
          </TScroller>
        </Container>
        <div className={css.buttonArea}>
          <TButton size={BSIZES.xLarge} onClick={onClickPrevBtn}>{$L("Cancel")}</TButton>
          <TButton size={BSIZES.xLarge} onClick={onClickNextBtn}>{$L("Send")}</TButton>
        </div>
        {/* {toast && mobileList.length < maxList && !valid &&
          <TToast text={$L("Check your email address.")}/>
        }
        {toast && mobile && mobileList.length === maxList &&
          <TToast text={$L("Enter up to 10 exercises")}/>
        } */}
        {/* {toast && mobileList.length < maxList &&
          <TToast text={$L("This email already exists.")}/>
        } */}

      </TBody>
    </TPanel>
  );
};
export default MobileSend;