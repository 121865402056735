import React, { useEffect, useRef, useMemo } from 'react';
import css from './TTimeLine.module.less';
import TSummary, { SUMMARY_SIZE } from '../TSummary';
import { $L } from '../../../../utils/helperMethods';
import { formatDateTime } from '../../../../utils/helperMethods';
import TTimeProgressBar from '../../../../components/TTimeProgressBar/TTimeProgressBar';
import ic_calendar from '../../../../../assets/HomecareProgram/ic_calendar.svg';

const TTimeLine = ({ daily = [], idx = "",className, ...rest }) => {
  const timeLineRef = useRef(null);

  const dailyInfo = useMemo(() => {
    let res = { date: "", image: "", expandCategory: "", expandTitle: "", summary: [], exerciseProgress: ""};

		if(daily){
      res.date = daily.date;
      res.image = daily.image;
      res.expandCategory = daily.expandCategory;
      res.expandTitle = daily.expandTitle;
      res.summary = daily.summary.map(item => ({ ...item }));
      res.exerciseProgress = daily.exerciseProgress;
		}
		return res;
  }, [daily]);

  const progress = dailyInfo.exerciseProgress; //todo data
  const total = 100; //todo data

	useEffect(() => {
		if (timeLineRef && timeLineRef.current) {
			Array.from(timeLineRef.current.children).forEach(el => {
				if (el.nextSibling) {
					const nextEl = el.nextSibling;
					const elHeight = el.offsetHeight - el.firstChild.offsetHeight;
					const nextElHeight = nextEl.offsetHeight - nextEl.firstChild.offsetHeight;

					el.firstChild.lastChild.setAttribute('style', `height: ${((elHeight + nextElHeight) / 2) - 8}px;`);
				}
			})
		}
	}, [daily]);

	return (
		<ul ref={timeLineRef} className={css.timeLineContainer} {...rest}>
      <li key={`timeLine-${idx}`} className={css.timeWrapper}>
        <div className={css.time}>
          <div className={css.calendar}>
            <img src={ic_calendar} />
          </div>
          <div>{formatDateTime('YYYY.MM.DD', dailyInfo?.date)}</div>
          {/* {idx < dailyInfo.date.length - 1 && <div className={css.bar} />} */}
          <div className={css.bar} />
        </div>
        <div className={css.infoBox}>
          <div className={css.infoWrapper}>
            <div className={css.image} style={{ backgroundImage: `url(${dailyInfo.image})` }} />
            <div className={css.info}>
              {dailyInfo.expandCategory && <div className={css.expandCategory}>{dailyInfo.expandCategory}</div>}
              <div className={css.expandTitle}>{dailyInfo.expandTitle}</div>
              <div className={css.tSummary}>
                {dailyInfo.summary.map((summary, idx) => (
                  <TSummary key={`summary_${idx}`} summary={summary} idx={idx} size={SUMMARY_SIZE.lite} />
                ))}
              </div>
              <div className={css.progressBarWrap}>
                <div className={css.progressBarInfo}>{$L("운동 진행")} <span>{dailyInfo.exerciseProgress}</span>%</div>
                <TTimeProgressBar
                  progress={progress}
                  total={total}
                  className={css.timeProgressBar}
                />
              </div>
            </div>
          </div>
        </div>
      </li>
		</ul>
	);
};

export default TTimeLine;