import React, {useRef, useCallback} from 'react';
import Image from '@enact/sandstone/Image';
import Repeater from '@enact/ui/Repeater';
import css from './PreloadImage.module.less';

const ImgItem = ({children, onLoad}) => (<Image src={children} className={css.visibleHidden} onLoad={onLoad}/>);

const PreloadImage = ({preloadImages=[], onLoadComplete}) => {
	const loadedCountRef = useRef(0);
	const onLoad = useCallback(()=>{
		loadedCountRef.current++;
		if(loadedCountRef.current === preloadImages.length){
			if(onLoadComplete){
				onLoadComplete();
			}
		}
	},[onLoadComplete]);
	return(
	<Repeater className={css.repeater} childComponent={ImgItem} itemProps={{onLoad}}>{preloadImages}</Repeater>
	)
};

export default PreloadImage;


