import css from "./ReportPanel.module.less";
import classNames from "classnames";

import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addPanels } from "../../features/panels/panelsSlice";
import { changeThemeModeStatus } from "../../features/common/commonSlice";
import {
  $L,
  transDateToText,
  transNumToAgeText,
} from "../../utils/helperMethods";
import * as Config from "../../utils/Config";

import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import TButton, { COLOR, ICONS, SIZES, TYPES } from "../../components/TButton/TButton";

import JIMMY_IMAGE from "../../../assets/report/jimmy.png"
import FIT_ICON from "../../../assets/report/icon/ic_intelligent_fit.png"

import Spotlight from "@enact/spotlight";
import InfoSection from "./InfoSection/InfoSection";

const ReportPanel = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { height, weight, sex, age } = useSelector((state) => state.fitService.survey);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const { profileNick, language } = useSelector(
    (state) => state.common.appStatus
  );

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  useEffect(() => {
    Spotlight.focus("report_share");
  }, []);

  const USER_INFO = useMemo(() => {
    return [
      { id: "name", title: $L("Name"), info: profileNick },
      {
        id: "gender",
        title: $L("Gender"),
        info: sex === 0 ? $L("Male") : $L("Female"),
      },
      {
        id: "birth",
        title: $L("Birth"),
        info: transDateToText("1963-05-30", language),
      },
      {
        id: "age",
        title: $L("Age"),
        info: transNumToAgeText(age ? age : 60),
      },
      { id: "height", title: $L("Height"), info: height ? `${height}cm` : "176cm" },
      { id: "weight", title: $L("Weight"), info: weight ? `${weight}kg` : "70kg"},
    ];
  }, [profileNick, age, sex, height, weight, language]);

  const ORDER = useMemo(() => {
    return [
      {
        type: "bodyalignment",
        title: $L("Body Alignment"),
        target: { name: Config.panel_names.BODY_ALIGNMENT_REPORT },
      },
      {
        type: "rom",
        title: $L("Range Of Motion"),
        target: { name: Config.panel_names.ROM_REPORT },
      },
      {
        type: "bodybalance",
        title: $L("Body Balance"),
        target: { name: Config.panel_names.BODY_BALANCE_REPORT },
      },
      {
        type: "physicaltest",
        title: $L("Physical Test"),
        target: { name: Config.panel_names.PHYSICAL_TEST_REPORT },
      },
    ];
  }, []);

  const _onClickSection = useCallback((target) => (ev) => {
    dispatch(addPanels(target));
  }, [dispatch]);


  const onClickReportBtn = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.PREVIEW_PANEL}));
  },[dispatch]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("FiT Report")} />
      <TBody className={css.body}>
        <div className={css.fitIcon} style={{backgroundImage: `url(${FIT_ICON})`}}/>
        <div className={css.jimmyImage} style={{backgroundImage: `url(${JIMMY_IMAGE})`}}/>
        {/* user info */}
        <div className={css.infoContainer}>
          <div className={css.infoArea}>
            {USER_INFO.map((item) => {
              return (
                <div className={css.infoBox} key={item.id}>
                  <div className={css.qBox}>{$L(item.title)}</div>
                  <div className={css.aBox}>{$L(item.info)}</div>
                </div>
              );
            })}
          </div>
          <div className={css.vr}/>
          <TButton
            size={SIZES.xLarge}
            type={TYPES.withIcon}
            icon={ICONS.share}
            color={COLOR.transparent}
            onClick={onClickReportBtn}
            spotlightId={"report_share"}
            disabled={cesShowMode}
          >
            {$L("Sharing Report")}
          </TButton>
        </div>
        {/* bodyCheckUp */}
        <div className={css.bodyCheckUpContainer}>
          {ORDER.map((item, index) => (
            <InfoSection
              key={"order" + index}
              type={item.type}
              onClick={_onClickSection(item.target)}
              title={item.title}
              disabled={true}
            />
          ))}
        </div>

      </TBody>
    </TPanel>
  );
};

export default ReportPanel;
