import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import classNames from "classnames";
import css from "./LandingPage.module.less";
import { useDispatch, useSelector } from "react-redux";
import { addPanels } from "../../features/panels/panelsSlice";
import * as Config from "../../utils/Config";
import { $L, convertNewlinesToBr } from "../../utils/helperMethods";
import useDebugKey from "../../hooks/useDebugKey";

import TPanel from "../../components/TPanel";
import THeader from "../../components/THeader/THeader";
import TButton, { COLOR, SIZES } from "../../components/TButton";
import MainSection from "./MainSection";
import TBody from "../../components/TBody";
import CustomImage from "../../components/CustomImage";
import SpotlightIds from "../../utils/SpotlightIds";

import { changeAppStatus, sendBroadCast } from "../../features/common/commonSlice";
import Spotlight from "@enact/spotlight";
import TPopUp from "../../components/TPopUp/TPopUp";
import { getContent } from "../../features/fitService/fitServiceSlice";
import TVerticalPagenator from "../../components/TVerticalPagenator/TVerticalPagenator";
import useContent from "../../hooks/useContent";

import PhysicalTestList from "./PhysicalTestList/PhysicalTestList";
import SMediaList, { ITEM_SIZE } from "../../components/SMediaList/SMediaList";
import SpriteImage from "./SpriteImage/SpriteImage";
import GGraph from "./GGraph/GGraph";
import ContentCollection from "./ContentCollection/ContentCollection";
import BodyAlignmentList from "./BodyAlignmentList/BodyAlignmentList";

import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import useGesture from "../../hooks/useGesture";

import HERO_BG_IMAGE from "../../../assets/mainSections/img_home_hero_bg.png";
import LOGO_IMAGE from "../../../assets/mainSections/img_logo.png";
import BANNER_IMAGE from "../../../assets/landingPage/img_event_banner.png"
import THorizontalScrollList from "../../components/THorizontalScrollList/THorizontalScrollList";
import SMediaItem from "../../components/SMediaList/SMediaItem";
// import dummyJVideoList from "../../../assets/mock/dummyJVideoList.json";

// thumbnail image
import img_neck_extension from "../../../assets/landingPage/bodyAlignmentContinueList/img_thumbnail_alignment_neckextension.png";
import img_neck_tilt from "../../../assets/landingPage/bodyAlignmentContinueList/img_thumbnail_alignment_necktilt.png";
import img_pelvic_rotation from "../../../assets/landingPage/bodyAlignmentContinueList/img_thumbnail_alignment_pelvicrotation.png";
import img_pelvic_shift from "../../../assets/landingPage/bodyAlignmentContinueList/img_thumbnail_alignment_pelvicshift.png";
import img_shoulder_tilt from "../../../assets/landingPage/bodyAlignmentContinueList/img_thumbnail_alignment_shouldertilt.png";
import img_trunk_extension from "../../../assets/landingPage/bodyAlignmentContinueList/img_thumbnail_alignment_trunkextension.png";
import img_trunk_lateral_flexion from "../../../assets/landingPage/bodyAlignmentContinueList/img_thumbnail_alignment_trunklateralflexion.png";
import useBLE from "../../hooks/useBLE";
import TutorialButton from "../../components/TutorialButton/TutorialButton";

const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const getGUIDEStatus = (cesShowMode) => {
  return {
    login: {
      text: cesShowMode ? $L("Smart life solution for healthy aging") : $L("<b>Log in</b> to check your health data and get help from <b>health care professionals</b>."),
      btn: $L("Log In"),
      target: { name: '' }
    },
    goal: {
      text: $L("Test your fitness level and <br/>set <b>personalized goals</b>!"),
      btn: $L("Set goals now"),
      target: { name: Config.panel_names.FITNESS_GOAL_SETTING }
    },
    play: {
      text: $L(
        "Reach <br/>this month's<b> goals</b> by working out consistently!"
      ),
      btn: $L("Resume the workout"),
      target: { name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { type: 'activity', subType: 'walking', contentId: 1 } }
      /**
       * todo 마지막 운동이 무엇인지, 그 운동을 완료했는지 진행 중이었는지에 대한 조건 필요
       * target: condition ? Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN : Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_START
       */
    }
  };
};

const cesConsentContent = $L(`The LG Intelli-FiT Exhibition (“LG Intelli-FiT”) provided by LG Electronics system will collect information about your body characteristics via cameras and pressure-sensing floor mats. LG Intelli-FiT analyzes the information collected to assess physical characteristics, including body alignment and body balance.
            <br><br>
            You can choose to have LG Intelli-FiT provide you with feedback and workout recommendations based on the analysis. This information, along with summaries of the measurements made, will be presented on the TV screen and will be viewable by anyone in the booth. You can choose to download the information to your mobile device via a QR code on the screen, but only while you are in the booth. Some participants may be able to receive feedback via voice within the booth
            <br><br>
            LG Intelli-FiT will store images locally. Abstract silhouettes derived from the images will be transferred to a cloud server along with feedback and workout recommendations so that you may download them via a QR code to your mobile device.
            <span>After you have downloaded the reports and returned to the application’s home screen on the TV screen, the data stored locally and on the cloud server will all be deleted.</span>
            Neither you nor LG Electronics will be able to access the information.  And apart from the temporary collection of images, LG Intelli-FiT does not collect information that identifies you.
            <br><br>
            <span>LG INTELLI-FIT IS FOR CONSUMER EDUCATIONAL USE ONLY. LG INTELLI-FIT’S RECOMMENDATIONS AND FEEDBACK SHOULD NOT BE CONSIDERED, OR USED AS A SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE OR TREATMENT.</span>
            <br><br>
            <span>WHILE LG INTELLI-FIT PROVIDES INFORMATION, WE CANNOT AND DO NOT DIAGNOSE YOUR HEALTH CONDITIONS.</span>
            <br><br>
            <span>DON’T USE LG INTELLI-FIT AS A SUBSTITUTE FOR CONSULTING WITH YOUR PHYSICIAN OR OTHER HEALTHCARE PROVIDER.</span>
            <br><br>
            If you rely on information provided to you via LG Intelli-FiT, you do so solely at your own risk. We are not responsible for the accuracy, reliability, availability, effectiveness, or correct use of information you receive through LG Intelli-FiT. Use of LG Intelli-FiT should not replace your good judgment and common sense.
            <br><br>
            By pressing [AGREE], you acknowledge that you have read and agreed to this Notice and Consent.`)

let GUIDE = null;

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);
const SpottableComponent = Spottable("div");

const PAGENATE = [
  "landing_firstpage",
  "landing_secondpage",
  "landing_thirdpage",
  "landing_fourthpage",
  "landing_fifthpage",
  "landing_sixthpage",
];

// todo remove
const cesPAGENATE = [
  "landing_firstpage",
];

const LandingPage = ({ isOnTop, ...rest }) => {
  const dispatch = useDispatch();
  useDebugKey({isLandingPage: true});
  const { gestureRelease } = useGesture();
  const { cameraList } = useSelector(state => state.camera);
  const { contentInfos } = useSelector((state) => state.content);
  const [ispopup, setIspopup] = useState(false);
  const { userNumber, profileNick } = useSelector((state) => state.common.appStatus);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const {bleConnectStatusRef} = useBLE({autoConnect: cesShowMode && isOnTop});
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);
  const [showSmartMatPopup, setShowSmartMatPopup] = useState(false);
  const [cestarget, setcestarget] = useState(null);

  if(!GUIDE){
    GUIDE = getGUIDEStatus(cesShowMode);
  }

  const { selectContentList: physicalTestContentList } = useContent({
    type: "recommend",
    selectList: [
      "physicalTest_LOWER_BODY_FLEX_Knee_Flexion_2_2",
      "physicalTest_STATIC_BALANCE_Head_Shift_1_6",
      "physicalTest_LOWER_BODY_STR_Pelvic_Shift_5_4",
      "physicalTest_LOWER_BODY_STR_Leg_Alignment_1_10",
      "physicalTest_STATIC_BALANCE_Pelvic_Tilt_5_9",
      "physicalTest_STATIC_BALANCE_Neck_Tilt_1_2",
      "physicalTest_STATIC_BALANCE_Pelvic_Rotation_4_5"
    ],
    mode: 'landing'
  });
  const { contentList: bodyAlignmentContentList } = useContent({
    type: "recommend",
    subType: "bodyAlignment",
  });
  const { contentList: romContentList } = useContent({
    type: "recommend",
    subType: "rom",
  });
  const { contentList: activityContentList } = useContent({
    type: "activity",
    subType: "walking",
  });
  const [hikingList, setHikingList] = useState([]);
  const [showCesTermsAgree, setShowCesTermsAgree] = useState(null);

  const continueListBodyAlignment = useMemo(() => {
    const CES_DUMMY_INFO = [
      {
        type: "rom",
        subType: "NECK",
        contentId: "ROM_NECK_NeckExtension",
        thumbnail: img_neck_extension,
      },
      {
        type: "bodyAlignment",
        subType: "NECK_TILT",
        contentId: "bodyAlignment_NECK_TILT",
        thumbnail: img_neck_tilt,
      },
      {
        type: "bodyAlignment",
        subType: "PELVIC_ROTATION",
        contentId: "bodyAlignment_PELVIC_ROTATION",
        thumbnail: img_pelvic_rotation,
      },
      {
        type: "bodyAlignment",
        subType: "PELVIC_SHIFT",
        contentId: "bodyAlignment_PELVIC_SHIFT_ANTERIOR",
        thumbnail: img_pelvic_shift,
      },
      {
        type: "bodyAlignment",
        subType: "SHOULDER_TILT",
        contentId: "bodyAlignment_SHOULDER_TILT",
        thumbnail: img_shoulder_tilt,
      },
      {
        type: "rom",
        subType: "TRUNK",
        contentId: "ROM_TRUNK_TrunkExtension",
        thumbnail: img_trunk_extension,
      },
      {
        type: "rom",
        subType: "TRUNK",
        contentId: "ROM_TRUNK_TrunkLateralFlexion",
        thumbnail: img_trunk_lateral_flexion,
      },
    ];

    const ret = [];
    CES_DUMMY_INFO.map((info) => {
      const baseList =
        info.type === "bodyAlignment"
          ? bodyAlignmentContentList
          : romContentList;
      const content = baseList[info.subType]?.find(
        (item) => item.contentId === info.contentId
      );

      content && ret.push({ ...content, thumbnailUrl: info.thumbnail });
    });
    return ret;
  }, [bodyAlignmentContentList, romContentList]);

  const ITEMS = useMemo(() => ([
    // { type: "bodycheckup", target: (cesShowMode ? { name: Config.panel_names.BODY_ALIGNMENT_SCAN} : { name: Config.panel_names.BODY_CHECK_UP_MAIN })},
    { type: "bodycheckup", target: {name: Config.panel_names.BODY_CHECK_UP_MAIN }},
    {
      type: "homecare",
      target: (cesShowMode ?
        { name: Config.panel_names.SELECT_WORKOUT, panelInfo: { enterFrom: 'workout', bodyPart: 'SHOULDER_03' }}
        :
        { name: Config.panel_names.HOMECARE_PROGRAM_MAIN})
    },
    {
      type: "inhomeoutdoor",
      target: (cesShowMode ?
        { name: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER, panelInfo: { type: 'activity', subType: 'hiking', contentId: 10, init: true, preview: true, enterFrom: 'hiking' } }
        :
        { name: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN })
    }
  ]), [cesShowMode]);

  const onClickFunc = useCallback((target) => () => {
    if (cesShowMode) {
      dispatch(sendBroadCast({ type: "cesTermsAgree", moreInfo: { target } }));
      return;
    }
    if (typeof target !== 'object' || !target.name) {
      if (target.name === '') {
        dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'general' } }));
      }
      return;
    }
    if (cesShowMode) {
      if (cameraList.length < 1) {
        setIsConnectionPopupOpen(true);
      } else {
        console.log('onClickFunc1111', cesShowMode, cameraList, bleConnectStatusRef.current, target);
        if(target.name === Config.panel_names.BODY_CHECK_UP_MAIN || target.name === Config.panel_names.SELECT_WORKOUT) {
          dispatch(addPanels(target));
          // if(target.name === Config.panel_names.BODY_ALIGNMENT_SCAN){
          //   dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
          // }
        }
        if(target.name === Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER){
          if(bleConnectStatusRef.current !== "connected"){
            setShowSmartMatPopup(true);
          }else{
            dispatch(addPanels(target));
          }
        }
      }
    } else {
      if (target.name === Config.panel_names.CONTENT_PLAYER_START && target.panelInfo && target.panelInfo.contentId) {
        dispatch(getContent({ contentId: target.panelInfo.contentId }));
      }
      dispatch(addPanels(target));
    }
  }, [dispatch, showCesTermsAgree, cesShowMode, cameraList, bleConnectStatusRef]);

  const handleCancel = useCallback(() => {
    if (!isOnTop) {
      return;
    }
    if (typeof window === "object") {
      setIspopup(true);
    }
  }, [isOnTop]);

  const onClickPopUpBtn = useCallback((index) => {
    if (index === 0) {
      Spotlight.focus("landingGuideBtn")
    } else {
      if(typeof window === "object"){
        if (window.PalmSystem) {
          gestureRelease();
          window.close();
        } else if (typeof window === "object") {
          window.location.reload();
        }
      }
    }
    setIspopup(false);
  },[]);

  const guideText = useMemo(() => {
    const goal = true; // todo 운동목표 설정 데이터 추가
    if (!userNumber) {
      return GUIDE.login;
    } else {
      if (goal) {
        return GUIDE.play;
      } else {
        return GUIDE.goal;
      }
    }
  }, [userNumber]);

  const onItemClick = useCallback(({ itemInfo }) => {
    if (itemInfo) {
      dispatch(getContent({ contentId: itemInfo.contentId })); // focus? click?
    }
    dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { type: itemInfo?.type, subType: itemInfo?.subType, contentId: itemInfo?.contentId } }));
  }, []);

  const onPageChanged = useCallback((pageIndex) => {
    let node = document.querySelector(`[data-spotlight-id="landingPage"]`);
    if (node) {
      if(pageIndex > 0){
        node.style.setProperty('background-color', '#D4C8BD', 'important');
      }else{
        node.style.setProperty('background-color', "#EFE7DF", 'important');
      }
    }
  }, []);

  useEffect(() => {
      setHikingList([
        {
          contentId: 6,
          title: "마데이라 상공에서 가장 인기 있는 산악 하이킹",
          thumbnailUrl:
            "https://d16h06llvpeyov.cloudfront.net/thumbnails/hiking.jpg",
          playTime: "00:00:22",
          icons: {
            isNew: false,
            isLike: false,
          },
          dataType: "hiking",
          description:
            "마데리아 상공을 날아가는 영상은 청명한 하늘과 푸른 대양이 어우러져 공중에서의 해방을 느끼게 합니다.<br/>마치 구름 사이로 펼쳐진 푸른 산수경을 자유롭게 탐험하는 듯한 기분을 누려보세요.",
          distance: 0.04,
          progressDistance: 0.01,
          trackType: "height",
          calories: "7kcal",
          currentPlayTime: "00:00:05",
        },
      ]);
  }, [contentInfos]);

  return (
    <TPanel
      className={classNames(css.panel, !isOnTop && css.hide)}
      onCancel={handleCancel}
      {...rest}
    >
      <THeader iconType={null} disabled={!isOnTop}/>
      <TBody
        className={css.body}
        spotlightId={SpotlightIds.LANDING_TBODY}
        spotlightDisabled={!isOnTop}
      >
        <TVerticalPagenator
          className={css.tVerticalPagenator}
          disabled={!isOnTop}
          pageSpotIds={cesShowMode ? cesPAGENATE : PAGENATE}
          onPageChanged={onPageChanged}
        >
          {/* first page */}
          <Container className={css.firstPage} spotlightId={PAGENATE[0]}>
            <CustomImage
              src={HERO_BG_IMAGE}
              className={classNames(css.heroBGImage, userNumber && css.hasData)}
            />
            {/* fashionista sprite image */}
            {isOnTop && (
              <SpriteImage
                className={classNames(
                  css.imageContainer,
                  userNumber && css.hasData
                )}
              />
            )}
            <Container className={css.upperContainer}>
              <div className={css.logoLayer}>
                <CustomImage src={LOGO_IMAGE} className={css.logoImage} />
                <div
                  className={css.guideText}
                  dangerouslySetInnerHTML={{
                    __html: guideText.text,
                  }}
                />
                <TButton
                  className={css.btn}
                  color={COLOR.black}
                  size={SIZES.xLarge}
                  onClick={onClickFunc(guideText.target)}
                  spotlightId={"landingGuideBtn"}
                >
                  {guideText.btn}
                </TButton>
              </div>
              {/* Ggraph */}
              {isOnTop && userNumber && <GGraph className={css.graphContainer}/>}
            </Container>
            <Container className={css.mainSectionContainer}>
              {ITEMS.map((item, i) => (
                <MainSection
                  key={item.type}
                  type={item.type}
                  isOnTop={isOnTop}
                  onClick={onClickFunc(item.target)}
                />
              ))}
            </Container>
            {ispopup && (
              <TPopUp
                kind="textPopUp"
                title={$L("Exit LG Intelli-FiT.")}
                text={$L("Do you want to exit LG Intelli-FiT?")}
                button1text={$L("Cancel")}
                button2text={$L("Quit")}
                onClick={onClickPopUpBtn}
              />
            )}
            {!cesShowMode && <div className={css.scrollGuide}>{$L("Scroll down")}</div>}
          </Container>
          {!cesShowMode &&
          <>
          {/* second page */}
          <Container
            className={css.nthPage}
            spotlightId={PAGENATE[1]}
            style={{ paddingBottom: "52px" }}
          >
            <BodyAlignmentList className={css.bodyAlignmentList}/>
            <div className={css.mediaListTitle}>
              {$L("근골격상태분석 운동 이어하기")}
            </div>
            <THorizontalScrollList itemSize={ITEM_SIZE.large}>
              {continueListBodyAlignment &&
                continueListBodyAlignment.length > 0 &&
                continueListBodyAlignment.map((item, index) => (
                  <SMediaItem
                    key={`continueListBodyAlignment_${index}`}
                    className={css.contentList}
                    itemSize={ITEM_SIZE.large}
                    itemInfo={item}
                    onClick={onItemClick}
                    playIcon={true}
                  />
                ))}
            </THorizontalScrollList>
          </Container>
          {/* third page */}
          <Container
            className={css.nthPage}
            spotlightId={PAGENATE[2]}
            style={{ paddingBottom: "80px" }}
          >
            <ContentCollection/>
          </Container>
          {/* fourth page */}
          <Container
            className={css.nthPage}
            spotlightId={PAGENATE[3]}
            style={{ paddingBottom: "32px" }}
          >
            <PhysicalTestList className={css.physicalTestList}/>
            <div className={css.mediaListTitle}>{$L("{nick}님의 신체능력평가 운동 이어하기").replace("{nick}", profileNick)}</div>
            <THorizontalScrollList itemSize={ITEM_SIZE.large}>
              {/* {physicalTestContentList["AEROBIC"] && physicalTestContentList["AEROBIC"].length > 0 &&  physicalTestContentList["AEROBIC"].map((item, index) => */}
              {physicalTestContentList && physicalTestContentList.length > 0 &&  physicalTestContentList.map((item, index) =>
                <SMediaItem
                  key={'physicalTestContentList'+index}
                  className={css.contentList}
                  itemSize={ITEM_SIZE.large}
                  itemInfo={item}
                  onClick={onItemClick}
                  playIcon={true}
                />
              )}
           </THorizontalScrollList>
          </Container>
          {/* fifth page */}
          <Container className={css.nthPage} spotlightId={PAGENATE[4]}>
            <SpottableComponent SpottableComponent className={css.banner} style={{backgroundImage: `url(${BANNER_IMAGE})`}}/>
          </Container>
          {/* sixth page */}
          <Container className={css.nthPage} spotlightId={PAGENATE[5]}>
            <div className={css.mediaListTitle}>
              {$L("Continue the Walking/Jogging")}
            </div>
            <THorizontalScrollList itemSize={ITEM_SIZE.large}>
              {activityContentList[1] && activityContentList[1].length > 0 &&  activityContentList[1].map((item, index) =>
                <SMediaItem
                  key={'activityContentList'+index}
                  className={css.contentList}
                  itemSize={ITEM_SIZE.large}
                  itemInfo={item}
                  onClick={onItemClick}
                  playIcon={true}
                />
              )}
           </THorizontalScrollList>
            <div className={css.mediaListTitle} style={{ paddingTop: "32px" }}>
              {$L("Continue the Hiking")}
            </div>
            <SMediaList
              className={css.contentList}
              itemSize={ITEM_SIZE.large}
              contents={hikingList}
              onItemClick={onItemClick}
              playIcon={true}
            />
          </Container>
          </>}
        </TVerticalPagenator>
        {/* {showCesTermsAgree !== null && <TPopUp
          kind="textPopUp"
          title={$L("Disclaimer")}
          button1text={$L("Cancel")}
          button2text={$L("Agree")}
          onClick={onClickTerms}
          style={{ width: "908px" }}
        />} */}
      <TutorialButton isLandingPage={true}></TutorialButton>
      </TBody>
    </TPanel>
  );
};

export default LandingPage;
