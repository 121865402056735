import { ADAPTER_ADDRESS, ADDRESS, DEFAULT_INTERVAL, convertFeaturesFromFloat } from "./dummyDefault";
/*
보행분석
sequence->value : Signature(1byte), Version(1byte), MessageId(1byte), NRP(2byte), payload(...)
sequence->interval: option
*/
export default {
  "interval": DEFAULT_INTERVAL, //default interval
  "loop": false,
  "common": {
    "adapterAddress": ADAPTER_ADDRESS,
    "address": ADDRESS,
    "returnValue": true,
    "subscribed": false
  },
  "sequence": [
    //Report message
    {"value": [0xA5, 0, 0x0, 0, 0, 0x40, 2, 0, 0x02, 0x01]}, //not ready
    {"value": [0xA5, 0, 0x1, 0, 0, 0x40, 2, 0, 0x02, 0x02]}, //not ready
    {"value": [0xA5, 0, 0x5, 0, 0, 0x40, 2, 0, 0x01, 0x00]}, //ready

    {"value": [0xA5, 0, 0x10, 0, 0, 0x40, 2, 0, 0x03, 0x00], "interval": 1000}, //check start
    {"value": [0xA5, 0, 0x11, 0, 0, 0x40, 2, 0, 0x04, 0x00], "interval": 2000}, //check end
    //COP
    {"value": [0xA5, 0, 0x12, 0, 0, 0x42, 40, 0, ...convertFeaturesFromFloat([],
        [287.97,242.68,293.52,250.04,289.67,233.7,302.84,255.9,279.44,252.35,288.74,260.27,277.87,274.1,303.06,229.31,305.26,263.2,303.5,241.2], 100)], "interval": 1000},
    //PTI 왼발
    {"value": [0xA5, 0, 0x13, 0, 0, 0x43, 10, 0, ...convertFeaturesFromFloat([], [0.351, 0.355, 0.568, 0.519, 1.132], 1000)], "interval": 1000},
    //PTI 오른발
    {"value": [0xA5, 0, 0x14, 0, 0, 0x44, 10, 0, ...convertFeaturesFromFloat([], [0.287, 0.305, 0.704, 0.634, 1.496], 1000)], "interval": 1000},


  ]
}