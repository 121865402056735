import css from './DetailLayout.module.less';
import TGGraph from '../../../../components/TGGraph/TGGraph';

const TGGraphLayout = () => {
  return (
    <div className={css.progressLayout}>
      <div>
        <TGGraph size={300} primaryPercentage={78} />
        <p>single progress</p>
      </div>
      <div>
        <TGGraph size={300} primaryPercentage={78} secondaryPercentage={30} />
        <p>double progress</p>
      </div>
      <div>
        <TGGraph size={300} primaryPercentage={78} secondaryPercentage={30} primaryIncrement={3} />
        <p>double progress</p>
        <p>primary increment: 3</p>
      </div>
      <div>
        <TGGraph size={300} primaryPercentage={78} secondaryPercentage={30} animationInterval={300} />
        <p>double progress</p>
        <p>animation interval : 300ms</p>
      </div>
    </div>
  );
};

export default TGGraphLayout;
