import css from "./MainSection.module.less";
import classNames from "classnames";

import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spritesheet from "react-responsive-spritesheet";

import {$L, convertDateToDaysAgo, convertNewlinesToBr} from '../../../utils/helperMethods';
import * as Config from '../../../utils/Config';
import * as SoundEffect from "../../../utils/SoundEffect";

import bodyCheckUpsprite from "../../../../assets/mainSections/bodyCheckUp/10frame_sec/body-check-up-sprites.png";
import INHOME_BG from "../../../../assets/mainSections/img_category_inhome.png";
import HOMECARE_BG_01 from "../../../../assets/mainSections/img_category_homecare_01.png";
import HOMECARE_BG_02 from "../../../../assets/mainSections/img_category_homecare_02.png";
import HOMECARE_BG_03 from "../../../../assets/mainSections/img_category_homecare_03.png";
import HOMECARE_BG_04 from "../../../../assets/mainSections/img_category_homecare_04.png";

import Spottable from "@enact/spotlight/Spottable";
import { Job } from "@enact/core/util";

const SectionsData = () => ({
    "bodycheckup":
    {
      sprite: {
        image: bodyCheckUpsprite,
        scaleUp: 1.0,
        widthFrame: 380,
        heightFrame: 440,
        displayHeight: 440,//css @BODYCHECKUP_ANISIZE
        steps: 30,
        fps: 10,
      },
    sectionTitle: convertNewlinesToBr($L({ key: "_My Body Analysis", value: "My Body{br}Analysis" })),
    sectionInfo: $L("Understanding My Body")
    },
    "homecare":
    {
      bgImage: [HOMECARE_BG_01, HOMECARE_BG_02, HOMECARE_BG_03, HOMECARE_BG_04],
      sectionTitle: convertNewlinesToBr($L({ key: "_My Workout Program", value: "My Workout{br}Program" })),
      sectionInfo: $L("AI Workout Manager")
    },
    "inhomeoutdoor":
    {
      bgImage: INHOME_BG,
      sectionTitle:convertNewlinesToBr($L({ key: "_Outdoor Activity Indoors", value: "Outdoor Activity{br}Indoors" })),
      sectionInfo: $L("Virtual Training")
    }
});

const SpottableComponent = Spottable('div');
/**
 *
 * @param {type} 'bodycheckup' , 'homecare', 'inhomeoutdoor'
 * @returns
 */
const MainSection = ({type, daysAgo = 0, isOnTop, onClick})=>{
  const spriteRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [focused, setFocused] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [bgImageIndex, setBgImageIndex] = useState(0);
  const { dateList: bodyAlignmentDateList } = useSelector((state) => state.bodyAlignment);
  const { dateList: romDateList } = useSelector((state) => state.rom);
  const { dateList: bodyBalanceDateList } = useSelector((state) => state.bodyBalance);
  const { dateList: physicalTestDateList } = useSelector((state) => state.physicalTest);
  const { dateList: activityWalkingDateList } = useSelector((state) => state.activityWalking);

  const getLatestDate = useCallback((dateLists) => {
    let latestDate = '';

    if (dateLists.length > 0) {
      dateLists.sort();
      latestDate = dateLists[dateLists.length - 1];
    }

    return latestDate;
  }, []);

  const bodyCheckUpLatestDate = useMemo(() => {
    const dateLists = [
      ...bodyAlignmentDateList.slice(-1),
      ...romDateList.slice(-1),
      ...bodyBalanceDateList.slice(-1),
      ...physicalTestDateList.slice(-1)
    ];

    return getLatestDate(dateLists);
  }, [bodyAlignmentDateList, romDateList, bodyBalanceDateList, physicalTestDateList]);

  const activityLatestDate = useMemo(() => {
    const dateLists = [
      ...activityWalkingDateList.slice(-1)
    ];

    return getLatestDate(dateLists);
  }, [activityWalkingDateList]);

  const daysAgoList = useMemo(() => ({
    "bodycheckup": convertDateToDaysAgo(bodyCheckUpLatestDate),
    "homecare": convertDateToDaysAgo(""),
    "inhomeoutdoor": convertDateToDaysAgo(activityLatestDate)
  }), [bodyCheckUpLatestDate, activityLatestDate]);


  const sectionData = useMemo(()=>{
    return SectionsData()[type];
  },[type]);

  const clearPressedJob = useRef(new Job((func) => {
    setPressed(false);
    setTimeout(func, Config.BUTTON_PRESS_DELAY/2);
  }, Config.BUTTON_PRESS_DELAY));

  useEffect(()=>{
    setIsMounted(true);
    return ()=>{
      setIsMounted(false);
    }
  },[]);

  useEffect(()=>{
    if(!isOnTop){
      setBgImageIndex(bgImageIndex+1);
    }
  },[isOnTop]);

  const transNumToDayAgoText = useCallback((daysAgo)=>{
    if (daysAgo === 0) {
      return $L("Today");
    } else if (daysAgo === 1) {
      return $L("{num} Day ago").replace('{num}', daysAgo);;
    } else if (daysAgo > 1){
      return $L("{num} Days ago").replace('{num}', daysAgo);
    } else {
      return null
    }
  },[]);

  const bgImage = useMemo(()=>{
    if(Array.isArray(sectionData.bgImage)){
      return sectionData.bgImage[bgImageIndex%(sectionData.bgImage.length)];
    }
    return sectionData.bgImage;
  },[sectionData, bgImageIndex]);

	const _onClick = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressed(true);
    clearPressedJob.current.start(()=>{
      if(onClick){
        onClick(ev)
      }
    });
	},[onClick]);

  const onFocus = useCallback((ev)=>{
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    setFocused(true);
	},[]);
  const onBlur = useCallback((ev)=>{
    setFocused(false);
    setPressed(false);
    clearPressedJob.current.stop();
	},[sectionData]);


  useEffect(()=>{
    if(spriteRef.current){
      if(focused){
        spriteRef.current.pause();
        spriteRef.current.setDirection("forward");
        spriteRef.current.setStartAt(0);
        spriteRef.current.frame = 0;
        spriteRef.current.init();
        spriteRef.current.play();
      }
      else if(isMounted){
        spriteRef.current.pause();
        spriteRef.current.setStartAt(sectionData.sprite.steps-1);
        spriteRef.current.frame = sectionData.sprite.steps-1;
        spriteRef.current.setDirection("rewind");
        spriteRef.current.init();
        spriteRef.current.play();
      }
    }
  },[focused]);

  return (
    <SpottableComponent
      className={classNames(css.main, css[type])}
      data-webos-voice-intent={isOnTop ? 'Select': null}
      data-webos-voice-label={null}
      spotlightDisabled={!isOnTop}
      disabled={!isOnTop}
      onBlur={onBlur}
      onFocus={onFocus}
      onClick={_onClick}
    >
      {sectionData.bgImage && <img className={css.bgimage} src={bgImage}/>}
      {sectionData.sprite &&
        <div className={css.imageContainer}>
          <Spritesheet
            className={css.spriteCommon}
            ref={spriteRef}
            image={sectionData.sprite.image}
            widthFrame={sectionData.sprite.widthFrame}
            heightFrame={sectionData.sprite.heightFrame}
            steps={sectionData.sprite.steps}
            fps={sectionData.sprite.fps}
            autoplay={false}
          />
        </div>
      }
      <div className={css.sectionText}>
        {sectionData.sectionTitle}
      </div>
      {focused && daysAgoList[type] !== null && <div className={css.dayText}>{transNumToDayAgoText(daysAgoList[type])}</div>}
      {pressed && <div className={css.pressed}></div>}
      <div className={css.sectionInfo}>
        {sectionData.sectionInfo}
      </div>
    </SpottableComponent>
  );
}
export default MainSection;