import { useMemo } from "react";
import { $L } from "../utils/helperMethods";

const useBpm = ({ bpm = 0, age = 65 }) => {
  const rangeOfHeartRate = useMemo(() => {
    const maxHeartRate = 200 - Number(age);
    const warnHeartRate = maxHeartRate * 0.85;

    let status;
    let statusKey;
    if (bpm > maxHeartRate * 0.7) {
      status = $L("Exercise intensity: High");
      statusKey = "HIGH";
    } else if (bpm > maxHeartRate * 0.5) {
      status = $L("Exercise intensity: Moderate");
      statusKey = "MEDIUM";
    } else {
      status = $L("Exercise intensity: Low");
      statusKey = "LOW";
    }

    return { maxHeartRate, warnHeartRate, status, statusKey };
  }, [age, bpm]);

  const warningText = useMemo(() => {
    const maxText =
      bpm > rangeOfHeartRate.maxHeartRate
        ? $L("연령별 최대심박수보다 높아요")
        : null;
    const warnText =
      bpm > rangeOfHeartRate.warnHeartRate
        ? $L("고강도 권장범위 심박수보다 높아요")
        : null;
    return { maxText, warnText };
  }, [rangeOfHeartRate, bpm]);

  return {
    bpmStatus: rangeOfHeartRate.status,
    bpmStatusKey: rangeOfHeartRate.statusKey,
    bpmWarningText: warningText,
  };
};

export default useBpm;
