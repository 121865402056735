import css from "./TChart.module.less";
import classNames from "classnames";

const TChart = ({ chartList = [], className, ...rest }) => {
  return (
    <div className={classNames(css.chartContainer, className)}>
      {chartList?.map((graphItem, idx) => {
        return (
          <div className={classNames(css.graphContainer)} key={idx}>
            <div className={classNames(css.average)} style={{ height: `${graphItem?.average}%` }}/>
            <div className={classNames(css.mySelf)} style={{ height: `${graphItem?.mySelf}%` }}/>
          </div>
        );
      })}
    </div>
  );
};

export default TChart;
