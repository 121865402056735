import { useCallback, useState } from 'react';
import TInput from '../../../../components/TInput/TInput';
import { $L } from '../../../../utils/helperMethods';

const TInputLayout = () => {
  const [value, setValue] = useState('')
  const _onChange = useCallback((e) => {
    setValue(e.value)
  },[])

  return (
    <TInput
      value={value}
      onChange={_onChange}
      placeholder={$L('Enter your e-mail address')} 
    />
  );
};

export default TInputLayout;
