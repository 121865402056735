import React, { useState } from "react";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader/THeader";
import TBody from "../../components/TBody/TBody";
import VRPlayer, { HANDLER_MODES, MAP_TO_MESH_URLS, MODES, STREO_MODES } from "../../components/VRPlayer/VRPlayer";
import RadioItem from "@enact/sandstone/RadioItem";
import TButton from "../../components/TButton/TButton";
import TScroller from "../../components/TScroller";
import css from './VRPlayerPanel.module.less';
import RangePicker from "@enact/sandstone/RangePicker";

const videoUrl = {
  [MODES.vr]: (typeof window === 'object' && window.PalmSystem) ?
    'https://d16h06llvpeyov.cloudfront.net/360VR/Rakuten_Drive/1208_58442944_360/1208_58442944_360.mpd' :
    'https://d16h06llvpeyov.cloudfront.net/origins/360_sample.mp4',
  [MODES.time]: 'https://d16h06llvpeyov.cloudfront.net/%5BHiking%5D/hiking_gesture_lg_edit/%EC%A0%9C%EC%8A%A4%EC%B2%98+HD_edit_merge.mpd'
}

const VRPlayerPanel = ({ ...rest }) => {
  const [handlerMode, setHandlerMode] = useState(HANDLER_MODES.auto);
  const [useWideAngle, setUseWideAngle] = useState(false);
  const [stereoMode, setStereoMode] = useState(STREO_MODES.monoscopic);
  const [mapToMeshUrl, setMapToMeshUrl] = useState(MAP_TO_MESH_URLS.equirectangular);
  const [interval, setInterval] = useState(20);
  const [delta, setDelta] = useState(0.5);
  const [xMaxAngle, setXMaxAngle] = useState(68);
  const [yMaxAngle, setYMaxAngle] = useState(12.5);
  const mode = MODES.vr;

  return (
    <TPanel {...rest}>
      <THeader title={"VR Player Test"} />
      <TBody>
        <div className={css.container}>
          <div className={css.videoContainer}>
            <VRPlayer
              src={videoUrl[mode]}
              mode={mode}
              handlerMode={handlerMode}
              useWideAngle={useWideAngle}
              stereoMode={stereoMode}
              mapToMeshUrl={mapToMeshUrl}
              interval={interval}
              delta={delta}
              xMaxAngle={xMaxAngle}
              yMaxAngle={yMaxAngle}
            />
            <div className={css.box}>
              <div className={css.title}>Interval: {interval}</div>
              <RangePicker max={100} min={10} step={5} width="small" className={css.switch} value={interval} onChange={({ value }) => setInterval(value)} />
            </div>
            <div className={css.box}>
              <div className={css.title}>Delta: {delta}</div>
              <RangePicker max={2} min={0.1} step={0.1} width="small" className={css.switch} value={delta} onChange={({ value }) => setDelta(Number(value.toFixed(1)))} />
            </div>
          </div>
          <TScroller className={css.optionContainer}>
            <div className={css.box}>
              <div className={css.title}>X max angle: 0° ~ {Math.round(360 * (xMaxAngle / 100))}°</div>
              <RangePicker max={100} min={0} step={1} width="small" className={css.switch} value={xMaxAngle} onChange={({ value }) => setXMaxAngle(value)} />
            </div>
            <div className={css.box}>
              <div className={css.title}>Y max angle: -{Math.round(360 * (yMaxAngle / 100))}° ~ {Math.round(360 * (yMaxAngle / 100))}°</div>
              <RangePicker max={50} min={0} step={0.5} width="small" className={css.switch} value={yMaxAngle} onChange={({ value }) => setYMaxAngle(Number(value.toFixed(1)))} />
            </div>
            <div className={css.box}>
              <div className={css.title}>handlerMode: {handlerMode}</div>
              <RadioItem selected={handlerMode === HANDLER_MODES.auto} onClick={() => setHandlerMode(HANDLER_MODES.auto)}>{HANDLER_MODES.auto}</RadioItem>
              <RadioItem selected={handlerMode === HANDLER_MODES.click} onClick={() => setHandlerMode(HANDLER_MODES.click)}>{HANDLER_MODES.click}</RadioItem>
            </div>
            <div className={css.box}>
              <div className={css.title}>useWideAngle: {useWideAngle ? 'TRUE' : 'FALSE'}</div>
              <TButton onClick={() => setUseWideAngle(!useWideAngle)}>{useWideAngle ? 'FALSE' : 'TRUE'}</TButton>
            </div>
            <div className={css.box}>
              <div className={css.title}>stereoMode: {stereoMode}</div>
              <RadioItem selected={stereoMode === STREO_MODES.monoscopic} onClick={() => setStereoMode(STREO_MODES.monoscopic)}>{STREO_MODES.monoscopic}</RadioItem>
              <RadioItem selected={stereoMode === STREO_MODES.stereoscopicLeftRight} onClick={() => setStereoMode(STREO_MODES.stereoscopicLeftRight)}>{STREO_MODES.stereoscopicLeftRight}</RadioItem>
              <RadioItem selected={stereoMode === STREO_MODES.stereoscopicTopBottom} onClick={() => setStereoMode(STREO_MODES.stereoscopicTopBottom)}>{STREO_MODES.stereoscopicTopBottom}</RadioItem>
            </div>
            <div className={css.box}>
              <div className={css.title}>mapToMeshUrl: {mapToMeshUrl}</div>
              <RadioItem selected={mapToMeshUrl === MAP_TO_MESH_URLS.equirectangular} onClick={() => setMapToMeshUrl(MAP_TO_MESH_URLS.equirectangular)}>{MAP_TO_MESH_URLS.equirectangular}</RadioItem>
              <RadioItem selected={mapToMeshUrl === MAP_TO_MESH_URLS.rectangular} onClick={() => setMapToMeshUrl(MAP_TO_MESH_URLS.rectangular)}>{MAP_TO_MESH_URLS.rectangular}</RadioItem>
            </div>
          </TScroller>
        </div>
      </TBody>
    </TPanel>
  );
};

export default VRPlayerPanel;
