import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Spotlight from "@enact/spotlight";
import css from "./BodyBalanceReport.module.less";
import * as Config from "../../../../utils/Config";
import * as HelperMethods from "../../../../utils/helperMethods";
import { $L } from "../../../../utils/helperMethods";
import {
  addPanels,
  popPanel,
  updatePanel,
} from "../../../../features/panels/panelsSlice";
import { bodyBalanceMenu, getBodyBalanceMenuTitle } from "./Constants";
import TSimpleButtonTab, {
  LIST_TYPE,
} from "../../../../components/TSimpleButtonTab/TSimpleButtonTab";
import { getBBT_INFO } from "../BodyBalanceTest/Constants";
import * as TTSService from "../../../../lunaSend/TTSService";

//컴포넌트
import TPanel from "../../../../components/TPanel/TPanel";
import THeader from "../../../../components/THeader/THeader";
import TBody from "../../../../components/TBody/TBody";
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import ReportTop from "../../../../components/ReportTop/ReportTop";
import Overall from "./TabContents/Overall";
import FootType from "./TabContents/FootType";
import BodyBalanceTab from "./TabContents/BodyBalanceTab";
import CoP from "./TabContents/CoP";
import WalkingPath from "./TabContents/WalkingPath";
import WalkInPlace from "./TabContents/WalkInPlace";
import SmartMatPopup from "../../../SmartMat/SmartMatPopup";
import TestSelectPopup from "../../../../components/TestSelectPopup/TestSelectPopup";
import TextPopup from "../../../../components/TextPopup/TextPopup";
import useBLE from "../../../../hooks/useBLE";
import { convertGrades } from "./feedback/convertGrades";
import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
} from "../../../../utils/Constants";
import { BODY_CHECKUP_TYPE } from "../../../../utils/Constants";
import { getBodyCheckUpData, getContent } from "../../../../features/fitService/fitServiceSlice";
import {
  changeAppStatus,
  changeVoiceGuideStatus,
} from "../../../../features/common/commonSlice";
import classNames from "classnames";

// cesShowMode
import { BBT_TYPE } from '../../BodyBalance/BodyBalanceTest/Constants';
import * as SoundEffect from "../../../../utils/SoundEffect";
import TPopUp from '../../../../components/TPopUp/TPopUp';

let BBT_INFO = null;
/**
 * @module BodyBalanceReport
 * @returns view Details Button 클릭시 tab layout
 */
const BodyBalanceReport = ({ panelInfo, ...rest }) => {
  if(!BBT_INFO){
    BBT_INFO = getBBT_INFO();
  }
  const dispatch = useDispatch();
  const {bleConnectStatusRef} = useBLE({autoConnect: true});
  const [isText, setText] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [textDisabled, setTextDisabled] = useState(false);
  const [showTestSelectPopup, setShowTestSelectPopup] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    panelInfo.selectedTabIndex ? panelInfo.selectedTabIndex : 0
  );
  const { overall, subTab } = useSelector(
    (state) => state.common.appStatus.voiceGuideStatus[BODY_CHECKUP_TYPE.BODY_BALANCE]
  );
  const { testResults, dateList } = useSelector((state) => state.bodyBalance);
  const [selectedDate, setSelectedDate] = useState(
    panelInfo.selectedDate ? panelInfo.selectedDate : null
  );
  // cesShowMode
	const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const initialCheckedState = Array(BBT_INFO.length).fill(true);
  const [showSmartMatPopup, setShowSmartMatPopup] = useState(false);

  const titleArray = getBodyBalanceMenuTitle();

  const onItemClick = useCallback(
    ({ index }) => {
      setSelectedTabIndex(index);
      setText(false);
    },
    [dispatch]
  );

  const onSelectedDate = useCallback(
    ({ selectedDate: date }) => {
      console.log("selectedDate", date);
      //todo read data...from reducer
      const strDate = HelperMethods.convertDateToString2(date);
      if (strDate !== selectedDate) {
        setSelectedDate(strDate);
        setSelectedTabIndex(0);
        dispatch(getBodyCheckUpData({type: BODY_CHECKUP_TYPE.BODY_BALANCE, date:strDate, syncFlag:"immediately"}));
        dispatch(
          updatePanel({
            name: Config.panel_names.BODY_BALANCE_REPORT,
            panelInfo: { selectedDate: strDate },
          })
        );
      }
    },
    [selectedDate]
  );

  const currentData = useMemo(() => {
    if (selectedDate) {
      console.log(" current data ", testResults[selectedDate]);
      return testResults[selectedDate];
    }
    return {};
  }, [selectedDate, testResults]);

  const hasData = useMemo(() => {
    const footType =
      currentData?.twolegstance?.rawData &&
      currentData?.twolegstance?.value_archIndex_L &&
      currentData?.twolegstance?.value_archIndex_R;
    const bodyBalanceTab =
      currentData?.twolegstance?.rawData &&
      currentData?.twolegstance?.value_balanceRatio_LR &&
      currentData?.twolegstance?.value_balanceRatio_FFRF;
    const CoP =
      currentData?.onelegstanceR?.rawData &&
      currentData?.onelegstanceR?.value_leftBalance &&
      currentData?.onelegstanceR?.value_rightBalance;
    const overall = footType || bodyBalanceTab || CoP;

    return { overall, footType, bodyBalanceTab, CoP };
  }, [currentData]);

  const menuList = useMemo(() => {
    const res = convertGrades(currentData);
    const tabMenu = [
      {
        menuType: bodyBalanceMenu.OVERALL,
        title: titleArray[bodyBalanceMenu.OVERALL],
        sortIndex: 0,
      },
      {
        menuType: bodyBalanceMenu.FOOT_TYPE,
        title: titleArray[bodyBalanceMenu.FOOT_TYPE],
        grade: hasData.footType && res.footType,
        sortIndex: 1,
      },
      {
        menuType: bodyBalanceMenu.BODY_BALANCE_TAB,
        title: titleArray[bodyBalanceMenu.BODY_BALANCE_TAB],
        grade: hasData.bodyBalanceTab && res.bodyBalanceTab,
        sortIndex: 2,
      },
      {
        menuType: bodyBalanceMenu.COP,
        title: titleArray[bodyBalanceMenu.COP],
        grade: hasData.CoP && res.cop,
        sortIndex: 3,
      },
      {
        menuType: bodyBalanceMenu.WALKING_PATH,
        title: titleArray[bodyBalanceMenu.WALKING_PATH],
        grade: null,
        sortIndex: 4,
      },
      {
        menuType: bodyBalanceMenu.WALK_IN_PLACE,
        title: titleArray[bodyBalanceMenu.WALK_IN_PLACE],
        grade: null,
        sortIndex: 5,
      },
    ];
    // if (...){
    //   tabMenu.push({menuType: ..})
    // }
    // return tabMenu;

    // grade를 기준으로 정렬
    const gradeTabMenuList = [
      tabMenu[0],
      ...tabMenu.slice(1).sort((a, b) => {
        const gradeOrder = {
          [SCORE_RED_KEY]: 3,
          [SCORE_YELLOW_KEY]: 2,
          [SCORE_MINT_KEY]: 1,
          undefined: 0,
          null: -1,
        };
        const gradeA = gradeOrder[a.grade] || 0;
        const gradeB = gradeOrder[b.grade] || 0;
        if (gradeA === gradeB) {
          // 같은 값일 경우, sortIndex를 참조
          return b.sortIndex < a.sortIndex ? 1 : -1;
        }
        return gradeB - gradeA;
      }),
    ];

    const scoreTabMenuTypes = gradeTabMenuList.map((item) => item.title);
    const menuTypeList = gradeTabMenuList.map((item) => item.menuType);
    return {
      titleList: scoreTabMenuTypes,
      menuTypeList,
      tabMenu: gradeTabMenuList,
    };
  }, [currentData, hasData]);

  const scoreArray = useMemo(() => {
    if (!cesShowMode) {
      return menuList.tabMenu.map((item) => item.grade);
    }
  }, [cesShowMode, menuList]);

  const renderTabContent = useCallback(() => {
    switch (menuList.menuTypeList[selectedTabIndex]) {
      case bodyBalanceMenu.OVERALL:
        return (
          <Overall
            date={selectedDate}
            currentData={currentData}
            openMenuTestPopup={checkConnectionMat}
            setFeedback={setFeedback}
            setTextDisabled={setTextDisabled}
            hasData={hasData}
          />
        );
      case bodyBalanceMenu.FOOT_TYPE:
        return (
          <FootType
            date={selectedDate}
            currentData={currentData}
            openMenuTestPopup={checkConnectionMat}
            feedback={feedback}
            setFeedback={setFeedback}
            setTextDisabled={setTextDisabled}
            isEmpty={!hasData.footType}
          />
        );
      case bodyBalanceMenu.BODY_BALANCE_TAB:
        return (
          <BodyBalanceTab
            date={selectedDate}
            currentData={currentData}
            openMenuTestPopup={checkConnectionMat}
            feedback={feedback}
            setFeedback={setFeedback}
            setTextDisabled={setTextDisabled}
            isEmpty={!hasData.bodyBalanceTab}
          />
        );
      case bodyBalanceMenu.COP:
        return (
          <CoP
            date={selectedDate}
            currentData={currentData}
            openMenuTestPopup={checkConnectionMat}
            feedback={feedback}
            setFeedback={setFeedback}
            setTextDisabled={setTextDisabled}
            isEmpty={!hasData.CoP}
          />
        );
      case bodyBalanceMenu.WALKING_PATH:
        return (
          <WalkingPath
            date={selectedDate}
            openMenuTestPopup={checkConnectionMat}
          />
        );
      case bodyBalanceMenu.WALK_IN_PLACE:
        return (
          <WalkInPlace
            date={selectedDate}
            openMenuTestPopup={checkConnectionMat}
          />
        );
      default:
        return null;
    }
  }, [
    menuList,
    selectedTabIndex,
    currentData,
    selectedDate,
    feedback,
    hasData,
  ]);

  const BODYBALANCE_TEST_ACTIONS = {
    [bodyBalanceMenu.OVERALL]: () => openTestSelectPopup(true),
    [bodyBalanceMenu.FOOT_TYPE]: 0,
    [bodyBalanceMenu.BODY_BALANCE_TAB]: 0,
    [bodyBalanceMenu.COP]: 1,
  };

  const goToBodyBalanceTest = useCallback(() => {
    const selectedMenuType = menuList.menuTypeList[selectedTabIndex];
    const action = BODYBALANCE_TEST_ACTIONS[selectedMenuType];
    console.log("action", action);
    if (typeof action === "function") {
      action();
    } else if (typeof action === "number") {
      dispatch(
        updatePanel({
          name: Config.panel_names.BODY_BALANCE_REPORT,
          panelInfo: { selectedTabIndex: selectedTabIndex },
        })
      );
      dispatch(
        addPanels({
          name: Config.panel_names.BODY_BALANCE_TEST,
          panelInfo: { bbtList: [action] },
        })
      );
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
    } else {
      // setShowTestSelectPopup(true);
      openTestSelectPopup(true);
    }
  }, [menuList, selectedTabIndex]);

  const toggleTestButton = useCallback(() => {
    switch (menuList.menuTypeList[selectedTabIndex]) {
      case bodyBalanceMenu.FOOT_TYPE:
        return hasData.footType;
      case bodyBalanceMenu.BODY_BALANCE_TAB:
        return hasData.bodyBalanceTab;
      case bodyBalanceMenu.COP:
        return hasData.CoP;
      case bodyBalanceMenu.OVERALL:
        return hasData.overall;
      default:
        return null;
    }
  }, [menuList, selectedTabIndex, hasData]);

  const checkConnectionMat = useCallback(() => {
    console.log("checkConnectionMat", bleConnectStatusRef.current);
    if (bleConnectStatusRef.current !== "connected") {
      setShowSmartMatPopup(true);
    } else {
      goToBodyBalanceTest();
    }
  }, [bleConnectStatusRef, selectedTabIndex]);

  const openTestSelectPopup = useCallback(() => {
    setShowTestSelectPopup(true);
  }, []);

  const closeTestSelectPopup = useCallback((ev) => {
    setShowTestSelectPopup(false);
    if (ev.checked) {
      // dispatch(resetPanels());
      dispatch(
        addPanels({
          name: Config.panel_names.BODY_BALANCE_TEST,
          panelInfo: { bbtList: ev.checked },
        })
      );
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
    }
  }, []);

  const goToBodyBalanceDetails = useCallback(() => {
    dispatch(
      updatePanel({
        name: Config.panel_names.BODY_BALANCE_REPORT,
        panelInfo: { selectedTabIndex: selectedTabIndex },
      })
    );
    dispatch(
      addPanels({
        name: Config.panel_names.BODY_BALANCE_DETAILS,
        panelInfo: {
          selectedTabIndex: selectedTabIndex,
          menuType: menuList.menuTypeList[selectedTabIndex],
          menuTitle: menuList.titleList[selectedTabIndex],
          currentData: currentData,
        },
      })
    );
  }, [dispatch, menuList, selectedTabIndex, currentData]);

  const closeSmartMatPopup = useCallback(() => {
    setShowSmartMatPopup(false);
  }, []);

  // cesMode
	const goToCesTest = useCallback(() => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    if (bleConnectStatusRef.current !== "connected") {
      setShowSmartMatPopup(true);
    } else {
      dispatch(addPanels({name : Config.panel_names.BODY_BALANCE_TEST, panelInfo:{bbtList:[BBT_TYPE.TWO_LEG_STANCE]}}));
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
      return;
    }
	},[dispatch]);

  // cesMode
	const goToPlayer = useCallback(() => () => {
    const contentId = 10;
    dispatch(addPanels({ name: Config.panel_names.PREVIEW_PANEL }));
    dispatch(addPanels({ name: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER, panelInfo: { type: 'activity', subType: 'hiking', contentId, init: true, preview: true, enterFrom: 'hiking' } }));
	},[dispatch]);

  // cesMode
  const goToOverallSummary = useCallback(() => {
    dispatch(
      updatePanel({
        name: Config.panel_names.BODY_BALANCE_REPORT,
      })
    );
    dispatch(
      addPanels({
        name: Config.panel_names.BODY_BALANCE_SUMMARY,
        panelInfo: {
          tabInfos: currentData,
        },
      })
    );
  }, [dispatch, menuList, selectedTabIndex, currentData]);

  const tabDisabled = useMemo(() => {
    if (cesShowMode) {
      return [
        titleArray[bodyBalanceMenu.FOOT_TYPE],
        titleArray[bodyBalanceMenu.BODY_BALANCE_TAB],
        titleArray[bodyBalanceMenu.COP],
        titleArray[bodyBalanceMenu.WALKING_PATH],
        titleArray[bodyBalanceMenu.WALK_IN_PLACE]
      ];
    }
    return [
      titleArray[bodyBalanceMenu.WALKING_PATH],
      titleArray[bodyBalanceMenu.WALK_IN_PLACE]
    ];
  }, [cesShowMode]);

  const isEmpty = useMemo(() => {
    return !dateList || dateList?.length <= 0;
  }, [dateList]);

  useEffect(() => {
    if (feedback) {
      if (selectedTabIndex === 0) {
        console.log("BodyBalance feedback text:", feedback?.feedback);
        overall ? TTSService.speak(feedback?.feedbackVoice) : TTSService.stop();
      } else {
        console.log("BodyBalance feedback text: ", feedback?.feedback);
        subTab ? TTSService.speak(feedback?.feedbackVoice) : TTSService.stop();
      }
    }
    return () => {
      TTSService.stop();
    };
  }, [feedback, overall, subTab, selectedTabIndex]);

  useEffect(() => {
    Spotlight.focus("bbtBtn");
    Spotlight.focus("viewDetailsBtn");
  }, [menuList, selectedTabIndex]);

  useEffect(() => {
    if (cesShowMode) {
      dispatch(
        changeVoiceGuideStatus({
          reportType: BODY_CHECKUP_TYPE.BODY_BALANCE,
          tabType: "overall",
          value: false,
        })
      );
    }
  }, [cesShowMode]);

  return (
    <>
      <TPanel {...rest}>
        <THeader title={$L("Body Balance")} />
        {/* tab 버튼 상단 */}
        <TBody className={css.tbody}>
          <ReportTop
            className={css.top}
            pose={false}
            dateList={
              isEmpty
                ? [HelperMethods.convertDateToString2(new Date())]
                : dateList
            }
            selectedDateStr={selectedDate}
            onSelectedDate={onSelectedDate}
            reportType={BODY_CHECKUP_TYPE.BODY_BALANCE}
            selectedTabIndex={selectedTabIndex}
            isText={isText}
            setText={setText}
            isEmpty={textDisabled}
          />
          {/* tab */}
          <TSimpleButtonTab
            className={css.tab}
            listType={LIST_TYPE.report}
            contents={menuList.titleList}
            type="bodyBalance"
            score={scoreArray}
            disabledItem={tabDisabled}
            selectedIndex={selectedTabIndex}
            maxItemCount={6}
            onItemClick={onItemClick}
          />
          {/* 하단 contents */}
          <div className={css.tabLayout}>
            {renderTabContent()}
            {toggleTestButton() ? (
              menuList.menuTypeList[selectedTabIndex] ===
              bodyBalanceMenu.OVERALL ? (
                (cesShowMode ?
                  <div className={css.testBtnWrap}>
                    <TButton
                      size={SIZES.test}
                      spotlightId="cesSummary"
                      onClick={goToOverallSummary}
                      className={css.firstBtn}
                    >
                      {$L("Summary")}
                    </TButton>
                    <TButton
                      size={SIZES.test}
                      spotlightId="cesTest"
                      onClick={goToCesTest}
                    >
                      {$L("Rescan")}
                    </TButton>
                  </div>
                  :
                  <div className={css.testBtnWrap}>
                    <TButton
                      size={SIZES.test}
                      spotlightId="cesTest"
                      onClick={checkConnectionMat}
                    >
                      {$L("Test")}
                    </TButton>
                  </div>
                )
              ) : (
                <div className={css.testBtnWrap}>
                  <TButton size={SIZES.test} className={css.firstBtn} disabled>
                    {$L("Workout")}
                  </TButton>
                  <TButton
                    size={SIZES.test}
                    spotlightId="viewDetailsBtn"
                    onClick={goToBodyBalanceDetails}
                  >
                    {$L("View Details")}
                  </TButton>
                </div>
              )
            ) : null}
          </div>
          {/* SmartMat Popup */}
          {showSmartMatPopup && <SmartMatPopup onClose={closeSmartMatPopup} />}
          {/* Test select popup */}
          {showTestSelectPopup && (
            <TestSelectPopup
              testInfos={BBT_INFO}
              initialCheckedState={initialCheckedState}
              onClose={closeTestSelectPopup}
            />
          )}
          <div className={classNames(css.notification, cesShowMode && css.ces)}>
            {!cesShowMode ? (
              <div className={css.guideText}>
                {$L(
                  "The foot changes as we age(Normal foot→Flat foot, Two-legged stand balance↓), increasing the importance of examining feet and providing foot care in older adults."
                )}
              </div>
            ) : (
              <div className={css.guideText}>
                {$L(
                  "LG Intelli-FiT recommendations and feedback should not be considered, or used as a substitute for professional medical advice or treatment."
                )}
              </div>
            )}
          </div>
          {!cesShowMode && <div className={css.medicalCenter} />}
        </TBody>
      </TPanel>
      {feedback && (
        <TextPopup
          open={isText}
          onClose={() => setText(false)}
          children={feedback?.feedback}
        />
      )}
    </>
  );
};

export default BodyBalanceReport;
