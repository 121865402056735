import React, { useMemo } from "react";
import css from "./ViewDetails.module.less";
import Wrapper from "../../components/Wrapper/Wrapper";
import { $L, durationText } from "../../../../utils/helperMethods";
import Title, { ICONS, TYPES } from "../../components/Title/Title";
import List from "../../components/List/List";
import { MENUTYPE, MENUTYPE_TEST, getReportFeedback } from "./Constants";
import ListItem from "../../components/ListItem/ListItem";
import { BODY_SCAN_SCORE } from "../../../../utils/Constants";
import { useSelector } from "react-redux";
import useBpm from "../../../../hooks/useBpm";

const age = 65;

const ViewDetails = ({ tabInfos }) => {
  const { cesFeedbackData } = useSelector((state) => state.common.appStatus);
  const { maxCameraBpm } = useSelector((state) => state.camera);
  const { bpmStatusKey } = useBpm({ bpm: maxCameraBpm, age });

  const bpmFeedback = useMemo(() => {
    let intensity;
    switch (bpmStatusKey) {
      case "HIGH":
        intensity = $L("high-intensity");
        break;
      case "MEDIUM":
        intensity = $L("moderate intensity");
        break;
      default:
        intensity = $L("low-intensity");
        break;
    }
    return $L(
      "The exercise you experienced today was a {intensity} exercise for you."
    )
      .replace("{age}", age)
      .replace("{intensity}", intensity);
  }, [bpmStatusKey, age]);

  const filteredTabInfos = useMemo(() => {
    const ret = tabInfos.filter((tabInfo) => {
      if (
        tabInfo.type !== MENUTYPE.OVERALL &&
        tabInfo.type !== MENUTYPE.PELVIC_ROTATION &&
        (tabInfo.score === BODY_SCAN_SCORE.danger ||
          tabInfo.score === BODY_SCAN_SCORE.manage)
      ) {
        return tabInfo;
      }
    });

    return ret;
  }, [tabInfos]);

  const feedback = useMemo(() => {
    const feedbackArr = getReportFeedback();

    const ret = [];
    if (filteredTabInfos.length > 0) {
      filteredTabInfos.map((tabInfo) => {
        const type = tabInfo.type;
        const detailKey = tabInfo.detailType;

        let detailType;
        if (type === MENUTYPE.LEG_ALIGNMENT) {
          if (detailKey === "out") {
            detailType = MENUTYPE_TEST.BOW_LEG;
          } else {
            detailType = MENUTYPE_TEST.KNOCK_KNEE;
          }
        } else if (
          type === MENUTYPE.PELVIC_SHIFT ||
          type === MENUTYPE.KNEE_FLEXION ||
          type === MENUTYPE.HEAD_SHIFT ||
          type === MENUTYPE.TRUNK_SHIFT
        ) {
          if (detailKey === "front") {
            detailType = MENUTYPE_TEST.ANTERIOR;
          } else {
            detailType = MENUTYPE_TEST.POSTERIOR;
          }
        } else {
          detailType = null;
        }

        let feedback;
        if (detailType === null) {
          feedback = feedbackArr[type];
        } else {
          feedback = feedbackArr[type][detailType];
        }

        const randomIndex = Math.floor(
          Math.random() * feedback.LIFESTYLE.length
        );
        ret.push(feedback.LIFESTYLE[randomIndex]);
      });
    } else {
      ret.push(
        $L(
          "Your body is well balanced. Keep your body balance through muscle workout and stretching."
        )
      );
    }

    return { feedback: ret };
  }, [filteredTabInfos]);

  return (
    <Wrapper>
      <div className={css.pointContainer}>
        <Title className={css.title}>
          {$L("Healthy Habits to Improve Your Posture")}
        </Title>
        <List>
          {feedback.feedback.map((item, index) => (
            <ListItem key={"impro_" + index}>{item}</ListItem>
          ))}
        </List>
      </div>
      <div className={css.exerciseContainer}>
        <Title type={TYPES.withIcon} icon={ICONS.check} className={css.title}>
          {$L("Today's Workout Recommendation")}
        </Title>
        <img
          className={css.mediaItem}
          src={cesFeedbackData.VIDEO_THUMBNAIL}
          art=""
        />
        <List>
          <ListItem>
            <strong>{$L("Workout Name")}</strong> :{" "}
            {cesFeedbackData.EXERCISE_NAME}
          </ListItem>
          <ListItem>
            <strong>{$L("Associated Muscles")}</strong> :{" "}
            {cesFeedbackData.ASSOCIATED_MUSCLES}
          </ListItem>
          <ListItem>
            <strong>{$L("Workout Effect")}</strong> :{" "}
            {cesFeedbackData.EXERCISE_EFFECT}
          </ListItem>
          <ListItem>
            <strong>{$L("Workout Time")}</strong> :{" "}
            {durationText(cesFeedbackData.EXERCISE_TIME)}
          </ListItem>
        </List>
      </div>
      <div className={css.bpmContainer}>
        <Title type={TYPES.withIcon} icon={ICONS.check} className={css.title}>
          {$L("Briefing")}
        </Title>
        <div className={css.bpmFeedback}>{bpmFeedback}</div>
      </div>
      <div className={css.guideText}>
        {$L("☞ Set your age for tailored information")}
      </div>
    </Wrapper>
  );
};

export default ViewDetails;
