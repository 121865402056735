import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import Spottable from '@enact/spotlight/Spottable';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import compose from 'ramda/src/compose';
import { Marquee, MarqueeController } from '@enact/sandstone/Marquee';
import css from './TCheckboxItem.module.less';
import TCheckbox from '../TCheckbox';
import { useSelector } from 'react-redux';
/**
 * TCheckboxItem
 * do not set width
 * @module TCheckboxItem
 */

const ContainerComponent = SpotlightContainerDecorator('div');
const SpottableComponent = Spottable('div');

const TCheckboxItemBase = ({className, title, subText, inline=false, children, selected, onToggle, ...rest}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);
	const [focus, setFocus] = useState(false);

	const _onClick = useCallback(() => {
		if(onToggle){
			onToggle({selected: !selected});
		}
	}, [onToggle, selected]);

	const _onFocus = useCallback(() => {
		setFocus(true);
	}, []);

	const _onBlur = useCallback(() => {
		setFocus(false);
	}, []);

	return ( inline ?
    <ContainerComponent
      {...rest}
      className={classNames(
        css.item,
        css.inline,
        themeMode === "dark" && css.isDark,
        focus && css.focus,
        selected && css.selected,
        className
      )}
		onClick={_onClick}
	>
		<TCheckbox
			className={classNames(css.checkbox)}
			selected={selected}
			onToggle={onToggle}
			onFocus={_onFocus}
			onBlur={_onBlur}
		/>
		{!subText &&
			<>
				<Marquee className={css.title} marqueeOn={"focus"}>{title}</Marquee>
				<div className={classNames(css.subText)}>{subText}</div>
				{children}
			</>
		}
		{subText && <div className={css.textContainer}>
			<Marquee className={css.title} marqueeOn={"focus"}>{title}</Marquee>
			{subText &&
				<div className={classNames(css.subText)}>{subText}</div>
			}
		</div>}
	</ContainerComponent>
	:
    <SpottableComponent
      {...rest}
      className={classNames(
        css.item,
        css.block,
        themeMode === "dark" && css.isDark,
        className
      )}
			onClick={_onClick}
		>
			<div
        className={classNames(css.checkbox, selected && css.selected)}
      />
      {!subText &&
				<>
					<Marquee className={css.title} marqueeOn={"focus"}>{title}</Marquee>
					<div className={classNames(css.subText)}>{subText}</div>
					{children}
				</>
			}
			{subText && <div className={css.textContainer}>
	      <Marquee className={css.title} marqueeOn={"focus"}>{title}</Marquee>
	      {subText &&
					<div className={classNames(css.subText)}>{subText}</div>
				}
			</div>}
		</SpottableComponent>
	);
};
// {children && React.cloneElement(children, {className: classNames(css.children, css.childrenSpace), spotlightDisabled: true})}
// {children && React.cloneElement(children, {className: css.children})}
const ItemDecorator = compose(
	MarqueeController({marqueeOnFocus: true})
);
const TCheckboxItem = ItemDecorator(TCheckboxItemBase);
export default TCheckboxItem;