import React, { useCallback, useEffect, useMemo } from "react";
import css from "./TextPopup.module.less";
import TButton, { SIZES } from "../TButton/TButton";
import TScroller from "../TScroller/TScroller";
import Spotlight from "@enact/spotlight";
import SpotlightIds from "../../utils/SpotlightIds";
import Alert from "@enact/sandstone/Alert";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import { $L } from "../../utils/helperMethods";

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element", preserveId: true },
  Spottable("div")
);

const TextPopup = ({ open, onClose, children }) => {
  const _onClick = useCallback(() => {
    if (onClose) {
      Spotlight.focus(SpotlightIds.REPORT_TEXT_BUTTON);
      onClose();
    }
  }, [onClose]);

  const divideNewLine = useMemo(() => {
    if (children?.includes("{br}")) {
      const devideChildArr = children.split("{br}").filter((item) => item !== "");
      const elChild = devideChildArr.map((item) => `<p>${item}</p>`);
      return elChild.join("");
    } else {
      return children;
    }
  }, [children]);

  useEffect(() => {
    open && children && Spotlight.focus("textPopupFirstSpot");
  }, [open, children]);

  return (
    <>
      {open && children ? (
        <Alert
          className={css.container}
          open
          onClose={_onClick}
          type={"overlay"}
        >
          <Container className={css.popup}>
            <TScroller className={css.tScroller}>
              <div
                className={css.text}
                dangerouslySetInnerHTML={{ __html: divideNewLine }}
              />
            </TScroller>
            <TButton
              className={css.button}
              size={SIZES.small}
              onClick={_onClick}
              spotlightId="textPopupFirstSpot"
            >
              {$L("Close")}
            </TButton>
          </Container>
        </Alert>
      ) : null}
    </>
  );
};
export default TextPopup;