import DateFmt from '@enact/i18n/ilib/lib/DateFmt';
import ilib from "ilib/lib/ilib";
import LocaleInfo from "ilib/lib/LocaleInfo";

let _tf, _df;
const li = new LocaleInfo(ilib.getLocale());

export const initIlib = () => {
	let fmtParams = {
		type: 'time',
		timezone: 'local',
		length: 'full',
		date: 'dmwy',
		useNative: false,
	};
	let fdtParams = {
		type: 'date',
		timezone: 'local',
		length: 'medium',
		date: 'dmy',
		useNative: false,
	};

	// if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.locale) {
	// 	fmtParams.locale = window.PalmSystem.locale;
	// 	fdtParams.locale = window.PalmSystem.locale;
	// }

	if (li.locale && li.locale.spec) {
		fmtParams.locale = li.locale.spec;
		fdtParams.locale = li.locale.spec;
	}
	_tf = new DateFmt(fmtParams);
	_df = new DateFmt(fdtParams);
}
export const getTimeFormat = () => {
	return _tf;
}
export const getDateFormat = () => {
	return _df;
}
export const getClockPref = () => {
	if(_tf){
		return _tf.clock;
	}else{
		return '12';
	}
}
export const getTimeOrder = () => {
	if(_tf && _tf.timeComponents){
		let order = _tf.getTemplate().replace(/'.*?'/g, '').match(/([khma])(?!\1)/ig).map(function (s) {
			return s[0].toLowerCase();
		});
		return order;
	}else{
		return ['h','m','a'];
	}
}