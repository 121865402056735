import classnames from "classnames";
import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './Center.module.less';
import { $L } from '../../../utils/helperMethods';
import IC_ROUTINE_STAR from '../../../../assets/HomecareProgram/ic_routine_star.png'
import IC_ROUTINE_STAR_LINE from '../../../../assets/HomecareProgram/ic_routine_star_line.png'
import Spottable from "@enact/spotlight/Spottable";
import TButton, {SIZES, COLOR} from "../../../components/TButton/TButton";

const SpottableComponent = Spottable("div");

const Center = ({
  info,
  onClick,
  onFocus,
  index,
  focusedIndex,
  setFocusedIndex,
}) => {
  const _onClick = useCallback(
    (ev) => {
      if (onClick) {
          onClick(ev);
      }
      ev.persist();
    },
    [onClick]
  );

  const _onFocus = useCallback((ev) => {
    if (onFocus) {
      onFocus(ev);
    }
  }, [onFocus]);

  return (
    <SpottableComponent
      className={css.center}
      // onClick={_onClick}
      onFocus={_onFocus}
      style={focusedIndex === index ? { opacity: "1" } : {}}
    >
      <div className={css.titleContainer}>
        <img className={css.logo} src={info?.logoSmall}/>
        <div className={css.titleBox}>
          <div className={css.titleTags}>
            <div className={css.title}>{info.title}</div>
            <div className={css.tags}>
              {info.tags.map((tag, index) => (
                <div className={css.tag} key={"tag" + index}>{tag}</div>
              ))}
            </div>
          </div>
          <div className={css.features}>
            {info.features.join(' · ')}
          </div>
          <div className={css.fav}>
            {/* {info.like && <>
              <span className={css.like}/>
              $L("즐겨찾는 센터")
            </>} */}
            <span className={css.like}/>
            {$L("즐겨찾는 센터")}
          </div>
          {/* <div className={css.rating}>
            {stars.map((star, index) => (
              <img key={"star" + index} className={css.star} src={star ? IC_ROUTINE_STAR : IC_ROUTINE_STAR_LINE}/>
            ))}
          </div> */}
        </div>
      </div>
      {/* <div className={css.subTitle}>{info.subTitle}</div> */}
      {/* <div className={css.hr} /> */}
      {/* <div className={css.program}>{"BODY TYPE PROGRAM"}</div> */}
      <div className={css.introduction}>
        {info.introduction}
      </div>
      <TButton
        className={css.button}
        size={SIZES.large}
        color={COLOR.brown}
        onClick={_onClick}
        // onFocus={_onFocus}
      >
        {$L("센터 프로그램 보기")}
      </TButton>
    </SpottableComponent>
  )
}

export default Center;