import { ADAPTER_ADDRESS, ADDRESS, DEFAULT_INTERVAL, convertFeaturesFromFloat } from "./dummyDefault";
/*
한발서기
sequence->value : Signature(1byte), Version(1byte), MessageId(1byte), NRP(2byte), payload(...)
sequence->interval: option
*/
export default {
  "interval": DEFAULT_INTERVAL, //default interval
  "loop": false,
  "common": {
    "adapterAddress": ADAPTER_ADDRESS,
    "address": ADDRESS,
    "returnValue": true,
    "subscribed": false
  },
  "sequence": [
    //Report message
    {"value": [0xA5, 0, 0x0, 0, 0, 0x30, 2, 0, 0x02, 0x01]}, //not ready
    {"value": [0xA5, 0, 0x5, 0, 0, 0x30, 2, 0, 0x02, 0x06]}, //not ready
    {"value": [0xA5, 0, 0x6, 0, 0, 0x30, 2, 0, 0x02, 0x07]}, //not ready
    {"value": [0xA5, 0, 0x7, 0, 0, 0x30, 2, 0, 0x02, 0x08]}, //not ready
    {"value": [0xA5, 0, 0x8, 0, 0, 0x30, 2, 0, 0x02, 0x09]}, //not ready
    {"value": [0xA5, 0, 0x8, 0, 0, 0x30, 2, 0, 0x02, 0x0A]}, //not ready
    {"value": [0xA5, 0, 0x9, 0, 0, 0x30, 2, 0, 0x01, 0x00]}, //ready
    //(115,59), (194, 59), (110, 251), (196, 251)
    {"value": [0xA5, 0, 0x10, 0, 0, 0x33, 32, 0, ...convertFeaturesFromFloat(2, [0,0,0,0,0,0,0,0,527, 68, 425, 272, 408, 238, 578, 102], 1)], "interval": 1000}, //left position

    {"value": [0xA5, 0, 0x11, 0, 0, 0x30, 2, 0, 0x03, 0x00], "interval": 1000}, //check start
    {"value": [0xA5, 0, 0x12, 0, 0, 0x30, 2, 0, 0x04, 0x01], "interval": 2000}, //check end 왼발

    //raw data 800byte
    {"value": [0xA5, 0, 0x15, 1, 0, 0x31, 0x90, 0x01,
      ...convertFeaturesFromFloat(1, [4,5,2,2,6,9,10,0,11,19,11,18,10,9,13,4,3,2,10,15,19,0,5,10,18,30,55,25,8,1,0,0,2,0,0,3,3,0,2,0,
        5,5,1,1,7,0,0,7,13,24,78,1107,61,34,21,8,0,4,6,19,0,0,4,10,18,32,56,6,3,0,0,0,0,2,0,0,0,0,0,4,
        0,6,0,0,7,9,0,0,3,29,1180,247,742,1148,30,13,4,7,1,8,0,4,0,17,278,405,1073,33,3,0,6,0,0,0,21,28,13,5,0,3,
        9,8,6,12,9,6,12,6,2,430,135,135,205,504,23,12,2,2,5,0,17,21,14,18,1471,580,69,337,12,0,1,0,2,1,0,0,0,0,1,0,
        6,2,2,0,1,11,0,15,0,53,1134,1798,1442,1019,39,22,13,8,9,10,23,7,2,22,736,354,235,661,119,7,59,4,0,2,0,8,0,0,2,0,
        4,9,0,2,6,5,9,0,25,1018,1617,1975,1751,1303,60,32,7,17,30,12,26,17,34,336,1408,1616,1593,411,63,12,2,0,4,0,0,2,0,28,0,0,
        5,7,13,0,0,4,7,9,113,1314,1464,1571,1592,559,48,16,9,16,6,9,18,24,40,396,1490,1600,1684,1381,59,17,0,23,0,7,1,3,5,0,0,0,
        11,8,5,12,8,7,11,7,59,1213,1294,1255,970,86,25,17,4,15,17,12,36,24,45,64,1134,1356,1489,1444,176,29,9,2,0,37,13,0,13,0,7,0,
        0,11,7,10,2,8,8,12,15,1046,1153,789,178,38,18,9,0,8,1,0,25,17,17,24,703,1155,1399,1303,57,14,16,0,7,11,0,0,1,0,0,5,
        4,8,0,0,0,13,13,14,12,677,1106,595,61,50,13,6,1,6,18,7,22,4,27,35,487,1163,1309,619,46,34,0,0,0,0,0,0,0,0,0,11], 0.1)
     ], "interval": 100},
     {"value": [0xA5, 0, 0x15, 0, 0, /* no type and len */
     ...convertFeaturesFromFloat(1, [1,0,0,0,4,5,11,14,22,271,1083,614,59,28,19,1,0,12,7,3,14,29,35,50,479,1240,1354,78,14,0,0,0,0,0,0,0,0,0,0,0,
      0,0,5,0,6,7,2,14,14,28,1066,870,107,24,23,0,0,1,7,8,11,3,11,59,729,1199,849,16,9,4,0,0,0,0,0,0,0,0,0,0,
      0,0,4,1,5,4,8,5,25,21,1181,1473,635,38,25,12,6,0,1,9,8,9,35,507,1235,1240,50,10,7,11,0,0,0,0,0,0,0,0,0,0,
      7,9,6,8,7,10,9,8,7,21,1328,1838,1282,38,20,8,7,5,8,10,19,31,95,1715,1618,611,26,11,14,0,0,0,0,0,0,0,0,0,0,0,
      3,6,0,4,0,0,9,3,5,19,1561,1937,1450,41,21,7,9,1,9,8,30,34,859,2011,1799,225,28,14,9,14,0,0,0,0,0,0,0,0,0,0,
      0,5,0,3,4,0,2,4,11,18,1121,1880,1179,21,26,13,3,1,3,4,17,41,1520,2066,1774,74,29,9,26,23,0,0,0,0,0,0,0,0,0,0,
      3,0,5,0,6,5,5,3,14,10,45,456,59,15,16,0,0,1,12,0,13,14,277,1344,284,24,11,0,3,2,0,0,0,0,0,0,0,0,0,0,
      6,5,1,0,7,0,12,5,9,5,26,40,23,15,3,10,0,0,15,0,12,5,18,29,30,14,18,0,7,5,0,0,0,0,0,0,0,0,0,0,
      4,3,1,8,9,3,8,0,8,5,14,25,18,11,4,11,0,0,0,13,0,0,10,2,15,0,6,6,4,0,0,0,0,0,0,0,0,0,0,0,
      0,2,6,4,2,5,0,4,2,2,4,19,0,0,2,4,0,0,12,0,0,8,3,31,7,12,0,7,0,0,0,0,0,0,0,0,0,0,0,0], 0.1)
     ], "interval": 100},
    {"value": [0xA5, 0, 0x16, 0, 0, 0x32, 8, 0, ...convertFeaturesFromFloat([2,2,2,2],
        [10.904, 9.514, 4.108, 9.564], 1000)], "interval": 5000}, //feature


  ]
}