import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import css from "./TIcon.module.less";

const ICON_TYPES = {
  none: "none",
  dayActive: "dayActive",
  dayNor: "dayNor",
  calorieActive: "calorieActive",
  calorieNor: "calorieNor",
  timeActive: "timeActive",
  timeNor: "timeNor",
  stepNor: "stepNor",
  stepActive: "stepActive",
  kmNor: "kmNor",
  kmActive: "kmActive",
};

const TIcon = ({
  size = "normal",
  iconType = ICON_TYPES.none,
  className,
  ...rest
}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);

  return (
    <i
      className={classNames(
        css.tIcon,
        css[size],
        css[iconType],
        css.normal,
        themeMode === "dark" && css.isDark
      )}
      {...rest}
    />
  );
};

export default TIcon;
