import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {$L} from '../../../utils/helperMethods';
import TSettingSection from '../../../components/TSettingSection/TSettingSection';
import SmoodShowingAnimation from "../../../components/SmoodShowingAnimation";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { postSetting } from '../../../features/fitService/fitServiceSlice';

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused' }, 'div');

const StepSound = ({...rest}) => {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.fitService.settings);

  const SETTING_INFO = [
    {
      key: 'footPrintYn',
      title: $L("Sound of footsteps"),
      detail: $L("You can turn on or off the sound of your footsteps while walking or jogging."),
      button1: { text: $L("ON"), value: true },
      button2: { text: $L("OFF"), value: false }
    },
    {
      key: 'footPrintRecommendYn',
      title: $L("Sound recommendations"),
      detail: $L("We recommend the sound of footsteps depending on the walking scenario."),
      button1: { text: $L("Auto"), value: true },
      button2: { text: $L("Manual"), value: false }
    },
    {
      key: 'footPrintId',
      title: $L("소리 선택"),
      detail: $L("Select the sound of your footsteps while working out.")
    }
  ];

  const _onClick = useCallback((key) => (value) => {
    dispatch(postSetting({ [key]: value }));
  }, []);

  return (
    <div {...rest}>
      {SETTING_INFO.map((info, idx) => <Container key={idx}>
        <TSettingSection
          type="underLine"
          spotlightId={`StepSound_${info.key}`}
          text={info.title}
          subText={info.detail}
          button1={info.button1}
          button2={info.button2}
          value={settings[info.key]}
          onClick={_onClick(info.key)}
        />
      </Container>)}
    </div>
  );
};

export default SmoodShowingAnimation(StepSound);
