import React, { useEffect, useState, useRef, useCallback } from 'react';



const TDonutChart = ({width = 300, height = 300, lineWidth = 30, data=[], backgroundColor=[]}) => {
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      setCtx(ctx);
    }
  }, []);

  const drawDonutChart = useCallback((ctx, data) => {
     // Clear the canvas
     ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // Calculate the center coordinates and radius
    const centerX = canvasRef.current.width / 2;
    const centerY = canvasRef.current.height / 2;
    const radius = canvasRef.current.width / 2 - lineWidth;
   
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
    ctx.strokeStyle = "#494847"; // Set the color of the stroke
    ctx.stroke();
    // Loop through the data and draw each slice
    let startAngle = 0;
    for (let i = 0; i < data.length; i++) {
      const sliceAngle = data[i] / data.reduce((a, b) => a + b, 0) * 2 * Math.PI;
      const endAngle = startAngle + sliceAngle;
  
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      ctx.strokeStyle = backgroundColor[i]; // Set the color of the stroke
      ctx.stroke();

      startAngle = endAngle;
    }
  }, []);

  useEffect(() => {
    if (ctx) {
      drawDonutChart(ctx, data);
    }
  }, [ctx, data]);

  return (
    <canvas ref={canvasRef} width={width} height={height} />
  );
};

export default TDonutChart;