import { useMemo } from "react";
import { useSelector } from "react-redux";
import * as Config from "../utils/Config";

const useTestResult = () => {
  const recalculatedBodyTestResults = useSelector((state) => state.camera.recalculatedBodyTestResults);
  const testResults = useSelector((state) => state.physicalTest.testResults);
  const bbtResults = useSelector((state) => state.bodyBalance.testResults);
  const {showCustomDummyData} = useSelector((state) => state.common.localSettings);
	const panels = useSelector((state) => state.panels.panels);

  const isBodyScaned = useMemo(() => {
    if((typeof window === 'object' && !window.PalmSystem) && !showCustomDummyData){
      return false;
    }
    if (recalculatedBodyTestResults.side) {
      return true;
    }
    return false;
  }, [recalculatedBodyTestResults, showCustomDummyData]);

  const isSFTResultReady = useMemo(() => {
    if((typeof window === 'object' && !window.PalmSystem) && !showCustomDummyData){
      return false;
    }
    if (!!testResults?.lowerStrength?.["test_id"] && !!testResults?.upperStrength?.["test_id"]) {
      return true;
    }
    return false;
  }, [testResults, showCustomDummyData]);

  const isBBTResultReady = useMemo(() => {
    if((typeof window === 'object' && !window.PalmSystem) && !showCustomDummyData){
      return false;
    }
    if (!!testResults?.footType?.["test_id"] && !!testResults?.bodyBalance?.["test_id"]
      && !!testResults?.CoP?.["test_id"] && !!testResults?.gaitPath?.["test_id"] 
      && !!testResults?.walkInPlace?.["test_id"]) {
      return true;
    }
    return false;
  },[bbtResults, showCustomDummyData]);

  const today = new Date();
  let Tyear = today.getFullYear();
  let Tmonth = ("0" + (today.getMonth() + 1)).slice(-2);
  let Tday = ("0" + today.getDate()).slice(-2);
  let Ttoday = `${Tyear}/${Tmonth}/${Tday}`;

  const headerDate = useMemo(()=>{
    const prevDate = "2022/11/28";
    let ret = Ttoday;
    if(panels && panels.length > 0){
      switch (panels[panels.length - 1].name) {
        case Config.panel_names.PHYSICAL_TEST_SUMMARY:
            ret = prevDate;
          break;
        case Config.panel_names.BODY_ALIGNMENT_REPORT:
          if(!isBodyScaned){
            ret = prevDate;
          }
          break;
        case Config.panel_names.PHYSICAL_TEST_REPORT:
          if(!isSFTResultReady){
            ret = prevDate;
          }
          break;
      }
    }
    return ret;
  },[isBodyScaned, isSFTResultReady, panels]);

  return { isBodyScaned, isSFTResultReady, recalculatedBodyTestResults, headerDate, isBBTResultReady };
};

export default useTestResult;
