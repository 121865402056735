import React, { useMemo, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import css from "./BarItem.module.less";
import { $L } from "../../../../utils/helperMethods";

const BarItem = ({ completedExercise, name, className, ...rest }) => {
  const BARS = ["one", "two", "three"];
  // console.log("completedExercise", completedExercise)

  return (
		<div className={css.barItem}>
      <div className={css.bars}>
        {BARS.map((bar, idx) => (
          <div key={name+'_'+bar} className={css.barContainer}>
            <div className={classNames(css[bar], idx < completedExercise ? css.active : null)}/>
          </div>
        ))}
      </div>
		</div>
	)
}

export default BarItem;