import { useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Config from "../../../../utils/Config";
import {
  $L,
  convertDateToDaysAgo,
  convertNewlinesToBr,
} from "../../../../utils/helperMethods";
import css from "./SftMain.module.less";
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import age from "../../../../../assets/icon/age_Icon.png";
import time from "../../../../../assets/icon/time_Icon.png";
import test from "../../../../../assets/icon/test_Icon.png";
import mat from "../../../../../assets/icon/mat_icon_bg_beige.svg";
import camera from "../../../../../assets/icon/ic_bodycheck_camera.svg";
import { addPanels } from "../../../../features/panels/panelsSlice";
import { changeAppStatus } from "../../../../features/common/commonSlice";
import useUserCheck from "../../../../hooks/useUserCheck";
import TestSelectPopup from "../../../../components/TestSelectPopup/TestSelectPopup";
import { getSFT_TEST_INFO } from "../PhysicalTestCapture/Constants";
import CameraPopup from "../../../Camera/CameraPopup";

let SFT_TEST_INFO = null;
const SftMain = () => {
  if (!SFT_TEST_INFO) {
    SFT_TEST_INFO = getSFT_TEST_INFO();
  }
  console.log( 'SFT_TEST_INFO',  SFT_TEST_INFO);
  const dispatch = useDispatch();
  const { userCheck } = useUserCheck();
  const { cameraList } = useSelector(state => state.camera);
  const { dateList } = useSelector((state) => state.physicalTest);
  const [showTestSelectPopup, setShowTestSelectPopup] = useState(false);
  const initialCheckedState = Array(SFT_TEST_INFO.length).fill(true);
  const CONDITIONS = useMemo(() => {
    return [
      { title: $L("In his/her 60s or older"), img: age },
      { title: "14:00", img: time },
      { title: $L("6개 테스트"), img: test },
      { title: $L("Camera"), img: camera },
      // { title: $L("Smart Mat"), img: mat },
    ];
  }, []);
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);

  // const testStart = useCallback(() => {
  //   if (!userCheck) {
  //     dispatch(
  //       changeAppStatus({ showUserCheckPopup: { show: true, mode: "test" } })
  //     );
  //   } else {
  //     dispatch(addPanels({ name: Config.panel_names.PHYSICAL_TEST_CAPTURE }));
  //   }
  // }, [userCheck]);

  const testReport = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.PHYSICAL_TEST_REPORT }));
  }, []);

  const openTestSelectPopup = useCallback(() => {
    if (!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: "test" }}));
    }else if(cameraList.length < 1){
      setIsConnectionPopupOpen(true);
    }else{
      setShowTestSelectPopup(true);
    }
  }, []);

  const closeTestSelectPopup = useCallback((ev) => {
    setShowTestSelectPopup(false);
    if (ev.checked) {
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
      dispatch(addPanels({name: Config.panel_names.PHYSICAL_TEST_CAPTURE, panelInfo: { sftList: ev.checked}}));
    }
  }, []);

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  const dateAgo = useMemo(() => {
    const date = dateList.length > 0 ? dateList[dateList.length - 1] : null;
    const daysAgo = convertDateToDaysAgo(date);

    let text = "";
    if (daysAgo === 0) {
      text = $L("테스트 결과를 확인해주세요");
    } else if (daysAgo === 1) {
      text = $L("{num} Day ago").replace("{num}", daysAgo);
    } else if (daysAgo > 1) {
      text = $L("{num} Days ago").replace("{num}", daysAgo);
    } else {
      text = $L("테스트를 진행해주세요");
    }
    return { text, date };
  }, [dateList]);

  return (
    <div className={css.tabContent}>
      <div className={css.dateAgo}>{dateAgo.text}</div>
      <div className={css.title}>{$L("SFT(SENIOR FITNESS TEST)")}</div>
      <div className={css.detail}>
        {convertNewlinesToBr(
          $L(
            "미국의 California State Univ.에서 만들어진 시니어 체력 검사로,{br} 일상 생활을 하는데 있어 가장 필요한 체력 요소를 모두 측정해요."
          )
        )}
      </div>
      <div className={css.flexBox}>
        {CONDITIONS.map((condition, index) => {
          return (
            <div className={css.align} key={index}>
              <span style={{ backgroundImage: `url(${condition.img})` }}></span>
              {condition.title}
            </div>
          );
        })}
      </div>
      <div className={css.testBtn}>
        <TButton className={css.button} size={SIZES.test} onClick={openTestSelectPopup}>
          {$L("Start the test")}
        </TButton>
        {dateAgo.date !== null && (
          <TButton
            className={css.button}
            size={SIZES.test}
            onClick={testReport}
          >
            {$L("See test results")}
          </TButton>
        )}
      </div>
      {/* <div className={css.tip}>
        <div>
          <span />
          {$L(
            "테스트 시작 후에는 테스트 중지가 어려워요. 충분한 시간을 확보한 뒤 테스트를 진행해주세요."
          )}
        </div>
        <div>
          <span />
          {$L("If you stop testing, you have to start it all over again.")}
        </div>
      </div> */}
      {showTestSelectPopup && (
        <TestSelectPopup
          testInfos={SFT_TEST_INFO}
          initialCheckedState={initialCheckedState}
          onClose={closeTestSelectPopup}
          physicalTest={true}
        />
      )}
      {/* check camera connect */}
      {isConnectionPopupOpen && <CameraPopup onClose={onCloseCameraPopup}/>}
    </div>
  );
};

export default SftMain;