import * as helperMethods from "../../../../utils/helperMethods";

export default class ShoulderAngleDataParser {
    constructor(test_id) {
        this.test_id = test_id;
        this.timeStamp = [];
        this.originData = [];
        this.value = 0;
    }
    pushData = (value, shape) => {
        if(!this.currentTime){
            this.currentTime = new Date();
        }
        const gap = new Date() - this.currentTime;
        this.timeStamp.push(gap);
        const data = helperMethods.cloneObject(shape);
        data.timeStamp = gap;
        this.originData.push(data);
        this.value = value;
    }
    calculateDistance = (x1, y1, x2, y2) => {
        const deltaX = x2 - x1;
        const deltaY = y2 - y1;
        const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        return Number(distance.toFixed(2));
    }
    //private
    makeDelta = (thresHold) => {
        const ret = {timeStamp:[]};
        let prevIndex = 0;
        let prevTarget = this.originData[0];
        for(let i=1; i<this.timeStamp.length;i++){
            const target = this.originData[i];
            if(!target){
                return;
            }
            if(this.timeStamp[i] - this.timeStamp[prevIndex] <  (thresHold.interval * 1000)){
                continue;
            }
            prevTarget = this.originData[prevIndex];
            prevIndex = i;
            ret.timeStamp.push(this.timeStamp[i]);

            const keys = Object.keys(target);
            let totalMeaningFullChanged = { //set to 1
                mCount: 0,
                mAngleCount: 0,
                mDistanceCount: 0,
                // mCenterDistanceCount: 0,
                mLeftDistanceCount: 0,
                mRightDistanceCount: 0,
            };
            for(let j = 0; j < keys.length; j++){
                if(!(keys[j] === 'shoulder' || keys[j] === 'pelvic')){
                    continue;
                }
                if(prevTarget){
                    let meaningfullChanged = false;
                    //degree
                    const gapDegree = Number((target[keys[j]].degree - prevTarget[keys[j]].degree).toFixed(2));
                    if(!ret[keys[j]]){
                        ret[keys[j]] = {
                            mCount: 0,
                            mAngleCount: 0,
                            mDistanceCount: 0,
                            // mCenterDistanceCount: 0,
                            mLeftDistanceCount: 0,
                            mRightDistanceCount: 0,
                            degree: [0], //for calc
                            distance: {left:[0], center:[0], right: [0]}, //for calc
                        }
                    }
                    ret[keys[j]].degree.push(gapDegree);

                    let thresholdAngleKey = keys[j] === 'shoulder' ? 'shoulderAngle': 'pelvicAngle';

                    if(Math.abs(gapDegree) > thresHold[thresholdAngleKey]){
                        ret[keys[j]].mAngleCount = ret[keys[j]].mAngleCount +1;
                        meaningfullChanged = true;
                        totalMeaningFullChanged.mAngleCount = 1;
                    }

                    let thresholdDistanceKey = keys[j] === 'shoulder' ? 'shoulderDistance': 'pelvicDistance';
                    let distanceOvered = false;
                    ['Right','Left'/*,'Center'*/].forEach((m)=>{
                        const item = m.toLowerCase();
                        let mDistanceKey = 'm'+m+'DistanceCount';
                        const distance = this.calculateDistance(prevTarget[keys[j]].position[item][0],prevTarget[keys[j]].position[item][1],
                            target[keys[j]].position[item][0], target[keys[j]].position[item][1]);
                        ret[keys[j]].distance[item].push(distance);
                        if(Math.abs(distance) > thresHold[thresholdDistanceKey]){
                            ret[keys[j]][mDistanceKey] = ret[keys[j]][mDistanceKey] + 1;
                            distanceOvered = true;
                            totalMeaningFullChanged[mDistanceKey] = 1;
                        }
                    });
                    if(distanceOvered){
                        ret[keys[j]].mDistanceCount = ret[keys[j]].mDistanceCount+1;
                        meaningfullChanged = true;
                        totalMeaningFullChanged.mDistanceCount = 1;
                    }
                    if(meaningfullChanged){
                        ret[keys[j]].mCount = ret[keys[j]].mCount+1;
                        totalMeaningFullChanged.mCount = 1;
                    }
                }
            }
            if(!ret.total){
                ret.total = {
                    mCount: 0,
                    mAngleCount: 0,
                    mDistanceCount: 0,
                    // mCenterDistanceCount: 0,
                    mLeftDistanceCount: 0,
                    mRightDistanceCount: 0,
                };
            }
            const totalkeys = Object.keys(totalMeaningFullChanged);
            for(let k = 0; k < totalkeys.length; k++){
                if(totalMeaningFullChanged[totalkeys[k]]){
                    ret.total[totalkeys[k]] +=1;
                }
            }
        }
        /* remove calc data */
        // delete ret.timeStamp;
        delete ret?.shoulder?.degree;
        delete ret?.pelvic?.degree;
        delete ret?.shoulder?.distance;
        delete ret?.pelvic?.distance;
        return ret;
    }
    /**
     *
     * @param {*} thresHold
     * @returns
     * mCount : or with angle, (left, right, center) distance
     * mDistanceCount : or with left, right, center
     * mAngleCount: angle
     * mCenterDistanceCount: center
     * mLeftDistanceCount: left
     * mRightDistanceCount: right
     */
    getResult = (thresHold) => {
        if (typeof window === 'object' && !window.PalmSystem) {
            console.log('ShoulderAngleDataParser dummy getResult ');
            this.timeStamp = DummyTimeStamp;
            for(let i=0; i<DummyTimeStamp.length; i++){
                const data = helperMethods.cloneObject(DummyOrigin[i]);
                data.timeStamp = DummyTimeStamp[i];
                if(this.test_id === '8'){
                    delete data.pelvic;
                }
                this.originData.push(data);
            }
        }
        const result = {};
        result.thresHold = thresHold;
        result.test_id = this.test_id;
        result.timeStamp = this.timeStamp;
        result.originData = this.originData;
        try{
            result.delta = this.makeDelta(thresHold);
        }catch(e){
            console.log('ShoulderAngleDataParser parsing error ', e);
            result.delta = {};
        }
        result.value = this.value;
        // console.log('ShoulderAngleDataParser getResult result ',result);
        return result;
    }
}
const DummyTimeStamp = [0,107,250,385,526,656,786,916,1054,1190,1317,1472,1608,1717,1853,2005,2138,2266,2472,2549,2693,2809,2951,3138];
const DummyOrigin = [{"shoulder":{"degree":-1.79,"position":{"right":[583,242],"left":[679,239],"center":[631,240.5]}},"pelvic":{"degree":0,"position":{"right":[597,401],"left":[653,401],"center":[625,401]}}},
    {"shoulder":{"degree":-1.17,"position":{"right":[581,241],"left":[679,239],"center":[630,240]}},"pelvic":{"degree":0,"position":{"right":[596,403],"left":[654,403],"center":[625,403]}}},
    {"shoulder":{"degree":-1.77,"position":{"right":[582,241],"left":[679,238],"center":[630.5,239.5]}},"pelvic":{"degree":0,"position":{"right":[596,402],"left":[653,402],"center":[624.5,402]}}},
    {"shoulder":{"degree":-1.18,"position":{"right":[582,242],"left":[679,240],"center":[630.5,241]}},"pelvic":{"degree":-1.12,"position":{"right":[599,404],"left":[650,403],"center":[624.5,403.5]}}},
    {"shoulder":{"degree":1.68,"position":{"right":[681,235],"left":[579,238],"center":[630,236.5]}},"pelvic":{"degree":3.58,"position":{"right":[652,399],"left":[604,402],"center":[628,400.5]}}},
    {"shoulder":{"degree":0,"position":{"right":[579,240],"left":[678,240],"center":[628.5,240]}},"pelvic":{"degree":-1.97,"position":{"right":[593,403],"left":[651,401],"center":[622,402]}}},
    {"shoulder":{"degree":-0.6,"position":{"right":[579,241],"left":[674,240],"center":[626.5,240.5]}},"pelvic":{"degree":-0.99,"position":{"right":[591,402],"left":[649,401],"center":[620,401.5]}}},
    {"shoulder":{"degree":-1.21,"position":{"right":[573,240],"left":[668,238],"center":[620.5,239]}},"pelvic":{"degree":-0.92,"position":{"right":[585,403],"left":[647,402],"center":[616,402.5]}}},
    {"shoulder":{"degree":-0.59,"position":{"right":[568,239],"left":[665,238],"center":[616.5,238.5]}},"pelvic":{"degree":-4.69,"position":{"right":[584,405],"left":[645,400],"center":[614.5,402.5]}}},
    {"shoulder":{"degree":-1.74,"position":{"right":[566,241],"left":[665,238],"center":[615.5,239.5]}},"pelvic":{"degree":-3.75,"position":{"right":[583,401],"left":[644,397],"center":[613.5,399]}}},
    {"shoulder":{"degree":-1.16,"position":{"right":[569,239],"left":[668,237],"center":[618.5,238]}},"pelvic":{"degree":-4.61,"position":{"right":[585,399],"left":[647,394],"center":[616,396.5]}}},
    {"shoulder":{"degree":-0.58,"position":{"right":[573,239],"left":[672,238],"center":[622.5,238.5]}},"pelvic":{"degree":-3.63,"position":{"right":[588,405],"left":[651,401],"center":[619.5,403]}}},
    {"shoulder":{"degree":0.56,"position":{"right":[579,238],"left":[681,239],"center":[630,238.5]}},"pelvic":{"degree":-1.88,"position":{"right":[594,405],"left":[655,403],"center":[624.5,404]}}},
    {"shoulder":{"degree":2.89,"position":{"right":[594,237],"left":[693,242],"center":[643.5,239.5]}},"pelvic":{"degree":1.85,"position":{"right":[601,406],"left":[663,408],"center":[632,407]}}},
    {"shoulder":{"degree":2.92,"position":{"right":[604,234],"left":[702,239],"center":[653,236.5]}},"pelvic":{"degree":5.36,"position":{"right":[608,399],"left":[672,405],"center":[640,402]}}},
    {"shoulder":{"degree":2.36,"position":{"right":[608,235],"left":[705,239],"center":[656.5,237]}},"pelvic":{"degree":5.62,"position":{"right":[613,389],"left":[674,395],"center":[643.5,392]}}},
    {"shoulder":{"degree":2.34,"position":{"right":[607,235],"left":[705,239],"center":[656,237]}},"pelvic":{"degree":4.61,"position":{"right":[612,396],"left":[674,401],"center":[643,398.5]}}},
    {"shoulder":{"degree":4.04,"position":{"right":[602,235],"left":[701,242],"center":[651.5,238.5]}},"pelvic":{"degree":0.88,"position":{"right":[610,408],"left":[675,409],"center":[642.5,408.5]}}},
    {"shoulder":{"degree":-0.56,"position":{"right":[587,241],"left":[690,240],"center":[638.5,240.5]}},"pelvic":{"degree":0,"position":{"right":[599,404],"left":[663,404],"center":[631,404]}}},
    {"shoulder":{"degree":-0.56,"position":{"right":[579,238],"left":[682,237],"center":[630.5,237.5]}},"pelvic":{"degree":-3.63,"position":{"right":[594,405],"left":[657,401],"center":[625.5,403]}}},
    {"shoulder":{"degree":-1.09,"position":{"right":[574,237],"left":[679,235],"center":[626.5,236]}},"pelvic":{"degree":-4.54,"position":{"right":[592,393],"left":[655,388],"center":[623.5,390.5]}}},
    {"shoulder":{"degree":-2.22,"position":{"right":[574,238],"left":[677,234],"center":[625.5,236]}},"pelvic":{"degree":-3.63,"position":{"right":[592,394],"left":[655,390],"center":[623.5,392]}}},
    {"shoulder":{"degree":-1.67,"position":{"right":[577,239],"left":[680,236],"center":[628.5,237.5]}},"pelvic":{"degree":-2.68,"position":{"right":[593,404],"left":[657,401],"center":[625,402.5]}}},
    {"shoulder":{"degree":-1.67,"position":{"right":[585,239],"left":[688,236],"center":[636.5,237.5]}},"pelvic":{"degree":0,"position":{"right":[600,402],"left":[664,402],"center":[632,402]}}}
];
