import { configureStore } from "@reduxjs/toolkit";
import appDataReducer from "../features/appData/appDataSlice";
import commonReducer from "../features/common/commonSlice";
import panelsReducer from "../features/panels/panelsSlice";
import bodyAlignmentReducer from "../features/bodyAlignment/bodyAlignmentSlice";
import physicalTestReducer from "../features/physicalTest/physicalTestSlice";
import romReducer from "../features/rom/romSlice";
import bodyBalanceReducer from "../features/bodyBalance/bodyBalanceSlice";
import homecareProgramReducer from "../features/homecareProgram/homecareProgramSlice";
import cameraReducer from "../features/camera/cameraSlice";
import logDataReducer from "../features/logData/logDataSlice";
import bleReducer from "../features/ble/bleSlice";
import fitServiceReducer from "../features/fitService/fitServiceSlice";
import centerDataReducer from "../features/centerData/centerDataSlice";
import WeeklyStatusReducer from "../features/centerData/WeeklyStatusSlice";
import alarmReducer from "../features/alarm/alarmSlice";
import surveyReducer from "../features/survey/surveySlice"
import contentReducer from "../features/content/contentSlice"
import walkingReducer from "../features/activity/walkingSlice"
import activityReducer from "../features/activity/activitySlice"

export default configureStore({
  reducer: {
    appData: appDataReducer,
    common: commonReducer,
    panels: panelsReducer,
    homecareProgram: homecareProgramReducer,
    bodyAlignment: bodyAlignmentReducer,
    rom: romReducer,
    physicalTest: physicalTestReducer,
    bodyBalance: bodyBalanceReducer,
    camera: cameraReducer,
    logData: logDataReducer,
    ble: bleReducer,
    fitService: fitServiceReducer,
    centerData: centerDataReducer,
    WeeklyStatus: WeeklyStatusReducer,
    alarm: alarmReducer,
    survey: surveyReducer,
    content: contentReducer,
    activityWalking: walkingReducer,
    activity: activityReducer
  },
});
