import css from './TCanvasMatRaw.module.less';
import { useEffect, useRef, useCallback, useState } from 'react';
import { $L } from "../../utils/helperMethods";
import classNames from 'classnames';
import WebWorkerUtil, { WORKER_ID } from '../../utils/WebWorker/WebWorkerUtil';

const sensorData = [
	[0,11],
	[11,14],	//이 구간 많아지면 흐려짐.
	[14,44],   // [0] <= value < [1]
	[44,63],
	[63,85],
	[85, 107],
	[107,129],
	[129,148],
	[148,170],
	[170,192],
	[192,214],
	[214,233],
	[233,255],
	[255,256]
];
const colorData = [
	["000080", "000080"],
	["000080", "0000FF"],
	["0000FF", "0100DB"],
	["0100DB", "1550D8"],
	["1550D8", "0E87BE"],
	["0E87BE", "1EC0C2"],
	["1EC0C2", "0AD987"],
	["0AD987", "17F009"],
	["17F009", "8FFF15"],
	["8FFF15", "FFFF0F"],
	["FFFF0F", "FDBF12"],
	["FDBF12", "FE8012"],
	["FE8012", "FF0000"],
	["FF0000", "FF0000"]
];

const Colors256 = [];
const rgbToHex=(r, g, b) =>{
	const toHex = (value) => {
		const hex = value.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	};

	const hexR = toHex(r);
	const hexG = toHex(g);
	const hexB = toHex(b);

	return `${hexR}${hexG}${hexB}`;
}
const makePalette = () => {
	let index = 0;
	for(let i=0; i<sensorData.length; i++){
		const sensorGap = sensorData[i][1]-sensorData[i][0];
		const colorCode = colorData[i][0];
		const lastColorCode = colorData[i][1];

		const r = parseInt(colorCode.substring(0, 2), 16);
		const g = parseInt(colorCode.substring(2, 4), 16);
		const b = parseInt(colorCode.substring(4, 6), 16);
		const lr = parseInt(lastColorCode.substring(0, 2), 16);
		const lg = parseInt(lastColorCode.substring(2, 4), 16);
		const lb = parseInt(lastColorCode.substring(4, 6), 16);

		for(let j=0; j<sensorGap;j++){
			const cr = Math.ceil(r+((lr-r)/sensorGap)*j);
			const cg = Math.ceil(g+((lg-g)/sensorGap)*j);
			const cb = Math.ceil(b+((lb-b)/sensorGap)*j);
			Colors256[index] = rgbToHex(cr,cg,cb);
			index++;
		}
	}
}
const MULTIPLE_THRESHOLD = 12;
/**
 * @param {number[]} rawData - An array of color values represented from `0 to 255`.
 * @param {number} width - The `width` of the canvas.
 * @param {number} height -The `height` of the canvas.
 * @param {string} className - A class name that the user wants to add.
 */
const TCanvasMatRaw = ({ rawData, width, height, className, date, type = "oneleg", debug = false, ...rest }) => {
  const [mWorker, setMWorker] = useState(null);
  const [newData, setNewData] = useState({left:[], right:[]});
  const leftCanvasRef = useRef(null);
  const rightCanvasRef = useRef(null);

  const canvasMap = [
    {
      ref: leftCanvasRef,
      sideClass: css.left,
    },
    {
      ref: rightCanvasRef,
      sideClass: css.right,
    },
  ]

  const figureArray = ['H', 'L'];
  const contourLineFigure = Array.from(figureArray).map((figure, idx) => (
    <span key={`contourLineFigure - ${idx}`}>{figure}</span>
  ));

  const onResponse = useCallback((e) => {
    setNewData(e.value);
	},[]);
  useEffect(()  => {
    if (!mWorker) {
        WebWorkerUtil.makeWorker(WORKER_ID.CANVAS, onResponse).then((workerId) => {
          setMWorker(workerId);
        });
    }
    }, []);

  useEffect(()  => {
    if (rawData && mWorker) {
				WebWorkerUtil.postMessage(mWorker, { type: "interpolate", value: { rawData, width, height, scaleFactor: MULTIPLE_THRESHOLD, date, type, debug } }, true);
    }
  }, [mWorker, rawData, date, type]);

  useEffect(() => {
		const leftCanvasCtx = leftCanvasRef?.current?.getContext && leftCanvasRef?.current?.getContext('2d');
    const rightCanvasCtx = rightCanvasRef?.current?.getContext && rightCanvasRef?.current?.getContext('2d');
    leftCanvasCtx?.clearRect(0, 0, leftCanvasRef.current.width, leftCanvasRef.current.height);
		rightCanvasCtx?.clearRect(0, 0, rightCanvasRef.current.width, rightCanvasRef.current.height);

    if ( newData.left.length <=0 || rawData.length !== width * height) {
      console.warn("Warning: Please check the 'rawData', 'width', and 'height'.");
      return;
    }
		if(Colors256.length === 0){
			makePalette();
		}
		const d = [{canvasRef:leftCanvasRef, ctx: leftCanvasCtx, data: newData.left},
			{canvasRef:rightCanvasRef, ctx: rightCanvasCtx, data: newData.right}];
		d.map((item)=>{
			const imageData = item.ctx.createImageData(item.canvasRef.current.width, item.canvasRef.current.height);
			for (let i = 0; i < item.data.length; ++i) {
				let colorIndex = item.data[i];
				const colorCode = Colors256[colorIndex];
				const red = parseInt(colorCode.substring(0, 2), 16);
				const green = parseInt(colorCode.substring(2, 4), 16);
				const blue = parseInt(colorCode.substring(4, 6), 16);
				imageData.data[i * 4 + 0] = red;
				imageData.data[i * 4 + 1] = green;
				imageData.data[i * 4 + 2] = blue
				imageData.data[i * 4 + 3] = 255;
			}
			item.ctx.putImageData(imageData, 0, 0);
		});
  }, [rawData, newData]);

  if(!rawData || rawData.length <=0){
    return null;
  }
  return (
    <div className={classNames(css.canvasWrap, className)}>
      {canvasMap.map((el, idx) => (
        <div className={css.canvasBox} key={`canvasMap-${idx}`}>
          <canvas {...rest} ref={el.ref} width={MULTIPLE_THRESHOLD * width/2} height={MULTIPLE_THRESHOLD * height} className={classNames(css.canvas)} />
          <div className={classNames(css.footPosition, el.sideClass)}>
            {idx === 0 ? $L("L") : $L("R")}
          </div>
          <div className={classNames(css.contourLineSect, el.sideClass)}>
            <div className={css.flexBox}>
              <div className={css.line}></div>
              <div className={css.figure}>
                {contourLineFigure}
              </div>
            </div>
          </div>
        </div>
      ))}
	  </div>
  );
};

export default TCanvasMatRaw;
