import css from "./DonutChartItem.module.less";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { $L, transSecondsToHMS, numberWithCommas } from "../../../../utils/helperMethods";
import * as Config from "../../../../utils/Config";
import TDonutChart from "../../../../components/TDonutChart/TDonutChart";

/**
 *
 * @param {type} 'intensity', 'exercise', 'routine', 'time'
 * @returns
 */


export const getChartData = (itemInfo) => ({
  "intensity": 
  {
    title: $L("Exercise intensity"),
    labels: [$L("High-intensity"), $L("Medium-intensity"), $L("Low-intensity")],
    data: itemInfo,
    backgroundColor: ["#827161", "#B59D85", "#EFE7DF"],
  },
  "exercise": 
  {
    title: $L("Type of exercise"),
    labels: [$L("Full-body"), $L("Core"), $L("Upper Body"), $L("Lower Body")],
    data: itemInfo,
    backgroundColor: ["#827161", "#B59D85", "#DACBBD", "#EFE7DF"],
  },
  "routine":
  {
    title: $L("O2O Program/AI exercise program"),
    labels: [$L("O2O Program"), $L("AI exercise program")],
    data: itemInfo,
    backgroundColor: ["#827161", "#EFE7DF"],
  },
  "time":
  {
    title: $L("Workout Time"),
    rank: 22,
    text: "<span>50th</span> among 3,388 male members in their 50s",
    data: itemInfo,
    backgroundColor: ["#494847", "#B59D85"],
  }
});

const DonutChartItem = ({className, type, title, itemInfo, ...rest }) => {

  const chartData = useMemo(() => {
    return getChartData(itemInfo)[type];
  }, [itemInfo]);

  return (
    <div
      className={classNames(
        className,
        css.donutChartItem,
      )}
      {...rest}
    >
      {chartData.title && 
      <div className={css.title}>{chartData.title}</div>}
      <div className={css.donutContainer}>
        <TDonutChart width={200} height={200} lineWidth={30} data={chartData.data} backgroundColor={chartData.backgroundColor}/>
        {chartData.rank &&
          <div className={css.graphText}>
            <div className={css.top}>{$L("Top")}</div>
            <div className={css.rank}>{`${chartData.rank}%`}</div>
          </div>
        }
      </div>
      {chartData.labels && chartData.labels.length > 0 && 
        <div className={css.dataContainer}>
          {chartData.data 
          && chartData.data.length > 0 
          && chartData.data.map((_, index) => (
            <div key={"item" + index} className={css.dataWrapper}>
              <div className={css.dot} style={{backgroundColor: `${chartData.backgroundColor[index]}`}}/>
              <div className={css.label}>{chartData.labels[index]}</div>
              <div className={css.data}>{`${chartData.data[index]}%`}</div>
            </div>
          ))}
        </div>
        }
        {chartData.text &&
        <div className={css.text} dangerouslySetInnerHTML={{__html: chartData.text}}/>
        }
    </div>
  );
};

export default DonutChartItem;