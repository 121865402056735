import classNames from 'classnames';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { $L } from '../../../utils/helperMethods';
import css from "./MyBodyProfile.module.less";
import TButton from '../../../components/TButton/TButton';
import TSimpleButtonTab, {LIST_TYPE} from '../../../components/TSimpleButtonTab/TSimpleButtonTab';
import TSimpleDatePicker from '../../../components/TSimpleDatePicker/TSimpleDatePicker';
import Spotlight from '@enact/spotlight';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import dummyImage from '../../../../assets/jogging.png'

const columns = [
  { title: $L("Measurement/assessment date")},
  { title: $L("category")},
  { title: $L("항목")},
  { title: $L("Diagnosis results")}
];

const table = [
  {
    id: 1,
    date: '2023.07.11',
    category: $L("Range Of Motion"),
    item: $L("Knee"),
    result: $L("측정결과 60점으로 관리 수준입니다")
  },
  {
    id: 2,
    date: '2023.07.11',
    category: $L("Range Of Motion"),
    item: $L("Knee"),
    result: $L("측정결과 60점으로 관리 수준입니다")
  },
  {
    id: 3,
    date: '2023.07.11',
    category: $L("Range Of Motion"),
    item: $L("Knee"),
    result: $L("측정결과 60점으로 관리 수준입니다")
  },
  {
    id: 4,
    date: '2023.07.11',
    category: $L("Range Of Motion"),
    item: $L("Knee"),
    result: $L("측정결과 60점으로 관리 수준입니다")
  },
  {
    id: 5,
    date: '2023.07.11',
    category: $L("Range Of Motion"),
    item: $L("Knee"),
    result: $L("측정결과 60점으로 관리 수준입니다")
  },
];

const body = [
  {
    id: 1,
    title: $L("Total 신체능력"),
    text: "25" + $L("points")
  },
  {
    id: 2,
    title: $L("Total 신체능력"),
    text: "25" + $L("points")
  },
];
const MyBodyProfile = () => {
  const NOW = new Date();
  const [year, setYear] = useState(NOW.getFullYear());
  const [month, setMonth] = useState(NOW.getMonth() + 1); //january is 1
  const [selectedIndexSmall, setSelectedIndexSmall] = useState(0);

  const onSelectMonth = useCallback(
    ({ first, last, previous }) => {
        const date = new Date(first);
        const newYear = date.getFullYear();
        const newMonth = date.getMonth() + 1;
        if (year !== newYear || month !== newMonth) {
            setYear(newYear);
            setMonth(newMonth);
        }
    },
    [year, month]
  );

  const onItemClickSmall = useCallback(({index,...rest})=>{
    console.log('TSimpleButtonTabLayout onItemClickSmall', index, rest);
    setSelectedIndexSmall(index);
  },[]);

  return (
    <>
      <div className={css.gridLayout}>
        <div className={css.stage}>
          <div className={css.title}>
            {$L("Stages by musculoskeletal index")}
          </div>
          <div className={css.graph}/>
        </div>
        <div className={css.bodyType} >
          <div className={css.image} style={{backgroundImage: `url(${dummyImage})`}}/>
          <div className={css.title}>{$L("Body types")}</div>
          <div className={css.text}>{$L("슬림하고 키가 큰 남성")}</div>
        </div>
        {body && body.length > 0 && body.map((data, index) => (
          <div className={css.data} key={"data" + index}>
            <div className={css.title}>{data.title}</div>
            <div className={css.text}>{data.text}</div>
          </div>
        ))}
      </div>
      <div className={css.dateWrap}>
        <TSimpleDatePicker
          size="small"
          width="small"
          period='monthly' onSelect={onSelectMonth}
        />
        <TSimpleButtonTab
          listType={LIST_TYPE.small}
          contents={[$L("Overall"), $L("Quarterly"), $L("Monthly"), $L("Weekly")]}
          onItemClick={onItemClickSmall}
          selectedIndex={selectedIndexSmall}
        />
      </div>
      <table className={css.table}>
        <tbody>
          <tr>
            {columns && columns.length > 0 && columns.map((col, index) => (
              <th className={css.th} key={`column-${index}`}>{col.title}</th>
            ))}
          </tr>
            {table && table.length > 0 && table.map((row, index) => (
              <tr key={`row-${index}`}>
                <td className={css.td}>{row.date}</td>
                <td className={css.td}>{row.category}</td>
                <td className={css.td}>{row.item}</td>
                <td className={css.td}>{row.result}</td>
              </tr>
            ))}
        </tbody>
      </table>


    </>
  );
};

export default MyBodyProfile;