import css from "./PhysicalTest.module.less";
import PhysicalTestGraph from "../PhysicalTest/PhysicalTestGraph/PhysicalTestGraph";
import { useEffect, useMemo, useState } from "react";
import { $L, convertNewlinesToBr } from "../../../utils/helperMethods";

//임시이미지
import * as HelperMethods from "../../../utils/helperMethods";
import PTEmptyTestReport from "./PTEmptyTestReport/PTEmptyTestReport";
import { CONSTANTS_MONTH } from "../../../utils/Constants";

const mockData = [
  {
    score: 80,
    time: 135,
  },
  {
    score: 95,
    time: 150,
  },
  {
    score: 49,
    time: 88,
  },
  {
    score: 93,
    time: 32,
  },
];
const ScoreComponent = ({ scoreCount, selectedDate }) => {
  const monthScoreArr = useMemo(() => {
    // 월간 계산
    const currentDate = new Date(selectedDate);
    const month = currentDate.getMonth() + 1;

    let ret = [];
    let countMonth = month;
    for (let i = 0; i < 4; i++) {
      // month
      let monthKey = "";
      if (countMonth - 1 < 0) {
        monthKey = CONSTANTS_MONTH[CONSTANTS_MONTH.length - 1];
        countMonth = CONSTANTS_MONTH.length - 1;
      } else {
        monthKey = CONSTANTS_MONTH[countMonth - 1];
        countMonth -= 1;
      }
      ret.unshift({ ...mockData[i], month: monthKey });
    }

    return ret;
  }, [mockData, selectedDate]);

  return (
    <div className={css.scoreContainer}>
      <div className={css.userBodyScore}>
        <div className={css.bodyScoreText}>{$L("Total Score")}</div>
        <div className={css.num}>
          {scoreCount < 10 ? `0${scoreCount}` : scoreCount}
        </div>
      </div>
      <div className={css.scoreMonthContainer}>
        {monthScoreArr.map((data, dataIndex) => (
          <div key={`data-${dataIndex}`} className={css.scoreMonthItem}>
            <div className={css.month}>{$L(data.month)}</div>
            <div className={css.score}>{data.score}</div>
            <div className={css.time}>
              {HelperMethods.convertTimeToText(data.time)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PhysicalOverallContainer = ({ contentsInfos, isEmpty, selectedDate }) => {
  const [isUpdated, setUpdated] = useState(false);
  const [scoreCount, setCount] = useState(0);

  const userScore = useMemo(() => {
    const ret = { totalScore: 0, data: [] };

    let totalScore = contentsInfos[0]?.score ? contentsInfos[0]?.score : 0;
    for (let i = 1; i < contentsInfos.length; i++) {
      const values = {};
      values.title = contentsInfos[i].tabName;
      values.innerHTML = contentsInfos[i].tabName;
      values.score = contentsInfos[i].score;
      values.grade = contentsInfos[i].grade;
      ret.data.push(values);
    }
    totalScore = Math.round(totalScore / ret.data.length);
    ret.totalScore = totalScore;
    ret.data.sort((a, b) => {
      if (a.score > b.score) return -1;
      if (a.score === b.score) {
        return b.title < a.title ? -1 : 1;
      }
      if (a.score < b.score) return 1;
    });
    return ret;
  }, [contentsInfos]);

  useEffect(() => {
    setCount(0);
    setUpdated(false);
    setTimeout(() => {
      setUpdated(true);
    }, 0);
  }, [selectedDate]);

  useEffect(() => {
    let interval;
    if (scoreCount < userScore.totalScore) {
      interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 2000 / 60);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [scoreCount, userScore.totalScore]);

  return isEmpty ? (
    <PTEmptyTestReport />
  ) : (
    <div className={css.physicalOverAll}>
      <div className={css.overAllGraphArea}>
        <div className={css.overAllBarGraph}>
          {userScore.data.map((data, index) => {
            return (
              <PhysicalTestGraph
                key={index}
                score={data.score}
                grade={data.grade}
                physicalWord={data.innerHTML}
                bgNum={index + 1}
                // gap={data.gap}
                styleByScore={true}
                isUpdated={isUpdated}
              />
            );
          })}
        </div>
        <ScoreComponent scoreCount={scoreCount} selectedDate={selectedDate} />
      </div>
    </div>
  );
};

export default PhysicalOverallContainer;
