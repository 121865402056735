import { useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import * as lunaSend from "../lunaSend";
import { $L } from "../utils/helperMethods";

let gestureUse = false; // todo remove. toast 방지용

const useGesture = () => {
  const { gestureYn } = useSelector(state => state.fitService.settings);

  // useEffect(() => {
  //   if (gestureYn !== undefined) {
  //     if (gestureYn) {
  //       gestureInitialize()
  //     } else {
  //       gestureRelease()
  //     }
  //   }
  // }, [gestureYn]);

  const gestureInitialize = useCallback(() => {
    lunaSend.gestureInitialize('camera1', {
      onSuccess:(res) => {
        lunaSend.createToast($L("Gestures have been activated."));
        gestureUse = true;
        console.log('gesture initialize success', res);
      },
      onFailure:(err) => {
        console.log('gesture initialize Failure', err);
      }
    });
  }, []);

  const gestureRelease = useCallback(() => {
    lunaSend.gestureRelease({
      onSuccess:(res) => {
        if (gestureUse) lunaSend.createToast($L("Gestures have been deactivated."));
        gestureUse = false;
        console.log('gesture release success', res);
      },
      onFailure:(err) => {
        console.log('gesture release Failure', err);
      }
    });
  }, []);

  return { gestureInitialize, gestureRelease };
}

export default useGesture;
