import { $L } from "../../../../utils/helperMethods";
export const romMenu = {
  OVERALL: "OVERALL",
  NECK: "NECK",
  SHOULDER: "SHOULDER",
  TRUNK: "TRUNK",
  KNEE: "KNEE",
  HIP: "HIP",
};

export const getRomMenuTitle = () => ({
  [romMenu.OVERALL]: $L("Overall"),
  [romMenu.NECK]: $L("Neck"),
  [romMenu.SHOULDER]: $L("Shoulder"),
  [romMenu.TRUNK]: $L("Trunk"),
  [romMenu.KNEE]: $L("Knee"),
  [romMenu.HIP]: $L("Hip"),
});

export const getEmptyResultsReportData = () => {
  const romList = {
    [romMenu.OVERALL]: {
      title: $L(
        "ROM(관절 가동범위 테스트)는 {br}정지 상태의 체형분석 상에서 알 수 없는 관절의 건강 상태를 평가하기 위한 테스트예요."
      ),
      detail: $L(
        "우리 몸은 뼈와 피부, 근육보다 관절, 인대 등의 노화속도가 가장 빠르다고 해요. {br}이에 대한 각 관절의 건강상태를 평가하여 거기에 맞는 운동을 추천해주고 있어요. {br}예를 들어 통증이 있는 관절은 운동강도를 조절하거나 생략하는 방법으로 {br}운동이 오히려 건강을 악화시키는 일을 방지할 수 있어요."
      ),
    },
    [romMenu.NECK]: {
      title: $L("No test information found."),
      detail: $L(
        "아직 관절가동범위평가 목 부위 테스트를 진행하지 않았어요. {br}테스트를 진행하면 결과에 따라 맞춤형 운동을 추천해 드려요."
      ),
    },
    [romMenu.SHOULDER]: {
      title: $L("No test information found."),
      detail: $L(
        "아직 관절가동범위평가 어깨 부위 테스트를 진행하지 않았어요. {br}테스트를 진행하면 결과에 따라 맞춤형 운동을 추천해 드려요."
      ),
    },
    [romMenu.TRUNK]: {
      title: $L("No test information found."),
      detail: $L(
        "아직 관절가동범위평가 허리 부위 테스트를 진행하지 않았어요. {br}테스트를 진행하면 결과에 따라 맞춤형 운동을 추천해 드려요."
      ),
    },
    [romMenu.KNEE]: {
      title: $L("No test information found."),
      detail: $L(
        "아직 관절가동범위평가 무릎 부위 테스트를 진행하지 않았어요. {br}테스트를 진행하면 결과에 따라 맞춤형 운동을 추천해 드려요."
      ),
    },
    [romMenu.HIP]: {
      title: $L("No test information found."),
      detail: $L(
        "아직 관절가동범위평가 고관절 부위 테스트를 진행하지 않았어요. {br}테스트를 진행하면 결과에 따라 맞춤형 운동을 추천해 드려요."
      ),
    },
  };
  return romList;
};
