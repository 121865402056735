/**
 * TLineChart
 *
 * @module TLineChart
 */
import classNames from "classnames";
import css from "./TLineChart.module.less";
import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useMemo } from "react";
import { useCallback } from "react";

const _BASE_COLOR = "#63534b";

/**
 * @param {string} className - A class name that the user wants to add.
 * @param {number[]} monthlyExerciseData - An array containing the total monthly exercise time, with a required length of 4 or over.
 * @param {function} onLineChartCoordinates - The callback function that stores an array of objects representing the x and y coordinates of the line chart.
 * @param {boolean} debug - When in debug mode, horizontal ticks on the line chart are enabled. (Default: false)
 * @param {number} width - The width of the line chart. (Default: 840)
 * @param {number} height - The height of the line chart. (Default: 360)
 * @param {number} dataPointDiamete - The diameter of the circles representing data points. (Default: 16)
 * @param {number} animationDurationMs - The duration of the animation in milliseconds. (Default: 1000)
 */
const TLineChart = ({
  className,
  monthlyExerciseData,
  onLineChartCoordinates,
  debug = false,
  width = 840,
  height = 360,
  maxRange = 150,
  dataPointDiameter = 8,
  animationDurationMs = 1000,
  baseColor,
  ...rest
}) => {
  const [pointCoordinates, setPointCoordinates] = useState([]);
  const svgRef = useRef(null);

  const clearCanvas = useCallback(() => {
    const svg = svgRef.current;

    if (svg) {
      while (svg.lastChild) {
        svg.removeChild(svg.lastChild);
      }
    }
  }, [svgRef.current]);

  const BASE_COLOR = useMemo(() => {
    if(baseColor) {
      return baseColor;
    } else {
      return _BASE_COLOR;
    }
  }, [baseColor]);

  const IS_MAX_VALUE_ABOVE_MAX_RANGE = useMemo(
    () => Math.max(...monthlyExerciseData) <= maxRange,
    [monthlyExerciseData]
  );

  const xScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, monthlyExerciseData.length - 1])
      .range([0, width]);
  }, [monthlyExerciseData]);

  const yScale = useMemo(() => {
    return IS_MAX_VALUE_ABOVE_MAX_RANGE
      ? d3.scaleLinear().domain([0, maxRange]).range([height, 0])
      : d3
          .scaleLinear()
          .domain([0, Math.max(...monthlyExerciseData)])
          .range([height, 0]);
  }, [IS_MAX_VALUE_ABOVE_MAX_RANGE, monthlyExerciseData]);

  const drawLine = useCallback(() => {
    const coordinates = [];
    const group = d3.select(svgRef.current).append("g").attr("class", css.line);

    const line = d3
      .line()
      .x((_, i) => {
        const x = xScale(i);
        coordinates.push({ x, y: yScale(monthlyExerciseData[i]) });

        return x;
      })
      .y((d) => yScale(d))
      .curve(d3.curveBumpX); // shartness 정할 수 있음

    const path = group
      .append("path")
      .datum(monthlyExerciseData)
      .attr("d", line)
      .attr("class", css.line);

    const pathLength = path.node().getTotalLength();
    const transitionPath = d3
      .transition()
      .ease(d3.easeSin)
      .duration(animationDurationMs);

    path
      .attr("stroke-dashoffset", pathLength)
      .attr("stroke-dasharray", pathLength)
      .transition(transitionPath)
      .attr("stroke-dashoffset", 0);

    setPointCoordinates([...coordinates]);
  }, [svgRef.current, monthlyExerciseData]);

  const drawGradient = useCallback(() => {
    const OFFSET = 80;
    const INCREMENT = 20;
    const OFFSETS = [];

    for (let i = 0; i <= OFFSET; i += INCREMENT) {
      const offset = i / 100;
      OFFSETS.push(offset.toFixed(1));
    }

    const group = d3
      .select(svgRef.current)
      .append("g")
      .attr("class", "gradient");

    group
      .append("defs")
      .append("linearGradient")
      .attr("id", "areaGradient")
      .attr("fy", "90%")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")
      .selectAll("stop")
      .data([
        { offset: "0%", color: BASE_COLOR, opacity: 0.8 },
        { offset: `${OFFSET.toString()}%`, color: BASE_COLOR, opacity: 0 },
      ])
      .enter()
      .append("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-opacity", (d) => d.opacity)
      .attr("stop-color", (d) => d.color);

    group.selectAll("stop").each(function (_, i) {
      const stop = d3.select(this);

      stop
        .append("animate")
        .attr("attributeName", "offset")
        .attr("delay", `${animationDurationMs.toString()}ms`)
        .attr("values", i === 1 ? OFFSETS.join(";") : "")
        .attr("dur", `${animationDurationMs.toString()}ms`)
        .attr("repeatCount", "1");
    });

    const area = d3
      .area()
      .x((_, i) => xScale(i))
      .y0(height)
      .y1((d) => yScale(d))
      .curve(d3.curveBumpX);

    const transitionPath = d3
      .transition()
      .ease(d3.easeSin)
      .delay(animationDurationMs)
      .duration(animationDurationMs);

    group
      .append("path")
      .datum(monthlyExerciseData)
      .attr("d", area)
      .attr("class", css.area)
      .style("opacity", 0)
      .transition()
      .duration(animationDurationMs)
      .style("opacity", 1);
  }, [monthlyExerciseData]);

  const drawDataPoints = useCallback(() => {
    const ANIMATION_DURATION_MS_PER_POINT = animationDurationMs / 5;
    const group = d3.select(svgRef.current).append("g").attr("class", "points");

    group
      .selectAll("circle")
      .data(monthlyExerciseData)
      .enter()
      .append("circle")
      .attr("cx", (_, i) => xScale(i))
      .attr("cy", (d) => yScale(d))
      .attr("r", dataPointDiameter)
      .attr("class", css.circle)
      .style("opacity", 0)
      .transition()
      .delay((_, i) => i * ANIMATION_DURATION_MS_PER_POINT)
      .duration(ANIMATION_DURATION_MS_PER_POINT)
      .style("opacity", 1);
  }, [svgRef.current, monthlyExerciseData]);

  const drawTick = useCallback(() => {
    const group = d3.select(svgRef.current).append("g").attr("class", "ticks");

    for (let i = 0; i <= height; i += height / 5) {
      group
        .append("line")
        .attr("x1", 0)
        .attr("y1", i)
        .attr("x2", width)
        .attr("y2", i)
        .style("stroke", "black");
    }
  }, [svgRef.current, monthlyExerciseData]);

  useEffect(() => {
    if (monthlyExerciseData.length < 4)
      console.warn(
        "The monthlyExerciseData array must be at least 4 in length."
      );
  }, [monthlyExerciseData]);

  useEffect(() => {
    clearCanvas();

    if (debug) drawTick();
    drawLine();
    drawGradient();
    drawDataPoints();

    return () => clearCanvas();
  }, [monthlyExerciseData]);

  useEffect(() => {
    //callback call
    onLineChartCoordinates(pointCoordinates);
  }, [monthlyExerciseData, onLineChartCoordinates, pointCoordinates]);

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      className={classNames(css.lineChart, className)}
      {...rest}
    />
  );
};

export default TLineChart;
