import classNames from "classnames";
import css from "./MyProfile.module.less";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { $L, numberWithCommas } from "../../../utils/helperMethods";
import { addPanels } from "../../../features/panels/panelsSlice";
import { launchMembershipApp } from "../../../features/common/commonSlice";
import * as Config from "../../../utils/Config";

import TButton, {SIZES} from "../../../components/TButton/TButton";
import TPopUp from "../../../components/TPopUp/TPopUp";
import TListItemButton from "../../../components/TListItemButton/TListItemButton";
import HealthBall from "./HealthBall/HealthBall";

import dummyImage0 from "../../../../assets/myPanel/dummy_prfile_image.png";
import dummyImage1 from "../../../../assets/myPanel/icon/naver_pay.png";
import dummyImage2 from "../../../../assets/myPanel/icon/img_my_reward_coin.png";

import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";


const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

// dummy reward
const reward = [
  {
    id: 0,
    image: dummyImage1,
    text: "네이버 페이 쿠폰",
    point: 30000,
  },
  {
    id: 1,
    image: dummyImage2,
    text: "운동 리워드",
    point: 3000,
  },
  {
    id: 2,
    image: dummyImage1,
    text: "네이버 페이 쿠폰",
    point: 30000,
  },
  {
    id: 3,
    image: dummyImage2,
    text: "운동 리워드",
    point: 3000,
  },
];

const MyProfile = () => {
  const dispatch = useDispatch();
  const { userId, profileNick } = useSelector((state) => state.common.appStatus);
  const [showAccountPopup, setShowAccountPopup] = useState(false);

  const onClickAccount = useCallback((ev) => {
    setShowAccountPopup((prev) => !prev);
  }, []);

  const onClickPopUpBtn = useCallback((ev) => {
    if (ev === 0) {
      setShowAccountPopup((prev) => !prev);
    } else if (ev === 1) {
      dispatch(launchMembershipApp());
    }
  }, []);

  const onClickAdvertisment = useCallback((ev) => {
    console.log("advertisment")
  }, []);

  return (
    <>
      <Container className={css.myProfile}>
        {/* account */}
        <div className={classNames(css.account)}>
          <div className={css.image} style={{ backgroundImage: `url(${dummyImage0})` }}/>
          <div className={css.info}>
            {profileNick && <div className={css.name}>{$L(profileNick)}</div>}
            {userId && <div className={css.email}>{$L(userId)}</div>}
          </div>
          <TButton onClick={onClickAccount} className={css.button} size={SIZES.tiny}>
            {$L("Manage your account")}
          </TButton>
        </div>
        {/* subscription */}
        <TListItemButton className={css.subscription}>
          <div className={css.subscribeIcon}/>
          <div className={css.title}>{$L("My subscriptions")}</div>
          <div className={css.text}>{$L("프리미엄 외 2건 | 12개월 (2023.10.12~2024.10.11)")}</div>
          <div className={css.arrowIcon}/>
        </TListItemButton>
        {/* healthBall */}
        <HealthBall className={css.healthBall}/>
        {/* advertisment */}
        <SpottableComponent
          className={css.advertisment}
          onClick={onClickAdvertisment}
        >
          {$L("Banner ad area")}
        </SpottableComponent>
        {/* reward */}
        <div className={css.rewardContainer}>
          <div className={css.title}>{$L("Rewards")}</div>
          {reward && reward.length > 0 && reward.map((item, index) => (
            <div key={item.id} className={css.reward}>
              <div className={css.image} style={{ backgroundImage: `url(${item.image})` }}/>
              <div className={css.text}>{$L(item.text)}</div>
              <div className={css.point}>{`+${numberWithCommas(item.point)} ${$L("Points")}`}</div>
            </div>
          ))}
        </div>
      </Container>
      {showAccountPopup && (
        <TPopUp
          kind="textPopUp"
          text={$L(
            "Do you want to open the LG Membership app to manage your account?"
          )}
          button1text={$L("Cancel")}
          button2text={$L("Go to")}
          onClick={onClickPopUpBtn}
        ></TPopUp>
      )}
    </>
  );
};

export default MyProfile;
