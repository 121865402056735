import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './HPAlarmSetting.module.less';
import { addPanels, popPanel } from '../../features/panels/panelsSlice';
import THeader from "../../components/THeader/THeader";
import { $L } from '../../utils/helperMethods';
import TPanel from '../../components/TPanel/TPanel';
import TBody from '../../components/TBody/TBody';
import TButton, { SIZES } from '../../components/TButton/TButton';
import TSettingSection from '../../components/TSettingSection/TSettingSection'
import { panel_names } from '../../utils/Config';
import { updateAlarm } from '../../features/alarm/alarmSlice';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import classNames from 'classnames';

const Container = SpotlightContainerDecorator({ enterTo: 'default-element' }, 'div');

const HPAlarmSetting = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const { language, profileNick} = useSelector((state) => state.common.appStatus);
  // const { activated, alarms } = useSelector((state) => state.alarm);
  const alarm = useSelector(state => state.alarm);
  const [alarms, setAlarms] = useState(alarm?.alarms || []);
  const [times, setTimes] = useState([]);
  const [isActivated, setActivated] = useState(alarm.activated);

  const DAYS = [$L("SUN"), $L("MON"), $L("TUE"), $L("WED"), $L("THU"), $L("FRI"), $L("SAT")];
  const SETTING_INFO = [
    {
      key: 'routineAlarmSetting',
      title: $L("Set a routine alert"),
      detail: $L("You'll get a daily workout alert at a preset time notifying you to start working out."),
      button1: { text: $L("ON"), value: true },
      button2: { text: $L("OFF"), value: false }
    }
  ];

  useEffect(() => {
    if(alarms && language) {
      const formatedTimes = alarms.map((day) => {
        const date = new Date(2000, 1, 1, day.time.substring(0, 2), day.time.substring(3, 5));
        return date.toLocaleTimeString(language === 'ko' ? 'ko-KR' : 'en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      });
      setTimes(formatedTimes);
    }
  }, [alarms, language]);

  const onClickAlarm = useCallback((key) => (value) => {
    setActivated(value);
    if(value) {
      setAlarms((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        // todo default 프로그램 지정 요일
        newData.map((el, idx) => {
          (idx % 2 === 1) && ( el.active = true );
        });
        return newData;
      });
    } else {
      setAlarms((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        newData.forEach(el => {
          el.active = false;
        });
        return newData;
      });
    }
  }, [])

  const onClickChange = useCallback(() => {
    dispatch(addPanels({ name: panel_names.HOMECARE_TIME_SETTING, panelInfo: { days: DAYS, alarms: alarms, activated: isActivated } }));
  }, [dispatch, alarms, isActivated])

  const onClickComplete = useCallback(() => {
    dispatch(updateAlarm({ value: { alarms: [...alarms], activated: isActivated } }));
    dispatch(popPanel());
  }, [dispatch, isActivated])

  const overview = useCallback((title, programTitle)=> {
    return (
      `${$L("We'll make a <span>{programTitle}</span> routine for {title}!").replace('{programTitle}', programTitle).replace('{title}', title)}
      <br/>
      <span>${profileNick} </span>${$L(
        "Start a workout routine tailored to your schedule!"
      )}`)
  }, [profileNick]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Set a routine")} iconType="back"/>
      <TBody className={css.tBody}>
        <div className={css.overview} dangerouslySetInnerHTML={{__html: overview(panelInfo.title, panelInfo.programTitle)}}/>
        <div className={css.section}>
          <div className={css.title}>
            {$L({ key: "_Set alerts", value: "Set alerts" })}
          </div>
          {SETTING_INFO.map((info, idx) => <Container key={idx}>
            <TSettingSection
              text={info.title}
              subText={info.detail}
              button1={info.button1}
              button2={info.button2}
              value={isActivated}
              onClick={onClickAlarm(info.key)}
            />
          </Container>)}
        </div>
        <div className={css.section}>
          <div className={css.title}>{$L("Workout Time")}</div>
          <div className={css.weekdays}>
            {times.map((time, index) => {
              return (
                <div className={classNames(css.timeSetting, alarms[index]?.active ? null : css.disabled)} key={"alarm"+index}>
                  <div className={css.day}>{DAYS[index]}</div>
                  <div className={css.time}>{time}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={css.buttonArea}>
          <TButton size={SIZES.large} onClick={onClickChange}>{$L("Change the time of your workout")}</TButton>
          <TButton size={SIZES.large} onClick={onClickComplete}>{$L("Setting completed")}</TButton>
        </div>


      </TBody>
    </TPanel>
  );
};

export default HPAlarmSetting;