import React, { useCallback } from 'react';
import css from "./WalkingPath.module.less";
import { $L } from '../../../../../utils/helperMethods';
import EmptyResultsReport from '../../../../../components/EmptyResultsReport/EmptyResultsReport';

const WalkingPath = ({openTestSelectPopup}) => {
  const selectTest = useCallback(() => {
    openTestSelectPopup();
  },[openTestSelectPopup]);

  return (
    <div className={css.Container}>
      <div>WalkingPath</div>
    </div>
  )
}

export default WalkingPath;