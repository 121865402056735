import css from "./BodyAlignmentReport.module.less";
import classNames from "classnames";
import { useCallback, useState, useMemo, useEffect } from "react";
import * as Config from "../../../../utils/Config";
import { MENUTYPE, TAB_DETAIL, getTabMenuTitle } from "./Constants";
import { 
  BODY_SCAN_SCORE, 
  BODY_SCAN_BASIC_SCORE, 
  BODY_CHECKUP_TYPE 
} from "../../../../utils/Constants";
import { voiceGuideBodyAlignment, voiceGuideEmpty } from "../../../BodyCheckUp/BodyAlignment/feedback/feedbackBodyAlignment";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { useDispatch, useSelector } from "react-redux";
import { getBodyCheckUpData } from "../../../../features/fitService/fitServiceSlice";
import Overall from "./Overall"
import { $L } from "../../../../utils/helperMethods";
import Header from "../../components/Header/Header";
import ViewDetails from "./ViewDetails";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");
let tabMenuTitle = null;
const BodyAlignmentReport = ({ className, dateList, ...rest }) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { testResults } = useSelector((state) => state.bodyAlignment);
  const [feedback, setFeedback] = useState(null);

  if (!tabMenuTitle) {
    tabMenuTitle = getTabMenuTitle();
  }

  const isEmpty = useMemo(() => (!dateList || dateList.length <= 0), [dateList]);

  const currentData = useMemo(() => {
    if (!isEmpty) {
      return testResults[dateList[dateList.length - 1]];
    }
    return {};
  }, [testResults, dateList, isEmpty]);
 
  const tabInfos = useMemo(() => {
    const titleArray = tabMenuTitle;
    const ret = [];
    const keys = Object.keys(MENUTYPE);
    let totalScore = 0;
    for (let i = 0; i < keys.length; i++) {
      let score = 0;
      let detailType = "";
      if (keys[i] !== MENUTYPE.OVERALL) {
        const detail = TAB_DETAIL[keys[i]];

        const scoreKey = currentData?.[detail.mainkey]?.[detail.testkey]?.[3];
        const typeKey = currentData?.[detail.mainkey]?.[detail.testkey]?.[0];
        if (BODY_SCAN_SCORE[scoreKey]) {
          score = BODY_SCAN_SCORE[scoreKey];
          detailType = typeKey;
        }
        const scoreKey2 = currentData?.[detail.mainkey]?.[detail.testkey2]?.[3];
        const typeKey2 = currentData?.[detail.mainkey]?.[detail.testkey2]?.[0];
        if (BODY_SCAN_SCORE[scoreKey2]) {
          score = Math.min(score, BODY_SCAN_SCORE[scoreKey2]);
          detailType = typeKey2;
        }
      }
      if(Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION && keys[i] === MENUTYPE.PELVIC_ROTATION){
        score = 0;
      }
      ret.push({
        type: keys[i],
        sortIndex: i,
        title: titleArray[keys[i]][0],
        detailTitle: titleArray[keys[i]][1],
        score: score,
        detailType: detailType,
      });
      if(Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION && keys[i] === MENUTYPE.PELVIC_ROTATION){
        totalScore += 7;
      }else{
        totalScore += score;
      }
    }
    ret[0].score = totalScore + BODY_SCAN_BASIC_SCORE;
    ret.sort((a, b) => {
      if (a.type === MENUTYPE.OVERALL || b.type === MENUTYPE.OVERALL) {
        return 0;
      }

      // MENUTYPE.PELVIC_ROTATION을 맨 마지막으로 정렬
      if(Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION){
        if (a.type === MENUTYPE.PELVIC_ROTATION) return 1;
        if (b.type === MENUTYPE.PELVIC_ROTATION) return -1;
      }
      if (a.score > b.score) return 1;
      if (a.score < b.score) return -1;
      if (a.score === b.score) {
        return b.sortIndex < a.sortIndex ? 1 : -1;
      }
    });
    return ret;
  }, [currentData]);

  const tabNames = useMemo(() => {
    return tabInfos.map((item) => item.title);
  }, [tabInfos]);
  const scoreArray = useMemo(() => {
    return tabInfos.map((item) => item.score);
  }, [tabInfos]);


  
  useEffect(() => {
    if (dateList && dateList.length > 0) {
      dispatch(
        getBodyCheckUpData({
          type: BODY_CHECKUP_TYPE.BODY_ALIGNMENT,
          date: dateList[dateList.length - 1],
          syncFlag: "immediately",
        })
      );
    }
  }, [dateList]);
  
  useEffect(() => {
    setFeedback(
      isEmpty
        ? voiceGuideEmpty()
        : voiceGuideBodyAlignment(selectedIndex, tabInfos)
    );
  }, [selectedIndex, tabInfos, isEmpty]);

  // const disabledItem = useMemo(() => {
  //   if (currentData) {
  //     if(Config.BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION){
  //       return [tabMenuTitle[MENUTYPE.PELVIC_ROTATION][0]];
  //     }else{
  //       return [];
  //     }
  //   } else {
  //     return tabNames.slice(1);
  //   }
  // }, [currentData, tabNames]);
  
  const onClickTabButton = useCallback((index) => (ev) => {
    setSelectedIndex(index);
  }, []);

  return (
    <>
      <Header>{$L("Body Alignment")}</Header>
      <Container
        {...rest}
        className={classNames(
          className,
          css.bodyAlignmentReport
        )}
      > 
        
        {/* <TabButtonWrapper className={css.tabButtonWrapper}>
          {tabNames.map((item, index) => (
            <TabButton
              key={"button" + index}
              onClick={onClickTabButton(index)}
              score={scoreArray[index]}
              index={index}
            >
              {item}
            </TabButton>
          ))}
        </TabButtonWrapper> */}
        <Overall
          date={dateList[dateList.length - 1]}
          scoreArray={scoreArray}
          feedback={feedback}
          tabInfos={tabInfos}
        />
        <ViewDetails tabInfos={tabInfos} />
        {/* <SubTab
          date={dateList[dateList.length - 1]}
          menuType={tabInfos[1].type}
          // launchReportDetail={launchReportDetail}
          title={tabInfos[1].detailTitle}
          tabDetail={TAB_DETAIL[tabInfos[1].type]}
          selectedDate={dateList[dateList.length - 1]}
        /> */}
      </Container>
    </>
  );
};

export default BodyAlignmentReport;