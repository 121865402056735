import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as HelperMethods from "../utils/helperMethods";
import { postActivityStroll } from "../features/fitService/fitServiceSlice";
import { updateProgressInfos } from "../features/activity/walkingSlice";

const useActivity = ({ subType, contentId }) => {
  const dispatch = useDispatch();
  const activityTypeRef = useRef(subType || '');
  const { contentInfos } = useSelector(state => state.content);
  const { cameraSize } = useSelector((state) => state.camera);

  const content = useMemo(() => {
    return (contentInfos && contentId && contentInfos[contentId]) || {};
  }, [contentInfos]);

  useEffect(() => {
    if (activityTypeRef.current !== content.subType) {
      // activityTypeRef.current = content.subType;
      activityTypeRef.current = content.contentId === 4 ? 'jogging' : content.subType;
    }
  }, [content]);

  const contentInfo = useMemo(() => {
    const obj = {
      title: content.title,
      playUrl: content.playUrl,
      thumbnailUrl: content.thumbnailUrl,
      distance: content.activity?.distance || 0,
      step: content.activity?.step || 0,
      trackType: content.activity?.trackType || '',
      hotSpot: content.activity?.hotSpot || [],
      calories: content.metadata?.calories || 0,
      met: content.metadata?.met || 0,
      history: {
        progressDistance: content.history?.progressDistance || 0,
        progressStep: content.history?.progressStep || 0,
        progressTime: content.history?.progressTime || 0,
        progressCalories: content.history?.progressCalories || 0
      },
      // bgm
      bgm: content.activity?.bgm,
      sceneryBgm: content.activity?.sceneryBgm,
      leftFoot: content.activity?.leftFoot,
      rightFoot: content.activity?.rightFoot
    };
    obj.hotSpotPercent = obj.hotSpot.map(el => el.distance / obj.distance * 100) || [];

    // todo remove
    if ([1].includes(content.contentId)) {
      obj.contentVelocity = 3.5;
      obj.boostVelocity = 5;
      obj.boostStrength = 4;
      obj.footVolume = 1.5;
    } else if ([4].includes(content.contentId)) {
      obj.contentVelocity = 8.5;
      obj.boostVelocity = 4;
      obj.boostStrength = 0.8;
    } else if ([6].includes(content.contentId)) {
      obj.contentVelocity = 6;
      obj.boostVelocity = 0;
      obj.boostStrength = 0;
    } else if ([7].includes(content.contentId)) {
      obj.contentVelocity = 5.3;
      obj.boostVelocity = 5;
      obj.boostStrength = 2.5;
    } else if ([8].includes(content.contentId)) {
      obj.contentVelocity = 2;
      obj.boostVelocity = 5;
      obj.boostStrength = 4;
    } else if ([9].includes(content.contentId)) {
      obj.contentVelocity = 3.3;
      obj.boostVelocity = 5;
      obj.boostStrength = 4;
    } else if ([10].includes(content.contentId)) {
      obj.contentVelocity = 4;
      obj.boostVelocity = 0;
      obj.boostStrength = 0;
      obj.footVolume = 0.9;
      obj.volume = 1.3;
    } else {
      obj.contentVelocity = 4;
      obj.boostVelocity = 0;
      obj.boostStrength = 0;
    }

    return obj;
  }, [content]);

  const saveActivityHistory = useCallback((reportData) => {
    if (reportData.startTime !== undefined) {
      const curDate = new Date();
      const isoDateTime = HelperMethods.timeToISO8601Str(curDate);

      const history = Object.assign({}, reportData);
      history.contentId = content.contentId;
      history.date = isoDateTime;
      history.endTime = isoDateTime;
      delete history.progressHeight; // report 용으로 추가한 건이라 데이터 저장에선 제외

      console.log('postActivityStroll...', history)
      // dispatch(updateProgressInfos({ date: HelperMethods.convertDateToString2(curDate), value: history }));
      // dispatch(postActivityStroll(history));
    }
  }, [content]);

  const getkneeGuideLineStyle = useCallback((position, videoTarget) => {
    const style = {};
    const video = videoTarget || document.querySelector("video");
    if (Array.isArray(position) && video && typeof window === "object") {
      const p1 = position[0], p2 = position[1];
      const scale = video.clientHeight / cameraSize.height;
      const cameraCenter = cameraSize.width / 2;
      const videoCenter = video.clientWidth / 2;

      const topPos = p1[1] * scale;
      const leftPos = (Math.min(p1[0], p2[0]) - cameraCenter) * scale + videoCenter; // 카메라 중앙에서 왼쪽 좌표까지 거리(scale 처리) + 비디오 중앙
      const rightPos = (Math.max(p1[0], p2[0]) - cameraCenter) * scale + videoCenter; // 카메라 중앙에서 오른쪽 좌표까지 거리(scale 처리) + 비디오 중앙

      style.width = (rightPos - leftPos) + 100; // 전체 너비 좌우 50씩 늘리기
      style.top = topPos;
      style.left = (video.clientWidth - rightPos) - 50; // 좌측 - 50, 카메라 반전
      style.right = (video.clientWidth - leftPos) + 50; // 우측 + 50, 카메라 반전

      console.log('getkneeGuideLineStyle...', { top: p1[1], left: Math.min(p1[0], p2[0]), right: Math.max(p1[0], p2[0]) }, style);
      return style;
    }
    return null;
  }, [cameraSize]);

  return useMemo(() => {
    return { activityTypeRef, contentInfo, saveActivityHistory, getkneeGuideLineStyle };
  }, [contentInfo, saveActivityHistory, getkneeGuideLineStyle]);
};

export default useActivity;
