import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import css from "./BodyBalanceTabDetails.module.less";
import { $L } from "../../../../../utils/helperMethods";

import BodyBalanceTabDetailsExplain from "../../../../../../assets/bodyBalance/img_balance_detail_explain.png";
import { bodyBalanceMenu } from "../Constants";
import { bodyBalanceViewDetails } from "../feedback/feedbackBodyBalance";
import JMediaList, {
  LIST_TYPE,
} from "../../../../../components/JMediaList/JMediaList";
import SMediaList, { ITEM_SIZE, TYPES } from "../../../../../components/SMediaList/SMediaList";
import useContent from "../../../../../hooks/useContent";
import TButton, { SIZES } from "../../../../../components/TButton/TButton";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TVerticalPagenator from "../../../../../components/TVerticalPagenator/TVerticalPagenator";
import SMediaItem from "../../../../../components/SMediaList/SMediaItem";
import THorizontalScrollList from "../../../../../components/THorizontalScrollList/THorizontalScrollList";


const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);
const SpottableComponent = Spottable("div");

const PAGENATE = [
  "BODY_BALANCE_TAB_DETAILS_0",
  "BODY_BALANCE_TAB_DETAILS_1",
  "BODY_BALANCE_TAB_DETAILS_2",
  "BODY_BALANCE_TAB_DETAILS_3",
  "BODY_BALANCE_TAB_DETAILS_4",
];

const BodyBalanceTabDetails = ({
  tabType = bodyBalanceMenu.BODY_BALANCE_TAB,
  checkConnectionMat,
  ...rest
}) => {
  const { contentList } = useContent({
    type: "recommend",
    subType: "bodyAlignment", // 임시본
  });

  const [feedback, setFeedback] = useState({});
  const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const onPageChanged = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  useEffect(() => {
    setFeedback(bodyBalanceViewDetails(tabType));
  }, []);

  return (
    <TVerticalPagenator
      className={css.scroller}
      pageSpotIds={PAGENATE}
      onPageChanged={onPageChanged}
      visible={"visible"}
    >
      {/* page: BODY_BALANCE_TAB_DETAILS_0 */}
      <Container className={css.infoBox} spotlightId={PAGENATE[0]}>
        <div className={css.info}>
          <img src={BodyBalanceTabDetailsExplain} alt="" />
          <div className={css.bodyBalanceTabInfo}>
            <div className={css.title}>{feedback?.mainTitle}</div>
            <div className={css.desc}>{feedback?.mainDesc}</div>
            <div className={css.source}>
              {$L(
                "(출처: Successful 10-second one-legged stance performance predicts survival in middle-aged and older individuals)"
              )}
            </div>
          </div>
        </div>
      </Container>

      {/* page: BODY_BALANCE_TAB_DETAILS_1 */}
      <Container className={css.diseasesListBox} spotlightId={PAGENATE[1]}>
        <div
          className={css.title}
          style={{ padding: "0 60px", marginBottom: "28px" }}
        >
          {$L("Impact in daily life")}
        </div>
        <div className={css.diseasesList}>
          {feedback?.diseases?.map((item, index) => (
            <div key={`disease-${index}`} className={classNames(css.desc)}>
              {item}
            </div>
          ))}
          {feedback?.diseases?.length > 1 && <div className={css.divider} />}
          {feedback?.diseases?.length > 2 && (
            <div className={classNames(css.divider, css.second)} />
          )}
        </div>
      </Container>

      {/* page: BODY_BALANCE_TAB_DETAILS_2 */}
      <Container className={css.exerciseLayer} spotlightId={PAGENATE[2]}>
        <div className={css.title} style={{ padding: "0 60px" }}>
          <span className={css.icon} />
          {$L("Recommend Exercise")}
        </div>
        <div className={css.pictogramLayer}>
          {feedback?.exercise?.map((item, index) => (
            <SpottableComponent
              key={"pictogram_" + index}
              className={classNames(
                css.pictogram,
                index === selectedExerciseIndex && css.selected
              )}
              onClick={() => setSelectedExerciseIndex(index)}
            >
              <span
                className={css.picIcon}
                // style={{
                //   backgroundImage: `url(${pic.icon})`,
                // }}
              >
                #
              </span>
              {item?.title}
            </SpottableComponent>
          ))}
        </div>
        <div className={css.exerciseFeedbackLayer}>
          <div className={css.exerciseBox}>
            <span className={css.dotPoint} />
            <span>{`${$L("Workout Name")} : ${
              feedback?.exercise?.[selectedExerciseIndex]?.title
            }`}</span>
          </div>
          <div className={css.exerciseBox}>
            <span className={css.dotPoint} />
            <span>{`${$L("운동 설명")} : ${
              feedback?.exercise?.[selectedExerciseIndex]?.desc
            }`}</span>
          </div>
        </div>
      </Container>

      {/* page: BODY_BALANCE_TAB_DETAILS_3 */}
      <Container spotlightId={PAGENATE[3]}>
        <THorizontalScrollList startGap={60}>
          {contentList["KNEE_FLEXION"] && contentList["KNEE_FLEXION"].length > 0 && contentList["KNEE_FLEXION"].map((item, index) => (
          <SMediaItem
            key={`content_${index}`}
            type={TYPES.withCheck}
            itemInfo={item}
            // onClick={onItemClick}
          />
          ))}
        </THorizontalScrollList>
      </Container>

      {/* page: BODY_BALANCE_TAB_DETAILS_4 */}
      <Container className={css.bottomButtonLayer} spotlightId={PAGENATE[4]}>
        <TButton className={css.button} size={SIZES.test} disabled>
          {$L("Workout")}
        </TButton>
        <TButton
          className={css.button}
          size={SIZES.test}
          onClick={checkConnectionMat}
        >
          {$L("Test")}
        </TButton>
      </Container>
    </TVerticalPagenator>
  );
};

export default BodyBalanceTabDetails;
