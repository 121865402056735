import classNames from 'classnames';
import React, { useEffect, useCallback } from "react";
import VirtualGridList from "@enact/sandstone/VirtualList";
import ri from '@enact/ui/resolution';
import css from "./TMediaVerticalList.module.less";
import {$L} from '../../utils/helperMethods';
import TMediaVerticalItem from "./TMediaVerticalItem";


const TMediaVerticalList = ({className, products, itemLayout, onItemClick, ...rest}) => {
  const {width, height, gap, rowCount, colCount} = itemLayout

  const renderItem = useCallback(({index, ...rest}) => {
    return (
      <TMediaVerticalItem
        index={index}
        {...rest}
        itemInfo={products[index]}
        onItemClick={onItemClick}
      />
    );
  },[products, onItemClick]);

  return (
    <div 
      className={classNames(
        css.tMediaList,
        className ? className : null,
      )}
      style={{width: ri.scale((width + gap) * rowCount - gap), height: ri.scale((height + gap) * colCount - height / 1.5)}}>
      {products && products.length > 0 ?
        <VirtualGridList
          dataSize={products.length}
          itemSize={{minWidth: ri.scale(width), minHeight: ri.scale(height)}}
          itemRenderer={renderItem}
          spacing={ri.scale(gap)}
          horizontalScrollbar="hidden"
          // hoverToScroll
          snapToCenter={true}
          scrollMode="translate"
          noScrollByWheel={false}
        ></VirtualGridList>
      :
        <div>{$L("No Media")}</div>
      }
    </div>
  );
}

export default TMediaVerticalList;
