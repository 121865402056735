import React, {useState, useEffect, useMemo, useCallback} from 'react';
import SubBody from '../SubBody';
import TermsConditions from './TermsConditions'
import classNames from 'classnames';

const SubTerms = ({className, ...rest}) => {
  return (
    <div className={(classNames(className))}>
      <TermsConditions/>
    </div>
  );
};

export default SubTerms;