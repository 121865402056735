import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from 'classnames';
import css from "./HomecareProgramMain.module.less";
import { addPanels } from "../../features/panels/panelsSlice";
import THeader from "../../components/THeader/THeader";
import {
  $L,
  getRectDiff,
  isChildFullyShowing,
} from "../../utils/helperMethods";
import TPanel from "../../components/TPanel/TPanel";
import TBody from "../../components/TBody/TBody";

import Spottable from '@enact/spotlight/Spottable';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";

import * as Config from "../../utils/Config";
import * as SoundEffect from "../../utils/SoundEffect";
import { convertNewlinesToBr } from "../../utils/helperMethods";
import useUserCheck from "../../hooks/useUserCheck";
import { changeThemeModeStatus } from "../../features/common/commonSlice";
import TVerticalPagenator from "../../components/TVerticalPagenator/TVerticalPagenator";
import useContent from "../../hooks/useContent";
import MainSection from "./MainSection/MainSection";
import Scroller from "@enact/ui/Scroller";
import SMediaList, {ITEM_SIZE as MSIZE} from "../../components/SMediaList/SMediaList";
import { getContent, getWeeklySolution } from "../../features/fitService/fitServiceSlice";

import program_img_01 from "../../../assets/HomecareProgram/img_program_01.png";
import program_img_02 from "../../../assets/HomecareProgram/img_program_02.png";
import testIcon from "../../../assets/icon/test_Icon.png";
import THorizontalScrollList, { ITEM_SIZE } from "../../components/THorizontalScrollList/THorizontalScrollList";
import SMediaItem from "../../components/SMediaList/SMediaItem";


const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);
const SpottableComponent = Spottable("div");

const ProgramList = () => {
  const dispatch = useDispatch();
  const centerList = useSelector((state) => state.centerData.centerList);

  useEffect(() => {
    // todo remove
    dispatch(getWeeklySolution());
  },[]);

  const ITEMS = useMemo(() => {
    return [
      {
        img: program_img_01,
        title: $L("제이엔에스 바디플로우"),
        subtitle: $L(
          "힘의 진화 풀에디션 : 근력 증진을 위한 특별한 운동 프로그램"
        ),
        progress: $L("1주차"),
        progressStatus: $L("In progress"),
        icon: testIcon,
        currentStatus: $L("Yes, twice/three times."),
      },
      {
        img: program_img_02,
        title: $L("Pilates in New York"),
        subtitle: $L(
          "힘의 진화 풀에디션 : 근력 증진을 위한 특별한 운동 프로그램"
        ),
        progress: $L("1주차"),
        progressStatus: $L("In progress"),
        icon: testIcon,
        currentStatus: $L("Yes, twice/three times."),
      },
    ];
  }, []);

  const _onClick = useCallback((item) => (ev) => {
    if (item) {
      dispatch(addPanels({ name: Config.panel_names.HOMECARE_CENTER_INFO, panelInfo: item}))
    }
  }, []);

  return (
    <THorizontalScrollList startGap={75} itemSize={ITEM_SIZE.xLarge}>
      {centerList && centerList.length > 0  && centerList.map((item, index) => (
        item.progressStatus === "inProgress" &&
        <SpottableComponent
          key={"program"+index}
          className={css.programContainer}
          onClick={_onClick(item)}
        >
          <div className={css.contentImage} style={{backgroundImage: `url(${item.logoLarge})`}}/>
          <div className={css.contentBox}>
            <div className={css.contentTitle}>{item.title}</div>
            <div className={css.contentSubtitle}>{item.subTitle}</div>
            <div className={css.contentBottomBox}>
              <div className={css.contentBottomText}>
                <span>{item.week+$L("주차")}</span>&nbsp;
                {$L("In progress")}
              </div>
              <div className={css.contentBottomText}>
                <img src={item.icon} alt="program_icon" />
                {item.currentStatus+$L("회 완료")}
              </div>
            </div>
          </div>
        </SpottableComponent>
      ))}
    </THorizontalScrollList>
  );
};

const HomecareProgramMain = ({ ...rest }) => {
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(0);
  const { contentList } = useContent({ type: "recommend", subType: "bodyAlignment" });

  useEffect(() => {
    if (pageIndex === 0) {
      dispatch(changeThemeModeStatus("transparent"));
    } else {
      dispatch(changeThemeModeStatus("light"));
    }
    return () => dispatch(changeThemeModeStatus("light"));
  }, [pageIndex]);


  const ITEMS = useMemo(() => {
    return (
      [
        { type: 'routine', target: Config.panel_names.HOMECARE_WORKOUT_OPTIONS},
        { type: 'recommend', target: Config.panel_names.HOMECARE_RECOMMEND_WORKOUT},
      ]
  )});


  const onClickItem = useCallback((item) => (ev) => {
    dispatch(addPanels({ name: item.target }));
  }, [])

  const onPageChanged = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  const onMediaItemClick = useCallback(({ itemInfo }) => {
    if (itemInfo) {
      dispatch(getContent({ contentId: itemInfo.contentId })); // focus? click?
    }
    dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { contentId: itemInfo?.contentId } }));
  }, []);

  return (
    <>
      <div className={classNames(css.themeImage, pageIndex > 0 && css.display)} />
      <div
        className={classNames(css.bgGradient, pageIndex > 0 && css.display)}
      />
      <TPanel className={css.panel} {...rest}>
        <div
          className={classNames(css.themeImage, pageIndex > 0 && css.display)}
          style={{ backgroundPosition: "right" }}
        />
        <div
          className={classNames(
            css.bgGradient,
            css.top,
            pageIndex > 0 && css.display
          )}
        />
        <div
          className={classNames(css.bgGradient, pageIndex > 0 && css.display)}
        />
        <THeader title={$L("My Workout Program")}/>
        <TBody className={css.tBody}>
          <TVerticalPagenator onPageChanged={onPageChanged} pageSpotIds={['homecare_firstpage', 'homecare_secondpage']}>
            {/* firstPage */}
            <Container className={css.firstPage} spotlightId={'homecare_firstpage'}>
              <div className={css.flexBox}>
                {ITEMS.map((item, index) => {
                  return (
                    <MainSection key={"item" + index} type={item.type} onClick={onClickItem(item)}/>
                    );
                  })
                }
              </div>
              <div className={css.scrollGuide}>{$L("Scroll down")}</div>
            </Container>
            {/* second page */}
            <Container
              className={css.secondPage}
              spotlightId={"homecare_secondpage"}
            >
              <div
                className={css.mediaListTitle}
                style={{ paddingTop: "60px" }}
              >
                {$L("진행 중인 O2O 정규프로그램")}
              </div>
              <ProgramList />
              <div
                className={css.mediaListTitle}
                style={{ paddingTop: "40px" }}
              >
                {$L("AI 맞춤형 운동추천 이어하기")}
              </div>

            <THorizontalScrollList startGap={75} className={css.contentList}>
            {contentList["HEAD_SHIFT"] && contentList["HEAD_SHIFT"].length > 0 && contentList["HEAD_SHIFT"].map((item, index) => (
              <SMediaItem
                key={`content_${index}`}
                itemInfo={item}
                onClick={onMediaItemClick}
              />
            ))}
            </THorizontalScrollList>
          </Container>
          </TVerticalPagenator>
        </TBody>
      </TPanel>
    </>
  );
};

export default HomecareProgramMain;
