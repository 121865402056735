import React, { useCallback } from "react";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import css from "./JMediaListPanel.module.less";
import dummyJVideoList from "../../../assets/mock/dummyJVideoList.json";
import JMediaList, { LIST_TYPE } from "../../components/JMediaList/JMediaList";
import ri from "@enact/ui/resolution";

const JMediaListPanel = ({ ...rest }) => {
  const onItemClick = useCallback(({ index, itemInfo, ev }) => {
    console.log("JMediaListLayout onItemClick", index, itemInfo, ev);
  }, []);

  return (
    <TPanel {...rest}>
      <THeader title={"JMedia Test"} />
      <TBody>
        <>
          <JMediaList
            title={"MediaList Large"}
            listType={LIST_TYPE.large}
            contents={dummyJVideoList.data}
            onItemClick={onItemClick}
            startGap={ri.scale(120 * 2)}
          />
        </>
      </TBody>
    </TPanel>
  );
};

export default JMediaListPanel;
