import { $L } from '../../../../utils/helperMethods';
import standImg from "../../../../../assets/rom/test_left.png";
import test1 from "../../../../../assets/rom/test1.png";
import test2 from "../../../../../assets/rom/test2.png";
import ready from "../../../../../assets/video/rom/0_Ready.mp4";
import frontGuide from "../../../../../assets/video/rom/frontGuide.mp4";
import rightGuide from "../../../../../assets/video/rom/rightGuide.mp4";
import leftGuide from "../../../../../assets/video/rom/leftGuide.mp4";
import NeckLateralFlexion_Front from "../../../../../assets/video/rom/1_NeckLateralFlexion.mp4";
import NeckFlexionExtension_Right from "../../../../../assets/video/rom/2_NeckFlexionExtension.mp4";
import ShoulderInternalRotation_ExternalRotation_Right from "../../../../../assets/video/rom/3_ShoulderInternalRotation_ExternalRotation.mp4";
import ShoulderFlexion_Extension_Right from "../../../../../assets/video/rom/4_ShoulderFlexion_Extension.mp4";
import ShoulderInternalRotation_ExternalRotation_Left from "../../../../../assets/video/rom/5_ShoulderInternalRotation_ExternalRotation.mp4";
import ShoulderFlexion_Extension_Left from "../../../../../assets/video/rom/6_ShoulderFlexion_Extension.mp4";
import ShoulderAbduction_Front from "../../../../../assets/video/rom/7_Shoulder_Abduction.mp4";
import TrunkLeftRightLateralFlexion_Front from "../../../../../assets/video/rom/8_TrunkLeftRightLateralFlexion.mp4";
import TrunkFlexion_Extension_Right from "../../../../../assets/video/rom/9_TrunkFlexion_Extension.mp4";
import HipFlexion_Extension_Right from "../../../../../assets/video/rom/10_HipFlexion_Extension.mp4";
import HipFlexion_Extension_Left from "../../../../../assets/video/rom/11_HipFlexion_Extension.mp4";

import Knee_Flexion_Left from "../../../../../assets/video/rom/12_Knee_Flexion_Left.mp4";
import Knee_Flexion_Right from "../../../../../assets/video/rom/12_Knee_Flexion_Right.mp4";
// import Knee_Flexion from "../../../../../assets/video/rom/12_Knee_Flexion.mp4";

import ready_Img from "../../../../../assets/video/rom/0_Ready.png";
import frontGuide_Img from "../../../../../assets/video/rom/frontGuide.png";
import rightGuide_Img from "../../../../../assets/video/rom/rightGuide.png";
import leftGuide_Img from "../../../../../assets/video/rom/leftGuide.png";
import NeckLateralFlexion_Front_Img from "../../../../../assets/video/rom/1_NeckLateralFlexion.png";
import NeckFlexionExtension_Right_Img from "../../../../../assets/video/rom/2_NeckFlexionExtension.png";
import ShoulderInternalRotation_ExternalRotation_Right_Img from "../../../../../assets/video/rom/3_ShoulderInternalRotation_ExternalRotation.png";
import ShoulderFlexion_Extension_Right_Img from "../../../../../assets/video/rom/4_ShoulderFlexion_Extension.png";
import ShoulderInternalRotation_ExternalRotation_Left_Img from "../../../../../assets/video/rom/5_ShoulderInternalRotation_ExternalRotation.png";
import ShoulderFlexion_Extension_Left_Img from "../../../../../assets/video/rom/6_ShoulderFlexion_Extension.png";
import ShoulderAbduction_Front_Img from "../../../../../assets/video/rom/7_Shoulder_Abduction.png";
import TrunkLeftRightLateralFlexion_Front_Img from "../../../../../assets/video/rom/8_TrunkLeftRightLateralFlexion.png";
import TrunkFlexion_Extension_Right_Img from "../../../../../assets/video/rom/9_TrunkFlexion_Extension.png";
import HipFlexion_Extension_Right_Img from "../../../../../assets/video/rom/10_HipFlexion_Extension.png";
import HipFlexion_Extension_Left_Img from "../../../../../assets/video/rom/11_HipFlexion_Extension.png";
// import Knee_Flexion_Img from "../../../../../assets/video/rom/12_Knee_Flexion.png";
import Knee_Flexion_Left_Img from "../../../../../assets/video/rom/12_Knee_Flexion_Left.png";
import Knee_Flexion_Right_Img from "../../../../../assets/video/rom/12_Knee_Flexion_Right.png";

export const ROM_POSITION_TYPE = {
	FRONT: 0,
	RIGHT: 1,
	LEFT: 2
}

// 테스트 타입
export const ROM_TYPE = {
	NECK: 0,
	SHOULDER: 1,
	TRUNK: 2,
	HIP: 3,
	KNEE: 4,
}

export const SUB_TYPE = {
	NECK_LATERAL_FLEXION: 0, //Front
	NECK_FLEXION_EXTENSION: 1, //Left

	SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT: 2,
	SHOULDER_FLEXION_EXTENSION_RIGHT: 3, // Right
	SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT: 4, // Left
	SHOULDER_FLEXION_EXTENSION_LEFT: 5, // Left
	SHOULDER_ABDUCTION: 6, // Front

	TRUNK_LATERAL_FLEXION: 7, // Front
	TRUNK_FLEXION_EXTENSION: 8, // Left

	HIP_FLEXION_EXTENSION_RIGHT: 9, // Right
	HIP_FLEXION_EXTENSION_LEFT: 10, // Left

	KNEE_FLEXION_RIGHT: 11, // Right
	KNEE_FLEXION_LEFT: 12, // Left
}

// 시퀀스
export const ROM_SEQUENCE = {
	SEQUENCE_INIT: 0, //whole sequence
	GUIDE_START: 1,
	GUIDE_END: 2,
	TEST_START: 3,
	TEST_END: 4,
	SEQUENCE_FINISHED: 100 //whole sequence
}


// 세부 테스트 시퀀스
export const SUB_TEST_SEQUENCE = {
	SEQUENCE_INIT: 0,
	GUIDE_START: 1,
	GUIDE_END: 2,
	TEST_START: 3,
	GET_SCORE: 4,
	TEST_END: 5,
}

//  guideTextImg : 테스트 전 노출 이미지
// [0] : 가이드 시작, [1] : 가이드 끝 -> 테스트 시작

export const GUIDE_MSG = {}

const notOnTVTime = (typeof window === 'object' && !window.PalmSystem) ? 5 : -1;

let ROM_INFO = null;
let ROM_POSITION_INFO = null;
export const INTRO_INFO = {
	guideVideo: ready,
}
export const INTRO_AVATAR = ready_Img;
export const getROM_POSITINO_INFO = () => {
	if (ROM_POSITION_INFO) {
		return ROM_POSITION_INFO;
	}
	ROM_POSITION_INFO = [];
	ROM_POSITION_INFO[ROM_POSITION_TYPE.FRONT] = {
		type: ROM_POSITION_TYPE.FRONT,
		guideText: $L("Front"), //guide, test
		guideVideo: frontGuide,
		test:[SUB_TYPE.NECK_LATERAL_FLEXION, SUB_TYPE.SHOULDER_ABDUCTION, SUB_TYPE.TRUNK_LATERAL_FLEXION] //will be changed on ROMTest
	}
	ROM_POSITION_INFO[ROM_POSITION_TYPE.RIGHT] = {
		type: ROM_POSITION_TYPE.RIGHT,
		guideText: $L("Left"), //guide, test
		guideVideo: rightGuide,
		test:[SUB_TYPE.NECK_FLEXION_EXTENSION, SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT, SUB_TYPE.SHOULDER_FLEXION_EXTENSION_RIGHT
			, SUB_TYPE.TRUNK_FLEXION_EXTENSION, SUB_TYPE.HIP_FLEXION_EXTENSION_RIGHT, SUB_TYPE.KNEE_FLEXION_RIGHT]
	}
	ROM_POSITION_INFO[ROM_POSITION_TYPE.LEFT] = {
		type: ROM_POSITION_TYPE.LEFT,
		guideText: $L("Right"), //guide, test
		guideVideo: leftGuide,
		test:[SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT, SUB_TYPE.SHOULDER_FLEXION_EXTENSION_LEFT, SUB_TYPE.HIP_FLEXION_EXTENSION_LEFT, SUB_TYPE.KNEE_FLEXION_LEFT]
	}
	return ROM_POSITION_INFO;
}

export const getROM_INFO = () => {
	if (ROM_INFO) {
		return ROM_INFO;
	}
	ROM_INFO = [];
	ROM_INFO[ROM_TYPE.NECK] = {
		type: ROM_TYPE.NECK,
		resultName: "neck",
		guideText: [$L("Neck Guide").toUpperCase(), $L("Neck").toUpperCase()], //guide, test
		guideTextImg: [test1],
		test: [SUB_TYPE.NECK_LATERAL_FLEXION, SUB_TYPE.NECK_FLEXION_EXTENSION], //[0,1,2]
		testDetail: [$L({key: "Neck Lateral Flexion", value:"Lateral Flexion"}), $L({key:"Neck Flexion", value:"Flexion"}) + "/" + $L({key:"Neck Extension", value:"Extension"})],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
	}
	ROM_INFO[ROM_TYPE.SHOULDER] = {
		type: ROM_TYPE.SHOULDER,
		resultName: "shoulder",
		guideText: [$L("Shoulder Guide").toUpperCase(), $L("Shoulder").toUpperCase()], //guide, test
		guideTextImg: [test1, test2],
		guideVideo: "",
		test: [SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT, SUB_TYPE.SHOULDER_FLEXION_EXTENSION_RIGHT, SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT, SUB_TYPE.SHOULDER_FLEXION_EXTENSION_LEFT, SUB_TYPE.SHOULDER_ABDUCTION], //[3,4,5,6,7]
 		testDetail: [$L({key: "Shoulder External Rotation",	value: "External"}) + "/" + $L({key: "Shoulder Internal Rotation",	value: "Internal Rotation"}), $L({key: "Shoulder Flexion",	value: "Flexion"}) + "/" + $L({key: "Shoulder Extension",	value: "Extension"}), $L({key: "Shoulder Abduction",	value: "Abduction"})],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
	}
	ROM_INFO[ROM_TYPE.TRUNK] = {
		type: ROM_TYPE.TRUNK,
		resultName: "trunk",
		guideText: [$L("Trunk Guide").toUpperCase(), $L("Trunk").toUpperCase()], //guide, test
		guideTextImg: [test1, test2],
		guideVideo: "",
		test: [SUB_TYPE.TRUNK_LATERAL_FLEXION, SUB_TYPE.TRUNK_FLEXION_EXTENSION], //[8,9,10]
		testDetail: [$L({key: "Trunk Left/Right Lateral Flexion", value: "Left/Right Lateral Flexion"}), $L({key:"Trunk Flexion", value:"Flexion"}) + "/" + $L({key:"Trunk Extension", value:"Extension"})],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
	}

	ROM_INFO[ROM_TYPE.HIP] = {
		type: ROM_TYPE.HIP,
		resultName: "hip",
		guideText: [$L("Hip Guide").toUpperCase(), $L("Hip").toUpperCase()], //guide, test
		guideTextImg: [test1, test2],
		guideVideo: "",
		test: [SUB_TYPE.HIP_FLEXION_EXTENSION_RIGHT, SUB_TYPE.HIP_FLEXION_EXTENSION_LEFT], // 세부 테스트 테스트 타입 [12,13]
		testDetail: [$L({key:"Hip Flexion", value:"Flexion"}) + "/" + $L({key:"Hip Extension", value:"Extension"})],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
	}

	ROM_INFO[ROM_TYPE.KNEE] = {
		type: ROM_TYPE.KNEE,
		resultName: "knee",
		guideText: [$L("Knee Guide").toUpperCase(), $L("Knee").toUpperCase()], //guide, test
		guideTextImg: [test1, test2],
		guideVideo: "",
		test: [SUB_TYPE.KNEE_FLEXION_RIGHT, SUB_TYPE.KNEE_FLEXION_LEFT], //[11]
		testDetail: [$L({key: "_Knee Flexion",	value: "Flexion"})],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
	}
	return ROM_INFO;
}
//---------------------------------------------------
let ROM_SUB_TEST_INFO = null;
export const getROM_SUB_TEST_INFO = () => {
	if (ROM_SUB_TEST_INFO) {
		return ROM_SUB_TEST_INFO;
	}
	ROM_SUB_TEST_INFO = [];
	//Neck
	ROM_SUB_TEST_INFO[SUB_TYPE.NECK_LATERAL_FLEXION] = {
		type: SUB_TYPE.NECK_LATERAL_FLEXION,
		title: $L('Neck Lateral Flexion'),
		resultName: "neck",
		file: 'neckLateralFlexion',
		guideVideo: NeckLateralFlexion_Front,
		position: $L("Front").toUpperCase(),
		romScoreTitle: [$L("Right"), $L("Left")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "10", direction: "front" }, // 이미지 : 왼/오
		guideMsg: $L('어깨와 몸통이 함께 움직이지 않도록 잘 고정해주세요.'),
		voiceGuide: "neckSideFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.NECK_FLEXION_EXTENSION] = {
		type: SUB_TYPE.NECK_FLEXION_EXTENSION,
		title: $L('Neck Flexion') + ', ' + $L({key:'Neck Extension', value:'Extension'}),
		resultName: "neck",
		file: 'neckFlexionExtension',
		guideVideo: NeckFlexionExtension_Right,
		position: $L("Left").toUpperCase(),
		romScoreTitle: [$L("Flexion"), $L("Extension")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "11", direction: "left" },
		guideMsg: $L('몸통이 함께 움직이지 않도록 잘 고정해주세요.'),
		voiceGuide: "neckShoulderFix"
	}
	//Shoulder
	ROM_SUB_TEST_INFO[SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT] = {
		type: SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT,
		title: $L({key:'Shoulder External Rotation', value:'External'}) + ', ' + $L({key: 'Shoulder Internal Rotation', value:'Internal Rotation'}),
		resultName: "shoulder",
		file: 'shoulderExternalInternalRotationRight',
		guideVideo: ShoulderInternalRotation_ExternalRotation_Right,
		romScoreTitle: [$L("External Rotation"), $L("Internal Rotation")],
		position: $L("Left").toUpperCase(),
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "02", direction: "left" },  // 이미지 : 왼/오
		guideMsg: $L('몸통이 함께 움직이지 않도록 잘 고정해주세요.'),
		voiceGuide: "neckShoulderFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.SHOULDER_FLEXION_EXTENSION_RIGHT] = {
		type: SUB_TYPE.SHOULDER_FLEXION_EXTENSION_RIGHT,
		title: $L('Shoulder Flexion') + ', ' + $L({key:'Shoulder Extension', value:'Extension'}),
		resultName: "shoulder",
		file: 'shoulderFlexionExtensionRight',
		guideVideo: ShoulderFlexion_Extension_Right,
		position: $L("Left").toUpperCase(),
		romScoreTitle: [$L("Flexion"), $L("Extension")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "01", direction: "left" },  // 이미지 : 왼/오
		guideMsg: $L('몸통이 함께 움직이지 않도록 잘 고정해주세요.'),
		voiceGuide: "neckShoulderFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT] = {
		type: SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT,
		title: $L({key:'Shoulder External Rotation', value:'External'}) + ', ' + $L({key: 'Shoulder Internal Rotation', value:'Internal Rotation'}),
		resultName: "shoulder",
		file: 'shoulderExternalInternalRotationLeft',
		guideVideo: ShoulderInternalRotation_ExternalRotation_Left,
		position: $L("Right").toUpperCase(),
		romScoreTitle: [$L("External Rotation"), $L("Internal Rotation")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "02", direction: "right" },  // 이미지 : 왼/오
		guideMsg: $L('몸통이 함께 움직이지 않도록 잘 고정해주세요.'),
		voiceGuide: "neckShoulderFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.SHOULDER_FLEXION_EXTENSION_LEFT] = {
		type: SUB_TYPE.SHOULDER_FLEXION_EXTENSION_LEFT,
		title: $L('Shoulder Flexion') + ', ' + $L({key:'Shoulder Extension', value:'Extension'}),
		resultName: "shoulder",
		file: 'shoulderFlexionExtensionLeft',
		guideVideo: ShoulderFlexion_Extension_Left,
		position: $L("Right").toUpperCase(),
		romScoreTitle: [$L("Flexion"), $L("Extension")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "01", direction: "right" },  // 이미지 : 왼/오
		guideMsg: $L('몸통이 함께 움직이지 않도록 잘 고정해주세요.'),
		voiceGuide: "neckShoulderFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.SHOULDER_ABDUCTION] = {
		type: SUB_TYPE.SHOULDER_ABDUCTION,
		title: $L('Shoulder Abduction'),
		resultName: "shoulder",
		file: 'shoulderAbduction',
		guideVideo: ShoulderAbduction_Front,
		romScoreTitle: [$L("Right"), $L("Left")],
		position: $L("Front").toUpperCase(),
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "00", direction: "front" },  // 이미지 : 왼/오
		guideMsg: $L('몸통이 함께 옆으로 구부러지지 않도록 잘 고정해주세요.'),
		voiceGuide: "shoulderSideFix"
	}
	//Trunk
	ROM_SUB_TEST_INFO[SUB_TYPE.TRUNK_LATERAL_FLEXION] = {
		type: SUB_TYPE.TRUNK_LATERAL_FLEXION,
		title: $L({key:'Trunk Left/Right Lateral Flexion', value:'Left/Right Lateral Flexion'}),
		resultName: "trunk",
		file: 'trunkLateralFlexion',
		guideVideo: TrunkLeftRightLateralFlexion_Front,
		position: $L("Front").toUpperCase(),
		romScoreTitle: [$L("Right"), $L("Left")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "20", direction: "front" }, // 이미지 : 왼/오
		guideMsg: $L('몸통이 앞으로 구부러지지 않고 정면을 유지한 상태에서 진행해주세요.'),
		voiceGuide: "trunkSideFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.TRUNK_FLEXION_EXTENSION] = {
		type: SUB_TYPE.TRUNK_FLEXION_EXTENSION,
		title: $L('Trunk Flexion') + ', ' + $L({key: 'Trunk Extension', value:'Extention'}),
		resultName: "trunk",
		file: 'trunkFlexionExtensionRight',
		guideVideo: TrunkFlexion_Extension_Right,
		position: $L("Left").toUpperCase(),
		romScoreTitle: [$L("Flexion"), $L("Extension")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "21", direction: "left" },
		guideMsg: $L('무릎이 구부러지지 않도록 쭉 편 상태를 유지해주세요.'),
		voiceGuide: "trunkFix"
	}
	//Hip
	ROM_SUB_TEST_INFO[SUB_TYPE.HIP_FLEXION_EXTENSION_RIGHT] = {
		type: SUB_TYPE.HIP_FLEXION_EXTENSION_RIGHT,
		title: $L('Hip Flexion') + ', ' + $L({key:'Hip Extension', value:'Extension'}),
		resultName: "hip",
		file: 'hipFlexionExtensionRight',
		guideVideo: HipFlexion_Extension_Right,
		position: $L("Left").toUpperCase(),
		romScoreTitle: [$L("Flexion"), $L("Extension")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "40", direction: "left" },
		guideMsg: $L("허리가 구부러지거나 젖혀지지 않도록 허리를 편 상태를 유지해주세요."),
		voiceGuide: "hipFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.HIP_FLEXION_EXTENSION_LEFT] = {
		type: SUB_TYPE.HIP_FLEXION_EXTENSION_LEFT,
		title: $L('Hip Flexion') + ', ' + $L({key:'Hip Extension', value:'Extension'}),
		resultName: "hip",
		file: 'hipFlexionExtensionLeft',
		guideVideo: HipFlexion_Extension_Left,
		position: $L("Right").toUpperCase(),
		romScoreTitle: [$L("Flexion"), $L("Extension")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "40", direction: "right" },
		guideMsg: $L("허리가 구부러지거나 젖혀지지 않도록 허리를 편 상태를 유지해주세요."),
		voiceGuide: "hipFix"
	}
	//Knee
	ROM_SUB_TEST_INFO[SUB_TYPE.KNEE_FLEXION_RIGHT] = {
		type: SUB_TYPE.KNEE_FLEXION_RIGHT,
		title: $L({key: "_Knee Flexion",	value: "Knee Flexion"}),
		resultName: "knee",
		file: 'kneeFlexionLeft',
		guideVideo: Knee_Flexion_Left,
		position: $L("Left").toUpperCase(),
		romScoreTitle: [$L("Right"), $L("Right")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "30", direction: "left" },  // 이미지 : 왼/오
		guideMsg: $L("상체가 움직이지 않도록 잘 고정해주세요."),
		voiceGuide: "kneeFix"
	}
	ROM_SUB_TEST_INFO[SUB_TYPE.KNEE_FLEXION_LEFT] = {
		type: SUB_TYPE.KNEE_FLEXION_LEFT,
		title: $L({key: "_Knee Flexion",	value: "Knee Flexion"}),
		resultName: "knee",
		file: 'kneeFlexionRight',
		guideVideo: Knee_Flexion_Right,
		position: $L("Right").toUpperCase(),
		romScoreTitle: [$L("Left"), $L("Left")],
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 5,
		engineParams: { index: "30", direction: "right" },  // 이미지 : 왼/오
		guideMsg: $L("상체가 움직이지 않도록 잘 고정해주세요."),
		voiceGuide: "kneeFix"
	}
	return ROM_SUB_TEST_INFO;
}


export const ROM_ONTIME = [];
let SUB_ONTIME = null;

ROM_ONTIME[ROM_POSITION_TYPE.FRONT] = {
	0.1: { avatarImage: null},
	"end": { sequence: ROM_SEQUENCE.GUIDE_END, avatarImage: frontGuide_Img}
};

ROM_ONTIME[ROM_POSITION_TYPE.RIGHT] = {
	0.1: { avatarImage: null},
	"end": { sequence: ROM_SEQUENCE.GUIDE_END, avatarImage: rightGuide_Img }
};

ROM_ONTIME[ROM_POSITION_TYPE.LEFT] = {
	0.1: { avatarImage: null},
	"end": { sequence: ROM_SEQUENCE.GUIDE_END, avatarImage: leftGuide_Img }
};

// guideMsg : "제가 먼저 해볼게요. 천천히 따라해보세요~"
// testMsg : "움직일 수 있는 범위 최대로 움직이세요."
// 테스트 종료음 : RING2

export const GUIDE_START_TIME = 0.5;

export const getSUB_ONTIME = () => {
	if(SUB_ONTIME){
		return SUB_ONTIME;
	}
	SUB_ONTIME = [];
	SUB_ONTIME[SUB_TYPE.NECK_LATERAL_FLEXION] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		5.6: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		5.7: { timer: true, testMsg: true, degree: 45, testTitle: $L("Left Lateral Flexion")}, // subTest1 start
		13.0: { guideMsg: true },
		16.0: { timer: true, testMsg: true, degree: 45, testTitle: $L("Right Lateral Flexion") }, // subTest2 start
		21.0: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: NeckLateralFlexion_Front_Img },
	}
	SUB_ONTIME[SUB_TYPE.NECK_FLEXION_EXTENSION] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		6.0: { timer: true, testMsg: true, degree: 45, testTitle: $L("Flexion") }, // subTest3 start
		13.0: { guideMsg: true },
		16.0: { timer: true, testMsg: true, degree: 70, testTitle: $L("Extension") }, // subTest4 start
		21.0: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: NeckFlexionExtension_Right_Img },
	}
	//yhcho 시간 보정
	SUB_ONTIME[SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_LEFT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'negative' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		8.0: { timer: true, testMsg: true, degree: 90, testTitle: $L("External Rotation") }, // subTest5 start
		17.5: { guideMsg: true, angleGuideCanvasDirection: 'auto' },
		22.0: { timer: true, testMsg: true, degree: 90, angleGuideCanvasDirection: 'positive', testTitle: $L("Internal Rotation") }, // subTest6 start
		27.0: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: ShoulderInternalRotation_ExternalRotation_Left_Img },
	}
	SUB_ONTIME[SUB_TYPE.SHOULDER_FLEXION_EXTENSION_LEFT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'negative' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		7.0: { timer: true, testMsg: true, degree: 170, testTitle: $L({key: "_Flexion",	value: "Flexion"}) }, // subTest7 start
		14.5: { guideMsg: true, angleGuideCanvasDirection: 'auto' },
		17.5: { timer: true, testMsg: true, degree: 60, angleGuideCanvasDirection: 'positive', testTitle: $L("Extension") }, // subTest8 start
		22.5: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: ShoulderFlexion_Extension_Left_Img },
	}
	//yhcho 시간 보정
	SUB_ONTIME[SUB_TYPE.SHOULDER_EXTERNAL_INTERNAL_ROTATION_RIGHT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'positive' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		8.0: { timer: true, testMsg: true, degree: 90, testTitle: $L("External Rotation")}, // subTest9 start
		17.5: { guideMsg: true, angleGuideCanvasDirection: 'auto' },
		22.0: { timer: true, testMsg: true, degree: 90, angleGuideCanvasDirection: 'negative', testTitle: $L("Internal Rotation") }, // subTest10 start
		27.0: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: ShoulderInternalRotation_ExternalRotation_Right_Img },
	}
	SUB_ONTIME[SUB_TYPE.SHOULDER_FLEXION_EXTENSION_RIGHT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'positive' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		7.0: { timer: true, testMsg: true, degree: 170, testTitle: $L({key: "_Flexion",	value: "Flexion"}) }, // subTest11 start
		14.5: { guideMsg: true, angleGuideCanvasDirection: 'auto' },
		17.5: { timer: true, testMsg: true, degree: 60, angleGuideCanvasDirection: 'negative', testTitle: $L({key: "_Extension",	value: "Extension"}) }, // subTest12 start
		22.5: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: ShoulderFlexion_Extension_Right_Img },
	}
	SUB_ONTIME[SUB_TYPE.SHOULDER_ABDUCTION] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'positive' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END, userDegree: 'left' },
		8.0: { timer: true, testMsg: true, degree: 170, testTitle: $L("Left Abduction") }, // subTest13 start
		14.0: { guideMsg: true, userDegree: 'right', angleGuideCanvasDirection: 'auto' },
		17.5: { timer: true, testMsg: true, degree: 170, angleGuideCanvasDirection: 'negative', testTitle: $L("Right Abduction")  }, // subTest14 start
		22.5: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: ShoulderAbduction_Front_Img },
	}
	
	SUB_ONTIME[SUB_TYPE.TRUNK_LATERAL_FLEXION] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		7.0: { timer: true, testMsg: true, degree: 45, testTitle: $L("Left Lateral Flexion")  }, // subTest15 start
		13.0: { guideMsg: true },
		17.5: { timer: true, testMsg: true, degree: 45, testTitle: $L("Right Lateral Flexion") }, // subTest16 start
		22.5: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: TrunkLeftRightLateralFlexion_Front_Img },
	}
	//yhcho 시간보정
	SUB_ONTIME[SUB_TYPE.TRUNK_FLEXION_EXTENSION] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		11.5: { timer: true, testMsg: true, degree: 90, testTitle: $L("Flexion") }, // subTest17 start
		18.0: { guideMsg: true },
		29.0: { timer: true, testMsg: true, degree: 30, testTitle: $L("Extension") }, // subTest18 start
		34.0: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: TrunkFlexion_Extension_Right_Img },
	}
	
	SUB_ONTIME[SUB_TYPE.HIP_FLEXION_EXTENSION_LEFT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		2.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		9.5: { timer: true, testMsg: true, degree: 135, testTitle: $L("Flexion") }, // subTest19 start
		16.5: { guideMsg: true },
		22.5: { testMsg: true, timer: true, degree: 30, testTitle: $L("Extension") }, // subTest19 start
		27.5: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: HipFlexion_Extension_Left_Img },
	}
	
	SUB_ONTIME[SUB_TYPE.HIP_FLEXION_EXTENSION_RIGHT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		2.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		9.5: { timer: true, testMsg: true, degree: 135, testTitle: $L("Flexion") }, // subTest20 start
		16.5: { guideMsg: true },
		22.5: { timer: true, testMsg: true, degree: 30, testTitle: $L("Extension") }, // subTest19 start
		27.5: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: HipFlexion_Extension_Right_Img },
	}
	
	SUB_ONTIME[SUB_TYPE.KNEE_FLEXION_LEFT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		8.5: { timer: true, testMsg: true, degree: 135, testTitle: $L("Right Flexion") }, // subTest21 start
		14.0: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: Knee_Flexion_Left_Img, angleGuideCanvasDirection: 'auto' },
	}
	SUB_ONTIME[SUB_TYPE.KNEE_FLEXION_RIGHT] = {
		0.1: { avatarImage: null, sequence: SUB_TEST_SEQUENCE.GUIDE_START, guideMsg: true, angleGuideCanvasDirection: 'auto' },
		3.1: { sequence: SUB_TEST_SEQUENCE.GUIDE_END },
		8.5: { timer: true, testMsg: true, degree: 135, testTitle: $L("Left Flexion") }, // subTest21 start
		14.0: { voiceGuide: 'RING2' },
		"end": { sequence: SUB_TEST_SEQUENCE.GET_SCORE, noMsg: true, avatarImage: Knee_Flexion_Right_Img, angleGuideCanvasDirection: 'auto' },
	}
	return SUB_ONTIME;
}

export const initAllSubOnTime = (subType) => {
	const testOnTime = SUB_ONTIME[subType];
	const keys = Object.keys(testOnTime);
	for (let i = 0; i < keys.length; i++) {
		if (testOnTime[keys[i]]) {
			testOnTime[keys[i]].excuted = false;
		} else {
			console.warn('initAllSubOnTime: testOnTime[keys[i]] is undefined or null', keys[i]);
		}
	}
};

//key excuted = false
export const initROMOnTime = (romType) => {
	if(romType >=0){
		const testOnTime = ROM_ONTIME[romType];
		const keys = Object.keys(testOnTime);
		for (let i = 0; i < keys.length; i++) {
			testOnTime[keys[i]].excuted = false;
		}
		const subKeys = ROM_POSITION_INFO[romType].test;
		for (let i = 0; i < subKeys.length; i++) {
			initAllSubOnTime(subKeys[i]);
		}
	}
};

//데이터 초기화
export const initAllROMOnTime = () => {
	const typeKeys = Object.keys(ROM_POSITION_TYPE);
	for (let i = 0; i < typeKeys.length; i++) {
		initROMOnTime(ROM_POSITION_TYPE[i]);
	}
};
