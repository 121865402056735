import TLineChart from "../../../../components/TLineChart/TLineChart";
import css from "./DetailLayout.module.less";
import { useState } from "react";

const TLineChartLayout = () => {
  const [data1] = useState([0, 0, 120, 150, 0]);
  const [data2] = useState([135, 1500, 750, 750, 0, 100]);
  const [coordinates1, setCoordinates1] = useState([]);
  const [coordinates2, setCoordinates2] = useState([]);

  return (
    <div className={css.lineChartLayout}>
      <div>
        <p>less than or equal to 'maxRange'</p>
        <TLineChart
          onLineChartCoordinates={setCoordinates1}
          width={600}
          height={300}
          monthlyExerciseData={data1}
        />
      </div>
      <div>
        <p>greater than 'maxRange' (debug 'true')</p>
        <TLineChart
          onLineChartCoordinates={setCoordinates2}
          debug={true}
          monthlyExerciseData={data2}
          animationDurationMs={2500}
        />
      </div>
    </div>
  );
};

export default TLineChartLayout;
