import React, { useMemo, useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import css from "./BarItem.module.less";
import { $L } from "../../../../utils/helperMethods";

// "bodyAlignment", "rom", "bodyBalance", "physicalTest"
// $L("weak"), $L("borderline"), $L("good")
const BARS = ["weak", "borderline", "good"];

export const SCORE = (Id, score) => {
  switch (Id) {
    case 'bodyAlignment':
      switch (score) {
        case 1:
          return "weak"
        case 4:
          return "borderline"
        case 9:
          return "good"
        default:
          return ""
      }
    case 'ROM':
      switch (score) {
        case 1:
          return "weak"
        case 2:
          return "borderline"
        case 3:
          return "good"
        default:
          return ""
      }
    case 'bodyBalance':
      // todo
      switch (score) {
        case 1:
          return "weak"
        case 2:
          return "borderline"
        case 3:
          return "good"
        case 4:
          return "borderline"
        case 5:
          return "weak"
        default:
          return ""
      }
      // if (score <= 45) {
      //   return "weak"
      // } else if (score >= 45 && score <= 80) {
      //   return "borderline"
      // } else {
      //   return "good"
      // }
    case 'physicalTest':
      if (score > 0 && score <= 45) {
        return "weak"
      } else if (score >= 45 && score <= 80) {
        return "borderline"
      } else if (score > 80) {
        return "good"
      } else {
        return ""
      }
  }
};

const BarItem = ({ tabId= "bodyAlignment", name, score=0, className, ...rest }) => {

  return (
		<div className={classNames(
			css.barItem,
			className ? className : null,
		)}>
      {/* {name && <div className={css.name}>{$L(name)}</div>} */}
      <div className={css.bars}>
        {score !== 0 ?
          BARS.map((bar, idx) => (
            <div key={name+'_'+bar} className={css.barContainer}>
              <div className={classNames(css[bar], BARS.indexOf(SCORE(tabId, score)) >= idx ? css.active : null)}/>
              <span>
                {SCORE(tabId, score) === bar && <span className={classNames(css.emoji, css[SCORE(tabId, score)])}/>}
                {$L(bar.replace(/\b[a-z]/g, char => char.toUpperCase()))}
              </span>
            </div>
          ))
        :
          <div className={css.notScore}>
            <span className={css.alert}/>
            {$L("Test information needed.")}
          </div>
        }
      </div>
		</div>
	)
}

export default BarItem;