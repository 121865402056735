import React, { useCallback, useEffect, useMemo, useState } from "react";
import css from "./VerticalBarGraph.module.less";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { $L, convertNewlinesToBr, transNumToMonthText } from "../../../../../utils/helperMethods";
import TButton, { BORDER, COLOR, SIZES } from "../../../../../components/TButton/TButton";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import VerticalBarItem from "../VerticalBarItem/VerticalBarItem";

const order = ["score", "time"];

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const VerticalBarGraph = ({ title, itemInfo, monthList=[] }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { cesShowMode } = useSelector((state)=>state.common.localSettings);
  const lineList = useMemo(() => {
      return [
        {
          id: 0,
          text: $L("Good")
        },
        {
          id: 1,
          text: $L("Borderline")
        },
        {
          id: 2,
          text: $L("Weak")
        },
        {
          id: 3,
          text: ""
        },
      ];
  }, []);

  const maxValues = useMemo(() => {

    const value = monthList.reduce((acc, date) => {
      const data = (cesShowMode && selectedIndex === 1 ? Math.floor(itemInfo[date]?.[order[selectedIndex]] / 60)  : itemInfo[date]?.[order[selectedIndex]]) || 0;
      return Math.max(acc, data);
    }, 0);

    return value;
  }, [itemInfo, monthList, selectedIndex, cesShowMode]);

  const labels = useMemo(() =>
    monthList.map(date => {
      const [, month] = date.split('-');
      return month;
    }
  ), [monthList]);

  const onClickBottomButton = useCallback((index) => (ev) => {
    setSelectedIndex(index);
  }, []);

  return (
    <Container className={css.verticalBarGraph}>
      <div className={css.title}>{title}</div>
      {/* legend */}
      {cesShowMode && selectedIndex === 1 && (
        <div className={css.legend}>{$L("[Unit : Hours]")}</div>
      )}
      <div className={css.lineContainer}>
        {/* lines */}
        {lineList.map((line, index) => (
          <div
            key={"line" + line.id}
            className={css.lineWrapper}
          >
            {!cesShowMode && index < 2 &&
            <div className={css.number}>
              {order[selectedIndex] === "score" ?
              Number((maxValues * (lineList.length - 1 - index) / (lineList.length - 1)).toFixed(1)) :
              Number((maxValues * (lineList.length - 1 - index) / (lineList.length - 1)).toFixed(0))
              }
            </div>
            }
            <div className={classNames(css.line, (index === lineList.length - 1) && css.last)} />
            {!(cesShowMode && selectedIndex === 1) && <div className={css.text}>{line.text}</div>}
          </div>
        ))}
        {maxValues > 0 &&
        <div className={css.graphContainer}>
        {monthList.map((date, index) => {
          const data = (cesShowMode && selectedIndex === 1 ? Math.floor(itemInfo[date]?.[order[selectedIndex]] / 60) : itemInfo[date]?.[order[selectedIndex]])|| 0;
          return (
            <VerticalBarItem
              key={ "bar" + index }
              data={data}
              maxValues={maxValues && cesShowMode && selectedIndex === 0 ? 15 : maxValues}
              index={cesShowMode && selectedIndex === 1}
            />
          );
        })}
        </div>
        }
        {maxValues === 0 &&
        <div className={css.emptyContainer}>
          <div className={css.title}>{$L("There is no record of exercise yet.")}</div>
          <div className={css.text}>{convertNewlinesToBr($L("Start scanning your body and{br}begin personalized workout!"))}</div>
        </div>
        }
      </div>
      {/* month label */}
      <div className={css.labelContainer}>
        {labels && labels.length > 0 && labels.map((label, index) => (
          <div key={`month-${index}`} className={css.label}>
            {transNumToMonthText(labels[index])}
            {/* {$L("{month}M").replace("{month}", labels[index])} */}
          </div>
        ))}
      </div>
      <div className={css.buttonContainer}>
        <TButton onClick={onClickBottomButton(0)} size={SIZES.tiny} color={COLOR.transparent} border={BORDER.none} selected={selectedIndex === 0}>{$L("Analysis results")}</TButton>
        <TButton onClick={onClickBottomButton(1)} size={SIZES.tiny} color={COLOR.transparent} border={BORDER.none} selected={selectedIndex === 1}>{$L("Total exercise time")}</TButton>
      </div>
    </Container>
  );
};

export default VerticalBarGraph;
