import { createSlice } from "@reduxjs/toolkit";
import * as HelperMethods from "../../utils/helperMethods";
//tab아이콘
const notOnTv = typeof window === "object" && !window.PalmSystem;

export const DATAKEY = {
  NECK: "neck",
  SHOULDER: "shoulder",
  TRUNK: "trunk",
  KNEE: "knee",
  HIP: "hip",
};
export const rom = createSlice({
  name: "rom",
  initialState: {
    current: {
      testing: "", // neck, shoulder, trunk, knee, hip
      subTesting: "", // neck Lateral Flexion, abduction, trunk extension etc
      [DATAKEY.NECK]:{},
      [DATAKEY.SHOULDER]:{},
      [DATAKEY.TRUNK]:{},
      [DATAKEY.HIP]:{},
      [DATAKEY.KNEE]:{},
      // neck: {
      //   neckLateralFlexion: {
      //     // front
      //     value: { 0: 27, 1: 41.6 },
      //     grade: { 0: "b", 1: "a" },
      //     test_id: "10",
      //   },
      //   neckFlexionExtension: {
      //     // p: Right, e: Left
      //     value: { 0: 59.8, 1: 51.2 },
      //     grade: { 0: "a", 1: "a" },
      //     test_id: "11",
      //   },
      // },
      // shoulder: {
      //   shoulderExternalInternalRotationLeft: {
      //     // e: Left
      //     value: { 0: 52.7, 1: 89.4 },
      //     grade: { 0: "b", 1: "a" },
      //     test_id: "02",
      //   },
      //   shoulderFlexionExtensionRight: {
      //     // e: Left
      //     value: { 0: 9.3, 1: 53.6 },
      //     grade: { 0: "c", 1: "a" },
      //     test_id: "01",
      //   },
      //   shoulderExternalInternalRotationRight: {
      //     // e: right
      //     value: { 0: 81.5, 1: 46.2 },
      //     grade: { 0: "a", 1: "b" },
      //     test_id: "02",
      //   },
      //   shoulderFlexionExtensionLeft: {
      //     // e: right
      //     value: { 0: 56.5, 1: 43.7 },
      //     grade: { 0: "c", 1: "a" },
      //     test_id: "01",
      //   },
      //   shoulderAbduction: {
      //     // front
      //     value: { 0: 95.3, 1: 91 },
      //     grade: { 0: "a", 1: "a" },
      //     test_id: "00",
      //   },
      // },
      // trunk: {
      //   trunkLateralFlexion: {
      //     // front
      //     value: { 0: 23.8, 1: 24.3 },
      //     grade: { 0: "b", 1: "b" },
      //     test_id: "20",
      //   },
      //   trunkFlexionExtensionRight: {
      //     // e: Left
      //     value: { 0: 106.6, 1: 54.3 },
      //     grade: { 0: "a", 1: "a" },
      //     test_id: "21",
      //   },
      // },
      // hip: {
      //   hipFlexionExtensionRight: {
      //     // e: left
      //     value: { 0: 89.1, 1: 104.5 },
      //     grade: { 0: "b", 1: "a" },
      //     test_id: "40",
      //   },
      //   hipFlexionExtensionLeft: {
      //     // e: right
      //     value: { 0: 106.1, 1: 58.2 },
      //     grade: { 0: "a", 1: "b" },
      //     test_id: "40",
      //   },
      // },
      // knee: {
      //   kneeFlexionLeft: {
      //     // e: right
      //     value: { 0: 5.3, 1: 0.9 },
      //     grade: { 0: "c", 1: "c" },
      //     test_id: "30",
      //   },
      //   kneeFlexionRight: {
      //     // e: left
      //     value: { 0: 2.7, 1: 13.3 },
      //     grade: { 0: "c", 1: "c" },
      //     test_id: "30",
      //   },
      // },
    },
    dateList: [],
    testResults: {
      // "2023-09-11":{Neck: "Neck Lateral Flexion":{}, "Neck Flexion":{}......}
      // romScore만 저장하면 됨!
      //dummy data
      // "2023-10-23": {
      //   testing: "", // neck, shoulder, trunk, knee, hip
      //   subTesting: "", // neck Lateral Flexion, abduction, trunk extension etc
      //   neck: {
      //     neckLateralFlexion: {
      //       // front
      //       value: { 0: 27, 1: 41.6 },
      //       grade: { 0: "b", 1: "a" },
      //       test_id: "10",
      //     },
      //     neckFlexionExtension: {
      //       // p: Right, e: Left
      //       value: { 0: 59.8, 1: 51.2 },
      //       grade: { 0: "a", 1: "a" },
      //       test_id: "11",
      //     },
      //   },
      //   shoulder: {
      //     shoulderExternalInternalRotationLeft: {
      //       // e: Left
      //       value: { 0: 52.7, 1: 89.4 },
      //       grade: { 0: "b", 1: "a" },
      //       test_id: "02",
      //     },
      //     shoulderFlexionExtensionRight: {
      //       // e: Left
      //       value: { 0: 9.3, 1: 53.6 },
      //       grade: { 0: "c", 1: "a" },
      //       test_id: "01",
      //     },
      //     shoulderExternalInternalRotationRight: {
      //       // e: right
      //       value: { 0: 81.5, 1: 46.2 },
      //       grade: { 0: "a", 1: "b" },
      //       test_id: "02",
      //     },
      //     shoulderFlexionExtensionLeft: {
      //       // e: right
      //       value: { 0: 56.5, 1: 43.7 },
      //       grade: { 0: "c", 1: "a" },
      //       test_id: "01",
      //     },
      //     shoulderAbduction: {
      //       // front
      //       value: { 0: 95.3, 1: 91 },
      //       grade: { 0: "a", 1: "a" },
      //       test_id: "00",
      //     },
      //   },
      //   trunk: {
      //     trunkLateralFlexion: {
      //       // front
      //       value: { 0: 23.8, 1: 24.3 },
      //       grade: { 0: "b", 1: "b" },
      //       test_id: "20",
      //     },
      //     trunkFlexionExtensionRight: {
      //       // e: Left
      //       value: { 0: 106.6, 1: 54.3 },
      //       grade: { 0: "a", 1: "a" },
      //       test_id: "21",
      //     },
      //   },
      //   hip: {
      //     hipFlexionExtensionRight: {
      //       // e: left
      //       value: { 0: 89.1, 1: 104.5 },
      //       grade: { 0: "b", 1: "a" },
      //       test_id: "40",
      //     },
      //     hipFlexionExtensionLeft: {
      //       // e: right
      //       value: { 0: 106.1, 1: 58.2 },
      //       grade: { 0: "a", 1: "b" },
      //       test_id: "40",
      //     },
      //   },
      //   knee: {
      //     kneeFlexionLeft: {
      //       // e: right
      //       value: { 0: 5.3, 1: 0.9 },
      //       grade: { 0: "c", 1: "c" },
      //       test_id: "30",
      //     },
      //     kneeFlexionRight: {
      //       // e: left
      //       value: { 0: 2.7, 1: 13.3 },
      //       grade: { 0: "c", 1: "c" },
      //       test_id: "30",
      //     },
      //   },
      // },
    },
  },
  reducers: {
    setCurrentTestingName: (state, action) => {
      state.current.testing = action.payload;
    },
    setCurrentSubTestingName: (state, action) => {
      state.current.subTesting = action.payload;
    },
    clearCurrentTestingStatus: (state, action) => {
      if (!action.payload) {
        state.current = { testing: "" };
        state.current = { subTesting: "" };
      } else {
        if (action.payload instanceof Array) {
          action.payload.forEach((element) => {
            state.current[element] = {};
            state.current = { ...state.current };
          });
        } else {
          state.current[action.payload] = {};
          state.current = { ...state.current };
        }
      }
    },
    updateCurrentTestingStatus: (state, action) => {
      if (state.current.testing) {
        state.current[state.current.testing] = Object.assign(
          {},
          state.current[state.current.testing],
          action.payload
        );
        state.current = { ...state.current };
      }
    },
    updateDateList: (state, action) => {
      state.dateList = action.payload;
      state.dateList.sort();
    },
    clearTestResults: (state, action) => {
      state.dateList = [];
      state.testResults = {};
    },
    updateTestResults: (state, action) => {
      const { date, value } = action.payload;
      //override
      if(value.romHistoryId && state.testResults?.[date]?.romHistoryId === value.romHistoryId){
        console.log('ignore date update');
        return;
      }
      if (state.dateList.indexOf(date) < 0) {
        state.dateList.push(date);
      }
      state.testResults[date] = Object.assign({}, state.testResults[date], value);
    },
  },
});

export const {
  setCurrentTestingName,
  setCurrentSubTestingName,
  clearCurrentTestingStatus,
  updateCurrentTestingStatus,
  updateDateList,
  clearTestResults,
  updateTestResults,
} = rom.actions;

export default rom.reducer;
