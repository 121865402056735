import React, { useCallback, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import css from "./TChipsItem.module.less";

import Spottable from '@enact/spotlight/Spottable';
import { Job } from "@enact/core/util";

import * as Config from '../../utils/Config';
import * as SoundEffect from "../../utils/SoundEffect";

const SpottableComponent = Spottable("div");

const TChipsItem = ({size, shape, isDark, selected, disabled, children, onClick, className,...rest}) => {
  const [pressed, setPressed] = useState(false);
  const _onClick = useCallback((ev) => {
    if (disabled) {
      return;
    }
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick(ev);
      }
    });
	}, [onClick, disabled]);

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onBlur = useCallback(()=>{
    setPressed(false);
    clearPressedJob.current.stop();
  },[]);

  const _onFocus = useCallback(()=>{
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
  },[]);

  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        css.item, 
        selected && css.selected, 
        pressed && css.pressed, 
        disabled && css.disabled, 
        css[size],
        css[shape],
        isDark && css.isDark,
        className,
        
      )}
      onClick={_onClick}
      onFocus={_onFocus}
      onBlur={_onBlur}
    >
      <div className={css.title}>{children}</div>
    </SpottableComponent>
  );
}


export default TChipsItem;