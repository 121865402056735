import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Spottable from '@enact/spotlight/Spottable';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import css from './TVirtualSpotContainer.module.less';

const SpottableComponent = Spottable('div');

/**
 *
 * @param {string[]} colors - A 2-dimensional array of color values (rgb) contained in the `bgImage`.
 * @param {string} bgImage - An image that can be used to determine the default positions of child nodes.
 * @param {string} className - A class name that the user wants to add.
 * @param {boolean} debug - When the debug mode is set to `true`, the background-image property of the TVirtualSpotContainer is assigned the value of the `bgImage` variable. (The default value is `false`.)
 * @param {function} onSelected - A function that provides the index of the selected child component.
 * @param {function} onClick - The onclick event of the children to be passed to the container's onclick event.
 * @param {string} enterTo - Implement the Enact SpotlightContainerDecorator's enterTo property similarly. (The default value is `last-focused`.)
 */
const TVirtualSpotContainer = ({
  children,
  spotlightId,
  colors,
  bgImage,
  className,
  debug = false,
  onSelected,
  onClick,
  enterTo = 'last-focused',
  ...rest
}) => {
  const [bgImageSize, setBgImageSize] = useState({ width: 0, height: 0 });
  const [ctx, setCtx] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  const focusTimerRef = useRef(null);
  const containerRef = useRef(null);
  const { cursorVisible } = useSelector((state) => state.common.appStatus);
  const styles = useMemo(
    () =>
      debug
        ? {
            backgroundImage: `linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url(${bgImage})`,
          }
        : {
            // Add the necessary styles.
          },
    [debug, bgImage],
  );

  useEffect(() => {
    if (React.Children.count(children) !== colors?.length)
      console.warn("Warning: 'children' and 'colors' must have the same length.");
  }, [children, colors]);

  useEffect(() => {
    const image = document.createElement('img');
    image.src = bgImage;

    const handleImageLoad = () => {
      setBgImageSize({ width: image.width, height: image.height });

      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const _ctx = canvas.getContext && canvas.getContext('2d');
      _ctx.drawImage(image, 0, 0);

      setCtx(_ctx);
    };

    image.addEventListener('load', handleImageLoad);

    return () => {
      image.removeEventListener('load', handleImageLoad);
    };
  }, [bgImage]);

  useEffect(() => {
    if (onSelected) onSelected(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    if (selectedIndex !== -1 && enterTo === 'last-focused'){
      setLastSelectedIndex(selectedIndex);
    } 
  }, [selectedIndex]);

  const setSelectedIndexByMouseCoordinates = useCallback(
    (event) => {
      if (!ctx) return -1;

      const { clientX: x, clientY: y } = event;
      //it's keyboard action
      if (typeof x === 'undefined') return selectedIndex;

      const {
        current: {
          node: { offsetWidth: containerWidth, offsetHeight: containerHeight },
        },
      } = containerRef;
      const { left: containerStartX, top: containerStartY } = containerRef.current.node.getBoundingClientRect();
      const { width: bgImageWidth, height: bgImageHeight } = bgImageSize;

      const ADJUSTED_X = Math.round(((x - containerStartX) * bgImageWidth) / containerWidth);
      const ADJUSTED_Y = Math.round(((y - containerStartY) * bgImageHeight) / containerHeight);

      const pixelData = ctx.getImageData(ADJUSTED_X, ADJUSTED_Y, 1, 1).data;
      let newSelectedIndex = -1;

      for (let i = 0; i < colors.length; ++i) {
        const [referenceRed, referenceGreen, referenceBlue] = colors[i];
        const [currentRed, currentGreen, currentBlue] = [...pixelData];

        if (referenceRed === currentRed && referenceGreen === currentGreen && referenceBlue === currentBlue) {
          newSelectedIndex = i;
          break;
        }
      }

      setSelectedIndex(newSelectedIndex);

      if (debug) {
        const [r, g, b] = pixelData;
        console.log(
          `
             [Coordinates] x: ${Math.round(x - containerStartX)}, y: ${Math.round(y - containerStartY)}
          [Selected Index] ${newSelectedIndex}
          [Hex Color Code] 🟥RED: ${r}, 🟩GREEN: ${g}, 🟦BLUE: ${b}`,
        );
      }

      return newSelectedIndex;
    },
    [ctx, bgImageSize.width, bgImageSize.height, selectedIndex],
  );

  const _onClick = useCallback(
    (event) => {
      clearTimeout(focusTimerRef.current);

      const clickedIndex = setSelectedIndexByMouseCoordinates(event);

      setTimeout(() => {
        if (clickedIndex !== -1 && onClick) onClick(clickedIndex);
      }, 0);
    },
    [onClick, selectedIndex, setSelectedIndexByMouseCoordinates],
  );

  const _onMouseMove = useCallback(
    (event) => {
      setSelectedIndexByMouseCoordinates(event);
    },
    [setSelectedIndexByMouseCoordinates],
  );

  const _onKeyDown = useCallback(
    (event) => {
      const { key } = event;
      const LENGTH = React.Children.count(children);

      switch (key) {
        case 'ArrowRight': {
          if (selectedIndex < LENGTH - 1) {
            event.stopPropagation();
            setSelectedIndex((prev) => ++prev);
          }
          break;
        }
        case 'ArrowLeft': {
          if (selectedIndex > 0) {
            event.stopPropagation();
            setSelectedIndex((prev) => --prev);
          }
          break;
        }
      }
    },
    [selectedIndex],
  );

  const _onFocus = useCallback(() => {
    if (cursorVisible) return;

    const restoreIndex = lastSelectedIndex < 0 ? 0 : lastSelectedIndex;

    focusTimerRef.current = setTimeout(() => {
      setSelectedIndex(restoreIndex);
    }, 100);
  }, [cursorVisible, lastSelectedIndex]);

  const _onBlur = useCallback(() => {
    setSelectedIndex(-1);
  }, []);

  return (
    <SpottableComponent
      {...rest}
      ref={containerRef}
      className={classNames(css.container, className)}
      onMouseMove={_onMouseMove}
      onClick={_onClick}
      onKeyDown={_onKeyDown}
      onFocus={_onFocus}
      onBlur={_onBlur}
      spotlightId={spotlightId}
      style={styles}
    >
      {children}
    </SpottableComponent>
  );
};

export default TVirtualSpotContainer;
