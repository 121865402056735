import React, { useState, useCallback, useEffect, useRef } from "react";
import { $L } from '../../utils/helperMethods';

const ProgressBar = ({onTimeCounterComplete, initialTime = 30, mainTest, subTest, currentStep, totalSteps, position, currentMainTest, currentSubTest}) => {
  // console.log('ProgressBar currentMainTest', mainTest, currentMainTest, initialTime, currentStep, totalSteps)
  const canvasRef = useRef(null);
  const animation = useRef(false);
  const [positionText, setPositionText] = useState(position);

  useEffect(() => {
    if (positionText) {
      setTimeout(() => {
        setPositionText(null);
      }, 2500);
    }
  }, [positionText]);

  const makeTime = useCallback((source) => {
    source = Math.floor(source);
    let mm = Math.floor(source / 60) % 60;
    let ss = source % 60;
    if(source <= 0){
      onTimeCounterComplete();
    }
    return (mm < 10 ? "0" + mm : mm) + ":" + (ss < 10 ? "0" + ss : ss);
  },[onTimeCounterComplete])

  const drawCircle = useCallback((time) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2; //canvas area half width
    const centerY = canvas.height / 2; //canvas area half height
    const radius = (canvas.width - 24) / 2; // donut radius

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // base line
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, true);
    ctx.lineWidth = 20;
    ctx.strokeStyle = "#787776";
    ctx.lineCap = "round";
    ctx.stroke();
    ctx.fillStyle = "rgba(33, 33, 32, 0.80)";
    ctx.fill();

    //progress line
    if(currentSubTest === 3 || currentMainTest === 3){
      const angle = (time % initialTime) * (Math.PI / (initialTime / 2));
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius+6, -Math.PI / 2, -Math.PI / 2 - angle, false);
    }
    if(currentSubTest === 0){
      const angleSteps = (currentStep / totalSteps) * 2 * Math.PI;
      ctx.beginPath();
      ctx.arc(centerX, centerY, radius+6, -Math.PI / 2, -Math.PI / 2 + angleSteps, false);
    }
    ctx.lineWidth = 12;
    ctx.strokeStyle = "#21FFCD";
    if(Math.floor(-time)+1 === 0){
      ctx.strokeStyle = '#21FFCD'; // 360 degrees color
    }
    ctx.lineCap = "round";
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0.5;
    ctx.shadowColor = "#787776";
    ctx.stroke();

    if(currentSubTest === 0 || currentSubTest === 1 || currentSubTest === 3){ // init, guide start, test start
      // text
      ctx.font = "22px LGSmartUISB";
      ctx.fillStyle = "#DBD7D4";
      ctx.textAlign = "center";
      ctx.fillText(mainTest, canvas.width / 2, 75);
      // 선 그리기
      ctx.beginPath();
      ctx.moveTo(canvas.width / 2 - 61, 92);
      ctx.lineTo(canvas.width / 2 + 61, 92);
      ctx.strokeStyle = "#fff";
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.font = "24px LGSmartUIB";
      ctx.fillStyle = "#FEFEFE";
      ctx.textAlign = "center";

      const maxWidth = 130;
      // const maxWidth = 200;
      const words = subTest?.split(' ');
      let line = '';
      let lines = [];
      for(let n = 0; n < words.length; n++) {
        let testLine = line + words[n] + ' ';
        let metrics = ctx.measureText(testLine);
        let testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          lines.push(line);
          line = words[n] + ' ';
        } else {
          line = testLine;
        }
      }
      lines.push(line);

      // 각 줄을 캔버스에 그립니다.
      for (let j = 0; j < lines.length; j++) {
          ctx.fillText(lines[j], canvas.width / 2, 144 + (j * 30));  // 24 = lineheight
      }
    }

    // 위치 가이드 canvas (physicalTest 사용)
    if(currentMainTest === 3){ // testStart
      if (positionText) {
        
        ctx.font = "22px LGSmartUISB";
        ctx.fillStyle = "#DBD7D4";
        ctx.textAlign = "center";
        ctx.fillText(mainTest, canvas.width / 2, 85);

        ctx.beginPath();
        // 좌표를 구분선 중심으로 설정
        const startX = canvas.width / 2 - 61;
        const startY = 110;
        const endX = canvas.width / 2 + 61;
        const endY = 110;
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = "#fff";
        ctx.lineWidth = 1;
        ctx.stroke();
    
        ctx.font = "26px LGSmartUIB";
        ctx.fillStyle = "#FEFEFE";
        ctx.textAlign = "center";
    
        ctx.font = "26px LGSmartUISB";
        ctx.fillStyle = "#DBD7D4";
        ctx.textAlign = "center";
        // positionText를 구분선 중심에 맞춰서 그리기
        const textX = (startX + endX) / 2;
        const textY = 155;
        ctx.fillText(positionText, textX, textY);
      }
    }

  },[initialTime, makeTime, positionText, currentSubTest, currentMainTest]);

  useEffect(() => {
    let startTime;
    let rafId =null;
    let drawTag = 0;
    if(canvasRef.current !== null && typeof window === "object"){
      const animate = (timestamp) => {
        if(!animation.current){
          animation.current = true;
          startTime = timestamp;
        }
          let elapsed = timestamp - startTime;
          let time = initialTime - elapsed / 1000;

          if(currentSubTest === 3 && time <= 0){
            onTimeCounterComplete();
          }
          if(currentMainTest === 3 && initialTime !== -1 && time <= 0){
            onTimeCounterComplete();
          }
          drawTag++; // increased 60 per second
          const drawInterval = 3;
          if (drawTag % drawInterval === 0) {
            if ((currentSubTest === 3 || currentMainTest === 3) && (initialTime !== -1 || initialTime === -1)) {
              // console.log('initialTime === -1', currentStep / totalSteps);
              // testStart
              drawCircle(initialTime !== -1 ? time : currentStep / totalSteps);
            } else if (currentSubTest === 0) {
              drawCircle(time);
            }
          }
        rafId = window.requestAnimationFrame(animate);
      };
      rafId = window.requestAnimationFrame(animate);
    }
    return () => {
      animation.current = false;
      // console.log('sh unmount rafId',rafId);
      if(rafId !== null && typeof window === "object"){
        window.cancelAnimationFrame(rafId);
      }
    };
  }, [initialTime, currentStep, totalSteps, position, currentSubTest, currentMainTest]);

  return (
      <div id="canvas-wrap">
        <canvas id="canvas" ref={canvasRef} width={258} height={258}/>
      </div>
  );
}

export default ProgressBar;