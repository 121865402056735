import classNames from 'classnames';
import css from "./Subscription.module.less";
import { useMemo } from "react";
import BarItem from "./BarItem/BarItem";


const Subscription = ({ item }) => {

  const itemInfo = useMemo(() => {
    let res = {
      title: "",
      subtitle: "",
      logo: "",
      progress: { my: null, progress: null },
    };

		if(item){
      res.title = item.title;
      res.subtitle = item.subtitle;
      res.logo = item.logo;
      res.progress = {
        my: item.progress.my,
        progress: item.progress.progress,
      };
    }
    return res;
  }, [item]);

  return (
    <div className={css.container}>
      <div className={css.box}>
        <div className={css.logo} style={{backgroundImage: `url(${itemInfo.logo})`}}/>
        <div className={css.textArea}>
          <div className={css.title}>{itemInfo.title}</div>
          <div className={css.subTitle}>{itemInfo.subtitle}</div>
        </div>
      </div>
      <BarItem
        className={css.bar}
        my={itemInfo.progress.my}
        progress={itemInfo.progress.progress}
      />
    </div>
  );
};

export default Subscription;
