import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import css from "./BleTestDummy.module.less";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader/THeader";
import TBody from "../../components/TBody/TBody";
import TButton from "../../components/TButton/TButton";
import WebWorkerUtil, {WORKER_ID} from "../../utils/WebWorker/WebWorkerUtil";
import { startStopBleService } from "../../features/ble/bleSlice";
import { DATAKEY, setCurrentTestingName, clearCurrentTestingStatus, updateCurrentTestingStatus } from "../../features/bodyBalance/bodyBalanceSlice";
import TCanvasMatRaw from "../../components/TCanvasMatRaw/TCanvasMatRaw";
import useBodyBalance from "../../hooks/useBodyBalance";
import usePrevious from "../../hooks/usePrevious";

const MODE_WALKMODE = "walkmode"; //산책
const MODE_TWO_LEG_STANCE = "bodycheck1"; //양발서기
const MODE_ONE_LEG_STANCE = "bodycheck2"; //한발서기
const MODE_WALKING = "bodycheck3"; //보행분석


const BleTestDummy = ({...rest}) => {
	const dispatch = useDispatch();
	const [mode, setMode] = useState(MODE_WALKMODE);
	const [running, setRunning] = useState(false);
  	const [hitEngineRes, setHitEngineRes] = useState({});
	const {currentTestedData, saveTestResults } = useBodyBalance();
	const saveTestResultsRef = usePrevious(saveTestResults);

	const onResponseHitWorker = useCallback((e) => {
		console.log('onResponseHitWorker', e);
		if (e.type === 'response') {
			console.log('onResponseHitWorker, getWalkOutputData', e);
			setHitEngineRes(e.value);
		} else if(e.type === 'analyzeTwoLegStance' || e.type ==='analyzeSingleLegStance'){
			if(e.value){
				let engineValue = e.value;
				if(typeof e.value === 'string'){
					engineValue = JSON.parse(e.value);
				}
				setHitEngineRes(engineValue);
				dispatch(updateCurrentTestingStatus(engineValue));
			}
		}
	}, [dispatch]);

	useEffect(()=>{
		WebWorkerUtil.makeWorker(WORKER_ID.HIT, onResponseHitWorker).then(()=>{
			WebWorkerUtil.postMessage(WORKER_ID.HIT, {type:"getVersion"}, true);
		});
		dispatch(clearCurrentTestingStatus());
		return ()=>{
			saveTestResultsRef.current();
			dispatch(clearCurrentTestingStatus());
			dispatch(startStopBleService({clientId: null, mode: mode, start: false, useDummy: true}));
		}
	},[]);


	const onModeChange = useCallback((toMode)=>()=>{
		if(mode !== toMode){
			setRunning(false);
			dispatch(startStopBleService({clientId: null, mode: mode, start: false, useDummy: true}));
			setMode(toMode);
			if(mode === MODE_WALKMODE){
				WebWorkerUtil.postMessage(WORKER_ID.HIT, {type:"resetWalkMode"}, true);
			}else{
				WebWorkerUtil.postMessage(WORKER_ID.HIT, {type:"resetBalanceMode"}, true);
			}
		}
	},[dispatch, mode]);

	const startTest = useCallback(()=>{
		if(!running){
			if(mode === MODE_WALKMODE){
				WebWorkerUtil.postMessage(WORKER_ID.HIT, {type:"initWalkMode", value:[2, 1]}, true);
			}else{
				WebWorkerUtil.postMessage(WORKER_ID.HIT, {type:"initBalanceMode"}, true);
			}
		}
		switch(mode){
			case MODE_TWO_LEG_STANCE:
				dispatch(setCurrentTestingName(DATAKEY.TWO_LEG_STANCE));
				dispatch(clearCurrentTestingStatus(DATAKEY.TWO_LEG_STANCE));
				break;
			case MODE_ONE_LEG_STANCE:
				dispatch(setCurrentTestingName(DATAKEY.ONE_LEG_STANCE_L));
				dispatch(clearCurrentTestingStatus(DATAKEY.ONE_LEG_STANCE_L));
				break;
			default:
				dispatch(setCurrentTestingName(""));
				break;
		}
		setHitEngineRes({});
		dispatch(startStopBleService({clientId: null, mode: mode, start: !running, useDummy: true}));
		setRunning(!running);
	},[running, mode, dispatch]);

	const renderHitEngineRes = useCallback(()=>{
		const keys = Object.keys(hitEngineRes);
		return <div className={css.row}>
			 {keys.map((item, index) => (
					<div key={index}>{`${item} : ${hitEngineRes[item]}`}</div>
				))}
		</div>
	},[hitEngineRes]);

	const matRawData = useMemo(()=>{
		let ret = [];
		switch(mode){
			case MODE_TWO_LEG_STANCE:
				if(currentTestedData[DATAKEY.TWO_LEG_STANCE]){
					ret = currentTestedData[DATAKEY.TWO_LEG_STANCE].rawData;
				}
				break;
			case MODE_ONE_LEG_STANCE:
				if(currentTestedData[DATAKEY.ONE_LEG_STANCE_L]){
					ret = currentTestedData[DATAKEY.ONE_LEG_STANCE_L].rawData;
				}
				break;
			default:
				break;
		}
		if(ret){
			return ret;
		}
		return [];
	},[currentTestedData, mode]);

	const footPosition = useMemo(()=>{
		let ret = {};
		switch(mode){
			case MODE_TWO_LEG_STANCE:
				if(currentTestedData[DATAKEY.TWO_LEG_STANCE]){
					if(currentTestedData[DATAKEY.TWO_LEG_STANCE].left_foot_position && currentTestedData[DATAKEY.TWO_LEG_STANCE].right_foot_position){
						ret = {left:currentTestedData[DATAKEY.TWO_LEG_STANCE].left_foot_position ,
							right: currentTestedData[DATAKEY.TWO_LEG_STANCE].right_foot_position}
					}
				}
				break;
			case MODE_ONE_LEG_STANCE:
				if(currentTestedData[DATAKEY.ONE_LEG_STANCE_L].left_foot_position && currentTestedData[DATAKEY.ONE_LEG_STANCE_L].right_foot_position){
					ret = {left:currentTestedData[DATAKEY.ONE_LEG_STANCE_L].left_foot_position ,
						right: currentTestedData[DATAKEY.ONE_LEG_STANCE_L].right_foot_position}
				}
				break;
			default:
				break;
		}
		return ret;
	},[currentTestedData, mode]);

	const matReportMessage = useMemo(()=>{
		let {matStatus, matStatusValue} = currentTestedData;
		return {matStatus, matStatusValue} ;
	},[currentTestedData]);

	const renderFootPosition = useCallback(()=>{
		const keys = Object.keys(footPosition);
		return <div className={css.row}>
			 {keys.map((item, index) => (
					<div key={index}>{`${item} : ${footPosition[item]}`}</div>
				))}
		</div>
	},[footPosition]);

	return (
		<TPanel {...rest}>
			<THeader title={"Ble Test Dummy"} />
			<TBody>
				<div className={css.inline}>
					<TButton selected={mode===MODE_WALKMODE} onClick={onModeChange(MODE_WALKMODE)}>산책모드</TButton>
					<TButton selected={mode===MODE_TWO_LEG_STANCE} onClick={onModeChange(MODE_TWO_LEG_STANCE)}>양발서기</TButton>
					<TButton selected={mode===MODE_ONE_LEG_STANCE} onClick={onModeChange(MODE_ONE_LEG_STANCE)}>한발서기L</TButton>
					<TButton selected={mode===MODE_WALKING} onClick={onModeChange(MODE_WALKING)}>보행분석</TButton>
					<div>{"Status : " + (running ? "RUNNING":"IDLE")}</div>
				</div>
				<div className={css.inline}>
					<TButton onClick={startTest}>{running ? "테스트 종료" : "테스트 시작"}</TButton>
				</div>
				<div className={css.inline}>
					<div>{`Report Message : ${matReportMessage?.matStatus} - ${matReportMessage?.matStatusValue}`}</div>
				</div>
				<div className={css.inline}>
					<div className={css.responseBox}>
						<div>hitEngine Response</div>
						{renderHitEngineRes()}
					</div>
					<div className={css.responseBox}>
						<div>Foot Position</div>
						{renderFootPosition()}
					</div>
					{matRawData && matRawData.length > 0 &&
						<div className={css.responseBox}>
							<TCanvasMatRaw rawData={matRawData} width={40} height={20} />
						</div>
					}
				</div>
			</TBody>
		</TPanel>
	);
};

export default BleTestDummy;
