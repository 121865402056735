import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  weeklySolution: {
    // { [type]: {} }
  }
}

export const homecareProgram = createSlice({
  name: "homecareProgram",
  initialState,
  reducers: {
    getWeeklySolution: (state, action) => {
      state.weeklySolution = action.payload.data;
    },
    updateWeeklySolution: (state, action) => {
      state.weeklySolution = action.payload.data;
      // state.weeklySolution[type] = data;
    },
  },
});

export const {
  getWeeklySolution,
  updateWeeklySolution,
} = homecareProgram.actions;

export default homecareProgram.reducer;
