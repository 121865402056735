import React, { useMemo } from 'react';
import css from './TSummary.module.less';

export const SUMMARY_SIZE = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  lite: 'lite'
}

export const SUMMARY_ALIGN = {
  left: 'left',
  center: 'center'
}

const TSummary = ({ summary=[], idx="", size = SUMMARY_SIZE.small, align = SUMMARY_ALIGN.left, className, children, ...rest }) => {

  const summaryInfo = useMemo(() => {
		let res= {progress: "", total: "", img: ""};

		if(summary){
      res.progress = summary.progress;
      res.total = summary.total;
      res.img = summary.img;
		}
		return res;
  }, [summary]);


  return (
    <div key={`summaryInfo-${idx}`} className={css.summaryItem}>
      <img src={summaryInfo.img} alt=''/>
      <div>
        <div className={css.value}><span>{summaryInfo.progress}</span>/{summaryInfo.total}</div>
      </div>
    </div>
  );
};

export default TSummary;