import { useRef, useCallback, useEffect } from "react";

const useScrollTo = () => {
  const scrollTo = useRef();

  const scrollTop = useCallback(() => {
    if (scrollTo && scrollTo.current) {
      scrollTo.current({ position: { y: 0 }, animate: false });
    }
  }, []);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  return { getScrollTo, scrollTop };
};

export default useScrollTo;
