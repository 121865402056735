import LS2Request from './LS2Request';
import dummyBleGetDevices from './../../assets/mock/dummyBleGetDevices.json';
import dummyBleGattConnect from './../../assets/mock/dummyBleGattConnect.json';
import dummyBleGattStatus from './../../assets/mock/dummyBleGattStatus.json';


// export const startDiscovery = ({onSuccess, onFailure, onComplete}) => {
//   // if(typeof window === "object" && window.PalmSystem) {
//   //   if(process.env.REACT_APP_MODE === "DEBUG") {
//   //     console.log("LUNA startDiscovery");
//   //     return "scan......";
//   //   }else{
//       // if(scanHandle) {
//       //   scanHandle.cancel();
//       //   scanHandle = null;
//       // }
//       // scanHandle = 
//       return new LS2Request().send({
//         service: 'luna://com.webos.service.bluetooth2',
//         method: 'adapter/startDiscovery',
//         subscribe: false,
//         parameters:{},
//         onSuccess,
//         onFailure,
//         onComplete
//       })
//     //   return scanHandle;
//     // }
// //   }else{
// //     setTimeout(()=>{
// //       onSuccess();
// //       onComplete();
// //     }, 100);
// //   }
// };
export const getDummyBleValues = () => {
	return {dummyBleGetDevices,
		dummyBleGattStatus,
		dummyBleGattConnect
	}
}
export const scan = ({ onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		if (process.env.REACT_APP_MODE === "DEBUG") {
			console.log("LUNA scan");
			return "scan......";
		} else {
			return new LS2Request().send({
				service: 'luna://com.webos.service.bluetooth2',
				method: 'le/startScan',
				subscribe: true,
				parameters: {},
				onSuccess,
				onFailure,
				onComplete
			});
		}
	} else {
		setTimeout(() => {
			const res = dummyBleGetDevices;
			onSuccess(res);
			onComplete && onComplete();
		}, 100);
	}
}

//subscribe not supported
export const getDevice = ({ onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		if (process.env.REACT_APP_MODE === "DEBUG") {
			console.log("LUNA getDevice");
			return "getDevice......";
		} else {
			return new LS2Request().send({
				service: 'luna://com.webos.service.bluetooth2',
				method: 'device/getStatus',
				subscribe: false,
				parameters: {},
				onSuccess,
				onFailure,
				onComplete
			});
		}
	} else {
		setTimeout(() => {
			const res = dummyBleGetDevices;
			onSuccess(res);
			onComplete && onComplete();
		}, 100);
	}
};


export const gattGetStatus = (address, { onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.bluetooth2',
			method: 'gatt/getStatus',
			subscribe: true,
			parameters: { "address": address },
			onSuccess,
			onFailure,
			onComplete
		});
	} else {
		setTimeout(() => {
			const res = dummyBleGattStatus;
			onSuccess(res);
			onComplete();
		}, 100);
	}
};


export const gattConnect = (address, { onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		new LS2Request().send({
			service: 'luna://com.webos.service.bluetooth2',
			method: 'gatt/connect',
			parameters: { "address": address },
			onSuccess,
			onFailure,
			onComplete
		});
	} else {
		const res = dummyBleGattConnect;
		setTimeout(() => {
			onSuccess(res);
			onComplete();
		}, 100);
	}
};
export const gattDisconnect = (clientId, { onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		new LS2Request().send({
			service: 'luna://com.webos.service.bluetooth2',
			method: 'gatt/disconnect',
			parameters: { "clientId": clientId },
			onSuccess,
			onFailure,
			onComplete
		});
	} else {
		onSuccess();
	}
};
export const gattDiscoverServices = (address, { onSuccess, onFailure, onComplete }) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/discoverServices',
		parameters: { "address": address },
		onSuccess,
		onFailure,
		onComplete
	});
};

export const getServices = (address, { onSuccess, onFailure, onComplete }) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/getServices',
		parameters: { "address": address },
		onSuccess,
		onFailure,
		onComplete
	});
};

export const gattMonitorCharacteristics = (clientId, service, characteristics, { onSuccess, onFailure, onComplete }) => {
	const handle = new LS2Request().send({
		service: 'luna://com.webos.service.bluetooth2',
		method: 'gatt/monitorCharacteristics',
		subscribe: true,
		parameters: {
			"clientId": clientId,
			"service": service,
			"characteristics": characteristics
		},
		onSuccess,
		onFailure,
		onComplete
	});
	return handle;
}

// noti on <-> noti off [0, 0]
export const writeDescriptorValue = (clientId, bytes = [], service, characteristic, { onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.bluetooth2',
			method: 'gatt/writeDescriptorValue',
			subscribe: false,
			parameters: {
				"clientId": clientId,
				"service": service,
				"characteristic": characteristic,
				"descriptor": "00002902-0000-1000-8000-00805f9b34fb",
				"value": { "bytes": bytes }
			},
			onSuccess,
			onFailure,
			onComplete
		});
	} else {
		setTimeout(() => {
			const res = {};
			onSuccess(res);
			onComplete();
		}, 100);
	}
}

export const writeCharacteristicValue = (clientId, bytes = [], { onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.bluetooth2',
			method: 'gatt/writeCharacteristicValue',
			subscribe: false,
			parameters: {
				"clientId": clientId,
				"service": "531e00d0-ffa3-11ed-be56-0242ac120002",
				"characteristic": "531e00d2-ffa3-11ed-be56-0242ac120002",
				"value": { "bytes": bytes }
			},
			onSuccess,
			onFailure,
			onComplete
		});
	} else {
		setTimeout(() => {
			const res = {};
			onSuccess(res);
			onComplete && onComplete();
		}, 100);
	}
}

//usb serial
export const sendMatSerialCommand = (portNum="/dev/ttyUSB0", bytes = [], { onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.terminalmanager',
			method: 'command/sendCommand',
			subscribe: false,
			parameters: {
				"portnum": portNum,
				"command": bytes
			},
			onSuccess,
			onFailure,
			onComplete
		});
	} else {
		setTimeout(() => {
			const res = {};
			onSuccess(res);
			onComplete && onComplete();
		}, 100);
	}
}
export const receiveSerialCommand = (portNum="/dev/ttyUSB0", { onSuccess, onFailure, onComplete }) => {
	if (typeof window === "object" && window.PalmSystem) {
		const handle = new LS2Request().send({
			service: 'luna://com.webos.service.terminalmanager',
			method: 'command/receiveDatafromMAT',
			subscribe: true,
			parameters: {
				"portnum": portNum
			},
			onSuccess,
			onFailure,
			onComplete
		});
		return handle;
	}else{
		setTimeout(() => {
			const res = {};
			onSuccess(res);
			onComplete && onComplete();
		}, 100);
	}
}