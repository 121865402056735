import { useState, useCallback } from "react";
import TSimpleDatePicker from "../../../../components/TSimpleDatePicker/TSimpleDatePicker";

const TSimpleDatePickerLayout = () => {
	const NOW = new Date();
	const [year, setYear] = useState(NOW.getFullYear());
	const [month, setMonth] = useState(NOW.getMonth() + 1); //january is 1

	const onSelectedDate = useCallback(
		(res) => {
			console.log('onSelectedDate ', res);
		},
		[year, month]
	);
	const handleDateRange = useCallback((res) => {
		console.log('handleDateRange ', res);
	}, []);

	return (
		<>
			<TSimpleDatePicker
				size="small"
				width="small"
				period='quarterly'
				onSelectedDate={onSelectedDate}
				handleDateRange={handleDateRange}
			/>
			<TSimpleDatePicker
				size="small"
				width="small"
				period='monthly'
				onSelectedDate={onSelectedDate}
				handleDateRange={handleDateRange}
			/>
			<TSimpleDatePicker
				size="small"
				width="small"
				period='weekly'
				onSelectedDate={onSelectedDate}
				handleDateRange={handleDateRange}
			/>
			<TSimpleDatePicker
				size="small"
				width="small"
				period='total'
				onSelectedDate={onSelectedDate}
				handleDateRange={handleDateRange}
			/>
			<div>whitelist</div>
			<TSimpleDatePicker
				size="small"
				width="small"
				period='total'
				onSelectedDate={onSelectedDate}
				handleDateRange={handleDateRange}
				whitelist={["2023-08-15", "2023-09-16"]}
			/>
		</>
	);
};

export default TSimpleDatePickerLayout;