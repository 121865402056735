import TCalendar from '../../../../components/TCalendar/TCalendar';

const TCalendarLayout = () => {
    return (
        <>
            <TCalendar />
        </>
    );
};

export default TCalendarLayout;
