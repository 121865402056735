import classNames from 'classnames';
import React,{ useRef, useEffect, useReducer, useState, useCallback } from 'react';
import { on, off } from '@enact/core/dispatcher';
import css from './CountDownTimer.module.less';
import { $L } from '../../utils/helperMethods';

import number1 from '../../../assets/images/videoText/1.png'
import number2 from '../../../assets/images/videoText/2.png'
import number3 from '../../../assets/images/videoText/3.png'

const defaultString = $L('Up next in {sec}');
const dummyCount = 1;

const CountDownTimer = ({istring=defaultString, onZeroFired=false, hideWhenFinish=false, count = 6, onTick, onFire, onCanceled, cancelDisabled, hasStart=false, className}) => {
	const countdown = useRef(null);
	const lastMousePoint = useRef({x:-1, y:-1});
	const remainCount = useRef(count + dummyCount);
	const [countDownWorking, setCountDownWorking] = useState(true);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);

	useEffect(() => {
		countdown.current = setInterval(() => {
			const r = remainCount.current - 1;
			if (hasStart ? (r < -1 || (onZeroFired && r<=-1)) : (r < 0 || (onZeroFired && r<=0))) {
				clearCount();
				if(onFire){
					onFire();
				}
				return;
			} else {
				if(onTick){
					onTick(r);
				}
				remainCount.current = r;
			}
			forceUpdate();
		}, 1000);

		if(onTick){
			onTick(remainCount.current);
		}
		on('keydown', onKeyDownAction, window);
		on('mousemove', onMouseMoveAction, window);
		return () => {
			if (countdown.current) {
				off('keydown', onKeyDownAction, window);
				off('mousemove', onMouseMoveAction, window);
				clearInterval(countdown.current);
			}
		};
	}, []);

	const clearCount = useCallback(() => {
		if (countdown.current) {
			off('keydown', onKeyDownAction, window);
			off('mousemove', onMouseMoveAction, window);
			clearInterval(countdown.current);
			if (hasStart ? (onCanceled && remainCount.current >= -1) : onCanceled && remainCount.current >= 0) {
				onCanceled();
			}
			countdown.current = null;
			remainCount.current = count;
			setCountDownWorking(false);
		}
	}, [count, onCanceled]);

	const onKeyDownAction = useCallback((evt) => {
		if (!evt || evt.key === 'Unidentified') {
			return;
		}
		if (!cancelDisabled) {
			clearCount();
		}
	}, [cancelDisabled, clearCount]);


	const onMouseMoveAction = useCallback((evt) => {
		if(lastMousePoint.current.x > 0 && lastMousePoint.current.y > 0){
			if( Math.abs(lastMousePoint.current.y - evt.y) > 5 || Math.abs(lastMousePoint.current.x - evt.x) > 10){
				if (!cancelDisabled) {
					clearCount();
				}
			}
		}
		lastMousePoint.current= {x: evt.x, y: evt.y};
	}, [cancelDisabled, clearCount]);

	// let timeoutText = "";

	const currentCount = () => {
	  switch (remainCount.current) {
		case 0:
        return <div className={css.start}>{$L("Start")}</div>;
		case 1:
			return <div className={css.count}>1</div>;
		case 2:
			return <div className={css.count}>2</div>;
		case 3:
			return <div className={css.count}>3</div>;
		default:
			break;
	  }
	}

	// if(istring){
	// 	timeoutText = istring.replace('{sec}', remainCount.current);
	// }
	if (countDownWorking && typeof countdown.current === "number" || !hideWhenFinish) {
		return (
			<div className={classNames(className ? className : "", (remainCount > count || !countDownWorking) ? css.hide : null)}>
				{ currentCount() }
			</div>
		);
	} else {
		return null;
	}
};

export default CountDownTimer;
