import frame822 from "./frame822.png";
import frame822_B from "./frame822_B.png";
import frame822_W from "./frame822_W.png";

import img_silhouette_aspect_01 from "./wholebody/img_silhouette_aspect_01.png";
import img_silhouette_aspect_02 from "./wholebody/img_silhouette_aspect_02.png";
import img_silhouette_aspect_03 from "./wholebody/img_silhouette_aspect_03.png";
import img_silhouette_aspect_04 from "./wholebody/img_silhouette_aspect_04.png";
import img_silhouette_aspect_05 from "./wholebody/img_silhouette_aspect_05.png";
import img_silhouette_aspect_06 from "./wholebody/img_silhouette_aspect_06.png";
import img_silhouette_aspect_07 from "./wholebody/img_silhouette_aspect_07.png";
import img_silhouette_aspect_08 from "./wholebody/img_silhouette_aspect_08.png";
import img_silhouette_aspect_09 from "./wholebody/img_silhouette_aspect_09.png";
import img_silhouette_aspect_10 from "./wholebody/img_silhouette_aspect_10.png";
import img_silhouette_aspect_11 from "./wholebody/img_silhouette_aspect_11.png";
import img_silhouette_aspect_12 from "./wholebody/img_silhouette_aspect_12.png";
import img_silhouette_front_01 from "./wholebody/img_silhouette_front_01.png";
import img_silhouette_front_02 from "./wholebody/img_silhouette_front_02.png";
import img_silhouette_front_03 from "./wholebody/img_silhouette_front_03.png";
import img_silhouette_front_04 from "./wholebody/img_silhouette_front_04.png";
import img_silhouette_front_05 from "./wholebody/img_silhouette_front_05.png";
import img_silhouette_front_06 from "./wholebody/img_silhouette_front_06.png";
import img_silhouette_front_07 from "./wholebody/img_silhouette_front_07.png";
import img_silhouette_front_08 from "./wholebody/img_silhouette_front_08.png";
import img_silhouette_front_09 from "./wholebody/img_silhouette_front_09.png";

import img_silhouette_kneeflexion_bad from "./img_silhouette_kneeflexion_bad.png";
import img_silhouette_kneeflexion_borderline from "./img_silhouette_kneeflexion_borderline.png";
import img_silhouette_trunkshift_bad_back from "./img_silhouette_trunkshift_bad_back.png";
import img_silhouette_trunkshift_borderline_back from "./img_silhouette_trunkshift_borderline_back.png";
import img_silhouette_kneeflexion_good from "./img_silhouette_kneeflexion_good.png";

import img_silhouette_shouldertilt_left from "./img_silhouette_shouldertilt_left.png";
import img_silhouette_shouldertilt_right from "./img_silhouette_shouldertilt_right.png";
import img_silhouette_shouldertilt_good from "./img_silhouette_shouldertilt_good.png";

import img_silhouette_pelvictilt_good from "./img_silhouette_pelvictilt_good.png";


import img_silhouette_trunkshift_good from "./img_silhouette_trunkshift_good.png";
import img_silhouette_trunkshift_borderline_front from "./img_silhouette_trunkshift_borderline_front.png";

import img_silhouette_legalignment_good from "./img_silhouette_legalignment_good.png";

import img_silhouette_necktilt_good from "./img_silhouette_necktilt_good.png";
import img_silhouette_necktilt_left from "./img_silhouette_necktilt_left.png";
import img_silhouette_necktilt_right from "./img_silhouette_necktilt_right.png";
import img_silhouette_pelvicshift_good from "./img_silhouette_pelvicshift_good.png";

import {SCORE_MINT_KEY, SCORE_YELLOW_KEY, SCORE_RED_KEY} from "../../../src/utils/Constants";
export const WHOLEBODY_ILLUST_WIDTH = 312;
export const WHOLEBODY_ILLUST_HEIGHT = 412;

/**
 * img: []
 * baseLine : bottomDot, topDot or rightDot, leftDot
 */
export const ILLUST_IMAGE_INFO={
    "head_shift": {
        img: {[SCORE_MINT_KEY]:frame822_W, [SCORE_YELLOW_KEY]:frame822_B, [SCORE_RED_KEY]: frame822}
    },
    "knee_flexion":{
        img: {[SCORE_MINT_KEY]:img_silhouette_kneeflexion_good, [SCORE_YELLOW_KEY]:img_silhouette_kneeflexion_borderline, [SCORE_RED_KEY]: img_silhouette_kneeflexion_bad}
    },
    "shoulder_tilt":{
        img: {[SCORE_MINT_KEY]:img_silhouette_shouldertilt_good, [SCORE_YELLOW_KEY]:img_silhouette_shouldertilt_good, [SCORE_RED_KEY]: img_silhouette_shouldertilt_left},
        imgReverse: {[SCORE_MINT_KEY]:img_silhouette_shouldertilt_good, [SCORE_YELLOW_KEY]:img_silhouette_shouldertilt_good, [SCORE_RED_KEY]: img_silhouette_shouldertilt_right}
    },
    "pelvic_tilt":{
        img: img_silhouette_pelvictilt_good
    },
    "trunk_shift":{
        img: {[SCORE_MINT_KEY]:img_silhouette_trunkshift_good, [SCORE_YELLOW_KEY]:img_silhouette_trunkshift_borderline_front, [SCORE_RED_KEY]: img_silhouette_trunkshift_borderline_front},
        imgReverse: {[SCORE_MINT_KEY]:img_silhouette_trunkshift_good, [SCORE_YELLOW_KEY]:img_silhouette_trunkshift_borderline_back, [SCORE_RED_KEY]: img_silhouette_trunkshift_bad_back}
    },
    "leg_alignment_left":{
        img: img_silhouette_legalignment_good
    },
    "leg_alignment_right":{
        img: img_silhouette_legalignment_good
    },
    "neck_tilt":{
        img: {[SCORE_MINT_KEY]:img_silhouette_necktilt_good, [SCORE_YELLOW_KEY]:img_silhouette_necktilt_left, [SCORE_RED_KEY]: img_silhouette_necktilt_left} ,
        imgReverse: {[SCORE_MINT_KEY]:img_silhouette_necktilt_good, [SCORE_YELLOW_KEY]:img_silhouette_necktilt_right, [SCORE_RED_KEY]: img_silhouette_necktilt_right}
    },
    "pelvic_rotation":{
        img: img_silhouette_pelvictilt_good
    },
    "pelvic_shift":{
        img: img_silhouette_pelvicshift_good,
        baseLine: [[230,414], [230,155]]
    },
    "wholebody":{
        img: [img_silhouette_front_01, img_silhouette_aspect_01],
        "neck_tilt":{baseLine: [[186,79], [186,27]]},
        "shoulder_tilt":{baseLine: [[225,106], [147,106]]},
        "pelvic_tilt":{baseLine: [[209,223], [163,223]]},
        "pelvic_rotation":{baseLine: [[209,223], [163,223]]},
        "leg_alignment_left":{baseLine: [[163,474], [163,223], [163, 364]]}, //bottom, top, center
        "leg_alignment_right":{baseLine: [[209,474], [209,223], [209, 364]]}, //bottom, top, center

        //right
        "head_shift": {baseLine: [[198,104], [198,59]]},
        "trunk_shift":{baseLine: [[198,262], [198,104]]},
        "pelvic_shift":{baseLine: [[198,371], [198,262]]},
        "knee_flexion":{baseLine: [[198,482], [198,371]]}
    }

};

const DESCRIPTION_INDEX = 0;
const ANGLE_INDEX = 1;
const STATE_INDEX=3;

const NORMAL = 0;
const SHOULDER_LEFT = 1;
const SHOULDER_RIGHT = 2;
const NECK_LEFT = 2<<1;
const NECK_RIGHT = 2<<2;

const SHOULDER_FRONT = 1;
const SHOULDER_BACK = 2;
const KNEE_TILT = 2<<1;
const HEAD_FRONT = 2<<2;

//baseLine : bottomDot, topDot or rightDot, leftDot
export const getBaseLine = (type, testkey, testResultSub) => {
    const description = testResultSub[testkey][DESCRIPTION_INDEX];
    const state = testResultSub[testkey][STATE_INDEX];
    if(type === 'wholebody'){
        switch (testkey) {
            case "neck_tilt":
                return [[156, 61], [156, 21]];
            case "shoulder_tilt":
                return [[188, 82], [123, 82]];
            case "pelvic_tilt":
            case "pelvic_rotation":
                return [[175, 173], [136, 173]];
            case "leg_alignment_left"://bottom, top, center
                return [[136, 369], [136, 173], [136, 276]];
            case "leg_alignment_right"://bottom, top, center
                return [[175, 369], [175, 173], [175, 276]];
            case "head_shift":
                return [[166, 75], [166, 45]];
            case "trunk_shift":
                return [[166, 196], [166, 75]];
            case "pelvic_shift":
                return [[166, 285], [166, 196]];
            case "knee_flexion":
                return [[166, 363], [166, 285]];
        }
    }else{
        switch(testkey){
            case 'head_shift': return  [[182,213], [182,132]];
            case 'knee_flexion':
                if(state === SCORE_MINT_KEY){
                    return [[146,298], [146,100]];
                }else{
                    return [[156,298], [156,100]];
                }
            case 'shoulder_tilt':
                return [[234,237], [76,237]];
            case 'pelvic_tilt':
            case 'pelvic_rotation':
                return [[213,177], [97,177]];
            case 'trunk_shift':
                if(state === SCORE_MINT_KEY){
                    return [[172,350], [172,130]];
                }else{
                    return [[167,350], [167,130]];
                }
            case 'leg_alignment_left':
                return [[130,310], [130,36], [130,185]];//bottom, top, center
            case 'leg_alignment_right':
                return [[180,310], [180,36], [180,185]];//bottom, top, center
            case 'neck_tilt':
                if(state === SCORE_MINT_KEY){
                    return [[156,149],[156,35]];
                }else {
                    if(description === 'left high'){
                        return [[162,149], [162,35]];
                    }else{
                        return [[147,149], [147,35]];
                    }
                }
            case 'pelvic_shift':{
                return [[153,286], [153,109]];
            }
        }
    }
    return [];
}
export const getIllustImage = (bodyType, type, testkey, testResult) => {
  try {
    let imgSrc = "";
    if(type === 'wholebody'){
        if(bodyType === 'front'){
            let matched = NORMAL;
            const shouldAngle = Number(testResult[bodyType]["shoulder_tilt"][ANGLE_INDEX]);
            if(shouldAngle >= 4.7){
                if(testResult[bodyType]["shoulder_tilt"][DESCRIPTION_INDEX] === 'left high'){
                    matched += SHOULDER_LEFT;
                }else{
                    matched += SHOULDER_RIGHT;
                }
            }
            const neckAngle = Number(testResult[bodyType]["neck_tilt"][ANGLE_INDEX]);
            if(neckAngle >= 4.7){ //Neck 은 어깨와 반대로 동작
                if(testResult[bodyType]["neck_tilt"][DESCRIPTION_INDEX] === 'left high'){
                    matched += NECK_RIGHT;
                }else{
                    matched += NECK_LEFT;
                }
            }
            switch(matched){
                case SHOULDER_LEFT: imgSrc = img_silhouette_front_02; break;
                case SHOULDER_RIGHT: imgSrc = img_silhouette_front_03; break;
                case NECK_LEFT: imgSrc = img_silhouette_front_04; break;
                case NECK_LEFT|SHOULDER_LEFT: imgSrc = img_silhouette_front_05; break;
                case NECK_LEFT|SHOULDER_RIGHT: imgSrc = img_silhouette_front_06; break;
                case NECK_RIGHT: imgSrc = img_silhouette_front_07; break;
                case NECK_RIGHT|SHOULDER_LEFT: imgSrc = img_silhouette_front_08; break;
                case NECK_RIGHT|SHOULDER_RIGHT: imgSrc = img_silhouette_front_09; break;
                default: imgSrc = img_silhouette_front_01; break;
            }
        }else{ //side
            let matched = NORMAL;
            const shouldAngle = Number(testResult[bodyType]["trunk_shift"][ANGLE_INDEX]);
            if(shouldAngle >= 9.5 && testResult[bodyType]["trunk_shift"][DESCRIPTION_INDEX] === 'front'){
                matched += SHOULDER_FRONT;
            }else if(shouldAngle >= 9.5 && testResult[bodyType]["trunk_shift"][DESCRIPTION_INDEX] === 'back'){
                matched += SHOULDER_BACK;
            }
            const headAngle = Number(testResult[bodyType]["head_shift"][ANGLE_INDEX]);
            if(headAngle >= 9.5 && testResult[bodyType]["head_shift"][DESCRIPTION_INDEX] === 'front'){
                matched += HEAD_FRONT;
            }
            const kneeAngle = Number(testResult[bodyType]["knee_flexion"][ANGLE_INDEX]);
            if(kneeAngle >= 10 ){
                matched += KNEE_TILT;
            }
            switch(matched){
                case SHOULDER_FRONT: imgSrc = img_silhouette_aspect_02; break;
                case SHOULDER_BACK: imgSrc = img_silhouette_aspect_03; break;
                case KNEE_TILT: imgSrc = img_silhouette_aspect_04; break;
                case SHOULDER_FRONT|KNEE_TILT: imgSrc = img_silhouette_aspect_05; break;
                case SHOULDER_BACK|KNEE_TILT: imgSrc = img_silhouette_aspect_06; break;
                case HEAD_FRONT: imgSrc = img_silhouette_aspect_07; break;
                case HEAD_FRONT|SHOULDER_FRONT: imgSrc = img_silhouette_aspect_08; break;
                case HEAD_FRONT|SHOULDER_BACK: imgSrc = img_silhouette_aspect_09; break;
                case HEAD_FRONT|KNEE_TILT: imgSrc = img_silhouette_aspect_10; break;
                case HEAD_FRONT|SHOULDER_FRONT|KNEE_TILT: imgSrc = img_silhouette_aspect_11; break;
                case HEAD_FRONT|SHOULDER_BACK|KNEE_TILT: imgSrc = img_silhouette_aspect_12; break;
                default: imgSrc = img_silhouette_aspect_01; break;
            }
        }
    }else{
        const angle = Number(testResult[bodyType][testkey][ANGLE_INDEX]);
        const description = testResult[bodyType][testkey][DESCRIPTION_INDEX];
        const state = testResult[bodyType][testkey][STATE_INDEX];
        switch(testkey){
            case 'head_shift':{
                imgSrc = ILLUST_IMAGE_INFO[testkey].img[state];
                break;
            }
            case 'knee_flexion':{//실루엣 이미지 같음.
                imgSrc = ILLUST_IMAGE_INFO[testkey].img[state];
                break;
            }
            case 'shoulder_tilt': {
                if(description === 'left high'){
                    imgSrc = ILLUST_IMAGE_INFO[testkey].img[state];
                }else{
                    imgSrc = ILLUST_IMAGE_INFO[testkey].imgReverse[state];
                }
                break;
            }
            case 'pelvic_tilt':
            case 'pelvic_rotation':{
                imgSrc = ILLUST_IMAGE_INFO[testkey].img;
                break;
            }
            case "trunk_shift":{
                if(description === 'back'){
                    imgSrc = ILLUST_IMAGE_INFO[testkey].imgReverse[state];
                }else{
                    imgSrc = ILLUST_IMAGE_INFO[testkey].img[state];
                }
                break;
            }
            case 'leg_alignment_left': {
                imgSrc = ILLUST_IMAGE_INFO[testkey].img;
                break;
            }
            case 'neck_tilt': {
                if(description === 'left high'){//Neck은 어깨와 반대로 동작
                    imgSrc = ILLUST_IMAGE_INFO[testkey].imgReverse[state];
                }else{
                    imgSrc = ILLUST_IMAGE_INFO[testkey].img[state];
                }
                break;
            }
            case 'pelvic_shift': {
                imgSrc = ILLUST_IMAGE_INFO[testkey].img;
                break;
            }
        }
    }
    return imgSrc;
  } catch (e) {
    return null;
  }
}