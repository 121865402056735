import classNames from 'classnames';
import css from './PageInfo.module.less';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Question from '../Question/Question';
import { getSurveyData } from '../../MyPanel/MySureveyInfo/surveyInfo';

const TYPES = ["basic", "body", "exercise", "life"];
const surveyData = getSurveyData();

const PageInfo = ({className, index, ...rest}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const answer = useSelector((state) => state.fitService.survey);

  useEffect(() => {
    setSelectedIndex(index);
  }, [index]);

  const selectedArticles = useCallback((question) => {
    if (!answer) {
      return []
    }
    const filteredArticles = question.article.filter(item => {
      if (question.answer_form === "checkbox") {
        return answer?.[question.id]?.includes(item.seq);
      } else {
        return answer?.[question.id] === item.seq;
      }
    });

    return filteredArticles;
  }, [answer])

  return (
    <div className={classNames(className, css.questionContainer, css[TYPES[selectedIndex]])}>
      {surveyData[TYPES[selectedIndex]].question.map((question, index) =>
        <Question
          key={"question" + index}
          className={css.question}
          question={question}
          articles={question.sub_yn === "Y" ? selectedArticles(question) : []}
        />
      )}
    </div>
  );
};

export default PageInfo;