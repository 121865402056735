import React, { useEffect, useCallback } from "react";
import VirtualGridList from "@enact/sandstone/VirtualList";
import classNames from "classnames";
import ri from '@enact/ui/resolution';
import css from "./TMediaList.module.less";
import { useSelector, useDispatch } from "react-redux";
import {$L} from '../../utils/helperMethods';
import TMediaItem from "./TMediaItem";


export const LIST_TYPE = {
  "large": "large",
  "medium": "medium",
  "small": "small"
}
const LIST_ITEM_CONF = {
  [LIST_TYPE.large]: {
    ITEM_WIDTH: 420*2,//uhd size
    SPACING: 20*2
  },
  [LIST_TYPE.medium]: {
    ITEM_WIDTH: 320*2,//uhd size
    SPACING: 10*2
  },
  [LIST_TYPE.small]: {
    ITEM_WIDTH: 220*2,//uhd size
    SPACING: 10*2
  }
};
/*
리스트의 높이는 기본적으로 자동 계산되나, No Media 인 경우 별도 로직 필요
listType 은 TMediaItem 에도 구현해야함.
 */
const TMediaList = ({className, title, listType=LIST_TYPE.large, contents, onItemClick, ...rest}) => {
  const dispatch = useDispatch();

  const renderItem = useCallback(({index, ...rest}) => {
    return (
      <TMediaItem
        index={index}
        listType={listType}
        width={ri.scale(LIST_ITEM_CONF[listType].ITEM_WIDTH)}
        {...rest}
        title={contents[index].title}
        itemInfo={contents[index]}
        onItemClick={onItemClick}
      />
    );
  },[contents, onItemClick, listType]);

  return (
    <div className={className}>
      {title &&
        <div className={css.tListTitle}>{title}</div>
      }
      {contents && contents.length > 0 ?
        <VirtualGridList
          className={css.tMediaList}
          dataSize={contents.length}
          itemSize={ri.scale(LIST_ITEM_CONF[listType].ITEM_WIDTH)}
          direction="horizontal"
          itemRenderer={renderItem}
          spacing={ri.scale(LIST_ITEM_CONF[listType].SPACING)}
          noAffordance={true}
          horizontalScrollbar="hidden"
          noScrollByWheel
          // hoverToScroll
        ></VirtualGridList>
      :
        <div>{$L("No Media")}</div>
      }
    </div>
  );
}

export default TMediaList;
