import TVirtualSpotContainer from '../../../../components/TVirtualSpotContainer/TVirtualSpotContainer';
import css from './DetailLayout.module.less';
import bgImage from '../../../../../assets/TVirtualSpotContainerLayout/bg-for-test.png';
import rectangle from '../../../../../assets/TVirtualSpotContainerLayout/rectangle.png';
import star from '../../../../../assets/TVirtualSpotContainerLayout/star.png';
import polygon from '../../../../../assets/TVirtualSpotContainerLayout/polygon.png';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import TButton from '../../../../components/TButton/TButton';

const COLORS = [
  [255, 0, 0], // red
  [0, 0, 255], // blue
  [0, 255, 0], // green
];

const TEST_CONTENTS = [
  {
    image: rectangle,
  },
  {
    image: star,
  },
  {
    image: polygon,
  },
];

const TVirtualSpotContainerLayout = () => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const onSelected = useCallback((index) => {
    setFocusedIndex(index);
  }, []);

  const onClick = useCallback(() => {
    console.log('clicked index: ', focusedIndex);
  }, [focusedIndex]);

  return (
    <div className={css.container}>
      <TButton className={classNames(css.button, css.upbutton)}>UP</TButton>
      <TButton className={classNames(css.button, css.leftbutton)}>LEFT</TButton>
      <TVirtualSpotContainer
        className={css.TVirtualSpotContainerLayout}
        debug={true}
        spotlightId={'TVirtualSpotContainerLayout_test_1'}
        colors={COLORS}
        onSelected={onSelected}
        onClick={onClick}
        // enterTo=''
        bgImage={bgImage}
      >
        {TEST_CONTENTS.map((elem, i) => (
          <div
            className={classNames(css.child, i === focusedIndex && css.focused)}
            style={{ backgroundImage: `url(${elem.image})` }}
            key={i}
          >
            <p>{i === focusedIndex ? 'selected' : 'unselected'}</p>
          </div>
        ))}
      </TVirtualSpotContainer>
      <TButton className={classNames(css.button, css.rightbutton)}>RIGHT</TButton>
      <TButton className={classNames(css.button, css.downbutton)}>DOWN</TButton>
    </div>
  );
};

export default TVirtualSpotContainerLayout;
