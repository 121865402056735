import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import css from "./ContentCollection.module.less";

import { $L, convertNewlinesToBr } from "../../../utils/helperMethods";
import { addPanels } from "../../../features/panels/panelsSlice";

import ri from "@enact/ui/resolution";
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";

import { Swiper, SwiperSlide } from "swiper/react";
import CustomImage from "../../../components/CustomImage/CustomImage";

// image
import imageSenior from "../../../../assets/landingPage/contentCollection/img_thumbnail_lg_senior.jpg";
import imageProper from "../../../../assets/landingPage/contentCollection/img_thumbnail_lg_proper.jpg";
import imageMedical from "../../../../assets/landingPage/contentCollection/img_thumbnail_lg_medical.jpg";
import imageVirtual from "../../../../assets/landingPage/contentCollection/img_thumbnail_lg_virtual.jpg";
import TIconButton, {
  ICON_TYPES,
} from "../../../components/TIconButton/TIconButton";
import * as Config from "../../../utils/Config";
import { Job } from "@enact/core/util";

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);

const ITEMS = [
  {
    id: 0,
    image: imageSenior,
    target: {
      path: Config.panel_names.WORKOUT_PANEL,
      panelInfo: {
        title: $L("Senior Exercise Collection"),
        type: "virtualTraning",
        subType: "LEG_ALIGNMENT", // todo remove
        // subType: "SENIOR",
      },
    },
  },
  {
    id: 1,
    image: imageProper,
    target: {
      path: Config.panel_names.WORKOUT_PANEL,
      panelInfo: {
        title: $L("Proper Physique Workout"),
        type: "virtualTraning",
        subType: "KNEE_FLEXION", // todo remove
        // subType: "PROPER",
      },
    },
  },
  {
    id: 2,
    image: imageMedical,
    target: {
      path: Config.panel_names.WORKOUT_PANEL,
      panelInfo: {
        title: $L("Medical Workout Solution"),
        type: "virtualTraning",
        subType: "PELVIC_SHIFT", // todo remove
        // subType: "MEDICAL",
      },
    },
  },
  {
    id: 3,
    image: imageVirtual,
    target: {
      path: Config.panel_names.WORKOUT_PANEL,
      panelInfo: {
        title: $L("Virtual Home Traning"),
        type: "virtualTraning",
        subType: "SHOULDER_TILT", // todo remove
        // subType: "VIRTUAL",
      },
    },
  },
];

const ContentCollection = ({ className, ...rest }) => {
  const dispatch = useDispatch();
  const { cursorVisible } = useSelector((state) => state.common.appStatus);

  const autoScrollJob = useRef(
    new Job((func) => {
      func();
    }, Config.AUTO_SCROLL_DELAY)
  );

  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [bulletFocus, setBulletFocus] = useState(false);
  const [autoScrollState, setAutoScrollState] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const [items, setItems] = useState(ITEMS);

  const setMouseOver = useCallback(
    (b) => () => {
      setIsMouseOver(b);
    },
    []
  );

  const isCursorArrowVisible = useMemo(() => {
    return cursorVisible && isMouseOver;
  }, [isMouseOver, cursorVisible]);

  const _onClick = useCallback((item) => {
    if (item.target) {
      dispatch(
        addPanels({
          name: item.target.path,
          panelInfo: item.target.panelInfo,
        })
      );
    }
  }, []);

  const _onSlideChange = useCallback((ev) => {
    setActiveIndex(ev.activeIndex);
  }, []);

  const moveRight = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  }, []);

  const onRightScrollAreaOver = useCallback(() => {
    if (cursorVisible) {
      moveRight();
      setAutoScrollState(true);
    }
  }, [moveRight, cursorVisible]);

  const onScrollAreaOut = useCallback(() => {
    setAutoScrollState(false);
  }, []);

  const doAutoScroll = useCallback(() => {
    autoScrollState && moveRight();
  }, [autoScrollState, moveRight]);

  const onFocusIndex = useCallback(
    () => () => {
      if (swiperRef.current) {
        swiperRef.current.swiper.slideNext();
      }
    },
    []
  );

  useEffect(() => {
    if (!cursorVisible) return;
    if (!autoScrollState) {
      autoScrollJob.current.stop();
    } else {
      autoScrollJob.current.start(doAutoScroll);
    }
  }, [autoScrollState, cursorVisible, activeIndex]);

  return (
    <Container
      className={classNames(className, css.collection)}
      onMouseOver={setMouseOver(true)}
      onMouseOut={setMouseOver(false)}
    >
      <div className={css.logo} />
      <div className={css.title}>
        {convertNewlinesToBr($L("LG Virtual{br}Home Training"))}
      </div>
      <div className={css.description}>
        {$L("These are a collection of premium contents provided by LG.")}
      </div>

      {isCursorArrowVisible && (
        <div
          className={css.rButtonIcon}
          onMouseOver={onRightScrollAreaOver}
          onMouseOut={onScrollAreaOut}
        />
      )}
      <Swiper
        ref={swiperRef}
        loop={true}
        className={css.swiperContainer}
        spaceBetween={ri.scale(58 * 2)}
        slidesPerView={"auto"}
        onSlideChange={_onSlideChange}
      >
        {items.map((item, index) => {
          let convertIndex = index + 4;
          if (activeIndex === 8) {
            convertIndex += 4;
          } else if (activeIndex === 3) {
            convertIndex -= 4;
          }
          return (
            <SwiperSlide
              key={`collection-${index}`}
              className={classNames(
                css.swiperSlide,
                convertIndex === activeIndex && css.focusedImg
              )}
              virtualIndex={index}
              onClick={() => _onClick(item)}
            >
              <Container spotlightDisabled={convertIndex !== activeIndex}>
                <CustomImage src={item.image} />
              </Container>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {Array.isArray(items) && items.length > 1 && (
        <div
          className={css.paginationContainer}
          onFocus={() => setBulletFocus(true)}
          onBlur={() => setBulletFocus(false)}
        >
          <div className={classNames(css.pagination, bulletFocus && css.focus)}>
            {items.map((_, index) => {
              let convertIndex = index + 4;
              if (activeIndex === 8) {
                convertIndex += 4;
              } else if (activeIndex === 3) {
                convertIndex -= 4;
              }
              return (
                <TIconButton
                  iconType={ICON_TYPES.none}
                  key={`pagination-bullet-${index}`}
                  className={classNames(
                    css.bullet,
                    convertIndex === activeIndex && css.active
                  )}
                  onSpotlightRight={onFocusIndex(convertIndex + 1)}
                  spotlightDisabled={convertIndex !== activeIndex}
                />
              );
            })}
          </div>
        </div>
      )}
    </Container>
  );
};

export default ContentCollection;
