import classNames from "classnames";
import css from "./ToggleButton.module.less";

import { useCallback, useState, useRef } from "react";

import * as Config from "../../utils/Config";
import * as SoundEffect from "../../utils/SoundEffect";

import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

import { Job } from "@enact/core/util";
import TToolTip from "../TToolTip/TToolTip";
import { useDispatch, useSelector } from "react-redux";
import { changeAppStatus } from "../../features/common/commonSlice";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);
const SpottableComponent = Spottable("div");
/**
 *
 * @param {type} normal
 * {size} 'big', 'mid', 'small
 * withMarquee: should have absolute width
 * @returns
 */

const TYPES = {
  activity: ["walking", "hiking"],
  pose: ["pose", "photo"],
};

const ToggleButton = ({
  type = "normal",
  spotlightId,
  className,
  icon,
  color,
  label,
  isRightSelected,
  disabled,
  selectedTabIndex,
  parentRef,
  onClick,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { themeMode } = useSelector((state) => state.common.appStatus);

  const [leftPressed, setLeftPressed] = useState(false);
  const [rightPressed, setRightPressed] = useState(false);
  const [isLeftTooltip, setLeftTooltip] = useState(false);
  const [isRightTooltip, setRightTooltip] = useState(false);

  const clearPressedJob = useRef(
    new Job((func) => {
      setLeftPressed(false);
      setRightPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback(
    (index) => {
      SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
      index === 0 ? setLeftPressed(true) : setRightPressed(true);
      clearPressedJob.current.start(() => {
        if (type === TYPES.pose) {
          if (selectedTabIndex === 0) {
            dispatch(
              changeAppStatus({
                pictureTypeOverAll: index === 0 ? "illust" : "photo",
              })
            );
          } else {
            dispatch(
              changeAppStatus({
                pictureType: index === 0 ? "illust" : "photo",
              })
            );
          }
        }
      });

      if (onClick) {
        onClick(index);
      }
    },
    [selectedTabIndex, type, onClick]
  );

  const onBlur = useCallback((index) => {
    index === 0 ? setLeftPressed(false) : setRightPressed(false);
    clearPressedJob.current.stop();

    if (label) {
      index === 0 ? setLeftTooltip(false) : setRightTooltip(false);
    }
  }, []);

  const onFocus = useCallback((index) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);

    if (label) {
      index === 0 ? setLeftTooltip(true) : setRightTooltip(true);
    }
  }, []);

  const tooltipRenderer = useCallback((isTooltip, labelText, ref) => {
    return (
      <TToolTip
        relativeRef={ref}
        relativePosition={ref && "right"}
        isOpen={isTooltip}
        style={{ top: "71px" }}
      >
        {labelText}
      </TToolTip>
    );
  }, []);

  return (
    <Container
      {...rest}
      className={classNames(css.container, themeMode === "dark" && css.isDark)}
    >
      <SpottableComponent
        className={classNames(
          css.tButton,
          Array.isArray(type) && type.length >= 2 && css[type[0]],
          !isRightSelected && css.selected,
          leftPressed && css.pressed,
          disabled && css.disabled,
          className ? className : null
        )}
        onClick={() => _onClick(0)}
        onBlur={() => onBlur(0)}
        onFocus={() => onFocus(0)}
        data-webos-voice-intent={"Select"}
        data-webos-voice-label={label[0]}
        spotlightId={spotlightId}
      >
        {label && tooltipRenderer(isLeftTooltip, label[0])}
      </SpottableComponent>
      <div className={css.divider} />
      <SpottableComponent
        className={classNames(
          css.tButton,
          Array.isArray(type) && type.length >= 2 && css[type[1]],
          isRightSelected && css.selected,
          rightPressed && css.pressed,
          disabled && css.disabled,
          className ? className : null
        )}
        onClick={() => _onClick(1)}
        onBlur={() => onBlur(1)}
        onFocus={() => onFocus(1)}
        data-webos-voice-intent={"Select"}
        data-webos-voice-label={label[1]}
        spotlightId={spotlightId}
      >
        {label && tooltipRenderer(isRightTooltip, label[1], parentRef)}
      </SpottableComponent>
    </Container>
  );
};

export default ToggleButton;
export { TYPES };
