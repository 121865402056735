import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Marquee, MarqueeController } from '@enact/sandstone/Marquee';
import compose from 'ramda/src/compose';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import css from "./SubNotice.module.less";
import { $L } from '../../../utils/helperMethods';
import SubBody from '../SubBody';
import NoticeDetail from "./NoticeDetail";
import TScroller from '../../../components/TScroller';
import TCheckboxItem from "../../../components/TCheckboxItem";
import dummyNotice from '../../../../assets/mock/dummyNotice.json';
import NoticeItem from './NoticeItem/NoticeItem';

const SubNotice = ({ spotlightId, onClick, index, className, ...rest }) => {
  const scrollTo = useRef(null);

  const [noticePopupDetail, setnoticePopupDetail] = useState(false);
	const [popupIndex, setPopupIndex] = useState(null);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);


  const noticeDetail = useCallback((i) => (ev) =>{
    console.log('noticeDetail', i);
    setPopupIndex(i);
    setnoticePopupDetail(true);
    ev.stopPropagation();
  }, [])

	const onClosePopup = useCallback(()=>{
    setnoticePopupDetail(false);
    Spotlight.focus('SubNoticeScroller');
	},[]);

  return (
    <div className={classNames(className)}>
      <TScroller 
        className={css.tScroller} 
        cbScrollTo={getScrollTo} 
        spotlightId='SubNoticeScroller'
      >
        {/* todo : server 연동 */}
        {dummyNotice.data.map((item, index) => (
          <NoticeItem 
            key={'noticeItem' + index}
            className={css.item}
            title={item.title}
            text={item.text}
            date={item.date}
            isRead={item.isRead}
            onClick={noticeDetail(index)}
          />
        ))}
      </TScroller>
      {noticePopupDetail && 
        <NoticeDetail index={popupIndex} onClose={onClosePopup}/>}
    </div>
  );
};

export default SubNotice;