import React, { useEffect, useMemo, useState } from "react";
import css from "./TwoVerticalBarGraph.module.less";
import classNames from "classnames";
import { $L, convertNewlinesToBr, transNumToMonthText, transSecondsToHMS } from "../../../../utils/helperMethods";
import ToolTip from "../Tooltip/ToolTip";

const numberOfLines = 5;

/**
 *
 * @param {type} 'date', 'time', 'calorie'
 * @returns
 */

const TwoVerticalBarGraph = ({ title, itemInfo, type, monthList=[] }) => {

  const maxValues = useMemo(() => {
    const value = monthList.reduce((acc, date) => {
      const planData = itemInfo[date] || { user: 0, plan: 0 };
      const { user, plan } = planData;
      return Math.max(acc, user, plan);
    }, 0);

    return value;
  }, [itemInfo, monthList]) 

  const labels = useMemo(() => 
    monthList.map(date => {
      const [, month] = date.split('-'); 
      return month;
    }
  ), [monthList]); 
  

  return (
    <div className={css.twoVerticalBarGraph}>
      <div className={css.titleContainer}>
        <div className={css.title}>{title}</div>
        <div className={css.textWrapper}>
          <div className={css.text}>{$L("Plan")}</div>
          <div className={css.text}>{$L("Performance")}</div>
        </div>
      </div>
      <div className={css.lineContainer}>
        {Array.from({ length: numberOfLines }, (_, index) => (
          <div key={"line" + index} className={classNames(css.line, (index === numberOfLines - 1) && css.last)} />
        ))}
        {maxValues > 0 && 
          <div className={css.graphContainer}>
          {monthList.map((date, index) => {
            const planData = itemInfo[date] || { user: 0, plan: 0 };
            const { user, plan } = planData;
            return (
              <div key={ "bar" + index } className={css.verticalBarContainer}>
                {/* vertical bars */}
                <div className={css.verticalBarWrapper}>
                  <div className={css.verticalBar} style={{ height: `${Math.floor(user / maxValues * 100)}%` }} />
                  <div className={css.verticalBar} style={{ height: `${Math.floor(plan / maxValues * 100)}%` }} />
                </div>
                {/* tooltip */}
                {plan > 0 && 
                <ToolTip 
                  title={type === "time" ? `${transSecondsToHMS(user, false, true)}/${transSecondsToHMS(plan, false, true)}` : `${user}/${plan}`} 
                  style={{bottom: `${Math.floor(Math.max(plan, user) / maxValues * 100) + 5}%`}}
                />
                }
                {/* label */}
                <div key={`month-${index}`} className={css.label}>
                  {transNumToMonthText(labels[index])}
                </div>
              </div>
            );
          })}
          </div>
        }
        {maxValues === 0 && 
          <div className={css.emptyContainer}>
            <div className={css.title}>{$L("There is no record of exercise yet.")}</div>
            <div className={css.text}>{convertNewlinesToBr($L("Start scanning your body and{br}begin personalized workout!"))}</div>
          </div>
        } 
      </div>
    </div>
  );
};

export default TwoVerticalBarGraph;
