import classNames from "classnames";
import compose from "ramda/src/compose";

import css from "./TButton.module.less";

import { useCallback, useEffect, useState, useRef } from "react";

import * as Config from "../../utils/Config";
import * as SoundEffect from "../../utils/SoundEffect";

import Spottable from "@enact/spotlight/Spottable";
import { Job } from "@enact/core/util";
import { Marquee, MarqueeController } from "@enact/sandstone/Marquee";
import { useSelector } from "react-redux";

const SpottableComponent = Spottable("div");
/**
 *
 * @param {type} normal
 * {size} 'big', 'mid', 'small
 * withMarquee: should have absolute width
 * @returns
 */

const TYPES = { normal: "normal", withIcon: "withIcon", report: "report", noBg: "noBg", square: "square", reportOnlyIcon: "reportOnlyIcon" };
const ICONS = {
  bell: "bell",
  bellW: "bellW",
  sound: "sound",
  soundMute: "soundMute",
  pose: "pose",
  note: "note",
  photo: "photo",
  check: "check",
  landscape: "landscape",
  portrait55: "portrait55",
  portrait73: "portrait73",
  fullscreen: "fullscreen",
  bgsyncup: "bgsyncup",
  show: "show",
  hidden: "hidden",
  loading: "loading",
  refresh: "refresh",
  refresh_nor: "refresh_nor",
  edit: "edit",
  autoPtzOff: "autoPtzOff",
  autoPtzOn: "autoPtzOn",
  musicOff: "musicOff",
  musicOn: "musicOn",
  plus: "plus",
  plusW: "plusW",
  hiking: "hiking",
  setting: "setting",
  heart: "heart",
  heartH: "heartH",
  share: "share",
};
const COLOR = {
  black: "black",
  green: "green",
  yellow: "yellow",
  brown: "brown",
  transparent: "transparent",
}; // white: default color
const SIZES = { tiny: "tiny", small: "small", large: "large", xLarge: "xLarge", test: "test", popup: "popup" }; // medium: default size
const BORDER = { none: "none"}

const TButtonBase = ({
  type = "normal",
  size = "medium",
  fillduration = 2000,
  children,
  withMarquee = false,
  spotlightId,
  className,
  onClick,
  onBlur,
  onFocus,
  disabled,
  onDisabled,
  selected,
  fillPer = 100,
  icon,
  color,
  border,
  itemRenderer,
  ...rest
}) => {
  const [runFillAni, setRunFillAni] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [isTooltip, setTooltip] = useState(false);

  const { themeMode } = useSelector((state) => state.common.appStatus);

  useEffect(() => {
    setTimeout(() => {
      setRunFillAni(true);
    }, 0);
  }, [fillduration]);

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback((ev) => {
    if (disabled) {
      ev.stopPropagation();
      return;
    }
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick(ev, type);
      }
    });

    ev.persist();
  }, [onClick, disabled, type]);

  const _onBlur = useCallback((ev) => {
    setPressed(false);
    clearPressedJob.current.stop();
    if (onBlur) {
      onBlur(ev);
    }
    if(itemRenderer) {
      setTooltip(false);
    }
  }, [onBlur]);

  const _onFocus = useCallback((ev) => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    if (onFocus) {
      onFocus(ev);
    }
    if(itemRenderer) {
      setTooltip(true);
    }
    const tooltipTimeout = setTimeout(()=>{
      setTooltip(false)
    }, 6000);
    return () => clearTimeout(tooltipTimeout);
  }, [onFocus]);

  return (
    <SpottableComponent
      {...rest}
      // style={additionalStyle}
      className={classNames(
        css.tButton,
        css[type],
        css[size],
        css[color],
        css[border],
        selected && css.selected,
        pressed && css.pressed,
        disabled ? css.disabled : null,
        className ? className : null,
        fillduration > 0 ? css.fillAni : "",
        themeMode === "dark" && css.isDark
      )}
      onClick={_onClick}
      onBlur={_onBlur}
      onFocus={_onFocus}
      data-webos-voice-intent={"Select"}
      data-webos-voice-label={children}
      spotlightId={spotlightId}
    >
      {(type === "withIcon" ||
        type === "report" ||
        type === "reportOnlyIcon") &&
        icon && <div className={classNames(css.icon, css[icon])} />}
      {withMarquee ? (
        <Marquee className={css.marquee} marqueeOn={"focus"}>
          {children}
        </Marquee>
      ) : (
        <div className={css.text}>{children}</div>
      )}
      {itemRenderer && itemRenderer(isTooltip)}
    </SpottableComponent>
  );
};
const ButtonDecorator = compose(MarqueeController({ marqueeOnFocus: true }));
const TButton = ButtonDecorator(TButtonBase);
export default TButton;
export { TYPES, ICONS, COLOR, SIZES, BORDER };
