import React, { useEffect, useRef, useState } from "react";
import css from "./LoggerTest.module.less";
import { enumEventType, logger } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux";
import { onLoggerWindowState } from "../../features/logData/logDataSlice";

const LoggerWindow = ({...rest}) => {
  const { totalLog, loggerWindowState } = useSelector((state) => state.logData);
  const dispatch = useDispatch();

  const [isLaunched, setIsLaunched] = useState(false);

  const [logging, setLogging] = useState([]);
  const scrollRef = useRef(null);
  const [isClickMinimum, setIsClickMinimum] = useState(false);

  const onClickHandler = (eventType, info, immediately) => {
    logger(eventType, info, immediately);
  };

  useEffect(() => {
    setLogging(totalLog);
  }, [totalLog]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [logging]);

  const handleWheel = (event) => {
    if (scrollRef.current.contains(event.target)) {
      // 스크롤 이벤트 핸들러의 로직
      const delta = Math.max(-1, Math.min(1, event.deltaY));
      const scrollStep = 50;
      console.log("스크롤 이벤트가 발생함");

      // 스크롤 위치 읽어오기
      const currentScrollTop = scrollRef.current.scrollTop;
      const newScrollTop = currentScrollTop + delta * scrollStep;

      // 스크롤 위치 조정
      scrollRef.current.scrollTo({
        top: newScrollTop,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    typeof window === 'object' && window.addEventListener("wheel", handleWheel);

    return () => {
      typeof window === 'object' && window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  console.log("isLaunched: ", isLaunched);

  return (
    <div className={css.wrapper}>
      <div className={css.loggerWindowBar}>
        <i
          className={css.loggerWindowButton}
          onClick={() => setIsClickMinimum((prev) => !prev)}
          style={{ backgroundColor: isClickMinimum ? "#34c903" : "#f0b000" }}
        >
          {isClickMinimum ? (
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="15 3 21 3 21 9" />
              <polyline points="9 21 3 21 3 15" />
              <line x1="21" y1="3" x2="14" y2="10" />
              <line x1="3" y1="21" x2="10" y2="14" />
            </svg>
          ) : (
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0V0z" />
              <path d="M6 19h12v2H6z" />
            </svg>
          )}
        </i>
        <i
          className={css.loggerWindowButton}
          style={{ backgroundColor: "tomato" }}
          onClick={() => dispatch(onLoggerWindowState(!loggerWindowState))}
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke="white"
              strokeWidth="2"
              d="M3,3 L21,21 M3,21 L21,3"
            />
          </svg>
        </i>
      </div>
      <div className={css.loggerModalWrapper}>
        <div
          className={css.loggerButtonArea}
          style={{ display: isClickMinimum ? "none" : "flex" }}
        >
          <button
            className={
              !isLaunched ? css.loggerTestButton : css.loggerTestButton_disabled
            }
            disabled={isLaunched}
            onClick={() => {
              setIsLaunched(true);
              onClickHandler(enumEventType.LAUNCH, "info: launch", 1);
            }}
          >
            LAUNCH
          </button>
          <button
            className={
              isLaunched ? css.loggerTestButton : css.loggerTestButton_disabled
            }
            disabled={!isLaunched}
            onClick={() =>
              onClickHandler(enumEventType.ON_CLICK, "info: onClick", null)
            }
          >
            ONCLICK
          </button>
          <button
            className={
              isLaunched ? css.loggerTestButton : css.loggerTestButton_disabled
            }
            disabled={!isLaunched}
            onClick={() => {
              setIsLaunched(false);
              onClickHandler(enumEventType.FINISH, "info: finish", 1);
            }}
          >
            FINISH
          </button>
        </div>
        <div
          ref={scrollRef}
          className={css.loggerTestScrollArea}
          style={{ height: isClickMinimum ? "30px" : "260px" }}
        >
          {isClickMinimum ? (
            <p key={logging.length - 1}>
              <span className={css.logIndex}>{logging.length - 1}</span>
              <span className={css.logItem}>
                {JSON.stringify(logging[logging.length - 1])}
              </span>
            </p>
          ) : (
            logging?.map((log, index) => (
              <p key={index}>
                <span className={css.logIndex}>{index}</span>
                <span className={css.logItem}>{JSON.stringify(log)}</span>
              </p>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LoggerWindow;
