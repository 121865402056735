import classNames from "classnames";
import css from "./THorizontalScrollList.module.less";
import React, { useEffect, useCallback, useState, useRef, useMemo } from "react";

import * as HelperMethods from "../../utils/helperMethods";
import * as Config from "../../utils/Config";

import ri from '@enact/ui/resolution';
import Scroller from '@enact/sandstone/Scroller/Scroller';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import { Job } from "@enact/core/util";
import { useSelector } from "react-redux";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div" );

export const TYPES = {
  normal: "normal",
};

export const ITEM_SIZE = {
  bodyCheckUp: "bodyCheckUp",
  xLarge: "xLarge",
  large: "large",
  medium: "medium",
  small: "small"
};

const LIST_ITEM_CONF = {
  [ITEM_SIZE.bodyCheckUp]: {
    ITEM_WIDTH: 650,
    SPACING: 28
  },
  [ITEM_SIZE.xLarge]: {
    ITEM_WIDTH: 896,
    SPACING: 60
  },
  [ITEM_SIZE.large]: {
    ITEM_WIDTH: 427,
    SPACING: 24
  },
  [ITEM_SIZE.medium]: {
    ITEM_WIDTH: 300,
    SPACING: 20,
  },
  [ITEM_SIZE.small]: {
    ITEM_WIDTH: 296,
    SPACING: 20
  },
    [ITEM_SIZE.small]: {
    ITEM_WIDTH: 296,
    SPACING: 20
  }
};



const THorizontalScrollList = ({
  className,
  type=TYPES.normal,
  itemSize=ITEM_SIZE.large,
  spotlightId,
  startGap=115,
  spacing=24,
  children,
  ...rest
}) => {
  const scrollTo = useRef(null);
  const containerRef = useRef();
  const [totalLength, setTotalLength] = useState(0);
  const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);
  const [lastVisibleIndex, setLastVisibleIndex] = useState(0);

  const [autoScrollState, setAutoScrollState] = useState("none"); //left, right
  const { cursorVisible, themeMode } = useSelector((state) => state.common.appStatus);
  const [isMouseOver, setIsMouseOver] = useState(false)

  const leftRightAutoScrollJob = useRef(
    new Job((func) => {
      func();
    }, Config.AUTO_SCROLL_DELAY)
  );
  

  const isCursorArrowVisible = useMemo(()=>{
    return cursorVisible && isMouseOver;
  },[isMouseOver, cursorVisible]);

  const scrollXAmount = LIST_ITEM_CONF[itemSize].ITEM_WIDTH + LIST_ITEM_CONF[itemSize].SPACING;


  useEffect(()=>{
    if(containerRef.current){
      calculateVisibleIndex();
      const childNodes = containerRef.current.children;
      const itemCount = childNodes.length;
      setTotalLength(itemCount);
    }
  }, [children]);

  const moveLeft = useCallback((ev) => {
    if (firstVisibleIndex === 0) return; 
    const nextIndex = firstVisibleIndex - 1;
    scrollTo.current({ position: { x: nextIndex * scrollXAmount }, animate: true });
    setFirstVisibleIndex(firstVisibleIndex-1);
    setLastVisibleIndex(lastVisibleIndex-1);
  }, [firstVisibleIndex, lastVisibleIndex]);

  const moveRight = useCallback((ev) => {
    if (lastVisibleIndex >= (totalLength -1)) return; 
    const nextIndex = firstVisibleIndex + 1;
    scrollTo.current({ position: { x: nextIndex * scrollXAmount }, animate: true });
    setFirstVisibleIndex(firstVisibleIndex+1);
    setLastVisibleIndex(lastVisibleIndex+1);
  }, [firstVisibleIndex, lastVisibleIndex, totalLength]);


  const doLeftRightAutoScroll = useCallback(() => {
		switch(autoScrollState){
			case 'left': moveLeft(); break;
			case 'right': moveRight(); break;
		}
	}, [autoScrollState, moveLeft, moveRight]);

	useEffect(() => {
    if(!cursorVisible) return;
		if(autoScrollState === 'none'){
      leftRightAutoScrollJob.current.stop();
    }else{
      leftRightAutoScrollJob.current.start(doLeftRightAutoScroll);
    }
	}, [autoScrollState, firstVisibleIndex, lastVisibleIndex, cursorVisible]);

  const onFocusMediaList = useCallback((ev) => {
    if (cursorVisible) {
      return;
    }
		const listItem = ev.target;
		if (listItem && scrollTo.current) {
			if (!HelperMethods.isChildFullyShowing(listItem.parentNode.parentNode, listItem)) {
				const { left } = HelperMethods.getRectDiff(listItem.parentNode.parentNode, listItem);
        const gap = left < 0 ? 1 : - 1;
				scrollTo.current({ position: { x: (firstVisibleIndex+gap) * scrollXAmount }, animate: true });
        setFirstVisibleIndex(firstVisibleIndex+gap);
        setLastVisibleIndex(lastVisibleIndex+gap);
			}
		}
	}, [cursorVisible, firstVisibleIndex, lastVisibleIndex]);

  const cbScrollTo = useCallback((fn)=>{
		scrollTo.current = fn;
	},[]);

  const onLeftScrollAreaOver = useCallback(() => {
		if(cursorVisible){
			moveLeft();
			setAutoScrollState("left");
		}
  }, [moveLeft, cursorVisible]);

  const onRightScrollAreaOver = useCallback(() => {
		if(cursorVisible){
			moveRight();
			setAutoScrollState("right");
		}
  }, [moveRight, cursorVisible]);

  const onScrollAreaOut = useCallback(() => {
    setAutoScrollState("none");
	}, []);


  const setMouseOver = useCallback((b)=>()=>{
    setIsMouseOver(b);
  },[]);

  const calculateVisibleIndex = useCallback((ev)=>{
    const childNodes = containerRef.current.children;
    const itemCount = childNodes.length;
    containerRef.current.style.setProperty('--itemCount', itemCount);
    let firstIndex = -1, lastIndex = 100;
    for (let i = 0; i < childNodes.length; i++) {
      if (HelperMethods.isChildFullyShowing(containerRef.current.parentNode, childNodes[i])) { 
        if(firstIndex < 0){
          firstIndex = i;
        }
        lastIndex = i;
      }
    }
    setFirstVisibleIndex(firstIndex);
    setLastVisibleIndex(lastIndex);
  },[]);
  const onScrollStop = useCallback((ev)=>{
    calculateVisibleIndex();
    if(rest.onScrollStop){
      rest.onScrollStop(ev)
    }
  },[rest.onScrollStop, calculateVisibleIndex]);

  return (
    <div className={classNames(
      className, 
      css.mediaList, 
      css[itemSize],
      themeMode === "dark" && css.isDark,
    )}
      onMouseOver={setMouseOver(true)}
      onMouseOut={setMouseOver(false)}
    
    >
      {firstVisibleIndex > 0 && isCursorArrowVisible &&
        <div className={css.lButtonIcon} onMouseOver={onLeftScrollAreaOver} onMouseOut={onScrollAreaOut}/>
      }
      {lastVisibleIndex < totalLength-1 && isCursorArrowVisible && 
        <div className={css.rButtonIcon} onMouseOver={onRightScrollAreaOver} onMouseOut={onScrollAreaOut}/>
      }
			<Scroller
        scrollMode='translate'
        focusableScrollbar={false}
        noScrollByWheel={true}
        className={css.scroller}
        direction='horizontal'
        horizontalScrollbar='hidden'
        verticalScrollbar='hidden'
        overscrollEffectOn={{
          arrowKey: false,
          drag: false,
          pageKey: false,
          track: false,
          wheel: false
        }}
        cbScrollTo={cbScrollTo}
        onScrollStop={onScrollStop}
      >
        <div
          className={classNames(css.container)}
          ref={containerRef}
          style={{paddingLeft: ri.scale(startGap * 2), paddingRight: ri.scale(startGap * 2)}}
          onFocus={onFocusMediaList}
        >
          {children}
        </div>
      </Scroller>
    </div>
  );
}

export default THorizontalScrollList;