import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spotlight from '@enact/spotlight';

import classNames from "classnames";
import css from "./HPTimeSetting.module.less";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import TButton, { SIZES, TYPES } from "../../components/TButton/TButton";
import TTimePicker from "../../components/TTimePicker/TTimePicker";
import TCheckboxItem from "../../components/TCheckboxItem"

import { $L, formatDateTime } from "../../utils/helperMethods";
import { updateAlarm } from "../../features/alarm/alarmSlice"
import { popPanel } from "../../features/panels/panelsSlice";

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const HPTimeSetting = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  // const alarm = useSelector(state => state.alarm);
  const [alarms, setAlarms] = useState(panelInfo.alarms || []);
  const [time, setTime] = useState(new Date(2000, 1, 1, 0, 0));
  const [selectIndex, setSelectIndex] = useState(0);
  const [totalClick, setTotalClick] = useState(false); // 전체 선택 후 시간 설정할 경우 모든 요일 일괄 적용

  const total = useMemo(() => {
    return alarms.filter((d) => !d.active).length === 0;
  },[alarms]);

  const onCompleteTimePicker = useCallback(() => {
		Spotlight.focus("complete");
	}, []);

  const onChangeTime = useCallback((ev) => {
    setTime(ev.value);
    if(totalClick) {
      setAlarms((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        newData.forEach(el => {
          el.time = ev.value.toTimeString().split(' ')[0];
        });
        return newData;
      });
    } else {
      setAlarms((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        newData[selectIndex].time = ev.value.toTimeString().split(' ')[0];
        return newData;
      });
    }
	}, [selectIndex, totalClick]);

  const onClickCancel = useCallback(() => {
    dispatch(popPanel());
  }, [dispatch])

  const onClickSubmit = useCallback(() => {
    dispatch(updateAlarm({ value: { alarms: alarms, activated: panelInfo.activated } }));
    dispatch(popPanel());
  }, [dispatch, alarms, panelInfo]);

  const onSpotlightLeft = useCallback(() => {
    Spotlight.focus("workoutWeekContainer");
  }, []);

  const onSpotlightRight = useCallback(() => {
    Spotlight.focus("complete");
  }, []);

  const onClickTotal = useCallback(() => () => {
    // todo 컨셉 필요
    setTotalClick(true);
    if(total) {
      setAlarms((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        newData.forEach(el => {
          el.active = false;
        });
        return newData;
      });
    } else {
      setAlarms((cur) => {
        const newData = JSON.parse(JSON.stringify(cur));
        newData.forEach(el => {
          el.active = true;
        });
        return newData;
      });
    }
  }, [total]);

  const onClickDay = useCallback((idx) => () => {
    setTotalClick(false);
    setSelectIndex(idx);
    setAlarms((cur) => {
      const newData = JSON.parse(JSON.stringify(cur));
      const activeAlarmLength = newData.filter(alarm => alarm.active).length;
      console.log('activeAlarmLength', activeAlarmLength);
      activeAlarmLength >= 3
      ?
      newData[idx].active = false
      :
      newData[idx].active = !(newData[idx].active);
      return newData;
    });

    if(typeof alarms[idx].time === "string") {
      const hour = parseInt(alarms[idx].time.substring(0, 2));
      const minutes = parseInt(alarms[idx].time.substring(3, 5));
      const date = new Date(2000, 1, 1, hour, minutes);
      setTime(date);
    } else {
      setTime(alarms[idx].time);
    }
  }, [alarms]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Change the time of your workout")} />
      <TBody>
        <Container className={css.body} spotlightId={"workoutWeekContainer"}>
          <div className={css.workoutWeek}>
            <div className={css.titleDiv}>
              <div className={css.subTitle}>{$L("Day of the week you're working out on")}</div>
              <TCheckboxItem
                className={classNames(css.total)}
                title={"Overall"}
                selected={total}
                onToggle={onClickTotal()}
              />
            </div>
            <Container className={css.week}>
              {alarms.length > 0 && alarms.map((day, idx) => (
                <TButton
                  className={css.day}
                  type={TYPES.square}
                  key={`alarm_${idx}`}
                  onClick={onClickDay(idx)}
                >
                  <span className={classNames(css.check, day.active ? css.select : null)}/>
                  <div className={css.title}>{panelInfo?.days[idx]}</div>
                  <div className={classNames(css.time, day.time ? css.setData : null)}>{formatDateTime('aa hh:mm', "2000-01-01 "+day.time ) || "__ __:__"}</div>
                </TButton>
              ))}
            </Container>
          </div>
          <span className={css.vr}/>
          <Container className={css.workoutTime}>
            <div className={css.subTitle}>{$L("Set time")}</div>
            <TTimePicker
              value={time}
              onSpotlightRight={onSpotlightRight}
              onSpotlightLeft={onSpotlightLeft}
              onComplete={onCompleteTimePicker}
              onChange={onChangeTime}
              disabled={!alarms[selectIndex].active}
            />
          </Container>
        </Container>
        <Container className={css.btnArea} >
          <TButton size={SIZES.xLarge} onClick={onClickCancel}>
            {$L("Cancel")}
          </TButton>
          <TButton
            size={SIZES.xLarge}
            onClick={onClickSubmit}
            spotlightId={"complete"}
          >
            {$L("Finished")}
          </TButton>
        </Container>
      </TBody>
    </TPanel>
  );
};

export default HPTimeSetting;