import { $L } from "../../../../../utils/helperMethods";
import { romMenu } from "../Constants";

import { SUB_TYPE } from "../../feedback/constantsFeedback";

export const getContentsArr = () => {
  return {
    [romMenu.NECK]: [
      $L("Neck Lateral Flexion"), // Left : Right, (L/R)
      $L("Neck Flexion"), // none, (B/F)
      $L("Neck Extension"), // none, (B/F)
    ],
    [romMenu.SHOULDER]: [
      $L("Shoulder Abduction"), // Left : Right, (L/R)
      $L("Shoulder Internal Rotation"), // Left : Right, (B/F)
      $L("Shoulder External Rotation"), // Left : Right, (B/F)
      $L("Shoulder Flexion"), // Left : Right, (B/F)
      $L("Shoulder Extension"), // Left : Right, (B/F)
    ],
    [romMenu.TRUNK]: [
      $L("Trunk Left/Right Lateral Flexion"), // Left : Right, (L/R)
      $L("Trunk Flexion"), // none, (B/F)
      $L("Trunk Extension"), // none, (B/F)
    ],
    [romMenu.KNEE]: [
      $L({ key: "rom_Knee_Flexion", value: "Knee Flexion" }), // Left : Right, (B/F)
    ],
    [romMenu.HIP]: [
      $L("Hip Flexion"), // Left : Right, (B/F)
      $L("Hip Extension"), // Left : Right, (B/F)
    ],
  };
};

export const getConvertTestName = () => {
  return {
    [SUB_TYPE.NECK_LATERAL_FLEXION]: $L("Neck Lateral Flexion"),
    [SUB_TYPE.NECK_FLEXION]: $L("Neck Flexion"),
    [SUB_TYPE.NECK_EXTENSION]: $L("Neck Extension"),
    [SUB_TYPE.SHOULDER_ABDUCTION]: $L("Shoulder Abduction"),
    [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: $L("Shoulder Internal Rotation"),
    [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: $L("Shoulder External Rotation"),
    [SUB_TYPE.SHOULDER_FLEXION]: $L("Shoulder Flexion"),
    [SUB_TYPE.SHOULDER_EXTENSION]: $L("Shoulder Extension"),
    [SUB_TYPE.TRUNK_LATERAL_FLEXION]: $L("Trunk Left/Right Lateral Flexion"),
    [SUB_TYPE.TRUNK_FLEXION]: $L("Trunk Flexion"),
    [SUB_TYPE.TRUNK_EXTENSION]: $L("Trunk Extension"),
    [SUB_TYPE.KNEE_FLEXION]: $L({
      key: "rom_Knee_Flexion",
      value: "Knee Flexion",
    }),
    [SUB_TYPE.HIP_FLEXION]: $L("Hip Flexion"),
    [SUB_TYPE.HIP_EXTENSION]: $L("Hip Extension"),
  };
};

export const POSITION_TYPE = {
  // !important: do not translate
  LEFT_LABEL: "L",
  RIGHT_LABEL: "R",
  BACK_LABEL: "B",
  FRONT_LABEL: "F",
};
