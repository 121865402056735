import { useEffect, useCallback } from "react";
import ThemeDecorator from "@enact/sandstone/ThemeDecorator";
import { Job } from "@enact/core/util";
import { useDispatch, useSelector } from "react-redux";
// import * as HelperMethods from "../utils/helperMethods";
import platform from "@enact/core/platform";
import { lunaTest } from "../lunaSend/lunaTest";
import "./attachErrorHandler";
import MainView from "../views/MainView/MainView";
import { updateCameraList, changeUpdatingCameraList, changeActivatedCameraId } from "../features/camera/cameraSlice";
import { getSurvey, getSettings, getBgmList, getFootprintList, getBodyAlignmentDateList, getBodyBalanceDateList, getPhycalTestDateList, getPolicy, getContentListAll, getRomDateList, getActivityStrollDateList, getActivityData, getContent, updateSettings } from "../features/fitService/fitServiceSlice";
import * as bodyAlignmentSlice from "../features/bodyAlignment/bodyAlignmentSlice";
import * as bodyBalanceSlice from "../features/bodyBalance/bodyBalanceSlice";
import * as physicalTestSlice from "../features/physicalTest/physicalTestSlice";
import * as romSlice from "../features/rom/romSlice";
import { gattDisconnect } from '../features/ble/bleSlice';
import * as TTSService from "../lunaSend/TTSService";
// redux slices
import {
  changeAppStatus,
  getSystemInfo,
  getDeviceId,
  getSystemSettings,
  getSystemSettings2,
  getHttpHeaderForServiceRequest,
  getConnectionStatus,
  getDeviceList,
  getLoginUserData
} from "../features/common/commonSlice";


import * as SoundEffect from "../utils/SoundEffect";
import $L from "@enact/i18n/$L";
import useGesture from "../hooks/useGesture";
import { PREVENT_BACKGROUND_PLAY_PANELS } from "../utils/Constants";
import { popPanel } from "../features/panels/panelsSlice";
import { CES_DEFAULT_CONCADE } from "../utils/Config";

let cameraListRetryCount = 0;
const CAMERACHECK_MAX_COUNT = 3;
let foreGroundChangeTimer = null;

const updateCameraListJob = new Job((dispatch) => {
  if (typeof window === 'object' && typeof window.navigator === 'object' && window.navigator.mediaDevices) {
    window.navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        let cameraList = [];
        console.log('enumerateDevices devices ', devices);
        for (let i = 0; i < devices.length; i++) {
          const device = devices[i];
          if (device.kind === "videoinput") {
            cameraList.push({deviceId: device.deviceId, label: device.label ? device.label : $L("Unknown")});
          }
        }
        dispatch(updateCameraList({ cameraList }));
        dispatch(changeUpdatingCameraList(false));
        cameraListRetryCount = 0;
      })
      .catch(function (err) {
        console.log(
          "updateCameraList exception cameraListRetryCount",
          cameraListRetryCount
        );
        console.log("updateCameraList exception err", err);
        dispatch(updateCameraList({ cameraList: [] }));
        if (cameraListRetryCount < CAMERACHECK_MAX_COUNT) {
          cameraListRetryCount++;
          updateCameraListJob.startAfter(cameraListRetryCount * 500, dispatch);
        } else {
          dispatch(changeUpdatingCameraList(false));
        }
      });
  }else if(typeof window === "object" && !window.PalmSystem){
    dispatch(updateCameraList({ cameraList: [{deviceId: "dummy", label: $L("Unknown")}]}));
  }
}, 500);

const AppBase = () => {
  const dispatch = useDispatch();
  const { webOSVersion, language, isAppForeground, userNumber } = useSelector((state) => state.common.appStatus);
  const { email, password, cesShowMode } = useSelector((state) => state.common.localSettings);
  const { gestureRelease } = useGesture();

  const initService = useCallback(
    async (haveyInit = true) => {
      // console.log(
      //   "<<<<<<<<<<<<<        appinfo      >>>>>>>>>>>> ",
      //   appinfo,
      //   haveyInit
      // );
      if (haveyInit) {
        dispatch(changeAppStatus({ connectionFailed: false }));
        if (typeof window === "object" && window.PalmSystem) {
          dispatch(changeAppStatus({ cursorVisible: window.PalmSystem?.cursor?.visibility }));
          console.log('App.js cursor vi', JSON.stringify(window.PalmSystem?.cursor?.visibility));
        }
        dispatch(getSystemSettings());
        //get captionEnable
        dispatch(getSystemSettings2());
        dispatch(getSystemInfo());
        dispatch(getDeviceId());
        dispatch(getBgmList({ sort: 'wdate' }));
        dispatch(getFootprintList());
        dispatch(getHttpHeaderForServiceRequest(webOSVersion, language));
      }

      SoundEffect.initWebAudio();
    },
    [dispatch, email, password]
  );

  const handleExitApp = useCallback(() => {
    // TODO: 추가적인 close 함수 구현 (다른 앱 launch 등)
    // #IF_COMMERCIAL
  }, []);

  const activateApp = useCallback(() => {
    if (typeof window === "object" && window.PalmSystem) {
      window.PalmSystem.activate();
      // window.lunaTest = (service, method, subscribe, parameters) => lunaTest(service, method, subscribe, parameters);
    }
  }, []);

  const handleLaunchEvent = useCallback(
    (isRelaunch) => {
      // const launchParams = HelperMethods.getLaunchParams();

      // if(launchParams.intent === 'SearchContent' || launchParams.intent === 'PlayContent'){
      // 	launchParams.panel = 'search';
      // }
      // if(launchParams.intent === 'deepLink' || launchParams.contentTarget){
      // 	launchParams.panel = 'deepLink';
      // 	if(launchParams.contentTarget){
      // 		launchParams.contentId = launchParams.contentTarget;
      // 	}
      // }
      // console.log('localSettings.isFirstLaunched= ', isFirstLaunched);
      // if(isFirstLaunched || Config.DEMO){
      // 	dispatch(PanelActions.pushPanel(PANEL_NAME.startpage));
      // }
      // if(launchParams.panel){

      // 	switch(launchParams.panel){
      // 		case 'home':
      // 			dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.HOME_VIEW));
      // 			break;
      // 		case 'category':
      // 			dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.CATEGORIES_VIEW));
      // 			break;
      // 		case 'dashboard':
      // 			dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.DASHBOARDS_VIEW));
      // 			break;
      // 		//admin preview
      // 		case 'termsDetail':
      // 			dispatch(PanelActions.pushPanel(PANEL_NAME.termsConditionsDetail));
      // 			break;
      // 		case 'termsModification':
      // 			dispatch(PanelActions.pushPanel(PANEL_NAME.termsModification));
      // 			break;
      // 		case 'search':
      // 			dispatch({ type: types.GET_CONTENTS_KEYWORD_RESET });
      // 			dispatch({ type: types.ADD_LOADING_SPINNER_STATUS, payload: "getResultOfLGFitness" });
      // 			setTimeout(() => {
      // 				dispatch(PanelActions.popPanel(PANEL_NAME.search));
      // 				dispatch(PanelActions.pushPanel(PANEL_NAME.search,{keyword: launchParams.intentParam ? launchParams.intentParam: ""}));
      // 			}, isRelaunch ? 10: 2000);
      // 			break;
      // 		case 'deepLink':
      // 			if(launchParams.storeCaller==='search'){
      // 				dispatch(CommonActions.setPlayLaunchPath("integratedSearch"));
      // 			}else if(launchParams.storeCaller === 'Nudge'){
      // 				dispatch(CommonActions.setPlayLaunchPath("nudge"));
      // 			}else{
      // 				dispatch(CommonActions.setPlayLaunchPath("deepLink"));
      // 			}
      // 			dispatch(PanelActions.popPanel(PANEL_NAME.player));
      // 			// if(launchParams.contentId){
      // 			// 	setTimeout(() => {
      // 			// 		dispatch(CmsActions.getContentInfo(Number(launchParams.contentId), true,
      // 			// 			playFromIntent({contentId: Number(launchParams.contentId), contentType: ContentType.SINGLE})));
      // 			// 	}, isRelaunch ? 10: 2000);
      // 			// }
      // 			break;
      // 		case 'noticeList':
      // 			dispatch(PanelActions.pushPanel(PANEL_NAME.notice));
      // 			break;
      // 	}
      // }
      //todo remove this
      // if(launchParams.action){
      // 	dispatch(CommonActions.setLaunchAction(launchParams.action));
      // }
      setTimeout(() => {
        initService(!isRelaunch);
      }, 100);

      clearTimeout(foreGroundChangeTimer);
      foreGroundChangeTimer = setTimeout(() => {
        dispatch(changeAppStatus({ isAppForeground: true }));
      }, 1000);
      // if (isRelaunch) {
      //   activateApp();
      // }
      !cesShowMode && dispatch(getLoginUserData());
      cameraListRetryCount = 0;
      updateCameraListJob.start(dispatch);

    },
    [activateApp, dispatch, initService, cesShowMode]
  );

  const clearAllTestData = useCallback(()=>{
    dispatch(bodyAlignmentSlice.clearTestResults());
    dispatch(bodyBalanceSlice.clearTestResults());
    dispatch(physicalTestSlice.clearTestResults());
    dispatch(romSlice.clearTestResults());
  },[]);

  useEffect(() => {
    if (userNumber) {
      console.log('App userNumber changed......', userNumber);
      clearAllTestData();
      if(!cesShowMode){
        dispatch(getPolicy());
        dispatch(getSurvey());
        dispatch(getBodyAlignmentDateList());
        dispatch(getPhycalTestDateList());
        dispatch(getRomDateList());
        dispatch(getBodyBalanceDateList());
        dispatch(getActivityStrollDateList());
      }
      dispatch(getSettings(userNumber));
    } else if (userNumber === "") {
      clearAllTestData();
      /**
       * TV 관련 설정 get
       * 카메라 지원
       * 매트 지원
       * 메뉴 진입 조건에 따라
       * 로그인 시나리오_비로그인/약관 미동의 접근 범위 체크
       */
    }
    dispatch(getActivityData('walking'));
    dispatch(getContentListAll());

    // todo remove: hiking...
    const contentId = cesShowMode ? 10 : 6;
    dispatch(getContent({ contentId }));
    if (cesShowMode) dispatch(updateSettings({ concede: CES_DEFAULT_CONCADE })); //30
    // if (cesShowMode) dispatch(updateSettings({ concede: "middle" })); //60
  }, [userNumber]);

  const handleRelaunchEvent = useCallback(() => {
    console.log("handleRelaunchEvent started");
    handleLaunchEvent(true);
  }, [handleLaunchEvent]);

  const visibilityChanged = useCallback(() => {
    console.log("document is hidden", document.hidden);
    console.log("document.visibilityState= ", document.visibilityState);
    if (document.hidden && typeof window === "object") {
      // change to background
      // console.log("visibility changed !!! ==> set to background"); // eslint-disable-line no-console
      // clearTimeout(foreGroundChangeTimer);
      // updateCameraListJob.stop();
      // cameraListRetryCount = CAMERACHECK_MAX_COUNT;
      // SoundEffect.stopAll();
      // resetRedux();
      //todo
      TTSService.stop();
      dispatch(gattDisconnect());
      gestureRelease();
      PREVENT_BACKGROUND_PLAY_PANELS.map((panelName) => {
        dispatch(popPanel(panelName));
      });
      //todo 양산때 적용
      window.close();
    } else {
      // change to foreground
      // set foreground flag using delay time.
      clearTimeout(foreGroundChangeTimer);
      foreGroundChangeTimer = setTimeout(() => {
        console.log("visibility changed !!! ==> set to foreground cursorVisible", JSON.stringify(window.PalmSystem?.cursor?.visibility)); // eslint-disable-line no-console
        if (platform.platformName !== "webos") {
          //for debug
          dispatch(changeAppStatus({ isAppForeground: true, cursorVisible: !platform.touchscreen }));
        } else if (typeof window === 'object') {
          dispatch(changeAppStatus({ isAppForeground: true, cursorVisible: window.PalmSystem?.cursor?.visibility }));
        }
      }, 1000);
      cameraListRetryCount = 0;
      updateCameraListJob.start(dispatch);
      setTimeout(() => {
        initService(false);
      }, 100);
    }
  }, [dispatch, initService]);

  const ondevicechange = useCallback((event) => {
    console.log('ondevicechange....', event);
    if (isAppForeground) {
      dispatch(changeUpdatingCameraList(true));
      updateCameraListJob.startAfter(1500, dispatch);
    }
  }, [dispatch, isAppForeground]);

  // useEffect(() => {
  //   if (fontLoaded) {
  //     // dispatch(CommonActions.removeLoadingStatus("fontLoaded"));
  //     console.log("yhcho call encrypt test");
  //     HelperMethods.encryptPassword(`<BDI8+\"b'\\`);
  //     HelperMethods.encryptPassword("Twindebug1!");
  //   }
  // }, [fontLoaded]);

  useEffect(() => {
    if (typeof window === "object" && window.PalmSystem) {
      window.PalmSystem.activate();
      window.lunaTest = (service, method, subscribe, parameters) => lunaTest(service, method, subscribe, parameters);
    } else {
      dispatch(
        changeAppStatus({ isAppForeground: true })
      );
    }
    handleLaunchEvent();

    // on("visibilitychange", visibilityChanged);
    // on("webOSRelaunch", handleRelaunchEvent);
    document.addEventListener("visibilitychange", visibilityChanged);
    document.addEventListener("webOSRelaunch", handleRelaunchEvent);
    if (typeof window === "object") {
      if (
        window.PalmSystem &&
        Object.prototype.hasOwnProperty.call(window.PalmSystem, "onclose")
      ) {
        window.PalmSystem.onclose = handleExitApp;
      } else {
        window.onclose = handleExitApp;
      }
      if (window.PalmSystem && !cesShowMode) {
        dispatch(getConnectionStatus());
      }
      dispatch(getDeviceList());
      if (typeof window.navigator === 'object' && window.navigator.mediaDevices) {
        window.navigator.mediaDevices.addEventListener('devicechange', ondevicechange, false);
      }
    }
    if (document.URL.indexOf('fittv.t-win.kr') >= 0) {
      dispatch(changeActivatedCameraId(""));
    }
    return () => {
      //todo
      dispatch(gattDisconnect());
      gestureRelease();
      document.removeEventListener("visibilitychange", visibilityChanged)
      document.removeEventListener("webOSRelaunch", handleRelaunchEvent)
      // off("webOSRelaunch", handleRelaunchEvent);
      // off("visibilitychange", visibilityChanged);
      if (typeof window === 'object' && typeof window.navigator === 'object') {
        if (window.navigator.mediaDevices) {
          window.navigator.mediaDevices.removeEventListener('devicechange', ondevicechange);
        }
      }
      cameraListRetryCount = CAMERACHECK_MAX_COUNT;
      updateCameraListJob.stop();
    };
  }, [dispatch]);

  return <MainView initService={initService} />;
};

const App = ThemeDecorator({ noAutoFocus: true }, AppBase);

export default App;
export { App, AppBase };
