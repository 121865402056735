import React, {useState, useEffect, useCallback} from 'react';
import { useDispatch } from 'react-redux';
import {$L} from '../../../utils/helperMethods';
import SubBody from '../SubBody';
import * as Config from "../../../utils/Config";
import {updatePanel} from "../../../features/panels/panelsSlice";
import CenterScreen from './CenterScreen';
import SkipGuide from './SkipGuide';
import Gesture from './Gesture';
import Wallpaper from './Wallpaper';

const SubScreen = ({...rest}) => {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onTabChanged = useCallback(({index})=>{
    setSelectedTabIndex(index);
    dispatch(updatePanel({name: Config.panel_names.SETTING_PANEL, panelInfo: {selectedTab: index}}));
  },[]);

  useEffect(() => {
    console.log('selectedTabIndex ',selectedTabIndex);
  }, [selectedTabIndex]);

  const tabList = [
    // { title: $L("운동 배경화면"), children: <Wallpaper/> },
    { title: $L("Center the screen content"), children: <CenterScreen/> },
    { title: $L("Skip the guide video"), children: <SkipGuide/> },
    // { title: $L("제스쳐"), children: <Gesture/> } // todo: 위치 미정
  ];

  return (
    <SubBody simpleButtonList={tabList.map(tab => tab.title)} onTabChanged={onTabChanged} {...rest}>
      {tabList[selectedTabIndex].children || null}
    </SubBody>
  );
};

export default SubScreen;