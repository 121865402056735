import { panel_names } from "./Config";

export const SCORE_RED_KEY = 'danger';
export const SCORE_YELLOW_KEY = 'manage';
export const SCORE_MINT_KEY = 'fine';

export const CONSTANTS_MONTH = [
  "Jan",
  "Fab",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// belly cheking
export const BELLY_CHECKING_INDEX = [1, 4, 17];

//bodyScan
export const BODY_SCAN_SCORE = {
  "fine": 9,
  "manage": 4,
  "danger": 1
}
export const BODY_SCAN_COLOR = {
  "fine": '#44A280',
  "manage": '#F9B811',
  "danger": '#F44B4A',
}
export const BODY_SCAN_BASIC_SCORE = 15;

//sft
export const SFT_SCORE_MANAGE_TOP = 80;
export const SFT_SCORE_DANGER_TOP = 10;

//body balance
export const BBT_SCORE_MANAGE_TOP = 80;
export const BBT_SCORE_DANGER_TOP = 45;

export const SCORE_MAX_PERCENT = 98;
export const SCORE_PERCENT_DUMMY = 3;

//ROM
export const ROM_SCORE = {
  a: 3,
  b: 2,
  c: 1,
};
export const ROM_COLOR = {
  a: "#44A280",
  b: "#F9B811",
  c: "#F44B4A",
};

export const BODY_CHECKUP_TYPE = {
  BODY_ALIGNMENT: "BODY_ALIGNMENT",
  ROM: "ROM",
  BODY_BALANCE: "BODY_BALANCE",
  PHYSICAL_TEST: "PHYSICAL_TEST"
}

export const CAMERA_USING_PANELS = [
  panel_names.BODY_ALIGNMENT_SCAN,
  panel_names.PHYSICAL_TEST_CAPTURE,
  panel_names.ROM_TEST,
  panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER
];
export const BLE_MAT_USING_PANELS = [
  // panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER, //keep panel
  panel_names.BODY_BALANCE_TEST
];
export const PREVENT_BACKGROUND_PLAY_PANELS = [
  panel_names.BODY_ALIGNMENT_SCAN,
  panel_names.PHYSICAL_TEST_CAPTURE,
  panel_names.ROM_TEST,
  panel_names.BODY_BALANCE_TEST,
  panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER
];
const MIN_DISTANCE = 22;
export class BellySmoother {
  constructor(queueSize = 10) {
    this.smoothMode = true;
    this.queue = [];
    this.queueSize = queueSize;
    this.average = [];
  }
  pushValue = (x, y) => {
    if (this.queue.length >= this.queueSize) {
      this.queue.shift();
    }
    if (this.average[0]) {
      const dx = (x - this.average[0]);
      const dy = (y - this.average[1]);
      const distance = Math.sqrt(dx * dx + dy * dy);
      if (distance > MIN_DISTANCE) {
        this.queue = [];
      }
    }
    this.queue.push([x, y]);

    let sumX = 0, sumY = 0;
    for (let i = 0; i < this.queue.length; i++) {
      sumX += this.queue[i][0];
      sumY += this.queue[i][1];
    }
    if (this.queue.length <= 0) {
      this.average = [];
    }
    if (this.smoothMode) {
      this.average = [sumX / this.queue.length, sumY / this.queue.length];
    } else {
      this.average = [this.queue[this.queue.length - 1][0], this.queue[this.queue.length - 1][1]];
    }
  }
  getAverageData = () => {
    return this.average;
  }
  clearQueue = () => {
    this.queue = [];
    this.average = [];
  }
  changeQueueSize = (queueSize) => {
    this.queueSize = queueSize;
    this.clearQueue();
  }
  changeSmoothMode = (mode) => {
    this.smoothMode = mode;
  }
}

export const FOOTTYPE_VALUE = {
  "cavusMin": 1.16,
  "cavusMax": 1.26,
  "suscavusMin": 0.74,
  "suscavusMax": 1.16,
  "normalMin": 0.31,
  "normalMax": 0.74,
  "susflatMin": 0.26,
  "susflatMax": 0.31,
  "flatMin": 0.16,
  "flatMax": 0.26,
};

export const ONE_LEG_BALANCE_VALUE = {
  "preventionMin": 1.95,
  "preventionMax": 2.95,
  "managementMin": 2.95,
  "managementMax": 6.16,
  "dangerMin": 6.16,
  "dangerMax": 7.16
};