import LS2Request from "./LS2Request";
import * as helperMethods from "../utils/helperMethods";
//W6.0
const SERVICE_URL = "luna://com.webos.service.tts";
const SETTING_SERVICE_URL = "luna://com.webos.settingsservice";
const METHOD_TYPE = {
  GET_SYSTEM_SETTINGS: "getSystemSettings",
  GET_STATUS: "getStatus",
  SPEAK: "speak",
  STOP: "stop",
};

const isLocalConditions = () => (typeof window === 'object' && !window.PalmSystem);

export const getAudioFeedbackStatus = () => {
  if (isLocalConditions()) {
    console.log("isLocal: LUNA SEND getAudioFeedbackStatus");
    return "Some Hard Coded Mock Data";
  } else {
    try {
      const response = new LS2Request().send({
        service: SETTING_SERVICE_URL,
        method: METHOD_TYPE.GET_SYSTEM_SETTINGS,
        subscribe: false,
        parameters: {
          category: "voiceframework",
          key: "audioFeedback",
        },
      });
      console.log("LUNA SEND getAudioFeedbackStatus");
      console.log("LUNA getAudioFeedbackStatus response: ", response);

      return response;
    } catch (e) {
      console.error("LUNA SEND ERROR: ", e);
    }
  }
};

export const setAudioFeedback = (value = false) => {
  if (isLocalConditions()) {
    console.log("isLocal: LUNA SEND setAudioFeedback: ", value);
    return "Some Hard Coded Mock Data";
  } else {
    try {
      const response = new LS2Request().send({
        service: SETTING_SERVICE_URL,
        method: METHOD_TYPE.SETTING_SERVICE_URL,
        subscribe: false,
        parameters: {
          category: "voiceframework",
          settings: { audioFeedback: value },
        },
      });
      console.log("LUNA SEND setAudioFeedback: ", value);
      console.log("LUNA setAudioFeedback response: ", response);
    } catch (e) {
      console.error("LUNA SEND ERROR: ", e);
    }
  }
};

export const getStatus = () => {
  if (isLocalConditions()) {
    console.log("isLocal: LUNA SEND getStatus");
    return "Some Hard Coded Mock Data";
  } else {
    console.log("LUNA SEND getStatus");
    return new LS2Request().send({
      service: SERVICE_URL,
      method: METHOD_TYPE.GET_STATUS,
      subscribe: false,
      parameters: {},
    });
  }
};
let speakHandle;
export const speak = (text, onEnded) => {
  stop();
  speakHandle = setTimeout(() => _speak(text, onEnded), 500);
};
let serviceHandle = null;
let msgId = null;
export const _speak = (text, onEnded) => {
  const _onEnded = () => {
    if(onEnded){
      onEnded(text);
    }
  };
  const _text = helperMethods.stripHTML(text);
  if (isLocalConditions()) {
    console.log("isLocal: LUNA SEND speak", text);
    localPlay(_text, _onEnded);
    return "Some Hard Coded Mock Data";
  } else {
    const _onSuccess = (res) =>{
      console.log('TTS Speak onSuccess', res);
      if(res.returnValue && res.msgID){
        if(res.msgStatus === 'done'){
          if(msgId === res.msgID){
            if(onEnded){
              onEnded(text);
            }
          }
          serviceHandle.cancel();
          serviceHandle = null;
        }else{
          msgId = res.msgID;
        }
      }
    }
    const _onFailure = (res) =>{
      console.log('TTS Speak onFailure', res);
      serviceHandle.cancel();
    }
    try {
      const textArr = helperMethods.splitText(_text, 300);

      textArr.map((textEl, index) => {
        serviceHandle = new LS2Request().send({
          service: SERVICE_URL,
          method: METHOD_TYPE.SPEAK,
          subscribe: true,
          parameters: {
            text: textEl,
            feedback: true,
            mode: "audioFeedback",
            clear: index === textEl.length - 1 ? false : true,
          },
          onSuccess: _onSuccess,
          onFailure: _onFailure,
        });
        return serviceHandle;
      });
    } catch (e) {
      console.error("TTS Speak ERROR: ", e);
    }
  }
};

export const stop = () => {
  clearTimeout(speakHandle);
  speakHandle = null;
  if(serviceHandle){
    serviceHandle.cancel();
    serviceHandle = null;
  }
  if (isLocalConditions()) {
    console.log("isLocal: LUNA SEND stop");
    localStop();
    return "Some Hard Coded Mock Data";
  } else {
    const _onSuccess = (res) =>{
      console.log('TTS stop onSuccess', res);
    }
    const _onFailure = (res) =>{
      console.log('TTS stop onFailure', res);
    }
    try {
      const handle = new LS2Request().send({
        service: SERVICE_URL,
        method: METHOD_TYPE.STOP,
        subscribe: false,
        parameters: {},
        onSuccess: _onSuccess,
        onFailure: _onFailure
      });
      return handle;
    } catch (e) {
      console.error("LUNA SEND ERROR: ", e);
    }
  }
};

const localSoundEffect = (textToSpeak, onended) => {
  if (typeof window === "object") {
    try {
      if (window.speechSynthesis) {
        const synth = window.speechSynthesis;

        synth.cancel();

        const utterance = new window.SpeechSynthesisUtterance(textToSpeak);

        if (typeof window.navigator === 'object') {
          const isAppleOS = window.navigator.userAgent
            .toLowerCase()
            .includes("mac");

          // 음성 합성 설정 (선택 사항)
          utterance.lang = "ko-KR"; // 음성 언어 설정
          utterance.volume = 1; // 음성 볼륨 설정 (0.0에서 1.0까지)
          if (isAppleOS) {
            utterance.rate = 1.2; // 음성 속도 설정 (0.1에서 10.0까지)
            utterance.pitch = 1; // 음성 음조 설정 (0.1에서 2.0까지)
          } else {
            utterance.rate = 1.7; // 음성 속도 설정 (0.1에서 10.0까지)
            utterance.pitch = 1.7; // 음성 음조 설정 (0.1에서 2.0까지)
          }
        }
        utterance.onend = onended;
        synth.speak(utterance);
      }
    } catch (e) {
      console.error("error: ", e);
    }
  }
};

export const localPlay = (text, onended) => {
  localSoundEffect(text, onended);
};

export const localStop = () => {
  if (typeof window === "object") {
    if (window.speechSynthesis) {
      const synth = window.speechSynthesis;
      synth.cancel();
    }
  }
};
