import classNames from 'classnames';
import { useMemo, useCallback} from 'react';
import Slider from '@enact/sandstone/Slider';
import css from './TSlider.module.less';
import { $L } from '../../utils/helperMethods';

const TSlider = ({className=null, max=10, value=0, onChange, ...rest}) => {

  const PAINS = useMemo(() => {
    return [
      {id: 0, title: $L("Mild pain")},
      {id: 1, title: $L("Moderate pain")},
      {id: 2, title: $L("Severe pain")},
    ]
  }, []);

  const NUMBERS = useMemo(() => {
    return Array.from({ length: max + 1}, (_, index) => index);
  }, []);

  const _onClick = useCallback((index) => (ev) => {
    if (onChange) {
      onChange(index);
    }
  }, [onChange]);

  const _onChange = useCallback(({value}) => {
    if(onChange) {
      onChange(value);
    }
  }, [onChange]);

  return (
    <div className={classNames(css.container, className)}>
      <div className={css.pains}>
        {
          PAINS.map((item) => (
            <div key={item.id}>{item.title}</div>
          ))
        }
      </div>

      <Slider
        {...rest}
        className={classNames(
          css.slider,
          value >= 0 && css.selected
        )}
        onChange={_onChange}
        max={max}
        noFill={true}
        value={value}
      />
      <div className={css.numbers}>
        {NUMBERS.map((number, index) => (
          <div
            key={number}
            onClick={_onClick(index)}
            style={{left: `${100 / max * index}%`, width: `${100 / max}%`}}
          >
            <div className={classNames(css.dot, value === index && css.selected)}/>
            {number}
          </div>
        ))}
      </div>
    </div>



  )
}

export default TSlider;