import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as lunaSend from "../../lunaSend";
import {Job} from '@enact/core/util';
import WebWorkerUtil, {WORKER_ID} from "../../utils/WebWorker/WebWorkerUtil";
import { changeLocalSettings } from "../../features/common/commonSlice";
import MatDummyActivator from '../../utils/MatDummyActivator';
import MatDataParser from '../../utils/MatDataParser';
import { BLE_MAT_USING_PANELS } from '../../utils/Constants';
import { popPanel } from '../panels/panelsSlice';
import { $L } from '../../utils/helperMethods';

export const CONNECTION_STATUS={
	idle: 'idle',
	searching: 'searching',
	searched: 'searched',
	searchingFailed: 'searchingFailed',
	connecting: 'connecting',
	connected: 'connected',
	disconnected: 'disconnected',
	failed: 'failed'
}

export const MAT_STATUS = {
	PAUSE: 'pause',
	PLAY: 'play',
	STOP: 'stop'
}

const BLE_JOB_TIME = 10000;
const updateDevicesJob = new Job((dispatch, func) => {
	dispatch(func());
}, 1000);

const gattLunaActionTimeOutJob = new Job((_onFail) => {
	console.log('bleSlice gattLunaActionTimeOutJob ',_onFail);
	_onFail();
}, BLE_JOB_TIME);


/** getDevice cancel */
let scanHandle = null;
export const cancelScan = createAsyncThunk("ble/cancelScan", async(_, thunkAPI) => {
	if(scanHandle){
		thunkAPI.dispatch(changeIsBleSearching(false));
		scanHandle.cancel();
		scanHandle = null;
		updateDevicesJob.stopJob();
	}
});

/** search devices */
export const getDevice = createAsyncThunk("ble/getDevice", async (_,thunkAPI) => {
	lunaSend.getDevice({
		onSuccess:(res) => {
			const bleList = [];
			if(res.devices && res.devices.length > 0){
				for(let i=0; i<res.devices.length; i++){
					if(res.devices[i].name && res.devices[i].name === '[LGE] LG Smart Mat'){
              bleList.push({
                ...res.devices[i],
                name: res.devices[i].name.replace("[LGE] ", ""),
              });
					}
				}
			}
			// bleList 업데이트 []
			thunkAPI.dispatch(getBleDeviceList(bleList));
		},
		onFailure:(err) => {
			console.log("bleSlice getDevice Failure ",err);
			gattLunaActionTimeOutJob.stop();
			thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.searchingFailed}));
		}
	})
})

/** GATT getStatus (connected, connecting) */
let gattConnectionStatusHandle = null;
let gattConnectionStatusResponseTimer = null;
export const gattGetStatus = createAsyncThunk("ble/gattGetStatus", async (address, thunkAPI) => {
	if(gattConnectionStatusHandle){
		gattConnectionStatusHandle.cancel();
		gattConnectionStatusHandle=null;
	}
	if(gattConnectionStatusResponseTimer){
		clearTimeout(gattConnectionStatusResponseTimer);
		gattConnectionStatusResponseTimer = null;
	}
	gattConnectionStatusHandle = lunaSend.gattGetStatus(address,{
		onSuccess:(res) => {
			console.log('bleSlice onSuccess gattGetStatus..........', res);
			clearTimeout(gattConnectionStatusResponseTimer);
			gattConnectionStatusResponseTimer = setTimeout(()=>{
				if(res.connecting){
					thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.connecting}));
				}else if(res.connected){
					lunaSend.createToast($L("The smart mat is connected."));
					thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.connected}));
				}else if(!res.connected){
					lunaSend.createToast($L("The smart mat is disconnected. Please check the connection status."));
					thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.failed}));
					BLE_MAT_USING_PANELS.map((panelName)=>{
						thunkAPI.dispatch(popPanel(panelName));
					});
					if(gattConnectionStatusHandle){
						gattConnectionStatusHandle.cancel();
						gattConnectionStatusHandle=null;
					}
					if(batteryHandle){
						batteryHandle.cancel();
						batteryHandle = null;
					}
					if(workingHandle){
						workingHandle.cancel();
						workingHandle = null;
					}
				}
			}, 300);
		},
		onFailure:(err) => {
			console.log("bleSlice gattGetStatus Failure ",err);
		},
		onComplete: (res) => {
			console.log("bleSlice gattGetStatus onComplete222222222", res)
		}
	})
})

/** scan
 *  will be error occured if no subscribe
*/
export const scan = createAsyncThunk("ble/leScan", async(autoConnect, thunkAPI) => {
	console.log("bleSlice scan Called autoConnect",autoConnect);
	const useBleSerial= thunkAPI.getState().common.localSettings.useBleSerial;
	if(useBleSerial){
		const {dummyBleGetDevices, dummyBleGattStatus, dummyBleGattConnect} = lunaSend.getDummyBleValues();
		const bleList = [];
		if(dummyBleGetDevices && dummyBleGetDevices.length > 0){
			for(let i=0; i<dummyBleGetDevices.length; i++){
				if(dummyBleGetDevices[i].name && dummyBleGetDevices[i].name === '[LGE] LG Smart Mat'){
					bleList.push({
						...dummyBleGetDevices[i],
						name: dummyBleGetDevices[i].name.replace("[LGE] ", ""),
					});
				}
			}
		}
		// bleList 업데이트 []
		thunkAPI.dispatch(getBleDeviceList(bleList));
		thunkAPI.dispatch(setGattConnectingStatus({ connectStatus: CONNECTION_STATUS.connected, address: dummyBleGattConnect.address, clientId: dummyBleGattConnect.clientId }));
		return;
	}
	if(!scanHandle){
		thunkAPI.dispatch(changeIsBleSearching(true));
		scanHandle = lunaSend.scan({
			onSuccess : (res) =>{
				gattLunaActionTimeOutJob.stop();
				updateDevicesJob.throttle(thunkAPI.dispatch, getDevice);
			},
			onFailure : (err) =>{
				console.log("bleSlice scan onFailure ", err);
				gattLunaActionTimeOutJob.stop();
				thunkAPI.dispatch(cancelScan());
			}
		});
	}
});

/** cancel scan, getDevice*/
export const cancelScanAndGetDevice = createAsyncThunk("ble/cancelScanAndGetDevice", async (_, thunkAPI) => {
	console.log('bleSlice cancelScanAndGetDevice');
	const connectStatus= thunkAPI.getState().ble.gattConnectingStatus.connectStatus;
	thunkAPI.dispatch(cancelScan());
	if(connectStatus === 'searching'){
		thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.idle}));
	}
})

/** GATT Connect */
export const gattConnect = createAsyncThunk("ble/gattConnect", async (address, thunkAPI) => {
	// monitor gatt status
	lunaSend.gattConnect(address, {
		onSuccess:(res) => {
			console.log('bleSlice gattConnect Success', res);
			gattLunaActionTimeOutJob.stop();
			thunkAPI.dispatch(gattGetStatus(address));
			thunkAPI.dispatch(setGattConnectingStatus({ address: res.address, clientId: res.clientId }));
			/** save localSetting, lastConnectedDevice */
			const lastConnectedDevices = thunkAPI.getState().common.localSettings.lastConnectedBleDevices;
			if (lastConnectedDevices) {
				const filteredLastConnectedDevice = lastConnectedDevices.filter((item) => item !== res.address);
				filteredLastConnectedDevice.push(res.address);
				thunkAPI.dispatch(changeLocalSettings({ lastConnectedBleDevices : filteredLastConnectedDevice }));
			}
			/** discoverServices */
			thunkAPI.dispatch(gattDiscoverServices(address));
		},
		onFailure:(err) => {
			console.log('bleSlice gattConnect Failure', err);
			gattLunaActionTimeOutJob.stop();

			thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.failed, address: address, clientId: 0}));
		},
		onComplete: (res) => {
			console.log("bleSlice gattConnect onComplete", res)
		}
	})
})

/** GATT Disconnect */
export const gattDisconnect = createAsyncThunk("ble/gattDisconnect", async (_, thunkAPI) =>{
	const clientId = thunkAPI.getState().ble.gattConnectingStatus.clientId;
	if(batteryHandle){
		batteryHandle.cancel();
		batteryHandle = null;
	}
	if(workingHandle){
		workingHandle.cancel();
		workingHandle = null;
	}
	if(gattConnectionStatusHandle){
		gattConnectionStatusHandle.cancel();
		gattConnectionStatusHandle=null;
	}
	lunaSend.gattDisconnect(clientId, {
		onSuccess:(res) => {
			console.log('bleSlice gattDisconnect success', res);
			lunaSend.createToast($L("The smart mat is disconnected."));
			thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.disconnected, address: '', clientId: ''}));
		},
		onFailure:(err) => {
			console.log('bleSlice gattDisconnect Failure', err);
			thunkAPI.dispatch(setGattConnectingStatus({connectStatus: CONNECTION_STATUS.disconnected, address: '', clientId: ''}));
		}
	})
});

/** discover Services */
export const gattDiscoverServices = createAsyncThunk("ble/gattDiscoverServices", async (address, thunkAPI) =>{
	lunaSend.gattDiscoverServices(address, {
		onSuccess: (res) => {
			console.log("bleSlice gattDiscoverServices success", res);
			thunkAPI.dispatch(getServices(address));
		},
		onFailure: (err) => {
			console.log("bleSlice gattDiscoverServices error", err);
		}
	})
});

/** get Services */
export const getServices = createAsyncThunk("ble/getServices", async (address, thunkAPI) =>{
	lunaSend.getServices(address, {
		onSuccess:(res) => {
			console.log('bleSlice getServices Success', res);
		},
		onFailure:(err) => {
			console.log('bleSlice getServices Failure', err);
		},
		onComplete: (res) => {
			console.log("bleSlice getServices onComplete", res)
		}
	})
});

/** battery noti on */
export const gattBatteryNotification = createAsyncThunk("ble/gattBatteryNotification", async (clientId, thunkAPI) =>{
	const gattConnectedClientId = thunkAPI.getState().ble.gattConnectingStatus.clientId;
	const service = "0000180f-0000-1000-8000-00805f9b34fb";
	const characteristic = "00002a19-0000-1000-8000-00805f9b34fb";
	if(!clientId){
		clientId = gattConnectedClientId;
	}
	lunaSend.writeDescriptorValue(clientId, [1,0], service, characteristic, {
		onSuccess:(res) => {
			thunkAPI.dispatch(gattBatteryMonitor(clientId));
			console.log('bleSlice gattBatteryNotification onSuccess', res);
		},
		onFailure:(err) => {
			console.log('bleSlice gattBatteryNotification Failure', err);
		},
		onComplete: () => {
		}
	})
});


/** battery Monitor */
let batteryHandle = null;
export const gattBatteryMonitor = createAsyncThunk("ble/gattMonitorCharacteristics", async (clientId, thunkAPI) =>{
	const gattConnectedClientId = thunkAPI.getState().ble.gattConnectingStatus.clientId;
	const service = "0000180f-0000-1000-8000-00805f9b34fb";
	const characteristics = ["00002a19-0000-1000-8000-00805f9b34fb"];
	if(!clientId){
		clientId = gattConnectedClientId;
	}
	if(batteryHandle){
		batteryHandle.cancel();
		batteryHandle = null;
	}
	batteryHandle = lunaSend.gattMonitorCharacteristics(clientId, service, characteristics, {
		onSuccess:(res) => {
			if(res.changed){
				const batteryValue = res.changed.value.bytes[0];
				console.log('bleSlice gattBatteryMonitor onSuccess', batteryValue);
				thunkAPI.dispatch(changeBatteryStatus(batteryValue))
			}
		},
		onFailure:(err) => {
			console.log('bleSlice gattBatteryMonitor Failure', err);
		},
		onComplete: () => {
		}
	})
});

/** gatt Monitor Characteristics */
// mode : walkmode, bodycheck1, bodycheck2, bodycheck3
// let lastCalled = new Date();
let matDataParser = null;
const monitorCharacteristics = (res, thunkAPI) => {
	const matStatus = thunkAPI.getState().ble.matStatus;
	const useBleSerial= thunkAPI.getState().common.localSettings.useBleSerial;

	//todo mode case
	if(!matDataParser){
		matDataParser = new MatDataParser(thunkAPI, useBleSerial);
	}
	if( matStatus === MAT_STATUS.PLAY){
		if(useBleSerial && res.data){
			matDataParser.updateMatData(res.data);
		}else if(res.changed){
			matDataParser.updateMatData(res.changed.value.bytes);
		}
	}
};
let workingHandle = null;
export const gattMonitorCharacteristics = createAsyncThunk("ble/gattMonitorCharacteristics", async ({clientId, mode}, thunkAPI) =>{
	const gattConnectedClientId = thunkAPI.getState().ble.gattConnectingStatus.clientId;
	const service = "531e00d0-ffa3-11ed-be56-0242ac120002";
	const characteristics = mode ==='walkmode' ? ["531e00d4-ffa3-11ed-be56-0242ac120002"]: ["531e00d6-ffa3-11ed-be56-0242ac120002"];
	if(!clientId){
		clientId = gattConnectedClientId;
	}
	if(workingHandle){
		workingHandle.cancel();
		workingHandle = null;
	}
	workingHandle = lunaSend.gattMonitorCharacteristics(clientId, service, characteristics, {
		onSuccess: (res)=> monitorCharacteristics(res, thunkAPI),
		onFailure:(err) => {
			console.log('bleSlice gattMonitorCharacteristics Failure', err);
		},
		onComplete: () => {
		}
	})
});

let matDummyActivator = null;

/*
 mode : walkmode, bodycheck1, bodycheck2, bodycheck3
 */
export const startStopBleService = createAsyncThunk("ble/startBleService", async ({clientId, mode, start=true, useDummy=false}, thunkAPI) =>{
	const gattConnectedClientId = thunkAPI.getState().ble.gattConnectingStatus.clientId;
	const useBleDummy = thunkAPI.getState().common.localSettings.useBleDummy;
	const useBleSerial= thunkAPI.getState().common.localSettings.useBleSerial;
	if(!clientId){
		clientId = gattConnectedClientId;
	}
	if(matDummyActivator){
		matDummyActivator.stopJob();
		matDummyActivator = null;
	}
	if(workingHandle){
		workingHandle.cancel();
		workingHandle = null;
	}
	if(useBleSerial){
		thunkAPI.dispatch(writeCharacteristicValue({clientId, mode, command: start ? "start":"stop"}));
		if(typeof window === "object" && !window.PalmSystem && start === true){
			matDummyActivator = new MatDummyActivator(monitorCharacteristics, thunkAPI, mode);
			matDummyActivator.startJob();
		}
		return;
	}
	const indicate = (mode !== 'walkmode');
	if(typeof window === "object" && window.PalmSystem && !useDummy && !useBleDummy){
		console.log('writeDescriptorValue', mode);
		const characteristic = mode === 'walkmode' ? "531e00d4-ffa3-11ed-be56-0242ac120002" :  "531e00d6-ffa3-11ed-be56-0242ac120002"
		const service = "531e00d0-ffa3-11ed-be56-0242ac120002"
			// noti [1,0] indicate [2,0] off [0,0]
		lunaSend.writeDescriptorValue(clientId, start && indicate ? [2,0] : start ? [1,0] : [0,0], service, characteristic, {
			onSuccess:(res) => {
				console.log('bleSlice writeDescriptorValue Success', res);
				// 산책모드 start
				thunkAPI.dispatch(writeCharacteristicValue({clientId, mode, command: start ? "start":"stop"}));
			},
			onFailure:(err) => {
				console.log('bleSlice writeDescriptorValue Failure', err);
			},
			onComplete: (res) => {
				console.log("bleSlice writeDescriptorValue onComplete", res);
			}
		});
	}else if(start === true){
		matDummyActivator = new MatDummyActivator(monitorCharacteristics, thunkAPI, mode);
		matDummyActivator.startJob();
	}
});

let monitorTimer = null;
// mode : walkmode, bodycheck1, bodycheck2, bodycheck3
// command : start, stop, scan
export const writeCharacteristicValue = createAsyncThunk("ble/writeCharacteristicValue", async ({clientId, mode, command="scan"}, thunkAPI) =>{
	const gattConnectedClientId = thunkAPI.getState().ble.gattConnectingStatus.clientId;
	const useBleSerial= thunkAPI.getState().common.localSettings.useBleSerial;
	if(!clientId){
		clientId = gattConnectedClientId;
	}
	const commands = //start, stop, scan
	{
		'walkmode': {start: 0x10, stop: 0x11},
		'bodycheck1': {start: 0x20, scan: 0x21, stop:0x22},
		'bodycheck2Left': {start: 0x30, scan: 0x31, stop:0x34},
		'bodycheck2Right': {start: 0x32, scan: 0x33, stop:0x34},
		'bodycheck3': {start: 0x40, stop:0x41},
	}
	const bytes = [0xA5, 0x00, commands?.[mode]?.[command]];
	// console.log('bleSlice writeCharacteristicValue clientId, mode, command, bytes:', clientId, mode, command, bytes);
	if(workingHandle){
		workingHandle.cancel();
		workingHandle = null;
	}
	if(monitorTimer){
		clearTimeout(monitorTimer);
	}
	if(useBleSerial){
		monitorTimer = setTimeout(()=>{
			lunaSend.sendMatSerialCommand("/dev/ttyUSB0", bytes, {
				onSuccess:(res) => {
					console.log('bleSlice sendMatSerialCommand Success', res);
					// monitor
					if(command === "start" || command === "scan"){
						workingHandle = lunaSend.receiveSerialCommand("/dev/ttyUSB0",{
							onSuccess: (res)=> monitorCharacteristics(res, thunkAPI),
							onFailure:(err) => {
								console.log('bleSlice gattMonitorCharacteristics Failure', err);
							},
							onComplete: () => {
							}
						});
					}
				},
				onFailure:(err) => {
					console.log('bleSlice sendMatSerialCommand Failure', err);
				}
			})
		}, 500);
		return;
	}
	monitorTimer = setTimeout(()=>{
		lunaSend.writeCharacteristicValue(clientId, bytes, {
			onSuccess:(res) => {
				console.log('bleSlice writeCharacteristicValue Success', res);
				// monitor
				if(command === "start" || command === "scan"){
					thunkAPI.dispatch(gattMonitorCharacteristics({clientId, mode}));
				}
			},
			onFailure:(err) => {
				console.log('bleSlice writeCharacteristicValue Failure', err);
			}
		})
	}, 500);
});

// 매트 LED 켜기 명령어
export const setMatLedOn = createAsyncThunk("ble/setMatLedOn", async ({clientId}, thunkAPI) =>{
	const gattConnectedClientId = thunkAPI.getState().ble.gattConnectingStatus.clientId;
	const useBleSerial = thunkAPI.getState().common.localSettings.useBleSerial;
	if(!clientId){
		clientId = gattConnectedClientId;
	}
	const bytes = [0xA5, 0x00, 0x01];

	if(useBleSerial){
		lunaSend.sendMatSerialCommand("/dev/ttyUSB0", bytes, {
			onSuccess:(res) => {
				console.log('bleSlice setMatLedOn Success', res);
			},
			onFailure:(err) => {
				console.log('bleSlice setMatLedOn Failure', err);
			}
		})
	}else{
		lunaSend.writeCharacteristicValue(clientId, bytes, {
			onSuccess:(res) => {
				console.log('bleSlice setMatLedOn Success', res);
			},
			onFailure:(err) => {
				console.log('bleSlice setMatLedOn Failure', err);
			}
		});
	}
});


/** Auto Connect */
export const gattAutoConnect = createAsyncThunk("ble/gattAutoConnect", async (_, thunkAPI) => {
	thunkAPI.dispatch(scan(true));
});

const initialBleSettings = { connectStatus: 'idle', address: '', clientId: '' };
const initialState = { bleDeviceList: [], gattConnectingStatus: initialBleSettings,
		matBytes: [], matStatus: MAT_STATUS.PLAY,
		isBleSearching: false,
		batteryStatus: 0
	};

const bleSlice = createSlice({
	name : "bleDeviceList",
	initialState,
	reducers : {
		getBleDeviceList: (state, action) => {
			state.bleDeviceList = action.payload;
		},
		setGattConnectingStatus: (state, action) => {
			state.gattConnectingStatus = {...state.gattConnectingStatus, ...action.payload};
		},
		//just for debug
		setMatBytesData: (state, action) => {
			state.matBytes = action.payload;
		},
		changeMatStatus: (state, action) => {
			state.matStatus = action.payload;
		},
		changeIsBleSearching: (state, action) => {
			state.isBleSearching = action.payload;
		},
		changeBatteryStatus:(state, action)=> {
			state.batteryStatus = action.payload;
		}
	}
})

export const {getBleDeviceList, setGattConnectingStatus, setMatBytesData, changeMatStatus, changeIsBleSearching, changeBatteryStatus} = bleSlice.actions;
export default bleSlice.reducer;
