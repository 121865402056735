import React, { useCallback, useState, useMemo, useEffect } from "react";
import css from "./TestSelectPopup.module.less";
import { $L } from "../../utils/helperMethods";
import TPopUp from "../../components/TPopUp/TPopUp";
import TCheckboxItem from "../../components/TCheckboxItem/TCheckboxItem";
import TScroller from "../TScroller/TScroller";
import { DATAKEY } from "../../features/bodyBalance/bodyBalanceSlice";
import { ROM_TYPE } from "../../views/BodyCheckUp/ROM/ROMTest/Constants";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import { BBT_TYPE } from "../../views/BodyCheckUp/BodyBalance/BodyBalanceTest/Constants";
import TDropdown from "../TDropDown/TDropDown";

const checkboxIndices = {
  upper: [ROM_TYPE.NECK, ROM_TYPE.SHOULDER, ROM_TYPE.TRUNK], // 상체
  lower: [ROM_TYPE.HIP, ROM_TYPE.KNEE], // 하체
};

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);
const TestSelectPopup = ({
  testInfos = [],
  initialCheckedState = [],
  physicalTest = false,
  romTest = false,
  onClose,
}) => {
  const [checked, setChecked] = useState(initialCheckedState);
  const [testIndex, setTestIndex] = useState(0);
  const dropDownMenu = [$L("Both"), $L("Left"), $L("Right")];

  const _testInfos = useMemo(() => {
    const newData = [];
    testInfos.map((data) => {
      if (data.resultName !== DATAKEY.ONE_LEG_STANCE_R) {
        const _data = { ...data };
        if (data.resultName === DATAKEY.ONE_LEG_STANCE_L) {
          _data.guideText = [$L("Location guide"), $L("Single leg balance")];
        }
        newData.push(_data);
      }
    });
    return newData;
  }, [testInfos]);

  useEffect(() => {
    //to remove ONE_LEG_STANCE_R
    if (_testInfos.length !== initialCheckedState.length) {
      setChecked(initialCheckedState.slice(0, _testInfos.length));
    }
  }, [testInfos]);

  // checked 배열에 true가 하나라도 있으면 return true
  const isAnyCheckboxChecked = useMemo(() => {
    return checked.some((value) => value);
  }, [checked]);

  // 상체, 하체 각 check -> selected
  const isUpperSelected = useMemo(() => {
    return checkboxIndices.upper.every((upperIndex) => checked[upperIndex]);
  }, [checked]);
  const isLowerSelected = useMemo(() => {
    return checkboxIndices.lower.every((lowerIndex) => checked[lowerIndex]);
  }, [checked]);

  const onToggle = useCallback(
    (index) => () => {
      let newChecked = [];
      if (index === "all") {
        newChecked = _testInfos.map(() => !checked.every((value) => value));
      } else if (Array.isArray(checkboxIndices[index])) {
        let currentValue = false;
        if (index === "upper") {
          currentValue = isUpperSelected;
        } else {
          currentValue = isLowerSelected;
        }
        newChecked = checked.map((value, i) =>
          checkboxIndices[index].includes(i) ? !currentValue : value
        );
      } else {
        newChecked = [...checked];
        newChecked[index] = !newChecked[index];
      }
      setChecked(newChecked);
    },
    [checked, _testInfos, isUpperSelected, isLowerSelected]
  );

  const _onClose = useCallback(
    (ev) => {
      if (onClose) {
        onClose(ev);
      }
    },
    [onClose]
  );

  const handleTestPopup = useCallback(
    (ev) => {
      if (ev === 0) {
        _onClose(ev);
      } else {
        const ret = [];
        for (let i = 0; i < _testInfos.length; i++) {
          if (checked[i]) {
            ret.push(_testInfos[i].type);
            if (_testInfos[i].resultName === DATAKEY.ONE_LEG_STANCE_L) {
              ret.push(BBT_TYPE.SINGLE_LEG_STANCE_R);
            }
          }
        }
        _onClose({ checked: ret });
        console.log("Starting selected tests:", ev);
      }
    },
    [_onClose, _testInfos, checked]
  );

  const onSelect = useCallback(({ selected }) => {
    setTestIndex(selected);
  }, []);

  return (
    <TPopUp
      kind="testSelectPopup"
      className={css.tPopUp}
      title={
        physicalTest
          ? $L("Select the test you'd like to take.")
          : $L("Select the body part you'd like to work out.")
      }
      text={$L(
        "We recommend that you select all items when testing for the first time."
      )}
      button1text={$L("Cancel")}
      button2text={$L("Getting started")}
      button2disabled={!isAnyCheckboxChecked}
      onClick={handleTestPopup}
      onClose={_onClose}
    >
      <Container className={css.checkBoxContainer}>
        <TScroller className={css.tscroller}>
          {!romTest && (
            <TCheckboxItem
              className={css.totalCheck}
              title={$L("Overall")}
              selected={checked.every((value) => value)}
              onToggle={onToggle("all")}
            />
          )}
          {romTest && (
            <div className={css.romSelectContainer}>
              <div className={css.romCheckBox}>
                <TCheckboxItem
                  className={css.upperCheck}
                  title={$L("Upper Body")}
                  selected={isUpperSelected}
                  onToggle={onToggle("upper")}
                />
                <TCheckboxItem
                  className={css.lowerCheck}
                  title={$L("Lower Body")}
                  selected={isLowerSelected}
                  onToggle={onToggle("lower")}
                />
              </div>
              {/* <div className={css.dropdownBox}>
								{dropDownMenu.length > 0 &&
									<TDropdown
										spotlightId={'testSelect_dropdown'}
										placeholder="testSelect_dropdown"
										width={378}
										size="small"
										direction="below"
										selected={testIndex}
										onSelect={onSelect}
									>
										{dropDownMenu}
									</TDropdown>
								}
							</div> */}
            </div>
          )}
          {_testInfos.map((list, index) => {
            return (
              <TCheckboxItem
                key={index}
                className={css.eachBox}
                title={list.guideText[1]}
                subText={
                  Array.isArray(list.testDetail)
                    ? list.testDetail.join(", ")
                    : list.testDetail
                }
                selected={checked[index]}
                onToggle={onToggle(index)}
              >
                {physicalTest && (
                  <div className={css.sftMode}>
                    {list.chair && (
                      <>
                        <span />
                        <span>{$L("chair")}</span>
                      </>
                    )}
                    {list.chair && list.dumbell && (
                      <span className={css.divideBar} />
                    )}
                    {list.dumbell && (
                      <>
                        <span />
                        <span>{$L("dumbbell")}</span>
                      </>
                    )}
                  </div>
                )}
              </TCheckboxItem>
            );
          })}
        </TScroller>
      </Container>
    </TPopUp>
  );
};

export default TestSelectPopup;
