import React, { useRef, useEffect, useCallback, useState, useMemo } from "react";
import {useDispatch, useSelector} from 'react-redux';

import classNames from "classnames";
import css from "./BodyAlignmentList.module.less";

import * as HelperMethods from "../../../utils/helperMethods";
import { $L } from "../../../utils/helperMethods";
import { addPanels } from "../../../features/panels/panelsSlice";
import BodyAlignmentItem from "./BodyAlignmentItem";
import * as Config from "../../../utils/Config";

import Scroller from '@enact/sandstone/Scroller/Scroller';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import THorizontalScrollList, {ITEM_SIZE} from "../../../components/THorizontalScrollList/THorizontalScrollList";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div" );

// const ITEM_SIZE = {
// 	WIDTH: 300,
// 	SPACING: 20
// }

const ITEMS = [
    {
      type: "headShift",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Head Shift"),
          type: "bodyAlignment", 
        subType: "HEAD_SHIFT",
        },
      },
    },
    {
      type: "neckTilt",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Neck Tilt"),
          type: "bodyAlignment", 
        subType: "NECK_TILT",
        },
      },
    },
    {
      type: "shoulderTilt",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Shoulder Tilt"),
          type: "bodyAlignment", 
        subType: "SHOULDER_TILT",
        },
      },
    },
    {
      type: "shoulderShift",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Shoulder Shift"),
          type: "bodyAlignment", 
        subType: "TRUNK_SHIFT",
        },
      },
    },
    {
      type: "pelvicRotation",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Pelvic Shift"),
          type: "bodyAlignment", 
        subType: "PELVIC_ROTATION",
        },
      },
    },
    {
      type: "pelvicTilt",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Pelvic Tilt"),
          type: "bodyAlignment", 
        subType: "PELVIC_TILT",
        },
      },
    },
    {
      type: "pelvicShift",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Pelvic Rotation"),
          type: "bodyAlignment", 
        subType: "PELVIC_SHIFT",
        },
      },
    },
    {
      type: "legAlignment",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Leg Alignment"),
          type: "bodyAlignment", 
        subType: "LEG_ALIGNMENT",
        },
      },
    },
    {
      type: "knee",
      target: {
        path: Config.panel_names.WORKOUT_PANEL,
        panelInfo: { 
        title: $L("Knee Flexion/Extension"),
          type: "bodyAlignment", 
        subType: "KNEE_FLEXION",
        },
      },
    },
];

const BodyAlignmentList = ({className, ...rest}) => {
	const dispatch = useDispatch();

	const _onClick = useCallback((item) => (ev) =>{
		if (item.target) {
        dispatch(
          addPanels({
            name: item.target.path,
            panelInfo: item.target.panelInfo,
          })
        );
		} 
	},[]);

	return (
		<div className={classNames(className, css.bodyAlignmentList)}>
      <div className={css.title}>{$L("근골격상태분석 기반 추천 카테고리")}</div>
			<THorizontalScrollList
        itemSize={ITEM_SIZE.small}
      >
        {ITEMS.map((item, index) => (
          <BodyAlignmentItem
            key={"item" + index}
            className={css.item}
            type={item.type}
            onClick={_onClick(item)}
          />
				))}
			</THorizontalScrollList>
		</div>

	);
}

export default BodyAlignmentList;