import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { $L, converDateStrtoDot } from "../../../utils/helperMethods";
import css from "./FitnessBriefing.module.less";
import TButton, { ICONS, SIZES, TYPES } from "../../../components/TButton/TButton";
import TDropdown from "../../../components/TDropDown/TDropDown";
import TSimpleDatePicker from "../../../components/TSimpleDatePicker/TSimpleDatePicker";
import TSimpleButtonTab from "../../../components/TSimpleButtonTab/TSimpleButtonTab";
import guideImage from "../../../../assets/guideImage.svg";
import dummy_briefing_01 from "../../../../assets/FitnessManager/dummy_briefing_01.png"
import dummy_briefing_02 from "../../../../assets/HomecareProgram/dummyImage.png"
import TScroller from "../../../components/TScroller/TScroller";
import useScrollTo from "../../../hooks/useScrollTo";
import Spottable from "@enact/spotlight/Spottable";
import Spotlight from "@enact/spotlight";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import { addPanels } from "../../../features/panels/panelsSlice";
import { panel_names } from "../../../utils/Config";
import { useDispatch } from "react-redux";
import dummy_capture_01 from "../../../../assets/images/dummy_capture_01.png"
import dummy_capture_02 from "../../../../assets/images/dummy_capture_02.png"
import dummy_capture_03 from "../../../../assets/images/dummy_capture_03.png"
import dummy_capture_04 from "../../../../assets/images/dummy_capture_04.png"
import classNames from "classnames";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  Spottable("div")
);

const getMock = () => ([
  {
    date: "2023-06-24",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_01,
        captureTime: 90,
        feedback: $L("Your body is out of balance when I sit and stand up"),
        minusScore: -10
      },
      {
        capture: dummy_capture_02,
        captureTime: 190,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -10
      },
      {
        capture: dummy_capture_03,
        captureTime: 362,
        feedback: $L("Bend your waist a little more than 90 degrees to the bottom."),
        minusScore: -10
      },
    ],
    totalScore: 74,
    image: dummy_briefing_01,
    rank: 30,
  },
  {
    date: "2023-07-01",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_01,
        captureTime: 90,
        feedback: $L("Your body is out of balance when I sit and stand up"),
        minusScore: -10
      },
      {
        capture: dummy_capture_02,
        captureTime: 190,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -10
      },
      {
        capture: dummy_capture_03,
        captureTime: 362,
        feedback: $L("Bend your waist a little more than 90 degrees to the bottom."),
        minusScore: -10
      },
    ],
    totalScore: 74,
    image: dummy_briefing_01,
    rank: 30,
  },
  {
    date: "2023-08-20",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_01,
        captureTime: 90,
        feedback: $L("Your body is out of balance when I sit and stand up"),
        minusScore: -10
      },
      {
        capture: dummy_capture_02,
        captureTime: 530,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -10
      }
    ],
    totalScore: 80,
    image: dummy_briefing_01,
    rank: 30,
  },
  {
    date: "2023-08-28",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_01,
        captureTime: 90,
        feedback: $L("Your body is out of balance when I sit and stand up"),
        minusScore: -10
      },
      {
        capture: dummy_capture_02,
        captureTime: 190,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -10
      },
      {
        capture: dummy_capture_03,
        captureTime: 362,
        feedback: $L("Bend your waist a little more than 90 degrees to the bottom."),
        minusScore: -10
      },
    ],
    totalScore: 74,
    image: dummy_briefing_01,
    rank: 30,
  },
  {
    date: "2023-08-29",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_01,
        captureTime: 90,
        feedback: $L("Your body is out of balance when I sit and stand up"),
        minusScore: -10
      },
      {
        capture: dummy_capture_02,
        captureTime: 190,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -10
      },
      {
        capture: dummy_capture_03,
        captureTime: 362,
        feedback: $L("Bend your waist a little more than 90 degrees to the bottom."),
        minusScore: -10
      },
    ],
    totalScore: 74,
    image: dummy_briefing_01,
    rank: 30,
  },
  {
    date: "2023-11-02",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_01,
        captureTime: 90,
        feedback: $L("Your body is out of balance when I sit and stand up"),
        minusScore: -10
      },
      {
        capture: dummy_capture_02,
        captureTime: 190,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -10
      },
      {
        capture: dummy_capture_03,
        captureTime: 362,
        feedback: $L("Bend your waist a little more than 90 degrees to the bottom."),
        minusScore: -10
      },
    ],
    totalScore: 74,
    image: dummy_briefing_01,
    rank: 30,
  },
  {
    date: "2023-12-29",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [],
    totalScore: 100,
    image: dummy_briefing_01,
    rank: 1,
  },
  {
    date: "2024-01-02",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_02,
        captureTime: 190,
        feedback: $L("Your body is out of balance when I sit and stand up"),
        minusScore: -10
      },
      {
        capture: dummy_capture_03,
        captureTime: 313,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -6
      },
      {
        capture: dummy_capture_04,
        captureTime: 383,
        feedback: $L("Bend your waist a little more than 90 degrees to the bottom."),
        minusScore: -10
      },
    ],
    totalScore: 74,
    image: dummy_briefing_01,
    rank: 30,
  },
  {
    date: "2024-01-08",
    title: $L("Whole-body stability-enhancing exercises (Prevention of injury, building a stable body)"),
    subTitle: $L("Senior Health Promotion Vitality Project (Stretching healthy today as well)"),
    minusList: [
      {
        capture: dummy_capture_02,
        captureTime: 190,
        feedback: $L("Put strength on the soles of both feet and come up like you're pushing the floor"),
        minusScore: -10
      },
      {
        capture: dummy_capture_04,
        captureTime: 313,
        feedback: $L("Bend your waist a little more than 90 degrees to the bottom."),
        minusScore: -8
      },
    ],
    totalScore: 80,
    image: dummy_briefing_02,
    rank: 30,
  },
]);

const FitnessBriefing = () => {
  const dispatch = useDispatch();
  const scrollTo = useRef(null);
  const mock = useMemo(() => {
    return getMock();
  }, []);
  //Date
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(1);
  const [selectedPeriod, setSelectedPeriod] = useState("quarterly");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const NOW = new Date();
  const [year, setYear] = useState(NOW.getFullYear());
  const [month, setMonth] = useState(NOW.getMonth() + 1);

  //Category dropDown
  const [selectedId, setSelectedId] = useState(null);
  const [data, setData] = useState(mock);
  const dropDownMenu = [$L("무언가"), $L("예시"), $L("List")];

  //SortButton
  const [sortOrder, setSortOrder] = useState("recent");

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  const scrollTop = useCallback(() => {
    if (scrollTo && scrollTo.current) {
      scrollTo.current({ position: { y: 0 }, animate: false });
    }
  }, []);

  const handleDateRange = useCallback(({ first, last }) => {
      const date = new Date(first);
      const newYear = date.getFullYear();
      const newMonth = date.getMonth() + 1;
      if (year !== newYear || month !== newMonth) {
        setYear(newYear);
        setMonth(newMonth);
      }
      setFirstDate(first);
      setLastDate(last);
    }, [year, month]);

  const onSelectedDate = useCallback(({ selectedDate }) => {
    setSelectedDate(selectedDate);
    scrollTop();
  }, []);

  const filterDataByDateRange = useCallback(
    (data) => {
      return data.filter((item) => {
        const itemDate = new Date(item.date);
        return (
          itemDate >= new Date(firstDate) && itemDate <= new Date(lastDate)
        );
      });
    },
    [firstDate, lastDate]
  );

  useEffect(() => {
    if (selectedPeriod === "total") {
      setData(mock);
    } else {
      const filteredData = filterDataByDateRange(mock);
      setData(filteredData);
    }
  }, [firstDate, lastDate, selectedPeriod]);

  const updateSelectedInterval = useCallback(({ index }) => {
    const periodMappings = {
      0: "total",
      1: "quarterly",
      2: "monthly",
      3: "weekly",
    };
    setSelectedPeriodIndex(index);
    setSelectedPeriod(periodMappings[index]);
    scrollTop();
  }, []);

  const onSelectId = useCallback(({ selected }) => {
    setSelectedId(selected);
  }, []);

  const handleSortClick = useCallback(
    (sortType) => {
      if (sortType === "recent") {
        if (selectedPeriod === "total") {
          setData(mock);
        } else {
          const filteredData = filterDataByDateRange(mock);
          setData(filteredData);
        }
        setSortOrder("recent");
      } else {
        const sortedData = [...data]; // 데이터 복사
        sortedData.sort((a, b) => a.title.localeCompare(b.title)); // 가나다순으로 정렬
        setData(sortedData);
        setSortOrder("asc");
      }
    },
    [data]
  );

  const _onKeyDown = useCallback((event) => {
    if (event.key === "ArrowDown") {
      Spotlight.focus("FitnessBriefing_tScroller");
    }
  }, []);

  const onClickDetail = useCallback((content) => () => {
    dispatch(addPanels({ name: panel_names.FITNESS_BRIEFING_DETAIL, panelInfo:content }));
    // console.log('onClickDetail content', content);
  }, [dispatch]);

  return (
    <Container>
      <div className={css.container}>
        <div className={css.dateWrap}>
          <TSimpleDatePicker
            period={selectedPeriod}
            selectedDate={selectedDate}
            onSelectedDate={onSelectedDate}
            handleDateRange={handleDateRange}
            size="small"
            width="small"
          />
          <TSimpleButtonTab
            listType={"small"}
            contents={[$L("Overall"), $L("Quarterly"), $L("Monthly"), $L("Weekly")]}
            onItemClick={updateSelectedInterval}
            selectedIndex={selectedPeriodIndex}
          />
        </div>
        <div className={css.filterSection}>
          <TDropdown
            placeholder={$L("Overall")}
            direction="below"
            size="small"
            width="small"
            selected={selectedId}
            onSelect={onSelectId}
          >
            {dropDownMenu}
          </TDropdown>
        </div>
        <TScroller
          className={css.scroller}
          cbScrollTo={getScrollTo}
          spotlightId={"FitnessBriefing_tScroller"}
          verticalScrollbar={"hidden"}
        >
          {data.length > 0
          ?
          data?.map((content, index) => (
            <Container className={css.contentContainer} key={`mainContent-${index}`}>
              <div className={css.dateContainer}>
                <div className={css.dateText}>{converDateStrtoDot(content.date, true, '.')}</div>
                <TButton
                  type={TYPES.noBg}
                  size={SIZES.tiny}
                  icon={ICONS.right}
                  className={css.datailBtn}
                  onClick={onClickDetail(content)}
                >
                  {$L("View Details")}
                  <div className={css.icon}/>
                </TButton>
              </div>
              <div  className={css.mainContainer}>
                <img className={css.imgBox} src={content.image}></img>
                <div className={css.textBox}>
                  <div className={css.title}>{content.title}</div>
                  <div className={css.subTitle}>{content.subTitle}</div>
                  {content?.minusList.length > 0 && content.minusList.map((li, index) => (
                    <div key={`minusList-${index}`} className={css.scoreBox}>
                      <span className={classNames(css.score, li.minusScore <= -10 ? css.red : css.yellow)}>{li.minusScore}</span>
                      <span className={css.scoreText}>{li.feedback}</span>
                    </div>
                  ))}
                  {/* {Object.keys(content.minusList).map((key, index) => (
                    <div key={`bottomContent-${index}`} className={css.scoreBox}>
                      <span className={css.score}>{content.score}</span>
                      <span className={css.scoreText}>{content.data[key]}</span>
                    </div>
                  ))} */}
                </div>
                <div className={css.totalScore}>
                  <div className={css.totalScoreNumber}>{content.totalScore}</div>
                  <div className={css.totalScoreText}>{$L("Total Score")}</div>
                </div>
              </div>
            </Container>
          ))
          :
          <div className={css.contentContainer}>
            {$L("운동 기록이 없습니다.")}
          </div>
          }
        </TScroller>
      </div>
    </Container>
  );
};

export default FitnessBriefing;
