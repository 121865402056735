import { useState, useEffect, useMemo, useCallback } from "react";
import { $L } from "../../../utils/helperMethods";
import css from "./FitnessGoalSettingDetail.module.less";
import TPanel from "../../../components/TPanel/TPanel";
import THeader from "../../../components/THeader/THeader";
import TBody from "../../../components/TBody/TBody";
import TButton from "../../../components/TButton/TButton";
import { InputField } from "@enact/sandstone/Input";
import { popPanel } from "../../../features/panels/panelsSlice";
import { useDispatch } from "react-redux";

const FitnessGoalSettingDetail = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const [number, setNumber] = useState(0);
  const [valid, setValid] = useState(false);

  const panelType = useMemo(() => {
    const type = panelInfo.type;
    let maxLength;
    let inputLabel;
    let name;
    if (type === "Days") {
      maxLength = 3;
      inputLabel = $L("Days");
      name = $L("Number of days you worked out");
    } else if (type === "hr") {
      maxLength = 4;
      inputLabel = $L("hr");
      name = $L("Workout Time");
    } else {
      maxLength = 5;
      inputLabel = $L("Kcal");
      name = $L("Calories burned");
    }

    return { name, maxLength, inputLabel };
  }, [panelInfo.type]);

  const onNumberChange = useCallback((e) => {
    if (e.value && e.value.length > panelType.maxLength) {
      setNumber(e.value.slice(0, panelType.maxLength));
    } else {
      setNumber(e.value);
    }
  }, []);

  const onSave = useCallback(() => {
    dispatch(popPanel());
  }, []);

  const onCancel = useCallback(() => {
    dispatch(popPanel());
  }, [dispatch]);

  useEffect(() => {
    if (number > 0) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [number]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={panelType.name} iconType="back" />
      <TBody className={css.tBody}>
        <div className={css.inputBox}>
          <InputField
            className={css.inputField}
            autoFocus
            type={"number"}
            value={number}
            onChange={onNumberChange}
            maxLength={panelType.maxLength}
          />
          <div className={css.inputLabel}>{panelType.inputLabel}</div>
        </div>
        <div className={css.buttonBox}>
          <TButton onClick={onCancel}>{$L("Cancel")}</TButton>
          <TButton onClick={onSave} disabled={!valid}>
            {$L("Save")}
          </TButton>
        </div>
      </TBody>
    </TPanel>
  );
};

export default FitnessGoalSettingDetail;
