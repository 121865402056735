import React, { useState, useEffect, useCallback } from "react";
import * as Config from "../../../utils/Config";
import classNames from "classnames";
import css from "./PhysicalTest.module.less";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TButton, { SIZES } from "../../../components/TButton/TButton";
import { useDispatch, useSelector } from "react-redux";
import PhysicalOverallContainer from "./PhysicalOverallContainer";

//임시이미지
import Spotlight from "@enact/spotlight";

import { addPanels, resetPanels } from "../../../features/panels/panelsSlice";
import useTestResult from "../../../hooks/useTestResult";
import useCaptureImage from "../../../hooks/useCaptureImage";
import { changeAppStatus } from "../../../features/common/commonSlice";
import { MENUTYPE } from "./Constants";
import PTSubTabContainer from "./PTSubTabContainer/PTSubTabContainer";
import useUserCheck from "../../../hooks/useUserCheck";
import { $L } from "../../../utils/helperMethods";
import { getContentList } from "../../../features/fitService/fitServiceSlice";
import TestSelectPopup from "../../../components/TestSelectPopup/TestSelectPopup";
import { getSFT_TEST_INFO } from "./PhysicalTestCapture/Constants";
import { SFT_TYPE } from "./PhysicalTestCapture/Constants";
import CameraPopup from "../../Camera/CameraPopup";

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);

/**
 * @module PhysicalTestTabContentsLayout
 * @returns PhysicalTest tab하단부
 */

let SFT_TEST_INFO = null;
function PhysicalTestTabContentsLayout({
  physicalTestInfo,
  contentsInfos,
  isEmpty,
  feedback,
  selectedDate,
}) {
  if(!SFT_TEST_INFO){
    SFT_TEST_INFO = getSFT_TEST_INFO();
  }
  const dispatch = useDispatch();
  const { userCheck } = useUserCheck();
  const { cameraList } = useSelector(state => state.camera);
  const [showTestSelectPopup, setShowTestSelectPopup] = useState(false);
  const initialCheckedState = Array(SFT_TEST_INFO.length).fill(true);
  const { cameraRecordingActivated } = useSelector(
    (state) => state.common.localSettings
  );
  const { isSFTResultReady } = useTestResult();
  const { saveRecordedVideo } = useCaptureImage({});
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);

  useEffect(() => {
    Spotlight.focus("sftContentsLayoutTestBtn");
  }, [physicalTestInfo]);

  const goToWorkout = () => {
    console.log("goToWorkout in Physical test");

    dispatch(
      getContentList({
        type: "recommend",
        subType: "physicalTest",
        category: physicalTestInfo.type,
        subCategory: "A",
      })
    );
    dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { contentId: `physicalTest_${physicalTestInfo.type}_A` } }));
    // dispatch(
    //   addPanels({
    //     name: Config.panel_names.HOMECARE_RECOMMEND_WORKOUT,
    //     panelInfo: {
    //       index: 3,
    //       contentId: `physicalTest_${physicalTestInfo.type}_A`,
    //     },
    //   })
    // );
  };

  //
  const mapMenuTypeToSFTType = (menuType) => {
    switch (menuType) {
      case MENUTYPE.UPPER_BODY_STR:
        return SFT_TYPE.UPPER_BODY_STR;
      case MENUTYPE.LOWER_BODY_STR:
        return SFT_TYPE.LOWER_BODY_STR;
      case MENUTYPE.AEROBIC:
        return SFT_TYPE.AEROBIC;
      case MENUTYPE.LOWER_BODY_FLEX:
        return SFT_TYPE.LOWER_BODY_FLEX;
      case MENUTYPE.STATIC_BALANCE:
        return SFT_TYPE.STATIC_BALANCE;
      case MENUTYPE.UP_AND_GO_TEST:
        return SFT_TYPE.UP_AND_GO_TEST;
      default:
        return -1;
    }
  };

  const goToPhysicalTestCapture = useCallback((ev) => {
    console.log('physicalTestInfo.type', physicalTestInfo.type);
    if (!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: "test" }}));
    } else if (cameraList.length < 1) {
      setIsConnectionPopupOpen(true);
    } else {
      if (physicalTestInfo.type === MENUTYPE.OVERALL) {
        setShowTestSelectPopup(true);
      } else {
        dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
        dispatch(addPanels({ name: Config.panel_names.PHYSICAL_TEST_CAPTURE, panelInfo: { sftList: [mapMenuTypeToSFTType(physicalTestInfo.type)] }}));
      }
    }
  }, [dispatch, userCheck, physicalTestInfo, cameraList]);


  const goToHomecare = useCallback(() => {
    dispatch(resetPanels());
  }, [dispatch]);

  const saveVideo = useCallback(() => {
    saveRecordedVideo();
  }, [saveRecordedVideo]);

  const closeTestSelectPopup = useCallback((ev) => {
    setShowTestSelectPopup(false);
    if (ev.checked) {
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
      dispatch(addPanels({name: Config.panel_names.PHYSICAL_TEST_CAPTURE, panelInfo: { sftList: ev.checked}}));
    }
  }, []);

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  return (
    <div
      className={css.cl_contentsLayoutType01}
      style={physicalTestInfo.type === MENUTYPE.OVERALL ? { paddingTop: "61px" } : {}}
    >
      <div className={css.physicalTestTabContentsWrapper}>
        <div className={css.physicalTestTabContents}>
          {physicalTestInfo.type === MENUTYPE.OVERALL ? (
            <PhysicalOverallContainer
              contentsInfos={contentsInfos}
              selectedDate={selectedDate}
              isEmpty={isEmpty}
            />
          ) : (
            <PTSubTabContainer
              physicalTestInfo={physicalTestInfo}
              selectedDate={selectedDate}
              feedback={feedback}
            />
          )}
        </div>

        {!isEmpty && (
          <Container className={classNames(css.cl_btnArea)}>
            {physicalTestInfo.type !== MENUTYPE.OVERALL && (
              <TButton
                style={{ marginRight: "20px" }}
                size={SIZES.test}
                onClick={goToWorkout}
              >
                {$L("Workout")}
              </TButton>
            )}
            <TButton
              size={SIZES.test}
              onClick={goToPhysicalTestCapture}
              spotlightId="sftContentsLayoutTestBtn"
            >
              {$L("Test")}
            </TButton>
            {physicalTestInfo.type === MENUTYPE.OVERALL &&
              cameraRecordingActivated && (
                <TButton onClick={saveVideo}>saveLastRecordedVideo</TButton>
              )}
          </Container>
        )}
      </div>
      {showTestSelectPopup && (
        <TestSelectPopup
          testInfos={SFT_TEST_INFO}
          initialCheckedState={initialCheckedState}
          onClose={closeTestSelectPopup}
          physicalTest={true}
        />
      )}
      {isConnectionPopupOpen && <CameraPopup onClose={onCloseCameraPopup}/>}
    </div>
  );
}

export default PhysicalTestTabContentsLayout;
