import { useState, useCallback } from "react";
import css from "./DetailLayout.module.less";
import TStepperTest from "../../../../components/TStepperTest/TStepperTest";
import { $L } from "../../../../utils/helperMethods";

const order = [$L("Start"), $L("1주"), $L("2주"), $L("3주"), $L("4주")];
const TESTLIST = {
  testNum: [
    {
      my: 28,
      program: 68,
    },
    {
      my: 50,
      program: 50,
    },
  ],
};

const TStepperLayout = () => {
  return (
    <>
      <div className={css.stepperTestLayout}>
        {TESTLIST.testNum.map((test) => (
          <TStepperTest
            order={order}
            numberMy={test.my}
            numberProgram={test.program}
            type={"horizontal"}
          />
        ))}
      </div>
    </>
  );
};

export default TStepperLayout;
