import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Spotlight from "@enact/spotlight";
import IHOAPopupPanel from "../IHOAPopupPanel";
import TButton, { SIZES } from "../../../components/TButton/TButton";
import { $L } from "../../../utils/helperMethods";
import css from "./ActivityPopup.module.less";
import img_complete_01 from "../../../../assets/inHomeOutdoorActivity/img_complete_01.svg";
import { ACTIVITY_SEQUENCE } from "./Constants";

const ActivityEndPopup = ({ onClick, onClose, ...props }) => {
  const { userId, profileNick } = useSelector((state) => state.common.appStatus);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);

  const _onClick = useCallback((idx) => (ev) => {
    if (onClick) onClick(idx)(ev);
  }, [onClick]);

  const _onClose = useCallback((ev) => {
    if(onClose) onClose(ev);
  }, [onClose]);

  useEffect(() => {
    setTimeout(() => Spotlight.focus('activityEndPopup_firstSpot'), 0);
  },[])

  const userName = useMemo(() => {
    return profileNick || (userId ? userId.split('@')[0] : '')
  }, [userId, profileNick]);

  return (
    <IHOAPopupPanel onClose={_onClose} {...props}>
      <div className={css.endContainer}>
        <img src={img_complete_01}/>
        <div>
          <div className={css.title} dangerouslySetInnerHTML={{ __html: cesShowMode ? $L("Great job!") : $L("Great job, <span>{NICKNAME}</span>!").replace("{NICKNAME}", userName) }}/>
          <div className={css.text}>{$L("You've completed the course!")}<br/>{$L("Now, take a deep breath and finish.")}</div>
        </div>
      </div>
      <div className={css.btnWrapper}>
        <TButton spotlightId="activityEndPopup_firstSpot" size={SIZES.xLarge} className={css.btn} onClick={_onClick(ACTIVITY_SEQUENCE.INIT)}>{$L("Repeat the workout")}</TButton>
        <TButton size={SIZES.xLarge} className={css.btn} onClick={_onClick(ACTIVITY_SEQUENCE.REPORT)}>{$L("See workout results")}</TButton>
      </div>
    </IHOAPopupPanel>
  );
};

export default ActivityEndPopup;