import React, { useState, useEffect, useCallback, useRef } from "react";
import css from "./TIconButton.module.less";
import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";
import { Job } from "@enact/core/util";
import * as Config from "../../utils/Config";
import { useSelector, useDispatch } from "react-redux";
import { popPanel, resetPanels } from "../../features/panels/panelsSlice";
import classNames from "classnames";
import * as SoundEffect from "../../utils/SoundEffect";

const SpottableComponent = Spottable("div");

/**
 *
 * @param iconType 아이콘 타입 개별 지정
 * @param children
 * @param size : normal/small
 * @param ...rest
 * @returns TIconButton
 */

const SIZES = {tiny: "tiny", small: "small", };
const COLOR = {black: "black"};

const ICON_TYPES = {
  none: "none",
  back: "back",
  home: "home",
  pause: "pause",
  testPause: "testPause",
  play: "play",
  leftArrow: "leftArrow",
  rightArrow: "rightArrow",
  underArrow: "underArrow",
  leftArrowS: "leftArrowS",
  rightArrowS: "rightArrowS",
  calendar: "calendar",
  heart: "heart",
  heartHollow: "heartHollow",
  heartWhite: "heartWhite",
  heartHollowWhite: "heartHollowWhite",
  plus: "plus",
  minus: "minus",
  mat: "mat",
  matNo: "matNo",
  camera: "camera",
  cameraNo: "cameraNo",
  close: "close",
  alert: "alert",
  alertNoti: "alertNoti",
  speaker: "speaker",
  calendarArrow: "calendarArrow",
  top: "top",
  like: "like",
  likeHollow: "likeHollow",
  controlArrow: "controlArrow",
  countPlus: "countPlus",
  countMinus: "countMinus",
  question: 'question'
};

const TIconButton = ({
  className,
  iconType = ICON_TYPES.none,
  size = "normal",
  color,
  spotlightId = undefined,
  children,
  onClick,
  spotlightDisabled,
  disabled,
  //tooltip
  itemRenderer,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { themeMode } = useSelector((state) => state.common.appStatus);
  const isModalOpen = useSelector((state) => state.panels.isModalOpen);
  const [pressed, setPressed] = useState(false);
  const [isTooltip, setTooltip] = useState(false);

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback((ev) => {
    if (disabled) {
      ev.stopPropagation();
      return;
    }
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick(ev);
      }
      switch (iconType) {
      case ICON_TYPES.back: {
        dispatch(popPanel());
        break;
      }
      case ICON_TYPES.home: {
        dispatch(resetPanels());
        break;
      }
    }
    });
    },
    [dispatch, iconType, onClick, disabled]
  );

  const _onBlur = useCallback(() => {
    setPressed(false);
    clearPressedJob.current.stop();

    if(itemRenderer) {
      setTooltip(false);
    }
  }, []);

  const _onFocus = useCallback(() => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);

    if(itemRenderer) {
      setTooltip(true);
    }
  }, []);

  return (
    <SpottableComponent
      spotlightId={spotlightId}
      className={classNames(
        css.tIconButton,
        css[iconType],
        css[size],
        css[color],
        disabled && css.disabled,
        className ? className : null,
        pressed && css.pressed,
        (themeMode === "dark" || themeMode === "transparent") && css.isDark
      )}
      onClick={_onClick}
      onBlur={_onBlur}
      onFocus={_onFocus}
      spotlightDisabled={isModalOpen || spotlightDisabled}
      disabled={isModalOpen || disabled}
      {...rest}
    >
      {children}
      {itemRenderer && itemRenderer(isTooltip)}
    </SpottableComponent>
  );
};

export default TIconButton;
export { ICON_TYPES, SIZES, COLOR, TIconButton };
