import "animate.css";
import classnames from "classnames";
import css from "./PhysicalTestCapture.module.less";
import { useCallback, useState, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $L, convertNewlinesToBr, formatTime } from "../../../../utils/helperMethods";
import { Cancelable } from "@enact/ui/Cancelable";
import PIPCamera from "../../../../components/PIPCamera/PIPCamera";
import * as Config from "../../../../utils/Config";
import * as HelperMethods from "../../../../utils/helperMethods";
import TShakaPlayer from "../../../../components/TShakaPlayer";
import { addPanels, updateModalStatus, updatePanel, popPanel } from "../../../../features/panels/panelsSlice";
import { changeAppStatus } from "../../../../features/common/commonSlice";
import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";
import TStepper from "../../../../components/TStepper/TStepper";
import { Job } from "@enact/core/util";
import { BELLY_CHECKING_INDEX, BellySmoother } from "../../../../utils/Constants";
import { changeLocalSettings } from "../../../../features/common/commonSlice";
import { setCurrentTestingName, updateCurrentTestingStatus, clearCurrentTestingStatus } from "../../../../features/physicalTest/physicalTestSlice";
import usePhysicalTest from "../../../../hooks/usePhysicalTest";
import useActivatePositionDetection from "../../../../hooks/useActivatePositionDetection";
import { SFT_TYPE, SFT_SEQUENCE, getSFT_TEST_INFO, SFT_TEST_ONTIME, GUIDE_START_TIME, initAllSftTestOnTime, initSftTestOnTime } from "./Constants";
import WebWorkerUtil, { WORKER_ID } from "../../../../utils/WebWorker/WebWorkerUtil";
import ShoulderAngleDataParser from "./ShoulderAngleDataParser";

// component
import TPopUp from "../../../../components/TPopUp/TPopUp";
import TButton from "../../../../components/TButton/TButton";
import bellyPoint from "../../../../../assets/bodyScan/point.png";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import * as SoundEffect from "../../../../utils/SoundEffect";
import BodyPositionViewer from "../../../../components/BodyPositionViewer";
import TToolTip, { POSITION, COLOR } from "../../../../components/TToolTip/TToolTip";
import BodyCheckUpCountdownTimer from "../../BodyCheckUpCountdownTimer/BodyCheckUpCountdownTimer";
import * as TTSService from "../../../../lunaSend/TTSService";
import TestEndCompletePopup from "../../../../components/TestEndCompletePopup/TestEndCompletePopup";
import TestPartCompletePopup from "../../../../components/TestPartCompletePopup/TestPartCompletePopup";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import BodyPositionCorrectionSign from "../../BodyAlignment/BodyPositionCorrectionSign/BodyPositionCorrectionSign";
import TTestClosePopup from "../../../../components/TTestClosePopup/TTestClosePopup";
import TestSelectPopup from "../../../../components/TestSelectPopup/TestSelectPopup";
import BaseLine from "../../../../components/BaseLine/BaseLine";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TIconButton from "../../../../components/TIconButton/TIconButton";
import DistanceGuideCanvas from "./DistanceGuideCanvas/DistanceGuideCanvas";

const resumeTestJob = new Job((resumeTest) => {
	resumeTest();
}, 0);

const clearGuideTextJob = new Job(
	(setGuideText, setVoiceGuide, voiceCode = "") => {
		setGuideText({ index: null, text: "" });
		voiceCode && setVoiceGuide(voiceCode);
	}, 0);

let SFT_TEST_INFO = null;

let lastCalledBaseLine = null;
const CancelableDiv = Cancelable(
	{ modal: true, onCancel: "handleCancel" },
	"div"
);
const SpottableDiv = Spottable("div");
const ButtonContainer = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");
const DEFAULT_SFT_LIST = [
	SFT_TYPE.LOWER_BODY_STR,
	SFT_TYPE.UPPER_BODY_STR,
	SFT_TYPE.AEROBIC,
	SFT_TYPE.LOWER_BODY_FLEX,
	SFT_TYPE.STATIC_BALANCE,
	SFT_TYPE.UP_AND_GO_TEST,
];

const PhysicalTestCapture = ({ panelInfo, ...rest }) => {
	if (!SFT_TEST_INFO) {
		SFT_TEST_INFO = getSFT_TEST_INFO();
	}
	const mWorker = useRef(null);
	const skipRef = useRef();
	const finishDelayRef = useRef(null);
	const bellySmootherRef = useRef([]);
	const [playBGM, setPlayBGM] = useState(false);
	const [isCountDownStarted, setIsCountDownStarted] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [guideText, setGuideText] = useState({ index: null, text: "" });
	const [currentSftIndex, setCurrentSftIndex] = useState(0);
	const [sftSequence, setSftSequence] = useState(-1);
	const sftSequenceRef = useRef(-1);
	const noResponseTimerRef = useRef(null);
	const broadcast = useSelector((state) => state.common.broadcast);
	//for LOWER_BODY_FLEX and STATIC_BALANCE  : two times test
	const sftProcessShape = useRef("");
	const sftProcessCount = useRef(0);
	const mountedTime = useRef(null);
	const initialCheckedState = Array(SFT_TEST_INFO.length).fill(true);
	const [loopVoiceGuide, setLoopVoiceGuide] = useState("");
	const [voiceGuide, setVoiceGuide] = useState("");
	const [retry, setRetry] = useState("");
	const [exerciseCounter, setExerciseCounter] = useState({});
	const [totalSftScore, setTotalSftScore] = useState({});
	const [sftTsvData, setSftTsvData] = useState({});
	const [isTimeUp, setIsTimeUp] = useState(true);
	const [showTestPartComplete, setShowTestPartComplete] = useState(false);
	const [showTestEndComplete, setShowTestEndComplete] = useState(false);
	const [kneeGuideLineStyle, setKneeGuideLineStyle] = useState(null);
	const [avatarImage, setAvatarImage] = useState(null);
	const [upAndgo, setUpAndGo] = useState(false);
	const [instrunctionSetting, setInstructionSetting] = useState('');
	const { saveSFTData, getkneeGuideLineStyle, saveTestResults } = usePhysicalTest();
	const { cameraPosition, skipVideoGuide, xFitBodyBellySmootherQueueSize, showBodyCoordinations, sftEnduranceRatio } = useSelector((state) => state.common.localSettings);
	const { xFitKTestThresHoldInterval, xFitKTestThresHoldOneLegShoulderAngle, xFitKTestThresHoldWalkingShoulderAngle, xFitKTestThresHoldOneLegShoulderDistance, xFitKTestThresHoldWalkingShoulderDistance, xFitKTestThresHoldWalkingPelvicAngle, xFitKTestThresHoldWalkingPelvicDistance
	  } = useSelector((state) => state.common.localSettings);
  const { sex: userGender, age: userAge } = useSelector(
    (state) => state.fitService.survey
  );

	const { isAppForeground, showLoadingPanel } =
		useSelector((state) => state.common.appStatus);
	const { cameraList, cameraSize } = useSelector((state) => state.camera);
	const { activatePositionDetection, deActivatePositionDetection, bodyPositionLiveData, cancelGetEventNotification } = useActivatePositionDetection();

	const { physicalTestSkip, chairSelected, dumbbellSelected } = useSelector(
		(state) => state.common.localSettings
	);
	const [isInstruction, setIsInstruction] = useState(!physicalTestSkip);
	const [isTestClose, setTestClose] = useState(false); //test 중에 뒤로가기

	const dispatch = useDispatch();
	const videoProgressRef = useRef(0);
	const webcamRef = useRef(null);
	const PTVideoPlayerRef = useRef();
	const shoulderAngleDataParserRef = useRef();
	const [bodyPositionCorrectionDirection, setBodyPositionCorrectionDirection] = useState({});

	// Guide-test Title
	const renderGuideText = useCallback(() => {
		if (guideText.text) {
			return (
				<div>
					{sftList.length !== 1 && sftSequence !== SFT_SEQUENCE.TEST_START &&
						<div className={css.testNo}>{sftSequence === SFT_SEQUENCE.GUIDE_START ? $L("Guide").toUpperCase() : $L("Test").toUpperCase()} {currentSftIndex + 1}</div>
					}
					<div className={css.guideText}>{guideText.text}</div>
				</div>
			)
		}
		return null;
	}, [guideText]);

	const sftList = useMemo(() => {
		if (panelInfo?.sftList) {
			return panelInfo?.sftList;
		}
		return DEFAULT_SFT_LIST;
	}, [panelInfo]);

	const getInstructionMode = useCallback((sftList)=>{
		const hasLowerBodyStr = sftList.includes(SFT_TYPE.LOWER_BODY_STR);
	  const hasUpperBodyStr = sftList.includes(SFT_TYPE.UPPER_BODY_STR);
	  const hasLowerBodyFlex = sftList.includes(SFT_TYPE.LOWER_BODY_FLEX);
	  const hasUpAndGoTest = sftList.includes(SFT_TYPE.UP_AND_GO_TEST);

		if (hasUpperBodyStr) {
			setInstructionSetting('both');
		} else if(hasLowerBodyStr || hasLowerBodyFlex || hasUpAndGoTest){
			setInstructionSetting('chair');
		} else{
			setInstructionSetting('');
			setIsInstruction(false);
			setTimeout(()=>{
				setPlayBGM(true);
				setSftSequence(SFT_SEQUENCE.GUIDE_START);
			}, 5000);
		}

		if(hasUpAndGoTest){
			setUpAndGo(true);
		}
	}, [sftList, instrunctionSetting, sftSequence, isInstruction]);

	useEffect(() => {
    const sftList = panelInfo?.sftList || DEFAULT_SFT_LIST;
    getInstructionMode(sftList);
  }, [panelInfo]);

	const order = useMemo(() => {
		const ret = [];
    ret.push($L("Start"));
		for (let i = 0; i < sftList.length; i++) {
			ret.push(SFT_TEST_INFO[sftList[i]].guideText[1]);
		}
    ret.push($L("Finished"));
		return ret;
	}, [sftList]);

	useEffect(() => {
		const canvasSize = { w: 960, h: 1080 };
		// const cameraSize = { w: 1280, h: 720 };

		const scale = canvasSize.h / cameraSize.height;
		const leftMargin = (cameraSize.width * scale - canvasSize.w) / 2;
		if (!!bodyPositionLiveData?.joints2D?.length) {
			BELLY_CHECKING_INDEX.forEach((value, index) => {
				const bellyCoordination = bodyPositionLiveData.joints2D[value];
				const reversedCameraBellyX =
					canvasSize.w - (bellyCoordination[0] * scale - leftMargin);
				const reversedCameraBellyY = bellyCoordination[1] * scale;
				if (!bellySmootherRef.current[index]) {
					bellySmootherRef.current[index] = new BellySmoother(
						xFitBodyBellySmootherQueueSize
					);
				}
				bellySmootherRef.current[index].pushValue(
					reversedCameraBellyX,
					reversedCameraBellyY
				);
			});
		}
	}, [bodyPositionLiveData, cameraSize]);

	const showPIPCamera = useMemo(() => {
		if (!isAppForeground) {
			return false;
		}
		if (typeof window === "object" && window.PalmSystem) {
			return cameraList && cameraList.length > 0;
		} else {
			return true;
		}
		// return false //only for loading status development
	}, [isAppForeground, cameraList]);

	useEffect(()=>{
		if(cameraList && cameraList.length < 1){
			dispatch(popPanel(Config.panel_names.PHYSICAL_TEST_CAPTURE));
		}
	},[cameraList]);

	const currentSftTestOnTime = useMemo(() => {
		return SFT_TEST_ONTIME[sftList[currentSftIndex]] ? SFT_TEST_ONTIME[sftList[currentSftIndex]] : {};
	}, [currentSftIndex, sftList]);

	const currentSftTestInfo = useMemo(() => {
		return SFT_TEST_INFO[sftList[currentSftIndex]] ? SFT_TEST_INFO[sftList[currentSftIndex]] : {};
	}, [currentSftIndex, sftList]);

	const showExerciseCounter = useMemo(() => {
		return (
			sftSequence === SFT_SEQUENCE.TEST_START ||
			sftSequence === SFT_SEQUENCE.GET_SCORE ||
			sftSequence === SFT_SEQUENCE.TEST_END ||
			sftSequence === SFT_SEQUENCE.SEQUENCE_FINISHED
		);
	}, [sftSequence]);

	const showTimeCounter = useMemo(() => {
		return (currentSftTestInfo.type === SFT_TYPE.STATIC_BALANCE || currentSftTestInfo.type === SFT_TYPE.UP_AND_GO_TEST);
	}, [currentSftTestInfo]);


	const onCameraReady = useCallback(() => {
		dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
		//추가지점
		if (!isInstruction) {
			dispatch(updateModalStatus(false));
			setTimeout(() => {
				setSftSequence(SFT_SEQUENCE.SEQUENCE_INIT);
				// delay: MIN_SHOWING_TIME+HIDING_TIME in loadingpanel
			}, (4000 - (new Date() - mountedTime.current)));
		}
	},[dispatch, isInstruction, sftSequence]);

	const onTimeCounterComplete = useCallback(() => {
		// console.log('onTimeCounterComplete currentSftIndex', currentSftIndex);
		setShowTestPartComplete(false);
		setIsTimeUp(true);
		setSftSequence(SFT_SEQUENCE.GET_SCORE);
	}, [sftSequence]);

	const countdownCompleted = useCallback(() => {
		// console.log("countdownCompleted+sftSequence=", sftSequence, currentSftIndex);
		setIsTimeUp(false);
		setSftSequence(SFT_SEQUENCE.TEST_START);
		setIsCountDownStarted(false);
		setVoiceGuide("RING2");
	}, []);

	const doSequancialAction = useCallback((action, key) => {
		if (action && !action.excuted) {
			// console.log("PhysicalTestCapture doSequancialAction[action,key,sftSequenceRef]:", action, key, sftSequenceRef.current, 'currentSftIndex', currentSftIndex);
			action.excuted = true;
			if (typeof action.avatarImage !== "undefined") {
				setAvatarImage(action.avatarImage);
			}
			if (action.voiceGuide) {
				setVoiceGuide(action.voiceGuide);
			}
			if (typeof action.loopVoiceGuide !== "undefined") {
				setLoopVoiceGuide(action.loopVoiceGuide);
			}
			if (action.videoPause) {
				PTVideoPlayerRef.current.pause();
			}
			if (action.sequence) {
				setSftSequence(action.sequence);
			}
		}
	}, []);

	const onVideoEnd = useCallback(() => {
		// console.log('onVideoEnd currentSftTestOnTime', currentSftTestOnTime);
		const action = currentSftTestOnTime["end"];
		doSequancialAction(action, "end");
	}, [currentSftTestOnTime, doSequancialAction]);

	const onVoiceGuideEnd = useCallback((ev) => {
		// console.log("PhysicalTest onVoiceGuideEnd", ev, currentSftIndex);
		if (ev === currentSftTestInfo.preCountVoice) {
			setIsCountDownStarted(true);
		}
		else if (ev === currentSftTestInfo.guideAudio) {
			onVideoEnd();
		}
	}, [currentSftTestInfo, onVideoEnd]);

	// const closeInstructionModal = useCallback(() => {
	//   setIsInstruction(false);
	//   setPlayBGM(true);
	//   Spotlight.focus("#shakaPTPlayer");
	//   if (skipVideoGuide) {
	//     onVideoEnd();
	//   } else if (PTVideoPlayerRef.current) {
	//     PTVideoPlayerRef.current.play();
	//     if (!currentSftTestInfo.guideVideo) {
	//       if (currentSftTestInfo.guideAudio) {
	//         setVoiceGuide(currentSftTestInfo.guideAudio);
	//       } else {
	//         onVideoEnd();
	//       }
	//     }
	//   }
	// }, [skipVideoGuide, onVideoEnd, currentSftTestInfo]);

	const onResponseWorker = useCallback((e) => {
		console.log("PhysicalTestCapture onResponseWorker", e, 'currentSftIndex', currentSftIndex);
		if (e.type === 'checkLocation' || e.type === 'checkLocationSide') {
			const bodyAdjustmentDirection = e.value;
			setBodyPositionCorrectionDirection(bodyAdjustmentDirection);
		}
		if (e.type === "getSftScore") {
			const sftScore = e.value;
			setExerciseCounter((prevState) => ({
				...prevState,
				[currentSftTestInfo.resultName]: { ...sftScore },
			}));
			setTotalSftScore(sftScore);
			dispatch(updateCurrentTestingStatus(sftScore));
      }
      if (e.type === "getSftScoreBoundary") {
        const sftBoundary = e.value;
        setExerciseCounter((prevState) => ({
          ...prevState,
          [currentSftTestInfo.resultName]: {
            ...prevState[currentSftTestInfo.resultName],
            boundary: [...sftBoundary],
          },
        }));
        dispatch(updateCurrentTestingStatus({ boundary: [...sftBoundary] }));
		}
		if (e.type === "getSftBaseline") {
			const baseline = e.value;
			if (baseline.result) {
				setKneeGuideLineStyle(getkneeGuideLineStyle(baseline.result));
				clearTimeout(noResponseTimerRef.current);
				noResponseTimerRef.current = setTimeout(() => {
					setKneeGuideLineStyle(null);
				}, 3000);
			}
		}
		if (e.type === "process") {
			const processResult = e.value;
			if (currentSftTestInfo.type === SFT_TYPE.LOWER_BODY_FLEX || currentSftTestInfo.type === SFT_TYPE.STATIC_BALANCE || currentSftTestInfo.type === SFT_TYPE.UP_AND_GO_TEST) {
				if (sftProcessShape.current !== processResult?.shape && processResult?.shape === "end") {
					sftProcessCount.current += 1;
				}
				sftProcessShape.current = processResult?.shape;
				if (sftProcessCount.current >= 1) {
					setSftSequence(SFT_SEQUENCE.GET_SCORE);
				}
			} else if (currentSftTestInfo.type === SFT_TYPE.ONE_LEGGED_STAND) {
				if (processResult?.shape?.state === "ing") {
					if (!shoulderAngleDataParserRef.current) {
						shoulderAngleDataParserRef.current = new ShoulderAngleDataParser(
							currentSftTestInfo.engineParams.index
						);
					}
					shoulderAngleDataParserRef.current.pushData(
						processResult.value,
						processResult.shape
					);
				}
				if (processResult?.shape?.state === "end") {
					setSftSequence(SFT_SEQUENCE.GET_SCORE);
				}
			} else if (currentSftTestInfo.type === SFT_TYPE.POWER_WALKING) {
				if (processResult?.shape) {
					if (!shoulderAngleDataParserRef.current) {
						shoulderAngleDataParserRef.current = new ShoulderAngleDataParser(
							currentSftTestInfo.engineParams.index
						);
					}
					shoulderAngleDataParserRef.current.pushData(
						processResult.value,
						processResult.shape
					);
				}
			}
			// console.log("PhysicalTestCapture onResponseWorker processResult ", processResult, sftProcessCount.current);
			if (processResult) {
				setExerciseCounter((prevState) => {
					if (processResult.value > 0 && prevState[currentSftTestInfo.resultName].value !== processResult.value) {
						if (currentSftTestInfo.type !== SFT_TYPE.UP_AND_GO_TEST && currentSftTestInfo.type !== SFT_TYPE.STATIC_BALANCE && currentSftTestInfo.type !== SFT_TYPE.LOWER_BODY_FLEX) {
							SoundEffect.playAudio("COUNT_" + processResult.value);
						}
					}
					return {
						...prevState,
						[currentSftTestInfo.resultName]: { ...processResult },
					};
				});
			}
		}
	},
		[currentSftTestInfo, getkneeGuideLineStyle]);

	useEffect(() => {
		WebWorkerUtil.setCallback(mWorker.current, onResponseWorker);
	}, [onResponseWorker]);

	useEffect(() => {
		Spotlight.focus("sftGuideSkipBtn");
		Spotlight.focus("sftSkipBtn");
	}, [sftSequence])

	useEffect(() => {
		sftSequenceRef.current = sftSequence;
		console.log("PhysicalTest sftSequence ", currentSftTestInfo.resultName, sftSequence, isInstruction, currentSftIndex);
		switch (sftSequence) {
			case SFT_SEQUENCE.SEQUENCE_INIT: {
				if (PTVideoPlayerRef.current && !showLoadingPanel.show) {
					if (!isInstruction) {
						setPlayBGM(true);
						if (skipVideoGuide) {
							onVideoEnd();
						} else {
							PTVideoPlayerRef.current?.play();
							if (!currentSftTestInfo.guideVideo) {
								if (currentSftTestInfo.guideAudio) {
									setVoiceGuide(currentSftTestInfo.guideAudio);
								} else {
									onVideoEnd();
								}
							}
						}
					}
				}
				break;
			}
			case SFT_SEQUENCE.GUIDE_START: {
				initSftTestOnTime(sftList[currentSftIndex]);
				if (skipVideoGuide) {
					onVideoEnd();
				} else {
					if (PTVideoPlayerRef.current && PTVideoPlayerRef.current.paused()) {
						PTVideoPlayerRef.current.play();
					}
					setGuideText({ index: currentSftTestInfo.index, text: currentSftTestInfo.guideText[0] });
					clearGuideTextJob.startAfter(2500, setGuideText); //todo for test
				}
				break;
			}
			case SFT_SEQUENCE.GUIDE_END: {
				setGuideText({ index: currentSftTestInfo.guideTextIndex, text: currentSftTestInfo.guideText[1] });
				clearGuideTextJob.startAfter(2500, setGuideText, setVoiceGuide, currentSftTestInfo.preCountVoice);
				shoulderAngleDataParserRef.current = null;
				// setVideoRatioJob.startAfter(2000, setVideoRatio, 5000);
				WebWorkerUtil.postMessage(mWorker.current, { type: "init" }, true);
				WebWorkerUtil.postMessage(mWorker.current, { type: "setSFTEnduranceRatio", value: [sftEnduranceRatio] }, true);
				WebWorkerUtil.postMessage(mWorker.current, { type: "setParam", value: ["sft", currentSftTestInfo.engineParams.index, currentSftTestInfo.engineParams.direction] }, true);
				activatePositionDetection({ isDetectionDelayed: false });
				break;
			}
			case SFT_SEQUENCE.TEST_START: {
				if (typeof window === "object" && !window.PalmSystem) {
					shoulderAngleDataParserRef.current = new ShoulderAngleDataParser(
						currentSftTestInfo.engineParams.index
					);
				}
				dispatch(setCurrentTestingName(currentSftTestInfo.resultName));
				dispatch(clearCurrentTestingStatus(currentSftTestInfo.resultName));
				sftProcessCount.current = 0;
				setExerciseCounter((prevState) => ({
					...prevState,
					[currentSftTestInfo.resultName]: { value: 0 },
				}));
				break;
			}
			case SFT_SEQUENCE.GET_SCORE: {
				setKneeGuideLineStyle(null);
				setShowTestPartComplete(false);
				setVoiceGuide("YEAH");
				let age = userAge;
				if (Number(userAge) > 94 || Number(userAge) < 60) {
					age = "60";
				}
				if (currentSftTestInfo.type === SFT_TYPE.ONE_LEGGED_STAND || currentSftTestInfo.type === SFT_TYPE.POWER_WALKING) {
					//do nothing
					if (shoulderAngleDataParserRef.current) {
						const thresHold = {
							interval: xFitKTestThresHoldInterval,
							shoulderAngle:
								currentSftTestInfo.type === SFT_TYPE.ONE_LEGGED_STAND
									? xFitKTestThresHoldOneLegShoulderAngle
									: xFitKTestThresHoldWalkingShoulderAngle,
							shoulderDistance:
								currentSftTestInfo.type === SFT_TYPE.ONE_LEGGED_STAND
									? xFitKTestThresHoldOneLegShoulderDistance
									: xFitKTestThresHoldWalkingShoulderDistance,
						};
						if (currentSftTestInfo.type === SFT_TYPE.POWER_WALKING) {
							thresHold.pelvicAngle = xFitKTestThresHoldWalkingPelvicAngle;
							thresHold.pelvicDistance =
								xFitKTestThresHoldWalkingPelvicDistance;
						}
						const result = shoulderAngleDataParserRef.current.getResult(thresHold);
						setSftTsvData((prevState) => ({
							...prevState,
							[currentSftTestInfo.resultName]: { ...result },
						}));
					}
				}
        WebWorkerUtil.postMessage(
          mWorker.current,
          {
            type: "getSftScore",
            value: [age, userGender === 0 ? "male" : "female"],
          },
          true
        );
        WebWorkerUtil.postMessage(
          mWorker.current,
          {
            type: "getSftScoreBoundary",
            value: [
              currentSftTestInfo.engineParams.index,
              HelperMethods.clampingNumber(age, 60, 94),
              userGender === 0 ? "male" : "female",
            ],
          },
          true
        );
				setTimeout(() => {
					setSftSequence(SFT_SEQUENCE.TEST_END);
				}, 2500);
				break;
			}
			//no break;
			case SFT_SEQUENCE.TEST_END: {
				setKneeGuideLineStyle(null);
				setShowTestPartComplete(false);
				setIsTimeUp(true);
				cancelGetEventNotification();
				if (currentSftIndex + 1 >= sftList.length) {
					setVoiceGuide("M23");
					setShowTestEndComplete(true);
					finishDelayRef.current = setTimeout(() => {
						setSftSequence(SFT_SEQUENCE.SEQUENCE_FINISHED);
					}, 4800);
				} else {
					setShowTestPartComplete(true);
				}
				break;
			}
			case SFT_SEQUENCE.SEQUENCE_FINISHED: {
				dispatch(setCurrentTestingName(""));
				saveTestResults();
				if(!retry){
					setTimeout(() => {
						dispatch(popPanel(Config.panel_names.PHYSICAL_TEST_CAPTURE));
						dispatch(addPanels({ name: Config.panel_names.PHYSICAL_TEST_REPORT }));
					}, 0);
				}
				// saveSFTData(excerciseCounter, sftTsvData);
				break;
			}
		}
	}, [sftSequence, dispatch]);

	const onVideoProgress = useCallback((ev) => {
		const current = ev;
		// trigger
		if (!PTVideoPlayerRef.current.paused()) {
			const keys = Object.keys(currentSftTestOnTime);
			let matched = -1;
			for (let i = 0; i < keys.length; i++) {
				if (Number(current) >= Number(keys[i]) && !currentSftTestOnTime[keys[i]].excuted) {
					matched = keys[i];
					break;
				}
			}
			const action = currentSftTestOnTime[matched];
			doSequancialAction(action, matched);
		}
		videoProgressRef.current = current;
	}, [currentSftTestOnTime, doSequancialAction]);

	const skipRenderer = useCallback((isTooltip) => {
		return (
			<TToolTip
				position={POSITION.top}
				color={COLOR.green}
				relativeRef={skipRef}
				relativePosition="left"
				isOpen={isTooltip}
			>
				{$L("Skip the guide video for the current body part.")}
			</TToolTip>
		);
	}, []);

	useEffect(() => {
		// dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: "tips" } }));
		setMounted(true);
		WebWorkerUtil.makeWorker(WORKER_ID.XFIT, onResponseWorker).then(
			(workerId) => {
				mWorker.current = workerId;
			}
		);
		dispatch(clearCurrentTestingStatus());
		setTimeout(() => {
			TTSService.stop();
		}, 1000);

		mountedTime.current = new Date();

		return () => {
			initAllSftTestOnTime();
			setPlayBGM(false);
			deActivatePositionDetection();
			dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
		};
	}, []);

	useEffect(() => {
		console.log("isTimeUp, bodyPositionLiveData] xfit 888=", isTimeUp, bodyPositionLiveData, currentSftTestInfo.type);
		if (!isTimeUp && bodyPositionLiveData !== undefined && !!bodyPositionLiveData?.id) {
			if (currentSftTestInfo.engineParams.direction === "front") {
				WebWorkerUtil.postMessage(mWorker.current, { type: "checkLocation", value: [bodyPositionLiveData, [cameraSize.width, cameraSize.height]] }, true);
			} else {
				WebWorkerUtil.postMessage(mWorker.current, { type: "checkLocationSide", value: [bodyPositionLiveData, [cameraSize.width, cameraSize.height]] }, true);
			}
			if (currentSftTestInfo.type === SFT_TYPE.AEROBIC || currentSftTestInfo.type === SFT_TYPE.STATIC_BALANCE || currentSftTestInfo.type === SFT_TYPE.POWER_WALKING) {
				const current = new Date();
				if (!lastCalledBaseLine || current - lastCalledBaseLine > 2500) {
					WebWorkerUtil.postMessage(mWorker.current, { type: "getSftBaseline", value: [bodyPositionLiveData] }, true);
					lastCalledBaseLine = current;
				}
			}
			WebWorkerUtil.postMessage(mWorker.current, { type: "process", value: [bodyPositionLiveData] }, true);
		}
	}, [isTimeUp, bodyPositionLiveData, currentSftTestInfo]);

	useEffect(() => {
		if(broadcast?.type === 'positionLiveDataCleared'){
			BELLY_CHECKING_INDEX.forEach((_, index) => {
				bellySmootherRef.current[index]?.clearQueue();
			});
		}
	}, [broadcast]);

	const skipTest = useCallback(() => {
		setSftSequence(SFT_SEQUENCE.TEST_END);
	}, [sftSequence]);

	const seekToPrevious = useCallback((sec) => {
		const keys = Object.keys(currentSftTestOnTime);
		let matched = -1;
		for (let i = 0; i < keys.length; i++) {
			if (Number(keys[i]) >= sec) {
				currentSftTestOnTime[keys[i]].excuted = false;
			}

			if (Number(sec) >= Number(keys[i]) && !currentSftTestOnTime[keys[i]].excuted) {
				matched = keys[i];
				break;
			}
		}
		setSftSequence(SFT_SEQUENCE.GUIDE_START);
		PTVideoPlayerRef.current.seekTo(GUIDE_START_TIME);
		setPlayBGM(true);
		setIsCountDownStarted(false);

		const action = currentSftTestOnTime[matched];
		doSequancialAction(action, matched);
	}, [doSequancialAction, currentSftTestOnTime]);

	const onTestPause = useCallback((e) => {
		if (PTVideoPlayerRef.current) {
			PTVideoPlayerRef.current.pause();
		}

		setSftSequence(-1);
		setPlayBGM(false);
		// setIsCountDownStarted(false);
		setIsTimeUp(true);
		setRetry(false);
		setShowTestPartComplete(false);
		setShowTestEndComplete(false);
		setGuideText({ text: "" });
		clearGuideTextJob.stop();
		clearGuideTextJob.startAfter(0, setGuideText, setVoiceGuide, "");
		setLoopVoiceGuide("");
		setVoiceGuide("");
		setKneeGuideLineStyle(null);
		setAvatarImage("");
		sftProcessCount.current = null;
		sftProcessShape.current = null;
		shoulderAngleDataParserRef.current = null;
		setExerciseCounter((prevState) => ({
			...prevState,
			[currentSftTestInfo.resultName]: { value: 0 },
		}));
	}, []);

	const resumeTest = useCallback(() => {
		Spotlight.focus("#shakaPTPlayer");
		seekToPrevious(GUIDE_START_TIME);
	}, []);

	const onTestResume = useCallback(() => {
		resumeTestJob.startAfter(500, resumeTest);
	}, [resumeTestJob]);

	const backKeyHandler = useCallback((ev) => {
		dispatch(popPanel());
		if (ev) {
			ev?.stopPropagation();
			ev?.preventDefault();
		}
	},[dispatch]);

	const pauseHandler = useCallback((ev) => {
		if (ev) ev.stopPropagation();
		if (isTestClose) {
			if (PTVideoPlayerRef) {
				onTestResume();
			}
			setTestClose(false);
		} else {
			if (sftSequence !== SFT_SEQUENCE.SEQUENCE_INIT &&
				sftSequence !== SFT_SEQUENCE.SEQUENCE_FINISHED
			) {
				setTestClose(true);
				onTestPause();
			} else {
				return;
			}
		}
	}, [dispatch, sftSequence, isTestClose]);

	const backToPreviousStep = useCallback(() => {
		PTVideoPlayerRef.current.seekTo(0);
		let currenTestIndex = currentSftIndex;
		setShowTestPartComplete(false);
		setCurrentSftIndex(currenTestIndex);
		setSftSequence(SFT_SEQUENCE.GUIDE_START);
	}, [currentSftIndex]);

	const retrySelectedTest = useCallback(() => {
		setShowTestPartComplete(false);
		setRetry(true);
		clearTimeout(finishDelayRef.current);
	}, []);

	const nextStep = useCallback(() => {
		let nextIndex = currentSftIndex + 1;
		setShowTestPartComplete(false);
		setCurrentSftIndex(nextIndex);
		setSftSequence(SFT_SEQUENCE.GUIDE_START);
	}, [currentSftIndex]);

	// skip VideoGuide
	const skipGuideVideo = useCallback(() => {
		PTVideoPlayerRef.current.pause();
		setVoiceGuide("");
		onVideoEnd();
	}, [onVideoEnd]);

	const onClickSkip = useCallback(() => {
		dispatch(changeLocalSettings({ physicalTestSkip: !physicalTestSkip }));
	}, [physicalTestSkip]);

  const retryPreviousTest = useCallback(()=>{
    let testIndex;
    onTestPause();
		setPlayBGM(true);
    PTVideoPlayerRef.current.seekTo(0);
    testIndex = currentSftIndex - 1;
    initAllSftTestOnTime();
    setCurrentSftIndex(testIndex);
    setSftSequence(SFT_SEQUENCE.SEQUENCE_INIT);
  },[currentSftIndex, sftList, sftSequence]);


	const handleTestStart = useCallback(({ checked }) => {
		// console.log('physicalTest handleTestStart sftSequenceRef', sftSequenceRef.current, checked);
		setRetry(false);
		setShowTestPartComplete(false);
		setShowTestEndComplete(false);
		if (!checked) {
			if (currentSftIndex + 1 >= sftList.length) {
				setSftSequence(SFT_SEQUENCE.SEQUENCE_FINISHED);
			} else {
				let nextIndex = currentSftIndex + 1;
				setCurrentSftIndex(nextIndex);
				setSftSequence(SFT_SEQUENCE.SEQUENCE_INIT);
			}
		}
		if (checked) {
			setCurrentSftIndex(0);
			dispatch(updatePanel({ name: Config.panel_names.PHYSICAL_TEST_CAPTURE, panelInfo: { sftList: checked } }));
			//action excuted initializing
			checked.forEach(element => {
				const keys = Object.keys(SFT_TEST_ONTIME[element]);
				for (let i = 0; i < keys.length; i++) {
					SFT_TEST_ONTIME[element][keys[i]].excuted = false;
				}
			});
			setSftSequence(SFT_SEQUENCE.SEQUENCE_INIT);
		}
	}, [dispatch, currentSftIndex, sftList]);

	const onClosePopup = useCallback((index) => {
		if (index) {
			// 시작하기
			Spotlight.focus("#shakaPTPlayer");
			setSftSequence(SFT_SEQUENCE.SEQUENCE_INIT);
		} else {
			// 나가기
			dispatch(popPanel());
		}
		setIsInstruction(false);
	}, []);

	const onClickTools = useCallback((type) => () => {
		// 의자 또는 덤벨 선택
		// console.log(type);
		if (type === "chair") {
			dispatch(changeLocalSettings({ chairSelected: !chairSelected }));
		} else {
			dispatch(changeLocalSettings({ dumbbellSelected: !dumbbellSelected }));
		}
	}, [dispatch, chairSelected, dumbbellSelected]);

	const onClickTestClose = useCallback((index) => {
		if (!index) {
			if (PTVideoPlayerRef) onTestResume();
		}
		setTestClose(false);
	}, [PTVideoPlayerRef]);

	// for renderRealTimeRecord, renderSftResult
	const currentExerciseCounter = useMemo(() => {
		return exerciseCounter[currentSftTestInfo.resultName];
	}, [exerciseCounter, currentSftTestInfo]);

	// user RealTime Record
	const isLowerBodyFlex = currentSftTestInfo.type === SFT_TYPE.LOWER_BODY_FLEX;

	const renderRealTimeRecord = useCallback(() => {
		return (
			<div className={showTimeCounter ? css.timeCounter : css.exerciseCounter}>
				<div className={showTimeCounter ? css.detailTime : css.detailCount}>
					{showTimeCounter ?
						formatTime(currentExerciseCounter.value) :
						`${currentExerciseCounter.value} ${(showTimeCounter || isLowerBodyFlex) ? null : $L('회')}`
					}
				</div>
				{showTimeCounter ?
					null :
					<div className={css.testTimeSec}>
						{formatTime(currentSftTestInfo.testTimeSec)}
					</div>
				}
			</div>
		)
	}, [currentSftTestInfo, exerciseCounter]);

	// user SftResult
	const renderSftResult = useCallback(() => {
    const isPrevent = ['s', 'a'].includes(totalSftScore.grade);
    const isManage = ['b', 'c'].includes(totalSftScore.grade);
    const gradeClass = isPrevent ? css.prevent : isManage ? css.manage : css.danger;
		// console.log('renderSftResult', exerciseCounter, currentExerciseCounter);
		return(
			<div className={showTimeCounter || isLowerBodyFlex ? css.timeResult : css.sftScoreResult}>
				{isLowerBodyFlex ?
					<div className={classnames(css.sGrade, gradeClass)}>
						{$L("Grade {grade}").replace("{grade}", totalSftScore?.grade.toUpperCase())}
					</div>
					:
					<div className={classnames(css.grade, gradeClass)}>
            {isPrevent ? $L("Good") : isManage ? $L("Borderline") : $L("Weak")}
				</div>
				}
				<div className={showTimeCounter ? css.timeValue : isLowerBodyFlex ? null : css.countResult}>
					{showTimeCounter ?
						formatTime(currentExerciseCounter.value)
						: isLowerBodyFlex ? null : currentExerciseCounter.value
					}
					{showTimeCounter ? <span/> : isLowerBodyFlex ? null : <span>{$L("회")}</span>}
				</div>
				{showTimeCounter ?
					null : <div className={isLowerBodyFlex ? css.bodyFlexTime : css.time}>{isLowerBodyFlex ? "00:04" : formatTime(currentSftTestInfo.testTimeSec)}</div>
				}
			</div>
		)
	},[exerciseCounter, currentSftTestInfo, totalSftScore]);

	return (
		<CancelableDiv className={css.container} handleCancel={backKeyHandler}>
			{/* Stepper */}
			{mounted && sftList.length !== 1 &&
				<TStepper
					className={css.stepper}
					order={order}
					number={sftSequenceRef.current === SFT_SEQUENCE.SEQUENCE_FINISHED ? order.length - 1 : currentSftIndex + 1}
					type={"horizontal"}
				/>
			}
			{/* guide-test title */}
			<div
				className={classnames(css.workoutTitleContainer, "animate__animated", {
					animate__fadeIn: guideText.index || guideText.text,
					animate__fadeOut: !(guideText.index || guideText.text),
				})}
			>
				{renderGuideText()}
			</div>
			<div className={css.cl_bgVideoType}>
				{/* 3,2,1 */}
				{showPIPCamera && !isTestClose && sftSequence === SFT_SEQUENCE.GUIDE_END &&
					<BodyCheckUpCountdownTimer
						isCountDownStarted={isCountDownStarted}
						countdownCompleted={countdownCompleted}
					/>
				}
				<section>

					{/* left - TshakaPlayer */}
					<SpottableDiv
						id="shakaPTPlayer"
						className={classnames(css.avatar)}
					// onClick={onTestPause}
					>
						<TShakaPlayer
							playerRef={PTVideoPlayerRef}
							className={css["shaka-player"]}
							src={currentSftTestInfo.guideVideo}
							onProgress={onVideoProgress}
							onEnded={onVideoEnd}
						/>
						{!showLoadingPanel.show && (
							<img
								width={"100%"}
								height={"100%"}
								style={{
									opacity: avatarImage ? "1.0" : "0",
									position: "absolute",
								}}
								src={avatarImage}
								alt={"videoPlay"}
							/>
						)}
						{/* direction guide */}
						<div className={css.labelContainer}>
							<div className={classnames(css.label)}>{"L"}</div>
							<div className={classnames(css.label, css.isRight)}>{"R"}</div>
						</div>
					</SpottableDiv>

					{/* right- User Data */}
					<div className={classnames(css.user)}>
						{showPIPCamera && (
							<>
								<PIPCamera
									webcamRef={webcamRef}
									size="medium"
									position={cameraPosition}
									onCameraReady={onCameraReady}
								/>
								{showBodyCoordinations && sftSequence !== SFT_SEQUENCE.GUIDE_END && sftSequence !== SFT_SEQUENCE.TEST_END && (
									<BodyPositionViewer
										bodyPositionLiveData={bodyPositionLiveData}
										cameraSize={cameraSize}
									/>
								)}
								{sftSequence === SFT_SEQUENCE.TEST_START &&
									<div className={css.bellyPointContainer}>
										{/* Belly Point */}
										{BELLY_CHECKING_INDEX.map((bIndex, index) => {
											const point = bellySmootherRef.current[index]
												? bellySmootherRef.current[index].getAverageData()
												: [];
											const style = point[0]
												? { left: point[0] + "px", top: point[1] + "px" }
												: {};
											return (
												<img
													key={index}
													src={bellyPoint}
													alt="bellyPoint"
													className={classnames(
														css.bellyPoint,
														point.length <= 0 && css.hide
													)}
													style={style}
												/>
											);
										})}
									</div>
								}
								{sftSequence === SFT_SEQUENCE.TEST_START && currentSftTestInfo.type === SFT_TYPE.LOWER_BODY_FLEX &&
									<DistanceGuideCanvas bodyPositionLiveData={bodyPositionLiveData} cameraSize={cameraSize}/>
								}
								{/* Body Position Sign except lower body flex, up and go*/}
								<BodyPositionCorrectionSign
									bodyPositionCorrectionDirection={currentSftTestInfo.type === SFT_TYPE.LOWER_BODY_FLEX || currentSftTestInfo.type === SFT_TYPE.UP_AND_GO_TEST ? null : bodyPositionCorrectionDirection}
									// onBodyCorrectionSign={onBodyCorrectionSign}
									feedbackEnable={sftSequence === SFT_SEQUENCE.TEST_START}
									sft={true}
									showOutline={false}
								/>
							</>
						)}
					</div>
				</section>
				{/* kneeGuideLine */}
				{sftSequence === SFT_SEQUENCE.TEST_START && (currentSftTestInfo.type === SFT_TYPE.STATIC_BALANCE || currentSftTestInfo.type === SFT_TYPE.AEROBIC) &&
					<BaseLine kneeGuideLineStyle={kneeGuideLineStyle} currentExerciseCounter={currentExerciseCounter}  keepMatchingStatus={currentSftTestInfo.type === SFT_TYPE.STATIC_BALANCE} />
				}
				{/* progress Bar */}
				{!isTimeUp &&
					<div className={css.counterContainer}>
						<ProgressBar
							onTimeCounterComplete={onTimeCounterComplete}
							initialTime={currentSftTestInfo.testTimeSec !== -1 ? currentSftTestInfo.testTimeSec : -1}
							mainTest={currentSftTestInfo.guideText[1]}
							position={currentSftTestInfo.position}
							currentMainTest={sftSequenceRef.current}
							currentStep={0}
							totalSteps={2}
						/>
					</div>
				}
				{/* real Time user counter */}
				{showExerciseCounter && sftSequence === SFT_SEQUENCE.TEST_START && currentExerciseCounter && currentSftTestInfo.type !== SFT_TYPE.LOWER_BODY_FLEX &&
					renderRealTimeRecord()
				}
				{/* user sftScore */}
				{showExerciseCounter && sftSequence === SFT_SEQUENCE.GET_SCORE && currentExerciseCounter &&
					Object.keys(totalSftScore).length > 0 && renderSftResult()
				}
			</div>

			{/* Instruction */}
			{isInstruction && mounted && instrunctionSetting !== '' && (
				<TPopUp
					kind="imagePopUp"
					title={$L("테스트 도구 확인")}
					text={convertNewlinesToBr($L("정확한 신체능력 테스트를 위해{br}아래 도구가 필요하니 미리 준비해주세요."))}
					text1={instrunctionSetting === 'both' && $L("Dumbbell Weight Guidelines: 3kg for men, 2kg for women")}
					button1text={$L("Quit")}
					button2text={$L("Getting started")}
					hasCheck
					onCheck={physicalTestSkip}
					onClickCheck={onClickSkip}
					onClick={onClosePopup}
					className={css.instruction}
				>
					<div className={css.imgBtnGroup}>
						{(instrunctionSetting === 'chair' || instrunctionSetting === 'both') &&
							// <SpottableDiv
							<div
								className={classnames(
									css.imgBox,
									// chairSelected ? css.selected : null
								)}
								onClick={onClickTools("chair")}
							>
								<div className={classnames(css.chair, css.img)} />
								<div className={css.text}>{$L("chair")}</div>
							{/* </SpottableDiv> */}
							</div>
						}
						{instrunctionSetting === 'both' &&
							// <SpottableDiv
							<div
								className={classnames(
									css.imgBox,
									// dumbbellSelected ? css.selected : null
								)}
								onClick={onClickTools("dumbbell")}
							>
								<div className={classnames(css.dumbbell, css.img)} />
								<div className={css.text}>{$L("dumbbell")}</div>
							{/* </SpottableDiv> */}
							</div>
						}
						{upAndgo &&
							// <SpottableDiv
							<div
								className={classnames(
									css.imgBox,
									// dumbbellSelected ? css.selected : null
								)}
								onClick={onClickTools("target")}
							>
								<div className={classnames(css.target, css.img)} />
								<div className={css.text}>{$L("target")}</div>
							{/* </SpottableDiv> */}
							</div>
						}
					</div>
					{upAndgo &&
						<div className={css.upAndGoGuide}>
							<div><span/>{$L("테스트 방법")}</div>
							<div>
								{convertNewlinesToBr($L("의자에 앉아서 출발 신호와 함께 의자에서 일어나서 2.44m 앞에 표적을{br} 가능한 빨리 걸어서 제자리에 앉기까지의 시간을 측정합니다.{br}표적은 집에 가지고 있는 것 중 적당한 물체를 준비해주세요."))}
							</div>
						</div>
					}
				</TPopUp>
			)}

			<ButtonContainer className={classnames(showLoadingPanel.show && css.hide)}>
				{/* retry previous Test */}
				{currentSftIndex !== 0 && sftSequence !== SFT_SEQUENCE.SEQUENCE_INIT && !showTestPartComplete &&
					<div className={css.retryTestContainer}>
		        <TButton
		          className={css.retryBtn}
							spotlightId="physicalTestRetryPreviousTest"
		          onClick={retryPreviousTest}
		        >
		          {$L("Repeat the previous test")}
		        </TButton>
					</div>
				}
				{/* guide Skip btn */}
				{!isInstruction && sftSequence === SFT_SEQUENCE.GUIDE_START &&
					<div className={classnames(css.videoSkip, currentSftIndex === 0 && css.changePosition)} ref={skipRef}>
						<TButton
							className={css.skipBtn}
							spotlightId="sftGuideSkipBtn"
							size="small"
							itemRenderer={skipRenderer}
							onClick={skipGuideVideo}
						>
							{$L("Skip guide")}
							{/* <span /> */}
						</TButton>
					</div>
				}
				{/* pause Btn */}
				{(!isInstruction && sftSequence !== SFT_SEQUENCE.SEQUENCE_INIT &&
					sftSequence !== SFT_SEQUENCE.SEQUENCE_FINISHED) &&
					!showTestPartComplete &&
					<TIconButton
						className={sftSequence === SFT_SEQUENCE.TEST_START ? css.movePosition : css.pauseBtn}
						iconType="testPause"
						onClick={pauseHandler}
					/>
				}
				{/* test skip Btn */}
				{!isTimeUp && sftSequence === SFT_SEQUENCE.TEST_START &&
					<div className={css.testSkip}>
						<TButton className={css.skipBtn} spotlightId="sftSkipBtn" size="small" onClick={skipTest}>
							{$L("Skip the test")}
							{/* <span /> */}
						</TButton>
					</div>
				}
			</ButtonContainer>

			{/* AudioPlayer */}
			<AudioPlayer srcTypeStr={voiceGuide} onEnded={onVoiceGuideEnd} />
			{playBGM && (
				<AudioPlayer
					srcTypeStr={"FUNK_HAPPY"}
					loop
					volume={Config.BODY_TEST_BG_VOLUME}
				/>
			)}
			{loopVoiceGuide && <AudioPlayer srcTypeStr={loopVoiceGuide} loop />}

			{/* testPartComplete */}
			{showTestPartComplete &&
				<TestPartCompletePopup
					retry
					currentTestTitle={SFT_TEST_INFO[sftList[currentSftIndex + 1]].guideText[1]}
					retryPreviousTest={backToPreviousStep}
					onClose={nextStep}
				/>
			}

			{/* testSelectPopup */}
			{retry &&
				<TestSelectPopup testInfos={SFT_TEST_INFO} initialCheckedState={initialCheckedState} physicalTest={true} onClose={handleTestStart} />
			}

			{/* after final test -> TestEnd popup */}
			{showTestEndComplete && sftSequence >= SFT_SEQUENCE.TEST_END && currentSftIndex + 1 >= sftList.length && //전체 테스트 중 마지막일때
				<TestEndCompletePopup
					open
					retryPreviousTest={retrySelectedTest}
					onClose={handleTestStart}
					testType={sftList.length === 1 ? "single" : "total"}
				/>
			}
			{/* test Close Popup */}
			{isTestClose &&
				<TTestClosePopup onClick={onClickTestClose} />
			}
		</CancelableDiv>
	);
};

export default PhysicalTestCapture;
