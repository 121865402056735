export const SUPPORT_COUNTRYS = { US: "en", KR: "ko" };

// /* accessCode */
export const ACCESS_REQUIRED_URL = "intellifit.t-win.kr";
export const ACCESS_CODE = "twin2024!";

// /* debug */
export const DEBUG_KEY = "5286";
export const TESTPANEL_KEY = "5325";
export const SFT_TEST = "5555";
export const SFT_TEST2 = "6666";
export const ACTIVITY_SCENERY = "8282";

export const panel_names = {
  //body checkup main
  BODY_CHECK_UP_MAIN: "body_check_up_main",

  //body checkup-body alignment
  BODY_ALIGNMENT: "body_alignment",
  BODY_ALIGNMENT_SCAN: "body_alignment_scan",
  BODY_ALIGNMENT_REPORT: "body_alignment_report",
  BODY_ALIGNMENT_REPORT_DETAIL: "body_alignment_report_detail",
  //ces
  BODY_ALIGNMENT_SUMMARY: "BODY_ALIGNMENT_SUMMARY",

  //body checkup-ROM
  ROM_REPORT: "rom_report",
  ROM_TEST: "rom_test",

  //body checkup-physical test
  PHYSICAL_TEST: "physical_test",
  PHYSICAL_TEST_GATE: "physical_test_gate",
  PHYSICAL_TEST_SUMMARY: "physical_test_summary",
  PHYSICAL_TEST_CAPTURE: "physical_test_capture",
  PHYSICAL_TEST_REPORT: "physical_test_report",

  //body checkup-body balance
  BODY_BALANCE_REPORT: "body_balance_report",
  BODY_BALANCE_TEST: "body_balance_test",
  BODY_BALANCE_DETAILS: "body_balance_details",
  //ces
  BODY_BALANCE_SUMMARY: "BODY_BALANCE_SUMMARY",

  //My Workout Program
  HOMECARE_PROGRAM_MAIN: "homecare_program_main",
  HOMECARE_RECOMMEND_WORKOUT: "homecare_recommend_workout",
  HOMECARE_WORKOUT_PLAYER: "homecare_workout_player",
  HOMECARE_WORKOUT_PLANNER: "homcare_workout_planner",

  HOMECARE_WORKOUT_OPTIONS: "homecare_workout_options",
  HOMECARE_CENTER_INFO: "homecare_center_info",
  HOMECARE_ALARM_SETTING: "homecare_alarm_setting",
  HOMECARE_TIME_SETTING: "homecare_time_setting",

  HOMECARE_ROUTINE_PROGRAM: "homecare_routine_program",
  HOMECARE_ROUTINE_PROGRAM_COMPOSITION: "homecare_routine_Program_composition",

  //Outdoor Activity Indoors
  IN_HOME_OUTDOOR_ACTIVITY_MAIN: "in_home_outdoor_activity_main",
  IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN: "in_home_outdoor_activity_walking_jogging_main",
  IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_FULL_ACTIVITY: "in_home_outdoor_activity_walking_jogging_full_activity",
  IN_HOME_OUTDOOR_ACTIVITY_PLAYER: "IN_HOME_OUTDOOR_ACTIVITY_PLAYER",
  VIRTUAL_TREKKING: "virtual_trekking",

  //intro
  INTRO_PANEL: "intropanel",
  LOGIN_PANEL: "loginpanel",
  TERMS_PANEL: "termspanel",
  SURVEY_PANEL: "surveypanel",

  //my
  MY_PANEL: "myPanel",
  SUBSCRIPTION_PASS_PANEL: "subscription_pass_panel",
  MY_SURVEY_PANEL: "my_survey_panel",

  //fitness manager
  FITNESS_MANAGER: "fitness_manager",
  FITNESS_GOAL_SETTING: "fitness_goal_setting",
  FITNESS_GOAL_SETTING_DETAIL: "fitness_goal_setting_detail",
  FITNESS_BRIEFING_DETAIL: "fitness_briefing_detail",

  //Report
  REPORT_PANEL: "reportPanel",
  MEASURMENT_DATE: "measurmentDate",
  SHARE_METHOD: "shareMethod",
  EMAIL_SEND: "emailSend",
  MOBILE_SEND: "mobileSend",
  PREVIEW_PANEL: "previewPanel",

  //setting
  SETTING_PANEL: "settingpanel",
  SELECT_BGM_PANEL: "selectbgmpanel",

  //debug, test
  DEBUG_PANEL: "debugpanel",
  TEST_PANEL: "testpanel",
  TEST_PANEL_SCAN: "scan_test",
  TEST_PANEL_BELLY: "belly_test",
  TEST_PANEL_SHAKA: "shaka_test",
  TEST_PANEL_PLAYSPEED: "playspeed_test",
  TEST_PANEL_CHART: "chart_test",
  TEST_PANEL_LOGGER: "logger_test",
  TEST_PANEL_BLE: "ble_test",
  TEST_PANEL_BLE_DUMMY: "ble_test_dummy",
  TEST_PANEL_WEBWORKER: "webworker_test",
  TEST_PANEL_STORYBOOK: "storybook",
  TEST_PANEL_SOUNDEFFECT: "sound_effect_test",
  TEST_PANEL_JMEDIALIST: "jmedia_list_test",
  TEST_PANEL_VRPLAYER: "vr_player",

  // contentPlayer
  CONTENT_PLAYER_START: 'content_player_start',

  //error
  ERROR_PANEL: "error_panel",

  // accessRestriction
  ACCESS_RESTRICTION_PANEL: "access_restriction_panel",

  //workout
  WORKOUT_PANEL: "workout_panel",

  // ces select workout
  SELECT_WORKOUT: "select_workout",
  // AIManagerFeedback
  AI_MANAGER_FEEDBACK: 'ai_manager_feedback'
};

export const DATA_VALIDATION_TEST = false;
export const BODY_TEST_BG_VOLUME = 0.1;
export const FADE_INTERVAL_MS = 1000;

export const AUTO_SCROLL_DELAY = 600;
export const HLIST_HOME_MAX = 15;
export const SHOW_TOUCH_GUIDE = false;
export const SUPPORT_LOGIN = true;
export const USE_DUMMY = false;
export const DEBUG_WINDOW_ACCOUNTID_KR = "KR00000000";
export const DEBUG_WINDOW_ACCOUNTID_US = "US00000000";
export const DEBUG_WINDOW_DEVICEID = "twintestdeviceid";
export const DEBUG_BODY_POINT_COLOR = { 0: "blue", 7: "green", 10: "yellow", 17: "yellow", etc: "red" };
export const BUTTON_PRESS_DELAY = 300;
export const LIST_SIZE = 10;
export const CES_DEFAULT_CONCADE = 'low'; //low : 30, middle: 60
export const BODY_ALIGNMENT_REMOVE_PELVIC_ROTATION = true;

//button
export const TBUTTON_PRESS_DELAY = 100;
export const TCALENDAR_PRESS_DELAY = 100;

//sound
export const MAIN_BUTTON_CLICK_SOUND = "CLICK_BUTTON_SOUND";
export const MAIN_BUTTON_FOCUS_SOUND = "FOCUS_BUTTON_SOUND";