import { useEffect, useState, useMemo, useCallback } from "react";
import classNames from "classnames";
import css from "./ArchBarItem.module.less";
import { $L } from "../../utils/helperMethods";
import BarMark from "../../../assets/icon/barMark.svg";
import overallCopLeft from "../../../assets/bodyBalance/overall_cop_left.png";
import overallCopRight from "../../../assets/bodyBalance/overall_cop_right.png";
import { ONE_LEG_BALANCE_VALUE } from "../../utils/Constants";

const preventionMin = ONE_LEG_BALANCE_VALUE.preventionMin;
const preventionMax = ONE_LEG_BALANCE_VALUE.preventionMax;
const managementMin = ONE_LEG_BALANCE_VALUE.managementMin;
const managementMax = ONE_LEG_BALANCE_VALUE.managementMax;
const dangerMin = ONE_LEG_BALANCE_VALUE.dangerMin;
const dangerMax = ONE_LEG_BALANCE_VALUE.dangerMax;

const ArchBarItem = ({
  leftBalanceData,
  rightBalanceRData,
  style = {},
  OverallImg,
  ...rest
}) => {
  const [leftFoot, setLeftFoot] = useState(0);
  const [rightFoot, setRightFoot] = useState(0);

  const {
    coPArchFlexStyle,
    coPArchBarItemSectStyle,
    coPArchInfoWrapStyle,
    coPStepFlexStyle,
    coPStepStyle,
  } = style;

  const resetData = useCallback((data) => {
    if (data > dangerMax) {
      return (data = dangerMax);
    } else if (data < preventionMin) {
      return (data = preventionMin);
    } else if (preventionMin <= data && data <= dangerMax) {
      return data;
    }
  }, []);

  const positionData = useMemo(() => {
    const roundToTwoDecimalPlaces = (number) => {
      if (number % 1 !== 0) {
        return parseFloat(number.toFixed(2));
      } else {
        return number;
      }
    };

    const percentageData = (data) => {
      if (data < preventionMax) {
        return (100 / (preventionMax - preventionMin)) * (data - preventionMin);
      } else if (managementMin <= data && data <= managementMax) {
        return (100 / (managementMax - managementMin)) * (data - managementMin);
      } else if (dangerMin < data && data) {
        return (100 / (dangerMax - dangerMin)) * (data - dangerMin);
      }
    };

    const renderType = (data) => {
      if (data < preventionMax) {
        return $L("Good");
      } else if (managementMin <= data && data <= managementMax) {
        return $L("Borderline");
      } else if (dangerMin < data && data) {
        return $L("Weak");
      }
    };

    const footData = {
      left: {
        data: leftFoot,
        footImg: overallCopLeft,
        footImgAlt: "leftFoot_img",
        displayName: $L("Left Foot"),
      },
      right: {
        data: rightFoot,
        footImg: overallCopRight,
        footImgAlt: "rightFoot_img",
        displayName: $L("Right Foot"),
      },
    };
    const ret = [];

    for (const position in footData) {
      const data = footData[position];
      const resultItem = {
        footImg: data.footImg,
        footImgAlt: data.footImgAlt,
        footDirection: data.displayName,
        renderType: renderType(data.data),
        foot: roundToTwoDecimalPlaces(Number(data.data)),
        percentageData: percentageData(data.data),
      };
      ret.push(resultItem);
    }
    return ret;
  }, [leftFoot, rightFoot]);

  const renderArchBarComponents = useCallback(() => {
    return positionData.map((item, index) => (
      <div
        className={css.archBarItemSectWrap}
        style={style && coPArchFlexStyle}
        key={`ArchBarItem-${index}`}
      >
        {OverallImg && (
          <img
            src={item.footImg}
            alt={item.footImgAlt}
            className={css.archImg}
          />
        )}
        <div
          className={css.archBarItemSect}
          style={style && coPArchBarItemSectStyle}
        >
          <div
            className={css.archInfoWrap}
            style={style && coPArchInfoWrapStyle}
          >
            <div className={css.archIndex}>{item.footDirection}</div>
            <div className={css.type}>{item.renderType}</div>
            <div className={css.level}>{`(${item.foot})`}</div>
          </div>
          <div className={classNames(css.fixedBar)}>
            <div
              className={classNames(
                css.fixedBarSection,
                dangerMin < item.foot ? css.located : null
                )}
                >
              <div
                className={classNames(dangerMin < item.foot ? css.marker : css.none)}
                style={{
                  right: `${item.percentageData}%`,
                  backgroundImage: `url(${BarMark})`,
                }}
              />
            </div>
            <div
              className={classNames(
                css.fixedBarSection,
                css.centerBar,
                managementMin <= item.foot && item.foot <= managementMax ? css.located : null
              )}
            >
              <div
                className={classNames(
                  managementMin <= item.foot && item.foot <= managementMax ? css.marker : css.none
                )}
                style={{
                  right: `${item.percentageData}%`,
                  backgroundImage: `url(${BarMark})`,
                }}
              />
            </div>
            <div
              className={classNames(
                css.fixedBarSection,
                item.foot < preventionMax ? css.located : null
              )}
            >
              <div
                className={classNames(item.foot < preventionMax ? css.marker : css.none)}
                style={{
                  right: `${item.percentageData}%`,
                  backgroundImage: `url(${BarMark})`,
                }}
              />
            </div>
          </div>
          <div className={css.stepFlex} style={style && coPStepFlexStyle}>
            <div className={css.LeftAlign}>
              <span>{$L("Weak")}</span>
              <span className={css.step} style={style && coPStepStyle}>
                {`(${dangerMin}<)`}
              </span>
            </div>
            <div>
              <span>{$L("Borderline")}</span>
              <span className={css.step} style={style && coPStepStyle}>
                {`(${managementMin}~${managementMax})`}
              </span>
            </div>
            <div className={css.rightAlign}>
              <span>{$L("Good")}</span>
              <span className={css.step} style={style && coPStepStyle}>
                {`(<${preventionMax})`}
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  }, [positionData]);

  useEffect(() => {
    setLeftFoot(resetData(leftBalanceData));
    setRightFoot(resetData(rightBalanceRData));
  }, [leftBalanceData, rightBalanceRData]);

  return <>{renderArchBarComponents()}</>;
};

export default ArchBarItem;