import css from "./SwiperComponent.module.less";
import Spottable from "@enact/spotlight/Spottable";
import { Swiper, SwiperSlide } from "swiper/react";
import ri from '@enact/ui/resolution';
import classNames from "classnames";
import React, { useEffect, useRef, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Canvas from "../../../../components/Canvas/Canvas";
import {getBodyCheckUpData} from "../../../../features/fitService/fitServiceSlice";
import { BODY_CHECKUP_TYPE } from "../../../../utils/Constants";
import { $L, converDateStrtoShort } from "../../../../utils/helperMethods";

import * as Config from "../../../../utils/Config";
import * as SoundEffect from "../../../../utils/SoundEffect";
import TToolTip, {
  POSITION,
  TYPES,
} from "../../../../components/TToolTip/TToolTip";

const SpottableComponent = Spottable("div");

const WorkoutInfo = ({ className, infos, isHidden = false }) => {
  const [tooltip, setTooltip] = useState(false);

  const onFocus = useCallback(() => {
    if (!isHidden) {
      SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
      setTooltip(true);
    }
  }, [isHidden]);

  const onBlur = useCallback(() => {
    !isHidden && setTooltip(false);
  }, []);

  return (
    <div className={classNames(css.infoItem, className)}>
      <div className={css.leftline} />
      <SpottableComponent
        className={css.textLayer}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <div className={css.contents}>
          {$L(infos?.days > 1 ? "{days} days" : "{days} day").replace(
            "{days}",
            infos?.days
          ) +
            ", " +
            $L("{hh} h").replace("{hh}", infos?.hours)}
        </div>
        <TToolTip
          position={POSITION.top}
          type={TYPES.square}
          isOpen={tooltip}
          style={{ bottom: "68px" }}
        >
          {$L("운동일수, 시간")}
        </TToolTip>
      </SpottableComponent>
      <div className={css.rightline} />
    </div>
  )
}
/**
 * @module SwiperComponent
 * @returns SwiperComponent
 */

const SwiperComponent = ({ pictureType, tabDetail, date }) => {
  const dispatch = useDispatch();
  const swiperRef = useRef();
  const [swiperOnTransition, setSwiperOnTransition] = useState(false);
  const dateList = useSelector((state) => state.bodyAlignment.dateList);
  const { testResults, testResultUpdated } = useSelector((state) => state.bodyAlignment);

  const tabDataInfo = useMemo(() => {
    let ret = [];
    dateList.forEach(element => {
      ret.push({id: 'userImage', date: element});
    });
    if(ret.length < 3){
      for(let i= 3-ret.length; i>0; i--){
        ret.push({id: 'empty'});
      }
    }
    return ret;
  }, [dateList]);

  //prev버튼 클릭했을때
  const prevArrowClick = useCallback(() => {
    swiperRef.current.swiper.slidePrev();
  },[]);

  //next버튼 클릭했을때
  const nextArrowClick = useCallback(() => {
    swiperRef.current.swiper.slideNext();
  },[]);

  useEffect(()=>{
    if(swiperRef.current && tabDataInfo && tabDataInfo.length > 0){
      let index = tabDataInfo.length;
      for(let i=0; i<tabDataInfo.length; i++){
        if(tabDataInfo[i].date === date){
          index = i-1;
          break;
        }
      }
      swiperRef.current.swiper.slideTo(index);
    }
  },[tabDataInfo, date]);

  const renderSlide = useCallback((index, tabDataInfoRecord) => {
    const activeIndex = swiperRef.current?.swiper?.activeIndex;
    const currentRelativeIndex = activeIndex !== undefined ? (index - activeIndex): 0;
    if(!testResults[tabDataInfoRecord.date]){
      if(activeIndex !== undefined && currentRelativeIndex >=-1 && currentRelativeIndex < 4 ){
          dispatch(getBodyCheckUpData({type: BODY_CHECKUP_TYPE.BODY_ALIGNMENT, date: tabDataInfoRecord.date, syncFlag: "fast"}));
      }else{
        dispatch(getBodyCheckUpData({type: BODY_CHECKUP_TYPE.BODY_ALIGNMENT, date: tabDataInfoRecord.date, syncFlag: "slow"}));
      }
    }
    return (
      <>
      <div className={css.slideWrapper}>
        {tabDataInfoRecord.id === 'userImage' && testResults[tabDataInfoRecord.date] &&
          <div className={css.swiperInnerImg}>
            {(activeIndex !== undefined && currentRelativeIndex >=-1 && currentRelativeIndex <4) &&
              <Canvas
                bodyType={tabDetail.mainkey}
                type={tabDetail.type}
                className={css.canvas}
                testkeys={[tabDetail.testkey, tabDetail.testkey2]}
                testResult={testResults[tabDataInfoRecord.date]}
                isIllust={pictureType!=='photo'}
              />
            }
          </div>
        }
      </div>
          <div className={css.recordDate}>
            {tabDataInfoRecord.date
              ? converDateStrtoShort(tabDataInfoRecord.date, true)
              : ""}
          </div>
      </>
    )
  },[dispatch, pictureType, testResults, tabDetail]);

  const onTransitionStart = useCallback(()=>{
    setSwiperOnTransition(true);
  },[]);
  const onTransitionEnd = useCallback(()=>{
    setSwiperOnTransition(false);
  },[]);

  return (
  <div className={classNames(css.photoWrapper)}>
      <SpottableComponent
        onClick={prevArrowClick}
        className={classNames(
          "swiperButtonNext",
          css.chevron,
          css.swiperButtonPrev,
          swiperRef.current?.swiper?.isBeginning && css.disabled
        )}
      />
    <Swiper
      ref={swiperRef}
      loop={false}
      className={css.swiper_container}
      spaceBetween={ri.scale(24*2)}
      slidesPerView={3}
      initialSlide={1}
      onTransitionStart={onTransitionStart}
      onTransitionEnd={onTransitionEnd}
    >
      {tabDataInfo !== undefined &&
        tabDataInfo.length > 0 &&
        tabDataInfo.map((tabDataInfoRecord, index) => {
          return (
            <SwiperSlide
              key={index+tabDataInfoRecord.id}
              className={css.swiperSlide}
              virtualIndex={index}
            >
              {renderSlide(index, tabDataInfoRecord)}
            </SwiperSlide>
          );
        })}
      <div className={classNames(swiperOnTransition && css.hidden, css.workoutInfoLayer)}>
          <WorkoutInfo infos={{ days: 6, hours: 8 }} />
          <WorkoutInfo
            className={
              tabDataInfo &&
              tabDataInfo.filter((item) => item.id === "empty").length === 2
                ? css.hidden
                : null
            }
            isHidden={
              tabDataInfo.filter((item) => item.id === "empty").length === 2
            }
            infos={{ days: 6, hours: 12 }}
          />
      </div>
    </Swiper>
    <SpottableComponent
      onClick={nextArrowClick}
      className={classNames(
        "swiperButtonPrev",
        css.chevron,
        css.swiperButtonNext,
        swiperRef.current?.swiper?.isEnd && css.disabled
      )}
      />
  </div>
  );
}

export default SwiperComponent;
