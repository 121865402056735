import css from "./ShakaPlayerTest.module.less";
import React, { useState, useRef, useCallback } from "react";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import TShakaPlayer from "../../components/TShakaPlayer/TShakaPlayer";
import SFTchair from "../../../assets/video/SFTchair.mp4";
import SFTdumbbell from "../../../assets/video/bodyScan.mp4";
import chairStandThumb from "../../../assets/images/chairStandThumb.png";
import bodyScanThumb from "../../../assets/images/bodyScanThumb.png";
import TButton from "../../components/TButton/TButton";

/**
 * @module ShakaPlayerTest
 */
const VIDEO_SOURCE = [
  "https://streamingtest-bucket.s3.ap-northeast-2.amazonaws.com/hls/running_video_only_output.m3u8",
  SFTchair, SFTdumbbell];

const ShakaPlayerTest = ({...rest}) => {
	const playerRef = useRef(null);
  const reftestRef = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);

	const onVideoProgress = useCallback((ev) => {
    console.log('ShakaPlayerTest onVideoProgress');
    const current = ev.toFixed(1);
    console.log('ShakaPlayerTest onVideoProgress current paused ', current, playerRef.current.paused());
  },[]);

  const onVideoEnd = useCallback(() => {
    console.log('ShakaPlayerTest onVideoEnd')
    setVideoIndex((videoIndex+1)%VIDEO_SOURCE.length);
  },[videoIndex]);

	const onPlay = useCallback(()=>{
    playerRef.current.play();
	}, []);

	const nextStep = useCallback(()=>{
		setVideoIndex((videoIndex+1)%VIDEO_SOURCE.length);
	},[videoIndex]);

  return (
  <TPanel {...rest}>
    <THeader title={"ShakaPlayer Test"}/>
    <TBody>
      <TShakaPlayer
        playerRef={playerRef}
        className={css["shaka-player"]}
        src={VIDEO_SOURCE[videoIndex]}
        onProgress={onVideoProgress}
        onEnded={onVideoEnd}
        reftest={reftestRef}
        poster={videoIndex === 0? chairStandThumb : bodyScanThumb}
      />
      <div className={css.buttoncontainer}>
        <TButton onClick={onPlay}>play</TButton>
        <TButton onClick={nextStep}>next</TButton>
      </div>
    </TBody>
  </TPanel>
  );
}

export default ShakaPlayerTest;