import classNames from 'classnames';
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Panel} from '@enact/sandstone/Panels';
import {Cancelable} from '@enact/ui/Cancelable';
import { popPanel } from "../../features/panels/panelsSlice";
import SmoodShowingAnimation from "../SmoodShowingAnimation";
import css from './TPanel.module.less';
import * as TTSService from "../../lunaSend/TTSService";

const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const TPanel = ({className, children, handleCancel, isTabActivated, ...rest}) => {
	const dispatch = useDispatch();
  const { themeMode } = useSelector((state) => state.common.appStatus);
	delete rest.panelInfo;
	const onCancel = useCallback((ev)=>{
		if(isTabActivated){
			return;
		}
		if(handleCancel){
			handleCancel(ev);
		}else{
			dispatch(popPanel());
			ev.stopPropagation();
		}
	},[dispatch, handleCancel, isTabActivated]);
  useEffect(() => {
    TTSService.stop();
    return () => TTSService.stop();
  }, []);
	return (
    <CancelablePanel
      {...rest}
      handleCancel={onCancel}
      className={classNames(
        css.tpanelmain,
        themeMode === "dark" && css.isDark,
        themeMode === "transparent" && css.isTransparent,
        className
      )}
    >
			{children}
		</CancelablePanel>
	);
};

export default SmoodShowingAnimation(TPanel);
