import { useEffect, useCallback } from "react";

import * as SoundEffect from "../../utils/SoundEffect";
import * as TTSService from "../../lunaSend/TTSService";
/**
 *
 * @param {srcTypeStr} 'B1'
 * @returns
 */
const AudioPlayer = ({srcTypeStr, loop=undefined, volume=1.0, onEnded, audioStatus}) => {
  const _onEnded = useCallback(() => {
    if(onEnded){
      onEnded(srcTypeStr);
    }
  },[srcTypeStr, onEnded]);

  useEffect(() => {
    if(srcTypeStr){
        if(SoundEffect.SOURCETYPE[srcTypeStr] || srcTypeStr.indexOf('http') >= 0){
          SoundEffect.playAudio(srcTypeStr, loop, _onEnded, volume);
        }else{
          TTSService.speak(srcTypeStr, _onEnded);
        }
      }
      return () => {
        if(srcTypeStr){
            if(SoundEffect.SOURCETYPE[srcTypeStr] || srcTypeStr.indexOf('http') >= 0){
              SoundEffect.stopAudio(srcTypeStr);
            }else{
              TTSService.stop();
            }
        }
      };
  }, [srcTypeStr]);

  useEffect(() => {
    if (audioStatus && SoundEffect.SOURCETYPE[srcTypeStr]) {
      if (audioStatus === 'suspended') {
        SoundEffect.suspendAudio(srcTypeStr);
      } else if (audioStatus === 'running') {
        SoundEffect.resumeAudio(srcTypeStr);
      }
    }
  }, [audioStatus]);

  return null;
};
export default AudioPlayer;
