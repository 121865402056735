import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import css from "./HPTestCompleted.module.less";
import { $L } from "../../../../utils/helperMethods";
import TScroller from '../../../../components/TScroller/TScroller';
import TButton, { SIZES, TYPES } from "../../../../components/TButton/TButton";
import BarItem, { SCORE } from '../BarItem'
import useScrollTo from "../../../../hooks/useScrollTo";

const HPTestCompleted = ({
  className,
  testType,
  contentItem,
  selectedTabIndex,
  testInfo,
  tabInfos,
  bodyParts,
  selectedPart,
  label,
  onPartClick,
  ...rest
}) => {

  const { getScrollTo, scrollTop } = useScrollTo();

  const _onPartClick = useCallback((part) => () => {
    if (part && onPartClick) {
      onPartClick(part);
    };
  }, [onPartClick]);

  const testInfoItem = useCallback(({ part = false, title = '', lastDate = '', tabId = '', score = 0 }) => {
    return (
      <>
        <div className={css.title}>
          <div>{title}</div>
          <div className={css.lastDate}>
            {lastDate ? "(" + lastDate.split("-").join("/") + ")" : "--"}
          </div>
        </div>
        <div className={classNames(css.bars, score === 0 ? css.notScore : null)}>
          <BarItem tabId={tabId} score={score} />
        </div>
      </>
    );
  }, []);

  const contentTitleItem = useCallback(({ test = false, level = '', title = '' }) => {
    return (
      test
        ?
        // test 진행
        <>
          <div className={css.level}>{$L(level.replace(/\b[a-z]/g, char => char.toUpperCase()))}</div>
          <div>
            {
              $L(`등급인 ${title}에 도움이 되는 운동`)
            }
          </div>
        </>
        :
        // test 미진행
        <div>
          {title + $L("에 좋은 운동")}
        </div>
    );
  }, []);

  const _contentItem = useCallback(( info ) => {
    if (info && contentItem) {
      return contentItem(info);
    };
  }, [contentItem]);

  useEffect(() => {
    scrollTop();
  }, [selectedTabIndex]);

	return (
    // TEST
    testInfo && tabInfos && testInfo.lastDate && tabInfos.length > 0 && (
    <TScroller className={css.scroller} cbScrollTo={getScrollTo}>
      <div className={css.content}>
        <div className={css.topLayer}>
          <div>
            {(testType === "bodyAlignment" || testType === "ROM") &&
              <div className={css.partScroller}>
                <div className={css.partLayer}>
                  <TButton
                    size={SIZES.small}
                    onClick={_onPartClick("all")}
                    selected={selectedPart === 'all'}
                    onFocus={scrollTop}
                  >
                    {$L("Overall")}
                  </TButton>
                  {bodyParts.length > 0 && bodyParts.map((part, idx) => (
                    <TButton
                      key={part + '_' + idx}
                      size={SIZES.small}
                      type={TYPES.withIcon}
                      onClick={_onPartClick(part)}
                      selected={selectedPart === part}
                      icon={label(part, 'class')}
                      onFocus={scrollTop}
                    >
                      {$L(part.replace(/\b[a-z]/g, char => char.toUpperCase()))}
                    </TButton>
                  ))}
                </div>
              </div>
            }
            {/* test result bar */}
            <div className={css.detailLayer}>
              <div className={css.barContainer}>
                {/* bodyAlignment, ROM */}
                {(testType === "bodyAlignment" || testType === "ROM")
                  ?
                  (selectedPart === 'all'
                    ?
                    bodyParts.length > 0 && bodyParts.map((part, idx) => (
                      <div className={css.info} key={part + '_' + idx}>
                        {testInfoItem({
                          part: true,
                          title: $L(part.replace(/\b[a-z]/g, char => char.toUpperCase())),
                          lastDate: label(part, 'num') !== 0 ? testInfo.lastDate : '',
                          tabId: testType,
                          score: label(part, 'num')
                        })}
                      </div>
                    ))
                    :
                    tabInfos.map((info, idx) => (
                      selectedPart === info?.bodyPart &&
                      <div className={css.info} key={info?.title + '_' + idx}>
                        {testInfoItem({
                          title: info?.title,
                          lastDate: info?.score !== 0 ? testInfo.lastDate : '',
                          tabId: testType,
                          score: info?.score
                        })}
                      </div>
                    )))
                  :
                  // {/* balance, sft */}
                  tabInfos.map((info, idx) => (
                    <div className={css.info} key={info + '_' + idx}>
                      {testInfoItem({
                        title: info?.title,
                        lastDate: info?.score !== 0 ? testInfo.lastDate : '',
                        tabId: testType,
                        score: info?.score
                      })}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* part recommend media */}
        {/* bodyAlignment, ROM */}
        {(testType === "bodyAlignment" || testType === "ROM")
          ?
          (selectedPart === 'all'
            ?
            (bodyParts.length > 0 && bodyParts.map((part, idx) => (
              //  {/* OVERALL */}
              <div key={part + "_media_" + idx} className={css.mediaLayer}>
                <div className={css.levelTitle}>
                  {contentTitleItem(
                    {
                      test: label(part, 'num') !== 0,
                      level: label(part, 'class'),
                      title: $L(part.replace(/\b[a-z]/g, char => char.toUpperCase()))
                    }
                  )}
                </div>
                {_contentItem({ type: tabInfos?.filter(info => info.bodyPart === part)?.[0]?.type, level: label(part, 'class') })}
              </div>
            )))
            :
            (tabInfos.length > 0 && tabInfos.map((info, idx) => (
              // {/* PART */}
              selectedPart === info?.bodyPart &&
              <div key={info?.title + "_media_" + idx} className={css.mediaLayer}>
                <div className={css.levelTitle}>
                  {contentTitleItem(
                    {
                      test: info?.score !== 0,
                      level: SCORE(testType, info?.score),
                      title: $L(info?.title.replace(/\b[a-z]/g, char => char.toUpperCase()))
                    }
                  )}
                </div>
                {_contentItem({ type: info?.type, level: SCORE(testType, info?.score) })}
              </div>
            )))
          )
          :
          (tabInfos.map((info, idx) => (
            <div key={info?.type + "_media_" + idx} className={css.mediaLayer}>
              <div className={css.levelTitle}>
                {contentTitleItem(
                  {
                    test: info?.score !== 0,
                    level: SCORE(testType, info?.score),
                    title: $L(info?.title.replace(/\b[a-z]/g, char => char.toUpperCase()))
                  }
                )}
              </div>
              {_contentItem({ type: info?.type })}
            </div>
          )))
        }
      </div>
    </TScroller>
    )
	)
}

export default HPTestCompleted