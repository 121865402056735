import classNames from 'classnames';
import React from "react";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import css from './Wrapper.module.less';
const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

/**
 *
 * @param {type} 'normal', 'withIcon
 * @returns
 */

const TYPES = { normal: "normal", withIcon: "withIcon" };

const Wrapper = ({title, children, className, type, ...rest}) => {
	return (
		<>
			{title && <div className={css.title}>{title}</div>}
			<div className={classNames(css.wrapper, className)} {...rest}>
				{children}
			</div>
			{type === "withIcon" && <div className={css.arrowIcon}/>}
		</>
	);
};

export default Wrapper;
export { TYPES };