import React, { useMemo, useCallback, useEffect, useRef } from "react";
import css from './ROMReportAngleCanvas.module.less';
import classNames from 'classnames';

	const DASH_SIZE = 6;
	const ARC_WIDTH = 13;
	const ARC_MARGIN = 4;
	const ARROW_ANGLE = 65;
	const ARROW_ARC_LENGTH = 40;//arrow width
	const ARC_COLORS = ["#F44B4A", "#F9B811", "#44A280"];//danger, mange, fine
	const INTERVAL_PER_ANGLE = 5;

	const getPointDistance = (p1, p2) => {
		const dx = p1[0] - p2[0];
		const dy = p1[1] - p2[1];
		const distance = Math.sqrt(dx * dx + dy * dy);
		return distance;
	}

	const drawLine = (ctx, p1, p2, color="#11A27E") => {
		ctx.beginPath();
		ctx.lineWidth = 2;
		ctx.strokeStyle = color;
		ctx.lineCap = 'round';
		ctx.setLineDash([DASH_SIZE, DASH_SIZE]);
		ctx.moveTo(p1[0], p1[1]);
		ctx.lineTo(p2[0], p2[1]);
		ctx.stroke();
		ctx.closePath();
		ctx.setLineDash([0, 0]);
	}
	const calcAngles = (centerPoint, basePoint, guideAngles, valueAngle, isNegative) => {
		const lineDistance = getPointDistance(centerPoint, basePoint);
		const currentAngle = calculateAngle(centerPoint, basePoint);
		const maxRotationAngle = guideAngles[0] * (Math.PI / 180);
		const goodRotationAngle = guideAngles[1] * (Math.PI / 180);
		const normalRotationAngle = guideAngles[2] * (Math.PI / 180);
		if(valueAngle>360){
			valueAngle = 0;
		}
		// 화살표 max 이상도 그리기
		// else if(valueAngle > guideAngles[0]){
		// 	valueAngle = guideAngles[0];
		// }
		const valueRotationAngle = valueAngle * (Math.PI / 180);

		const directionValue = isNegative ? -1: 1;
		const maxTargetAngle = currentAngle+maxRotationAngle*directionValue;
		const goodTargetAngle = currentAngle+goodRotationAngle*directionValue;
		const normalTargetAngle = currentAngle+normalRotationAngle*directionValue;
		const valueTargetAngle = currentAngle+valueRotationAngle*directionValue;
		return [currentAngle, maxTargetAngle, goodTargetAngle, normalTargetAngle, valueTargetAngle, lineDistance];
	}
	const drawBaseLine = (ctx, centerPoint, basePoint, maxTargetAngle, lineDistance)=>{
		drawLine(ctx, centerPoint, basePoint, "red"); //기준선
			// 회전 변환을 캔버스에 적용
		ctx.translate(centerPoint[0], centerPoint[1]);
		ctx.rotate(maxTargetAngle);
		drawLine(ctx, [0,0], [lineDistance, 0]); //상대선
	}

	// 중심점과 좌표 간의 각도 계산
	const calculateAngle = (center, target) => {
		return Math.atan2(target[1] - center[1], target[0] - center[0]);
	}
	const calculateAngleWithRadius = (radius, arcLength) => {
		// 호의 중심각(라디안) 계산
		const centralAngleRad = arcLength / radius;
		// 라디안을 도(도수)로 변환
		//const centralAngleDegree = (centralAngleRad * 180) / Math.PI;
		return centralAngleRad;
	}
	const makeArrow = (ctx, x1, y1, w, h, angle, isNegative)=> {
		ctx.beginPath();
		ctx.fillStyle = "white";
		ctx.translate(x1, y1);
		if(isNegative){ //60은 삼각 화살표 각도
			ctx.rotate(angle - Math.PI/2 + ARROW_ANGLE*(Math.PI/180));
		}else{
			ctx.rotate(angle - ARROW_ANGLE*(Math.PI/180));
		}
		ctx.fillRect(0,0, w, h);
		ctx.closePath();
	};
	const drawArrow=(ctx, centerPoint, currentAngle, targetAngle, radius, gradient, isNegative)=>{
		const negativeScale = isNegative ? -1: 1;
		let arrowStart = targetAngle-calculateAngleWithRadius(radius, ARROW_ARC_LENGTH)*negativeScale;
		if(!isNegative && arrowStart < currentAngle){
			arrowStart = currentAngle;
		}else if(isNegative && arrowStart > currentAngle){
			arrowStart = currentAngle;
		}

		ctx.beginPath();
		ctx.arc(centerPoint[0], centerPoint[1], radius+ARC_WIDTH/2, arrowStart, targetAngle, isNegative);
		ctx.strokeStyle = gradient; // 선 색상에 그라데이션 적용
		ctx.lineWidth = ARC_WIDTH*2; // 선 두께
		ctx.stroke(); // 선 그리기

		const x = centerPoint[0] + (radius-ARC_WIDTH/2)* Math.cos(targetAngle);
		const y = centerPoint[1] + (radius-ARC_WIDTH/2)* Math.sin(targetAngle);
		//사각형 돌려 그려서 삼각형 만듬.
		makeArrow(ctx, x, y, ARROW_ARC_LENGTH*1.5, ARROW_ARC_LENGTH*1.5, targetAngle, isNegative);
	}
	const createConicGradient = (ctx, gradeColor = ARC_COLORS[0], centerPoint, maxTargetAngle, goodTargetAngle, normalTargetAngle, currentAngle, isNegative) => {
		// The start angle is 0
		// The center position is 100, 100
		const gradient = ctx.createConicGradient(isNegative? maxTargetAngle: currentAngle, centerPoint[0], centerPoint[1]);
		let endPoint = null;
		if(isNegative){
                        gradient.addColorStop(0, gradeColor); // 시작 부분 색상 good
			endPoint = (Math.abs(goodTargetAngle-maxTargetAngle))/(Math.PI*2);
                        gradient.addColorStop(endPoint, gradeColor); //good
			endPoint = (Math.abs(normalTargetAngle-maxTargetAngle))/(Math.PI*2);
                        gradient.addColorStop(endPoint, gradeColor); //normal
			endPoint = (Math.abs(currentAngle-maxTargetAngle))/(Math.PI*2);
                        gradient.addColorStop(endPoint, gradeColor); //danger
                        gradient.addColorStop(endPoint, gradeColor); //good
		}else{
                        gradient.addColorStop(0, gradeColor); // 시작 부분 색상 danger
			endPoint = (Math.abs(normalTargetAngle-currentAngle))/(Math.PI*2);
                        gradient.addColorStop(endPoint, gradeColor); //normal
			endPoint = (Math.abs(goodTargetAngle-currentAngle))/(Math.PI*2);
                        gradient.addColorStop(endPoint, gradeColor); //good
		}
		return gradient;
	}
	const drawRainBowArc=(ctx, centerPoint, currentAngle, maxTargetAngle, targetAngle, arc_radius, gradient,isNegative)=>{
		// 호의 경로 설정
		ctx.beginPath();
		const radius = arc_radius + ARC_WIDTH + ARC_MARGIN;
		ctx.arc(centerPoint[0], centerPoint[1], radius, currentAngle, targetAngle, isNegative);
		ctx.strokeStyle = gradient; // 선 색상에 그라데이션 적용
		ctx.lineWidth = ARC_WIDTH; // 선 두께
		ctx.stroke(); // 선 그리기
		drawArrow(ctx, centerPoint, currentAngle, targetAngle, radius, gradient, isNegative);
	}

/*
	centerPoint: [x, y]
	basePoint: [x, y]
	guideAngles: max, good, normal
	degree: 50
	isNegative: true 반시계 방향
*/
const ROMReportAngleCanvas = ({className, grade, children, canvasWidth=595, canvasHeight=418, centerPoint, basePoint, guideAngles, degree, isNegative = false, debug=false}) => {
	const canvasRef = useRef(null);

  const canvasColor = useMemo(() => {
    switch (grade) {
      case "a":
        return ARC_COLORS[2];
      case "b":
        return ARC_COLORS[1];
      case "c":
        return ARC_COLORS[0];
      default:
        break;
    }
  }, [grade]);

	useEffect(() => {
		let rafId =null;
		if(canvasRef.current !== null && typeof window === "object"){
			try{
				const ctx = canvasRef.current.getContext('2d');
				const [currentAngle, maxTargetAngle, goodTargetAngle, normalTargetAngle, valueTargetAngle, lineDistance] = calcAngles(centerPoint, basePoint, guideAngles, degree, isNegative);
				const angleGap = Math.abs(Math.max(currentAngle, valueTargetAngle) - Math.min(currentAngle, valueTargetAngle));
				const gradient = createConicGradient(ctx, canvasColor, centerPoint, maxTargetAngle, goodTargetAngle, normalTargetAngle, currentAngle, isNegative);
				let startTime=null;
				let movingangle = currentAngle;
				let animationSpeed = 1.0;
				const animate = (timestamp) => {
					if(!startTime){
						startTime = timestamp;
					}
					let elapsed = timestamp - startTime;
					startTime = timestamp;

					//adjust elapsed
					const position = Math.abs(Math.max(movingangle, valueTargetAngle) - Math.min(movingangle, valueTargetAngle))/angleGap;
					animationSpeed = Math.abs(position-2);
					elapsed = elapsed*animationSpeed;

					let matched = false;
					if(isNegative){
						movingangle -= (Math.PI/180) * (elapsed/INTERVAL_PER_ANGLE);
						if(movingangle > valueTargetAngle){
							matched = true;
						}
					}else if(!isNegative ){
						movingangle +=  (Math.PI/180) * (elapsed/INTERVAL_PER_ANGLE);
						if(movingangle < valueTargetAngle){
							matched = true;
						}
					}
					ctx.save();
					ctx.clearRect(0, 0, canvasWidth, canvasHeight);
					ctx.stroke();
					if(debug){
						drawBaseLine(ctx, centerPoint, basePoint, maxTargetAngle, lineDistance);
					}
					ctx.restore();
					ctx.save();
					if(matched){
						drawRainBowArc(ctx,centerPoint, currentAngle, maxTargetAngle, movingangle, lineDistance, gradient, isNegative);
						rafId = window.requestAnimationFrame(animate);
					}else{
						drawRainBowArc(ctx,centerPoint, currentAngle, maxTargetAngle, valueTargetAngle, lineDistance, gradient, isNegative);
					}
					ctx.restore();
				};
				rafId = window.requestAnimationFrame(animate);
			}catch(e){
				console.log('ROMReportAngleCanvas Exception ',e);
			}
		}else{
			if(rafId !== null && typeof window === "object"){
				window.cancelAnimationFrame(rafId);
				rafId = null;
			}
		}
		return () => {
			// console.log('sh unmount rafId',rafId);
			if(rafId !== null && typeof window === "object"){
				window.cancelAnimationFrame(rafId);
				rafId = null;
			}
		};
	}, [canvasWidth, canvasHeight, centerPoint, basePoint, guideAngles, degree, isNegative]);

	return (
			<div id="canvas-wrap" className={classNames(css.canvas, className)}>
				<canvas id="canvas" ref={canvasRef} width={canvasWidth} height={canvasHeight}/>
				{children}
			</div>
	);
}

export default ROMReportAngleCanvas;