import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addPanels, updatePanel } from "../../../../features/panels/panelsSlice";
import css from './IHOAWJActivityToday.module.less';
import * as Config from '../../../../utils/Config';
import * as HelperMethods from "../../../../utils/helperMethods";
import {$L} from '../../../../utils/helperMethods';
import TSummary from '../../../../components/TSummary';
import TSimpleDatePicker from '../../../../components/TSimpleDatePicker/TSimpleDatePicker';
import { getActivityStrollToday } from '../../../../features/fitService/fitServiceSlice';
import TButton, { ICONS, SIZES, TYPES } from '../../../../components/TButton/TButton';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);

const IHOAWJActivityToday = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { summary, dateList } = useSelector(state => state.activityWalking);
  const today = HelperMethods.convertDateToString2(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const exerciseStatusStr = $L("You walked and jogged <span>{num}</span> times today. Good luck with the rest of your exercises.");

  const curSummary = useMemo(() => {
    const strDate = HelperMethods.convertDateToString2(selectedDate);
    return (summary && summary[strDate]) || {};
  }, [summary, selectedDate]);

  const SUMMARY_INFO = useMemo(() => {
    const arr = [
      { key: 'totalDistance', label: $L("Distance"), value: 0, type: 'distance' },
      {
        key: "totalStep",
        label: $L("Number of steps taken"),
        value: 0,
        type: "steps",
      },
      { key: "totalTime", label: $L("hours"), value: 0, type: "time" },
      {
        key: "totalCalories",
        label: $L({ key: "_Calories burned", value: "Calories burned" }),
        value: 0,
        type: "calorie",
      },
    ];

    return arr.map(info => {
      info.value = curSummary[info.key] || 0;
      return info;
    })
  }, [curSummary]);

  const goToIHOAWJFullActivity = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_FULL_ACTIVITY }));
    dispatch(updatePanel({ name: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN, panelInfo: { lastFocusedId: "seeAllactivity" } }));
  },[dispatch]);

  const _onSelectedDate = useCallback(({ selectedDate: date }) => {
    console.log('onSelectDate...', date);
    setSelectedDate(date);
    const strDate = HelperMethods.convertDateToString2(date);
    if (date && strDate !== selectedDate){
      dispatch(getActivityStrollToday(strDate));
    }
  }, [dispatch, selectedDate]);

  return (
    <Container className={css.activityTodayContainer} {...rest}>
      {(Array.isArray(dateList) && dateList.length > 0) ?
      <>
      <div className={css.activityTodaySummary}>
        <div className={css.activityTodayTitle}>{$L("Today's activities")}</div>
        <div className={css.exerciseStatusStr} dangerouslySetInnerHTML={{ __html: exerciseStatusStr.replace('{num}', summary[today]?.totalCount || 0) }} />
      </div>

      <TSummary items={SUMMARY_INFO} className={css.summaryBox}>
        <div className={css.summaryTop}>
          <TSimpleDatePicker
            size="small"
            width="small"
            period="total"
            whitelist={dateList}
            selectedDate={selectedDate}
            onSelectedDate={_onSelectedDate}
            useCalendar={false}
            className={css.datePicker}
          />
          <TButton type={TYPES.noBg} size={SIZES.tiny} icon={ICONS.right} className={css.fullBtn} onClick={goToIHOAWJFullActivity}>
            {$L('See all activities')}
            <div className={css.icon}/>
          </TButton>
        </div>
      </TSummary>
      </>
      :
      <div className={css.activityTodayEmpty}>
        <div dangerouslySetInnerHTML={{ __html: $L("With <span>Outdoor Activity Indoors</span>, you can try out different outdoor activities.") }}/>
        <div dangerouslySetInnerHTML={{ __html: $L("Experience <span>nature with a variety of themes on a mat.</span>") }}/>
      </div>
      }
    </Container>
  );
};

export default IHOAWJActivityToday;