import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import classNames from "classnames";
import css from "./ReportTop.module.less";
import TButton, { TYPES, ICONS } from "../TButton/TButton";
import TSimpleDatePicker from "../../components/TSimpleDatePicker/TSimpleDatePicker";
import { BODY_CHECKUP_TYPE } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAppStatus,
  changeVoiceGuideStatus,
} from "../../features/common/commonSlice";

import { $L } from "../../utils/helperMethods";
import Spottable from "@enact/spotlight/Spottable";

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import SpotlightIds from "../../utils/SpotlightIds";
import TToolTip from "../TToolTip/TToolTip";
import OnOffButton from "./OnOffButton";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  Spottable("div")
);

const ReportTop = ({
  className,
  pose,
  toggledImg,
  image,
  onSelectedDate,
  selectedDateStr,
  // handleText,
  // handleSound,
  dateList,
  onSpotlightDown,
  reportType = BODY_CHECKUP_TYPE.BODY_ALIGNMENT,
  selectedTabIndex = 0,
  disabled = false,
  isEmpty = false,
  isText = false,
  setText,
  cesShowMode = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const parentRef = useRef(null);
  const { pictureTypeOverAll, pictureType } = useSelector(
    (state) => state.common.appStatus
  );
  const { overall, subTab } = useSelector(
    (state) => state.common.appStatus.voiceGuideStatus[reportType]
  );
  const [isSound, setSound] = useState(false);

  const handleText = useCallback(() => {
    setText && setText((prev) => !prev);
  }, []);
  const handleSound = useCallback(() => {
    if (selectedTabIndex === 0) {
      dispatch(
        changeVoiceGuideStatus({
          reportType,
          tabType: "overall",
          value: !overall,
        })
      );
    } else {
      dispatch(
        changeVoiceGuideStatus({
          reportType,
          tabType: "subTab",
          value: !subTab,
        })
      );
    }
  }, [overall, subTab, selectedTabIndex]);

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setSound(overall);
      overall && console.log("voiceStatus: 'overall' is true!! overall");
    }
  }, [overall, selectedTabIndex]);

  useEffect(() => {
    if (selectedTabIndex !== 0) {
      setSound(subTab);
      subTab &&
        console.log(
          "voiceStatus: 'subTab' is 'true', index is ",
          selectedTabIndex
        );
    }
  }, [subTab, selectedTabIndex]);

  const [selectedDate, setSelectedDate] = useState(selectedDateStr? new Date(selectedDateStr+"T00:00:00"):new Date());

  const _onSelectedDate = useCallback(
    ({ selectedDate }) => {
      console.log("onSelectedDate", selectedDate);
      setSelectedDate(selectedDate);
      if (onSelectedDate) {
        onSelectedDate({ selectedDate });
      }
    },
    [onSelectedDate]
  );

  const currentPictureType = useMemo(()=>{
    if(selectedTabIndex===0){
      return pictureTypeOverAll;
    }
    return pictureType;
  }, [selectedTabIndex, pictureTypeOverAll, pictureType]);

  const textBtnDisable = useMemo(() => {
    if (cesShowMode && selectedTabIndex !== 0) {
      return true;
    } else {
      if (isEmpty) {
        return isEmpty;
      } else {
        return disabled;
      }
    }
  }, [isEmpty, disabled, cesShowMode, selectedTabIndex]);

  const voiceBtnDisable = useMemo(() => {
    if (cesShowMode && selectedTabIndex !== 0) {
      return true;
    } else {
      return disabled;
    }
  }, [cesShowMode, selectedTabIndex, disabled]);

  return (
    <Container className={classNames(css.topLayout, className)} {...rest}>
      <div>
        <TSimpleDatePicker
          period={"total"}
          selectedDate={selectedDate}
          onSelectedDate={_onSelectedDate}
          size="small"
          width="small"
          whitelist={dateList}
          useCalendar={false}
          noArrow={true}
          dropDown={true}
        />
      </div>
      <div className={css.btnBox} ref={parentRef}>
        {/* Text Btn은 팝업 형식 예정 */}
        <div className={css.feedbackBox}>
          <span>{$L("Expert feedbacks")}</span>
          <TButton
            type={TYPES.reportOnlyIcon}
            icon={ICONS.note}
            onSpotlightDown={onSpotlightDown}
            disabled={textBtnDisable}
            onClick={handleText}
            spotlightId={SpotlightIds.REPORT_TEXT_BUTTON}
            itemRenderer={(isTooltip) =>
              !textBtnDisable && (
                <TToolTip
                  relativeRef={parentRef}
                  isOpen={isTooltip}
                  style={{ top: "77px" }}
                >
                  {$L("Text")}
                </TToolTip>
              )
            }
          ></TButton>
          <TButton
            type={TYPES.reportOnlyIcon}
            icon={isSound ? ICONS.sound : ICONS.soundMute}
            onSpotlightDown={onSpotlightDown}
            disabled={voiceBtnDisable} // if ReportTop hasn't data, disabled is true
            onClick={handleSound}
            itemRenderer={(isTooltip) =>
              !disabled && (
                <TToolTip
                  relativeRef={parentRef}
                  relativePosition={!pose && "right"}
                  isOpen={isTooltip}
                  style={{ top: "77px" }}
                >
                  {$L("Voice")}
                </TToolTip>
              )
            }
          ></TButton>
        </div>
        {pose && (
          <div className={classNames(css.feedbackBox, css.onOff)}>
            <div className={css.divider} />
            <span>{$L("Photo")}</span>
            <OnOffButton
              isLeftSelected={currentPictureType === "photo" ? true : false}
              disabled={isEmpty}
              selectedTabIndex={selectedTabIndex}
              parentRef={parentRef}
            />
          </div>
        )}
      </div>
    </Container>
  );
};
export default ReportTop;