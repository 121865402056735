import React, { useMemo } from "react";
import CoordinatePlane from "../../../../../components/CoordinatePlane/CoordinatePlane";

const BodyBalanceTabResults = ({ currentData, style, totalLength = 278 }) => {
  const balanceRatioIndexData = useMemo(() => {
    const ret = { xBalanceRatioIndex: 0, yBalanceRatioIndex: 1 };
    if (currentData?.twolegstance) {
      ret.xBalanceRatioIndex = Number(currentData?.twolegstance?.value_balanceRatio_LR);
      ret.yBalanceRatioIndex = Number(currentData?.twolegstance?.value_balanceRatio_FFRF);
    }
    return ret;
  }, [currentData]);

  return (
    <CoordinatePlane
      style={style}
      balanceRatioXData={balanceRatioIndexData.xBalanceRatioIndex}
      balanceRatioYData={balanceRatioIndexData.yBalanceRatioIndex}
      totalLength={totalLength}
    />
  );
};

export default BodyBalanceTabResults;