import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./FinishModal.module.less";
import Spotlight from "@enact/spotlight";
import classNames from "classnames";
import IMG_GPOINT from "../../../../../assets/images/workout/img_gpoint.png"
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import { $L } from "../../../../utils/helperMethods";
import THeader from "../../../../components/THeader/THeader";
import { changeThemeModeStatus } from "../../../../features/common/commonSlice";
import IC_ALERT_M from "../../../../../assets/icon/ic_alert_m.svg"
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Alert from "@enact/sandstone/Alert";

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const FinishModal = ({ onFinish  }) => {
  const dispatch = useDispatch();
  const point = 150;

  useEffect(() => {
    Spotlight.focus("recovery");
    dispatch(changeThemeModeStatus("dark"));
    return(() => {
      dispatch(changeThemeModeStatus("light"));
    });
  }, []);

  const _onFinish = useCallback((id) => () => {
    if (onFinish) onFinish(id)
  }, [onFinish]);


  return (
  <Alert
    open
    type={"fullscreen"}
    className={css.popup}
  >
    <Container className={classNames(css.finisiContainer)}>
      <div className={css.cl_modalDimmer}/>
      <THeader iconType="back" useTool={false} style={{ zIndex: "2" }}/>
      <Container className={css.container}>
        <img src={IMG_GPOINT}/>
        <div className={css.textContainer}>
          <div className={css.title}>{$L("You've completed all of today's workouts!")}</div>
          <div className={css.desc}>{$L("In this workout")+" "+point+$L("포인트를 얻었어요~!")}</div>
          <div className={css.desc}>{$L("Tap the [Do Recovery Workouts] button to start the recovery workout.")}</div>
          <div className={css.hr} />
          <div className={css.heartContainer}>
            <div className={css.heartInfo}>
              <img src={IC_ALERT_M}/>
              <div className={css.heartDesc}>{$L("Check the real-time heart rate graph after the workout.")}</div>
            </div>
            <TButton size={SIZES.large} onClick={_onFinish('heart')}>{$L("View the heart rate graph")}</TButton>
          </div>
        </div>
        <div className={css.buttonContainer}>
          <TButton size={SIZES.large} spotlightId={'recovery'} onClick={_onFinish('recovery')}>{$L("Do recovery exercises")}</TButton>
          <TButton size={SIZES.large} onClick={_onFinish('replay')}>{$L("Repeat the workout")}</TButton>
          <TButton size={SIZES.large} onClick={_onFinish('result')}>{$L("See workout results")}</TButton>
        </div>
      </Container>
    </Container>
  </Alert>
  );
};

export default FinishModal;
