import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import LS2Request from "./LS2Request";
// import * as Config from "../../utils/Config";
import * as lunaSend from "../../lunaSend";
import appinfo from "../../../webos-meta/appinfo.json";
import * as HelperMethods from "../../utils/helperMethods";
import * as SoundEffect from "../../utils/SoundEffect";
import { DEFAULT_SERVERTYPE } from '../fitService/fitServiceConfig';
import { BODY_CHECKUP_TYPE } from "../../utils/Constants";
import { Job } from "@enact/core/util";

export const getSystemSettings = createAsyncThunk("common/getSystemSettings",
  async (_, thunkAPI) => {
    lunaSend.getSystemSettings({ category: "option", keys: ["smartServiceCountryCode2", "cameraResourcePermission"] },
      {
        onSuccess: (res) => {
          console.log("getSystemSettings onSuccess-11 ", res);
        },
        onFailure: (err) => {
          console.log("getSystemSettings onFailure-22 ", err);
        },
        onComplete: (res) => {
          console.log("getSystemSettings onComplete-33", res);
          let country = null;
          let cameraPermissionRequired = undefined;
          if (res && res.settings) {
            if (res.settings.smartServiceCountryCode2) {
              country = res.settings.smartServiceCountryCode2;
            }
            if (res.settings.cameraResourcePermission) {
              for (let i = 0; i < res.settings.cameraResourcePermission.length; i++) {
                const data = res.settings.cameraResourcePermission[i];
                if (data.appId === appinfo.id) {
                  for (let j = 0; j < data.permissions.length; j++) {
                    if (data.permissions[j].resource === "camera") {
                      cameraPermissionRequired = data.permissions[j].permission;
                      break;
                    }
                  }
                  break;
                }
              }
            }
          }
          if (country) {
            let ln = HelperMethods.getLanguageCode(country);
            if (thunkAPI.getState().common.localSettings.languageSetting !== 'system') {
              ln = thunkAPI.getState().common.localSettings.languageSetting;
            }
            thunkAPI.dispatch(changeAppStatus({ country, language: ln, cameraPermissionRequired }));
            SoundEffect.setLanguage(ln);
          }
        },
      }
    );
  }
);

export const getSystemSettings2 = createAsyncThunk("common/getSystemSettings2",
  async (_, thunkAPI) => {
    lunaSend.getSystemSettings({ category: "caption", keys: ["captionEnable"] },
      {
        onSuccess: (res) => {
          console.log("getSystemSettings2 onSuccess ", res);
        },
        onFailure: (err) => {
          console.log("getSystemSettings2 onFailure ", err);
        },
        onComplete: (res) => {
          console.log("getSystemSettings2 onComplete", res);
          if (res && res.settings) {
            if (typeof res.settings.captionEnable !== "undefined") {
              thunkAPI.dispatch(changeAppStatus({ captionEnable: res.settings.captionEnable }));
            }
          }
        }
      }
    );
  }
);

export const getSystemInfo = createAsyncThunk("common/getSystemInfo",
  async (_, thunkAPI) => {
    lunaSend.getSystemInfo({ keys: ["sdkVersion", "otaId"] },
      {
        onSuccess: (res) => {
          if (res.returnValue) {
            const sdkVersion = res.sdkVersion;
            if (sdkVersion === "local") {
              thunkAPI.dispatch(changeAppStatus({ webOSVersion: "local", webOSVersionReal: "local", otaId: "local" }));
            } else {
              const version = sdkVersion.substring(0, sdkVersion.lastIndexOf("."));
              thunkAPI.dispatch(changeAppStatus({ webOSVersion: version, webOSVersionReal: sdkVersion, otaId: res.otaId }));
            }
          }
        },
        onFailure: () => {
          // console.log(err);
        },
        onComplete: () => {
          // console.log("getSystemInfo done");
        },
      }
    );
  }
);

export const getDeviceId = createAsyncThunk("common/getDeviceId",
  async (_, thunkAPI) => {
    lunaSend.getDeviceId({ idType: ["LGUDID"] },
      {
        onSuccess: (res) => {
          // console.log("getDeviceId res", res);
          if (res.returnValue) {
            const deviceId = res.idList[0].idValue;
            thunkAPI.dispatch(changeAppStatus({ deviceId }));
          }
        },
        onFailure: () => {
          // console.log(err);
        },
        onComplete: () => {
          // console.log("getDeviceId done");
        },
      }
    );
  });

export const getHttpHeaderForServiceRequest = createAsyncThunk(
  "common/getHttpHeaderForServiceRequest",
  async (webOSVersion, language) => {
    let getHttpHeaderForServiceRequestResult = null;

    lunaSend.getHttpHeaderForServiceRequest(webOSVersion, language, {
      onSuccess: (res) => {
        getHttpHeaderForServiceRequestResult = res;
        console.log("getHttpHeaderForServiceRequest res", res);
      },
      onFailure: () => {
        // console.log("getHttpHeaderForServiceRequest err", err);
      },
      onComplete: () => {
        // console.log("getHttpHeaderForServiceRequest done");
      },
    });
    return getHttpHeaderForServiceRequestResult;
  }
);
let updateNetworkStateJob = new Job((connected, _thunkAPI) => {
  _thunkAPI.dispatch(changeAppStatus({ isInternetConnected: connected }));
});
export const getConnectionStatus = createAsyncThunk("common/getConnectionStatus",
  async (_, thunkAPI) => {
    lunaSend.getConnectionStatus({
      onSuccess: (res) => {
        if (res.returnValue) {
          let isInternet = (res.wifi && res.wifi.onInternet === "yes") || (res.wired && res.wired.onInternet === "yes");
          let isInternetConnected = (res.wifi && res.wifi.state === "connected") || (res.wired && res.wired.state === "connected");
          let connected = isInternet && isInternetConnected;
          updateNetworkStateJob.startAfter(connected ? 100 : 3000, connected, thunkAPI);
        }
      },
      onFailure: () => {
        // console.log(err);
      },
      onComplete: () => {
        // console.log("getConnectionStatus done", res);
      },
    });
  }
);

export const getDeviceList = createAsyncThunk("common/getDeviceList", async (_, thunkAPI) => {
  lunaSend.getDeviceList({
    onSuccess: (res) => {
      let usbDeviceUri = "";
      for (let i = 0; i < res.devices.length; i++) {
        const device = res.devices[i];
        if (device.deviceType === 'usb' && (device.deviceStatus === 'NORMAL' || device.deviceStatus === 'SUSPEND')) {
          if (device.subDevices) { // only support single partition
            usbDeviceUri = device.subDevices[0].deviceUri;
          }
        }
      }
      thunkAPI.dispatch(changeAppStatus({ usbDeviceUri }));
    },
    onFailure: () => {
      thunkAPI.dispatch(changeAppStatus({ usbDeviceUri: "" }));
    },
    onComplete: () => {
    },
  });
  return "";
}
);

export const getLoginUserData = createAsyncThunk("common/getLoginUserData", async (_, thunkAPI) => {
  let useFakeLogin = thunkAPI.getState().common.localSettings.useFakeLogin;
  const isOnTV = typeof window === "object" && window.PalmSystem;
  if (useFakeLogin || !isOnTV) {
    thunkAPI.dispatch(changeAppStatus({ userId: "kracm01@yopmail.com", userNumber: "KR2311236618864", profileNick: "LGEUSER0" }));
    // thunkAPI.dispatch(changeAppStatus({userId: "krtest@yopmail.com", userNumber:"KRTEST", profileNick:"KRTESTUSER0"}));
  } else {
    lunaSend.getLoginUserData({ serviceName: "LGE" }, {
      onSuccess: (res) => {
        if (res.returnValue) {
          console.log('getLoginUserData success', res);
          const userId = res.id;
          const userNumber = res.lastSignInUserNo;
          const profileNick = res.profileNick || res.id.split('@')[0];
          if (userId && userNumber && profileNick) {
            thunkAPI.dispatch(changeAppStatus({ userId, userNumber, profileNick }));
          } else {
            thunkAPI.dispatch(changeAppStatus({ userId: "", userNumber: "", profileNick: "" }));
          }
        }
      },
      onFailure: (err) => {
        console.log('getLoginUserData error', err);
      },
      onComplete: () => {
      },
    });
  }
}
);

export const launchMembershipApp = createAsyncThunk("common/launchMembershipApp", async (_, thunkAPI) => {
  lunaSend.launchMembershipApp({
    onSuccess: (res) => {
      console.log(res);
    },
    onFailure: (err) => {
      console.log(err);
    },
    onComplete: () => {
    }
  });
});

let broadcastTimer = null;
export const sendBroadCast = createAsyncThunk("common/sendBroadcast", async ({ type, moreInfo }, thunkAPI) => {
  clearTimeout(broadcastTimer);
  thunkAPI.dispatch(changeBroadcastEvent({ type, moreInfo }));
  broadcastTimer = setTimeout(() => {
    thunkAPI.dispatch(changeBroadcastEvent({}));
  }, 500);
});

const initialLocalSettings = {
  showCustomDummyData: true,
  setAudioFeedback: false,
  cesShowMode: false,
  useBleSerial: true,
  useFakeLogin: false,
  useBleDummy: false,
  xFitBodyProcessQueueSize: 1,
  xFitBodySetCustomBaseline: true,
  showBodyCoordinations: false,
  xFitBodyBottomPosition: 95, //Engine
  xFitBodyBottomPositionGUI: 85, //Gui
  xFitBodyBellySmootherQueueSize: 1,
  xFitKTestThresHoldInterval: 1,
  xFitKTestThresHoldOneLegShoulderAngle: 1.0,
  xFitKTestThresHoldOneLegShoulderDistance: 10,
  xFitKTestThresHoldWalkingShoulderAngle: 1.0,
  xFitKTestThresHoldWalkingShoulderDistance: 10,
  xFitKTestThresHoldWalkingPelvicAngle: 1.0,
  xFitKTestThresHoldWalkingPelvicDistance: 10,
  languageSetting: 'system', //system, ko, en
  //camera
  roiSize: 0.5,
  camera2DPose: "NPU_mid",
  cameraRPPGModel: "CPU",
  sftEnduranceRatio: 50, //sft 무릎높이 설정
  climingEnduranceRatio_90: 20,//Climing 무릎높이 설정
  climingEnduranceRatio_60: 50,//Climing 무릎높이 설정
  climingEnduranceRatio_30: 80,//Climing 무릎높이 설정
  cameraSizeType: "small",
  cameraPosition: "rt",
  cameraRecordingActivated: false,
  skipVideoGuide: false,
  userName: "", //todo remove
  userAge: 65, //todo appStatus
  userGender: "", //todo appStatus

  //todo remove (server)
  // termsAgree:{},
  surveyFinished: {},

  //server
  serverType: DEFAULT_SERVERTYPE, //prd, qa, dev, twin
  localURL: "",
  //ble
  lastConnectedBleDevices: [],

  //popupSkip
  bodyScanSkip: false,
  physicalTestSkip: false,

  // physicalTest tool
  chairSelected: false,
  dumbbellSelected: false
};

const updateInitialLocalSettings = () => {
  const data = HelperMethods.readLocalStorage(
    "localSettings",
    initialLocalSettings
  );
  if (Object.keys(data).length !== Object.keys(initialLocalSettings).length) {
    HelperMethods.writeLocalStorage("localSettings", initialLocalSettings);
    return initialLocalSettings;
  } else {
    return data;
  }
};

const initialState = {
  appStatus: {
    checkingAccountId: true,
    isAppForeground: true,
    webOSVersion: "",
    webOSVersionReal: "",
    deviceId: "",
    isInternetConnected: true,
    connectionFailed: false,
    cursorVisible: false,
    captionEnable: false,
    showTermsPopup: false,
    cameraPermissionRequired: undefined,
    otaId: "",
    country: 'US',
    language: "en",
    pictureTypeOverAll: 'photo',
    pictureType: "illust", // illust, photo,
    audioBodyScan_Previous: true,
    audioBodyScan_Overall: true,
    audioBodyScan_Detail: true,
    audioPhysicalTest_Previous: true,
    audioPhysicalTest_Overall: true,
    audioPhysicalTest_Detail: true,
    chairSelected: true,
    dumbbellSelected: true,
    showLoadingPanel: { show: true, type: 'launching' }, //type : "launching, wait, analyzing, tips
    showUserCheckPopup: { show: false, mode: 'genaral' }, // mode: "genaral", "play", "test"
    usbDeviceUri: '',
    showBodyAlignmentSummary: true,
    showPhysicalTestSummary: true,
    //account
    userId: undefined,
    userNumber: undefined,
    profileNick: undefined,

    //accessible
    accessible: true,

    //toast
    toast: { isToasted: false, message: "" },

    // theme mode
    themeMode: "light", // light, dark, transparent

    //image index
    imageIndex: 0,

    // voiceGuideStatus
    voiceGuideStatus: {
      [BODY_CHECKUP_TYPE.BODY_ALIGNMENT]: {
        overall: true,
        subTab: true,
      },
      [BODY_CHECKUP_TYPE.ROM]: {
        overall: true,
        subTab: true,
      },
      [BODY_CHECKUP_TYPE.BODY_BALANCE]: {
        overall: true,
        subTab: true,
      },
      [BODY_CHECKUP_TYPE.PHYSICAL_TEST]: {
        overall: true,
        subTab: true,
      },
    },

    // cesFeedbackData
    cesFeedbackData: {
      EXERCISE_NAME: null,
      ASSOCIATED_MUSCLES: null,
      EXERCISE_TIME: null,
      VIDEO_INDEX: null,
      VIDEO_THUMBNAIL: null,
    },
  },
  localSettings: updateInitialLocalSettings(),
  broadcast: {},
  isLoading: true,
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changeAppStatus: (state, action) => {
      let isUpdated = false;
      for (let i in action.payload) {
        if (typeof action.payload[i] === 'object') {
          if (JSON.stringify(action.payload[i]) !== JSON.stringify(state.appStatus[i])) {
            isUpdated = true;
            break;
          }
        } else if (state.appStatus[i] !== action.payload[i]) {
          isUpdated = true;
          break;
        }
      }
      if (isUpdated) {
        state.appStatus = { ...state.appStatus, ...action.payload };
      }
    },
    changeLocalSettings: (state, action) => {
      state.localSettings = { ...state.localSettings, ...action.payload };
      HelperMethods.writeLocalStorage("localSettings", state.localSettings);
    },
    changeLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    changeToastStatus: (state, action) => {
      state.appStatus.toast = action.payload;
    },
    changeImageIndex: (state, action) => {
      console.log(action.payload)
      state.appStatus.imageIndex = action.payload.imageIndex;
    },
    changeThemeModeStatus: (state, action) => {
      state.appStatus.themeMode = action.payload;
    },
    changeVoiceGuideStatus: (state, action) => {
      const { reportType, tabType, value } = action.payload;
      state.appStatus.voiceGuideStatus[reportType][tabType] = value;
    },
    changeCesFeedbackData: (state, action) => {
      state.appStatus.cesFeedbackData = action.payload;
    },
    changeBroadcastEvent: (state, action) => {
      state.broadcast = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSystemSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSystemSettings.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSystemSettings2.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSystemSettings2.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSystemInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSystemInfo.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getDeviceId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDeviceId.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getHttpHeaderForServiceRequest.pending, (state) => {
      state.isLoading = true;
      state.appStatus.checkingAccountId = true;
    });
    builder.addCase(getHttpHeaderForServiceRequest.fulfilled, (state) => {
      state.isLoading = false;
      state.appStatus.checkingAccountId = false;
      // state.localSettings.deviceHTTPHeader = action.payload;
    }
    );
    builder.addCase(getConnectionStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConnectionStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getDeviceList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDeviceList.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  changeAppStatus,
  addMainIndex,
  changeLoadingStatus,
  changeLocalSettings,
  changeToastStatus,
  changeImageIndex,
  changeThemeModeStatus,
  changeVoiceGuideStatus,
  changeCesFeedbackData,
  changeBroadcastEvent
} = commonSlice.actions;

export default commonSlice.reducer;
