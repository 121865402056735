import { createSlice } from "@reduxjs/toolkit";
import * as HelperMethods from "../../utils/helperMethods";
//tab아이콘
const notOnTv = (typeof window === 'object' && !window.PalmSystem);

export const DATAKEY={
  TWO_LEG_STANCE: "twolegstance",//양발서기
  ONE_LEG_STANCE_L: "onelegstanceL",//한발서기
  ONE_LEG_STANCE_R: "onelegstanceR",//한발서기
  WALKING: "walking"//보행분석
}
export const bodyBalance = createSlice({
  name: "bodyBalance",
  initialState: {
    current:{
      testing: "", //TWO_LEG_STANCE, ONE_LEG_STANCE, WALKING
      matStatus: "", //"idle", "ready", "not ready", "start", "finished"
      matStatusValue: "",// 0~5
      [DATAKEY.TWO_LEG_STANCE]:{},
      [DATAKEY.ONE_LEG_STANCE_L]:{},
      [DATAKEY.ONE_LEG_STANCE_R]:{}
    },
    dateList: [],
    testResults: {
      // "2023-09-11":{"twolegstance":{}, "onelegstanceL":{}}
      // "2023-10-24": {
      //   "twolegstance":{
      //   date: "2023-10-24T18:28:59",
      //   left_foot_position: [119, 272, 136, 85, 102, 255, 187, 102],
      //   right_foot_position: [493, 289, 493, 68, 476, 272, 561, 221]
      // }}
    }
  },
  reducers: {
    setCurrentTestingName: (state, action) => {
      state.current.testing = action.payload;
    },
    clearCurrentTestingStatus: (state, action) => {
      if(!action.payload){
        state.current = {testing: ""};
      }else{
        if(action.payload instanceof Array){
          action.payload.forEach(element => {
            state.current[element] = {};
            state.current = {...state.current};
          });
        }else{
          state.current[action.payload] = {};
          state.current = {...state.current};
        }
      }
    },
    updateCurrentTestingStatus: (state, action) => {
      if(state.current.testing){
        state.current[state.current.testing] = Object.assign({}, state.current[state.current.testing], action.payload);
        state.current = {...state.current};
      }
    },
    clearMatStatusAndValue: (state, action) => {
      state.current.matStatus = "";
      state.current.matStatusValue = "";
      state.current = {...state.current};
    },
    updateMatStatusAndValue:  (state, action) => {
      if(state.current.testing){
        state.current.matStatus = action.payload.matStatus;
        state.current.matStatusValue = action.payload.matStatusValue;
        state.current = {...state.current};
      }else{
        console.warn('bodyBalanceSlice no testing status');
      }
    },
    updateDateList: (state, action) => {
      state.dateList = action.payload;
      state.dateList.sort();
    },
    clearTestResults: (state, action) => {
      state.dateList = [];
      state.testResults = {};
    },
    updateTestResults: (state, action) => {
      const {date, value} = action.payload;
      //override
      if(value.bodyBalanceHistoryId && state.testResults?.[date]?.bodyBalanceHistoryId === value.bodyBalanceHistoryId){
        console.log('ignore date update');
        return;
      }
      if(state.dateList.indexOf(date) < 0){
        state.dateList.push(date);
      }
      state.testResults[date] = Object.assign({}, state.testResults[date], value);

    }
  },
});

export const {
  setCurrentTestingName,
  clearCurrentTestingStatus,
  updateCurrentTestingStatus,
  clearMatStatusAndValue,
  updateMatStatusAndValue,
  updateDateList,
  clearTestResults,
  updateTestResults,
} = bodyBalance.actions;

export default bodyBalance.reducer;
