import classNames from "classnames";
import css from "./MySurveyInfo.module.less";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  $L,
  numberWithCommas,
  transDateToText,
  transNumToAgeText,
} from "../../../utils/helperMethods";
import { addPanels, updatePanel } from "../../../features/panels/panelsSlice";
import * as Config from "../../../utils/Config";

import TListItemButton from '../../../components/TListItemButton/TListItemButton';

import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const MySurveyInfo = () => {
  const dispatch = useDispatch();
  const { height, weight, sex, age } = useSelector((state) => state.fitService.survey);
  const { profileNick, language } = useSelector(
    (state) => state.common.appStatus
  );

  const USER_INFO = useMemo(() => {
    return [
      { id: "name", title: $L("Name"), info: profileNick },
      {
        id: "gender",
        title: $L("Gender"),
        info: sex === 0 ? $L("Male") : $L("Female"),
      },
      {
        id: "birth",
        title: $L("Birth"),
        info: transDateToText("1963-05-30", language),
      },
      {
        id: "age",
        title: $L("Age"),
        info: transNumToAgeText(age ? age : 60),
      },
      { id: "height", title: $L("Height"), info: `${height}cm` },
      { id: "weight", title: $L("Weight"), info: `${weight}kg` },
    ]
  }, [profileNick, age, sex, height, weight]);

  const ORDER = useMemo(() => {
    return [
      {id: 'basic', title: $L("Basic Information"), date: ""},
      {id: 'body', title: $L("바디정보") , date: ""},
      {id: 'exercise', title: $L("운동정보") , date: ""},
      {id: 'life', title: $L("생활정보") , date: ""},
    ];
  }, []);

  const _onClickSection = useCallback((index) => (ev) => {
    dispatch(addPanels({ name: Config.panel_names.MY_SURVEY_PANEL, panelInfo: { selectedIndex: index } }));
    dispatch(updatePanel({ name: Config.panel_names.MY_PANEL, panelInfo: { selectedTabIndex: 2 } }))
  }, [dispatch]);

  return (
    <Container className={css.healthInfo}>
      {/* my info */}
      <div className={css.myInfoContainer}>
        <div className={css.title}>{$L("내 정보")}</div>
        <div className={css.infoArea}>
          {USER_INFO.map((item) => {
            return (
              <div className={css.infoBox} key={item.id}>
                <div className={css.qBox}>{item.title}</div>
                <div className={css.aBox}>{item.info}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* health info */}
      <div className={css.healthInfoContainer}>
        <div className={css.title}>{$L("최근 건강정보 보기")}</div>
        <div className={css.infoArea}>
          {ORDER.map((item, index) => (
            <TListItemButton
              className={css.tListItemButton}
              key={"order" + index}
              onClick={_onClickSection(index)}
            >
              <div className={css.text}>{item.title}</div>
              <div className={css.date}></div>
            </TListItemButton>
          ))}
        </div>

      </div>

    </Container>
  );
};

export default MySurveyInfo;
