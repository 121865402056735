import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./Overall.module.less";
import classNames from "classnames";
import { getEmptyResultsReportData, romMenu } from "../Constants";
import EmptyResultsReport from "../../../../../components/EmptyResultsReport/EmptyResultsReport";
import TScroller from "../../../../../components/TScroller/TScroller";
import { $L } from "../../../../../utils/helperMethods";
import { POSITION_TYPE } from "./Constants";
import TButton, { SIZES } from "../../../../../components/TButton/TButton";
import TestSelectPopup from "../../../../../components/TestSelectPopup/TestSelectPopup";
import { getROM_INFO } from "../../ROMTest/Constants";
import { addPanels } from "../../../../../features/panels/panelsSlice";
import * as Config from "../../../../../utils/Config";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import OverallDetail from "./OverallDetail/OverallDetail";
import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
} from "../../../../../utils/Constants";
import {
  voiceGuideEmpty,
  voiceGuideOverallROM,
} from "../../feedback/feedbackROM";
import { getBODY_PART_TYPE, SUB_TYPE } from "../../feedback/constantsFeedback";
import { DEGREE_DETAIL_DATA, IMG_DATA } from "./ConstantsSubTab";

// dotted-line
import svg_dotted_line_neck from "../../../../../../assets/rom/rom_dotted_line_neck.svg";
import svg_dotted_line_shoulder from "../../../../../../assets/rom/rom_dotted_line_shoulder.svg";
import svg_dotted_line_trunk from "../../../../../../assets/rom/rom_dotted_line_trunk.svg";
import svg_dotted_line_hip from "../../../../../../assets/rom/rom_dotted_line_hip.svg";
import svg_dotted_line_knee from "../../../../../../assets/rom/rom_dotted_line_knee.svg";
import { changeAppStatus } from "../../../../../features/common/commonSlice";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

let ROM_INFO = null;
let BODY_PART_TYPE = null;

const Overall = ({
  date = "",
  tabInfos = [],
  openTestSelectPopup,
  openMenuTestPopup,
  feedback,
  setFeedback,
  tabIndex = 0,
  ...rest
}) => {
  if (!BODY_PART_TYPE) {
    BODY_PART_TYPE = getBODY_PART_TYPE();
  }
  if (!ROM_INFO) {
    ROM_INFO = getROM_INFO();
  }
  const scrollTo = useRef();
  const dispatch = useDispatch();
  const [dotIndex, setDotIndex] = useState(romMenu.NECK);

  const { testResults } = useSelector((state) => state.rom);
  const emptyResultsReportData = getEmptyResultsReportData();
  const overallData = emptyResultsReportData[romMenu.OVERALL];
  const initialCheckedState = Array(ROM_INFO.length).fill(true);
  const [showTestSelectPopup, setShowTestSelectPopup] = useState(false);
  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);
  useEffect(() => {
    if (scrollTo && scrollTo.current) {
      scrollTo.current({ position: { y: 0 }, animate: false });
    }
  }, [dotIndex]);

  const openSelectPopup = useCallback(() => {
    openTestSelectPopup();
  }, []);

  const openTestPopup = useCallback(() => {
    setShowTestSelectPopup(true);
  }, []);

  const closeTestSelectPopup = useCallback((ev) => {
    console.log("ev.checked", ev.checked);
    setShowTestSelectPopup(false);
    if (ev.checked) {
      dispatch(
        addPanels({
          name: Config.panel_names.ROM_TEST,
          panelInfo: { romList: ev.checked },
        })
      );
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
    }
  }, []);

  const isEmpty = useMemo(() => {
    return testResults?.[date] ? false : true;
  }, [testResults, date]);

  const dotColor = useMemo(() => {
    const grades = {};
    const color = {};
    const gradesArr = {
      [SCORE_MINT_KEY]: [],
      [SCORE_YELLOW_KEY]: [],
      [SCORE_RED_KEY]: [],
    };
    const gradesDotArr = {
      [SCORE_MINT_KEY]: [],
      [SCORE_YELLOW_KEY]: [],
      [SCORE_RED_KEY]: [],
    };
    tabInfos?.map((tabInfo) => {
      if (tabInfo.type !== romMenu.OVERALL) {
        if (tabInfo.showingGradeLR) {
          grades[tabInfo.type] = tabInfo.showingGradeLR;
          color[tabInfo.type] = {};
          const keys = Object.keys(grades[tabInfo.type]);
          keys.map((key) => {
            switch (grades[tabInfo.type][key]) {
              case "a":
                color[tabInfo.type][key] = "good";
                gradesArr[SCORE_MINT_KEY].push(`${tabInfo.type}_${key}`);
                !gradesDotArr[SCORE_MINT_KEY].includes(tabInfo.type) &&
                  gradesDotArr[SCORE_MINT_KEY].push(tabInfo.type);
                break;
              case "b":
                color[tabInfo.type][key] = "borderline";
                gradesArr[SCORE_YELLOW_KEY].push(`${tabInfo.type}_${key}`);
                !gradesDotArr[SCORE_YELLOW_KEY].includes(tabInfo.type) &&
                  gradesDotArr[SCORE_YELLOW_KEY].push(tabInfo.type);
                break;
              case "c":
                color[tabInfo.type][key] = "weak";
                gradesArr[SCORE_RED_KEY].push(`${tabInfo.type}_${key}`);
                !gradesDotArr[SCORE_RED_KEY].includes(tabInfo.type) &&
                  gradesDotArr[SCORE_RED_KEY].push(tabInfo.type);
                break;
              default:
                break;
            }
          });
        } else {
          grades[tabInfo.type] = tabInfo.showingGrade;
          switch (grades[tabInfo.type]) {
            case "a":
              color[tabInfo.type] = "good";
              gradesArr[SCORE_MINT_KEY].push(tabInfo.type);
              !gradesDotArr[SCORE_MINT_KEY].includes(tabInfo.type) &&
                gradesDotArr[SCORE_MINT_KEY].push(tabInfo.type);
              break;
            case "b":
              color[tabInfo.type] = "borderline";
              gradesArr[SCORE_YELLOW_KEY].push(tabInfo.type);
              !gradesDotArr[SCORE_YELLOW_KEY].includes(tabInfo.type) &&
                gradesDotArr[SCORE_YELLOW_KEY].push(tabInfo.type);
              break;
            case "c":
              color[tabInfo.type] = "weak";
              gradesArr[SCORE_RED_KEY].push(tabInfo.type);
              !gradesDotArr[SCORE_RED_KEY].includes(tabInfo.type) &&
                gradesDotArr[SCORE_RED_KEY].push(tabInfo.type);
              break;
            default:
              break;
          }
        }
      }
    });

    let status;
    if (gradesArr[SCORE_RED_KEY].length > 0) {
      status = SCORE_RED_KEY;
    } else if (gradesArr[SCORE_YELLOW_KEY].length > 0) {
      status = SCORE_YELLOW_KEY;
    } else {
      status = SCORE_MINT_KEY;
    }

    if (gradesDotArr[status].length > 0) {
      setDotIndex(gradesDotArr[status][0]);
    }

    const convertGradeArr = (partName) => {
      const hasLeft = gradesArr[status].includes(`${partName}_L`);
      const hasRight = gradesArr[status].includes(`${partName}_R`);

      if (hasLeft && hasRight) {
        gradesArr[status].push(`${partName}_BOTH`);
        gradesArr[status] = gradesArr[status].filter(
          (part) => part !== `${partName}_L` && part !== `${partName}_R`
        );
      }
    };
    convertGradeArr("HIP");
    convertGradeArr("KNEE");
    convertGradeArr("SHOULDER");

    setFeedback(
      isEmpty
        ? voiceGuideEmpty(romMenu.OVERALL)
        : voiceGuideOverallROM(gradesArr[status])
    );

    return color;
  }, [tabInfos, isEmpty]);

  const detailInfos = useMemo(() => {
    const gradeObj = {};
    tabInfos?.map((tabInfo) => {
      if (tabInfo.type !== romMenu.OVERALL) {
        gradeObj[tabInfo.type] = tabInfo.data;
      }
    });

    // init type
    const initObj = { images: null, value: 0, grade: "" };
    const initObjLR = {
      images: {
        L: null,
        R: null,
      },
      value: {
        L: 0,
        R: 0,
      },
      grade: {
        L: "",
        R: "",
      },
    };

    const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

    // default data
    const bodyTypeObj = {
      [romMenu.NECK]: {
        title: `${BODY_PART_TYPE.NECK} (${$L("Cervical spine")})`,
        testList: {
          [SUB_TYPE.NECK_LATERAL_FLEXION]: deepClone(initObjLR),
          [SUB_TYPE.NECK_FLEXION]: deepClone(initObj),
          [SUB_TYPE.NECK_EXTENSION]: deepClone(initObj),
        },
      },
      [romMenu.HIP]: {
        title: BODY_PART_TYPE.HIP,
        testList: {
          [SUB_TYPE.HIP_FLEXION]: deepClone(initObjLR),
          [SUB_TYPE.HIP_EXTENSION]: deepClone(initObjLR),
        },
      },
      [romMenu.KNEE]: {
        title: `${BODY_PART_TYPE.KNEE} (${$L("Knee joint")})`,
        testList: {
          [SUB_TYPE.KNEE_FLEXION]: deepClone(initObjLR),
        },
      },
      [romMenu.SHOULDER]: {
        title: `${BODY_PART_TYPE.SHOULDER} (${$L("Shoulder joints")})`,
        testList: {
          [SUB_TYPE.SHOULDER_ABDUCTION]: deepClone(initObjLR),
          [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: deepClone(initObjLR),
          [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: deepClone(initObjLR),
          [SUB_TYPE.SHOULDER_FLEXION]: deepClone(initObjLR),
          [SUB_TYPE.SHOULDER_EXTENSION]: deepClone(initObjLR),
        },
      },
      [romMenu.TRUNK]: {
        title: `${BODY_PART_TYPE.TRUNK} (${$L("Lumbar spine")})`,
        testList: {
          [SUB_TYPE.TRUNK_LATERAL_FLEXION]: deepClone(initObjLR),
          [SUB_TYPE.TRUNK_FLEXION]: deepClone(initObj),
          [SUB_TYPE.TRUNK_EXTENSION]: deepClone(initObj),
        },
      },
    };

    for (const bodyType in gradeObj) {
      const bodyPart = gradeObj[bodyType];
      const testKeys = Object.keys(bodyPart);
      testKeys.map((test) => {
        const grades = bodyPart[test].grade;
        for (const grade in grades) {
          if (grades[grade] !== "") {
            if (
              test === SUB_TYPE.TRUNK_FLEXION ||
              test === SUB_TYPE.TRUNK_EXTENSION ||
              test === SUB_TYPE.NECK_FLEXION ||
              test === SUB_TYPE.NECK_EXTENSION
            ) {
              const imgArr = IMG_DATA[bodyType][test];
              let imgPath = "";
              const degreeData = DEGREE_DETAIL_DATA[bodyType][test];
              const value = bodyPart[test].value[grade];

              const findIndex = degreeData.findIndex((el) => value > el);
              const index =
                findIndex !== -1 ? findIndex : degreeData.length - 1;
              imgPath = imgArr[index];

              bodyTypeObj[bodyType].testList[test].images = imgPath;
              bodyTypeObj[bodyType].testList[test].grade = grades[grade];
              bodyTypeObj[bodyType].testList[test].value =
                bodyPart[test].value[grade];
              break;
            } else {
              const convertLR = grade === "0" ? "L" : "R";
              const imgArr = IMG_DATA[bodyType][test][convertLR];
              let imgPath = "";
              const degreeData = DEGREE_DETAIL_DATA[bodyType][test];
              const value = bodyPart[test].value[grade];

              const findIndex = degreeData.findIndex((el) => value > el);
              const index =
                findIndex !== -1 ? findIndex : degreeData.length - 1;
              imgPath = imgArr[index];

              bodyTypeObj[bodyType].testList[test].images[convertLR] = imgPath;
              bodyTypeObj[bodyType].testList[test].grade[convertLR] =
                grades[grade];
              bodyTypeObj[bodyType].testList[test].value[convertLR] =
                bodyPart[test].value[grade];
            }
          }
        }
      });
    }
    return bodyTypeObj;
  }, [tabInfos]);

  const _onClick = useCallback((type) => {
    setDotIndex(type);
  }, []);

  return !isEmpty ? (
    <div className={css.wrapper}>
      <Container className={css.overallContainer}>
        <Container className={css.imageBox}>
          <div className={css.overallImage} />

          {/* bodyPart dot point */}
          <div className={classNames(css.dotBox, css.dotNeck)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.NECK]],
                dotIndex === romMenu.NECK && css.selected
              )}
            />
          </div>
          <div className={classNames(css.dotBox, css.dotShoulderL)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.SHOULDER]?.[POSITION_TYPE.LEFT_LABEL]],
                dotIndex === romMenu.SHOULDER && css.selected
              )}
            />
          </div>
          <div className={classNames(css.dotBox, css.dotShoulderR)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.SHOULDER]?.[POSITION_TYPE.RIGHT_LABEL]],
                dotIndex === romMenu.SHOULDER && css.selected
              )}
            />
          </div>
          <div className={classNames(css.dotBox, css.dotTrunk)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.TRUNK]],
                dotIndex === romMenu.TRUNK && css.selected
              )}
            />
          </div>
          <div className={classNames(css.dotBox, css.dotHipL)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.HIP]?.[POSITION_TYPE.LEFT_LABEL]],
                dotIndex === romMenu.HIP && css.selected
              )}
            />
          </div>
          <div className={classNames(css.dotBox, css.dotHipR)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.HIP]?.[POSITION_TYPE.RIGHT_LABEL]],
                dotIndex === romMenu.HIP && css.selected
              )}
              onClick={() => _onClick(romMenu.HIP)}
            />
          </div>
          <div className={classNames(css.dotBox, css.dotKneeL)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.KNEE]?.[POSITION_TYPE.LEFT_LABEL]],
                dotIndex === romMenu.KNEE && css.selected
              )}
              onClick={() => _onClick(romMenu.KNEE)}
            />
          </div>
          <div className={classNames(css.dotBox, css.dotKneeR)}>
            <div
              className={classNames(
                css.dot,
                css[dotColor[romMenu.KNEE]?.[POSITION_TYPE.RIGHT_LABEL]],
                dotIndex === romMenu.KNEE && css.selected
              )}
            />
          </div>
          <div className={css.clickBox}>
            <div className={css.clickItem}>
              <TButton
                className={classNames(
                  css.bodyPart,
                  dotIndex === romMenu.NECK && css.selected
                )}
                onClick={() => _onClick(romMenu.NECK)}
              >
                {$L("Neck")}
              </TButton>
              <div
                className={classNames(css.dotLine, css.dotLineNeck)}
                style={{
                  backgroundImage: `url(${svg_dotted_line_neck})`,
                }}
              />
            </div>
            <div className={css.clickItem}>
              <TButton
                className={classNames(
                  css.bodyPart,
                  dotIndex === romMenu.SHOULDER && css.selected
                )}
                onClick={() => _onClick(romMenu.SHOULDER)}
              >
                {$L("Shoulder")}
              </TButton>
              <div
                className={classNames(css.dotLine, css.dotLineShoulder)}
                style={{
                  backgroundImage: `url(${svg_dotted_line_shoulder})`,
                }}
              />
            </div>
            <div className={css.clickItem}>
              <TButton
                className={classNames(
                  css.bodyPart,
                  dotIndex === romMenu.TRUNK && css.selected
                )}
                onClick={() => _onClick(romMenu.TRUNK)}
              >
                {$L("Trunk")}
              </TButton>
              <div
                className={classNames(css.dotLine, css.dotLineTrunk)}
                style={{
                  backgroundImage: `url(${svg_dotted_line_trunk})`,
                }}
              />
            </div>
            <div className={css.clickItem}>
              <TButton
                className={classNames(
                  css.bodyPart,
                  dotIndex === romMenu.HIP && css.selected
                )}
                onClick={() => _onClick(romMenu.HIP)}
              >
                {$L("Hip")}
              </TButton>
              <div
                className={classNames(css.dotLine, css.dotLineHip)}
                style={{
                  backgroundImage: `url(${svg_dotted_line_hip})`,
                }}
              />
            </div>
            <div className={css.clickItem}>
              <TButton
                className={classNames(
                  css.bodyPart,
                  dotIndex === romMenu.KNEE && css.selected
                )}
                onClick={() => _onClick(romMenu.KNEE)}
              >
                {$L("Knee")}
              </TButton>
              <div
                className={classNames(css.dotLine, css.dotLineKnee)}
                style={{
                  backgroundImage: `url(${svg_dotted_line_knee})`,
                }}
              />
            </div>
          </div>
        </Container>
        <TScroller className={css.scroller} cbScrollTo={getScrollTo}>
          <div className={css.bodyContainer}>
            {detailInfos && (
              <OverallDetail
                detailInfo={detailInfos[dotIndex]}
                type={dotIndex}
                feedback={feedback}
              />
            )}
          </div>
        </TScroller>
        {showTestSelectPopup && (
          <TestSelectPopup
            initialCheckedState={initialCheckedState}
            testInfos={ROM_INFO}
            romTest={true}
            onClose={closeTestSelectPopup}
          />
        )}
      </Container>
      <div className={css.testBtnWrap}>
        <TButton
          size={SIZES.test}
          className={css.testBtn}
          onClick={openTestPopup}
        >
          {$L("Test")}
        </TButton>
      </div>
    </div>
  ) : (
    <EmptyResultsReport
      openMenuTestPopup={openMenuTestPopup}
      overallResult
      title={overallData.title}
      detail={overallData.detail}
      onClick={openSelectPopup}
    />
  );
};

export default Overall;
