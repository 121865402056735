import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const SettingIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.2968 30.0045L37.9058 28.7771C37.9712 28.2534 38.004 27.7952 38.004 27.3697C38.004 26.9442 37.9712 26.5023 37.9058 25.9623L39.2968 24.7348C40.0333 24.0802 40.2297 22.9674 39.7223 22.1L37.8894 18.9252C37.3985 18.0578 36.3347 17.665 35.3855 17.976L33.6508 18.5815C32.8816 17.9923 32.0634 17.5177 31.2287 17.1577L30.8687 15.3575C30.6559 14.3756 29.7886 13.6719 28.7903 13.6719H25.1408C24.1425 13.6719 23.2752 14.3756 23.0788 15.3575L22.7187 17.1577C21.8677 17.5177 21.0495 17.9923 20.2803 18.5815L18.5292 17.9923C17.5964 17.665 16.5326 18.0742 16.0417 18.9415L14.2088 22.1164C13.7178 22.9838 13.8978 24.0802 14.6343 24.7512L16.0253 25.9786C15.9598 26.5023 15.9271 26.9605 15.9271 27.386C15.9271 27.8115 15.9598 28.2534 16.0253 28.7935L14.6343 30.0045C13.8815 30.6591 13.7014 31.7719 14.2088 32.6393L16.0417 35.8142C16.549 36.6816 17.5964 37.0743 18.5456 36.7634L20.2967 36.1742C21.0658 36.7634 21.8841 37.238 22.7187 37.598L23.0788 39.3982C23.2752 40.3801 24.1425 41.0838 25.1408 41.0838H28.7903C29.7886 41.0838 30.6559 40.3801 30.8523 39.3982L31.2124 37.598C32.047 37.238 32.8653 36.7634 33.6344 36.1742L35.3855 36.7634C36.3347 37.0743 37.3821 36.6816 37.8894 35.8142L39.7223 32.6393C40.2297 31.7719 40.0496 30.6591 39.2968 30.0045ZM31.9652 27.3697C31.9652 30.1354 29.7231 32.3775 26.9574 32.3775C24.1916 32.3775 21.9496 30.1354 21.9496 27.3697C21.9496 24.6039 24.1916 22.3619 26.9574 22.3619C29.7231 22.3619 31.9652 24.6039 31.9652 27.3697Z"
        fill={convertThemeColor(iconType)}
      />
    </svg>
  );
};

export default SettingIcon;
