import React, { useCallback, useEffect, useMemo, useState } from "react";
import css from "./PTSubTabGraph.module.less";
import TNudeTab from "../../../../components/TNudeTab/TNudeTab";
import PhysicalScoreGraph from "./PhysicalScoreGraph";
import ExerciseTimeGraph from "./ExerciseTimeGraph";
import TSimpleButtonTab, {
  LIST_TYPE,
} from "../../../../components/TSimpleButtonTab/TSimpleButtonTab";
import { $L, getWeekNumber } from "../../../../utils/helperMethods";
import { CONSTANTS_MONTH } from "../../../../utils/Constants";

const mockData = {
  // temp value
  balance: {
    quarterly: [
      { score: "93", time: "20" },
      { score: "78", time: "133" },
      { score: "73", time: "123" },
      { score: "81", time: "115" },
    ],
    monthly: [
      { score: "93", time: "20" },
      { score: "81", time: "31" },
      { score: "84", time: "59" },
      { score: "68", time: "43" },
    ],
    weekly: [
      { score: "94", time: "7" },
      { score: "78", time: "6" },
      { score: "80", time: "9" },
      { score: "92", time: "13" },
    ],
  },
  lowerFlexibility: {
    quarterly: [
      { score: "95", time: "18" },
      { score: "77", time: "135" },
      { score: "71", time: "121" },
      { score: "83", time: "113" },
    ],
    monthly: [
      { score: "94", time: "19" },
      { score: "82", time: "29" },
      { score: "83", time: "57" },
      { score: "67", time: "41" },
    ],
    weekly: [
      { score: "96", time: "5" },
      { score: "91", time: "11" },
      { score: "79", time: "4" },
      { score: "81", time: "7" },
    ],
  },
  upperStrength: {
    quarterly: [
      { score: "92", time: "22" },
      { score: "79", time: "131" },
      { score: "74", time: "125" },
      { score: "80", time: "119" },
    ],
    monthly: [
      { score: "91", time: "21" },
      { score: "80", time: "33" },
      { score: "85", time: "61" },
      { score: "69", time: "45" },
    ],
    weekly: [
      { score: "93", time: "9" },
      { score: "77", time: "8" },
      { score: "90", time: "15" },
      { score: "79", time: "11" },
    ],
  },
  lowerStrength: {
    quarterly: [
      { score: "94", time: "19" },
      { score: "77", time: "137" },
      { score: "72", time: "127" },
      { score: "82", time: "117" },
    ],
    monthly: [
      { score: "92", time: "18" },
      { score: "82", time: "28" },
      { score: "82", time: "56" },
      { score: "66", time: "40" },
    ],
    weekly: [
      { score: "91", time: "12" },
      { score: "95", time: "6" },
      { score: "78", time: "5" },
      { score: "80", time: "8" },
    ],
  },
  stepInPlace: {
    quarterly: [
      { score: "96", time: "16" },
      { score: "76", time: "139" },
      { score: "70", time: "129" },
      { score: "84", time: "121" },
    ],
    monthly: [
      { score: "90", time: "17" },
      { score: "83", time: "27" },
      { score: "80", time: "55" },
      { score: "65", time: "39" },
    ],
    weekly: [
      { score: "97", time: "4" },
      { score: "76", time: "3" },
      { score: "78", time: "6" },
      { score: "90", time: "10" },
    ],
  },
  upandgo: {
    quarterly: [
      { score: "91", time: "24" },
      { score: "80", time: "129" },
      { score: "75", time: "127" },
      { score: "79", time: "119" },
    ],
    monthly: [
      { score: "89", time: "23" },
      { score: "84", time: "35" },
      { score: "81", time: "63" },
      { score: "64", time: "47" },
    ],
    weekly: [
      { score: "92", time: "11" },
      { score: "75", time: "10" },
      { score: "89", time: "17" },
      { score: "77", time: "13" },
    ],
  },
};

const PTSubTabGraph = ({ testKey, selectedDate }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [periodTabIndex, setPeriodTabIndex] = useState(0);

  const convertData = useMemo(() => {
    const selectedData = mockData[testKey];

    const currentDate = new Date(selectedDate);
    // const currentDate = new Date("2023-11-20");
    const year = currentDate.getFullYear().toString().slice(2);
    const month = currentDate.getMonth() + 1;

    let ret = {
      quarterly: {},
      monthly: {},
      weekly: {},
    };

    // 분기 계산
    let countQuarter = Math.floor((month - 1) / 3) + 1;
    let currentYear = year;
    const quarterlyKeys = [];

    // 월간 계산
    let countMonth = month;
    const monthlyKeys = [];

    // 주간 계산
    let countDate = currentDate.getDate();
    const weeklyKeys = [];

    for (let i = 0; i < 4; i++) {
      // quarter
      if (countQuarter - 1 < 0) {
        currentYear -= 1;
        quarterlyKeys.unshift(`4Q ${currentYear}`);
        countQuarter = 3;
      } else {
        quarterlyKeys.unshift(`${countQuarter}Q ${currentYear}`);
        countQuarter -= 1;
      }

      // month
      if (countMonth - 1 < 0) {
        monthlyKeys.unshift(CONSTANTS_MONTH[CONSTANTS_MONTH.length - 1]);
        countMonth = CONSTANTS_MONTH.length - 1;
      } else {
        monthlyKeys.unshift(CONSTANTS_MONTH[countMonth - 1]);
        countMonth -= 1;
      }

      // week
      const calcDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        countDate
      );
      weeklyKeys.unshift(getWeekNumber(calcDate));
      countDate -= 7;
    }

    for (let i = 0; i < 4; i++) {
      ret.quarterly[quarterlyKeys[i]] = selectedData.quarterly[i];
      ret.monthly[monthlyKeys[i]] = selectedData.monthly[i];
      ret.weekly[weeklyKeys[i]] = selectedData.weekly[i];
    }

    return ret;
  }, [mockData, testKey, selectedDate]);

  const pickData = useMemo(() => {
    let period;
    switch (periodTabIndex) {
      case 0:
        period = "quarterly";
        break;
      case 1:
        period = "monthly";
        break;
      case 2:
        period = "weekly";
        break;
      default:
        break;
    }

    const label = Object.keys(convertData[period]);
    const values = Object.values(convertData[period]);

    let score = [];
    let time = [];
    values.map((item) => {
      score.push(item.score);
      time.push(item.time);
    });

    const convertLabel = [];
    label.map((item, index) => {
      let translateLabel;
      if (period === "quarterly") {
        const splitLabel = item.replace("Q", "").split(" ");

        translateLabel = $L("{quarter}Q {year}")
          .replace("{quarter}", splitLabel[0])
          .replace("{year}", splitLabel[1]);

        if (index !== 0 && label[index - 1].includes(splitLabel[1])) {
          translateLabel = $L("{quarter}Q").replace("{quarter}", splitLabel[0]);
        }

        convertLabel.push(translateLabel);
      } else if (period === "monthly") {
        const convertMonth = $L(label[index]);
        convertLabel.push(convertMonth);
      } else if (period === "weekly") {
        const splitLabel = item.replace("W", "").split(" ");
        const convertMonth = $L(splitLabel[0]);

        translateLabel = $L("{month} W{week}")
          .replace("{month}", convertMonth)
          .replace("{week}", splitLabel[1]);

        convertLabel.push(translateLabel);
      }
    });

    return { label: convertLabel, score, time, period };
  }, [convertData, periodTabIndex]);

  const onPeriodTabClick = useCallback(({ index, ...rest }) => {
    console.log("TSimpleButtonTabLayout onPeriodTabClick", index, rest);
    setPeriodTabIndex(index);
  }, []);

  useEffect(() => setPeriodTabIndex(0), [selectedTabIndex]);
  useEffect(() => {
    setSelectedTabIndex(0);
    setPeriodTabIndex(0);
  }, [testKey]);

  return (
    <div className={css.wrapper}>
      <TNudeTab
        className={css.nudeTabArea}
        labelArr={[$L("Split workout scores"), $L("Body part split workout time")]}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
      <div className={css.tabArea}>
        <TSimpleButtonTab
          listType={LIST_TYPE.smallReport}
          contents={[$L("Quarterly"), $L("Monthly"), $L("Weekly")]}
          onItemClick={onPeriodTabClick}
          selectedIndex={periodTabIndex}
        />
      </div>
      <div className={css.graphWrapper}>
        <div className={css.graph}>
          {selectedTabIndex === 0 ? (
            <PhysicalScoreGraph label={pickData.label} data={pickData.score} />
          ) : (
            <ExerciseTimeGraph
              label={pickData.label}
              data={pickData.time}
              period={pickData.period}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PTSubTabGraph;
