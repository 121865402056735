import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import css from "./CesOverallSummary.module.less";
import TButton, { SIZES } from "../TButton/TButton"
import TScroller from '../TScroller/TScroller';
import * as Config from "../../utils/Config";
import { addPanels } from "../../features/panels/panelsSlice";
import { $L, durationText } from '../../utils/helperMethods';
import DoubleRightArrow from "../../../assets/icon/double_right_arrow.svg"
import { getSummaryFeedback as getBAFeedback } from "../../views/BodyCheckUp/BodyAlignment/feedback/ConstantsFeedback";
import { getSummaryFeedback as getBBFeedback } from "../../views/BodyCheckUp/BodyBalance/BodyBalanceReport/feedback/feedbackBodyBalance";
import {
  SCORE_MINT_KEY,
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
} from "../../utils/Constants";
import { MENUTYPE } from "../../views/BodyCheckUp/BodyAlignment/BodyAlignmentReport/Constants";
import { changeCesFeedbackData } from "../../features/common/commonSlice";
import img_bodyBalance_front from "../../../assets/images/bodyBalanceSummary/summary_bodyBalance_front.jpg";
import img_bodyBalance_back from "../../../assets/images/bodyBalanceSummary/summary_bodyBalance_back.jpg";
import img_hiking_thumbnail from "../../../assets/images/bodyBalanceSummary/hiking_thumbnail.png";
import { TYPES } from "../../views/PreviewPanel/PreviewPanel";

const CesOverallSummary = ({ panelInfo, enterFrom, ...rest }) => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();
  const { tabInfos } = panelInfo;

  const feedback = useMemo(() => {
    if (enterFrom === "bodyAlignment_summary") {
      const summaryFeedback = getBAFeedback();

      const totalTabInfos = [];
      tabInfos?.LEFT.map((item) => totalTabInfos.push(item));
      tabInfos?.RIGHT.map((item) => totalTabInfos.push(item));

      const statusArr = {
        [SCORE_MINT_KEY]: [],
        [SCORE_YELLOW_KEY]: [],
        [SCORE_RED_KEY]: [],
      };

      console.log('totalTabInfos ', totalTabInfos);
      totalTabInfos.map((item) => {
        switch (item.status) {
          case SCORE_MINT_KEY:
            statusArr[SCORE_MINT_KEY].push(item);
            break;
          case SCORE_YELLOW_KEY:
            statusArr[SCORE_YELLOW_KEY].push(item);
            break;
          default:
            statusArr[SCORE_RED_KEY].push(item);
            break;
        }
      });

      let status = SCORE_MINT_KEY;
      if (statusArr[SCORE_RED_KEY].length > 0) {
        status = SCORE_RED_KEY;
      } else if (statusArr[SCORE_YELLOW_KEY].length > 0) {
        status = SCORE_YELLOW_KEY;
      } else {
        status = SCORE_MINT_KEY;
      }

      const feedbackTip = $L(
        "Misalignment in one area can effect the rest of your body. Strengthening one part can contribute to better overall alignment."
      );
      let feedbackArr = {};
      let tempArr = {};

      const dangerousBodyParts = [];
      statusArr[SCORE_RED_KEY].map((item) => dangerousBodyParts.push(item));
      statusArr[SCORE_YELLOW_KEY].map((item) => dangerousBodyParts.push(item));

      // Verify that LEFT and RIGHT have a status of SCORE_RED_KEY or SCORE_YELLOW_KEY
      const leftHasStatus = tabInfos?.LEFT.find(
        (item) =>
          item.status === SCORE_RED_KEY || item.status === SCORE_YELLOW_KEY
      );
      const rightHasStatus = tabInfos?.RIGHT.find(
        (item) =>
          item.status === SCORE_RED_KEY || item.status === SCORE_YELLOW_KEY
      );
      let target;
      if (status === SCORE_MINT_KEY) {
        // if dangerous part hasn't
        feedbackArr.mainFeedback = summaryFeedback.GOOD.mainFeedback;
        feedbackArr.image = summaryFeedback.GOOD.IMAGE;
        feedbackArr.exerciseFeedback = { ...summaryFeedback.GOOD };
        delete feedbackArr.exerciseFeedback.mainFeedback;
        delete feedbackArr.exerciseFeedback.IMAGE;
      } else {
        if (dangerousBodyParts.length === 1) {
          // if dangerous part has only one
          target = statusArr[status][0];
          tempArr = summaryFeedback.ONE_PART_WEAK[target.type];

          feedbackArr.mainFeedback =
            summaryFeedback.ONE_PART_WEAK.mainFeedback.replace(
              "{bodyPart}",
              target.replacePartName
            );
        } else if (leftHasStatus && rightHasStatus) {
          // if dangerous parts has both side
          dangerousBodyParts.sort((a, b) => {
            return a.sortIndex - b.sortIndex;
          });
          target = dangerousBodyParts.find((item) => item.status === status);
          tempArr = summaryFeedback.MULTY_WEAK[target.type];

          // get dangerousPart
          const dangerousPart = [];
          dangerousBodyParts.map((item) => {
            !dangerousPart.includes(item.replacePartName) &&
              dangerousPart.push(item.replacePartName);
          });
          const textToDangerousPart = dangerousPart.join(", ");
          feedbackArr.mainFeedback =
            summaryFeedback.MULTY_WEAK.mainFeedback.replace(
              "{bodyParts}",
              textToDangerousPart
            );
        } else {
          if (leftHasStatus || rightHasStatus) {
            dangerousBodyParts.sort((a, b) => {
              return a.sortIndex - b.sortIndex;
            });
            target = dangerousBodyParts.find((item) => item.status === status);

            let WEAK_KEY = "";
            if (leftHasStatus) {
              WEAK_KEY = "FRONT_WEAK";
            } else {
              WEAK_KEY = "SIDE_WEAK";
            }

            tempArr = summaryFeedback[WEAK_KEY][target.type];

            // get dangerousPart
            const dangerousPart = [];
            dangerousBodyParts.map((item) => {
              dangerousPart.push(item.replacePartName);
            });
            const textToDangerousPart = dangerousPart.join(", ");
            feedbackArr.mainFeedback = summaryFeedback[
              WEAK_KEY
            ].mainFeedback.replace("{bodyParts}", textToDangerousPart);
          } else {
            return null;
          }
        }

        if (target?.detailType === null) {
          feedbackArr.exerciseFeedback = { ...tempArr };
          feedbackArr.exerciseFeedback?.subFeedback &&
            delete feedbackArr.exerciseFeedback.subFeedback;
          feedbackArr.exerciseFeedback?.IMAGE &&
            delete feedbackArr.exerciseFeedback.IMAGE;
          feedbackArr.subFeedback = tempArr.subFeedback;

          // get position
          const positionKey = target.detailKey;
          let position;
          if (positionKey.includes("right")) {
            if (
              target.type === MENUTYPE.PELVIC_ROTATION ||
              target.type === MENUTYPE.NECK_TILT
            ) {
              position = "LEFT";
            } else {
              position = "RIGHT";
            }
          } else if (positionKey.includes("left")) {
            if (
              target.type === MENUTYPE.PELVIC_ROTATION ||
              target.type === MENUTYPE.NECK_TILT
            ) {
              position = "RIGHT";
            } else {
              position = "LEFT";
            }
          } else if (
            positionKey.includes("same") &&
            target.type === MENUTYPE.NECK_TILT &&
            target.type === MENUTYPE.PELVIC_TILT &&
            target.type === MENUTYPE.SHOULDER_TILT
          ) {
            position = "LEFT";
          } else {
            position = null;
          }

          if (position !== null) {
            feedbackArr.image = tempArr.IMAGE[position];
          }
        } else {
          feedbackArr.exerciseFeedback = { ...tempArr[target?.detailType] };
          feedbackArr.exerciseFeedback?.subFeedback &&
            delete feedbackArr.exerciseFeedback.subFeedback;
          feedbackArr.exerciseFeedback?.IMAGE &&
            delete feedbackArr.exerciseFeedback.IMAGE;

          feedbackArr.subFeedback = tempArr[target?.detailType]?.subFeedback;
          feedbackArr.image = tempArr[target?.detailType].IMAGE;
        }
      }

      let targetName;
      if (target) {
        targetName = target.replacePartName;
      } else {
        targetName = $L("Neck");
      }

      const feedbackStatus = [];
      feedbackStatus.push(feedbackArr.mainFeedback);
      feedbackArr.subFeedback && feedbackStatus.push(feedbackArr.subFeedback);

      return {
        feedbackStatus,
        feedbackTip,
        exerciseFeedback: feedbackArr.exerciseFeedback,
        image: feedbackArr.image,
        centerTitle: $L("{targetName} Muscles for Workout").replace(
          "{targetName}",
          targetName
        ),
        status,
      };
    } else if (enterFrom === "bodyBalance_summary") {
      const feedbackTip = $L(
        "Altered foot can cause other parts of the body to be misaligned in the following order: Pelvis→body→shoulders. We recommend massaging your feet and calves regularly, or doing leg exercises to build more muscles."
      );

      const feedbackStatus = [getBBFeedback(tabInfos)];

      return {
        feedbackStatus,
        feedbackTip,
        exerciseFeedback: {
          EXERCISE_NAME: $L("Hiking"),
          EXERCISE_EFFECT: $L(
            "Strengthens the lower body and improves balance"
          ),
          HOW_TO_EXERCISE: $L(
            "Walking in place by lifting your knees up more than 90°"
          ),
          EXERCISE_TIME: 34,
          VIDEO_THUMBNAIL: img_hiking_thumbnail,
        },
        image: [img_bodyBalance_front, img_bodyBalance_back],
        centerTitle: $L("Muscles for Exercise"),
        status: SCORE_MINT_KEY,
      };
    } else {
      return {};
    }
  }, [tabInfos, enterFrom]);

  const workoutInfoList = useMemo(() => {
    let ret = [];
    if (enterFrom === "bodyAlignment_summary") {
      ret = [
        {
          num: "1",
          title: $L("Workout Name"),
          info: feedback.exerciseFeedback.EXERCISE_NAME,
          type: "string",
        },
        {
          num: "2",
          title: $L("Associated Muscles"),
          info: feedback.exerciseFeedback.ASSOCIATED_MUSCLES,
          type: "string",
        },
        {
          num: "3",
          title: $L("Workout Time"),
          info: feedback.exerciseFeedback.EXERCISE_TIME,
          type: "time",
        },
      ];
    } else if (enterFrom === "bodyBalance_summary") {
      ret = [
        {
          num: "1",
          title: $L("Exercise Name"),
          info: feedback.exerciseFeedback.EXERCISE_NAME,
          type: "string",
        },
        {
          num: "2",
          title: $L("Exercise Effect"),
          info: feedback.exerciseFeedback.EXERCISE_EFFECT,
          type: "string",
        },
        {
          num: "3",
          title: $L("How to Exercise"),
          info: feedback.exerciseFeedback.HOW_TO_EXERCISE,
          type: "string",
        },
        {
          num: "4",
          title: $L("Exercise Time"),
          info: feedback.exerciseFeedback.EXERCISE_TIME,
          type: "time",
        },
      ];
    }

    return ret;
  }, [feedback, enterFrom]);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  const goToPlayer = useCallback(() => {
    dispatch(changeCesFeedbackData(feedback.exerciseFeedback));
    if (enterFrom === 'bodyAlignment_summary') {
      dispatch(addPanels({
        name: Config.panel_names.PREVIEW_PANEL,
        panelInfo: { type: TYPES.BODY_ALIGNMENT },
      }));
      dispatch(addPanels({ name: Config.panel_names.SELECT_WORKOUT, panelInfo: { enterFrom: enterFrom, bodyPart: feedback.exerciseFeedback.VIDEO_INDEX } }));
    } else if (enterFrom === 'bodyBalance_summary') {
      dispatch(addPanels({
        name: Config.panel_names.PREVIEW_PANEL,
        panelInfo: { type: TYPES.BODY_BALANCE },
      }));
      dispatch(addPanels({ name: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER, panelInfo: { type: 'activity', subType: 'hiking', contentId: 10, init: true, preview: true, enterFrom: enterFrom } }));
    }
  }, [dispatch, feedback, enterFrom]);

  const containers = ['labelContainerL', 'labelContainerR'];
  useEffect(()=>{
    if (scrollTo && scrollTo.current) {
      scrollTo.current({position: {y: 0}, animate: false});
    }
  }, []);

  return(
    <div className={css.summarySect}>
      <div className={css.summaryFlex}>
        <div className={css.statusWrap}>
          <div className={classNames(css.statusBox, css.innerBox)}>
            <div className={css.title}>{$L("Status")}</div>
            <TScroller className={css.tScroller} cbScrollTo={getScrollTo} >
              <div className={css.info}>
                {feedback.feedbackStatus.map((item, index) => (
                  <div key={`infoText_${index}`} className={css.infoText}>
                    {item}
                  </div>
                ))}
              </div>
            </TScroller>
          </div>
          <div className={classNames(css.statusBox, css.innerBox)}>
            <div className={css.title}>{$L("TIP")}</div>
            <div className={css.info}>{feedback.feedbackTip}</div>
          </div>
        </div>
        <div className={classNames(css.muscleWrap, css.innerBox)}>
          <div className={css.title}>{feedback.centerTitle}</div>
          <div className={css.mirrorMode}>{$L("mirror mode")}</div>
          <div className={css.muscleImgWrap}>
            {feedback.image?.map((item, index) => (
              <img key={`image_${index}`} src={item} alt={`image_${index}`} />
            ))}
          </div>
          {containers.map((item, index) => (
            <div key={`lr_${index}`} className={css[item]}>
              <div className={classNames(css.label)}>{$L("L")}</div>
              <div className={classNames(css.label, css.isRight)}>{$L("R")}</div>
            </div>
          ))}
          <div className={css.positionFB}>
						<div className={classNames(css.text)}>{$L("Front")}</div>
						<div className={classNames(css.text)}>{$L("Back")}</div>
					</div>
          <div className={css.muscleInfoWrap}>
          {enterFrom === 'bodyBalance_summary' ? (
              <div className={css.markerGreen}>{$L("stabilizing muscle")}</div>
            ) : (
              <>
                <div>{$L("shortness")}</div>
                <div>{$L("elongation")}</div>
              </>
            )}
          </div>
        </div>
        <img className={css.doubleRightArrow} src={DoubleRightArrow} alt="" />
        <div className={classNames(css.recommendWrap, css.innerBox)}>
          <div className={css.recommendInner}>
            <div className={css.title}>
              {enterFrom === "bodyAlignment_summary"
                ? $L("Today's Workout Recommendation")
                : $L("Today's Exercise Recommendation")}
            </div>
            <div className={css.imgBox}>
              <img src={feedback.exerciseFeedback.VIDEO_THUMBNAIL} alt="" />
            </div>
            <div
              className={classNames(
                css.infoBox,
                enterFrom === "bodyBalance_summary" && css.smallMargin
              )}
            >
              {workoutInfoList.map((info, idx) => (
                <div key={`cesOverallSummary-${idx}`} className={css.info}>
                  <span className={css.dotPoint} />
                  <div>
                    {`${info.title}` +
                      " : " +
                      `${
                        info.type === "time"
                          ? durationText(info.info)
                          : info.info
                      }`}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={css.testBtnWrap}>
            <TButton
              size={SIZES.test}
              spotlightId="cesWorkout"
              onClick={goToPlayer}
            >
              {$L("Workout")}
            </TButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CesOverallSummary;