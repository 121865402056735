import css from './SubscriptionPassPanel.module.less';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { $L } from '../../../utils/helperMethods';
import { addPanels } from "../../../features/panels/panelsSlice";
import * as Config from "../../../utils/Config";
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader';
import TBody from '../../../components/TBody/TBody';
import TButton from '../../../components/TButton/TButton';
import TScroller from '../../../components/TScroller/TScroller';
import classNames from 'classnames';

const columns = [
  {
    id:1,
    title: $L("Product name")
  },
  {
    id: 2,
    title: $L("Status")
  },
  {
    id: 3,
    title: $L("Amount Paid"),
  },
  {
    id: 4,
    title: $L("Payment Date"),
  },
];

const table = [
  {
    id: 1,
    title: '프리미엄 12개월',
    usagePeriod: '2023.07.11~2023.08.11',
    date: 'ROM',
    state: "변경/해지",
    price: 9900,
    paymentDate: '2023년 7월 11일',
  },
  {
    id: 2,
    title: '프리미엄 12개월',
    usagePeriod: '2023.07.11~2023.08.11',
    date: 'ROM',
    state: "변경/해지",
    price: 9900,
    paymentDate: '2023년 7월 11일',
  },
  {
    id: 3,
    title: '프리미엄 12개월',
    usagePeriod: '2023.07.11~2023.08.11',
    date: 'ROM',
    state: "변경/해지",
    price: 9900,
    paymentDate: '2023년 7월 11일',
  },
  {
    id: 4,
    title: '프리미엄 12개월',
    usagePeriod: '2023.07.11~2023.08.11',
    date: 'ROM',
    state: "변경/해지",
    price: 9900,
    paymentDate: '2023년 7월 11일',
  },

];

const SubscriptionPassPanel = ({...rest}) => {
  const dispatch = useDispatch()
  const { profileNick } = useSelector((state) => state.common.appStatus);

  const showOtherSubscriptions = useCallback(() => {
    console.log("showOtherSubscription")
    // dispatch(addPanels({ name:Config.panel_names.SUBSCRIPTION_PASS_PANEL }));
  }, [dispatch]);

  const subscriptionTitle = useMemo(()=>{
    return $L(`<span>${profileNick}</span>님은 총 <span>${table.length}건</span>을 구독하고 있어요.`)
  }, [profileNick, table.length]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Subscription vouchers")} />
      <TBody className={css.body}>
        <div className={css.titleSection}>
          <div className={css.title} dangerouslySetInnerHTML={{ __html: subscriptionTitle }}/>
          <TButton onClick={showOtherSubscriptions} size="small">
            {$L("See other plans")}
          </TButton>
        </div>
        <table className={css.table}>
          <tbody>
            <tr>
              {columns && columns.length > 0 && columns.map((col, index) => (
                <th className={css.th} key={`column-${index}`}>{col.title}</th>
              ))}
            </tr>
              {table && table.length > 0 && table.map((row, index) => (
                <tr key={`row-${index}`}>
                  <td className={css.td}>
                    <div className={css.product}>
                      <div className={css.title}>{row.title}</div>
                      <div>{row.usagePeriod}</div>
                    </div>
                  </td>
                  <td className={css.td}>{row.state}</td>
                  <td className={css.td}>{`${row.price}`}</td>
                  <td className={css.td}>{row.paymentDate}</td>
                </tr>
              ))}
          </tbody>
        </table>

      </TBody>

    </TPanel>
  );
};

export default SubscriptionPassPanel;