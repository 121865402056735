import {
  MUSCLE_DETAIL_IMAGE,
  MUSCLE_SILHOUETTE_IMAGE,
} from "../../../../../assets/images/bodyAlignmentDetail/muscle";
import {
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
  SCORE_MINT_KEY,
  BODY_SCAN_SCORE,
} from "../../../../utils/Constants";
import { $L } from "../../../../utils/helperMethods";
import {
  MENUTYPE,
  MENUTYPE_TEST,
  getEmptyResultsReportData,
} from "../BodyAlignmentReport/Constants";
import {
  FEEDBACK_TYPE,
  getFeedbackGuide,
  getViewDetailsFeedbackGuide,
  getViewDetailFeedbackMuscleGuide,
  getCesShowOVerallGuide,
} from "./ConstantsFeedback";

export const getCesShowModeOverall = () => {
  return {
    [SCORE_MINT_KEY]: {
      feedback: $L("You are in a very good physical condition."),
    },
    [SCORE_YELLOW_KEY]: {
      feedback:
        "{partsArr} {verb} inappropriate to require consistent management and improvement of lifestyle. {guideFeedback}",
    },
    [SCORE_RED_KEY]: {
      feedback:
        "{partsArr} {verb} inappropriate to require consistent management and improvement of lifestyle. {guideFeedback}",
    },
  };
};

export const getFeedbackBodyAlignment = () => {
  return {
    [MENUTYPE.OVERALL]: {
      [SCORE_MINT_KEY]: {
        title: $L("고객님의 체형 상태는 매우 양호한 편입니다."),
        subtitle: $L(
          "고객님의 체형 상태는 매우 양호한 편입니다. {overallFeedback}"
        ),
        feedback: $L("고객님의 체형 상태는 매우 양호한 편입니다."),
      },
      [SCORE_YELLOW_KEY]: {
        title: $L(
          "To restore the mobility of {partsArr}, proper exercise is required."
        ),
        subtitle: $L(
          "{dangerousPart}가 가장 관리가 필요해 보이네요~! {overallFeedback} {dangerousPart}를 클릭해 자세한 결과를 알아볼까요?"
        ),
        feedback:
          $L(
            "To restore the mobility of {partsArr}, proper exercise is required."
          ) + " {guideFeedback}",
        guideFeedback: $L("{muscleManagementGuide} {conditionGuide}"),
      },
      [SCORE_RED_KEY]: {
        title: $L(
          "To restore the mobility of {partsArr}, proper exercise is required."
        ),
        subtitle: $L(
          "{dangerousPart}가 가장 관리가 필요해 보이네요~! {overallFeedback} {dangerousPart}를 클릭해 자세한 결과를 알아볼까요?"
        ),
        feedback:
          $L(
            "To restore the mobility of {partsArr}, proper exercise is required."
          ) + " {guideFeedback}",
        guideFeedback: $L("{muscleManagementGuide} {conditionGuide}"),
      },
    },
  };
};

export const voiceGuideEmpty = () => {
  const overallData = getEmptyResultsReportData(MENUTYPE.OVERALL);
  const feedback = overallData.title + " " + overallData.detail;

  return { feedback };
};

export const voiceGuideBodyAlignment = (
  selectedTabIndex,
  tabInfos,
  cesShowMode
) => {
  if (tabInfos) {
    let res = {
      [SCORE_MINT_KEY]: [],
      [SCORE_YELLOW_KEY]: [],
      [SCORE_RED_KEY]: [],
    };

    let status = SCORE_MINT_KEY;

    let tabType = "";
    if (selectedTabIndex === 0) {
      tabType = MENUTYPE.OVERALL;
      tabInfos.map((item) => {
        switch (item.score) {
          case BODY_SCAN_SCORE[SCORE_MINT_KEY]:
            res[SCORE_MINT_KEY].push(item);
            break;
          case BODY_SCAN_SCORE[SCORE_YELLOW_KEY]:
            res[SCORE_YELLOW_KEY].push(item);
            break;
          case BODY_SCAN_SCORE[SCORE_RED_KEY]:
            res[SCORE_RED_KEY].push(item);
            break;
          default:
            break;
        }
      });

      if (res[SCORE_RED_KEY].length > 0) {
        status = SCORE_RED_KEY;
      } else if (res[SCORE_YELLOW_KEY].length > 0) {
        status = SCORE_YELLOW_KEY;
      } else {
        status = SCORE_MINT_KEY;
      }

      if (cesShowMode) {
        const feedback = getCesShowModeOverall()[status];
        const convertToFeedback = {
          feedback: "",
        };

        if (status === SCORE_MINT_KEY) {
          return { feedback: feedback.feedback };
        } else {
          const partsArr = [];
          const guideFeedbackArr = [];
          
          res[status].map((item) => {
            const elToText = item.title.replace("{br}", " ");
            partsArr.push(elToText);

            const guideFeedback = getFeedback(item, "CES_SHOW_MODE");
            guideFeedbackArr.push(guideFeedback);
          });
          const partsArrToText = partsArr.join(", ");
          const guideFeedbackArrToText = guideFeedbackArr.join(" ");

          const verb = partsArr.length > 1 ? "are" : "is";

          convertToFeedback.feedback = $L(
             feedback.feedback
              .replace("{partsArr}", partsArrToText)
              .replace("{guideFeedback}", guideFeedbackArrToText)
              .replace("{verb}", verb)
          );

          return convertToFeedback;
        }
      } else {
        const feedbackBodyAlignment = getFeedbackBodyAlignment();
        const feedback = feedbackBodyAlignment[MENUTYPE.OVERALL][status];
        const convertToFeedback = {
          title: "",
          subtitle: "",
          feedback: "",
        };

      if (status === SCORE_MINT_KEY) {
        const arrLength = res[status].length;
        const randomIndex = Math.floor(Math.random() * arrLength);
        const randomPart = res[status][randomIndex];
        const getSubtitle = getFeedback(
          randomPart,
          FEEDBACK_TYPE.OVERALL_FEEDBACK
        );

        const subtitle = feedback.subtitle.replace(
          "{overallFeedback}",
          getSubtitle
        );
        return {
          title: feedback.title,
          subtitle: subtitle,
          feedback: feedback.feedback,
        };
      } else {
        const partsArr = [];
        const guideFeedbackArr = [];
        res[status].map((item) => {
          const elToText = item.title.replace("{br}", " ");
          partsArr.push(elToText);

          const muscleManagementFeedback = getFeedback(
            item,
            FEEDBACK_TYPE.OVERALL_MUSCLE_MANAGEMENT
          );
          const conditionFeedback = getFeedback(item, FEEDBACK_TYPE.CONDITION);
          const guideFeedback =
            muscleManagementFeedback + " " + conditionFeedback;
          guideFeedbackArr.push(guideFeedback);
        });
        const partsArrToText = partsArr.join(", ");
        convertToFeedback.title = $L(
          feedback.title.replace("{partsArr}", partsArrToText)
        );

        const dangerousPart = partsArr[0]; // Take the first item
        const dangerousPartObj = res[status][0];

        const overallFeedback = getFeedback(
          dangerousPartObj,
          FEEDBACK_TYPE.OVERALL_FEEDBACK
        );

        const guideFeedbackArrToText = guideFeedbackArr.join(" ");

        convertToFeedback.subtitle = $L(
          feedback.subtitle
            .replaceAll("{dangerousPart}", dangerousPart)
            .replace("{overallFeedback}", overallFeedback)
        );

        convertToFeedback.feedback = $L(
          feedback.feedback
            .replace("{partsArr}", partsArrToText)
            .replace("{guideFeedback}", guideFeedbackArrToText)
        );

        return convertToFeedback;
        }
      }
    } else {
      const tabInfo = tabInfos[selectedTabIndex];
      tabType = tabInfo.type;

      let status;
      let feedback = getFeedback(tabInfo, FEEDBACK_TYPE.SPECIFY_STATUS);
      if (tabInfo.score === BODY_SCAN_SCORE[SCORE_MINT_KEY]) {
        status = SCORE_MINT_KEY;
      } else if (tabInfo.score === BODY_SCAN_SCORE[SCORE_YELLOW_KEY]) {
        status = SCORE_YELLOW_KEY;
        feedback += " " + getFeedback(tabInfo, FEEDBACK_TYPE.MUSCLE_MANAGEMENT);
      } else if (tabInfo.score === BODY_SCAN_SCORE[SCORE_RED_KEY]) {
        status = SCORE_RED_KEY;
        feedback += " " + getFeedback(tabInfo, FEEDBACK_TYPE.MUSCLE_MANAGEMENT);
        feedback += " " + getFeedback(tabInfo, FEEDBACK_TYPE.CONDITION);
      }

      return { feedback };
    }
  }
};

const getFeedback = (obj, feedbackType) => {
  const feedbackGuide = getFeedbackGuide();
  if (obj) {
    const objType = obj?.type;

    let detailType;
    if (objType === MENUTYPE.LEG_ALIGNMENT) {
      if (obj?.detailType === "out") {
        detailType = MENUTYPE_TEST.BOW_LEG;
      } else {
        detailType = MENUTYPE_TEST.KNOCK_KNEE;
      }
    } else if (
      objType === MENUTYPE.PELVIC_SHIFT ||
      objType === MENUTYPE.KNEE_FLEXION ||
      objType === MENUTYPE.HEAD_SHIFT ||
      objType === MENUTYPE.TRUNK_SHIFT
    ) {
      if (obj?.detailType === "front") {
        detailType = MENUTYPE_TEST.ANTERIOR;
      } else {
        detailType = MENUTYPE_TEST.POSTERIOR;
      }
    } else {
      detailType = null;
    }

    let feedback;

    if (feedbackType === "CES_SHOW_MODE") {
      const cesGuide = getCesShowOVerallGuide();

      if (detailType === null) {
        const randomIndex = Math.floor(
          Math.random() * cesGuide[objType].length
        );
        feedback = cesGuide[objType][randomIndex];
      } else {
        const randomIndex = Math.floor(
          Math.random() * cesGuide[objType][detailType].length
        );
        feedback = cesGuide[objType][detailType][randomIndex];
      }
    } else if (
      feedbackType === FEEDBACK_TYPE.SPECIFY_STATUS ||
      feedbackType === FEEDBACK_TYPE.OVERALL_FEEDBACK
    ) {
      let status;
      switch (obj?.score) {
        case BODY_SCAN_SCORE[SCORE_MINT_KEY]:
          status = SCORE_MINT_KEY;
          break;
        case BODY_SCAN_SCORE[SCORE_YELLOW_KEY]:
          status = SCORE_YELLOW_KEY;
          break;
        case BODY_SCAN_SCORE[SCORE_RED_KEY]:
          status = SCORE_RED_KEY;
          break;
        default:
          break;
      }

      if (detailType === null) {
        const randomIndex = Math.floor(
          Math.random() * feedbackGuide[objType][feedbackType][status].length
        );
        feedback = feedbackGuide[objType][feedbackType][status][randomIndex];
      } else {
        const randomIndex = Math.floor(
          Math.random() *
            feedbackGuide[objType][feedbackType][detailType][status].length
        );
        feedback =
          feedbackGuide[objType][feedbackType][detailType][status][randomIndex];
      }
    } else {
      if (detailType === null) {
        const randomIndex = Math.floor(
          Math.random() * feedbackGuide[objType][feedbackType].length
        );
        feedback = feedbackGuide[objType][feedbackType][randomIndex];
      } else {
        const randomIndex = Math.floor(
          Math.random() *
            feedbackGuide[objType][feedbackType][detailType].length
        );
        feedback =
          feedbackGuide[objType][feedbackType][detailType][randomIndex];
      }
    }

    return feedback;
  } else {
    return null;
  }
};

export const getViewDetailsFeedback = (type, detailKey, cesShowMode) => {
  const feedbackArr = getViewDetailsFeedbackGuide();

  let detailType;
  if (type === MENUTYPE.LEG_ALIGNMENT) {
    if (detailKey === "out") {
      detailType = MENUTYPE_TEST.BOW_LEG;
    } else {
      detailType = MENUTYPE_TEST.KNOCK_KNEE;
    }
  } else if (
    type === MENUTYPE.PELVIC_SHIFT ||
    type === MENUTYPE.KNEE_FLEXION ||
    type === MENUTYPE.HEAD_SHIFT ||
    type === MENUTYPE.TRUNK_SHIFT
  ) {
    if (detailKey === "front") {
      detailType = MENUTYPE_TEST.ANTERIOR;
    } else {
      detailType = MENUTYPE_TEST.POSTERIOR;
    }
  } else {
    detailType = null;
  }

  let feedback;
  if (detailType === null) {
    feedback = feedbackArr[type];
  } else {
    feedback = feedbackArr[type][detailType];
  }

  let lifeStyleFeedback;
  if (cesShowMode) {
    lifeStyleFeedback = getFeedbackCount(
      feedback[FEEDBACK_TYPE.CES_LIFE_STYLE],
      3
    );
  } else {
    lifeStyleFeedback = getFeedbackCount(feedback[FEEDBACK_TYPE.LIFE_STYLE], 3);
  }

  return { ...feedback, [FEEDBACK_TYPE.LIFE_STYLE]: lifeStyleFeedback };
};

export const getViewDetailsFeedbackMuscle = (type, detailKey) => {
  const feedbackArr = getViewDetailFeedbackMuscleGuide()[type];

  const bodyOrientation = (key) => {
    const orientation = {
      front: {
        key: "FRONT",
        text: $L({ key: "_position_front", value: "Front" }),
      },
      back: {
        key: "BACK",
        text: $L({ key: "_position_back", value: "Back" }),
      },
      left: { key: "LEFT", text: $L({ key: "_position_left", value: "Left" }) },
      right: {
        key: "RIGHT",
        text: $L({ key: "_position_right", value: "Right" }),
      },
    };

    if (key.includes("front")) {
      return orientation.front;
    } else if (key.includes("back")) {
      return orientation.back;
    } else if (key.includes("right")) {
      if (type === MENUTYPE.PELVIC_ROTATION || type === MENUTYPE.NECK_TILT) {
        return orientation.left;
      } else {
        return orientation.right;
      }
    } else if (key.includes("left")) {
      if (type === MENUTYPE.PELVIC_ROTATION || type === MENUTYPE.NECK_TILT) {
        return orientation.right;
      } else {
        return orientation.left;
      }
    } else if (key.includes("same")) {
      return orientation.left;
    } else {
      return { key: null, text: null };
    }
  };
  const positionKey = bodyOrientation(detailKey).key;
  const position = bodyOrientation(detailKey).text;
  const bodyPart = feedbackArr.part;

  let detailType;
  let tightObj, elongatedObj, stabilizingObj;
  let tightMuscleText, elongatedMuscleText, stabilizingMuscleText;
  let tightImg, elongatedImg, stabilizingImg;
  if (type === MENUTYPE.LEG_ALIGNMENT) {
    if (detailKey === "out") {
      detailType = MENUTYPE_TEST.BOW_LEG;
    } else {
      detailType = MENUTYPE_TEST.KNOCK_KNEE;
    }
  } else if (
    type === MENUTYPE.PELVIC_SHIFT ||
    type === MENUTYPE.KNEE_FLEXION ||
    type === MENUTYPE.HEAD_SHIFT ||
    type === MENUTYPE.TRUNK_SHIFT
  ) {
    if (detailKey === "front") {
      detailType = MENUTYPE_TEST.ANTERIOR;
    } else {
      detailType = MENUTYPE_TEST.POSTERIOR;
    }
  } else {
    detailType = null;
  }
  const imageArr = MUSCLE_DETAIL_IMAGE[type];
  if (detailType !== null) {
    tightObj = feedbackArr[detailType][FEEDBACK_TYPE.TIGHT];
    elongatedObj = feedbackArr[detailType][FEEDBACK_TYPE.ELONGATED];
    stabilizingObj = feedbackArr[detailType][FEEDBACK_TYPE.STABILIZING];

    tightMuscleText = tightObj.muscleArr.join(", ");
    elongatedMuscleText = elongatedObj.muscleArr.join(", ");
    stabilizingMuscleText = stabilizingObj.muscleArr.join(", ");

    tightImg = imageArr[detailType][FEEDBACK_TYPE.TIGHT];
    elongatedImg = imageArr[detailType][FEEDBACK_TYPE.ELONGATED];
    stabilizingImg = imageArr[detailType][FEEDBACK_TYPE.STABILIZING];
  } else {
    tightImg = imageArr[FEEDBACK_TYPE.TIGHT][positionKey];
    elongatedImg = imageArr[FEEDBACK_TYPE.ELONGATED][positionKey];
    stabilizingImg = imageArr[FEEDBACK_TYPE.STABILIZING][positionKey];

    tightObj = feedbackArr[FEEDBACK_TYPE.TIGHT];
    elongatedObj = feedbackArr[FEEDBACK_TYPE.ELONGATED];
    stabilizingObj = feedbackArr[FEEDBACK_TYPE.STABILIZING];

    if (type === MENUTYPE.PELVIC_ROTATION) {
      tightMuscleText = tightObj.muscleArr[detailKey].join(", ");
      elongatedMuscleText = elongatedObj.muscleArr[detailKey].join(", ");
    } else {
      tightMuscleText = tightObj.muscleArr.join(", ");
      elongatedMuscleText = elongatedObj.muscleArr.join(", ");
    }
    stabilizingMuscleText = stabilizingObj.muscleArr.join(", ");
  }

  const tightFeedback = tightObj.feedback
    .replace("{part}", bodyPart)
    .replace("{position}", position)
    .replace("{muscleArr}", tightMuscleText);
  const elongatedFeedback = elongatedObj.feedback
    .replace("{part}", bodyPart)
    .replace("{position}", position)
    .replace("{muscleArr}", elongatedMuscleText);
  const stabilizingFeedback = stabilizingObj.feedback
    .replace("{part}", bodyPart)
    .replace("{muscleArr}", stabilizingMuscleText);

  const tight = {
    title: $L("Tight Muscles"),
    subtitle: tightFeedback,
    image: tightImg,
  };
  const elongated = {
    title: $L("Elongated Muscles"),
    subtitle: elongatedFeedback,
    image: elongatedImg,
  };
  const stabilizing = {
    title: $L("Stabilizing Muscles"),
    subtitle: stabilizingFeedback,
    image: stabilizingImg,
  };

  const feedback = [];

  feedback.push(tight);
  feedback.push(elongated);
  feedback.push(stabilizing);

  return feedback;
};

export const getViewDetailsFeedbackSilhouette = (type, detailKey, score) => {
  const grade = score === SCORE_MINT_KEY ? SCORE_MINT_KEY : SCORE_RED_KEY;
  const bodyOrientation = (key) => {
    const orientation = {
      front: {
        key: "FRONT",
      },
      back: {
        key: "BACK",
      },
      left: {
        key: "LEFT",
      },
      right: {
        key: "RIGHT",
      },
    };

    if (key.includes("front")) {
      return orientation.front;
    } else if (key.includes("back")) {
      return orientation.back;
    } else if (key.includes("right")) {
      if (type === MENUTYPE.PELVIC_ROTATION || type === MENUTYPE.NECK_TILT) {
        return orientation.left;
      } else {
        return orientation.right;
      }
    } else if (key.includes("left")) {
      if (type === MENUTYPE.PELVIC_ROTATION || type === MENUTYPE.NECK_TILT) {
        return orientation.right;
      } else {
        return orientation.left;
      }
    } else if (key.includes("same")) {
      return orientation.left;
    } else {
      return { key: null };
    }
  };
  const positionKey = bodyOrientation(detailKey).key;

  let detailType;
  let feedbackImg;
  if (type === MENUTYPE.LEG_ALIGNMENT) {
    if (detailKey === "out") {
      detailType = MENUTYPE_TEST.BOW_LEG;
    } else {
      detailType = MENUTYPE_TEST.KNOCK_KNEE;
    }
  } else if (
    type === MENUTYPE.PELVIC_SHIFT ||
    type === MENUTYPE.KNEE_FLEXION ||
    type === MENUTYPE.HEAD_SHIFT ||
    type === MENUTYPE.TRUNK_SHIFT
  ) {
    if (detailKey === "front") {
      detailType = MENUTYPE_TEST.ANTERIOR;
    } else {
      detailType = MENUTYPE_TEST.POSTERIOR;
    }
  } else {
    detailType = null;
  }
  const imageArr = MUSCLE_SILHOUETTE_IMAGE[type];
  if (detailType !== null) {
    feedbackImg = imageArr[detailType][grade];
  } else {
    feedbackImg = imageArr[grade][positionKey];
  }

  return feedbackImg;
};

const getFeedbackCount = (arr, getCount) => {
  const newArr = [];
  let count = 0;
  while (count < Math.min(getCount, arr.length)) {
    const randomIndex = Math.floor(Math.random() * arr.length);

    if (!newArr.includes(arr[randomIndex])) {
      newArr.push(arr[randomIndex]);
      count++;
    }
  }
  return newArr;
};
