import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Config from "../../../utils/Config";
import { $L } from '../../../utils/helperMethods';
import css from './IHOAWJMain.module.less';
import { addPanels } from '../../../features/panels/panelsSlice';
import IHOAWJActivityToday from './IHOAWJActivityToday/IHOAWJActivityToday';
import RecommendedCourse from './RecommendedCourse/RecommendedCourse';
import TPanel from '../../../components/TPanel';
import THeader from "../../../components/THeader";
import TBody from '../../../components/TBody';
import TDropdown from "../../../components/TDropDown/TDropDown";
import useContent from '../../../hooks/useContent';
import { getContent } from '../../../features/fitService/fitServiceSlice';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import TVerticalPagenator from '../../../components/TVerticalPagenator/TVerticalPagenator';
import classNames from 'classnames';
import THorizontalScrollList, { ITEM_SIZE } from '../../../components/THorizontalScrollList/THorizontalScrollList';
import SMediaItem from '../../../components/SMediaList/SMediaItem';

const CONTENT_TYPE = { type: 'activity', subType: 'walking' };

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);

const IHOAWJMain = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
	const [selectedId, setSelectedId] = useState(null);
  const { contentList, themeList, likeList, continueList, recommendList, getContentInfo } = useContent(CONTENT_TYPE);
  const [pageIndex, setPageIndex] = useState(0);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const contentInfos = useSelector(state => state.content.contentInfos);

	const onSelect = useCallback(({selected}) => {
    setSelectedId(selected);
	}, [selectedId]);

  const onItemClick = useCallback(({ itemInfo, init }) => {
    if (itemInfo) {
      dispatch(getContent({ contentId: itemInfo.contentId })); // focus? click?
    }

    const panelInfo = {
      type: itemInfo.type || CONTENT_TYPE.type,
      subType: itemInfo.subType || CONTENT_TYPE.subType,
      contentId: itemInfo.contentId,
      init
    }
    dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo }));
  }, []);

  // todo change themeList
  const CONTENT_THEME_LIST_BASE = useMemo(() => {
    const arr = [{ title: "", alias: $L("Overall"), themeId: "" }];

    for (let i = 0; i < themeList.length; i++) {
      arr.push(Object.assign({}, themeList[i], { alias: themeList[i].title.slice(0, 2) }));
    }

    if (likeList.length > 0) {
      arr.push({ title: $L("Walking/Jogging course you kept"), alias: $L("Keep"), themeId: 'like' });
    }

    return arr;
  }, [themeList, likeList]);

  const CONTENT_THEME_LIST = useMemo(() => {
    const arr = [];

    for (let i = 1; i < CONTENT_THEME_LIST_BASE.length; i++) {
      if ([0, null, i].includes(selectedId)) {
        const theme = CONTENT_THEME_LIST_BASE[i];

        if (theme.themeId === 'like') {
          arr.push(Object.assign({}, theme, { items: likeList }));
        } else if (contentList[theme.themeId] && contentList[theme.themeId].length > 0) {
          arr.push(Object.assign({}, theme, { items: contentList[theme.themeId] }));
        }
      }
    }

    return arr;
  }, [CONTENT_THEME_LIST_BASE, likeList, contentList, selectedId]);

  const recommendedCourseList = useMemo(() => {
    if (cesShowMode) {
      const arr = [];
      const contentIds = [1, 8, 9, 10];

      for (let i = 0; i < recommendList.length; i++) {
        if (contentIds.includes(recommendList[i].contentId)) {
          arr.push(recommendList[i]);
        }
      }
      if (contentInfos[10]) {
        arr.push(getContentInfo(contentInfos[10]))
      }

      return arr;
    }

    if (continueList.length > 0) {
      return continueList;
    } else if (recommendList.length > 0) {
      return recommendList;
    }

    return [];
  }, [continueList, recommendList, cesShowMode, contentInfos]);

  const onPageChanged = useCallback((pageIndex) => {
    setPageIndex(pageIndex);
  }, []);

  const pageSpotIds = useMemo(() => {
    const arr = ['ihoawj_firstpage', 'ihoawj_secondpage'];

    if (cesShowMode) {
      arr.pop();
    } else {
      for (let i = 2; i < CONTENT_THEME_LIST.length; i+=2) {
        arr.push(`ihoawj_${CONTENT_THEME_LIST[i].themeId}`);
      }
    }

    return arr;
  }, [CONTENT_THEME_LIST, cesShowMode]);

  return (
    <TPanel className={classNames(css.panel, pageIndex > 0 && css.contentPanel)} {...rest}>
      <THeader title={$L("Walking / Jogging")}/>
      <TBody className={css.tBody}>
        <TVerticalPagenator onPageChanged={onPageChanged} pageSpotIds={pageSpotIds} className={css.tScroller}>
          <Container spotlightId='ihoawj_firstpage'>
            <IHOAWJActivityToday />
            {/* <div className={css.banner}>{$L("Banner ad area")}</div> */}
            {<RecommendedCourse items={recommendedCourseList} onClick={onItemClick}/>}
          </Container>
          {!cesShowMode && <div className={css.contentContainer}>
            <Container className={css.themeContainer} spotlightId='ihoawj_secondpage'>
              {CONTENT_THEME_LIST_BASE.length > 0 &&
                <TDropdown
                  spotlightId={'ihoawjmain_dropdown'}
                  placeholder={$L("Filter")}
                  width={378}
                  direction="below"
                  onSelect={onSelect}
                >
                  {CONTENT_THEME_LIST_BASE.map((theme) => theme.alias)}
                </TDropdown>
              }
            </Container>

            {CONTENT_THEME_LIST.map((theme, idx) => (
              <Container key={`contentList-${idx}`} className={css.listContainer} spotlightId={`ihoawj_${theme.themeId}`}>
                <div className={css.listTitle}>{$L(theme.title)}</div>
                <THorizontalScrollList startGap={75} itemSize={ITEM_SIZE.large}>
                  {theme.items && theme.items.length > 0 &&  theme.items.map((item, idx2) =>
                    <SMediaItem
                      key={`ihoawjmain_${theme.themeId}_${idx2}`}
                      itemSize={ITEM_SIZE.large}
                      itemInfo={item}
                      onClick={onItemClick}
                      playIcon={true}
                    />
                  )}
                </THorizontalScrollList>
              </Container>
            ))}
          </div>}
        </TVerticalPagenator>
      </TBody>
    </TPanel>
  );
};

export default IHOAWJMain;