import { createSlice } from "@reduxjs/toolkit";
import { makeContent, makeContentAll } from "./recommendContent";

export const content = createSlice({
  name: "content",
  initialState: {
    recommendLists: {
      bodyAlignment: {}, // { [category]: [] }
      ROM: {},
      bodyBalance: {},
      physicalTest: {}
    },

    themeList: {}, // { [subType]: [] }
    activityLists: {
      walking: {}, // { [themeId]: [] }
      hiking: {},
      trainingCoaching: {},
      golfCoaching: {}
    },

    contentInfos: {}, // { [contentId]: {} }

    //todo
    aiLists: [], // { [ [index]: {} ] }
  },
  reducers: {
    updateRecommendList: (state, action) => {
      const { subType, data } = action.payload;

      // todo remove
      const categoryArr = makeContentAll(subType, data[0]);
      Object.keys(categoryArr).forEach(category => {
        categoryArr[category].forEach(obj => {
          state.contentInfos[obj.contentId] = obj;
        });
      });

      state.recommendLists[subType] = categoryArr;
    },
    updateThemeList: (state, action) => {
      const { subType, data } = action.payload;

      state.themeList[subType] = data;
    },
    updateActivityList: (state, action) => {
      const { subType, themeId, data, append } = action.payload;

      if (append && state.activityLists[subType][themeId]) {
        state.activityLists[subType][themeId] = state.activityLists[subType][themeId].concat(data);
      } else {
        state.activityLists[subType][themeId] = data;
      }
    },
    updateContent: (state, action) => {
      const data = action.payload;
      state.contentInfos[data.contentId] = data;
    },
    updateAiList: (state, action) => {
      const data = action.payload;
      state.aiLists = data;
    }
  },
});

export const {
  updateRecommendList,
  updateThemeList,
  updateActivityList,
  updateContent,
  updateAiList
} = content.actions;

export default content.reducer;
