/**
 * Custom Image
 *
 * @module CustomImage
 */
import React, {useCallback, useEffect, useState, useRef} from 'react';
import classNames from 'classnames';
import {Job} from '@enact/core/util';
import css from './CustomImage.module.less';

//animationSpeed : "slow", "normal",  "fast",    ==> 500ms, 250ms, 10ms
const CustomImage = ({src, className, hide, delay=100, animationSpeed="slow", onLoad}) => {
	const [imageLoaded, setImageLoaded] = useState(false);
	const [imgSrc, setImgSrc] = useState("");
	const showImageJob = useRef(new Job((setImageLoaded) => {
		setImageLoaded(true);
	}, delay));

	useEffect(() => {
		setImageLoaded(false);
		setImgSrc(src);
		showImageJob.current.stop();
	}, [src]);

	const _onLoad = useCallback(() => {
		showImageJob.current.start(setImageLoaded);
		if (onLoad) onLoad();
	}, [onLoad]);
	const onError = useCallback(() => {
	}, []);

	if(!src){
		return null;
	}
	return (
		<img className={classNames(css.customimage, className, (!imageLoaded || hide) ? css.hidden: null, css[animationSpeed])} src={imgSrc} onLoad={_onLoad} onError={onError} alt=""/>
	);
};

export default CustomImage;
