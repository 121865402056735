import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import css from "./VirtualTrekking.module.less";
import classnames from "classnames";
import TPanel from "../../components/TPanel";
import THeader from "../../components/THeader";
import { resetPanels } from "../../features/panels/panelsSlice";
import Spottable from "@enact/spotlight/Spottable";

import tempVtMain from "../../../assets/images/tempVT/tempVtMain.png"
import imgBodyBalancing from "../../../assets/images/tempVT/btn_bodyBalancing.png";
import imgHomeOutdoor from "../../../assets/images/tempVT/btn_homeOutdoor.png";

const SpottableComponent = Spottable("div");

const TRButton = ({btnIndex}) => {
  return (
    <SpottableComponent className={classnames(css.trButton, btnIndex===1 && css.outdoor)}>
      <img alt={btnIndex === 0 ? "imgBodyBalancing": "imgHomeOutdoor"} src={btnIndex === 0 ? imgBodyBalancing: imgHomeOutdoor}/>
    </SpottableComponent>
  )
}
const VirtualTrekking = () => {
  const dispatch = useDispatch();

  const goHome = useCallback(()=>{
    dispatch(resetPanels());
  },[dispatch]);

  return (
    <TPanel handleCancel={goHome}>
      <THeader title="" iconType="home"/>
      <div style={{textAlign:"center", paddingTop:"1.5rem"}}>
          <div>
            <img src={tempVtMain} alt="" />
            <TRButton btnIndex={0}/>
            <TRButton btnIndex={1}/>
          </div>
        </div>
    </TPanel>
  )
}

export default VirtualTrekking;
