import React from "react";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import css from "./LoggerTest.module.less";
import { useDispatch, useSelector } from "react-redux";
import { onLoggerWindowState } from "../../features/logData/logDataSlice";

const LoggerTest = ({...rest}) => {
  const { loggerWindowState } = useSelector((state) => state.logData);
  const dispatch = useDispatch();

  return (
    <TPanel {...rest}>
      <THeader title={"Logger Test"} />
      <TBody>
        <button
          className={css.loggerTestButton}
          onClick={() => dispatch(onLoggerWindowState(!loggerWindowState))}
        >
          LoggerWindow 버튼
        </button>
      </TBody>
    </TPanel>
  );
};

export default LoggerTest;
