/**
 * TVerticalPagenator
 *
 * @module TVerticalPagenator
 */

import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef} from 'react';
import {on, off} from '@enact/core/dispatcher';
import css from './TVerticalPagenator.module.less';
import TScroller from '../TScroller/TScroller';
import { useSelector } from 'react-redux';
import * as HelperMethods from "../../utils/helperMethods";

const TVerticalPagenator = ({
  className,
  children,
  disabled,
  pageSpotIds = [],
  onPageChanged,
  visible = "hidden",
  parentRef,
  scrollTop=false,
  ...rest
}) => {
    const [pageIndex, setPageIndex] = useState(0);
    const broadcast = useSelector((state) => state.common.broadcast);
    const isScrolling = useRef(false);
    const scrollTo = useRef(null);
    const { cursorVisible } = useSelector((state) => state.common.appStatus);
    useEffect(() => {
        if(broadcast?.type === 'gnbButtonClicked' && !disabled){
            setPageIndex(0);
        }
    }, [broadcast]);

    const onFocusItem = useCallback((ev)=>{
        ev.stopPropagation();
        ev.preventDefault();
        if (cursorVisible && isScrolling.current) {
            return;
        }
        pageSpotIds.forEach((element, index) => {
            const pageNode = document.querySelector(
                `[data-spotlight-id="${element}"]`
            );
            const { bottom, top } = HelperMethods.getRectDiff(pageNode.parentNode.parentNode, ev.target);
            if(pageNode && pageNode.contains(ev.target) && (bottom < 0 || top > 0)){
                setPageIndex(index);
            }
        });
    },[pageIndex, cursorVisible]);

    const handleWheel = useCallback((ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        if(disabled){
            return;
        }
        if(isScrolling.current) {
            return;
        }
        let newIndex = ev.deltaY > 0 ? pageIndex+1: pageIndex -1;
        newIndex = Math.max(newIndex, 0);
        newIndex = Math.min(newIndex, pageSpotIds.length-1);
        setPageIndex(newIndex);
    }, [disabled, pageIndex, pageSpotIds]);

  const getScrollTo = useCallback(
    (cbScrollTo) => {
      if (parentRef) {
        parentRef.current = cbScrollTo;
      } else {
        scrollTo.current = cbScrollTo;
      }
    },
    [parentRef]
  );

    useEffect(() => {
        if (scrollTop && scrollTo && scrollTo.current) {
            scrollTo.current({ position: { y: 0, x: 0 }, animate: true });
            setPageIndex(0);
        }
    }, [scrollTop]);

    useEffect(() => {
        on('wheel', handleWheel);
        return () => {
            off('wheel', handleWheel);
        };
    }, [handleWheel]);

    useEffect(() => {
        if(pageSpotIds[pageIndex]){
            const currentNode = document.querySelector(
                `[data-spotlight-id="${pageSpotIds[pageIndex]}"]`
            );
            if (parentRef) {
                parentRef.current({ node: currentNode, animate: true });
            } else {
                scrollTo.current({ node: currentNode, animate: true });
            }
        }
        if(onPageChanged){
            onPageChanged(pageIndex);
        }
  }, [pageIndex, parentRef]);

    const _onScrollStart = useCallback(() => {
        isScrolling.current = true;
    }, []);

    const _onScrollStop = useCallback(() => {
        isScrolling.current = false
    }, []);

    return (
        <TScroller
            className={classNames(css.verticalPagenator, className)}
            focusableScrollbar={visible !== "hidden"}
            noScrollByWheel={true}
            noScrollByDrag={true}
            verticalScrollbar={visible}
            onFocus={onFocusItem}
            cbScrollTo={getScrollTo}
            onScrollStart={_onScrollStart}
            onScrollStop={_onScrollStop}
        >
            {children}
        </TScroller>
    );
};

export default TVerticalPagenator;
