import TRadarChart from '../../../../components/TRadarChart/TRadarChart';
import dummyPersonalData from '../../../../../assets/mock/radarChartPersonalData.json';
import dummyAverageData from '../../../../../assets/mock/radarChartAverageData.json';
import css from './DetailLayout.module.less';

const labelColors = [
  'rgba(85, 170, 141, 1)',
  'rgba(85, 170, 141, 1)',
  'rgba(252, 195, 97, 1)',
  'rgba(252, 195, 97, 1)',
  'rgba(247, 68, 54, 1)',
];

const TRaderChartLayout = () => {
  return (
    <div className={css.radarChartLayout}>
      <TRadarChart
        personalData={dummyPersonalData.data5}
        averageData={dummyAverageData.data5}
        size={700}
        levels={[0.65]}
        startIndex={3}
        labelColors={labelColors}
      />
      <TRadarChart
        personalData={dummyPersonalData.data7}
        averageData={dummyAverageData.data7}
        size={700}
        levels={3}
        startIndex={1}
        labelColors={labelColors}
        sides={7}
      />
    </div>
  );
};

export default TRaderChartLayout;
