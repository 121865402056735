import { ADAPTER_ADDRESS, ADDRESS, DEFAULT_INTERVAL } from "./dummyDefault";
/*
산책모드
sequence->value : Signature(1byte), Version(1byte), MessageId(1byte), NRP(2byte), payload(...)
sequence->interval: option
*/

export default {
  "interval": DEFAULT_INTERVAL, //default interval
  "loop": true,
  "common": {
    "adapterAddress": ADAPTER_ADDRESS,
    "address": ADDRESS,
    "returnValue": true,
    "subscribed": false
  },
  "sequence": [
    {"value": [0xA5, 0, 0, 0, 0, 0x10, 2, 0, 0x10, 0x01], "interval": 100},
    {"value": [0xA5, 0, 1, 0, 0, 0x10, 2, 0, 0x10, 0x02], "interval": 200},
    {"value": [0xA5, 0, 2, 0, 0, 0x10, 2, 0, 0x11, 0x01], "interval": 300},
    {"value": [0xA5, 0, 3, 0, 0, 0x10, 2, 0, 0x11, 0x02], "interval": 200},
    {"value": [0xA5, 0, 4, 0, 0, 0x10, 2, 0, 0x10, 0x01], "interval": 100},
    {"value": [0xA5, 0, 5, 0, 0, 0x10, 2, 0, 0x10, 0x02], "interval": 300},
    {"value": [0xA5, 0, 6, 0, 0, 0x10, 2, 0, 0x11, 0x01], "interval": 300},
    {"value": [0xA5, 0, 7, 0, 0, 0x10, 2, 0, 0x11, 0x02], "interval": 200}
  ]
}