import React, { useCallback, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import css from "./VerticalBarItem.module.less";
// todo : make commom toolTip component
import ToolTip from "../../Tooltip/ToolTip";

const VerticalBarItem = ({ children, data, maxValues, className, index, ...rest }) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    setNumber(data / maxValues);
  }, [data, maxValues]); 

  return (
    <div 
      className={classNames(
        css.verticalBarItem,
        className
      )} 
      style={{ 
        height: `${number * 100}%`, 
        background: 
        (data / maxValues <= 1 / 3 * 1) ?  `linear-gradient(0deg, #EFE7DF 0, #EFE7DF 100%)` : 
        (data / maxValues <= 1 / 3 * 2) ? `linear-gradient(0deg, #EFE7DF 0, #EFE7DF ${maxValues / data * (1 / 3) * 100}%, #B59D85 0, #B59D85 100%)` :
        `linear-gradient(0deg, #EFE7DF 0, #EFE7DF ${maxValues / data * (1 / 3) * 100}%, #B59D85 0, #B59D85 ${maxValues / data * (2 / 3) * 100}%, #827161 0, #827161 100%)`
      }} 
      {...rest}
    >
      {index && data > 0 && 
      <ToolTip
        title={data}
      />}
    </div>
  );
};

export default VerticalBarItem;