import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const HomeIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.7057 25.346L38.8283 23.0082C38.8283 23.0082 38.8283 22.9918 38.8119 22.9918L32.9754 18.2507L30.6539 16.3542C30.6539 16.3542 30.6375 16.3542 30.6375 16.3379L28.316 14.4414C27.5803 13.8529 26.4359 13.8529 25.6839 14.4414L23.3623 16.3379C23.3623 16.3379 23.3623 16.3379 23.346 16.3379L21.3514 17.9564L15.1879 22.9918C15.1716 22.9918 15.1716 23.0082 15.1553 23.0245L12.2942 25.346C11.9509 25.624 11.9018 26.1144 12.1798 26.4578C12.3269 26.654 12.5558 26.752 12.7847 26.752C12.9645 26.752 13.1444 26.6866 13.2751 26.5722L14.8773 25.2643V38.1962C14.8773 39.3569 15.8255 40.3052 16.9863 40.3052H23.2969C23.8691 40.3052 24.3433 39.8311 24.3433 39.2589V32.9482H29.6076V39.2589C29.6076 39.8311 30.0817 40.3052 30.6539 40.3052H36.9645C38.1253 40.3052 39.0735 39.3569 39.0735 38.1962V25.2643L40.6757 26.5722C40.8228 26.6866 41.0027 26.752 41.1661 26.752C41.395 26.752 41.6239 26.654 41.7711 26.4578C42.098 26.1144 42.049 25.624 41.7057 25.346Z"
        fill={convertThemeColor(iconType)}
      />
    </svg>
  );
};

export default HomeIcon;
