import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spotlight from "@enact/spotlight";
import css from './HPCenterInfo.module.less';
import { addPanels } from '../../features/panels/panelsSlice';
import THeader from "../../components/THeader/THeader";
import { $L } from '../../utils/helperMethods';
import TPanel from '../../components/TPanel/TPanel';
import TBody from '../../components/TBody/TBody';
import TButton, { COLOR, ICONS, SIZES, TYPES } from '../../components/TButton/TButton';
import Spottable from '@enact/spotlight/Spottable';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import useUserCheck from '../../hooks/useUserCheck';
import { changeAppStatus } from '../../features/common/commonSlice';
import { panel_names } from '../../utils/Config';
import classNames from 'classnames';
import TVerticalPagenator from '../../components/TVerticalPagenator/TVerticalPagenator';
import SMediaItem from '../../components/SMediaList/SMediaItem';
import THorizontalScrollList, { ITEM_SIZE } from '../../components/THorizontalScrollList/THorizontalScrollList';

const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");
const ITEM_WIDTH = 427;
const ITEM_GAP = 25;

const HPCenterInfo = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const scrollTo = useRef();
  const { userCheck } = useUserCheck();
  const [subscribe, setSubscribe] = useState(false); //todo data
  const [favorit, setFavorit] = useState(false); //todo data
  const [scrollTop, setScrollTop] = useState(false);

  const programList = useMemo(() => {
    return panelInfo ? panelInfo.programList : [];
  }, [panelInfo]);

  const onClickSubscribe = useCallback(() => {
    if(!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'genaral' } }));
    } else {
      setSubscribe(!subscribe);
    }
	},[subscribe]);

  const onClickFav = useCallback(() => {
    if(!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'genaral' } }));
    } else {
      setFavorit(!favorit);
    }
	},[favorit]);

  const gotoProgram = useCallback((index) => () => {
    if(!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'genaral' } }));
    } else {
      console.log('gotoProgram index', index);
      dispatch(addPanels({ name: panel_names.HOMECARE_ROUTINE_PROGRAM, panelInfo: { panelInfo, programIndex: index } }));
    }
	},[panelInfo]);

  const _onFocus = useCallback(() => {
    setScrollTop(true);
    setTimeout(() => {
      setScrollTop(false);
    }, 0);
  }, []);

  useEffect(() => {
    Spotlight.focus("hpCenterInfoFirstSpot");
  }, []);

  const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, []);

  const PAGENATE = useMemo(() => {
    const arr = ['HP_PROGRAM_INFO'];
    programList.length > 0 && programList.map((pro, idx) => ( arr.push(`PROGRAM_LIST_${idx}`) ));
    return arr;
  }, [programList]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={panelInfo.title}/>
      <TBody className={css.tBody}>
        <TVerticalPagenator
          className={css.scroll}
          pageSpotIds={PAGENATE}
          visible={"visible"}
          scrollTop={scrollTop}
        >
        {/* <TScroller
          className={css.scroll}
          cbScrollTo={getScrollTo}
        > */}
          <Container className={css.container} spotlightId={`HP_PROGRAM_INFO`}>
            <div className={css.header}>
              <img className={css.logo} src={panelInfo?.logoLarge}/>
              <div className={css.infoLayer}>
                <div className={css.features}>
                  <span />
                  {panelInfo.features.map((feature, index) => (
                    <div className={css.feature} key={"feature" + index}>
                      {index > 0 && <span />}
                      {$L(feature)}
                    </div>
                  ))}
                </div>
                <div className={css.introduction}>{panelInfo.introduction}</div>
                <TButton
                  type={TYPES.withIcon}
                  icon={subscribe ? ICONS.bellW : ICONS.plusW}
                  color={COLOR.brown}
                  size={SIZES.tiny}
                  className={css.button}
                  onClick={onClickSubscribe}
                  onFocus={_onFocus}
                >
                  {subscribe ? $L("구독중") : $L("구독하기")}
                </TButton>
                <TButton
                  type={TYPES.withIcon}
                  icon={favorit ? ICONS.heart : ICONS.heartH}
                  color={COLOR.brown}
                  size={SIZES.tiny}
                  className={css.button}
                  spotlightId="hpCenterInfoFirstSpot"
                  onClick={onClickFav}
                  onFocus={_onFocus}
                >
                  {$L("센터 즐겨찾기")}
                </TButton>
              </div>
            </div>
            {programList.length > 0 &&
            <div className={css.register}>
              <div>{$L("등록 프로그램")}</div>
              <span />
              <div>
                {
                  $L("총")
                  +" "
                  + programList.length
                  + $L("개의 프로그램이 있어요.")
                }
              </div>
            </div>}
            {programList.length > 0 && programList.map((pro, idx) => (
              <Container key={'program_'+idx} className={css.programLists} spotlightId={`PROGRAM_LIST_${idx}`}>
                <div className={css.programTitle}>
                  <div>
                    {pro.tag && <span>{pro.tag}</span>} {/** todo  */}
                    <div className={css.title}>{pro.programTitle}</div>
                    <div className={css.programIntroduction} >
                      {pro.programIntroduction}
                    </div>
                  </div>
                  <TButton size={SIZES.large} color={COLOR.brown} onClick={gotoProgram(idx)}>{$L("프로그램 시작하기")}</TButton>
                </div>
                <div className={css.programDetail}>
                  {pro.programDetail && Object.keys(pro.programDetail).map((el) => (
                    <React.Fragment key={'detail_'+el}>
                      <div className={classNames(css.icon, css[el])} />
                      <div className={css.iconTitle}>{pro.programDetail[el]}{el === "playTime" && "분" }</div>
                    </React.Fragment>
                  ))}
                </div>
                <THorizontalScrollList
                  itemSize={ITEM_SIZE.large}
                  startGap={75}
                >
                  {pro.contents.map((item, index) => (
                    <SMediaItem
                      key={`center_info_${index}`}
                      itemSize={ITEM_SIZE.large}
                      itemInfo={item}
                      centerTitle={true}
                    />
                  ))}
                </THorizontalScrollList>
                {(programList.length - 1) !== idx && <div className={css.hr} />}
              </Container>
            ))}
          </Container>
        {/* </TScroller> */}
        </TVerticalPagenator>
      </TBody>
    </TPanel>
  );
};

export default HPCenterInfo;
