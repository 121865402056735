import React, { useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { $L } from "../../../../../utils/helperMethods";
import css from "./Overall.module.less";
import { bodyBalanceMenu, getEmptyResultsReportData } from "../Constants";
import EmptyResultsReport from "../../../../../components/EmptyResultsReport/EmptyResultsReport";
import TCanvasMatRaw from "../../../../../components/TCanvasMatRaw/TCanvasMatRaw";
import FootTypeResults from "./FootTypeResults";
import BodyBalanceTabResults from "../../../../../views/BodyCheckUp/BodyBalance/BodyBalanceReport/TabContents/BodyBalanceTabResults";
import CoPResults from "../../../../../views/BodyCheckUp/BodyBalance/BodyBalanceReport/TabContents/CoPResults";
import GaitResults from "../../../../../views/BodyCheckUp/BodyBalance/BodyBalanceReport/TabContents/GaitResults";
import {
  voiceGuideEmpty,
  voiceGuideOverallBodyBalance,
} from "../feedback/feedbackBodyBalance";
import AlertLineGray from "../../../../../../assets/icon/ic_alert_line_gray.svg";

const Overall = ({
  date = "",
  currentData,
  openMenuTestPopup,
  setFeedback,
  setTextDisabled,
  hasData = {},
}) => {
  const NoDataComp = () => {
    return (
      <div className={css.noDataSect}>
        <img src={AlertLineGray} alt="" />
        <div>{$L("측정이 필요합니다.")}</div>
      </div>
    );
  };

  const scrollTo = useRef();
  const emptyResultsReportData = getEmptyResultsReportData();
  const emptyResultsData = emptyResultsReportData[bodyBalanceMenu.OVERALL];
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const twolegTestResultsRawData = useMemo(()=>currentData?.twolegstance?.rawData, [currentData]);

  // style변경
  const bbtStyle = {
    bbtCoordinatePlaneWrapStyle: {
      top: "28px",
    },
    bbtInfoWrapStyle: cesShowMode
      ? {
          marginTop: "28px",
          fontSize: "24px",
          bottom: "10px",
        }
      : {
          marginTop: "28px",
          fontSize: "24px",
          bottom: "28px",
        },
  };
  const footStyle = {
    footArchBarItemSectStyle: {
      margin: "0px",
      paddingBottom: "82px",
      width: "44%",
      border: "none",
    },
    footArchInfoWrapStyle: cesShowMode
      ? { marginBottom: "20px", fontSize: "20px", lineHeight: "35px" }
      : { marginBottom: "20px", fontSize: "20px" },
    footStepFlexStyle: cesShowMode
      ? {
          fontSize: "21px",
          lineHeight: "25px",
          marginTop: "0px",
        }
      : {
          fontSize: "14px",
          marginTop: "0px",
        },
    footStepFlexImgStyle: cesShowMode
      ? {}
      : { width: "35px", marginTop: "8px" },
    footRenderTypeStyle: cesShowMode ? {} : { display: "none" },
  };
  const coPStyle = {
    coPArchFlexStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    coPArchBarItemSectStyle: {
      width: "100%",
      margin: "0px",
      padding: "0px",
      border: "none",
    },
    coPArchInfoWrapStyle: {
      justifyContent: "flex-start",
      marginBottom: "30px",
      fontSize: "24px",
    },
    coPStepFlexStyle: { fontSize: "15px", marginTop: "6px" },
    coPStepStyle: { marginTop: "-12px" },
  };

  useEffect(() => {
    if (scrollTo && scrollTo.current) {
      scrollTo.current({ position: { y: 0 }, animate: false });
    }
  }, []);

  useEffect(() => {
    if (hasData.overall) {
      setTextDisabled(false);
      setFeedback(voiceGuideOverallBodyBalance(cesShowMode, currentData));
    } else {
      setTextDisabled(true);
      setFeedback(voiceGuideEmpty(bodyBalanceMenu.OVERALL));
    }
  }, [hasData, cesShowMode, currentData]);

  if (hasData.overall) {
    if (cesShowMode) {
      return (
        <div className={css.reportSect}>
          <div className={classNames(css.bodyBalanceTabWrap, css.ces)}>
            <div className={css.bodyBalanceTabComp}>
              <div className={css.coordinates}>
                {hasData.bodyBalanceTab && (
                  <BodyBalanceTabResults
                    currentData={currentData}
                    style={bbtStyle}
                    totalLength={293}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={classNames(css.responseBox, css.ces)}>
            <TCanvasMatRaw
              rawData={twolegTestResultsRawData}
              width={40}
              height={20}
              date={date}
              type="twoleg"
            />
          </div>
          <div className={classNames(css.footTypeWrap, css.ces)}>
            <div className={css.compWrap}>
              {hasData.footType && (
                <FootTypeResults
                  currentData={currentData}
                  style={footStyle}
                />
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={css.reportSect}>
          <div className={css.report}>
            <div className={css.wrap}>
              <div className={css.footTypeWrap}>
                <div className={css.tit}>{$L("Foot Shape")}</div>
                <div className={css.footTypeComp}>
                  {hasData.footType ?
                    <div className={css.compWrap}>
                      <FootTypeResults
                        currentData={currentData}
                        style={footStyle}
                      />
                    </div>
                    : <NoDataComp />
                  }
                </div>
              </div>
              <div className={css.walkingPathWrap}>
                <div className={css.tit}>{$L("Gait")}</div>
                <div className={css.walkingPathComp}>
                  {hasData.footType ?
                    <div className={css.compWrap}>
                      <div className={css.line} />
                      <GaitResults />
                    </div>
                    : <NoDataComp />
                  }
                </div>
              </div>
            </div>
            <div className={css.bodyBalanceTabWrap}>
              <div className={css.tit}>{$L("Two-Leg Balance")}</div>
              <div className={css.bodyBalanceTabComp}>
                {hasData.bodyBalanceTab ?
                  <div className={css.coordinates}>
                    <BodyBalanceTabResults
                      currentData={currentData}
                      style={bbtStyle}
                      totalLength={293}
                    />
                  </div>
                  : <NoDataComp />
                }
              </div>
            </div>
            <div className={css.coPWrap}>
              <div className={css.tit}>{$L("One-Leg Balance")}</div>
              <div className={css.coPComp}>
                {hasData.CoP ?
                  <div className={css.compWrap}>
                    <CoPResults
                      currentData={currentData}
                      style={coPStyle}
                      OverallImg
                    />
                  </div>
                  : <NoDataComp />
                }
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <EmptyResultsReport
        openMenuTestPopup={openMenuTestPopup}
        overallResult
        bodyBalance={true}
        title={emptyResultsData.title}
        detail={emptyResultsData.detail}
        subheading={emptyResultsData.subheading}
        list={emptyResultsData.list}
      />
    );
  }
};

export default Overall;
