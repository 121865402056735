import css from "./SoundEffectTestPanel.module.less";
import { useCallback, useEffect, useRef, useState } from "react";
import TPanel from "../../../components/TPanel/TPanel";
import THeader from "../../../components/THeader/THeader";
import TBody from "../../../components/TBody/TBody";
import TButton from "../../../components/TButton/TButton";
import * as SoundEffect from "../../../utils/SoundEffect";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";

const SoundEffectTestPanel = () => {
	const [audioUrl, setAudioUrl] = useState("");

	const onProcess = useCallback((url)=>() => {
		console.log('onProcess url ', url)
		SoundEffect.playAudio(url);
		// setAudioUrl(url);
	},[]);

	return (
	<TPanel>
		<THeader title={"SoundEffect Test Panel"}/>
		<TBody>
			<div className={css.body}>
				<TButton withMarquee={true} onClick={onProcess("http://13.124.67.170:8080/data/sound/foot-1-1.wav")}>fot-1-1</TButton>
				<TButton withMarquee={true} onClick={onProcess("http://13.124.67.170:8080/data/sound/foot-1-2.wav")}>fot-1-2</TButton>
				<TButton withMarquee={true} onClick={onProcess("SCH_BUTTON_3")}>SCH_BUTTON_3</TButton>
				<TButton withMarquee={true} onClick={onProcess("M1")}>M1</TButton>
			</div>
			{/* <AudioPlayer srcTypeStr={audioUrl}/> */}
		</TBody>
	</TPanel>
  );
}

export default SoundEffectTestPanel;