import React, { useEffect, useMemo } from "react";
import css from "./FootType.module.less";
import { getEmptyResultsReportData, bodyBalanceMenu } from "../Constants";
import EmptyResultsReport from "../../../../../components/EmptyResultsReport/EmptyResultsReport";
import BodyBalanceResultsReport from "./BodyBalanceResultsReport/BodyBalanceResultsReport";
import {
  voiceGuideBodyBalance,
  voiceGuideEmpty,
} from "../feedback/feedbackBodyBalance";
import { $L } from "../../../../../utils/helperMethods";

const FootType = ({
  date = "",
  currentData,
  openMenuTestPopup,
  feedback,
  setFeedback,
  setTextDisabled,
  isEmpty = false,
}) => {
  const emptyResultsData =
    getEmptyResultsReportData()[bodyBalanceMenu.FOOT_TYPE];

  const resultData = useMemo(() => {
    const ret = {
      leftFoot: $L("Left Foot") + " " + $L("Assessment results"),
      leftFootInfo: "",
      rightFoot: $L("Right Foot") + " " + $L("Assessment results"),
      rightFootInfo: "",
    };
    if (feedback?.footType) {
      const resFeedback = feedback.footType;
      ret.leftFootInfo = resFeedback.left;
      ret.rightFootInfo = resFeedback.right;
    }
    return ret;
  }, [feedback]);

  useEffect(() => {
    if (!isEmpty) {
      setTextDisabled(false);
      setFeedback(
        voiceGuideBodyBalance(bodyBalanceMenu.FOOT_TYPE, currentData)
      );
    } else {
      setTextDisabled(true);
      setFeedback(voiceGuideEmpty(bodyBalanceMenu.FOOT_TYPE));
    }
  }, [isEmpty, currentData]);

  if (!isEmpty) {
    return (
      <div className={css.reportSect}>
        <BodyBalanceResultsReport
          bodyBalanceResultsData={resultData}
          currentData={currentData}
          date={date}
          tabType={bodyBalanceMenu.FOOT_TYPE}
        />
      </div>
    );
  } else {
    return (
      <EmptyResultsReport
        openMenuTestPopup={openMenuTestPopup}
        partResult={true}
        bodyBalance={true}
        title={emptyResultsData.title}
        detail={emptyResultsData.detail}
        subheading={emptyResultsData.subheading}
        list={emptyResultsData.list}
      />
    );
  }
};

export default FootType;
