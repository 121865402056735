import classNames from 'classnames';
import css from './ToolTip.module.less';



const ToolTip = ({ className, title, ...rest }) => {

	return (
		<div
			className={classNames(
				css.toolTip,
				className
			)}
			{...rest}
		>
			<div className={css.arrow}/>
			<div className={css.title}>{title}</div>
		</div>
	);
};


export default ToolTip;

