import React, { useEffect, useRef } from 'react';
import css from './TTimeLine.module.less';
import ic_inhome_activity_day from '../../../assets/icon/ic_inhome_activity_day.svg';
import { formatDateTime } from '../../utils/helperMethods';

const TTimeLine = ({ items = [], itemRenderer, ...rest }) => {
	const timeLineRef = useRef(null);

	useEffect(() => {
		if (timeLineRef && timeLineRef.current) {
			Array.from(timeLineRef.current.children).forEach(el => {
				if (el.nextSibling) {
					const nextEl = el.nextSibling;
					const elHeight = el.offsetHeight - el.firstChild.offsetHeight;
					const nextElHeight = nextEl.offsetHeight - nextEl.firstChild.offsetHeight;
					console.log(elHeight, nextElHeight)

					el.firstChild.lastChild.setAttribute('style', `height: ${((elHeight + nextElHeight) / 2) - 8}px;`);
				}
			})
		}
	}, [items]);

	return (
		<ul ref={timeLineRef} className={css.timeLineContainer} {...rest}>
			{items.map((item, idx) => <li key={`timeLine-${idx}`} className={css.timeWrapper}>
				<div className={css.time}>
					<img src={ic_inhome_activity_day} />
					<div>{formatDateTime('YYYY.MM.DD', item?.date)}</div>
					{idx < items.length - 1 && <div className={css.bar} />}
				</div>
				{itemRenderer && itemRenderer(item)}
			</li>)}
		</ul>
	);
};

export default TTimeLine;