import classnames from "classnames";
import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './HPWorkoutOptions.module.less';
import { addPanels } from '../../features/panels/panelsSlice';
import THeader from '../../components/THeader/THeader';
import { $L } from '../../utils/helperMethods';
import TPanel from '../../components/TPanel/TPanel';
import TBody from '../../components/TBody/TBody';
import HomecareWorkoutBtn from '../../components/GateSection/HomecareWorkoutBtn';
import TScroller from '../../components/TScroller/TScroller';
import IMG_HOMECARE_SINGLE_BANNER from "../../../assets/HomecareProgram/img_homecare_single_banner.png"
import TDropdown from "../../components/TDropDown/TDropDown";
import { useState } from "react";
import TNudeTab from "../../components/TNudeTab"
import Center from "./Center/Center";
import { panel_names } from "../../utils/Config";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const HPWorkoutOptions = ({...rest}) => {
  const scrollTo = useRef(null);
  const dispatch = useDispatch();
  const centerList = useSelector((state) => state.centerData.centerList);
  const [selectedId, setSelectedId] = useState(null);
  const [selectOderIndex, setSelectOderIndex] = useState(-1);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [centers, setcenters] = useState(centerList);
  const [focusCenter, setFocusCenter] = useState(centers[0]);
  // todo Category dropDown
  const dropDownState = [$L("전체 보기"), $L("구독중"), $L("즐겨찾기")];
  const dropDownCountry = [$L("All countries"), $L("한국"), $L("미국")];
  const dropDownIndex = [$L("색인 보기"), $L("A"), $L("E"), $L("J")];
  const order = [$L("From the newest"), $L("In alphabetical order")];

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

	const onSelect = useCallback(({selected}) => {
    setSelectedId(selected);
	}, [selectedId]);

  useEffect(()=>{
    if (scrollTo && scrollTo.current) {
      scrollTo.current({position: {y: 0}, animate: false});
    }
  }, []);

  const onClickCenter = useCallback((centerInfo) => () => {
    dispatch(addPanels({ name: panel_names.HOMECARE_CENTER_INFO, panelInfo: centerInfo}))
  }, [dispatch])

  const onFocusCenter = useCallback((info, index) => (ev) => {
    // console.log('onFocusCenter', info, index, ev);
    setFocusCenter(info);
      setFocusedIndex(index);
  }, [])

  const onClickSort = useCallback(({ index }) => {
    const data = [...centerList]; // 데이터 복사
    if (index === 0) {
      setSelectOderIndex(0);
      setcenters(centerList); // todo server에서 최신순으로 내려주는지 확인
    } else if (index === 1) {
      setSelectOderIndex(1);
      setcenters(data.sort((a, b) => a.title.localeCompare(b.title)))
    } else {
      setSelectOderIndex(-1);
      setcenters(centerList);
    }
  }, [centerList, selectOderIndex]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("O2O 정규프로그램")} />
      <TBody className={css.tBody}>
        <Container className={css.topLayer}>
          <Container className={css.dropLayer}>
            <TDropdown
              placeholder={$L("전체 보기")}
              direction="below"
              width={464}
              onSelect={onSelect}
            >
              {dropDownState}
            </TDropdown>
            <TDropdown
              placeholder={$L("All countries")}
              direction="below"
              width={464}
              onSelect={onSelect}
            >
              {dropDownCountry}
            </TDropdown>
            <TDropdown
              placeholder={$L("색인 보기")}
              direction="below"
              width={464}
              onSelect={onSelect}
            >
              {dropDownIndex}
            </TDropdown>
          </Container>
          {/* <div className={css.collection}>
            {$L("LG Collection")}
          </div> */}
          <div className={css.orderLayer}>
            <TNudeTab
              className={css.order}
              size={'small'}
              labelArr={order}
              selectedTabIndex={selectOderIndex}
              setSelectedTabIndex={setSelectOderIndex}
              onClick={onClickSort}
            />
          </div>
        </Container>
        <div className={css.container}>
          <TScroller className={css.tScroller} cbScrollTo={getScrollTo} >
            <div className={css.centerInfo}>
              {centers.map((info, index) =>
                <Center
                  key={"center"+index}
                  info={info}
                  onClick={onClickCenter(info)}
                  onFocus={onFocusCenter(info, index)}
                  index={index}
                  focusedIndex={focusedIndex}
                  setFocusedIndex={setFocusedIndex}
                />
              )}
              <div>''</div>
            </div>
          </TScroller>
          <div className={css.workout_lists}>
            <HomecareWorkoutBtn
              key={focusCenter?.title}
              title={focusCenter?.title}
              detail={focusCenter?.introduction}
              color={focusCenter?.centerBannerColor || '#697889'}
              image={focusCenter?.centerBannerImg || IMG_HOMECARE_SINGLE_BANNER}
              className={css.single}
            />
          </div>
        </div>
      </TBody>
    </TPanel>
  );
};

export default HPWorkoutOptions;
