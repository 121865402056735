import css from "./TestPanel.module.less";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import TPanel from "../../components/TPanel";
import THeader from "../../components/THeader";
import TButton from "../../components/TButton/TButton";
import TBody from "../../components/TBody";
import { addPanels } from "../../features/panels/panelsSlice";
import * as Config from "../../utils/Config";
import { enumEventType, logger } from "../../utils/logger";

/**
 * @module TestPanel
 */
const TESTPANELS = [
  { title: "Scan Test", target: Config.panel_names.TEST_PANEL_SCAN },
  { title: "BellySmooth Test", target: Config.panel_names.TEST_PANEL_BELLY },
  { title: "ShakaPlayer Test", target: Config.panel_names.TEST_PANEL_SHAKA },
  { title: "PlaySpeed Test", target: Config.panel_names.TEST_PANEL_PLAYSPEED },
  { title: "Chart Test", target: Config.panel_names.TEST_PANEL_CHART },
  { title: "Logger Test", target: Config.panel_names.TEST_PANEL_LOGGER },
  { title: "Ble Test(산책)", target: Config.panel_names.TEST_PANEL_BLE },
  { title: "Ble Dummy", target: Config.panel_names.TEST_PANEL_BLE_DUMMY },
  { title: "WebWorker Test", target: Config.panel_names.TEST_PANEL_WEBWORKER },
  { title: "SoundEffect Test", target: Config.panel_names.TEST_PANEL_SOUNDEFFECT },
  { title: "StoryBook", target: Config.panel_names.TEST_PANEL_STORYBOOK },
  { title: "JMediaList", target: Config.panel_names.TEST_PANEL_JMEDIALIST },
  { title: "VR Player", target: Config.panel_names.TEST_PANEL_VRPLAYER },
  { title: "CES AI Coaching(bodyAlignmentSummary)", target: Config.panel_names.SELECT_WORKOUT, panelInfo: { enterFrom: 'bodyAlignment_summary' }},
  { title: "CES AI Coaching(workout)", target: Config.panel_names.SELECT_WORKOUT, panelInfo: { enterFrom: 'workout' }},
];

const TestPanel = ({...rest}) => {
  const dispatch = useDispatch();

  const onButtonClick = useCallback((item, index) => () => {
    dispatch(addPanels({ name: TESTPANELS[index].target, panelInfo: TESTPANELS[index]?.panelInfo }));
    logger(
      enumEventType.ON_CLICK,
      `TestPanel 컴포넌트의 ${item.title} 버튼을 클릭함`
    );
  }, [dispatch]);

  return (
    <TPanel {...rest}>
      <THeader title={"Test Panel"} />
      <TBody className={css.buttoncontainer}>
        {TESTPANELS.map((item, index) => {
          return (
            <TButton key={"btn" + index} onClick={onButtonClick(item, index)} withMarquee={true}>
              {item.title}
            </TButton>
          );
        })}
      </TBody>
    </TPanel>
  );
};

export default TestPanel;
