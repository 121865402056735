import { SUB_TYPE } from "../../feedback/constantsFeedback";

// shoulder
import abduction_left_bad_1 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_left_bad_1.png";
import abduction_left_bad_2 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_left_bad_2.png";
import abduction_left_bad_3 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_left_bad_3.png";
import abduction_left_good_1 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_left_good_1.png";
import abduction_left_weak_1 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_left_weak_1.png";
import abduction_left_weak_2 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_left_weak_2.png";

import abduction_right_bad_1 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_right_bad_1.png";
import abduction_right_bad_2 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_right_bad_2.png";
import abduction_right_bad_3 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_right_bad_3.png";
import abduction_right_good_1 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_right_good_1.png";
import abduction_right_weak_1 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_right_weak_1.png";
import abduction_right_weak_2 from "../../../../../../assets/rom/shoulder/shoulder_abduction/shoulderabduction_right_weak_2.png";

import shoulder_extension_left_bad from "../../../../../../assets/rom/shoulder/shoulder_extension/shoulderextension_left_bad.png";
import shoulder_extension_left_good from "../../../../../../assets/rom/shoulder/shoulder_extension/shoulderextension_left_good.png";
import shoulder_extension_left_weak from "../../../../../../assets/rom/shoulder/shoulder_extension/shoulderextension_left_weak.png";
import shoulder_extension_right_bad from "../../../../../../assets/rom/shoulder/shoulder_extension/shoulderextension_right_bad.png";
import shoulder_extension_right_good from "../../../../../../assets/rom/shoulder/shoulder_extension/shoulderextension_right_good.png";
import shoulder_extension_right_weak from "../../../../../../assets/rom/shoulder/shoulder_extension/shoulderextension_right_weak.png";

import shoulder_flexion_left_bad_1 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_left_bad_1.png";
import shoulder_flexion_left_bad_2 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_left_bad_2.png";
import shoulder_flexion_left_bad_3 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_left_bad_3.png";
import shoulder_flexion_left_bad_4 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_left_bad_4.png";
import shoulder_flexion_left_good_1 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_left_good_1.png";
import shoulder_flexion_left_weak_1 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_left_weak_1.png";
import shoulder_flexion_right_bad_1 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_right_bad_1.png";
import shoulder_flexion_right_bad_2 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_right_bad_2.png";
import shoulder_flexion_right_bad_3 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_right_bad_3.png";
import shoulder_flexion_right_bad_4 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_right_bad_4.png";
import shoulder_flexion_right_good_1 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_right_good_1.png";
import shoulder_flexion_right_weak_1 from "../../../../../../assets/rom/shoulder/shoulder_flexion/shoulderflexion_right_weak_1.png";

import external_rotation_left_bad from "../../../../../../assets/rom/shoulder/shoulder_external_rotation/shoulderexternalrotation_left_bad.png";
import external_rotation_left_good from "../../../../../../assets/rom/shoulder/shoulder_external_rotation/shoulderexternalrotation_left_good.png";
import external_rotation_left_weak from "../../../../../../assets/rom/shoulder/shoulder_external_rotation/shoulderexternalrotation_left_weak.png";
import external_rotation_right_bad from "../../../../../../assets/rom/shoulder/shoulder_external_rotation/shoulderexternalrotation_right_bad.png";
import external_rotation_right_good from "../../../../../../assets/rom/shoulder/shoulder_external_rotation/shoulderexternalrotation_right_good.png";
import external_rotation_right_weak from "../../../../../../assets/rom/shoulder/shoulder_external_rotation/shoulderexternalrotation_right_weak.png";

import internal_rotation_left_bad from "../../../../../../assets/rom/shoulder/shoulder_internal_rotation/shoulderinternalrotation_left_bad.png";
import internal_rotation_left_good from "../../../../../../assets/rom/shoulder/shoulder_internal_rotation/shoulderinternalrotation_left_good.png";
import internal_rotation_left_weak from "../../../../../../assets/rom/shoulder/shoulder_internal_rotation/shoulderinternalrotation_left_weak.png";
import internal_rotation_right_bad from "../../../../../../assets/rom/shoulder/shoulder_internal_rotation/shoulderinternalrotation_right_bad.png";
import internal_rotation_right_good from "../../../../../../assets/rom/shoulder/shoulder_internal_rotation/shoulderinternalrotation_right_good.png";
import internal_rotation_right_weak from "../../../../../../assets/rom/shoulder/shoulder_internal_rotation/shoulderinternalrotation_right_weak.png";

// knee
import knee_flexion_left_bad_1 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_left_bad_1.png";
import knee_flexion_left_bad_2 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_left_bad_2.png";
import knee_flexion_left_good_1 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_left_good_1.png";
import knee_flexion_left_good_2 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_left_good_2.png";
import knee_flexion_left_weak_1 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_left_weak_1.png";
import knee_flexion_left_weak_2 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_left_weak_2.png";

import knee_flexion_right_bad_1 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_right_bad_1.png";
import knee_flexion_right_bad_2 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_right_bad_2.png";
import knee_flexion_right_good_1 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_right_good_1.png";
import knee_flexion_right_good_2 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_right_good_2.png";
import knee_flexion_right_weak_1 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_right_weak_1.png";
import knee_flexion_right_weak_2 from "../../../../../../assets/rom/knee/knee_flexion/kneeflexion_right_weak_2.png";

// hip
import hip_extension_left_bad from "../../../../../../assets/rom/hip/hip_extension/hipextension_left_bad.png";
import hip_extension_left_good from "../../../../../../assets/rom/hip/hip_extension/hipextension_left_good.png";
import hip_extension_left_weak from "../../../../../../assets/rom/hip/hip_extension/hipextension_left_weak.png";
import hip_extension_right_bad from "../../../../../../assets/rom/hip/hip_extension/hipextension_right_bad.png";
import hip_extension_right_good from "../../../../../../assets/rom/hip/hip_extension/hipextension_right_good.png";
import hip_extension_right_weak from "../../../../../../assets/rom/hip/hip_extension/hipextension_right_weak.png";

import hip_flexion_left_bad_1 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_left_bad_1.png";
import hip_flexion_left_bad_2 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_left_bad_2.png";
import hip_flexion_left_good_1 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_left_good_1.png";
import hip_flexion_left_good_2 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_left_good_2.png";
import hip_flexion_left_weak_1 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_left_weak_1.png";
import hip_flexion_left_weak_2 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_left_weak_2.png";
import hip_flexion_right_bad_1 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_right_bad_1.png";
import hip_flexion_right_bad_2 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_right_bad_2.png";
import hip_flexion_right_good_1 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_right_good_1.png";
import hip_flexion_right_good_2 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_right_good_2.png";
import hip_flexion_right_weak_1 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_right_weak_1.png";
import hip_flexion_right_weak_2 from "../../../../../../assets/rom/hip/hip_flexion/hipflexion_right_weak_2.png";

// neck
import neck_extension_bad from "../../../../../../assets/rom/neck/neck_extension/neckextension_bad.png";
import neck_extension_good from "../../../../../../assets/rom/neck/neck_extension/neckextension_good.png";
import neck_extension_weak from "../../../../../../assets/rom/neck/neck_extension/neckextension_weak.png";

import neck_flexion_bad from "../../../../../../assets/rom/neck/neck_flexion/neckflexion_bad.png";
import neck_flexion_good from "../../../../../../assets/rom/neck/neck_flexion/neckflexion_good.png";
import neck_flexion_weak from "../../../../../../assets/rom/neck/neck_flexion/neckflexion_weak.png";

import neck_lateral_flexion_left_bad from "../../../../../../assets/rom/neck/neck_lateral_flexion/necklateralflexion_left_bad.png";
import neck_lateral_flexion_left_good from "../../../../../../assets/rom/neck/neck_lateral_flexion/necklateralflexion_left_good.png";
import neck_lateral_flexion_left_weak from "../../../../../../assets/rom/neck/neck_lateral_flexion/necklateralflexion_left_weak.png";
import neck_lateral_flexion_right_bad from "../../../../../../assets/rom/neck/neck_lateral_flexion/necklateralflexion_right_bad.png";
import neck_lateral_flexion_right_good from "../../../../../../assets/rom/neck/neck_lateral_flexion/necklateralflexion_right_good.png";
import neck_lateral_flexion_right_weak from "../../../../../../assets/rom/neck/neck_lateral_flexion/necklateralflexion_right_weak.png";

// trunk
import trunk_extension_right_bad from "../../../../../../assets/rom/trunk/trunk_extension/trunkextension_right_bad.png";
import trunk_extension_right_good from "../../../../../../assets/rom/trunk/trunk_extension/trunkextension_right_good.png";
import trunk_extension_right_weak from "../../../../../../assets/rom/trunk/trunk_extension/trunkextension_right_weak.png";

import trunk_flexion_right_bad from "../../../../../../assets/rom/trunk/trunk_flexion/trunkflexion_right_bad.png";
import trunk_flexion_right_good from "../../../../../../assets/rom/trunk/trunk_flexion/trunkflexion_right_good.png";
import trunk_flexion_right_weak from "../../../../../../assets/rom/trunk/trunk_flexion/trunkflexion_right_weak.png";

import trunk_lateral_flexion_left_bad from "../../../../../../assets/rom/trunk/trunk_lateral_flexion/trunklateralflexion_left_bad.png";
import trunk_lateral_flexion_left_good from "../../../../../../assets/rom/trunk/trunk_lateral_flexion/trunklateralflexion_left_good.png";
import trunk_lateral_flexion_left_weak from "../../../../../../assets/rom/trunk/trunk_lateral_flexion/trunklateralflexion_left_weak.png";
import trunk_lateral_flexion_right_bad from "../../../../../../assets/rom/trunk/trunk_lateral_flexion/trunklateralflexion_right_bad.png";
import trunk_lateral_flexion_right_good from "../../../../../../assets/rom/trunk/trunk_lateral_flexion/trunklateralflexion_right_good.png";
import trunk_lateral_flexion_right_weak from "../../../../../../assets/rom/trunk/trunk_lateral_flexion/trunklateralflexion_right_weak.png";

export const IMG_DATA = {
  SHOULDER: {
    [SUB_TYPE.SHOULDER_ABDUCTION]: {
      L: [
        abduction_left_good_1,
        abduction_left_weak_1,
        abduction_left_weak_2,
        abduction_left_bad_1,
        abduction_left_bad_2,
        abduction_left_bad_3,
      ],
      R: [
        abduction_right_good_1,
        abduction_right_weak_1,
        abduction_right_weak_2,
        abduction_right_bad_1,
        abduction_right_bad_2,
        abduction_right_bad_3,
      ],
      // L: {
      //   a: [abduction_left_good_1],
      //   b: [abduction_left_weak_1, abduction_left_weak_2],
      //   c: [abduction_left_bad_1, abduction_left_bad_2, abduction_left_bad_3],
      // },
      // R: {
      //   a: [abduction_right_good_1],
      //   b: [abduction_right_weak_1, abduction_right_weak_2],
      //   c: [
      //     abduction_right_bad_1,
      //     abduction_right_bad_2,
      //     abduction_right_bad_3,
      //   ],
      // },
    },
    [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: {
      R: [
        internal_rotation_left_good,
        internal_rotation_left_weak,
        internal_rotation_left_bad,
      ],
      L: [
        internal_rotation_right_good,
        internal_rotation_right_weak,
        internal_rotation_right_bad,
      ],
      // R: {
      //   a: [internal_rotation_left_good],
      //   b: [internal_rotation_left_weak],
      //   c: [internal_rotation_left_bad],
      // },
      // L: {
      //   a: [internal_rotation_right_good],
      //   b: [internal_rotation_right_weak],
      //   c: [internal_rotation_right_bad],
      // },
    },
    [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: {
      R: [
        external_rotation_left_good,
        external_rotation_left_weak,
        external_rotation_left_bad,
      ],
      L: [
        external_rotation_right_good,
        external_rotation_right_weak,
        external_rotation_right_bad,
      ],
      // R: {
      //   a: [external_rotation_left_good],
      //   b: [external_rotation_left_weak],
      //   c: [external_rotation_left_bad],
      // },
      // L: {
      //   a: [external_rotation_right_good],
      //   b: [external_rotation_right_weak],
      //   c: [external_rotation_right_bad],
      // },
    },
    [SUB_TYPE.SHOULDER_FLEXION]: {
      R: [
        shoulder_flexion_left_good_1,
        shoulder_flexion_left_weak_1,
        shoulder_flexion_left_bad_1,
        shoulder_flexion_left_bad_2,
        shoulder_flexion_left_bad_3,
        shoulder_flexion_left_bad_4,
      ],
      L: [
        shoulder_flexion_right_good_1,
        shoulder_flexion_right_weak_1,
        shoulder_flexion_right_bad_1,
        shoulder_flexion_right_bad_2,
        shoulder_flexion_right_bad_3,
        shoulder_flexion_right_bad_4,
      ],
      // R: {
      //   a: [shoulder_flexion_left_good_1],
      //   b: [shoulder_flexion_left_weak_1],
      //   c: [
      //     shoulder_flexion_left_bad_1,
      //     shoulder_flexion_left_bad_2,
      //     shoulder_flexion_left_bad_3,
      //     shoulder_flexion_left_bad_4,
      //   ],
      // },
      // L: {
      //   a: [shoulder_flexion_right_good_1],
      //   b: [shoulder_flexion_right_weak_1],
      //   c: [
      //     shoulder_flexion_right_bad_1,
      //     shoulder_flexion_right_bad_2,
      //     shoulder_flexion_right_bad_3,
      //     shoulder_flexion_right_bad_4,
      //   ],
      // },
    },
    [SUB_TYPE.SHOULDER_EXTENSION]: {
      R: [
        shoulder_extension_left_good,
        shoulder_extension_left_weak,
        shoulder_extension_left_bad,
      ],
      L: [
        shoulder_extension_right_good,
        shoulder_extension_right_weak,
        shoulder_extension_right_bad,
      ],
      // R: {
      //   a: [shoulder_extension_left_good],
      //   b: [shoulder_extension_left_weak],
      //   c: [shoulder_extension_left_bad],
      // },
      // L: {
      //   a: [shoulder_extension_right_good],
      //   b: [shoulder_extension_right_weak],
      //   c: [shoulder_extension_right_bad],
      // },
    },
  },
  KNEE: {
    [SUB_TYPE.KNEE_FLEXION]: {
      R: [
        knee_flexion_left_good_1,
        knee_flexion_left_good_2,
        knee_flexion_left_weak_1,
        knee_flexion_left_weak_2,
        knee_flexion_left_bad_1,
        knee_flexion_left_bad_2,
      ],
      L: [
        knee_flexion_right_good_1,
        knee_flexion_right_good_2,
        knee_flexion_right_weak_1,
        knee_flexion_right_weak_2,
        knee_flexion_right_bad_1,
        knee_flexion_right_bad_2,
      ],
      // R: {
      //   a: [knee_flexion_left_good_1, knee_flexion_left_good_2],
      //   b: [knee_flexion_left_weak_1, knee_flexion_left_weak_2],
      //   c: [knee_flexion_left_bad_1, knee_flexion_left_bad_2],
      // },
      // L: {
      //   a: [knee_flexion_right_good_1, knee_flexion_right_good_2],
      //   b: [knee_flexion_right_weak_1, knee_flexion_right_weak_2],
      //   c: [knee_flexion_right_bad_1, knee_flexion_right_bad_2],
      // },
    },
  },
  HIP: {
    [SUB_TYPE.HIP_FLEXION]: {
      R: [
        hip_flexion_left_good_1,
        hip_flexion_left_good_2,
        hip_flexion_left_weak_1,
        hip_flexion_left_weak_2,
        hip_flexion_left_bad_1,
        hip_flexion_left_bad_2,
      ],
      L: [
        hip_flexion_right_good_1,
        hip_flexion_right_good_2,
        hip_flexion_right_weak_1,
        hip_flexion_right_weak_2,
        hip_flexion_right_bad_1,
        hip_flexion_right_bad_2,
      ],
      // R: {
      //   a: [hip_flexion_left_good_1, hip_flexion_left_good_2],
      //   b: [hip_flexion_left_weak_1, hip_flexion_left_weak_2],
      //   c: [hip_flexion_left_bad_1, hip_flexion_left_bad_2],
      // },
      // L: {
      //   a: [hip_flexion_right_good_1, hip_flexion_right_good_2],
      //   b: [hip_flexion_right_weak_1, hip_flexion_right_weak_2],
      //   c: [hip_flexion_right_bad_1, hip_flexion_right_bad_2],
      // },
    },
    [SUB_TYPE.HIP_EXTENSION]: {
      R: [
        hip_extension_left_good,
        hip_extension_left_weak,
        hip_extension_left_bad,
      ],
      L: [
        hip_extension_right_good,
        hip_extension_right_weak,
        hip_extension_right_bad,
      ],
      // R: {
      //   a: [hip_extension_left_good],
      //   b: [hip_extension_left_weak],
      //   c: [hip_extension_left_bad],
      // },
      // L: {
      //   a: [hip_extension_right_good],
      //   b: [hip_extension_right_weak], // jjy: 이미지 원점이 다름
      //   c: [hip_extension_right_bad],
      // },
    },
  },
  NECK: {
    [SUB_TYPE.NECK_LATERAL_FLEXION]: {
      L: [
        neck_lateral_flexion_left_good,
        neck_lateral_flexion_left_weak,
        neck_lateral_flexion_left_bad,
      ],
      R: [
        neck_lateral_flexion_right_good,
        neck_lateral_flexion_right_weak,
        neck_lateral_flexion_right_bad,
      ],
      // L: {
      //   a: [neck_lateral_flexion_left_good],
      //   b: [neck_lateral_flexion_left_weak],
      //   c: [neck_lateral_flexion_left_bad],
      // },
      // R: {
      //   a: [neck_lateral_flexion_right_good],
      //   b: [neck_lateral_flexion_right_weak],
      //   c: [neck_lateral_flexion_right_bad],
      // },
    },
    [SUB_TYPE.NECK_FLEXION]: [
      neck_flexion_good,
      neck_flexion_weak,
      neck_flexion_bad,
    ],
    // {
    //   a: [neck_flexion_good],
    //   b: [neck_flexion_weak],
    //   c: [neck_flexion_bad],
    // },
    [SUB_TYPE.NECK_EXTENSION]: [
      neck_extension_good,
      neck_extension_weak,
      neck_extension_bad,
    ],
    // {
    //   a: [neck_extension_good],
    //   b: [neck_extension_weak],
    //   c: [neck_extension_bad],
    // },
  },
  TRUNK: {
    [SUB_TYPE.TRUNK_LATERAL_FLEXION]: {
      L: [
        trunk_lateral_flexion_left_good,
        trunk_lateral_flexion_left_weak,
        trunk_lateral_flexion_left_bad,
      ],
      R: [
        trunk_lateral_flexion_right_good,
        trunk_lateral_flexion_right_weak,
        trunk_lateral_flexion_right_bad,
      ],
      // L: {
      //   a: [trunk_lateral_flexion_left_good],
      //   b: [trunk_lateral_flexion_left_weak],
      //   c: [trunk_lateral_flexion_left_bad],
      // },
      // R: {
      //   a: [trunk_lateral_flexion_right_good],
      //   b: [trunk_lateral_flexion_right_weak],
      //   c: [trunk_lateral_flexion_right_bad],
      // },
    },
    [SUB_TYPE.TRUNK_FLEXION]: [
      trunk_flexion_right_good,
      trunk_flexion_right_weak,
      trunk_flexion_right_bad,
    ],
    // {
    //   a: [trunk_flexion_right_good],
    //   b: [trunk_flexion_right_weak],
    //   c: [trunk_flexion_right_bad],
    // },
    [SUB_TYPE.TRUNK_EXTENSION]: [
      trunk_extension_right_good,
      trunk_extension_right_weak,
      trunk_extension_right_bad,
    ],
    // {
    //   a: [trunk_extension_right_good],
    //   b: [trunk_extension_right_weak],
    //   c: [trunk_extension_right_bad],
    // },
  },
};

export const DEGREE_DETAIL_DATA = {
  SHOULDER: {
    [SUB_TYPE.SHOULDER_ABDUCTION]: [160, 125, 90, 60, 30, 0],
    [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: [80, 45, 0],
    [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: [80, 45, 0],
    [SUB_TYPE.SHOULDER_FLEXION]: [160, 125, 90, 60, 30, 0],
    [SUB_TYPE.SHOULDER_EXTENSION]: [50, 30, 0],
  },
  KNEE: {
    [SUB_TYPE.KNEE_FLEXION]: [120, 100, 75, 50, 25, 0],
  },
  HIP: {
    [SUB_TYPE.HIP_FLEXION]: [120, 100, 75, 50, 25, 0],
    [SUB_TYPE.HIP_EXTENSION]: [20, 10, 0],
  },
  NECK: {
    [SUB_TYPE.NECK_LATERAL_FLEXION]: [40, 20, 0],
    [SUB_TYPE.NECK_FLEXION]: [40, 25, 0],
    [SUB_TYPE.NECK_EXTENSION]: [50, 30, 0],
  },
  TRUNK: {
    [SUB_TYPE.TRUNK_LATERAL_FLEXION]: [30, 15, 0],
    [SUB_TYPE.TRUNK_FLEXION]: [60, 45, 0],
    [SUB_TYPE.TRUNK_EXTENSION]: [20, 10, 0],
  },
};

export const DEGREE_DATA = {
  SHOULDER: {
    [SUB_TYPE.SHOULDER_ABDUCTION]: [180, 160, 90],
    [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: [90, 80, 45],
    [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: [90, 80, 45],
    [SUB_TYPE.SHOULDER_FLEXION]: [180, 160, 90],
    [SUB_TYPE.SHOULDER_EXTENSION]: [60, 50, 30],
  },
  KNEE: {
    [SUB_TYPE.KNEE_FLEXION]: [135, 120, 100],
  },
  HIP: {
    [SUB_TYPE.HIP_FLEXION]: [135, 120, 100],
    [SUB_TYPE.HIP_EXTENSION]: [30, 20, 10],
  },
  NECK: {
    [SUB_TYPE.NECK_LATERAL_FLEXION]: [45, 40, 20],
    [SUB_TYPE.NECK_FLEXION]: [50, 40, 25],
    [SUB_TYPE.NECK_EXTENSION]: [60, 50, 30],
  },
  TRUNK: {
    [SUB_TYPE.TRUNK_LATERAL_FLEXION]: [40, 30, 15],
    [SUB_TYPE.TRUNK_FLEXION]: [90, 60, 45],
    [SUB_TYPE.TRUNK_EXTENSION]: [30, 20, 10],
  },
};

// angle center
//basepoint top 은 부채 기준
export const CANVAS_POINT = { //centerpoint, basepoint, negative(left, right)
  SHOULDER: {
    [SUB_TYPE.SHOULDER_ABDUCTION]: [ //left, right
      [[294, 231], [294, 366], false], //centerpoint, basepoint, negative(left, right)
      [[302, 231], [302, 366], true],
    ],
    [SUB_TYPE.SHOULDER_INTERNAL_ROTATION]: [
      //left, right
      [[298, 252], [454, 252], true],
      [[298, 252], [141, 252], false], //centerpoint, basepoint, negative(left, right)
    ],
    [SUB_TYPE.SHOULDER_EXTERNAL_ROTATION]: [ //left, right
      [[298,231],[454, 231], false],
      [[298,231],[141, 231], true] //centerpoint, basepoint, negative(left, right)
    ],
    [SUB_TYPE.SHOULDER_FLEXION]: [ //left, right
      [[298,244],[298, 378], true],
      [[299,244],[299, 378], false] //centerpoint, basepoint, negative(left, right)
    ],
    [SUB_TYPE.SHOULDER_EXTENSION]:[ //left, right
      [[297,203],[297, 338], false],
      [[299,203],[299, 338], true] //centerpoint, basepoint, negative(left, right)
    ],
  },
  KNEE: {
    [SUB_TYPE.KNEE_FLEXION]: [ //left, right
      [[297,229],[297, 366], false],
      [[299,229],[299, 366], true]
    ]
  },
  HIP: {
    [SUB_TYPE.HIP_FLEXION]: [ //left, right
      [[297,200],[297, 336], true],
      [[299,200],[297, 336], false]
    ],
    [SUB_TYPE.HIP_EXTENSION]: [ //left, right
      [[297,192],[297, 344], false],
      [[299,200],[299, 344], true]
    ]
  },
  NECK: {
    [SUB_TYPE.NECK_LATERAL_FLEXION]: [ //left, right
      [[297,307],[297, 128], true],
      [[297,307],[297, 128], false]
    ],
    [SUB_TYPE.NECK_FLEXION]: [ //left, right
      [[298,245],[298, 67], true],
      [[298,245],[298, 67], true]
    ],
    [SUB_TYPE.NECK_EXTENSION]: [ //left, right
      [[297,245],[298, 72], false],
      [[297,245],[298, 72], true]
    ]
  },
  TRUNK: {
    [SUB_TYPE.TRUNK_LATERAL_FLEXION]: [ //left, right
      [[297,307],[297, 127], true],
      [[298,307],[298, 128], false]
    ],
    [SUB_TYPE.TRUNK_FLEXION]: [ //left, right
      [[300,239],[300, 73], true],
      [[297,268],[297, 73], false]
    ],
    [SUB_TYPE.TRUNK_EXTENSION]: [ //left, right
      [[297,268],[297, 56], false],
      [[297,268],[297, 56], false]
    ]
  },
};
