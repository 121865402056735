import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import css from "./WorkoutPlayerModal.module.less";
import { memoize } from "@enact/core/util";
import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import ProgressBar from "@ramonak/react-progress-bar";
import Slider from "@enact/sandstone/Slider"
import DurationFmt from 'ilib/lib/DurationFmt';
import { Job } from "@enact/core/util";
import classNames from "classnames";
import { $L } from "../../../../utils/helperMethods";

import * as Config from "../../../../utils/Config";
import Times from '../../../../components/Times/Times';
import TButton, { TYPES } from "../../../../components/TButton/TButton";

import useSetSolution from "../../../../hooks/useSetSolution";

const memoGetDurFmt = memoize((/* locale */) => new DurationFmt({length: 'long', style: 'clock', useNative: false}));
const getDurFmt = () => {
  if (typeof window === 'undefined') return null;

	return memoGetDurFmt();
};
const DUR_FMT = getDurFmt();

const SpottableComponent = Spottable("div");
const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const WorkoutPlayerModal = ({ startPlayingVideoJob, setPlayVideo, playVideo, playDuration, playProgress, onClick, onVideoSeek, size, bgsyncup, autoPtz, setAutoPtz }) => {
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const [pressed, setPressed] = useState(false);
  const [bgMusic, setBgMusic] = useState(false); //todo
  const { activeAutoPTZ } = useSetSolution();

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const toggleHandler = useCallback(() => {
    setPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick();
      }
    });
  },[onClick]);

  const onBlur = useCallback(() => {
    setPressed(false);
    clearPressedJob.current.stop();
  }, []);

  useEffect(() => {
    playVideo && startPlayingVideoJob.startAfter(500, setPlayVideo);
  }, [playVideo]);

  useEffect(() => {
    Spotlight.focus("#pausePlayToggle");
    // return(() => {
    //   Spotlight.focus("#shakaWorkoutPlayer");
    // })
  }, []);

  const onChangeProgress = useCallback((ev) => {
    if(onVideoSeek && ev && ev.value) {
      onVideoSeek(ev.value)
    };
  },[onVideoSeek, playProgress]);

  const onClickAutoPtz = useCallback(() => {
    setAutoPtz(!autoPtz);
    activeAutoPTZ({ autoPtz: !autoPtz });
  },[autoPtz]);

  const onClickMusic = useCallback(() => {
    //todo
    setBgMusic(!bgMusic)
  },[bgMusic]);

  return (
    <div className={classNames(css.modal, css[size])}>
      <div className={css.cl_modalDimmer}/>
      <div className={css.container}>
        <Container className={css.progressBarContainer}>
          <SpottableComponent
            id="pausePlayToggle"
            className={css.playIcon}
            onClick={toggleHandler}
            onBlur={onBlur}
          >
            <div className={classNames(playVideo ? css.pause : css.play, pressed ? css.pressed : null)}/>
          </SpottableComponent>
          <Slider
            className={css.progressBar}
            activateOnSelect={false}
            knobStep={1}
            onChange={onChangeProgress}
            value={playProgress ? playProgress: 0}
            min={0}
            max={Math.floor(playDuration)}
            noFill={false}
          />
          <div className={css.timeContainer}>
            <Times noCurrentTime total={playProgress ? playProgress: 0} formatter={DUR_FMT} />
            <Times noTotalTime current={playDuration} formatter={DUR_FMT} />
          </div>
          {!cesShowMode &&
          <div className={css.bottomButton}>
            <TButton
              className={css.bButton}
              type={TYPES.withIcon}
              icon={autoPtz ? 'autoPtzOn' : 'autoPtzOff'}
              onClick={onClickAutoPtz}
              disabled={bgsyncup}
            >
              {$L("Auto PTZ")}
            </TButton>
            <TButton
              className={css.bButton}
              type={TYPES.withIcon}
              icon={bgMusic ? 'musicOn' : 'musicOff'}
              onClick={onClickMusic}
              disabled={true}
            >
              {$L("Music")}
            </TButton>
          </div>}
        </Container>
      </div>
    </div>
  );
};

export default WorkoutPlayerModal;
