
import React,{ useMemo, useEffect, useState } from "react";
/* eslint-disable */
const SmoodShowingAnimation = (WrappedComponent) => {
  return (props) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => {
          setIsMounted(false);
        };
    }, []);

    const aniStyle = useMemo(()=>{
        if(isMounted){
            return {opacity:1, transition: "opacity 0.3s ease"};
        }
        return {opacity: 0}
    },[isMounted]);

    return (
        <WrappedComponent {...props} style={aniStyle}/>
    );
  };
}

export default SmoodShowingAnimation;