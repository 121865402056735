import React, { useMemo, useRef, useState, useEffect, useCallback } from "react";
import css from "./FootPositionCorrectionSign.module.less";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import { $L } from "../../../../utils/helperMethods";

const FootPositionCorrectionSign = ({matReportMessage, timeOut=3000, twoLegType=false, onEnded}) => {
  const timeOutRef = useRef(null);
  const [msgInfo, setMsgInfo] = useState({});
  // const singleLegFinishedRef = usePrevious(singleLegFinished);

  const signInfo = useMemo(() => {
    console.log('FootPositionCorrectionSign matReportMessage', matReportMessage);
    const ret = {instruction: "", voiceGuide:""};

    if(matReportMessage?.matStatus === 'not ready'){
      switch (matReportMessage?.matStatusValue) {
        case 0: {
          ret.instruction = $L("Place your left foot correctly on the mat.");
          ret.voiceGuide = 'singleLegLeft';
          break;
        }
        case 1: {
          if(twoLegType){
            ret.instruction = $L("Place your feet correctly on the mat.");
            ret.voiceGuide = 'noLeg';
          }else{
            ret.instruction =$L("Place your right foot correctly on the mat.");
            ret.voiceGuide = 'singleLegRight';
          }
          break;
        }
        case 2: {
          ret.instruction =  $L("Your left foot is on the line. Step back.");
          ret.voiceGuide = 'backLeft';
          break;
        }
        case 3:{
          ret.instruction = $L("Your left foot is on the line. Step forward.");
          ret.voiceGuide = 'forwardLeft';
          break;
        }
        case 4:{
          ret.instruction = $L("Your left foot is on the line. Move to your right.");
          ret.voiceGuide = 'rightLeft';
          break;
        }
        case 5:{
          ret.instruction = $L("Your left foot is on the line. Move to your left.");
          ret.voiceGuide = 'leftLeft';
          break;
        }
        case 6:{
          ret.instruction = $L("Your right foot is on the line. Step back.");
          ret.voiceGuide = 'backRight';
          break;
        }
        case 7:{
          ret.instruction = $L("Your right foot is on the line. Step forward.");
          ret.voiceGuide = 'forwardRight';
          break;
        }
        case 8:{
          ret.instruction = $L("Your right foot is on the line. Move to your right.");
          ret.voiceGuide = 'rightRight';
          break;
        }
        case 9:{
          ret.instruction = $L("Your right foot is on the line. Move to your left.");
          ret.voiceGuide = 'leftRight';
          break;
        }
        case 10:{
          ret.instruction = $L("You're stepping on the opposite foot. Switch feet.");
          ret.voiceGuide = 'otherFoot';
          break;
        }
      }
    }
    if(ret.instruction && !ret.voiceGuide){
      ret.voiceGuide = ret.instruction;
    }
    return ret;
  }, [matReportMessage, twoLegType]);

  const clearMessage = useCallback(()=>{
    if(timeOutRef.current){
      clearTimeout(timeOutRef.current);
      timeOutRef.current=null;
    }
    timeOutRef.current = setTimeout(()=>{
      setMsgInfo({});
    }, timeOut);
  },[timeOut]);

  useEffect(()=>{
    if(signInfo.instruction){
      setMsgInfo({...signInfo});
    }
    clearMessage();
  },[signInfo]);

  return (
    <>
      {msgInfo.instruction &&
        <div className={css.instructionSign}>{msgInfo.instruction}</div>
      }
      <AudioPlayer srcTypeStr={msgInfo.voiceGuide} onEnded={onEnded}/>
    </>
  );
};

const propsAreEqual = (prev, next) => {
	const keys = Object.keys(prev);
	const nextKeys = Object.keys(next);
	if(keys.length !== nextKeys.length){
		return false;
	}
	for(let i=0; i<keys.length; i++){
		if(prev[keys[i]] !== next[keys[i]]){
      //ignore same matReportMessage
      // if(keys[i] === 'matReportMessage' && (JSON.stringify(prev[keys[i]]) === JSON.stringify(next[keys[i]]))){
      //  continue;
      // }
			return false;
		}
	}
	return true;
}
export default React.memo(FootPositionCorrectionSign, propsAreEqual);