import css from './DetailLayout.module.less';
import TButton from '../../../../components/TButton/TButton';
import TPopUp from '../../../../components/TPopUp/TPopUp';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TCalendar from '../../../../components/TCalendar/TCalendar';
import { $L } from '../../../../utils/helperMethods';
import GuideImage from "../../../../../assets/guideImage.svg";
import SmartMatPopup from '../../../SmartMat/SmartMatPopup';
import TestSelectPopup from '../../../../components/TestSelectPopup/TestSelectPopup';
import StarRatingPopup from '../../../../components/StarRatingPopup/StarRatingPopup';
import { getBBT_INFO } from '../../../BodyCheckUp/BodyBalance/BodyBalanceTest/Constants';
import CameraPopup from '../../../Camera/CameraPopup';

let BBT_INFO = null;
const TPopUpLayout = () => {
  if(!BBT_INFO){
    BBT_INFO = getBBT_INFO();
  }
  const [isTextClosed, setIsTextClosed] = useState(true);
  const [isText2Closed, setIsText2Closed] = useState(true);
  const [isCalendarClosed, setIsCalendarClosed] = useState(true);
  const [isImageClosed, setIsImageClosed] = useState(true);
  const [isSmartMatPopupOpen, setIsSmartMatPopupOpen] = useState(false);
  const [isCameraPopupOpen, setIsCameraPopupOpen] = useState(false);
  const [isTestSelectOpen, setIsTestSelectOpen] = useState(false);
  const [isStarRatingOpen, setIsStarRatingOpen] = useState(false);

  const onClickPopUpBtn = useCallback(() => {
    setIsTextClosed(!isTextClosed)
  }, [isTextClosed]);
  const onClickPopUpBtnWithButton1Only = useCallback(() => {
    setIsText2Closed(!isText2Closed)
  }, [isText2Closed]);
  const popUpCalendar = useCallback(() => {
    setIsCalendarClosed(!isCalendarClosed)
  }, [isCalendarClosed]);
  const popUpImage = useCallback(() => {
    setIsImageClosed(!isImageClosed)
  }, [isImageClosed]);

  // for smartMat BLE
  const popupSmartMat = useCallback(() => {
    setIsSmartMatPopupOpen(!isSmartMatPopupOpen);
  }, [isSmartMatPopupOpen]);

  const popupTestSelectPopup = useCallback(()=>{
    setIsTestSelectOpen(!isTestSelectOpen);
  },[isTestSelectOpen])

  const popupStarRatingPopup = useCallback(()=>{
    setIsStarRatingOpen(!isStarRatingOpen);
  },[isStarRatingOpen])

  const popupCamera = useCallback(()=>{
    setIsCameraPopupOpen(!isCameraPopupOpen);
  },[isCameraPopupOpen])

    return (
      <div className={css.popupLayout}>
        <TButton onClick={onClickPopUpBtn}>
          PopUp Text
        </TButton>
        {!isTextClosed &&
          <TPopUp
            kind="textPopUp"
            title={$L("TITLE")}
            text={$L("텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스텍스트텍스트텍스텍스트텍스트텍스트텍스트텍스텍스트텍스트텍스텍스트텍스트텍스트텍스트텍")}
            button1text={$L("Cancel")}
            button2text={$L("Verified")}
            onClose={onClickPopUpBtn}
          >
          </TPopUp>
        }
        <TButton onClick={onClickPopUpBtnWithButton1Only} withMarquee={true}>
          popUpTextWithButton1
        </TButton>
        {!isText2Closed &&
          <TPopUp
            kind="textPopUp"
            title={$L("TITLE")}
            text={$L("텍스트텍스트텍스트텍스트텍스트텍스트텍스트텍스텍스트텍스트텍스텍스트텍스트텍스트텍스트텍스텍스트텍스트텍스텍스트텍스트텍스트텍스트텍")}
            button1text={$L("Cancel")}
            onClose={onClickPopUpBtnWithButton1Only}
          >
          </TPopUp>
        }
        <TButton onClick={popUpCalendar}>
          PopUp Calendar
        </TButton>

        {!isCalendarClosed &&
          <TPopUp
            kind="calendarPopUp"
            title={$L("날짜 선택")}
            button2text={$L("button2")}
            onClose={popUpCalendar}
          >
            <TCalendar/>
          </TPopUp>

        }
        <TButton onClick={popUpImage}>
          PopUp Image
        </TButton>
        {!isImageClosed &&
          <TPopUp
            kind="imagePopUp"
            title={$L("How to connect your camera")}
            text={$L("Connect LG Smart TV dedicated camera to the USB port. Once your camera is connected, you can use assessment/test and My Workout Programs. {br} We can also recommend personalized workouts.")}
            image={GuideImage}
            button2text={$L("button2")}
            onClose={popUpImage}
          >
          </TPopUp>
        }
        {/* startMat BLE, connected 상태에서는 open 안되게...*/}
        <TButton onClick={popupSmartMat}>
          PopUp Smart Mat
        </TButton>
        {isSmartMatPopupOpen &&
          <SmartMatPopup onClose={popupSmartMat}/>
        }
        <TButton onClick={popupTestSelectPopup}>
          TestSelect Popup
        </TButton>
        {isTestSelectOpen &&
          <TestSelectPopup testInfos={BBT_INFO} onClose={popupTestSelectPopup}/>
        }
        <TButton onClick={popupStarRatingPopup}>
          StarRating Popup
        </TButton>
        {isStarRatingOpen &&
          <StarRatingPopup onClose={popupStarRatingPopup}/>
        }
        <TButton onClick={popupCamera}>
          PopUp Camera
        </TButton>
        {isCameraPopupOpen &&
          <CameraPopup onClose={popupCamera}/>
        }
      </div>
    );
};

export default TPopUpLayout;
