
const MIN_DISTANCE = 22;
export default class BellySmoother {
    constructor (queueSize = 10) {
        this.smoothMode = true;
        this.queue = [];
        this.queueSize = queueSize;
        this.average = [];
    }
    pushValue = (x, y) => {
        if(this.queue.length >= this.queueSize){
            this.queue.shift();
		}
        if(this.average[0]){
            const dx = (x - this.average[0]);
            const dy = (y-this.average[1]);
            const distance = Math.sqrt(dx*dx + dy*dy);
            if(distance > MIN_DISTANCE){
                this.queue = [];
            }
        }
        this.queue.push([x,y]);

        let sumX = 0, sumY = 0;
        for(let i=0; i<this.queue.length; i++){
            sumX += this.queue[i][0];
            sumY += this.queue[i][1];
        }
        if(this.queue.length <= 0){
            this.average = [];
        }
        if(this.smoothMode){
            this.average = [sumX/this.queue.length, sumY/this.queue.length];
        }else{
            this.average = [this.queue[this.queue.length-1][0], this.queue[this.queue.length-1][1]];
        }
    }
    getAverageData = () => {
        return this.average;
    }
    clearQueue = () => {
        this.queue = [];
    }
    changeQueueSize = (queueSize) => {
        this.queueSize = queueSize;
        this.clearQueue();
    }
    changeSmoothMode = (mode) => {
        this.smoothMode = mode;
    }
}
