import { $L } from "../../../../utils/helperMethods";
export const bodyBalanceMenu = {
  OVERALL: "OVERALL",
  FOOT_TYPE: "FOOT_TYPE",
  BODY_BALANCE_TAB: "BODY_BALANCE_TAB",
  COP: "COP",
  WALKING_PATH: "WALKING_PATH",
  WALK_IN_PLACE: "WALK_IN_PLACE",
};
export const getBodyBalanceMenuTitle = () => ({
  [bodyBalanceMenu.OVERALL]: $L("Overall"), //Overall
  [bodyBalanceMenu.FOOT_TYPE]: $L("Foot Shape"), //족부 형상
  [bodyBalanceMenu.BODY_BALANCE_TAB]: $L("Two-Leg Balance"), //양발 균형
  [bodyBalanceMenu.COP]: $L("One-Leg Balance"), //외발 균형
  [bodyBalanceMenu.WALKING_PATH]: $L("Gait"), //보행경로
  [bodyBalanceMenu.WALK_IN_PLACE]: $L("Walk in place"), //제자리 걸음 분석
});

export const getEmptyResultsReportData = () => {
  const BodyBalanceList = {
    [bodyBalanceMenu.OVERALL]: {
      title: $L(
        "스마트 매트 검사를 통해 하체 건강도 챙기고 관련된 노화 질환을 예방할 수 있어요."
      ),
      detail: $L(
        "신체 균형 감각을 평가하거나 족부 형상을 확인할 수 있고 매트 위를 걸어가는 보행경로 검사를 통해서는 평소 나의 걸음걸이 습관까지 한눈에 볼 수 있답니다."
      ),
    },
    [bodyBalanceMenu.FOOT_TYPE]: {
      title: $L("You can check whether you have normal feet, flat feet, or cavus feet by checking both feet."),
      detail: $L(
        "발은 발목, 다리, 골반 그리고 척추 등 정렬 상태에 영향을 주므로 나의 발 상태를 아는 것은 중요해요."
      ),
    },
    [bodyBalanceMenu.BODY_BALANCE_TAB]: {
      title: $L("You can check your balance by checking both feet."),
      detail: $L(
        "균형 능력은 계단 오르내리기, 대중교통에서 서 있기 등 일상생활을 수행하는데 있어서 가장 기본이 되는 필수 요소에요."
      ),
    },
    [bodyBalanceMenu.COP]: {
      title: $L("You can check your balance by checking one foot."),
      detail: $L(
        "특히, 외발서기는 양발서기에 비해 근육과 균형감각의 조정 능력이 더욱 요구되어 낙상 위험과도 연관이 높습니다."
      ),
    },
    [bodyBalanceMenu.WALKING_PATH]: {
      title: $L("Footpath"),
      detail: $L(
        "보행검사는 동적 족부압력 및 압력중심점 변화를 평가 합니다.{br} 올바른 보행은 평소 몸의 균형감각과 신경학적기능의 협응성이 요구 됩니다."
      ),
      subheading: $L("Test Result"),
      list: $L(
        "1. 양측어깨 높이 차이{br} 2. 발의 압력분포(전족부, 중족부, 후족부, COP){br} 3. 보행 시 발의 형상(정적 상태와 비교되는 데이터)"
      ),
    },
    [bodyBalanceMenu.WALK_IN_PLACE]: {
      title: $L("제자리걸음"),
      detail: $L(
        "양발 서기, 외발 서기 등 균형감각을 평가할 수 있고, 평발, 요족 등 족부형상에 대한 진단도 가능합니다.{br} 매트 위를 걸어가는 보행경로로 하지 관련 진단이 가능합니다."
      ),
    },
  };
  return BodyBalanceList;
};