import classNames from 'classnames';
import React from "react";
import css from './Header.module.less';

const Header = ({children, className, ...rest}) => {
	return (
		<div className={classNames(css.header, className)} {...rest}>
			{children}
		</div>
	);
};

export default Header;