import { createSlice } from "@reduxjs/toolkit";

export const walking = createSlice({
  name: "walking",
  initialState: {
    summary: {}, // { [date]: {]} }
    dateList: [],
    progressInfos: {}, // { [contentId]: {} }
    activityHistorySummary: {},
    activityHistory: []
  },
  reducers: {
    updateSummary: (state, action) => {
      const { date, value } = action.payload;

      state.summary[date] = Object.assign({}, state.summary[date], value);
    },
    updateDateList: (state, action) => {
      state.dateList = action.payload;
      state.dateList.sort();
    },
    updateActivityHistorySummary: (state, action) => {
      state.activityHistorySummary = action.payload;
    },
    updateActivityHistory: (state, action) => {
      const { data, append } = action.payload;

      if (append && Array.isArray(state.activityHistory) && state.activityHistory.length > 0) {
        state.activityHistory = state.activityHistory.concat(data);
      } else {
        state.activityHistory = data;
      }
    },
    updateProgressInfos: (state, action) => {
      const { date, value } = action.payload;

      if (state.dateList.indexOf(date) < 0) {
        state.dateList.push(date);
      }

      state.progressInfos[value.contentId] = Object.assign({}, state.progressInfos[value.contentId], value);
    }
  }
});

export const {
  updateSummary,
  updateDateList,
  updateActivityHistorySummary,
  updateActivityHistory,
  updateProgressInfos
} = walking.actions;

export default walking.reducer;
