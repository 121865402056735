import css from "./TUserCheckPopup.module.less";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAppStatus, launchMembershipApp } from "../../features/common/commonSlice";
import { $L } from "../../utils/helperMethods";
import TPopUp from "../TPopUp/TPopUp";
import { addPanels } from "../../features/panels/panelsSlice";
import { panel_names } from "../../utils/Config";
import useUserCheck from "../../hooks/useUserCheck";
import { postSurvey, updateSurvey } from "../../features/fitService/fitServiceSlice";
import { useMemo } from "react";
import { getResetAnswerData } from "../../utils/surveyData";

const TUserCheckPopup = ({ showUserCheckPopup, ...rest }) => {
  const dispatch = useDispatch();
  const { userCheckType } = useUserCheck();
	const [showingStatus, setShowingStatus] = useState(false);
  const { profileNick } = useSelector((state) => state.common.appStatus);
  const { lastpage } = useSelector((state) => state.fitService.survey);
  const resetAnswerData = getResetAnswerData();
  const surveyTitle = '운동정보'; // todo
  const mode = showUserCheckPopup.mode;

  useEffect(() => {
    setShowingStatus(showUserCheckPopup.show)
  }, [showUserCheckPopup]);

  const _onClick = useCallback((index) => {
    if (userCheckType === 'login') {
      if (index === 1) {
        dispatch(launchMembershipApp());
      }
    } else if (userCheckType === 'terms') {
      if (index === 1) {
        dispatch(addPanels({ name: panel_names.TERMS_PANEL }));
      }
    } else if (userCheckType === 'survey') {
      if (index === 0) {
        // 처음부터 입력하기, 저장된 정보 reset 후 첫 화면
        dispatch(updateSurvey(resetAnswerData))
        dispatch(postSurvey());
      }
      if (typeof index === 'number') {
        dispatch(addPanels({ name: panel_names.SURVEY_PANEL }));
      }
    }
    dispatch(changeAppStatus({ showUserCheckPopup: { show: false } }));
    setShowingStatus(false);
}, [dispatch, userCheckType]);

  const contents =  useCallback(() => {
    if (userCheckType === 'login') {
      const TEXT = {
        genaral: {
          text: $L("You need to log in to access the contents."),
	  text1: $L("Do you want to log in and start the service?")
        },
        play: {
          text: $L("You need to log in to work out."),
	  text1: $L("Do you want to log in and try the workout?")
        },
        test: {
          text: $L("Log in to get tested or scanned."),
          text1: $L("Do you want to log in and get tested?")
        }
      }

      return (
        <TPopUp
          kind="textPopUp"
          className={css.TLoginPopup}
          title={$L("Log In")}
          text={TEXT[mode]?.text}
          text1={TEXT[mode]?.text1}
          button1text={$L("Do it later")}
          button2text={$L("Log In")}
          onClick={_onClick}
          onClose={_onClick}
        />
      );
    } else if (userCheckType === 'terms') {
      return (
        <TPopUp
          kind="textPopUp"
          className={css.TTermsPopup}
          title={$L("A Terms & Conditions agreement is required.")}
          text={$L("Tick the 'I Agree' checkbox confirming that you've read and agree to terms and conditions and ready to use the service.")}
          text1={$L("Tap [See Terms & Conditions] to move to the terms & conditions agreement page.")}
          button1text={$L("Do it later")}
          button2text={$L("See Terms and Conditions")}
          onClick={_onClick}
          onClose={_onClick}
        />
      );
    } else if (userCheckType === 'survey') {
      const title = [$L("Basic Information"), $L("바디정보"), $L("운동정보"), $L("생활정보")]

      return (
        <TPopUp
          kind="textPopUp"
          className={css.TTermsPopup}
          title={$L("Do you want to continue filling out {PAGE_NAME}?").replace("{PAGE_NAME}", title[lastpage])}
          text={$L("There is a {PAGE_NAME} you did not finish filling out. Do you want to go back and continue?").replace("{PAGE_NAME}", title[lastpage])}
          text1={$L("Enter {PAGE_NAME} to see your personalized workouts recommendations.").replace("{PAGE_NAME}", title[lastpage]).replace("{NICKNAME}", profileNick)}
          button1text={$L("Enter from the beginning")}
          button2text={$L("Continue typing")}
          onClick={_onClick}
          onClose={_onClick}
        />
      )
    }
    return null;
  },[showUserCheckPopup, userCheckType, surveyTitle]);

  if (showingStatus) {
    return <>{contents()}</>
  } else {
    return null;
  }
};

export default TUserCheckPopup;
