export const convertThemeColor = (iconType) => {
  if (typeof window === "object") {
    const { themeMode } =
      typeof window === "object" && window.store.getState().common.appStatus;
    const theme = {
      light: {
        normal: "#DBD7D4",
        expanded: "#494847",
        focused: "#FEFEFE",
        selected: "#FEFEFE",
      },
      dark: {
        normal: "#B2B0AE",
        expanded: "#F2EFED",
        focused: "#494847",
        selected: "#CFBF8C",
      },
      transparent: {
        normal: "#DBD7D4",
        expanded: "#494847",
        focused: "#FEFEFE",
        selected: "#FEFEFE",
      },
    };
    return theme[themeMode][iconType];
  } else {
    return;
  }
};
