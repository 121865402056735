import { useCallback } from 'react';
import Spottable from '@enact/spotlight/Spottable';
import css from './HomecareWorkoutBtn.module.less';
import classNames from 'classnames';

const SpottableComponent = Spottable('div');

const HomecareWorkoutBtn = ({ title, detail, image, color, children, spotlightId, className, onClick, ...rest }) => {
    const _onClick = useCallback(
        (ev) => {
            if (onClick) {
                onClick(ev);
            }
            ev.persist();
        },
        [onClick]
    );

    return (
        <SpottableComponent
            {...rest}
            className={classNames(css.HomecareWorkoutBtn, className ? className : null)}
            onClick={_onClick}
            data-webos-voice-intent={'Select'}
            spotlightId={spotlightId}
            style={{ backgroundImage: `url(${image})`, backgroundColor: `${color}` }}
            spotlightDisabled
        >
            {title && <div className={css.title}>{title}</div>}
            {detail && <div className={css.detail}>{detail}</div>}
            {/* {color && <div className={css.color} style={{ backgroundColor: `${color}` }}></div>} */}
        </SpottableComponent>
    );
};

export default HomecareWorkoutBtn;
