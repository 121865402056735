import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Job } from "@enact/core/util";
import css from "./CommonButtonLayer.module.less";
import TButton, { SIZES } from "../../../../../components/TButton/TButton";

const CommonButtonLayer = ({ buttonTitles = [], disabled = [], onClick }) => {
  const _onClick = useCallback(
    (index) => () => {
      onClick(index);
    },
    [onClick]
  );
  return (
    <div className={css.buttonLayer}>
      {buttonTitles.map((title, index) => {
        return (
          <TButton
            key={"common_button_" + index}
            size={SIZES.test}
            className={css.button}
            disabled={disabled[index]}
            onClick={_onClick(index)}
          >
            {title}
          </TButton>
        );
      })}
    </div>
  );
};
export default CommonButtonLayer;
