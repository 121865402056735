import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {Job} from '@enact/core/util';
import {TAxios} from './TAxios';
import {URLS} from './fitServiceConfig';
import * as BodyAlignmentSlice from "../bodyAlignment/bodyAlignmentSlice";
import * as physicalTestSlice from "../physicalTest/physicalTestSlice";
import * as BodyBalanceSlice from "../bodyBalance/bodyBalanceSlice";
import * as RomSlice from "../rom/romSlice"
import * as contentSlice from "../content/contentSlice"
import * as walkingSlice from "../activity/walkingSlice";
import * as activitySlice from "../activity/activitySlice";
import * as homecareProgramSlice from "../homecareProgram/homecareProgramSlice";
import { dispatch } from "d3-dispatch";
import contentList from "../../../assets/mock/dummyContentList.json";
import aiList from "../../../assets/mock/dummyAiList.json";
import weeklySolution from "../../../assets/mock/dummyWeeklySolution.json";
import { getResetAnswerData } from "../../utils/surveyData";
import * as Config from '../../utils/Config';
import * as HelperMethods from '../../utils/helperMethods';
import { BODY_CHECKUP_TYPE } from "../../utils/Constants";
import { sendBroadCast } from "../common/commonSlice";

const notOnTv = (typeof window === 'object' && !window.PalmSystem);

const initialState = {
  policy: undefined,
  survey: "",
  settings: {
    bgmId: 0,
    bgmYn: true,
    centerScreenAlignmentYn: false,
    concede: 'high',
    footPrintId: 0,
    footPrintYn: true,
    motivationSetYn: false,
    playWay: "order",
    skipGuideYn: false,
    stride: 70,
    strideAutoYn: false,
    userNo: "string",
    wallpaperId: 0,
    wallpaperList: [],
    wallpaperYn: false,
    footPrintRecommendYn: false
  }
};
/*
 BODY ALIGNMENT
*/
export const getBodyAlignmentDateList = createAsyncThunk("fitService/getBodyAlignmentDateList", async (_, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getBodyAlignmentDateList onSuccess ', response.data);
    thunkAPI.dispatch(BodyAlignmentSlice.updateDateList(response.data));
    // if(typeof window === 'object' && !window.PalmSystem){ //todo remove
    //   thunkAPI.dispatch(BodyAlignmentSlice.updateDateList(["2023-07-18", "2023-08-03"]));
    // }
  }
  const onFail = (response) => {
    console.log('getBodyAlignmentDateList onFail ', response);
    //todo
    thunkAPI.dispatch(BodyAlignmentSlice.updateDateList(["2023-07-18", "2023-08-03"]));
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.BODY_ALIGNMENT_DATELIST, {}, {}, onSuccess, onFail, false);
});

const getBodyAlignmentData = createAsyncThunk("fitService/getBodyAlignmentData", async (date, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getBodyAlignmentData onSuccess ', response.data);
    if(response.data){
      thunkAPI.dispatch(BodyAlignmentSlice.updateTestResults({date, value: response.data}));
    }
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  const onFail = (response) => {
    console.log('getBodyAlignmentData onFail ', response);
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.BODY_ALIGNMENT, {testDate: date}, {}, onSuccess, onFail, false);
});
export const postBodyAlignmentData = createAsyncThunk("fitService/postBodyAlignmentData", async (value, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('postBodyAlignmentData onSuccess ', response.data);
  }
  const onFail = (response) => {
    console.log('postBodyAlignmentData onFail ', response);
  }
  if (typeof window === 'object' && window.PalmSystem) {
    TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.BODY_ALIGNMENT, {}, value, onSuccess, onFail, false);
  }
});
/*
PHYSICALTEST
*/
export const getPhycalTestDateList = createAsyncThunk("fitService/getPhycalTestDateList", async (_, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getPhycalTestDateList onSuccess ', response.data);
    thunkAPI.dispatch(physicalTestSlice.updateDateList(response.data));
  }
  const onFail = (response) => {
    console.log('getPhycalTestDateList onFail ', response);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.PHYSICALTEST_DATELIST, {}, {}, onSuccess, onFail, false);
});
const getPhycalTestData = createAsyncThunk("fitService/getPhycalTestData", async (date, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getPhycalTestData onSuccess ', response.data);
    if(response.data){
      thunkAPI.dispatch(physicalTestSlice.updateTestResults({date, value: response.data}));
    }
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  const onFail = (response) => {
    console.log('getPhycalTestData onFail ', response);
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.PHYSICALTEST, {testDate: date}, {}, onSuccess, onFail, false);
});
export const postPhycalTestData = createAsyncThunk("fitService/postPhycalTestData", async (value, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('postPhycalTestData onSuccess ', response.data);
  }
  const onFail = (response) => {
    console.log('postPhycalTestData onFail ', response);
  }
  if (typeof window === 'object' && window.PalmSystem) {
    TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.PHYSICALTEST, {}, value, onSuccess, onFail, false);
  }
});
/*
 ROM
*/
export const getRomDateList = createAsyncThunk("fitService/getRomDateList", async (_, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getRomDateList onSuccess ', response.data);
    thunkAPI.dispatch(RomSlice.updateDateList(response.data));
  }
  const onFail = (response) => {
    console.log('getRomDateList onFail ', response);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.ROM_DATELIST, {}, {}, onSuccess, onFail, false);
});
const getRomData = createAsyncThunk("fitService/getRomData", async (date, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getRomData onSuccess ', response.data);
    if(response.data){
      thunkAPI.dispatch(RomSlice.updateTestResults({date, value: response.data}));
    }
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  const onFail = (response) => {
    console.log('getRomData onFail ', response);
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.ROMTEST, {testDate: date}, {}, onSuccess, onFail, false);
});
export const postRomData = createAsyncThunk("fitService/postRomData", async (value, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('postRomData onSuccess ', response.data);
  }
  const onFail = (response) => {
    console.log('postRomData onFail ', response);
  }
  if (typeof window === 'object' && window.PalmSystem) {
    TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.ROMTEST, {}, value, onSuccess, onFail, false);
  }
});


/*
 BODY BALANCE
*/
export const getBodyBalanceDateList = createAsyncThunk("fitService/getBodyBalanceDateList", async (_, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getBodyBalanceDateList onSuccess ', response.data);
    if(response.data){
      thunkAPI.dispatch(BodyBalanceSlice.updateDateList(response.data));
    }
  }
  const onFail = (response) => {
    console.log('getBodyBalanceDateList onFail ', response);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.BODY_BALANCE_DATELIST, {},{}, onSuccess, onFail, false);
});
const getBodyBalanceData = createAsyncThunk("fitService/getBodyBalanceData", async (date, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getBodyBalanceData onSuccess ', response.data);
    if(response.data){
      thunkAPI.dispatch(BodyBalanceSlice.updateTestResults({date, value: response.data}));
    }
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  const onFail = (response) => {
    console.log('getBodyBalanceData onFail ', response);
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.BODY_BALANCE, {testDate: date}, {}, onSuccess, onFail, false);
});
export const postBodyBalanceData = createAsyncThunk("fitService/postBodyBalanceData", async (value, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('postBodyBalanceData onSuccess ', response.data);
  }
  const onFail = (response) => {
    console.log('postBodyBalanceData onFail ', response);
  }
  if (typeof window === 'object' && window.PalmSystem) {
    TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.BODY_BALANCE, {}, value, onSuccess, onFail, false);
  }
});

let getBodyScanDataQueue = [];
const doGetBodyScanDataQueue = (dispatch) => {
	if(getBodyScanDataQueue.length > 0){
		const {type, date} = getBodyScanDataQueue.pop();
    switch(type){
      case BODY_CHECKUP_TYPE.BODY_ALIGNMENT:{
        dispatch(getBodyAlignmentData(date));
        break;
      }
      case BODY_CHECKUP_TYPE.PHYSICAL_TEST:{
        dispatch(getPhycalTestData(date));
        break;
      }
      case BODY_CHECKUP_TYPE.BODY_BALANCE:{
        dispatch(getBodyBalanceData(date));
        break;
      }
      case BODY_CHECKUP_TYPE.ROM:{
        dispatch(getRomData(date));
        break;
      }
    }
	}
}
let getBodyScanDataJob = new Job(doGetBodyScanDataQueue, 5000);

const doNextJobGegBodyScanData = (dispatch) => {
  const speed={"fast": 0, "slow": 500};
  if(getBodyScanDataQueue.length > 0){
    const queue = getBodyScanDataQueue[getBodyScanDataQueue.length-1];
    if(queue.syncFlag === "immediately"){
      getBodyScanDataJob.stop();
      getBodyScanDataJob.startAfter(speed[queue.syncFlag], dispatch);
    }else if(speed[queue.syncFlag] !== undefined){
      if(queue.syncFlag === "fast"){
        getBodyScanDataJob.stop();
      }
      getBodyScanDataJob.startAfter(speed[queue.syncFlag], dispatch);
    }else{
      console.warn("fitServiceSlice doNextJobGegBodyScanData invalid case");
    }
  }
};

/**
 * type:BODY_CHECKUP_TYPE
 * syncFlag: "immediately", "fast","slow"
 * fast 부터는 api 호출 이후에 동작, immediately 는 api 호출 응답 기다리지 않고 바로 호출.
 */
export const getBodyCheckUpData = createAsyncThunk("fitService/getBodyCheckUpData", async ({type, date, syncFlag="immediately"}, thunkAPI) => {
  if(!date || thunkAPI.getState().common.localSettings.cesShowMode){
		return;
	}
  //remove from queue
  getBodyScanDataQueue = getBodyScanDataQueue.filter((item) => item.type !== type || item.date !== date);

  if(syncFlag === 'slow'){
    getBodyScanDataQueue.unshift({type, date, syncFlag});
  }else{
    getBodyScanDataQueue.push({type, date, syncFlag});
  }
  //immediately, fast 일 때는 기존 job 취소하고 바로 해야 하므로.
  // length가 1개일 때는 기존 job이 동작하지 않고 있으므로.
  if(getBodyScanDataQueue.length === 1 || syncFlag === 'immediately' || syncFlag === 'fast'){
    doNextJobGegBodyScanData(thunkAPI.dispatch);
  }
});

/**
 * TODO: Content
 */
const CONTENT_LIST_FILTERS = [
  { type: 'recommend', subType: 'bodyAlignment' },
  { type: 'recommend', subType: 'ROM' },
  { type: 'recommend', subType: 'physicalTest' }
]
export const getContentListAll = createAsyncThunk("fitService/getContentListAll", async (_, thunkAPI) => {
  CONTENT_LIST_FILTERS.forEach(filter => {
    thunkAPI.dispatch(getContentList(filter));
  });
});

export const getContentList = createAsyncThunk("fitService/getContentList", async (filter, thunkAPI) => {
  const { type, subType } = filter;

  const data = contentList.filter(content => content.contentId === 'custom');

  if (type === 'recommend') {
    thunkAPI.dispatch(contentSlice.updateRecommendList({ subType, data }));
  }
});

export const getContent = createAsyncThunk("fitService/getContent", async ({ contentId, update }, thunkAPI) => {
  const contentInfos = thunkAPI.getState().content.contentInfos;
  const contentInfo = contentInfos[contentId];

  if (contentInfo && update !== true) {
    console.log('already contentInfo...', contentInfo);
    return;
  }

  const onSuccess = (response) => {
    console.log("getContent onSuccess", response.data);
    thunkAPI.dispatch(contentSlice.updateContent(response.data));
  }
  const onFail = (response) => {
    console.log("getContent onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", `${URLS.ACTIVITY_CONTENT}/${contentId}`, {},{}, onSuccess, onFail, false);
});

export const getAiList = createAsyncThunk("fitService/getAiList", async (_, thunkAPI) => {
  thunkAPI.dispatch(contentSlice.updateAiList(aiList));
});

// activity
export const getActivityTheme = createAsyncThunk("fitService/getActivityTheme", async(subType, thunkAPI) => {
  const onSuccess = (response) => {
    if (response.data) {
      thunkAPI.dispatch(contentSlice.updateThemeList({ subType, data: response.data }));
      for (let i = 0; i < response.data.length; i++) {
        thunkAPI.dispatch(getActivityContentList({ subType, themeId: response.data[i].themeId }));
      }
    }
  }
  const onFail = (response) => {
    console.log("getActivityTheme onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", `${URLS.ACTIVITY_THEME_LIST}/${subType.toUpperCase()}`, {}, {}, onSuccess, onFail, false);
});

let getActivityContentListKey = null;
export const getActivityContentList = createAsyncThunk("fitService/getActivityContentList", async ({ subType, themeId, page = 1, key }, thunkAPI) => {
  const query = {
    page: page,
    size: Config.LIST_SIZE
  }
  let currentKey = key;

  const onSuccess = (response) => {
    if (response.data && response.data.content) {
      if (page === 1) {
        getActivityContentListKey = new Date();
        currentKey = getActivityContentListKey;
        thunkAPI.dispatch(contentSlice.updateActivityList({ subType, themeId, data: response.data.content }));
      } else if (currentKey === getActivityContentListKey) {
        thunkAPI.dispatch(contentSlice.updateActivityList({ subType, themeId, data: response.data.content, append: true }));
      }

      if (currentKey === getActivityContentListKey && !response.data.last) {
        thunkAPI.dispatch(getActivityContentList({ subType, themeId, page: page + 1, key: currentKey }));
      }
    }
  }
  const onFail = (response) => {
    console.log("getActivityContentList onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", `${URLS.ACTIVITY_CONTENT_LIST}/${themeId}`, query, {}, onSuccess, onFail, false);
});

let getActivityLikeListKey = null;
export const getActivityLikeList = createAsyncThunk("fitService/getActivityLikeList", async ({ type, page = 1, key }, thunkAPI) => {
  const query = {
    page: page,
    size: Config.LIST_SIZE
  }
  let currentKey = key;

  const onSuccess = (response) => {
    if (response.data && response.data.content) {
      if (page === 1) {
        getActivityLikeListKey = new Date();
        currentKey = getActivityLikeListKey;
        thunkAPI.dispatch(activitySlice.updateLikeList({ type, data: response.data.content }));
      } else if (currentKey === getActivityLikeListKey) {
        thunkAPI.dispatch(activitySlice.updateLikeList({ type, data: response.data.content, append: true }));
      }

      if (currentKey === getActivityLikeListKey && !response.data.last) {
        thunkAPI.dispatch(getActivityLikeList({ type, page: page + 1, key: currentKey }));
      }
    }
  }
  const onFail = (response) => {
    console.log("getActivityLikeList onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", `${URLS.ACTIVITY_LIKE_LIST}/${type.toUpperCase()}`, query, {}, onSuccess, onFail, false);
});

export const getActivityContinueList = createAsyncThunk("fitService/getActivityContinueList", async (type, thunkAPI) => {
  const onSuccess = (response) => {
    if (response.data) {
      thunkAPI.dispatch(activitySlice.updateContinueList({ type, data: response.data }));
    }
  }
  const onFail = (response) => {
    console.log("getActivityContinueList onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", `${URLS.ACTIVITY_CONTINUE_LIST}/${type.toUpperCase()}`, {}, {}, onSuccess, onFail, false);
});

export const getActivityRecommendList = createAsyncThunk("fitService/getActivityRecommendList", async (type, thunkAPI) => {
  const onSuccess = (response) => {
    if (response.data) {
      thunkAPI.dispatch(activitySlice.updateRecommendList({ type, data: response.data }));
    }
  }
  const onFail = (response) => {
    console.log("getActivityRecommendList onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", `${URLS.ACTIVITY_RECOMMEND_LIST}/${type.toUpperCase()}`, {}, {}, onSuccess, onFail, false);
});

export const getActivityStrollToday = createAsyncThunk("fitService/getActivityStrollToday", async (date, thunkAPI) => {
  const todayStr = HelperMethods.convertDateToString2(new Date());
  const summary = thunkAPI.getState().activityWalking.summary;
  const summaryInfo = summary[date];

  if (todayStr !== date && summaryInfo) {
    console.log('already SummaryInfo...', summaryInfo);
    return;
  }

  const onSuccess = (response) => {
    if (response.data) {
      thunkAPI.dispatch(walkingSlice.updateSummary({date, value: response.data}));
    }
  }
  const onFail = (response) => {
    console.log("getActivityStrollToday onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.ACTIVITY_STROLL_TODAY, { testDate: date }, {}, onSuccess, onFail, false);
});

export const getActivityStrollDateList = createAsyncThunk("fitService/getActivityStrollDateList", async (_, thunkAPI) => {
  const onSuccess = (response) => {
    if (response.data) {
      thunkAPI.dispatch(walkingSlice.updateDateList(response.data));
    }
  }
  const onFail = (response) => {
    console.log("getActivityStrollDateList onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.ACTIVITY_STROLL_HISTORY_DATELIST, {}, {}, onSuccess, onFail, false);
});

export const getActivityStrollHistorySummary = createAsyncThunk("fitService/getActivityStrollHistorySummary", async ({ startDate, endDate }, thunkAPI) => {
  const onSuccess = (response) => {
    if (response.data) {
      thunkAPI.dispatch(walkingSlice.updateActivityHistorySummary(response.data));
    }
  }
  const onFail = (response) => {
    console.log("getActivityStrollHistorySummary onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.ACTIVITY_STROLL_HISTORY_SUMMARY, { startDate, endDate }, {}, onSuccess, onFail, false);
});

let getActivityStrollHistoryListKey = null;
export const getActivityStrollHistoryList = createAsyncThunk("fitService/getActivityStrollHistoryList", async ({ startDate, endDate, page = 1, key }, thunkAPI) => {
  const query = {
    startDate: startDate,
    endDate: endDate,
    page: page,
    size: Config.LIST_SIZE
  }
  let currentKey = key;

  const onSuccess = (response) => {
    if (response.data && response.data.content) {
      if (page === 1) {
        getActivityStrollHistoryListKey = new Date();
        currentKey = getActivityStrollHistoryListKey;
        thunkAPI.dispatch(walkingSlice.updateActivityHistory({ data: response.data.content }));
      } else if (currentKey === getActivityStrollHistoryListKey) {
        thunkAPI.dispatch(walkingSlice.updateActivityHistory({ data: response.data.content, append: true }));
      }

      if (currentKey === getActivityStrollHistoryListKey && !response.data.last) {
        thunkAPI.dispatch(getActivityStrollHistoryList({ startDate, endDate, page: page + 1, key: currentKey }));
      }
    }
  }
  const onFail = (response) => {
    console.log("getActivityStrollHistoryList onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.ACTIVITY_STROLL_HISTORY_LIST, query, {}, onSuccess, onFail, false);
});

export const postActivityStrollLike = createAsyncThunk("fitService/postActivityStrollLike", async ({ type, contentId, likeYn }, thunkAPI) => {
  const onSuccess = (response) => {
	  thunkAPI.dispatch(getActivityLikeList({ type }));
  }
  const onFail = (response) => {
    console.log("postActivityStrollLike onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", `${URLS.ACTIVITY_STROLL_LIKE}/${contentId}/${likeYn}`, {}, {}, onSuccess, onFail, false);
});

export const postActivityStroll = createAsyncThunk("fitService/postActivityStroll", async (value , thunkAPI) => {
  const onSuccess = (response) => {
    thunkAPI.dispatch(getContent({ contentId: value.contentId, update: true }));
    thunkAPI.dispatch(getActivityContinueList('walking'));

    const todayStr = HelperMethods.convertDateToString2(new Date());
    thunkAPI.dispatch(getActivityStrollToday(todayStr));
  }
  const onFail = (response) => {
    console.log("postActivityStroll onFail", response.data);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.ACTIVITY_STROLL, {}, value, onSuccess, onFail, false);
});

export const getActivityData = createAsyncThunk("fitService/getActivityData", async (type , thunkAPI) => {
  thunkAPI.dispatch(getActivityTheme(type));
  thunkAPI.dispatch(getActivityLikeList({ type }));
  thunkAPI.dispatch(getActivityContinueList(type));
  thunkAPI.dispatch(getActivityRecommendList(type));
});

// homecareProgram
export const getWeeklySolution = createAsyncThunk("fitService/getWeeklySolution", async (_, thunkAPI) => {
  thunkAPI.dispatch(homecareProgramSlice.getWeeklySolution(weeklySolution));
});

export const postWeeklySolution = createAsyncThunk("fitService/updateWeeklySolution", async (value, thunkAPI) => {
  thunkAPI.dispatch(homecareProgramSlice.updateWeeklySolution(value));
});

// survey getSurvey
export const getSurvey = createAsyncThunk("fitService/getSurvey", async (_, thunkAPI) => {
  const {userNumber} = thunkAPI.getState().common.appStatus;
  const onSuccess = (response) => {
    console.log('getSurvey onSuccess ', response.data);
    const {
      sex,
      height,
      weight,
      age,
      intensity_low,
      intensity_mid,
      intensity_high,
      lastpage,
      disease,
      pain,
      purpose,
      interest,
      preference,
      desire,
      ...rest
    } = response.data;

    const defaultData = {
      sex: sex === null ? 0 : sex,
      age: age === null ? 30 : age,
      weight: sex === null && weight === null ? 74 : weight,
      height: sex === null && height === null ? 172 : height,
      intensity_low: intensity_low === null ? 4 : intensity_low,
      intensity_mid: intensity_mid === null ? 3 : intensity_mid,
      intensity_high: intensity_high === null ? 3 : intensity_high,
      lastpage: lastpage === null ? 0 : lastpage,
      disease: disease === null ? []: disease,
      pain: pain=== null ? []: pain,
      purpose: purpose === null ? []: purpose,
      interest: interest === null ? []: interest,
      preference: preference === null ? []: preference,
      desire: desire === null ? []: desire,
      ...rest
    };
    thunkAPI.dispatch(setSurvey(defaultData));
  }
  const onFail = (response) => {
    thunkAPI.dispatch(setSurvey(getResetAnswerData()));
    console.log('getSurvey onFail ', response);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.SURVEY, {}, {}, onSuccess, onFail, false);
});


// export const postBodyAlignmentData = createAsyncThunk("fitService/postBodyAlignmentData", async (value, thunkAPI) => {
//   const onSuccess = (response) => {
//     console.log('postBodyAlignmentData onSuccess ', response.data);
//   }
//   const onFail = (response) => {
//     console.log('postBodyAlignmentData onFail ', response);
//   }
//   TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.BODY_ALIGNMENT, {}, value, onSuccess, onFail, false);
// });

// survey postSurvey
export const postSurvey = createAsyncThunk("fitService/postSurvey", async (_, thunkAPI) => {
  const { survey } = thunkAPI.getState().fitService;
  const onSuccess = (response) => {
    console.log('postSurvey onSuccess ', response.data);
  }
  const onFail = (response) => {
    console.log('postSurvey onFail ', response);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.SURVEY, {}, survey, onSuccess, onFail, false);
});

export const postCapturedScreen = createAsyncThunk("fitService/postCapturedScreen", async (imageStr, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('postCapturedScreen onSuccess ', response.data);
    thunkAPI.dispatch(sendBroadCast({type: "imageUrlCreated", moreInfo:{url:response.data}}));
  }
  const onFail = (response) => {
    console.log('postCapturedScreen onFail ', response);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.UPLOAD_IMAGE, {}, imageStr, onSuccess, onFail, false);
});

export const deleteCapturedScreen = createAsyncThunk("fitService/deleteCapturedScreen", async (fileName, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('deleteCapturedScreen onSuccess ', response.data);
  }
  const onFail = (response) => {
    console.log('deleteCapturedScreen onFail ', response);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "delete", `${URLS.DELETE_FILE}/${fileName}`, {}, {}, onSuccess, onFail, false);
});

// export const getCapturedScreen = createAsyncThunk("fitService/getCapturedScreen", async (_, thunkAPI) => {
//   const onSuccess = (response) => {
//     console.log('getCapturedScreen onSuccess ', response.data);
//   }
//   const onFail = (response) => {
//     console.log('getCapturedScreen onFail ', response);
//   }

//   TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.UPLOAD_IMAGE, {}, {}, onSuccess, onFail, false);
// });

// policy getPolicy
export const getPolicy = createAsyncThunk("fitService/getPolicy", async (_, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getPolicy onSuccess ', response.data);
    thunkAPI.dispatch(setPolicy(response.data.policyYn));
  }
  const onFail = (response) => {
    console.log('getPolicy onFail ', response);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.POLICY, {}, {}, onSuccess, onFail, false);
});

// policy postPolicy
export const postPolicy = createAsyncThunk("fitService/postPolicy", async (_, thunkAPI) => {
  const { policy } = thunkAPI.getState().fitService;
  const onSuccess = (response) => {
    console.log('postPolicy onSuccess ', response.data);
  }
  const onFail = (response) => {
    console.log('postPolicy onFail ', response);
  }
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.POLICY, {}, policy, onSuccess, onFail, false);
});

// Setting get Settings
export const getSettings = createAsyncThunk("fitService/getSettings", async (userNumber, thunkAPI) => {
  const onSuccess = (response) => {
    const settingData = response.data;
    Object.keys(settingData).forEach(settingKey => {
      if (settingData[settingKey] === null && initialState.settings[settingKey] !== undefined) {
        settingData[settingKey] = initialState.settings[settingKey];
      }
    });

    thunkAPI.dispatch(updateSettings(settingData));
  }
  const onFail = (response) => {
    console.log('getSettings onFail ', response);
  }

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.SETTING, {}, {}, onSuccess, onFail, true);
});

export const postSetting = createAsyncThunk("fitService/postSetting", async (params, thunkAPI) => {
  const onSuccess = (response) => {
    thunkAPI.dispatch(updateSettings(params));
  }
  const onFail = (response) => {
    console.log('postSetting onFail ', response);
    thunkAPI.dispatch(updateSettings(params)); // todo remove
  }

  const settings = thunkAPI.getState().fitService.settings;
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.SETTING, {}, Object.assign({}, settings, params), onSuccess, onFail, true);
});

// Setting > BGM
export const getBgmList = createAsyncThunk("fitService/getBgmList", async ({sort}, thunkAPI) => {
  const originSettings = thunkAPI.getState().fitService.settings;
  const onSuccess = (response) => {
    console.log('getBgmList onSuccess ', response.data);
    thunkAPI.dispatch(setBgmList(response.data.data));
  };
  const onFail = (response) => {
    console.log('getBgmList onFail ', response);
  };
  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.SETTING_BGM_LIST, {}, {sort: sort}, onSuccess, onFail, true);
});
export const postBgm = createAsyncThunk("fitService/postBgm", async ({bgm, playway, music}, thunkAPI) => {
  const {userNumber} = thunkAPI.getState().common.appStatus;
  const settings = thunkAPI.getState().fitService.settings;
  const _music = music ? music : settings.music;
  let convertedMusic = [];
  _music && _music.forEach((m)=>{
    convertedMusic.push(m.seq);
  });
  const params = {
    userno: userNumber,
    bgm: bgm ? bgm : settings.bgm,
    playway: playway ? playway : settings.playway,
    music: convertedMusic
  };

  const onSuccess = (response) => {
    thunkAPI.dispatch(updateSettings({bgm: params.bgm, playway:params.playway, music:_music}));
  };

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.SETTING_BGM, {},params, onSuccess, null, true);
});

// Setting > FootPrint
export const getFootprintList = createAsyncThunk("fitService/getFootprintList", async (_, thunkAPI) => {
  const onSuccess = (response) => {
    console.log('getFootprintList onSuccess ', response.data);
    thunkAPI.dispatch(setFootprintList(response.data.data));
  };

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "get", URLS.SETTING_FOOTPRINT_LIST, {},{}, onSuccess, null, true);
});
export const postFootprint = createAsyncThunk("fitService/postFootprint", async ({footprint, sound, footauto}, thunkAPI) => {
  const {userNumber} = thunkAPI.getState().common.appStatus;
  const settings = thunkAPI.getState().fitService.settings;

  const params = {
    userno: userNumber,
    footprint: footprint ? footprint : settings.footprint,
    sound: Number(sound ? sound : settings.sound),
    footauto: footauto ? footauto : settings.footauto
  };
  if(params.sound === NaN){
    params.sound = 0;
  }
  const onSuccess = (response) => {
    const soundpath = settings.footprintList?.[params.sound]?.filepath;
    thunkAPI.dispatch(updateSettings({footprint: params.footprint, sound:params.sound, footauto:params.footauto, soundpath}));
  };

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.SETTING_FOOTPRINT,{}, params, onSuccess, null, true);
});
export const postSettingCenter = createAsyncThunk("fitService/postSettingCenter", async ({areacenter}, thunkAPI) => {
  const {userNumber} = thunkAPI.getState().common.appStatus;

  const params = {
    userno: userNumber,
    areacenter: areacenter
  };
  const onSuccess = (response) => {
    thunkAPI.dispatch(updateSettings({areacenter}));
  };

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.SETTING_CENTER, {},params, onSuccess, null, true);
});
export const postSettingGuide = createAsyncThunk("fitService/postSettingGuide", async ({guide}, thunkAPI) => {
  const {userNumber} = thunkAPI.getState().common.appStatus;

  const params = {
    userno: userNumber,
    guide: guide
  };
  const onSuccess = (response) => {
    thunkAPI.dispatch(updateSettings({guide}));
  };

  TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.SETTING_GUIDE, {},params, onSuccess, null, true);
});

export const postSettingGesture = createAsyncThunk("fitService/postSettingGesture", async ({ gesture }, thunkAPI) => {
  const {userNumber} = thunkAPI.getState().common.appStatus;

  const params = {
    userno: userNumber,
    gesture: gesture
  };

  const onSuccess = (response) => {
    thunkAPI.dispatch(updateSettings({ gesture }));
  };

  onSuccess();
  // TAxios(thunkAPI.dispatch, thunkAPI.getState, "post", URLS.SETTING_GUIDE, {},params, onSuccess, null, true);
});

export const fitService = createSlice({
  name: "fitService",
  initialState,
  reducers: {
    setPolicy: (state, action) => {
      state.policy = action.payload;
    },
    setSurvey: (state, action) => {
      state.survey = action.payload;
    },
    updateSurvey: (state, action) => {
      state.survey = Object.assign({}, state.survey, action.payload);
    },
    updateSettings: (state, action) => {
      state.settings = Object.assign({}, state.settings, action.payload);
    },
    setBgmList: (state, action) => {
      state.settings.bgmList = action.payload;
      if(!state.settings.music) {
        state.settings.music = action.payload?.[0] ? [action.payload?.[0]]: null;
      }
    },
    setFootprintList: (state, action) => {
      state.settings.footprintList = action.payload;
      if(!state.settings.soundpath){
        state.settings.sound =  action.payload?.[0]?.seq;
        state.settings.soundpath = action.payload?.[0]?.filepath;
      }
    },
  },
});

export const {
  setPolicy,
  setSurvey,
  setImageURL,
  updateSurvey,
  updateSettings,
  setBgmList,
  setFootprintList
} = fitService.actions;



export default fitService.reducer;
