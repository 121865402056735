import css from './DetailLayout.module.less';
import classNames from 'classnames';
import TIconButton, { SIZES, COLOR } from '../../../../components/TIconButton';


const TIconButtonLayout = () => {
    return (
        <div className={css.buttonLayout}>
            <TIconButton className={css.withMargin} iconType="back"/>
            <TIconButton className={css.withMargin} iconType="home"/>
            <TIconButton className={css.withMargin} iconType="pause"/>
            <TIconButton className={css.withMargin} iconType="play"/>
            <TIconButton className={css.withMargin} iconType="pause" size="small"/>
            <TIconButton className={css.withMargin} iconType="play" size="small"/>
            <TIconButton className={css.withMargin} iconType="rightArrow" size="small"/>
            <TIconButton className={css.withMargin} iconType="leftArrow" size="small"/>
            <TIconButton className={css.withMargin} iconType="calendar" size="small"/>
            <TIconButton className={css.withMargin} iconType="heart"/>
            <TIconButton className={css.withMargin} iconType="heartHollow" size={"small"} disabled={true}/>
            <TIconButton className={css.withMargin} iconType="plus" size={SIZES.tiny} color={COLOR.black}/>
            <TIconButton className={css.withMargin} iconType="minus" size={SIZES.tiny} color={COLOR.black}/>
            <TIconButton className={css.withMargin} iconType="mat"/>
            <TIconButton className={css.withMargin} iconType="matNo"/>
            <TIconButton className={css.withMargin} iconType="camera"/>
            <TIconButton className={css.withMargin} iconType="cameraNo"/>
            <TIconButton className={css.withMargin} iconType="close"/>
            <TIconButton className={css.withMargin} iconType="alert"/>
            <TIconButton className={css.withMargin} iconType="alertNoti"/>
            <TIconButton className={css.withMargin} iconType="speaker"/>
            <TIconButton className={css.withMargin} iconType="speaker" selected={true}/>
        </div>
    );
};
export default TIconButtonLayout;