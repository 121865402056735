import { ADAPTER_ADDRESS, ADDRESS, DEFAULT_INTERVAL, convertFeaturesFromFloat } from "./dummyDefault";
/*
한발서기
sequence->value : Signature(1byte), Version(1byte), MessageId(1byte), NRP(2byte), payload(...)
sequence->interval: option
*/
export default {
  "interval": DEFAULT_INTERVAL, //default interval
  "loop": false,
  "common": {
    "adapterAddress": ADAPTER_ADDRESS,
    "address": ADDRESS,
    "returnValue": true,
    "subscribed": false
  },
  "sequence": [
    //Report message
    {"value": [0xA5, 0, 0x0, 0, 0, 0x30, 2, 0, 0x02, 0x01]}, //not ready
    {"value": [0xA5, 0, 0x1, 0, 0, 0x30, 2, 0, 0x02, 0x02]}, //not ready
    {"value": [0xA5, 0, 0x2, 0, 0, 0x30, 2, 0, 0x02, 0x03]}, //not ready
    {"value": [0xA5, 0, 0x3, 0, 0, 0x30, 2, 0, 0x02, 0x04]}, //not ready
    {"value": [0xA5, 0, 0x4, 0, 0, 0x30, 2, 0, 0x02, 0x05]}, //not ready
    {"value": [0xA5, 0, 0x5, 0, 0, 0x33, 32, 0, ...convertFeaturesFromFloat(2, [68, 68, 221, 221, 51, 85, 255, 187, 0, 0, 0, 0, 0, 0, 0, 0], 1)], "interval": 1000}, //left position
    {"value": [0xA5, 0, 0x6, 0, 0, 0x30, 2, 0, 0x02, 0x0A]}, //not ready
    {"value": [0xA5, 0, 0x7, 0, 0, 0x30, 2, 0, 0x01, 0x00]}, //ready
    //(115,59), (194, 59), (110, 251), (196, 251)
    {"value": [0xA5, 0, 0x10, 0, 0, 0x33, 32, 0, ...convertFeaturesFromFloat(2, [68, 68, 221, 221, 51, 85, 255, 187, 0, 0, 0, 0, 0, 0, 0, 0], 1)], "interval": 1000}, //left position

    {"value": [0xA5, 0, 0x11, 0, 0, 0x30, 2, 0, 0x03, 0x00], "interval": 1000}, //check start
    {"value": [0xA5, 0, 0x12, 0, 0, 0x30, 2, 0, 0x04, 0x00], "interval": 2000}, //check end 왼발
  ]
}