/**
 * TInput
 *
 * @module TInput
 */
import classNames from 'classnames';
import React, { useEffect } from 'react';
import css from './TInput.module.less';
import { InputField,  } from '@enact/sandstone/Input';
import { $L } from '../../utils/helperMethods';

const KINDS = { withIcon: "withIcon" };
const ICONS = { email: "email" };
const BORDER = { none: "none" };
const COLOR = { transparent: "transparent" }

const TInput = ({ kind='', icon=null, border=null, color=null,  className=null, spotlightDisabled, ...rest }) => {
  return (
    <InputField
      {...rest}
      className={classNames(
        css.input,
        icon && css[icon],
        css[kind],
        css[border],
        css[color],
        className
      )}
      spotlightDisabled={spotlightDisabled}
    />
  );
};
export default TInput;
export { KINDS, ICONS, BORDER, COLOR };