import React, { useCallback, useState, useEffect } from 'react';
import css from './StarRatingPopup.module.less';
import Spottable from '@enact/spotlight/Spottable';
import { $L } from "../../utils/helperMethods"
import TPopUp from "../TPopUp/TPopUp"
import classNames from 'classnames';

const SpottableComponent = Spottable('div');
const StarRatingPopup = ({ onClose }) => {

  const _onClose = useCallback((ev)=>{
    if(onClose){
      onClose(ev);
    }
  },[onClose]);

  const [starArr, setStarArr] = useState([false, false, false, false, false]);
  const _onClick = useCallback((idx) => () => {
    setStarArr(cur => {
      const newData = [...cur];
      for (let i = 0; i < starArr.length; i++) {
        if (i < idx || i === idx ) {
          newData[i] = true;
        } else if (i > idx) {
          newData[i] = false;
        }
      }
      return newData;
    });
  }, [starArr])


  return (
    <TPopUp
      kind="starratingPopup"
      title={$L("Did you enjoy working out?")}
      text={$L("Rate the workout you just did!")}
      button1text={$L("Verified")}
      onClose={_onClose}
    >
      <div className={css.starlayer}>
        {starArr.map((el, idx) =>
          <SpottableComponent
            key={idx}
            className={classNames(css.star, el ? css.select : null)}
            onClick={_onClick(idx)}
          />
        )}

      </div>
    </TPopUp>
  )
}

export default StarRatingPopup;