import { useCallback, useState, useMemo, useRef, useEffect } from "react";
import css from "./BodyAlignmentScan.module.less";
import classNames from "classnames";
import Skinnable from '@enact/sandstone/Skinnable';
import { Cancelable } from '@enact/ui/Cancelable';
import TShakaPlayer from "../../../../components/TShakaPlayer/TShakaPlayer";
import PIPCamera from "../../../../components/PIPCamera/PIPCamera";
import * as Config from "../../../../utils/Config";
import { useDispatch, useSelector } from "react-redux";
import { changeAppStatus } from "../../../../features/common/commonSlice";
import {
  DATAKEY,
  setCurrentTestingName,
  clearCurrentTestingStatus,
  clearTestResults,
} from "../../../../features/bodyAlignment/bodyAlignmentSlice";
import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";

import { Job } from "@enact/core/util";

import TPopUp from "../../../../components/TPopUp/TPopUp";
import { $L } from "../../../../utils/helperMethods";
import { changeLocalSettings } from "../../../../features/common/commonSlice";
import { addPanels, popPanel } from "../../../../features/panels/panelsSlice";

import BodyPositionViewer from "../../../../components/BodyPositionViewer";
import BodyPositionCorrectionSign from "../BodyPositionCorrectionSign/BodyPositionCorrectionSign";
import GoodSignAnimation from "../../../../components/GoodSignAnimation/GoodSignAnimation";

import bodyScan from "../../../../../assets/video/bodyScan.mp4";
import bodyScanThumbnail from "../../../../../assets/images/bodyScanThumb.png";

import { BELLY_CHECKING_INDEX, BellySmoother } from "../../../../utils/Constants";

//custom hooks
import useBodyAlignment from "../../../../hooks/useBodyAlignment";
import useActivatePositionDetection from "../../../../hooks/useActivatePositionDetection";

import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";

import bellyPoint from "../../../../../assets/bodyScan/point.png";
import * as SoundEffect from "../../../../utils/SoundEffect";
import WebWorkerUtil, { WORKER_ID } from "../../../../utils/WebWorker/WebWorkerUtil";
import TStepper from "../../../../components/TStepper/TStepper";
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import TToolTip, { POSITION, COLOR } from "../../../../components/TToolTip/TToolTip";
import TestGuideSignMsg from "../../../../components/TestGuideSignMsg/TestGuideSignMsg";
import TTestClosePopup from "../../../../components/TTestClosePopup/TTestClosePopup";
import PreloadImage from "../../../../components/PreloadImage/PreloadImage";
import BodyCheckUpCountdownTimer from "../../BodyCheckUpCountdownTimer/BodyCheckUpCountdownTimer";
import * as TTSService from "../../../../lunaSend/TTSService";

import scanSprites from "../../../../../assets/bodyScan/10frame_sec/scan-sprites.png";
import TestEndCompletePopup from "../../../../components/TestEndCompletePopup/TestEndCompletePopup";
import TIconButton from "../../../../components/TIconButton/TIconButton";

const preloadImages = [
	scanSprites
];

const disableDirectionSignJob = new Job((setDirectionSign) => {
	setDirectionSign("");
}, 0);

const resumeTestJob = new Job((resumeTest) => {
	resumeTest();
}, 0);

const Container = SpotlightContainerDecorator({ enterTo: "default-element", continue5WayHold: true, defaultElement: "#shakaPlayer" }, "div");
const ButtonContainer = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");
const CancelableDiv = Cancelable({ modal: true, onCancel: 'handleCancel' }, Skinnable(Container));
const SpottableDiv = Spottable("div");

const SEQUENCE_INIT = 0;
const SEQUENCE_FRONT_START = 1;
const SEQUENCE_FRONT_POSITIONING = 2;
const SEQUENCE_FRONT_POSITION_MATCHED = 3;
const SEQUENCE_FRONT_CAPTURED = 4;

const SEQUENCE_RIGHT_START = 10;
const SEQUENCE_RIGHT_POSITIONING = 11;
const SEQUENCE_RIGHT_POSITION_MATCHED = 12;
const SEQUENCE_RIGHT_CAPTURED = 13;
const SEQUENCE_FINISHED = 20;

const SEQUENCE_FRONT_START_TIME = 0.8;
const SEQUENCE_RIGHT_START_TIME = 29.0;
const SEQUENCE_FRONT_POSITIONING_TIME = 21.2;
const SEQUENCE_RIGHT_POSITIONING_TIME = 40.4;


const getVideoOnTimeActionList = () => ({
	[SEQUENCE_FRONT_START_TIME]: { voiceGuide: "M1", sequence: SEQUENCE_FRONT_START, guideSignMsg: $L("Let's start <b>Front Posture Scanning</b>. Simply follow the character guide.") },
	7.5: { loopVoiceGuide: "SCH_BUTTON_10" },
	7.7: { voiceGuide: "M2", guideSignMsg: $L("Place your toes on <b>the guideline</b> and stand with your feet shoulder-width apart.") },
	[SEQUENCE_FRONT_POSITIONING_TIME]: { videoPause: true, loopVoiceGuide: "", sequence: SEQUENCE_FRONT_POSITIONING },
	22.1: { voiceGuide: "M4", guideSignMsg: $L("Now, let's start the scanning process.") },
	27.6: { voiceGuide: "M6", guideSignMsg: $L("<b>Great job</b> with the front scan! Now, we'll move on to the side posture.") },
	[SEQUENCE_RIGHT_START_TIME]: { sequence: SEQUENCE_RIGHT_START },
	32.6: { voiceGuide: "M7", loopVoiceGuide: "SCH_BUTTON_10", guideSignMsg: $L("Turn your body to the left, align your toes <b>horizontally</b> with the guideline.") },
	[SEQUENCE_RIGHT_POSITIONING_TIME]: { videoPause: true, loopVoiceGuide: "", sequence: SEQUENCE_RIGHT_POSITIONING },
	40.5: { voiceGuide: "M9", guideSignMsg: $L("Look straight ahead instead of at the screen.") }, //front view
	43.0: { voiceGuide: "M4", guideSignMsg: $L("Now, let's start the scanning process.") },//측정하겠습니다.
	47.5: { voiceGuide: "M10" },
	48.0: { videoPause: true, sequence: SEQUENCE_FINISHED }, //pause before finish video
});
let VIDEO_ACTION_ON_TIME = null;
const BODY_LOCATION_GOOD_TRIGER_COUNT = 5;

const BodyAlignmentScan = () => {
	if (!VIDEO_ACTION_ON_TIME) {
		VIDEO_ACTION_ON_TIME = getVideoOnTimeActionList();
	}
	const dispatch = useDispatch();
	const skipRef = useRef();
	const mWorker = useRef(null);
	const videoPlayer = useRef();
	const bellySmootherRef = useRef([]);
	const goodCountRef = useRef(0);
	const finishTimer = useRef(null);
	const positioningTimer = useRef(null);
	const testingPauseRef = useRef(null);
	const lastBodyPositionData = useRef(null);
	const mountedTime = useRef(null);

	const bodyScanSkip = useSelector((state) => state.common.localSettings.bodyScanSkip);
	const { xFitBodySetCustomBaseline, xFitBodyBottomPosition, skipVideoGuide, xFitBodyBellySmootherQueueSize, showBodyCoordinations } = useSelector((state) => state.common.localSettings);
	const { xFitBodyProcessQueueSize, cesShowMode } = useSelector((state) => state.common.localSettings);
	const cameraSize = useSelector((state) => state.camera.cameraSize);
	const broadcast = useSelector((state) => state.common.broadcast);
	const { isAppForeground, showLoadingPanel } = useSelector((state) => state.common.appStatus);
	const { cameraList } = useSelector(state => state.camera);

	const [guideSignMsg, setGuideSignMsg] = useState("");
	const [cameraStatus, setCameraStatus] = useState(-1);
	const [mounted, setMounted] = useState(false);
	const cameraStatusRef = useRef(-1);
	const [playVideo, setPlayVideo] = useState(false);
	const [isGoodSignOn, setIsGoodSignOn] = useState(false);
	const [bodyPositionCorrectionDirection, setBodyPositionCorrectionDirection] = useState({});
	const [directionSign, setDirectionSign] = useState("");
	const [isCountDownStarted, setIsCountDownStarted] = useState(false);
	const [checkLocationResult, setCheckLocationResult] = useState({});
	const [voiceGuide, setVoiceGuide] = useState("");
	const [loopVoiceGuide, setLoopVoiceGuide] = useState("");
	const [isTestingPaused, setIsTestingPaused] = useState(false);
	const [stepNum, setStepNum] = useState(0);
	const [isSkipped, setIsSkipped] = useState(false);
	const [isInstruction, setIsInstruction] = useState(cesShowMode ? bodyScanSkip : !bodyScanSkip);
	const [isTestClose, setTestClose] = useState(false); //test 중에 뒤로가기
	const [showTestEndComplete, setShowTestEndComplete] = useState(false);

	const onCaptured = useCallback(() => {
		// console.log("BodyAlignmentScan onCaptured cameraStatus ", cameraStatusRef.current);
	}, [cameraStatus]);

	const { captureImage, saveTestResults } = useBodyAlignment({ onCaptured });
	const {
		activatePositionDetection,
		deActivatePositionDetection,
		bodyPositionLiveData,
		getEventNotification,
		cancelGetEventNotification,
		getSingleEventNotification,
	} = useActivatePositionDetection();


	const showPIPCamera = useMemo(() => {
		if (!isAppForeground) {
			return false;
		}
		if (typeof window === 'object' && window.PalmSystem) {
			return cameraList && cameraList.length > 0;
		} else {
			return true;
		}
	}, [isAppForeground, cameraList]);

	const onCameraReady = useCallback(() => {
		dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
		if (!isInstruction) {
			// setPlayVideo(true);
			setTimeout(() => {
				setCameraStatus(SEQUENCE_INIT);
				// delay: MIN_SHOWING_TIME+HIDING_TIME in loadingpanel
			}, (4000 - (new Date() - mountedTime.current)));
		}
	},[dispatch, isInstruction]);

	useEffect(() => {
		if (bodyPositionLiveData !== undefined && !!bodyPositionLiveData?.id) {
			if (cameraStatus < SEQUENCE_RIGHT_START) {
				WebWorkerUtil.postMessage(mWorker.current, { type: "checkLocation", value: [bodyPositionLiveData, [cameraSize.width, cameraSize.height]] }, false);
			} else {
				WebWorkerUtil.postMessage(mWorker.current, { type: "checkLocationSide", value: [bodyPositionLiveData, [cameraSize.width, cameraSize.height]] }, false);
			}
		}
	}, [bodyPositionLiveData, cameraStatus]);

	useEffect(() => {
		if(broadcast?.type === 'positionLiveDataCleared'){
			BELLY_CHECKING_INDEX.forEach((_, index) => {
				bellySmootherRef.current[index]?.clearQueue();
			});
		}
	}, [broadcast]);

	const processImage = useCallback(() => {
		setShowTestEndComplete(true);
		saveTestResults();
	}, [dispatch, saveTestResults]);

	useEffect(() => {
		cameraStatusRef.current = cameraStatus;
		console.log("BodyAlignmentScan cameraStatus ", cameraStatus, cameraStatusRef.current, isInstruction, videoPlayer.current);
		switch (cameraStatus) {
			case SEQUENCE_INIT: {
				if (!showLoadingPanel.show) {
					if (!isInstruction && videoPlayer.current) {
						setStepNum(1);
						setPlayVideo(true);
						videoPlayer.current.play();
					}
				}
				break;
			}
			case SEQUENCE_FRONT_START: {
				if (!testingPauseRef.current) {
					dispatch(setCurrentTestingName(DATAKEY.FRONT));
					setDirectionSign("front");
					disableDirectionSignJob.startAfter(3000, setDirectionSign);
					WebWorkerUtil.postMessage(mWorker.current, { type: "init" }, true);
					WebWorkerUtil.postMessage(mWorker.current, { type: "setCustomBaseline", value: [xFitBodySetCustomBaseline] }, true);
					WebWorkerUtil.postMessage(mWorker.current, { type: "setGroundLineRatio", value: [xFitBodyBottomPosition * 0.01] }, true);
					WebWorkerUtil.postMessage(mWorker.current, { type: "setParam", value: ["bodytype", "0", "front"] }, true);
					activatePositionDetection({ isDetectionDelayed: false });
					if (skipVideoGuide) {
						videoPlayer.current.seekTo(14.0);
					}
				}
				break;
			}
			case SEQUENCE_FRONT_POSITIONING: {
				if (typeof window === 'object' && !window.PalmSystem) {
					clearTimeout(positioningTimer.current);
					positioningTimer.current = null;
					positioningTimer.current = setTimeout(() => {
						SoundEffect.playAudio("SCH_BUTTON_3");
						setCameraStatus(SEQUENCE_FRONT_POSITION_MATCHED);
					}, 5000);
					if (testingPauseRef.current) {
						clearTimeout(positioningTimer.current);
						positioningTimer.current = null;
					}
				}
				setIsCountDownStarted(false);
				break;
			}
			case SEQUENCE_FRONT_POSITION_MATCHED: {
				setBodyPositionCorrectionDirection(checkLocationResult);
				videoPlayer.current.play();
				break;
			}
			case SEQUENCE_FRONT_CAPTURED: {
				if (!testingPauseRef.current) {
					setBodyPositionCorrectionDirection({});
					videoPlayer.current.play();
				}
				break;
			}

			case SEQUENCE_RIGHT_START: {
				if (!testingPauseRef.current) {
					setDirectionSign("side");
					dispatch(setCurrentTestingName(DATAKEY.SIDE));
					setIsCountDownStarted(false);
					disableDirectionSignJob.startAfter(3000, setDirectionSign);
					WebWorkerUtil.postMessage(mWorker.current, { type: "init" }, true);
					WebWorkerUtil.postMessage(mWorker.current, { type: "setCustomBaseline", value: [xFitBodySetCustomBaseline] }, true);
					WebWorkerUtil.postMessage(mWorker.current, { type: "setGroundLineRatio", value: [xFitBodyBottomPosition * 0.01] }, true);
					WebWorkerUtil.postMessage(mWorker.current, { type: "setParam", value: ["bodytype", "1", "left"] }, true);
					getEventNotification({ isDetectionDelayed: true });
					if (skipVideoGuide) {
						videoPlayer.current.seekTo(SEQUENCE_RIGHT_POSITIONING_TIME);
					}

				}
				//skip button
				if (isSkipped) {
					setIsSkipped(false);
				}
				break;
			}
			case SEQUENCE_RIGHT_POSITIONING: {
				if (typeof window === 'object' && !window.PalmSystem) {
					clearTimeout(positioningTimer.current);
					positioningTimer.current = null;
					positioningTimer.current = setTimeout(() => {
						SoundEffect.playAudio("SCH_BUTTON_3");
						setCameraStatus(SEQUENCE_RIGHT_POSITION_MATCHED);
					}, 5000);
					if (testingPauseRef.current) {
						clearTimeout(positioningTimer.current);
						positioningTimer.current = null;
					}
				}
				setIsCountDownStarted(false);
				break;
			}
			case SEQUENCE_RIGHT_POSITION_MATCHED: {
				setBodyPositionCorrectionDirection(checkLocationResult);
				videoPlayer.current.play();
				break;
			}
			case SEQUENCE_RIGHT_CAPTURED: {
				break;
			}
			case SEQUENCE_FINISHED: {
				dispatch(setCurrentTestingName(""));
				processImage();
				clearTimeout(finishTimer.current);
				finishTimer.current = null;
				finishTimer.current = setTimeout(() => {
					dispatch(popPanel(Config.panel_names.BODY_ALIGNMENT_REPORT_DETAIL));
					dispatch(popPanel(Config.panel_names.BODY_ALIGNMENT_SCAN));
					if(cesShowMode){
						dispatch(addPanels({name : Config.panel_names.BODY_ALIGNMENT_REPORT}));
					}
				}, 5000);
				if (testingPauseRef.current) {
					clearTimeout(finishTimer.current);
					finishTimer.current = null;
				}
				break;
			}
		}
	}, [cameraStatus, showLoadingPanel, isTestingPaused]);

	const doCaptureImage = useCallback((bodyPositionDatas = []) => {
		lastBodyPositionData.current = bodyPositionDatas[bodyPositionDatas.length - 1];
		WebWorkerUtil.postMessage(mWorker.current, { type: "process", value: [bodyPositionDatas] }, true);
	}, []);

	const countdownCompleted = useCallback(() => {
		videoPlayer.current.pause();
		getSingleEventNotification(xFitBodyProcessQueueSize, doCaptureImage);
	}, [doCaptureImage]);

	const onCountTick = useCallback((ev) => {
		if (ev === 1) {
			countdownCompleted();
		}
	}, [countdownCompleted]);

	const onResponseWorker = useCallback((e) => {
		// console.log('BodyAlignmentScan onResponseWorker', e);
		if (e.type === 'checkLocation' || e.type === 'checkLocationSide') {
			const bodyAdjustmentDirection = e.value;
			if (cameraStatus === SEQUENCE_FRONT_POSITIONING || cameraStatus === SEQUENCE_RIGHT_POSITIONING) {
				if (bodyAdjustmentDirection.result === "good") {
					goodCountRef.current += 1;
					setLoopVoiceGuide("");
				} else {
					goodCountRef.current = 0;
					setLoopVoiceGuide("SCH_BUTTON_10");
				}
				if (goodCountRef.current >= BODY_LOCATION_GOOD_TRIGER_COUNT && !testingPauseRef.current) {
						goodCountRef.current = 0;
						SoundEffect.playAudio("SCH_BUTTON_3");
						setCameraStatus(cameraStatus + 1); //SEQUENCE_FRONT_POSITION_MATCHED, SEQUENCE_RIGHT_POSITION_MATCHED
						setCheckLocationResult(bodyAdjustmentDirection);
					// }
				}
				setBodyPositionCorrectionDirection(bodyAdjustmentDirection);
			} else if (cameraStatus === SEQUENCE_FRONT_POSITION_MATCHED || cameraStatus === SEQUENCE_RIGHT_POSITION_MATCHED) {
				if (bodyAdjustmentDirection.result !== "good") {
					goodCountRef.current = 0;
					//go to POSITIONING
					setVoiceGuide("");
					seekToPrevious(cameraStatus === SEQUENCE_FRONT_POSITION_MATCHED ? SEQUENCE_FRONT_POSITIONING_TIME : SEQUENCE_RIGHT_POSITIONING_TIME);
					setGuideSignMsg("");
					setCheckLocationResult(bodyAdjustmentDirection);
				}
			} else { // neither positioning nor matched
				goodCountRef.current = 0;
			}
		}
		if (e.type === 'process') {
			const processRes = e.value;
			cancelGetEventNotification(); // 카메라 이벤트 캔슬
			// console.log('e type process2222', processRes);
			setVoiceGuide("MEASURESOUND"); // 사진 촬영음
			if (cameraStatusRef.current === SEQUENCE_FRONT_POSITION_MATCHED) {//정면
				captureImage("front", lastBodyPositionData.current, processRes);
			} else {
				captureImage("side", lastBodyPositionData.current, processRes);
			}
		}
	}, [cameraStatus]);

	useEffect(() => {
		WebWorkerUtil.setCallback(mWorker.current, onResponseWorker);
	}, [onResponseWorker]);

	useEffect(() => {
		setStepNum(0);
		setMounted(true);
		if (cesShowMode) {
			dispatch(clearTestResults());
		}
		WebWorkerUtil.makeWorker(WORKER_ID.XFIT, onResponseWorker).then((workerId) => {
			mWorker.current = workerId;
		});
		if (typeof window === 'object') window.setCameraStatus = setCameraStatus;
		dispatch(clearCurrentTestingStatus());
		Spotlight.focus("bodyScanGuideSkipBtn");
		setTimeout(() => {
			TTSService.stop();
		}, 1000);

		mountedTime.current = new Date();

		return () => {
			const keys = Object.keys(VIDEO_ACTION_ON_TIME);
			for (let i = 0; i < keys.length; i++) {
				VIDEO_ACTION_ON_TIME[keys[i]].excuted = false;
			}
			deActivatePositionDetection();
			dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
		};
	}, []);

	const seekToPrevious = useCallback((sec) => {
		const keys = Object.keys(VIDEO_ACTION_ON_TIME);
		for (let i = 0; i < keys.length; i++) {
			if (Number(keys[i]) >= sec) {
				VIDEO_ACTION_ON_TIME[keys[i]].excuted = false;
			}
			if (sec === Number(keys[i])) {
				doSequancialAction(VIDEO_ACTION_ON_TIME[keys[i]], keys[i]);
			}
		}
		videoPlayer.current.seekTo(sec);
	}, []);


	const doSequancialAction = useCallback((action, key) => {
		if (action && !action.excuted) {
			console.log('BodyAlignmentScan doSequancialAction[action,key,cameraStatus]:', action, key, cameraStatusRef.current);
			action.excuted = true;
			if (action.voiceGuide) {
				setVoiceGuide(action.voiceGuide);
			}
			if (action.guideSignMsg) {
				setGuideSignMsg(action.guideSignMsg);
				if (!action.voiceGuide) {
					setVoiceGuide(action.guideSignMsg);
				}
			}
			if (typeof action.loopVoiceGuide !== "undefined") {
				setLoopVoiceGuide(action.loopVoiceGuide);
			}
			if (action.videoPause) {
				videoPlayer.current.pause();
			}
			if (action.sequence) {
				setCameraStatus(action.sequence);
			}
		}
	}, []);

	const onVideoProgress = useCallback((ev) => {
		const current = ev;
		//trigger
		if (!videoPlayer.current.paused()) {
			const keys = Object.keys(VIDEO_ACTION_ON_TIME);
			let matched = -1;
			for (let i = 0; i < keys.length; i++) {
				if (Number(current) >= Number(keys[i]) && !VIDEO_ACTION_ON_TIME[keys[i]].excuted) {
					matched = keys[i];
					break;
				}
			}
			const action = VIDEO_ACTION_ON_TIME[matched];
			doSequancialAction(action, matched);
		}
		// SEQUENCE_BEFORE_FINISHED
		if (Number(current) > 48 && stepNum < orderBodyAlignmentList.length - 1) {
			setStepNum(num => num + 1);
		}
		// videoProgress.current = current;
	}, [stepNum]);

	const onVideoEnd = useCallback(() => {
		const action = VIDEO_ACTION_ON_TIME["end"];
		doSequancialAction(action, "end");
	}, []);

	const onVoiceGuideEnd = useCallback((ev) => {
		if (!testingPauseRef.current) {
			if (ev === 'M4') {//측정하겠습니다.
				if (cameraStatusRef.current === SEQUENCE_FRONT_POSITION_MATCHED || cameraStatusRef.current === SEQUENCE_RIGHT_POSITION_MATCHED) {
					if(cesShowMode){
						countdownCompleted();
					}else{
						setIsCountDownStarted(true);
					}
				}
			}
			if (ev === "MEASURESOUND") { //찰칵
				setVoiceGuide("RING2"); //완료음
				setIsGoodSignOn(true);
				setCameraStatus(cameraStatusRef.current + 1); //SEQUENCE_FRONT_CAPTURED, SEQUENCE_RIGHT_CAPTURED
			}
			if (ev === 'RING2') {
				videoPlayer.current.play();
			}
		}
	}, [countdownCompleted]);

	const onGoodSignAniFinished = useCallback(() => {
		setTimeout(() => {
			setIsGoodSignOn(false);
			setStepNum(num => num + 1);
		}, 1000);
	}, [])


	useEffect(() => {
		const canvasSize = { w: 960, h: 1080 };
		// const cameraSize = { w: 1280, h: 720 };

		const scale = canvasSize.h / cameraSize.height;
		const leftMargin = (cameraSize.width * scale - canvasSize.w) / 2;
		if (!!bodyPositionLiveData?.joints2D?.length) {
			BELLY_CHECKING_INDEX.forEach((value, index) => {
				const bellyCoordination = bodyPositionLiveData.joints2D[value];
				const reversedCameraBellyX = canvasSize.w - (bellyCoordination[0] * scale - leftMargin);
				const reversedCameraBellyY = bellyCoordination[1] * scale;
				if (!bellySmootherRef.current[index]) {
					bellySmootherRef.current[index] = new BellySmoother(xFitBodyBellySmootherQueueSize);
				}
				bellySmootherRef.current[index].pushValue(reversedCameraBellyX, reversedCameraBellyY);
			});
		}
	}, [bodyPositionLiveData, cameraSize]);

	const popupDesc = useMemo(() => {
		return [
			$L("If possible, stand on a solid, unpatterned background."),
			$L("Please keep a distance of 2M from the TV so that your whole body is on the camera."),
			$L("First, the frontal toe and belly are photographed, and later the sides are photographed."),
			$L("Make sure the camera is turned on and follow the instructions in the video."),
			$L("For accurate measurements, tie or tuck your hair back in light, not thick clothing."),
		];
	}, []);

	const onClickSkip = useCallback(() => {
		dispatch(changeLocalSettings({ bodyScanSkip: !bodyScanSkip }));
	}, [bodyScanSkip]);

	const onClickInstruction = useCallback((index) => {
		if (index) {
			// 시작하기
			Spotlight.focus("bodyScanGuideSkipBtn");
			if (videoPlayer.current) {
				setCameraStatus(SEQUENCE_INIT);
			}
		} else {
			// 나가기
			dispatch(popPanel());
		}
		setIsInstruction(false);
	}, []);

	const testType = () => {
		switch (directionSign) {
			case "front":
				return <div>{$L("Front")}</div>;
			case "side":
				return <div>{$L("Side")}</div>;
			default:
				break;
		}
	};

	useEffect(() => {
		testingPauseRef.current = isTestingPaused;
	}, [isTestingPaused]);

	useEffect(()=>{
		if(cameraList && cameraList.length < 1){
			dispatch(popPanel(Config.panel_names.BODY_ALIGNMENT_SCAN));
		}
	},[cameraList]);

	const onTestPause = useCallback((e) => {
		if (videoPlayer.current) {
			videoPlayer.current.pause();
		}
		setIsTestingPaused(true);
		clearTimeout(positioningTimer.current);
		positioningTimer.current = null;

		clearTimeout(finishTimer.current);
		finishTimer.current = null;

		disableDirectionSignJob.stop();
		resumeTestJob.stop();
		SoundEffect.stopAll();
		setIsSkipped(false);

		cancelGetEventNotification();
		setPlayVideo(false);
		setIsCountDownStarted(false);
		setDirectionSign("");
		setLoopVoiceGuide("");
		setVoiceGuide("");
		setBodyPositionCorrectionDirection({});
	}, [videoPlayer]);

	useEffect(()=>{
		Spotlight.focus("bodyScanGuideSkipBtn");
	},[cameraStatus, isSkipped])

	const resumeTest = useCallback(() => {
		Spotlight.focus("bodyScanGuideSkipBtn");
		setIsTestingPaused(false);
		setPlayVideo(true);
		switch (cameraStatusRef.current) {
			case 0:
			case 1:
			case 2:
			case 3:
				seekToPrevious(SEQUENCE_FRONT_START_TIME);
				videoPlayer.current.play();
				break;
			case 4:
			case 10:
			case 11:
			case 12:
				seekToPrevious(SEQUENCE_RIGHT_START_TIME);
				videoPlayer.current.play();
				break;
			case 13:
			case 20:
				break;
			default:
				break;
		}
	}, []);

	const onTestResume = useCallback(() => {
		resumeTestJob.startAfter(500, resumeTest);
	}, [resumeTestJob]);

	useEffect(() => {
		console.log("camera status 2222= ", cameraStatus);
	}, [cameraStatus]);

	const backKeyHandler = useCallback((ev) => {
		dispatch(popPanel());
		if (ev) {
			ev?.stopPropagation();
			ev?.preventDefault();
		}
	},[dispatch]);

	const pauseHandler = useCallback((ev) => {
		if (ev) ev.stopPropagation();
		if (isTestClose) {
			if (videoPlayer) {
				onTestResume();
			}
			setTestClose(false);
		} else {
			if (cameraStatus && !(cameraStatus === SEQUENCE_FRONT_CAPTURED || cameraStatus === SEQUENCE_RIGHT_CAPTURED || cameraStatus === SEQUENCE_FINISHED)) {
				setTestClose(true);
				onTestPause();
			} else return;
		}
	}, [dispatch, cameraStatus, isTestClose]);

	const orderBodyAlignmentList = useMemo(() => {
		return [$L('Start'), $L("Front"), $L("Side"), $L('Finished')];
	}, []);

	const skipRenderer = useCallback((isTooltip) => {
		return (
			<TToolTip
				position={POSITION.top}
				color={COLOR.green}
				relativeRef={skipRef}
				relativePosition="left"
				isOpen={isTooltip}
			>
				{$L("Skip the guide video for the current body part.")}
			</TToolTip>
		)
	}, []);

	const onSkipBtn = useCallback(() => {
		setVoiceGuide("");
		if (cameraStatus < SEQUENCE_FRONT_POSITIONING) {
			videoPlayer.current.seekTo(SEQUENCE_FRONT_POSITIONING_TIME);
		} else if (cameraStatus < SEQUENCE_RIGHT_POSITIONING) {
			videoPlayer.current.seekTo(SEQUENCE_RIGHT_POSITIONING_TIME);
		}
		setIsSkipped(true);
	}, [cameraStatus]);

	const onClickTestClose = useCallback((index) => {
		if (!index) {
			if (videoPlayer) onTestResume();
		}
		setTestClose(false);
	}, [videoPlayer]);

	const onBodyCorrectionSign = useCallback((ret) => {
		if (ret.instruction) {
			setGuideSignMsg(ret.instruction);
		} else {
			setGuideSignMsg("");
		}
	}, []);

	// const handleScan = useCallback(()=>{
	// 	//todo (수동스캔)
	// 	console.log('handleScan', goodCountRef.current, 'cameraStatus', cameraStatus)
	// 	goodCountRef.current = 0;
	// 	SoundEffect.playAudio("SCH_BUTTON_3");
	// 	setCameraStatus(cameraStatus + 1); //SEQUENCE_FRONT_POSITION_MATCHED, SEQUENCE_RIGHT_POSITION_MATCHED
	// 	setCheckLocationResult(bodyAdjustmentDirectionRef.current);
	// },[cameraStatus, goodCountRef, bodyAdjustmentDirectionRef]);

	return (
		<CancelableDiv className={css.container} handleCancel={backKeyHandler}>
			<PreloadImage preloadImages={preloadImages} />
			{(isGoodSignOn) && (
				<GoodSignAnimation onAnimationEnd={onGoodSignAniFinished} />
			)}

			{/* stepper */}
			{mounted &&
				<TStepper className={css.stepperHorizon} order={orderBodyAlignmentList} number={stepNum}
					// (cameraStatusRef.current === SEQUENCE_RIGHT_CAPTURED || cameraStatusRef.current === SEQUENCE_FRONT_CAPTURED) ? stepNum - 1 : stepNum + 1}
					 type={"horizontal"} />
				}
			<div className={css.cl_bgVideoType}>

				{/* <div className={css.guideTitWrap}>
          <TIconButton iconType="home" onClick={backKeyHandler}/>
        </div> */}

				{/* 3,2,1 */}
				{!cesShowMode && showPIPCamera && isCountDownStarted && !isTestClose && !isTestingPaused && (
					<BodyCheckUpCountdownTimer
						isCountDownStarted={isCountDownStarted}
						onTick={onCountTick}
					/>
				)}
				<section>
					<SpottableDiv
						className={classNames(css.avatar, showLoadingPanel.show && css.hide)}
						id="shakaPlayer"
					// onClick={onTestPause}
					>
						<TShakaPlayer
							playerRef={videoPlayer}
							className={css["shaka-player"]}
							style={{ zIndex: -1 }}
							src={bodyScan}
							poster={/*bodyScanThumbnail*/""}
							muted={true}
							onProgress={onVideoProgress}
							onEnded={onVideoEnd}
						/>

						{/* position guide */}
						{/* {cameraStatus >= SEQUENCE_FRONT_START &&
							<div className={css.counterContainer}>
								<CirclePositionGuide mainText={$L("Location guide")} subText={cameraStatus < SEQUENCE_RIGHT_START ? $L("Front") : $L("Side")} currentStep={cameraStatus < SEQUENCE_RIGHT_START ? 1 : 2} totalSteps={2} />
							</div>
						} */}

						{/* check L, R */}
						<div className={css.labelContainer}>
							<div className={classNames(css.label)}>{"L"}</div>
							<div className={classNames(css.label, css.isRight)}>{"R"}</div>
						</div>
					</SpottableDiv>

					<div className={css.user}>
						{showPIPCamera && (
							<>
								<PIPCamera
									size="medium"
									onCameraReady={onCameraReady}
								/>
								{showBodyCoordinations && cameraStatus >= SEQUENCE_FRONT_START &&
									<BodyPositionViewer bodyPositionLiveData={bodyPositionLiveData} cameraSize={cameraSize} />
								}

								{/* belly Point */}
								{(cameraStatus === SEQUENCE_FRONT_POSITIONING || cameraStatus === SEQUENCE_RIGHT_POSITIONING) &&
									BELLY_CHECKING_INDEX.map((bIndex, index) => {
										const point = bellySmootherRef.current[index] ? bellySmootherRef.current[index].getAverageData() : [];
										const style = point[0] ? { left: point[0] + "px", top: point[1] + "px" } : {};
										return (
											<img
												key={index}
												src={bellyPoint}
												alt="bellyPoint"
												className={classNames(css.bellyPoint, point.length <= 0 && css.hide)}
												style={style}
											/>
										);
									})}

								{/* body position sign */}
								{cameraStatus >= SEQUENCE_FRONT_START &&
									<BodyPositionCorrectionSign
										bodyPositionCorrectionDirection={bodyPositionCorrectionDirection}
										isFront={cameraStatus < SEQUENCE_RIGHT_START}
										feedbackEnable={(cameraStatus === SEQUENCE_FRONT_POSITIONING || cameraStatus === SEQUENCE_RIGHT_POSITIONING)}
										onBodyCorrectionSign={onBodyCorrectionSign}
										bodyPositionLiveData={bodyPositionLiveData}
										cameraSize={cameraSize}
										showScanner={voiceGuide === 'MEASURESOUND'}
										showOutline={true}
									/>
								}
							</>
						)}
					</div>
				</section>
			</div>

			{/* direction Sign */}
			<div
				className={classNames("animate__animated", css.testType, {
					animate__fadeIn: !isInstruction && !showLoadingPanel.show && !!directionSign,
					animate__fadeOut: isInstruction && !showLoadingPanel.show && !directionSign,
				})}
			>
				{testType()}
			</div>

			{/* Guide text */}
			{guideSignMsg &&
				<TestGuideSignMsg message={guideSignMsg} />
			}

			{/* skip btn */}
			<ButtonContainer className={classNames(showLoadingPanel.show && css.hide)}>
				{/* guide skip */}
				{(cameraStatus < SEQUENCE_FRONT_POSITIONING || (cameraStatus >= SEQUENCE_RIGHT_START && cameraStatus < SEQUENCE_RIGHT_POSITIONING)) && !skipVideoGuide && !isSkipped &&
					<div className={css.videoSkip} ref={skipRef}>
						<TButton className={css.skipBtn} spotlightId="bodyScanGuideSkipBtn" size="small" itemRenderer={skipRenderer} onClick={onSkipBtn}>
							{$L("Skip guide")}
							{/* <span /> */}
						</TButton>
					</div>
				}
				
				{/* pause */}
				{!(cameraStatus === SEQUENCE_FRONT_CAPTURED
					|| cameraStatus === SEQUENCE_RIGHT_CAPTURED
					|| cameraStatus === SEQUENCE_FINISHED) &&
					<TIconButton
						className={css.pauseBtn}
						iconType="testPause"
						spotlightId="bodyScanPauseBtn"
						onClick={pauseHandler}
					/>
				}
			</ButtonContainer>

			{/* instruction */}
			{!cesShowMode && isInstruction && mounted &&
				<TPopUp
					kind="textPopUp"
					title={$L("Instruction")}
					text={$L("Read precautions below before taking the test.")}
					subText={popupDesc}
          button1text={$L("Quit")}
					button2text={$L("Getting started")}
					hasCheck
					onCheck={bodyScanSkip}
					onClickCheck={onClickSkip}
					onClick={onClickInstruction}
					style={{ width: "948px" }}
				/>
			}

			{/* guide sound */}
			<AudioPlayer srcTypeStr={voiceGuide} onEnded={onVoiceGuideEnd} />

			{/* bgm */}
			{playVideo && <AudioPlayer srcTypeStr={"DISCOPOP"} loop volume={Config.BODY_TEST_BG_VOLUME} />}

			{/* loop sound */}
			{loopVoiceGuide && <AudioPlayer loopVoiceGuide={loopVoiceGuide} loop autoPlay />}

			{/* test end complete popup */}
			{showTestEndComplete &&
				<TestEndCompletePopup
					open
					testType="single"
				/>
			}

			{/* pause UI */}
			{isTestClose && <TTestClosePopup onClick={onClickTestClose} />}

		</CancelableDiv>
	);
};

export default BodyAlignmentScan;
