import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./SubTab.module.less";
import EmptyResultsReport from "../../../../../components/EmptyResultsReport/EmptyResultsReport";
import * as Config from "../../../../../utils/Config";
import { addPanels } from "../../../../../features/panels/panelsSlice";
import TSimpleButtonTab, {
  LIST_TYPE,
} from "../../../../../components/TSimpleButtonTab/TSimpleButtonTab";
import { convertNewlinesToBr } from "../../../../../utils/helperMethods";
import { $L } from "../../../../../utils/helperMethods";
import TButton, { SIZES } from "../../../../../components/TButton/TButton";
import { POSITION_TYPE, getContentsArr } from "./Constants";
import TFixBarItem from "../../../../../components/TFixBarItem/TFixBarItem";
import classNames from "classnames";
import {
  voiceGuideEmpty,
  voiceGuideIsError,
  voiceGuideSubTabROM,
} from "../../feedback/feedbackROM";
import { SUB_TYPE } from "../../feedback/constantsFeedback";
import { romMenu } from "../Constants";
import {
  IMG_DATA,
  DEGREE_DATA,
  CANVAS_POINT,
  DEGREE_DETAIL_DATA,
} from "./ConstantsSubTab";
import { getContentList } from "../../../../../features/fitService/fitServiceSlice";
import ROMReportAngleCanvas from "../ROMReportAngleCanvas/ROMReportAngleCanvas";
import { changeAppStatus } from "../../../../../features/common/commonSlice";

let contentsArr = null;
const SubTab = ({
  date = "",
  type,
  feedback,
  setFeedback,
  tabInfo,
  openMenuTestPopup,
  selectedTabIndex,
  ...rest
}) => {
  if (!contentsArr) {
    contentsArr = getContentsArr();
  }
  const dispatch = useDispatch();

  const { testResults } = useSelector((state) => state.rom);

  // Test Tab Button Index
  const [testIndex, setTestIndex] = useState(0);
  // Left or Right, Back or Front Tab Index
  const [positionIndex, setPositionIndex] = useState(0);

  const hasData = useMemo(() => {
    const showingGrade = tabInfo?.showingGrade;

    if (showingGrade === "f") {
      setFeedback(voiceGuideEmpty(tabInfo?.type));
      return false;
    } else {
      return true;
    }
  }, [tabInfo]);

  const convertTabInfo = useMemo(() => {
    const testInfo = tabInfo?.data;
    const testKeys = Object.keys(testInfo);

    const currentTestName = testKeys[testIndex];

    if (testInfo[currentTestName]) {
      const countKeys = Object.keys(testInfo[currentTestName].grade);

      const tabStyle = () => {
        switch (currentTestName) {
          case SUB_TYPE.NECK_FLEXION:
          case SUB_TYPE.NECK_EXTENSION:
          case SUB_TYPE.TRUNK_FLEXION:
          case SUB_TYPE.TRUNK_EXTENSION:
            return false;
          default:
            return true;
        }
      };
      const labelStyle = () => {
        switch (currentTestName) {
          case SUB_TYPE.NECK_LATERAL_FLEXION:
          case SUB_TYPE.SHOULDER_ABDUCTION:
          case SUB_TYPE.TRUNK_LATERAL_FLEXION:
            return [POSITION_TYPE.LEFT_LABEL, POSITION_TYPE.RIGHT_LABEL];
          case SUB_TYPE.NECK_FLEXION:
          case SUB_TYPE.NECK_EXTENSION:
          case SUB_TYPE.TRUNK_FLEXION:
          case SUB_TYPE.TRUNK_EXTENSION:
            return [POSITION_TYPE.FRONT_LABEL, POSITION_TYPE.BACK_LABEL];
          default:
            return [POSITION_TYPE.BACK_LABEL, POSITION_TYPE.FRONT_LABEL];
        }
      };

      let bodyOrientation = {
        testName: currentTestName,
        style: {
          tab: tabStyle(),
          label: labelStyle(),
        },
      };
      countKeys.map((key) => {
        const keyValue = testInfo[currentTestName].value[key];
        const isError = keyValue === 0 || keyValue === 999 || keyValue === -999;
        bodyOrientation = {
          ...bodyOrientation,
          [key]: {
            grade: testInfo[currentTestName].grade[key],
            value: keyValue,
            isError,
          },
        };
      });

      return bodyOrientation;
    }
  }, [tabInfo, testIndex]);

  const onTestClick = useCallback(
    ({ index, ...rest }) => {
      setTestIndex(index);
      setPositionIndex(0);
    },
    [testIndex]
  );

  const onPositionClick = useCallback(({ index, ...rest }) => {
    setPositionIndex(index);
  }, []);

  const goWorkout = useCallback(() => {
    // todo remove. content 유무 확인용
    let contentId = `ROM_${type}_${convertTabInfo.testName}`;

    dispatch(
      getContentList({
        type: "recommend",
        subType: "ROM",
        category: type,
        subCategory: convertTabInfo.testName,
      })
    );
    dispatch(addPanels({ name: Config.panel_names.CONTENT_PLAYER_START, panelInfo: { contentId } }));
    // dispatch(
    //   addPanels({
    //     name: Config.panel_names.HOMECARE_RECOMMEND_WORKOUT,
    //     panelInfo: { contentId, index: 1 },
    //   })
    // );
  }, [convertTabInfo]);

  const ROM_TEST_ACTIONS = {
    [romMenu.NECK]: 0,
    [romMenu.SHOULDER]: 1,
    [romMenu.TRUNK]: 2,
    [romMenu.HIP]: 3,
    [romMenu.KNEE]: 4,
  };

  const testStart = useCallback(() => {
    dispatch(
      addPanels({
        name: Config.panel_names.ROM_TEST,
        panelInfo: { romList: [ROM_TEST_ACTIONS[type]] },
      })
    );
    dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
  }, [dispatch, type]);

  const convertImgUrl = useMemo(() => {
    if (convertTabInfo) {
      const testName = convertTabInfo?.testName;
      const value = convertTabInfo[positionIndex].value;
      const labelLR = positionIndex === 0 ? "L" : "R";
      const degreeData = DEGREE_DETAIL_DATA[type][testName];

      const findIndex = degreeData.findIndex((el) => value > el);
      const imgIndex = findIndex !== -1 ? findIndex : degreeData.length - 1;

      const hasTab = convertTabInfo?.style?.tab;
      const originResource = hasTab
        ? IMG_DATA[type][testName][labelLR]
        : IMG_DATA[type][testName];

      return originResource[imgIndex];
    }
  }, [convertTabInfo, type, positionIndex]);

  const convertLRTab = useMemo(() => {
    switch (type) {
      case romMenu.SHOULDER:
        return [$L("Left Shoulder"), $L("Right Shoulder")];
      case romMenu.HIP:
        return [$L("Left Hip"), $L("Right Hip")];
      case romMenu.KNEE:
        return [$L("Left Knee"), $L("Right Knee")];
      default:
        return [$L("Left"), $L("Right")];
    }
  }, [type]);

  useEffect(() => {
    if (convertTabInfo && hasData) {
      if (convertTabInfo[positionIndex].isError) {
        setFeedback(voiceGuideIsError());
      } else {
        setFeedback(
          voiceGuideSubTabROM(
            type,
            convertTabInfo?.testName,
            convertTabInfo[positionIndex]?.grade
          )
        );
      }
    }
  }, [type, convertTabInfo, positionIndex]);

  // Reset all tab indices when moving the main(selectedTabIndex) tab
  useEffect(() => {
    setTestIndex(0);
    setPositionIndex(0);
  }, [selectedTabIndex]);

  return (
    <div>
      {testResults?.[date] && hasData && convertTabInfo ? (
        <div className={css.container}>
          <TSimpleButtonTab
            className={css.topTap}
            listType={LIST_TYPE.detailTest}
            contents={contentsArr[type]}
            onItemClick={onTestClick}
            selectedIndex={testIndex}
          />
          <div className={css.flexBox}>
            <div className={css.imageContainer}>
              {convertTabInfo?.style?.tab && (
                <TSimpleButtonTab
                  className={css.positionTab}
                  listType={LIST_TYPE.leftRightTab}
                  contents={convertLRTab}
                  onItemClick={onPositionClick}
                  selectedIndex={positionIndex}
                />
              )}
              <div
                className={classNames(
                  css.valueLabel,
                  convertTabInfo?.style?.tab
                    ? css.valueLabelS
                    : css.valueLabelL,
                  ((positionIndex === 0 &&
                    (convertTabInfo?.testName === SUB_TYPE.HIP_FLEXION ||
                      convertTabInfo?.testName ===
                        SUB_TYPE.SHOULDER_EXTERNAL_ROTATION ||
                      convertTabInfo?.testName === SUB_TYPE.SHOULDER_FLEXION ||
                      convertTabInfo?.testName ===
                        SUB_TYPE.SHOULDER_INTERNAL_ROTATION)) ||
                    (positionIndex === 1 &&
                      !(
                        convertTabInfo?.testName === SUB_TYPE.HIP_FLEXION ||
                        convertTabInfo?.testName ===
                          SUB_TYPE.SHOULDER_EXTERNAL_ROTATION ||
                        convertTabInfo?.testName ===
                          SUB_TYPE.SHOULDER_FLEXION ||
                        convertTabInfo?.testName ===
                          SUB_TYPE.SHOULDER_INTERNAL_ROTATION
                      )) ||
                    convertTabInfo?.testName === SUB_TYPE.NECK_EXTENSION ||
                    convertTabInfo?.testName === SUB_TYPE.TRUNK_EXTENSION) &&
                    css.valueLabelRight
                )}
              >
                {!convertTabInfo[positionIndex].isError &&
                  Math.round(convertTabInfo[positionIndex].value) + "°"}
              </div>
              <div
                className={classNames(
                  css.testImg,
                  convertTabInfo?.style?.tab ? css.backgroundS : css.backgroundL
                )}
              >
                {!convertTabInfo[positionIndex].isError ? (
                  <ROMReportAngleCanvas
                    className={css.resultImg}
                    grade={convertTabInfo[positionIndex].grade}
                    centerPoint={
                      CANVAS_POINT?.[type]?.[convertTabInfo?.testName]?.[
                        positionIndex
                      ]?.[0]
                    }
                    basePoint={
                      CANVAS_POINT?.[type]?.[convertTabInfo?.testName]?.[
                        positionIndex
                      ]?.[1]
                    }
                    guideAngles={DEGREE_DATA[type][convertTabInfo?.testName]}
                    degree={convertTabInfo[positionIndex].value}
                    isNegative={
                      CANVAS_POINT?.[type]?.[convertTabInfo?.testName]?.[
                        positionIndex
                      ]?.[2]
                    }
                    debug={false}
                  />
                ) : (
                  <div className={css.isError}>{$L("Not Tested")}</div>
                )}
                <div
                  className={css.resultImg}
                  style={{
                    backgroundImage: `url(${convertImgUrl})`,
                  }}
                />
              </div>
              <div className={css.labelContainer}>
                <div className={css.positionLabel}>
                  {convertTabInfo?.style?.label[0] ===
                  POSITION_TYPE.LEFT_LABEL
                    ? convertTabInfo?.style?.label[0]
                    : positionIndex === 0
                    ? convertTabInfo?.style?.label[0]
                    : convertTabInfo?.style?.label[1]}
                </div>
                <div className={css.positionLabel}>
                  {convertTabInfo?.style?.label[0] ===
                  POSITION_TYPE.LEFT_LABEL
                    ? convertTabInfo?.style?.label[1]
                    : positionIndex === 0
                    ? convertTabInfo?.style?.label[1]
                    : convertTabInfo?.style?.label[0]}
                </div>
              </div>
            </div>
            <div className={css.contents}>
              <div className={css.textBox}>
                <div className={css.title}>{feedback?.title}</div>
                <div className={css.detail}>{feedback?.subtitle}</div>
              </div>
              <div className={css.ratingBox}>
                <div className={css.rating}>
                  <div>
                    <span />
                    {$L("Measurement management level")}
                  </div>
                  <div>
                    {convertTabInfo[positionIndex].isError
                      ? $L("Not Tested")
                      : Math.round(convertTabInfo[positionIndex].value) + "°"}
                  </div>
                </div>
                <TFixBarItem
                  className={css.tBar}
                  value={convertTabInfo[positionIndex].value}
                  grade={convertTabInfo[positionIndex].grade}
                  degree={DEGREE_DATA[type][convertTabInfo?.testName]}
                  isError={convertTabInfo[positionIndex].isError}
                />
              </div>
            </div>
          </div>
          <div className={css.testBtnWrap}>
            <TButton size={SIZES.test} onClick={goWorkout}>
              {$L("Workout")}
            </TButton>
            <TButton size={SIZES.test} onClick={testStart}>
              {$L("Part Test")}
            </TButton>
          </div>
        </div>
      ) : (
        <EmptyResultsReport
          openMenuTestPopup={openMenuTestPopup}
          partResult={true}
          title={feedback?.title}
          detail={feedback?.subtitle}
        />
      )}
    </div>
  );
};

export default SubTab;
