import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";
import css from "./TChips.module.less";
import TChipsItem from "./TChipsItem";

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const SIZES = {large: "large", medium: "medium", small: "small",};
const SHAPE = {round: "round", square: "square"};
const COLOR = {transparent: "transparent"}

const TChips = ({
  className,
  size=SIZES.medium,
  shape=SHAPE.square,
  color=COLOR.transparent,
  selectedIndex,
  onItemClick,
  contents = [],
  noMarquee = false,
  ...rest
}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);
  const onClick = useCallback(
    (index) => (ev) => {
      if (onItemClick) {
        onItemClick({ index, ev });
      }
    },
    [selectedIndex]
  );

  return (
    <Container
      className={classNames(css.chips, css[size], className)}
      {...rest}
    >
      {contents.map((item, index) => {
        return (
          <TChipsItem
            key={"chips" + index}
            size={size}
            shape={shape}
            isDark={themeMode === "dark"}
            selected={index === selectedIndex}
            onClick={onClick(index)}
          >
            {item}
          </TChipsItem>
        );
      })}
    </Container>
  );
};

export default TChips;
export {SIZES, SHAPE, COLOR};