import css from "./HealthBall.module.less";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";

import level01 from "../../../../../assets/myPanel/healthBall/img_my_healthball_01.png"
import level02 from "../../../../../assets/myPanel/healthBall/img_my_healthball_02.png"
import level03 from "../../../../../assets/myPanel/healthBall/img_my_healthball_03.png"
import level04 from "../../../../../assets/myPanel/healthBall/img_my_healthball_04.png"
import level05 from "../../../../../assets/myPanel/healthBall/img_my_healthball_05.png"
import level06 from "../../../../../assets/myPanel/healthBall/img_my_healthball_06.png"
import level07 from "../../../../../assets/myPanel/healthBall/img_my_healthball_07.png"
import level08 from "../../../../../assets/myPanel/healthBall/img_my_healthball_08.png"
import level09 from "../../../../../assets/myPanel/healthBall/img_my_healthball_09.png"
import level10 from "../../../../../assets/myPanel/healthBall/img_my_healthball_10.png"

import { $L } from "../../../../utils/helperMethods";
import * as Config from "../../../../utils/Config";
import TButton, {SIZES, ICONS, TYPES} from "../../../../components/TButton/TButton";
import ToolTip, { POSITION } from "./Tooltip/ToolTip";

import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");
const SpottableComponent = Spottable("div");

const LEVEL = [level01, level02, level03, level04, level05, level06, level07, level08, level09, level10];

const HealthBall = ({className, ...rest}) => {
  const [hideBallMsg, setHideBallMsg] = useState(true);
  const day = 5

  const toggleBallMessage = useCallback(() => {
    setHideBallMsg((isHidden) => !isHidden);
  }, []);

  const dayText = useMemo(() => {
    return $L("+{num}-day workout").replace("{num}", day);
  }, []);

  const ballHideMessage = useMemo(() => {
    return $L(`
              <span>헬스 뱃지 숨김 상태입니다.</span>
              <span>헬스 뱃지 보기를 사용하여 헬스 레벨을 키워보세요!</span>
  `);
  }, []);

  return (
    <Container className={classNames(css.healthBall, className)}>
      <Container className={css.titleContainer}>
        <div className={css.titleArea}>
          <div className={css.title}>{$L("나의 헬스 뱃지")}</div>
          <SpottableComponent className={css.icon}>
            <ToolTip
              className={css.tooltip}
              title={$L("헬스뱃지란?")}
              description={$L("의학기술의 발달로 기대수명이 증가함에 따라 고객은 자연노화 질환에 노출되었습니다. 이를 해결하고자 자사는 특화 운동프로그램을 개발하여 운동효과를 검증하였고, 고객은 본 특화 운동프로그램으로 이를 예방할 수 있습니다. 얼마나 더 건강해졌는지 헬스뱃지로 시각화하고, 예방운동 실적에 따라 10등급으로 나누어 발급하여, 고객이 더 열심히 예방운동을 하도록 동기부여를 주고자 합니다.")}
            />
          </SpottableComponent>
        </div>
        <TButton 
          className={css.button} 
          onClick={toggleBallMessage}
          type={TYPES.withIcon}
          size={SIZES.tiny}
          icon={hideBallMsg ?  ICONS.show : ICONS.hidden}
        >
          {hideBallMsg ? $L("show") : $L("hide")}
        </TButton>
      </Container>
      {/* todo : health ball server 연결 */}
      {hideBallMsg ? (
        <div className={css.ball}>
          <SpottableComponent className={css.image} style={{ backgroundImage: `url(${LEVEL[0]})` }}>
            <ToolTip
              className={css.tooltip}
              position={POSITION.top}
              title={$L("헬스 뱃지 10단계에요")}
              description={$L("누군가 말하길, 행동을 21일 동안 반복하면 습관이 된다고 해요. 운동이 생활이 되는 그날까지!")}
            />
          </SpottableComponent>
          <div className={css.text}>{dayText}</div>
        </div>
      ) : (
        <div
          className={css.message}
          dangerouslySetInnerHTML={{ __html: ballHideMessage }}
        />
      )}
      <div className={css.reference}>
        {$L( "나의 헬스볼은 참고용입니다. 의학적인 진단의 근거가 될 수 없습니다.")}
      </div>
    </Container>
  );
};

export default HealthBall;