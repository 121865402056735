import classNames from 'classnames';
import css from "./ResetSurveyBody.module.less";

import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { getSurveyData } from "../../../../utils/surveyData";
import { $L } from '../../../../utils/helperMethods';

import Question from '../../../SurveyPanel/Question/Question';

const ResetSurveyBody = ({className, ...rest}) => {
  const surveyData = getSurveyData();
  const bodyInfo = useMemo(() => surveyData.filter(item => item.part === "exercise"), []);

  return (
    <div className={classNames(className)}>
      <div className={css.title}>{$L("Reset workout goals and preferred workouts")}</div>
      <div className={css.questionContainer}>
        {bodyInfo.map((question, index) =>
          <Question
            key={"question" + index}
            className={css.question}
            question={question}
          />
        )}
      </div>
    </div>
  );
}
export default ResetSurveyBody