import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {$L} from '../../../utils/helperMethods';
import SpotlightIds from '../../../utils/SpotlightIds';
import SubBody from '../SubBody';
import SmartMatSetting from './SmartMatSetting';
import CameraSetting from './CameraSetting';
import * as Config from "../../../utils/Config";
import { startDiscovery, scan } from '../../../features/ble/bleSlice';
import {updatePanel} from "../../../features/panels/panelsSlice";
import TButton, {TYPES, SIZES, ICONS} from '../../../components/TButton/TButton';

const SubConnection = ({selectedTab, ...rest}) => {

  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { connectStatus } = useSelector(state => state.ble.gattConnectingStatus);
  const { updatingCameraList } = useSelector(state => state.camera);

  const matRenderer = useCallback(() => (
    <TButton
      style={{transform: "scale(1.0)"}}
      disabled={connectStatus === 'searching'}
      size={SIZES.small}
      type={TYPES.withIcon}
      icon={connectStatus === 'searching' ? ICONS.loading : ICONS.refresh}
      onClick={searchBleDevices}
    >
        {connectStatus === "searching"
          ? $L("Searching...")
          : $L("Search a device")}
    </TButton>
  ), [connectStatus]);

  const cameraRenderer = useCallback(()=>{
    if(updatingCameraList){
      return <TButton spotlightDisabled disabled size={SIZES.small} type={TYPES.withIcon} icon={ICONS.loading}>{$L("Searching...")}</TButton>
    }else{
      return null;
    }
  },[updatingCameraList]);

  const searchBleDevices = useCallback(() => {
    // dispatch(startDiscovery());
    dispatch(scan());
  }, [dispatch]);

  useEffect(() => {
    setSelectedTabIndex(selectedTab)
  }, [selectedTab])

  const onTabChanged = useCallback(({index})=>{
    setSelectedTabIndex(index);
    dispatch(updatePanel({name: Config.panel_names.SETTING_PANEL, panelInfo: {selectedTab: index}}));
  },[]);

  const tabList = [
    {
      title: $L("Smart Mat"),
      children: <SmartMatSetting />,
      itemRenderer: matRenderer,
    },
    {
      title: $L("Camera"),
      children: <CameraSetting />,
      itemRenderer: cameraRenderer,
    },
  ];

  return (
    <SubBody
      simpleButtonList={tabList.map(tab => tab.title)}
      title={tabList[selectedTabIndex].title}
      itemRenderer={tabList[selectedTabIndex].itemRenderer}
      spotlightId={SpotlightIds.SETTINGS_SUB_CONNECTION}
      selectedTab={selectedTabIndex}
      onTabChanged={onTabChanged}
      {...rest}
    >
      {tabList[selectedTabIndex].children || null}
    </SubBody>
  );
};

export default SubConnection;
