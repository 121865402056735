import { addTotalLog } from "../features/logData/logDataSlice";
import store from "../store/store";

const timeNow = () => {
  const now = new Date();
  return new Date(now.getTime());
};

let startTime;
let isLaunched = false;
let launchEventIntervalId;
const tempLoggerArr = [];

const resetTempLoggerArr = () => {
  tempLoggerArr.length = 0;
};

export const enumEventType = {
  ON_CLICK: "onClick",
  POPSTATE: "popstate",
  LAUNCH: "launch",
  FINISH: "finish",
};

export const logger = (eventType, info, immediately) => {
  // log가 찍힌 시간 참조
  const currentTime = timeNow();
  if (!startTime) {
    startTime = currentTime;
  }
  const stayTime = (currentTime - startTime) / 1000 + "s";

  const intervalTime = 5000;

  let msg;
  switch (eventType) {
    case enumEventType.ON_CLICK:
      msg = `onClick event 발생`;
      break;
    case enumEventType.POPSTATE:
      msg = `popstate event 발생`;
      break;
    case enumEventType.LAUNCH:
      if (!isLaunched) {
        msg = `Application 시작`;
        store.dispatch(
          addTotalLog(`${intervalTime / 1000}s 마다 callApi를 호출합니다.`)
        );
        startTime = currentTime; // 명시적으로 한 번 더 주입
      } else {
        return;
      }
      break;
    case enumEventType.FINISH:
      if (isLaunched) {
        msg = `Application 종료`;
      } else {
        return;
      }
      break;
    default:
      break;
  }

  const convertInfo =
    info !== null
      ? JSON.stringify(info)
      : "info 인자가 입력되지 않아 빈 값입니다.";

  const currentLog = {
    eventType: eventType,
    msg: msg,
    info: convertInfo,
    currentTime: currentTime.toString(),
    stayTime: stayTime.toString(),
  };

  console.log("currentLog: ", currentLog);
  tempLoggerArr.push(currentLog);
  store.dispatch(addTotalLog(currentLog));

  console.log(
    "일정 시간마다 callApi가 전송할 배열인 tempLoggerArr: ",
    tempLoggerArr
  );

  if (immediately) {
    // LAUNCH 이벤트가 발생하면 intervalTime 간격으로 쌓인 로그 확인 및 리셋
    if (eventType === enumEventType.LAUNCH) {
      if (!isLaunched) {
        isLaunched = true;
        launchEventIntervalId = setInterval(() => {
          callApi();
        }, intervalTime); // intervalTime 마다 반복하게 함
      }
    }

    // FINISH 이벤트가 발생하면 callApi()를 호출하고 LAUNCH 이벤트를 즉시 중단
    if (eventType === enumEventType.FINISH) {
      isLaunched = false;
      clearInterval(launchEventIntervalId);
      callApi();
    }
  }
};

const callApi = () => {
  if (
    tempLoggerArr !== null &&
    tempLoggerArr !== undefined &&
    Array.isArray(tempLoggerArr) &&
    tempLoggerArr.length > 0 &&
    typeof window === 'object'
  ) {
    try {
      window.localStorage.setItem("logger", JSON.stringify(tempLoggerArr)); // 임시로 localStorage에 저장. 향후 api 호출로 수정 필요
      console.log(
        "저장한 스택",
        JSON.parse(window.localStorage.getItem("logger")) // localStorage에 잘 저장되었는지 확인, 향후 api 호출 이후 response 값 확인 필요
      );
      resetTempLoggerArr(); // tempLoggerArr 초기화
      console.log("tempLoggerArr: ", tempLoggerArr);

      const currentLog = "정상적으로 callApi가 호출되었습니다.";
      store.dispatch(addTotalLog(currentLog));
    } catch (e) {
      console.log("callApi Error: ", e);
    }
  } else {
    const currentLog =
      "'tempLoggerArr'가 비어 있어 아무 값도 보내지 못했습니다.";
    store.dispatch(addTotalLog(currentLog));
  }
};
