import classNames from 'classnames';
import React from "react";
import css from './List.module.less';

const List = ({children, className, ...rest}) => {
	return (
		<div className={classNames(css.list, className)} {...rest}>
      {children}
		</div>
	);
};

export default List;