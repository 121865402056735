import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import Spotlight from '@enact/spotlight';
import css from './SettingsMain.module.less';
import {updatePanel} from '../../../features/panels/panelsSlice';
import * as Config from "../../../utils/Config";
import THeader from "../../../components/THeader/THeader";
import {$L} from '../../../utils/helperMethods';
import TPanel from '../../../components/TPanel/TPanel';
import TBody from '../../../components/TBody';
import TopList from './TopList';
import SubConnection from "../SubConnection/SubConnection";
import SubAudio from "../SubAudio/SubAudio";
import SubScreen from "../SubScreen/SubScreen"
import SubEtc from "../SubEtc/SubEtc.jsx"
import SubNotice from "../SubNotice/SubNotice";
import SubHelp from '../SubHelp/SubHelp';
import SubTerms from '../SubTerms/SubTerms';
import SubLicense from '../SubLicense/SubLicense';
import { changeThemeModeStatus } from "../../../features/common/commonSlice";

const SettingsMain = ({panelInfo, ...rest}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  useEffect(()=>{
    const _list = [
        {title: $L("Connection Settings"), body: SubConnection},
        {title: $L("Audio Settings"), body: SubAudio},
        {title: $L("Screen Settings"), body: SubScreen},
      { title: $L("Smart Mat"), body: SubEtc },
        {title: $L("Notice"), body: SubNotice},
        {title: $L("Help"), body: SubHelp},
        {title: $L("Terms and Conditions of Use"), body: SubTerms},
        {title: $L("Version Information")},
        {title: $L("Open Source License"), body: SubLicense},
    ];
    setList(_list);
    if(panelInfo && panelInfo.lastFocusedId){
      setTimeout(() => {
        Spotlight.focus(panelInfo.lastFocusedId);
      }, 100);
    }
  },[]);
  useEffect(() => {
    if(panelInfo){
      setSelectedIndex(panelInfo?.selectedIndex ? panelInfo.selectedIndex: 0);
    }
  }, [panelInfo])

  const onItemClick = useCallback(({index, itemInfo, ev})=>{
    dispatch(updatePanel({name: Config.panel_names.SETTING_PANEL, panelInfo: {selectedIndex: index, selectedTab: 0}}));
  },[dispatch]);

  const subMenu = useMemo(()=>{
    const Comp = list?.[selectedIndex]?.body;
    const selectedTab = panelInfo?.selectedTab;
    if(Comp){
      return <Comp className={css.menu} selectedTab={selectedTab ? selectedTab: 0}/>;
    }
    return null;
  },[list, selectedIndex, panelInfo]);

  const disabledItem = useMemo(() => {
    return [$L("Audio Settings"), $L("Help"), $L("Version Information"), $L("Open Source License")]
  },[]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Settings")} />
      <TBody className={css.tBody}>
        <TopList
          className={css.topList}
          contents={list}
          onItemClick={onItemClick}
          selectedIndex={selectedIndex}
          disabledItem ={disabledItem}
        />
        {subMenu}
      </TBody>
    </TPanel>
  );
};

export default SettingsMain;
