import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TSimpleButtonTab, {
  LIST_TYPE,
} from "../../components/TSimpleButtonTab/TSimpleButtonTab";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader/THeader";
import TBody from "../../components/TBody/TBody";
import { $L } from "../../utils/helperMethods";
import css from "./FitnessManager.module.less";
import FitnessRecord from "./FitnessRecord/FitnessRecord";
import FitnessBriefing from "./FitnessBriefing/FitnessBriefing";
import FitnessGoalSetting from "./FitnessGoalSetting/FitnessGoalSetting";
import FitnessPainHistory from "./FitnessPainHistory/FitnessPainHistory";
import FitnessReport from "./FitnessReport/FitnessReport";
import { updatePanel } from "../../features/panels/panelsSlice";
import * as Config from "../../utils/Config";
import { changeThemeModeStatus } from "../../features/common/commonSlice";

const getSimpleButtonList = () => ([
  $L("Goal Setting"),
  $L("Workout History"),
  $L("Workout Briefing"),
  $L("Pain/ Heart Rate Monitoring"),
  $L("Monthly Report")
]);

let simpleButtonList = null;
const FitnessManager = ({ panelInfo, ...rest }) => {
  if (!simpleButtonList) {
    simpleButtonList = getSimpleButtonList();
  }
  const dispatch = useDispatch();
  // todo : change 1 to 0 --> ces mode
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    panelInfo?.selectedTabIndex ? panelInfo.selectedTabIndex : 1
  );
  const { cesShowMode } = useSelector((state) => state.common.localSettings);


  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  const onItemClick = useCallback(
    ({ index }) => {
      setSelectedTabIndex(index);
      dispatch(
        updatePanel({
          name: Config.panel_names.FITNESS_MANAGER,
          panelInfo: { selectedTabIndex: index },
        })
      );
    },
    [dispatch]
  );

  const disabledItem = useMemo(() => {
    if(cesShowMode) {
      return simpleButtonList && simpleButtonList.filter((_,idx) => idx === 0 || idx === 3);
    }
    return [];
  }, [cesShowMode, simpleButtonList]);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("AI FiT Manager")}/>
      <TBody className={css.body}>
        {simpleButtonList && simpleButtonList.length > 0 && (
          <TSimpleButtonTab
            className={css.tab}
            listType={LIST_TYPE.header}
            contents={simpleButtonList}
            selectedIndex={selectedTabIndex}
            onItemClick={onItemClick}
            disabledItem={disabledItem}
          />
        )}
        {selectedTabIndex === 0 && <FitnessGoalSetting />}
        {selectedTabIndex === 1 && <FitnessRecord />}
        {selectedTabIndex === 2 && <FitnessBriefing />}
        {selectedTabIndex === 3 && <FitnessPainHistory />}
        {selectedTabIndex === 4 && <FitnessReport />}
      </TBody>
    </TPanel>
  );
};

export default FitnessManager;
