/**
 * TDropdown
 *
 * @module TDropdown
 */
import classNames from 'classnames';
import css from './TDropDown.module.less';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";

import * as Config from "../../utils/Config";

import Dropdown from '@enact/sandstone/Dropdown';
import { Job } from "@enact/core/util";

const SIZES = { large: "large" }
const COLOR = { transparent: "transparent" }

const TDropdown = ({className=null, children, width=400, direction="below", size, color, ...rest}) => {
  const { themeMode } = useSelector((state) => state.common.appStatus);
	// const [pressed, setPressed] = useState(false);

	// const clearPressedJob = useRef(
  //   new Job((func) => {
  //     setPressed(false);
  //     setTimeout(func, Config.TBUTTON_PRESS_DELAY);
  //   }, Config.TBUTTON_PRESS_DELAY)
  // );

	// const _onClick = useCallback((ev) => {
  //   setPressed(true);
  //   clearPressedJob.current.start(() => {});
  // }, []);


	useEffect(() => {
		const floatLayer = document.getElementById('floatLayer');
		if (floatLayer) {
      if (themeMode === "dark") {
				floatLayer.style.setProperty('--list-background-color', '#363533');
				floatLayer.style.setProperty('--list-item-font-color', '#DBD7D4');
				floatLayer.style.setProperty('--list-item-focus-background-color', '#FEFEFE');
				floatLayer.style.setProperty('--list-item-focus-font-color', '#212120');
				floatLayer.style.setProperty('--list-item-select-border-color', '#FEFEFE');
				floatLayer.style.setProperty('--scroll-track-color', 'rgba(254, 254, 254, 0.05)');
				floatLayer.style.setProperty('--scroll-bar-color', 'rgba(254, 254, 254, 0.3)');
			} else {
				floatLayer.style.setProperty('--list-background-color', '#FEFEFE');
				floatLayer.style.setProperty('--list-item-font-color', '#212120');
				floatLayer.style.setProperty('--list-item-focus-background-color', '#63544B');
				floatLayer.style.setProperty('--list-item-focus-font-color', '#FEFEFE');
				floatLayer.style.setProperty('--list-item-select-border-color', '#4F423C');
				floatLayer.style.setProperty('--scroll-track-color', '#DBD7D4');
				floatLayer.style.setProperty('--scroll-bar-color', '#B2B0AE');
			}
		}
  }, [themeMode]);

	return (
		<Dropdown
			className={classNames(
			css.tDrapdown,
			css[size],
			css[color],
			// pressed && css.pressed,
        themeMode === "dark" && css.isDark,
			className,
		)}
			direction={direction}
			width={width}
			// onClick={_onClick}
			{...rest}
		>
			{children}
		</Dropdown>
	);
};
export default TDropdown;
export { SIZES, COLOR };