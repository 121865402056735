import { $L } from "../../../../utils/helperMethods";
import idealHeadShift from "../../../../../assets/bodyScan/idealimage/idealHeadShift.png";
import idealLegAlignment from "../../../../../assets/bodyScan/idealimage/idealLegAlignment.png";
import idealPelvicTilt from "../../../../../assets/bodyScan/idealimage/idealPelvicTilt.png";
import idealShoulderTilt from "../../../../../assets/bodyScan/idealimage/idealShoulderTilt.png";
import idealPelvicRotation from "../../../../../assets/bodyScan/idealimage/idealPelvicRotation.png";
import idealNeckTilt from "../../../../../assets/bodyScan/idealimage/idealNeckTilt.png";
import idealTrunkShift from "../../../../../assets/bodyScan/idealimage/idealTrunkShift.png";
import idealPelvicShift from "../../../../../assets/bodyScan/idealimage/idealPelvicShift.png";
import idealKneeFlexion from "../../../../../assets/bodyScan/idealimage/idealKneeFlexion.png";

export const MENUTYPE = {
  OVERALL: "OVERALL",
  HEAD_SHIFT: "HEAD_SHIFT",
  NECK_TILT: "NECK_TILT",
  SHOULDER_TILT: "SHOULDER_TILT",
  TRUNK_SHIFT: "TRUNK_SHIFT",
  PELVIC_TILT: "PELVIC_TILT",
  PELVIC_SHIFT: "PELVIC_SHIFT",
  PELVIC_ROTATION: "PELVIC_ROTATION",
  LEG_ALIGNMENT: "LEG_ALIGNMENT",
  KNEE_FLEXION: "KNEE_FLEXION",
};
export const MENUTYPE_TEST = {
  BOW_LEG: "BOW_LEG",
  KNOCK_KNEE: "KNOCK_KNEE",
  SHOULDER_SHIFT: "SHOULDER_SHIFT",
  ANTERIOR: "ANTERIOR",
  POSTERIOR: "POSTERIOR",
};
export const getTabMenuTitle = () => ({
  [MENUTYPE.OVERALL]: [$L("Overall"), ""], //Overall
  [MENUTYPE.HEAD_SHIFT]: [
    $L("Head Shift"),
    $L("Head Shift") + " " + $L("View Details"),
  ],
  [MENUTYPE.LEG_ALIGNMENT]: [
    $L("Leg Alignment"),
    $L("Leg Alignment") + " " + $L("View Details"),
  ],
  [MENUTYPE.PELVIC_TILT]: [
    $L("Pelvic Tilt"),
    $L("Pelvic Tilt") + " " + $L("View Details"),
  ],
  [MENUTYPE.SHOULDER_TILT]: [
    $L("Shoulder Tilt"),
    $L("Shoulder Tilt") + " " + $L("View Details"),
  ],
  [MENUTYPE.PELVIC_ROTATION]: [
    $L("Pelvic Rotation"),
    $L("Pelvic Rotation") + " " + $L("View Details"),
  ],
  [MENUTYPE.NECK_TILT]: [
    $L("Neck Tilt"),
    $L("Neck Tilt") + " " + $L("View Details"),
  ],
  [MENUTYPE.TRUNK_SHIFT]: [
    $L("Shoulder Shift"),
    $L("Shoulder Shift") + " " + $L("View Details"),
  ],
  [MENUTYPE.PELVIC_SHIFT]: [
    $L("Pelvic Shift"),
    $L("Pelvic Shift") + " " + $L("View Details"),
  ],
  [MENUTYPE.KNEE_FLEXION]: [
    $L("Knee Flexion/Extension"),
    $L("Knee Flexion/Extension") + " " + $L("View Details"),
  ],
});

export const TAB_DETAIL = {
  [MENUTYPE.HEAD_SHIFT]: {
    mainkey: "side",
    testkey: "head_shift",
    type: "upperbody",
    bodyPart: "head",
  },
  [MENUTYPE.LEG_ALIGNMENT]: {
    mainkey: "front",
    testkey: "leg_alignment_left",
    testkey2: "leg_alignment_right",
    type: "lowerbody",
    bodyPart: "leg",
  },
  [MENUTYPE.PELVIC_TILT]: {
    mainkey: "front",
    testkey: "pelvic_tilt",
    type: "body",
    bodyPart: "pelvic",
  },
  [MENUTYPE.SHOULDER_TILT]: {
    mainkey: "front",
    testkey: "shoulder_tilt",
    type: "upperbody",
    bodyPart: "shoulder",
  },
  [MENUTYPE.PELVIC_ROTATION]: {
    mainkey: "front",
    testkey: "pelvic_rotation",
    type: "body",
    bodyPart: "pelvic",
  },
  [MENUTYPE.NECK_TILT]: {
    mainkey: "front",
    testkey: "neck_tilt",
    type: "upperbody",
    bodyPart: "neck",
  },
  [MENUTYPE.TRUNK_SHIFT]: {
    mainkey: "side",
    testkey: "trunk_shift",
    type: "trunkbody",
    bodyPart: "shoulder",
  },
  [MENUTYPE.PELVIC_SHIFT]: {
    mainkey: "side",
    testkey: "pelvic_shift",
    type: "body",
    bodyPart: "pelvic",
  },
  [MENUTYPE.KNEE_FLEXION]: {
    mainkey: "side",
    testkey: "knee_flexion",
    type: "lowerbody",
    bodyPart: "leg",
  },
};

export const IDEAL_IMAGES = {
  [MENUTYPE.HEAD_SHIFT]: idealHeadShift,
  [MENUTYPE.LEG_ALIGNMENT]: idealLegAlignment,
  [MENUTYPE.PELVIC_TILT]: idealPelvicTilt,
  [MENUTYPE.SHOULDER_TILT]: idealShoulderTilt,
  [MENUTYPE.PELVIC_ROTATION]: idealPelvicRotation,
  [MENUTYPE.NECK_TILT]: idealNeckTilt,
  [MENUTYPE.TRUNK_SHIFT]: idealTrunkShift,
  [MENUTYPE.PELVIC_SHIFT]: idealPelvicShift,
  [MENUTYPE.KNEE_FLEXION]: idealKneeFlexion,
};

export const getEmptyResultsReportData = (menuType) => {
  const data = {
    [MENUTYPE.OVERALL]: {
      title: $L(
        "체형 진단은 당신의 체형을 더 젊게 하고 최적의 건강 상태로 유지할 수 있도록 하여 몸과 마음이 활기찬 노후생활을 보낼 수 있게 해요."
      ),
      detail: $L(
        "우리 몸은 특정 자세나 운동을 할 때 올바른 자세와 체형을 유지하는 것이 중요해요. 이는 부상 예방과 운동 효과를 극대화하기 위해 필수적이에요. {br}올바른 자세와 체형을 유지하는 것은 운동을 효과적으로 수행하고 부상을 예방하는 데 도움이 되요. 이는 운동을 하는 것뿐만 아니라 일상적인 활동에서도 중요해요."
      ),
    },
  };
  return data[menuType] ? data[menuType] : {};
};

export const getBarItem = () => {
  return {
    [MENUTYPE.NECK_TILT]: {
      partName: $L("Neck"),
      positionType: "LR",
      titleDisabled: false,
      sortIndex: 0,
    },
    [MENUTYPE.HEAD_SHIFT]: {
      partName: $L("Neck"),
      positionType: "FB",
      titleDisabled: true,
      sortIndex: 1,
    },
    [MENUTYPE.SHOULDER_TILT]: {
      partName: $L("Shoulder"),
      positionType: "LRH",
      titleDisabled: false,
      sortIndex: 2,
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      partName: $L("Shoulder"),
      positionType: "FB",
      titleDisabled: true,
      sortIndex: 3,
    },
    [MENUTYPE.PELVIC_TILT]: {
      partName: $L("Pelvis"),
      positionType: "LRH",
      titleDisabled: false,
      sortIndex: 4,
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      partName: $L("Pelvis"),
      positionType: "FB",
      titleDisabled: true,
      sortIndex: 5,
    },
    [MENUTYPE.LEG_ALIGNMENT]: {
      partName: $L("Leg"),
      positionType: "LEG",
      titleDisabled: false,
      sortIndex: 6,
    },
    [MENUTYPE.KNEE_FLEXION]: {
      partName: $L("Knee"),
      positionType: "FB",
      titleDisabled: false,
      sortIndex: 7,
    },
  };
};

export const getReportFeedback = () => {
  return {
    [MENUTYPE.NECK_TILT]: {
      LIFESTYLE: [
        $L("Maintain the proper neck alignment, and sit on a chair in a proper posture."),
        $L(
          "Perform stretching every 30 minutes to prevent excessive tension on the neck."
        ),
        $L(
          "Avoid the habit of resting the chin to one side."
        ),
      ],
      RECOMMENDED_EXERCISE: {
        EXERCISE_NAME: $L("Stretching of the sternocleidomastoid muscle"),
        EXERCISE_EFFECT: $L(
          "Proper neck alignment is maintained through stretching of the sternocleidomastoid muscle"
        ),
        CAUTIONS: [
          $L(
            "Caution is required on preventing the shoulder opposite from the stretching shoulder from going up."
          ),
          $L(
            "Exercise must be stopped immediately when having pain on the neck while stretching."
          ),
        ],
      },
    },
    [MENUTYPE.SHOULDER_TILT]: {
      LIFESTYLE: [
        $L(
          "Carrying a heavy load on one side can have influence on imbalance of left and right sides of the shoulder."
        ),
        $L(
          "When studying avoid the posture of leaning to one side on the desk."
        ),
        $L(
          "Height of the crutches or stick must be used according to your body shape. "
        ),
      ],
      RECOMMENDED_EXERCISE: {
        EXERCISE_NAME: $L("Stretching of the levator scapulae muscle"),
        EXERCISE_EFFECT: $L(
          "Proper shoulder alignment is maintained through stretching of the levator scapulae muscle"
        ),
        CAUTIONS: [
          $L(
            "Caution is required on preventing the shoulder opposite from the stretching shoulder from going up."
          ),
          $L(
            "Caution is required on not pressing the head excessively downward."
          ),
          $L(
            "Exercise must be stopped immediately when having pain on the neck while stretching."
          ),
        ],
      },
    },
    [MENUTYPE.PELVIC_TILT]: {
      LIFESTYLE: [
        $L(
          "Legs should not be crossed when sitting, and proper sitting posture is required."
        ),
        $L(
          "Please take caution on not concentrating the body weight only on one leg when standing. "
        ),
        $L(
          "When walking, it is recommended to balance the body weight on both sides of the leg."
        ),
      ],
      RECOMMENDED_EXERCISE: {
        EXERCISE_NAME: $L("Lumbar muscle stretching / side stretching"),
        EXERCISE_EFFECT: $L(
          "Pelvic alignment is maintained through Quadratus Lumborum stretching"
        ),
        CAUTIONS: [
          $L(
            "Exercise must be stopped immediately when having pain on the hip joint or knee during stretching."
          ),
          $L(
            "Hold the knees with your hands when it is difficult to hold the pelvis."
          ),
        ],
      },
    },
    [MENUTYPE.PELVIC_ROTATION]: {
      LIFESTYLE: [
        $L("Do not cross your legs, and maintain a proper sitting posture."),
        $L(
          "Please take caution on not concentrating the body weight only on one leg when standing."
        ),
        $L(
          "Leaning to one side on desk or sitting in a tilted posture must be avoided."
        ),
        $L(
          "When walking, it is recommended to balance the body weight on both sides of the leg."
        ),
        $L("Sitting with your legs raised to one side must be avoided."),
        $L(
          "It is recommended to sit on a chair instead of sitting on the floor."
        ),
        $L(
          "Holding a heavy object to rotate the body to one side must be avoided."
        ),
        $L(
          "After repeating the work on rotating the body, please perform stretching to relax the muscles."
        ),
        $L(
          "When there is pain, exercises including the movement of rotating the body to one side (Ex.: Golf, tennis, etc.) must be avoided as possible."
        ),
      ],
      RECOMMENDED_EXERCISE: {
        EXERCISE_NAME: $L("Left and right sit-up / crunch exercise"),
        EXERCISE_EFFECT: $L(
          "Restoration of pelvic alignment through exercise on strengthening the internal and external oblique"
        ),
        CAUTIONS: [
          $L(
            "When having discomfort around the neck when first starting the sit-up movement, use a pillow to start the exercise."
          ),
        ],
      },
    },
    [MENUTYPE.LEG_ALIGNMENT]: {
      [MENUTYPE_TEST.BOW_LEG]: {
        LIFESTYLE: [
          $L(
            "Sitting in a cross-legged posture should be avoided as possible as it can have influence to knee joint."
          ),
          $L(
            "When sitting, try not to sit cross-legged as possible."
          ),
          $L("When leg deformation is continued even after the adolescent period, consultation with the specialist may be required."),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Bridge Exercise"),
          EXERCISE_EFFECT: $L(
            "Stabilization around the pelvic joint through strengthening of Gluteus Maximus"
          ),
          CAUTIONS: [
            $L(
              "Caution is required to prevent the back from stretching excessively."
            ),
            $L("Take caution not to lift your hips higher than necessary."),
          ],
        },
      },
      [MENUTYPE_TEST.KNOCK_KNEE]: {
        LIFESTYLE: [
          $L(
            "When sitting, try not to sit cross-legged as possible, and please sit in a proper posture."
          ),
          $L(
            "When walking, please check whether walking on knock knees."
          ),
          $L(
            "Sitting in W-shaped leg posture should be avoided as possible as it can have influence to the knee joint."
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L(
            "Adductor muscle tightening exercise on the hip joint"
          ),
          EXERCISE_EFFECT: $L(
            "Relaxing the knock knee through the exercise on strenghtening the extended hip adductor group"
          ),
          CAUTIONS: [
            $L(
              "Caution is required on not having excessive tension on the neck and shoulder, etc."
            ),
          ],
        },
      },
    },
    [MENUTYPE.HEAD_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        LIFESTYLE: [
          $L(
            "The computer monitor must be adjusted according to the eye level."
          ),
          $L("Please adjust according to the eye level when looking at a smartphone or a book."),
          $L(
            "Anterior head shift may be caused from playing games for a long time in an inappropriate posture. Maintain the proper posture. "
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Neck stabilization exercise"),
          EXERCISE_EFFECT: $L(
            "Proper head posture is maintained through neck stabilization exercise"
          ),
          CAUTIONS: [
            $L(
              "Caution is required on not raising the shoulder or not letting the shoulder to be curled forward."
            ),
            $L(
              "Caution is required on not having too much force on the shoulder."
            ),
            $L("Caution is required on not bending the neck."),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        LIFESTYLE: [
          $L(
            "There is possibility of occurring with posterior head shift due to inappropriate pillow height. Please check whether the pillow height is appropriate."
          ),
          $L(
            "Posterior head shift may occur when the stand at attention such as a military posture is maintained for long time. The chin must not be pulled excessively."
          ),
          $L(
            "Perform stretching every 30 minutes to prevent excessive tension on the neck."
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Neck stabilization exercise"),
          EXERCISE_EFFECT: $L(
            "Proper head posture is maintained through neck stabilization exercise"
          ),
          CAUTIONS: [
            $L(
              "Caution is required on not raising the shoulder or not letting the shoulder to be curled forward."
            ),
            $L(
              "Caution is required on not having too much force on the shoulder."
            ),
            $L("Caution is required on not bending and pulling on the neck."),
          ],
        },
      },
    },
    [MENUTYPE.TRUNK_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        LIFESTYLE: [
          $L(
            "Using the computer or smartphone for a long time in a slumped posture must be avoided."
          ),
          $L("When working on the computer, adjust the height of the desk and monitor properly."),
          $L(
            "Caution is required when using the pillow that is excessively high."
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Pectoral muscle stretching"),
          EXERCISE_EFFECT: $L(
            "Relaxing the anterior shoulder tilt through pectoral muscle stretching"
          ),
          CAUTIONS: [
            $L("Stretching is performed within the possible range."),
            $L(
              "Exercise must be stopped immediately when having pain on the shoulder joint while stretching."
            ),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        LIFESTYLE: [
          $L(
            "When working on the computer, adjust the height of the desk and monitor properly."
          ),
          $L(
            "When the pillow height is inappropriate, the height must be adjusted. Please check whether the pillow height is appropriate.."
          ),
          $L(
            "Caution must be taken not to tilt the shoulder excessively to the back."
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Push-ups on knees"),
          EXERCISE_EFFECT: $L(
            "Relaxing the posterior shoulder tilt through the exercise on strengthening the pectorialis major"
          ),
          CAUTIONS: [
            $L(
              "Caution is required on not tilting the back excessively to the back while exercising."
            ),
            $L(
              "Exercise is performed within the range of not occurring with shoulder pain."
            ),
          ],
        },
      },
    },
    [MENUTYPE.PELVIC_SHIFT]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        LIFESTYLE: [
          $L(
            "High heels or elevator shoes can cause excessive anterior pelvic shift."
          ),
          $L(
            "Excessive anterior pelvic shift can be caused on the abdominal obesity body type, and so maintaining the proper body weight is recommended."
          ),
          $L(
            "Posture of stretching the back excessively can increase the anterior pelvic shift angle to require caution."
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Iliopsoas stretching"),
          EXERCISE_EFFECT: $L(
            "Relaxing the anterior pelvic tilt through stretching of the shortened Iliopsoas"
          ),
          CAUTIONS: [
            $L(
              "Caution is required on whether occurring with backache while stretching."
            ),
            $L(
              "When stretching, the pelvis shall not be twisted, and proper alignment must be maintained."
            ),
            $L(
              "Caution is required on not tilting the back excessively to the back while stretching."
            ),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        LIFESTYLE: [
          $L(
            "When sitting on a chair or on a sofa, please stretch your back to sit in a proper posture."
          ),
          $L(
            "To prevent posterior pelvic shift, sitting in a slumped posture for long period must be avoided."
          ),
          $L(
            "Instead of maintaining one posture for long time, change the posture regularly to reduce the stress on the joint."
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Superman Exercise"),
          EXERCISE_EFFECT: $L(
            "Relaxing the posterior pelvic tilt through the exercise on strengthening the lumbar extensor"
          ),
          CAUTIONS: [
            $L(
              "Caution is required on not occurring with backache during the exercise."
            ),
            $L(
              "Caution is required as pain can be caused from excessive stretching of the back."
            ),
            $L(
              "The neck must not be stretched or bent excessively, and neutral posture must be maintained to be in a straight line with the spine."
            ),
          ],
        },
      },
    },
    [MENUTYPE.KNEE_FLEXION]: {
      [MENUTYPE_TEST.ANTERIOR]: {
        LIFESTYLE: [
          $L(
            "Hips and back must be in contact with the back of a chair when sitting. "
          ),
          $L(
            "The height of the chair must be adjusted to have the feet in contact with the floor."
          ),
          $L(
            "Please change your posture regularly to reduce the strain on the knee joint."
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("(Lying on the side) Front thigh stretching"),
          EXERCISE_EFFECT: $L("Quadriceps Femoris stretching"),
          CAUTIONS: [
            $L(
              "When there is pain around the knee while stretching, stop the exercise immediately."
            ),
            $L(
              "Caution is required on not having the pelvis tilted to the back while stretching."
            ),
            $L("Back must not be tilted excessively while stretching."),
          ],
        },
      },
      [MENUTYPE_TEST.POSTERIOR]: {
        LIFESTYLE: [
          $L(
            "Shoes with high heels can give stress to the knees, so wearing comfortable shoes is recommended."
          ),
          $L(
            "Movement of having the belly sticking out excessively can cause excessive stretching, so please maintain proper spinal alignment."
          ),
          $L(
            "Leaning on one leg can have influence on the knee joint, so balancing the body weight while standing is recommended. "
          ),
        ],
        RECOMMENDED_EXERCISE: {
          EXERCISE_NAME: $L("Towel Pressing Exercise"),
          EXERCISE_EFFECT: $L(
            "Stabilization around the knee joint through activation of the thigh muscles"
          ),
          CAUTIONS: [
            $L(
              "When there is pain around the knee cap, reduce the strength to perform the exercise again."
            ),
            $L(
              "When the knee joint is not fully extended, raise the height of the tower to perform the exercise."
            ),
          ],
        },
      },
    },
  };
};
