import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Job } from "@enact/core/util";
import css from "./Overall.module.less";
import * as Config from "../../../../../utils/Config";
import * as TTSService from "../../../../../lunaSend/TTSService";
import {
  addPanels,
  popPanel,
} from "../../../../../features/panels/panelsSlice";
import {
  MENUTYPE,
  MENUTYPE_TEST,
  TAB_DETAIL,
  getBarItem,
  getEmptyResultsReportData,
  getOverallFeedback,
} from "../Constants";
import {
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
  SCORE_MINT_KEY,
  BODY_SCAN_SCORE,
} from "../../../../../utils/Constants";
import EmptyResultsReport from "../../../../../components/EmptyResultsReport/EmptyResultsReport";
import Canvas from "../../../../../components/Canvas/Canvas";
import { $L } from "../../../../../utils/helperMethods";
import CommonButtonLayer from "./CommonButtonLayer";
import Spotlight from "@enact/spotlight";
import { changeAppStatus } from "../../../../../features/common/commonSlice";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TButton from "../../../../../components/TButton/TButton";

// dotted-line
import svg_dotted_line_left_0 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_left_0.svg";
import svg_dotted_line_left_1 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_left_1.svg";
import svg_dotted_line_left_2 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_left_2.svg";
import svg_dotted_line_left_3 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_left_3.svg";
import svg_dotted_line_right_0 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_right_0.svg";
import svg_dotted_line_right_1 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_right_1.svg";
import svg_dotted_line_right_2 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_right_2.svg";
import svg_dotted_line_right_3 from "../../../../../../assets/bodyAlignment/dottedLine/bodyalignment_dotted_line_right_3.svg";
import Spottable from "@enact/spotlight/Spottable";
import CameraPopup from "../../../../Camera/CameraPopup";
import BarItem, { TYPES } from "../../../../../components/BarItem/BarItem";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const SpottableComponent = Spottable("div");

const dotLine = {
  LEFT: [
    svg_dotted_line_left_0,
    svg_dotted_line_left_1,
    svg_dotted_line_left_2,
    svg_dotted_line_left_3,
  ],
  RIGHT: [
    svg_dotted_line_right_0,
    svg_dotted_line_right_1,
    svg_dotted_line_right_2,
    svg_dotted_line_right_3,
  ],
};

const PositionGuide = ({ type }) => {
  return (
    <div
      className={classNames(
        css.positionGuide,
        (type === "R" || type === "B") && css.rightAlign
      )}
    >
      {$L(type)}
    </div>
  );
};

const DetailCard = ({
  typeInfo,
  selectedScore,
  index,
  position,
  onClick,
  cesShowMode,
}) => {
  const classIndex = useMemo(() => {
    switch (index) {
      case 0:
        return "classIndex0";
      case 1:
        return "classIndex1";
      case 2:
        return "classIndex2";
      case 3:
        return "classIndex3";
    }
  }, [index]);

  const _onClick = useCallback(() => {
    if(!cesShowMode){
      onClick && onClick();
    }
  }, [onClick, cesShowMode]);

  return (
    <SpottableComponent
      className={classNames(
        css.detailCard,
        ((selectedScore === null && typeInfo.status !== SCORE_MINT_KEY) ||
          selectedScore === typeInfo.status) &&
          !cesShowMode &&
          css.choose
      )}
      disabled={cesShowMode}
      spotlightDisabled={cesShowMode}
      onClick={_onClick}
    >
      <div
        className={classNames(
          css.dottedLine,
          css[position],
          ((selectedScore === null && typeInfo.status !== SCORE_MINT_KEY) ||
            selectedScore === typeInfo.status ||
            cesShowMode) &&
            css.selected,
          css[classIndex]
        )}
        style={{
          backgroundImage: `url(${dotLine[position][index]})`,
        }}
      />
      {cesShowMode ? (
        position === "LEFT" ? (
          <>
            <BarItem info={typeInfo} type={TYPES.OVERALL} />
            <div
              className={classNames(
                css.bodyScoreLayer,
                cesShowMode && css.bigFont,
                css[position]
                // ((selectedScore === null &&
                //   typeInfo.status !== SCORE_MINT_KEY) ||
                //   selectedScore === typeInfo.status) &&
                //   css[typeInfo.status]
              )}
            >
              {typeInfo.partName}
            </div>
          </>
        ) : (
          <>
            <div
              className={classNames(
                css.bodyScoreLayer,
                cesShowMode && css.bigFont,
                css[position]
                // ((selectedScore === null &&
                //   typeInfo.status !== SCORE_MINT_KEY) ||
                //   selectedScore === typeInfo.status) &&
                //   css[typeInfo.status]
              )}
            >
              {typeInfo.partName}
            </div>
            <BarItem info={typeInfo} type={TYPES.OVERALL} />
          </>
        )
      ) : (
        <>
          <div className={css.bodyPartLayer}>
            <div
              className={css.partName}
              dangerouslySetInnerHTML={{
                __html: typeInfo.feedback,
              }}
            ></div>
            {/* {typeInfo.detail && (
            <div className={css.partDetail}>{typeInfo.detail}</div>
          )} */}
          </div>
          <div
            className={classNames(
              css.bodyScoreLayer
              // ((selectedScore === null && typeInfo.status !== SCORE_MINT_KEY) ||
              //   selectedScore === typeInfo.status) &&
              //   css[typeInfo.status]
            )}
          >
            {typeInfo.score}
            <span className={css.rightIcon} />
          </div>
        </>
      )}
    </SpottableComponent>
  );
};

const TEMP_GUIDE_TERM = 6500;
const TEMP_GUIDE_TERM_SHORT = 3000;

const Overall = ({ date = "", scoreArray, feedback, tabInfos, ...rest }) => {
  const dispatch = useDispatch();
  const overallData = getEmptyResultsReportData(MENUTYPE.OVERALL);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const { dateList, testResults, testResultUpdated } = useSelector(
    (state) => state.bodyAlignment
  );
  const { pictureTypeOverAll } = useSelector((state) => state.common.appStatus);
  const [tempGuideIndex, setTempGuideIndex] = useState(-1);
  const changeTempGuideJob = useRef(
    new Job((index) => {
      setTempGuideIndex(index);
    }, TEMP_GUIDE_TERM_SHORT)
  );
  const [selectedScore, setSelectedScore] = useState(null);
  const { cameraList } = useSelector((state) => state.camera);
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);
  const isIllust = useMemo(() => {
    return pictureTypeOverAll !== "photo";
  },[pictureTypeOverAll]);

  useEffect(() => {
    setTempGuideIndex(-1);
    if (testResults?.[date] && isIllust) {
      changeTempGuideJob.current.startAfter(1500, 0);
    }
  }, [date]);
  useEffect(() => {
    if (tempGuideIndex === -1 && testResults?.[date] && isIllust) {
      changeTempGuideJob.current.startAfter(1500, 0);
    }
  }, [testResultUpdated]);

  useEffect(() => {
    if (testResults?.[date] && isIllust) {
      changeTempGuideJob.current.startAfter(
        TEMP_GUIDE_TERM_SHORT,
        tempGuideIndex + 1
      );
    }else{
      changeTempGuideJob.current.stop();
    }
  }, [tempGuideIndex, isIllust]);

  const testkeys = useMemo(() => {
    let res = [];
    if (!testResults?.[date]) {
      return res;
    }
    const stateKeys = [SCORE_RED_KEY, SCORE_YELLOW_KEY, SCORE_MINT_KEY];
    const gIndex = tempGuideIndex % 3;

    if (testResults[date]?.front && testResults[date]?.side) {
      const frontKeys = Object.keys(testResults[date].front);
      const sideKeys = Object.keys(testResults[date].side);
      if(isIllust){
        for (let i = 0; i < frontKeys.length; i++) {
          if (testResults[date].front[frontKeys[i]]?.[3] === stateKeys[gIndex]) {
            res.push(frontKeys[i]);
          }
        }
        for (let i = 0; i < sideKeys.length; i++) {
          if (testResults[date].side[sideKeys[i]]?.[3] === stateKeys[gIndex]) {
            res.push(sideKeys[i]);
          }
        }
      }else{
        res = res.concat(frontKeys).concat(sideKeys);
      }
    }
    return res;
  }, [testResults, date, tempGuideIndex, isIllust]);

  const scoreValues = useMemo(() => {
    let res = {
      [SCORE_MINT_KEY]: 0,
      [SCORE_YELLOW_KEY]: 0,
      [SCORE_RED_KEY]: 0,
    };

    for (let i = 0; i < scoreArray.length; i++) {
      if (scoreArray[i] === BODY_SCAN_SCORE[SCORE_MINT_KEY]) {
        res[SCORE_MINT_KEY] = res[SCORE_MINT_KEY] + 1;
      } else if (scoreArray[i] === BODY_SCAN_SCORE[SCORE_YELLOW_KEY]) {
        res[SCORE_YELLOW_KEY] = res[SCORE_YELLOW_KEY] + 1;
      } else if (scoreArray[i] === BODY_SCAN_SCORE[SCORE_RED_KEY]) {
        res[SCORE_RED_KEY] = res[SCORE_RED_KEY] + 1;
      }
    }
    return res;
  }, [scoreArray]);

  const scoreTitle = useMemo(() => {
    let res = {
      [SCORE_MINT_KEY]: $L("Good"),
      [SCORE_YELLOW_KEY]: $L("Borderline"),
      [SCORE_RED_KEY]: $L("Weak"),
    };
    return res;
  }, []);

  const testStart = useCallback(() => {
    TTSService.stop();
    dispatch(addPanels({ name: Config.panel_names.BODY_ALIGNMENT_SCAN }));
    dispatch(
      changeAppStatus({ showLoadingPanel: { show: true, type: "tips" } })
    );
  }, []);

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  const scoreClick = useCallback((score) => {
    setSelectedScore(score);
  }, []);

  const goToViewDetails = useCallback(
    (item) => {
      dispatch(
        addPanels({
          name: Config.panel_names.BODY_ALIGNMENT_REPORT_DETAIL,
          panelInfo: {
            title: item.detailTitle,
            tabDetail: TAB_DETAIL[item.type],
            menuType: item.type,
            selectedDate: date,
          },
        })
      );
    },
    [dispatch, tabInfos, date]
  );

  const detailCardInfo = useMemo(() => {
    const feedbackArr = getOverallFeedback();

    const filteredTabInfos = tabInfos.filter((tabInfo) => {
      if (
        tabInfo.type !== MENUTYPE.OVERALL &&
        tabInfo.type !== MENUTYPE.PELVIC_ROTATION
      ) {
        return tabInfo;
      }
    });

    const ret = [];

    filteredTabInfos.map((item) => {
      const retEl = {};

      const type = item.type;
      const detailTitle = item.detailTitle;
      const detailKey = item.detailType;

      let detailType;
      if (type === MENUTYPE.LEG_ALIGNMENT) {
        if (detailKey === "out") {
          detailType = MENUTYPE_TEST.BOW_LEG;
        } else {
          detailType = MENUTYPE_TEST.KNOCK_KNEE;
        }
      } else if (
        type === MENUTYPE.PELVIC_SHIFT ||
        type === MENUTYPE.KNEE_FLEXION ||
        type === MENUTYPE.HEAD_SHIFT ||
        type === MENUTYPE.TRUNK_SHIFT
      ) {
        if (detailKey === "front") {
          detailType = MENUTYPE_TEST.ANTERIOR;
        } else {
          detailType = MENUTYPE_TEST.POSTERIOR;
        }
      } else {
        detailType = null;
      }

      let score, status;
      switch (item.score) {
        case 9:
          score = scoreTitle[SCORE_MINT_KEY];
          status = SCORE_MINT_KEY;
          break;
        case 4:
          score = scoreTitle[SCORE_YELLOW_KEY];
          status = SCORE_YELLOW_KEY;
          break;
        default:
          score = scoreTitle[SCORE_RED_KEY];
          status = SCORE_RED_KEY;
          break;
      }

      let feedback;
      if (detailType === null) {
        feedback = feedbackArr[type][status];
      } else {
        feedback = feedbackArr[type][detailType][status];
      }

      if (cesShowMode) {
        const positionType = {
          LR: [$L("Left tilt"), $L("Right tilt")],
          LRH: [$L("Left high"), $L("Right high")],
          FB: [$L("Anterior"), $L("Posterior")],
          LEG: [$L("Bow legs"), $L("Knock knees")],
        };
        const bodyPart = getBarItem();

        // const key = item.detailType; // detailKey
        const cesScore = item.score; // score

        let cesDetailKey;
        if (
          detailKey.includes("front") ||
          detailKey.includes("out") ||
          detailKey.includes("same")
        ) {
          cesDetailKey = "LEFT";
        } else if (detailKey.includes("back") || detailKey.includes("in")) {
          cesDetailKey = "RIGHT";
        } else if (detailKey.includes("right")) {
          if (
            type === MENUTYPE.PELVIC_ROTATION ||
            type === MENUTYPE.NECK_TILT
          ) {
            cesDetailKey = "LEFT";
          } else {
            cesDetailKey = "RIGHT";
          }
        } else if (detailKey.includes("left")) {
          if (
            type === MENUTYPE.PELVIC_ROTATION ||
            type === MENUTYPE.NECK_TILT
          ) {
            cesDetailKey = "RIGHT";
          } else {
            cesDetailKey = "LEFT";
          }
        } else {
          cesDetailKey = null;
        }

        let value = 3;
        if (cesDetailKey === "LEFT") {
          if (cesScore === 4) {
            value -= 1;
          } else if (cesScore === 1) {
            value -= 2;
          }
        } else if (cesDetailKey === "RIGHT") {
          if (cesScore === 4) {
            value += 1;
          } else if (cesScore === 1) {
            value += 2;
          }
        }

        retEl.partName = bodyPart[item.type].partName;
        retEl.replacePartName = bodyPart[item.type].replacePartName;
        retEl.keys = positionType[bodyPart[item.type].positionType];
        retEl.titleDisabled = bodyPart[item.type].titleDisabled;
        retEl.value = value;
      }

      retEl.detailType = detailType;
      retEl.detailKey = detailKey;
      retEl.sortIndex = item.sortIndex;
      retEl.type = type;
      retEl.detailTitle = detailTitle;
      retEl.score = score;
      retEl.status = status;
      retEl.feedback = feedback;

      ret.push(retEl);
    });

    const key = [
      "NECK_TILT",
      "SHOULDER_TILT",
      "PELVIC_TILT",
      "LEG_ALIGNMENT",
      "HEAD_SHIFT",
      "TRUNK_SHIFT",
      "PELVIC_SHIFT",
      "KNEE_FLEXION",
    ];

    const detailCard = [];
    for (let i = 0; i < key.length; i++) {
      for (let j = 0; j < ret.length; j++) {
        if (key[i] === ret[j].type) {
          detailCard.push(ret[j]);
          break;
        }
      }
    }

    return {
      LEFT: detailCard.slice(0, 4),
      RIGHT: detailCard.slice(4),
    };
  }, [scoreTitle, tabInfos]);

  const onClickCommonBtn = useCallback(
    (index) => {
      console.log("onClickCommonBtn", index);
      if (cesShowMode) {
        if (cameraList.length > 0 && index === 1) {
          dispatch(popPanel(Config.panel_names.BODY_ALIGNMENT_REPORT));
          testStart();
        } else {
          dispatch(
            addPanels({
              name: Config.panel_names.BODY_ALIGNMENT_SUMMARY,
              panelInfo: {
                tabInfos: detailCardInfo,
              },
            })
          );
        }
      } else {
        if (cameraList.length > 0 && index === 0) {
          testStart();
        } else {
          setIsConnectionPopupOpen(true);
        }
      }
    },
    [testStart, cesShowMode, detailCardInfo]
  );

  useEffect(() => {
    Spotlight.focus("bodyScanTestBtn");
  }, []);
  // const str = $L(
  //   "측정 부위(9개) 중,</br><b>관리 필요</b> 부위가 가장 많습니다."
  // );

  if (dateList && dateList.length > 0) {
    if (testResults[date]) {
      return (
        <>
          <Container
            className={classNames(
              css.detailContainer,
              cesShowMode && css.cesShowMode
            )}
          >
            <div className={css.totalScore}>
              <div className={css.title}>{$L("Total Score")}</div>
              <div className={css.pointStr}>
                <div className={css.point}>{scoreArray[0]}</div>
              </div>
            </div>
            {!cesShowMode && (
              <div className={css.scoreSum}>
                {[SCORE_RED_KEY, SCORE_YELLOW_KEY, SCORE_MINT_KEY].map(
                  (scoreType) => {
                    return (
                      <TButton
                        className={classNames(
                          css.scoreItem,
                          selectedScore === scoreType && css.selected
                        )}
                        key={scoreType}
                        onClick={() => scoreClick(scoreType)}
                      >
                        <span className={css.title}>
                          {scoreTitle[scoreType]}
                        </span>
                        <span
                          className={classNames(
                            css.value,
                            selectedScore === scoreType && css[scoreType]
                          )}
                        >
                          {scoreValues[scoreType]}
                        </span>
                      </TButton>
                    );
                  }
                )}
              </div>
            )}
          </Container>

          <Container
            className={classNames(
              css.silhouetteContainer,
              cesShowMode && css.cesShowMode
            )}
          >
            <Container className={css.detailCardsContainer}>
              {detailCardInfo.LEFT?.map((item, index) => (
                <DetailCard
                  key={"detailCard_left_" + index}
                  index={index}
                  typeInfo={item}
                  selectedScore={selectedScore}
                  position={"LEFT"}
                  onClick={() => goToViewDetails(item)}
                  cesShowMode={cesShowMode}
                />
              ))}
            </Container>
            <div className={css.profileContainer}>
              {["front", "side"].map((bodyType, index) => {
                return (
                  <div
                    className={css.bodyWrapper}
                    key={"canvasWrapper" + index}
                  >
                    {isIllust && <div className={css.grid} />}
                    <div className={css.canvasWrapper}>
                      <Canvas
                        className={css.canvas}
                        bodyType={bodyType}
                        type="wholebody"
                        testResult={testResults[date]}
                        testkeys={testkeys}
                        adjustScale={0.7}
                        isIllust={isIllust}
                      />
                    </div>
                    <PositionGuide type={bodyType === "front" ? "L" : "F"} />
                    <PositionGuide type={bodyType === "front" ? "R" : "B"} />
                  </div>
                );
              })}
              <div className={css.mirrorMode}>{$L("mirror mode")}</div>
            </div>
            <Container className={css.detailCardsContainer}>
              {detailCardInfo.RIGHT?.map((item, index) => (
                <DetailCard
                  key={"detailCard_right_" + index}
                  index={index}
                  typeInfo={item}
                  selectedScore={selectedScore}
                  position={"RIGHT"}
                  onClick={() => goToViewDetails(item)}
                  cesShowMode={cesShowMode}
                />
              ))}
            </Container>
          </Container>

          <CommonButtonLayer
            buttonTitles={
              cesShowMode
                ? [$L("Summary"), $L("Rescan")]
                : [$L("Body Scan"), $L("Analysis")]
            }
            disabled={[false, !cesShowMode]}
            spotlightId="bodyScanTestBtn"
            onClick={onClickCommonBtn}
          ></CommonButtonLayer>
          {isConnectionPopupOpen && (
            <CameraPopup onClose={onCloseCameraPopup} />
          )}
        </>
      );
    } else {
      return null;
    }
  }
  return (
    <EmptyResultsReport
      bodyScan
      overallResult
      title={overallData.title}
      detail={overallData.detail}
      subheading={overallData.subheading}
      list={overallData.list}
      openMenuTestPopup={testStart}
    />
  );
};

export default Overall;
