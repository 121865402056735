import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from "./SmartMatSetting.module.less";
import { $L, convertNewlinesToBr } from '../../../utils/helperMethods';
import SmoodShowingAnimation from "../../../components/SmoodShowingAnimation";
import { scan, gattConnect, gattDisconnect } from '../../../features/ble/bleSlice';
import TScroller from '../../../components/TScroller/TScroller';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import TSettingSection from '../../../components/TSettingSection/TSettingSection';

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const SmartMatSetting = ({...rest}) => {
  const dispatch = useDispatch();
  const bleList = useSelector(state => state.ble.bleDeviceList);
  const gattConnectingStatus = useSelector(state => state.ble.gattConnectingStatus);
  const connectStatus = gattConnectingStatus.connectStatus;
  const bleAddress = gattConnectingStatus.address;
  const batteryValue = useSelector(state => state.ble.batteryStatus);

  // const [connectedPopupOpen, setConnectedPopupOpen] = useState(false);

  // const itemRenderer = useCallback(()=>{
  //   return (
  //     connectStatus === 'searching' ?
  //       <TButton spotlightDisabled disabled size="small">{$L("Searching...")}</TButton>
  //       :
  //       <TButton spotlightId="searchBtn" size="small" onClick={searchBleDevices}>{$L("Search a device")}</TButton>
  //   )
  // },[connectStatus]);

  const handleConnect = useCallback((address) => () =>{
    dispatch(gattConnect(address));
  },[dispatch]);

  const handleDisconnect  = useCallback(() =>{
    dispatch(gattDisconnect());
  },[dispatch]);

  // const searchBleDevices = useCallback(() => {
  //   dispatch(startDiscovery());
  // }, [dispatch]);

  useEffect(()=>{
    if(connectStatus !== 'connected'){
      // dispatch(startDiscovery()); // searching
      dispatch(scan()); // searching
    }
    // setConnectedPopupOpen(false);
  },[]);

  // const textRenderer = useCallback(()=>{
  //   return (
  //       connectStatus === 'connected' ?
  //         <div>
  //           <div>{$L("Connecting...")}</div>
  //         </div>
  //         :
  //         <TButton spotlightId="searchBtn" size="small" onClick={searchBleDevices}>{$L("기기 검색하기")}</TButton>
  //     )
  // },[]);

    return (
      <Container className={css.container} {...rest}>
        {/* <TSettingSection
          type="underLine"
          className={css.listHeader}
          text={$L("List of Smart Mats connected")}
          itemRenderer={itemRenderer}
        /> */}
        <TScroller className={css.tscroller}>
          {bleList.length === 0 && connectStatus === 'idle' &&
            <div className={css.searchFailed}>
              {convertNewlinesToBr($L("You don't have a Smart Mat yet. {br}LG Intelli-FiT needs the Smart Mat to provide accurate data and health information to you. {br}Tap the [Search a device] to search the Mat."))}
            </div>
          }
          {bleList.length> 0 &&
            bleList.map((list, idx)=>{
            return (
              <TSettingSection

                key={idx}
                className={css.setting}
                text={`${list.name} (${list.address})`}
                subText={list.address === bleAddress && connectStatus === 'connected' ? (
                  <div className={css.subText}>
                    <span>{$L("Connecting...")}</span> <span>{`${$L("Battery")} 80%`}</span>
                  </div>
                  // <div className={css.subText}>
                  //   <span>{$L("Connecting...")}</span> <span>{`${$L("Battery")} ${batteryValue}%`}</span>
                  // </div>
                ) : null}
                button1={{ text: list.address === bleAddress && connectStatus === 'connected'? $L("Disconnect"): $L("Connect") }}
                onClick={list.address === bleAddress && connectStatus === 'connected'? handleDisconnect : handleConnect(list.address)}
              />
            )
          })}
        </TScroller>
        {connectStatus === 'seachingFailed' &&
          <div className={css.searchFailed}>
            {convertNewlinesToBr($L("We can't find the Smart Mat. {br} LG Intelli-FiT needs the Smart Mat to provide accurate data and health information to you. {br} Check if you have a Smart Mat and the connection."))}
          </div>
        }
        {/* {connectedPopupOpen && connectStatus === 'connected' &&
          <ConnectedPopup/>
        } */}
      </Container>
    );
};

export default SmoodShowingAnimation(SmartMatSetting);
