import { useState, useEffect, useCallback } from "react";
import * as Config from "../../utils/Config";
import css from "./IntroPanel.module.less";
import classNames from "classnames";
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader/THeader";
import TBody from "../../components/TBody/TBody";
import TButton from "../../components/TButton/TButton";
import {$L} from '../../utils/helperMethods';
import Spotlight from "@enact/spotlight";
import Spottable from '@enact/spotlight/Spottable';
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import GuideImage from "../../../assets/guideImage.svg";
import { useDispatch } from "react-redux";
import { addPanels } from "../../features/panels/panelsSlice";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div");

const SpottableComponent = Spottable('div');

const IntroPanel = ({...rest}) => {
  const dispatch = useDispatch();
  const [introOrder, setIntroOrder] = useState(0);
  const TUTORIALS = [
    {
      title: $L("You can manage your health."),
      detail: [
        $L("LG Intelli-FiT opens up an opportunity to become healthier."),
        $L(
          "Do a body checkup to find out your current health status."
        ),
        $L(
          "Feel the change in your body by exercising with My Workout Programs and Outdoor Activity Indoors."
        ),
      ],
      image: GuideImage
    },
    {
      detail: [
        $L("With My Workout Program, you can exercise by following along a celebrity trainer."),
        $L("With Outdoor Activity Indoors, you can try out different workouts using the Smart Mat.")
      ],
      image: GuideImage
    },
  ]
  const totalPages = TUTORIALS.length;

  const handleTutorialChange = useCallback(() => {
    // setIntroOrder((prevOrder) => (prevOrder === 0 ? 1 : 0));
    setIntroOrder((prevPage) => (prevPage === 0 ? 1 : 0));
  }, []);

  const goToSurvey = useCallback(()=>{
    dispatch(addPanels({ name: Config.panel_names.SURVEY_PANEL }));
  },[dispatch]);

  useEffect(()=>{
    Spotlight.focus('introNext');
    Spotlight.focus('introStart');
  },[introOrder]);

  return (
    <TPanel {...rest}>
      <THeader/>
      <TBody className={css.tBody}>
        <Container className={css.pagination}>
            {Array.from({ length: totalPages }).map((_, pageIndex) => (
              <SpottableComponent
                key={pageIndex}
                className={classNames(css.paginationButton, introOrder === pageIndex? css.selected : null)}
                onClick={handleTutorialChange}
                tabIndex="0"
              />
            ))}
        </Container>
        <div className={css.title}>{TUTORIALS[0].title}</ div>
        <div className={css.detail}>
          {TUTORIALS[introOrder].detail.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
          {introOrder === 0 ?
            <>
              <img className={css.image} src={TUTORIALS[0].image} alt='Tutorial01'/>
              {/* <TButton className={css.rightBtn} type='rightArrowBtn' spotlightId={'rightArrowBtn'} onClick={handleTutorialChange}/> */}
              <TButton className={css.next} spotlightId="introNext" onClick={handleTutorialChange}>{$L("다음")}</TButton>
            </>
            :
            <>
              {/* <TButton className={css.leftBtn} type='leftArrowBtn' spotlightId={'leftArrowBtn'} onClick={handleTutorialChange}/> */}
              <img className={css.image} src={TUTORIALS[1].image} alt='Tutorial02'/>
              <div className={css.startBox}>
                <TButton className={css.previous} onClick={handleTutorialChange}>{$L("Previous page")}</TButton>
                <TButton className={css.startBtn} spotlightId="introStart" onClick={goToSurvey}>{$L("Getting started")}</TButton>
              </div>
            </>
          }
        </div>
      </TBody>
    </TPanel>
  );
};

export default IntroPanel;
