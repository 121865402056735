import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import css from "./TLineGraph.module.less";
import classNames from "classnames";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import TLineChart from "../TLineChart/TLineChart";
import { $L } from "../../utils/helperMethods";
import ic_tooltip_dark_s from "../../../assets/icon/ic_tooltip_dark_s.svg";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, Spottable("div"));

const TLineGraph = ({
  className,
  label=[],
  data,
  unit = 'point', //$L({"point"}), $L({"time"})
  width = 400,
  height = 250,
  dataPointDiameter = 6,
  maxRange,
  yRange=30,
  xLabel=true,
  yLabel=false
}) => {

  const [coordinates, setCoordinates] = useState([]);
  const graphLine = [1, 2, 3, 4, 5, 6];

  const calcMaxRange = useMemo(() => {
    if (data) {
      const maxData = Math.max(...data);
      return yRange * Math.ceil(maxData / yRange);
    }
  }, [data, yRange]);

  return (
    <div className={classNames(className, css.container)}>
      <div className={classNames(css.graphArea, yLabel && css.withLabel)}>
        {graphLine.map((_, idx) => (
          <div
            key={`linegraph_${idx}`}
            className={classNames(css.graphLine, idx === 0 && css.solid)}
            style={{ bottom: `${Math.ceil(height/5)*idx + 4}px` }}
          />
        ))}
        {graphLine.map((_, idx) => (
          yLabel && idx > 0 &&
          <div
            key={`linegraph_${idx}`}
            className={classNames(css.yLabel)}
            style={{ bottom: `${Math.ceil(height/5)*idx - 15}px` }}
          >
            {unit === 'point' ? `${yRange*idx}${$L("P")}` : `${yRange*idx}${$L("")}`}
          </div>
        ))}
        {data && data.length > 0
        ?
        <div className={css.graphContainer}>
          <div className={css.lineGraphBox}>
            <TLineChart
              className={css.lineGraph}
              onLineChartCoordinates={setCoordinates}
              width={width}
              height={height}
              maxRange={maxRange || calcMaxRange}
              dataPointDiameter={dataPointDiameter}
              monthlyExerciseData={data}
              // baseColor={"rgba(133, 118, 103, 0.42)"}
              baseColor={"#857667"}
            />
            {coordinates.length > 0 &&
              coordinates.map((item, index) => (
                <div
                  className={css.ordinator}
                  key={`coordinates-${index}`}
                  style={{ top: item.y, left: item.x }}
                >
                  <div>
                    <div className={css.textBox}>
                      {data[index] + $L(unit)}
                      <img src={ic_tooltip_dark_s} alt="tooltip_arrow" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        :
        <div className={css.notTest}>
          <div>
            {$L("You don't have a workout plans yet.")}
          </div>
          <div>
            {$L("Get a My Body Analysis and start your personalized workouts recommendations!")}
          </div>
        </div>
        }
      </div>
      {xLabel && <div className={classNames(css.labelArea, yLabel && css.withLabel)}>
        {label.map((item, index) => (
          <div key={`label-${index}`} className={css.label}>
            {item}
          </div>
        ))}
      </div>}
    </div>
  );
};

export default TLineGraph;
