import React, { useEffect, useCallback, useState, useRef } from "react";
import VirtualGridList from "@enact/sandstone/VirtualList";
import classNames from "classnames";
import ri from "@enact/ui/resolution";
import css from "./JMediaList.module.less";
import { useSelector, useDispatch } from "react-redux";
import { $L } from "../../utils/helperMethods";
import JMediaItem from "./JMediaItem";

export const LIST_TYPE = {
  large: "large",
  medium: "medium",
};
const LIST_ITEM_CONF = {
  [LIST_TYPE.large]: {
    ITEM_WIDTH: (427 + 36) * 2, //uhd size
    SPACING: -12 * 2, // test
  },
  [LIST_TYPE.medium]: {
    ITEM_WIDTH: 296 * 2,
    // ITEM_WIDTH: (296 + 20) * 2, //uhd size
    SPACING: 24 * 2, // test
    // SPACING: -12 * 2, // test
  },
};
/*
리스트의 높이는 기본적으로 자동 계산되나, No Media 인 경우 별도 로직 필요
listType 은 JMediaItem 에도 구현해야함.
 */
const JMediaList = ({
  startGap = 0,
  className,
  listType = LIST_TYPE.large,
  contents,
  onItemClick,
  spotlightId,
  checkProp = false,
  playProp = false,
  centerTitle = false,
  mode='', // edit
  ...rest
}) => {
  const gap = startGap >= 18 && startGap - 18;
  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  const renderItem = useCallback(
    ({ index, ...rest }) => {
      return (
        <JMediaItem
          index={index}
          listType={listType}
          width={ri.scale(LIST_ITEM_CONF[listType].ITEM_WIDTH)}
          {...rest}
          title={contents[index].title}
          itemInfo={contents[index]}
          onItemClick={onItemClick}
          lastIndex={(contents.length - 1).toString()}
          spotlightId={`${spotlightId}_${index}`}
          checkProp={checkProp}
          playProp={playProp}
          centerTitle={centerTitle}
          mode={mode}
        />
      );
    },
    [contents, onItemClick, listType, checkProp, playProp]
  );

  const getRef = useCallback((ref) => {
    containerRef.current = ref;
  }, []);

  const onScrollHandler = useCallback((ev) => {
    if(typeof window === "object" && scrollRef.current){
      const scrollRefWidth = Number(
        window.getComputedStyle(scrollRef.current)["width"].replace("px", "")
      );
      const parentWidth = Number(
        window.getComputedStyle(scrollRef.current.parentNode)
          ["width"].replace("px", "")
      );
      const endScrollValue = scrollRefWidth - parentWidth - 1;

      if (scrollRef.current) {
        if (ev.scrollLeft > endScrollValue) {
          scrollRef.current.style.marginLeft = `-${gap}px`;
        } else if (ev.scrollLeft !== 0) {
          scrollRef.current.style.marginLeft = "0";
        } else {
          scrollRef.current.style.marginLeft = `${gap}px`;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (containerRef.current && contents && contents.length > 0 ) {
      scrollRef.current = containerRef.current.firstChild?.firstChild?.firstChild;
      scrollRef.current.style.margin = `0 ${gap}px`;
    }
  }, [contents]);

  return (
    <div className={className} ref={getRef}>
      {contents && contents.length > 0 ? (
        <>
          <VirtualGridList
            className={css.jMediaList}
            dataSize={contents.length}
            itemSize={ri.scale(LIST_ITEM_CONF[listType].ITEM_WIDTH)}
            direction="horizontal"
            itemRenderer={renderItem}
            spacing={ri.scale(LIST_ITEM_CONF[listType].SPACING)}
            noAffordance={true}
            horizontalScrollbar="hidden"
            noScrollByWheel
            // hoverToScroll
            onScroll={onScrollHandler}
            // snapToCenter={true}
          ></VirtualGridList>
        </>
      ) : (
        <div>{$L("No Media")}</div>
      )}
    </div>
  );
};

export default JMediaList;
