import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./PainModal.module.less";
import Spotlight from "@enact/spotlight";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import classNames from "classnames";
import IMG_PAIN from "../../../../../assets/images/workout/img_pain.png";
import IC_ALERT_CIRCLE from "../../../../../assets/icon/alert_circle.png";
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import { $L } from "../../../../utils/helperMethods";
import TSlider from "../../../../components/TSlider";
import THeader from "../../../../components/THeader/THeader";
import { changeThemeModeStatus } from "../../../../features/common/commonSlice";
import Alert from "@enact/sandstone/Alert";

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const painList = ["없음", "목", "어깨", "팔꿈치", "손목", "등", "허리", "종아리", "골반", "엉덩이", "발목", "발바닥"]

const PainModal = ({ onNext }) => {
  const dispatch = useDispatch();
  const { profileNick } = useSelector((state) => state.common.appStatus);
  const [answer, setAnswer] = useState(-1);
  const [value, setValue] = useState(null)

  useEffect(() => {
    Spotlight.focus("pain_0");
    dispatch(changeThemeModeStatus("dark"));
    return(() => {
      dispatch(changeThemeModeStatus("light"));
    });
  }, []);

  const onClickPain = useCallback((index) => () => {
    setAnswer(index);
  }, []);

  const _onNext = useCallback((id) => () => {
    if (onNext) onNext(id);
  }, [onNext]);

  const onChange = useCallback((value) => {
    console.log('onChange', value)
    setValue(value);
}, []);


  return (
  <Alert
    open
    type={"fullscreen"}
    className={css.popup}
  >
    <Container className={classNames(css.painContainer)}>
      <div className={css.cl_modalDimmer}/>
      <THeader iconType="back" useTool={false} style={{ zIndex: "2" }} />
      <div className={css.container}>
        <div className={css.titleContainer}>
          <div className={css.title}>{profileNick+$L("님, 수고하셨어요!")}</div>
          <div className={css.desc}>{$L("아래의 통증 체크를 해주시면 {NICKNAME}님에게 최적화된 맞춤 솔루션을 제공해 드려요.").replace("{NICKNAME}", profileNick)}</div>
        </div>
        <Container className={css.contentsContainer}>
          <img src={IMG_PAIN}/>
          <div className={css.painLayer}>
            <div className={css.paintitle}>{$L("Tick the box if you have pain in the area.")}</div>
            <div className={css.painDesc}>
              <img src={IC_ALERT_CIRCLE}/>
              {$L("You can only select 1 area for pain.")}
            </div>
            <div className={css.painList}>
              {painList.map((item, index) => (
                <TButton
                  key={"pain" + index}
                  spotlightId={'pain_'+index}
                  selected={answer === index}
                  onClick={onClickPain(index)}
                >
                  {$L(item)}
                </TButton>
              ))}
            </div>
            <div className={css.hr} />
            <div className={css.painLevel}>
              <div className={css.levelTitle}>{$L("Select the level of your pain between 0 through 10.")}</div>
              <TSlider
                value={value}
                onChange={onChange}
              />
            </div>
          </div>
        </Container>
        <div className={css.buttonContainer}>
          <TButton size={SIZES.large} onClick={_onNext('skip')}>{$L("Skip")}</TButton>
          <TButton size={SIZES.large} onClick={_onNext('ok')}>{$L("Verified")}</TButton>
        </div>
      </div>
    </Container>
  </Alert>
  );
};

export default PainModal;
