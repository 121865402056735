import css from "./InfoSection.module.less";
import classNames from "classnames";
import { useCallback, useState, useRef } from "react";

import * as Config from "../../../utils/Config";
import * as SoundEffect from "../../../utils/SoundEffect";

import { Job } from "@enact/core/util";
import Spottable from "@enact/spotlight/Spottable";

const SpottableComponent = Spottable("div");

const InfoSection = ({className, type, onClick, title, disabled, ...rest }) => {
  const [isPressed, setIsPressed] = useState(false);

  const clearPressedJob = useRef(
    new Job((func) => {
      setIsPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback((ev) => {
    if (disabled) {
      ev?.stopPropagation();
      return;
    }
    SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
    setIsPressed(true);
    clearPressedJob.current.start(() => {
      if (onClick) {
        onClick(ev);
      }
    });
  }, [onClick]);

  const _onBlur = useCallback((ev) => {
    setIsPressed(false);
    clearPressedJob.current.stop();
  }, []);

  const _onFocus = useCallback(() => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
  }, []);


  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        className,
        css[type],
        css.infoSection,
        isPressed && css.pressed,
      )}
      onClick={_onClick}
      onFocus={_onFocus}
      onBlur={_onBlur}
    >
      <div className={css.text}>{title}</div>
      <div className={css.date}></div>
    </SpottableComponent>
  );
};

export default InfoSection;