import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spotlight from "@enact/spotlight";
import css from "./BodyAlignmentSummaryReport.module.less";
import * as Config from "../../../../utils/Config";
import { $L } from '../../../../utils/helperMethods';
import { addPanels, popPanel } from "../../../../features/panels/panelsSlice";
import THeader from "../../../../components/THeader/THeader";
import TPanel from "../../../../components/TPanel/TPanel";
import TBody from "../../../../components/TBody/TBody";
import CesOverallSummary from "../../../../components/CesOverallSummary/CesOverallSummary";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const BodyAlignmentSummaryReport = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const gattConnectingStatus = useSelector(
    (state) => state.ble.gattConnectingStatus
  );
  const connectStatus = gattConnectingStatus.connectStatus;
  const connectStatusRef = useRef(connectStatus);
  const [showSmartMatPopup, setShowSmartMatPopup] = useState(false);

  useEffect(() => {
    connectStatusRef.current = connectStatus;
  }, [connectStatus]);

  useEffect(() => {
    Spotlight.focus("testBtn");
  }, []);

  return (
    <TPanel className={css.panel} {...rest}>
      <THeader title={$L("Summary")} iconType="back" />
      <TBody className={css.tBody}>
        <Container className={css.summaryReportSect}>
          <CesOverallSummary panelInfo={panelInfo} enterFrom={'bodyAlignment_summary'} />
        </Container>
        <div className={css.notification}>
          <div className={css.guideText}>
            {$L(
              "LG Intelli-FiT recommendations and feedback should not be considered, or used as a substitute for professional medical advice or treatment."
            )}
          </div>
        </div>
      </TBody>
    </TPanel>
  );
};

export default BodyAlignmentSummaryReport;
