import classNames from 'classnames';
import React from "react";
import css from './ListItem.module.less';

const ListItem = ({children, className, ...rest}) => {
	return (
		<div className={classNames(css.listItem, className)} {...rest}>
      <div className={css.dot}/>
      <div className={css.text}>{children}</div>
		</div>
	);
};

export default ListItem;