import classNames from "classnames";
import css from "./SMediaList.module.less";
import React, { useEffect, useCallback, useState, useRef } from "react";

import {$L} from '../../utils/helperMethods';
import * as HelperMethods from "../../utils/helperMethods";

import SMediaItem from "./SMediaItem";

import ri from '@enact/ui/resolution';
import Scroller from '@enact/sandstone/Scroller/Scroller';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";


const Container = SpotlightContainerDecorator({ enterTo: "last-focused"}, "div" );

export const TYPES = {
  normal: "normal",
  withCheck: "withCheck",
  withCheckIndex: "withCheckIndex",
};

export const ITEM_SIZE = {
  large: "large",
  medium: "medium",
  small: "small"
};

const LIST_ITEM_CONF = {
  [ITEM_SIZE.large]: {
    ITEM_WIDTH: 427,
    SPACING: 24
  },
  [ITEM_SIZE.medium]: {
    ITEM_WIDTH: 320,
    SPACING: 20,
  },
  [ITEM_SIZE.small]: {
    ITEM_WIDTH: 296,
    SPACING: 20
  }
};

const SMediaList = ({
  className,
  type=TYPES.normal,
  itemSize=ITEM_SIZE.large,
  contents,
  onItemClick,
  spotlightId,
  playIcon,
  centerTitle,
  mode='',
  startGap=115,
  secondSubText=false,
  subTypeIcon=false,
  ...rest
}) => {
  const scrollTo = useRef(null);
  const containerRef = useRef(null);
  const [totalLength, setTotalLength] = useState(0);
  const [firstInvisibleIndex, setFirstInvisibleIndex] = useState(0);
  const [invisibleIndex, setInvisibleIndex] = useState(0);
  const scrollXAmount = LIST_ITEM_CONF[itemSize].ITEM_WIDTH + LIST_ITEM_CONF[itemSize].SPACING;

  useEffect(()=>{
    if(contents && contents.length > 0 && containerRef.current){
      const itemCount = contents.length;
      containerRef.current.style.setProperty('--itemCount', itemCount);
      setTotalLength(itemCount);
      const childNodes = containerRef.current.children;
      for (let i = 0; i < childNodes.length; i++) {
        if (!HelperMethods.isChildFullyShowing(containerRef.current.parentNode, childNodes[i])) {
          setFirstInvisibleIndex(i)
          return;
        }
      }
    }
  }, [contents]);

  const _onFocus = useCallback((ev) => {
		const listItem = ev.target;
		if (listItem && scrollTo.current) {
			if (!HelperMethods.isChildFullyShowing(listItem.parentNode.parentNode, listItem)) {
				const { left } = HelperMethods.getRectDiff(listItem.parentNode.parentNode, listItem);
        const nextIndex = left < 0 ? invisibleIndex + 1 : invisibleIndex - 1;
				scrollTo.current({ position: { x: nextIndex * scrollXAmount }, animate: true });
        setInvisibleIndex(nextIndex);
			}
		}
	}, [invisibleIndex]);

  const cbScrollTo = useCallback((fn)=>{
		scrollTo.current = fn;
	},[]);

  return (
    <div className={classNames(className, css.mediaList, css[itemSize])}>
      {invisibleIndex > 0 &&
        <div className={css.lButtonIcon}/>
      }
      {firstInvisibleIndex > 0 && invisibleIndex < totalLength - firstInvisibleIndex &&
        <div className={css.rButtonIcon}/>
      }
      {contents && contents.length > 0 ?
			<Scroller
        scrollMode='translate'
        focusableScrollbar={false}
        noScrollByWheel={true}
        className={css.scroller}
        direction='horizontal'
        horizontalScrollbar='hidden'
        verticalScrollbar='hidden'
        overscrollEffectOn={{
          arrowKey: false,
          drag: false,
          pageKey: false,
          track: false,
          wheel: false
        }}
        cbScrollTo={cbScrollTo}
      >
        <div
          className={classNames(css.container)}
          ref={containerRef}
          style={{paddingLeft: ri.scale(startGap * 2), paddingRight: ri.scale(startGap * 2)}}
        >
          {contents.map((item, index) => (
            <SMediaItem
              key={"item" + index}
              className={css.item}
              spotlightId={`${spotlightId}_${index}`}
              index={index}
              itemSize={itemSize}
              type={type}
              onClick={onItemClick}
              onFocus={_onFocus}
              itemInfo={contents[index]}
              playIcon={playIcon}
              mode={mode}
              secondSubText={secondSubText}
              subTypeIcon={subTypeIcon}
            />
          ))}
        </div>
      </Scroller>
      :
        <div>{$L("No Media")}</div>
      }
    </div>
  );
}

export default SMediaList;