import css from "./SpriteImage.module.less";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import Spritesheet from "react-responsive-spritesheet";

import Fashionista from "../../../../assets/landingPage/8frame_sec/fashionista-sprites.png";

const spriteData ={
  sprite: {
    image: Fashionista,
    widthFrame: 400,
    heightFrame: 500,
    steps: 48,
    fps: 8,
  }
};

const SpriteImage = ({ className, ...rest }) => {
  const [spriteInstance, setSpriteInstance] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (spriteInstance) {
      spriteInstance.setDirection("forward");
      spriteInstance.pause(0);
      spriteInstance.goToAndPlay(1);
    }
  }, [spriteInstance]);

  useEffect(() => {
    if (spriteInstance && !spriteInstance.getInfo("isPlaying") && isFocused) {
      spriteInstance.setDirection("forward");
      spriteInstance.pause(0);
      spriteInstance.goToAndPlay(1);
    }
  }, [spriteInstance, isFocused]);

  const getInstance = useCallback((instance)=>{
    setSpriteInstance(instance);
    window.aaa = instance;
  }, []);


  return (
    <div className={classNames(css.imageContainer, className)}>
      <Spritesheet
        image={spriteData.sprite.image}
        widthFrame={spriteData.sprite.widthFrame}
        heightFrame={spriteData.sprite.heightFrame}
        steps={spriteData.sprite.steps}
        fps={spriteData.sprite.fps}
        autoplay={false}
        getInstance={getInstance}
        onMouseEnter={() => setIsFocused(true)}
        onMouseLeave={() => setIsFocused(false)}
      />
    </div>
  );
};

export default SpriteImage;