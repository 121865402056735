import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Job } from "@enact/core/util";
import css from "./Overall.module.less";
import * as Config from "../../../../utils/Config";
import * as TTSService from "../../../../lunaSend/TTSService";
import { resetPanels, addPanels } from "../../../../features/panels/panelsSlice";
import { MENUTYPE, getBarItem, getEmptyResultsReportData } from "./Constants";
import {SCORE_RED_KEY, SCORE_YELLOW_KEY, SCORE_MINT_KEY, BODY_SCAN_SCORE, BODY_SCAN_BASIC_SCORE} from "../../../../utils/Constants";
import EmptyResultsReport from '../../../../components/EmptyResultsReport/EmptyResultsReport';
import Canvas from "../../../../components/Canvas/Canvas";
import {$L, convertNewlinesToBr} from '../../../../utils/helperMethods';
import Spotlight from '@enact/spotlight';
import { changeAppStatus } from '../../../../features/common/commonSlice';
import Wrapper, { TYPES } from "../../components/Wrapper/Wrapper";
import BarItem from "../../../../components/BarItem/BarItem";

const PositionGuide = ({type}) => {
  return <div className={classNames(css.positionGuide, (type=== 'R' || type === 'B') && css.rightAlign)}>{$L(type)}</div>
}
const TEMP_GUIDE_TERM = 6500;
const TEMP_GUIDE_TERM_SHORT = 3000;

const Overall = ({ date = "", scoreArray, tabInfos, feedback, ...rest }) => {
  const dispatch = useDispatch();
  const overallData = getEmptyResultsReportData(MENUTYPE.OVERALL);
  const { dateList, testResults, testResultUpdated } = useSelector((state) => state.bodyAlignment);
  const {pictureTypeOverAll } = useSelector((state) => state.common.appStatus);
  const [tempGuideIndex, setTempGuideIndex] = useState(-1);
  const changeTempGuideJob = useRef(new Job((index) => {
    setTempGuideIndex(index);
  }, TEMP_GUIDE_TERM_SHORT));

  useEffect(() => {
    setTempGuideIndex(-1);
    if(testResults?.[date]){
      changeTempGuideJob.current.startAfter(1500, 0);
    }
  }, [date]);
  useEffect(() => {
    if(tempGuideIndex === -1 && testResults?.[date]){
      changeTempGuideJob.current.startAfter(1500, 0);
    }
  }, [testResultUpdated]);

  useEffect(() => {
    if(testResults?.[date]){
        changeTempGuideJob.current.startAfter(TEMP_GUIDE_TERM_SHORT, tempGuideIndex+1);
    }
  }, [tempGuideIndex]);

  const testkeys = useMemo(() => {
    let res = [];
    if(!testResults?.[date]){
      return res;
    }
    if (testResults[date]?.front && testResults[date]?.side) {
      const frontKeys = Object.keys(testResults[date].front);
      const sideKeys = Object.keys(testResults[date].side);
      res = res.concat(frontKeys).concat(sideKeys);
    }
    return res;
  }, [testResults, date]);

  const scoreValues = useMemo(() => {
    let res = {[SCORE_MINT_KEY]: 0, [SCORE_YELLOW_KEY]: 0, [SCORE_RED_KEY]: 0};

   for(let i=0; i<scoreArray.length; i++){
    if(scoreArray[i] === BODY_SCAN_SCORE[SCORE_MINT_KEY]){
      res[SCORE_MINT_KEY] = res[SCORE_MINT_KEY]+1;
    }else if(scoreArray[i] === BODY_SCAN_SCORE[SCORE_YELLOW_KEY]){
      res[SCORE_YELLOW_KEY] = res[SCORE_YELLOW_KEY]+1;
    }else if(scoreArray[i] === BODY_SCAN_SCORE[SCORE_RED_KEY]){
      res[SCORE_RED_KEY] = res[SCORE_RED_KEY]+1;
    }
   }
    return res;
  }, [scoreArray]);

  const scoreTitle = useMemo(() => {
    let res = {[SCORE_MINT_KEY]: $L("Good"), [SCORE_YELLOW_KEY]: $L("Management"), [SCORE_RED_KEY]: $L("Bad")};
    return res;
  }, []);

  // const testStart= useCallback(()=>{
  //   TTSService.stop();
  //   dispatch(addPanels({ name: Config.panel_names.BODY_ALIGNMENT_SCAN }));
  //   dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
  // },[]);

  // const onClickCommonBtn= useCallback((index)=>{
  //   console.log('onClickCommonBtn', index);
  //   if(index === 0){
  //     testStart();
  //   }
  // },[testStart]);

  // useEffect(()=>{
  //   Spotlight.focus('bodyScanTestBtn');
  // },[]);
  // const str = $L(
  //   "측정 부위(9개) 중,</br><b>관리 필요</b> 부위가 가장 많습니다."
  // );

  const feedbackInfo = useMemo(() => {
    const ret = {title: "", subtitle: ""};
    if (feedback && feedback !== null) {
      const title = feedback.title;
      const subtitle = feedback.subtitle;
      return { title, subtitle };
    }
    return ret;
  }, [feedback]);

  const filteredTabInfos = useMemo(() => {
    return [...tabInfos].filter(
      (item) =>
        item.type !== MENUTYPE.OVERALL && item.type !== MENUTYPE.PELVIC_ROTATION
    );
  }, [tabInfos]);

  const convertTabInfos = useMemo(() => {
    const ret = [];

    const positionType = {
      LR: [$L("Left tilt"), $L("Right tilt")],
      LRH: [$L("Left high"), $L("Right high")],
      FB: [$L("Anterior"), $L("Posterior")],
      LEG: [$L("Bow legs"), $L("Knock knees")],
    };
    const bodyPart = getBarItem();

    filteredTabInfos?.map((item) => {
      const key = item.detailType;
      const type = item.type;
      const score = item.score;

      let detailKey;
      if (
        key.includes("front") ||
        key.includes("out") ||
        key.includes("same")
      ) {
        detailKey = "LEFT";
      } else if (key.includes("back") || key.includes("in")) {
        detailKey = "RIGHT";
      } else if (key.includes("right")) {
        if (type === MENUTYPE.PELVIC_ROTATION || type === MENUTYPE.NECK_TILT) {
          detailKey = "LEFT";
        } else {
          detailKey = "RIGHT";
        }
      } else if (key.includes("left")) {
        if (type === MENUTYPE.PELVIC_ROTATION || type === MENUTYPE.NECK_TILT) {
          detailKey = "RIGHT";
        } else {
          detailKey = "LEFT";
        }
      } else {
        detailKey = null;
      }

      let value = 3;
      if (detailKey === "LEFT") {
        if (score === 4) {
          value -= 1;
        } else if (score === 1) {
          value -= 2;
        }
      } else if (detailKey === "RIGHT") {
        if (score === 4) {
          value += 1;
        } else if (score === 1) {
          value += 2;
        }
      }

      let retEl = {
        partName: bodyPart[item.type].partName,
        keys: positionType[bodyPart[item.type].positionType],
        titleDisabled: bodyPart[item.type].titleDisabled,
        value: value,
        sortIndex: bodyPart[item.type].sortIndex,
      };
      ret.push(retEl);
    });

    ret.sort((a, b) => {
      return b.sortIndex < a.sortIndex ? 1 : -1;
    });
    return ret;
  }, [filteredTabInfos]);

  if (dateList && dateList.length > 0 ) {
    if(testResults[date]){
    return (
      <>
        {/* <div className={}>{$L("Overall")}</div> */}
        <Wrapper type={TYPES.withIcon}>
          <div className={css.pointLayer}>
            <div className={css.title}>{$L("Total Score")}</div>
            <div className={css.point}>{scoreArray[0]}</div>
            {/* <div className={css.point}>{$L("{num}points").replace("{num}", scoreArray[0])}</div> */}
            {/* <div className={css.scoreSum}>
              {[SCORE_RED_KEY, SCORE_YELLOW_KEY, SCORE_MINT_KEY].map((scoreType, index) => {
                return (
                  <div className={css.scoreItem} key={scoreType}>
                    <div className={css.text}>{scoreTitle[scoreType]}</div>
                    <div className={classNames(css.value, css[scoreType])}>{scoreValues[scoreType]}</div>
                  </div>
                );
              })}
            </div> */}
          </div>
          <div className={css.profileContainer}>
            {["front", "side"].map((bodyType, index) => {
                return (
                  <div className={css.bodyWrapper} key={"canvasWrapper"+index}>
                    <div className={css.canvasWrapper}>
                      <Canvas
                        className={css.canvas}
                        bodyType={bodyType}
                        type="wholebody"
                        testResult={testResults[date]}
                        testkeys={testkeys}
                        adjustScale={0.3}
                        isIllust={true}
                        disabledText={true}
                    />
                    </div>
                    <PositionGuide type={bodyType === 'front' ? "L": "F"}/>
                    <PositionGuide type={bodyType === 'front' ? "R": "B"}/>
                </div>
                );
              })}
          </div>
          <div className={css.barContainer}>
            {convertTabInfos.length > 0 &&
              convertTabInfos.map((info, index) => (
                <BarItem
                  key={`barItem_${index}`}
                  info={info}
                  style={
                    index === 6 || index === 7 ? { marginBottom: "4px" } : {}
                  }
                />
              ))}
          </div>
          <div className={css.notice}>{$L("Measuring your body alignment using the camera may be influenced by your surroundings (e.g. clothing).")}</div>
          {/* <div className={css.detailContainer}>
            <div className={css.summaryDetail}>{feedbackInfo.subtitle}</div>
            <div className={css.canvasGuide}>{$L("The image shown on the screen is not flipped left or right.")}</div>
          </div> */}
        </Wrapper>

      </>

    )
    }else{
      return null;
    }
  }
  return (
    <EmptyResultsReport
      bodyScan
      overallResult
      title={overallData.title}
      detail={overallData.detail}
      subheading={overallData.subheading}
      list={overallData.list}
      // openMenuTestPopup={testStart}
    />
  )
}

export default Overall;