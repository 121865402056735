import classNames from "classnames";
import React, { useCallback, useEffect, useState } from 'react';
import css from "./CheckMark.module.less";



const CheckMark = ({className=null}) => {



	return (
        <div className={classNames(className, css.checkmarkWrapper)}>
            <div className={css.checkmark}>
                <div className={css.short}/>
                <div className={css.long}/>
            </div>
        </div>
	);
};

export default CheckMark;