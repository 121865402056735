
import html2canvas from 'html2canvas';

const DEFAULT_TEMPLATE_WIDTH = 1920;
const DEFAULT_TEMPLATE_HEIGHT = 1080;

// const screenCaptured = (thumbnail) => {
//   return thumbnail;
// };

// export const captureScreen = (spotlightId) => {
//   const preview = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
//   let thumbnail = "";
//   // captureFailed = false;
//   if (preview) {
//     // screenCaptureFailedJob.start();
//     // const baseRect = preview.firstElementChild.getBoundingClientRect();
//     // const thumbScale = DEFAULT_TEMPLATE_WIDTH / baseRect.width;
//     html2canvas(preview, {
//       allowTaint: true,
//       useCORS: true,
//       letterRendering: true,
//       // destWidth: DEFAULT_TEMPLATE_WIDTH,
//       // destHeight: DEFAULT_TEMPLATE_HEIGHT,
//       // scale: thumbScale,
//     }).then((canvas) => {
//       let imgStr = canvas.toDataURL('image/jpeg', 0.9);
//       thumbnail = imgStr;
//       // let output1 = imgStr.replace(/^data:image\/(png|jpeg);base64,/, '');
  
//       screenCaptured(thumbnail);
//     });
//   }
//   return thumbnail;
// };

export const captureScreen = async (spotlightId) => {
  const preview = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
  
  if (preview) {
    try {
      const canvas = await html2canvas(preview, {
        allowTaint: true,
        useCORS: true,
        letterRendering: true,
        // destWidth: DEFAULT_TEMPLATE_WIDTH,
        // destHeight: DEFAULT_TEMPLATE_HEIGHT,
        // scale: thumbScale,
      });
      const thumbnail = canvas.toDataURL('image/jpeg', 0.9);
      return thumbnail;
    } catch (error) {
      console.error('Error capturing screen:', error);
      return null;
    }
  }
  return null;
};