import classNames from "classnames";
import css from "./THeader.module.less";

import React, { useState, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addPanels } from "../../features/panels/panelsSlice";
import { launchMembershipApp } from "../../features/common/commonSlice";

import * as Config from "../../utils/Config";
import { $L } from "../../utils/helperMethods";

import { TIconButton, ICON_TYPES } from "../TIconButton/TIconButton";
import TButton, { SIZES, COLOR } from "../TButton/TButton";
import TToolTip from "../TToolTip/TToolTip";

import DummyProfile from "../../../assets/Theader/dummy_profile.png";

import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TPopUp from "../TPopUp/TPopUp";
import Spotlight from "@enact/spotlight";
import useGesture from "../../hooks/useGesture";
import SpotlightIds from "../../utils/SpotlightIds";

const Container = SpotlightContainerDecorator({ enterTo: "last-focused" }, "div");

const THeader = ({
  className,
  title,
  onClick,
  onKeyDown,
  iconType = ICON_TYPES.back,
  subTitle = "",
  useTool = true,
  disabled,
  ...rest
}) => {
  const dispatch = useDispatch();
  const connectStatus = useSelector((state) => state.ble.gattConnectingStatus.connectStatus);
  const { bleDeviceList, gattConnectingStatus } = useSelector((state) => state.ble);
  const { themeMode, userNumber } = useSelector(
    (state) => state.common.appStatus
  );
  const { cameraList, activatedCameraId } = useSelector((state) => state.camera);
  const [alert, setAlert] = useState(false);
  const [ispopup, setIspopup] = useState(false);
  const deviceRef = useRef(null);
  const { gestureRelease } = useGesture();
  const batteryValue = useSelector(state => state.ble.batteryStatus)
  const { cesShowMode, useBleSerial } = useSelector((state) => state.common.localSettings);

  const connectMat = useMemo(() => {
    if(bleDeviceList.length > 0 &&
      gattConnectingStatus.address &&
      bleDeviceList.filter(ble => ble?.address === gattConnectingStatus?.address).length > 0
    ) {
      return bleDeviceList.filter(ble => ble.address === gattConnectingStatus.address)?.[0]?.name || ''
    } else return '';
  },[bleDeviceList, gattConnectingStatus]);

  const connectCamera = useMemo(() => {
    if(cameraList.length > 0 &&
      activatedCameraId &&
      cameraList.filter(cam => cam?.deviceId === activatedCameraId).length > 0
    ) {
      return (
        cameraList
          .filter((cam) => cam.deviceId === activatedCameraId)?.[0]
          ?.label?.replace(/[\u4E00-\u9FFF]/g, "") || "" // 한문을 나타내는 유니코드 범위 색출 및 제거
      );
    } else return '';
  },[cameraList, activatedCameraId]);

  const itemRenderer = useCallback((isTooltip) => {
    return (
      <TToolTip
        className={css.loginTooltip}
        relativeRef={deviceRef}
        relativePosition="right"
        isOpen={isTooltip}
      >
        {$L("Log in now to get a personalized care!")}
      </TToolTip>
    );
  }, []);

  const handleCancel = useCallback(() => {
    setIspopup(true);
  }, [ispopup]);

  const onClickPopUpBtn = useCallback(
    (index) => {
      if (index === 0) {
        setIspopup(false);
        Spotlight.focus("theaderClose")
      } else {
        if(typeof window === "object"){
          if (window.PalmSystem) {
            gestureRelease();
            setTimeout(() => {
              window.close();
            }, 10);
          } else {
            window.location.reload();
          }
          setIspopup(false);
        }
      }
    },
    [ispopup]
  );

  const onClickMatBtn = useCallback(() => {
    dispatch(addPanels({name: Config.panel_names.SETTING_PANEL, panelInfo: { selectedIndex: 0, selectedTab: 0 },}));
  }, [dispatch]);

  const onClickCameraBtn = useCallback(() => {
    dispatch(
      addPanels({
        name: Config.panel_names.SETTING_PANEL,
        panelInfo: { selectedIndex: 0, selectedTab: 1 },
      })
    );
  }, [dispatch]);

  const onClickAlertBtn = useCallback(() => {
    dispatch(
      addPanels({
        name: Config.panel_names.SETTING_PANEL,
        panelInfo: { selectedIndex: 4 },
      })
    );
  }, [dispatch]);

  const onClickLoginBtn = useCallback(() => {
    dispatch(launchMembershipApp());
  }, [dispatch]);

  const onClickUserBtn = useCallback(() => {
    dispatch(addPanels({ name: Config.panel_names.MY_PANEL }));
  }, [dispatch]);

  return (
    <Container
      {...rest}
      onKeyDown={onKeyDown}
      className={classNames(
        css.tHeader,
        (themeMode === "dark" || themeMode === "transparent") && css.isDark,
        className
      )}
    >
      <div className={css.backBtn}>
        {iconType && iconType !== ICON_TYPES.none && (
          <TIconButton
            className={classNames(css.icon)}
            iconType={ICON_TYPES.back}
            spotlightId={SpotlightIds.THEADER_BACK}
            onClick={onClick}
            disabled={disabled}
          />
        )}
        <div className={css.title}>{title}</div>
      </div>
      {useTool && <div className={css.device} ref={deviceRef}>
        {connectStatus === "connected" ? (
          <TIconButton
            iconType={ICON_TYPES.none}
            className={classNames(css.matBtn)}
            onClick={onClickMatBtn}
            itemRenderer={
              (isTooltip) => <TToolTip isOpen={isTooltip}>{useBleSerial ? 'LG Smart Mat': connectMat}</TToolTip>
            }
          >
            <div className={classNames(css.icBattery)} />
            <div
              className={classNames(css.percentage)}
            >{`${80}%`}</div>
            {/* <div
              className={classNames(css.percentage)}
            >{`${batteryValue}%`}</div> */}
            <div className={classNames(css.icon)} />
          </TIconButton>
        ) : (
          <TIconButton
            iconType={ICON_TYPES.matNo}
            className={classNames(css.iconBtn)}
            onClick={onClickMatBtn}
            itemRenderer={
              (isTooltip) => <TToolTip isOpen={isTooltip}>{$L("Connect your Smart Mat now!")}</TToolTip>
            }
          >
          </TIconButton>
        )}
        <TIconButton
          iconType={activatedCameraId ? ICON_TYPES.camera: ICON_TYPES.cameraNo}
          className={classNames(css.iconBtn)}
          onClick={onClickCameraBtn}
          itemRenderer={
            (isTooltip) =><TToolTip isOpen={isTooltip}>{activatedCameraId ? connectCamera : $L("Connect your camera now!")} </TToolTip>
          }
        >
        </TIconButton>
        <TIconButton
          iconType={alert ? ICON_TYPES.alertNoti : ICON_TYPES.alert}
          className={classNames(css.iconBtn)}
          onClick={onClickAlertBtn}
          itemRenderer={
            (isTooltip) => <TToolTip
              relativeRef={deviceRef}
              relativePosition="right"
              isOpen={isTooltip}
            >
              {$L("A new alert has been added.")}
            </TToolTip>
          }
        >
        </TIconButton>
        {userNumber ? (
          <TIconButton
            iconType={ICON_TYPES.none}
            onClick={onClickLoginBtn}
            className={classNames(css.userBtn)}
            style={{ backgroundImage: `url(${DummyProfile})` }}
          >
          </TIconButton>
        ) : (
          <TButton
            className={classNames(css.loginBtn, cesShowMode && css.fontSize)}
            onClick={onClickLoginBtn}
            size={SIZES.tiny}
            color={themeMode === "dark" ? COLOR.yellow : COLOR.green}
            itemRenderer={itemRenderer}
          >
            {!cesShowMode ? $L("Log In") : $L("CES 2024")}
          </TButton>
        )}
        <TIconButton
          iconType={ICON_TYPES.close}
          className={classNames(css.iconBtn)}
          onClick={handleCancel}
          spotlightId={"theaderClose"}
          itemRenderer={
            (isTooltip) => <TToolTip
              relativeRef={deviceRef}
              relativePosition="right"
              isOpen={isTooltip}
            >
              {$L("Finish LG Intelli-FiT.")}
            </TToolTip>
          }
        >
        </TIconButton>
      </div>}
      {ispopup && (
        <TPopUp
          kind="textPopUp"
          title={$L("Exit LG Intelli-FiT.")}
          text={$L("Do you want to exit LG Intelli-FiT?")}
          button1text={$L("Cancel")}
          button2text={$L("Quit")}
          onClick={onClickPopUpBtn}
        />
      )}
    </Container>
  );
};

export default THeader;
