import classNames from "classnames";
import css from "./SDropdown.module.less";
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { $L } from "../../../../utils/helperMethods";
import TDropdown from "../../../../components/TDropDown/TDropDown";
import { updateSurvey } from "../../../../features/fitService/fitServiceSlice";
import { changeToastStatus } from "../../../../features/common/commonSlice";

const percent = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const SDropdown = ({question, className}) => {
  const dispatch = useDispatch();
  const answer = useSelector((state) => state.fitService.survey);

  const checkTotalIntensity = useCallback((id, selectedNum) => {
    let totalIntensity = 0;
    if (id === "intensity_low") {
      totalIntensity = percent[selectedNum] + percent[answer.intensity_mid] + percent[answer.intensity_high];
    }else if (id === "intensity_mid") {
      totalIntensity = percent[answer.intensity_low] + percent[selectedNum] + percent[answer.intensity_high];
    }else if (id === "intensity_high") {
      totalIntensity = percent[answer.intensity_low] + percent[answer.intensity_mid] + percent[selectedNum];
    }

    if (totalIntensity > 100) {
      dispatch(changeToastStatus({ isToasted: true, message: $L("The ratio of the full-body workout has exceeded 100%. Select again.") }));
    } else if (totalIntensity < 100) {
      dispatch(changeToastStatus({ isToasted: true, message: $L("The ratio of the full-body workout is less than 100%. Increase the ratio.") }));
    }
  }, [dispatch, answer]);

  const _onSelect = useCallback(({ selected }) => {
    dispatch(updateSurvey({ [question.id]: selected }));
    if (question.id === "intensity_low" || question.id === "intensity_mid" || question.id === "intensity_high") {
      checkTotalIntensity(question.id, selected);
    }
  }, [dispatch, question, checkTotalIntensity]);

	return (
    answer && (
      <TDropdown
        className={classNames(css.dropdown, className)}
        placeholder={question.placeholder}
        onSelect={_onSelect}
        selected={answer?.[question.id]}
        width={334}
      >
        {question.article.map(item => $L(item.article_title))}
      </TDropdown>
    )
  );
};

export default SDropdown;