import { $L } from "../../../../utils/helperMethods";

// guide video
import upper_body_strength from "../../../../../assets/video/physicalTest/upper_body_strength.mp4";
import upper_body_flexibility from "../../../../../assets/video/physicalTest/upper_body_flexibility.mp4";
import lower_body_strength from "../../../../../assets/video/physicalTest/lower_body_strength.mp4";
import lower_body_flexibility from "../../../../../assets/video/physicalTest/lower_body_flexibility.mp4";
import agility_dynamic_balance from "../../../../../assets/video/physicalTest/agility_dynamic_balance.mp4";
import cardiorespiratory_endurance from "../../../../../assets/video/physicalTest/cardiorespiratory_endurance.mp4";

import UpperBodyStrengthImg from "../../../../../assets/physicalTest/upper_body_strength.png";
import UpperBodyFlexibilityImg from "../../../../../assets/physicalTest/upper_body_flexibility.png";
import LowerBodyStrengthImg from "../../../../../assets/physicalTest/lower_body_strength.png";
import LowerBodyFlexibilityImg from "../../../../../assets/physicalTest/lower_body_flexibility.png";
import AgilityDynamicBalanceImg from "../../../../../assets/physicalTest/agility_dynamic_balance.png";
import CardiorespiratoryEnduranceImg from "../../../../../assets/physicalTest/cardiorespiratory_endurance.png";

import { DATAKEY } from "../../../../features/physicalTest/physicalTestSlice";
export const SFT_TYPE = {
	LOWER_BODY_STR: 0, //Lower Body Strength (하지 근력)
	UPPER_BODY_STR: 1, //Upper Body Strength (상지 근력)
	AEROBIC: 2, //Aerobic Endurance (step in Place, 심폐지구력) 2분
	LOWER_BODY_FLEX: 3, //Lower Body Flexibility (하지 유연성)  시간없음.  최대, 최소
	STATIC_BALANCE: 4, //Static Balance (상체 유연성) 한발서기.. 시간측정필요.
	UP_AND_GO_TEST: 5, //Agility Dynamic Balance (민첩성 및 동적균형)
	ONE_LEGGED_STAND: 6, //외발서기  어깨측정
	POWER_WALKING: 7, // 제자리 걸음(파워워킹)  어깨측정
};

export const SFT_SEQUENCE = {
	SEQUENCE_INIT: 0, //whole sequence
	GUIDE_START: 1,
	GUIDE_END: 2,
	TEST_START: 3,
	GET_SCORE: 4,
	TEST_END: 5,
	SEQUENCE_FINISHED: 100, //whole sequence
};


export const getSFT_TEST_INFO = () => {
	const SFT_TEST_INFO = [];
	const notOnTVTime = typeof window === "object" && !window.PalmSystem ? 5 : -1;

	SFT_TEST_INFO[SFT_TYPE.LOWER_BODY_STR] = {
		index: 1,
		type: SFT_TYPE.LOWER_BODY_STR,
		resultName: DATAKEY.LOWER_BODY_STR,
		preCountVoice: "SFT_3SEC_REMINDER",
		guideText: [$L("Lower Body Strength guide"), $L("Lower Body Strength")],
		position: $L("Front"),
		guideVideo: lower_body_strength,
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 30,
		engineParams: { index: "0", direction: "front" },
		chair: true,
		dumbell: false
	};
	SFT_TEST_INFO[SFT_TYPE.UPPER_BODY_STR] = {
		index: 2,
		type: SFT_TYPE.UPPER_BODY_STR,
		resultName: DATAKEY.UPPER_BODY_STR,
		preCountVoice: "SFT_3SEC_REMINDER",
		guideText: [$L("Upper Body Strength guide"), $L("Upper Body Strength")],
		position: $L("왼손"),
		guideVideo: upper_body_strength,
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 30,
		engineParams: { index: "1", direction: "front" },
		chair: true,
		dumbell: true
	};
	SFT_TEST_INFO[SFT_TYPE.AEROBIC] = {
		index: 3,
		type: SFT_TYPE.AEROBIC,
		resultName: DATAKEY.AEROBIC,
		preCountVoice: "SFT_3SEC_REMINDER",
		position: $L("Front"),
		guideText: [
			$L("Cardiopulmonary Endurance guide"),
			$L("Cardiopulmonary Endurance"),
		],
		guideVideo: cardiorespiratory_endurance,
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : 120,
		engineParams: { index: "2", direction: "front" },
		chair: false,
		dumbell: false
	};
	SFT_TEST_INFO[SFT_TYPE.LOWER_BODY_FLEX] = {
		index: 4,
		type: SFT_TYPE.LOWER_BODY_FLEX,
		resultName: DATAKEY.LOWER_BODY_FLEX,
		preCountVoice: "SFT_3SEC_REMINDER",
		position: $L("Side"),
		guideText: [$L("Lower Body Flexibility guide"), $L("Lower Body Flexibility")],
		guideVideo: lower_body_flexibility,
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : -1,
		engineParams: { index: "3", direction: "left" },
		chair: true,
		dumbell: false
	};
	SFT_TEST_INFO[SFT_TYPE.STATIC_BALANCE] = {
		index: 5,
		type: SFT_TYPE.STATIC_BALANCE,
		resultName: DATAKEY.STATIC_BALANCE,
		position: $L("Right Foot"),
		preCountVoice: "SFT_3SEC_REMINDER",
		guideText: [$L("Balance guide"), $L("Balance")],
		guideTextImg: [null, null],

		guideVideo: upper_body_flexibility,
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : -1,
		engineParams: { index: "5", direction: "front" },
		chair: false,
		dumbell: false
	};
	SFT_TEST_INFO[SFT_TYPE.UP_AND_GO_TEST] = {
		index: 6,
		type: SFT_TYPE.UP_AND_GO_TEST,
		resultName: DATAKEY.UP_AND_GO_TEST,
		position: $L("Front"),
		preCountVoice: "SFT_3SEC_REMINDER",
		guideText: [
			$L("Agility & Dynamic Balance guide"),
			$L("Agility & Dynamic Balance"),
		],
		guideTextImg: [null, null],
		guideVideo: agility_dynamic_balance,
		testTimeSec: notOnTVTime > 0 ? notOnTVTime : -1,
		engineParams: { index: "6", direction: "front" },
		chair: true,
		dumbell: false
	};
	// SFT_TEST_INFO[SFT_TYPE.ONE_LEGGED_STAND] = {
	//   type: SFT_TYPE.ONE_LEGGED_STAND,
	//   resultName: DATAKEY.ONE_LEGGED_STAND,
	//   preCountVoice: "SFT_3SEC_REMINDER",
	//   position: $L("Right Foot"),
	//   guideText: [$L("A one-legged stand guide"), $L("A one-legged stand")],
	//   guideVideo: "",
	//   testTimeSec: notOnTVTime > 0 ? notOnTVTime : 30,
	//   engineParams: { index: "8", direction: "front" },
	// };
	// SFT_TEST_INFO[SFT_TYPE.POWER_WALKING] = {
	//   type: SFT_TYPE.POWER_WALKING,
	//   resultName: DATAKEY.POWER_WALKING,
	//   preCountVoice: "SFT_3SEC_REMINDER",
	//   position: $L("Front"),
	//   guideText: [$L("Power walking in place guide"), $L("Power walking in place")],
	//   guideVideo: "",
	//   testTimeSec: notOnTVTime > 0 ? notOnTVTime : 60,
	//   engineParams: { index: "7", direction: "front" },
	// };
	return SFT_TEST_INFO;
}

export const GUIDE_START_TIME = 0.1
export const SFT_TEST_ONTIME = [];

SFT_TEST_ONTIME[SFT_TYPE.LOWER_BODY_STR] = {
	1.7: { sequence: SFT_SEQUENCE.GUIDE_START },
	"end": { sequence: SFT_SEQUENCE.GUIDE_END, avatarImage: LowerBodyStrengthImg },
};
SFT_TEST_ONTIME[SFT_TYPE.UPPER_BODY_STR] = {
	0.1: { avatarImage: null, sequence: SFT_SEQUENCE.GUIDE_START },
	"end": { sequence: SFT_SEQUENCE.GUIDE_END, avatarImage: UpperBodyStrengthImg },
};
SFT_TEST_ONTIME[SFT_TYPE.AEROBIC] = {
	0.1: { avatarImage: null, sequence: SFT_SEQUENCE.GUIDE_START },
	"end": {
		sequence: SFT_SEQUENCE.GUIDE_END,
		avatarImage: CardiorespiratoryEnduranceImg,
	},
};
SFT_TEST_ONTIME[SFT_TYPE.LOWER_BODY_FLEX] = {
	0.1: { avatarImage: null, sequence: SFT_SEQUENCE.GUIDE_START },
	"end": {
		sequence: SFT_SEQUENCE.GUIDE_END,
		avatarImage: LowerBodyFlexibilityImg,
	},
};
SFT_TEST_ONTIME[SFT_TYPE.STATIC_BALANCE] = {
	0.1: { avatarImage: null, sequence: SFT_SEQUENCE.GUIDE_START },
	"end": {
		sequence: SFT_SEQUENCE.GUIDE_END,
		avatarImage: UpperBodyFlexibilityImg,
	},
};
SFT_TEST_ONTIME[SFT_TYPE.UP_AND_GO_TEST] = {
	0.1: { avatarImage: null, sequence: SFT_SEQUENCE.GUIDE_START },
	"end": {
		sequence: SFT_SEQUENCE.GUIDE_END,
		avatarImage: AgilityDynamicBalanceImg,
	},
};
SFT_TEST_ONTIME[SFT_TYPE.ONE_LEGGED_STAND] = {
	"end": { sequence: SFT_SEQUENCE.GUIDE_END, avatarImage: LowerBodyStrengthImg },
};
SFT_TEST_ONTIME[SFT_TYPE.POWER_WALKING] = {
	"end": { sequence: SFT_SEQUENCE.GUIDE_END, avatarImage: UpperBodyStrengthImg },
};
export const initSftTestOnTime = (sftType) => {
	const testOnTime = SFT_TEST_ONTIME[sftType];
	const keys = Object.keys(testOnTime);
	for (let i = 0; i < keys.length; i++) {
		testOnTime[keys[i]].excuted = false;
	}
};
export const initAllSftTestOnTime = () => {
	const typeKeys = Object.keys(SFT_TEST_ONTIME);
	for (let i = 0; i < typeKeys.length; i++) {
		initSftTestOnTime(typeKeys[i]);
	}
};
