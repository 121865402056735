import React, {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { $L } from '../../../utils/helperMethods';
import css from './RecommendedStride.module.less';
import { getSurveyData } from "../../../utils/surveyData";
import SmoodShowingAnimation from "../../../components/SmoodShowingAnimation/SmoodShowingAnimation";
import TDropdown from "../../../components/TDropDown/TDropDown";
import TButton, { SIZES } from '../../../components/TButton';
import classNames from 'classnames';

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused' }, 'div');
const STRIDE_SIZE = [65, 70, 75, 80, 85];

const RecommendedStride = ({ value, onClick, ...rest }) => {
  const [selectedId, setSelectedId] = useState(null);
  const surveyData = getSurveyData();
  const heightQuestion = surveyData.find(question => question.id === 'height');

	const onSelectId = useCallback(({selected}) => {
    setSelectedId(selected);
  }, []);

  const onSelectSize = useCallback((size) => () => {
    if (onClick) onClick(size);
  }, [onClick]);

  return (
    <div className={css.recommendedStrideContainer}>
      <div className={classNames(css.infoBox, css.height)}>
        <div className={css.infoTitle}>{$L("Enter a key.")}</div>
        <TDropdown
          placeholder={$L("Pectoralis major")}
          direction="below"
          size="small"
          width="small"
          selected={selectedId}
          onSelect={onSelectId}
        >
          {heightQuestion.article.map((li, index) => { return li.article_title })}
        </TDropdown>
      </div>
      <div className={css.infoBox}>
        <div className={css.infoTitle}>{$L("Select the stride length")}</div>
        <Container className={css.strideSizeActivity}>
          {STRIDE_SIZE.map((size, idx) => <TButton key={`stride-size-${idx}`} className={css.button} selected={value === size} onClick={onSelectSize(size)}>{size}</TButton>)}
        </Container>
      </div>
    </div>
  )
};

export default SmoodShowingAnimation(RecommendedStride);
