import { useCallback, useEffect, useRef, useState } from 'react';
import TButton from '../../../../components/TButton/TButton';
import TTrack, { TRACK_TYPE } from '../../../../components/TTrack/TTrack';

const DEFAULT_VALUE = 0;

const TTrackLayout = () => {
  const [percentage, setPercentage] = useState(DEFAULT_VALUE);
  const timer = useRef(null);

  const plusFn = useCallback((minus = false) => () => {
    if (minus) {
      setPercentage(Math.max(percentage - 10, 0));
    } else {
      setPercentage(Math.min(percentage + 10, 100));
    }
  }, [percentage]);

  const autoFn = useCallback(() => {
    timer.current = setInterval(() => {
      setPercentage((percentage) => percentage + 0.1);
    }, 100);
  }, [percentage]);

  const clearFn = useCallback((clear = true) => () => {
    if (timer && timer.current) clearInterval(timer.current);
    if (clear) setPercentage(DEFAULT_VALUE);
  }, []);

  useEffect(() => {
    if (percentage > 100 && timer && timer.current) clearInterval(timer.current);
  }, [percentage]);

  return (
    <>
      <div style={{ marginBottom: '8px' }}>
        <TButton style={{ marginRight: '8px' }} onClick={plusFn()}>+10</TButton>
        <TButton style={{ marginRight: '8px' }} onClick={plusFn(true)}>-10</TButton>
        <TButton style={{ marginRight: '8px' }} onClick={autoFn}>auto</TButton>
        <TButton style={{ marginRight: '8px' }} onClick={clearFn(false)}>stop</TButton>
        <TButton style={{ marginRight: '8px' }} onClick={clearFn()}>clear</TButton>
        <span>percentage: {percentage}</span>
      </div>
      <div style={{ background: 'black', padding: '16px' }}>
        <TTrack type={TRACK_TYPE.cycloid} scale={2} percentage={percentage} guidePercentage={percentage === 0 ? percentage : Math.min(percentage + 10, 100)} hotSpot={[25, 50, 75]}/>
        <TTrack type={TRACK_TYPE.line} scale={2} percentage={percentage} guidePercentage={percentage === 0 ? percentage : Math.min(percentage + 10, 100)} hotSpot={[33, 66]}/>
        <TTrack type={TRACK_TYPE.height} scale={2} percentage={percentage} guidePercentage={percentage === 0 ? percentage : Math.min(percentage + 10, 100)} hotSpot={[50]}/>
        <TTrack type={TRACK_TYPE.cycloid} scale={1} percentage={percentage} guidePercentage={percentage === 0 ? percentage : Math.min(percentage + 10, 100)}/>
        <TTrack type={TRACK_TYPE.line} scale={1} percentage={percentage} guidePercentage={percentage === 0 ? percentage : Math.min(percentage + 10, 100)}/>
        <TTrack type={TRACK_TYPE.height} scale={1} percentage={percentage} guidePercentage={percentage === 0 ? percentage : Math.min(percentage + 10, 100)}/>
      </div>
    </>
  );
};

export default TTrackLayout;
