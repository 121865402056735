import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import css from "./WeeklyWorkout.module.less";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import { $L, stringTimetoSec } from "../../../../utils/helperMethods";
import JMediaList, { LIST_TYPE } from '../../../../components/JMediaList/JMediaList';
import TButton, { SIZES, TYPES } from "../../../../components/TButton/TButton";
import { addPanels } from "../../../../features/panels/panelsSlice";
import { panel_names } from "../../../../utils/Config";

// todo remove
import dummyJVideoList from "../../../../../assets/mock/dummyJVideoList.json";
import useUserCheck from "../../../../hooks/useUserCheck";
import { changeAppStatus } from "../../../../features/common/commonSlice";

export const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');
const SpottableComponent = Spottable("div");

const WeeklyWorkout = ({
  title='',
  isRoutine=true,
  mediaList=[],
  onClick=null,
  selectWeek=0,
  onFocus,
  className,
  ...rest
 }) => {
  const dispatch = useDispatch();
  const { userCheck } = useUserCheck();
  // const [isRoutine, setRoutine] = useState(true); //todo
  // const [selectWeek, setSelectWeek] = useState(new Date().getDay()); //todo

  const weeklyWorkouts = useMemo(() => {
    let weeklyWorkouts = {
      "SUN": {title: $L("SUN"), list: [], totalTime: 0},
      "MON": {title: $L("MON"), list: [], totalTime: 0},
      "TUE": {title: $L("TUE"), list: [], totalTime: 0},
      "WED": {title: $L("WED"), list: [], totalTime: 0},
      "THU": {title: $L("THU"), list: [], totalTime: 0},
      "FRI": {title: $L("FRI"), list: [], totalTime: 0},
      "SAT": {title: $L("SAT"), list: [], totalTime: 0},
    }

    // todo server
    // dummyJVideoList.data.forEach(item => {
    //   const day = DAYS[item.day];
    //   weeklyWorkouts[day].list.push(item);
    //   weeklyWorkouts[day].totalTime += stringTimetoSec(item.playTime)
    // });

    mediaList.forEach((item, idx) => {
      const day = DAYS[idx];
      weeklyWorkouts[day].list.push(item);
      weeklyWorkouts[day].totalTime += stringTimetoSec(item.playTime)
    });
		return weeklyWorkouts;
	}, [mediaList]);

  // const weekly = useMemo(() => {
  //   const today = new Date();
  //   const dateList = [];
  //   for (let i = 0; i < 7; i++) {
  //     dateList.push({
  //       day: weeklyWorkouts[DAYS[i]].title,
  //       date : new Date(today.getFullYear(), today.getMonth(), (today.getDate() - today.getDay()) + i ).getDate(),
  //       isWorkout: weeklyWorkouts[DAYS[i]].list.length > 0
  //     })
  //   }
  //   return dateList;
  // },[weeklyWorkouts, selectWeek]);


  // 날짜 한자리수인 것만 0 붙이도록 수정
  const weekly = useMemo(() => {
    const today = new Date();
    const dateList = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + i).getDate();
      const formattedDate = date < 10 ? `0${date}` : date;

      dateList.push({
        day: weeklyWorkouts[DAYS[i]].title,
        date: formattedDate,
        isWorkout: weeklyWorkouts[DAYS[i]].list.length > 0
      });
    }
    return dateList;
  }, [weeklyWorkouts, selectWeek]);

  const gotoRoutine = useCallback(() => {
    if(!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: "genaral" }}));
    } else {
      // todo 운동 계획하기 화면으로 이동
      dispatch(addPanels({ name: panel_names.HOMECARE_WORKOUT_PLANNER }));
    }
  },[userCheck]);

  const onClickWeek = useCallback((idx) => () => {
    if(onClick) {
      onClick(idx);
    }
    // setSelectWeek(idx);
  },[onClick]);

  const onItemPlay = useCallback(() => {
    // weeklyWorkouts[DAYS[selectWeek]].list 중 해당 영상 한 개 재생
  },[]);

  const onPlayStart = useCallback(() => {
    // weeklyWorkouts[DAYS[selectWeek]].list 해당 영상 순차 재생
  },[]);

	return (
    <Container className={css.contentContainer}>
      <div className={css.contentTitle}>
        <div className={css.title}>
          <div className={css.img} />
          <div>{title}</div>
        </div>
        {isRoutine &&
        <TButton
          className={css.edit}
          type={TYPES.withIcon}
          icon={'edit'}
          onClick={gotoRoutine}
        >
          {$L("Revise workout plans")}
        </TButton>}
      </div>
      {!isRoutine ? (
        <div className={css.doPlan}>
          <div className={css.description} dangerouslySetInnerHTML={{__html: $L(`Make your workout plan. <br>Be healthier and energized by doing exercises recommended by Fit Engine.`)}}/>
          <TButton className={css.playButton} size={SIZES.large} onClick={gotoRoutine}>{$L("Plan your workout")}</TButton>
        </div>
      ):(
        <>
          <div className={css.weeklyList}>
            {weekly.length > 0 && weekly.map((d, index) =>
              <TButton
                key={"weekly" + index}
                type={TYPES.square}
                className={classNames(css.weekly, selectWeek === index && css.select)}
                onClick={onClickWeek(index)}
                // selected={selectWeek === index}
              >
                <div className={css.day}>{d.day}</div>
                <div className={css.dateLayer}>
                <div className={css.date}>{d.date}</div>
                  {d.isWorkout && <div className={css.routine} />}
                </div>
              </TButton>
            )}
          </div>
          {/* <div className={css.mediaContainer}>
          {weeklyWorkouts[DAYS[selectWeek]].list && weeklyWorkouts[DAYS[selectWeek]].list.length > 0 ?
          <>
            <div className={css.titleLayer}>
              <div className={css.playCount}>
                <div className={css.stick}/>
                <span className={css.countTitle}>{$L("Planned daily exercises")}</span>
                <span className={css.count}>{weeklyWorkouts[DAYS[selectWeek]].list.length+$L("개")}</span>
              </div>
              <div className={css.playInfo}>
                <div className={css.process}>
                  {$L("현재 진행률")}
                  <span className={css.curProcess}>{"75%"}</span>
                </div>
                <div className={css.stick}/>
                <div className={css.playTime}>
                  {$L("Workout Time")}
                  <span className={css.curTime}>{"23:21"}</span>
                  <span className={css.totalTime}>{" /36:23"}</span>
                </div>
              </div>
            </div>
            <JMediaList
              className={css.contentList}
              listType={LIST_TYPE.large}
              contents={weeklyWorkouts[DAYS[selectWeek]].list}
              onItemClick={onItemPlay}
              mode={'weekly'}
            />
            <div className={css.buttonLayer}>
              <TButton size={SIZES.large} onClick={onPlayStart}>
                {$L("운동 시작하기")}
              </TButton>
            </div>
          </>
          :
          <div className={css.noneContent}>
            <div >{$L("You don't have a workout plans yet.")}</div>
            <div >{$L("Make your own workout plan.")}</div>
            <div >{$L("Be healthier and energized by doing exercises recommended by Fit Engine.")}</div>
            <TButton className={css.playButton} size={SIZES.large} onClick={gotoRoutine}>{$L("운동 계획하기")}</TButton>
          </div>}
          </div> */}
        </>
      )}
    </Container>
	)
}

export default WeeklyWorkout