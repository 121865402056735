import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./ROMReport.module.less";
import * as Config from "../../../../utils/Config";
import { getROM_INFO } from "../ROMTest/Constants";
import * as HelperMethods from "../../../../utils/helperMethods";
import { $L } from "../../../../utils/helperMethods";
import { romMenu, getRomMenuTitle } from "./Constants";
import TSimpleButtonTab, {
  LIST_TYPE,
} from "../../../../components/TSimpleButtonTab/TSimpleButtonTab";
import {
  addPanels,
  updatePanel,
} from "../../../../features/panels/panelsSlice";

//컴포넌트
import TPanel from "../../../../components/TPanel/TPanel";
import THeader from "../../../../components/THeader/THeader";
import TBody from "../../../../components/TBody/TBody";
import TScroller from "../../../../components/TScroller/TScroller";
import ReportTop from "../../../../components/ReportTop/ReportTop";
import TestSelectPopup from "../../../../components/TestSelectPopup/TestSelectPopup";
import TextPopup from "../../../../components/TextPopup/TextPopup";
import Overall from "./TabContents/Overall";
import SubTab from "./TabContents/SubTab";

import * as TTSService from "../../../../lunaSend/TTSService";
import { DATAKEY } from "../../../../features/rom/romSlice";
import { getBodyCheckUpData } from "../../../../features/fitService/fitServiceSlice";
import CameraPopup from "../../../Camera/CameraPopup";
import { BODY_CHECKUP_TYPE } from "../../../../utils/Constants";
import { changeAppStatus } from "../../../../features/common/commonSlice";

let ROM_INFO = null;
/**
 * @module ROMReport
 * @returns view Details Button 클릭시 tab layout
 */
const ROMReport = ({ panelInfo, ...rest }) => {
  if(!ROM_INFO){
    ROM_INFO = getROM_INFO();
  }
  const scrollTo = useRef();
  const dispatch = useDispatch();
  const [isText, setText] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    panelInfo.selectedDate ? panelInfo.selectedDate : null
  );
  // const [isTestResult, setIsTestResult] = useState(true);
  const [showTestSelectPopup, setShowTestSelectPopup] = useState(false);

  // main Tab Button Index
  // const [selectedTabIndex, setSelectedTabIndex] = useState(
  //   panelInfo?.selectedTabIndex ? panelInfo.selectedTabIndex : 0
  // ); //tab
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [feedback, setFeedback] = useState(null);
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);
  const initialCheckedState = Array(ROM_INFO.length).fill(true);
  const { dateList, testResults } = useSelector((state) => state.rom);
  const { overall, subTab } = useSelector(
    (state) => state.common.appStatus.voiceGuideStatus[BODY_CHECKUP_TYPE.ROM]
  );

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, []);

  useEffect(() => {
    if (scrollTo && scrollTo.current) {
      scrollTo.current({ position: { y: 0 }, animate: false });
    }
  }, [selectedTabIndex]);

  // tab title
  const tabInfos = useMemo(() => {
    const titleArray = getRomMenuTitle();
    const ret = [
      {
        type: romMenu.OVERALL,
        title: titleArray[romMenu.OVERALL],
        sortIndex: 0,
      },
    ];

    const currentData = testResults[selectedDate];

    // convert Info
    let filteredData = {};
    if (currentData) {
      Object.keys(currentData).map((key) => {
        if (key !== "subTesting" && key !== "testing") {
          filteredData = {
            ...filteredData,
            [key]: currentData[key],
          };
        }
      });
    }

    const initObj = {
      grade: {
        0: "",
        1: "",
      },
      value: {
        0: 0,
        1: 0,
      },
    };

    const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
    const newData = {
      neck: {
        NeckLateralFlexion: deepClone(initObj),
        NeckFlexion: deepClone(initObj),
        NeckExtension: deepClone(initObj),
      },
      shoulder: {
        ShoulderAbduction: deepClone(initObj),
        ShoulderInternalRotation: deepClone(initObj),
        ShoulderExternalRotation: deepClone(initObj),
        ShoulderFlexion: deepClone(initObj),
        ShoulderExtension: deepClone(initObj),
      },
      trunk: {
        TrunkLateralFlexion: deepClone(initObj),
        TrunkFlexion: deepClone(initObj),
        TrunkExtension: deepClone(initObj),
      },
      hip: {
        HipFlexion: deepClone(initObj),
        HipExtension: deepClone(initObj),
      },
      knee: {
        KneeFlexion: deepClone(initObj),
      },
    };
    const sortIndex = {
      neck: 1,
      shoulder: 2,
      trunk: 3,
      hip: 4,
      knee: 5,
    };
    if (filteredData && Object.keys(filteredData).length > 0) {
      // Hip
      if (filteredData.hip) {
        // Hip Flexion and Extension (LEFT)
        if (filteredData.hip.hipFlexionExtensionLeft) {
          newData.hip.HipFlexion.grade[0] =
            filteredData.hip.hipFlexionExtensionLeft.grade[0]; // jjy: 엔진에서 반대 값을 쏴 줌
          newData.hip.HipFlexion.value[0] =
            filteredData.hip.hipFlexionExtensionLeft.value[0];
          newData.hip.HipExtension.grade[0] =
            filteredData.hip.hipFlexionExtensionLeft.grade[1];
          newData.hip.HipExtension.value[0] =
            filteredData.hip.hipFlexionExtensionLeft.value[1];
        }

        // Hip Flexion and Extension (RIGHT)
        if (filteredData.hip.hipFlexionExtensionRight) {
          newData.hip.HipFlexion.grade[1] =
            filteredData.hip.hipFlexionExtensionRight.grade[0]; // jjy: 엔진에서 반대 값을 쏴 줌
          newData.hip.HipFlexion.value[1] =
            filteredData.hip.hipFlexionExtensionRight.value[0];
          newData.hip.HipExtension.grade[1] =
            filteredData.hip.hipFlexionExtensionRight.grade[1];
          newData.hip.HipExtension.value[1] =
            filteredData.hip.hipFlexionExtensionRight.value[1];
        }
      }
      // Knee Flexion
      if (filteredData.knee) {
        // Knee Flexion (LEFT)
        if (filteredData.knee.kneeFlexionLeft) {
          newData.knee.KneeFlexion.grade[1] =
            filteredData.knee.kneeFlexionLeft.grade[1]; // todo: maybe right..
          newData.knee.KneeFlexion.value[1] =
            filteredData.knee.kneeFlexionLeft.value[1]; // todo: maybe right..
        }
        // Knee Flexion (RIGHT)
        if (filteredData.knee.kneeFlexionRight) {
          newData.knee.KneeFlexion.grade[0] =
            filteredData.knee.kneeFlexionRight.grade[1]; // todo: maybe right..
          newData.knee.KneeFlexion.value[0] =
            filteredData.knee.kneeFlexionRight.value[1]; // todo: maybe right..
        }
      }

      // Neck
      if (filteredData.neck) {
        // Neck Flexion and Extension
        if (filteredData.neck.neckFlexionExtension) {
          newData.neck.NeckFlexion.grade[0] = // Flexion
            filteredData.neck.neckFlexionExtension.grade[0];
          newData.neck.NeckFlexion.value[0] =
            filteredData.neck.neckFlexionExtension.value[0];
          newData.neck.NeckExtension.grade[0] = // extension
            filteredData.neck.neckFlexionExtension.grade[1];
          newData.neck.NeckExtension.value[0] =
            filteredData.neck.neckFlexionExtension.value[1];
        }

        // Neck Lateral Flexion
        if (filteredData.neck.neckLateralFlexion) {
          newData.neck.NeckLateralFlexion.grade[0] = // left
            filteredData.neck.neckLateralFlexion.grade[1];
          newData.neck.NeckLateralFlexion.value[0] =
            filteredData.neck.neckLateralFlexion.value[1];
          newData.neck.NeckLateralFlexion.grade[1] = // right
            filteredData.neck.neckLateralFlexion.grade[0];
          newData.neck.NeckLateralFlexion.value[1] =
            filteredData.neck.neckLateralFlexion.value[0];
        }
      }

      // Shoulder
      if (filteredData.shoulder) {
        // Shoulder Abduction
        if (filteredData.shoulder.shoulderAbduction) {
          newData.shoulder.ShoulderAbduction.grade[0] = // left
            filteredData.shoulder.shoulderAbduction.grade[1];
          newData.shoulder.ShoulderAbduction.value[0] =
            filteredData.shoulder.shoulderAbduction.value[1];
          newData.shoulder.ShoulderAbduction.grade[1] = // right
            filteredData.shoulder.shoulderAbduction.grade[0];
          newData.shoulder.ShoulderAbduction.value[1] =
            filteredData.shoulder.shoulderAbduction.value[0];
        }

        // Shoulder External and Internal Rotation (LEFT)
        if (filteredData.shoulder.shoulderExternalInternalRotationLeft) {
          newData.shoulder.ShoulderExternalRotation.grade[0] =
            filteredData.shoulder.shoulderExternalInternalRotationLeft.grade[1];
          newData.shoulder.ShoulderExternalRotation.value[0] =
            filteredData.shoulder.shoulderExternalInternalRotationLeft.value[1];
          newData.shoulder.ShoulderInternalRotation.grade[0] =
            filteredData.shoulder.shoulderExternalInternalRotationLeft.grade[0];
          newData.shoulder.ShoulderInternalRotation.value[0] =
            filteredData.shoulder.shoulderExternalInternalRotationLeft.value[0];
        }

        // Shoulder External and Internal Rotation (RIGHT)
        if (filteredData.shoulder.shoulderExternalInternalRotationRight) {
          newData.shoulder.ShoulderExternalRotation.grade[1] =
            filteredData.shoulder.shoulderExternalInternalRotationRight.grade[1];
          newData.shoulder.ShoulderExternalRotation.value[1] =
            filteredData.shoulder.shoulderExternalInternalRotationRight.value[1];
          newData.shoulder.ShoulderInternalRotation.grade[1] =
            filteredData.shoulder.shoulderExternalInternalRotationRight.grade[0];
          newData.shoulder.ShoulderInternalRotation.value[1] =
            filteredData.shoulder.shoulderExternalInternalRotationRight.value[0];
        }

        // Shoulder Flexion and Extension (LEFT)
        if (filteredData.shoulder.shoulderFlexionExtensionLeft) {
          newData.shoulder.ShoulderFlexion.grade[0] =
            filteredData.shoulder.shoulderFlexionExtensionLeft.grade[0]; // jjy: 엔진에서 반대 값을 쏴 줌
          newData.shoulder.ShoulderFlexion.value[0] =
            filteredData.shoulder.shoulderFlexionExtensionLeft.value[0];
          newData.shoulder.ShoulderExtension.grade[0] =
            filteredData.shoulder.shoulderFlexionExtensionLeft.grade[1];
          newData.shoulder.ShoulderExtension.value[0] =
            filteredData.shoulder.shoulderFlexionExtensionLeft.value[1];
        }

        // Shoulder Flexion and Extension (RIGHT)
        if (filteredData.shoulder.shoulderFlexionExtensionRight) {
          newData.shoulder.ShoulderFlexion.grade[1] =
            filteredData.shoulder.shoulderFlexionExtensionRight.grade[0]; // jjy: 엔진에서 반대 값을 쏴 줌
          newData.shoulder.ShoulderFlexion.value[1] =
            filteredData.shoulder.shoulderFlexionExtensionRight.value[0];
          newData.shoulder.ShoulderExtension.grade[1] =
            filteredData.shoulder.shoulderFlexionExtensionRight.grade[1];
          newData.shoulder.ShoulderExtension.value[1] =
            filteredData.shoulder.shoulderFlexionExtensionRight.value[1];
        }
      }

      // Trunk
      if (filteredData.trunk) {
        // Trunk Flexion and Extension (only RIGHT)
        if (filteredData.trunk.trunkFlexionExtensionRight) {
          newData.trunk.TrunkFlexion.grade[0] =
            filteredData.trunk.trunkFlexionExtensionRight.grade[0]; // jjy: 엔진에서 반대 값을 쏴 줌
          newData.trunk.TrunkFlexion.value[0] =
            filteredData.trunk.trunkFlexionExtensionRight.value[0];
          newData.trunk.TrunkExtension.grade[0] =
            filteredData.trunk.trunkFlexionExtensionRight.grade[1];
          newData.trunk.TrunkExtension.value[0] =
            filteredData.trunk.trunkFlexionExtensionRight.value[1];
        }

        // Trunk Lateral Flexion
        if (filteredData.trunk.trunkLateralFlexion) {
          newData.trunk.TrunkLateralFlexion.grade[0] = // left
            filteredData.trunk.trunkLateralFlexion.grade[1];
          newData.trunk.TrunkLateralFlexion.value[0] =
            filteredData.trunk.trunkLateralFlexion.value[1];
          newData.trunk.TrunkLateralFlexion.grade[1] = // right
            filteredData.trunk.trunkLateralFlexion.grade[0];
          newData.trunk.TrunkLateralFlexion.value[1] =
            filteredData.trunk.trunkLateralFlexion.value[0];
        }
      }
    }

    if (newData && Object.keys(newData).length > 0) {
      for (const category in newData) {
        const tests = newData[category];
        let testsScore = 0;
        const testsLength = Object.keys(tests).length;

        let showingGrade = "";
        let showingGradeScore = 0;
        let showingGradeLR = {
          L: "",
          R: "",
        };
        let showingGradeScoreLR = {
          L: 0,
          R: 0,
        };

        for (const test in tests) {
          const grades = tests[test].grade;
          let gradesLength = Object.keys(grades).length;

          let gradeScore = 0;

          for (const grade in grades) {
            if (
              category === DATAKEY.HIP ||
              category === DATAKEY.KNEE ||
              category === DATAKEY.SHOULDER
            ) {
              const convertLR = grade === "0" ? "L" : "R";

              if (
                grades[grade] === "a" &&
                showingGradeLR[convertLR] !== "c" &&
                showingGradeLR[convertLR] !== "b"
              ) {
                showingGradeLR[convertLR] = "a";
                showingGradeScoreLR[convertLR] = 3;
              } else if (
                grades[grade] === "b" &&
                showingGradeLR[convertLR] !== "c"
              ) {
                showingGradeLR[convertLR] = "b";
                showingGradeScoreLR[convertLR] = 2;
              } else if (grades[grade] === "c") {
                showingGradeLR[convertLR] = "c";
                showingGradeScoreLR[convertLR] = 1;
              }
            }

            if (grades[grade] === "") {
              gradesLength -= 1;
            } else if (grades[grade] === "a") {
              gradeScore += 3;
              if (showingGrade !== "c" && showingGrade !== "b") {
                showingGrade = "a";
                showingGradeScore = 3;
              }
            } else if (grades[grade] === "b") {
              gradeScore += 2;
              if (showingGrade !== "c") {
                showingGrade = "b";
                showingGradeScore = 2;
              }
            } else if (grades[grade] === "c") {
              gradeScore += 1;
              showingGrade = "c";
              showingGradeScore = 1;
            }
          }
          const averageTestScore = gradeScore / gradesLength;

          if (!isNaN(averageTestScore)) {
            testsScore += averageTestScore;
          }
        }

        if (testsScore === 0) {
          ret.push({
            type: romMenu[category.toUpperCase()],
            title: titleArray[category.toUpperCase()],
            realGradeScore: 0,
            averageGrade: 0,
            showingGrade: "f",
            showingGradeScore: 0,
            data: tests,
            sortIndex: sortIndex[category],
          }); // if no test, score 0
        } else {
          const averageTestsScore = testsScore / testsLength;

          ret.push({
            type: romMenu[category.toUpperCase()],
            title: titleArray[category.toUpperCase()],
            realGradeScore: averageTestsScore,
            showingGrade,
            showingGradeScore,
            showingGradeLR:
              (category === DATAKEY.HIP ||
                category === DATAKEY.KNEE ||
                category === DATAKEY.SHOULDER) &&
              showingGradeLR,
            showingGradeScoreLR:
              (category === DATAKEY.HIP ||
                category === DATAKEY.KNEE ||
                category === DATAKEY.SHOULDER) &&
              showingGradeScoreLR,
            data: tests,
            sortIndex: sortIndex[category],
          });
        }
      }
    }

    if (ret.length !== 1) {
      ret.sort((a, b) => {
        // // previous Logic
        // if (a.realGradeScore === 0 || b.realGradeScore === 0) {
        //   return 1;
        // }
        // if (a.type === romMenu.OVERALL || b.type === romMenu.OVERALL) {
        //   return 0;
        // }
        // if (a.showingGradeScore > b.showingGradeScore) return 1;
        // if (a.showingGradeScore < b.showingGradeScore) return -1;
        // if (a.showingGradeScore === b.showingGradeScore) {
        //   return b.title < a.title ? 1 : -1;
        // }

        // 1. First, we handle cases where readGradeScore is 0 and send them to the back.
        if (a.realGradeScore === 0 && b.realGradeScore !== 0) {
          return 1;
        }
        if (b.realGradeScore === 0 && a.realGradeScore !== 0) {
          return -1;
        }

        // 2. Next, we handle cases where type is romMenu.OVERALL and send them to the front.
        if (a.type === romMenu.OVERALL && b.type !== romMenu.OVERALL) {
          return -1;
        }
        if (b.type === romMenu.OVERALL && a.type !== romMenu.OVERALL) {
          return 1;
        }

        // 3. And make sure that the lower-numbered value of showingGradeScore comes next if the type is romMenu.OVERALL.

        if (a.showingGradeScore < b.showingGradeScore) return -1;
        if (b.showingGradeScore < a.showingGradeScore) return 1;

        // 4. If the numbers in showingGradeScore are equal to each other, the title values are sorted alphabetically.
        if (a.showingGradeScore === b.showingGradeScore) {
          return b.sortIndex < a.sortIndex ? 1 : -1;
        }
      });
      return ret;
    } else {
      const titleKeys = Object.keys(titleArray);

      titleKeys.map((key) => {
        if (key !== romMenu.OVERALL) {
          const newObj = {
            type: romMenu[key],
            title: titleArray[key],
          };
          ret.push(newObj);
        }
      });
      return ret;
    }
  }, [testResults, selectedDate]);

  const tabNames = useMemo(() => {
    return tabInfos.map((item) => item.title);
  }, [tabInfos]);
  const tabTypes = useMemo(() => {
    return tabInfos.map((item) => item.type);
  }, [tabInfos]);
  const scoreArray = useMemo(() => {
    return tabInfos.map((item) => item.showingGradeScore);
  }, [tabInfos]);

  const ROM_TEST_ACTIONS = {
    [romMenu.OVERALL]: () => openTestSelectPopup(true),
    [romMenu.NECK]: 0,
    [romMenu.SHOULDER]: 1,
    [romMenu.TRUNK]: 2,
    [romMenu.HIP]: 3,
    [romMenu.KNEE]: 4,
  };

  const openMenuTestPopup = useCallback(() => {
    const action = ROM_TEST_ACTIONS[tabTypes[selectedTabIndex]];
    if (typeof action === "function") {
      action();
    } else if (typeof action === "number") {
      dispatch(
        addPanels({
          name: Config.panel_names.ROM_TEST,
          panelInfo: { romList: [action] },
        })
      );
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
    } else {
      openTestSelectPopup(true);
    }
  }, [dispatch, tabTypes, selectedTabIndex]);

  const renderTabContent = useCallback(() => {
    switch (tabInfos[selectedTabIndex].type) {
      case romMenu.OVERALL:
        return (
          <Overall
            date={selectedDate}
            tabInfos={tabInfos}
            openMenuTestPopup={openMenuTestPopup}
            feedback={feedback}
            setFeedback={setFeedback}
          />
        );
      default:
        return (
          <SubTab
            date={selectedDate}
            tabInfo={tabInfos[selectedTabIndex]}
            type={tabInfos[selectedTabIndex].type}
            feedback={feedback}
            setFeedback={setFeedback}
            openMenuTestPopup={openMenuTestPopup}
            selectedTabIndex={selectedTabIndex}
          />
        );
    }
  }, [tabInfos, selectedTabIndex, selectedDate, feedback]);

  const onSelectedDate = useCallback(
    ({ selectedDate: date }) => {
      console.log("selectedDate", date);
      const strDate = HelperMethods.convertDateToString2(date);
      //todo read data...from reducer
      if(strDate !== selectedDate){
        setSelectedDate(strDate);
        setSelectedTabIndex(0);
        dispatch(getBodyCheckUpData({type: BODY_CHECKUP_TYPE.ROM, date:strDate, syncFlag:"immediately"}));
        dispatch(
          updatePanel({
            name: Config.panel_names.ROM_REPORT,
            panelInfo: { selectedDate: strDate },
          })
        );
      }
    },
    [dispatch, selectedDate]
  );

  const onItemClick = useCallback(
    ({ index }) => {
      TTSService.stop();
      setSelectedTabIndex(index);
      setText(false);
      dispatch(
        updatePanel({
          name: Config.panel_names.ROM_REPORT,
          panelInfo: { selectedTabIndex: index },
        })
      );
    },
    [dispatch]
  );

  const openTestSelectPopup = useCallback(() => {
    setShowTestSelectPopup(true);
  }, []);

  const closeTestSelectPopup = useCallback((ev) => {
    console.log("ev.checked", ev.checked);
    setShowTestSelectPopup(false);
    if (ev.checked) {
      dispatch(
        addPanels({
          name: Config.panel_names.ROM_TEST,
          panelInfo: { romList: ev.checked },
        })
      );
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
    }
  }, []);

  const disabledItem = useMemo(() => {
    return [];
  }, []);

  const isEmpty = useMemo(() => {
    return !dateList || dateList.length <= 0;
  }, [dateList]);

  useEffect(() => {
    if (feedback) {
      if (selectedTabIndex === 0) {
        console.log("ROM feedback text:", feedback?.feedback);
        overall ? TTSService.speak(feedback?.feedbackVoice) : TTSService.stop();
      } else {
        console.log("ROM feedback text: ", feedback?.feedback);
        subTab ? TTSService.speak(feedback?.feedbackVoice) : TTSService.stop();
      }
    }
    return () => {
      TTSService.stop();
    };
  }, [feedback, overall, subTab, selectedTabIndex]);

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  return (
    <>
      <TPanel {...rest}>
        <THeader title={$L("Range Of Motion")} />
        <TBody style={{ paddingTop: "9px" }}>
          {/* tab 버튼 최상단 */}
          <ReportTop
            className={css.top}
            dateList={
              isEmpty
                ? [HelperMethods.convertDateToString2(new Date())]
                : dateList
            }
            onSelectedDate={onSelectedDate}
            reportType={BODY_CHECKUP_TYPE.ROM}
            selectedTabIndex={selectedTabIndex}
            // disabled={isEmpty}
            isText={isText}
            setText={setText}
            isEmpty={isEmpty}
          />
          {/* tab */}
          <TSimpleButtonTab
            className={css.tab}
            listType={LIST_TYPE.report}
            type="ROM"
            contents={tabNames}
            disabledItem={disabledItem}
            selectedIndex={selectedTabIndex}
            maxItemCount={6}
            score={scoreArray}
            onItemClick={onItemClick}
          />
          {/* tab 하단 contents */}
          <div className={css.tabLayout}>{renderTabContent()}</div>
          <div className={css.medicalCenter} />
        </TBody>
        {/* Test select popup : case overall */}
        {showTestSelectPopup && (
          <TestSelectPopup
            className={css.selectPopup}
            initialCheckedState={initialCheckedState}
            testInfos={ROM_INFO}
            romTest={true}
            onClose={closeTestSelectPopup}
          />
        )}
      </TPanel>
      <TextPopup
        open={isText}
        onClose={() => setText(false)}
        children={feedback?.feedback}
      />
      {/* check camera connect */}
      {isConnectionPopupOpen && <CameraPopup onClose={onCloseCameraPopup}/>}
    </>
  );
};

export default ROMReport;
