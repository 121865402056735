import { createSlice } from "@reduxjs/toolkit";

import center_dummy_01 from "../../../assets/images/center_dummy_01.png";
import center_dummy_02 from "../../../assets/images/center_dummy_02.png";
import center_dummy_03 from "../../../assets/images/center_dummy_03.png";
import center_dummy_04 from "../../../assets/images/center_dummy_04.png";
import guideImage01 from "../../../assets/guideImage.png";
import guideImage from "../../../assets/image-4647.png";
import blankImage from "../../../assets/guideImage.svg";
import dummyLogo_01 from "../../../assets/HomecareProgram/img_program_01.png";
import dummyLogo_02 from "../../../assets/HomecareProgram/img_program_02.png";
import dummyLogo_03 from "../../../assets/HomecareProgram/img_program_03.png";
import testIcon from "../../../assets/icon/test_Icon.png";
import IMG_HOMECARE_SINGLE_BANNER from "../../../assets/HomecareProgram/img_homecare_single_banner.png";
import IMG_HOMECARE_SINGLE_BANNER_ERICA from "../../../assets/HomecareProgram/img_homecare_singlebanner_erika.png";
// demo
import logo_s from "../../../assets/images/logo_s.png";
import logo_l from "../../../assets/images/logo_l.svg";
import TITLE_C_4_3_A from "../../../assets/images/TITLE_C_4_3_A.jpg";
import TITLE_C_1_10_A from "../../../assets/images/TITLE_C_1_10_A.jpg";
import TITLE_C_5_12_A from "../../../assets/images/TITLE_C_5_12_A.jpg";
import A_1_8 from "../../../assets/images/A_1_8.jpg";
import A_1_7 from "../../../assets/images/A_1_7.jpg";
import A_1_11 from "../../../assets/images/A_1_11.jpg";

const initialState = {
  centerList: [
    {
      title: "LG Collection",
      subTitle: "다양한 프로그램을 연구하여 차별화된 시스템을 제공하는 필라테스 센터",
      logoSmall: logo_s,
      logoLarge: logo_l,
      tags: ["구독중", "NEW", "유료"],
      introduction: "LG전자가 제공하는 프리미엄 운동 프로그램",
      features: ["필라테스"],
      composition: [
        "시니어 건강 증진 활력 프로젝트",
        "오늘도 건강하게 스트레칭",
      ],
      programList: [
        {
          programTitle: "스마트 시니어를 위한 건강 예방 프로그램",
          programIntroduction: "약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다.",
          contents: [
            {
              title: '어깨가 시원해지는 운동',
              thumbnailUrl: TITLE_C_4_3_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_1_10_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_5_12_A
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        },
        {
          programTitle: "바른 체형 교정 프로그램",
          programIntroduction: "잘못된 생활습관에서 오는 지속적인 근육의 결림, 불편함을 느끼는 원인을 분석하고 바른자세 교육과 운동으로 균형잡힌 신체를 유지할 수 있도록 합니다. ",
          contents: [
            {
              title: '허리가 편해지는 롤다운 운동',
              thumbnailUrl: A_1_8
            },
            {
              title: '낙상방지에 좋은 종아리와 발목 스트레칭',
              thumbnailUrl: A_1_7
            },
            {
              title: '햄스트링 유연성에 좋은 운동',
              thumbnailUrl: A_1_11
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        }
      ],
      progress: { my: 25, program: 75 },
      week: "1",
      progressStatus: "notProgress",
      icon: testIcon,
      currentStatus: "0/3",
      centerBannerColor: "#697889",
      centerBannerImg: IMG_HOMECARE_SINGLE_BANNER_ERICA
    },
    {
      title: "ERIKA BLOOM",
      subTitle: "다양한 프로그램을 연구하여 차별화된 시스템을 제공하는 필라테스 센터",
      logoSmall: guideImage,
      logoLarge: dummyLogo_03,
      tags: ["구독중", "NEW", "유료"],
      introduction: "지속적인 신체의 불편함을 해소하고, 바른 자세로 이끌어 드림으로써, 균형 잡힌 신체와 아름다운 바디라인을 만들어 드립니다. 지속적인 신체의 불편함을 해소하고, 바른 자세로 이끌어 드림으로써, 균형 잡힌 신체와 아름다운 바디라인을 만들어 드립니다.",
      features: ["필라테스", "전신집중"],
      composition: [
        "시니어 건강 증진 활력 프로젝트",
        "오늘도 건강하게 스트레칭",
      ],
      programList: [
        {
          programTitle: "스마트 시니어를 위한 건강 예방 프로그램",
          programIntroduction: "약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다.",
          contents: [
            {
              title: '어깨가 시원해지는 운동',
              thumbnailUrl: TITLE_C_4_3_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_1_10_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_5_12_A
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        },
        {
          programTitle: "바른 체형 교정 프로그램",
          programIntroduction: "잘못된 생활습관에서 오는 지속적인 근육의 결림, 불편함을 느끼는 원인을 분석하고 바른자세 교육과 운동으로 균형잡힌 신체를 유지할 수 있도록 합니다. ",
          contents: [
            {
              title: '허리가 편해지는 롤다운 운동',
              thumbnailUrl: A_1_8
            },
            {
              title: '낙상방지에 좋은 종아리와 발목 스트레칭',
              thumbnailUrl: A_1_7
            },
            {
              title: '햄스트링 유연성에 좋은 운동',
              thumbnailUrl: A_1_11
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        }
      ],
      progress: { my: 25, program: 75 },
      week: "1",
      progressStatus: "inProgress",
      icon: testIcon,
      currentStatus: "2/3",
      centerBannerColor: "#697889",
      centerBannerImg: IMG_HOMECARE_SINGLE_BANNER_ERICA
    },
    {
      title: "제이엔에스 바디플로우",
      subTitle: "힘의 진화 풀에디션 : 근력 증진을 위한 특별한 운동 프로그램",
      logoSmall: dummyLogo_01,
      logoLarge: dummyLogo_01,
      tags: ["구독중", "NEW", "유료"],
      introduction: "남녀노소 불문하고 함께 하셨던 많은 분들이 놀라운 속도로 건강하게 변화되는 모습을 보았습니다. 1:1 레슨으로 진행되는 맞춤식 프로그램으로 최소한의 시간과 노력으로 최대의 효과를 경험 할 수 있습니다.",
      features: ["필라테스", "전신집중"],
      composition: [
        "시니어 건강 증진 활력 프로젝트",
        "오늘도 건강하게 스트레칭",
      ],
      programList: [
        {
          programTitle: "스마트 시니어를 위한 건강 예방 프로그램",
          programIntroduction: "약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다.",
          contents: [
            {
              title: '어깨가 시원해지는 운동',
              thumbnailUrl: TITLE_C_4_3_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_1_10_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_5_12_A
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        },
        {
          programTitle: "바른 체형 교정 프로그램",
          programIntroduction: "잘못된 생활습관에서 오는 지속적인 근육의 결림, 불편함을 느끼는 원인을 분석하고 바른자세 교육과 운동으로 균형잡힌 신체를 유지할 수 있도록 합니다. ",
          contents: [
            {
              title: '허리가 편해지는 롤다운 운동',
              thumbnailUrl: A_1_8
            },
            {
              title: '낙상방지에 좋은 종아리와 발목 스트레칭',
              thumbnailUrl: A_1_7
            },
            {
              title: '햄스트링 유연성에 좋은 운동',
              thumbnailUrl: A_1_11
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        }
      ],
      progress: { my: 75, program: 25 },
      week: "1",
      progressStatus: "inProgress",
      icon: testIcon,
      currentStatus: "2/3",
      centerBannerColor: "#5C7982",
      centerBannerImg: IMG_HOMECARE_SINGLE_BANNER
    },
    {
      title: "뉴욕 인 필라테스",
      subTitle: "힘의 진화 풀에디션 : 근력 증진을 위한 특별한 운동 프로그램",
      logoSmall: dummyLogo_02,
      logoLarge: dummyLogo_02,
      tags: ["구독중", "NEW"],
      introduction: "남녀노소 불문하고 함께 하셨던 많은 분들이 놀라운 속도로 건강하게 변화되는 모습을 보았습니다. 1:1 레슨으로 진행되는 맞춤식 프로그램으로 최소한의 시간과 노력으로 최대의 효과를 경험 할 수 있습니다.",
      features: ["필라테스", "전신집중"],
      composition: [
        "시니어 건강 증진 활력 프로젝트",
        "오늘도 건강하게 스트레칭",
      ],
      programList: [
        {
          programTitle: "스마트 시니어를 위한 건강 예방 프로그램",
          programIntroduction: "약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다.",
          contents: [
            {
              title: '어깨가 시원해지는 운동',
              thumbnailUrl: TITLE_C_4_3_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_1_10_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_5_12_A
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        },
        {
          programTitle: "바른 체형 교정 프로그램",
          programIntroduction: "잘못된 생활습관에서 오는 지속적인 근육의 결림, 불편함을 느끼는 원인을 분석하고 바른자세 교육과 운동으로 균형잡힌 신체를 유지할 수 있도록 합니다. ",
          contents: [
            {
              title: '허리가 편해지는 롤다운 운동',
              thumbnailUrl: A_1_8
            },
            {
              title: '낙상방지에 좋은 종아리와 발목 스트레칭',
              thumbnailUrl: A_1_7
            },
            {
              title: '햄스트링 유연성에 좋은 운동',
              thumbnailUrl: A_1_11
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        }
      ],
      progress: { my: 0, program: 100 },
      week: "1",
      progressStatus: "inProgress",
      icon: testIcon,
      currentStatus: "2/3",
      centerBannerColor: "#5C7982",
      centerBannerImg: IMG_HOMECARE_SINGLE_BANNER
    },
    {
      title: "JJ 인 필라테스",
      subTitle: "맞춤식 프로그램으로 각 회원의 건강상태 및 운동 목적에 따라 진행합니다",
      logoSmall: dummyLogo_01,
      logoLarge: dummyLogo_01,
      tags: ["NEW", "유료"],
      introduction: "남녀노소 불문하고 함께 하셨던 많은 분들이 놀라운 속도로 건강하게 변화되는 모습을 보았습니다. 1:1 레슨으로 진행되는 맞춤식 프로그램으로 최소한의 시간과 노력으로 최대의 효과를 경험 할 수 있습니다.",
      features: ["필라테스", "전신집중"],
      composition: [
        "시니어 건강 증진 활력 프로젝트",
        "오늘도 건강하게 스트레칭",
      ],
      programList: [
        {
          programTitle: "스마트 시니어를 위한 건강 예방 프로그램",
          programIntroduction: "약해진 근력과 기억력, 만성질환에 노출된 노인분들을 위한 기능 향상 및 회복 프로그램으로 구성되어 있습니다.",
          contents: [
            {
              title: '어깨가 시원해지는 운동',
              thumbnailUrl: TITLE_C_4_3_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_1_10_A
            },
            {
              title: '튼튼한 다리를 만드는 운동',
              thumbnailUrl: TITLE_C_5_12_A
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        },
        {
          programTitle: "바른 체형 교정 프로그램",
          programIntroduction: "잘못된 생활습관에서 오는 지속적인 근육의 결림, 불편함을 느끼는 원인을 분석하고 바른자세 교육과 운동으로 균형잡힌 신체를 유지할 수 있도록 합니다. ",
          contents: [
            {
              title: '허리가 편해지는 롤다운 운동',
              thumbnailUrl: A_1_8
            },
            {
              title: '낙상방지에 좋은 종아리와 발목 스트레칭',
              thumbnailUrl: A_1_7
            },
            {
              title: '햄스트링 유연성에 좋은 운동',
              thumbnailUrl: A_1_11
            }
          ],
          tag: 'NEW',
          programDetail: {
            update: "2023.12.11",
            features:  "스트레칭",
            age: "시니어",
            playTime: 30,
            level: "초급",
            strength: "중강도"
          },
          timesAweek: 3
        }
      ],
      progress: { my: 100, program: 0 },
      week: "1",
      progressStatus: "notProgress",
      icon: testIcon,
      currentStatus: "0/3",
      centerBannerColor: "#5C7982",
      centerBannerImg: IMG_HOMECARE_SINGLE_BANNER
    },
  ],
};

export const centerData = createSlice({
  name: "centerData",
  initialState,
  reducers: {
    setCenterData: (state, action) => {},
  },
});

export const { setCenterData } = centerData.actions;

export default centerData.reducer;
