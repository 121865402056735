import React from "react";
import { convertThemeColor } from "./convertThemeColor";

const MyIcon = ({ iconType = "normal" }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27 41C34.732 41 41 34.732 41 27C41 19.268 34.732 13 27 13C19.268 13 13 19.268 13 27C13 34.732 19.268 41 27 41ZM19.5 36.1315C19.5 36.2187 19.5082 36.3041 19.5237 36.387C21.5745 38.0225 24.1731 39 27 39C29.8269 39 32.4255 38.0225 34.4762 36.387C34.4918 36.3041 34.5 36.2187 34.5 36.1315V35.3967C34.5 32.1557 32.6326 29.7468 29.9782 28.7236C30.9346 27.9194 31.5606 26.7425 31.6336 25.4034L31.6337 25.4022C31.7686 22.8174 29.6563 20.5381 27.0729 20.5005L27.0728 20.5005C24.4728 20.463 22.3656 22.5609 22.3656 25.1534C22.3656 26.5896 23.0169 27.8679 24.0292 28.7207C21.3708 29.7423 19.5 32.1527 19.5 35.3967V36.1315Z"
        fill={convertThemeColor(iconType)}
      />
    </svg>
  );
};

export default MyIcon;
