import css from "./MainSection.module.less";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Job } from "@enact/core/util";
import classNames from "classnames/bind";
import Spritesheet from "react-responsive-spritesheet";
import Spottable from "@enact/spotlight/Spottable";
import {$L} from '../../../utils/helperMethods';
import * as Config from '../../../utils/Config';
import { getSectionsData } from "./Constants";
import gamerSprites from "../../../../assets/inHomeOutdoorActivity/8frame_sec/gamer_sprite_image.png";

const SpottableComponent = Spottable('div');

/**
 *
 * @param {type} 'walking' , 'hiking', 'trainingCoaching', 'golfCoaching'
 * @returns
 */

const spriteData = {
  sprite: {
    image: gamerSprites,
    widthFrame: 356,
    heightFrame: 398,
    steps: 54,
    fps: 8,
  },
};

let SectionsData = null;
const MainSection = ({ type, daysAgo, onClick, onFocus, index }) => {
    const spriteRef = useRef(null);
    const { userNumber } = useSelector((state) => state.common.appStatus);
    const [isMounted, setIsMounted] = useState(false);
    const [focused, setFocused] = useState(false);
    const [pressed, setPressed] = useState(false);
  const [spriteInstance, setSpriteInstance] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isSpriteFocused, setSpriteFocused] = useState(false);

  const onPause = useCallback(() => {
    setIsPaused(true);
  }, []);
  const getInstance = useCallback((instance) => {
    setSpriteInstance(instance);
  }, []);
    if (!SectionsData) {
      SectionsData = getSectionsData();
    }
    useEffect(()=>{
        setIsMounted(true);
        return ()=>{
            setIsMounted(false);
        }
    },[]);

    const clearPressedJob = useRef(new Job((func) => {
        setPressed(false);
        setTimeout(func, Config.BUTTON_PRESS_DELAY/2);
      }, Config.BUTTON_PRESS_DELAY));

    const sectionData = useMemo(()=>{
        return SectionsData[type];
    },[type]);

    const style = useMemo(()=>{
        if(sectionData.sprite){
            const scaleNormal = sectionData.sprite.displayHeight/sectionData.sprite.heightFrame;
            const scaleupCalculated = scaleNormal * sectionData.sprite.scaleUp;
            return {
                transform: focused ? `translate3d(0px, 0px, 0px) scale(${scaleupCalculated})`
                : `translate3d(0px, 0px, 0px) scale(${scaleNormal})`
            };
        }else{
            return {};
        }
    },[focused, sectionData]);

	const _onClick = useCallback((ev)=>{
        if(onFocus){
            onFocus(ev);
        }
        setPressed(true);
        clearPressedJob.current.start(()=>{
            if(onClick){
                onClick(ev)
            }
        });
	},[onClick, onFocus]);
    const _onFocus = useCallback((ev)=>{
        setFocused(true);
        if(onFocus){
            onFocus(ev);
        }
	},[onFocus]);

    const onBlur = useCallback((ev)=>{
        setFocused(false);
        setPressed(false);
        clearPressedJob.current.stop();
	},[sectionData]);

  useEffect(() => {
    if (spriteRef.current) {
      if (spriteInstance && !spriteInstance.getInfo("isPlaying")) {
        if (isSpriteFocused) {
          spriteRef.current.pause();
          spriteRef.current.setDirection("forward");
          spriteRef.current.setStartAt(0);
          spriteRef.current.frame = 0;
          spriteRef.current.init();
          spriteRef.current.play();
        }
      }
    }
  }, [isSpriteFocused]);

    const userStatus = useMemo(() => {
        if (userNumber) {
            // loginNoData
            // login
        } else {
            return 'logout';
        }
        return 'default';
    }, [userNumber]);

    const daysAgoText = useMemo(()=>{
        if (daysAgo === 0) {
          return $L("Today");
        } else if (daysAgo === 1) {
          return $L("{num} Day ago").replace('{num}', daysAgo);;
        } else if (daysAgo > 1){
          return $L("{num} Days ago").replace('{num}', daysAgo);
        } else {
          return null
        }
    },[daysAgo]);
  useEffect(() => {
    if (spriteInstance) {
      spriteInstance.setDirection("forward");
      spriteInstance.goToAndPause(0);
      spriteInstance.goToAndPlay(1);
    }
    return () => spriteInstance?.pause();
  }, [spriteInstance]);

  return (
    <>
      <SpottableComponent
        className={classNames(css.dummyBox)}
        data-webos-voice-intent={"Select"}
        data-webos-voice-label={null}
        onBlur={onBlur}
        onFocus={_onFocus}
        onClick={_onClick}
      >
        <div className={classNames(css.main, css[type])}>
          <div className={css.sectionText}>{sectionData.sectionTitle}</div>
          <div className={css.subTitle}>
            {sectionData.subTitle[userStatus][focused ? "focused" : "normal"]}
          </div>
          {/* {sectionData.sprite && (
            <div className={css.imageContainer} style={style}>
              <Spritesheet
                className={css.spriteCommon}
                ref={spriteRef}
                image={sectionData.sprite.image}
                widthFrame={sectionData.sprite.widthFrame}
                heightFrame={sectionData.sprite.heightFrame}
                steps={sectionData.sprite.steps}
                fps={sectionData.sprite.fps}
                autoplay={false}
              />
            </div>
          )} */}
          {daysAgoText && <div className={css.label}>{daysAgoText}</div>}
          <div className={css.prepare} />
          {pressed && <div className={css.pressed}></div>}
        </div>
      </SpottableComponent>
      {index === 0 && (
        <div className={css.imageContainer}>
          <Spritesheet
            image={spriteData.sprite.image}
            ref={spriteRef}
            widthFrame={spriteData.sprite.widthFrame}
            heightFrame={spriteData.sprite.heightFrame}
            steps={spriteData.sprite.steps}
            fps={spriteData.sprite.fps}
            autoplay={false}
            onPause={onPause}
            getInstance={getInstance}
            onMouseEnter={() => setSpriteFocused(true)}
            onMouseLeave={() => setSpriteFocused(false)}
          />
        </div>
      )}
    </>
  );
};
export default MainSection;