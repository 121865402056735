import css from './StoryBookPanel.module.less';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/THeader';
import VirtualList from '@enact/sandstone/VirtualList';
import Item from '@enact/sandstone/Item';
import ri from '@enact/ui/resolution';
import TButtonLayout from './DetailLayout/TButtonLayout';
import TIconButtonLayout from './DetailLayout/TIconButtonLayout';
import TCheckboxItemLayout from './DetailLayout/TCheckboxItemLayout';
import TCalendarLayout from './DetailLayout/TCalendarLayout';
import TBody from '../../../components/TBody/TBody';
import TPopUpLayout from './DetailLayout/TPopUpLayout';
import TDropDownLayout from './DetailLayout/TDropDownLayout';
import TSimpleDatePickerLayout from './DetailLayout/TSimpleDatePickerLayout';
import TBarGraphLayout from './DetailLayout/TBarGraphLayout';
import TMediaListLayout from './DetailLayout/TMediaListLayout';
import JMediaListLayout from './DetailLayout/JMediaListLayout';
import TSimpleButtonTabLayout from './DetailLayout/TSimpleButtonTabLayout';
import TAccordionLayout from './DetailLayout/TAccordionLayout';
import TSettingSectionLayout from './DetailLayout/TSettingSectionLayout';
import TStepperLayout from './DetailLayout/TStepperLayout';
import TStepperTestLayout from './DetailLayout/TStepperTestLayout';
import TPage from './DetailLayout/TPage';
import TMediaVerticalListLayout from './DetailLayout/TMediaVerticalListLayout';
import TAlarmPickerLayout from './DetailLayout/TAlarmPickerLayout';
import TVirtualSpotContainerLayout from './DetailLayout/TVirtualSpotContainerLayout';
import TToastLayout from './DetailLayout/TToastLayout';
import TToolTipLayout from './DetailLayout/TToolTipLayout';
import TGGraphLayout from './DetailLayout/TGGraphLayout';
import TRadarChartLayout from './DetailLayout/TRadarChartLayout';
import TSummaryLayout from './DetailLayout/TSummaryLayout';
import TTimeLineLayout from './DetailLayout/TTimeLineLayout';
import TSliderLayout from './DetailLayout/TSliderLayout';
import TTrackLayout from './DetailLayout/TTrackLayout';
import TLineChartLayout from './DetailLayout/TLineChartLayout';
import TNudeTabLayout from "./DetailLayout/TNudeTabLayout";
import TInputLayout from './DetailLayout/TInputLayout';
import TScrollLayout from './DetailLayout/TScrollLayout';

const LISTITEM = [
  { title: 'TPage', component: TPage },
  { title: 'TPopUp', component: TPopUpLayout },
  { title: 'TDropDown', component: TDropDownLayout },
  { title: "TNudeTab", component: TNudeTabLayout },
  { title: 'TButton', component: TButtonLayout },
  { title: 'TIconButtonLayout', component: TIconButtonLayout },
  { title: 'TCheckboxItem', component: TCheckboxItemLayout },
  { title: 'TCalendar', component: TCalendarLayout },
  { title: 'TSimpleDatePicker', component: TSimpleDatePickerLayout },
  { title: 'TAccordion', component: TAccordionLayout },
  { title: 'TBarGraph', component: TBarGraphLayout },
  { title: 'TGGraph', component: TGGraphLayout },
  { title: 'TRadarChart', component: TRadarChartLayout },
  { title: 'TLineChart', component: TLineChartLayout },
  { title: 'TMediaList', component: TMediaListLayout },
  { title: 'TMediaVerticalList', component: TMediaVerticalListLayout },
  { title: 'JMediaList', component: JMediaListLayout },
  { title: 'TSimpleButtonTabLayout', component: TSimpleButtonTabLayout },
  { title: 'TSettingSectionLayout', component: TSettingSectionLayout },
  { title: 'TStepperLayout', component: TStepperLayout },
  { title: 'TStepperTestLayout', component: TStepperTestLayout },
  { title: 'TAlarmPicker', component: TAlarmPickerLayout },
  { title: 'TVirtualSpotContainerLayout', component: TVirtualSpotContainerLayout },
  { title: 'TToast', component: TToastLayout },
  { title: 'TToolTip', component: TToolTipLayout },
  { title: 'TSummary', component: TSummaryLayout },
  { title: 'TTimeLineLayout', component: TTimeLineLayout },
  { title: 'TSlider', component: TSliderLayout },
  { title: 'TTrackLayout', component: TTrackLayout },
  { title: 'TInput', component: TInputLayout },
  { title: 'TScroll', component: TScrollLayout },
];
const StoryBookPanel = ({ ...rest }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const listItemClick = useCallback(
    (index) => () => {
      setSelectedIndex(index);
    },
    [],
  );

  const listRenderer = useCallback(
    ({ index }) => {
      // console.log('listRenderer', index, ri.scale(52));
      return (
        <Item className={css.item} onClick={listItemClick(index)}>
          {LISTITEM[index].title}
        </Item>
      );
    },
    [listItemClick],
  );

  const DetailLayout = useMemo(() => {
    return LISTITEM[selectedIndex].component;
  }, [selectedIndex]);

  return (
    <TPanel {...rest}>
      <THeader title={'StoryBook Panel'} />
      <TBody>
        <div className={css.body}>
          <VirtualList
            className={css.bookList}
            itemSize={ri.scale(104)}
            dataSize={LISTITEM.length}
            spacing={0}
            itemRenderer={listRenderer}
          />
          <div className={css.detailLayout}>
            <DetailLayout />
          </div>
        </div>
      </TBody>
    </TPanel>
  );
};

export default StoryBookPanel;
