import css from "./MainSection.module.less";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import Spritesheet from "react-responsive-spritesheet";
import {$L} from '../../../utils/helperMethods';
import bodyAlignmentSprite from "../../../../assets/mainSections/bodyAlignment/10frame_sec/body-alignment-sprites.png";
import bodyBalanceSprite from "../../../../assets/mainSections/bodyBalance/10frame_sec/body-balance-sprites.png";
import physicalTestSprites from "../../../../assets/mainSections/physicalTest/10frame_sec/physical-test-sprites.png";
import ROM_NOR_BG_01 from "../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_01_nor.png";
import ROM_NOR_BG_02 from "../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_02_nor.png";
import ROM_NOR_BG_03 from "../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_03_nor.png";
import ROM_PRE_BG_01 from "../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_01_pre.png";
import ROM_PRE_BG_02 from "../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_02_pre.png";
import ROM_PRE_BG_03 from "../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_03_pre.png";

const SectionsData = () => ({
    "bodyalignment":
    {
      sprite: {
        image: bodyAlignmentSprite,
        scaleUp: 1.3,
        widthFrame: 280,
        heightFrame: 700,
        displayHeight: 550,//css @BODYALIGNMENT_ANISIZE
        steps: 40,
        fps: 10,
      },
      sectionTitle: $L("Body Alignment")
    },
    "rom":
    {
      norImage: [ROM_NOR_BG_01, ROM_NOR_BG_02, ROM_NOR_BG_03],
      preImage: [ROM_PRE_BG_01, ROM_PRE_BG_02, ROM_PRE_BG_03],
      sectionTitle: $L("Range Of Motion"),
    },
    "bodybalance":
    {
        sprite: {
            image: bodyBalanceSprite,
            scaleUp: 1.0,
            widthFrame: 434,
            heightFrame: 366,
            displayHeight: 366,//css @BODYBALANCE_ANISIZE
            steps: 32,
            fps: 10,
        },

      sectionTitle: $L("Body Balance"),
    },
    "physicaltest":
    {
        sprite: {
            image: physicalTestSprites,
            scaleUp: 1.0,
            widthFrame: 434,
            heightFrame: 366,
            displayHeight: 366, //css @PHYSICALTEST_ANISIZE
            steps: 17,
            fps: 10,
        },
      sectionTitle: $L("Physical Test"),
    }
});

/**
 *
 * @param {type} 'bodyalignment' , 'rom', 'bodybalance', 'physicaltest'
 * @returns
 */
const MainSection = ({type, daysAgo = 0, focused=false, pressed=false, imageIndex})=>{
    const spriteRef = useRef(null);
    const mainRef = useRef(null);
    const [isMounted, setIsMounted] = useState(false);
    const sectionData = useMemo(()=>{
        return SectionsData()[type];
    },[type]);

    const daysAgoText = useMemo(()=>{
      if (daysAgo === 0) {
        return $L("Today");
      } else if (daysAgo === 1) {
        return $L("{num} Day ago").replace('{num}', daysAgo);
      } else if (daysAgo > 1){
        return $L("{num} Days ago").replace('{num}', daysAgo);
      } else {
        return null
      }
    },[daysAgo]);

    const style = useMemo(()=>{
      if(sectionData.sprite){
          const scaleNormal = sectionData.sprite.displayHeight/sectionData.sprite.heightFrame;
          const scaleupCalculated = scaleNormal * sectionData.sprite.scaleUp;
          return {
              transform: focused ? `translate3d(0px, 0px, 0px) scale(${scaleupCalculated})`
              : `translate3d(0px, 0px, 0px) scale(${scaleNormal})`
          };
      }else{
          return {};
      }
    },[focused, sectionData]);

    const norImage = useMemo(()=>{
      if (sectionData && sectionData.norImage) {
        return sectionData.norImage[imageIndex];
      }
      return null
    },[sectionData, imageIndex]);

    const preImage = useMemo(()=>{
      if (sectionData && sectionData.preImage) {
        return sectionData.preImage[imageIndex];
      }
      return null
    },[sectionData, imageIndex]);

    useEffect(()=>{
        setIsMounted(true);
        return ()=>{
            setIsMounted(false);
        }
    },[]);

    useEffect(()=>{
        if(spriteRef.current){
            if(focused){
                spriteRef.current.pause();
                spriteRef.current.setDirection("forward");
                spriteRef.current.setStartAt(0);
                spriteRef.current.frame = 0;
                spriteRef.current.init();
                spriteRef.current.play();
            }else if(isMounted){
                spriteRef.current.pause();
                spriteRef.current.setStartAt(sectionData.sprite.steps-1);
                spriteRef.current.frame = sectionData.sprite.steps-1;
                spriteRef.current.setDirection("rewind");
                spriteRef.current.init();
                spriteRef.current.play();
            }
        }
    },[focused]);

    return (
        <div className={
          classNames(css.main, css[type], focused && css.focused, pressed && css.pressed)}
          ref={mainRef}
        >
            <img src={pressed ? preImage : norImage}/>
            {sectionData.sprite &&
              <div className={css.imageContainer} style={style}>
                <Spritesheet
                  className={css.spriteCommon}
                  ref={spriteRef}
                  image={sectionData.sprite.image}
                  widthFrame={sectionData.sprite.widthFrame}
                  heightFrame={sectionData.sprite.heightFrame}
                  steps={sectionData.sprite.steps}
                  fps={sectionData.sprite.fps}
                  autoplay={false}
                />
              </div>
            }
            <div className={type==='bodyalignment' ? css.bodyAlignmentText: css.sectionText}>
                <div className={css.title}>{sectionData.sectionTitle}</div>
                {daysAgoText && <div className={css.subtext}>{daysAgoText}</div>}
            </div>
            <div className={css.prepare}/>
        </div>
      );
}
export default MainSection;