import "animate.css";
import classNames from "classnames";
import css from "./BodyBalanceTest.module.less";
import Spotlight from "@enact/spotlight";
import { on, off } from "@enact/core/dispatcher";
import { useCallback, useState, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $L } from '../../../../utils/helperMethods';
import { Job } from "@enact/core/util";
import * as Config from "../../../../utils/Config";
import TShakaPlayer from "../../../../components/TShakaPlayer/TShakaPlayer";
import { updatePanel, popPanel, addPanels } from "../../../../features/panels/panelsSlice";
import { changeAppStatus } from "../../../../features/common/commonSlice";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import Cancelable from "@enact/ui/Cancelable";
import { setCurrentTestingName, clearCurrentTestingStatus, updateCurrentTestingStatus, clearMatStatusAndValue } from "../../../../features/bodyBalance/bodyBalanceSlice";
import useBodyBalance from "../../../../hooks/useBodyBalance";
import { BBT_TYPE, BBT_SEQUENCE, INTRO_INFO, INTRO_AVATAR, initAllBBTOnTime, initBBTOnTime, getBBT_INFO, getBBT_ONTIME } from "./Constants";
import WebWorkerUtil, { WORKER_ID } from "../../../../utils/WebWorker/WebWorkerUtil";
import { MAT_STATUS, changeMatStatus, startStopBleService, writeCharacteristicValue, setMatLedOn } from "../../../../features/ble/bleSlice";
import FootPositionCorrectionSign from "./FootPositionCorrectionSign";
import useBLE from "../../../../hooks/useBLE";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import TIconButton from "../../../../components/TIconButton/TIconButton";

// component
import TButton from "../../../../components/TButton/TButton";
import TStepper from "../../../../components/TStepper/TStepper";
import TToolTip, { POSITION, COLOR } from "../../../../components/TToolTip/TToolTip";
import TestSelectPopup from "../../../../components/TestSelectPopup/TestSelectPopup";
import TestPartCompletePopup from "../../../../components/TestPartCompletePopup/TestPartCompletePopup";
import TestEndCompletePopup from "../../../../components/TestEndCompletePopup/TestEndCompletePopup";
import TestGuideSignMsg from "../../../../components/TestGuideSignMsg/TestGuideSignMsg";
import FootPositionRenderer from "./FootPositionRenderer";
import * as TTSService from "../../../../lunaSend/TTSService";
import BodyCheckUpCountdownTimer from "../../BodyCheckUpCountdownTimer/BodyCheckUpCountdownTimer";
import TTestClosePopup from "../../../../components/TTestClosePopup/TTestClosePopup";

// image
import leftFoot from "../../../../../assets/bodyBalance/ic_foot_pixel_left_s.png";
import rightFoot from "../../../../../assets/bodyBalance/ic_foot_pixel_right_s.png";

const clearGuideTextJob = new Job((setGuideText, setVoiceGuide, voiceCode = "") => {
	setGuideText({ text: "" });
	voiceCode && setVoiceGuide(voiceCode);
}, 0);

const resumeTestJob = new Job((resumeTest) => {
	resumeTest();
}, 0);

const CancelableDiv = Cancelable({ modal: true, onCancel: 'handleCancel' }, 'div');
const ButtonContainer = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");

const DEFAULT_BBT_LIST = [
	BBT_TYPE.TWO_LEG_STANCE,
	BBT_TYPE.SINGLE_LEG_STANCE_L,
	BBT_TYPE.SINGLE_LEG_STANCE_R,
	// BBT_TYPE.WORK_MODE
];

let BBT_INFO = null;
let BBT_ONTIME = null;
const BodyBalanceTest = ({ panelInfo, ...rest }) => {
	if (!BBT_INFO) {
		BBT_INFO = getBBT_INFO();
	}
	if(!BBT_ONTIME) {
		BBT_ONTIME = getBBT_ONTIME();
	}
	const dispatch = useDispatch();
	const { bleConnectStatusRef } = useBLE({});
	const skipRef = useRef(null);
	const testEndRef = useRef(null);
	const guideStartdRef = useRef(null);
	const finishDelayRef = useRef(null);
	const videoProgressRef = useRef(0);
	const testingPauseRef = useRef(null);
	const PTVideoPlayerRef = useRef(null);
	const mountedTime = useRef(null);
	const { currentTestedData, saveTestResults } = useBodyBalance();
	const [voiceGuide, setVoiceGuide] = useState("");
	const [instruction, setInstruction] = useState("");
	const initialCheckedState = Array(BBT_INFO.length).fill(true);
	const bbtSequenceRef = useRef(-1);
	const [bbtSequence, setBbtSequence] = useState(-1);
	const [retry, setRetry] = useState(false);
	const [showTestPartComplete, setShowTestPartComplete] = useState(false);
	const [showTestEndComplete, setShowTestEndComplete] = useState(false);
	const [isTestingPaused, setIsTestingPaused] = useState(false);
	const [isCountDownStarted, setIsCountDownStarted] = useState(false);
	const [loopVoiceGuide, setLoopVoiceGuide] = useState("");
	const [avatarImage, setAvatarImage] = useState(null);
	const [currentBbtIndex, setCurrentBbtIndex] = useState(0);
	const [guideText, setGuideText] = useState({ img: null, text: "" });
	const { showLoadingPanel, language } = useSelector((state) => state.common.appStatus);
	const { skipVideoGuide, cesShowMode } = useSelector((state) => state.common.localSettings);
	const [countCompleted, setCountCompleted] = useState(false);
	const guideSkipRef = useRef(null)
	const [isTestClose, setTestClose] = useState(false); //test 중에 뒤로가기

	// Guide-test Title
	const renderGuideText = useCallback(() => {
		if (guideText.text) {
			return (
				<div>
					{bbtList.length !== 1 && bbtSequence !== BBT_SEQUENCE.TEST_START &&
						<div className={classNames(css.testNo, guideText.text === $L("Location guide") && css.noDisplay)}>{$L("Test").toUpperCase()} {currentBbtIndex + 1}</div>
					}
					<div className={css.guideText}>{guideText.text}</div>
				</div>
			)
		}
		return null;
	}, [guideText, currentBbtIndex, bbtSequence,]);

	const bbtList = useMemo(() => {
		if(cesShowMode){
			return [BBT_TYPE.TWO_LEG_STANCE];
		}
		if (panelInfo?.bbtList?.length === 1
			&& panelInfo.bbtList[0] === BBT_TYPE.SINGLE_LEG_STANCE_L) {
			return [BBT_TYPE.SINGLE_LEG_STANCE_L, BBT_TYPE.SINGLE_LEG_STANCE_R];
		}
		if (panelInfo?.bbtList) {
			return panelInfo?.bbtList;
		}
		return DEFAULT_BBT_LIST;
	}, [panelInfo]);

	// Stepper
	const order = useMemo(() => {
		const ret = [];
		ret.push($L('Start'));
		for (let i = 0; i < bbtList.length; i++) {
			ret.push(BBT_INFO[bbtList[i]].guideText[1]);
		}
		ret.push($L('Finished'));
		return ret;
	}, [bbtList]);

	// Actions based on time in a video.
	const doSequancialAction = useCallback((action, key) => {
		if (action && !action.excuted) {
			console.log('BodyBalance doSequancialAction[action,key,sftSequenceRef]:', action, key, bbtSequenceRef.current);
			action.excuted = true;
			if (typeof action.avatarImage !== "undefined") {
				setAvatarImage(action.avatarImage);
			}
			if (action.voiceGuide) {
				setVoiceGuide(action.voiceGuide);
			}
			if (typeof action.loopVoiceGuide !== "undefined") {
				setLoopVoiceGuide(action.loopVoiceGuide);
			}
			if (action.videoPause) {
				PTVideoPlayerRef.current.pause();
			}
			if (action.guideMsg) {
				setInstruction(action.guideMsg);
			}
			if (action.sequence) {
				setBbtSequence(action.sequence);
			}
		}
	}, []);

	const currentBbtOnTime = useMemo(() => {
		return BBT_ONTIME[bbtList[currentBbtIndex]] ? BBT_ONTIME[bbtList[currentBbtIndex]] : {};
	}, [currentBbtIndex, bbtList]);

	const matReportMessage = useMemo(() => {
		let { matStatus, matStatusValue } = currentTestedData;
		return { matStatus, matStatusValue };
	}, [currentTestedData]);

	const currentBBTInfo = useMemo(() => {
		if (bbtSequence === BBT_SEQUENCE.SEQUENCE_INIT) {
			return INTRO_INFO;
		}
		return BBT_INFO[bbtList[currentBbtIndex]] ? BBT_INFO[bbtList[currentBbtIndex]] : {};
	}, [currentBbtIndex, bbtList, bbtSequence]);


	const footPosition = useMemo(() => {
		let ret = {};
		ret = { left: currentTestedData[currentBBTInfo.resultName]?.left_foot_position, right: currentTestedData[currentBBTInfo.resultName]?.right_foot_position };

		// 왼발정상
		// [153, 34, 170, 272, 119, 102, 187, 34]
		// 오른발 정상
		// [476, 34, 510, 272, 476, 34, 544, 51]
		// 왼발 \
		// [68, 68, 221, 221, 51, 85, 255, 187]
		// 오른발 /
		// [527, 68, 425, 272, 408, 238, 578, 102]
		// 왼발 아래
		// [153, 272, 136, 323, 119, 306, 187, 289]
		// ret.left = [153, 272, 136, 323, 119, 306, 187, 289];
		// ret.right = [527, 68, 425, 272, 408, 238, 578, 102];
		return ret;
	}, [currentBBTInfo, currentTestedData]);

	const goNextTest = useCallback(() => {
		if (currentBBTInfo.type === BBT_TYPE.SINGLE_LEG_STANCE_L) {
			// test end.....
			setInstruction($L("We've completed the measurement of one foot. Now we'll measure the other foot."));
			setVoiceGuide("finishedLeft");
			testEndRef.current = setTimeout(() => {
				setBbtSequence(BBT_SEQUENCE.TEST_END);
				setVoiceGuide("");
			}, 2500);
		} else {
			setInstruction($L("Great job! You've completed scanning!"));
			setVoiceGuide("M10");
		}
	}, [currentBBTInfo]);

	const countdownCompleted = useCallback(() => {
		// console.log("countdownCompleted+sftSequence=", bbtSequence);
		setCountCompleted(true);
		if (matReportMessage?.matStatus === 'finished') {
			goNextTest();
			setCountCompleted(false);
		}
		// setBbtSequence(BBT_SEQUENCE.TEST_END);
		// dispatch(writeCharacteristicValue({mode: currentBBTInfo.bleMode, command: "scan"}));
	}, [dispatch, currentBBTInfo, matReportMessage?.matStatus, goNextTest]);

	const onResponseWorker = useCallback((e) => {
		console.log('BodyBalance onResponseWorker', e.type, e);
		if (e.type === 'analyzeTwoLegStance' || e.type === 'analyzeSingleLegStance') {
			if (e.value) {
				let engineValue = e.value;
				if (typeof e.value === 'string') {
					engineValue = JSON.parse(e.value);
				}
				dispatch(updateCurrentTestingStatus(engineValue));
			}
			setBbtSequence(BBT_SEQUENCE.TEST_END);
		}
	}, []);

	// init - makeWorker (init Engine Setting after Loading)
	useEffect(() => {
		// dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
		mountedTime.current = new Date();

		WebWorkerUtil.makeWorker(WORKER_ID.HIT, onResponseWorker);
		dispatch(clearCurrentTestingStatus());
		dispatch(setMatLedOn({}));
		setTimeout(() => {
			TTSService.stop();
		}, 1000);
		return () => {
			initAllBBTOnTime();
			dispatch(clearCurrentTestingStatus());
			dispatch(startStopBleService({ clientId: null, mode: currentBBTInfo?.bleMode, start: false }));
			dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
			dispatch(changeMatStatus(MAT_STATUS.PLAY));
		};
	}, []);

	useEffect(() => {
			Spotlight.focus("bodyBalanceTestPauseBtn");
			Spotlight.focus("bodyBalanceTestSkipBtn");
			Spotlight.focus("bodyBalanceGuideSkipBtn");
	}, [bbtSequence]);

	useEffect(() => {
		if (bleConnectStatusRef.current !== 'connected') {
			dispatch(popPanel(Config.panel_names.BODY_BALANCE_TEST));
		}

		if (bleConnectStatusRef.current === 'connected' && showLoadingPanel.show && bbtSequence < BBT_SEQUENCE.SEQUENCE_INIT) {
			setTimeout(() => {
				dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
			}, 0);
			setTimeout(() => {
				setBbtSequence(BBT_SEQUENCE.SEQUENCE_INIT);
				// delay: MIN_SHOWING_TIME+HIDING_TIME in loadingpanel
			}, (4000 - (new Date() - mountedTime.current)));
		}
	}, [bleConnectStatusRef, showLoadingPanel]);

	useEffect(() => {
		testingPauseRef.current = isTestingPaused;
		// console.log("Body Balance isTestingPaused ", isTestingPaused);
	}, [isTestingPaused]);

	useEffect(() => {
		bbtSequenceRef.current = bbtSequence;
		console.log("BodyBalance bbtSequence ", currentBBTInfo.resultName, bbtSequence);
		switch (bbtSequence) {
			case BBT_SEQUENCE.SEQUENCE_INIT: {
				dispatch(clearMatStatusAndValue());
				if (PTVideoPlayerRef.current && !showLoadingPanel.show) {
					if (skipVideoGuide) {
						onVideoEnd();
					} else {
						PTVideoPlayerRef.current?.play();
						// setGuideText({text: currentBBTInfo.guideText[0]});
						// clearGuideTextJob.startAfter(2500, setGuideText);
					}
					// guideStartdRef.current = setTimeout(()=>{
					//   setBbtSequence(BBT_SEQUENCE.GUIDE_START);
					// }, 3000);
				}
				break;
			}
			case BBT_SEQUENCE.GUIDE_START: {
				initBBTOnTime(bbtList[currentBbtIndex]);
				setIsCountDownStarted(false);
				if (skipVideoGuide) {
					onVideoEnd();
				} else {
					setGuideText({ text: currentBBTInfo.guideText[0] });
					clearGuideTextJob.startAfter(2500, setGuideText);
					if (PTVideoPlayerRef.current && PTVideoPlayerRef.current.paused()) {
						PTVideoPlayerRef.current.play();
					}
					setTimeout(() => {
							setGuideText({ text: currentBBTInfo?.guideText[1] });
							clearGuideTextJob.startAfter(2500, setGuideText);
						}, 2500);
					if (!currentBBTInfo.guideVideo) {
						if (currentBBTInfo.guideAudio) {
							setVoiceGuide(currentBBTInfo.guideAudio);
						} else {
							onVideoEnd();
						}
					}
				}
				break;
			}
			case BBT_SEQUENCE.GUIDE_END: {
				setBbtSequence(BBT_SEQUENCE.TEST_START);
			  // if (!isTestingPaused) {
			  //   setGuideText({img: currentBBTInfo.guideTextImg[1], text: currentBBTInfo.guideText[1]});
			  //   // clearGuideTextJob.startAfter(2500, setGuideText, setVoiceGuide, currentBBTInfo.preCountVoice);
			  //   clearGuideTextJob.startAfter(2500, setGuideText, setVoiceGuide, null);
			  //   WebWorkerUtil.postMessage(WORKER_ID.HIT, {type:"resetBalanceMode"}, true);
			  //   WebWorkerUtil.postMessage(WORKER_ID.HIT, {type:"initBalanceMode"}, true);
			  // }
			  break;
			}
			case BBT_SEQUENCE.TEST_START: {
				console.log('BBT_SEQUENCE.TEST_START');
				setIsCountDownStarted(false);
				dispatch(clearMatStatusAndValue());
				setVoiceGuide("");
				WebWorkerUtil.postMessage(WORKER_ID.HIT, { type: "resetBalanceMode" }, true);
				WebWorkerUtil.postMessage(WORKER_ID.HIT, { type: "initBalanceMode" }, true);
				dispatch(setCurrentTestingName(currentBBTInfo.resultName));
				dispatch(clearCurrentTestingStatus(currentBBTInfo.resultName));
				dispatch(changeMatStatus(MAT_STATUS.PLAY));
				dispatch(startStopBleService({ clientId: null, mode: currentBBTInfo?.bleMode, start: true }));
				setBbtSequence(BBT_SEQUENCE.TEST_POSINING);
				break;
			}
			case BBT_SEQUENCE.TEST_POSINING: {
				setIsCountDownStarted(false);
				setInstruction("");
				setVoiceGuide("");
				break;
			}
			case BBT_SEQUENCE.TEST_MATCHED: {
				// if(!cesShowMode){
					setLoopVoiceGuide("");
					setInstruction($L("Now, let's start the scanning process."));
					setVoiceGuide('M4');
					setTimeout(() => {
						setInstruction("");
					}, 3000);
				// }
				break;
			}
			case BBT_SEQUENCE.TEST_END: {
				dispatch(updateCurrentTestingStatus({ "left_foot_position": undefined, "right_foot_position": undefined }));
				// order stop
				// if(currentBBTInfo?.bleMode === 'bodycheck1'){
				//   dispatch(startStopBleService({clientId: null, mode: currentBBTInfo?.bleMode, start: false}));
				// }
				guideSkipRef.current = false;
				// final test
				if (currentBBTInfo?.bleMode !== 'bodycheck2Left' && currentBbtIndex + 1 >= bbtList.length) {
					setShowTestEndComplete(true);
					finishDelayRef.current = setTimeout(() => {
						setBbtSequence(BBT_SEQUENCE.SEQUENCE_FINISHED);
					}, 4800);
				} else {
					if (currentBBTInfo.type === BBT_TYPE.SINGLE_LEG_STANCE_L) {
						setCurrentBbtIndex(currentBbtIndex + 1);
						// setBbtSequence(BBT_SEQUENCE.SEQUENCE_INIT);
						setBbtSequence(BBT_SEQUENCE.GUIDE_START);
					} else {
						setShowTestPartComplete(true);
					}
				}
				break;
			}
			case BBT_SEQUENCE.SEQUENCE_FINISHED: {
				dispatch(setCurrentTestingName(""));
				saveTestResults();
				setTimeout(() => {
					dispatch(popPanel(Config.panel_names.BODY_BALANCE_DETAILS));
					dispatch(popPanel(Config.panel_names.BODY_BALANCE_TEST));
					if(cesShowMode){
						dispatch(addPanels({name : Config.panel_names.BODY_BALANCE_REPORT}));
					}
				}, 0);
				break;
			}
		}
	}, [bbtSequence, dispatch, isTestingPaused]);

	useEffect(() => {
		console.log('matReportMessage?.matStatus', matReportMessage?.matStatus);
		if (matReportMessage?.matStatus === 'not ready') {
			//for loop Guide
			setLoopVoiceGuide("SCH_BUTTON_10");
			setIsCountDownStarted(false);
			if (bbtSequenceRef.current === BBT_SEQUENCE.TEST_MATCHED) {
				setBbtSequence(BBT_SEQUENCE.TEST_POSINING);
			}
		}
		if (matReportMessage?.matStatus === 'ready') {
			setLoopVoiceGuide("");
		}
		if (matReportMessage?.matStatus === 'start') {
			if (bbtSequenceRef.current === BBT_SEQUENCE.TEST_POSINING) {
				setBbtSequence(BBT_SEQUENCE.TEST_MATCHED);
			}
		}
		if (matReportMessage?.matStatus === 'finished') {
			setLoopVoiceGuide("");
			if (countCompleted) {
				goNextTest();
			}
		}
	}, [matReportMessage?.matStatus]);

	const onVideoProgress = useCallback((ev) => {
		const current = ev;
		// trigger
		if (!PTVideoPlayerRef.current.paused()) {
			const keys = Object.keys(currentBbtOnTime);
			let matched = -1;
			for (let i = 0; i < keys.length; i++) {
				if (Number(current) >= Number(keys[i]) && !currentBbtOnTime[keys[i]].excuted) {
					matched = keys[i];
					break;
				}
			}
			const action = currentBbtOnTime[matched];
			doSequancialAction(action, matched);
		}
		videoProgressRef.current = current;
	}, [currentBbtOnTime, doSequancialAction]);

	const onVideoEnd = useCallback(() => {
		// console.log('onVideoEnd', bbtSequence, currentBbtOnTime);
		if (bbtSequence === BBT_SEQUENCE.SEQUENCE_INIT) {
			setBbtSequence(BBT_SEQUENCE.GUIDE_START);
			setAvatarImage(INTRO_AVATAR);
		} else {
			const action = currentBbtOnTime["end"];
			doSequancialAction(action, "end");
		}
	}, [bbtSequence, currentBbtOnTime, doSequancialAction]);

	const onVoiceGuideEnd = useCallback((ev) => {
		// console.log("Body Balance Test onVoiceGuideEnd", ev);
		if (ev === 'M4') { //측정하겠습니다.
			setIsCountDownStarted(true);
			dispatch(writeCharacteristicValue({ mode: currentBBTInfo.bleMode, command: "scan" }));
		}
	}, [currentBBTInfo]);

	// skip VideoGuide
	const skipGuideVideo = useCallback(() => {
		//  guide skip UI
		guideSkipRef.current = true;
		PTVideoPlayerRef.current.pause();
		setLoopVoiceGuide("");
		setVoiceGuide("");
		setGuideText({ text: "" });
		// if(currentBBTInfo.bleMode === 'bodycheck1'){
		//   PTVideoPlayerRef.current.seekTo(8.0);
		// }else{
		//   PTVideoPlayerRef.current.seekTo(11.0);
		// }
		onVideoEnd();
	}, [onVideoEnd]);

	const retryPreviousTest = useCallback(() => {
		let testIndex;
		onTestPause();
		PTVideoPlayerRef.current.seekTo(0);
		dispatch(changeMatStatus(MAT_STATUS.PLAY));
		testIndex = currentBbtIndex - 1;
		initAllBBTOnTime();
		setCurrentBbtIndex(testIndex);
		if (bbtSequence === BBT_SEQUENCE.GUIDE_START) {
			setBbtSequence(BBT_SEQUENCE.SEQUENCE_INIT);
		} else {
			setBbtSequence(BBT_SEQUENCE.GUIDE_START);
		}
	}, [currentBbtIndex, bbtList, bbtSequence]);

	const handleTestStart = useCallback(({ checked }) => {
		// console.log('BodyBalance handleTestStart bbtSequenceRef', bbtSequenceRef.current, checked)
		setRetry(false);
		setShowTestPartComplete(false);
		setShowTestEndComplete(false);
		if (!checked) {
			if (currentBbtIndex + 1 >= bbtList.length) {
				setBbtSequence(BBT_SEQUENCE.SEQUENCE_FINISHED);
			} else {
				let nextIndex = currentBbtIndex + 1;
				setCurrentBbtIndex(nextIndex);
				// setBbtSequence(BBT_SEQUENCE.SEQUENCE_INIT);
				setBbtSequence(BBT_SEQUENCE.GUIDE_START);
			}
		}
		if (checked) {
			dispatch(updatePanel({ name: Config.panel_names.BODY_BALANCE_TEST, panelInfo: { bbtList: checked } }));
			//action excuted initializing
			checked.forEach(element => {
				const keys = Object.keys(BBT_ONTIME[element]);
				for (let i = 0; i < keys.length; i++) {
					BBT_ONTIME[element][keys[i]].excuted = false;
				}
			});
			setCurrentBbtIndex(0);
			setBbtSequence(BBT_SEQUENCE.SEQUENCE_INIT);
		}
	}, [dispatch, currentBbtIndex, bbtList]);

	const skipTest = useCallback(() => {
		setGuideText({ text: "" });
		setLoopVoiceGuide("");
		setVoiceGuide("");
		if (currentBBTInfo.type === BBT_TYPE.SINGLE_LEG_STANCE_L) {
			setCurrentBbtIndex(currentBbtIndex + 1);
		}
		setBbtSequence(BBT_SEQUENCE.TEST_END);
	}, [bbtSequence, currentBBTInfo]);

	const retryTest = useCallback(() => {
		PTVideoPlayerRef.current.seekTo(0);
		let currenTestIndex = currentBbtIndex;
		setShowTestPartComplete(false);
		setCurrentBbtIndex(currenTestIndex);
		setBbtSequence(BBT_SEQUENCE.GUIDE_START);
	}, []);

	const retrySelectedTest = useCallback(() => {
		setShowTestPartComplete(false);
		setRetry(true);
		clearTimeout(finishDelayRef.current);
	}, []);

	const skipRenderer = useCallback((isTooltip) => {
		return (
			<TToolTip
				position={POSITION.top}
				color={COLOR.green}
				relativeRef={skipRef}
				relativePosition="left"
				isOpen={isTooltip}
			>
				{$L("Skip the guide video for the current body part.")}
			</TToolTip>
		)
	}, []);

	const seekToPrevious = useCallback((sec) => {
		const testOnTime = BBT_ONTIME[currentBbtIndex];
		const keys = Object.keys(BBT_ONTIME[currentBbtIndex]);
		for (let i = 0; i < keys.length; i++) {
			testOnTime[keys[i]].excuted = false;
		}

		if (guideSkipRef.current) {
			setBbtSequence(BBT_SEQUENCE.TEST_START);
			skipGuideVideo();
		} else {
			setBbtSequence(BBT_SEQUENCE.SEQUENCE_INIT);
			PTVideoPlayerRef.current.seekTo(sec);
		}
	}, [currentBbtIndex]);

	const onTestPause = useCallback((e) => {
		if (PTVideoPlayerRef.current) {
			PTVideoPlayerRef.current.pause();
		}
		dispatch(changeMatStatus(MAT_STATUS.PAUSE));
		setRetry(false);
		setShowTestPartComplete(false);
		setShowTestEndComplete(false);
		setGuideText({ text: "" });
		setLoopVoiceGuide("");
		setVoiceGuide("");
		setIsCountDownStarted(false);
		setAvatarImage("");
		setInstruction("");
		setCountCompleted(false);
		clearTimeout(testEndRef.current);
		testEndRef.current = null;
		clearTimeout(guideStartdRef.current);
		guideStartdRef.current = null;
		clearTimeout(finishDelayRef.current);
		finishDelayRef.current = null;

		if (bbtList?.length > 1 && bbtList?.length - currentBbtIndex === 1) setCurrentBbtIndex(currentBbtIndex - 1);
	}, [dispatch, currentBbtIndex, bbtList]);

	const resumeTest = useCallback(() => {
		Spotlight.focus("#shakaPlayer");
		seekToPrevious(0.5);
		dispatch(changeMatStatus(MAT_STATUS.PLAY));
	}, [dispatch]);

	const onTestResume = useCallback(() => {
		resumeTestJob.startAfter(500, resumeTest);
	}, [resumeTestJob]);

	const backKeyHandler = useCallback((ev) => {
		dispatch(popPanel());
		if (ev) {
			ev?.stopPropagation();
			ev?.preventDefault();
		}
	},[dispatch]);

	const pauseHandler = useCallback((ev) => {
		if (ev) ev.stopPropagation();
		if (isTestClose) {
			if (PTVideoPlayerRef) {
				onTestResume();
			}
			setTestClose(false);
		} else {
			if (bbtSequence !== BBT_SEQUENCE.SEQUENCE_INIT
				&& bbtSequence !== BBT_SEQUENCE.SEQUENCE_FINISHED
				&& !isCountDownStarted
			) {
				setTestClose(true);
				onTestPause();
			} else return;
		}
	}, [dispatch, bbtSequence, isCountDownStarted, isTestClose]);

	const onClickTestClose = useCallback((index) => {
		if (!index) {
			onTestResume();
		}
		setTestClose(false);
	}, []);

	const nextStep = useCallback(() => {
		let nextIndex = currentBbtIndex + 1;
		setShowTestPartComplete(false);
		setCurrentBbtIndex(nextIndex);
		setBbtSequence(BBT_SEQUENCE.GUIDE_START);
	}, [currentBbtIndex]);

	const stepperNumber = useMemo(() => {
		if (bbtSequence === BBT_SEQUENCE.SEQUENCE_INIT) {
			return 0;
		}
		return bbtSequence.current === BBT_SEQUENCE.SEQUENCE_FINISHED ? order.length - 1 : currentBbtIndex + 1;
	}, [bbtSequence, order, currentBbtIndex]);

	/** 수동 scan (cesMode) */
	// const handleScan = useCallback(()=>{
	// 	console.log('handleScan1111111', bbtSequence, matReportMessage?.matStatus)
	// 	if((bbtSequence === BBT_SEQUENCE.TEST_POSINING || bbtSequence === BBT_SEQUENCE.TEST_MATCHED) && matReportMessage?.matStatus === 'start'){
	// 		console.log('handleScan22222222', bbtSequence, matReportMessage?.matStatus)
	// 		setLoopVoiceGuide("");
	// 		setInstruction($L("Now, let's start the scanning process."));
	// 		setVoiceGuide('M4');
	// 		setTimeout(() => {
	// 			setInstruction("");
	// 		}, 3000);
	// 	}
	// },[bbtSequence, matReportMessage]);

	/** ces SCAN_KEY */
	// const handleKeydown = useCallback((ev)=>{
	// 	const keyCode = ev.keyCode;
	// 	const SCAN_KEY = 403;
	// 	if (keyCode === SCAN_KEY && (bbtSequence === BBT_SEQUENCE.TEST_POSINING || bbtSequence === BBT_SEQUENCE.TEST_MATCHED) && matReportMessage?.matStatus === 'start') {
	// 		setLoopVoiceGuide("");
	// 		setInstruction($L("Now, let's start the scanning process."));
	// 		setVoiceGuide('M4');
	// 		setTimeout(() => {
	// 			setInstruction("");
	// 		}, 3000);
	// 	};
	// },[bbtSequence, matReportMessage])

	// useEffect(() => {
  //   on("keydown", handleKeydown);
  //   return () => {
  //     off("keydown", handleKeydown);
  //   };
  // }, [handleKeydown]);

	return (
		<CancelableDiv className={css.container} handleCancel={backKeyHandler}>

			{/* Stepper */}
			{bbtList.length !== 1 &&
				<TStepper className={css.stepper} order={order} number={stepperNumber} type={"horizontal"} />
			}
			{/* guide-test title */}
			<div className={classNames(css.workoutTitleContainer,
				"animate__animated",
				{
					animate__fadeIn: (guideText.img || guideText.text),
					animate__fadeOut: !(guideText.img || guideText.text)
				})}>
				{renderGuideText()}
			</div>

			{(instruction || voiceGuide) && !isTestClose &&
				<TestGuideSignMsg message={instruction} voice={voiceGuide} onEnded={onVoiceGuideEnd} />
			}
			{/* foot Position Correction UI */}
			{bbtSequence >= BBT_SEQUENCE.TEST_POSINING && bbtSequence < BBT_SEQUENCE.TEST_END && !isTestClose &&
				<FootPositionCorrectionSign matReportMessage={matReportMessage} twoLegType={currentBBTInfo.type === BBT_TYPE.TWO_LEG_STANCE} onEnded={onVoiceGuideEnd} />
			}
			{/* 3,2,1 */}
			<BodyCheckUpCountdownTimer
				isCountDownStarted={isCountDownStarted}
				countdownCompleted={countdownCompleted}
			/>
			<section>

				{/* left - TshakaPlayer */}
				<div className={css.avatar}>
					<TShakaPlayer
						playerRef={PTVideoPlayerRef}
						className={css["shaka-player"]}
						src={currentBBTInfo.guideVideo}
						selectedAudioTrack={language==='ko'?0:1}
						onProgress={onVideoProgress}
						onEnded={onVideoEnd}
					// muted={true}
					// /poster={currentBBTInfo.poster}
					/>
					{!showLoadingPanel.show && (
						<img className={css.avatarImg} src={avatarImage} style={{ opacity: avatarImage ? "1.0" : "0" }} alt={"videoPlay"} />
					)}
					{/* mini foot Position Guide UI */}
					<div className={bbtSequence === BBT_SEQUENCE.SEQUENCE_INIT ? css.miniFootCorrectionSign : css.matchedMiniFootSign}>
						{(currentBBTInfo.bleMode === 'bodycheck1' || currentBBTInfo.bleMode === 'bodycheck2Left') &&
							<img className={css.leftFoot} src={leftFoot} alt={"leftFoot"} />
						}
						{(currentBBTInfo.bleMode === 'bodycheck1' || currentBBTInfo.bleMode === 'bodycheck2Right') &&
							<img className={css.rightFoot} src={rightFoot} alt={"rightFoot"} />
						}
					</div>
					{/* position Left - Right */}
					<div className={css.labelContainer}>
						<div className={classNames(css.label)}>{"L"}</div>
						<div className={classNames(css.label, css.isRight)}>{"R"}</div>
					</div>
				</div>

				{/* right- User Data */}
				<div className={css.user}>
					{/* foot renderer */}
					<FootPositionRenderer
						matReportMessage={matReportMessage}
						testType={currentBBTInfo.bleMode}
						feedbackEnable={bbtSequence >= BBT_SEQUENCE.TEST_POSINING && bbtSequence < BBT_SEQUENCE.TEST_END}
						footPosition={footPosition}
						showScanner={isCountDownStarted}
					/>
				</div>
			</section>

			<ButtonContainer className={classNames(showLoadingPanel.show && css.hide)}>
				{/* retry previous Test */}
				{currentBbtIndex !== 0 && bbtSequence !== BBT_SEQUENCE.SEQUENCE_INIT && !showTestPartComplete &&
					<div className={css.retryTestContainer}>
						<TButton
							className={css.retryBtn}
							spotlightId="bodyBalanceRetryPreviousTest"
							onClick={retryPreviousTest}
						>
							{$L("Repeat the previous test")}
						</TButton>
					</div>
				}
				{/* guide skip Btn */}
				{!isCountDownStarted && (bbtSequence === BBT_SEQUENCE.GUIDE_START) && !isTestClose &&
					<div className={classNames(css.videoSkip, currentBbtIndex === 0 && css.changePosition)} ref={skipRef}>
						<TButton className={css.skipBtn} spotlightId="bodyBalanceGuideSkipBtn" size="small" itemRenderer={skipRenderer} onClick={skipGuideVideo}>
							{$L("Skip guide")}<span />
						</TButton>
					</div>
				}

				{/* scan Btn (수동 - cesShowMode) */}
				{/* {cesShowMode &&
					<div className={css.testSkip}>
            <TButton
              className={css.skipBtn}
              spotlightId="bodyBalanceMesuring"
              size="small"
              onClick={handleScan}
            >
              {$L("Scan")}
            </TButton>
					</div>
				} */}

				{/* pause Btn */}
				{(bbtSequence !== BBT_SEQUENCE.SEQUENCE_INIT
					&& bbtSequence !== BBT_SEQUENCE.SEQUENCE_FINISHED
					&& !isCountDownStarted && !showTestPartComplete) &&
					<TIconButton
						className={classNames(bbtSequence === BBT_SEQUENCE.GUIDE_START || cesShowMode ? css.pauseBtn : css.movePosition)}
						iconType="testPause"
						spotlightId="bodyBalanceTestPauseBtn"
						onClick={pauseHandler}
					/>
				}
				{/* test skip Btn */}
				{!cesShowMode && !showTestPartComplete && !isCountDownStarted && bbtSequence >= BBT_SEQUENCE.TEST_START && !isTestClose &&
					<div className={css.testSkip}>
						<TButton className={css.skipBtn} spotlightId="bodyBalanceTestSkipBtn" size="small" onClick={skipTest}>
							{$L("Skip the test")}<span />
						</TButton>
					</div>
				}
			</ButtonContainer>

			{/* loopVoiceGuide */}
			{bbtSequence === BBT_SEQUENCE.TEST_POSINING && loopVoiceGuide && <AudioPlayer srcTypeStr={loopVoiceGuide} loop />}

			{/* testPartComplete */}
			{showTestPartComplete && !isTestClose &&
				<TestPartCompletePopup
					currentTestTitle={BBT_INFO[bbtList[currentBbtIndex + 1]].guideText[1]}
					retryPreviousTest={retryTest}
					retry
					// onClose={handleTestStart}
					onClose={nextStep}
				/>
			}

			{/* testSelectPopup */}
			{retry &&
				<TestSelectPopup testInfos={BBT_INFO} initialCheckedState={initialCheckedState} onClose={handleTestStart} />
			}

			{/* after final test -> TestEndComplete popup */}
			{showTestEndComplete && bbtSequence >= BBT_SEQUENCE.TEST_END && currentBbtIndex + 1 >= bbtList.length && //전체 테스트 중 마지막일때
				<TestEndCompletePopup
					open
					retryPreviousTest={retrySelectedTest}
					onClose={handleTestStart}
					testType={bbtList.length === 1 || bbtList.length === 2 ? "single" : "total"}
				/>
			}
			{/* test Close Popup */}
			{isTestClose && <TTestClosePopup onClick={onClickTestClose} />}
		</CancelableDiv>
	)
};
export default BodyBalanceTest;