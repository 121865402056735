import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import css from "./BleTest.module.less";
import { useDispatch, useSelector } from 'react-redux';
import TPanel from "../../components/TPanel/TPanel";
import THeader from "../../components/THeader";
import TBody from "../../components/TBody/TBody";
import TButton from "../../components/TButton/TButton";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import { scan, gattConnect, startStopBleService, gattDisconnect, gattBatteryMonitor, getDevice, gattBatteryNotification } from "../../features/ble/bleSlice";
import WebWorkerUtil, {WORKER_ID} from "../../utils/WebWorker/WebWorkerUtil";
import TDropdown from "../../components/TDropDown/TDropDown";

import ShakaPlayer from "../../components/TShakaPlayer/ShakaPlayer";
import useBLE from "../../hooks/useBLE";
import muxjs from 'mux.js';

if(typeof window === 'object') window.muxjs = muxjs;

const Container = SpotlightContainerDecorator({enterTo: 'null'}, 'div');

const audioSrc = "https://dash.akamaized.net/akamai/bbb_30fps/bbb_a64k.mpd";

const VIDEO_SOURCE = [
  {id:"running2", src: "https://streamingtest-bucket.s3.ap-northeast-2.amazonaws.com/hls/running_video_only_output.m3u8"},
  {id: "mountain", src: "https://d1up91cavfzvg.cloudfront.net/vod/eedbec9d-c818-4cd9-bf83-1ba4d09c4c80/9c7c6390548b465fb9975ff798069425.m3u8"},
  {id: "walk_normal", src: "https://d1up91cavfzvg.cloudfront.net/vod/e9fb51d5-309c-46e7-96fb-02941e347813/c707303b1b904c13a2cb5763b3428062.m3u8"},
  {id: "running", src: "https://d1up91cavfzvg.cloudfront.net/vod/be14f16b-e289-4e45-b785-2d6ac05778dc/123c706eac224480840c23d6a7b64efc.m3u8"},
];

const dropDownMenu = ["running2", 'mountain', 'walk_normal', 'running'];

const BleTest = ({...rest}) => {
  const dispatch = useDispatch();
  const {bleDeviceList} = useBLE({autoConnect: true});
  const gattConnectingStatus = useSelector(state => state.ble.gattConnectingStatus);
  const batteryValue = useSelector(state => state.ble.batteryStatus);
  const matBytes = useSelector(state =>state.ble.matBytes);
  const [walkOutput, setWalkOutput] = useState({validHit: false, left: false, right: false, rate: '', velocity: '', totalHit: ''});
  const [hitEngineVersion, setHitEngineVersion] = useState('');
  const mHitWorker = useRef(null);
  const playerRef = useRef(null);
  // const playerRef2 = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);


  const onSelect = useCallback(({ selected }) => {
    setVideoIndex(selected);
  }, []);

  const PLAYER_CONFIG = {
    streaming: {
      bufferingGoal: 180,
      rebufferingGoal: 5,
    },
    manifest: {
      disableAudio: true
    }
  };

  // const PLAYER_CONFIG2 = {
  //   streaming: {
  //     bufferingGoal: 180,
  //     rebufferingGoal: 5,
  //   },
  //   manifest: {
  //     disableAudio: false
  //   }
  // };

  const onVideoLoadedData = useCallback(() => {
    console.log('ShakaPlayerTest onloadeddata');
  }, []);

  const onPlay = useCallback(() => {
    playerRef.current.videoElement.play();
    // playerRef2.current.videoElement.play();
  }, []);

  const onPause = useCallback(() => {
    playerRef.current.videoElement.pause();
    // playerRef2.current.videoElement.pause();
  }, [])

  const nextStep = useCallback(() => {
    setVideoIndex((prevIndex) => (prevIndex + 1) % VIDEO_SOURCE.length);
  }, []);

	const seekTo = useCallback(() => {
		playerRef.current.videoElement.currentTime = 0;
		// playerRef2.current.videoElement.currentTime = 0;
	}, []);

  const handlePlaybackRate = useCallback((rate) => {
    if (rate <= 0) {
      console.log("[skkim] pause video. because rate is ", rate);
      playerRef.current.videoElement.pause();
      return;
    }
    let newRate = Math.min(rate, 2);
    let prevRate = playerRef.current.player.getPlaybackRate();
    if (newRate !== prevRate) {
      console.log(`[skkim] set PlayBackRate: ${prevRate} to ${newRate}`);
      playerRef.current.videoElement.playbackRate = rate;
    } else {
      console.log("[skkim] keep cur rate", prevRate);
    }
    if (playerRef.current.videoElement.paused) playerRef.current.videoElement.play();
  }, []);

  ////////////////////////////////////////////////

  const searchBleDevices = useCallback(() => {
    // dispatch(startDiscovery());
    dispatch(scan());
  }, [dispatch]);

  // useEffect(() => {
  //   console.log('bleDeviceList', bleDeviceList, gattConnectingStatus);
  // }, [bleDeviceList, gattConnectingStatus]);

  const connect = useCallback((address) => () => {
    console.log('connect - invalid address', address);
    dispatch(gattConnect(address));
  }, [dispatch])

  const getData = useCallback((clientId) => () => {
    console.log('invalid clientId', clientId);
    dispatch(startStopBleService({clientId, mode:"walkmode", start: true}));
  }, [dispatch]);

  const gattBatteryNotiOn = useCallback((clientId) => () => {
    console.log('gattBatteryNotiOn', clientId);
    dispatch(gattBatteryNotification(clientId));
  }, [dispatch]);

  const infos = useMemo(() => {
    let v = [];
    v.push({ title: 'HitEngineVersion', value: hitEngineVersion });
    v.push({ title: 'ValidHit', value: walkOutput.validHit });
    v.push({ title: 'Left', value: walkOutput.left });
    v.push({ title: 'Right', value: walkOutput.right });
    v.push({ title: 'Rate', value: walkOutput.rate });
    v.push({ title: 'Velocity', value: walkOutput.velocity });
    v.push({ title: 'TotalHit', value: walkOutput.totalHit });
    v.push({ title: 'BatteryInfo', value: batteryValue });
    return v;
  }, [hitEngineVersion, walkOutput, batteryValue]);

  const onResponseHitWorker = useCallback((e) => {
    console.log('onResponseHitWorker', e);
    if (e.type === 'response') {
      setWalkOutput({ validHit: e.value.validHit, left: e.value.left, right: e.value.right, rate: e.value.rate, velocity: e.value.velocity, totalHit: e.value.totalHit });
      console.log('onResponseHitWorker, getWalkOutputData', e.value);
      handlePlaybackRate(e.value.rate);
    } else if (e.type === 'getVersion') {
      setHitEngineVersion(e.value);
    }
  }, []);

  useEffect(() => {
    return () => {
      WebWorkerUtil.terminate(mHitWorker.current);
      // cancelGattMonitorCharacteristics();
      dispatch(startStopBleService({clientId:null, mode:"walkmode", start: false}));
    }
  }, []);

  const setWorkModeParams = useCallback((index)=>{
    if(index===0){
      WebWorkerUtil.postMessage(mHitWorker.current, { type: "resetWalkMode" }, true);
      WebWorkerUtil.postMessage(mHitWorker.current, { type: "setWalkModeParams", value: [70, 4] }, true);
    }else if(index === 1){
      WebWorkerUtil.postMessage(mHitWorker.current, { type: "resetWalkMode" }, true);
      WebWorkerUtil.postMessage(mHitWorker.current, { type: "setWalkModeParams", value: [70, 2] }, true);
    }else {
      WebWorkerUtil.postMessage(mHitWorker.current, { type: "resetWalkMode" }, true);
      WebWorkerUtil.postMessage(mHitWorker.current, { type: "setWalkModeParams", value: [70, 7] }, true);
    }
  },[]);

  useEffect(() => {
    if(!mHitWorker.current){
      WebWorkerUtil.makeWorker(WORKER_ID.HIT, onResponseHitWorker, true).then((workerId) => {
        mHitWorker.current = workerId;
        WebWorkerUtil.postMessage(mHitWorker.current, { type: "getVersion" }, true);
        WebWorkerUtil.postMessage(mHitWorker.current, { type: "initWalkMode", value: [2, 1] }, true);
        setWorkModeParams(videoIndex);
      });
    }else{
      setWorkModeParams(videoIndex);
    }
    playerRef.current.videoElement.currentTime = 0;
    // playerRef2.current.videoElement.currentTime = 0;
    let videoOptions = {};
    videoOptions.htmlMediaOption = {};
    videoOptions.htmlMediaOption.useMediaPlayerManager = false;
    videoOptions.option = {};
    videoOptions.option.avSink = {};
    videoOptions.option.avSink.audioSink = {};
    playerRef.current.videoElement.mediaOption = JSON.stringify(videoOptions);
    // let audioOptions = {};
    // audioOptions.htmlMediaOption = {};
    // audioOptions.htmlMediaOption.useMediaPlayerManager = false;
    // audioOptions.option = {};
    // audioOptions.option.avSink = {};
    // audioOptions.option.avSink.videoSink = {};
    // playerRef2.current.videoElement.mediaOption = JSON.stringify(audioOptions);
  }, [videoIndex]);

  const onVideoSetting = useCallback((idx) => () => {
    setVideoIndex(idx);
  },[]);

  const videoSrc = useMemo(()=>{
    return VIDEO_SOURCE[videoIndex].src;
  },[videoIndex]);

  const bleDisconnect = useCallback((clientId) => ()=>{
    dispatch(gattDisconnect(clientId));
    console.log('ble disconnect');
  },[gattConnectingStatus.clientId]);


  return (
    <TPanel {...rest}>
      <THeader title={"Ble Test(산책)"} />
      <TBody>
        <Container>
          <div className={css.bleTestFlex}>
            <div className={css.bleTestFlexItem}>
              <TButton onClick={searchBleDevices}>Ble Scan</TButton>
              {gattConnectingStatus.connectStatus === 'connected' &&
                <TButton onClick={bleDisconnect(gattConnectingStatus.clientId)}>Ble Disconnect</TButton>
              }
              <div>
                {bleDeviceList.map((list, idx) => {
                  return (
                    <TButton withMarquee className={css.bleList} key={idx} onClick={connect(list.address)}>
                      Connect : {list.name}
                    </TButton>
                  )
                })
                }
                {gattConnectingStatus.clientId !==1 && gattConnectingStatus.clientId &&
                  <TButton withMarquee className={css.bleList} onClick={getData(gattConnectingStatus.clientId)}>
                    MonitorCharactoristic
                  </TButton>
                }
                {gattConnectingStatus.clientId !==1 && gattConnectingStatus.clientId &&
                  <TButton withMarquee className={css.bleList} onClick={gattBatteryNotiOn(gattConnectingStatus.clientId)}>
                    BatteryMonitor
                  </TButton>
                }
              </div>
              {gattConnectingStatus.clientId !==1 && gattConnectingStatus.clientId ?
                <div className={css.connectSuccess}>Connect Success : clientID({gattConnectingStatus.clientId})</div>
                :
                gattConnectingStatus.clientId === 0 ?
                  <div className={css.connectFailure}>Connect Failure</div>
                  :
                  null
              }
              {matBytes && matBytes.length > 1 && (
                <div className={css.matData}>
                  <div>MonitorData</div>
                  <div className={css.byteData}>
                    {matBytes.map((item, index) => (
                      <div key={index}>{(index!==0 ? ', 0x':'0x')+item?.toString(16)}</div>
                    ))}
                  </div>
                </div>
              )}
              {matBytes && matBytes.length > 1 && infos.map((item, index) => {
                return (
                  <div className={css.infoBox} key={index}>
                    <div className={css.text}>{item.title} : {item.value.toString()}</div>
                  </div>
                )
              }
              )}
            </div>
            <div className={css.playerBox}>
              <ShakaPlayer
                ref={playerRef}
                className={css["shaka-player"]}
                src={videoSrc}
                config={PLAYER_CONFIG}
                chromeless
                style={{ zIndex: -1 }}
                width="100%"
                height="100%"
                autoPlay={false}
                controls={false}
                muted={false}
                loop={false}
                onLoadedData={onVideoLoadedData}
              />
              {/* <ShakaPlayer
                ref={playerRef2}
                // className={css["shaka-player"]}
                src={audioSrc}
                config={PLAYER_CONFIG2}
                chromeless
                style={{ zIndex: -1 }}
                width="500px"
                height="50px"
                autoPlay={false}
                controls={true}
                muted={false}
                loop={false}
                onLoadedData={onVideoLoadedData}
              /> */}
              <div className={css.videoSelect}>
                {/* {VIDEO_SOURCE.map((list, idx) => {
                  return (
                    <TButton key={idx} onClick={onVideoSetting(idx)}>
                      {list.id}
                    </TButton>
                  )
                })
                } */}
                {dropDownMenu.length > 0 && (
                  <TDropdown
                    className={css.tDropDown}
                    placeholder="영상 선택"
                    direction="below"
                    size="small"
                    width="small"
                    selected={videoIndex}
                    onSelect={onSelect}
                  >
                    {dropDownMenu}
                  </TDropdown>
                )}
              </div>
              <div className={css.buttonContainer}>
                <TButton onClick={onPlay}>play</TButton>
                <TButton onClick={onPause}>pause</TButton>
                <TButton onClick={nextStep}>next</TButton>
                <TButton onClick={seekTo}>seek0</TButton>
              </div>
            </div>
          </div>
        </Container>
      </TBody>
    </TPanel>
  );
};

export default BleTest;
