import React, { useMemo } from "react";
import classNames from "classnames";
import css from "./GaitResults.module.less";
import OverallGaitLeft from "../../../../../../assets/bodyBalance/overall_gait_left.png";
import OverallGaitRight from "../../../../../../assets/bodyBalance/overall_gait_right.png";
import { $L } from "../../../../../utils/helperMethods";

const GaitResults = ({ ...rest }) => {

  return (
    <div className={css.gaitResultsSect}>
      <div className={classNames(css.gaitFootDirection, css.left)}>L</div>
      <div className={classNames(css.gaitFootDirection, css.right)}>R</div>
      <div className={css.gaitImgWrap}>
        <img src={OverallGaitLeft} alt="" />
        <img src={OverallGaitRight} alt="" />
      </div>
      <div className={classNames(css.gaitInfoWrap, css.pronate)}>
        {$L("Adduction")}
      </div>
      <div className={classNames(css.gaitInfoWrap, css.supinate)}>
        {$L("Abduction")}
      </div>
    </div>
  );
};

export default GaitResults;