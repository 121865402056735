import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import TPopUp from '../../components/TPopUp/TPopUp';
import { $L, convertNewlinesToBr } from '../../utils/helperMethods';
import GuideImage from "../../../assets/testPopup/img_popup_camera_connect.png";

const CameraPopup = ({onClose}) => {
  const { cameraList } = useSelector(state => state.camera);

  const _onClose = useCallback(()=>{
    if(onClose){
      onClose();
    }
  },[]);

  return (
    <>
    {(cameraList && cameraList.length < 1) &&
      <TPopUp
        kind="smartMatPopUp"
        title={$L("How to connect your camera")}
        text={$L("Connect LG Smart TV dedicated camera to the USB port.")}
        text1={convertNewlinesToBr($L("Once your camera is connected, you can use measurement/test and My Workout Programs. {br} We can also recommend personalized workouts."))}
        image={GuideImage}
        button1text={$L("Verified")}
        onClose={_onClose}
      />

    }
    </>
  )
};

export default CameraPopup;
