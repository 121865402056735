import LS2Request from '../lunaSend/LS2Request';

export const lunaTest = (service, method, subscribe, parameters) => {
	const onSuccess = (res) => {
		console.log('LunaTest onSuccess ',service, method, res);
	}
	const onFailure = (res) => {
		console.log('LunaTest onFailure ',service, method, res);
	}
	const onComplete = (res) => {
		console.log('LunaTest onComplete ',service, method, res);
	}
	return new LS2Request().send({
		service: service,
		method: method,
		subscribe: subscribe,
		parameters:parameters,
		onSuccess:onSuccess,
		onFailure:onFailure,
		onComplete: onComplete
	})
}