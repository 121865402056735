import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { $L } from '../../../utils/helperMethods';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import css from "./SubLicense.module.less";
import SubBody from '../SubBody';
import LicenseDetail from "./LicenseDetail";
import dummyLicense from '../../../../assets/mock/dummyLicense.json';

const SpottableComponent = Spottable('div');

const LicenseItem = ({ idx, onClick, title, desc, date, ...rest }) => {
  return (
    <SpottableComponent
      {...rest}
      className={css.item}
      onClick={onClick}
    >
      <div className={css.marqueeWrap}>{$L("Open Source License")}</div>
    </SpottableComponent>
  );
}

const SubLicense = ({ className, inline, spotlightId, onClick, index, ...rest }) => {
  const scrollTo = useRef(null);

  const [licensePopupDetail, setlicensePopupDetail] = useState(false);
	const [popupIndex, setPopupIndex] = useState(null);

  useEffect(()=>{
    if (scrollTo && scrollTo.current) {
      scrollTo.current({position: {y: 0}, animate: false});
    }
  }, [index]);

  const licenseDetail = useCallback((i) => (ev) =>{
    console.log('licenseDetail', i);
    setPopupIndex(i);
    setlicensePopupDetail(true);
    ev.stopPropagation();
  }, [])

	const onClosePopup = useCallback(()=>{
    setlicensePopupDetail(false);
    Spotlight.focus('SubLicenseScroller');
	},[]);

  return (
    <SubBody className={css.body}>
      {dummyLicense.data.map((el, idx) => (
        <LicenseItem key={"licenseItem" + idx} onClick={licenseDetail(idx)} />
      ))}
      {licensePopupDetail &&
        <LicenseDetail index={popupIndex} onClose={onClosePopup}/>
      }
    </SubBody>
  );
};

export default SubLicense;