/**
 * TGGraph
 *
 * @module TGGraph
 */
import classNames from 'classnames';
import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import css from './TGGraph.module.less';

const toRadian = (d) => (d * Math.PI) / 180;
const easeInOut = (t) => t ** 2 / (t ** 2 - t + 1);

/**
 *
 * @param {string} className - A class name that the user wants to add.
 * @param {number} size - Specify the length of a side of a regular polygon in pixels.
 * @param {number} lineWidth - Specify the line thickness of the graph.
 * @param {number} primaryPercentage - Progress percentage of the first graph.
 * @param {number} primaryIncrement - Increment value per frame for the animation of the first graph.
 * @param {number?} secondaryPercentage - (optional) Progress percentage of the second graph.
 * @param {number?} secondaryIncrement - (optional) Increment value per frame for the animation of the second graph.
 * @param {number?} animationInterval - (optional) Time interval at which the animations of the first and second graphs start. (unit: ms)
 */
const TGGraph = ({
  className,
  size,
  lineWidth,
  primaryPercentage,
  primaryIncrement = 1,
  secondaryPercentage,
  secondaryIncrement = 1,
  animationInterval = 500,
  isDark = false,
  ...rest
}) => {
  // const { themeMode } = useSelector((state) => state.common.appStatus);

  const backgroundCanvasRef = useRef(null);
  const primaryProgressRef = useRef(null);
  const secondaryProgressRef = useRef(null);

  const primaryProgressAnimationRef = useRef({ _progress: 0, _ratio: 0, _id: null });
  const secondaryProgressAnimationRef = useRef({ _progress: 0, _ratio: 0, _id: null });

  const LINE_WIDTH = useMemo(() => lineWidth ?? Math.floor(size / 10), [size]);
  const RADIUS = useMemo(() => size / 2 - LINE_WIDTH, [size]);

  const CIRCLE_TO_TOTAL_LENGTH_RATIO = ((9 * Math.PI) / (9 * Math.PI + 2)) * 100;

  const BACKGROUND_COLOR = isDark ? "#494847" : "#d3cac2"; // FIXME: mode에 따른 색 변경 필요
  const PRIMARY_PROGRESS_COLOR = isDark ? "#B59D85CC" : "#B59D85CC"; // FIXME: mode에 따른 색 변경 필요
  const SECONDARY_PROGRESS_COLOR = isDark ? "#B59D85" : "#63544c"; // FIXME: mode에 따른 색 변경 필요

  const clearCanvas = useCallback(
    (ctx) => {
      if (ctx) ctx.clearRect(0, 0, size, size);
    },
    [size]
  );

  const clearCanvasAll = useCallback(
    (...contexts) => {
      contexts.forEach((ctx) => {
        if (ctx) ctx.clearRect(0, 0, size, size);
      });
    },
    [size]
  );

  const drawBackground = useCallback(
    (ctx) => {
      ctx.strokeStyle = BACKGROUND_COLOR;

      ctx.beginPath();
      ctx.arc(RADIUS + LINE_WIDTH, RADIUS + LINE_WIDTH, RADIUS + LINE_WIDTH / 2, toRadian(270), toRadian(0), true);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(size - LINE_WIDTH / 2, size / 2);
      ctx.lineTo(size * (5 / 6), size / 2);
      ctx.stroke();
    },
    [size]
  );

  const drawProgress = useCallback(
    ({ ctx, percentage, color }) => {
      // FIXME: 0미만 값, 100초과 값에 대한 처리 필요하다면 따로 해주기
      if (percentage <= 0 || percentage > 100) return;

      clearCanvas(ctx);

      ctx.strokeStyle = color;

      if (percentage >= CIRCLE_TO_TOTAL_LENGTH_RATIO) {
        const endXCoordinate =
          (Math.abs(size * (1 / 6) - LINE_WIDTH / 2) * (percentage - CIRCLE_TO_TOTAL_LENGTH_RATIO)) /
          (100 - CIRCLE_TO_TOTAL_LENGTH_RATIO);

        ctx.beginPath();
        ctx.arc(RADIUS + LINE_WIDTH, RADIUS + LINE_WIDTH, RADIUS + LINE_WIDTH / 2, toRadian(270), toRadian(0), true);
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(size - LINE_WIDTH / 2, size / 2);
        ctx.lineTo(size - LINE_WIDTH / 2 - endXCoordinate, size / 2);
        ctx.stroke();
      } else {
        const degree = (percentage * 3 * (2 + 9 * Math.PI)) / (10 * Math.PI);

        ctx.beginPath();
        ctx.arc(
          RADIUS + LINE_WIDTH,
          RADIUS + LINE_WIDTH,
          RADIUS + LINE_WIDTH / 2,
          toRadian(270),
          toRadian(270 - degree),
          true
        );
        ctx.stroke();
      }
    },
    [size]
  );

  const drawGraphWithAnimation = useCallback(
    ({ ctx, color, increment, ref, percentage }) => {
      const { current } = ref;

      if (current && current._progress >= percentage) {
        if (typeof window === 'object') {
          window.cancelAnimationFrame(current._id);
        }

        current._ratio = 0;
        current._progress = 0;
        current._id = null;

        drawProgress({ percentage, ctx, color });

        return;
      }

      drawProgress({ percentage: current._progress, ctx, color });
      current._ratio += increment / percentage;
      current._progress = easeInOut(current._ratio) * percentage;

      if (typeof window === 'object') {
        current._id = window.requestAnimationFrame(() => {
          drawGraphWithAnimation({ ctx, color, increment, ref, percentage });
        });
      }
    },
    [drawProgress]
  );

  useEffect(() => {
    const contexts = [];
    const timeoutIds = [];

    const backgroundCanvas = backgroundCanvasRef?.current;
    const primaryProgressCanvas = primaryProgressRef?.current;
    const secondaryProgressCanvas = secondaryProgressRef?.current;

    if (backgroundCanvas) {
      const context = backgroundCanvas.getContext('2d');
      context.lineWidth = LINE_WIDTH;
      context.lineCap = 'round';

      contexts.push(context);
      drawBackground(context);
    }

    if (primaryProgressCanvas) {
      const context = primaryProgressCanvas.getContext('2d');
      context.lineWidth = LINE_WIDTH;
      context.lineCap = 'round';

      contexts.push(context);
      drawGraphWithAnimation({
        ctx: context,
        color: secondaryPercentage ? PRIMARY_PROGRESS_COLOR : SECONDARY_PROGRESS_COLOR,
        increment: primaryIncrement,
        percentage: primaryPercentage,
        ref: primaryProgressAnimationRef,
      });
    }

    if (secondaryPercentage && secondaryProgressCanvas) {
      const context = secondaryProgressCanvas.getContext('2d');
      context.lineWidth = LINE_WIDTH;
      context.lineCap = 'round';

      contexts.push(context);
      timeoutIds.push(
        setTimeout(() => {
          drawGraphWithAnimation({
            ctx: context,
            color: SECONDARY_PROGRESS_COLOR,
            increment: secondaryIncrement,
            percentage: secondaryPercentage,
            ref: secondaryProgressAnimationRef,
          });
        }, animationInterval)
      );
    }

    return () => {
      clearCanvasAll(...contexts);
      timeoutIds.forEach((intervalId) => {
        clearTimeout(intervalId);
      });
    };
  }, [primaryPercentage, secondaryPercentage]);

  return (
    <div className={css.container} style={{ width: size, height: size }}>
      <canvas
        ref={backgroundCanvasRef}
        className={classNames(css.graph, className)}
        width={size}
        height={size}
        {...rest}
      />
      <canvas
        ref={primaryProgressRef}
        className={classNames(css.graph, className)}
        width={size}
        height={size}
        {...rest}
      />
      {secondaryPercentage ? (
        <canvas
          ref={secondaryProgressRef}
          className={classNames(css.graph, className)}
          width={size}
          height={size}
          {...rest}
        />
      ) : null}
    </div>
  );
};

export default TGGraph;
