import React, { useCallback } from "react";
import Alert from '@enact/sandstone/Alert';
import css from "./InHomeOutdoorMain.module.less";
import THeader from "../../components/THeader";

const IHOAPopupPanel = ({ open, onClose, children, noHeader, ...rest }) => {
  const _onClose = useCallback((ev) => {
    if(onClose) onClose(ev);
  }, [onClose]);

  return (
    <Alert type={"fullscreen"} open className={css.popupPanel} onClose={_onClose} {...rest}>
      {!noHeader && <THeader iconType="back" useTool={false}/>}
      <div className={css.container}>{children}</div>
    </Alert>
  );
};

export default IHOAPopupPanel;