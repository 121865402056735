import { useCallback } from 'react';
import TSettingSection from '../../../../components/TSettingSection/TSettingSection';
import { $L } from '../../../../utils/helperMethods';

const TSettingSectionLayout = () => {

	const onClick = useCallback((ev) => {
		if (ev === 0) {
			console.log("button1 click!");
		} else if (ev === 1) {
			console.log("button2 click!");
		}
	}, []);

	return (
		<>
			<TSettingSection
				text={$L("Set a routine alert")}
				subText={$L("You'll get a daily workout alert at a preset time notifying you to start working out.")}
				button1={{ text: $L("ON"), value: 0 }}
				button2={{ text: $L("OFF"), value: 1 }}
				tag={$L("Connecting...")}
				onClick={onClick}
			/>
			<TSettingSection
				type={'underLine'}
				text={$L("Set a routine alert")}
				subText={$L("You'll get a daily workout alert at a preset time notifying you to start working out.")}
				subText2={"3rd line..."}
				value="80cm"
				button1={{ text: $L("ON"), value: 0 }}
				button2={{ text: $L("OFF"), value: 1 }}
				tag={$L("Connecting...")}
				onClick={onClick}
			/>
		</>
	);
};

export default TSettingSectionLayout;
