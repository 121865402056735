import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {$L} from '../../../utils/helperMethods';
import TSettingSection from '../../../components/TSettingSection/TSettingSection';
import SmoodShowingAnimation from "../../../components/SmoodShowingAnimation";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { postSetting } from '../../../features/fitService/fitServiceSlice';
import RecommendedStride from './RecommendedStride';

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused' }, 'div');

const Stride = ({...rest}) => {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.fitService.settings);

  const SETTING_INFO = [
    {
      key: 'strideAutoYn',
      title: $L("Set stride length"),
      detail: $L("Fit Engine automatically sets the stride length calculated for walking and jogging."),
      button1: { text: $L("Auto"), value: true, disabled: true },
      button2: { text: $L("Manual"), value: false }
    },
    {
      visible: { key: 'strideAutoYn', value: false },
      key: 'stride',
      title: $L({
        key: "_RECOMMENDED_Set stride length",
        value: "Set stride length",
      }),
      detail: $L("Select the recommended stride length that best fits you."),
      itemRenderer: (props) => <RecommendedStride {...props}/>
    }
  ];

  const _onClick = useCallback((key) => (value) => {
    dispatch(postSetting({ [key]: value }));
  }, []);

  return (
    <div {...rest}>
      {SETTING_INFO.map((info, idx) => (info.visible && settings[info.visible.key] !== info.visible.value) ? null : <Container key={idx}>
        <TSettingSection
          type="underLine"
          spotlightId={`Stride_${info.key}`}
          text={info.title}
          subText={info.detail}
          button1={info.button1}
          button2={info.button2}
          value={settings[info.key]}
          onClick={_onClick(info.key)}
        />
        {info.itemRenderer && info.itemRenderer({ value: settings[info.key], onClick: _onClick(info.key) })}
      </Container>)}
    </div>
  );
};

export default SmoodShowingAnimation(Stride);
