import { $L } from "../../../../utils/helperMethods";

export const PHYSICAL_TYPE = {
  CARDIOPULMONARY_ENDURANCE: $L("Cardiopulmonary Endurance"),
  UPPER_EXTREMITY_FLEXIBILITY: $L("Upper Extremity Flexibility"),
  FLEXIBILITY_OF_THE_LOWER_EXTREMITIES: $L(
    "Flexibility Of The Lower Extremities"
  ),
  MUSCLE_STRENGTH_OF_THE_LOWER_EXTREMITIES: $L(
    "Muscle Strength Of The Lower Extremities"
  ),
  UPPER_LIMB_MUSCLE_STRENGTH: $L("Upper Limb Muscle Strength"),
  AGILITY_AND_MOTION_BALANCE: $L("Agility And Motion Balance"),
};

export const feedbackSFT = {
  S: {
    title: $L("You have an excellent {tabName} for your age group."),
    subtitle: $L(`Do you want to strengthen {tabName} even more by working out?`),
  },
  A: {
    title: $L("You have a good {tabName} for your age group."),
    subtitle: $L(`Do you want to strengthen {tabName} even more by working out?`),
  },
  B: {
    title: $L("You have an average {tabName} for your age group."),
    subtitle: $L(`Do you want to strengthen {tabName} even more by working out?`),
  },
  C: {
    title: $L("You have a very poor {tabName} for your age group."),
    subtitle: $L(`Do you want to strengthen {tabName} even more by working out?`),
  },
  D: {
    title: $L("You have a poor {tabName} for your age group."),
    subtitle: $L(`Do you want to strengthen {tabName} even more by working out?`),
  },
};

export const feedbackEmpty = {
  title: $L(
    "SFT (Senior Fitness Test)는 개인 신체능력을 나이별로{br}상/중/하로 구분하여 건강상태별 자체 진단 프로그램을 제공해요."
  ),
  desc1: $L(
    "SFT로 신체능력을 글로벌 기준에 맞춰서 평가받을 수 있으며,{br}평가항목에는 상체근력, 하체근력, 상체유연성, 하체유연성, 민첩성 등이 포함되요."
  ),
  desc2: $L(
    "같은 나이대 기준을 평가항목별로 나의 신체능력이 상위그룹에 위치하는지,{br}또는 하위그룹에 위치하는지 정보를 제공해주고, 자신의 신체능력에 맞는 운동을 추천받고, 상대적으로 부족한 신체능력을 보완할 수 있어요."
  ),
};

export const voiceGuideEmpty = () => {
  const feedback =
    feedbackEmpty.title + " " + feedbackEmpty.desc1 + " " + feedbackEmpty.desc2;
  return { feedbackVoice: feedback.replaceAll("{br}", "") };
};

export const voiceGuideOverall = (
  tabNames,
  lowestScore = 0,
  disabledArr = []
) => {
  if (disabledArr.length > 0) {
    return {
      feedbackVoice: $L(
        "아직 측정하지 못한 테스트가 있습니다. 신체 능력 점검을 위해 테스트를 마저 진행해주세요."
      ),
    };
  } else {
    if (lowestScore >= 95) {
      return {
        feedbackVoice: $L(
          "신체 능력이 매우 훌륭한 편입니다. 꾸준한 운동을 통해 현재 상태를 유지하세요."
        ),
      };
    } else {
      if (tabNames.length > 0) {
        const feedbackText = $L(
          "최근 고객님의 SFT 점수입니다. {tabName} 테스트가 가장 낮게 측정되었네요. {tabName}을 향상시킬 수 있는 운동을 진행하세요."
        );

        const transLateTabNames = tabNames
          .map((tabName) => $L(tabName))
          .join(tabNames.length >= 2 ? ", " : "");

        return {
          feedbackVoice: feedbackText.replaceAll(
            "{tabName}",
            transLateTabNames
          ),
        };
      }
    }
  }
};

export const voiceGuide = (score, tabName) => {
  let grade;
  if (score > 90) {
    grade = "S";
  } else if (score > 80) {
    grade = "A";
  } else if (score > 49) {
    grade = "B";
  } else if (score > 10) {
    grade = "C";
  } else if (score >= 0) {
    grade = "D";
  } else {
    return console.error(
      "feedback error: Score variable is not a valid value."
    );
  }

  const title = feedbackSFT[grade].title.replace("{tabName}", tabName);
  const subtitle = feedbackSFT[grade].subtitle.replace("{tabName}", tabName);

  const feedback = title + " " + subtitle;
  const feedbackVoice = feedback.replaceAll("<br />", "");

  return { title, subtitle, feedback, feedbackVoice };
};
