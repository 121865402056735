import css from './DetailLayout.module.less';
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import TButton from "../../../../components/TButton/TButton";
import { addPanels } from "../../../../features/panels/panelsSlice";
import * as Config from "../../../../utils/Config";
import { enumEventType, logger } from "../../../../utils/logger";

const TPage = ({...rest}) => {
  const dispatch = useDispatch();

  const onButtonClick = useCallback((item, index) => () => {
    dispatch(addPanels({ name: TPAGE[index].target }));
    logger(
      enumEventType.ON_CLICK,
      `TPage 컴포넌트의 ${item.title} 버튼을 클릭함`
    );
  }, [dispatch]);

  const TPAGE = [
    { title: "intro", target: Config.panel_names.INTRO_PANEL },
    { title: "Survey", target: Config.panel_names.SURVEY_PANEL },
    { title: "terms and Conditions", target: Config.panel_names.TERMS_PANEL },
  ];

  return (
    <div className={css.pageLayout}>
      {TPAGE.map((item, index) => {
        return (
          <TButton key={"btn" + index} onClick={onButtonClick(item, index)}>
            {item.title}
          </TButton>
        );
      })}
    </div>
  );
};

export default TPage;
