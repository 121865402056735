import { useCallback } from "react";
import TDropdown from "../../../../components/TDropDown/TDropDown";

const dropDownMenu = ['TEXTTEXT', 'TEXTTEXT', 'TEXTTEXT', 'TEXTTEXT', 'TEXTTEXT', 'TEXTTEXT', 'TEXTTEXT'];

const TDropDownLayout = () => {
	const onSelect = useCallback(({selected}) => {
			console.log(selected);
	}, []);

	

	return (
			<>
			{dropDownMenu.length > 0 &&
				<TDropdown
					placeholder="Select a category"
					onSelect={onSelect}
					direction="below"
					width={464} //ri.scale() size
				>
					{dropDownMenu}
				</TDropdown>
			}
			</>
	);
};

export default TDropDownLayout;
