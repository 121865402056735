import { createSlice } from "@reduxjs/toolkit";
// import * as Config from "../../utils/Config";

const notOnTv = (typeof window === 'object' && !window.PalmSystem);
import {
  localBBox,
  localBodyCoordinations,
  localBodyTestResult,
} from "../../utils/bodyDrawing/bodyCoordinations.js";

const initialState = {
  bodyDirection: "",
  imagePath: {
    front: "",
    side: "",
  },
  cameraSize: { width: null, height: null },
  audioPlayer: { isPlaying: false, file: "" },
  isLoading: false,
  bodyCoordinations: { front: null, side: null },
  //todo remove
  recalculatedTotalBodyCoordinations: notOnTv
    ? localBodyCoordinations
    : {
        front: null,
        side: null,
      },
  bodyTestResults: {
    front: null,
    side: null,
  },
  //todo remove
  recalculatedBodyTestResults: notOnTv ? localBodyTestResult
    : {
        front: null,
        side: null,
      },
  //todo remove
  recalculatedBBox: notOnTv ? localBBox
  : {
      front: null,
      side: null,
    },
  activatedCameraId: "",
  cameraList: [],
  updatingCameraList: false,
  maxCameraBpm: 0
};

export const camera = createSlice({
  name: "camera",
  initialState,
  reducers: {
    updateCameraList: (state, action) => {
      const cameraList = action.payload.cameraList;
      if(!cameraList || cameraList.length <= 0){
        state.activatedCameraId = "";
      }
      let found = false;
      for (let index in cameraList) {
        if(state.activatedCameraId === cameraList[index].deviceId){
          found = true;
        }
      }
      if(!found && cameraList?.[0]?.deviceId){
        state.activatedCameraId = cameraList[0].deviceId;
      }
      state.cameraList = cameraList;
    },
    changeActivatedCameraId:(state, action) => {
      state.activatedCameraId = action.payload;
    },
    changeUpdatingCameraList:(state, action) => {
      state.updatingCameraList = action.payload;
    },
    updateBodyDirection: (state, action) => {
      state.bodyDirection = action.payload;
    },
    updateCameraSize: (state, action) => {
      state.cameraSize = action.payload;
    },
    updateAudioPlayer: (state, action) => {
      state.audioPlayer = action.payload;
    },

    updateImagePath: (state, action) => {
      // state.imagePath[action.payload.type] = action.payload.data;
      state.imagePath = Object.assign({}, state.imagePath, {
        [action.payload.type]: action.payload.data,
      });
    },
    updateBodyCoordinations: (state, action) => {
      state.bodyCoordinations = Object.assign({}, state.bodyCoordinations, {
        [action.payload.type]: action.payload.data,
      });
    },
    updateMaxCameraBpm: (state, action) => {
      state.maxCameraBpm = action.payload;
    },
    resetCamera: () => initialState,
  }
});

export const {
  updateCameraList,
  changeActivatedCameraId,
  changeUpdatingCameraList,
  updateBodyDirection,
  updateCameraSize,
  updateAudioPlayer,
  updateBodyCoordinations,
  updateImagePath,
  updateMaxCameraBpm,
  resetCamera
} = camera.actions;

export default camera.reducer;
