import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import css from './RecommendedCourse.module.less';
import TButton, { SIZES } from '../../../../components/TButton/TButton';
import { $L } from '../../../../utils/helperMethods';
import CustomImage from '../../../../components/CustomImage/CustomImage';
import ic_inhome_activity_continuing_goal from '../../../../../assets/inHomeOutdoorActivity/ic_inhome_activity_continuing_goal.svg';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import { TRACK_TYPE } from '../../../../components/TTrack/TTrack';
import { Swiper, SwiperSlide } from "swiper/react";
import ri from '@enact/ui/resolution';
import img_track_cycloid_l from '../../../../../assets/inHomeOutdoorActivity/img_track_cycloid_l.svg';
import img_track_line_l from '../../../../../assets/inHomeOutdoorActivity/img_track_line_l.svg';
import img_track_height_l from '../../../../../assets/inHomeOutdoorActivity/img_track_height_l.svg';
import classNames from 'classnames';
import TIconButton, { ICON_TYPES } from '../../../../components/TIconButton/TIconButton';
import { useSelector } from 'react-redux';
import * as Config from '../../../../utils/Config';
import { Job } from '@enact/core/util';

const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);
const PaginationContainer = SpotlightContainerDecorator(
  { leaveFor: { left: '', right: '' }, enterTo: "default-element" },
  "div"
);

const TRACK_IMG = {
  [TRACK_TYPE.cycloid]: img_track_cycloid_l,
  [TRACK_TYPE.line]: img_track_line_l,
  [TRACK_TYPE.height]: img_track_height_l
}

const RecommendedCourse = ({ items, onClick, ...rest }) => {
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [bulletFocus, setBulletFocus] = useState(false);
  const { cursorVisible } = useSelector((state) => state.common.appStatus);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [autoScrollState, setAutoScrollState] = useState('');
  const autoScrollJob = useRef(new Job((func) => {
    if (func) func();
  }, Config.AUTO_SCROLL_DELAY));

  const _onClick = useCallback((item) => () => {
    if (onClick) onClick(item);
  }, [onClick]);

  // slide prev
  const _onSpotlightLeft = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  }, []);

  // slide next
  const _onSpotlightRight = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  }, []);

  const _onSlideChange = useCallback((ev) => {
    setActiveIndex(ev.activeIndex);
  }, []);

  const onFocusIndex = useCallback((idx) => () => {
    let nextIdx = idx;
    if (['left', 'right'].includes(idx)) {
      nextIdx = idx === 'left' ? Math.max(activeIndex - 1 , 0) : Math.min(activeIndex + 1, items.length - 1);
    }
    swiperRef.current.swiper.slideTo(nextIdx);
  }, [activeIndex, items]);

  const isCursorArrowVisible = useMemo(() => {
    return cursorVisible && isMouseOver;
  }, [isMouseOver, cursorVisible]);

  const setMouseOver = useCallback((b) => () => {
    setIsMouseOver(b);
  }, []);

  const doAutoScroll = useCallback((direction) => {
    autoScrollState && onFocusIndex(direction)();
  }, [autoScrollState, onFocusIndex]);

  const onScrollAreaOver = useCallback((direction) => () => {
    if (cursorVisible) {
      onFocusIndex(direction)();
      setAutoScrollState(direction);
    }
  }, [cursorVisible, onFocusIndex]);

  const onScrollAreaOut = useCallback(() => {
    setAutoScrollState('');
  }, []);

  useEffect(() => {
    if (!cursorVisible) return;
    if (autoScrollState === '') {
      autoScrollJob.current.stop();
    } else {
      autoScrollJob.current.start(() => doAutoScroll(autoScrollState));
    }
  }, [autoScrollState, cursorVisible, activeIndex]);

  return (
    <div className={css.recommendedCourseContainer}>
      <div className={css.recommendedCourseTitle}>{Array.isArray(items) && items[0] && items[0].progressDistance ? `${$L("Walking / Jogging")} ${$L("Continue")}` : `${$L("Walking / Jogging")} ${$L("Recommended courses")}`}</div>
      <Container onMouseOver={setMouseOver(true)} onMouseOut={setMouseOver(false)}>
        <Swiper
          ref={swiperRef}
          loop={false}
          className={css.swiperContainer}
          spaceBetween={ri.scale(22 * 2)}
          onSlideChange={_onSlideChange}
        >
          {isCursorArrowVisible && activeIndex > 0 && <div
            className={css.lButtonIcon}
            onMouseOver={onScrollAreaOver('left')}
            onMouseOut={onScrollAreaOut}
          />}
          {Array.isArray(items) && items.length > 0 && items.map((item, index) => <SwiperSlide key={`recommendCourse-${index}`}>
            <Container className={classNames(css.recommendedCourse, index !== activeIndex && css.dimmed)} spotlightDisabled={index !== activeIndex}>
              <div className={css.courseImage}>
                <CustomImage src={item.thumbnailUrl} className={css.img}/>
                {item.icons.isNew && <div className={css.new}>{$L("NEW")}</div>}
                <img src={TRACK_IMG[item.trackType]} className={css.course} />
                {item.progressDistance ? <div className={css.progressBar} style={{ '--progress': `${item.progressDistance / item.distance * 100}%` }}/> : null}
              </div>
              <div className={css.recommendedCourseInfo}>
                <div>
                  <div className={css.customContTit}>{$L(item.title)}</div>
                  <div className={css.courseStr} dangerouslySetInnerHTML={{ __html: $L(item.description) }} />
                  {item.progressDistance ? <div className={css.progressInfo}>
                    <img src={ic_inhome_activity_continuing_goal} className={css.stepImg}/>
                    <div className={css.progressDistance}>{item.progressDistance} Km<span> / {item.distance} Km</span></div>
                  </div> : null}
                </div>
                <div className={css.buttons}>
                  {item.currentPlayTime && <TButton size={SIZES.test} className={css.walkBtn} onClick={_onClick({ itemInfo: item })} onSpotlightLeft={_onSpotlightLeft}>{$L("Continue")}</TButton>}
                  <TButton size={SIZES.test} className={css.walkBtn} onClick={_onClick({ itemInfo: item, init: true })} onSpotlightLeft={!item.currentPlayTime ? _onSpotlightLeft : null} onSpotlightRight={_onSpotlightRight}>{item.currentPlayTime ? $L("Start from the beginning") : $L("Take a walk now")}</TButton>
                </div>
              </div>
            </Container>
          </SwiperSlide>)}
          {isCursorArrowVisible && activeIndex < items.length - 1 && <div
            className={css.rButtonIcon}
            onMouseOver={onScrollAreaOver('right')}
            onMouseOut={onScrollAreaOut}
          />}
        </Swiper>
      </Container>
      {Array.isArray(items) && items.length > 1 && <PaginationContainer className={css.paginationContainer} onFocus={() => setBulletFocus(true)} onBlur={() => setBulletFocus(false)}>
        <div className={classNames(css.pagination, bulletFocus && css.focus)}>
          {items.map((_, index) =>
            <TIconButton
              iconType={ICON_TYPES.none}
              key={`pagination-bullet-${index}`}
              className={classNames(css.bullet, index === activeIndex && css.active)}
              onSpotlightLeft={onFocusIndex(Math.max(index - 1 , 0))}
              onSpotlightRight={onFocusIndex(Math.min(index + 1, items.length - 1))}
              spotlightDisabled={index !== activeIndex}
            />
          )}
        </div>
      </PaginationContainer>}
    </div>
  );
};

export default RecommendedCourse;