import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { InputField } from "@enact/sandstone/Input";
import css from "./AccessRestrictionPanel.module.less";
import TButton, { SIZES } from "../../components/TButton";
import TPanel from "../../components/TPanel/TPanel";
import Spotlight from "@enact/spotlight";
import {
  changeAppStatus,
  changeThemeModeStatus,
} from "../../features/common/commonSlice";
import { $L } from "../../utils/helperMethods";
import TBody from "../../components/TBody/TBody";
import { ACCESS_CODE } from "../../utils/Config";

const MAX_CODE_LENGTH = 20;

const AccessRestrictionPanel = () => {
  const dispatch = useDispatch();
  const [accessCode, setAccessCode] = useState("");

  const onChangeAccessCode = useCallback((e) => {
    if (e.value && e.value.length > MAX_CODE_LENGTH) {
      setAccessCode(e.value.slice(0, MAX_CODE_LENGTH));
    } else {
      setAccessCode(e.value);
    }
  }, []);

  const callAccess = useCallback(() => {
    if (accessCode === ACCESS_CODE) {
      dispatch(changeAppStatus({ accessible: true }));
    }
  }, [accessCode]);

  const pressEnter = useCallback(
    (ev) => {
      if (ev.keyCode === 13) {
        callAccess();
      }
    },
    [callAccess]
  );

  useEffect(() => {
    Spotlight.focus("inputField");
  }, []);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

  return (
    <TPanel>
      <TBody className={css.body}>
        <div className={css.icon} />
        <div className={css.title}>{"Access Code"}</div>
        <InputField
          className={css.inputField}
          spotlightId="inputField"
          autoFocus
          type={"text"}
          dismissOnEnter
          value={accessCode}
          maxLength={MAX_CODE_LENGTH}
          onChange={onChangeAccessCode}
          placeholder={"put your Access Code"}
          onKeyDown={pressEnter}
        />
        <div className={css.btnLayer}>
          <TButton
            spotlightId="accessBtn"
            size={SIZES.xLarge}
            onClick={callAccess}
          >
            {$L("Access")}
          </TButton>
        </div>
      </TBody>
    </TPanel>
  );
};

export default AccessRestrictionPanel;
