import classNames from "classnames";
import css from "./OnOffButton.module.less";

import { useCallback, useState, useRef } from "react";

import * as Config from "../../utils/Config";
import * as SoundEffect from "../../utils/SoundEffect";

import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

import { Job } from "@enact/core/util";
import TToolTip from "../TToolTip/TToolTip";
import { useDispatch, useSelector } from "react-redux";
import { changeAppStatus } from "../../features/common/commonSlice";

const SpottableComponent = Spottable("div");

const OnOffButton = ({
  className,
  icon,
  color,
  isLeftSelected,
  disabled,
  selectedTabIndex,
  parentRef,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [isPressed, setPressed] = useState(false);
  const [isTooltip, setTooltip] = useState(false);

  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const _onClick = useCallback(() => {
    if (!disabled) {
      SoundEffect.playAudio(Config.MAIN_BUTTON_CLICK_SOUND);
      setPressed(true);
      clearPressedJob.current.start(() => {
        if (selectedTabIndex === 0) {
          dispatch(
            changeAppStatus({
              pictureTypeOverAll: !isLeftSelected ? "photo" : "illust",
            })
          );
        } else {
          dispatch(
            changeAppStatus({
              pictureType: !isLeftSelected ? "photo" : "illust",
            })
          );
        }
      });
    }
  }, [selectedTabIndex, isLeftSelected, disabled]);

  const onBlur = useCallback(() => {
    setPressed(false);
    setTooltip(false);
    clearPressedJob.current.stop();
  }, []);

  const onFocus = useCallback(() => {
    SoundEffect.playAudio(Config.MAIN_BUTTON_FOCUS_SOUND);
    !disabled && setTooltip(true);
  }, [disabled]);

  const tooltipRenderer = useCallback((isTooltip, labelText, ref) => {
    return (
      <TToolTip
        relativeRef={ref}
        relativePosition={ref && "right"}
        isOpen={isTooltip}
        style={{ top: "71px" }}
      >
        {labelText}
      </TToolTip>
    );
  }, []);

  return (
    <SpottableComponent
      {...rest}
      className={classNames(
        css.container,
        isPressed && css.pressed,
        disabled && css.disabled
      )}
      data-webos-voice-label={"OnOff"}
      data-webos-voice-intent={"Select"}
      onClick={_onClick}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <div className={classNames(css.tButton, isLeftSelected && css.selected)}>
        {"ON"}
      </div>
      <div className={classNames(css.tButton, !isLeftSelected && css.selected)}>
        {"OFF"}
      </div>
      {tooltipRenderer(isTooltip, "Photo On & Off", parentRef)}
    </SpottableComponent>
  );
};

export default OnOffButton;
