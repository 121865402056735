import React, { useCallback, useState, useRef } from "react";
import classNames from "classnames";
import css from "./TNudeTab.module.less";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import { Job } from "@enact/core/util";
import * as Config from "../../utils/Config";

const Container = SpotlightContainerDecorator(
  { enterTo: "last-focused" },
  "div"
);

const SpottableComponent = Spottable("div");

const TNudeTab = ({
  className,
  labelArr = [],
  selectedTabIndex,
  setSelectedTabIndex,
  onClick,
  size = "large",
  ...rest
}) => {
  const [pressed, setPressed] = useState(false);
  const clearPressedJob = useRef(
    new Job((func) => {
      setPressed(false);
      setTimeout(func, Config.TBUTTON_PRESS_DELAY);
    }, Config.TBUTTON_PRESS_DELAY)
  );

  const onItemClick = useCallback((index) => {
    setPressed(true);
    setSelectedTabIndex(index);
    clearPressedJob.current.start(() => {});
    if(onClick) {
      onClick({ index });
    }
  }, [onClick]);

  const onBlur = useCallback(() => {
    setPressed(false);
    clearPressedJob.current.stop();
  }, []);

  const renderItem = useCallback(
    (label, index) => {
      return (
        <SpottableComponent
          key={`label-${index}`}
          className={classNames(
            css.tabTitle,
            css[size],
            selectedTabIndex === index && css.active,
            selectedTabIndex === index && pressed && css.pressed
          )}
          onClick={() => onItemClick(index)}
          onBlur={onBlur}
        >
          <div className={css.tabTitleText}>{label}</div>
        </SpottableComponent>
      );
    },
    [selectedTabIndex, pressed]
  );

  return (
    <Container className={classNames(css.tabArea, className)} {...rest}>
      {Array.isArray(labelArr) && labelArr.length >= 2
        ? labelArr.map((label, index) => {
            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <div key={`devider-${index}`} className={css.devider} />
                )}
                {renderItem(label, index)}
              </React.Fragment>
            );
          })
        : "The number of items in a TNudeTab must be at least 2."}
    </Container>
  );
};

export default TNudeTab;
