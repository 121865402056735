import classNames from 'classnames';
import React from "react";
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import css from './TBody.module.less';
import SpotlightIds from '../../utils/SpotlightIds';
const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const TBody = ({children, className, spotlightId=SpotlightIds.TBODY, ...rest}) => {
	return (
		<Container spotlightId={spotlightId} {...rest} className={classNames(css.tBody, className)}>
			{children}
		</Container>
	);
};

export default TBody;
