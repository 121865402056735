import React, { useMemo } from "react";
import classNames from "classnames";
import css from "./BarItem.module.less";

const TYPES = {
  OVERALL: "OVERALL",
  PREVIEW: "PREVIEW",
};

const BarItem = ({ className, info, type = TYPES.PREVIEW, ...rest }) => {
  const percentageStyle = useMemo(() => {
    const color = {
      GREEN: "#44A280",
      YELLOW: "#F9B811",
      RED: "#F44B4A",
    };

    const indicatorSize = type === TYPES.OVERALL ? 15 : 10;
    const convertStyle = {
      1: { position: "0", color: color.RED },
      2: {
        position: `calc(25% - ${indicatorSize * 0.25}px)`,
        color: color.YELLOW,
      },
      3: {
        position: `calc(50% - ${indicatorSize * 0.5}px)`,
        color: color.GREEN,
      },
      4: {
        position: `calc(75% - ${indicatorSize * 0.75}px)`,
        color: color.YELLOW,
      },
      5: { position: `calc(100% - ${indicatorSize}px)`, color: color.RED },
    };
    return convertStyle[info.value];
  }, [info, type]);

  return (
    <div className={classNames(css.barItem, css[type], className)} {...rest}>
      {type === TYPES.PREVIEW && (
        <div className={css.title}>{!info.titleDisabled && info.partName}</div>
      )}
      <div className={css.bar}>
        {["left", "center", "right"].map((item, index) => (
          <div
            key={`separator_${index}`}
            className={classNames(css.separator, css[item])}
          />
        ))}

        <div
          className={css.indicator}
          style={
            percentageStyle
              ? {
                  left: percentageStyle.position,
                  backgroundColor: percentageStyle.color,
                }
              : {}
          }
        />
      </div>
      <div className={css.textWrapper}>
        {info.keys?.map((item, index) => (
          <div key={`guideText_${index}`} className={css.text}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarItem;
export { TYPES };
