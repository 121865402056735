import React, { useEffect, useState, useMemo } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import css from "./BodyPositionCorrectionSign.module.less";
// import * as Config from "../../../../utils/Config";
// import { SUB_TEST_SEQUENCE } from "../../ROM/ROMTest/Constants";
import outline_align from "../../../../../assets/bodyScan/img_guideline.png";
import outline_align_nogrid from "../../../../../assets/bodyScan/img_guideline_nogrid.png";
import outline_align_matched from "../../../../../assets/bodyScan/guideline_matched.png";
import outline_align_mismatch from "../../../../../assets/bodyScan/guideline_mismatch.png";
import outline_nogrid_mismatch from "../../../../../assets/bodyScan/img_nogrid_mismatch.png"
// import outline_nogrid_matched from "../../../../../assets/bodyScan/img_nogrid_matched.png"
import foot_location from "../../../../../assets/bodyScan/img_foot_location.png";
import foot_location_side from "../../../../../assets/bodyScan/img_foot_location_side.png";
import foot_location_match from "../../../../../assets/bodyScan/img_foot_location_match.png";
import img_foot_location_side_match from "../../../../../assets/bodyScan/img_foot_location_side_match.png";
import { $L } from "../../../../utils/helperMethods";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import ScanAnimator from "./ScanAnimator";


const BodyPositionCorrectionSign = ({ bodyPositionCorrectionDirection, isFront, onBodyCorrectionSign, feedbackEnable = true, bodyPositionLiveData, cameraSize, showScanner = false, showOutline = true, romTest = false, sft = false }) => {
	const { xFitBodyBottomPositionGUI } = useSelector((state) => state.common.localSettings);
	const [signInfo, setSignInfo] = useState({ instruction: "", outlineImage: "", moveLeftRight: "", guideFootLocation: "", voiceGuide: "" });

	useEffect(() => {
		const ret = { instruction: "", outlineImage: "", moveLeftRight: "", guideFootLocation: "", voiceGuide: "" };
		ret.outlineImage = outline_align;
		ret.outlingImageNoGrid = outline_align_nogrid;
		ret.guideFootLocation = isFront ? foot_location: foot_location_side;
		ret.voiceGuide = "";

		switch (bodyPositionCorrectionDirection?.result) {
			case "left": {
				ret.instruction = $L("Move to the left.");
				ret.moveLeftRight = "left";
				ret.voiceGuide = "ML";
				break;
			}
			case "right": {
				ret.instruction = $L("Move to the right.");
				ret.moveLeftRight = "right";
				ret.voiceGuide = "MR";
				break;
			}
			case "forward": {
				ret.instruction = isFront ? $L("Move forward.") : $L("Move back.");
				ret.voiceGuide = isFront ? "MF" : "MB";
				break;
			}
			case "back": {
				ret.instruction = isFront ? $L("Move back.") : $L("Move forward.");
				ret.voiceGuide = isFront ? "MB" : "MF";
				break;
			}
			case "tilt_R": {
				if (romTest || sft) {
					ret.instruction = null;
					ret.voiceGuide = null;
				} else {
					ret.instruction = isFront ? $L("Turn left.") : $L("Turn right.");
					ret.voiceGuide = isFront ? "TL" : "TR";
				}
				break;
			}
			case "tilt_L": {
				if (romTest || sft) {
					ret.instruction = null;
					ret.voiceGuide = null;
				} else {
					ret.instruction = isFront ? $L("Turn right.") : $L("Turn left.");
					ret.voiceGuide = isFront ? "TR" : "TL";
				}
				break;
			}
			case "camera_up": {
				ret.instruction = $L("Move camera up.");
				ret.voiceGuide = "MCD";
				break;
			}
			case "camera_down": {
				ret.instruction = $L("Move camera down.");
				ret.voiceGuide = "MCU";
				break;
			}
			case "good": {
				ret.outlineImage = outline_align_matched;
				ret.guideFootLocation = isFront ? foot_location_match: img_foot_location_side_match;
				break;
			}
			default: {
				break;
			}
		}

		if (ret.instruction) {
			if (!ret.voiceGuide) {
				ret.voiceGuide = ret.instruction;
			}
			if(romTest || sft){
				ret.voiceGuide = null;
				ret.guideFootLocation = null;
				ret.instruction = $L("You are out of position. Measure at the center of the screen.");
				ret.outlineImage = outline_nogrid_mismatch;
			}else{
				ret.outlineImage = outline_align_mismatch;
			}
		}
		// else{
		// 	if(romTest){
		// 		ret.instruction = $L("Move as much as you can.");
		// 	}
		// }
		setSignInfo(ret);
		if (onBodyCorrectionSign && feedbackEnable) {
			onBodyCorrectionSign(ret);
		}
	}, [bodyPositionCorrectionDirection, isFront]);

	const animatorBodyPosition = useMemo(() => {
		if (showScanner) {
			return bodyPositionLiveData;
		} else {
			return [];
		}
	}, [showScanner]);

	return (
		<div className={css.wrapper}>
			<img className={classNames(css.bodyOutline, (signInfo.instruction && !(romTest || sft)) && css.notMatch)} src={showOutline ? signInfo.outlineImage : signInfo.outlineImage === outline_nogrid_mismatch ? signInfo.outlineImage: signInfo.outlingImageNoGrid} alt="" />

			{showOutline &&
				<img className={css.guideFootLocation} src={signInfo.guideFootLocation} style={{ bottom: (100 - xFitBodyBottomPositionGUI) + "%" }} alt="" />
			}
			{showOutline && signInfo.moveLeftRight &&
				<div className={classNames(css.guideArrow, signInfo.moveLeftRight === "left" ? css.left : css.right)} />
			}
			{feedbackEnable &&
				<AudioPlayer srcTypeStr={signInfo.voiceGuide} />
			}
			{showScanner &&
				<ScanAnimator bodyPositionLiveData={animatorBodyPosition} cameraSize={cameraSize} />
			}
		</div>
	);
};

export default BodyPositionCorrectionSign;
