import classNames from "classnames";
import React, { useCallback, useEffect, useState } from 'react';
import { $L } from "../../../../utils/helperMethods";
import css from "./SSlider.module.less";
import TSlider from "../../../../components/TSlider/TSlider";
import { updateSurvey } from "../../../../features/fitService/fitServiceSlice";
import { useDispatch, useSelector } from "react-redux";


const SSlider = ({question, className}) => {
  const dispatch = useDispatch();
  const answer = useSelector((state) => state.fitService.survey);

  const _onChange = useCallback((value) => {
    dispatch(updateSurvey({[question.id]: value}))
  }, [dispatch, question])
 

	return (
    answer && 
    <TSlider className={classNames(css.slider, className)}  onChange={_onChange} value={answer[question.id]} />
	);
};

export default SSlider;