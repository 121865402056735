import classNames from "classnames";
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import css from "./SNone.module.less";
import Question from "../Question";


const SNone = ({question}) => {
  const [description, setDescription] = useState('');

  const _onFocus = useCallback((description) => (ev) => {
      setDescription(description);
  }, []);

	return (
    <>
      <div className={css.none}>
        {question.article.map((item, index) => (
          <Question key={"item" + index} question={item} onFocus={_onFocus(item.description)}/>
        ))}
      </div>
      {description && <div className={css.description}>{ description }</div>}
    </>

	);
};

export default SNone;