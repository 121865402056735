import React, { useState } from "react";
import TNudeTab from "../../../../components/TNudeTab/TNudeTab";
import { $L } from "../../../../utils/helperMethods";

const itemHas0 = [];
const itemHas1 = [$L("Item 1")];
const itemHas2 = [$L("Item 1"), $L("Item 2")];
const itemHas3 = [$L("Item 1"), $L("Item 2"), $L("Item 3")];

const TNudeTabLayout = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [item2Index, setItem2Index] = useState(0);
  const [item3Index, setItem3Index] = useState(0);

  return (
    <>
      <h3>Empty Item</h3>
      <TNudeTab
        className={{}}
        labelArr={itemHas0}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
      <h3>1 Item</h3>
      <TNudeTab
        className={{}}
        labelArr={itemHas1}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
      <h3>2 Items</h3>
      <TNudeTab
        className={{}}
        labelArr={itemHas2}
        selectedTabIndex={item2Index}
        setSelectedTabIndex={setItem2Index}
      />
      <h3>3 Items</h3>
      <TNudeTab
        className={{}}
        labelArr={itemHas3}
        selectedTabIndex={item3Index}
        setSelectedTabIndex={setItem3Index}
      />
    </>
  );
};

export default TNudeTabLayout;
