import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import Spritesheet from "react-responsive-spritesheet";
import css from './ContentStartPanel.module.less';
import THeader from "../../components/THeader/THeader";
import TBody from '../../components/TBody/TBody';
import TButton, { SIZES } from '../../components/TButton/TButton';
import { $L, cloneObject, transTimeSecToText } from '../../utils/helperMethods';
import { TIconButton, ICON_TYPES } from '../../components/TIconButton/TIconButton';
import dim from '../../../assets/inHomeOutdoorActivity/dim.png';
import Cancelable from '@enact/ui/Cancelable';
import { Panel } from '@enact/sandstone/Panels';
import CustomImage from '../../components/CustomImage/CustomImage';
import TTrack, { TRACK_TYPE } from '../../components/TTrack/TTrack';
import caminoDeSantiagoSpritesHiking from '../../../assets/inHomeOutdoorActivity/10frame_sec/camino-de-santiago-sprites-hiking.png';
import caminoDeSantiagoSpritesWalking from '../../../assets/inHomeOutdoorActivity/10frame_sec/camino-de-santiago-sprites-walking.png';
import {
  changeAppStatus,
  changeThemeModeStatus,
} from "../../features/common/commonSlice";
import { useDispatch, useSelector } from 'react-redux';
import * as Config from "../../utils/Config";
import { addPanels, popPanel } from "../../features/panels/panelsSlice";
import useUserCheck from '../../hooks/useUserCheck';
import Spotlight from '@enact/spotlight';
import useContent from '../../hooks/useContent';
import ic_homecare_level from '../../../assets/icon/ic_homecare_level.svg';
import ic_homecare_workout from '../../../assets/icon/ic_homecare_workout.svg';
import ic_homecare_senior from '../../../assets/icon/ic_homecare_senior.svg';
import ic_homecare_time from '../../../assets/icon/ic_homecare_time.svg';
import ic_homecare_calorie from '../../../assets/icon/ic_homecare_calorie.svg';
import ic_homecare_body from '../../../assets/icon/ic_homecare_body.svg';
import ic_bodyalignment_overall_alret from '../../../assets/icon/ic_bodyalignment_overall_alret.svg';
import SmartMatPopup from '../SmartMat/SmartMatPopup';
import useBLE from '../../hooks/useBLE';
import classNames from 'classnames';
import TPopUp from '../../components/TPopUp/TPopUp';

const CancelableDiv = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const ContentStartPanel = ({ panelInfo, ...rest }) => {
  const dispatch = useDispatch();
  const { bleConnectStatusRef } = useBLE({ autoConnect: panelInfo?.type === 'activity' });
  const spriteRef = useRef(null);
  const { userCheck } = useUserCheck();
  const { likeIdList, addLike, removeLike } = useContent(panelInfo);
  const { contentInfos } = useSelector(state => state.content);
  const [showSmartMatPopup, setShowSmartMatPopup] = useState(false);
  const [visible, setVisible] = useState(false);
  //todo remove cesshow
	const { cesShowMode } = useSelector((state) => state.common.localSettings);

  const spriteData = useMemo(() => {
    if(panelInfo?.subType ==='hiking'){
      return {
        sprite: {
          image: caminoDeSantiagoSpritesHiking,
          scaleUp: 1.0,
          widthFrame: 414,
          heightFrame: 839,
          steps: 16,
          fps: 10,
        }
      }
    } else {
      return {
        sprite: {
          image: caminoDeSantiagoSpritesWalking,
          scaleUp: 1.0,
          widthFrame: 414,
          heightFrame: 839,
          steps: 14,
          fps: 10,
        }
      }
    }
  }, [panelInfo]);

  useEffect(() => {
    if (spriteRef.current) {
      spriteRef.current.pause();
      spriteRef.current.setDirection("forward");
      spriteRef.current.setStartAt(0);
      spriteRef.current.frame = 0;
      spriteRef.current.init();
      spriteRef.current.play();
    }
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);


  const closeSmartMatPopup = useCallback(()=>{
    setShowSmartMatPopup(false);
  },[]);

  const formatUnit = useCallback((type, distance, digits) => {
    if (type === 'hiking' && !digits) {
      return Math.round(distance * 1000);
    }

    return Number(distance.toFixed(digits || 2));
  }, []);

  const contentInfo = useMemo(() => {
    // todo
    // const content = (contentInfos && panelInfo && panelInfo.contentId && contentInfos[panelInfo.contentId]) || {};
    const content = (
      Array.isArray(panelInfo) ?
      (contentInfos && panelInfo && panelInfo[0].contentId && contentInfos[panelInfo[0].contentId]) :
      (contentInfos && panelInfo && panelInfo.contentId && contentInfos[panelInfo.contentId])
    ) || {};
    const obj = cloneObject(content);

    if (content.type === 'activity') {
      obj.tags = [];
      if (content.subType === 'hiking') {
        obj.tags.push(`${formatUnit(content.subType, content.activity?.distance || 0)}m`);
      } else {
        obj.tags.push(`${content.activity?.distance || 0}Km`);
      }

      obj.tags.push(...[
        `${content.activity?.step || 0}steps`,
        transTimeSecToText(content.playTime, true || 0, 0, 0),
        `${content.metadata?.calories || 0}Kcal`
      ]);

      obj.distance = content.activity?.distance || 0;
      obj.trackType = content.activity?.trackType || '';
      obj.hotSpot = content.activity?.hotSpot.map(el => el.distance / obj.distance * 100) || [];
      obj.progressDistance = content.history?.progressDistance || 0;
      obj.likeYn = likeIdList?.includes(content.contentId) || false;
      obj.subType = content.subType;
    } else if (content.type === 'recommend') {
      obj.category = [];
      if (obj.metadata.category) obj.category.push(...obj.metadata.category.split('|'));

      obj.iconInfos = [];
      if (obj.metadata?.level) obj.iconInfos.push({ icon: ic_homecare_level, info: obj.metadata.level });
      if (obj.metadata?.equipment) obj.iconInfos.push({ icon: ic_homecare_workout, info: obj.metadata.equipment });
      if (obj.playTime) obj.iconInfos.push({ icon: ic_homecare_time, info: transTimeSecToText(obj.playTime, true) });
      if (obj.metadata?.calories) obj.iconInfos.push({ icon: ic_homecare_calorie, info: `${obj.metadata.calories || 0}Kcal` });
      if (obj.metadata?.bodyPart) obj.iconInfos.push({ icon: ic_homecare_body, info: obj.metadata.bodyPart.split('|') });
      if (obj.metadata?.ageGroup) obj.iconInfos.push({ icon: ic_homecare_senior, info: obj.metadata.ageGroup });

      obj.infos = [];
      if (obj.metadata?.position) obj.infos.push({ title: $L("Position"), info: obj.metadata.position.split('|') });
      if (obj.metadata?.exerciseEffect) obj.infos.push({ title: $L('Effects'), info: obj.metadata.exerciseEffect.split('|') });
      if (obj.metadata?.exceptionTarget) obj.infos.push({ title: $L('활동 주의 부위 ∙ 질환'), info: obj.metadata.exceptionTarget.split('|') });

      obj.caution = [];
      if (obj.metadata.caution) obj.caution.push(...obj.metadata.caution.split('|'));
    }
    return obj;
  }, [contentInfos, likeIdList]);

  const handleHeartClick = useCallback((isLike) => () => {
    if (isLike) {
      removeLike(panelInfo.contentId);
    } else {
      addLike(panelInfo.contentId);
    }
  }, []);

  const goToPlayer = useCallback((init = false) => () => {
    //todo remove cesshow
    if(!userCheck && !cesShowMode) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: 'play' } }));
    } else {
      if (contentInfo.type === 'activity') {
        if(bleConnectStatusRef.current !== "connected"){
          return setShowSmartMatPopup(true);
        }
        dispatch(popPanel());
        dispatch(addPanels({ name: Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER, panelInfo: { ...panelInfo, init } }));
      } else if (contentInfo.type === 'recommend') {
        dispatch(popPanel());
        dispatch(addPanels({ name: Config.panel_names.HOMECARE_WORKOUT_PLAYER, panelInfo }));
      }
    }
  },[dispatch, userCheck, contentInfo, cesShowMode]);

  const imgOnLoad = useCallback(() => {
    setVisible(true);

    setTimeout(() => {
      const node = document.querySelector(`#contentStartButtons > div`);
      if (node) {
        Spotlight.focus(node);
      }
    }, 0);
  }, []);

  const isContinue = useMemo(() => {
    return (contentInfo?.progressDistance > 0 && !panelInfo.init);
  }, [contentInfo]);

  return (
    <CancelableDiv className={css.panel}>
      <div className={css.bgImg}>
        <CustomImage src={contentInfo.thumbnailUrl} delay={0} animationSpeed='normal' onLoad={imgOnLoad}/>
        <CustomImage src={dim} delay={0} animationSpeed='normal' hide={!visible}/>
      </div>
      <THeader title={contentInfo.type === 'recommend' && contentInfo.cpName} iconType='back' />
      <TBody className={css.tBody}>
        {visible &&
          <div>
            {contentInfo.type === 'activity' && spriteData.sprite &&
              <div className={css.imageContainer}>
                <Spritesheet
                  ref={spriteRef}
                  image={spriteData.sprite.image}
                  widthFrame={spriteData.sprite.widthFrame}
                  heightFrame={spriteData.sprite.heightFrame}
                  steps={spriteData.sprite.steps}
                  fps={spriteData.sprite.fps}
                  autoplay={true}
                  loop={true}
                />
              </div>
              // <CustomImage
              //   src={jimiImage}
              //   className={css.jimiImage}
              // />
            }
            <div className={css.contentStartArea}>
              {Array.isArray(contentInfo.category) && contentInfo.category.length > 0 && <div className={css.category}>
                {contentInfo.category.map((category, idx, { length }) => <span key={`content-category-${idx}`} className={classNames(idx < length - 1 && css.bar)}>{category}</span>)}
              </div>}
              <div className={css.contentStartTitle}>{$L(contentInfo.title)}</div>
              {Array.isArray(contentInfo.tags) && contentInfo.tags.length > 0 && <div className={css.tagWrapper}>
                {contentInfo.tags.map((tag, idx) => <span key={`content-tag-${idx}`} className={css.tag}>{tag}</span>)}
              </div>}
              {Array.isArray(contentInfo.iconInfos) && contentInfo.iconInfos.length > 0 && <div className={css.iconInfoWrapper}>
                {contentInfo.iconInfos.map((iconInfo, idx) => <div key={`content-iconInfo-${idx}`} className={css.iconInfo}>
                  <div className={css.iconInfoImg}><img src={iconInfo.icon}/></div>
                  <div>
                    {Array.isArray(iconInfo.info) ?
                    iconInfo.info.map((value, idx2, { length }) => <span key={`content-iconInfo-${idx}-${idx2}`} className={classNames(idx2 < length - 1 && css.bar)}>{value}</span>)
                    :
                    iconInfo.info
                    }
                  </div>
                </div>)}
              </div>}
              {Array.isArray(contentInfo.infos) && contentInfo.infos.length > 0 && <div className={css.infoWrapper}>
                {contentInfo.infos.map((info, idx) => <div key={`content-info-${idx}`} className={css.info}>
                  <div className={css.infoTitle}>{info.title}</div>
                  <div>
                    {Array.isArray(info.info) ?
                    info.info.map((value, idx2, { length }) => <span key={`content-info-${idx}-${idx2}`}>{value}{idx2 < length - 1 && <br/>}</span>)
                    :
                    info.info
                    }
                  </div>
                </div>)}
              </div>}
              {Array.isArray(contentInfo.caution) && contentInfo.caution.length > 0 && <div>
                {contentInfo.caution.map((caution, idx) => <div key={`content-caution-${idx}`} className={css.caution}>
                  <div className={css.cautionImg}><img src={ic_bodyalignment_overall_alret}/></div>
                  <div>{caution}</div>
                </div>)}
              </div>}
              {contentInfo.type === 'activity' && <div className={css.contentStartDes} dangerouslySetInnerHTML={{ __html: $L(contentInfo.description) }} />}
              <div className={css.btnWrap} id="contentStartButtons">
                {isContinue && <TButton size={SIZES.xLarge} onClick={goToPlayer(false)}>{$L("Continue")}</TButton>}
                <TButton size={SIZES.xLarge} onClick={goToPlayer(true)}>{isContinue ? $L("Start from the beginning") : (contentInfo.type === 'recommend' ? $L("Start the workout") : $L("Getting started"))}</TButton>
                {userCheck && contentInfo.likeYn !== undefined && <div className={css.likeBtnContainer}>
                  <TIconButton
                    iconType={contentInfo.likeYn ? ICON_TYPES.like : ICON_TYPES.likeHollow}
                    className={css.likeBtn}
                    onClick={handleHeartClick(contentInfo.likeYn)}
                  />
                  <div>{$L("Keep it for later")}</div>
                </div>}
              </div>
              {contentInfo.type === 'activity' && <div className={css.trackContainer}>
                <TTrack
                  type={TRACK_TYPE[contentInfo.trackType]}
                  percentage={isContinue ? (contentInfo.progressDistance / contentInfo.distance * 100) : 0}
                  increase={1}
                  className={css.track}
                  hotSpot={contentInfo.hotSpot}
                />
                <div className={css.trackInfo}>
                  <div className={css.curDistance}>{formatUnit(contentInfo.subType, isContinue ? contentInfo.progressDistance : 0)}</div>
                  <div className={css.totalDistance}>/{formatUnit(contentInfo.subType, contentInfo.distance)}{contentInfo.subType === 'hiking' ? 'm' : 'km'}</div>
                </div>
              </div>}
            </div>
          </div>
        }
        {showSmartMatPopup && <SmartMatPopup onClose={closeSmartMatPopup}/>}
      </TBody>
    </CancelableDiv>
  );
};

export default ContentStartPanel;