import React, { useMemo } from "react";
import classNames from "classnames";
import css from "./PhysicalTestGraph.module.less";
import {
  SFT_SCORE_MANAGE_TOP,
  SFT_SCORE_DANGER_TOP,
} from "../../../../utils/Constants";
import { useSelector } from "react-redux";
import * as Config from "../../../../utils/Config";

const PhysicalTestGraph = ({
  score,
  grade,
  physicalWord,
  bgNum,
  gap = 0,
  isUpdated,
}) => {
  // const [animationEnded, setAnimationEnded] = useState(false);
  const panels = useSelector((state) => state.panels.panels);

  const gStyle = useMemo(() => {
    let ret = { color: "", maxWidth: "" };

    switch (grade) {
      case "s":
      case "a":
        ret.color = "#44A280"; //green
        break;
      case "b":
      case "c":
        ret.color = "#F9B811"; //yellow
        break;
      case "d":
        ret.color = "#F44B4A"; //red
        break;
      default:
        ret.color = "#8B8885"; //gray
        break;
    }

    // if (score > SFT_SCORE_MANAGE_TOP) {
    //   ret.color = "#44A280"; //green
    // } else if (score > SFT_SCORE_DANGER_TOP) {
    //   ret.color = "#F9B811"; //yellow
    // } else if (score >= 0 && score !== null) {
    //   ret.color = "#F44B4A"; //red
    // } else {
    //   ret.color = "#8B8885"; //gray
    // }
    ret.maxWidth = 55 + score / 2 + "%";
    return ret;
  }, [score, grade]);

  const currentPanelName = useMemo(() => {
    return panels[panels.length - 1]?.name;
  }, [panels]);

  // aniEnd
  // const onAanimationEnd = useCallback(() => {
  //   setAnimationEnded(true);
  // }, []);

  return (
    <div
      className={
        currentPanelName !== Config.panel_names.PHYSICAL_TEST_REPORT
          ? css.graphBox
          : css.overallGraphBox
      }
    >
      {isUpdated && (
        <div className={css.overallGraphBox1142}>
          <div
            className={classNames(
              css.commonGraphBg,
              css[`graphBg0${bgNum}`],
              css.ani
            )}
            style={{ backgroundColor: gStyle.color, maxWidth: gStyle.maxWidth }}
            // onAnimationEnd={onAanimationEnd}
          >
            <div className={css.graphInner}>
              <span className={css.physicalWord}>{physicalWord}</span>
              <span className={css.num}>{score === null ? 0 : score}</span>
              {/* {animationEnded && gap !== 0 && (
              <span className={css.gap}>{gap > 0 ? "+" + gap : gap}</span>
            )} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhysicalTestGraph;
