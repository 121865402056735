import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spotlight from "@enact/spotlight";
import IHOAPopupPanel from "../IHOAPopupPanel";
import TButton, { SIZES, TYPES } from "../../../components/TButton/TButton";
import { $L } from "../../../utils/helperMethods";
import css from "./ActivityPopup.module.less";
import classNames from "classnames";
import { postSetting, updateSettings } from "../../../features/fitService/fitServiceSlice";
import { SETTING_POPUP, concedeTextToNumber } from "./Constants";

const RatioSettingPopup = ({ onClick, onClose, ...props }) => {
  const dispatch = useDispatch();
  const { concede } = useSelector(state => state.fitService.settings);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const [curOpt, setCurOpt] = useState(concede || 'high');

  const _onClick = useCallback((save) => () => {
    if (save && concede !== curOpt) {
      if (cesShowMode) {
        dispatch(updateSettings({ concede: curOpt }));
      }
      else {
        dispatch(postSetting({ concede: curOpt }));
      }
    }

    if (onClick) onClick(SETTING_POPUP.RATIO_SETTING, (save && concede !== curOpt) ? curOpt : '');
  }, [onClick, curOpt, cesShowMode]);

  const _onClose = useCallback(() => {
    if(onClose) onClose();
  }, [onClose]);

  const ratioOptions = [
    { label: $L("High"), tag: '90°', value: 'high' },
    { label: $L("Middle"), tag: '60°', value: 'middle' },
    { label: $L("Low"), tag: '30°', value: 'low' }
  ];

  useEffect(() => {
    setTimeout(() => Spotlight.focus('ratioSettingPopup_firstSpot'), 0);

    return () => {
      setTimeout(() => {
        Spotlight.focus("pausePlayToggle");
      }, 0);
    }
  },[])

  return (
    <IHOAPopupPanel onClose={_onClose} {...props}>
      <div className={css.ratioSettingContainer}>
        <div className={css.title}>{$L("Set the knee raise angle.")}</div>
        <div className={css.text}>
          <div>{$L("For hiking, steps are only counted when knees are raised {ratio}° when walking in place.").replace('{ratio}', concedeTextToNumber[curOpt])}</div>
          <div>{$L("You can adjust the angle at which your steps are detected with by setting Concede.")}</div>
          <div>{$L("You can also change settings in Settings > Smart Mat > Concede")}</div>
        </div>
        <div className={css.ratioBtnWrapper}>
          {ratioOptions.map(ratio => <TButton
            key={`ratioSettingPopup-${ratio.value}`}
            type={TYPES.square}
            className={classNames(css.ratioBtn, curOpt === ratio.value && css.selected)}
            selected={curOpt === ratio.value}
            onClick={() => setCurOpt(ratio.value)}
          >
            <div className={css.label}>{ratio.label}</div>
            <div className={css.tag}>{ratio.tag}</div>
          </TButton>)}
        </div>
      </div>
      <div className={css.btnWrapper}>
        <TButton spotlightId="ratioSettingPopup_firstSpot" size={SIZES.xLarge} className={css.btn} onClick={_onClick()}>{$L("Cancel")}</TButton>
        <TButton size={SIZES.xLarge} className={css.btn} onClick={_onClick(true)}>{$L("Save")}</TButton>
      </div>
    </IHOAPopupPanel>
  );
};

export default RatioSettingPopup;