import React, { useRef, useCallback, useEffect, useState, useMemo } from "react";
import css from "./BaseLine.module.less";
import { $L } from "../../utils/helperMethods";
import ri from '@enact/ui/resolution';
import baseCircle from "../../../assets/physicalTest/base_circle.png";
import matchCircle from "../../../assets/physicalTest/match_circle.png";
import baseTag from "../../../assets/physicalTest/img_base_line.png"
import matchTag from "../../../assets/physicalTest/img_match_line.png"
import usePrevious from "../../hooks/usePrevious";
import classNames from "classnames";

const BaseLine = ({ kneeGuideLineStyle, currentExerciseCounter, keepMatchingStatus, matchingTime=0, size }) => {
	const [guideLineMatched, setGuideLineMatched] = useState(false);
	const matchingTimer = useRef(null);
	const currentExerciseCounterRef = usePrevious(currentExerciseCounter);
  const [previousExerciseCounter, setPreviousExerciseCounter] = useState(0);
	const drawingObject = useMemo(()=>{
		const ret = {};
		const notOnTv = typeof window === 'object' && !window.PalmSystem;
		if(!notOnTv && !kneeGuideLineStyle){
			return null;
		}
		const { width, left, top, right } = (notOnTv && !kneeGuideLineStyle) ? {
			width: 231.3106,
			left: 1345.531,
			top: 558,
			right: 1576.8416} : kneeGuideLineStyle;

		ret.leftImageStyle = {
			position: "absolute",
			left: left - ri.scale(40) + 'px',
			top: (top - ri.scale(52) / 2) + "px",
			width: "38px",
			height: "38px",
			backgroundImage: guideLineMatched? `url(${matchCircle})` : `url(${baseCircle})`
		};
		ret.rightImageStyle = {
			position: "absolute",
			left: left + width + 'px',
			top: (top - ri.scale(size === "small" ? 68 : 86) / 2) + "px",
			width: size === "small" ? "86px" : "108px",
			height: size === "small" ? "34px" : "43px",
			lineHeight: size === "small" ? "34px" : "43px",
			backgroundImage: guideLineMatched ? `url(${matchTag})` :`url(${baseTag})`,
			backgroundSize: "cover",
			color: "#000"
		};
		ret.dots = [];
		const numDots = 15; // 원하는 도트의 개수
		// p1[0]과 p2[1] 사이에 도트 추가
		for (let i = 1; i < numDots; i++) {
			const x = left + (right - left) * (i / numDots);
			const y = top;
			const dotStyle = {
				position: "absolute",
				left: x + "px",
				top: y + "px",
				width: size === "small" ? "6px" : "9px", // 도트의 가로
				height: size === "small" ? "2px" : "3px", // 도트의 세로
				backgroundColor: guideLineMatched ? "#21FFCD" : "#FEFEFE", // 도트 색상
				borderRadius: "32px",
			};
			ret.dots.push(dotStyle);
		}
		return ret;
	},[kneeGuideLineStyle, guideLineMatched, size]);

	useEffect(()=>{
		if(currentExerciseCounter && currentExerciseCounter?.value > 0 && previousExerciseCounter !== currentExerciseCounter?.value){
			setGuideLineMatched(true);
			clearTimeout(matchingTimer.current);
			let delayTime = keepMatchingStatus ? 2000:200;
			if(matchingTime > 0){
				delayTime = matchingTime;
			}
			matchingTimer.current = setTimeout(()=>{
				setGuideLineMatched(false);
			}, delayTime);
		}
    setPreviousExerciseCounter(currentExerciseCounter?.value);
	},[currentExerciseCounter]);

	useEffect(()=>{
		return () => clearTimeout(matchingTimer.current);
	},[]);

	if (!drawingObject) {
		return null
	}

	return (
		<div>
		{/* Left Image */}
		<div style={drawingObject.leftImageStyle} />

		{/* Right Image */}
      <div
        className={classNames(css.tagImg, css[size])}
        style={drawingObject.rightImageStyle}
      >
        <span className={classNames(guideLineMatched && css.matched)}>{$L("Baseline")}</span>
      </div>

		{/* Dots */}
		{drawingObject.dots.map((dotStyle, index) => (
			<div key={index} style={dotStyle}></div>
		))}
	</div>
	);
};

const propsAreEqual = (prev, next) => {
	const keys = Object.keys(prev);
	const nextKeys = Object.keys(next);
	if(keys.length !== nextKeys.length){
		return false;
	}
	for(let i=0; i<keys.length; i++){
		if(prev[keys[i]] !== next[keys[i]]){
      //ignore same json data
      if((JSON.stringify(prev[keys[i]]) === JSON.stringify(next[keys[i]]))){
       continue;
      }
			return false;
		}
	}
	return true;
}
export default React.memo(BaseLine, propsAreEqual);
