import React, { useEffect, useMemo, useRef, useState } from "react";
import css from "./BPMChart.module.less";
import { $L, convertNewlinesToBr } from "../../utils/helperMethods";
import usePrevious from "../../hooks/usePrevious";

let maxValue = 90;
let minValue = 70;
const maxArrlength = 8;

const drawLineChart = (ctx, data, strokeWidth = 3, isDebug) => {
  // canvas 초기화
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  const convertData = data.map(
    (item) =>
      // Math.min(Math.max(item, minValue), maxValue)
      item
  );

  // 데이터가 있을 때만 그리기
  if (convertData.length > 1) {
    // case 1. 마지막 데이터가 무조건 중간에 위치하게 하기
    // const currentValue = data[data.length - 1];
    // const lowerValue = currentValue >= 8 ? currentValue - 8 : 0;
    // const upperValue = currentValue >= 8 ? currentValue + 8 : 10;

    // case 2. 평균 값을 통해 차트 범위를 유동적으로 조정
    // 0 값을 필터링
    const filteredData = data.filter((item) => item !== 0);
    const sum = filteredData.reduce((acc, current) => {
      return acc + current;
    }, 0);
    const average = sum / filteredData.length;

    const lowerValue =
      typeof average !== "undefined" && !isNaN(average) ? average - 8 : 0;
    const upperValue =
      typeof average !== "undefined" && !isNaN(average) ? average + 8 : 10;

    const convertZeroData = data.map((item) =>
      // Math.min(Math.max(item, minValue), maxValue)
      {
        if (item === 0) {
          return lowerValue + 0.5;
        } else {
          return item;
        }
      }
    );

    const xAxisSpacing = ctx.canvas.width / (maxArrlength - 1);

    const point = [];
    convertZeroData.forEach((dot, index) => {
      const x = index * xAxisSpacing;
      const y =
        ctx.canvas.height -
        ((dot - lowerValue) / (upperValue - lowerValue)) * ctx.canvas.height;

      point.push({ x, y });
    });

    // curve 포인트 구하기
    const pointLeft = []; // 녹색점(왼쪽)
    const pointRight = []; // 빨간점(오른쪽)
    const dist = ctx.canvas.width / (maxArrlength * 2);

    for (let i = 0; i < point.length; i++) {
      pointLeft.push({ x: point[i].x - dist, y: point[i].y });
      pointRight.push({ x: point[i].x + dist, y: point[i].y });
    }

    if (isDebug) {
      // 일반 직선 그리기
      for (let i = 0; i < point.length; i++) {
        ctx.lineTo(point[i].x, point[i].y);
      }
      ctx.fillStyle = "white";
      ctx.strokeStyle = "blue"; // 라인 색상 설정
      ctx.lineWidth = strokeWidth; // 라인 두께 설정
      ctx.beginPath();
      ctx.stroke();
      ctx.closePath();

      // 포인트 표시
      const radius = 3;
      for (let i = 0; i < point.length; i++) {
        ctx.beginPath();
        ctx.arc(point[i].x, point[i].y, radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }

      // curve 포인트 그리기
      ctx.strokeStyle = "#00c50d"; // green
      for (let i = 0; i < pointLeft.length; i++) {
        ctx.beginPath();
        ctx.arc(pointLeft[i].x, pointLeft[i].y, radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }
      ctx.strokeStyle = "#c50d00"; // red
      for (let i = 0; i < pointRight.length; i++) {
        ctx.beginPath();
        ctx.arc(pointRight[i].x, pointRight[i].y, radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }
    }

    // 곡선 그리기
    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
    // gradient.addColorStop(1, "#ffa221"); // 주황색
    // gradient.addColorStop(0.5, "#FFFFFF"); // 흰색
    // gradient.addColorStop(0, "#21FFCD"); // 청록색

    gradient.addColorStop(1, "#FFFFFF"); // 흰색
    gradient.addColorStop(0, "#21FFCD"); // 청록색

    ctx.strokeStyle = gradient;
    ctx.lineWidth = strokeWidth;
    ctx.beginPath();
    ctx.moveTo(point[0].x, point[0].y);

    for (let i = 1; i < point.length; i++) {
      ctx.bezierCurveTo(
        pointRight[i - 1].x,
        pointRight[i - 1].y,
        pointLeft[i].x,
        pointLeft[i].y,
        point[i].x,
        point[i].y
      );
    }
    ctx.stroke();
    ctx.closePath();
  }
};

const BPMChart = ({
  width = 600,
  height = 250,
  data,
  strokeWidth = 3,
  isDebug = false,
}) => {
  const canvasRef = useRef(null);
  const dataRef = usePrevious(data);
  const [inputData, setInputData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof dataRef.current !== "undefined") {
        setInputData((prevData) => {
          if (prevData.length < maxArrlength) {
            return [...prevData, dataRef.current]; // 새로운 데이터 추가
          } else {
            const newData = [...prevData.slice(1), dataRef.current]; // 첫 번째 항목 제거 후 새로운 데이터 추가
            return newData;
          }
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      maxValue = 80;
      minValue = 70;
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(inputData) && inputData.length > 0) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      drawLineChart(ctx, inputData, strokeWidth, isDebug);
    }
  }, [inputData, isDebug]);

  return (
    <div className={css.container} style={{ width: width, height: height }}>
      {typeof data !== "undefined" ? (
        <canvas ref={canvasRef} width={width} height={height} />
      ) : (
        convertNewlinesToBr($L("Preparing to measure{br}heart rate."))
      )}
    </div>
  );
};

export default BPMChart;
