import classNames from "classnames";
import css from "./BodyCheckUpResult.module.less";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import TChips from "../../../../components/TChips/TChips";
import VerticalBarGraph from "./VerticalBarGraph/VerticalBarGraph";
import { $L } from "../../../../utils/helperMethods";
import THorizontalScrollList, { ITEM_SIZE } from "../../../../components/THorizontalScrollList/THorizontalScrollList";

export const BODY_ALIGNMENT_PART = {
  // NECK
	HEAD_SHIFT: "headShift",
	NECK_TILT: "neckTilt",

  // SHOULDER
	SHOULDER_TILT: "shoulderTilt",

  // PELVIC
	PELVIC_TILT: "pelvicTilt",
	PELVIC_SHIFT: "pelvicShift",
  PELVIC_ROTATION: "pelvicRotation",

  // TRUNK
  TRUNK_SHIFT: "trunkShift",

  // KNEE
  KNEE_FLEXION: "kneeFlexion",
  LEG_ALIGNMENT: "legAlignment"
};

export const ROM_PART = {
  // NECK
  NECK_LATERAL_FLEXION: "neckLateralFlexion",
  NECK_FLEXION: "neckFlexion",
  NECK_EXTENSION: "neckExtension",

  // SHOULDER
  SHOULDER_ABDUCTION: "shoulderAbduction",
  SHOULDER_INTERNAL_ROTATION: "shoulderInternalRotation",
  SHOULDER_EXTERNAL_ROTATION: "shoulderExternalRotation",
  SHOULDER_FLEXION: "shoulderFlexion",
  SHOULDER_EXTENSION: "shoulderExtension",

  // TRUNK
  TRUNK_LATERAL_FLEXION: "trunkLateralFlexion",
  TRUNK_EXTENSION: "trunkExtension",
  TRUNK_FLEXION: "trunkFlexion",

  // KNEE
  KNEE_FLEXION: "kneeFlexion",

  // HIP
  HIP_FLEXION: "hipFlexion",
  HIP_EXTENSION: "hipExtension",
};


export const getBodyCheckUpData = () => ({
  "bodyalignment": 
  {
    contents: [$L("Neck"), $L("Shoulder"), $L("Trunk"), $L("Pelvic"), $L("Knee"), $L("Leg")],
    title: {
      [BODY_ALIGNMENT_PART.HEAD_SHIFT]: $L("Head Shift"),
      [BODY_ALIGNMENT_PART.NECK_TILT]: $L("Neck Tilt"),
      [BODY_ALIGNMENT_PART.SHOULDER_TILT]: $L("Shoulder Tilt"),
      [BODY_ALIGNMENT_PART.PELVIC_TILT]: $L("Pelvic Tilt"),
      [BODY_ALIGNMENT_PART.PELVIC_SHIFT]: $L("Pelvic Shift"),
      [BODY_ALIGNMENT_PART.PELVIC_ROTATION]: $L("Pelvic Rotation"),
      [BODY_ALIGNMENT_PART.TRUNK_SHIFT]: $L("Trunk Shift"),
      [BODY_ALIGNMENT_PART.KNEE_FLEXION]: $L("Knee Flexion"),
      [BODY_ALIGNMENT_PART.LEG_ALIGNMENT]: $L("Leg Alignment"),
    },
    order: [
      [BODY_ALIGNMENT_PART.HEAD_SHIFT, BODY_ALIGNMENT_PART.NECK_TILT],
      [BODY_ALIGNMENT_PART.SHOULDER_TILT],
      [BODY_ALIGNMENT_PART.TRUNK_SHIFT],
      [BODY_ALIGNMENT_PART.PELVIC_TILT, BODY_ALIGNMENT_PART.PELVIC_SHIFT, BODY_ALIGNMENT_PART.PELVIC_ROTATION],
      [BODY_ALIGNMENT_PART.KNEE_FLEXION],
      [BODY_ALIGNMENT_PART.LEG_ALIGNMENT]
    ]
  },
  "rom": 
  {
    contents: [$L("Hip"), $L("Neck"), $L("Shoulder"), $L("Trunk"), $L("Knee")],
    title: {
      // HIP
      [ROM_PART.HIP_FLEXION]: $L("Hip Flexion"),
      [ROM_PART.HIP_EXTENSION]: $L("Hip Extension"),

      // NECK
      [ROM_PART.NECK_LATERAL_FLEXION]: $L("Neck Lateral Flexion"),
      [ROM_PART.NECK_FLEXION]: $L("Neck Flexion"),
      [ROM_PART.NECK_EXTENSION]: $L("Neck Extension"),

      // SHOULDER
      [ROM_PART.SHOULDER_ABDUCTION]: $L("Shoulder Abduction"),
      [ROM_PART.SHOULDER_INTERNAL_ROTATION]: $L("Shoulder Internal Rotation"),
      [ROM_PART.SHOULDER_EXTERNAL_ROTATION]: $L("Shoulder External Rotation"),
      [ROM_PART.SHOULDER_FLEXION]: $L("Shoulder Flexion"),
      [ROM_PART.SHOULDER_EXTENSION]: $L("Shoulder Extension"),

      // TRUNK
      [ROM_PART.TRUNK_LATERAL_FLEXION]: $L("Trunk Left/Right Lateral Flexion"),
      [ROM_PART.TRUNK_FLEXION]: $L("Trunk Flexion"),
      [ROM_PART.TRUNK_EXTENSION]: $L("Trunk Extension"),

      // KNEE
      [ROM_PART.KNEE_FLEXION]: $L({
        key: "rom_Knee_Flexion",
        value: "Knee Flexion",
      }),
    },
    order: [
      [ROM_PART.HIP_FLEXION, ROM_PART.HIP_EXTENSION],
      [ROM_PART.NECK_LATERAL_FLEXION, ROM_PART.NECK_FLEXION, ROM_PART.NECK_LATERAL_FLEXION],
      [ROM_PART.SHOULDER_ABDUCTION, ROM_PART.SHOULDER_INTERNAL_ROTATION, ROM_PART.SHOULDER_EXTERNAL_ROTATION, ROM_PART.SHOULDER_FLEXION, ROM_PART.SHOULDER_EXTENSION],
      [ROM_PART.TRUNK_LATERAL_FLEXION, ROM_PART.TRUNK_FLEXION, ROM_PART.TRUNK_EXTENSION],
      [ROM_PART.KNEE_FLEXION],
    ]
  }
});

const BodyCheckUpResult = ({className, monthList=[], itemInfo, type="bodyalignment", ...rest }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const contents = useMemo(() => {
    return getBodyCheckUpData()[type].contents;
  }, [type]);

  const title = useMemo(() => {
    return getBodyCheckUpData()[type].title;
  }, [type]);

  const order = useMemo(() => {
    return getBodyCheckUpData()[type].order;
  }, [type]);

  const onClickChipItem = useCallback(({ index }) => {
    setSelectedIndex(index);
  }, []);

  return (
    <div
      className={classNames(
        className,
        css.bodyCheckUpResult,
      )}
      {...rest}
    >
      <TChips
        className={css.chips}
        contents={contents}
        selectedIndex={selectedIndex}
        onItemClick={onClickChipItem}
      />
      {itemInfo &&
      <THorizontalScrollList itemSize={ITEM_SIZE.bodyCheckUp} startGap={75}>
        {order[selectedIndex] && 
        order[selectedIndex].length > 0 && 
        order[selectedIndex].map((part, index) =>
          <VerticalBarGraph
            key={"part" + selectedIndex + index}
            itemInfo={itemInfo[part]}
            title={title[part]}
            monthList={monthList}
          />
        )}
      </THorizontalScrollList>
      }
    </div>
  );
};

export default BodyCheckUpResult;