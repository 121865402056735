import { useMemo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panels } from "@enact/sandstone/Panels";
import { Panel } from "@enact/sandstone/Panels/Panel";
import platform from "@enact/core/platform";
import * as Config from "../../utils/Config";
import css from "./MainView.module.less";
import classNames from "classnames";
import Spotlight from "@enact/spotlight";
import SpotlightIds from '../../utils/SpotlightIds';
import PreloadImage from "../../components/PreloadImage/PreloadImage";
import TabLayout from "../../components/TabLayout";
import { addPanels, resetPanels } from "../../features/panels/panelsSlice";
import { changeAppStatus, changeLocalSettings } from "../../features/common/commonSlice";

import * as ilibData from '../../utils/ilibData';

import LandingPage from "../LandingPage/LandingPage";

//BodyCheckup
import BodyCheckUpMain from "../BodyCheckUp/BodyCheckUpMain";

//BodyCheckup-BodyAlignment
import BodyAlignmentReport from "../BodyCheckUp/BodyAlignment/BodyAlignmentReport";
import BodyAlignmentReportDetail from "../BodyCheckUp/BodyAlignment/BodyAlignmentReportDetail/BodyAlignmentReportDetail";
import BodyAlignmentScan from "../BodyCheckUp/BodyAlignment/BodyAlignmentScan";
//ces
import BodyAlignmentSummaryReport from "../BodyCheckUp/BodyAlignment/BodyAlignmentSummaryReport/BodyAlignmentSummaryReport.jsx";

//BodyCheckup-ROM
import ROMReport from "../BodyCheckUp/ROM/ROMReport/ROMReport"
import ROMTest from "../BodyCheckUp/ROM/ROMTest/ROMTest"

//BodyCheckup-PhysicalTest
import PhysicalTestGate from "../BodyCheckUp/PhysicalTest/PhysicalTestGate";
import PhysicalTestReport from "../BodyCheckUp/PhysicalTest/PhysicalTestReport";
import PhysicalTestCapture from "../BodyCheckUp/PhysicalTest/PhysicalTestCapture";

//BodyCheckup-BodyBalance
import BodyBalanceReport from "../BodyCheckUp/BodyBalance/BodyBalanceReport/BodyBalanceReport";
import BodyBalanceTest from "../BodyCheckUp/BodyBalance/BodyBalanceTest/BodyBalanceTest";
import BodyBalanceDetailsReport from "../BodyCheckUp/BodyBalance/BodyBalanceDetailsReport/BodyBalanceDetailsReport";
//ces
import BodyBalanceSummaryReport from "../BodyCheckUp/BodyBalance/BodyBalanceSummaryReport/BodyBalanceSummaryReport.jsx";

//HomecareProgram
import HomecareProgramMain from "../HomecareProgram/HomecareProgramMain";
import HPRecommendWorkout from "../HomecareProgram/HPRecommendWorkout/HPRecommendWorkout";
import WorkoutPlayer from "../HomecareProgram/WorkoutPlayer/WorkoutPlayer";
import HPWorkoutPlanner from "../HomecareProgram/HPWorkoutPlanner/HPWorkoutPlanner";

//HomecareProgram
import HPWorkoutOptions from "../HPWorkoutOptions/HPWorkoutOptions";
import HPCenterInfo from "../HPCenterInfo/HPCenterInfo";
import HPAlarmSetting from "../HPAlarmSetting/HPAlarmSetting";
import HPTimeSetting from "../HPTimeSetting/HPTimeSetting";
import HPRoutineProgram from "../HomecareProgram/HPRoutineProgram/HPRoutineProgram";
import HPRoutineProComposition from "../HomecareProgram/HPRoutineProgram/HPRoutineProComposition.jsx";

//InHomeOutdoor
import InHomeOutdoorMain from "../InHomeOutdoorAct/InHomeOutdoorMain";

//InHomeOutdoorActivity
import VirtualTrekking from "../VirtualTrekking/VirtualTrekking";
import IHOAWJMain from "../InHomeOutdoorAct/IHOAWalkingJogging/IHOAWJMain";
import IHOAWJFullActivity from "../InHomeOutdoorAct/IHOAWalkingJogging/IHOAWJFullActivity/IHOAWJFullActivity";
import ActivityPlayer from "../InHomeOutdoorAct/ActivityPlayer/ActivityPlayer";

//intro
import Intro from "../IntroPanel/IntroPanel";
import TermsConditionsPanel from "../TermsConditionsPanel/TermsConditionsPanel";
import SurveyPanel from "../SurveyPanel/SurveyPanel";
import LoginPanel from "../LoginPanel";

import LoadingPanel from "../LoadingPanel";

//Setting
import SettingPanel from "../Settings/SettingsMain";
import SelectBgmPanel from "../Settings/SubAudio/SelectBgmPanel";

//My
import MyPanel from "../MyPanel/MyPanel";
import MySurveyPanel from "../MyPanel/MySureveyInfo/MySurveyPanel";
import SubscriptionPassPanel from "../MyPanel/MyProfile/SubscriptionPassPanel";

//Fitness Manager
import FitnessManger from "../FitnessManager/FitnessManager";
import FitnessGoalSetting from "../FitnessManager/FitnessGoalSetting/FitnessGoalSetting";
import FitnessGoalSettingDetail from "../FitnessManager/FitnessGoalSetting/FitnessGoalSettingDetail";
import FitnessBriefingDetail from "../FitnessManager/FitnessBriefing/BriefingDetail";

//Report
import ReportPanel from "../ReportPanel/ReportPanel";
import MeasurementDate from "../ReportPanel/SharePanel/MeasurementDate";
import ShareMethod from "../ReportPanel/SharePanel/ShareMethod";
import EmailSend from "../ReportPanel/SharePanel/EmailSend";
import MobileSend from "../ReportPanel/SharePanel/MobileSend";
import PreviewPanel from "../PreviewPanel/PreviewPanel";

//Debug, TestPanel
import DebugPanel from "../DebugPanel/DebugPanel";
import TestPanel from "../TestPanel/TestPanel";
import ScanTest from "../TestPanel/ScanTest";
import BellySmoothTest from "../TestPanel/BellySmoothTest";
import ShakaPlayerTest from "../TestPanel/ShakaPlayerTest";
import PlaybackSpeedTest from "../TestPanel/PlaybackSpeedTest";
import ChartTest from "../TestPanel/ChartTest";
import LoggerTest from "../TestPanel/LoggerTest";
import BleTest from "../TestPanel/BleTest";
import BleTestDummy from "../TestPanel/BleTestDummy";
import WebWorkerTestPanel from "../TestPanel/WebWorkerTestPanel";
import SoundEffectTestPanel from "../TestPanel/SoundEffectTestPanel";
import StoryBookPanel from "../TestPanel/StoryBookPanel/StoryBookPanel";
import JMediaListPanel from "../TestPanel/JMediaListPanel";

// ContentPlayer
import ContentStart from "../ContentPlayerPanel/ContentStartPanel";

// Error
import ErrorPanel from "../ErrorPanel/ErrorPanel";

// AccessRestriction
import AccessRestrictionPanel from "../AccessRestrictionPanel/AccessRestrictionPanel.jsx";

// Workout
import WorkoutPanel from "../WorkoutPanel/WorkoutPanel"

import bodyCheckUpsprite from "../../../assets/mainSections/bodyCheckUp/10frame_sec/body-check-up-sprites.png";
import bodyAlignmentSprite from "../../../assets/mainSections/bodyAlignment/10frame_sec/body-alignment-sprites.png";
import bodyBalanceSprite from "../../../assets/mainSections/bodyBalance/10frame_sec/body-balance-sprites.png";
import rom_nor_bg_01 from "../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_01_nor.png";
import rom_nor_bg_02 from "../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_02_nor.png";
import rom_nor_bg_03 from "../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_03_nor.png";
import physicalTestSprites from "../../../assets/mainSections/physicalTest/10frame_sec/physical-test-sprites.png";
import fashionistaSprites from "../../../assets/landingPage/8frame_sec/fashionista-sprites.png"
import gamerSprites from "../../../assets/inHomeOutdoorActivity/8frame_sec/gamer_sprite_image.png"
import LoggerWindow from "../TestPanel/LoggerWindow";
import TUserCheckPopup from "../../components/TUserCheckPopup/TUserCheckPopup";
import VRPlayerPanel from "../TestPanel/VRPlayerPanel";
import CesSelectWorkout from "../../components/CesSelectWorkout";
import AIManagerFeedback from "../../components/AIManagerFeedback/AIManagerFeedback.jsx";
import TCesTermsAgree from "../../components/TCesTermsAgree/TCesTermsAgree";

const preloadImages = [
  bodyCheckUpsprite,
  bodyAlignmentSprite,
  bodyBalanceSprite,
  physicalTestSprites,
  rom_nor_bg_01,
  rom_nor_bg_02,
  rom_nor_bg_03,
  fashionistaSprites,
  gamerSprites
];

const panelMap = {
  // BodyCheckUp
  [Config.panel_names.BODY_CHECK_UP_MAIN]: BodyCheckUpMain,

  // BodyAlignment
  [Config.panel_names.BODY_ALIGNMENT_REPORT]: BodyAlignmentReport,
  [Config.panel_names.BODY_ALIGNMENT_REPORT_DETAIL]: BodyAlignmentReportDetail,
  [Config.panel_names.BODY_ALIGNMENT_SCAN]: BodyAlignmentScan,
  //ces
  [Config.panel_names.BODY_ALIGNMENT_SUMMARY]: BodyAlignmentSummaryReport,

  //ROM
  [Config.panel_names.ROM_REPORT]: ROMReport,
  [Config.panel_names.ROM_TEST]: ROMTest,

  // PhysicalTest
  [Config.panel_names.PHYSICAL_TEST_GATE]: PhysicalTestGate,
  [Config.panel_names.PHYSICAL_TEST_REPORT]: PhysicalTestReport,
  [Config.panel_names.PHYSICAL_TEST_CAPTURE]: PhysicalTestCapture,

  //BodyBalance
  [Config.panel_names.BODY_BALANCE_REPORT]: BodyBalanceReport,
  [Config.panel_names.BODY_BALANCE_TEST]: BodyBalanceTest,
  [Config.panel_names.BODY_BALANCE_DETAILS]: BodyBalanceDetailsReport,
  //ces
  [Config.panel_names.BODY_BALANCE_SUMMARY]: BodyBalanceSummaryReport,

  //HomecareProgram
  [Config.panel_names.HOMECARE_PROGRAM_MAIN]: HomecareProgramMain,
  [Config.panel_names.HOMECARE_RECOMMEND_WORKOUT]: HPRecommendWorkout,
  [Config.panel_names.HOMECARE_WORKOUT_PLAYER]: WorkoutPlayer,
  [Config.panel_names.HOMECARE_WORKOUT_PLANNER]: HPWorkoutPlanner,

  // HPWorkout
  [Config.panel_names.HOMECARE_WORKOUT_OPTIONS]: HPWorkoutOptions,
  [Config.panel_names.HOMECARE_CENTER_INFO]: HPCenterInfo,
  [Config.panel_names.HOMECARE_ALARM_SETTING]: HPAlarmSetting,
  [Config.panel_names.HOMECARE_TIME_SETTING]: HPTimeSetting,

  [Config.panel_names.HOMECARE_ROUTINE_PROGRAM]: HPRoutineProgram,
  [Config.panel_names.HOMECARE_ROUTINE_PROGRAM_COMPOSITION]: HPRoutineProComposition,

  // InHomeOutdoor
  [Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_MAIN]: InHomeOutdoorMain,
  [Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_MAIN]: IHOAWJMain,
  [Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_WALKING_JOGGING_FULL_ACTIVITY]: IHOAWJFullActivity,
  [Config.panel_names.IN_HOME_OUTDOOR_ACTIVITY_PLAYER]: ActivityPlayer,
  [Config.panel_names.VIRTUAL_TREKKING]: VirtualTrekking,

  //Login, Intro
  [Config.panel_names.LOGIN_PANEL]: LoginPanel,
  [Config.panel_names.INTRO_PANEL]: Intro,
  [Config.panel_names.TERMS_PANEL]: TermsConditionsPanel,
  [Config.panel_names.SURVEY_PANEL]: SurveyPanel,

  // Setting
  [Config.panel_names.SETTING_PANEL]: SettingPanel,
  [Config.panel_names.SELECT_BGM_PANEL]: SelectBgmPanel,

  // My
  [Config.panel_names.MY_PANEL]: MyPanel,
  [Config.panel_names.SUBSCRIPTION_PASS_PANEL]: SubscriptionPassPanel,
  [Config.panel_names.MY_SURVEY_PANEL]: MySurveyPanel,

  // Fitness Manager
  [Config.panel_names.FITNESS_MANAGER]: FitnessManger,
  [Config.panel_names.FITNESS_GOAL_SETTING]: FitnessGoalSetting,
  [Config.panel_names.FITNESS_GOAL_SETTING_DETAIL]: FitnessGoalSettingDetail,
  [Config.panel_names.FITNESS_BRIEFING_DETAIL]: FitnessBriefingDetail,

  // Report
  [Config.panel_names.REPORT_PANEL]: ReportPanel,
  [Config.panel_names.MEASURMENT_DATE]: MeasurementDate,
  [Config.panel_names.SHARE_METHOD]: ShareMethod,
  [Config.panel_names.EMAIL_SEND]: EmailSend,
  [Config.panel_names.MOBILE_SEND]: MobileSend,
  [Config.panel_names.PREVIEW_PANEL]: PreviewPanel,

  // Debug, Test
  [Config.panel_names.DEBUG_PANEL]: DebugPanel,
  [Config.panel_names.TEST_PANEL]: TestPanel,
  [Config.panel_names.TEST_PANEL_SCAN]: ScanTest,
  [Config.panel_names.TEST_PANEL_BELLY]: BellySmoothTest,
  [Config.panel_names.TEST_PANEL_SHAKA]: ShakaPlayerTest,
  [Config.panel_names.TEST_PANEL_PLAYSPEED]: PlaybackSpeedTest,
  [Config.panel_names.TEST_PANEL_CHART]: ChartTest,
  [Config.panel_names.TEST_PANEL_LOGGER]: LoggerTest,
  [Config.panel_names.TEST_PANEL_BLE]: BleTest,
  [Config.panel_names.TEST_PANEL_BLE_DUMMY]: BleTestDummy,
  [Config.panel_names.TEST_PANEL_WEBWORKER]: WebWorkerTestPanel,
  [Config.panel_names.TEST_PANEL_SOUNDEFFECT]: SoundEffectTestPanel,
  [Config.panel_names.TEST_PANEL_STORYBOOK]: StoryBookPanel,
  [Config.panel_names.TEST_PANEL_JMEDIALIST]: JMediaListPanel,
  [Config.panel_names.TEST_PANEL_VRPLAYER]: VRPlayerPanel,

  // ContentPlayer
  [Config.panel_names.CONTENT_PLAYER_START]: ContentStart,

  // Error
  [Config.panel_names.ERROR_PANEL]: ErrorPanel,

  // AccessRestriction
  [Config.panel_names.ERROR_PANEL]: AccessRestrictionPanel,

  // Workout
  [Config.panel_names.WORKOUT_PANEL]: WorkoutPanel,

  // ces select workout
  [Config.panel_names.SELECT_WORKOUT]: CesSelectWorkout,
  // AIManagerFeedback
  [Config.panel_names.AI_MANAGER_FEEDBACK]: AIManagerFeedback,
};

const MainView = ({ initService, ...props }) => {
  const dispatch = useDispatch();
  const [tabActivated, setTabActivated] = useState(false);
  const connectionFailed = false;
  const [showConnectionError, setShowConnectionError] = useState(connectionFailed);
  const { showLoadingPanel, showUserCheckPopup, isInternetConnected, accessible } = useSelector((state) => state.common.appStatus);
  const mainIndex = useSelector((state) => state.appData.mainIndex);
  const panels = useSelector((state) => state.panels.panels);

  // 재연
  const { loggerWindowState } = useSelector((state) => state.logData);

  const isOnTop = useMemo(() => {
    return (
      !mainIndex && isInternetConnected && !showConnectionError && panels.length <= 0
    );
  }, [isInternetConnected, mainIndex, showConnectionError, panels.length]);

  const onPreImageLoadComplete = useCallback(() => {
    dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
  }, [dispatch]);

  const cursorStateChange = useCallback((ev) => {
    dispatch(changeAppStatus({ cursorVisible: ev.visibility || ev.detail.visibility }));
    console.log('MainView cursorStateChange ', window.PalmSystem?.cursor?.visibility, ev?.visibility, ev?.detail?.visibility);
  }, [dispatch]);

  useEffect(() => {
    if (!isInternetConnected) {
      dispatch(resetPanels());
      dispatch(addPanels({ name: Config.panel_names.ERROR_PANEL, panelInfo: { reason: "networkError" } }));
    }
  }, [dispatch, isInternetConnected]);

  useEffect(() => {
    console.log('MainView cursor vi', JSON.stringify(window.PalmSystem?.cursor?.visibility));
    document.addEventListener('cursorStateChange', cursorStateChange, false);
    if (platform.platformName !== 'webos') {//for debug
      dispatch(changeAppStatus({ cursorVisible: !platform.touchscreen }));
    } else {
      if (typeof window === 'object') dispatch(changeAppStatus({ cursorVisible: window.PalmSystem?.cursor?.visibility }));
    }
    return () => {
      document.removeEventListener('cursorStateChange', cursorStateChange);
    };
  }, []);

  const renderTopPanel = useCallback(() => {
    if (panels && panels.length > 0) {
      const panel = panels[panels.length - 1];
      const Component = panelMap[panel.name];
      return <Component panelInfo={panel.panelInfo} isTabActivated={tabActivated} spotlightId={panel.name} />;
    } else {
      return null;
    }
  }, [panels, tabActivated]);

  const onTabActivated = useCallback((activated) => {
    setTabActivated(activated);
  }, []);
  const topPanelName = useMemo(() => {
    if (panels && panels.length > 0) {
      return panels[panels.length - 1].name;
    }
    return null;
  }, [panels]);

  useEffect(() => {
    if (!tabActivated) {
      Spotlight.focus(SpotlightIds.LANDING_TBODY);
      if (panels && panels.length > 0) {
        const panel = panels[panels.length - 1];
        Spotlight.focus(panel.name);
      }
      Spotlight.focus(SpotlightIds.TBODY);
    }
  }, [tabActivated, panels]);

  useEffect(() => {
    if (typeof window === "object" && !window.PalmSystem) {
      if (document.URL.indexOf(Config.ACCESS_REQUIRED_URL) >= 0) {
        dispatch(changeAppStatus({ accessible: false }));
      }
    }
  }, []);

  ilibData.initIlib();
  return (
    <>
      <PreloadImage preloadImages={preloadImages} onLoadComplete={onPreImageLoadComplete} />
      {/* 재연 */}
      {loggerWindowState && <LoggerWindow />}
      <LoadingPanel showLoadingPanel={showLoadingPanel} />
      <TUserCheckPopup showUserCheckPopup={showUserCheckPopup} />
      <TCesTermsAgree/>
      {accessible ? (
        <>
          <div
            className={classNames(
              css.mainlayout,
              showLoadingPanel.show ? css.transparent : null
            )}
          >
            <LandingPage isOnTop={isOnTop} spotlightId="landingPage" />
            {renderTopPanel()}
            <TabLayout
              topPanelName={topPanelName}
              onTabActivated={onTabActivated}
            />
          </div>
        </>
      ) : (
        <AccessRestrictionPanel />
      )}
    </>
  );
};

export default MainView;
