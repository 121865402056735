import TSummary, { SUMMARY_SIZE, SUMMARY_ALIGN } from "../../../../components/TSummary";
import { $L } from "../../../../utils/helperMethods";

const DUMMY_ITEMS = [
  { type: 'distance', label: "거리", value: 3.254 },
  { type: "steps", label: "Number of steps taken", value: 2345 },
  { type: 'time', label: "시간", value: 5029 },
  { type: 'calorie', label: "열량", value: 1345 }
];

const DUMMY_ITEMS2 = [
  { type: 'routine', label: "운동 일수", value: 5 },
  { type: 'time', label: "운동 시간", value: 5029 },
  { type: "calorie", label: $L("Calories burned"), value: 1345 },
];

const TSummaryLayout = () => {
  return (
    <>
      <div>
        <h3>Small (Default)</h3>
        <TSummary items={DUMMY_ITEMS}/>
      </div>
      <div>
        <h3>Large - center align</h3>
        <TSummary items={DUMMY_ITEMS2} size={SUMMARY_SIZE.large} align={SUMMARY_ALIGN.center}/>
      </div>
      <div>
        <h3>Lite</h3>
        <TSummary items={DUMMY_ITEMS} size={SUMMARY_SIZE.lite}/>
      </div>
    </>
  );
};

export default TSummaryLayout;