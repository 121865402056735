import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Config from "../utils/Config";
import * as HelperMethods from "../utils/helperMethods";
import { DATAKEY, updateTestResults } from "../features/bodyBalance/bodyBalanceSlice";
import { postBodyBalanceData } from "../features/fitService/fitServiceSlice";
import { updatePanel} from "../features/panels/panelsSlice";

const useBodyBalance = () => {
  const dispatch = useDispatch();
  const currentTestedData = useSelector(state => state.bodyBalance.current);
  const {cesShowMode } = useSelector((state) => state.common.localSettings);
  const saveTestResults = useCallback(() => {
    const now = new Date();
    const keys = Object.keys(DATAKEY);
    const toSave = {};
    const isoDateTime = HelperMethods.timeToISO8601Str(now);
    keys.forEach(key => {
      if(currentTestedData[DATAKEY[key]] && Object.keys(currentTestedData[DATAKEY[key]]).length > 0){
        toSave[DATAKEY[key]] = {...currentTestedData[DATAKEY[key]], date: isoDateTime};
      }
    });
    //update to reducer
    //2023-09-11
    const dateStr = HelperMethods.convertDateToString2(now);
    dispatch(updateTestResults({date: dateStr, value: toSave}));
    dispatch(updatePanel({name: Config.panel_names.BODY_BALANCE_REPORT, panelInfo: { selectedDate: dateStr }}));
    //push to server
    if(!cesShowMode){
      const toServerSave = HelperMethods.cloneObject(toSave);
      toServerSave.date = isoDateTime;
      dispatch(postBodyBalanceData(toServerSave));
    }
  },[dispatch, currentTestedData, cesShowMode]);

  return { currentTestedData, saveTestResults };
};

export default useBodyBalance;
