import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import classnames from "classnames";
import css from "./WorkoutPlayer.module.less";
import { Job } from "@enact/core/util";
import Spotlight from "@enact/spotlight";
import Spottable from "@enact/spotlight/Spottable";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";

import {
  changeAppStatus,
  changeThemeModeStatus,
} from "../../../features/common/commonSlice";
import { addPanels, popPanel } from "../../../features/panels/panelsSlice";

import TPanel from "../../../components/TPanel";
import PIPCamera from "../../../components/PIPCamera";
import WorkoutPlayerModal from "./WorkoutPlayerModal/WorkoutPlayerModal";
import PainModal from "./PainModal/PainModal";
import FinishModal from "./FinishModal/FinishModal";
import TShakaPlayer from "../../../components/TShakaPlayer";
import { $L, formatTime } from "../../../utils/helperMethods";
import TButton, { TYPES, ICONS, SIZES } from "../../../components/TButton";

import HEART from "../../../../assets/icon/heart.svg";
import TAutoPlayer from "../../../components/TAutoPlayer";
import TIconButton, {
  ICON_TYPES,
} from "../../../components/TIconButton/TIconButton";
import useSetSolution from "../../../hooks/useSetSolution";
import useActivatePositionDetection from "../../../hooks/useActivatePositionDetection";
import BPMChart from "../../../components/BPMChart/BPMChart";
import { panel_names } from "../../../utils/Config";
import WebWorkerUtil, { WORKER_ID } from "../../../utils/WebWorker/WebWorkerUtil";
import AngleGuideCanvas from "../../BodyCheckUp/ROM/ROMTest/AngleGuideCanvas/AngleGuideCanvas";
import TestGuideSignMsg from "../../../components/TestGuideSignMsg/TestGuideSignMsg";
import Alert from "@enact/sandstone/Alert";
import useBpm from "../../../hooks/useBpm";
import {updateMaxCameraBpm} from "../../../features/camera/cameraSlice";
import IMG_PAIN from "../../../../assets/images/workout/img_pain.png";

const SEQUENCE_INIT = 0;
const SEQUENCE_SHOW_EXCERCISENAME = 1;
const SEQUENCE_HIDE_EXCERCISENAME = 2;
const SEQUENCE_PLAY_VIDEO = 3;
const AUTOPLAY_TIME = 5;
const PLAYER_MODAL_DELAY = 2000;
const DEGREE_START = 4;
const DEGREE_END = 56;
const ROM_NICE_ANGLE = 15;
const ROM_FIRST_IGNORE_ANGLE = 5;
const startPlayingVideoJob = new Job((setPlayVideo) => {
  setPlayVideo(true);
}, 0);

const stopExcerciseNameJob = new Job((setExcerciseName, setCameraStatus) => {
  setExcerciseName({ title: "WORKOUT", type: "" });
  setCameraStatus(SEQUENCE_HIDE_EXCERCISENAME);
}, 0);

const setExcerciseNameJob = new Job((setExcerciseName, setCameraStatus) => {
  setExcerciseName({ title: "WORKOUT", type: "NECK STREACH" });
  // stopExcerciseNameJob.startAfter(3500, setExcerciseName, setCameraStatus);
  stopExcerciseNameJob.startAfter(500, setExcerciseName, setCameraStatus);
}, 0);

const SpottableSection = Spottable("section");
const Container = SpotlightContainerDecorator(
  { enterTo: "default-element" },
  "div"
);

const WorkoutPlayer = ({ panelInfo }) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const mWorker = useRef(null);
  const shakaPlayerRef = useRef(null);
  const playerControlRef = useRef(null);
  const { cameraPosition, useForceCamera } = useSelector((state) => state.common.localSettings);
  const { isAppForeground, webOSVersion, otaId, showLoadingPanel } =
    useSelector((state) => state.common.appStatus, shallowEqual);
  const { cameraList, cameraSize } = useSelector((state) => state.camera);
  const { contentInfos } = useSelector((state) => state.content);
  const { themeMode } = useSelector((state) => state.common.appStatus);

  const [playVideo, setPlayVideo] = useState(false);
  const [cameraStatus, setCameraStatus] = useState(SEQUENCE_INIT);
  const cameraStatusRef = useRef(SEQUENCE_INIT);
  const [isPlayerReady, setIsPlayerReady] = useState(true);
  const [playProgress, setplayProgress] = useState();
  const [validUserActionCount, setValidUserActionCount] = useState(0);
  const canCheckUserActionRef = useRef(true);
  const [excerciseName, setExcerciseName] = useState({
    title: "WORKOUT",
    type: "",
  });
  //videoPlayer popUp
  const [isPlayerEnded, setIsplayerEnded] = useState(false);
  const [isPaused, setPaused] = useState(false);
  const [isCountDownStarted, setIsCountDownStarted] = useState(false);
  const [isRatio, setRatio] = useState("fullscreen");
  const [bgsyncup, setBgsyncup] = useState(true);
  const [autoPtz, setAutoPtz] = useState(false);
  const [isAutoplay, setAutoplay] = useState(false);
  const isAutoplayRef = useRef(isAutoplay);
  const [autoplayCount, setAutoplayCount] = useState(AUTOPLAY_TIME);
  const [isplay, setPlay] = useState(true); // 운동 바로 시작하기
  const [isPlayerModal, setPlayerModal] = useState(false);
  const [isEndPopup, setEndPopup] = useState(""); //pain, finish
  const [isPlayCount, setPlayCount] = useState(0);
  const isRoutine = false; // todo server

  const [angleBaseLine, setAngleBaseLine] = useState([]);
  const [showBPM, setShowBPM] = useState(false);
  const { activeBGSegmentation, deActiveBGSegmentation } = useSetSolution();
  const { activeRPPG, deActiveRPPG, bpm, activatePositionDetection, deActivatePositionDetection, bodyPositionLiveData, } = useActivatePositionDetection();
  const { bpmStatus, bpmWarningText } = useBpm({ bpm });
  const [loopCount, setLoopCount] = useState(0);
  const { cesShowMode } = useSelector((state) => state.common.localSettings);
  const [romDegreeValue, setRomDegreeValue] = useState(0);
  const [forceMsg, setForceMsg] = useState("");
  const [aiCoachingMode, setAiCoachingMode] = useState(false);
  const [aiCoachingGuide, setAiCoachingGuide] = useState(false);
  const [nextbtnActive, setNextbtnActive] = useState(false);
  const currentTimeRef = useRef(0);
  const maxCameraBpmRef = useRef(0);
  const cameraContainerRef = useRef(null);
  const hiddenTimeoutRef = useRef(null);
  const [lastProcessedTime, setLastProcessedTime] = useState(0);
  const maxRangeRef = useRef(0);
  const [userTryCount, setUserTryCount] = useState(0);
  const partMaxRangeRef = useRef(0);
  const userDirection = useRef(undefined);
  const clearPartMaxRangeTimerRef = useRef(null);
  const [partMaxRange, setPartMaxRange] = useState(0);

  const contentInfo = useMemo(() => {
    if (cesShowMode && panelInfo?.noContentInfo) {
      const obj = {
        playUrl: panelInfo.content[isPlayCount]?.playUrl,
        align: panelInfo.content[isPlayCount]?.align
      };

      return obj;
    } else {
      const content =
        (Array.isArray(panelInfo)
          ? contentInfos &&
            panelInfo &&
            panelInfo[isPlayCount]?.contentId &&
            contentInfos[panelInfo[isPlayCount]?.contentId]
          : contentInfos &&
            panelInfo &&
            panelInfo.contentId &&
            contentInfos[panelInfo.contentId]) || {};
      // const content = (contentInfos && panelInfo && panelInfo[isPlayCount]?.contentId && contentInfos[panelInfo[isPlayCount]?.contentId]) || {};
      const obj = {
        title: content?.title,
        playUrl: content?.playUrl,
        playUrlB: content?.playUrlB,
        playTime: content?.playTime,
        playCount: panelInfo?.[isPlayCount]?.count,
        align: 'center'
      };

      setLoopCount(panelInfo[isPlayCount]?.count || 0);
      return obj;
    }
  }, [contentInfos, isPlayCount, panelInfo, cesShowMode]);

  //동영상 replay 버튼 클릭했을때
  const startPlayer = () => {
    setIsplayerEnded(false);
    shakaPlayerRef.current.seekTo(0);
    setPlayVideo(true); //동영상 시작 유무
  };

  const selectedtestkey = useMemo(() => {
    // return panelInfo?.detailTotalInfo?.testkey;
    // todo
    return panelInfo?.contentId;
  }, [panelInfo]);

  const showPIPCamera = useMemo(() => {
    if (!isAppForeground) {
      return false;
    }

    if (typeof window === "object" && window.PalmSystem) {
      return cameraList && cameraList.length > 0 && isPlayerReady;
    } else {
      return isPlayerReady;
    }
  }, [isAppForeground, cameraList]);

  const onResponseWorker = useCallback((e) => {
		if (e.type === 'process') {
			const processResult = e.value;
			// console.log('workoutPlayer processResult', processResult);
      setAngleBaseLine(processResult.baseline);
			setRomDegreeValue(processResult.value);
    }
	}, []);

  const onCameraReady = useCallback(() => {
    dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
    if(bgsyncup && cameraContainerRef.current) {
      cameraContainerRef.current.style.visibility = 'hidden';
      hiddenTimeoutRef.current = setTimeout(() => {
        if (cameraContainerRef.current) {
          cameraContainerRef.current.style.visibility = 'visible';
          setNextbtnActive(true);
        }
      }, 4000);
    }

    if(bgsyncup) {
      activeBGSegmentation();
    }

    setTimeout(() => {
      activeRPPG();
      if(cesShowMode) {
        activatePositionDetection({ isDetectionDelayed: false });
        WebWorkerUtil.postMessage(mWorker.current, { type: "init" }, true);
				WebWorkerUtil.postMessage(mWorker.current, { type: "setParam", value: ["rom", "20", "front"] }, true);
      }
    }, 0);

    // setTimeout(() => {
    //   setCameraStatus(SEQUENCE_SHOW_EXCERCISENAME);
    //   setIsCountDownStarted(false);
    //   setPaused(false);
    //   shakaPlayerRef.current.play();
    // }, 2000);
  }, [dispatch, bgsyncup, activeRPPG, cesShowMode, activeBGSegmentation]);

  const onFocusPlayer = useCallback(() => {
    setPlayerModal(true);

    clearTimeout(playerControlRef.current);
    playerControlRef.current = null;

    playerControlRef.current = setTimeout(() => {
      setPlayerModal(false);
    }, PLAYER_MODAL_DELAY);
  }, []);

  const onPlayerClick = () => {
    setPlayerModal(true);

    clearTimeout(playerControlRef.current);
    playerControlRef.current = null;

    if (!shakaPlayerRef.current.paused()) {
      playerControlRef.current = setTimeout(() => {
        setPlayerModal(false);
      }, PLAYER_MODAL_DELAY);
    }
  };

  const playerControl = () => {
    clearTimeout(playerControlRef.current);
    playerControlRef.current = null;

    if (shakaPlayerRef.current.paused()) {
      setPlayVideo(true);
      shakaPlayerRef.current.seekTo(playProgress);
      shakaPlayerRef.current.play();

      playerControlRef.current = setTimeout(() => {
        setPlayerModal(false);
      }, PLAYER_MODAL_DELAY);
    } else {
      shakaPlayerRef.current.pause();
      setPlayVideo(false);
    }
  };

  const onProgress = useCallback((e) => {
    setplayProgress(e);

    if (shakaPlayerRef && shakaPlayerRef.current) {
      const newCount = Math.floor(shakaPlayerRef.current.getDuration() - e);
      setAutoplayCount(newCount);

      const timing =
        Math.floor(shakaPlayerRef.current.getDuration() - AUTOPLAY_TIME) <=
        Math.floor(e);
      if (
        !isAutoplayRef.current &&
        newCount <= AUTOPLAY_TIME &&
        newCount > 0 &&
        timing
      ) {
        isAutoplayRef.current = true;
        setAutoplay(true);
      }
    }
  }, []);

  const onStop = useCallback(() => {
    shakaPlayerRef.current.seekTo(0);
    shakaPlayerRef.current.pause();
    setPlayVideo(false);
    setIsplayerEnded(true);
  }, []);

  const onVideoSeek = useCallback((progress) => {
    if (shakaPlayerRef.current) {
      clearTimeout(playerControlRef.current);
      playerControlRef.current = null;

      shakaPlayerRef.current.seekTo(progress);
      setplayProgress(progress);

      playerControlRef.current = setTimeout(() => {
        setPlayerModal(false);
      }, PLAYER_MODAL_DELAY);
    }
  }, []);

  const onEnded = useCallback(() => {
    // console.log('workoutplayer loopCount', loopCount,'isPlayCount', isPlayCount);
    if(cesShowMode) {
      onClickNext();
      // dispatch(popPanel());
      // dispatch(addPanels({ name: panel_names.AI_MANAGER_FEEDBACK, panelInfo: { enterFrom: panelInfo.enterFrom, successCount: (validUserActionCount > 20 ? 20 : validUserActionCount), maxRange: maxRangeRef.current }}));
      // if(panelInfo.enterFrom === 'workout') {
      //   dispatch(popPanel(panel_names.PREVIEW_PANEL));
      // } else {
      //   dispatch(popPanel(panel_names.BODY_ALIGNMENT_REPORT));
      // }
      // dispatch(popPanel());
    } else {
      if (Array.isArray(panelInfo)) {
        if (isPlayCount === panelInfo.length - 1) {
          setEndPopup("pain");
        } else if (isPlayCount < panelInfo.length - 1) {
          if (loopCount <= 1) {
            setPlayCount((cur) => {
              return (cur += 1);
            });
            setShowBPM(false);
            setTimeout(() => {
              setShowBPM(true);
            }, 2000);
          } else {
            setLoopCount((cur) => {
              return (cur -= 1);
            });
          }
          onVideoSeek(0);
          setPlayVideo(false);
          isAutoplayRef.current = false;
          setAutoplay(false);
          if (isplay) {
            setPlayVideo(true);
            setTimeout(() => {
              shakaPlayerRef.current.play();
            }, 10);
          }
        }
      } else {
        setEndPopup("pain");
      }
    }
    // 모든 비디오 재생 완료 > 통증 입력 > 재생 완료 > 별점 주기 > workout 진입점으로 이동
  }, [panelInfo, isplay, loopCount, isPlayCount, validUserActionCount, aiCoachingMode]);

  const onDuration = useCallback(() => {
    shakaPlayerRef.current.seekTo(currentTimeRef.current);
  }, []);

  const onCountTick = useCallback((ev) => {
    if (ev === 0) {
      setTimeout(() => {
        setIsCountDownStarted(false);
        setPaused(false);
        shakaPlayerRef.current.play();
      }, 1000);
    }
  }, []);

  const bgsyncupHandler = useCallback(() => {
    !bgsyncup ? activeBGSegmentation() : deActiveBGSegmentation();
    setBgsyncup(!bgsyncup);
    currentTimeRef.current = shakaPlayerRef.current.getCurrentTime();
  }, [bgsyncup, activeBGSegmentation, deActiveBGSegmentation]);

  const ratioHandler = useCallback(() => {
    //todo
    // isRatio === "portrait" ? setRatio("landscape") : setRatio("portrait");

    // Portrait 5:5 > Portrait 7:3 > Landscape > Fullscreen > Portrait 5:5
    if (isRatio === "portrait55") {
      setRatio("portrait73");
    } else if (isRatio === "portrait73") {
      setRatio("landscape");
    } else if (isRatio === "landscape") {
      setRatio("fullscreen");
    } else if (isRatio === "fullscreen") {
      setRatio("portrait55");
    }
  }, [isRatio]);

  const autoPlayerHandler = useCallback(() => {
    isAutoplayRef.current = false;
    setAutoplay(false);
    setPlay(true);
  }, []);

  const retryPrevious = useCallback(() => {
    isAutoplayRef.current = false;
    setAutoplay(false);
    isPlayCount === 0
      ? setPlayCount(isPlayCount)
      : setPlayCount(isPlayCount - 1);

    onVideoSeek(0);
    setPlayVideo(true);
    setTimeout(() => {
      shakaPlayerRef.current.play();
    }, 0);
  }, [isPlayCount]);

  const _onNext = useCallback((id) => {
    if (id === "skip") {
      // 데이터 보내지 않고 닫기
    } else if (id === "ok") {
      // 데이터 보내기
    }

    if (isRoutine) {
      setEndPopup("finish");
    } else {
      setEndPopup("");
      dispatch(popPanel());
    }
  }, []);

  const _onFinish = useCallback((id) => {
    switch (id) {
      case "heart":
        break;
      case "recovery":
        break;
      case "replay":
        break;
      case "result":
        break;
    }
    setEndPopup("");
    dispatch(popPanel());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(changeAppStatus({showLoadingPanel: {show: true, type: 'tips'}}));
    dispatch(updateMaxCameraBpm(0));
    dispatch(changeThemeModeStatus("dark"));
    Spotlight.focus("#shakaWorkoutPlayer");

    if (!showLoadingPanel.show) {
      setCameraStatus(SEQUENCE_SHOW_EXCERCISENAME);
      setIsCountDownStarted(false);
      setPaused(false);
      shakaPlayerRef.current.play();
      if(cesShowMode){
        WebWorkerUtil.makeWorker(WORKER_ID.XFIT, onResponseWorker).then((workerId) => {
          mWorker.current = workerId;
        });
      }
    }

    return () => {
      dispatch(changeThemeModeStatus("light"));
      dispatch(updateMaxCameraBpm(maxCameraBpmRef.current));
      deActivatePositionDetection();
      dispatch(changeAppStatus({ showLoadingPanel: { show: false } }));
      if (playerControlRef.current) {
        playerControlRef.current = null;
      }
      if (hiddenTimeoutRef.current) {
        hiddenTimeoutRef.current = null;
      }
      deActiveRPPG();
      deActiveBGSegmentation();
    };
  }, []);

  useEffect(() => {
		if (bodyPositionLiveData !== undefined && !!bodyPositionLiveData?.id) {
			WebWorkerUtil.postMessage(mWorker.current, { type: "process", value: [bodyPositionLiveData] }, true);
		}
	}, [bodyPositionLiveData]);

  useEffect(() => {
    cameraStatusRef.current = cameraStatus;
    console.log("Workout cameraStatus ", cameraStatus);
    switch (cameraStatus) {
      case SEQUENCE_INIT: {
        break;
      }
      case SEQUENCE_SHOW_EXCERCISENAME: {
        // setIsplayerEnded(false);
        setExcerciseNameJob.startAfter(0, setExcerciseName, setCameraStatus);
        break;
      }
      case SEQUENCE_HIDE_EXCERCISENAME: {
        setPlayVideo(true);
        setCameraStatus(SEQUENCE_PLAY_VIDEO);
        break;
      }
      case SEQUENCE_PLAY_VIDEO: {
        shakaPlayerRef.current.play();
        break;
      }
    }
  }, [cameraStatus]);

  useEffect(() => {
    if (cameraStatus) {
      setPaused(true);
      setIsCountDownStarted(true);
    }
    setShowBPM(true);
    dispatch(changeThemeModeStatus("dark"));
    return () => {
      dispatch(changeThemeModeStatus("light"));
      setShowBPM(false);
    };
  }, []);

  const ratioStr = useMemo(() => {
    switch (isRatio) {
      case "portrait55":
        return $L("Portrait 7:3");
      case "portrait73":
        return $L("Landscape");
      case "landscape":
        return $L("Fullscreen");
      case "fullscreen":
        return $L("Portrait 5:5");
    }
    return "";
  }, [isRatio]);

  const onClickNext = useCallback(() => {
    if (!aiCoachingMode) {
      setAiCoachingGuide(true);
      // next video
      setPlayCount((cur) => {
        return (cur += 1);
      });
      setTimeout(() => {
        setPlayVideo(false);
        setBgsyncup(false);
        deActiveBGSegmentation();
        setAiCoachingMode(true);
        setRatio("portrait55");
        shakaPlayerRef.current.pause();
      }, 0);
    } else {
      dispatch(popPanel());
      dispatch(addPanels({ name: panel_names.AI_MANAGER_FEEDBACK, panelInfo: { enterFrom: panelInfo.enterFrom, successCount: validUserActionCount, maxRange: maxRangeRef.current.toFixed(1), userTryCount }}));
      // if (panelInfo.enterFrom === 'workout') {
      //   dispatch(popPanel(panel_names.PREVIEW_PANEL));
      // } else if (panelInfo.enterFrom === 'bodyAlignmentSummary') {
      //   dispatch(popPanel(panel_names.BODY_ALIGNMENT_REPORT));
      // }
      // dispatch(popPanel());
    }
  }, [aiCoachingMode, panelInfo, deActiveBGSegmentation, validUserActionCount, userTryCount]);

  const backKeyHandler = useCallback(() => {
    // preview pop 의도적 2번 pop
    dispatch(popPanel());
  }, []);

  const roundBpm = useMemo(() => {
    const ret = bpm ? Math.round(bpm) : 0;
    return ret && ret !== 0 ? `<span>${ret}</span> BPM` : `<span>···</span>`;
  }, [bpm]);

	useEffect(() => {
		WebWorkerUtil.setCallback(mWorker.current, onResponseWorker);
	}, [onResponseWorker]);

  const overDegreeForceMsg = [$L("You have good waist flexibility."), $L("You're doing well."), $L(" It's a good movement.")];
  const underDegreeForceMsg = [$L("Please bend your waist to the side further."), $L("Move your waist to the side more.")];

  useEffect(() => {
		if (aiCoachingMode) {
      const overRandomIdx = Math.floor(Math.random() * overDegreeForceMsg.length);
      const underRandomIdx = Math.floor(Math.random() * underDegreeForceMsg.length);
      if (playProgress >= DEGREE_START && playProgress <= DEGREE_END) {
        // message
        if(Date.now() - lastProcessedTime > 2000){
          if (romDegreeValue >= ROM_NICE_ANGLE) {
            setForceMsg(overDegreeForceMsg[overRandomIdx]);
          } else {
            setForceMsg(underDegreeForceMsg[underRandomIdx]);
          }

          setLastProcessedTime(Date.now());
        }

        // success Count
        if(romDegreeValue >=ROM_NICE_ANGLE && canCheckUserActionRef.current){
          setValidUserActionCount((cur) => cur + 1);
          canCheckUserActionRef.current = false;
        }

        let direction = false;
        if(!!angleBaseLine?.length && angleBaseLine[0][0] < angleBaseLine[1][0]){
          direction = true;
        }
        let directionChanged = false;
        if(userDirection.current !== undefined && userDirection.current !== direction){ //direction changed
          canCheckUserActionRef.current = true;
          directionChanged = true;
        }
        if(userDirection.current === undefined && romDegreeValue < ROM_FIRST_IGNORE_ANGLE){
          console.log('WorkoutPlayer ignore first direction changed dummy', romDegreeValue);
        }else{
          userDirection.current = direction;
        }

        const intDegreeValue = Math.floor(romDegreeValue);
        // max Degree
        if(maxRangeRef.current < intDegreeValue) {
          maxRangeRef.current = intDegreeValue;
        }

        if(directionChanged){
          if(clearPartMaxRangeTimerRef.current){
            clearTimeout(clearPartMaxRangeTimerRef.current);
            clearPartMaxRangeTimerRef.current = null;
          }
          setPartMaxRange(partMaxRangeRef.current);
          setUserTryCount((cur)=> cur+1);
          partMaxRangeRef.current=0;
          clearPartMaxRangeTimerRef.current = setTimeout(() => {
            setPartMaxRange(-1);
          }, 1500);
        }
        //partMaxRangeRef
        if(partMaxRangeRef.current < romDegreeValue){
          partMaxRangeRef.current = romDegreeValue;
        }
      } else if (playProgress < DEGREE_START) {
        setForceMsg($L("Lean your chest sideways by at least {angle} degrees, making sure to stay within your comfortable range.").replace("{angle}", ROM_NICE_ANGLE));
      } else {
        setForceMsg(""); // over DEGREE_END
      }
		} else {
      if (Number(bpm) > 136 && Number(bpm) <= 160) {
        setForceMsg($L("고강도 권장범위 심박수보다 높아요"));
      } else if (Number(bpm) > 160) {
        setForceMsg($L("연령별 최대심박수보다 높아요"));
      }
    }

    if(Number(bpm) > 0 && bpm > maxCameraBpmRef.current){
      maxCameraBpmRef.current = bpm;
    }
	}, [playProgress, romDegreeValue]);

  const aiCoachingGuideHandler = useCallback(() => {
    setTimeout(() => {
      shakaPlayerRef.current.seekTo(0);
    }, 0);
    setAiCoachingGuide(false);
    setPlayVideo(true);
    setTimeout(() => {
      shakaPlayerRef.current.play();
    }, 0);
  }, []);

  const aiCoachingGuideClose = useCallback(() => {
    dispatch(popPanel());
    if(panelInfo.enterFrom === 'bodyAlignment_summary') {
      dispatch(popPanel(panel_names.PREVIEW_PANEL));
    }
  }, [panelInfo]);

  return (
    <TPanel
      handleCancel={backKeyHandler}
      className={classnames(
        css.Tpanel,
        bgsyncup ? css.portrait55 : css[isRatio],
        contentInfo?.align === "left" && css.alignLeft
      )}
    >
    {/* <TPanel
      className={classnames(
        css.Tpanel,
        bgsyncup ? css.portrait55 : css[isRatio]
      )}
    > */}
      <div className={css.container}>
        <div className={css.headerBg} />
        <Container className={css.guideTitWrap}>
          {isPlayerModal && (
            <TIconButton className={css.back} iconType={ICON_TYPES.back} onClick={backKeyHandler} />
          )}
          {!aiCoachingMode && <>
            <TButton
              id="bgsyncup"
              className={classnames(css.bgBtn, css.dark)}
              type={TYPES.withIcon}
              icon={ICONS.bgsyncup}
              onClick={bgsyncupHandler}
              // disabled={isRatio !== "portrait55" || autoPtz}
            >
              {$L("Working out together")}
            </TButton>
            <TButton
              className={css.ratioBtn}
              type={TYPES.withIcon}
              // icon={(isRatio === "portrait55" || isRatio === "portrait73") ? ICONS.portrait : ICONS.landscape}
              icon={ICONS[isRatio]}
              onClick={ratioHandler}
              disabled={bgsyncup}
            >
              {ratioStr}
            </TButton>
          </>}
        </Container>
        <div className={css.playContainer}>
          <div className={css.cl_bgVideoType}>
            <SpottableSection
              className={css.playSection}
              id="shakaWorkoutPlayer"
              onClick={onPlayerClick}
              spotlightDisabled={isPlayerModal}
              // onFocus={onFocusPlayer}
            >
              <div
                className={classnames(css.avatar, bgsyncup ? css.full : null)}
              >
                <TShakaPlayer
                  playerRef={shakaPlayerRef}
                  className={css["shaka-player"]}
                  style={{ zIndex: -1 }}
                  src={
                    bgsyncup && contentInfo?.playUrlB
                      ? contentInfo?.playUrlB
                      : contentInfo?.playUrl
                  }
                  onProgress={onProgress}
                  onEnded={onEnded} //영상끝날때모달
                  onDuration={onDuration}
                  loop={loopCount <= 1 && false}
                />
              </div>
              {showPIPCamera && (
                <div
                  className={classnames(
                    css.user,
                    !bgsyncup && isRatio === "fullscreen" ? css.hidden : null,
                    bgsyncup ? css.transparent : null
                  )}
                  ref={cameraContainerRef}
                >
                  <PIPCamera
                    webcamRef={webcamRef}
                    // todo
                    size={
                      bgsyncup
                        ? "medium"
                        : isRatio === "portrait55"
                        ? "medium"
                        : isRatio === "portrait73"
                        ? "tiny"
                        : "small"
                    }
                    position={cameraPosition}
                    onCameraReady={onCameraReady}
                    transparency={true}
                  />
                </div>
              )}
            	{/* Guide text */}
        			{forceMsg &&
        				<TestGuideSignMsg className={css.forceMsg} message={forceMsg} useTimeout={!aiCoachingMode} />
        			}
            </SpottableSection>
          </div>
          {isPlayerModal && (
            // {!playVideo && cameraStatus === SEQUENCE_PLAY_VIDEO && (
            <WorkoutPlayerModal
              onClick={playerControl}
              startPlayingVideoJob={startPlayingVideoJob}
              setPlayVideo={setPlayVideo}
              playVideo={playVideo}
              playDuration={shakaPlayerRef?.current.getDuration()}
              playProgress={playProgress}
              onVideoSeek={onVideoSeek}
              size={"full"}
              bgsyncup={bgsyncup}
              autoPtz={autoPtz}
              setAutoPtz={setAutoPtz}
              // size={bgsyncup ? "full" : (isRatio === "portrait55" ? "half" : (isRatio === "portrait73" ? "half73" : "landscape"))}
            />
          )}
          {/* {isCountDownStarted &&
            <div className={css.countLayer}>
              <div className={css.ready}>
                {$L("Are you ready to work out?")}
              </div>
              <CountdownTimer
                isTestingPaused={isPaused}
                isCountDownStarted={isCountDownStarted}
                hasStart={true}
                onTick={onCountTick}
              />
            </div>} */}
        </div>
        {/* max degree(range) */}
				{aiCoachingMode && partMaxRange > 0 &&
					<div className={classnames(css.degreeContainer, css.maxdegree)}>
            <div className={css.gradeWrapper}>
              <div className={css.userTryCount}>
                {userTryCount}
              </div>
              <div className={classnames(css.subGrade, partMaxRange >= ROM_NICE_ANGLE ? css.good : css.danger)}>
                {partMaxRange >= ROM_NICE_ANGLE ? $L("Good") : $L("Try more")}
              </div>
            </div>
						<span />{playProgress >= DEGREE_START ? partMaxRange.toFixed(1) : "0.0"}°
            <div className={css.subTitle}>{$L("Bend at least {angle}°").replace("{angle}", ROM_NICE_ANGLE)}</div>
					</div>
				}
        {/* user degree */}
				{aiCoachingMode &&
					<div className={css.degreeContainer}>
						<span />{(playProgress >= DEGREE_START && playProgress <= DEGREE_END) ? romDegreeValue.toFixed(1) : "0.0"}°
					</div>
				}
        {!aiCoachingMode && showPIPCamera && showBPM && (
          <div className={css.bpmContainer}>
            <div className={css.bpmBox}>
              <BPMChart width={227} height={74} data={bpm} />
            </div>
            <div className={css.bodyInfo}>
              <div className={css.bpmLayer}>
                <img src={HEART} alt="heart" />
                <div
                  className={css.bpm}
                  dangerouslySetInnerHTML={{ __html: roundBpm }}
                />
              </div>
              <div className={css.bpmStatus}>{bpmStatus}</div>
            </div>
          </div>
        )}
        {cesShowMode &&
          <TButton
            className={classnames(css.finishBtn)}
            onClick={onClickNext}
            size={SIZES.large}
            disabled={!nextbtnActive}
          >
            {(panelInfo.enterFrom === 'workout' && aiCoachingMode) ? $L("Finish") : $L("Next")}
          </TButton>
        }
        {aiCoachingMode && (playProgress >= DEGREE_START && playProgress <= DEGREE_END) &&
          <AngleGuideCanvas points={angleBaseLine} cameraSize={cameraSize} direction={'auto'} />
        }
        {loopCount <= 1 &&
          autoplayCount <= AUTOPLAY_TIME &&
          isAutoplay &&
          Array.isArray(panelInfo) &&
          isPlayCount !== panelInfo.length - 1 && (
            <TAutoPlayer
              setAutoplay={setAutoplay}
              setPlay={setPlay}
              onClose={autoPlayerHandler}
              count={autoplayCount}
              retryPrevious={retryPrevious}
              nextTitle={
                contentInfos?.[panelInfo?.[isPlayCount + 1]?.contentId]
                  ?.title || ""
              }
              nextDetail={
                contentInfos?.[panelInfo?.[isPlayCount + 1]?.contentId]
                  ?.title || ""
              }
              nextDuration={
                contentInfos?.[panelInfo?.[isPlayCount + 1]?.contentId]
                  ?.playTime || 0
              }
              nextThurm={
                contentInfos?.[panelInfo?.[isPlayCount + 1]?.contentId]
                  ?.thumbnailUrl
              }
            />
          )}
      </div>
      {isEndPopup === "pain" && <PainModal onNext={_onNext} />}
      {isEndPopup === "finish" && <FinishModal onFinish={_onFinish} />}
      {cesShowMode && aiCoachingGuide &&
        <Alert
          open
          type={"fullscreen"}
          className={css.aiCoachingGuidePopup}
          onClose={aiCoachingGuideClose}
        >
          <div className={css.container}>
            <img src={IMG_PAIN}/>
            <div>{"Let's find out about AI coaching!"}</div>
            <TButton
              className={css.startBtn}
              size={SIZES.xLarge}
              onClick={aiCoachingGuideHandler}
            >
              {$L("Start")}
            </TButton>
          </div>
        </Alert>
      }
    </TPanel>
  );
};

export default WorkoutPlayer;
