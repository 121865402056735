//BodyAlignment
import M1 from "../../assets/voice/BTS/m1.mp3";
import M2 from "../../assets/voice/BTS/m2.mp3";
import M3 from "../../assets/voice/BTS/m3.mp3";
import M3_1 from "../../assets/voice/BTS/m3_1.mp3";
import M3_2 from "../../assets/voice/BTS/m3_2.mp3";
import M3_3 from "../../assets/voice/BTS/m3_3.mp3";
import M3_4 from "../../assets/voice/BTS/m3_4.mp3";
import M3_5 from "../../assets/voice/BTS/m3_5.mp3";
import M4 from "../../assets/voice/BTS/m4.mp3";
import M6 from "../../assets/voice/BTS/m6.mp3";
import M7 from "../../assets/voice/BTS/m7.mp3";
import M8 from "../../assets/voice/BTS/m8.mp3";
import M9 from "../../assets/voice/BTS/m9.mp3";
import M10 from "../../assets/voice/BTS/m10.mp3";
import ML from "../../assets/voice/bodyAlignment/ml.mp3";
import MR from "../../assets/voice/bodyAlignment/mr.mp3";
import MF from "../../assets/voice/bodyAlignment/mf.mp3";
import MB from "../../assets/voice/bodyAlignment/mb.mp3";
import TL from "../../assets/voice/bodyAlignment/tl.mp3";
import TR from "../../assets/voice/bodyAlignment/tr.mp3";
import MCD from "../../assets/voice/bodyAlignment/moveCameraDown.mp3";
import MCU from "../../assets/voice/bodyAlignment/moveCameraUp.mp3";
import MEASURESOUND from "../../assets/voice/bodyAlignment/Scan_MASTER.mp3";

//bodyBalance
import noLeg from "../../assets/voice/bodyBalance/noLeg.mp3";
import singleLegLeft from "../../assets/voice/bodyBalance/singleLegLeft.mp3";
import singleLegRight from "../../assets/voice/bodyBalance/singleLegRight.mp3";
import backLeft from "../../assets/voice/bodyBalance/warnLeftFootLeft.mp3";
import forwardLeft from "../../assets/voice/bodyBalance/warnLeftFootUp.mp3";
import rightLeft from "../../assets/voice/bodyBalance/warnLeftFootRight.mp3";
import leftLeft from "../../assets/voice/bodyBalance/warnLeftFootLeft.mp3";
import backRight from "../../assets/voice/bodyBalance/warnRightFootLeft.mp3";
import forwardRight from "../../assets/voice/bodyBalance/warnRightFootUp.mp3";
import rightRight from "../../assets/voice/bodyBalance/warnRightFootRight.mp3";
import leftRight from "../../assets/voice/bodyBalance/warnRightFootLeft.mp3";
import otherFoot from "../../assets/voice/bodyBalance/plzChangeFoot.mp3";
import finishedLeft from "../../assets/voice/bodyBalance/leftLegStandDone.mp3";

//rom
import neckSideFix from "../../assets/voice/rom/neckSideFix.mp3";
import neckShoulderFix from "../../assets/voice/rom/neckShoulderFix.mp3";
import shoulderSideFix from "../../assets/voice/rom/shoulderSideFix.mp3";
import trunkFix from "../../assets/voice/rom/trunkFix.mp3";
import trunkSideFix from "../../assets/voice/rom/trunkSideFix.mp3";
import kneeFix from "../../assets/voice/rom/trunkSideFix.mp3";
import hipFix from "../../assets/voice/rom/trunkSideFix.mp3";

//PhysicalTest
import M11 from "../../assets/voice/BTS/m11.mp3";
import M12 from "../../assets/voice/BTS/m12.mp3";
import M13 from "../../assets/voice/BTS/m13.mp3";
import M15 from "../../assets/voice/BTS/m15.mp3";
import M17 from "../../assets/voice/BTS/m17.mp3";
import M18 from "../../assets/voice/BTS/m18.mp3";
import M19 from "../../assets/voice/BTS/m19.mp3";
import M21 from "../../assets/voice/BTS/m21.mp3";
import M23 from "../../assets/voice/BTS/m23.mp3";


import SFT_3SEC_REMINDER from "../../assets/voice/BTS/SFT_dummy/3secReminder.mp3";
import SFT2 from "../../assets/voice/BTS/SFT_dummy/sft2.mp3";
import SFT3 from "../../assets/voice/BTS/SFT_dummy/sft3.mp3";
import SFT5 from "../../assets/voice/BTS/SFT_dummy/sft5.mp3";
import SFT6 from "../../assets/voice/BTS/SFT_dummy/sft6.mp3";
import SFT7 from "../../assets/voice/BTS/SFT_dummy/sft7.mp3";
import SFT8 from "../../assets/voice/BTS/SFT_dummy/sft8.mp3";

import B1 from "../../assets/voice/BTS/BTSResult/B1.mp3";
//BodyAlignment > My Report(Overall)
import B2_1 from "../../assets/voice/BTS/BTSResult/B2-1.mp3";
import B2_2 from "../../assets/voice/BTS/BTSResult/B2-2.mp3";
import B2_3 from "../../assets/voice/BTS/BTSResult/B2-3.mp3";
import B2_4 from "../../assets/voice/BTS/BTSResult/B2-4.mp3";
import B2_5 from "../../assets/voice/BTS/BTSResult/B2-5.mp3";
import B2_6 from "../../assets/voice/BTS/BTSResult/B2-6.mp3";
import B2_7 from "../../assets/voice/BTS/BTSResult/B2-7.mp3";
import B2_8 from "../../assets/voice/BTS/BTSResult/B2-8.mp3";
import B2_9 from "../../assets/voice/BTS/BTSResult/B2-9.mp3";
import B3_1 from "../../assets/voice/BTS/BTSResult/B3-1.mp3";
import B3_2 from "../../assets/voice/BTS/BTSResult/B3-2.mp3";
import B3_3 from "../../assets/voice/BTS/BTSResult/B3-3.mp3";
import B4 from "../../assets/voice/BTS/BTSResult/B4.mp3";

//BodyAlignment 정규분포 그래프
import B2_A_1 from "../../assets/voice/BTS/BTSResult/B2-A-1.mp3";
import B2_A_2 from "../../assets/voice/BTS/BTSResult/B2-A-2.mp3";
import B2_A_3 from "../../assets/voice/BTS/BTSResult/B2-A-3.mp3";
import B2_A_4 from "../../assets/voice/BTS/BTSResult/B2-A-4.mp3";
import B2_B_1 from "../../assets/voice/BTS/BTSResult/B2-B-1.mp3";
import B2_B_2 from "../../assets/voice/BTS/BTSResult/B2-B-2.mp3";
import B2_B_3 from "../../assets/voice/BTS/BTSResult/B2-B-3.mp3";
import B2_B_4 from "../../assets/voice/BTS/BTSResult/B2-B-4.mp3";
import B2_C_1 from "../../assets/voice/BTS/BTSResult/B2-C-1.mp3";
import B2_C_2 from "../../assets/voice/BTS/BTSResult/B2-C-2.mp3";
import B2_C_3 from "../../assets/voice/BTS/BTSResult/B2-C-3.mp3";
import B2_C_4 from "../../assets/voice/BTS/BTSResult/B2-C-4.mp3";
import B2_D_1 from "../../assets/voice/BTS/BTSResult/B2-D-1.mp3";
import B2_D_2 from "../../assets/voice/BTS/BTSResult/B2-D-2.mp3";
import B2_D_3 from "../../assets/voice/BTS/BTSResult/B2-D-3.mp3";
import B2_D_4 from "../../assets/voice/BTS/BTSResult/B2-D-4.mp3";
import B2_E_1 from "../../assets/voice/BTS/BTSResult/B2-E-1.mp3";
import B2_E_2 from "../../assets/voice/BTS/BTSResult/B2-E-2.mp3";
import B2_E_3 from "../../assets/voice/BTS/BTSResult/B2-E-3.mp3";
import B2_E_4 from "../../assets/voice/BTS/BTSResult/B2-E-4.mp3";
import B2_F_1 from "../../assets/voice/BTS/BTSResult/B2-F-1.mp3";
import B2_F_2 from "../../assets/voice/BTS/BTSResult/B2-F-2.mp3";
import B2_F_3 from "../../assets/voice/BTS/BTSResult/B2-F-3.mp3";
import B2_F_4 from "../../assets/voice/BTS/BTSResult/B2-F-4.mp3";
import B2_G_1 from "../../assets/voice/BTS/BTSResult/B2-G-1.mp3";
import B2_G_2 from "../../assets/voice/BTS/BTSResult/B2-G-2.mp3";
import B2_G_3 from "../../assets/voice/BTS/BTSResult/B2-G-3.mp3";
import B2_G_4 from "../../assets/voice/BTS/BTSResult/B2-G-4.mp3";
import B2_H_1 from "../../assets/voice/BTS/BTSResult/B2-H-1.mp3";
import B2_H_2 from "../../assets/voice/BTS/BTSResult/B2-H-2.mp3";
import B2_H_3 from "../../assets/voice/BTS/BTSResult/B2-H-3.mp3";
import B2_H_4 from "../../assets/voice/BTS/BTSResult/B2-H-4.mp3";
import B2_I_1 from "../../assets/voice/BTS/BTSResult/B2-I-1.mp3";
import B2_I_2 from "../../assets/voice/BTS/BTSResult/B2-I-2.mp3";
import B2_I_3 from "../../assets/voice/BTS/BTSResult/B2-I-3.mp3";
import B2_I_4 from "../../assets/voice/BTS/BTSResult/B2-I-4.mp3";

//physicalTest
import S1 from "../../assets/voice/BTS/BTSResult/S1.mp3";
import S2 from "../../assets/voice/BTS/BTSResult/S2.mp3";
import S3 from "../../assets/voice/BTS/BTSResult/S3.mp3";
import S4_1 from "../../assets/voice/BTS/BTSResult/S4-1.mp3";
import S4_4 from "../../assets/voice/BTS/BTSResult/S4-4.mp3";
import S4_7 from "../../assets/voice/BTS/BTSResult/S4-7.mp3";
import S4_8 from "../../assets/voice/BTS/BTSResult/S4-8.mp3";
import S4_9 from "../../assets/voice/BTS/BTSResult/S4-9.mp3";

import ROMBGM from "../../assets/voice/rom/romBGM.mp3";
import DISCOPOP from "../../assets/voice/BTS/1_Disco.m4a";
import FUNK_HAPPY from "../../assets/voice/BTS/2_Funk_Happy.m4a";
import SUMMER_DISCO from "../../assets/voice/BTS/3_Summer_Disco.m4a";
import M5_3_2_1 from "../../assets/voice/BTS/m5_3__2__1.mp3";
import CLICK from "../../assets/voice/BTS/Click.mp3";
import RING2 from "../../assets/voice/BTS/Ring2.mp3";
import SCH_BUTTON_10 from "../../assets/voice/BTS/SCHButton10.mp3";
import SCH_BUTTON_5 from "../../assets/voice/BTS/SCHButton5.mp3";
import SCH_BUTTON_3 from "../../assets/voice/BTS/SCHButton3.mp3";
import YEAH from "../../assets/voice/BTS/Yeah.mp3";

import COUNT_1 from "../../assets/voice/counts/01_mp3.mp3";
import COUNT_2 from "../../assets/voice/counts/02_mp3.mp3";
import COUNT_3 from "../../assets/voice/counts/03_mp3.mp3";
import COUNT_4 from "../../assets/voice/counts/04_mp3.mp3";
import COUNT_5 from "../../assets/voice/counts/05_mp3.mp3";
import COUNT_6 from "../../assets/voice/counts/06_mp3.mp3";
import COUNT_7 from "../../assets/voice/counts/07_mp7.mp3";
import COUNT_8 from "../../assets/voice/counts/08_mp3.mp3";
import COUNT_9 from "../../assets/voice/counts/09_mp3.mp3";
import COUNT_10 from "../../assets/voice/counts/10_mp3.mp3";
import COUNT_11 from "../../assets/voice/counts/11_mp3.mp3";
import COUNT_12 from "../../assets/voice/counts/12_mp3.mp3";
import COUNT_13 from "../../assets/voice/counts/13_mp3.mp3";
import COUNT_14 from "../../assets/voice/counts/14_mp3.mp3";
import COUNT_15 from "../../assets/voice/counts/15_mp3.mp3";
import COUNT_16 from "../../assets/voice/counts/16_mp3.mp3";
import COUNT_17 from "../../assets/voice/counts/17_mp3.mp3";
import COUNT_18 from "../../assets/voice/counts/18_mp3.mp3";
import COUNT_19 from "../../assets/voice/counts/19_mp3.mp3";
import COUNT_20 from "../../assets/voice/counts/20_mp3.mp3";
import COUNT_21 from "../../assets/voice/counts/21_mp3.mp3";
import COUNT_22 from "../../assets/voice/counts/22_mp3.mp3";
import COUNT_23 from "../../assets/voice/counts/23_mp3.mp3";
import COUNT_24 from "../../assets/voice/counts/24_mp3.mp3";
import COUNT_25 from "../../assets/voice/counts/25_mp3.mp3";
import COUNT_26 from "../../assets/voice/counts/26_mp3.mp3";
import COUNT_27 from "../../assets/voice/counts/27_mp3.mp3";
import COUNT_28 from "../../assets/voice/counts/28_mp3.mp3";
import COUNT_29 from "../../assets/voice/counts/29_mp3.mp3";
import COUNT_30 from "../../assets/voice/counts/30_mp3.mp3";
import COUNT_31 from "../../assets/voice/counts/31_mp3.mp3";
import COUNT_32 from "../../assets/voice/counts/32_mp3.mp3";
import COUNT_33 from "../../assets/voice/counts/33_mp3.mp3";
import COUNT_34 from "../../assets/voice/counts/34_mp3.mp3";
import COUNT_35 from "../../assets/voice/counts/35_mp3.mp3";
import COUNT_36 from "../../assets/voice/counts/36_mp3.mp3";
import COUNT_37 from "../../assets/voice/counts/37_mp3.mp3";
import COUNT_38 from "../../assets/voice/counts/38_mp3.mp3";
import COUNT_39 from "../../assets/voice/counts/39_mp3.mp3";
import COUNT_40 from "../../assets/voice/counts/40_mp3.mp3";
import COUNT_41 from "../../assets/voice/counts/41_mp3.mp3";
import COUNT_42 from "../../assets/voice/counts/42_mp3.mp3";
import COUNT_43 from "../../assets/voice/counts/43_mp3.mp3";
import COUNT_44 from "../../assets/voice/counts/44_mp3.mp3";
import COUNT_45 from "../../assets/voice/counts/45_mp3.mp3";
import COUNT_46 from "../../assets/voice/counts/46_mp3.mp3";
import COUNT_47 from "../../assets/voice/counts/47_mp3.mp3";
import COUNT_48 from "../../assets/voice/counts/48_mp3.mp3";
import COUNT_49 from "../../assets/voice/counts/49_mp3.mp3";
import COUNT_50 from "../../assets/voice/counts/50_mp3.mp3";
import COUNT_51 from "../../assets/voice/counts/51.mp3";
import COUNT_52 from "../../assets/voice/counts/52.mp3";
import COUNT_53 from "../../assets/voice/counts/53.mp3";
import COUNT_54 from "../../assets/voice/counts/54.mp3";
import COUNT_55 from "../../assets/voice/counts/55.mp3";
import COUNT_56 from "../../assets/voice/counts/56.mp3";
import COUNT_57 from "../../assets/voice/counts/57.mp3";
import COUNT_58 from "../../assets/voice/counts/58.mp3";
import COUNT_59 from "../../assets/voice/counts/59.mp3";
import COUNT_60 from "../../assets/voice/counts/60.mp3";
import COUNT_61 from "../../assets/voice/counts/61.mp3";
import COUNT_62 from "../../assets/voice/counts/62.mp3";
import COUNT_63 from "../../assets/voice/counts/63.mp3";
import COUNT_64 from "../../assets/voice/counts/64.mp3";
import COUNT_65 from "../../assets/voice/counts/65.mp3";
import COUNT_66 from "../../assets/voice/counts/66.mp3";
import COUNT_67 from "../../assets/voice/counts/67.mp3";
import COUNT_68 from "../../assets/voice/counts/68.mp3";
import COUNT_69 from "../../assets/voice/counts/69.mp3";
import COUNT_70 from "../../assets/voice/counts/70.mp3";
import COUNT_71 from "../../assets/voice/counts/71.mp3";
import COUNT_72 from "../../assets/voice/counts/72.mp3";
import COUNT_73 from "../../assets/voice/counts/73.mp3";
import COUNT_74 from "../../assets/voice/counts/74.mp3";
import COUNT_75 from "../../assets/voice/counts/75.mp3";
import COUNT_76 from "../../assets/voice/counts/76.mp3";
import COUNT_77 from "../../assets/voice/counts/77.mp3";
import COUNT_78 from "../../assets/voice/counts/78.mp3";
import COUNT_79 from "../../assets/voice/counts/79.mp3";
import COUNT_80 from "../../assets/voice/counts/80.mp3";
import COUNT_81 from "../../assets/voice/counts/81.mp3";
import COUNT_82 from "../../assets/voice/counts/82.mp3";
import COUNT_83 from "../../assets/voice/counts/83.mp3";
import COUNT_84 from "../../assets/voice/counts/84.mp3";
import COUNT_85 from "../../assets/voice/counts/85.mp3";
import COUNT_86 from "../../assets/voice/counts/86.mp3";
import COUNT_87 from "../../assets/voice/counts/87.mp3";
import COUNT_88 from "../../assets/voice/counts/88.mp3";
import COUNT_89 from "../../assets/voice/counts/89.mp3";
import COUNT_90 from "../../assets/voice/counts/90.mp3";
import COUNT_91 from "../../assets/voice/counts/91.mp3";
import COUNT_92 from "../../assets/voice/counts/92.mp3";
import COUNT_93 from "../../assets/voice/counts/93.mp3";
import COUNT_94 from "../../assets/voice/counts/94.mp3";
import COUNT_95 from "../../assets/voice/counts/95.mp3";
import COUNT_96 from "../../assets/voice/counts/96.mp3";
import COUNT_97 from "../../assets/voice/counts/97.mp3";
import COUNT_98 from "../../assets/voice/counts/98.mp3";
import COUNT_99 from "../../assets/voice/counts/99.mp3";
import COUNT_100 from "../../assets/voice/counts/100.mp3";

// Activity
import A1 from "../../assets/voice/BTS/a1.mp3";

// EN Source
import EN_M1 from "../../assets/voice_en/BTS/en_m1.mp3";
import EN_M2 from "../../assets/voice_en/BTS/en_m2.mp3";
import EN_M3_1 from "../../assets/voice_en/BTS/en_m3_1.mp3";
import EN_M3_2 from "../../assets/voice_en/BTS/en_m3_2.mp3";
import EN_M3_3 from "../../assets/voice_en/BTS/en_m3_3.mp3";
import EN_M3_4 from "../../assets/voice_en/BTS/en_m3_4.mp3";
import EN_M3_5 from "../../assets/voice_en/BTS/en_m3_5.mp3";
import EN_M4 from "../../assets/voice_en/BTS/en_m4.mp3";
import EN_M5_3_2_1 from "../../assets/voice_en/BTS/en_m5_3__2__1.mp3";
import EN_M6 from "../../assets/voice_en/BTS/en_m6.mp3";
import EN_M7 from "../../assets/voice_en/BTS/en_m7.mp3";
import EN_M8 from "../../assets/voice_en/BTS/en_m8.mp3";
import EN_M9 from "../../assets/voice_en/BTS/en_m9.mp3";
import EN_M10 from "../../assets/voice_en/BTS/en_m10.mp3";

import EN_ML from "../../assets/voice/bodyAlignment/moveTotheLeft.mp3";
import EN_MR from "../../assets/voice/bodyAlignment/moveTotheRight.mp3";
import EN_MF from "../../assets/voice/bodyAlignment/moveForward.mp3";
import EN_MB from "../../assets/voice/bodyAlignment/moveBack.mp3";
import EN_TL from "../../assets/voice/bodyAlignment/turnLeft.mp3";
import EN_TR from "../../assets/voice/bodyAlignment/turnRight.mp3";

import EN_M11 from "../../assets/voice_en/BTS/en_m11.mp3";
import EN_M12 from "../../assets/voice_en/BTS/en_m12.mp3";
import EN_M13 from "../../assets/voice_en/BTS/en_m13.mp3";
import EN_M15 from "../../assets/voice_en/BTS/en_m15.mp3";
import EN_M17 from "../../assets/voice_en/BTS/en_m17.mp3";
import EN_M18 from "../../assets/voice_en/BTS/en_m18.mp3";
import EN_M19 from "../../assets/voice_en/BTS/en_m19.mp3";
import EN_M21 from "../../assets/voice_en/BTS/en_m21.mp3";
import EN_M23 from "../../assets/voice_en/BTS/en_m23.mp3";

import EN_SFT_3SEC_REMINDER from "../../assets/voice_en/BTS/SFT_dummy/en_3secReminder.mp3";
import EN_SFT2 from "../../assets/voice_en/BTS/SFT_dummy/en_sft2.mp3";
import EN_SFT3 from "../../assets/voice_en/BTS/SFT_dummy/en_sft3.mp3";
import EN_SFT5 from "../../assets/voice_en/BTS/SFT_dummy/en_sft5.mp3";
import EN_SFT6 from "../../assets/voice_en/BTS/SFT_dummy/en_sft6.mp3";

import EN_B1 from "../../assets/voice_en/BTS/BTSResult/en_B1.mp3";
import EN_B2_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-1.mp3";
import EN_B2_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-2.mp3";
import EN_B2_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-3.mp3";
import EN_B2_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-4.mp3";
import EN_B2_5 from "../../assets/voice_en/BTS/BTSResult/en_B2-5.mp3";
import EN_B2_6 from "../../assets/voice_en/BTS/BTSResult/en_B2-6.mp3";
import EN_B2_7 from "../../assets/voice_en/BTS/BTSResult/en_B2-7.mp3";
import EN_B2_8 from "../../assets/voice_en/BTS/BTSResult/en_B2-8.mp3";
import EN_B2_9 from "../../assets/voice_en/BTS/BTSResult/en_B2-9.mp3";
import EN_B3_1 from "../../assets/voice_en/BTS/BTSResult/en_B3-1.mp3";
import EN_B3_2 from "../../assets/voice_en/BTS/BTSResult/en_B3-2.mp3";
import EN_B3_3 from "../../assets/voice_en/BTS/BTSResult/en_B3-3.mp3";
import EN_B4 from "../../assets/voice_en/BTS/BTSResult/en_B4.mp3";

import EN_B2_A_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-A-1.mp3";
import EN_B2_A_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-A-2.mp3";
import EN_B2_A_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-A-3.mp3";
import EN_B2_A_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-A-4.mp3";
import EN_B2_B_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-B-1.mp3";
import EN_B2_B_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-B-2.mp3";
import EN_B2_B_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-B-3.mp3";
import EN_B2_B_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-B-4.mp3";
import EN_B2_C_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-C-1.mp3";
import EN_B2_C_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-C-2.mp3";
import EN_B2_C_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-C-3.mp3";
import EN_B2_C_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-C-4.mp3";
import EN_B2_D_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-D-1.mp3";
import EN_B2_D_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-D-2.mp3";
import EN_B2_D_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-D-3.mp3";
import EN_B2_D_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-D-4.mp3";
import EN_B2_E_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-E-1.mp3";
import EN_B2_E_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-E-2.mp3";
import EN_B2_E_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-E-3.mp3";
import EN_B2_E_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-E-4.mp3";
import EN_B2_F_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-F-1.mp3";
import EN_B2_F_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-F-2.mp3";
import EN_B2_F_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-F-3.mp3";
import EN_B2_F_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-F-4.mp3";
import EN_B2_G_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-G-1.mp3";
import EN_B2_G_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-G-2.mp3";
import EN_B2_G_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-G-3.mp3";
import EN_B2_G_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-G-4.mp3";
import EN_B2_H_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-H-1.mp3";
import EN_B2_H_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-H-2.mp3";
import EN_B2_H_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-H-3.mp3";
import EN_B2_H_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-H-4.mp3";
import EN_B2_I_1 from "../../assets/voice_en/BTS/BTSResult/en_B2-I-1.mp3";
import EN_B2_I_2 from "../../assets/voice_en/BTS/BTSResult/en_B2-I-2.mp3";
import EN_B2_I_3 from "../../assets/voice_en/BTS/BTSResult/en_B2-I-3.mp3";
import EN_B2_I_4 from "../../assets/voice_en/BTS/BTSResult/en_B2-I-4.mp3";

import EN_S1 from "../../assets/voice_en/BTS/BTSResult/en_S1.mp3";
import EN_S2 from "../../assets/voice_en/BTS/BTSResult/en_S2.mp3";
import EN_S3 from "../../assets/voice_en/BTS/BTSResult/en_S3.mp3";
import EN_S4_1 from "../../assets/voice_en/BTS/BTSResult/en_S4-1.mp3";
import EN_S4_4 from "../../assets/voice_en/BTS/BTSResult/en_S4-4.mp3";
import EN_S4_7 from "../../assets/voice_en/BTS/BTSResult/en_S4-7.mp3";
import EN_S4_8 from "../../assets/voice_en/BTS/BTSResult/en_S4-8.mp3";
import EN_S4_9 from "../../assets/voice_en/BTS/BTSResult/en_S4-9.mp3";

import EN_noLeg from "../../assets/voice_en/bodyBalance/EN_noLeg.mp3";
import EN_singleLegLeft from "../../assets/voice_en/bodyBalance/EN_singleLegLeft.mp3";
import EN_singleLegRight from "../../assets/voice_en/bodyBalance/EN_singleLegRight.mp3";
import EN_backLeft from "../../assets/voice_en/bodyBalance/EN_backLeft.mp3";
import EN_forwardLeft from "../../assets/voice_en/bodyBalance/EN_forwardLeft.mp3";
import EN_rightLeft from "../../assets/voice_en/bodyBalance/EN_rightLeft.mp3";
import EN_leftLeft from "../../assets/voice_en/bodyBalance/EN_leftLeft.mp3";
import EN_backRight from "../../assets/voice_en/bodyBalance/EN_backRight.mp3";
import EN_forwardRight from "../../assets/voice_en/bodyBalance/EN_forwardRight.mp3";
import EN_rightRight from "../../assets/voice_en/bodyBalance/EN_rightRight.mp3";
import EN_leftRight from "../../assets/voice_en/bodyBalance/EN_leftRight.mp3";
import EN_otherFoot from "../../assets/voice_en/bodyBalance/EN_otherFoot.mp3";
import EN_finishedLeft from "../../assets/voice_en/bodyBalance/EN_finishedLeft.mp3";

import EN_COUNT_1 from "../../assets/voice_en/counts/en_01.mp3";
import EN_COUNT_2 from "../../assets/voice_en/counts/en_02.mp3";
import EN_COUNT_3 from "../../assets/voice_en/counts/en_03.mp3";
import EN_COUNT_4 from "../../assets/voice_en/counts/en_04.mp3";
import EN_COUNT_5 from "../../assets/voice_en/counts/en_05.mp3";
import EN_COUNT_6 from "../../assets/voice_en/counts/en_06.mp3";
import EN_COUNT_7 from "../../assets/voice_en/counts/en_07.mp3";
import EN_COUNT_8 from "../../assets/voice_en/counts/en_08.mp3";
import EN_COUNT_9 from "../../assets/voice_en/counts/en_09.mp3";
import EN_COUNT_10 from "../../assets/voice_en/counts/en_10.mp3";
import EN_COUNT_11 from "../../assets/voice_en/counts/en_11.mp3";
import EN_COUNT_12 from "../../assets/voice_en/counts/en_12.mp3";
import EN_COUNT_13 from "../../assets/voice_en/counts/en_13.mp3";
import EN_COUNT_14 from "../../assets/voice_en/counts/en_14.mp3";
import EN_COUNT_15 from "../../assets/voice_en/counts/en_15.mp3";
import EN_COUNT_16 from "../../assets/voice_en/counts/en_16.mp3";
import EN_COUNT_17 from "../../assets/voice_en/counts/en_17.mp3";
import EN_COUNT_18 from "../../assets/voice_en/counts/en_18.mp3";
import EN_COUNT_19 from "../../assets/voice_en/counts/en_19.mp3";
import EN_COUNT_20 from "../../assets/voice_en/counts/en_20.mp3";
import EN_COUNT_21 from "../../assets/voice_en/counts/en_21.mp3";
import EN_COUNT_22 from "../../assets/voice_en/counts/en_22.mp3";
import EN_COUNT_23 from "../../assets/voice_en/counts/en_23.mp3";
import EN_COUNT_24 from "../../assets/voice_en/counts/en_24.mp3";
import EN_COUNT_25 from "../../assets/voice_en/counts/en_25.mp3";
import EN_COUNT_26 from "../../assets/voice_en/counts/en_26.mp3";
import EN_COUNT_27 from "../../assets/voice_en/counts/en_27.mp3";
import EN_COUNT_28 from "../../assets/voice_en/counts/en_28.mp3";
import EN_COUNT_29 from "../../assets/voice_en/counts/en_29.mp3";
import EN_COUNT_30 from "../../assets/voice_en/counts/en_30.mp3";
import EN_COUNT_31 from "../../assets/voice_en/counts/en_31.mp3";
import EN_COUNT_32 from "../../assets/voice_en/counts/en_32.mp3";
import EN_COUNT_33 from "../../assets/voice_en/counts/en_33.mp3";
import EN_COUNT_34 from "../../assets/voice_en/counts/en_34.mp3";
import EN_COUNT_35 from "../../assets/voice_en/counts/en_35.mp3";
import EN_COUNT_36 from "../../assets/voice_en/counts/en_36.mp3";
import EN_COUNT_37 from "../../assets/voice_en/counts/en_37.mp3";
import EN_COUNT_38 from "../../assets/voice_en/counts/en_38.mp3";
import EN_COUNT_39 from "../../assets/voice_en/counts/en_39.mp3";
import EN_COUNT_40 from "../../assets/voice_en/counts/en_40.mp3";
import EN_COUNT_41 from "../../assets/voice_en/counts/en_41.mp3";
import EN_COUNT_42 from "../../assets/voice_en/counts/en_42.mp3";
import EN_COUNT_43 from "../../assets/voice_en/counts/en_43.mp3";
import EN_COUNT_44 from "../../assets/voice_en/counts/en_44.mp3";
import EN_COUNT_45 from "../../assets/voice_en/counts/en_45.mp3";
import EN_COUNT_46 from "../../assets/voice_en/counts/en_46.mp3";
import EN_COUNT_47 from "../../assets/voice_en/counts/en_47.mp3";
import EN_COUNT_48 from "../../assets/voice_en/counts/en_48.mp3";
import EN_COUNT_49 from "../../assets/voice_en/counts/en_49.mp3";
import EN_COUNT_50 from "../../assets/voice_en/counts/en_50.mp3";
import EN_COUNT_51 from "../../assets/voice_en/counts/en_51.mp3";
import EN_COUNT_52 from "../../assets/voice_en/counts/en_52.mp3";
import EN_COUNT_53 from "../../assets/voice_en/counts/en_53.mp3";
import EN_COUNT_54 from "../../assets/voice_en/counts/en_54.mp3";
import EN_COUNT_55 from "../../assets/voice_en/counts/en_55.mp3";
import EN_COUNT_56 from "../../assets/voice_en/counts/en_56.mp3";
import EN_COUNT_57 from "../../assets/voice_en/counts/en_57.mp3";
import EN_COUNT_58 from "../../assets/voice_en/counts/en_58.mp3";
import EN_COUNT_59 from "../../assets/voice_en/counts/en_59.mp3";
import EN_COUNT_60 from "../../assets/voice_en/counts/en_60.mp3";
import EN_COUNT_61 from "../../assets/voice_en/counts/en_61.mp3";
import EN_COUNT_62 from "../../assets/voice_en/counts/en_62.mp3";
import EN_COUNT_63 from "../../assets/voice_en/counts/en_63.mp3";
import EN_COUNT_64 from "../../assets/voice_en/counts/en_64.mp3";
import EN_COUNT_65 from "../../assets/voice_en/counts/en_65.mp3";
import EN_COUNT_66 from "../../assets/voice_en/counts/en_66.mp3";
import EN_COUNT_67 from "../../assets/voice_en/counts/en_67.mp3";
import EN_COUNT_68 from "../../assets/voice_en/counts/en_68.mp3";
import EN_COUNT_69 from "../../assets/voice_en/counts/en_69.mp3";
import EN_COUNT_70 from "../../assets/voice_en/counts/en_70.mp3";
import EN_COUNT_71 from "../../assets/voice_en/counts/en_71.mp3";
import EN_COUNT_72 from "../../assets/voice_en/counts/en_72.mp3";
import EN_COUNT_73 from "../../assets/voice_en/counts/en_73.mp3";
import EN_COUNT_74 from "../../assets/voice_en/counts/en_74.mp3";
import EN_COUNT_75 from "../../assets/voice_en/counts/en_75.mp3";
import EN_COUNT_76 from "../../assets/voice_en/counts/en_76.mp3";
import EN_COUNT_77 from "../../assets/voice_en/counts/en_77.mp3";
import EN_COUNT_78 from "../../assets/voice_en/counts/en_78.mp3";
import EN_COUNT_79 from "../../assets/voice_en/counts/en_79.mp3";
import EN_COUNT_80 from "../../assets/voice_en/counts/en_80.mp3";
import EN_COUNT_81 from "../../assets/voice_en/counts/en_81.mp3";
import EN_COUNT_82 from "../../assets/voice_en/counts/en_82.mp3";
import EN_COUNT_83 from "../../assets/voice_en/counts/en_83.mp3";
import EN_COUNT_84 from "../../assets/voice_en/counts/en_84.mp3";
import EN_COUNT_85 from "../../assets/voice_en/counts/en_85.mp3";
import EN_COUNT_86 from "../../assets/voice_en/counts/en_86.mp3";
import EN_COUNT_87 from "../../assets/voice_en/counts/en_87.mp3";
import EN_COUNT_88 from "../../assets/voice_en/counts/en_88.mp3";
import EN_COUNT_89 from "../../assets/voice_en/counts/en_89.mp3";
import EN_COUNT_90 from "../../assets/voice_en/counts/en_90.mp3";
import EN_COUNT_91 from "../../assets/voice_en/counts/en_91.mp3";
import EN_COUNT_92 from "../../assets/voice_en/counts/en_92.mp3";
import EN_COUNT_93 from "../../assets/voice_en/counts/en_93.mp3";
import EN_COUNT_94 from "../../assets/voice_en/counts/en_94.mp3";
import EN_COUNT_95 from "../../assets/voice_en/counts/en_95.mp3";
import EN_COUNT_96 from "../../assets/voice_en/counts/en_96.mp3";
import EN_COUNT_97 from "../../assets/voice_en/counts/en_97.mp3";
import EN_COUNT_98 from "../../assets/voice_en/counts/en_98.mp3";
import EN_COUNT_99 from "../../assets/voice_en/counts/en_99.mp3";
import EN_COUNT_100 from "../../assets/voice_en/counts/en_100.mp3";

import EN_A1 from "../../assets/voice_en/BTS/en_a1.mp3";

//button
import FOCUS_BUTTON_SOUND from "../../assets/voice/button/focus_button_sound.mp3"
import CLICK_BUTTON_SOUND from "../../assets/voice/button/click_button_sound.mp3"

export const SOURCETYPE = {
  DISCOPOP,
  ROMBGM,
  MEASURESOUND,
  M1, M2, M3, M3_1, M3_2, M3_3, M3_4, M3_5, M4, M6, M7, M8, M9, M10, M11, M12, M13, M15, M17, M18, M19, M21, M23,
  SFT_3SEC_REMINDER, SFT2, SFT3, SFT5, SFT6, SFT7, SFT8,
  B1, B2_1, B2_2, B2_3, B2_4, B2_5, B2_6, B2_7, B2_8, B2_9,
  B2_A_1, B2_A_2, B2_A_3, B2_A_4, B2_B_1, B2_B_2, B2_B_3, B2_B_4, B2_C_1, B2_C_2, B2_C_3, B2_C_4,
  B2_D_1, B2_D_2, B2_D_3, B2_D_4, B2_E_1, B2_E_2, B2_E_3, B2_E_4, B2_F_1, B2_F_2, B2_F_3, B2_F_4,
  B2_G_1, B2_G_2, B2_G_3, B2_G_4, B2_H_1, B2_H_2, B2_H_3, B2_H_4, B2_I_1, B2_I_2, B2_I_3, B2_I_4,
  B3_1, B3_2, B3_3, B4, B4_1: B4, B4_2: B4, B4_3: B4, B4_4: B4, B4_5: B4, B4_6: B4, B4_7: B4, B4_8: B4,
  S1, S2, S3, S4_1, S4_4, S4_7, S4_8, S4_9,
  FUNK_HAPPY,
  SUMMER_DISCO,
  M5_3_2_1,
  CLICK,
  RING2,
  SCH_BUTTON_10,
  SCH_BUTTON_5,
  SCH_BUTTON_3,
  YEAH,
  COUNT_1, COUNT_2, COUNT_3, COUNT_4, COUNT_5, COUNT_6, COUNT_7, COUNT_8, COUNT_9, COUNT_10, COUNT_11, COUNT_12, COUNT_13, COUNT_14,
  COUNT_15, COUNT_16, COUNT_17, COUNT_18, COUNT_19, COUNT_20, COUNT_21, COUNT_22, COUNT_23, COUNT_24, COUNT_25, COUNT_26, COUNT_27,
  COUNT_28, COUNT_29, COUNT_30, COUNT_31, COUNT_32, COUNT_33, COUNT_34, COUNT_35, COUNT_36, COUNT_37, COUNT_38, COUNT_39, COUNT_40,
  COUNT_41, COUNT_42, COUNT_43, COUNT_44, COUNT_45, COUNT_46, COUNT_47, COUNT_48, COUNT_49, COUNT_50, COUNT_51, COUNT_52, COUNT_53, COUNT_54, COUNT_55, COUNT_56, COUNT_57, COUNT_58, COUNT_59, COUNT_60, COUNT_61, COUNT_62, COUNT_63, COUNT_64, COUNT_65, COUNT_66, COUNT_67, COUNT_68, COUNT_69, COUNT_70, COUNT_71, COUNT_72, COUNT_73, COUNT_74, COUNT_75, COUNT_76, COUNT_77, COUNT_78, COUNT_79, COUNT_80, COUNT_81, COUNT_82, COUNT_83, COUNT_84, COUNT_85, COUNT_86, COUNT_87, COUNT_88, COUNT_89, COUNT_90, COUNT_91, COUNT_92, COUNT_93, COUNT_94, COUNT_95, COUNT_96, COUNT_97, COUNT_98, COUNT_99, COUNT_100,
  noLeg, singleLegLeft, singleLegRight, backLeft, forwardLeft, rightLeft, leftLeft, backRight, forwardRight, rightRight, leftRight, otherFoot, finishedLeft,
  ML, MR, MF, MB, TL, TR, MCD, MCU,
  FOCUS_BUTTON_SOUND, CLICK_BUTTON_SOUND,
  A1,
  neckSideFix, neckShoulderFix, shoulderSideFix, trunkFix, trunkSideFix, kneeFix, hipFix
};

export const SOURCETYPE_EN = {
  // M2: EN_COUNT_41,
  M1: EN_M1, M2: EN_M2, M3: EN_M3_1, M3_1: EN_M3_1, M3_2: EN_M3_2, M3_3: EN_M3_3, M3_4: EN_M3_4, M3_5: EN_M3_5, M4: EN_M4, M6: EN_M6, M7: EN_M7, M8: EN_M8, M9: EN_M9, M10: EN_M10, M11: EN_M11, M12: EN_M12, M13: EN_M13, M15: EN_M15, M17: EN_M17, M18: EN_M18, M19: EN_M19, M21: EN_M21, M23: EN_M23,
  SFT_3SEC_REMINDER: EN_SFT_3SEC_REMINDER, SFT2: EN_SFT2, SFT3: EN_SFT3, SFT5: EN_SFT5, SFT6: EN_SFT6,
  B1: EN_B1, B2_1: EN_B2_1, B2_2: EN_B2_2, B2_3: EN_B2_3, B2_4: EN_B2_4, B2_5: EN_B2_5, B2_6: EN_B2_6, B2_7: EN_B2_7, B2_8: EN_B2_8, B2_9: EN_B2_9,
  B2_A_1: EN_B2_A_1, B2_A_2: EN_B2_A_2, B2_A_3: EN_B2_A_3, B2_A_4: EN_B2_A_4, B2_B_1: EN_B2_B_1, B2_B_2: EN_B2_B_2, B2_B_3: EN_B2_B_3, B2_B_4: EN_B2_B_4, B2_C_1: EN_B2_C_1, B2_C_2: EN_B2_C_2, B2_C_3: EN_B2_C_3, B2_C_4: EN_B2_C_4,
  B2_D_1: EN_B2_D_1, B2_D_2: EN_B2_D_2, B2_D_3: EN_B2_D_3, B2_D_4: EN_B2_D_4, B2_E_1: EN_B2_E_1, B2_E_2: EN_B2_E_2, B2_E_3: EN_B2_E_3, B2_E_4: EN_B2_E_4, B2_F_1: EN_B2_F_1, B2_F_2: EN_B2_F_2, B2_F_3: EN_B2_F_3, B2_F_4: EN_B2_F_4,
  B2_G_1: EN_B2_G_1, B2_G_2: EN_B2_G_2, B2_G_3: EN_B2_G_3, B2_G_4: EN_B2_G_4, B2_H_1: EN_B2_H_1, B2_H_2: EN_B2_H_2, B2_H_3: EN_B2_H_3, B2_H_4: EN_B2_H_4, B2_I_1: EN_B2_I_1, B2_I_2: EN_B2_I_2, B2_I_3: EN_B2_I_3, B2_I_4: EN_B2_I_4,
  B3_1: EN_B3_1, B3_2: EN_B3_2, B3_3: EN_B3_3, B4: EN_B4, B4_1: EN_B4, B4_2: EN_B4, B4_3: EN_B4, B4_4: EN_B4, B4_5: EN_B4, B4_6: EN_B4, B4_7: EN_B4, B4_8: EN_B4,
  S1: EN_S1, S2: EN_S2, S3: EN_S3, S4_1: EN_S4_1, S4_4: EN_S4_4, S4_7: EN_S4_7, S4_8: EN_S4_8, S4_9: EN_S4_9,
  M5_3_2_1: EN_M5_3_2_1,
  COUNT_1: EN_COUNT_1, COUNT_2: EN_COUNT_2, COUNT_3: EN_COUNT_3, COUNT_4: EN_COUNT_4, COUNT_5: EN_COUNT_5, COUNT_6: EN_COUNT_6, COUNT_7: EN_COUNT_7, COUNT_8: EN_COUNT_8, COUNT_9: EN_COUNT_9, COUNT_10: EN_COUNT_10, COUNT_11: EN_COUNT_11, COUNT_12: EN_COUNT_12, COUNT_13: EN_COUNT_13, COUNT_14: EN_COUNT_14,
  COUNT_15: EN_COUNT_15, COUNT_16: EN_COUNT_16, COUNT_17: EN_COUNT_17, COUNT_18: EN_COUNT_18, COUNT_19: EN_COUNT_19, COUNT_20: EN_COUNT_20, COUNT_21: EN_COUNT_21, COUNT_22: EN_COUNT_22, COUNT_23: EN_COUNT_23, COUNT_24: EN_COUNT_24, COUNT_25: EN_COUNT_25, COUNT_26: EN_COUNT_26, COUNT_27: EN_COUNT_27,
  COUNT_28: EN_COUNT_28, COUNT_29: EN_COUNT_29, COUNT_30: EN_COUNT_30, COUNT_31: EN_COUNT_31, COUNT_32: EN_COUNT_32, COUNT_33: EN_COUNT_33, COUNT_34: EN_COUNT_34, COUNT_35: EN_COUNT_35, COUNT_36: EN_COUNT_36, COUNT_37: EN_COUNT_37, COUNT_38: EN_COUNT_38, COUNT_39: EN_COUNT_39, COUNT_40: EN_COUNT_40,
  COUNT_41: EN_COUNT_41, COUNT_42: EN_COUNT_42, COUNT_43: EN_COUNT_43, COUNT_44: EN_COUNT_44, COUNT_45: EN_COUNT_45, COUNT_46: EN_COUNT_46, COUNT_47: EN_COUNT_47, COUNT_48: EN_COUNT_48, COUNT_49: EN_COUNT_49, COUNT_50: EN_COUNT_50,
  COUNT_51: EN_COUNT_51, COUNT_52: EN_COUNT_52, COUNT_53: EN_COUNT_53, COUNT_54: EN_COUNT_54, COUNT_55: EN_COUNT_55, COUNT_56: EN_COUNT_56, COUNT_57: EN_COUNT_57, COUNT_58: EN_COUNT_58, COUNT_59: EN_COUNT_59, COUNT_60: EN_COUNT_60, COUNT_61: EN_COUNT_61, COUNT_62: EN_COUNT_62, COUNT_63: EN_COUNT_63, COUNT_64: EN_COUNT_64, COUNT_65: EN_COUNT_65, COUNT_66: EN_COUNT_66, COUNT_67: EN_COUNT_67, COUNT_68: EN_COUNT_68, COUNT_69: EN_COUNT_69, COUNT_70: EN_COUNT_70, COUNT_71: EN_COUNT_71, COUNT_72: EN_COUNT_72, COUNT_73: EN_COUNT_73, COUNT_74: EN_COUNT_74, COUNT_75: EN_COUNT_75, COUNT_76: EN_COUNT_76, COUNT_77: EN_COUNT_77, COUNT_78: EN_COUNT_78, COUNT_79: EN_COUNT_79, COUNT_80: EN_COUNT_80, COUNT_81: EN_COUNT_81, COUNT_82: EN_COUNT_82, COUNT_83: EN_COUNT_83, COUNT_84: EN_COUNT_84, COUNT_85: EN_COUNT_85, COUNT_86: EN_COUNT_86, COUNT_87: EN_COUNT_87, COUNT_88: EN_COUNT_88, COUNT_89: EN_COUNT_89, COUNT_90: EN_COUNT_90, COUNT_91: EN_COUNT_91, COUNT_92: EN_COUNT_92, COUNT_93: EN_COUNT_93, COUNT_94: EN_COUNT_94, COUNT_95: EN_COUNT_95, COUNT_96: EN_COUNT_96, COUNT_97: EN_COUNT_97, COUNT_98: EN_COUNT_98, COUNT_99: EN_COUNT_99, COUNT_100: EN_COUNT_100,
  noLeg: EN_noLeg, singleLegLeft: EN_singleLegLeft, singleLegRight: EN_singleLegRight, backLeft: EN_backLeft, forwardLeft: EN_forwardLeft, rightLeft: EN_rightLeft, leftLeft: EN_leftLeft, backRight: EN_backRight, forwardRight: EN_forwardRight, rightRight: EN_rightRight, leftRight: EN_leftRight, otherFoot: EN_otherFoot, finishedLeft: EN_finishedLeft,
  ML:EN_ML, MR:EN_MR, MF:EN_MF, MB:EN_MB, TL:EN_TL, TR:EN_TR, MCD:MCD, MCU:MCU, 
  A1: EN_A1,
  neckSideFix:neckSideFix, neckShoulderFix:neckShoulderFix, shoulderSideFix:shoulderSideFix, trunkFix:trunkFix, trunkSideFix:trunkSideFix, kneeFix:kneeFix, hipFix:hipFix
}

let context, source;
let language = 'en';
export const setLanguage = (ln) => {
  language = ln;
}
export const initWebAudio = () => {
  if(typeof window === 'object') {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    // var options = {};
    // options.outputDeviceId = "tts";
    // context = new window.AudioContext(options);
    context = new window.AudioContext();
    source = new SoundSource(context, finishedLoading);
  }
}

let delayedQue = {};
export const playAudio = (key, loop = false, onended, volume = 1.0) => {
  let targetSourceType = language === 'ko' ? SOURCETYPE : SOURCETYPE_EN;
  // console.log(`playAudio language: ${language}, targetSourceType...`, targetSourceType);

  let url = "";
  if(key && key.indexOf('http')>=0){
    url = key;
  }else{
    url = targetSourceType[key];
    if (language === 'en' && !url) {
      url = SOURCETYPE[key];
    }
  }
  if (url && source) {
    if (!source.start(key, loop, onended, volume)) {
      delayedQue[key] = { loop, onended, volume };
      source.loadBuffer(url, key, volume);
    }
  }
}
export const stopAll = () => {
  source.stopAll();
}
export const stopAudio = (key) => {
  source.stop(key);
  if (delayedQue[key]) {
    delete delayedQue[key];
  }
}
export const suspendAudio = (key) => {
  source.suspend(key);
}
export const resumeAudio = (key) => {
  source.resume(key);
}
export const getSource = () => {
  return source;
}

function finishedLoading(key) {
  // Create two sources and play them both together.
  if (delayedQue[key]) {
    source.start(key, delayedQue[key].loop, delayedQue[key].onended, delayedQue[key].volume);
    delete delayedQue[key];
  }
}

function SoundSource(context, callback) {
  this.context = context;
  this.onload = callback;
  this.bufferList = {};
  this.sound = {};
  this.soundBuf = {};
  this.loadCount = 0;
}
SoundSource.prototype.loadBuffer = function (url, key) {
  // Load buffer asynchronously
  var request = typeof window === 'object' && new window.XMLHttpRequest();
  request.open("GET", url, true);
  request.responseType = "arraybuffer";
  var loader = this;
  request.onload = function () {
    // Asynchronously decode the audio file data in request.response
    loader.context.decodeAudioData(request.response, function (buffer) {
      if (!buffer) {
        console.log("error decoding file data: " + url);
        return;
      }
      loader.bufferList[key] = buffer;
      loader.prepareSound(key);
      loader.sound[key] = null;
      ++loader.loadCount;
      if (loader.onload != null) {
        loader.onload(key);
      }
    });
  };
  request.onerror = function () {
    console.log("BufferLoader: XHR error");
  };
  request.send();
};
SoundSource.prototype.prepareSound = function (key, volume = 1.0) {
  const gainNode = this.context.createGain();
  gainNode.gain.value = volume;
  gainNode.connect(this.context.destination);

  this.soundBuf[key] = this.context.createBufferSource();
  this.soundBuf[key].buffer = this.bufferList[key];
  this.soundBuf[key].connect(gainNode);
};

SoundSource.prototype.stopAll = function () {
  Object.keys(this.sound).forEach((key) => {
    this.stop(key);
  });
};
SoundSource.prototype.stop = function (key) {
  if (this.sound[key]) {
    this.sound[key].stop();
    delete this.sound[key];
  }
};
SoundSource.prototype.start = function (key, loop, onended, volume) {
  this.stop(key);
  if (this.soundBuf[key]) {
    this.prepareSound(key, volume);
    this.soundBuf[key].start(0);
    this.soundBuf[key].loop = loop;
    this.soundBuf[key].onended = onended;
    this.soundBuf[key].volume = volume;
    this.sound[key] = this.soundBuf[key];
    return true;
  } else {
    return false;
  }
};
SoundSource.prototype.suspend = function (key) {
  if (this.sound[key] && this.sound[key].context?.state === 'running') {
    this.sound[key].context?.suspend();
  }
};
SoundSource.prototype.resume = function (key) {
  if (this.sound[key] && this.sound[key].context?.state === 'suspended') {
    this.sound[key].context?.resume();
  }
};