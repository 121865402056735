import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from "react-redux";
import { mat4 } from 'gl-matrix';
import css from './VRPlayer.module.less';
import classNames from "classnames";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import TIconButton, { ICON_TYPES } from '../TIconButton/TIconButton';
import { $L, clampingNumber } from '../../utils/helperMethods';
import img_scenery_guide from "../../../assets/inHomeOutdoorActivity/img_scenery_guide.png";
import TShakaPlayer from '../TShakaPlayer/TShakaPlayer';
import ic_inhome_scenery_gesture from "../../../assets/inHomeOutdoorActivity/ic_inhome_scenery_gesture.svg";

export const MODES = {
	vr: 'vr',
	time: 'time'
}
export const HANDLER_MODES = {
	auto: 'auto',
	click: 'click'
};
export const STREO_MODES = {
	monoscopic: 'monoscopic',
	stereoscopicLeftRight: 'stereoscopic-left-right',
	stereoscopicTopBottom: 'stereoscopic-top-bottom'
};
export const MAP_TO_MESH_URLS = {
	equirectangular: 'equirectangular',
	rectangular: 'rectangular'
};

const notOnTv = (typeof window === 'object' && !window.PalmSystem);
const wideAngleDistance = 0.5;
const vfov = 60;
const hfov = 90;

const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");
const SpottableComponent = Spottable("div");

const VRPlayer = ({
		src, mode = MODES.vr, handlerMode = HANDLER_MODES.auto, useWideAngle = false, stereoMode = STREO_MODES.monoscopic, mapToMeshUrl = MAP_TO_MESH_URLS.equirectangular,
		interval = 50, delta = 0.5, xMaxAngle = 70, yMaxAngle = 8, className, ...rest
	}) => {
	const { cursorVisible } = useSelector((state) => state.common.appStatus);
	const playerRef = useRef(null);
	const intervalRef = useRef(null);
	const [videoLoaded, setVideoLoaded] = useState(false);
	const [roll, setRoll] = useState(0);
	const [pitch, setPitch] = useState(0);
	const [lastMouseX, setLastMouseX] = useState(null);
	const [lastMouseY, setLastMouseY] = useState(null);
	const [progressX, setProgressX] = useState(0);
	const [progressY, setProgressY] = useState(0);
	const [controlKey, setControlKey] = useState('');
	const [cursorInit, setCursorInit] = useState(true);

	const angleDelta = useMemo(() => {
		return 360 * delta / 100;
	}, [delta]);

	const controlBtns = useMemo(() => {
		const arr = [
			{ position: 'left', rate: -1, label: $L("Move to your left.") },
			{ position: 'right', rate: 1, label: $L("Move to your right.") }
		];

		if (mode === MODES.vr) {
			arr.push(...[
				{ position: 'top', rate: 1, label: $L("Move to your top.") },
				{ position: 'bottom', rate: -1, label: $L("Move to your bottom.") }
			]);
		}

		return arr;
	}, [mode, cursorVisible]);

	const controlBtnDisabled = useMemo(() => {
		const arr = [];

		if (progressX === 0) arr.push('left');
		else if (progressX === xMaxAngle) arr.push('right');

		if (progressY === yMaxAngle) arr.push('top');
		else if (progressY === -yMaxAngle) arr.push('bottom');

		return arr;
	}, [progressX, progressY]);

	const checkForMapToMeshSupport = useCallback(() => {
		return typeof window === 'object' && 'CSS' in window && 'supports' in window.CSS &&
			window.CSS.supports('filter', 'map-to-mesh(equirectangular, 90deg 60deg, matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1))');
	}, []);

	const degToRad = useCallback((degrees) => {
		return degrees * Math.PI / 180;
	}, []);

	const matrixToString = useCallback((m) => {
		if (m.length !== 16) {
			return '';
		}
		return `matrix3d(
			${m[0]}, ${m[1]}, ${m[2]}, ${m[3]},
			${m[4]}, ${m[5]}, ${m[6]}, ${m[7]},
			${m[8]}, ${m[9]}, ${m[10]}, ${m[11]},
			${m[12]}, ${m[13]}, ${m[14]}, ${m[15]})
		`;
	}, []);

	const getRotationMatrix = useCallback((angleX, angleY) => {
		const translateMatrix = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const rotateMatrix = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		const result = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    mat4.identity(translateMatrix);
    mat4.identity(rotateMatrix);
    if (useWideAngle) {
      mat4.translate(translateMatrix, translateMatrix, [0, 0, -wideAngleDistance]);
    }
    mat4.rotateX(rotateMatrix, rotateMatrix, degToRad(angleY !== undefined ? angleY : roll));
    mat4.rotateY(rotateMatrix, rotateMatrix, degToRad(angleX !== undefined ? angleX : pitch));
    mat4.multiply(result, translateMatrix, rotateMatrix);

		return result;
	}, [useWideAngle, roll, pitch]);

	const updateMTMfilter = useCallback((rotationMatrix, isAuto) => {
		const angles = isAuto ? '110deg 85deg' : `${hfov}deg ${vfov}deg`;
    const matrix = matrixToString(rotationMatrix);
    const filterString = `map-to-mesh(${mapToMeshUrl}, ${angles}, ${matrix}` + (isAuto ? '' : `, ${stereoMode})`);
    const videoElement = playerRef.current;
		if (videoElement) {
			videoElement.style.filter = filterString;
		}
	}, [stereoMode, mapToMeshUrl, hfov, vfov]);

	/** click */
	const changePosition = useCallback((ev) => {
		const newX = ev.clientX;
    const newY = ev.clientY;
    const deltaX = newX - lastMouseX;
    const new_pitch = pitch + deltaX / 10;
    const deltaY = newY - lastMouseY;
    const new_roll = roll + deltaY / 20;
		setLastMouseX(newX);
		setLastMouseY(newY);
    if (new_pitch !== pitch || new_roll !== roll) {
			setPitch(new_pitch);
      setRoll(new_roll);
      const matrix = getRotationMatrix();
      updateMTMfilter(matrix);
    }
	}, [lastMouseX, lastMouseY, pitch, roll]);

	/** auto */
	const onControlClick = useCallback((info) => (ev) => {
		if (cursorVisible) setCursorInit(false);

		if (mode === MODES.time) {
			if (controlBtnDisabled.includes(info.position)) return;
			// if (!cursorVisible && ['focus', 'blur'].includes(ev.type)) return;

			if (ev.type === 'blur') {
				if (!playerRef.current.paused()) {
					playerRef.current.pause();
				}
				setControlKey('');
			} else {
				const fullTime = playerRef.current.getDuration();
				const halfTime = playerRef.current.getDuration() / 2;
				const currentTime = playerRef.current.getCurrentTime();

				if (!playerRef.current.paused()) {
					playerRef.current.pause();
				}

				let seekValue = Math.max(currentTime + (info.rate < 0 ? 0.01 : -0.01), 0);

				if (info.rate < 0) {
					if (currentTime === fullTime) return;

					if (currentTime < halfTime) {
						seekValue = fullTime - currentTime;
					}
				} else {
					if (currentTime === halfTime) return;

					if (currentTime > halfTime) {
						seekValue = fullTime - currentTime;
					}
				}

				console.log('seekValue...', seekValue);
				setControlKey(info.position);
				setProgressX(50);
				setProgressY(0);
				if (seekValue !== currentTime) playerRef.current.seekTo(seekValue);
				playerRef.current.play();
			}
		} else {
			clearInterval(intervalRef.current);

			if (ev.type === 'blur') {
				setControlKey('');
			} else {
				setControlKey(info.position);

				if (['top', 'bottom'].includes(info.position)) {
					intervalRef.current = setInterval(() => {
						setProgressY((prev) => clampingNumber(prev + (delta * (info.position === 'top' ? 1 : -1)), -yMaxAngle, yMaxAngle));
					}, interval);
				} else {
					intervalRef.current = setInterval(() => {
						setProgressX((prev) => clampingNumber(prev + (delta * (info.position === 'right' ? 1 : -1)), 0, xMaxAngle));
					}, interval);
				}
			}
		}
	}, [getRotationMatrix, updateMTMfilter, delta, interval, controlBtnDisabled, cursorVisible, xMaxAngle, yMaxAngle]);

	useEffect(() => {
		if (mode === MODES.vr && handlerMode === HANDLER_MODES.auto) {;
			if (progressX >= 0 && progressX <= xMaxAngle && progressY >= -yMaxAngle && progressY <= yMaxAngle) {
				const angleX = angleDelta * (progressX / delta);
				const angleY = angleDelta * (progressY / delta);
				const matrix = getRotationMatrix(angleX, angleY);
				updateMTMfilter(matrix, true);
			} else {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		}
	}, [progressX, progressY]);

	useEffect(() => {
		setControlKey('');

		if (mode === MODES.time) {
			if (!playerRef.current.paused()) {
        playerRef.current.pause();
      }
		} else {
			clearInterval(intervalRef.current);
			intervalRef.current = null;

			if (progressX > xMaxAngle) {
				setProgressX(xMaxAngle);
			}
			if (progressY > yMaxAngle) {
				setProgressY(yMaxAngle);
			}
		}
	}, [interval, delta, cursorVisible, xMaxAngle, yMaxAngle]);

	const onVideoLoadedData = useCallback(() => {
		if (mode === MODES.time) {
			setControlKey('');
			setProgressX(0);
			setVideoLoaded(true);
		} else {
			if (checkForMapToMeshSupport() || notOnTv) {
				console.log('[VRPlayer] map-to-mesh supported');
				setVideoLoaded(true);

				const matrix = getRotationMatrix();
				updateMTMfilter(matrix);
			} else {
				console.log('[VRPlayer] map-to-mesh not supported');
			}
		}
	}, [getRotationMatrix, updateMTMfilter]);

	const onVideoProgress = useCallback((ev) => {
		if (mode === MODES.time) {
			if (ev >= (playerRef.current.getDuration() / 2) && controlKey === 'right') {
				playerRef.current.pause();
				setProgressX(100);
			}
		}
	}, [controlKey]);

	const onVideoEnd = useCallback(() => {
		if (mode === MODES.time) {
			setProgressX(0);
		}
	}, []);

	useEffect(() => {
		if (mode === MODES.vr) {
			if (intervalRef.current !== null) {
				clearInterval(intervalRef.current);
			}
			setRoll(0);
			setPitch(0);
			setLastMouseX(0);
			setLastMouseY(0);
			setProgressX(0);
			setProgressY(0);
			setControlKey('');
		}
	}, [handlerMode]);

	useEffect(() => {
		return () => {
			clearInterval(intervalRef.current);
		}
	}, []);

	return (
		<div className={classNames(css.vrPlayer, className)} {...rest}>
			{mode === MODES.time ?
			<TShakaPlayer playerRef={playerRef} src={src} onLoadedMetadata={onVideoLoadedData} onProgress={onVideoProgress} onEnded={onVideoEnd} muted={true} />
			:
			<video ref={playerRef} autoPlay loop width='100%' src={src} onLoadedData={onVideoLoadedData} onClick={handlerMode === HANDLER_MODES.click ? changePosition : null}/>
			}
			{cursorVisible && cursorInit && <div className={classNames(css.overlay, css.gestureGuide)}>
        <div className={css.box}>
          <img src={img_scenery_guide} className={css.guideImg}/>
          <div className={css.text}>{$L("Move to your left or right to enjoy a wider view.")}</div>
        </div>
      </div>}
			{handlerMode === HANDLER_MODES.auto && videoLoaded && <Container className={css.spotBtnContainer}>
				{controlBtns.map(btn => <React.Fragment key={`controlBtn-${btn.position}`}>
					{cursorVisible ?
					<SpottableComponent onFocus={onControlClick(btn)} onBlur={onControlClick(btn)} className={classNames(css.controlBox, css[btn.position])}>
						{['left', 'top'].includes(btn.position) && controlKey === btn.position && !controlBtnDisabled.includes(btn.position) &&
							<div><div className={css.controlLabel}><div className={css.labelFlex}><span className={css.arrow} /><span className={css.text}>{btn.label || ''}</span></div></div></div>
						}
						{['right', 'bottom'].includes(btn.position) && controlKey === btn.position && !controlBtnDisabled.includes(btn.position) &&
							<div><div className={css.controlLabel}><div className={css.labelFlex}><span className={css.text}>{btn.label || ''}</span><span className={css.arrow} /></div></div></div>
						}
					</SpottableComponent>
					:
					<div className={classNames(css.controlBtn, css[btn.position])}>
						<TIconButton
							iconType={ICON_TYPES.controlArrow}
							disabled={controlBtnDisabled.includes(btn.position)}
							onClick={onControlClick(btn)}
							onFocus={onControlClick(btn)}
							onBlur={onControlClick(btn)}
						/>
						{['left', 'right', 'top', 'bottom'].includes(btn.position) && <img src={ic_inhome_scenery_gesture}/>}
					</div>
					}
				</React.Fragment>)}
			</Container>}
		</div>
	);
};

export default VRPlayer;
