import {
  SCORE_RED_KEY,
  SCORE_YELLOW_KEY,
  SCORE_MINT_KEY,
} from "../../../../utils/Constants";
import { $L } from "../../../../utils/helperMethods";
import { getEmptyResultsReportData, romMenu } from "../ROMReport/Constants";
import {
  getBODY_DETAIL_TYPE,
  FEEDBACK_TYPE,
  getFeedbackROM,
  getSUB_TYPE,
  getBODY_PART_RANGE_TYPE,
} from "./constantsFeedback";

export const voiceGuideEmpty = (tabName) => {
  if (tabName) {
    const emptyData = getEmptyResultsReportData();

    const { title, detail } = emptyData[tabName.toUpperCase()];
    const feedback = title + " " + detail;

    return {
      title,
      subtitle: detail,
      feedback: feedback.replaceAll("{br}", ""),
      feedbackVoice: feedback.replaceAll("{br}", ""),
    };
  }
};

export const voiceGuideOverallROM = (partsArr) => {
  const BODY_DETAIL_TYPE = getBODY_DETAIL_TYPE();
  const feedbackROM = getFeedbackROM();
  let dangerousPartsArrText;
  let feedback;
  let title;
  let subtitle;
  if (partsArr.length > 0) {
    const translatePartsArr = partsArr.map((part) => BODY_DETAIL_TYPE[part]);
    dangerousPartsArrText = translatePartsArr.join(", ");
    const convertTitleEl = `<span>${dangerousPartsArrText}</span>`;
    const feedbackArr = feedbackROM[romMenu.OVERALL][SCORE_RED_KEY];
    const feedbackTitle = feedbackArr.title.replace(
      "{partsArr}",
      dangerousPartsArrText
    );
    const convertTitleElFeedback = feedbackArr.title.replace(
      "{partsArr}",
      convertTitleEl
    );
    title = convertTitleElFeedback;
    subtitle = feedbackArr.subtitle;
    feedback = feedbackTitle + " " + subtitle;
  } else {
    title = feedbackROM[romMenu.OVERALL][SCORE_MINT_KEY].feedback;
    feedback = title;
  }

  return { title, subtitle, feedback, feedbackVoice: feedback };
};

export const voiceGuideSubTabROM = (type, testName, grade) => {
  const feedbackROM = getFeedbackROM();
  let status;
  switch (grade) {
    case "a":
      status = SCORE_MINT_KEY;
      break;
    case "b":
      status = SCORE_YELLOW_KEY;
      break;
    case "c":
    default:
      status = SCORE_RED_KEY;
      break;
  }
  const feedbackArr = feedbackROM[type][status][testName];

  const title = feedbackROM[FEEDBACK_TYPE.COMMON][status]?.replace(
    "{bodyPart}",
    getBODY_PART_RANGE_TYPE()[type]
  );

  const mainFeedbackArr = feedbackArr[FEEDBACK_TYPE.MAIN_FEEDBACK];
  const mainRandomIndex = Math.floor(Math.random() * mainFeedbackArr.length);

  let subtitle = mainFeedbackArr[mainRandomIndex];
  let feedback = title;

  if (status !== SCORE_MINT_KEY) {
    const subFeedbackArr = feedbackArr[FEEDBACK_TYPE.SUB_FEEDBACK];
    const subRandomIndex = Math.floor(Math.random() * subFeedbackArr.length);

    const subtitle2 = subFeedbackArr[subRandomIndex];
    feedback += " " + subtitle + " " + subtitle2;
  }
  return { title, subtitle, feedback, feedbackVoice: feedback };
};

export const voiceGuideIsError = () => {
  const title = $L("Measure again.");
  const subtitle = $L(
    "측정 가능 범위를 벗어났습니다. 바른 자세로 다시 측정해 주세요."
  );
  const feedback = title + " " + subtitle;
  return { title, subtitle, feedback, feedbackVoice: feedback };
};
