/**
 * TToast
 *
 * @module TToast
 */
import classNames from 'classnames';
import React, { useEffect } from 'react';
import css from './TToast.module.less';
import Alert from '@enact/sandstone/Alert/Alert';
import { useDispatch } from 'react-redux';
import { changeToastStatus } from "../../features/common/commonSlice";
import { $L } from '../../utils/helperMethods';


const TToast = ({ text, ...rest }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		setTimeout(() => {
			dispatch(changeToastStatus({ toast: false }));
		}, 1000);
	})
	 return (
		 <Alert
			className={classNames(css.ttoast)}
			open
			type="overlay"
			spotlightRestrict="none"
			scrimType="none"
		>
			{text}
		</Alert>
	 );
 };

 export default TToast;