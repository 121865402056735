import { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendBroadCast } from "../features/common/commonSlice";
import { popPanel } from "../features/panels/panelsSlice";
import * as lunaSend from "../lunaSend";
import { panel_names } from "../utils/Config";
import { CAMERA_USING_PANELS } from "../utils/Constants";
import { $L, cloneObject } from "../utils/helperMethods";
import usePrevious from "./usePrevious";

let lastCalled = new Date();
const POSEDECTION_RETRYCOUNT_MAX = 10;
const CLEAR_POSITION_LIVE_DATA_TIME = 1500;

const useActivatePositionDetection = () => {
  const dispatch = useDispatch();
  const activateRetryRef = useRef(null);
  const retryCount = useRef(null);
  const clearPositionLiveDataTimer = useRef(null);
  const [poseActivateWorking, setPoseActivateWorking] = useState(false);
  const [bodyPositionLiveData, setBodyPositionLiveData] = useState();
  const cameraSize = useSelector((state) => state.camera.cameraSize);
  const cameraSizeRef = usePrevious(cameraSize);
  const {roiSize, camera2DPose, cameraRPPGModel} = useSelector((state) => state.common.localSettings);

  // rppg
  const [bpm, setBpm] = useState(undefined);
  const [rppgWorking, setRppgWorking] = useState(false);
  const rppgWorkingRef = usePrevious(rppgWorking);
  const rppgRetryRef = useRef(null);
  const rppgRetryCount = useRef(null);

  const doClearPositionLiveData = useCallback(()=>{
    if(clearPositionLiveDataTimer.current){
      clearTimeout(clearPositionLiveDataTimer.current);
      clearPositionLiveDataTimer.current = null;
    }
    clearPositionLiveDataTimer.current = setTimeout(() => {
      setBodyPositionLiveData({joints2D:[]});
      dispatch(sendBroadCast({type: "positionLiveDataCleared"}));
    }, CLEAR_POSITION_LIVE_DATA_TIME);
  },[dispatch]);

  const getEventNotification = ({isDetectionDelayed, testMode=false}) => {
    const params = {
      category: "solution",
      id: "camera1",
      key: [
        "rPPG",
        "PoseDetection"
      ]
    };
    console.log("useActivatePositionDetection getEventNotification");
    lunaSend.getEventNotification({
      params,
      onSuccess: (res) => {
        // console.log('getEventNotification res!!!!!!!!!!!!!!!', res);
        let poseEstimation = null;
        if(res?.poseEstimation){
          poseEstimation = res.poseEstimation;
        }
        if(res?.solutions?.[0]?.data?.poseEstimation){
          poseEstimation = res.solutions[0].data.poseEstimation;
        }
        if (!!poseEstimation) {
          const current = new Date();
          if(testMode){
            console.log("testMode : useActivatePositionDetection res.poseEstimation=", poseEstimation);
            return;
          }
          if (isDetectionDelayed && current - lastCalled > 100) {
            // console.log("getEventNotification res.poseEstimation=", poseEstimation);
            // ms
            //do something
            setBodyPositionLiveData(poseEstimation[0]);
            doClearPositionLiveData();
            lastCalled = new Date();
          }
          if (!isDetectionDelayed) {
            // console.log("getEventNotification res.poseEstimation=", poseEstimation);
            setBodyPositionLiveData(poseEstimation[0]);
            doClearPositionLiveData();
          }
        }
        // console.log("getEventNotification onSuccess=", res);
        if (res?.solutions?.[0]?.data?.rPPG?.[0]?.bpm !== undefined) {
          // console.log("getEventNotification rPPG bpm=", res?.solutions?.[0]?.data?.rPPG?.[0]?.bpm);
          setBpm(res?.solutions?.[0]?.data?.rPPG?.[0]?.bpm || 0);
          // res.solution[0].data.rPPG[0].bpm return값으로 심박 bpm
        }
      },
      onFailure: (err) => {
        console.error("getEventNotification lunaSend.getEventNotification onFailure=", err);
      },
      onComplete: () => {
        // console.log("Bodyscan lunaSend.getEventNotification onComplete=", res);
      },
      subscribe: true,
    });
  };

  const activateFailed = useCallback(() => {
    if(activateRetryRef.current){
      clearTimeout(activateRetryRef.current);
      activateRetryRef.current = null;
    }
    retryCount.current = 0;
    lunaSend.createToast($L("Camera settings error."));
    CAMERA_USING_PANELS.map((panelName)=>{
      dispatch(popPanel(panelName));
    });
  },[]);

  const activatePositionDetection = ({ isDetectionDelayed, testMode= false }) => {
    if(activateRetryRef.current){
      clearTimeout(activateRetryRef.current);
      activateRetryRef.current = null;
    }
    console.log("useActivatePositionDetection activatePositionDetection cameraSizeWidth, retryCount : ", cameraSizeRef.current?.width, retryCount.current);
    if(!cameraSizeRef.current.width || !cameraSizeRef.current.height){
      if(retryCount.current >= POSEDECTION_RETRYCOUNT_MAX){
        if(typeof window === "object" && !window.PalmSystem){
          activateFailed();
        }
        return;
      }
      retryCount.current++;
      activateRetryRef.current = setTimeout(()=>{
        activatePositionDetection({isDetectionDelayed, testMode});
      },1000);
      return;
    }
    retryCount.current = 0;
    const params = {
      id: "camera1",
      solutions: [
        {
          name: "PoseDetection",
          params: {
            enable: true,
            autoPtz: false,
            "2DPose": camera2DPose,
            modelParam: {
              origImgRoiX: (cameraSizeRef.current.width-cameraSizeRef.current.width*roiSize)/2,
              origImgRoiY: 0,
              origImgRoiWidth: cameraSizeRef.current.width*roiSize,
              origImgRoiHeight: cameraSizeRef.current.height,
            }
          }
        }
      ]
    };
    if(poseActivateWorking){
      getEventNotification({isDetectionDelayed, testMode});
    }else{
      lunaSend.setSolutions({
        params,
        onSuccess: (res) => {
          setPoseActivateWorking(true);
          getEventNotification({isDetectionDelayed, testMode});
          console.log("useActivatePositionDetection lunaSend.enablePoseDetection onSuccess=", res);
        },
        onFailure: (err) => {
          console.error("useActivatePositionDetection lunaSend.enablePoseDetection onFailure=", err);
          if(typeof window === "object" && window.PalmSystem){
            activateFailed();
            if(err.errorText && err.errorText.indexOf('Denied method call')>=0){
              lunaSend.createToast("Permission denied!!!");
            }
          }else{
            setPoseActivateWorking(true);
            getEventNotification({isDetectionDelayed, testMode});
          }
        },
        onComplete: () => {
          // console.log("Bodyscan lunaSend.enablePoseDetection onComplete=", res);
        },
      });
    }
  };
  const cancelGetEventNotification = () => {
    lunaSend.cancelGetEventNotification();
  };
  const deActivatePositionDetection = () => {
    if(activateRetryRef.current){
      clearTimeout(activateRetryRef.current);
      activateRetryRef.current = null;
    }
    retryCount.current = 0;
    setPoseActivateWorking(false);
    cancelGetEventNotification();
    const params = {
      id: "camera1",
      solutions: [{name: "PoseDetection", params: {enable: false}}]
    };
    lunaSend.setSolutions({
      params,
      onSuccess: (res) => {
        console.log("useActivatePositionDetection lunaSend.disablePoseDetection onSuccess=", res);
      },
      onFailure: (err) => {
        console.error("useActivatePositionDetection lunaSend.disablePoseDetection onFailure=", err);
      }
    });

  }

  let notiQueue = [];
  const getSingleEventNotification = (xFitBodyProcessQueueSize = 1, callback) => {
    notiQueue = [];
    const params = {
      category:"solution",
      id: "camera1",
      key: [
        "FaceDetection",
        "PoseDetection"
      ]
    };
    lunaSend.getEventNotification({
      params,
      onSuccess: (res) => {
        let poseEstimation = null;
        if(res?.poseEstimation){
          poseEstimation = res.poseEstimation;
        }
        if(res?.solutions?.[0]?.data?.poseEstimation){
          poseEstimation = res.solutions[0].data.poseEstimation;
        }
        if (!!poseEstimation) {
          notiQueue.push(poseEstimation[0]);
          if (callback && notiQueue.length >= xFitBodyProcessQueueSize) {
            callback(notiQueue);
            cancelGetEventNotification();
          }
        }
      },
      onFailure: (err) => {
        console.error("useActivatePositionDetection getSingleEventNotification onFailure=", err);
        if (typeof window === 'object' && !window.PalmSystem) {
          callback({});
        }
      },
      subscribe: true,
    });
  };

  const activeRPPG = () => {
    if(rppgRetryRef.current){
      clearTimeout(rppgRetryRef.current);
      rppgRetryRef.current = null;
    }
    console.log("useActivatePositionDetection activeRPPG rppgRetryCount : ", rppgRetryCount.current);

    rppgRetryCount.current = 0;

    const size = cloneObject(cameraSizeRef.current);
    if(!size.width){
      size.width = 1280;
      size.height = 720;
    }
    const params = {
      id: "camera1",
      solutions: [
        {
          name: "rPPG",
          params: {
            enable: true,
            rPPG: cameraRPPGModel,
            modelParam: {
              origImgRoiX: (size.width-size.width*0.5)/2,
              origImgRoiY: 0,
              origImgRoiWidth: size.width*0.5,
              origImgRoiHeight: size.height,
            }
          }
        }
      ]
    };

    console.log("useActivatePositionDetection activeRPPG rppgWorkingRef : ", rppgWorkingRef.current);
    if (rppgWorkingRef.current) {
      getEventNotification({});
    } else {
      lunaSend.setSolutions({
        params,
        onSuccess: (res) => {
          setRppgWorking(true);
          getEventNotification({});
          setBpm(undefined);
          console.log("useActivatePositionDetection activeRPPG onSuccess=", res);
        },
        onFailure: (err) => {
          console.error("useActivatePositionDetection activeRPPG onFailure=", err);
          if(typeof window === "object" && window.PalmSystem){
            activateFailed();
          }else{
            setRppgWorking(true);
            getEventNotification({});
          }
        },
        onComplete: () => {
          // console.log("useActivatePositionDetection lunaSend.activeRPPG onComplete=", res);
        },
      });
    }
  };

  const deActiveRPPG = () => {
    if(rppgRetryRef.current){
      clearTimeout(rppgRetryRef.current);
      rppgRetryRef.current = null;
    }
    rppgRetryCount.current = 0;
    cancelGetEventNotification();
    const params = {
      id: "camera1",
      solutions: [{name: "rPPG", params: {enable: false}}]
    };

    if(rppgWorkingRef.current){
      lunaSend.setSolutions({
        params,
        onSuccess: (res) => {
          console.log("useActivatePositionDetection deActiveRPPG onSuccess=", res);
        },
        onFailure: (err) => {
          console.error("useActivatePositionDetection deActiveRPPG onFailure=", err);
        }
      });
    }
    setRppgWorking(false);
  }

  return {
    activatePositionDetection, //setSolution
    deActivatePositionDetection, //clear setSolution
    bodyPositionLiveData,
    getEventNotification,
    cancelGetEventNotification, //cancelEvent
    getSingleEventNotification, //only body alignment
    activeRPPG,
    deActiveRPPG,
    bpm
  };
};

export default useActivatePositionDetection;
