import { createSlice } from "@reduxjs/toolkit";
import {panel_names} from "../../utils/Config";
const initialState = {
  panels: [],
  isModalOpen: false
}

const forceTopPanels = [panel_names.ERROR_PANEL, panel_names.DEBUG_PANEL];

export const panels = createSlice({
  name: "panels",
  initialState,
  reducers: {
    addPanels: (state, action) => {
      if (!action.payload.panelInfo) {
        action.payload.panelInfo = {};
      }
      const forceTopPanelsInfo = [];
      const newState = [];
      let forceTopIndex;
      for (let index in state.panels) {
        forceTopIndex = forceTopPanels.indexOf(state.panels[index].name);
        if (forceTopIndex >= 0) {
          forceTopPanelsInfo[forceTopIndex] = state.panels[index];
        } else if (state.panels[index].name !== action.payload.name) {
          newState.push(state.panels[index]);
        }
      }
      forceTopIndex = forceTopPanels.indexOf(action.payload.name);
      if (forceTopIndex >= 0) {
        forceTopPanelsInfo[forceTopIndex] = action.payload;
      } else {
        newState.push(action.payload);
      }
      for (let i = 0; i < forceTopPanels.length; i++) {
        if (forceTopPanelsInfo[i]) {
          newState.push(forceTopPanelsInfo[i]);
        }
      }
      state.panels = newState;
    },
    popPanel: (state, action) => {
      let existIndex = -1;
			if(action?.payload){
        for (let index in state.panels) {
          if (state.panels[index].name === action.payload) {
            existIndex = index;
            break;
          }
        }
			}
			if (existIndex >= 0) { // exist
				state.panels = [...state.panels.filter((value)=>value.name !== action.payload)];
			}if(!action.payload){
				state.panels = [...state.panels.slice(0, state.panels.length - 1)];
			}
    },
    updatePanel: (state, action) => {
      let existIndex = -1;
      for (let index in state.panels) {
        if (state.panels[index].name === action.payload.name) {
          existIndex = index;
          break;
        }
      }
      if (existIndex >= 0 && action.payload.panelInfo) {
        state.panels[existIndex].panelInfo = Object.assign(
          {},
          state.panels[existIndex].panelInfo,
          action.payload.panelInfo
        );
      }
    },
    updateModalStatus: (state, action) => {
      state.isModalOpen = action.payload;
    },
    resetPanels: (state, action) => {
      state.isModalOpen = false;
      if(action.payload){
        action.payload.forEach(function (panel) {
          if(!panel.panelInfo){
            panel.panelInfo = {};
          }
        });
      }
      state.panels = action.payload ? action.payload : [];
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  addPanels,
  popPanel,
  updatePanel,
  updateModalStatus,
  resetPanels
} = panels.actions;

export default panels.reducer;
