import { useCallback, useRef } from 'react';
import css from './DetailLayout.module.less';
import TButton, {SIZES} from '../../../../components/TButton/TButton';
import TToolTip, {POSITION} from '../../../../components/TToolTip/TToolTip';

const TToolTipLayout = () => {

  const ref = useRef();

  const itemRenderer1 = useCallback((isTooltip)=>{
    return(
      <>
        <TToolTip position={POSITION.bottom} isOpen={isTooltip} >TEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXT</TToolTip>
        <TToolTip position={POSITION.top} isOpen={isTooltip}>TEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXT</TToolTip>
        <TToolTip position={POSITION.left} isOpen={isTooltip}>TEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXT</TToolTip>
        <TToolTip position={POSITION.right} isOpen={isTooltip}>TEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXT</TToolTip>
      </>
    )
  },[]);

  const itemRenderer2 = useCallback((isTooltip)=>{
    return(
      <>
        <TToolTip position={POSITION.top} relativeRef={ref} relativePosition="left" isOpen={isTooltip}>TEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXT</TToolTip>
        <TToolTip position={POSITION.bottom} relativeRef={ref} relativePosition="right" isOpen={isTooltip}>TEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXTTEXT</TToolTip>

      </>
    )
  },[]);

  return (
    <div className={css.toolTipLayout}>
      <div className={css.positionBox}>
        <TButton className={css.button} style={{position: 'relative'}} itemRenderer={itemRenderer1} size={SIZES.xLarge}> toolTip Button </TButton>
      </div>
      <div className={css.positionBox} ref={ref}>
        <TButton className={css.button} style={{position: 'relative'}} itemRenderer={itemRenderer2} size={SIZES.xLarge}> custom position </TButton>
      </div>

    </div>
  );
};


export default TToolTipLayout;
