import React from 'react';
import css from './TSummary.module.less';
import classNames from 'classnames';

// default icon
import ic_inhome_data_distance from '../../../assets/icon/ic_inhome_data_distance.svg';
import ic_inhome_data_steps from '../../../assets/icon/ic_inhome_data_steps.svg';
import ic_inhome_data_time from '../../../assets/icon/ic_inhome_data_time.svg';
import ic_inhome_data_calorie from '../../../assets/icon/ic_inhome_data_calorie.svg';
import ic_inhome_data_height from '../../../assets/icon/ic_inhome_data_height.svg';
import ic_routine_data from '../../../assets/icon/ic_routine_data.svg';
// lite icon
import ic_inhome_activity_goal from '../../../assets/icon/ic_inhome_activity_goal.svg';
import ic_inhome_activity_steps from '../../../assets/icon/ic_inhome_activity_steps.svg';
import ic_inhome_activity_time from '../../../assets/icon/ic_inhome_activity_time.svg';
import ic_inhome_activity_calorie from '../../../assets/icon/ic_inhome_activity_calorie.svg';
import ic_inhome_activity_height from '../../../assets/icon/ic_inhome_activity_height.svg';
import ic_history_point from '../../../assets/icon/ic_history_point.svg';

export const SUMMARY_TYPE = {
  distance: 'distance',
  steps: 'steps',
  time: 'time',
  calorie: 'calorie',
  height: 'height',
  routine: 'routine'
}

export const SUMMARY_SIZE = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  lite: 'lite'
}

export const SUMMARY_ALIGN = {
  left: 'left',
  center: 'center'
}

const TYPE_UNIT = {
  [SUMMARY_TYPE.distance]: ' km',
  [SUMMARY_TYPE.steps]: ' steps',
  [SUMMARY_TYPE.calorie]: ' kcal',
  [SUMMARY_TYPE.height]: ' m'
}

const ICON_TYPES = {
  [SUMMARY_TYPE.distance]: { default: ic_inhome_data_distance, lite: ic_inhome_activity_goal },
  [SUMMARY_TYPE.steps]: { default: ic_inhome_data_steps, lite: ic_inhome_activity_steps },
  [SUMMARY_TYPE.time]: { default: ic_inhome_data_time, lite: ic_inhome_activity_time },
  [SUMMARY_TYPE.calorie]: { default: ic_inhome_data_calorie, lite: ic_inhome_activity_calorie },
  [SUMMARY_TYPE.height]: { default: ic_inhome_data_height, lite: ic_inhome_activity_height },
  [SUMMARY_TYPE.routine]: { default: ic_routine_data, lite: ic_history_point }
}

const TSummary = ({ items, size = SUMMARY_SIZE.small, align = SUMMARY_ALIGN.left, className, children, ...rest }) => {
  return (
    <div className={classNames(css.tSummary, css[size], css[align], className)} {...rest}>
      {children}
      <div className={css.summaryWrap}>
        {items.map((item, idx) => {
          return (
            <div key={`summaryInfo-${idx}`} className={css.summaryItem}>
              <img src={ICON_TYPES[item.type][size === SUMMARY_SIZE.lite ? 'lite' : 'default']} alt=''/>
              <div>
                {size !== SUMMARY_SIZE.lite && <div className={css.label}>{item.label}</div>}
                <div className={css.value}>{item.value.toLocaleString()}{TYPE_UNIT[item.type] || ''}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TSummary;