import { useMemo } from "react";
import { useSelector } from "react-redux";

const useUserCheck = () => {
  const { userNumber } = useSelector((state) => state.common.appStatus);
  const { policy, survey } = useSelector((state) => state.fitService)
  const isTermsAgree = policy;
  const isSurveyFinished = survey.lastpage === 4; 

  const userCheck = useMemo(() => {
    if(!userNumber) {
      return false;
    } else if(userNumber && !isTermsAgree) {
      return false;
    } else if(userNumber && isTermsAgree && !isSurveyFinished) {
      return false;
    } else {
      return true;
    }
  }, [userNumber, isTermsAgree, isSurveyFinished]);

  const userCheckType = useMemo(() => {
    if(!userNumber) {
      return 'login';
    } else if(userNumber && !isTermsAgree) {
      return 'terms';
    } else if(userNumber && isTermsAgree && !isSurveyFinished) {
      return 'survey';
    } else {
      return false;
    }
  }, [userNumber, isTermsAgree, isSurveyFinished]);

  return { userCheck, userCheckType };

};

export default useUserCheck;

