
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Alert from "@enact/sandstone/Alert";
import css from './CesSelectWorkout.module.less';
import TButton, { SIZES } from '../TButton/TButton';
import { $L, getLocalServerUrl } from '../../utils/helperMethods';
import * as Config from '../../utils/Config';
import RadioItem from "@enact/sandstone/RadioItem";
import { addPanels, popPanel } from '../../features/panels/panelsSlice';
import { WORKOUT } from './Constants';

const CesSelectWorkout = ({ onClose, panelInfo }) => {
	const dispatch = useDispatch();
	const { serverType, localURL } = useSelector((state) => state.common.localSettings);
	const [selectTraining, setSelectTraining] = useState(0);

	const _onClose = useCallback(() => {
		if (onClose) {
      onClose();
    } else {
			dispatch(popPanel());
			if(panelInfo.enterFrom === 'bodyAlignment_summary' || panelInfo.enterFrom === 'bodyBalance_summary') {
				dispatch(popPanel(Config.panel_names.PREVIEW_PANEL));
			}
		}
	}, [onClose, panelInfo]);

	const onChangeVideo = useCallback((index) => () => {
    setSelectTraining(index);
  }, []);

	const workoutList = useMemo(() => {
		return WORKOUT?.[panelInfo.bodyPart]?.length > 0 ? WORKOUT?.[panelInfo.bodyPart] : WORKOUT.SHOULDER_03;
	}, [panelInfo]);

	const onClickStart = useCallback(() => {
		dispatch(popPanel());
    // dispatch(
    //   addPanels({
    //     name: Config.panel_names.PREVIEW_PANEL,
    //     panelInfo: { type: TYPES.BODY_ALIGNMENT },
    //   })
    // );

		dispatch(addPanels({
			name: Config.panel_names.HOMECARE_WORKOUT_PLAYER,
			panelInfo: {
				content: [
					{
						playUrl:
							getLocalServerUrl(workoutList[selectTraining]?.playUrl, serverType, localURL),
						align: "left"
					},
					{
						// aicoaching video only one
						playUrl:
							// getLocalServerUrl("https://d16h06llvpeyov.cloudfront.net/ces/sb/side_bending.mpd", serverType, localURL),
							getLocalServerUrl("https://d16h06llvpeyov.cloudfront.net/ces/test_60_4k/TEST_60_4K-60f_HEVC_15Mbps.mpd", serverType, localURL),
						align: "left"
					}
				],
				noContentInfo: true,
				enterFrom: panelInfo.enterFrom || 'workout'
			}
		}));
  }, [selectTraining]);

	return (
		<Alert
			open
			type={"fullscreen"}
			className={css.popup}
			onClose={_onClose}
		>
			<div className={css.text}>
				{$L("Where would you like to exercise?")}
			</div>
			<div className={css.Container}>
				{workoutList && workoutList.length > 0 && workoutList.map((li, idx) => (
					<RadioItem
						key={`workoutList${idx}`}
						className={css.switch}
						selected={selectTraining === idx}
						onClick={onChangeVideo(idx)}
					>
						<img className={css.switchImg} src={li.thumnail} />
					</RadioItem>
				))}
			</div>
			<div className={css.startButton}>
				<TButton size={SIZES.xLarge} onClick={onClickStart}>
					{$L("Start")}
				</TButton>
			</div>
		</Alert>
	);
};

export default CesSelectWorkout;
