import React, { useMemo, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import css from "./TestGuideSignMsg.module.less";
import AudioPlayer from "../AudioPlayer/AudioPlayer";

const TestGuideSignMsg = ({ className, message, voice, onEnded, timeOut=5000, useTimeout=true }) => {
  const timeOutRef = useRef(null);
  const [signInfo, setSignInfo] = useState({});

  useEffect(()=>{
    if(message){
      setSignInfo({instruction: message});
    }else{
      setSignInfo({});
    }
    if(useTimeout) {
      if(timeOutRef.current){
        clearTimeout(timeOutRef.current);
        timeOutRef.current=null;
      }
      timeOutRef.current = setTimeout(()=>{
        setSignInfo({});
      }, timeOut);
    }
  },[message]);

  return (
    <>
      {signInfo.instruction &&
        <div className={classNames(className, css.instructionSign)} dangerouslySetInnerHTML={{ __html: signInfo.instruction }}/>
      }
      <AudioPlayer srcTypeStr={voice} onEnded={onEnded}/>
    </>
  );
};

export default TestGuideSignMsg;