import classNames from 'classnames';
import { useEffect, useRef, useMemo} from 'react';
import css from './TStepper.module.less';
import { $L } from '../../utils/helperMethods';

const TYPES = ["horizontal", "vertical"];

const TStepper = ({order = [], number = 0, type = "horizontal", percent, className, isReverse, useStartFinish, ...rest}) => {
  const stepperRef = useRef();
  const fixNum = useMemo(() => Math.min(number, order.length - 1), [number, order]);

  useEffect(() => {
    if (TYPES.indexOf(type) < 0) {
      console.error('TStepper type error');
    }
  }, [type]);

  useEffect(() => {
    stepperRef.current.style.setProperty('--length', order.length);
    stepperRef.current.style.setProperty('--number', fixNum);
  }, [order, fixNum]);

  const orderItems = useMemo(() => {
    const arr = [...order];
    if (isReverse) arr.reverse();

    return arr;
  }, [order, isReverse]);

  return (
    <div className={classNames(css.bg, css[type], isReverse && css.reverse, useStartFinish && css.startFinish)}>
      <div
        className={classNames(
          css.stepper,
          className ? className : null
        )}
        ref={stepperRef}
      >
        <div className={css.fixedBar}>
          {Array(orderItems.length - 1)
            .fill()
            .map((_, index) => (
              <div key={"bar" + index} className={css.bar}></div>
            ))}
        </div>
        <div className={classNames(css.stepBar, percent !== undefined && css.percent)} style={{ '--percent': `${percent || 0}%` }}/>
        {orderItems && orderItems.length > 0 ? (
          orderItems.map((text, index) => {
            const isActive = (isReverse ? (orderItems.length - 1 - index) : index) <= fixNum;
            const circleNum = isReverse ? (orderItems.length - index) : index + 1;

            return (<div
              key={"step" + index}
              className={classNames(
                css.step,
                isActive ? css.active : null,
                (isReverse ? index === orderItems.length - 1 : index === 0) && css.start,
                (isReverse ? index === 0 : index === orderItems.length - 1) && css.finish,
              )}
            >
              <div className={classNames(css.circle, (useStartFinish && index > 0 && orderItems.length - 1 > index && css.reward))}>
                {(isActive && !useStartFinish) ? circleNum : ''}
              </div>
              <div className={css.text}>{text}</div>
            </div>)
          })
        ) : (
          <div>{$L("No Order")}</div>
        )}
      </div>
    </div>
  );
};

export default TStepper;