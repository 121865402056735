import React, { useState, useCallback } from "react";
import css from "./PTEmptyTestReport.module.less";
import emptyMainImg from "../../../../../assets/physicalTest/img_empty_01.png";
import checkImg from "../../../../../assets/icon/bodycheck_test.png";
import { $L, convertNewlinesToBr } from "../../../../utils/helperMethods";
import * as Config from "../../../../utils/Config";
import TButton, { SIZES } from "../../../../components/TButton/TButton";
import useUserCheck from "../../../../hooks/useUserCheck";
import { useDispatch, useSelector } from "react-redux";
import { changeAppStatus } from "../../../../features/common/commonSlice";
import { addPanels } from "../../../../features/panels/panelsSlice";
import { feedbackEmpty } from "../feedback/feedbackSFT";
import { getSFT_TEST_INFO } from "../PhysicalTestCapture/Constants";
import TestSelectPopup from "../../../../components/TestSelectPopup/TestSelectPopup";
import CameraPopup from "../../../Camera/CameraPopup";

let SFT_TEST_INFO = null;
const PTEmptyTestReport = () => {
  if(!SFT_TEST_INFO){
    SFT_TEST_INFO = getSFT_TEST_INFO();
  }
  const dispatch = useDispatch();
  const { userCheck } = useUserCheck();
  const { cameraList } = useSelector(state => state.camera);
  const initialCheckedState = Array(SFT_TEST_INFO.length).fill(true);
  const [showTestSelectPopup, setShowTestSelectPopup] = useState(false);
  const feedback = feedbackEmpty;
  const [isConnectionPopupOpen, setIsConnectionPopupOpen] = useState(false);

  const testStart = useCallback(() => {
    if (!userCheck) {
      dispatch(changeAppStatus({ showUserCheckPopup: { show: true, mode: "test" } }));
    } else if (cameraList.length < 1){
      setIsConnectionPopupOpen(true);
    } else {
      setShowTestSelectPopup(true);
    }
  }, [userCheck]);

  const closeTestSelectPopup = useCallback((ev) => {
    setShowTestSelectPopup(false);
    if (ev.checked) {
      dispatch(changeAppStatus({ showLoadingPanel: { show: true, type: 'tips' } }));
      dispatch(addPanels({name: Config.panel_names.PHYSICAL_TEST_CAPTURE, panelInfo: { sftList: ev.checked}}));
    }
  }, []);

  const onCloseCameraPopup = useCallback(() => {
    setIsConnectionPopupOpen(false);
  }, []);

  return (
    <div className={css.container}>
      <div className={css.imgBox}>
        <img src={emptyMainImg} alt="empty_img" />
      </div>
      <div className={css.textBox}>
        <p className={css.title}>{convertNewlinesToBr(feedback.title)}</p>
        <p className={css.desc}>{convertNewlinesToBr(feedback.desc1)}</p>
        <p className={css.desc} style={{ marginBottom: "42px" }}>
          {convertNewlinesToBr(feedback.desc2)}
        </p>
        <div className={css.testText}>
          <img src={checkImg} alt="check_img" />
          {$L("Do you want to proceed and start the test now?")}
        </div>
        <TButton size={SIZES.test} spotlightId="testBtn" onClick={testStart}>
          {$L("Test")}
        </TButton>
      </div>
      {showTestSelectPopup && (
        <TestSelectPopup
          testInfos={SFT_TEST_INFO}
          initialCheckedState={initialCheckedState}
          physicalTest={true}
          onClose={closeTestSelectPopup}
        />
      )}
      {isConnectionPopupOpen && <CameraPopup onClose={onCloseCameraPopup}/>}
    </div>
  );
};

export default PTEmptyTestReport;
