import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import { Job } from "@enact/core/util";
import css from "./Terms.module.less";
import { $L } from '../../utils/helperMethods';
import * as Config from '../../utils/Config';
import TermsConditionsDetail from "../TermsConditionsPanel/TermsConditionsDetail";
import TCheckboxItem from "../../components/TCheckboxItem";
import TButton, { SIZES } from "../../components/TButton/TButton";
import { addPanels, popPanel, resetPanels } from "../../features/panels/panelsSlice";
import {
  changeThemeModeStatus,
  changeLocalSettings,
} from "../../features/common/commonSlice";
import classNames from "classnames";
import { postPolicy, setPolicy } from "../../features/fitService/fitServiceSlice";
import TScroller from "../../components/TScroller/TScroller";

// const Container = SpotlightContainerDecorator(
//   { enterTo: "default-element" , defaultElement: ".checkbox"},
//   "div"
// );
const Container = SpotlightContainerDecorator({ enterTo: "default-element" }, "div");
const SpottableComponent = Spottable("div");

const Terms = ({mainTerms}) => {
  const dispatch = useDispatch();
	const TERMS = [
		{title: $L('약관 전체 동의'), detail: false},
		{title: $L("[Required fields] Terms and conditions agreement for using the camera app"), detail: true},
		{title: $L("[Required fields] Terms and conditions agreement for using your health information"), detail: true},
	];
  const { userNumber, themeMode } = useSelector(
    (state) => state.common.appStatus
  );
  const { policy } = useSelector((state) => state.fitService);
	const [checked, setChecked] = useState(() => [...TERMS.map(() => false)]);
  // const [termsPopupDetail, setTermsPopupDetail] = useState(false);
	const [popupIndex, setPopupIndex] = useState(1);
  const [pressedIndex, setPressedIndex] = useState(-1);

  useEffect(() => {
    dispatch(changeThemeModeStatus("dark"));
    return () => dispatch(changeThemeModeStatus("light"));
  }, []);

	const onToggle = useCallback((index) => () => {
    //todo 전체 동의 선택 시 동작 확인
    setChecked(prevChecked => {
      const newChecked = [...prevChecked];
      if (index === 0) {
        const allSelected = newChecked.slice(1).every(val => val);
        newChecked[0] = !newChecked[0];
        if (allSelected) {
          for (let i = 1; i < newChecked.length; i++) {
            newChecked[i] = false;
          }
        }
        if (newChecked[0]) {
          for (let i = 1; i < newChecked.length; i++) {
            newChecked[i] = true;
          }
        }
      } else {
        setPopupIndex(index);
        // if(!newChecked[index]) {
        //   setPopupIndex(index);
        //   setTermsPopupDetail(true);
        // } else {
          newChecked[index] = !newChecked[index];
          if (!newChecked[index]) {
            newChecked[0] = false;
          } else {
            if (newChecked.slice(1).every(val => val)) {
                newChecked[0] = true;
            }
          }
        // }
      }
      return newChecked;
    });
}, []);

  // const termsDetail = useCallback((index) => () =>{
  //   setPopupIndex(index);
  //   setTermsPopupDetail(true);
  //   setPressedIndex(index);
  //   clearPressedIndexJob.current.start();
  // },[]);

  const clearPressedIndexJob = useRef(new Job((func) => {
    setPressedIndex(-1);
    setTimeout(func, Config.BUTTON_PRESS_DELAY);
  }, Config.BUTTON_PRESS_DELAY));

  // const _onBlur = useCallback(() => {
  //   setPressedIndex(-1);
  //   clearPressedIndexJob.current.stop();
	// },[]);

  const goMainView = useCallback(()=>{
    dispatch(resetPanels());
  },[]);

  const onContinue = useCallback(()=>{
    console.log('go to terms and conditions agreement');
    dispatch(popPanel());
    dispatch(setPolicy(true));
    dispatch(postPolicy());
    dispatch(addPanels({ name: Config.panel_names.SURVEY_PANEL }));
  },[userNumber, policy]);

	// const onClosePopup = useCallback(()=>{
	// 	setTermsPopupDetail(false);
	// },[]);

  const requiredCheckboxIndexes = [1, 2];
  const isButtonDisabled = requiredCheckboxIndexes.some(index => !checked[index]);

	return (
    <div>
      <div className={css.header}>
        <div className={css.title}>{$L("Terms and Conditions of Use")}</div>
        <div className={css.desc}>{$L("Select one or more terms and conditions and tick the [I Agree] box.")}</div>
      </div>
      <div className={css.container}>
        <div className={css.termsList}>
          {TERMS.map((list, index)=>{
            return(
              <div
                key={index}
                className={css.innerContainer}
              >
                <TCheckboxItem
                  title={list.title}
                  inline={true}
                  selected={checked[index]}
                  onToggle={onToggle(index)}
                  className={css.checkItem}
                >
                  {/* {list.detail && <SpottableComponent
                    className={classNames(css.detail, pressedIndex === index && css.pressed)}
                    onClick={termsDetail(index)}
                    onBlur={_onBlur}
                  >
                    {$L("View")}
                  </SpottableComponent>} */}
                </TCheckboxItem>
              </div>
            );
          })}
        </div>
        {/* container로 감싸기 */}
        <Container className={css.termsLayer}>
          <TermsConditionsDetail
            type="half"
            index={popupIndex}
            // setChecked={setChecked}
          />
        </Container>
        <div className={css.btnBox}>
          <TButton size={SIZES.xLarge} onClick={goMainView}>{$L("Explore")}</TButton>
          <TButton size={SIZES.xLarge} onClick={onContinue} disabled={isButtonDisabled}>{$L("I Agree")}</TButton>
        </div>
        {/* {mainTerms ? (
            <div className={css.btnBox}>
              <TButton size={SIZES.xLarge} onClick={goMainView}>{$L("Explore")}</TButton>
              <TButton size={SIZES.xLarge} onClick={onContinue} disabled={isButtonDisabled}>{$L("계속하기")}</TButton>
            </div>
          ) : (
            <div className={css.btnBox}>
              <TButton size={SIZES.xLarge} onClick={onContinue} disabled={isButtonDisabled}>{$L("계속하기")}</TButton>
            </div>
          )} */}
        {/* {termsPopupDetail &&
          <TermsConditionsDetail index={popupIndex} onClose={onClosePopup} setChecked={setChecked}/>
        } */}
      </div>
    </div>
	);
};

export default Terms;
