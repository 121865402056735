import classNames from 'classnames';
import css from "./MyAvatar.module.less";

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { $L } from '../../../utils/helperMethods';

import TButton, { SIZES } from '../../../components/TButton/TButton';
import TSettingSection from '../../../components/TSettingSection/TSettingSection';
import Avatar from './Avatar/Avatar';

const MyAvatar = () => {
  const [avatarToShow, setAvatarToShow] = useState(true);
  const onClickSetting = useCallback((ev) => {
	}, []);

  const onClickCacel = useCallback((ev) => {
	}, []);

  const onClickSend = useCallback((ev) => () => {
	}, []);

  return (
    <>
      {/* todo : setting section */}
      <TSettingSection
        className={css.setting}
        text={$L("Show avatar")}
        subText={$L("Select an avatar to see your avatar doing the workout")}
        button1={{ text: $L("ON") }}
        button2={{ text: $L("OFF") }}
        onClick={onClickSetting}
      />
      <Avatar className={css.avatar}/>
      <div className={css.buttonContainer}>
        <TButton size={SIZES.xLarge} onClick={onClickCacel}>{$L("Cancel")}</TButton>
        <TButton size={SIZES.xLarge} onClick={onClickSend}>{$L("Send")}</TButton>
      </div>
    </>
  );
};

export default MyAvatar;