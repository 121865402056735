import { createSlice } from "@reduxjs/toolkit";

import Sets from "../../../assets/HomecareProgram/ic_sets.svg";
import Time from "../../../assets/HomecareProgram/ic_time.svg";
import Kcal from "../../../assets/HomecareProgram/ic_kcal.svg";
import Points from "../../../assets/HomecareProgram/ic_points.svg";
import DummyImage from "../../../assets/HomecareProgram/dummyImage.png";
import TITLE_C_4_3_A from "../../../assets/images/TITLE_C_4_3_A.jpg";
import TITLE_C_1_10_A from "../../../assets/images/TITLE_C_1_10_A.jpg";
import TITLE_C_5_12_A from "../../../assets/images/TITLE_C_5_12_A.jpg";

const initialState = {
  weeklyList: [
    {
      title: "1주차",
      status: "지난 운동",
      infoTitle: "스마트 시니어를 위한 건강 예방 프로그램 1주차",
      infoCont: "지속적인 움직임으로 원활한 신진대사 촉진 및 관절 가동범위를 증가시켜 평소 생활에서 올 수 있는 상해로부터 예방 할 수 있도록 합니다.",
      completedExercise: 2,
      dailyList: [
        {
          date: "2023.12.11",
          image: TITLE_C_4_3_A,
          expandCategory: "어깨 가동범위",
          expandTitle: "굽은 등을 바르게 펴는 스트레칭",
          summary: [
            { progress: 3, total: "3 sets", img: Sets },
            { progress: "30:00", total: "30:00", img: Time },
            { progress: 300, total: "300 kcal", img: Kcal },
            { progress: 150, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 100
        },
        {
          date: "2023.12.13",
          image: TITLE_C_1_10_A,
          expandCategory: "하지 근력",
          expandTitle: "다리 근감소 예방 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        },
        {
          date: "2023.12.15",
          image: TITLE_C_5_12_A,
          expandCategory: "활력과 균형",
          expandTitle: "동적균형을 키우는 유산소 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        }
      ]
    },
    {
      title: "2주차",
      status: "진행 중",
      infoTitle: "스마트 시니어를 위한 건강 예방 프로그램 2주차",
      infoCont: "지속적인 움직임으로 원활한 신진대사 촉진 및 관절 가동범위를 증가시켜 평소 생활에서 올 수 있는 상해로부터 예방 할 수 있도록 합니다.",
      completedExercise: 0,
      dailyList: [
        {
          date: "2023.12.18",
          image: TITLE_C_4_3_A,
          expandCategory: "어깨 가동범위",
          expandTitle: "굽은 등을 바르게 펴는 스트레칭",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        },
        {
          date: "2023.12.20",
          image: TITLE_C_1_10_A,
          expandCategory: "하지 근력",
          expandTitle: "다리 근감소 예방 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        },
        {
          date: "2023.12.22",
          image: TITLE_C_5_12_A,
          expandCategory: "활력과 균형",
          expandTitle: "동적균형을 키우는 유산소 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        }
      ]
    },
    {
      title: "3주차",
      status: "진행 예정",
      infoTitle: "스마트 시니어를 위한 건강 예방 프로그램 3주차",
      infoCont: "지속적인 움직임으로 원활한 신진대사 촉진 및 관절 가동범위를 증가시켜 평소 생활에서 올 수 있는 상해로부터 예방 할 수 있도록 합니다.",
      completedExercise: 0,
      dailyList: [
        {
          date: "2023.12.25",
          image: TITLE_C_4_3_A,
          expandCategory: "어깨 가동범위",
          expandTitle: "굽은 등을 바르게 펴는 스트레칭",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        },
        {
          date: "2023.12.27",
          image: TITLE_C_1_10_A,
          expandCategory: "하지 근력",
          expandTitle: "다리 근감소 예방 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        },
        {
          date: "2023.12.29",
          image: TITLE_C_5_12_A,
          expandCategory: "활력과 균형",
          expandTitle: "동적균형을 키우는 유산소 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        }
      ]
    },
    {
      title: "4주차",
      status: "진행 예정",
      infoTitle: "스마트 시니어를 위한 건강 예방 프로그램 4주차",
      infoCont: "지속적인 움직임으로 원활한 신진대사 촉진 및 관절 가동범위를 증가시켜 평소 생활에서 올 수 있는 상해로부터 예방 할 수 있도록 합니다.",
      completedExercise: 0,
      dailyList: [
        {
          date: "2024-01-01",
          image: TITLE_C_4_3_A,
          expandCategory: "어깨 가동범위",
          expandTitle: "굽은 등을 바르게 펴는 스트레칭",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        },
        {
          date: "2024-01-03",
          image: TITLE_C_1_10_A,
          expandCategory: "하지 근력",
          expandTitle: "다리 근감소 예방 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        },
        {
          date: "2024-01-05",
          image: TITLE_C_5_12_A,
          expandCategory: "활력과 균형",
          expandTitle: "동적균형을 키우는 유산소 운동",
          summary: [
            { progress: 0, total: "3 sets", img: Sets },
            { progress: "00:00", total: "30:00", img: Time },
            { progress: 0, total: "300 kcal", img: Kcal },
            { progress: 0, total: "150 G Points", img: Points },
          ],
          exerciseProgress: 0
        }
      ]
    },
  ],
};

export const WeeklyStatus = createSlice({
  name: "WeeklyStatus",
  initialState,
  reducers: {
    setWeeklyStatus: (state, action) => { },
  },
});

export const { setWeeklyStatus } = WeeklyStatus.actions;

export default WeeklyStatus.reducer;